const district = [
    {
        "name": "Quận Ba Đình",
        "code": "quan_ba_dinh",
        "division_type": "quận",
        "short_codename": "ba_dinh",
        "wards": 14,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Hoàn Kiếm",
        "code": "quan_hoan_kiem",
        "division_type": "quận",
        "short_codename": "hoan_kiem",
        "wards": 18,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Tây Hồ",
        "code": "quan_tay_ho",
        "division_type": "quận",
        "short_codename": "tay_ho",
        "wards": 8,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Long Biên",
        "code": "quan_long_bien",
        "division_type": "quận",
        "short_codename": "long_bien",
        "wards": 14,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Cầu Giấy",
        "code": "quan_cau_giay",
        "division_type": "quận",
        "short_codename": "cau_giay",
        "wards": 8,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Đống Đa",
        "code": "quan_dong_da",
        "division_type": "quận",
        "short_codename": "dong_da",
        "wards": 21,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Hai Bà Trưng",
        "code": "quan_hai_ba_trung",
        "division_type": "quận",
        "short_codename": "hai_ba_trung",
        "wards": 18,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Hoàng Mai",
        "code": "quan_hoang_mai",
        "division_type": "quận",
        "short_codename": "hoang_mai",
        "wards": 14,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Thanh Xuân",
        "code": "quan_thanh_xuan",
        "division_type": "quận",
        "short_codename": "thanh_xuan",
        "wards": 11,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Sóc Sơn",
        "code": "huyen_soc_son",
        "division_type": "huyện",
        "short_codename": "soc_son",
        "wards": 26,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Đông Anh",
        "code": "huyen_dong_anh",
        "division_type": "huyện",
        "short_codename": "dong_anh",
        "wards": 24,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Gia Lâm",
        "code": "huyen_gia_lam",
        "division_type": "huyện",
        "short_codename": "gia_lam",
        "wards": 22,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Nam Từ Liêm",
        "code": "quan_nam_tu_liem",
        "division_type": "quận",
        "short_codename": "nam_tu_liem",
        "wards": 10,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Thanh Trì",
        "code": "huyen_thanh_tri",
        "division_type": "huyện",
        "short_codename": "thanh_tri",
        "wards": 16,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Bắc Từ Liêm",
        "code": "quan_bac_tu_liem",
        "division_type": "quận",
        "short_codename": "bac_tu_liem",
        "wards": 13,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Mê Linh",
        "code": "huyen_me_linh",
        "division_type": "huyện",
        "short_codename": "me_linh",
        "wards": 18,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Quận Hà Đông",
        "code": "quan_ha_dong",
        "division_type": "quận",
        "short_codename": "ha_dong",
        "wards": 17,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Thị xã Sơn Tây",
        "code": "thi_xa_son_tay",
        "division_type": "thị xã",
        "short_codename": "son_tay",
        "wards": 15,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Ba Vì",
        "code": "huyen_ba_vi",
        "division_type": "huyện",
        "short_codename": "ba_vi",
        "wards": 31,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Phúc Thọ",
        "code": "huyen_phuc_tho",
        "division_type": "huyện",
        "short_codename": "phuc_tho",
        "wards": 21,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Đan Phượng",
        "code": "huyen_dan_phuong",
        "division_type": "huyện",
        "short_codename": "dan_phuong",
        "wards": 16,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Hoài Đức",
        "code": "huyen_hoai_duc",
        "division_type": "huyện",
        "short_codename": "hoai_duc",
        "wards": 20,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Quốc Oai",
        "code": "huyen_quoc_oai",
        "division_type": "huyện",
        "short_codename": "quoc_oai",
        "wards": 21,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Thạch Thất",
        "code": "huyen_thach_that",
        "division_type": "huyện",
        "short_codename": "thach_that",
        "wards": 23,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Chương Mỹ",
        "code": "huyen_chuong_my",
        "division_type": "huyện",
        "short_codename": "chuong_my",
        "wards": 32,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Thanh Oai",
        "code": "huyen_thanh_oai",
        "division_type": "huyện",
        "short_codename": "thanh_oai",
        "wards": 21,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Thường Tín",
        "code": "huyen_thuong_tin",
        "division_type": "huyện",
        "short_codename": "thuong_tin",
        "wards": 29,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Phú Xuyên",
        "code": "huyen_phu_xuyen",
        "division_type": "huyện",
        "short_codename": "phu_xuyen",
        "wards": 27,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Ứng Hòa",
        "code": "huyen_ung_hoa",
        "division_type": "huyện",
        "short_codename": "ung_hoa",
        "wards": 29,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Huyện Mỹ Đức",
        "code": "huyen_my_duc",
        "division_type": "huyện",
        "short_codename": "my_duc",
        "wards": 22,
        "parentCode": "thanh_pho_ha_noi"
    },
    {
        "name": "Thành phố Hà Giang",
        "code": "thanh_pho_ha_giang",
        "division_type": "thành phố",
        "short_codename": "ha_giang",
        "wards": 8,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Đồng Văn",
        "code": "huyen_dong_van",
        "division_type": "huyện",
        "short_codename": "dong_van",
        "wards": 19,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Mèo Vạc",
        "code": "huyen_meo_vac",
        "division_type": "huyện",
        "short_codename": "meo_vac",
        "wards": 18,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Yên Minh",
        "code": "huyen_yen_minh",
        "division_type": "huyện",
        "short_codename": "yen_minh",
        "wards": 18,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Quản Bạ",
        "code": "huyen_quan_ba",
        "division_type": "huyện",
        "short_codename": "quan_ba",
        "wards": 13,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Vị Xuyên",
        "code": "huyen_vi_xuyen",
        "division_type": "huyện",
        "short_codename": "vi_xuyen",
        "wards": 24,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Bắc Mê",
        "code": "huyen_bac_me",
        "division_type": "huyện",
        "short_codename": "bac_me",
        "wards": 13,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Hoàng Su Phì",
        "code": "huyen_hoang_su_phi",
        "division_type": "huyện",
        "short_codename": "hoang_su_phi",
        "wards": 24,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Xín Mần",
        "code": "huyen_xin_man",
        "division_type": "huyện",
        "short_codename": "xin_man",
        "wards": 18,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Bắc Quang",
        "code": "huyen_bac_quang",
        "division_type": "huyện",
        "short_codename": "bac_quang",
        "wards": 23,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Huyện Quang Bình",
        "code": "huyen_quang_binh",
        "division_type": "huyện",
        "short_codename": "quang_binh",
        "wards": 15,
        "parentCode": "tinh_ha_giang"
    },
    {
        "name": "Thành phố Cao Bằng",
        "code": "thanh_pho_cao_bang",
        "division_type": "thành phố",
        "short_codename": "cao_bang",
        "wards": 11,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Huyện Bảo Lâm",
        "code": "huyen_bao_lam",
        "division_type": "huyện",
        "short_codename": "bao_lam",
        "wards": 13,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Huyện Bảo Lạc",
        "code": "huyen_bao_lac",
        "division_type": "huyện",
        "short_codename": "bao_lac",
        "wards": 17,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Huyện Hà Quảng",
        "code": "huyen_ha_quang",
        "division_type": "huyện",
        "short_codename": "ha_quang",
        "wards": 21,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Huyện Trùng Khánh",
        "code": "huyen_trung_khanh",
        "division_type": "huyện",
        "short_codename": "trung_khanh",
        "wards": 21,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Huyện Hạ Lang",
        "code": "huyen_ha_lang",
        "division_type": "huyện",
        "short_codename": "ha_lang",
        "wards": 13,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Huyện Quảng Hòa",
        "code": "huyen_quang_hoa",
        "division_type": "huyện",
        "short_codename": "quang_hoa",
        "wards": 19,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Huyện Hoà An",
        "code": "huyen_hoa_an",
        "division_type": "huyện",
        "short_codename": "hoa_an",
        "wards": 15,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Huyện Nguyên Bình",
        "code": "huyen_nguyen_binh",
        "division_type": "huyện",
        "short_codename": "nguyen_binh",
        "wards": 17,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Huyện Thạch An",
        "code": "huyen_thach_an",
        "division_type": "huyện",
        "short_codename": "thach_an",
        "wards": 14,
        "parentCode": "tinh_cao_bang"
    },
    {
        "name": "Thành Phố Bắc Kạn",
        "code": "thanh_pho_bac_kan",
        "division_type": "thành phố",
        "short_codename": "bac_kan",
        "wards": 8,
        "parentCode": "tinh_bac_kan"
    },
    {
        "name": "Huyện Pác Nặm",
        "code": "huyen_pac_nam",
        "division_type": "huyện",
        "short_codename": "pac_nam",
        "wards": 10,
        "parentCode": "tinh_bac_kan"
    },
    {
        "name": "Huyện Ba Bể",
        "code": "huyen_ba_be",
        "division_type": "huyện",
        "short_codename": "ba_be",
        "wards": 15,
        "parentCode": "tinh_bac_kan"
    },
    {
        "name": "Huyện Ngân Sơn",
        "code": "huyen_ngan_son",
        "division_type": "huyện",
        "short_codename": "ngan_son",
        "wards": 10,
        "parentCode": "tinh_bac_kan"
    },
    {
        "name": "Huyện Bạch Thông",
        "code": "huyen_bach_thong",
        "division_type": "huyện",
        "short_codename": "bach_thong",
        "wards": 14,
        "parentCode": "tinh_bac_kan"
    },
    {
        "name": "Huyện Chợ Đồn",
        "code": "huyen_cho_don",
        "division_type": "huyện",
        "short_codename": "cho_don",
        "wards": 20,
        "parentCode": "tinh_bac_kan"
    },
    {
        "name": "Huyện Chợ Mới",
        "code": "huyen_cho_moi",
        "division_type": "huyện",
        "short_codename": "cho_moi",
        "wards": 14,
        "parentCode": "tinh_bac_kan"
    },
    {
        "name": "Huyện Na Rì",
        "code": "huyen_na_ri",
        "division_type": "huyện",
        "short_codename": "na_ri",
        "wards": 17,
        "parentCode": "tinh_bac_kan"
    },
    {
        "name": "Thành phố Tuyên Quang",
        "code": "thanh_pho_tuyen_quang",
        "division_type": "thành phố",
        "short_codename": "tuyen_quang",
        "wards": 15,
        "parentCode": "tinh_tuyen_quang"
    },
    {
        "name": "Huyện Lâm Bình",
        "code": "huyen_lam_binh",
        "division_type": "huyện",
        "short_codename": "lam_binh",
        "wards": 8,
        "parentCode": "tinh_tuyen_quang"
    },
    {
        "name": "Huyện Na Hang",
        "code": "huyen_na_hang",
        "division_type": "huyện",
        "short_codename": "na_hang",
        "wards": 12,
        "parentCode": "tinh_tuyen_quang"
    },
    {
        "name": "Huyện Chiêm Hóa",
        "code": "huyen_chiem_hoa",
        "division_type": "huyện",
        "short_codename": "chiem_hoa",
        "wards": 26,
        "parentCode": "tinh_tuyen_quang"
    },
    {
        "name": "Huyện Hàm Yên",
        "code": "huyen_ham_yen",
        "division_type": "huyện",
        "short_codename": "ham_yen",
        "wards": 18,
        "parentCode": "tinh_tuyen_quang"
    },
    {
        "name": "Huyện Yên Sơn",
        "code": "huyen_yen_son",
        "division_type": "huyện",
        "short_codename": "yen_son",
        "wards": 28,
        "parentCode": "tinh_tuyen_quang"
    },
    {
        "name": "Huyện Sơn Dương",
        "code": "huyen_son_duong",
        "division_type": "huyện",
        "short_codename": "son_duong",
        "wards": 31,
        "parentCode": "tinh_tuyen_quang"
    },
    {
        "name": "Thành phố Lào Cai",
        "code": "thanh_pho_lao_cai",
        "division_type": "thành phố",
        "short_codename": "lao_cai",
        "wards": 17,
        "parentCode": "tinh_lao_cai"
    },
    {
        "name": "Huyện Bát Xát",
        "code": "huyen_bat_xat",
        "division_type": "huyện",
        "short_codename": "bat_xat",
        "wards": 21,
        "parentCode": "tinh_lao_cai"
    },
    {
        "name": "Huyện Mường Khương",
        "code": "huyen_muong_khuong",
        "division_type": "huyện",
        "short_codename": "muong_khuong",
        "wards": 16,
        "parentCode": "tinh_lao_cai"
    },
    {
        "name": "Huyện Si Ma Cai",
        "code": "huyen_si_ma_cai",
        "division_type": "huyện",
        "short_codename": "si_ma_cai",
        "wards": 10,
        "parentCode": "tinh_lao_cai"
    },
    {
        "name": "Huyện Bắc Hà",
        "code": "huyen_bac_ha",
        "division_type": "huyện",
        "short_codename": "bac_ha",
        "wards": 19,
        "parentCode": "tinh_lao_cai"
    },
    {
        "name": "Huyện Bảo Thắng",
        "code": "huyen_bao_thang",
        "division_type": "huyện",
        "short_codename": "bao_thang",
        "wards": 14,
        "parentCode": "tinh_lao_cai"
    },
    {
        "name": "Huyện Bảo Yên",
        "code": "huyen_bao_yen",
        "division_type": "huyện",
        "short_codename": "bao_yen",
        "wards": 17,
        "parentCode": "tinh_lao_cai"
    },
    {
        "name": "Thị xã Sa Pa",
        "code": "thi_xa_sa_pa",
        "division_type": "thị xã",
        "short_codename": "sa_pa",
        "wards": 16,
        "parentCode": "tinh_lao_cai"
    },
    {
        "name": "Huyện Văn Bàn",
        "code": "huyen_van_ban",
        "division_type": "huyện",
        "short_codename": "van_ban",
        "wards": 22,
        "parentCode": "tinh_lao_cai"
    },
    {
        "name": "Thành phố Điện Biên Phủ",
        "code": "thanh_pho_dien_bien_phu",
        "division_type": "thành phố",
        "short_codename": "dien_bien_phu",
        "wards": 12,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Thị Xã Mường Lay",
        "code": "thi_xa_muong_lay",
        "division_type": "thị xã",
        "short_codename": "muong_lay",
        "wards": 3,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Huyện Mường Nhé",
        "code": "huyen_muong_nhe",
        "division_type": "huyện",
        "short_codename": "muong_nhe",
        "wards": 11,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Huyện Mường Chà",
        "code": "huyen_muong_cha",
        "division_type": "huyện",
        "short_codename": "muong_cha",
        "wards": 12,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Huyện Tủa Chùa",
        "code": "huyen_tua_chua",
        "division_type": "huyện",
        "short_codename": "tua_chua",
        "wards": 12,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Huyện Tuần Giáo",
        "code": "huyen_tuan_giao",
        "division_type": "huyện",
        "short_codename": "tuan_giao",
        "wards": 19,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Huyện Điện Biên",
        "code": "huyen_dien_bien",
        "division_type": "huyện",
        "short_codename": "dien_bien",
        "wards": 21,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Huyện Điện Biên Đông",
        "code": "huyen_dien_bien_dong",
        "division_type": "huyện",
        "short_codename": "dien_bien_dong",
        "wards": 14,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Huyện Mường Ảng",
        "code": "huyen_muong_ang",
        "division_type": "huyện",
        "short_codename": "muong_ang",
        "wards": 10,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Huyện Nậm Pồ",
        "code": "huyen_nam_po",
        "division_type": "huyện",
        "short_codename": "nam_po",
        "wards": 15,
        "parentCode": "tinh_dien_bien"
    },
    {
        "name": "Thành phố Lai Châu",
        "code": "thanh_pho_lai_chau",
        "division_type": "thành phố",
        "short_codename": "lai_chau",
        "wards": 7,
        "parentCode": "tinh_lai_chau"
    },
    {
        "name": "Huyện Tam Đường",
        "code": "huyen_tam_duong",
        "division_type": "huyện",
        "short_codename": "tam_duong",
        "wards": 13,
        "parentCode": "tinh_lai_chau"
    },
    {
        "name": "Huyện Mường Tè",
        "code": "huyen_muong_te",
        "division_type": "huyện",
        "short_codename": "muong_te",
        "wards": 14,
        "parentCode": "tinh_lai_chau"
    },
    {
        "name": "Huyện Sìn Hồ",
        "code": "huyen_sin_ho",
        "division_type": "huyện",
        "short_codename": "sin_ho",
        "wards": 22,
        "parentCode": "tinh_lai_chau"
    },
    {
        "name": "Huyện Phong Thổ",
        "code": "huyen_phong_tho",
        "division_type": "huyện",
        "short_codename": "phong_tho",
        "wards": 17,
        "parentCode": "tinh_lai_chau"
    },
    {
        "name": "Huyện Than Uyên",
        "code": "huyen_than_uyen",
        "division_type": "huyện",
        "short_codename": "than_uyen",
        "wards": 12,
        "parentCode": "tinh_lai_chau"
    },
    {
        "name": "Huyện Tân Uyên",
        "code": "huyen_tan_uyen",
        "division_type": "huyện",
        "short_codename": "tan_uyen",
        "wards": 10,
        "parentCode": "tinh_lai_chau"
    },
    {
        "name": "Huyện Nậm Nhùn",
        "code": "huyen_nam_nhun",
        "division_type": "huyện",
        "short_codename": "nam_nhun",
        "wards": 11,
        "parentCode": "tinh_lai_chau"
    },
    {
        "name": "Thành phố Sơn La",
        "code": "thanh_pho_son_la",
        "division_type": "thành phố",
        "short_codename": "son_la",
        "wards": 12,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Quỳnh Nhai",
        "code": "huyen_quynh_nhai",
        "division_type": "huyện",
        "short_codename": "quynh_nhai",
        "wards": 11,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Thuận Châu",
        "code": "huyen_thuan_chau",
        "division_type": "huyện",
        "short_codename": "thuan_chau",
        "wards": 29,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Mường La",
        "code": "huyen_muong_la",
        "division_type": "huyện",
        "short_codename": "muong_la",
        "wards": 16,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Bắc Yên",
        "code": "huyen_bac_yen",
        "division_type": "huyện",
        "short_codename": "bac_yen",
        "wards": 16,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Phù Yên",
        "code": "huyen_phu_yen",
        "division_type": "huyện",
        "short_codename": "phu_yen",
        "wards": 27,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Mộc Châu",
        "code": "huyen_moc_chau",
        "division_type": "huyện",
        "short_codename": "moc_chau",
        "wards": 15,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Yên Châu",
        "code": "huyen_yen_chau",
        "division_type": "huyện",
        "short_codename": "yen_chau",
        "wards": 15,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Mai Sơn",
        "code": "huyen_mai_son",
        "division_type": "huyện",
        "short_codename": "mai_son",
        "wards": 22,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Sông Mã",
        "code": "huyen_song_ma",
        "division_type": "huyện",
        "short_codename": "song_ma",
        "wards": 19,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Sốp Cộp",
        "code": "huyen_sop_cop",
        "division_type": "huyện",
        "short_codename": "sop_cop",
        "wards": 8,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Huyện Vân Hồ",
        "code": "huyen_van_ho",
        "division_type": "huyện",
        "short_codename": "van_ho",
        "wards": 14,
        "parentCode": "tinh_son_la"
    },
    {
        "name": "Thành phố Yên Bái",
        "code": "thanh_pho_yen_bai",
        "division_type": "thành phố",
        "short_codename": "yen_bai",
        "wards": 15,
        "parentCode": "tinh_yen_bai"
    },
    {
        "name": "Thị xã Nghĩa Lộ",
        "code": "thi_xa_nghia_lo",
        "division_type": "thị xã",
        "short_codename": "nghia_lo",
        "wards": 14,
        "parentCode": "tinh_yen_bai"
    },
    {
        "name": "Huyện Lục Yên",
        "code": "huyen_luc_yen",
        "division_type": "huyện",
        "short_codename": "luc_yen",
        "wards": 24,
        "parentCode": "tinh_yen_bai"
    },
    {
        "name": "Huyện Văn Yên",
        "code": "huyen_van_yen",
        "division_type": "huyện",
        "short_codename": "van_yen",
        "wards": 25,
        "parentCode": "tinh_yen_bai"
    },
    {
        "name": "Huyện Mù Căng Chải",
        "code": "huyen_mu_cang_chai",
        "division_type": "huyện",
        "short_codename": "mu_cang_chai",
        "wards": 14,
        "parentCode": "tinh_yen_bai"
    },
    {
        "name": "Huyện Trấn Yên",
        "code": "huyen_tran_yen",
        "division_type": "huyện",
        "short_codename": "tran_yen",
        "wards": 21,
        "parentCode": "tinh_yen_bai"
    },
    {
        "name": "Huyện Trạm Tấu",
        "code": "huyen_tram_tau",
        "division_type": "huyện",
        "short_codename": "tram_tau",
        "wards": 12,
        "parentCode": "tinh_yen_bai"
    },
    {
        "name": "Huyện Văn Chấn",
        "code": "huyen_van_chan",
        "division_type": "huyện",
        "short_codename": "van_chan",
        "wards": 24,
        "parentCode": "tinh_yen_bai"
    },
    {
        "name": "Huyện Yên Bình",
        "code": "huyen_yen_binh",
        "division_type": "huyện",
        "short_codename": "yen_binh",
        "wards": 24,
        "parentCode": "tinh_yen_bai"
    },
    {
        "name": "Thành phố Hòa Bình",
        "code": "thanh_pho_hoa_binh",
        "division_type": "thành phố",
        "short_codename": "hoa_binh",
        "wards": 19,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Huyện Đà Bắc",
        "code": "huyen_da_bac",
        "division_type": "huyện",
        "short_codename": "da_bac",
        "wards": 17,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Huyện Lương Sơn",
        "code": "huyen_luong_son",
        "division_type": "huyện",
        "short_codename": "luong_son",
        "wards": 11,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Huyện Kim Bôi",
        "code": "huyen_kim_boi",
        "division_type": "huyện",
        "short_codename": "kim_boi",
        "wards": 17,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Huyện Cao Phong",
        "code": "huyen_cao_phong",
        "division_type": "huyện",
        "short_codename": "cao_phong",
        "wards": 10,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Huyện Tân Lạc",
        "code": "huyen_tan_lac",
        "division_type": "huyện",
        "short_codename": "tan_lac",
        "wards": 16,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Huyện Mai Châu",
        "code": "huyen_mai_chau",
        "division_type": "huyện",
        "short_codename": "mai_chau",
        "wards": 16,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Huyện Lạc Sơn",
        "code": "huyen_lac_son",
        "division_type": "huyện",
        "short_codename": "lac_son",
        "wards": 24,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Huyện Yên Thủy",
        "code": "huyen_yen_thuy",
        "division_type": "huyện",
        "short_codename": "yen_thuy",
        "wards": 11,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Huyện Lạc Thủy",
        "code": "huyen_lac_thuy",
        "division_type": "huyện",
        "short_codename": "lac_thuy",
        "wards": 10,
        "parentCode": "tinh_hoa_binh"
    },
    {
        "name": "Thành phố Thái Nguyên",
        "code": "thanh_pho_thai_nguyen",
        "division_type": "thành phố",
        "short_codename": "thai_nguyen",
        "wards": 32,
        "parentCode": "tinh_thai_nguyen"
    },
    {
        "name": "Thành phố Sông Công",
        "code": "thanh_pho_song_cong",
        "division_type": "thành phố",
        "short_codename": "song_cong",
        "wards": 10,
        "parentCode": "tinh_thai_nguyen"
    },
    {
        "name": "Huyện Định Hóa",
        "code": "huyen_dinh_hoa",
        "division_type": "huyện",
        "short_codename": "dinh_hoa",
        "wards": 23,
        "parentCode": "tinh_thai_nguyen"
    },
    {
        "name": "Huyện Phú Lương",
        "code": "huyen_phu_luong",
        "division_type": "huyện",
        "short_codename": "phu_luong",
        "wards": 15,
        "parentCode": "tinh_thai_nguyen"
    },
    {
        "name": "Huyện Đồng Hỷ",
        "code": "huyen_dong_hy",
        "division_type": "huyện",
        "short_codename": "dong_hy",
        "wards": 15,
        "parentCode": "tinh_thai_nguyen"
    },
    {
        "name": "Huyện Võ Nhai",
        "code": "huyen_vo_nhai",
        "division_type": "huyện",
        "short_codename": "vo_nhai",
        "wards": 15,
        "parentCode": "tinh_thai_nguyen"
    },
    {
        "name": "Huyện Đại Từ",
        "code": "huyen_dai_tu",
        "division_type": "huyện",
        "short_codename": "dai_tu",
        "wards": 30,
        "parentCode": "tinh_thai_nguyen"
    },
    {
        "name": "Thị xã Phổ Yên",
        "code": "thi_xa_pho_yen",
        "division_type": "thị xã",
        "short_codename": "pho_yen",
        "wards": 18,
        "parentCode": "tinh_thai_nguyen"
    },
    {
        "name": "Huyện Phú Bình",
        "code": "huyen_phu_binh",
        "division_type": "huyện",
        "short_codename": "phu_binh",
        "wards": 20,
        "parentCode": "tinh_thai_nguyen"
    },
    {
        "name": "Thành phố Lạng Sơn",
        "code": "thanh_pho_lang_son",
        "division_type": "thành phố",
        "short_codename": "lang_son",
        "wards": 8,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Tràng Định",
        "code": "huyen_trang_dinh",
        "division_type": "huyện",
        "short_codename": "trang_dinh",
        "wards": 22,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Bình Gia",
        "code": "huyen_binh_gia",
        "division_type": "huyện",
        "short_codename": "binh_gia",
        "wards": 19,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Văn Lãng",
        "code": "huyen_van_lang",
        "division_type": "huyện",
        "short_codename": "van_lang",
        "wards": 17,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Cao Lộc",
        "code": "huyen_cao_loc",
        "division_type": "huyện",
        "short_codename": "cao_loc",
        "wards": 22,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Văn Quan",
        "code": "huyen_van_quan",
        "division_type": "huyện",
        "short_codename": "van_quan",
        "wards": 17,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Bắc Sơn",
        "code": "huyen_bac_son",
        "division_type": "huyện",
        "short_codename": "bac_son",
        "wards": 18,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Hữu Lũng",
        "code": "huyen_huu_lung",
        "division_type": "huyện",
        "short_codename": "huu_lung",
        "wards": 24,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Chi Lăng",
        "code": "huyen_chi_lang",
        "division_type": "huyện",
        "short_codename": "chi_lang",
        "wards": 20,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Lộc Bình",
        "code": "huyen_loc_binh",
        "division_type": "huyện",
        "short_codename": "loc_binh",
        "wards": 21,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Huyện Đình Lập",
        "code": "huyen_dinh_lap",
        "division_type": "huyện",
        "short_codename": "dinh_lap",
        "wards": 12,
        "parentCode": "tinh_lang_son"
    },
    {
        "name": "Thành phố Hạ Long",
        "code": "thanh_pho_ha_long",
        "division_type": "thành phố",
        "short_codename": "ha_long",
        "wards": 33,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Thành phố Móng Cái",
        "code": "thanh_pho_mong_cai",
        "division_type": "thành phố",
        "short_codename": "mong_cai",
        "wards": 17,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Thành phố Cẩm Phả",
        "code": "thanh_pho_cam_pha",
        "division_type": "thành phố",
        "short_codename": "cam_pha",
        "wards": 16,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Thành phố Uông Bí",
        "code": "thanh_pho_uong_bi",
        "division_type": "thành phố",
        "short_codename": "uong_bi",
        "wards": 10,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Huyện Bình Liêu",
        "code": "huyen_binh_lieu",
        "division_type": "huyện",
        "short_codename": "binh_lieu",
        "wards": 7,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Huyện Tiên Yên",
        "code": "huyen_tien_yen",
        "division_type": "huyện",
        "short_codename": "tien_yen",
        "wards": 11,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Huyện Đầm Hà",
        "code": "huyen_dam_ha",
        "division_type": "huyện",
        "short_codename": "dam_ha",
        "wards": 9,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Huyện Hải Hà",
        "code": "huyen_hai_ha",
        "division_type": "huyện",
        "short_codename": "hai_ha",
        "wards": 11,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Huyện Ba Chẽ",
        "code": "huyen_ba_che",
        "division_type": "huyện",
        "short_codename": "ba_che",
        "wards": 8,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Huyện Vân Đồn",
        "code": "huyen_van_don",
        "division_type": "huyện",
        "short_codename": "van_don",
        "wards": 12,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Thị xã Đông Triều",
        "code": "thi_xa_dong_trieu",
        "division_type": "thị xã",
        "short_codename": "dong_trieu",
        "wards": 21,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Thị xã Quảng Yên",
        "code": "thi_xa_quang_yen",
        "division_type": "thị xã",
        "short_codename": "quang_yen",
        "wards": 19,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Huyện Cô Tô",
        "code": "huyen_co_to",
        "division_type": "huyện",
        "short_codename": "co_to",
        "wards": 3,
        "parentCode": "tinh_quang_ninh"
    },
    {
        "name": "Thành phố Bắc Giang",
        "code": "thanh_pho_bac_giang",
        "division_type": "thành phố",
        "short_codename": "bac_giang",
        "wards": 16,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Huyện Yên Thế",
        "code": "huyen_yen_the",
        "division_type": "huyện",
        "short_codename": "yen_the",
        "wards": 19,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Huyện Tân Yên",
        "code": "huyen_tan_yen",
        "division_type": "huyện",
        "short_codename": "tan_yen",
        "wards": 22,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Huyện Lạng Giang",
        "code": "huyen_lang_giang",
        "division_type": "huyện",
        "short_codename": "lang_giang",
        "wards": 21,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Huyện Lục Nam",
        "code": "huyen_luc_nam",
        "division_type": "huyện",
        "short_codename": "luc_nam",
        "wards": 25,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Huyện Lục Ngạn",
        "code": "huyen_luc_ngan",
        "division_type": "huyện",
        "short_codename": "luc_ngan",
        "wards": 29,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Huyện Sơn Động",
        "code": "huyen_son_dong",
        "division_type": "huyện",
        "short_codename": "son_dong",
        "wards": 17,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Huyện Yên Dũng",
        "code": "huyen_yen_dung",
        "division_type": "huyện",
        "short_codename": "yen_dung",
        "wards": 18,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Huyện Việt Yên",
        "code": "huyen_viet_yen",
        "division_type": "huyện",
        "short_codename": "viet_yen",
        "wards": 17,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Huyện Hiệp Hòa",
        "code": "huyen_hiep_hoa",
        "division_type": "huyện",
        "short_codename": "hiep_hoa",
        "wards": 25,
        "parentCode": "tinh_bac_giang"
    },
    {
        "name": "Thành phố Việt Trì",
        "code": "thanh_pho_viet_tri",
        "division_type": "thành phố",
        "short_codename": "viet_tri",
        "wards": 22,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Thị xã Phú Thọ",
        "code": "thi_xa_phu_tho",
        "division_type": "thị xã",
        "short_codename": "phu_tho",
        "wards": 9,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Đoan Hùng",
        "code": "huyen_doan_hung",
        "division_type": "huyện",
        "short_codename": "doan_hung",
        "wards": 22,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Hạ Hoà",
        "code": "huyen_ha_hoa",
        "division_type": "huyện",
        "short_codename": "ha_hoa",
        "wards": 20,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Thanh Ba",
        "code": "huyen_thanh_ba",
        "division_type": "huyện",
        "short_codename": "thanh_ba",
        "wards": 19,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Phù Ninh",
        "code": "huyen_phu_ninh",
        "division_type": "huyện",
        "short_codename": "phu_ninh",
        "wards": 17,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Yên Lập",
        "code": "huyen_yen_lap",
        "division_type": "huyện",
        "short_codename": "yen_lap",
        "wards": 17,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Cẩm Khê",
        "code": "huyen_cam_khe",
        "division_type": "huyện",
        "short_codename": "cam_khe",
        "wards": 24,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Tam Nông",
        "code": "huyen_tam_nong",
        "division_type": "huyện",
        "short_codename": "tam_nong",
        "wards": 12,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Lâm Thao",
        "code": "huyen_lam_thao",
        "division_type": "huyện",
        "short_codename": "lam_thao",
        "wards": 12,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Thanh Sơn",
        "code": "huyen_thanh_son",
        "division_type": "huyện",
        "short_codename": "thanh_son",
        "wards": 23,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Thanh Thuỷ",
        "code": "huyen_thanh_thuy",
        "division_type": "huyện",
        "short_codename": "thanh_thuy",
        "wards": 11,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Huyện Tân Sơn",
        "code": "huyen_tan_son",
        "division_type": "huyện",
        "short_codename": "tan_son",
        "wards": 17,
        "parentCode": "tinh_phu_tho"
    },
    {
        "name": "Thành phố Vĩnh Yên",
        "code": "thanh_pho_vinh_yen",
        "division_type": "thành phố",
        "short_codename": "vinh_yen",
        "wards": 9,
        "parentCode": "tinh_vinh_phuc"
    },
    {
        "name": "Thành phố Phúc Yên",
        "code": "thanh_pho_phuc_yen",
        "division_type": "thành phố",
        "short_codename": "phuc_yen",
        "wards": 10,
        "parentCode": "tinh_vinh_phuc"
    },
    {
        "name": "Huyện Lập Thạch",
        "code": "huyen_lap_thach",
        "division_type": "huyện",
        "short_codename": "lap_thach",
        "wards": 20,
        "parentCode": "tinh_vinh_phuc"
    },
    {
        "name": "Huyện Tam Dương",
        "code": "huyen_tam_duong",
        "division_type": "huyện",
        "short_codename": "tam_duong",
        "wards": 13,
        "parentCode": "tinh_vinh_phuc"
    },
    {
        "name": "Huyện Tam Đảo",
        "code": "huyen_tam_dao",
        "division_type": "huyện",
        "short_codename": "tam_dao",
        "wards": 9,
        "parentCode": "tinh_vinh_phuc"
    },
    {
        "name": "Huyện Bình Xuyên",
        "code": "huyen_binh_xuyen",
        "division_type": "huyện",
        "short_codename": "binh_xuyen",
        "wards": 13,
        "parentCode": "tinh_vinh_phuc"
    },
    {
        "name": "Huyện Yên Lạc",
        "code": "huyen_yen_lac",
        "division_type": "huyện",
        "short_codename": "yen_lac",
        "wards": 17,
        "parentCode": "tinh_vinh_phuc"
    },
    {
        "name": "Huyện Vĩnh Tường",
        "code": "huyen_vinh_tuong",
        "division_type": "huyện",
        "short_codename": "vinh_tuong",
        "wards": 28,
        "parentCode": "tinh_vinh_phuc"
    },
    {
        "name": "Huyện Sông Lô",
        "code": "huyen_song_lo",
        "division_type": "huyện",
        "short_codename": "song_lo",
        "wards": 17,
        "parentCode": "tinh_vinh_phuc"
    },
    {
        "name": "Thành phố Bắc Ninh",
        "code": "thanh_pho_bac_ninh",
        "division_type": "thành phố",
        "short_codename": "bac_ninh",
        "wards": 19,
        "parentCode": "tinh_bac_ninh"
    },
    {
        "name": "Huyện Yên Phong",
        "code": "huyen_yen_phong",
        "division_type": "huyện",
        "short_codename": "yen_phong",
        "wards": 14,
        "parentCode": "tinh_bac_ninh"
    },
    {
        "name": "Huyện Quế Võ",
        "code": "huyen_que_vo",
        "division_type": "huyện",
        "short_codename": "que_vo",
        "wards": 21,
        "parentCode": "tinh_bac_ninh"
    },
    {
        "name": "Huyện Tiên Du",
        "code": "huyen_tien_du",
        "division_type": "huyện",
        "short_codename": "tien_du",
        "wards": 14,
        "parentCode": "tinh_bac_ninh"
    },
    {
        "name": "Thị xã Từ Sơn",
        "code": "thi_xa_tu_son",
        "division_type": "thị xã",
        "short_codename": "tu_son",
        "wards": 12,
        "parentCode": "tinh_bac_ninh"
    },
    {
        "name": "Huyện Thuận Thành",
        "code": "huyen_thuan_thanh",
        "division_type": "huyện",
        "short_codename": "thuan_thanh",
        "wards": 18,
        "parentCode": "tinh_bac_ninh"
    },
    {
        "name": "Huyện Gia Bình",
        "code": "huyen_gia_binh",
        "division_type": "huyện",
        "short_codename": "gia_binh",
        "wards": 14,
        "parentCode": "tinh_bac_ninh"
    },
    {
        "name": "Huyện Lương Tài",
        "code": "huyen_luong_tai",
        "division_type": "huyện",
        "short_codename": "luong_tai",
        "wards": 14,
        "parentCode": "tinh_bac_ninh"
    },
    {
        "name": "Thành phố Hải Dương",
        "code": "thanh_pho_hai_duong",
        "division_type": "thành phố",
        "short_codename": "hai_duong",
        "wards": 25,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Thành phố Chí Linh",
        "code": "thanh_pho_chi_linh",
        "division_type": "thành phố",
        "short_codename": "chi_linh",
        "wards": 19,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Huyện Nam Sách",
        "code": "huyen_nam_sach",
        "division_type": "huyện",
        "short_codename": "nam_sach",
        "wards": 19,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Thị xã Kinh Môn",
        "code": "thi_xa_kinh_mon",
        "division_type": "thị xã",
        "short_codename": "kinh_mon",
        "wards": 23,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Huyện Kim Thành",
        "code": "huyen_kim_thanh",
        "division_type": "huyện",
        "short_codename": "kim_thanh",
        "wards": 18,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Huyện Thanh Hà",
        "code": "huyen_thanh_ha",
        "division_type": "huyện",
        "short_codename": "thanh_ha",
        "wards": 20,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Huyện Cẩm Giàng",
        "code": "huyen_cam_giang",
        "division_type": "huyện",
        "short_codename": "cam_giang",
        "wards": 17,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Huyện Bình Giang",
        "code": "huyen_binh_giang",
        "division_type": "huyện",
        "short_codename": "binh_giang",
        "wards": 16,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Huyện Gia Lộc",
        "code": "huyen_gia_loc",
        "division_type": "huyện",
        "short_codename": "gia_loc",
        "wards": 18,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Huyện Tứ Kỳ",
        "code": "huyen_tu_ky",
        "division_type": "huyện",
        "short_codename": "tu_ky",
        "wards": 23,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Huyện Ninh Giang",
        "code": "huyen_ninh_giang",
        "division_type": "huyện",
        "short_codename": "ninh_giang",
        "wards": 20,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Huyện Thanh Miện",
        "code": "huyen_thanh_mien",
        "division_type": "huyện",
        "short_codename": "thanh_mien",
        "wards": 17,
        "parentCode": "tinh_hai_duong"
    },
    {
        "name": "Quận Hồng Bàng",
        "code": "quan_hong_bang",
        "division_type": "quận",
        "short_codename": "hong_bang",
        "wards": 9,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Quận Ngô Quyền",
        "code": "quan_ngo_quyen",
        "division_type": "quận",
        "short_codename": "ngo_quyen",
        "wards": 12,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Quận Lê Chân",
        "code": "quan_le_chan",
        "division_type": "quận",
        "short_codename": "le_chan",
        "wards": 15,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Quận Hải An",
        "code": "quan_hai_an",
        "division_type": "quận",
        "short_codename": "hai_an",
        "wards": 8,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Quận Kiến An",
        "code": "quan_kien_an",
        "division_type": "quận",
        "short_codename": "kien_an",
        "wards": 10,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Quận Đồ Sơn",
        "code": "quan_do_son",
        "division_type": "quận",
        "short_codename": "do_son",
        "wards": 6,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Quận Dương Kinh",
        "code": "quan_duong_kinh",
        "division_type": "quận",
        "short_codename": "duong_kinh",
        "wards": 6,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Huyện Thuỷ Nguyên",
        "code": "huyen_thuy_nguyen",
        "division_type": "huyện",
        "short_codename": "thuy_nguyen",
        "wards": 37,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Huyện An Dương",
        "code": "huyen_an_duong",
        "division_type": "huyện",
        "short_codename": "an_duong",
        "wards": 16,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Huyện An Lão",
        "code": "huyen_an_lao",
        "division_type": "huyện",
        "short_codename": "an_lao",
        "wards": 17,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Huyện Kiến Thuỵ",
        "code": "huyen_kien_thuy",
        "division_type": "huyện",
        "short_codename": "kien_thuy",
        "wards": 18,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Huyện Tiên Lãng",
        "code": "huyen_tien_lang",
        "division_type": "huyện",
        "short_codename": "tien_lang",
        "wards": 21,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Huyện Vĩnh Bảo",
        "code": "huyen_vinh_bao",
        "division_type": "huyện",
        "short_codename": "vinh_bao",
        "wards": 30,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Huyện Cát Hải",
        "code": "huyen_cat_hai",
        "division_type": "huyện",
        "short_codename": "cat_hai",
        "wards": 12,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Huyện Bạch Long Vĩ",
        "code": "huyen_bach_long_vi",
        "division_type": "huyện",
        "short_codename": "bach_long_vi",
        "wards": 0,
        "parentCode": "thanh_pho_hai_phong"
    },
    {
        "name": "Thành phố Hưng Yên",
        "code": "thanh_pho_hung_yen",
        "division_type": "thành phố",
        "short_codename": "hung_yen",
        "wards": 17,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Huyện Văn Lâm",
        "code": "huyen_van_lam",
        "division_type": "huyện",
        "short_codename": "van_lam",
        "wards": 11,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Huyện Văn Giang",
        "code": "huyen_van_giang",
        "division_type": "huyện",
        "short_codename": "van_giang",
        "wards": 11,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Huyện Yên Mỹ",
        "code": "huyen_yen_my",
        "division_type": "huyện",
        "short_codename": "yen_my",
        "wards": 17,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Thị xã Mỹ Hào",
        "code": "thi_xa_my_hao",
        "division_type": "thị xã",
        "short_codename": "my_hao",
        "wards": 13,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Huyện Ân Thi",
        "code": "huyen_an_thi",
        "division_type": "huyện",
        "short_codename": "an_thi",
        "wards": 21,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Huyện Khoái Châu",
        "code": "huyen_khoai_chau",
        "division_type": "huyện",
        "short_codename": "khoai_chau",
        "wards": 25,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Huyện Kim Động",
        "code": "huyen_kim_dong",
        "division_type": "huyện",
        "short_codename": "kim_dong",
        "wards": 17,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Huyện Tiên Lữ",
        "code": "huyen_tien_lu",
        "division_type": "huyện",
        "short_codename": "tien_lu",
        "wards": 15,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Huyện Phù Cừ",
        "code": "huyen_phu_cu",
        "division_type": "huyện",
        "short_codename": "phu_cu",
        "wards": 14,
        "parentCode": "tinh_hung_yen"
    },
    {
        "name": "Thành phố Thái Bình",
        "code": "thanh_pho_thai_binh",
        "division_type": "thành phố",
        "short_codename": "thai_binh",
        "wards": 19,
        "parentCode": "tinh_thai_binh"
    },
    {
        "name": "Huyện Quỳnh Phụ",
        "code": "huyen_quynh_phu",
        "division_type": "huyện",
        "short_codename": "quynh_phu",
        "wards": 37,
        "parentCode": "tinh_thai_binh"
    },
    {
        "name": "Huyện Hưng Hà",
        "code": "huyen_hung_ha",
        "division_type": "huyện",
        "short_codename": "hung_ha",
        "wards": 35,
        "parentCode": "tinh_thai_binh"
    },
    {
        "name": "Huyện Đông Hưng",
        "code": "huyen_dong_hung",
        "division_type": "huyện",
        "short_codename": "dong_hung",
        "wards": 38,
        "parentCode": "tinh_thai_binh"
    },
    {
        "name": "Huyện Thái Thụy",
        "code": "huyen_thai_thuy",
        "division_type": "huyện",
        "short_codename": "thai_thuy",
        "wards": 36,
        "parentCode": "tinh_thai_binh"
    },
    {
        "name": "Huyện Tiền Hải",
        "code": "huyen_tien_hai",
        "division_type": "huyện",
        "short_codename": "tien_hai",
        "wards": 32,
        "parentCode": "tinh_thai_binh"
    },
    {
        "name": "Huyện Kiến Xương",
        "code": "huyen_kien_xuong",
        "division_type": "huyện",
        "short_codename": "kien_xuong",
        "wards": 33,
        "parentCode": "tinh_thai_binh"
    },
    {
        "name": "Huyện Vũ Thư",
        "code": "huyen_vu_thu",
        "division_type": "huyện",
        "short_codename": "vu_thu",
        "wards": 30,
        "parentCode": "tinh_thai_binh"
    },
    {
        "name": "Thành phố Phủ Lý",
        "code": "thanh_pho_phu_ly",
        "division_type": "thành phố",
        "short_codename": "phu_ly",
        "wards": 21,
        "parentCode": "tinh_ha_nam"
    },
    {
        "name": "Thị xã Duy Tiên",
        "code": "thi_xa_duy_tien",
        "division_type": "thị xã",
        "short_codename": "duy_tien",
        "wards": 16,
        "parentCode": "tinh_ha_nam"
    },
    {
        "name": "Huyện Kim Bảng",
        "code": "huyen_kim_bang",
        "division_type": "huyện",
        "short_codename": "kim_bang",
        "wards": 18,
        "parentCode": "tinh_ha_nam"
    },
    {
        "name": "Huyện Thanh Liêm",
        "code": "huyen_thanh_liem",
        "division_type": "huyện",
        "short_codename": "thanh_liem",
        "wards": 16,
        "parentCode": "tinh_ha_nam"
    },
    {
        "name": "Huyện Bình Lục",
        "code": "huyen_binh_luc",
        "division_type": "huyện",
        "short_codename": "binh_luc",
        "wards": 17,
        "parentCode": "tinh_ha_nam"
    },
    {
        "name": "Huyện Lý Nhân",
        "code": "huyen_ly_nhan",
        "division_type": "huyện",
        "short_codename": "ly_nhan",
        "wards": 21,
        "parentCode": "tinh_ha_nam"
    },
    {
        "name": "Thành phố Nam Định",
        "code": "thanh_pho_nam_dinh",
        "division_type": "thành phố",
        "short_codename": "nam_dinh",
        "wards": 25,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Huyện Mỹ Lộc",
        "code": "huyen_my_loc",
        "division_type": "huyện",
        "short_codename": "my_loc",
        "wards": 11,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Huyện Vụ Bản",
        "code": "huyen_vu_ban",
        "division_type": "huyện",
        "short_codename": "vu_ban",
        "wards": 18,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Huyện Ý Yên",
        "code": "huyen_y_yen",
        "division_type": "huyện",
        "short_codename": "y_yen",
        "wards": 31,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Huyện Nghĩa Hưng",
        "code": "huyen_nghia_hung",
        "division_type": "huyện",
        "short_codename": "nghia_hung",
        "wards": 24,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Huyện Nam Trực",
        "code": "huyen_nam_truc",
        "division_type": "huyện",
        "short_codename": "nam_truc",
        "wards": 20,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Huyện Trực Ninh",
        "code": "huyen_truc_ninh",
        "division_type": "huyện",
        "short_codename": "truc_ninh",
        "wards": 21,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Huyện Xuân Trường",
        "code": "huyen_xuan_truong",
        "division_type": "huyện",
        "short_codename": "xuan_truong",
        "wards": 20,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Huyện Giao Thủy",
        "code": "huyen_giao_thuy",
        "division_type": "huyện",
        "short_codename": "giao_thuy",
        "wards": 22,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Huyện Hải Hậu",
        "code": "huyen_hai_hau",
        "division_type": "huyện",
        "short_codename": "hai_hau",
        "wards": 34,
        "parentCode": "tinh_nam_dinh"
    },
    {
        "name": "Thành phố Ninh Bình",
        "code": "thanh_pho_ninh_binh",
        "division_type": "thành phố",
        "short_codename": "ninh_binh",
        "wards": 14,
        "parentCode": "tinh_ninh_binh"
    },
    {
        "name": "Thành phố Tam Điệp",
        "code": "thanh_pho_tam_diep",
        "division_type": "thành phố",
        "short_codename": "tam_diep",
        "wards": 9,
        "parentCode": "tinh_ninh_binh"
    },
    {
        "name": "Huyện Nho Quan",
        "code": "huyen_nho_quan",
        "division_type": "huyện",
        "short_codename": "nho_quan",
        "wards": 27,
        "parentCode": "tinh_ninh_binh"
    },
    {
        "name": "Huyện Gia Viễn",
        "code": "huyen_gia_vien",
        "division_type": "huyện",
        "short_codename": "gia_vien",
        "wards": 21,
        "parentCode": "tinh_ninh_binh"
    },
    {
        "name": "Huyện Hoa Lư",
        "code": "huyen_hoa_lu",
        "division_type": "huyện",
        "short_codename": "hoa_lu",
        "wards": 11,
        "parentCode": "tinh_ninh_binh"
    },
    {
        "name": "Huyện Yên Khánh",
        "code": "huyen_yen_khanh",
        "division_type": "huyện",
        "short_codename": "yen_khanh",
        "wards": 19,
        "parentCode": "tinh_ninh_binh"
    },
    {
        "name": "Huyện Kim Sơn",
        "code": "huyen_kim_son",
        "division_type": "huyện",
        "short_codename": "kim_son",
        "wards": 25,
        "parentCode": "tinh_ninh_binh"
    },
    {
        "name": "Huyện Yên Mô",
        "code": "huyen_yen_mo",
        "division_type": "huyện",
        "short_codename": "yen_mo",
        "wards": 17,
        "parentCode": "tinh_ninh_binh"
    },
    {
        "name": "Thành phố Thanh Hóa",
        "code": "thanh_pho_thanh_hoa",
        "division_type": "thành phố",
        "short_codename": "thanh_hoa",
        "wards": 34,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Thị xã Bỉm Sơn",
        "code": "thi_xa_bim_son",
        "division_type": "thị xã",
        "short_codename": "bim_son",
        "wards": 7,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Thành phố Sầm Sơn",
        "code": "thanh_pho_sam_son",
        "division_type": "thành phố",
        "short_codename": "sam_son",
        "wards": 11,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Mường Lát",
        "code": "huyen_muong_lat",
        "division_type": "huyện",
        "short_codename": "muong_lat",
        "wards": 8,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Quan Hóa",
        "code": "huyen_quan_hoa",
        "division_type": "huyện",
        "short_codename": "quan_hoa",
        "wards": 15,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Bá Thước",
        "code": "huyen_ba_thuoc",
        "division_type": "huyện",
        "short_codename": "ba_thuoc",
        "wards": 21,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Quan Sơn",
        "code": "huyen_quan_son",
        "division_type": "huyện",
        "short_codename": "quan_son",
        "wards": 12,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Lang Chánh",
        "code": "huyen_lang_chanh",
        "division_type": "huyện",
        "short_codename": "lang_chanh",
        "wards": 10,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Ngọc Lặc",
        "code": "huyen_ngoc_lac",
        "division_type": "huyện",
        "short_codename": "ngoc_lac",
        "wards": 21,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Cẩm Thủy",
        "code": "huyen_cam_thuy",
        "division_type": "huyện",
        "short_codename": "cam_thuy",
        "wards": 17,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Thạch Thành",
        "code": "huyen_thach_thanh",
        "division_type": "huyện",
        "short_codename": "thach_thanh",
        "wards": 25,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Hà Trung",
        "code": "huyen_ha_trung",
        "division_type": "huyện",
        "short_codename": "ha_trung",
        "wards": 20,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Vĩnh Lộc",
        "code": "huyen_vinh_loc",
        "division_type": "huyện",
        "short_codename": "vinh_loc",
        "wards": 13,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Yên Định",
        "code": "huyen_yen_dinh",
        "division_type": "huyện",
        "short_codename": "yen_dinh",
        "wards": 26,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Thọ Xuân",
        "code": "huyen_tho_xuan",
        "division_type": "huyện",
        "short_codename": "tho_xuan",
        "wards": 30,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Thường Xuân",
        "code": "huyen_thuong_xuan",
        "division_type": "huyện",
        "short_codename": "thuong_xuan",
        "wards": 16,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Triệu Sơn",
        "code": "huyen_trieu_son",
        "division_type": "huyện",
        "short_codename": "trieu_son",
        "wards": 34,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Thiệu Hóa",
        "code": "huyen_thieu_hoa",
        "division_type": "huyện",
        "short_codename": "thieu_hoa",
        "wards": 25,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Hoằng Hóa",
        "code": "huyen_hoang_hoa",
        "division_type": "huyện",
        "short_codename": "hoang_hoa",
        "wards": 37,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Hậu Lộc",
        "code": "huyen_hau_loc",
        "division_type": "huyện",
        "short_codename": "hau_loc",
        "wards": 23,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Nga Sơn",
        "code": "huyen_nga_son",
        "division_type": "huyện",
        "short_codename": "nga_son",
        "wards": 24,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Như Xuân",
        "code": "huyen_nhu_xuan",
        "division_type": "huyện",
        "short_codename": "nhu_xuan",
        "wards": 16,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Như Thanh",
        "code": "huyen_nhu_thanh",
        "division_type": "huyện",
        "short_codename": "nhu_thanh",
        "wards": 14,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Nông Cống",
        "code": "huyen_nong_cong",
        "division_type": "huyện",
        "short_codename": "nong_cong",
        "wards": 29,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Đông Sơn",
        "code": "huyen_dong_son",
        "division_type": "huyện",
        "short_codename": "dong_son",
        "wards": 14,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Huyện Quảng Xương",
        "code": "huyen_quang_xuong",
        "division_type": "huyện",
        "short_codename": "quang_xuong",
        "wards": 26,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Thị xã Nghi Sơn",
        "code": "thi_xa_nghi_son",
        "division_type": "thị xã",
        "short_codename": "nghi_son",
        "wards": 31,
        "parentCode": "tinh_thanh_hoa"
    },
    {
        "name": "Thành phố Vinh",
        "code": "thanh_pho_vinh",
        "division_type": "thành phố",
        "short_codename": "vinh",
        "wards": 25,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Thị xã Cửa Lò",
        "code": "thi_xa_cua_lo",
        "division_type": "thị xã",
        "short_codename": "cua_lo",
        "wards": 7,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Thị xã Thái Hoà",
        "code": "thi_xa_thai_hoa",
        "division_type": "thị xã",
        "short_codename": "thai_hoa",
        "wards": 9,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Quế Phong",
        "code": "huyen_que_phong",
        "division_type": "huyện",
        "short_codename": "que_phong",
        "wards": 13,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Quỳ Châu",
        "code": "huyen_quy_chau",
        "division_type": "huyện",
        "short_codename": "quy_chau",
        "wards": 12,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Kỳ Sơn",
        "code": "huyen_ky_son",
        "division_type": "huyện",
        "short_codename": "ky_son",
        "wards": 21,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Tương Dương",
        "code": "huyen_tuong_duong",
        "division_type": "huyện",
        "short_codename": "tuong_duong",
        "wards": 17,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Nghĩa Đàn",
        "code": "huyen_nghia_dan",
        "division_type": "huyện",
        "short_codename": "nghia_dan",
        "wards": 23,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Quỳ Hợp",
        "code": "huyen_quy_hop",
        "division_type": "huyện",
        "short_codename": "quy_hop",
        "wards": 21,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Quỳnh Lưu",
        "code": "huyen_quynh_luu",
        "division_type": "huyện",
        "short_codename": "quynh_luu",
        "wards": 33,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Con Cuông",
        "code": "huyen_con_cuong",
        "division_type": "huyện",
        "short_codename": "con_cuong",
        "wards": 13,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Tân Kỳ",
        "code": "huyen_tan_ky",
        "division_type": "huyện",
        "short_codename": "tan_ky",
        "wards": 22,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Anh Sơn",
        "code": "huyen_anh_son",
        "division_type": "huyện",
        "short_codename": "anh_son",
        "wards": 21,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Diễn Châu",
        "code": "huyen_dien_chau",
        "division_type": "huyện",
        "short_codename": "dien_chau",
        "wards": 37,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Yên Thành",
        "code": "huyen_yen_thanh",
        "division_type": "huyện",
        "short_codename": "yen_thanh",
        "wards": 39,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Đô Lương",
        "code": "huyen_do_luong",
        "division_type": "huyện",
        "short_codename": "do_luong",
        "wards": 33,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Thanh Chương",
        "code": "huyen_thanh_chuong",
        "division_type": "huyện",
        "short_codename": "thanh_chuong",
        "wards": 38,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Nghi Lộc",
        "code": "huyen_nghi_loc",
        "division_type": "huyện",
        "short_codename": "nghi_loc",
        "wards": 29,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Nam Đàn",
        "code": "huyen_nam_dan",
        "division_type": "huyện",
        "short_codename": "nam_dan",
        "wards": 19,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Huyện Hưng Nguyên",
        "code": "huyen_hung_nguyen",
        "division_type": "huyện",
        "short_codename": "hung_nguyen",
        "wards": 18,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Thị xã Hoàng Mai",
        "code": "thi_xa_hoang_mai",
        "division_type": "thị xã",
        "short_codename": "hoang_mai",
        "wards": 10,
        "parentCode": "tinh_nghe_an"
    },
    {
        "name": "Thành phố Hà Tĩnh",
        "code": "thanh_pho_ha_tinh",
        "division_type": "thành phố",
        "short_codename": "ha_tinh",
        "wards": 15,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Thị xã Hồng Lĩnh",
        "code": "thi_xa_hong_linh",
        "division_type": "thị xã",
        "short_codename": "hong_linh",
        "wards": 6,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Hương Sơn",
        "code": "huyen_huong_son",
        "division_type": "huyện",
        "short_codename": "huong_son",
        "wards": 25,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Đức Thọ",
        "code": "huyen_duc_tho",
        "division_type": "huyện",
        "short_codename": "duc_tho",
        "wards": 16,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Vũ Quang",
        "code": "huyen_vu_quang",
        "division_type": "huyện",
        "short_codename": "vu_quang",
        "wards": 10,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Nghi Xuân",
        "code": "huyen_nghi_xuan",
        "division_type": "huyện",
        "short_codename": "nghi_xuan",
        "wards": 17,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Can Lộc",
        "code": "huyen_can_loc",
        "division_type": "huyện",
        "short_codename": "can_loc",
        "wards": 18,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Hương Khê",
        "code": "huyen_huong_khe",
        "division_type": "huyện",
        "short_codename": "huong_khe",
        "wards": 21,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Thạch Hà",
        "code": "huyen_thach_ha",
        "division_type": "huyện",
        "short_codename": "thach_ha",
        "wards": 22,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Cẩm Xuyên",
        "code": "huyen_cam_xuyen",
        "division_type": "huyện",
        "short_codename": "cam_xuyen",
        "wards": 23,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Kỳ Anh",
        "code": "huyen_ky_anh",
        "division_type": "huyện",
        "short_codename": "huyen_ky_anh",
        "wards": 20,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Huyện Lộc Hà",
        "code": "huyen_loc_ha",
        "division_type": "huyện",
        "short_codename": "loc_ha",
        "wards": 12,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Thị xã Kỳ Anh",
        "code": "thi_xa_ky_anh",
        "division_type": "thị xã",
        "short_codename": "ky_anh",
        "wards": 11,
        "parentCode": "tinh_ha_tinh"
    },
    {
        "name": "Thành Phố Đồng Hới",
        "code": "thanh_pho_dong_hoi",
        "division_type": "thành phố",
        "short_codename": "dong_hoi",
        "wards": 15,
        "parentCode": "tinh_quang_binh"
    },
    {
        "name": "Huyện Minh Hóa",
        "code": "huyen_minh_hoa",
        "division_type": "huyện",
        "short_codename": "minh_hoa",
        "wards": 15,
        "parentCode": "tinh_quang_binh"
    },
    {
        "name": "Huyện Tuyên Hóa",
        "code": "huyen_tuyen_hoa",
        "division_type": "huyện",
        "short_codename": "tuyen_hoa",
        "wards": 19,
        "parentCode": "tinh_quang_binh"
    },
    {
        "name": "Huyện Quảng Trạch",
        "code": "huyen_quang_trach",
        "division_type": "huyện",
        "short_codename": "quang_trach",
        "wards": 17,
        "parentCode": "tinh_quang_binh"
    },
    {
        "name": "Huyện Bố Trạch",
        "code": "huyen_bo_trach",
        "division_type": "huyện",
        "short_codename": "bo_trach",
        "wards": 28,
        "parentCode": "tinh_quang_binh"
    },
    {
        "name": "Huyện Quảng Ninh",
        "code": "huyen_quang_ninh",
        "division_type": "huyện",
        "short_codename": "quang_ninh",
        "wards": 15,
        "parentCode": "tinh_quang_binh"
    },
    {
        "name": "Huyện Lệ Thủy",
        "code": "huyen_le_thuy",
        "division_type": "huyện",
        "short_codename": "le_thuy",
        "wards": 26,
        "parentCode": "tinh_quang_binh"
    },
    {
        "name": "Thị xã Ba Đồn",
        "code": "thi_xa_ba_don",
        "division_type": "thị xã",
        "short_codename": "ba_don",
        "wards": 16,
        "parentCode": "tinh_quang_binh"
    },
    {
        "name": "Thành phố Đông Hà",
        "code": "thanh_pho_dong_ha",
        "division_type": "thành phố",
        "short_codename": "dong_ha",
        "wards": 9,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Thị xã Quảng Trị",
        "code": "thi_xa_quang_tri",
        "division_type": "thị xã",
        "short_codename": "quang_tri",
        "wards": 5,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Huyện Vĩnh Linh",
        "code": "huyen_vinh_linh",
        "division_type": "huyện",
        "short_codename": "vinh_linh",
        "wards": 18,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Huyện Hướng Hóa",
        "code": "huyen_huong_hoa",
        "division_type": "huyện",
        "short_codename": "huong_hoa",
        "wards": 21,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Huyện Gio Linh",
        "code": "huyen_gio_linh",
        "division_type": "huyện",
        "short_codename": "gio_linh",
        "wards": 17,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Huyện Đa Krông",
        "code": "huyen_da_krong",
        "division_type": "huyện",
        "short_codename": "da_krong",
        "wards": 13,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Huyện Cam Lộ",
        "code": "huyen_cam_lo",
        "division_type": "huyện",
        "short_codename": "cam_lo",
        "wards": 8,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Huyện Triệu Phong",
        "code": "huyen_trieu_phong",
        "division_type": "huyện",
        "short_codename": "trieu_phong",
        "wards": 18,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Huyện Hải Lăng",
        "code": "huyen_hai_lang",
        "division_type": "huyện",
        "short_codename": "hai_lang",
        "wards": 16,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Huyện Cồn Cỏ",
        "code": "huyen_con_co",
        "division_type": "huyện",
        "short_codename": "con_co",
        "wards": 0,
        "parentCode": "tinh_quang_tri"
    },
    {
        "name": "Thành phố Huế",
        "code": "thanh_pho_hue",
        "division_type": "thành phố",
        "short_codename": "hue",
        "wards": 27,
        "parentCode": "tinh_thua_thien_hue"
    },
    {
        "name": "Huyện Phong Điền",
        "code": "huyen_phong_dien",
        "division_type": "huyện",
        "short_codename": "phong_dien",
        "wards": 16,
        "parentCode": "tinh_thua_thien_hue"
    },
    {
        "name": "Huyện Quảng Điền",
        "code": "huyen_quang_dien",
        "division_type": "huyện",
        "short_codename": "quang_dien",
        "wards": 11,
        "parentCode": "tinh_thua_thien_hue"
    },
    {
        "name": "Huyện Phú Vang",
        "code": "huyen_phu_vang",
        "division_type": "huyện",
        "short_codename": "phu_vang",
        "wards": 19,
        "parentCode": "tinh_thua_thien_hue"
    },
    {
        "name": "Thị xã Hương Thủy",
        "code": "thi_xa_huong_thuy",
        "division_type": "thị xã",
        "short_codename": "huong_thuy",
        "wards": 12,
        "parentCode": "tinh_thua_thien_hue"
    },
    {
        "name": "Thị xã Hương Trà",
        "code": "thi_xa_huong_tra",
        "division_type": "thị xã",
        "short_codename": "huong_tra",
        "wards": 15,
        "parentCode": "tinh_thua_thien_hue"
    },
    {
        "name": "Huyện A Lưới",
        "code": "huyen_a_luoi",
        "division_type": "huyện",
        "short_codename": "a_luoi",
        "wards": 18,
        "parentCode": "tinh_thua_thien_hue"
    },
    {
        "name": "Huyện Phú Lộc",
        "code": "huyen_phu_loc",
        "division_type": "huyện",
        "short_codename": "phu_loc",
        "wards": 17,
        "parentCode": "tinh_thua_thien_hue"
    },
    {
        "name": "Huyện Nam Đông",
        "code": "huyen_nam_dong",
        "division_type": "huyện",
        "short_codename": "nam_dong",
        "wards": 10,
        "parentCode": "tinh_thua_thien_hue"
    },
    {
        "name": "Quận Liên Chiểu",
        "code": "quan_lien_chieu",
        "division_type": "quận",
        "short_codename": "lien_chieu",
        "wards": 5,
        "parentCode": "thanh_pho_da_nang"
    },
    {
        "name": "Quận Thanh Khê",
        "code": "quan_thanh_khe",
        "division_type": "quận",
        "short_codename": "thanh_khe",
        "wards": 10,
        "parentCode": "thanh_pho_da_nang"
    },
    {
        "name": "Quận Hải Châu",
        "code": "quan_hai_chau",
        "division_type": "quận",
        "short_codename": "hai_chau",
        "wards": 13,
        "parentCode": "thanh_pho_da_nang"
    },
    {
        "name": "Quận Sơn Trà",
        "code": "quan_son_tra",
        "division_type": "quận",
        "short_codename": "son_tra",
        "wards": 7,
        "parentCode": "thanh_pho_da_nang"
    },
    {
        "name": "Quận Ngũ Hành Sơn",
        "code": "quan_ngu_hanh_son",
        "division_type": "quận",
        "short_codename": "ngu_hanh_son",
        "wards": 4,
        "parentCode": "thanh_pho_da_nang"
    },
    {
        "name": "Quận Cẩm Lệ",
        "code": "quan_cam_le",
        "division_type": "quận",
        "short_codename": "cam_le",
        "wards": 6,
        "parentCode": "thanh_pho_da_nang"
    },
    {
        "name": "Huyện Hòa Vang",
        "code": "huyen_hoa_vang",
        "division_type": "huyện",
        "short_codename": "hoa_vang",
        "wards": 11,
        "parentCode": "thanh_pho_da_nang"
    },
    {
        "name": "Huyện Hoàng Sa",
        "code": "huyen_hoang_sa",
        "division_type": "huyện",
        "short_codename": "hoang_sa",
        "wards": 0,
        "parentCode": "thanh_pho_da_nang"
    },
    {
        "name": "Thành phố Tam Kỳ",
        "code": "thanh_pho_tam_ky",
        "division_type": "thành phố",
        "short_codename": "tam_ky",
        "wards": 13,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Thành phố Hội An",
        "code": "thanh_pho_hoi_an",
        "division_type": "thành phố",
        "short_codename": "hoi_an",
        "wards": 13,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Tây Giang",
        "code": "huyen_tay_giang",
        "division_type": "huyện",
        "short_codename": "tay_giang",
        "wards": 10,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Đông Giang",
        "code": "huyen_dong_giang",
        "division_type": "huyện",
        "short_codename": "dong_giang",
        "wards": 11,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Đại Lộc",
        "code": "huyen_dai_loc",
        "division_type": "huyện",
        "short_codename": "dai_loc",
        "wards": 18,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Thị xã Điện Bàn",
        "code": "thi_xa_dien_ban",
        "division_type": "thị xã",
        "short_codename": "dien_ban",
        "wards": 20,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Duy Xuyên",
        "code": "huyen_duy_xuyen",
        "division_type": "huyện",
        "short_codename": "duy_xuyen",
        "wards": 14,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Quế Sơn",
        "code": "huyen_que_son",
        "division_type": "huyện",
        "short_codename": "que_son",
        "wards": 13,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Nam Giang",
        "code": "huyen_nam_giang",
        "division_type": "huyện",
        "short_codename": "nam_giang",
        "wards": 12,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Phước Sơn",
        "code": "huyen_phuoc_son",
        "division_type": "huyện",
        "short_codename": "phuoc_son",
        "wards": 12,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Hiệp Đức",
        "code": "huyen_hiep_duc",
        "division_type": "huyện",
        "short_codename": "hiep_duc",
        "wards": 11,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Thăng Bình",
        "code": "huyen_thang_binh",
        "division_type": "huyện",
        "short_codename": "thang_binh",
        "wards": 22,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Tiên Phước",
        "code": "huyen_tien_phuoc",
        "division_type": "huyện",
        "short_codename": "tien_phuoc",
        "wards": 15,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Bắc Trà My",
        "code": "huyen_bac_tra_my",
        "division_type": "huyện",
        "short_codename": "bac_tra_my",
        "wards": 13,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Nam Trà My",
        "code": "huyen_nam_tra_my",
        "division_type": "huyện",
        "short_codename": "nam_tra_my",
        "wards": 10,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Núi Thành",
        "code": "huyen_nui_thanh",
        "division_type": "huyện",
        "short_codename": "nui_thanh",
        "wards": 17,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Phú Ninh",
        "code": "huyen_phu_ninh",
        "division_type": "huyện",
        "short_codename": "phu_ninh",
        "wards": 11,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Huyện Nông Sơn",
        "code": "huyen_nong_son",
        "division_type": "huyện",
        "short_codename": "nong_son",
        "wards": 6,
        "parentCode": "tinh_quang_nam"
    },
    {
        "name": "Thành phố Quảng Ngãi",
        "code": "thanh_pho_quang_ngai",
        "division_type": "thành phố",
        "short_codename": "quang_ngai",
        "wards": 23,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Bình Sơn",
        "code": "huyen_binh_son",
        "division_type": "huyện",
        "short_codename": "binh_son",
        "wards": 22,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Trà Bồng",
        "code": "huyen_tra_bong",
        "division_type": "huyện",
        "short_codename": "tra_bong",
        "wards": 16,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Sơn Tịnh",
        "code": "huyen_son_tinh",
        "division_type": "huyện",
        "short_codename": "son_tinh",
        "wards": 11,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Tư Nghĩa",
        "code": "huyen_tu_nghia",
        "division_type": "huyện",
        "short_codename": "tu_nghia",
        "wards": 14,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Sơn Hà",
        "code": "huyen_son_ha",
        "division_type": "huyện",
        "short_codename": "son_ha",
        "wards": 14,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Sơn Tây",
        "code": "huyen_son_tay",
        "division_type": "huyện",
        "short_codename": "son_tay",
        "wards": 9,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Minh Long",
        "code": "huyen_minh_long",
        "division_type": "huyện",
        "short_codename": "minh_long",
        "wards": 5,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Nghĩa Hành",
        "code": "huyen_nghia_hanh",
        "division_type": "huyện",
        "short_codename": "nghia_hanh",
        "wards": 12,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Mộ Đức",
        "code": "huyen_mo_duc",
        "division_type": "huyện",
        "short_codename": "mo_duc",
        "wards": 13,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Thị xã Đức Phổ",
        "code": "thi_xa_duc_pho",
        "division_type": "thị xã",
        "short_codename": "duc_pho",
        "wards": 15,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Ba Tơ",
        "code": "huyen_ba_to",
        "division_type": "huyện",
        "short_codename": "ba_to",
        "wards": 19,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Huyện Lý Sơn",
        "code": "huyen_ly_son",
        "division_type": "huyện",
        "short_codename": "ly_son",
        "wards": 0,
        "parentCode": "tinh_quang_ngai"
    },
    {
        "name": "Thành phố Quy Nhơn",
        "code": "thanh_pho_quy_nhon",
        "division_type": "thành phố",
        "short_codename": "quy_nhon",
        "wards": 21,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Huyện An Lão",
        "code": "huyen_an_lao",
        "division_type": "huyện",
        "short_codename": "an_lao",
        "wards": 10,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Thị xã Hoài Nhơn",
        "code": "thi_xa_hoai_nhon",
        "division_type": "thị xã",
        "short_codename": "hoai_nhon",
        "wards": 17,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Huyện Hoài Ân",
        "code": "huyen_hoai_an",
        "division_type": "huyện",
        "short_codename": "hoai_an",
        "wards": 15,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Huyện Phù Mỹ",
        "code": "huyen_phu_my",
        "division_type": "huyện",
        "short_codename": "phu_my",
        "wards": 19,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Huyện Vĩnh Thạnh",
        "code": "huyen_vinh_thanh",
        "division_type": "huyện",
        "short_codename": "vinh_thanh",
        "wards": 9,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Huyện Tây Sơn",
        "code": "huyen_tay_son",
        "division_type": "huyện",
        "short_codename": "tay_son",
        "wards": 15,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Huyện Phù Cát",
        "code": "huyen_phu_cat",
        "division_type": "huyện",
        "short_codename": "phu_cat",
        "wards": 18,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Thị xã An Nhơn",
        "code": "thi_xa_an_nhon",
        "division_type": "thị xã",
        "short_codename": "an_nhon",
        "wards": 15,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Huyện Tuy Phước",
        "code": "huyen_tuy_phuoc",
        "division_type": "huyện",
        "short_codename": "tuy_phuoc",
        "wards": 13,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Huyện Vân Canh",
        "code": "huyen_van_canh",
        "division_type": "huyện",
        "short_codename": "van_canh",
        "wards": 7,
        "parentCode": "tinh_binh_dinh"
    },
    {
        "name": "Thành phố Tuy Hoà",
        "code": "thanh_pho_tuy_hoa",
        "division_type": "thành phố",
        "short_codename": "tuy_hoa",
        "wards": 16,
        "parentCode": "tinh_phu_yen"
    },
    {
        "name": "Thị xã Sông Cầu",
        "code": "thi_xa_song_cau",
        "division_type": "thị xã",
        "short_codename": "song_cau",
        "wards": 13,
        "parentCode": "tinh_phu_yen"
    },
    {
        "name": "Huyện Đồng Xuân",
        "code": "huyen_dong_xuan",
        "division_type": "huyện",
        "short_codename": "dong_xuan",
        "wards": 11,
        "parentCode": "tinh_phu_yen"
    },
    {
        "name": "Huyện Tuy An",
        "code": "huyen_tuy_an",
        "division_type": "huyện",
        "short_codename": "tuy_an",
        "wards": 15,
        "parentCode": "tinh_phu_yen"
    },
    {
        "name": "Huyện Sơn Hòa",
        "code": "huyen_son_hoa",
        "division_type": "huyện",
        "short_codename": "son_hoa",
        "wards": 14,
        "parentCode": "tinh_phu_yen"
    },
    {
        "name": "Huyện Sông Hinh",
        "code": "huyen_song_hinh",
        "division_type": "huyện",
        "short_codename": "song_hinh",
        "wards": 11,
        "parentCode": "tinh_phu_yen"
    },
    {
        "name": "Huyện Tây Hoà",
        "code": "huyen_tay_hoa",
        "division_type": "huyện",
        "short_codename": "tay_hoa",
        "wards": 11,
        "parentCode": "tinh_phu_yen"
    },
    {
        "name": "Huyện Phú Hoà",
        "code": "huyen_phu_hoa",
        "division_type": "huyện",
        "short_codename": "phu_hoa",
        "wards": 9,
        "parentCode": "tinh_phu_yen"
    },
    {
        "name": "Thị xã Đông Hòa",
        "code": "thi_xa_dong_hoa",
        "division_type": "thị xã",
        "short_codename": "dong_hoa",
        "wards": 10,
        "parentCode": "tinh_phu_yen"
    },
    {
        "name": "Thành phố Nha Trang",
        "code": "thanh_pho_nha_trang",
        "division_type": "thành phố",
        "short_codename": "nha_trang",
        "wards": 27,
        "parentCode": "tinh_khanh_hoa"
    },
    {
        "name": "Thành phố Cam Ranh",
        "code": "thanh_pho_cam_ranh",
        "division_type": "thành phố",
        "short_codename": "cam_ranh",
        "wards": 15,
        "parentCode": "tinh_khanh_hoa"
    },
    {
        "name": "Huyện Cam Lâm",
        "code": "huyen_cam_lam",
        "division_type": "huyện",
        "short_codename": "cam_lam",
        "wards": 14,
        "parentCode": "tinh_khanh_hoa"
    },
    {
        "name": "Huyện Vạn Ninh",
        "code": "huyen_van_ninh",
        "division_type": "huyện",
        "short_codename": "van_ninh",
        "wards": 13,
        "parentCode": "tinh_khanh_hoa"
    },
    {
        "name": "Thị xã Ninh Hòa",
        "code": "thi_xa_ninh_hoa",
        "division_type": "thị xã",
        "short_codename": "ninh_hoa",
        "wards": 27,
        "parentCode": "tinh_khanh_hoa"
    },
    {
        "name": "Huyện Khánh Vĩnh",
        "code": "huyen_khanh_vinh",
        "division_type": "huyện",
        "short_codename": "khanh_vinh",
        "wards": 14,
        "parentCode": "tinh_khanh_hoa"
    },
    {
        "name": "Huyện Diên Khánh",
        "code": "huyen_dien_khanh",
        "division_type": "huyện",
        "short_codename": "dien_khanh",
        "wards": 18,
        "parentCode": "tinh_khanh_hoa"
    },
    {
        "name": "Huyện Khánh Sơn",
        "code": "huyen_khanh_son",
        "division_type": "huyện",
        "short_codename": "khanh_son",
        "wards": 8,
        "parentCode": "tinh_khanh_hoa"
    },
    {
        "name": "Huyện Trường Sa",
        "code": "huyen_truong_sa",
        "division_type": "huyện",
        "short_codename": "truong_sa",
        "wards": 3,
        "parentCode": "tinh_khanh_hoa"
    },
    {
        "name": "Thành phố Phan Rang-Tháp Chàm",
        "code": "thanh_pho_phan_rang_thap_cham",
        "division_type": "thành phố",
        "short_codename": "phan_rang_thap_cham",
        "wards": 16,
        "parentCode": "tinh_ninh_thuan"
    },
    {
        "name": "Huyện Bác Ái",
        "code": "huyen_bac_ai",
        "division_type": "huyện",
        "short_codename": "bac_ai",
        "wards": 9,
        "parentCode": "tinh_ninh_thuan"
    },
    {
        "name": "Huyện Ninh Sơn",
        "code": "huyen_ninh_son",
        "division_type": "huyện",
        "short_codename": "ninh_son",
        "wards": 8,
        "parentCode": "tinh_ninh_thuan"
    },
    {
        "name": "Huyện Ninh Hải",
        "code": "huyen_ninh_hai",
        "division_type": "huyện",
        "short_codename": "ninh_hai",
        "wards": 9,
        "parentCode": "tinh_ninh_thuan"
    },
    {
        "name": "Huyện Ninh Phước",
        "code": "huyen_ninh_phuoc",
        "division_type": "huyện",
        "short_codename": "ninh_phuoc",
        "wards": 9,
        "parentCode": "tinh_ninh_thuan"
    },
    {
        "name": "Huyện Thuận Bắc",
        "code": "huyen_thuan_bac",
        "division_type": "huyện",
        "short_codename": "thuan_bac",
        "wards": 6,
        "parentCode": "tinh_ninh_thuan"
    },
    {
        "name": "Huyện Thuận Nam",
        "code": "huyen_thuan_nam",
        "division_type": "huyện",
        "short_codename": "thuan_nam",
        "wards": 8,
        "parentCode": "tinh_ninh_thuan"
    },
    {
        "name": "Thành phố Phan Thiết",
        "code": "thanh_pho_phan_thiet",
        "division_type": "thành phố",
        "short_codename": "phan_thiet",
        "wards": 18,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Thị xã La Gi",
        "code": "thi_xa_la_gi",
        "division_type": "thị xã",
        "short_codename": "la_gi",
        "wards": 9,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Huyện Tuy Phong",
        "code": "huyen_tuy_phong",
        "division_type": "huyện",
        "short_codename": "tuy_phong",
        "wards": 11,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Huyện Bắc Bình",
        "code": "huyen_bac_binh",
        "division_type": "huyện",
        "short_codename": "bac_binh",
        "wards": 18,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Huyện Hàm Thuận Bắc",
        "code": "huyen_ham_thuan_bac",
        "division_type": "huyện",
        "short_codename": "ham_thuan_bac",
        "wards": 17,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Huyện Hàm Thuận Nam",
        "code": "huyen_ham_thuan_nam",
        "division_type": "huyện",
        "short_codename": "ham_thuan_nam",
        "wards": 13,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Huyện Tánh Linh",
        "code": "huyen_tanh_linh",
        "division_type": "huyện",
        "short_codename": "tanh_linh",
        "wards": 13,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Huyện Đức Linh",
        "code": "huyen_duc_linh",
        "division_type": "huyện",
        "short_codename": "duc_linh",
        "wards": 12,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Huyện Hàm Tân",
        "code": "huyen_ham_tan",
        "division_type": "huyện",
        "short_codename": "ham_tan",
        "wards": 10,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Huyện Phú Quí",
        "code": "huyen_phu_qui",
        "division_type": "huyện",
        "short_codename": "phu_qui",
        "wards": 3,
        "parentCode": "tinh_binh_thuan"
    },
    {
        "name": "Thành phố Kon Tum",
        "code": "thanh_pho_kon_tum",
        "division_type": "thành phố",
        "short_codename": "kon_tum",
        "wards": 21,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Huyện Đắk Glei",
        "code": "huyen_dak_glei",
        "division_type": "huyện",
        "short_codename": "dak_glei",
        "wards": 12,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Huyện Ngọc Hồi",
        "code": "huyen_ngoc_hoi",
        "division_type": "huyện",
        "short_codename": "ngoc_hoi",
        "wards": 8,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Huyện Đắk Tô",
        "code": "huyen_dak_to",
        "division_type": "huyện",
        "short_codename": "dak_to",
        "wards": 9,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Huyện Kon Plông",
        "code": "huyen_kon_plong",
        "division_type": "huyện",
        "short_codename": "kon_plong",
        "wards": 9,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Huyện Kon Rẫy",
        "code": "huyen_kon_ray",
        "division_type": "huyện",
        "short_codename": "kon_ray",
        "wards": 7,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Huyện Đắk Hà",
        "code": "huyen_dak_ha",
        "division_type": "huyện",
        "short_codename": "dak_ha",
        "wards": 11,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Huyện Sa Thầy",
        "code": "huyen_sa_thay",
        "division_type": "huyện",
        "short_codename": "sa_thay",
        "wards": 11,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Huyện Tu Mơ Rông",
        "code": "huyen_tu_mo_rong",
        "division_type": "huyện",
        "short_codename": "tu_mo_rong",
        "wards": 11,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Huyện Ia H' Drai",
        "code": "huyen_ia_h_drai",
        "division_type": "huyện",
        "short_codename": "ia_h_drai",
        "wards": 3,
        "parentCode": "tinh_kon_tum"
    },
    {
        "name": "Thành phố Pleiku",
        "code": "thanh_pho_pleiku",
        "division_type": "thành phố",
        "short_codename": "pleiku",
        "wards": 22,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Thị xã An Khê",
        "code": "thi_xa_an_khe",
        "division_type": "thị xã",
        "short_codename": "an_khe",
        "wards": 11,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Thị xã Ayun Pa",
        "code": "thi_xa_ayun_pa",
        "division_type": "thị xã",
        "short_codename": "ayun_pa",
        "wards": 8,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện KBang",
        "code": "huyen_kbang",
        "division_type": "huyện",
        "short_codename": "kbang",
        "wards": 14,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Đăk Đoa",
        "code": "huyen_dak_doa",
        "division_type": "huyện",
        "short_codename": "dak_doa",
        "wards": 17,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Chư Păh",
        "code": "huyen_chu_pah",
        "division_type": "huyện",
        "short_codename": "chu_pah",
        "wards": 14,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Ia Grai",
        "code": "huyen_ia_grai",
        "division_type": "huyện",
        "short_codename": "ia_grai",
        "wards": 13,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Mang Yang",
        "code": "huyen_mang_yang",
        "division_type": "huyện",
        "short_codename": "mang_yang",
        "wards": 12,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Kông Chro",
        "code": "huyen_kong_chro",
        "division_type": "huyện",
        "short_codename": "kong_chro",
        "wards": 14,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Đức Cơ",
        "code": "huyen_duc_co",
        "division_type": "huyện",
        "short_codename": "duc_co",
        "wards": 10,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Chư Prông",
        "code": "huyen_chu_prong",
        "division_type": "huyện",
        "short_codename": "chu_prong",
        "wards": 20,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Chư Sê",
        "code": "huyen_chu_se",
        "division_type": "huyện",
        "short_codename": "chu_se",
        "wards": 15,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Đăk Pơ",
        "code": "huyen_dak_po",
        "division_type": "huyện",
        "short_codename": "dak_po",
        "wards": 8,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Ia Pa",
        "code": "huyen_ia_pa",
        "division_type": "huyện",
        "short_codename": "ia_pa",
        "wards": 9,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Krông Pa",
        "code": "huyen_krong_pa",
        "division_type": "huyện",
        "short_codename": "krong_pa",
        "wards": 14,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Phú Thiện",
        "code": "huyen_phu_thien",
        "division_type": "huyện",
        "short_codename": "phu_thien",
        "wards": 10,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Huyện Chư Pưh",
        "code": "huyen_chu_puh",
        "division_type": "huyện",
        "short_codename": "chu_puh",
        "wards": 9,
        "parentCode": "tinh_gia_lai"
    },
    {
        "name": "Thành phố Buôn Ma Thuột",
        "code": "thanh_pho_buon_ma_thuot",
        "division_type": "thành phố",
        "short_codename": "buon_ma_thuot",
        "wards": 21,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Thị Xã Buôn Hồ",
        "code": "thi_xa_buon_ho",
        "division_type": "thị xã",
        "short_codename": "buon_ho",
        "wards": 12,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Ea H'leo",
        "code": "huyen_ea_hleo",
        "division_type": "huyện",
        "short_codename": "ea_hleo",
        "wards": 12,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Ea Súp",
        "code": "huyen_ea_sup",
        "division_type": "huyện",
        "short_codename": "ea_sup",
        "wards": 10,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Buôn Đôn",
        "code": "huyen_buon_don",
        "division_type": "huyện",
        "short_codename": "buon_don",
        "wards": 7,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Cư M'gar",
        "code": "huyen_cu_mgar",
        "division_type": "huyện",
        "short_codename": "cu_mgar",
        "wards": 17,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Krông Búk",
        "code": "huyen_krong_buk",
        "division_type": "huyện",
        "short_codename": "krong_buk",
        "wards": 7,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Krông Năng",
        "code": "huyen_krong_nang",
        "division_type": "huyện",
        "short_codename": "krong_nang",
        "wards": 12,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Ea Kar",
        "code": "huyen_ea_kar",
        "division_type": "huyện",
        "short_codename": "ea_kar",
        "wards": 16,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện M'Đrắk",
        "code": "huyen_mdrak",
        "division_type": "huyện",
        "short_codename": "mdrak",
        "wards": 13,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Krông Bông",
        "code": "huyen_krong_bong",
        "division_type": "huyện",
        "short_codename": "krong_bong",
        "wards": 14,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Krông Pắc",
        "code": "huyen_krong_pac",
        "division_type": "huyện",
        "short_codename": "krong_pac",
        "wards": 16,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Krông A Na",
        "code": "huyen_krong_a_na",
        "division_type": "huyện",
        "short_codename": "krong_a_na",
        "wards": 8,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Lắk",
        "code": "huyen_lak",
        "division_type": "huyện",
        "short_codename": "lak",
        "wards": 11,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Huyện Cư Kuin",
        "code": "huyen_cu_kuin",
        "division_type": "huyện",
        "short_codename": "cu_kuin",
        "wards": 8,
        "parentCode": "tinh_dak_lak"
    },
    {
        "name": "Thành phố Gia Nghĩa",
        "code": "thanh_pho_gia_nghia",
        "division_type": "thành phố",
        "short_codename": "gia_nghia",
        "wards": 8,
        "parentCode": "tinh_dak_nong"
    },
    {
        "name": "Huyện Đăk Glong",
        "code": "huyen_dak_glong",
        "division_type": "huyện",
        "short_codename": "dak_glong",
        "wards": 7,
        "parentCode": "tinh_dak_nong"
    },
    {
        "name": "Huyện Cư Jút",
        "code": "huyen_cu_jut",
        "division_type": "huyện",
        "short_codename": "cu_jut",
        "wards": 8,
        "parentCode": "tinh_dak_nong"
    },
    {
        "name": "Huyện Đắk Mil",
        "code": "huyen_dak_mil",
        "division_type": "huyện",
        "short_codename": "dak_mil",
        "wards": 10,
        "parentCode": "tinh_dak_nong"
    },
    {
        "name": "Huyện Krông Nô",
        "code": "huyen_krong_no",
        "division_type": "huyện",
        "short_codename": "krong_no",
        "wards": 12,
        "parentCode": "tinh_dak_nong"
    },
    {
        "name": "Huyện Đắk Song",
        "code": "huyen_dak_song",
        "division_type": "huyện",
        "short_codename": "dak_song",
        "wards": 9,
        "parentCode": "tinh_dak_nong"
    },
    {
        "name": "Huyện Đắk R'Lấp",
        "code": "huyen_dak_rlap",
        "division_type": "huyện",
        "short_codename": "dak_rlap",
        "wards": 11,
        "parentCode": "tinh_dak_nong"
    },
    {
        "name": "Huyện Tuy Đức",
        "code": "huyen_tuy_duc",
        "division_type": "huyện",
        "short_codename": "tuy_duc",
        "wards": 6,
        "parentCode": "tinh_dak_nong"
    },
    {
        "name": "Thành phố Đà Lạt",
        "code": "thanh_pho_da_lat",
        "division_type": "thành phố",
        "short_codename": "da_lat",
        "wards": 16,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Thành phố Bảo Lộc",
        "code": "thanh_pho_bao_loc",
        "division_type": "thành phố",
        "short_codename": "bao_loc",
        "wards": 11,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Đam Rông",
        "code": "huyen_dam_rong",
        "division_type": "huyện",
        "short_codename": "dam_rong",
        "wards": 8,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Lạc Dương",
        "code": "huyen_lac_duong",
        "division_type": "huyện",
        "short_codename": "lac_duong",
        "wards": 6,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Lâm Hà",
        "code": "huyen_lam_ha",
        "division_type": "huyện",
        "short_codename": "lam_ha",
        "wards": 16,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Đơn Dương",
        "code": "huyen_don_duong",
        "division_type": "huyện",
        "short_codename": "don_duong",
        "wards": 10,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Đức Trọng",
        "code": "huyen_duc_trong",
        "division_type": "huyện",
        "short_codename": "duc_trong",
        "wards": 15,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Di Linh",
        "code": "huyen_di_linh",
        "division_type": "huyện",
        "short_codename": "di_linh",
        "wards": 19,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Bảo Lâm",
        "code": "huyen_bao_lam",
        "division_type": "huyện",
        "short_codename": "bao_lam",
        "wards": 14,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Đạ Huoai",
        "code": "huyen_da_huoai",
        "division_type": "huyện",
        "short_codename": "da_huoai",
        "wards": 9,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Đạ Tẻh",
        "code": "huyen_da_teh",
        "division_type": "huyện",
        "short_codename": "da_teh",
        "wards": 9,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Huyện Cát Tiên",
        "code": "huyen_cat_tien",
        "division_type": "huyện",
        "short_codename": "cat_tien",
        "wards": 9,
        "parentCode": "tinh_lam_dong"
    },
    {
        "name": "Thị xã Phước Long",
        "code": "thi_xa_phuoc_long",
        "division_type": "thị xã",
        "short_codename": "phuoc_long",
        "wards": 7,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Thành phố Đồng Xoài",
        "code": "thanh_pho_dong_xoai",
        "division_type": "thành phố",
        "short_codename": "dong_xoai",
        "wards": 8,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Thị xã Bình Long",
        "code": "thi_xa_binh_long",
        "division_type": "thị xã",
        "short_codename": "binh_long",
        "wards": 6,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Huyện Bù Gia Mập",
        "code": "huyen_bu_gia_map",
        "division_type": "huyện",
        "short_codename": "bu_gia_map",
        "wards": 8,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Huyện Lộc Ninh",
        "code": "huyen_loc_ninh",
        "division_type": "huyện",
        "short_codename": "loc_ninh",
        "wards": 16,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Huyện Bù Đốp",
        "code": "huyen_bu_dop",
        "division_type": "huyện",
        "short_codename": "bu_dop",
        "wards": 7,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Huyện Hớn Quản",
        "code": "huyen_hon_quan",
        "division_type": "huyện",
        "short_codename": "hon_quan",
        "wards": 13,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Huyện Đồng Phú",
        "code": "huyen_dong_phu",
        "division_type": "huyện",
        "short_codename": "dong_phu",
        "wards": 11,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Huyện Bù Đăng",
        "code": "huyen_bu_dang",
        "division_type": "huyện",
        "short_codename": "bu_dang",
        "wards": 16,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Huyện Chơn Thành",
        "code": "huyen_chon_thanh",
        "division_type": "huyện",
        "short_codename": "chon_thanh",
        "wards": 9,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Huyện Phú Riềng",
        "code": "huyen_phu_rieng",
        "division_type": "huyện",
        "short_codename": "phu_rieng",
        "wards": 10,
        "parentCode": "tinh_binh_phuoc"
    },
    {
        "name": "Thành phố Tây Ninh",
        "code": "thanh_pho_tay_ninh",
        "division_type": "thành phố",
        "short_codename": "tay_ninh",
        "wards": 10,
        "parentCode": "tinh_tay_ninh"
    },
    {
        "name": "Huyện Tân Biên",
        "code": "huyen_tan_bien",
        "division_type": "huyện",
        "short_codename": "tan_bien",
        "wards": 10,
        "parentCode": "tinh_tay_ninh"
    },
    {
        "name": "Huyện Tân Châu",
        "code": "huyen_tan_chau",
        "division_type": "huyện",
        "short_codename": "tan_chau",
        "wards": 12,
        "parentCode": "tinh_tay_ninh"
    },
    {
        "name": "Huyện Dương Minh Châu",
        "code": "huyen_duong_minh_chau",
        "division_type": "huyện",
        "short_codename": "duong_minh_chau",
        "wards": 11,
        "parentCode": "tinh_tay_ninh"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 15,
        "parentCode": "tinh_tay_ninh"
    },
    {
        "name": "Thị xã Hòa Thành",
        "code": "thi_xa_hoa_thanh",
        "division_type": "thị xã",
        "short_codename": "hoa_thanh",
        "wards": 8,
        "parentCode": "tinh_tay_ninh"
    },
    {
        "name": "Huyện Gò Dầu",
        "code": "huyen_go_dau",
        "division_type": "huyện",
        "short_codename": "go_dau",
        "wards": 9,
        "parentCode": "tinh_tay_ninh"
    },
    {
        "name": "Huyện Bến Cầu",
        "code": "huyen_ben_cau",
        "division_type": "huyện",
        "short_codename": "ben_cau",
        "wards": 9,
        "parentCode": "tinh_tay_ninh"
    },
    {
        "name": "Thị xã Trảng Bàng",
        "code": "thi_xa_trang_bang",
        "division_type": "thị xã",
        "short_codename": "trang_bang",
        "wards": 10,
        "parentCode": "tinh_tay_ninh"
    },
    {
        "name": "Thành phố Thủ Dầu Một",
        "code": "thanh_pho_thu_dau_mot",
        "division_type": "thành phố",
        "short_codename": "thu_dau_mot",
        "wards": 14,
        "parentCode": "tinh_binh_duong"
    },
    {
        "name": "Huyện Bàu Bàng",
        "code": "huyen_bau_bang",
        "division_type": "huyện",
        "short_codename": "bau_bang",
        "wards": 7,
        "parentCode": "tinh_binh_duong"
    },
    {
        "name": "Huyện Dầu Tiếng",
        "code": "huyen_dau_tieng",
        "division_type": "huyện",
        "short_codename": "dau_tieng",
        "wards": 12,
        "parentCode": "tinh_binh_duong"
    },
    {
        "name": "Thị xã Bến Cát",
        "code": "thi_xa_ben_cat",
        "division_type": "thị xã",
        "short_codename": "ben_cat",
        "wards": 8,
        "parentCode": "tinh_binh_duong"
    },
    {
        "name": "Huyện Phú Giáo",
        "code": "huyen_phu_giao",
        "division_type": "huyện",
        "short_codename": "phu_giao",
        "wards": 11,
        "parentCode": "tinh_binh_duong"
    },
    {
        "name": "Thị xã Tân Uyên",
        "code": "thi_xa_tan_uyen",
        "division_type": "thị xã",
        "short_codename": "tan_uyen",
        "wards": 12,
        "parentCode": "tinh_binh_duong"
    },
    {
        "name": "Thành phố Dĩ An",
        "code": "thanh_pho_di_an",
        "division_type": "thành phố",
        "short_codename": "di_an",
        "wards": 7,
        "parentCode": "tinh_binh_duong"
    },
    {
        "name": "Thành phố Thuận An",
        "code": "thanh_pho_thuan_an",
        "division_type": "thành phố",
        "short_codename": "thuan_an",
        "wards": 10,
        "parentCode": "tinh_binh_duong"
    },
    {
        "name": "Huyện Bắc Tân Uyên",
        "code": "huyen_bac_tan_uyen",
        "division_type": "huyện",
        "short_codename": "bac_tan_uyen",
        "wards": 10,
        "parentCode": "tinh_binh_duong"
    },
    {
        "name": "Thành phố Biên Hòa",
        "code": "thanh_pho_bien_hoa",
        "division_type": "thành phố",
        "short_codename": "bien_hoa",
        "wards": 30,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Thành phố Long Khánh",
        "code": "thanh_pho_long_khanh",
        "division_type": "thành phố",
        "short_codename": "long_khanh",
        "wards": 15,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Huyện Tân Phú",
        "code": "huyen_tan_phu",
        "division_type": "huyện",
        "short_codename": "tan_phu",
        "wards": 18,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Huyện Vĩnh Cửu",
        "code": "huyen_vinh_cuu",
        "division_type": "huyện",
        "short_codename": "vinh_cuu",
        "wards": 12,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Huyện Định Quán",
        "code": "huyen_dinh_quan",
        "division_type": "huyện",
        "short_codename": "dinh_quan",
        "wards": 14,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Huyện Trảng Bom",
        "code": "huyen_trang_bom",
        "division_type": "huyện",
        "short_codename": "trang_bom",
        "wards": 17,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Huyện Thống Nhất",
        "code": "huyen_thong_nhat",
        "division_type": "huyện",
        "short_codename": "thong_nhat",
        "wards": 10,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Huyện Cẩm Mỹ",
        "code": "huyen_cam_my",
        "division_type": "huyện",
        "short_codename": "cam_my",
        "wards": 13,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Huyện Long Thành",
        "code": "huyen_long_thanh",
        "division_type": "huyện",
        "short_codename": "long_thanh",
        "wards": 14,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Huyện Xuân Lộc",
        "code": "huyen_xuan_loc",
        "division_type": "huyện",
        "short_codename": "xuan_loc",
        "wards": 15,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Huyện Nhơn Trạch",
        "code": "huyen_nhon_trach",
        "division_type": "huyện",
        "short_codename": "nhon_trach",
        "wards": 12,
        "parentCode": "tinh_dong_nai"
    },
    {
        "name": "Thành phố Vũng Tàu",
        "code": "thanh_pho_vung_tau",
        "division_type": "thành phố",
        "short_codename": "vung_tau",
        "wards": 17,
        "parentCode": "tinh_ba_ria_vung_tau"
    },
    {
        "name": "Thành phố Bà Rịa",
        "code": "thanh_pho_ba_ria",
        "division_type": "thành phố",
        "short_codename": "ba_ria",
        "wards": 11,
        "parentCode": "tinh_ba_ria_vung_tau"
    },
    {
        "name": "Huyện Châu Đức",
        "code": "huyen_chau_duc",
        "division_type": "huyện",
        "short_codename": "chau_duc",
        "wards": 16,
        "parentCode": "tinh_ba_ria_vung_tau"
    },
    {
        "name": "Huyện Xuyên Mộc",
        "code": "huyen_xuyen_moc",
        "division_type": "huyện",
        "short_codename": "xuyen_moc",
        "wards": 13,
        "parentCode": "tinh_ba_ria_vung_tau"
    },
    {
        "name": "Huyện Long Điền",
        "code": "huyen_long_dien",
        "division_type": "huyện",
        "short_codename": "long_dien",
        "wards": 7,
        "parentCode": "tinh_ba_ria_vung_tau"
    },
    {
        "name": "Huyện Đất Đỏ",
        "code": "huyen_dat_do",
        "division_type": "huyện",
        "short_codename": "dat_do",
        "wards": 8,
        "parentCode": "tinh_ba_ria_vung_tau"
    },
    {
        "name": "Thị xã Phú Mỹ",
        "code": "thi_xa_phu_my",
        "division_type": "thị xã",
        "short_codename": "phu_my",
        "wards": 10,
        "parentCode": "tinh_ba_ria_vung_tau"
    },
    {
        "name": "Huyện Côn Đảo",
        "code": "huyen_con_dao",
        "division_type": "huyện",
        "short_codename": "con_dao",
        "wards": 0,
        "parentCode": "tinh_ba_ria_vung_tau"
    },
    {
        "name": "Quận 1",
        "code": "quan_1",
        "division_type": "quận",
        "short_codename": "quan_1",
        "wards": 10,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận 12",
        "code": "quan_12",
        "division_type": "quận",
        "short_codename": "quan_12",
        "wards": 11,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận Gò Vấp",
        "code": "quan_go_vap",
        "division_type": "quận",
        "short_codename": "go_vap",
        "wards": 16,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận Bình Thạnh",
        "code": "quan_binh_thanh",
        "division_type": "quận",
        "short_codename": "binh_thanh",
        "wards": 20,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận Tân Bình",
        "code": "quan_tan_binh",
        "division_type": "quận",
        "short_codename": "tan_binh",
        "wards": 15,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận Tân Phú",
        "code": "quan_tan_phu",
        "division_type": "quận",
        "short_codename": "tan_phu",
        "wards": 11,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận Phú Nhuận",
        "code": "quan_phu_nhuan",
        "division_type": "quận",
        "short_codename": "phu_nhuan",
        "wards": 13,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Thành phố Thủ Đức",
        "code": "thanh_pho_thu_duc",
        "division_type": "thành phố",
        "short_codename": "thu_duc",
        "wards": 34,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận 3",
        "code": "quan_3",
        "division_type": "quận",
        "short_codename": "quan_3",
        "wards": 12,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận 10",
        "code": "quan_10",
        "division_type": "quận",
        "short_codename": "quan_10",
        "wards": 14,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận 11",
        "code": "quan_11",
        "division_type": "quận",
        "short_codename": "quan_11",
        "wards": 16,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận 4",
        "code": "quan_4",
        "division_type": "quận",
        "short_codename": "quan_4",
        "wards": 13,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận 5",
        "code": "quan_5",
        "division_type": "quận",
        "short_codename": "quan_5",
        "wards": 14,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận 6",
        "code": "quan_6",
        "division_type": "quận",
        "short_codename": "quan_6",
        "wards": 14,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận 8",
        "code": "quan_8",
        "division_type": "quận",
        "short_codename": "quan_8",
        "wards": 16,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận Bình Tân",
        "code": "quan_binh_tan",
        "division_type": "quận",
        "short_codename": "binh_tan",
        "wards": 10,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Quận 7",
        "code": "quan_7",
        "division_type": "quận",
        "short_codename": "quan_7",
        "wards": 10,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Huyện Củ Chi",
        "code": "huyen_cu_chi",
        "division_type": "huyện",
        "short_codename": "cu_chi",
        "wards": 21,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Huyện Hóc Môn",
        "code": "huyen_hoc_mon",
        "division_type": "huyện",
        "short_codename": "hoc_mon",
        "wards": 12,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Huyện Bình Chánh",
        "code": "huyen_binh_chanh",
        "division_type": "huyện",
        "short_codename": "binh_chanh",
        "wards": 16,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Huyện Nhà Bè",
        "code": "huyen_nha_be",
        "division_type": "huyện",
        "short_codename": "nha_be",
        "wards": 7,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Huyện Cần Giờ",
        "code": "huyen_can_gio",
        "division_type": "huyện",
        "short_codename": "can_gio",
        "wards": 7,
        "parentCode": "thanh_pho_ho_chi_minh"
    },
    {
        "name": "Thành phố Tân An",
        "code": "thanh_pho_tan_an",
        "division_type": "thành phố",
        "short_codename": "tan_an",
        "wards": 14,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Thị xã Kiến Tường",
        "code": "thi_xa_kien_tuong",
        "division_type": "thị xã",
        "short_codename": "kien_tuong",
        "wards": 8,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Tân Hưng",
        "code": "huyen_tan_hung",
        "division_type": "huyện",
        "short_codename": "tan_hung",
        "wards": 12,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Vĩnh Hưng",
        "code": "huyen_vinh_hung",
        "division_type": "huyện",
        "short_codename": "vinh_hung",
        "wards": 10,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Mộc Hóa",
        "code": "huyen_moc_hoa",
        "division_type": "huyện",
        "short_codename": "moc_hoa",
        "wards": 7,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Tân Thạnh",
        "code": "huyen_tan_thanh",
        "division_type": "huyện",
        "short_codename": "tan_thanh",
        "wards": 13,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Thạnh Hóa",
        "code": "huyen_thanh_hoa",
        "division_type": "huyện",
        "short_codename": "thanh_hoa",
        "wards": 11,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Đức Huệ",
        "code": "huyen_duc_hue",
        "division_type": "huyện",
        "short_codename": "duc_hue",
        "wards": 11,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Đức Hòa",
        "code": "huyen_duc_hoa",
        "division_type": "huyện",
        "short_codename": "duc_hoa",
        "wards": 20,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Bến Lức",
        "code": "huyen_ben_luc",
        "division_type": "huyện",
        "short_codename": "ben_luc",
        "wards": 15,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Thủ Thừa",
        "code": "huyen_thu_thua",
        "division_type": "huyện",
        "short_codename": "thu_thua",
        "wards": 12,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Tân Trụ",
        "code": "huyen_tan_tru",
        "division_type": "huyện",
        "short_codename": "tan_tru",
        "wards": 10,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Cần Đước",
        "code": "huyen_can_duoc",
        "division_type": "huyện",
        "short_codename": "can_duoc",
        "wards": 17,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Cần Giuộc",
        "code": "huyen_can_giuoc",
        "division_type": "huyện",
        "short_codename": "can_giuoc",
        "wards": 15,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 13,
        "parentCode": "tinh_long_an"
    },
    {
        "name": "Thành phố Mỹ Tho",
        "code": "thanh_pho_my_tho",
        "division_type": "thành phố",
        "short_codename": "my_tho",
        "wards": 17,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Thị xã Gò Công",
        "code": "thi_xa_go_cong",
        "division_type": "thị xã",
        "short_codename": "go_cong",
        "wards": 12,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Thị xã Cai Lậy",
        "code": "thi_xa_cai_lay",
        "division_type": "thị xã",
        "short_codename": "cai_lay",
        "wards": 16,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Huyện Tân Phước",
        "code": "huyen_tan_phuoc",
        "division_type": "huyện",
        "short_codename": "tan_phuoc",
        "wards": 12,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Huyện Cái Bè",
        "code": "huyen_cai_be",
        "division_type": "huyện",
        "short_codename": "cai_be",
        "wards": 25,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Huyện Cai Lậy",
        "code": "huyen_cai_lay",
        "division_type": "huyện",
        "short_codename": "huyen_cai_lay",
        "wards": 16,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 23,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Huyện Chợ Gạo",
        "code": "huyen_cho_gao",
        "division_type": "huyện",
        "short_codename": "cho_gao",
        "wards": 19,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Huyện Gò Công Tây",
        "code": "huyen_go_cong_tay",
        "division_type": "huyện",
        "short_codename": "go_cong_tay",
        "wards": 13,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Huyện Gò Công Đông",
        "code": "huyen_go_cong_dong",
        "division_type": "huyện",
        "short_codename": "go_cong_dong",
        "wards": 13,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Huyện Tân Phú Đông",
        "code": "huyen_tan_phu_dong",
        "division_type": "huyện",
        "short_codename": "tan_phu_dong",
        "wards": 6,
        "parentCode": "tinh_tien_giang"
    },
    {
        "name": "Thành phố Bến Tre",
        "code": "thanh_pho_ben_tre",
        "division_type": "thành phố",
        "short_codename": "ben_tre",
        "wards": 14,
        "parentCode": "tinh_ben_tre"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 21,
        "parentCode": "tinh_ben_tre"
    },
    {
        "name": "Huyện Chợ Lách",
        "code": "huyen_cho_lach",
        "division_type": "huyện",
        "short_codename": "cho_lach",
        "wards": 11,
        "parentCode": "tinh_ben_tre"
    },
    {
        "name": "Huyện Mỏ Cày Nam",
        "code": "huyen_mo_cay_nam",
        "division_type": "huyện",
        "short_codename": "mo_cay_nam",
        "wards": 16,
        "parentCode": "tinh_ben_tre"
    },
    {
        "name": "Huyện Giồng Trôm",
        "code": "huyen_giong_trom",
        "division_type": "huyện",
        "short_codename": "giong_trom",
        "wards": 21,
        "parentCode": "tinh_ben_tre"
    },
    {
        "name": "Huyện Bình Đại",
        "code": "huyen_binh_dai",
        "division_type": "huyện",
        "short_codename": "binh_dai",
        "wards": 20,
        "parentCode": "tinh_ben_tre"
    },
    {
        "name": "Huyện Ba Tri",
        "code": "huyen_ba_tri",
        "division_type": "huyện",
        "short_codename": "ba_tri",
        "wards": 23,
        "parentCode": "tinh_ben_tre"
    },
    {
        "name": "Huyện Thạnh Phú",
        "code": "huyen_thanh_phu",
        "division_type": "huyện",
        "short_codename": "thanh_phu",
        "wards": 18,
        "parentCode": "tinh_ben_tre"
    },
    {
        "name": "Huyện Mỏ Cày Bắc",
        "code": "huyen_mo_cay_bac",
        "division_type": "huyện",
        "short_codename": "mo_cay_bac",
        "wards": 13,
        "parentCode": "tinh_ben_tre"
    },
    {
        "name": "Thành phố Trà Vinh",
        "code": "thanh_pho_tra_vinh",
        "division_type": "thành phố",
        "short_codename": "tra_vinh",
        "wards": 10,
        "parentCode": "tinh_tra_vinh"
    },
    {
        "name": "Huyện Càng Long",
        "code": "huyen_cang_long",
        "division_type": "huyện",
        "short_codename": "cang_long",
        "wards": 14,
        "parentCode": "tinh_tra_vinh"
    },
    {
        "name": "Huyện Cầu Kè",
        "code": "huyen_cau_ke",
        "division_type": "huyện",
        "short_codename": "cau_ke",
        "wards": 11,
        "parentCode": "tinh_tra_vinh"
    },
    {
        "name": "Huyện Tiểu Cần",
        "code": "huyen_tieu_can",
        "division_type": "huyện",
        "short_codename": "tieu_can",
        "wards": 11,
        "parentCode": "tinh_tra_vinh"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 14,
        "parentCode": "tinh_tra_vinh"
    },
    {
        "name": "Huyện Cầu Ngang",
        "code": "huyen_cau_ngang",
        "division_type": "huyện",
        "short_codename": "cau_ngang",
        "wards": 15,
        "parentCode": "tinh_tra_vinh"
    },
    {
        "name": "Huyện Trà Cú",
        "code": "huyen_tra_cu",
        "division_type": "huyện",
        "short_codename": "tra_cu",
        "wards": 17,
        "parentCode": "tinh_tra_vinh"
    },
    {
        "name": "Huyện Duyên Hải",
        "code": "huyen_duyen_hai",
        "division_type": "huyện",
        "short_codename": "huyen_duyen_hai",
        "wards": 7,
        "parentCode": "tinh_tra_vinh"
    },
    {
        "name": "Thị xã Duyên Hải",
        "code": "thi_xa_duyen_hai",
        "division_type": "thị xã",
        "short_codename": "duyen_hai",
        "wards": 7,
        "parentCode": "tinh_tra_vinh"
    },
    {
        "name": "Thành phố Vĩnh Long",
        "code": "thanh_pho_vinh_long",
        "division_type": "thành phố",
        "short_codename": "vinh_long",
        "wards": 11,
        "parentCode": "tinh_vinh_long"
    },
    {
        "name": "Huyện Long Hồ",
        "code": "huyen_long_ho",
        "division_type": "huyện",
        "short_codename": "long_ho",
        "wards": 15,
        "parentCode": "tinh_vinh_long"
    },
    {
        "name": "Huyện Mang Thít",
        "code": "huyen_mang_thit",
        "division_type": "huyện",
        "short_codename": "mang_thit",
        "wards": 12,
        "parentCode": "tinh_vinh_long"
    },
    {
        "name": "Huyện Vũng Liêm",
        "code": "huyen_vung_liem",
        "division_type": "huyện",
        "short_codename": "vung_liem",
        "wards": 20,
        "parentCode": "tinh_vinh_long"
    },
    {
        "name": "Huyện Tam Bình",
        "code": "huyen_tam_binh",
        "division_type": "huyện",
        "short_codename": "tam_binh",
        "wards": 17,
        "parentCode": "tinh_vinh_long"
    },
    {
        "name": "Thị xã Bình Minh",
        "code": "thi_xa_binh_minh",
        "division_type": "thị xã",
        "short_codename": "binh_minh",
        "wards": 8,
        "parentCode": "tinh_vinh_long"
    },
    {
        "name": "Huyện Trà Ôn",
        "code": "huyen_tra_on",
        "division_type": "huyện",
        "short_codename": "tra_on",
        "wards": 14,
        "parentCode": "tinh_vinh_long"
    },
    {
        "name": "Huyện Bình Tân",
        "code": "huyen_binh_tan",
        "division_type": "huyện",
        "short_codename": "binh_tan",
        "wards": 10,
        "parentCode": "tinh_vinh_long"
    },
    {
        "name": "Thành phố Cao Lãnh",
        "code": "thanh_pho_cao_lanh",
        "division_type": "thành phố",
        "short_codename": "cao_lanh",
        "wards": 15,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Thành phố Sa Đéc",
        "code": "thanh_pho_sa_dec",
        "division_type": "thành phố",
        "short_codename": "sa_dec",
        "wards": 9,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Thành phố Hồng Ngự",
        "code": "thanh_pho_hong_ngu",
        "division_type": "thành phố",
        "short_codename": "hong_ngu",
        "wards": 7,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Huyện Tân Hồng",
        "code": "huyen_tan_hong",
        "division_type": "huyện",
        "short_codename": "tan_hong",
        "wards": 9,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Huyện Hồng Ngự",
        "code": "huyen_hong_ngu",
        "division_type": "huyện",
        "short_codename": "huyen_hong_ngu",
        "wards": 10,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Huyện Tam Nông",
        "code": "huyen_tam_nong",
        "division_type": "huyện",
        "short_codename": "tam_nong",
        "wards": 12,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Huyện Tháp Mười",
        "code": "huyen_thap_muoi",
        "division_type": "huyện",
        "short_codename": "thap_muoi",
        "wards": 13,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Huyện Cao Lãnh",
        "code": "huyen_cao_lanh",
        "division_type": "huyện",
        "short_codename": "huyen_cao_lanh",
        "wards": 18,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Huyện Thanh Bình",
        "code": "huyen_thanh_binh",
        "division_type": "huyện",
        "short_codename": "thanh_binh",
        "wards": 13,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Huyện Lấp Vò",
        "code": "huyen_lap_vo",
        "division_type": "huyện",
        "short_codename": "lap_vo",
        "wards": 13,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Huyện Lai Vung",
        "code": "huyen_lai_vung",
        "division_type": "huyện",
        "short_codename": "lai_vung",
        "wards": 12,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 12,
        "parentCode": "tinh_dong_thap"
    },
    {
        "name": "Thành phố Long Xuyên",
        "code": "thanh_pho_long_xuyen",
        "division_type": "thành phố",
        "short_codename": "long_xuyen",
        "wards": 13,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Thành phố Châu Đốc",
        "code": "thanh_pho_chau_doc",
        "division_type": "thành phố",
        "short_codename": "chau_doc",
        "wards": 7,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Huyện An Phú",
        "code": "huyen_an_phu",
        "division_type": "huyện",
        "short_codename": "an_phu",
        "wards": 14,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Thị xã Tân Châu",
        "code": "thi_xa_tan_chau",
        "division_type": "thị xã",
        "short_codename": "tan_chau",
        "wards": 14,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Huyện Phú Tân",
        "code": "huyen_phu_tan",
        "division_type": "huyện",
        "short_codename": "phu_tan",
        "wards": 18,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Huyện Châu Phú",
        "code": "huyen_chau_phu",
        "division_type": "huyện",
        "short_codename": "chau_phu",
        "wards": 13,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Huyện Tịnh Biên",
        "code": "huyen_tinh_bien",
        "division_type": "huyện",
        "short_codename": "tinh_bien",
        "wards": 14,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Huyện Tri Tôn",
        "code": "huyen_tri_ton",
        "division_type": "huyện",
        "short_codename": "tri_ton",
        "wards": 15,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 13,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Huyện Chợ Mới",
        "code": "huyen_cho_moi",
        "division_type": "huyện",
        "short_codename": "cho_moi",
        "wards": 18,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Huyện Thoại Sơn",
        "code": "huyen_thoai_son",
        "division_type": "huyện",
        "short_codename": "thoai_son",
        "wards": 17,
        "parentCode": "tinh_an_giang"
    },
    {
        "name": "Thành phố Rạch Giá",
        "code": "thanh_pho_rach_gia",
        "division_type": "thành phố",
        "short_codename": "rach_gia",
        "wards": 12,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Thành phố Hà Tiên",
        "code": "thanh_pho_ha_tien",
        "division_type": "thành phố",
        "short_codename": "ha_tien",
        "wards": 7,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện Kiên Lương",
        "code": "huyen_kien_luong",
        "division_type": "huyện",
        "short_codename": "kien_luong",
        "wards": 8,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện Hòn Đất",
        "code": "huyen_hon_dat",
        "division_type": "huyện",
        "short_codename": "hon_dat",
        "wards": 14,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện Tân Hiệp",
        "code": "huyen_tan_hiep",
        "division_type": "huyện",
        "short_codename": "tan_hiep",
        "wards": 11,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 10,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện Giồng Riềng",
        "code": "huyen_giong_rieng",
        "division_type": "huyện",
        "short_codename": "giong_rieng",
        "wards": 19,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện Gò Quao",
        "code": "huyen_go_quao",
        "division_type": "huyện",
        "short_codename": "go_quao",
        "wards": 11,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện An Biên",
        "code": "huyen_an_bien",
        "division_type": "huyện",
        "short_codename": "an_bien",
        "wards": 9,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện An Minh",
        "code": "huyen_an_minh",
        "division_type": "huyện",
        "short_codename": "an_minh",
        "wards": 11,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện Vĩnh Thuận",
        "code": "huyen_vinh_thuan",
        "division_type": "huyện",
        "short_codename": "vinh_thuan",
        "wards": 8,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Thành phố Phú Quốc",
        "code": "thanh_pho_phu_quoc",
        "division_type": "thành phố",
        "short_codename": "phu_quoc",
        "wards": 9,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện Kiên Hải",
        "code": "huyen_kien_hai",
        "division_type": "huyện",
        "short_codename": "kien_hai",
        "wards": 4,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện U Minh Thượng",
        "code": "huyen_u_minh_thuong",
        "division_type": "huyện",
        "short_codename": "u_minh_thuong",
        "wards": 6,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Huyện Giang Thành",
        "code": "huyen_giang_thanh",
        "division_type": "huyện",
        "short_codename": "giang_thanh",
        "wards": 5,
        "parentCode": "tinh_kien_giang"
    },
    {
        "name": "Quận Ninh Kiều",
        "code": "quan_ninh_kieu",
        "division_type": "quận",
        "short_codename": "ninh_kieu",
        "wards": 11,
        "parentCode": "thanh_pho_can_tho"
    },
    {
        "name": "Quận Ô Môn",
        "code": "quan_o_mon",
        "division_type": "quận",
        "short_codename": "o_mon",
        "wards": 7,
        "parentCode": "thanh_pho_can_tho"
    },
    {
        "name": "Quận Bình Thuỷ",
        "code": "quan_binh_thuy",
        "division_type": "quận",
        "short_codename": "binh_thuy",
        "wards": 8,
        "parentCode": "thanh_pho_can_tho"
    },
    {
        "name": "Quận Cái Răng",
        "code": "quan_cai_rang",
        "division_type": "quận",
        "short_codename": "cai_rang",
        "wards": 7,
        "parentCode": "thanh_pho_can_tho"
    },
    {
        "name": "Quận Thốt Nốt",
        "code": "quan_thot_not",
        "division_type": "quận",
        "short_codename": "thot_not",
        "wards": 9,
        "parentCode": "thanh_pho_can_tho"
    },
    {
        "name": "Huyện Vĩnh Thạnh",
        "code": "huyen_vinh_thanh",
        "division_type": "huyện",
        "short_codename": "vinh_thanh",
        "wards": 11,
        "parentCode": "thanh_pho_can_tho"
    },
    {
        "name": "Huyện Cờ Đỏ",
        "code": "huyen_co_do",
        "division_type": "huyện",
        "short_codename": "co_do",
        "wards": 10,
        "parentCode": "thanh_pho_can_tho"
    },
    {
        "name": "Huyện Phong Điền",
        "code": "huyen_phong_dien",
        "division_type": "huyện",
        "short_codename": "phong_dien",
        "wards": 7,
        "parentCode": "thanh_pho_can_tho"
    },
    {
        "name": "Huyện Thới Lai",
        "code": "huyen_thoi_lai",
        "division_type": "huyện",
        "short_codename": "thoi_lai",
        "wards": 13,
        "parentCode": "thanh_pho_can_tho"
    },
    {
        "name": "Thành phố Vị Thanh",
        "code": "thanh_pho_vi_thanh",
        "division_type": "thành phố",
        "short_codename": "vi_thanh",
        "wards": 9,
        "parentCode": "tinh_hau_giang"
    },
    {
        "name": "Thành phố Ngã Bảy",
        "code": "thanh_pho_nga_bay",
        "division_type": "thành phố",
        "short_codename": "nga_bay",
        "wards": 6,
        "parentCode": "tinh_hau_giang"
    },
    {
        "name": "Huyện Châu Thành A",
        "code": "huyen_chau_thanh_a",
        "division_type": "huyện",
        "short_codename": "chau_thanh_a",
        "wards": 10,
        "parentCode": "tinh_hau_giang"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 8,
        "parentCode": "tinh_hau_giang"
    },
    {
        "name": "Huyện Phụng Hiệp",
        "code": "huyen_phung_hiep",
        "division_type": "huyện",
        "short_codename": "phung_hiep",
        "wards": 15,
        "parentCode": "tinh_hau_giang"
    },
    {
        "name": "Huyện Vị Thuỷ",
        "code": "huyen_vi_thuy",
        "division_type": "huyện",
        "short_codename": "vi_thuy",
        "wards": 10,
        "parentCode": "tinh_hau_giang"
    },
    {
        "name": "Huyện Long Mỹ",
        "code": "huyen_long_my",
        "division_type": "huyện",
        "short_codename": "huyen_long_my",
        "wards": 8,
        "parentCode": "tinh_hau_giang"
    },
    {
        "name": "Thị xã Long Mỹ",
        "code": "thi_xa_long_my",
        "division_type": "thị xã",
        "short_codename": "long_my",
        "wards": 9,
        "parentCode": "tinh_hau_giang"
    },
    {
        "name": "Thành phố Sóc Trăng",
        "code": "thanh_pho_soc_trang",
        "division_type": "thành phố",
        "short_codename": "soc_trang",
        "wards": 10,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Huyện Châu Thành",
        "code": "huyen_chau_thanh",
        "division_type": "huyện",
        "short_codename": "chau_thanh",
        "wards": 8,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Huyện Kế Sách",
        "code": "huyen_ke_sach",
        "division_type": "huyện",
        "short_codename": "ke_sach",
        "wards": 13,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Huyện Mỹ Tú",
        "code": "huyen_my_tu",
        "division_type": "huyện",
        "short_codename": "my_tu",
        "wards": 9,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Huyện Cù Lao Dung",
        "code": "huyen_cu_lao_dung",
        "division_type": "huyện",
        "short_codename": "cu_lao_dung",
        "wards": 8,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Huyện Long Phú",
        "code": "huyen_long_phu",
        "division_type": "huyện",
        "short_codename": "long_phu",
        "wards": 11,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Huyện Mỹ Xuyên",
        "code": "huyen_my_xuyen",
        "division_type": "huyện",
        "short_codename": "my_xuyen",
        "wards": 11,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Thị xã Ngã Năm",
        "code": "thi_xa_nga_nam",
        "division_type": "thị xã",
        "short_codename": "nga_nam",
        "wards": 8,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Huyện Thạnh Trị",
        "code": "huyen_thanh_tri",
        "division_type": "huyện",
        "short_codename": "thanh_tri",
        "wards": 10,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Thị xã Vĩnh Châu",
        "code": "thi_xa_vinh_chau",
        "division_type": "thị xã",
        "short_codename": "vinh_chau",
        "wards": 10,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Huyện Trần Đề",
        "code": "huyen_tran_de",
        "division_type": "huyện",
        "short_codename": "tran_de",
        "wards": 11,
        "parentCode": "tinh_soc_trang"
    },
    {
        "name": "Thành phố Bạc Liêu",
        "code": "thanh_pho_bac_lieu",
        "division_type": "thành phố",
        "short_codename": "bac_lieu",
        "wards": 10,
        "parentCode": "tinh_bac_lieu"
    },
    {
        "name": "Huyện Hồng Dân",
        "code": "huyen_hong_dan",
        "division_type": "huyện",
        "short_codename": "hong_dan",
        "wards": 9,
        "parentCode": "tinh_bac_lieu"
    },
    {
        "name": "Huyện Phước Long",
        "code": "huyen_phuoc_long",
        "division_type": "huyện",
        "short_codename": "phuoc_long",
        "wards": 8,
        "parentCode": "tinh_bac_lieu"
    },
    {
        "name": "Huyện Vĩnh Lợi",
        "code": "huyen_vinh_loi",
        "division_type": "huyện",
        "short_codename": "vinh_loi",
        "wards": 8,
        "parentCode": "tinh_bac_lieu"
    },
    {
        "name": "Thị xã Giá Rai",
        "code": "thi_xa_gia_rai",
        "division_type": "thị xã",
        "short_codename": "gia_rai",
        "wards": 10,
        "parentCode": "tinh_bac_lieu"
    },
    {
        "name": "Huyện Đông Hải",
        "code": "huyen_dong_hai",
        "division_type": "huyện",
        "short_codename": "dong_hai",
        "wards": 11,
        "parentCode": "tinh_bac_lieu"
    },
    {
        "name": "Huyện Hoà Bình",
        "code": "huyen_hoa_binh",
        "division_type": "huyện",
        "short_codename": "hoa_binh",
        "wards": 8,
        "parentCode": "tinh_bac_lieu"
    },
    {
        "name": "Thành phố Cà Mau",
        "code": "thanh_pho_ca_mau",
        "division_type": "thành phố",
        "short_codename": "ca_mau",
        "wards": 17,
        "parentCode": "tinh_ca_mau"
    },
    {
        "name": "Huyện U Minh",
        "code": "huyen_u_minh",
        "division_type": "huyện",
        "short_codename": "u_minh",
        "wards": 8,
        "parentCode": "tinh_ca_mau"
    },
    {
        "name": "Huyện Thới Bình",
        "code": "huyen_thoi_binh",
        "division_type": "huyện",
        "short_codename": "thoi_binh",
        "wards": 12,
        "parentCode": "tinh_ca_mau"
    },
    {
        "name": "Huyện Trần Văn Thời",
        "code": "huyen_tran_van_thoi",
        "division_type": "huyện",
        "short_codename": "tran_van_thoi",
        "wards": 13,
        "parentCode": "tinh_ca_mau"
    },
    {
        "name": "Huyện Cái Nước",
        "code": "huyen_cai_nuoc",
        "division_type": "huyện",
        "short_codename": "cai_nuoc",
        "wards": 11,
        "parentCode": "tinh_ca_mau"
    },
    {
        "name": "Huyện Đầm Dơi",
        "code": "huyen_dam_doi",
        "division_type": "huyện",
        "short_codename": "dam_doi",
        "wards": 16,
        "parentCode": "tinh_ca_mau"
    },
    {
        "name": "Huyện Năm Căn",
        "code": "huyen_nam_can",
        "division_type": "huyện",
        "short_codename": "nam_can",
        "wards": 8,
        "parentCode": "tinh_ca_mau"
    },
    {
        "name": "Huyện Phú Tân",
        "code": "huyen_phu_tan",
        "division_type": "huyện",
        "short_codename": "phu_tan",
        "wards": 9,
        "parentCode": "tinh_ca_mau"
    },
    {
        "name": "Huyện Ngọc Hiển",
        "code": "huyen_ngoc_hien",
        "division_type": "huyện",
        "short_codename": "ngoc_hien",
        "wards": 7,
        "parentCode": "tinh_ca_mau"
    }
]

export {district}