const initialState = []

const taskCommentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REPLACE_TASK_COMMENT":
             return action.payload

        case "SET_TASK_COMMENT":
            return action.payload
        
        case "CREATE_TASK_COMMENT":
            var temp = []
            temp.push(action.payload)
            return [...temp,...state]

        case "UPDATE_TASK_COMMENT":
            const index = state.findIndex(per => per._id === action.payload._id)
            if(index>-1)
            {
                state[index] = action.payload;
            }
            else
            {

            }
            return [...state]

        case "DELETE_TASK_COMMENT":
            const taskComment_old2 = [...state] 
            var taskComment_deleted = []
           
            if(action.payload.length > 0)
            {
                taskComment_deleted = taskComment_old2.filter(del =>!(action.payload).includes(del._id) );
            }
            return taskComment_deleted
        

        default:
            break;
    }

    return state
}

export default taskCommentReducer