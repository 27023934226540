import * as React from 'react';
import { useState } from 'react';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { teal } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { createTeam } from '../../../actions/team';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import { find } from 'lodash';
const initialState = {
    "code": "",
    "name": "",
    "managerId": "",
    "leaderId": "",
    "memberIdArr": [],
    "isActive": true
};
function AddForm(props) {
    const theme = useTheme()
    const listUser = useSelector(state => state.user)
    const [
        { name, managerId, isActive, code, leaderId, memberIdArr },
        setState
    ] = useState(initialState);
    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };
    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }
    const changeList = (e) => {
        let memberIdArrTemp = [...memberIdArr]
        let value = e.target.value
        let index = memberIdArrTemp.indexOf(value)
        if (index > -1) {
            memberIdArrTemp.splice(index, 1)
        }
        else {
            memberIdArrTemp.push(value)
        }
        setState(state => ({ ...state, memberIdArr: memberIdArrTemp }));
    }
    const removeFromList = (index) => {
        let memberIdArrTemp = [...memberIdArr]
        memberIdArrTemp.splice(index, 1)
        setState(state => ({ ...state, memberIdArr: memberIdArrTemp}));
    }
    const _clearState = () => {
        setState({ ...initialState });
    };
    const handleSubmit = () => {
        let data = { name, managerId, isActive, code, leaderId, memberIdArr }
        props?.createTeam(data)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }
    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8 }}>
            <Stack direction="row" spacing={1}
                justifyContent='space-between'
                alignItems="center" sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.main
                }}>
                <Stack direction={'row'}>
                    <Chip label="Thêm" variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
                    <Typography variant='h5' color={'#fff'}>
                        Đội nhóm
                    </Typography>
                </Stack>
                <Button variant='contained' disableElevation onClick={() => handleSubmit()}>
                    Lưu
                </Button>
            </Stack>
            <Box sx={{ paddingX: 2, paddingY: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color={teal[500]}>
                            Thông tin cơ bản
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Tên"
                                    id="last-name"
                                    value={name}
                                    name="name"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Mã "
                                    id="code"
                                    value={code}
                                    name="code"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label="Kích hoạt" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="demo-select-small">Quản lý</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={managerId || ""}
                                name="managerId"
                                onChange={onChange}
                                label="Quản lý"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {listUser?.map((res, ind) => {
                                    return <MenuItem value={res?._id}>{res?.firstName + ' ' + res?.lastName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="demo-select-small">Trưởng nhóm</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={leaderId || ""}
                                name="leaderId"
                                onChange={onChange}
                                label="Trưởng nhóm"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {listUser?.map((res, ind) => {
                                    return <MenuItem value={res?._id}>{res?.firstName + ' ' + res?.lastName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body' color={teal[500]}>
                            Danh sách thành viên
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ m: 1, minWidth: 360 }} size="small">
                            <InputLabel id="demo-select-small">Nhân viên</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                onChange={changeList}
                                label="Nhân viên"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {listUser?.map((res, ind) => {
                                    return <MenuItem key={ind} value={res?._id}>{res?.firstName + ' ' + res?.lastName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off">
                            {
                                memberIdArr?.map((res, index) => {
                                    let user = find(listUser, { _id: res })
                                    return <Chip
                                        sx={{ ml: 1 }}
                                        label={`${user?.firstName} ${user?.lastName}`}
                                        size='small'
                                        color='primary'
                                        variant="filled" onDelete={() => removeFromList(index)} />
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
const mapDispatchToProps = {
    createTeam
}
export default connect(null, mapDispatchToProps)(AddForm);