import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const History = props => {
    return (
        <>
            <div style={{
                width: '100%', bgcolor: 'background.paper', paddding: 16, overflowY: 'scroll',
                height: ' calc(100vh - 150px)'
            }}>
                {props?.data?.map((res, ind) => {
                    return <Accordion elevation={0} key={ind} sx={{
                        '& .Mui-expanded.MuiAccordionSummary-contentGutters':
                        {
                            margin: '0px!important'
                        }
                    }}>
                        <AccordionSummary
                            sx={{
                                borderBottom: '0.5px solid #d5d5d5',
                                minHeight: '48px!important'
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='title2' fontWeight={'bold'} color="#009688">{ind + 1}.&nbsp; &nbsp;{res?.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ background: '#607d8b0a' }}>
                            <pre style={{ marginTop: 0 }}><code>
                                {res?.description}
                            </code></pre>

                        </AccordionDetails>
                    </Accordion>
                })}
            </div>

            <div style={{
                position: 'absolute',
                bottom: '0px',
                background: '#faf7fc',
                width: '100%',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: 13,
                color: '#1b678c'
            }}>{props?.data?.length}/{props?.totalDocuments} {props?.data?.length < props?.totalDocuments ?
                <>
                    {props?.isLoad === false ?
                        <Button size='small' variant='text' sx={{ textTransform: 'inherit' }}
                            onClick={() => props?.loadMore()}
                            disableElevation >Get more...</Button> : <LoadingButton loading variant="text" >
                            Loading
                        </LoadingButton>
                    }

                </>
                : ''}
            </div>
        </>

    );
};



export default History;