import React from 'react';

const Home = (props) => {
    return (
        <>
            Home

            <a href='/setting'>setting</a>
        </>
    );
};


export default Home;