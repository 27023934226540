import { CloseOutlined } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createReadMoney, getAllData, updateReadMoney } from '../../../actions/readMoney';
import { DocTienLao } from '../../../untils/functions';
import { ccyFormat } from '../../../untils/number';
import DrawerExpand from '../../elements/drawerExpand';
import './style.css';

const Main = (params) => {
    const { props } = params
    const dispatch = useDispatch()
    const [money, setMoney] = useState(0)

    const [description, setDescription] = useState('')
    let listData = [...props?.data]

    useEffect(() => {
        const init = () => {
            props?.getAllData()
        }
        dispatch({
            type: "SET_ENUM",
            payload: {
                navigate: '',
                activeName: '',
                activeModule: ''
            }
        })
        localStorage.removeItem('navigate')
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const DocTien = async () => {
        let tmp = DocTienLao(money)
        if (!isEmpty(tmp)) {
            var data = {
                code: money,
                name: tmp,
                description: description
            }
            props?.createReadMoney(data)
                .then(res => {
                    console.log('createReadMoney', res?.data)
                    setDescription('')
                })
        }
    }
    const updateRead = (it, val) => {
        var data = {
            description: description,
            isCorrect: val
        }
        props?.updateReadMoney(data, it?._id)
            .then(res => {
                console.log('updateReadMoney', res?.data)
                setDescription('')
            })
    }

    console.log({ listData })
    return (
        <>
            <Box sx={{
                p: 6,
                background: 'white',
                marginTop: 2,
                borderRadius: 1,
                boxShadow: ' 2px 2px 6px #9cb8d5',
                marginRight: 3,
                alignItems:'center'
            }}>

                <Grid container spacing={2} >
                    <Grid item xs={2}>
                        <Button variant='contained' fullWidth disableElevation onClick={() => DocTien()}>
                            ACTION
                        </Button>
                        {/* <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            WATING
                        </LoadingButton> */}
                    </Grid>

                    <Grid item xs={8}>
                        <TextField id="outlined-basic"
                            fullWidth
                            label="The number" variant="outlined" onChange={e => { setMoney(e.target.value) }} />
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '32px'}}>
                    {listData?.map((res, index) => {
                      
                        return <div className='itemReadMoney' key={index} style={{
                            borderColor: !isEmpty(res?.isCorrect)? res?.isCorrect === 'true' ? '#009688' : '#E91E63':''
                        }}>
                    <div className='leftMoney'>
                        <Typography fontSize={18} fontWeight={600} color={'#009688'}>
                            {ccyFormat(res?.code)}
                        </Typography>
                        <Typography variant='body2'>
                            {res?.name}
                        </Typography>
                    </div>
                    <div className='rightMoney'>
                        <Typography variant='body2'>{res?.description}</Typography>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <TextField fullWidth variant='outlined'
                                size='small'
                                sx={{ mr: 2 }}
                                onChange={e => {
                                    setDescription(e?.target?.value)
                                }}></TextField>
                            <div style={{ width: 80, display: 'flex' }}>{isEmpty(res?.isCorrect) ?
                                <>
                                    <IconButton aria-label="delete" color='warning' onClick={() => updateRead(res, 'false')}>
                                        <CloseOutlined />
                                    </IconButton>
                                    <IconButton aria-label="delete" color='success' onClick={() => updateRead(res, 'true')}>
                                        <CheckIcon />
                                    </IconButton>
                                </> :
                                <>
                                    {res?.isCorrect === 'true' &&
                                        <Button size='small' variant="contained" color='success' disableElevation>
                                            <CheckIcon /> SUBMIT
                                        </Button>
                                    }
                                    {res?.isCorrect === 'false' &&
                                        <Button size='small' variant="contained" color='warning' disableElevation>
                                            <CloseOutlined /> SUBMIT
                                        </Button>
                                    }
                                </>
                            }</div>
                        </div>

                    </div>
                </div>
                    })}
            </Box>

        </Box >
        </>
    )
}

const ReadTesting = (props) => {
    // const { t } = useTranslation()

    let title = 'Reading Money'
    document.title = title
    return (
        <>
            <DrawerExpand headerText={title}>
                <Main props={props} />
            </DrawerExpand>
        </>
    );
};

const mapDispatchToProps = {
    getAllData, updateReadMoney, createReadMoney
}

const mapStateToProps = (state) => {
    return {
        data: state.readMoney
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadTesting);