import { setTokenAxios } from "../api/index"
import { _showAlertError, _showAlertSuccess } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const getProfile = (token) => {
  return setTokenAxios().get(`user/profile`, {
    headers: {
      "token": token
    }
  })
    .then(async res => {
      return { message: GET_SUCCESS, data: res.data.data }
    })
    .catch((err) => {
      return { message: GET_FAILURE, error: _showAlertError(err) }
    })
}

export const getUserById = (id) => (dispatch) => {
  return setTokenAxios().get(`user/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`user`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_USER",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch(async (err) => {
      await _showAlertError(err)
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataUser = (condition) => (dispatch) => {
  return setTokenAxios().get(`user`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_USER",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch(async (err) => {
      await _showAlertError(err)
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`user`, { params: condition })
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch(async (err) => {
      await _showAlertError(err)
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataByType = (condition) => (dispatch) => {
  return setTokenAxios().get(`user`, { params: condition })
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch(async (err) => {
      await _showAlertError(err)
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const createUser = (data) => (dispatch) => {
  return setTokenAxios().post(`user`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_USER",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateUser = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`user/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_USER",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateUserPermission = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`user/${_id}/user-permission`, data)
    .then(async res => {
      _showAlertSuccess(res?.data?.message)
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getUserPermission = (_id) => (dispatch) => {
  return setTokenAxios().get(`user/${_id}/user-permission`)
    .then(async res => {
      dispatch({
        type: "SET_USER_PERMISSION",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteUser = ( _id) => (dispatch) => {
  return setTokenAxios().delete(`user/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_USER",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}