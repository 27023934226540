import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, useTheme } from '@mui/material';
import DrawerExpand from '../../elements/drawerExpand';
import BreadCrumb from '../../elements/breadCrumb';
import RowMenu from '../../elements/rowMenu';


function createData(stt, name, calories, fat, carbs, protein) {
    return {stt, name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData(1, 'Frozen yoghurt', 'Tele Sale', '+84093276321', 'Quận 10, Thành phố Hồ Chí Minh', 'Đang hoạt động'),
    createData(2, 'Ice cream sandwich', 'Tele Sale', '+84093299888', 'Quận 12, Thành phố Hồ Chí Minh', 'Đang hoạt động'),
    createData(3, 'Eclair', 'Sale', '+8403231329888', 'TP Thủ Đức, Thành phố Hồ Chí Minh', 'Đang hoạt động'),
  ];

const Main = () => {
     const theme = useTheme()
     let breadcrumb = [{name: 'Home', href: '/'}, {name: 'Tiềm năng', href: '/lead'}]
    return (
        <>
        <BreadCrumb data={breadcrumb}/>
        <TableContainer component={Paper} sx={{
            backgroundColor: theme.palette.common.white,
            boxShadow:0,
            padding:2,
            borderRadius:2
        }}>
            <Box sx={{fontSize: 24}}>
                {/* Table of Customer */}
            </Box>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <caption>A basic table example with a caption</caption>
            <TableHead>
              <TableRow sx={{
                fontWeight:'bold'
              }}>
                <TableCell>No.</TableCell>
                <TableCell>Tên</TableCell>
                <TableCell align="right">Phòng ban</TableCell>
                <TableCell align="right">Điện thoại</TableCell>
                <TableCell align="right">Địa chỉ</TableCell>
                <TableCell align="right">Trạng thái</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name} >
                <TableCell align="left">
                  <RowMenu sx={{mr: 2}}/>
                  {row.stt}</TableCell>
                  <TableCell component="th" scope="row" sx={{
                        borderWidth:0.5
                    }}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.calories}</TableCell>
                  <TableCell align="right">{row.fat}</TableCell>
                  <TableCell align="right">{row.carbs}</TableCell>
                  <TableCell align="right">{row.protein}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </>
    )
}

const Lead = (props) => {
  let title = "Tiềm năng"
    document.title = title
    return (
        <>
            <DrawerExpand headerText={title}>
                <Main />
            </DrawerExpand>
        </>
    );
};


export default Lead;