import { animated, useSpring } from '@react-spring/web';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const TopAlert = props => {

    const [isOpen, setIsOpen] = useState(false)
    const openTopAlertRedux = useSelector(state => state.enumRedux.openTopAlert)
    const dispatch = useDispatch()

    useEffect(() => {
        setIsOpen(openTopAlertRedux)
    }, [openTopAlertRedux])

    const handleDialogChange = () => {
        dispatch({
            type: "SET_ENUM",
            payload: {
                openTopAlert: false
            }
        })
    }

    const style = useSpring({
        width: isOpen ? 480 : 0,
        background: isOpen ? 'black' : '#ffff',
        opacity: isOpen ? 1 : 0
    })


    return <>
        {isOpen === true ?
            (<animated.div className={'topDynamicAlert'} style={style}>
                <div style={{color:'#fff'}}>
                    Aha you found me!
                </div>

                <div onClick={() => handleDialogChange(false)} style={{ 
                    cursor:'pointer',
                    width: 36,
                    height: 36,
                    borderRadius: 18,
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    background: '#ea0000',
                 }}>
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.9574 14.1689L8.59651 6.75098L6.73232 8.59598L14.1313 16.071L6.71338 23.4129L8.5964 25.2769L15.9574 17.8779L23.3943 25.2769L25.2392 23.4129L17.8213 16.071L25.2202 8.59598L23.3752 6.75098L15.9574 14.1689Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
            </animated.div>
            ) : null}
    </>
};


export default TopAlert;