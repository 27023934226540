const initialState = []

const productReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_PRODUCT":
            return action.payload
            
        case "CREATE_PRODUCT":
            return [...state, action.payload]

        case "UPDATE_PRODUCT":
            
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            
            return [...state]

        case "UPDATE_PRODUCT_ACTIVE":
            const product_ = [...state] 
            if(action.payload.length > 0)
            {
                (action.payload).forEach((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    product_[index] = item;
                })
            }
            return product_

        case "SET_CURRENT_PRODUCT_IMG":
                return [...state]

        case "DELETE_PRODUCT":
            const product_old = [...state] 
            var product_deleted = []
           
            if(action.payload.length > 0)
            {
                product_deleted = product_old.filter(del =>!(action.payload).includes(del._id) );
            }
            return product_deleted
        

        default:
            break;
    }

    return state
}

export default productReducer