import { makeStyles } from "@material-ui/styles";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { ClickAwayListener, Divider, Fade, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import React, { useEffect } from "react";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useTranslation } from "react-i18next";

/* copied from https://github.com/mui-org/material-ui/blob/v4.3.2/packages/material-ui/src/Menu/Menu.js#L21 */
const useMenuStyles = makeStyles({
  /* Styles applied to the `Paper` component. */
  paper: {
    // specZ: The maximum height of a simple menu should be one or more rows less than the view
    // height. This ensures a tapable area outside of the simple menu with which to dismiss
    // the menu.
    maxHeight: "calc(100% - 96px)",
    // Add iOS momentum scrolling.
    WebkitOverflowScrolling: "touch"
  },
  /* Styles applied to the `List` component via `MenuList`. */
  list: {
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0
  }
});

export default function FakedReferencePopper(props) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleContextMenu(props.anchorEl)
  }, [props.anchorEl])


  const handleContextMenu = (e) => {
    e.preventDefault();
    const { clientX, clientY } = e;
    setOpen(true);
    const virtualElement = {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: clientY,
        right: clientX,
        bottom: clientY,
        left: clientX
      })
    };
    setAnchorEl(virtualElement);
  };

  const handleEdit = () => {
    props?.handleEdit(props?.data, 'edit')
    setAnchorEl(null)
    setOpen(false)
  }

  const handleDel = () => {
    props?.handleDelete(props?.data)
  }

  const handleOpen = () => {
    props?.handleApprove(props?.data)
 
    setOpen(false)
  }

  const id = open ? "faked-reference-popper" : undefined;
  const menuClasses = useMenuStyles();
  return open && <Popper
    id={id}
    open={open}
    anchorEl={anchorEl}
    transition
    sx={{ zIndex: 10000, minWidth:250 }}
    placement={props?.indexRow > 3 ? 'right-end' : 'right-end'}
  >
    {({ TransitionProps }) => (
      <ClickAwayListener onClickAway={handleClose}>
        <Fade {...TransitionProps}>
          <Paper className={menuClasses.paper}>
            <MenuList className={menuClasses.list} autoFocus>

              <MenuItem onClick={handleOpen}>
                <ListItemIcon>
                  <VisibilityOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('lblOpen')}</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                  <ModeEditOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('lblEdit')}</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleDel}> <ListItemIcon>
                <DeleteOutlinedIcon fontSize="small" />
              </ListItemIcon>
                <ListItemText>{t('lblDelete')}</ListItemText>
              </MenuItem>

            </MenuList>
          </Paper>
        </Fade>
      </ClickAwayListener>
    )}
  </Popper>
}
