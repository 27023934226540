import { Box, Tab, Tabs } from '@mui/material';

import React, { useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { connect } from 'react-redux';
import { getAllData as getDataLog, getMore } from '../../../actions/updateLog';
import { getAllDataNoneRedux as getUser } from '../../../actions/user';
import DrawerExpand from '../../elements/drawerExpand';
import History from './History';


function CustomTabPanel(props) {
    const { children, value, index, padding, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: padding || 2, background: '#fff' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Main = (params) => {
    const { props } = params
    const [page, setPage] = React.useState(1);
    const [isLoad, setIsLoad] = React.useState(false);
    const [totalDocuments, setTotalDocuments] = React.useState(0);

    const init = async () => {
        let condition = {
            "condition": {

            },
            "search": '',
            "sort": {
                "created": -1
            },
            "limit": 25,
            "page": page
        }
        await getData(condition)

    }

    const getData = async (condition) => {
        await props?.getDataLog(condition).then(res => {
            setPage(res?.data?.meta?.page)
            setTotalDocuments(res?.data?.meta?.totalDocuments)
        })
    }

    const loadMore = async () => {
        setIsLoad(true)
        let condition = {
            "condition": {

            },
            "search": '',
            "sort": {
                "created": -1
            },
            "limit": 25,
            "page": page + 1
        }


        await props.getMore(condition)
            .then(res => {
                setPage(res?.data?.meta?.page)
                setTotalDocuments(res?.data?.meta?.totalDocuments)
                setIsLoad(false)
            })
    }

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [valueTab, setValueTab] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab label="Historys" {...a11yProps(0)} sx={{ textTransform: 'capitalize!important', fontSize: 14 }} />
                    <Tab label="Features" {...a11yProps(1)} sx={{ textTransform: 'capitalize!important', fontSize: 14 }} />
                </Tabs>
            </Box>
            <CustomTabPanel value={valueTab} index={0} padding={2}>

                <History data={props?.data}
                    totalDocuments={totalDocuments}
                    isLoad={isLoad}
                    loadMore={loadMore} />

            </CustomTabPanel>
            <CustomTabPanel value={valueTab} index={1} padding={2}>
                Item Two
            </CustomTabPanel>
        </Box>

    );
};


const Log = (props) => {
    let title = "History"
    document.title = title

    return (
        <>
            <DrawerExpand headerText={title}>
                <Main props={props} />
            </DrawerExpand>
        </>
    );
};

const mapDispatchToProps = {
    getDataLog, getUser, getMore
}

const mapStateToProps = (state) => {
    return {
        data: state.updateLog
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Log);