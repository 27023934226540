import { Autocomplete, Avatar, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { createDocumentComment, getAllData as getAllDataComment } from '../../../actions/documentComment';
import { getPaymentByParent } from '../../../actions/payment';
import { getRequestByForCode, updateRequest } from '../../../actions/request';
import { getAllDataNoneRedux as getUser } from '../../../actions/user';
import { activeCreditNote } from '../../../actions/creditNote';
import DialogDetailDocument from './DialogDetailDocument';
import { _showAlertError, _showAlertSuccess } from '../../../untils/error';
import { findIndex } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';

const Request = props => {
    const [open, setOpen] = React.useState(false);
    const [isDetail, setIsDetail] = React.useState(false);
    const [dataDetail, setDataDetail] = React.useState(false);
    const [listUser, setListUser] = React.useState([]);
    const [active, setActive] = React.useState({});
    const [queue, setQueue] = React.useState([]);
    const [data, setData] = React.useState({});
    const [userChoose, setUserChoose] = React.useState({});
    const listComment = useSelector(state => state.docComment) || []
    const profile = useSelector(state => state.profile) || {}
    const [content, setContent] = React.useState("")
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenAdd = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAdd = () => {
        setAnchorEl(null);
    };

    const openAdd = Boolean(anchorEl);
    const idAdd = openAdd ? 'simple-popover' : undefined;

    React.useEffect(() => {
        handleOpen()
    }, [props?.isOpen])

    const init = async () => {
        let condition = {
            condition: {
                documentCode: { equal: props?.data?.code }
            },
            sort: {
                created: -1
            }
        }
        await props?.getAllDataComment(condition)
        await props?.getUser(condition).then(res => {
            setListUser(res.data)
        })

    }

    const getRequetByCode = async () => {
        await props?.getRequestByForCode(props?.data?.code)
            .then(res => {
                if (res?.data.length > 0) {
                    let data = { ...res?.data[0] }
                    let active = {}
                    let dataTmp = data?.queue?.map((item, index) => {
                        let status = ''
                        let color = ''
                        if (item?.hasConfirm === true && item?.hasDeny === false) {
                            color = '#009688'
                            status = 'Approved'
                        }
                        if (index > 0 && index < data.queue.length - 1) {
                            if (item?.hasConfirm === false
                                && data.queue[index - 1]?.hasConfirm === true
                                && data.queue[index - 1]?.hasDeny === false
                                && data.queue[index + 1]?.hasConfirm === false) {
                                status = 'Waiting approve'
                                active = item
                                color = '#2196f3'
                            }
                        }
                        if ((index === 0
                            && data.queue[1]?.hasConfirm === false
                            && item?.hasConfirm === false)
                            || (index === data.queue.length - 1
                                && item?.hasConfirm === false
                                && data.queue[index - 1]?.hasDeny === false
                                && data.queue[index - 1]?.hasConfirm === true)) {
                            status = 'Waiting approve'
                            active = item
                            color = '#2196f3'
                        }
                        if ((index === 0
                            &&data?.queue.length === 1
                            && item.hasConfirm === false
                            && item?.hasConfirm === false)
                            || (index === data.queue.length - 1
                                && item?.hasConfirm === false
                                && data.queue[index - 1]?.hasDeny === false
                                && data.queue[index - 1]?.hasConfirm === true)) {
                            status = 'Waiting approve'
                            active = item
                            color = '#2196f3'
                        }
                        if (item?.hasConfirm === true && item?.hasDeny === true) {
                            color = '#e91e63'
                            status = 'Denied'
                            active = item
                        }
                        item.color = color
                        item.status = status
                        return item
                    })
                    setQueue(dataTmp)
                    setActive(active)
                    setData(data)
                }
            })
    }

    React.useEffect(() => {
        init()
        getRequetByCode()
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        setOpen(false);
        props?.closeRequest(false)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleUpdateRequest = (deny = false) => {

        let tmp = { ...active }
        let dt = { ...data }
        tmp.hasConfirm = true
        if (deny === true) {
            tmp.hasDeny = true
        }
        let queTmp = [...queue]
        queTmp[tmp.order - 1] = tmp
        dt.queue = queTmp
        if(tmp?.order === queTmp.length)
        {
            dt.hasConfirm = true
        }
        props?.updateRequest(dt, data?._id)
            .then(res => {
                if (tmp?.order === queTmp.length && deny === false) {
                    props?.activeCreditNote(props?.data?._id, { isActive: true }).then(res => {
                        getRequetByCode()
                        _showAlertSuccess(`${t('alrSuccess')}` || '')
                    }).catch(err => {
                        _showAlertError(`${t('alrError')}` || '')
                    })
                }
                else {
                    getRequetByCode()
                    _showAlertSuccess(`${t('alrSuccess')}` || '')
                }
            })
            .catch(err => {
                _showAlertError(`${t('alrError')}` || '')
            })
    }

    const handleComment = () => {
        props?.createDocumentComment({
            documentCode: props?.data?.code,
            content: content
        })
            .then(res => {
                setContent('')
            })
    }

    const handleDetail = async () => {
        await props?.getPaymentByParent(props?.data?.code).then(res => {
            setDataDetail(res.data)
            setIsDetail(true)
        })
    }

    const handleCloseDetail = () => {
        setIsDetail(false)
    }

    const handleUpdateFollow = (user) => {
        let follow = [...data?.follow]
        let index = findIndex(follow, { _id: user?._id })
        if (index < 0) {
            follow.push({
                _id: user?._id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                code: user?.code,
                userName: user?.userName
            })
            props?.updateRequest({ follow }, data?._id)
                .then(res => {
                    _showAlertSuccess(`${t('alrSuccess')}` || '')
                    getRequetByCode()
                    handleCloseAdd()
                })
                .catch(err => {
                    _showAlertError(`${t('alrError')}` || '')
                })
        }


    }

    const handleDeleteFollow = (item) => {
        let tmp = [...data?.follow]
        let follow = tmp?.map(res => (res?._id !== item?._id))
        props?.updateRequest({ follow }, data?._id)
            .then(res => {
                _showAlertSuccess(`${t('alrSuccess')}` || '')
                getRequetByCode()
            })
            .catch(err => {
                _showAlertError(`${t('alrError')}` || '')
            })
    }

    return (
        <Dialog
            open={open}
            maxWidth='md'
            fullWidth={true}
            scroll='paper'
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >

            <DialogTitle id="alert-dialog-title"
                sx={{
                    background: '#2196f3'
                }}>
                <Box display='flex' justifyContent={"space-between"} alignItems="center" sx={{ color: '#fff' }}>
                    {`${t('frmApproveTitle')} ${data?.name || ''}`}
                </Box>

            </DialogTitle>
            <DialogContent style={{ minHeight: '400px', backgroundColor: '#F7FAFC' }}>
                <Grid container spacing={2} pt={3}>
                    <Grid item xs={8} >
                        <Grid container>
                            <Grid xs={12} item p={2} bgcolor={"#fff"} borderRadius={2}>
                                <Typography variant='subtitle1' fontWeight={600}>{t('frmDescription')}:</Typography>
                                <Typography variant='body' fontStyle='italic'>
                                    {data?.description}
                                </Typography>
                                <Box style={{ fontSize: 12, marginTop: 16 }}>
                                    <Box>
                                        {t('txtCreated')} <span className='aUserName'>@{data?.creater?.userName}</span> {data?.creater?.firstName} {data?.creater?.lastName} at {moment(data?.created).format('DD-MM-YYYY HH:ss')}
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid xs={12} item p={2} bgcolor={"#fff"} borderRadius={2} mt={2}>
                                <Typography variant='subtitle1' fontWeight={600}>{t('frmComment')}:</Typography>
                                <TextField multiline={true} minRows={2}
                                    fullWidth placeholder={t('frmWriteComment')}
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                > </TextField>
                                <Button variant='contained'
                                    onClick={handleComment}
                                    disableElevation style={{ marginTop: 12 }}>{t('frmSend')}</Button>
                                <List sx={{ width: '100%', bgcolor: 'background.paper', paddingY: 0, mt: 1.5 }}>
                                    {listComment.map((res, index) => {
                                        return <ListItem alignItems="flex-start" sx={{ paddingX: 0, pb: 0 }}>
                                            <ListItemAvatar>
                                                <Avatar alt="U" src="/static/images/avatar/1.jpg" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        {res?.creater?.firstName + ' ' + res?.creater?.lastName}
                                                        <span style={{ fontSize: '10px', color: '#808080' }}>
                                                            {moment(res?.created).startOf('hour').fromNow()}
                                                        </span>
                                                    </div>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        {res?.content}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    })}

                                </List>
                            </Grid>

                        </Grid>


                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle1' fontWeight={600} mb={2}>{t('frmWait')}:</Typography>
                        <div className='vertialApprove'>
                            {queue?.map((item, index) => {
                                return <div className='itemApprove' key={index} style={{ color: item?.color }}>
                                    <Typography variant='body'>{item?.userId?.firstName} {item?.userId?.lastName}</Typography>
                                    <Typography variant='subtitle2'>
                                        {item?.status}
                                    </Typography>
                                </div>
                            })}
                        </div>

                        <Typography variant='subtitle1' mt={3} fontWeight={600}>{t('frmFollow')}:</Typography>
                        <div className='rowFollow'>
                            {data?.follow?.map((res, ii) => {
                                return <ListItem alignItems="flex-start" sx={{ paddingX: 0, pb: 0 }}
                                    secondaryAction={
                                        <IconButton
                                            onClick={() => handleDeleteFollow(res)}
                                            edge="end" aria-label="delete">
                                            <DeleteIcon fontSize='small' color='#7798a9' />
                                        </IconButton>
                                    }
                                    key={ii}>
                                    <ListItemAvatar>
                                        <Avatar alt="U" src="/static/images/avatar/1.jpg" sx={{ width: 36, height: 36 }} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '13px' }}>
                                                {res?.firstName + ' ' + res?.lastName}
                                            </div>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                @{res?.userName}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            })}
                            {
                                profile?._id === data?.userCreate ?
                                    <Typography
                                        onClick={handleOpenAdd}
                                        color={'primary'} mt={1} sx={{ cursor: 'pointer' }}>+ {t('frmAdd')}</Typography> :
                                    <></>
                            }
                        </div>

                        <Typography variant='subtitle1' mt={3} fontWeight={600}>{t('frmDocument')}:</Typography>
                        <Typography sx={{ color: '#2196f3', cursor: 'pointer' }} onClick={handleDetail}>{props?.data?.code}</Typography>
                        <Typography variant='subtitle2' sx={{ color: '#aba5a5' }}>@{props?.data?.creater?.userName}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('btnCancel')}</Button>
                {
                    active?.userId?._id === profile?._id ?
                        <>
                            <Button onClick={() => handleUpdateRequest(true)} variant="contained" disableElevation color='error'>
                                {t('btnDeny')}
                            </Button>
                            <Button onClick={()=>handleUpdateRequest(false)} variant="contained" disableElevation color='primary'>
                                {t('btnApprove')}
                            </Button>

                        </> : ''
                }

            </DialogActions>
            {isDetail ? <DialogDetailDocument data={dataDetail}
                dataParent={props?.data}
                isOpen={isDetail} handleClose={handleCloseDetail} /> : <></>}

            <Popover
                id={idAdd}
                open={openAdd}
                anchorEl={anchorEl}
                onClose={handleCloseAdd}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Autocomplete
                    id="country-select-demo"
                    sx={{ width: 270 }}
                    options={listUser}
                    autoHighlight
                    size='small'
                    value={userChoose}
                    getOptionLabel={(option) => option?.firstName + option?.lastName || ''}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{}} {...props}>
                            {option?.userName} - ({option?.firstName})  {option?.lastName}
                        </Box>
                    )}
                    onChange={(event, newValue) => {
                        setUserChoose(userChoose)
                        handleUpdateFollow(newValue)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Popover>
        </Dialog>
    );
};

const mapDispatchToProps = {
    getRequestByForCode, getAllDataComment,
    createDocumentComment, getPaymentByParent,
    activeCreditNote, updateRequest, getUser
}

const mapStateToProps = (state) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Request);