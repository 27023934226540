import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { grey, orange, teal } from '@mui/material/colors';
import { find, findIndex, isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { updateMeetingStage } from '../../../../actions/chance';
import { getAllData as getDemand } from '../../../../actions/demand';
import { getAllData as getOption } from '../../../../actions/language';
import { getAllData as getProject } from '../../../../actions/project';
import { _showAlertErrorMessage } from '../../../../untils/error';

import ChanceNote from './ChanceNote';
import ListNeedCare from './ListNeedCare';


const initData = {

    "hasMet": false,
    "demands": [],
    "projects": [],
    "financialAmount": 0,
    "needCareMore": false,
    "hasOrder": false,
    "additionalAppointment": [],
    "additionalAttributes": {
        "customerName": "customerName",
        "customerAge": 5
    },
    pickupLocation: "", numberParticipants: 1,projectNameInput:'', projectName: '', type: 'normal',
    date: "", location: "", who: "", description: "",
    financialCode:"",
}


const Step2 = React.forwardRef((props, ref) => {
    const minDateTime = new Date();
    minDateTime.setHours(17);
    const maxDateTime = new Date();
    maxDateTime.setHours(20);

    const dialogRef = useRef(null)
    const editObject = useSelector(state => state.edit)
    const options = useSelector(state => state.option)

    const [
        { hasMet, needCareMore, hasOrder, financialAmount, additionalAppointment, demands, projects,
            pickupLocation, numberParticipants, projectName, projectNameInput, type, financialCode,
            date, location, who, description },
        setState
    ] = useState({ ...initData });

    useEffect(() => {

        const init = async () => {
            await props?.getDemand()
            await props?.getProject()
            let params = {
                "condition": {
                    "type": {
                        "equal": "financialAssessment"
                    }
                },
                "sort": {
                    "created": 1
                },
                "limit": 10,
                "page": 1
            };
            props?.getOption(params)
        }
        const stateChange = () => {
            let { hasMet, needCareMore, hasOrder, financialAmount, additionalAppointment, demands, projects } = { ...editObject?.meetingStage }
            setState(state => ({
                ...state,
                hasMet, needCareMore, hasOrder, financialAmount, additionalAppointment, demands, projects
            }))
        }
        init()
        stateChange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };



    const handleSubmit = () => {
        let data = {
            meetingStage: {},
            // stage:'communication'
        }

        data.meetingStage.financialAmount = financialAmount

        if (hasMet) {
            data.meetingStage.hasMet = hasMet
        }
        if (needCareMore) {
            data.meetingStage.needCareMore = needCareMore
        }
        if (hasOrder) {
            data.meetingStage.hasOrder = hasOrder
        }
        if (!isEmpty(additionalAppointment)) {
            data.meetingStage.additionalAppointment = additionalAppointment
        }

        if (!isEmpty(demands)) {
            data.meetingStage.demands = demands
        }

        if (!isEmpty(projects)) {
            data.meetingStage.projects = projects
        }
        // console.log({data})
        if (data?.meetingStage) {
            props?.updateMeetingStage(editObject?._id, data, { ...editObject })
        }

    }

    const chooseMultiValue = (name, value) => {
        switch (name) {
            case 'demands':
                let tmp = [...demands]
                let indExist = findIndex(tmp, { code: value })
                if (indExist > -1) {
                    tmp.splice(indExist, 1)
                }
                else {
                    let demand = find(props?.demand, { code: value })
                    tmp.push({ code: value, name: demand?.name })
                }
                setState(state => ({ ...state, demands: tmp }))
                break;

            case 'projects':
                let tmp1 = [...projects]
                let indExist1 = findIndex(tmp1, { code: value })
                if (indExist1 > -1) {
                    tmp1.splice(indExist1, 1)
                }
                else {
                    let project = find(props?.project, { code: value })
                    tmp1.push({ code: value, name: project?.name })
                }
                setState(state => ({ ...state, projects: tmp1 }))
                break;

            default:
                break;
        }
    }


    const addAppoinmentNeedCare = () => {
        let appoint = {
            "type": type,
            "date": date,
            "location": location,
            "pickUpLocation": pickupLocation,
            "who": who,
            "description": description,
            "numberParticipants": numberParticipants,
            "projectName": projectName,
            "isCustomerCome": false
        }

        if (isEmpty(type)) {
            _showAlertErrorMessage('Chưa chọn phân loại hẹn')
        }

        let tmpArr = [...additionalAppointment]
        tmpArr.push(appoint)
         setState(state => ({ ...state, additionalAppointment: tmpArr, projectNameInput:'', projectName:'', location:'', who:'', numberParticipants:1, description:'' }))

    }

    const customeOnChange = (name, value, item) => {
        setState(state => ({ ...state, [name]: value, financialAmount: item?.value?.to || 0 }));
    }

    return (
        <Box component={"div"} sx={{ minHeight: 450, padding: 2 }}>
            <Grid container spacing={2}>


                <Grid container sx={{ backgroundColor: grey[50], padding: 2, mt: 3 }}>
                    <Grid item xs={6} sx={{ padding: 0, mt: 1.5, mb: 1.5 }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name="hasMet"
                                value={hasMet}
                                checked={hasMet ? true : false}
                                onChange={(e) => setState(state => ({ ...state, hasMet: !hasMet }))}
                            />} label="Đã gặp mặt ở cuộc hẹn" />

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { mb: 0, mt: 2, width: '35ch', background: '#fff' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    label="Tài chính"
                                    id="last-finance"
                                    value={financialAmount}
                                    InputLabelProps={{ shrink: true }}
                                    name="financialAmount"
                                    onChange={onChange}
                                    size="small"
                                />
                                {/* <Typography variant='body' sx={{ display: 'flex', mt: 0.5, fontSize: '0.8em', width: '100%' }}>
                                    ~{ccyFormat(financialAmount || 0)}
                                </Typography> */}
                            </Box>
                            <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                marginLeft: 1.5,
                                '& .MuiTextField-root': { m: 1, width: '85ch' },
                            }}
                            noValidate
                            autoComplete="off" >

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Đánh giá tài chính</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    value={financialCode}
                                    name="financialCode"
                                >
                                    {options?.map((res, ind) => {
                                        return <FormControlLabel value={res.code} key={ind}
                                            control={<Radio onChange={(e) => customeOnChange('financialCode', e.target.value, res)} />}
                                            label={`${res.name}`} />
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>


                            <FormControl sx={{
                                mb: 1.5, mt: 3.5, width: '35ch',
                                '& .MuiInputBase-root': {
                                    background: '#fff'
                                }
                            }} size="small">
                                <InputLabel id="demo-select-small">Dự án</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value=""
                                    onChange={(e) => chooseMultiValue('projects', e.target.value)}
                                    label="Dự án"
                                >
                                    <MenuItem value="" >Chọn dự án quan tâm...</MenuItem>
                                    {props?.project?.map((res, ind) => {
                                        return <MenuItem value={res.code} key={ind}>{res.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                            {projects?.length > 0 ?
                                <Box sx={{ display: 'flex', mt: 1.5, mb: 2 }}>
                                    <Box>
                                        {
                                            projects?.map((res, index) => {
                                                return <Chip
                                                    sx={{ ml: 1 }}
                                                    label={`${res?.name}`}
                                                    size='small'
                                                    color='primary'
                                                    variant="filled" onDelete={() => chooseMultiValue('projects', res.code)} />
                                            })
                                        }
                                    </Box>
                                </Box> : <span></span>}

                            <Box
                                component="form"
                                sx={{
                                    m: 1, mt: 2, width: '100%'
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Đánh giá nhu cầu</FormLabel>

                                    <FormGroup aria-label="position" row>
                                        {props?.demand?.map((res, ind) => {
                                            return <FormControlLabel value={res.code} key={ind}
                                                checked={findIndex(demands, { code: res.code }) > -1 ? true : false}
                                                control={<Checkbox onChange={(e) => chooseMultiValue('demands', e.target.value)} />}
                                                label={`${res?.name}`} />
                                        })}
                                    </FormGroup>
                                </FormControl>
                            </Box>

                            <FormControl sx={{ minWidth: 360, m: 1, mt: 2 }}>
                                <FormLabel id="demo-row-radio-buttons-group-label">Kết quả</FormLabel>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel value={true} control={<Radio
                                        checked={hasOrder ? true : false}
                                        onChange={(e) => setState(state => ({ ...state, hasOrder: true, needCareMore: false }))}
                                    />} label="Có đơn hàng (*)" />
                                    <FormControlLabel value={true} control={<Radio
                                        checked={needCareMore ? true : false}
                                        onChange={(e) => setState(state => ({ ...state, hasOrder: false, needCareMore: true }))}
                                    />} label="Chăm sóc thêm" />
                                </FormGroup>
                            </FormControl>

                            {/*   <FormControlLabel control={<Checkbox name="hasAppointment"
                                value={true}
                                checked={hasAppointment?true:false}

                                disabled={!hasInfoZalo || !hasInfoCall}
                                onChange={(e) => setState(state => ({ ...state, hasAppointment: e.target.value }))}
                            />} label="Khách đồng ý hẹn gặp mặt" /> */}
                        </FormGroup>
                        {/* {hasAppointment ?
                            <>
                                <Typography variant='subtitle1' sx={{ mt: 2, color: colors.teal[500] }}>Thông tin cuộc hẹn</Typography>
                                <Stack direction="column">
                                    <Typography variant='subtitle2'>- <b>Thời gian:</b> {moment(appointment?.date).format('DD/MM/YYYY HH:mm') || '...'} </Typography>
                                    <Typography variant='subtitle2'>- <b>Địa điểm:</b> {appointment?.location || '...'} </Typography>
                                    <Typography variant='subtitle2'>- <b>Người liên hệ:</b> {appointment?.who || '...'} </Typography>
                                    <Typography variant='subtitle2'>- <b>Ghi chú:</b> {appointment?.description || '...'} </Typography>
                                </Stack></> : <span></span>} */}
                        <Button variant="contained" disableElevation
                            sx={{ mt: 3 }} onClick={() => handleSubmit()}
                        >Cập nhật</Button>
                    </Grid>
                    {/* {needCareMore ? */}
                    <Grid item xs={6} sx={{ padding: 0, mt: 1.5 }}>
                        <FormControl sx={{ minWidth: 360, m: 1, mt: 2 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Phân loại hẹn</span>
                                <Box sx={{ color: additionalAppointment?.length === 0 ? teal[500] : orange[500], cursor: 'pointer' }}
                                    onClick={() => {
                                        dialogRef.current?.openDialog()
                                    }}
                                >Xem chi tiết ({additionalAppointment?.length})</Box>
                            </FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel value={true} control={<Radio
                                    checked={type === "normal" ? true : false}
                                    onChange={(e) => setState(state => ({ ...state, type: 'normal' }))}
                                />} label="Cuộc hẹn" />
                                <FormControlLabel value={true} control={<Radio
                                    checked={type === "project" ? true : false}
                                    onChange={(e) => setState(state => ({ ...state, type: 'project' }))}
                                />} label="Xem dự án" />
                            </FormGroup>
                        </FormControl>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                label="Thời gian"
                                id="last-time"
                                value={date}
                                name="date"
                                type="datetime-local"
                                inputProps={{
                                    min: minDateTime.toISOString().slice(0, 16),
                                    // max: maxDateTime.toISOString().slice(0, 16)
                                }}
                                InputLabelProps={{ shrink: true }}
                                onChange={onChange}
                                size="small"
                            />
                        </Box>

                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">

                            {/* <TextField
                                    label="Dự án"
                                    id="last-location-project"
                                    value={projectName}
                                    name="projectName"
                                    onChange={onChange}
                                    size="small"
                                /> */}

                            <FormControl sx={{
                                m:1, mb: 1.5, mt: 2, width: '35ch',
                                '& .MuiInputBase-root': {
                                    background: '#fff'
                                }
                            }} size="small">
                                <InputLabel id="demo-select-small">Dự án</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={projectNameInput}
                                    onChange={(e) => {
                                        setState(state=>({...state, 
                                            projectName: find(props?.project,{code:e.target.value})?.name,
                                            projectNameInput: e.target.value,
                                            location: find(props?.project,{code:e.target.value})?.address
                                        }))
                                    }}
                                    label="Dự án"
                                >
                                    <MenuItem value="" >Chọn dự án quan tâm...</MenuItem>
                                    {props?.project?.map((res, ind) => {
                                        return <MenuItem value={res.code} key={ind}>{res.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                        </Box>



                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label="Địa điểm"
                                id="last-location"
                                value={location}
                                name="location"
                                onChange={onChange}
                                size="small"
                            />

                        </Box>

                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label="Địa điểm đón"
                                id="last-location-address"
                                value={pickupLocation}
                                name="pickupLocation"
                                onChange={onChange}
                                size="small"
                            />
                        </Box>

                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">
                            <TextField
                                label="Người hẹn"
                                id="last-who"
                                value={who}
                                name="who"
                                onChange={onChange}
                                size="small"
                            />
                        </Box>

                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                label="Ghi chú"
                                id="last-description"
                                value={description}
                                name="description"
                                onChange={onChange}
                                size="small"
                            />
                        </Box>




                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '45%', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label="Quantity: tham gia"
                                id="last-location-numberParticipants"
                                value={numberParticipants}
                                name="numberParticipants"
                                onChange={onChange}
                                size="small"
                            />

                            <Button variant='contained' sx={{ mt: 2.2, width: '40%' }}
                                onClick={() => addAppoinmentNeedCare()}
                                color={'primary'} disableElevation>Thêm</Button>
                        </Box>


                    </Grid>
                    {/* :
                        <span></span>
                    } */}

                </Grid>



                <Grid item xs={12}>
                    {props?.activeStep === 2 &&
                        <ChanceNote stage={props?.stage}></ChanceNote>
                    }
                </Grid>
            </Grid>
            {additionalAppointment.length > 0 && <ListNeedCare ref={dialogRef} data={additionalAppointment} />}
        </Box>
    );
});

const mapDispatchToProps = {
    updateMeetingStage, getDemand, getProject, getOption
}


const mapStateToProps = (state) => {
    return {
        demand: state.demand,
        project: state.project
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step2);