import React from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const AlertConfirm = (data, open = null, close = null) =>{
  confirmAlert({
    customUI: ({ onClose }) => {
        return (
          <div className='custom-ui-noticonfirm'>
            <h1>Thông báo</h1>
            <p>{data}</p>
            <button className="btnNo" onClick={()=>onClose()}>OK</button>
          </div>
        );
      }
  })
}
export {AlertConfirm};