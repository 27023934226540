import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import _ from "lodash";
import React, { Component } from 'react';
import { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import * as taskAction from "../../../actions/task";

import moment from 'moment';
import 'moment/locale/vi';

import ScheduleIcon from '@material-ui/icons/Schedule';
import { AddOutlined } from '@mui/icons-material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import { Button, Typography } from '@mui/material';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
import config from '../../../config/index';
import Comment from './Comment';
import i18next from 'i18next';

registerLocale('vi', vi)

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }


});


class TaskDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: 'paper',
      open: false,
      name: "",
      modules: "",
      labelWidth: 0,
      isActive: false,
      memberList: [],
      error: {},
      parentCode: "null",
      deadline: new Date(),
      description: "",
      subTaskId: "",
      comment: "",
      openComment:false
    }
  }

  componentDidMount() {

  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  createSubTask = () => {
    var data = {
      taskId: this.props.taskDetail._id,
      name: this.state.newSubTask,
      description: this.state.description
    }

    this.props.createSubTask(data)
      .then(res => {
        this.setState({ name: "", description: "" })
      })
  }

  deleteTask = (item) => {
    this.props.deleteSubTask(item._id)
  }

  makeComplete = (item) => {
    this.props.completeSubTask({ subTaskId: item._id, isComplete: true })
  }

  getComment = (item) => {
    this.props.getTaskCommentBySubTask(item._id)
    this.setState({ subTaskId: item._id, openComment: true })
  }

  handleCloseComment = () => {
    this.setState({openComment:false})
  }

  render() {
   
    const { taskDetail } = this.props
    return (
      <React.Fragment>
        {_.isEmpty(taskDetail) === false && <div className="taskDetail">
          <div className="topTaskDetail">
            <div className="titleTaskDetail">{taskDetail.name}
              <div className="editedFast" onClick={() => this.props.edit(taskDetail)}>
                {i18next.t('lblEdit')}
              </div>
            </div>
            <div className="toolTaskDetail">
              <div className="timeTask"><div className="icoLeft"><ScheduleIcon style={{ fontSize: 12 }} /></div>{moment(taskDetail.timeStart).format('l')}</div>
              {/* <div className="createdBy"><div className="icoLeft"><PersonIcon style={{fontSize:12}}/></div>Đồng Thiện Trưởng</div> */}
            </div>
            <div className="briefTaskDetail">
              <textarea readOnly={true}
                style={{
                  width: '100%', border: '1px dashed #cccccc00', borderRadius: '5px',
                  padding: ' 8px 12px', height: 75, overflowY: 'scroll', resize: 'none'
                }}
                placeholder="Mô tả" value={taskDetail.description}>


              </textarea>
            </div>
            <div className="memberTaskDetail">
              {_.get(taskDetail, 'assignedUsers', []).map((dt, index) => {
                return <Tooltip title={`${_.get(dt, 'userId.firstName')} ${_.get(dt, 'userId.lastName')}`} key={`assign${index}`}>
                  <div className="itemMember">
                    {dt.isMain === true && <div className="ownerRomm"><img src="/resources/icons/crown.png" alt="crown" /></div>}
                    <img alt="" src={`${config.baseURL}${_.get(dt, 'userId.fileAvatar.path', '')}`} />
                  </div>
                </Tooltip>
              })}
            </div>
          </div>
          <div className="bodyTaskDetail">
            <div className="subTaskDetail">
              <div className="titSubTask">
                {i18next.t('frmTaskSubLst')}
              </div>
              <ul className="listSubTask">
                {_.get(taskDetail, 'subTasks', []).map((dt, index) => {
                  return <li className={`${dt.isComplete === true && 'finshed'}`} key={`sub${index}`}>
                    <div className="chkComplete">
                      {index + 1}.
                    </div>
                    <div className="ctSub">
                      <label className="contentSubTask">{dt.name}</label>
                      <div style={{
                        fontSize: 10,
                        color: '#009688'
                      }}>{moment(dt?.created).format('DD/MM/YYYY HH:mm')}{dt?.isComplete ? ' - ' + moment(dt?.updated).format('DD/MM/YYYY HH:mm') : ''}</div>
                      <p>{dt.description}</p>
                      <div className="subTaskTool">
                        <div className="itemSub" onClick={() => this.getComment(dt)}>
                          <ModeCommentOutlinedIcon sx={{ fontSize: 16, color: '#fff' }} />
                        </div>
                        {dt.isComplete === false && <div className="itemSub" onClick={() => this.makeComplete(dt)}>
                          <CheckOutlinedIcon sx={{ fontSize: 16, color: '#fff' }} />
                        </div>}
                        {dt.isComplete === false && <div className="itemSub" onClick={() => { if (window.confirm('Bạn có chắc chắn muốn xóa dữ liệu không?')) { this.deleteTask(dt) } }}>
                          <DeleteOutlinedIcon sx={{ fontSize: 16, color: '#fff' }} />
                        </div>}
                      </div>
                    </div>
                  </li>
                })}
              </ul>

              {!this.state.isEdit && <Button variant='contained'
                onClick={() => this.setState({ isEdit: true })}
                disableElevation sx={{
                  background: '#009688',
                  textTransform: 'inherit',
                  '&:hover': {
                    background: '#037267'
                  }
                }} startIcon={<AddOutlined />}>
                Add
              </Button>}

              {this.state.isEdit && <div className="addSubTask">
                <Typography sx={{
                  fontWeight: 'bold',
                  marginBottom: '12px',
                  color: '#009688'
                }}> {i18next.t('frmTaskSubTit')}</Typography>
                 <div className="inputSubLeft">
                            <input className="contentSubTaskInput" value={this.state.newSubTask} name="newSubTask" placeholder={i18next.t('frmName')} onChange={this.onChange} />
                            <textarea className="contentSubTaskInput desc" value={this.state.descriptionSubTask} placeholder={i18next.t('frmDescription')} name="descriptionSubTask" onChange={this.onChange}> </textarea>
                          </div>
                <div className="btnSubTask">
                  <span onClick={() => this.createSubTask()} style={{ color: "#fff", background: '#009688' }}>
                  {i18next.t('btnCreate')}
                  </span>
                  <span onClick={() => this.setState({ isEdit: false })} style={{ color: '#fff', background: '#E91E63' }}>
                  {i18next.t('btnClose')}
                  </span>
                </div>
              </div>}
            </div>
            {this.state.subTaskId !== "" &&
              <Comment openComment={this.state.openComment} closeComment={this.handleCloseComment} subTaskId={this.state.subTaskId}/>
            }

          </div>
        </div>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAll: state.user,
    profile: state.profile,
    taskDetail: state.taskDetail,
    taskComment: state.taskComment
  }
}


export default withStyles(styles)(connect(mapStateToProps, { ...taskAction })(TaskDetail));