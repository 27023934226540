import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import { Typography, useTheme, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { connect, useSelector } from 'react-redux';
import { updateChance } from '../../../actions/chance';
import { getUserById, getAllDataNoneRedux as getUser } from '../../../actions/user'
import { getAllDataNoneRedux as getTeam } from '../../../actions/team'

import { _showAlertSuccess } from '../../../untils/error';
import { GET_SUCCESS } from '../../../enums/vi';
import { map, uniqBy } from 'lodash';

const initialState = {
    saleId: ''
}

function AssignForm(props) {
    const theme = useTheme()
    const editObject = useSelector(state => state.edit)
    const [sale, setSale] = useState([])
    const profile = useSelector(state => state.profile)


    const [
        { saleId },
        setState
    ] = useState(initialState);

    useEffect(() => {

        const fetchData = async () => {

            if (profile?.userType === 'root') {
                await props?.getUser({
                    condition: {
                        userType: {
                            notIn: ['leader']
                        }
                    }, limit: 200
                })
                    .then(async res => {
                        let sale = map(res?.data)
                        setSale(sale)
                    })
                    .catch(err => {
                        console.log({ err })
                    })
            }
            else {
                await props?.getUserById(profile?._id)
                    .then(async res => {
                        let leader = res?.data?.telesale?.leaders || []
                        if(profile?.userType === 'leader')
                        {
                            leader.push(profile)
                        }
                        await props?.getTeam({
                            condition: {
                                leaderArr: map(leader,'_id')
                            }, limit: 100
                        }).then(res1 => {
                            let sale = []
                            res1?.data?.forEach(sal => {
                                sale = [...sale, ...sal.memberArr]
                            })
                            sale.push(profile)
                            setSale(uniqBy(sale, '_id'))
                        })
                    })
                    .catch(err => {
                        console.log({ err })
                    })
            }

        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };


    const handleSubmit = () => {
        if (saleId) {
            let data = { assignedUserId: saleId }
            props?.updateChance(data, editObject?._id)
                .then(res => {
                    _showAlertSuccess(res?.message || GET_SUCCESS)
                })
        }
        else {
            alert("Vui lòng chọn nhân viên phụ trách")
        }

    }

    return (
        <Box sx={{
            bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8,
            paddingBottom: 4,
           
        }}>
            <Stack direction="row" spacing={1}
                justifyContent='space-between'
                alignItems="center" sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.main
                }}>
                <Stack direction={'row'}>
                    <Chip label="Assign" variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
                    <Typography variant='h5' color={'#fff'}>
                        Cơ hội
                    </Typography>
                </Stack>
                <Button variant='contained' disableElevation onClick={() => handleSubmit()}>
                    Lưu
                </Button>
            </Stack>

            <Box sx={{ paddingX: 2, paddingY: 4 }}>


              

                <FormControl sx={{ m: 1, minWidth: '95%' }} size="small">
                            <InputLabel id="demo-select-partner">Nhân viên</InputLabel>
                            <Select
                                labelId="demo-select-partner"
                                id="demo-select-partner"
                                value={saleId}
                                name="saleId"
                                fullWidth={true}
                                onChange={onChange}
                                label="Chọn nhân viên"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {sale?.map((res, ind)=>{
                                    return  <MenuItem key={ind} value={res._id}>{res.firstName} {res.lastName}</MenuItem>
                                })} 
                                
                            </Select>
                        </FormControl> 
            </Box>
        </Box>
    );
};

const mapDispatchToProps = {
    updateChance, getUserById, getUser, getTeam
}

const mapStateToProps = (state) => {
    return {
        data: state.edit
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignForm);