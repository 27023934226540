import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { findIndex, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  getChanceById, updateCommunicateStage, updateMeetingStage,
  updateBookingStage, updateDepositStage, updateContractStage, updateDoneStage
} from '../../../../actions/chance';
import { DATA_NOT_FOUND } from '../../../../enums/vi';
import { _showAlertErrorMessage } from '../../../../untils/error';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import { Alert, Snackbar } from '@mui/material';



// const initialState = {
//   stage: "",
//   isActive: "",
//   customerId: "",
//   leaderId: "",
//   assignedUserId: '',
//   description: '',
//   code: '',
//   userCreate: '',
//   trackers:[],
//   userUpdate:'',
//   communicationStage:{},
//   meetingStage:{},
//   bookingStage:{},
//   depositStage:{},
//   contractStage:{},
//   doneStage:{},
//   customer:{},
//   assignedUser:{},
//   leader:{}
// };

const steps = [{
  code: 'notStart',
  name: 'Khởi động',
},
{
  code: 'communication',
  name: 'Liên hệ',
},
{
  code: 'meeting',
  name: 'Gặp mặt',
},
{
  code: 'booking',
  name: 'Booking',
},
{
  code: 'deposit',
  name: 'Đặt cọc',
},
{
  code: 'contract',
  name: 'Hợp đồng',
},
{
  code: 'done',
  name: 'Hoàn thành',
}];

function StepperOp(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const editObject = useSelector(state => state.edit)
  const [open, setOpen] = React.useState(false);
  const [
    { _id, communicationStage, meetingStage, bookingStage,
      depositStage, contractStage, doneStage },
    setState
  ] = useState({
    ...editObject,
    communicationStage: editObject?.communicationStage || {},
    meetingStage: editObject?.meetingStage || {},
    bookingStage: editObject?.bookingStage || {},
    depositStage: editObject?.depositStage || {},
    contractStage: editObject?.contractStage || {},
    doneStage: editObject?.doneStage || {}
  });

  const getDataById = () => {
    props?.getChanceById(_id).then(res => {
      let active = findIndex(steps, { code: res?.data?.stage })
      setActiveStep(active || 0)
      setState(state => ({ ...state, ...res.data }))
    }).catch(err => {
      return _showAlertErrorMessage(DATA_NOT_FOUND)
    })
  }

  useEffect(() => {
    const init = async () => {
      await getDataById()
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const isStepOptional = (step) => {
    return step === 1;
  };


  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const checkStepNext = () => {
    let currentStep = findIndex(steps, { code: editObject.stage })

    if (activeStep < currentStep) {
      handleNext()
    }
    else {
      checkValidateNext()
    }
  }


  const actionNext = (data) => {
    if (!isEmpty(data?.stage) && data?.stage === 'meeting') {
      props?.updateCommunicateStage(editObject?._id, data)
        .then(res => {
          handleNext()
        })
    }
    if (!isEmpty(data?.stage) && data?.stage === 'booking') {
      props?.updateMeetingStage(editObject?._id, data)
        .then(res => {
          handleNext()
        })
    }
    if (!isEmpty(data?.stage) && data?.stage === 'deposit') {
      props?.updateBookingStage(editObject?._id, data)
        .then(res => {
          handleNext()
        })
    }

    if (!isEmpty(data?.stage) && data?.stage === 'contract') {
      props?.updateDepositStage(editObject?._id, data)
        .then(res => {
          handleNext()
        })
    }

    if (!isEmpty(data?.stage) && data?.stage === 'done') {
      props?.updateContractStage(editObject?._id, data)
        .then(res => {
          handleNext()
        })
    }


  }



  const checkValidateNext = () => {
    switch (activeStep) {
      case 1:
        let communicationStage = { ...editObject?.communicationStage }
        if (isEmpty(communicationStage?.appointment) ||
          !isEmpty(communicationStage?.hasInfoZalo) ||
          !isEmpty(communicationStage?.hasInfoCall)) {
          _showAlertErrorMessage('Chưa hoàn thành các thông tin theo yêu cầu!');
        }
        else {
          let data = { ...editObject }
          data.communicationStage.additionalAttributes = {}
          data.stage = 'meeting'
          actionNext(data)
        }
        break;

      case 2:

        let meetingStage = { ...editObject?.meetingStage }
        if (!meetingStage?.hasOrder) {
          _showAlertErrorMessage('Chưa hoàn thành các thông tin theo yêu cầu!');
        }
        else {
          let data = { ...editObject }
          data.meetingStage.additionalAttributes = {}
          data.stage = 'booking'
          actionNext(data)
        }
        break;

      case 3:

        let bookingStage = { ...editObject?.bookingStage }
        if (!bookingStage?.bookings) {
          _showAlertErrorMessage('Chưa hoàn thành thông tin booking');
        }
        else {
          let data = { ...editObject }
          data.bookingStage.additionalAttributes = {}
          data.stage = 'deposit'
          actionNext(data)
        }
        break;

      case 4:

        let depositStage = { ...editObject?.depositStage }
        if (!depositStage?.deposits) {
          _showAlertErrorMessage('Chưa hoàn thành thông tin cọc');
        }
        else {
          let data = { ...editObject }
          data.depositStage.additionalAttributes = {}
          data.stage = 'contract'
          actionNext(data)
        }
        break;

      case 5:

        let contractStage = { ...editObject?.contractStage }
        if (!contractStage?.signedContract) {
          _showAlertErrorMessage('Chưa ký hợp đồng');
        }
        else {
          let data = { ...editObject }
          data.contractStage.additionalAttributes = {}
          data.stage = 'done'
          actionNext(data)
        }

        break;

      case 6:

        break;

      default:
        return false
    }
  }


  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <Step0 stage={'notStart'} activeStep={activeStep} />

      case 1:
        return <Step1 data={communicationStage} stage={'communication'} activeStep={activeStep} />

      case 2:
        return <Step2 data={meetingStage} stage={'meeting'} activeStep={activeStep} />

      case 3:
        return <Step3 data={bookingStage} stage={'booking'} activeStep={activeStep} />

      case 4:
        return <Step4 data={depositStage} stage={'deposit'} activeStep={activeStep} />

      case 5:
        return <Step5 data={contractStage} stage={'contract'} activeStep={activeStep} />

      case 6:
        return <Step6 data={doneStage} stage={'done'} activeStep={activeStep} />

      default:
        return <div>Empty</div>
    }
  }


  const handleFailBooking = () => {
    let data = { ...editObject }
    data.doneStage = {result:'fail'}
    data.bookingStage.additionalAttributes = {}
    data.stage = 'done'
    if (data?.doneStage) {
      props?.updateBookingStage(editObject?._id, data)
      .then(res=>{
        setActiveStep(6);
      })
    }
  }

  const handleFailDeposit = () => {
    let data = { ...editObject }
    data.doneStage = {result:'fail'}
    data.depositStage.additionalAttributes = {}
    data.stage = 'done'
    if (data?.depositStage) {
      props?.updateDepositStage(editObject?._id, data).then(res=>{
        setActiveStep(6);
      })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Box component={"div"} sx={{ width: '100%', mt: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((item, index) => {
          let label = item?.name
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            // labelProps.optional = (
            //   <Typography variant="caption">Optional</Typography>
            // );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>

          {renderStep()}

          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}


          <Box component={"div"} sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {activeStep !== 0 ?
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Trở lại
              </Button> : <span></span>}
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Bỏ qua
              </Button>
            )} */}

            {/* {editObject?.stage !== 'notStart' ? <Button onClick={checkStepNext} variant="outlined">
              {activeStep === steps.length - 1 ? 'Hoàn thành' : 'Tiếp tục'}
            </Button> : <span></span>} */}
            {(editObject?.stage === 'deposit' || editObject?.stage === 'booking') &&
              <Button variant="contained" color="error" disableElevation
                sx={{ mr: 3 }} onClick={() => setOpen(true)}
              >Thất bại</Button>}

            {editObject?.stage !== 'notStart' ? activeStep < steps.length - 1 && <Button onClick={checkStepNext} variant="outlined">
              Tiếp tục
            </Button> : <span></span>}
          </Box>
        </React.Fragment>
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Bạn có chắc đánh giá thất bại!  <div>
           
              {
                (editObject?.stage === 'deposit')?<Button color="error" onClick={handleFailDeposit}>Có</Button>:
                 <Button color="error" onClick={()=> handleFailBooking()}>Có</Button>
            }
            <Button onClick={handleClose}>Không</Button>
          </div>
        </Alert>

      </Snackbar>
    </Box>
  );
}


const mapDispatchToProps = {
  getChanceById, updateCommunicateStage,
  updateMeetingStage, updateBookingStage,
  updateDepositStage, updateContractStage,
  updateDoneStage
}

export default connect(null, mapDispatchToProps)(StepperOp);