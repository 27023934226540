import _ from 'lodash';
import moment from 'moment';
import React, { memo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getLastestMessage, getMessageGroupByCode } from "../../../actions/chat";
import { emitSeenMessage } from '../../../actions/socketInside';
import config from '../../../config/index';

const LastedMessagePop = memo((props) => {

    const { lastedMessage, profile, currentChatMessage, typingMessage } = props
    let chatMessageLasted = [...lastedMessage]

    const dispatch = useDispatch()

    const minimizeChat = useSelector(state => state.minimizeChat)
    const detailMinimizeChat = useSelector(state => state.detailMinimizeChat)

    const loadDetail = (item) => {
        messageGroupDetail(item)
    }

    const messageGroupDetail = (group) => {

        let miniMize = [...minimizeChat]
        let detailMiniMize = [...detailMinimizeChat]

        // if (group.viewerIdArr.indexOf(profile.userId) < 0) {
        //     let data = {
        //         room: group.groupChatCode,
        //         messageIds: [group._id]
        //     };
        //     console.log('seenMassage')
        //     emitSeenMessage(data)
        // }
        props?.getMessageGroupByCode(group.groupChatCode)
            .then(res => {

                let index = _.findIndex(miniMize, { groupChatCode: group?.groupChatCode })
                let indexD = _.findIndex(detailMiniMize, { code: group?.groupChatCode })
                
                if (index > -1) {
                    miniMize.splice(index, 1)
                    miniMize.push(group)
                    detailMinimizeChat.splice(indexD, 1)
                    detailMinimizeChat.push(res.success.data)
                }
                else {
                    miniMize.push(group)
                    detailMinimizeChat.push(res.success.data)
                }

                dispatch({
                    type: "SET_MINI_CHAT",
                    payload: _.reverse(miniMize)
                })

                dispatch({
                    type: "SET_DETAIL_MINI_CHAT",
                    payload: detailMinimizeChat
                })
            })
    }

    let indexActive = 0;


    //check active lasted message
    if (_.has(currentChatMessage, 'code') === true && chatMessageLasted.length > 0) {
        indexActive = _.findIndex(chatMessageLasted, (e) => {
            return e.groupChatCode === currentChatMessage.code;
        });
    }

    var typing = {}
    if (typingMessage.length > 0) {
        typing = typingMessage[0]
    }

    return (
        <div className="main-ListChat">
            <ul className="listMessage">
                {chatMessageLasted.map((item, index) => {
                    if (_.get(item, 'groupChat.type', '') === 'single') {
                        var indexEmp = item.receiverEmployeeCodeArr.findIndex(sss => sss !== profile.employeeCode)
                        var name = ''
                        var prof = item.receiverChatArr[indexEmp].profile
                        name = `${_.get(prof, 'firstName', '')} ${_.get(prof, 'lastName', '')}`

                        return <li className={`itemMess ${indexActive === index ? `active` : ""}`} key={item._id} onClick={() => loadDetail(item)}>
                            <div className="avatar alone">
                                {_.get(prof, 'fileAvatar.path', '') === '' ? _.get(prof, 'lastName', '').charAt(0) : <div className="ctImg"><img src={config.baseURL + _.get(prof, 'fileAvatar.path', '')} alt={_.get(prof, 'lastName', '')} /></div>}
                                {_.includes(props.chatUserOnline, item.receiverIdArr[indexEmp]) === true && <div className="online"></div>}
                            </div>
                            <div className={`bodyMess ${_.findIndex(item.viewerChatArr, { id: profile._id }) < 0 ? "none-read" : ""}`}>
                                <div className="nameMess">{name !== '' ? name : item.groupChat.name}</div>
                                <div className="briefMess">
                                    {profile.employeeCode === item.senderEmployeeCode ? 'Bạn: ' : ''}{item.type === 'text' ? item.content : item.type === 'image' ? '[Hình ảnh]' : item.type === 'video' ? '[Video]' : '[Tệp]'}
                                    {(typingMessage.length > 0 && typing.data.isTyping === true && item.groupChatCode === typing.room)
                                        && <div className="typingGroup">
                                            <div className="spinner">
                                                <div className="bounce1"></div>
                                                <div className="bounce2"></div>
                                                <div className="bounce3"></div>
                                                <div className="bounce4"></div>
                                            </div>
                                        </div>}

                                </div>
                                <div className="timeMess">{moment(item.created).locale('en').startOf('minute').fromNow()}</div>
                            </div>
                        </li>
                    }
                    else {
                        return <li className={`itemMess ${indexActive === index ? `active` : ""}`} key={item._id} onClick={() => loadDetail(item)}>
                            <div className="avatar group">
                                {_.get(item, 'receiverChatArr', []).map((receive, i) => {
                                    if (i < 2) {
                                        var prof = _.get(receive, 'profile', {})
                                        return <div className="avChild" key={i}>
                                            {_.get(prof, 'fileAvatar.path', '') === '' ? _.get(prof, 'lastName', '').charAt(0) : <img src={config.baseURL + _.get(prof, 'fileAvatar.path', '')} alt={_.get(prof, 'lastName', '')} />}
                                        </div>
                                    }
                                    else if (i === _.get(item, 'receiverChatArr', []).length - 1) {
                                        return <div className="avChild absol" key={i}>+{_.get(item, 'receiverChatArr', []).length - 2}</div>
                                    }
                                    else {
                                        return <span key={i}></span>
                                    }
                                })}
                            </div>
                            <div className={`bodyMess ${_.findIndex(item.viewerChatArr, { id: profile._id }) < 0 ? "none-read" : ""}`}>
                                <div className="nameMess">{item.groupChat.name}</div>
                                <div className="briefMess">
                                    {profile.employeeCode === item.senderEmployeeCode ? 'Bạn: ' : ''}{item.type === 'text' ? item.content : item.type === 'image' ? '[Hình ảnh]' : item.type === 'video' ? '[Video]' : '[Tệp]'}
                                    {(typingMessage.length > 0 && typing.data.isTyping === true && item.groupChatCode === typing.room)
                                        && <div className="typingGroup">
                                            <div className="spinner">
                                                <div className="bounce1"></div>
                                                <div className="bounce2"></div>
                                                <div className="bounce3"></div>
                                                <div className="bounce4"></div>

                                            </div>
                                        </div>}
                                </div>
                                <div className="timeMess">{moment(item.created).locale('en').startOf('minute').fromNow()}</div>

                            </div>
                        </li>
                    }
                })}
            </ul>

        </div>
    );
});

const mapDispatchToProps = {
    getLastestMessage, getMessageGroupByCode, emitSeenMessage
}
const mapStateToProps = (state) => {

    return {
        chatUserOnline: state.chatUserOnline,
        lastedMessage: state.chatMessageLasted,
        chatGroup: state.chatGroup,
        userAll: state.user,
        profile: state.profile,
        typingMessage: state.typingMessage,
        currentChatMessage: state.currentChatMessage,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LastedMessagePop);
