import { Box } from '@mui/material';
import React from 'react';
// import { NumberToText } from '../../../../untils/functions';


const A402 = React.forwardRef((props, ref) => {

    return <Box>
        <div className='headPrintBank'>
            <div className='leftPrintBank'>

            </div>
            <div className='centerPrintBank'>
                <div className="prtCompany">
                    ໃບຢງັ້ຢືນການຈາ່ຍ
                </div>
                <div style={{ fontSize: 24 }}>
                    付款证明单
                </div>
            </div>
            <div className='rightPrintBank02'>

            </div>
        </div>

        <div className='headMiddleBank'>
            <div className='leftMiddleBank'>
                <div className='middleRowBank'>
                    <span className='titBank'>ບໍລສິ ດ/公司 :</span>
                    <span className='contentBank'></span>
                </div>
                <div className='middleRowBank'>
                    <span className='titBank'>ພາກສວ່ ນ/部分 :</span>
                    <span className='contentBank'></span>
                </div>
            </div>
            <div className='rightMiddleBank'>
                <div className='middleRowBank' style={{ minHeight: '20.56px' }}>

                </div>
                <div className='middleRowBank'>
                    <span className='titBank'>ວນທ່ 日期 :</span>
                    <span className='contentBank'></span>
                </div>
            </div>
        </div>
        <div className='subHeadPrintBank' style={{ flexDirection: 'column' }}>
            <table className='subTable' style={{ width: '60%' }}>
                <tr >
                    <td colSpan={2} rowSpan={2}>
                        ພາກສ່ວນ <bt />
                        单位
                    </td>
                    <td colSpan={3}>ຫວົຫນາ້ຄອນ 宽总支付</td>
                    <td style={{ minWidth: 120 }}></td>
                </tr>
                <tr>
                    <td colSpan={3}>ຄງັເງນິສດົ 现金支付</td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        ໂອນ 银行转账
                    </td>
                    <td></td>
                    <td colSpan={1}>ແຊກັ 支票 BCE</td>
                    <td></td>
                    <td style={{ minWidth: 120 }}>ເລກທ່ ແຊກັ .................</td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        LDB
                    </td>
                    <td></td>
                    <td colSpan={1}>LDB</td>
                    <td></td>
                    <td style={{ minWidth: 120 }}>支票号码.................</td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        JDB
                    </td>
                    <td></td>
                    <td colSpan={1}>JDB</td>
                    <td></td>
                    <td style={{ minWidth: 120 }}>ເລກທ່ແຊກັ .................</td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        BCEL-One
                    </td>
                    <td></td>
                    <td colSpan={1}></td>
                    <td></td>
                    <td style={{ minWidth: 120 }}>支票号码.................</td>
                </tr>
            </table>
            <div style={{ display: 'flex', width: '60%', flex: 1, marginTop: 24, flexDirection: 'column' }}>
                <div className='middleRowBank'>
                    <span className='titBank'>ຊ່ບືນຊຮບເງນິ 收款账户名 :</span>
                    <span className='contentBank'></span>
                </div>
                <div className='middleRowBank'>
                    <span className='titBank'>ເລກບນຊຮບເງນິ 收款账号 :</span>
                    <span className='contentBank'></span>
                </div>
                <div className='middleRowBank'>
                    <span className='titBank'>ພະແນກ 部门 :</span>
                    <span className='contentBank'></span>
                </div>
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', width: '60%', flexDirection: 'column' }}>
                    <div className='middleRowBank'>
                        <span className='titBank'>ຊ່ື ແລະ ນາມສະກນຸ 姓名 :</span>
                        <span className='contentBank'></span>
                    </div>
                    <div className='middleRowBank'>
                        <span className='titBank'>ຕາໍ ແຫນງ່ 岗位 :</span>
                        <span className='contentBank'></span>
                    </div>
                </div>
                <table className='subTable' style={{ minWidth: 300 }}>
                    <tr >
                        <td style={{ width: 150 }}>ບນິ (票据)</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>ຈາໍ ນວນໃບ(票据张数)</td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
        <div className='bodyPrintBank'>
            <table>
                <tr>
                    <td width={60}>
                        ລ/ດ<br />
                        序号
                    </td>
                    <td colSpan={2}>
                        ເນອ້ ໃນລາຍການ<br />
                        付款内容
                    </td>
                    <td>
                        USD<br />
                        美元
                    </td>
                    <td>
                        CNY<br />
                        人民币
                    </td>
                    <td>
                        BATH<br />
                        泰铢
                    </td>
                    <td>
                        KIP<br />
                        基普
                    </td>
                </tr>
                <tr>
                    <td>1</td>
                    <td colSpan={2}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>2</td>
                    <td colSpan={2}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td colSpan={2}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td colSpan={2}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>5</td>
                    <td colSpan={2}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>6</td>
                    <td colSpan={2}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td style={{ borderRight: 'none' }}></td>
                    <td style={{ borderRight: 'none' }}></td>
                    <td >ລວມ/合计</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        ລວມເປນຕວົ ຫນງສື<br />
                        合计(大写)
                    </td>
                    <td colSpan={5}></td>
                </tr>
                <tr>
                    <td colSpan={2}>
                    ຜອ້ະນຸມດັ <br/> 批准人
                    </td>
                    <td >ຫວົຫນາ ້-ບນັຊ<br/>财务主管</td>
                    <td >ຫວົຫນາ້-ການເງນິ<br/>出纳</td>
                    <td >ຜລ້ງົບນັຊ<br/>记账人员</td>
                    <td >ຜກັ້ວດບນິ<br/>审单人</td>
                    <td >ຢັງ້ຢນການຈ່າຍ<br/>报销人</td>
                </tr>
                <tr>
                    <td colSpan={2}></td>
                    <td ></td>
                    <td ></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </table>

        </div>

        <div className='footerPrintBank'>
           
        </div>
    </Box>
});


export default A402;