import * as React from 'react';
import { useEffect, useState } from 'react';

import { Grid, Radio, RadioGroup, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { find, findIndex, map } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { getDataById, updateData } from '../../../actions/data';
import { getAllData as getLanguage } from '../../../actions/language';

import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';


const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)

    const [
        { name, isActive, isDefault, code, description,  defaultLanguage, language, _id },
        setState
    ] = useState({ ...editObject });

    const listLanguage = useSelector(state => state?.language)


    const getDataById = () => {
        props?.getDataById(_id).then(res => {
            let data = res?.data
            let lang = [...data?.language]
            let index = findIndex(lang, { default: true })
            setState(state => ({ ...state, ...data, defaultLanguage: index > -1 ? lang[index]?.langCode : 'la' }))
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        const init = () => {
            props?.getLanguage()
        }
        init()
        getDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDefaultLanguage = (event) => {
        let langTmp = [...language]
        let stateNew = {}
        let index = findIndex(langTmp, { langCode: event.target.value })
        if (index > -1) {
            var newArr = map(langTmp, function (a) {
                return { ...a, default: false };
            });

            let tmp = { ...newArr[index] }
            tmp.default = true
            langTmp[index] = tmp
            stateNew.language = langTmp
            stateNew.name = newArr[index]?.value
        }
        setState(state => ({ ...state, defaultLanguage: event.target.value, ...stateNew }))
    };


    const onChangeLangue = (e, lang) => {
        let langTmp = [...language]
        let text = e.target.value
        let stateNew = {}
        if (lang.code === defaultLanguage) {
            stateNew.name = text
        }
        // get index in current language property in Accountant Object
        let index = findIndex(langTmp, { langCode: lang.code })
        if (index > -1) {
            let tmp = { ...langTmp[index] }
            tmp.value = text
            langTmp[index] = tmp
            stateNew.language = langTmp
        }
        else {
            langTmp.push({
                langCode: lang.code,
                default: false,
                value: text
            })
            stateNew.language = langTmp
        }
        setState(state => ({ ...state, ...stateNew }));
    }


    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const handleSubmit = () => {
        let data = { name, isActive, isDefault, code, description, language }
        props?.updateData(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
            })
    }

    React.useImperativeHandle(ref, () => ({ handleSubmit }));



    return (
        <Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 12, md: 12 }}>

            <Grid item xs={12} md={3} >
                <FormGroup >
                    <FormControlLabel control={<Checkbox />}
                        checked={isActive}
                        name="isActive"
                        onChange={(e) => customeOnChange('isActive', !isActive)}
                        label="Active" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} md={3} >
                <FormGroup >
                    <FormControlLabel control={<Checkbox />}
                        checked={isDefault}
                        name="isDefault"
                        onChange={(e) => customeOnChange('isDefault', !isDefault)}
                        label="Default" />
                </FormGroup>
            </Grid>
            <Grid item md={6} xs={12} >
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off" >
                    <TextField
                        label="Code "
                        id="code"
                        value={code}
                        name="code"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item md={12} xs={12}>
                <Typography sx={{ mb: 1, fontWeight: 600 }}>Name</Typography>
              <RadioGroup
                    aria-labelledby="demo-defaultLanguage"
                    name="defaultLanguage"
                    value={defaultLanguage}
                    onChange={handleDefaultLanguage}>
                    {listLanguage?.map((item, index) => {
               
                        return <Box
                            key={index}
                            component="form"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center',
                                '& .MuiTextField-root': { flex: 1, mb: 2 },
                            }}
                            noValidate
                            autoComplete="off">
                            <TextField
                                label={item?.name}
                                id="last-name"
                                value={find(language, { langCode: item.code })?.value}
                                name="name"
                                onChange={(e) => onChangeLangue(e, item)}
                                size="small"
                            />
                            <FormControlLabel value={item.code} sx={{ ml: 1, mb: 2 }} control={<Radio checked={Boolean(defaultLanguage === item.code)} />} label="" />
                        </Box>
                    })}
                </RadioGroup>
            </Grid>

            <Grid item lg={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off">

                    <TextField
                        label="Description"
                        id="last-description"
                        value={description}
                        multiline={true}
                        minRows={4}
                        name="description"
                        onChange={onChange}
                    />
                </Box>
            </Grid>

        </Grid>
    );
})


const mapDispatchToProps = {
    getDataById, updateData, getLanguage
}

const mapStateToProps = (state) => {
    return {
        editObj: state.edit
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditForm);