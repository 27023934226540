import { CloseOutlined, SearchOutlined } from '@mui/icons-material';
import { AppBar, Box, Button, Dialog, DialogContent, Fade, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TableFooter, TextField, Toolbar, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { findIndex, isEmpty, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getPaymentByParent } from '../../../actions/payment';
import { getAllDataNoneRedux as getSettlement } from '../../../actions/settlement';
import { getAllDataNoneRedux as getAdvancePayment } from '../../../actions/advancePayment';
import { getAllDataNoneRedux as getIncome } from '../../../actions/income';
import { getAllDataNoneRedux as getOutcome } from '../../../actions/outcome';
import { getAllDataNoneRedux as getDebitNote } from '../../../actions/debitNote';
import { getAllDataNoneRedux as getCreditNote } from '../../../actions/creditNote';
import { getAllDataNoneRedux as getJournalEntrie } from '../../../actions/journalEntrie';

import { dollarFormat } from '../../../untils/number';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} />;
});


const DocumentFormList = props => {

    const [paymentCode, setPaymentCode] = React.useState('');
    const [listPayment, setListPayment] = useState([])
    const [selected, setSelected] = useState([])
    const [detail, setDetail] = useState([])
    const [type, setType] = useState('')

    useEffect(() => {
        setType(props?.rule?.paymentFor)
    }, [props?.rule])


    const paymentSearch = (e) => {
        // if (e.keyCode === 13) {
        let condition = {
            condition: {},
            limit: 5
        }
        if (!isEmpty(paymentCode)) {
            condition.condition.code = {
                equal: paymentCode
            }
        }
        getData(condition, type)
        // }
    }

    const getData = (condition, key) => {
        switch (key) {
            case 'SEL':
                props?.getSettlement(condition).then(res => { setListPayment(res?.data) })
                break;

            case 'AP':
                props?.getAdvancePayment(condition).then(res => { setListPayment(res?.data) })
                break;

            case 'IC':
                props?.getIncome(condition).then(res => { setListPayment(res?.data) })
                break;

            case 'OC':
                props?.getOutcome(condition).then(res => { setListPayment(res?.data) })
                break;

            case 'JE':
                props?.getJournalEntrie(condition).then(res => { setListPayment(res?.data) })
                break;

            case 'DRB':
                props?.getDebitNote(condition).then(res => { setListPayment(res?.data) })
                break;

            case 'CRB':
                props?.getCreditNote(condition).then(res => { setListPayment(res?.data) })
                break;

            default:
                break;
        }
    }

    const getDetail = (item) => {
        let select = [...selected]
        let deta = [...detail]
        let index = findIndex(selected, { code: item.code })
        if (index > -1) {
            select.splice(index, 1)
            let dt = deta.filter(res => res.parentCode !== item.code)
            setDetail(dt)
        }
        else {
            select.push(item)
            props?.getPaymentByParent(item.code)
                .then(res => {
                    let dt = [...deta, ...res?.data]
                    setDetail(dt)
                })
        }
        setSelected(select)
    }

    const acceptDocument = () => {
        props?.accept(selected)
    }

    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            open={true}
            onClose={props.close}
            TransitionComponent={Transition}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <AppBar elevation={0} position='relative' sx={{ background: '#1976d2' }} color='transparent'>
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <IconButton edge="start" color="inherit" onClick={props.close} aria-label="close">
                            <CloseOutlined />
                        </IconButton>
                        <Typography variant="h6" color="#fff">
                            Document list
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ minHeight: 400, background: '#dde3ea', padding: 16 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        <Box sx={{ background: '#fff', minHeight: '400px', borderRadius: 1 }}>
                            <Box display='flex' justifyContent='space-between' alignItems={'flex-end'} sx={{ padding: '12px' }}>
                                <FormControl size='small' variant="standard" sx={{ minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-Apply">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-Apply"
                                        id="demo-simple-Apply"
                                        value={type}
                                        label="Apply for"
                                        onChange={(e) => {
                                            setType(e.target.value)
                                        }}>
                                        <MenuItem value={'IC'}>Income</MenuItem>
                                        <MenuItem value={'OC'}>Outcome</MenuItem>
                                        <MenuItem value={'CRB'}>Credit note</MenuItem>
                                        <MenuItem value={'DRB'}>Debit note</MenuItem>
                                        <MenuItem value={'JE'}>Journal Entrie</MenuItem>
                                        <MenuItem value={'AP'}>Advance Payment</MenuItem>
                                        <MenuItem value={'SEL'}>Financial Settlement</MenuItem>
                                    </Select>
                                </FormControl>
                                <Box sx={{
                                    display: 'flex', alignItems: 'center', flex: 1, paddingLeft: 2
                                }}>
                                    <SearchOutlined sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <TextField id="input-with-sx"
                                        fullWidth
                                        onChange={e => setPaymentCode(e.target.value)}
                                        onKeyDown={paymentSearch}
                                        placeholder='Enter financial code' variant="standard" />
                                    <Button variant="contained" disableElevation sx={{ ml: 1 }} onClick={(e) => paymentSearch(e)}>
                                        Find
                                    </Button>
                                </Box>

                            </Box>
                            <Box sx={{ background: '#dde3ea', height: 8, flex: 1 }}>

                            </Box>
                            <TableContainer >
                                <Table  sx={{ width: 'calc(100% - 24px)' }} aria-label="simple table">
                                    <TableHead >
                                        <TableRow>
                                            <TableCell align="left" width={100}>Code</TableCell>
                                            <TableCell align="left" >Contact</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listPayment.map((row) => {
                                            let active = findIndex(selected, { code: row.code }) > -1

                                            return (<TableRow
                                                className={active ? 'activeChooseRow' : 'nonono'}
                                                key={row.code}
                                                onClick={() => getDetail(row)}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                <TableCell component="th" scope="row">
                                                    {row.code}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography noWrap variant='body2'>
                                                        {row?.customer?.firstName} {row?.customer?.lastName}
                                                    </Typography>
                                                    <Typography noWrap fontSize={12} color={'#9E9E9E'} maxWidth={200}>
                                                        {row?.description}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">{dollarFormat(row.amount || 0)}</TableCell>
                                            </TableRow>)
                                        })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>


                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ background: '#fff', minHeight: '400px', borderRadius: 1, padding: 1.5 }}>
                            <TableContainer sx={{maxHeight: 400, overflow:'scroll'}} >
                                <Table sx={{ width: 'calc(100% - 24px)' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Parent</TableCell>
                                            <TableCell align="left">Contact</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {detail.map((row) => {
                                            return (<TableRow
                                                key={row.code}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                <TableCell component="th" scope="row">
                                                    {row.parentCode}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography noWrap variant='body2'>
                                                        {row?.customer?.firstName} {row?.customer?.lastName}
                                                    </Typography>
                                                    <Typography noWrap fontSize={12} variant='body2' color={'#9E9E9E'} maxWidth={180}>
                                                        {row?.description}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">{dollarFormat(row.amount || 0)}</TableCell>
                                            </TableRow>)
                                        })
                                        }

                                    </TableBody>

                                    <TableFooter>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                            <TableCell colSpan={2} align="right">
                                                <Typography fontWeight={600}>Total</Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography fontWeight={600}>{dollarFormat(sumBy(detail, 'amount') || 0)}</Typography></TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>

                            {
                                selected.length > 0 ? <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                                    <Button variant='contained' disableElevation onClick={() => acceptDocument()}>Accept [{selected.length}]</Button>
                                </Box>
                                    : <></>
                            }
                        </Box>
                    </Grid>
                </Grid>


            </DialogContent>
        </Dialog>
    );
};

const mapDispatchToProps = {
    getSettlement, getPaymentByParent, getIncome, getOutcome, getDebitNote, getCreditNote, getJournalEntrie, getAdvancePayment
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(DocumentFormList);