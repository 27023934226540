import  {setTokenAxios } from "../api/index"
import {_showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";



export const getPermissionById = (id) => (dispatch)=>{
    return setTokenAxios().get(`permission/${id}`)
      .then(async res => {
        return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
      })
      .catch((err) =>{
        _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
      })
  }

export const getAllData = (condition) => (dispatch)=> {
  return setTokenAxios().get(`permission`,condition)
    .then(async res => {
      dispatch({
        type: "SET_PERMISSION",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}


export const createPermission = (data) => (dispatch)=> {
  return setTokenAxios().post(`permission`,data)
    .then(async res => {
      dispatch({
        type: "CREATE_PERMISSION",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}

export const updatePermission = (data, _id) => (dispatch)=> {
  return setTokenAxios().put(`permission/${_id}`,data)
    .then(async res => {
      dispatch({
        type: "UPDATE_PERMISSION",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}