import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ccyFormat } from '../../../untils/number';

const DialogDetailDocument = props => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation()

    React.useEffect(() => {
        handleOpen()
    }, [props?.isOpen])

    React.useEffect(() => {
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        setOpen(false);
        props?.handleClose(false)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const renderBottomSheet = () => {
        return props?.data.map((row, index) => (
            <TableRow key={row._id}>
                <TableCell className="firstCell" align="left" sx={{ paddingLeft: '0px!important' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {index + 1}.
                    </div>
                </TableCell>
                <TableCell align="right">{moment(row?.date).format('DD/MM/YYYY')}
                    {row?.code}
                </TableCell>
                <TableCell align="left">{row.accountNumberDebit}</TableCell>
                <TableCell align="left">{row.accountNumberCredit}</TableCell>
                <TableCell align="right">{ccyFormat(row.amount)}</TableCell>
                <TableCell align="right">
                    {row?.vat?.name}
                </TableCell>
                <TableCell align="right">{ccyFormat(row.total)}</TableCell>
                <TableCell component="th" scope="row" sx={{
                    borderWidth: 0.5
                }}>
                    {row?.customer?.firstName} {row?.customer?.lastName}
                </TableCell>
                <TableCell align="left">
                    {row?.expense?.name ? <><Chip sx={{ height: 20, mr: 1 }} label={row?.department?.name} color="primary" size="small" />
                        <Typography variant='body' sx={{ lineHeight: 1.2 }}>
                            {row?.expense?.name}
                        </Typography></> : ''}
                </TableCell>
            </TableRow>
        ))
    }


    return (
        <Dialog
            open={open}
            maxWidth='llg'
            fullWidth={true}
            scroll='paper'
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >

            <DialogTitle id="alert-dialog-title"
                sx={{
                    background: '#2196f3'
                }}>
                <Box display='flex' justifyContent={"space-between"} alignItems="center" sx={{ color: '#fff' }}>
                    {`${props?.dataParent?.code || ''}`}
                </Box>

            </DialogTitle>
            <DialogContent style={{ minHeight: '400px', backgroundColor: '#F7FAFC' }}>
                <Paper sx={{ padding: 2, border: "0.5px solid #eaeaea",
                marginTop:2, borderTop: 'none' }} elevation={0} >
                    <div className='headBottomSheet'>

                    </div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table sx={{ minWidth: 500 }} aria-label="sticky table">
                            <TableHead>
                                <TableRow sx={{
                                    fontWeight: 'bold'
                                }}>
                                    <TableCell sx={{ width: 60 }}>{t('lblColNo')}</TableCell>
                                    <TableCell sx={{ width: 60 }}>{t('lblColDate')}</TableCell>
                                    <TableCell sx={{ width: 120 }}>{t('lblColDebit')}</TableCell>
                                    <TableCell sx={{ width: 120 }}>{t('lblColCredit')}</TableCell>
                                    <TableCell sx={{ width: 140 }}>{t('lblColOriginal')}</TableCell>
                                    <TableCell align="right">VAT</TableCell>
                                    <TableCell align="right" sx={{ width: 120 }}>{t('lblColAmount')}</TableCell>
                                    <TableCell >{t('lblColPartner')}</TableCell>
                                    <TableCell >{t('lblColRefer')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='customTable'>
                                {renderBottomSheet()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{
                        borderTop: '0.5px solid #eaeaea',
                        alignItems: 'center',
                        backgroundColor: 'white', paddingTop: 1.5, marginTop: 2, display: 'flex', justifyContent: 'space-between'
                    }}>
                        <div>
                            <Typography variant='caption' color={'#848484'}>
                                {t('txtCreated')} {props?.dataParent?.creater?.firstName}  {props?.dataParent?.creater?.lastName} at {moment(props?.dataParent?.created).format('DD/MM/YYYY HH:mm:ss')}
                            </Typography> &nbsp;
                            <Typography variant='caption' color={'#848484'}>
                                {t('txtUpdated')} {props?.dataParent?.updater?.firstName}  {props?.dataParent?.updater?.lastName} at {moment(props?.dataParent?.updated).format('DD/MM/YYYY HH:mm:ss')}
                            </Typography>
                        </div>

                    </Box>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DialogDetailDocument);