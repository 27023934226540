import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BreadCrumb = props => {

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
      }
      
        let breadcrumbs = [];

        props?.data?.map((item,ind)=>{
            let tmp = {}
            if(ind !== props?.data.length-1)
            {
                tmp = <Link underline="hover" key={ind+1} color="inherit" href={item.href} onClick={handleClick}>
                    {item.name}
                </Link>
               
            }
            else
            {
                tmp = <Typography key={ind+1} color="text.primary">
                    {item.name}
                </Typography>
            }
            return breadcrumbs.push(tmp)
        })

    return (
        <Breadcrumbs
        sx={{m:2}}
        separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
        >
            {breadcrumbs}
        </Breadcrumbs>
    );
};


export default BreadCrumb;