import * as React from 'react';
import { useEffect, useState } from 'react';

import { Autocomplete, Button, Divider, FormLabel, Grid, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// import StarOutlinedIcon from '@mui/icons-material/StarOutlined';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Select from '@mui/material/Select';
import { debounce, find, findIndex, isEmpty, map } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { uploadFile } from '../../../actions/file';
import { getAllData as getOption } from '../../../actions/language';
import { getAllDataNoneRedux as getPartner, getPartnerById, updatePartner } from '../../../actions/partner';
import { getAllDataNoneRedux as getGroup } from '../../../actions/partnerGroup';
import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

// import { district } from '../../../enums/district';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PartnerSource } from '../../../enums/partnerSource';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>

            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const initialState = {
    firstName: "",
    lastName: "",
    address: "",
    source: "",
    description: '',
    isActive: true,
    code: '',
    phone: '',
    financialCode: '',
    vocative: '',
    fax: '',
    website: '',
    idNumber: '',
    email: '',
    invoiceName: '',
    invoicePhone: '',
    invoiceAddress: '',
    invoiceEmail: '',
    bankAccount: '',
    bankBranch: '',
    bank: [],
    bankName: '',
    dateOfId: '',
    placeOfId: '',
    paymentAmount: '',
    paymentRule: '',
    paymentTerm: '',
    contact: []
};

const MAX_COUNT = 5;
const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)
    const options = useSelector(state => state.option)
    const [fileLimit, setFileLimit] = useState(false);
    const { t } = useTranslation()

    const [partner, setPartner] = useState({})
    const [listPartner, setListPartner] = useState([])
    const [listGroup, setListGroup] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [valueTab, setValueTab] = React.useState(0);

    const [
        { firstName, lastName, source, description, isActive, code, address, phone, financialCode,
            bankName, bankAccount, bankBranch, dateOfId, placeOfId, bank, type, taxCode,
            vocative, fax, website, idNumber, email, paymentRule, paymentAmount, paymentTerm,
            contact, _id, imageUploaded, uploadedFiles, phone2, groupCode,
            invoiceEmail, invoiceAddress, invoiceName, invoicePhone },
        setState
    ] = useState({ ...editObject, isCreateTele: false, imageUploaded: [] });

    const getDataById = () => {

        props?.getPartnerById(_id).then(res => {
            let financialAssessment = res?.data?.financialAssessment || {}
            let data = {
                financialCode: financialAssessment?.code || '',
                phone: res?.data?.phone?.phoneNumber,
                imageUploaded: res?.data?.images || [],
                uploadedFiles: res?.data?.images || [],
                idNumber: res?.data?.idInfo?.idNumber,
                dateOfId: res?.data?.idInfo?.dateOfId,
                placeOfId: res?.data?.idInfo?.placeOfId,
                invoiceName: res?.data?.invoiceInfo?.invoiceName,
                invoicePhone: res?.data?.invoiceInfo?.invoicePhone,
                invoiceEmail: res?.data?.invoiceInfo?.invoiceEmail,
                invoiceAddress: res?.data?.invoiceInfo?.invoiceAddress,
            }

            setPartner(res?.data?.parentCode.length > 0 ? res?.data?.parentCode[0]?.customer : {})
            setState(state => ({ ...state, ...res.data, ...data }))


        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        const init = async () => {
            let params = {
                "condition": {
                    "type": {
                        "equal": "financialAssessment"
                    }
                },
                "sort": {
                    "created": 1
                },
                "limit": 10,
                "page": 1
            };
            await props?.getOption(params)
            await props?.getPartner({ condition: {}, limit: 20 }).then(res => setListPartner(res.data))
            await props?.getGroup({ condition: {}, limit: 20 }).then(res => setListGroup(res.data))
        }
        init()
        getDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = () => {
        let data = {
            firstName, lastName, source, address,
            bank,
            type,
            idInfo: {
                idNumber, dateOfId, placeOfId
            },
            website,
            email, phone2, fax, taxCode, vocative,
            description, isActive, code, phone: {
                "nationCode": "+856",
                "phoneNumber": phone
            },
            invoiceInfo: {
                invoiceName, invoiceAddress, invoicePhone, invoiceEmail
            },
            groupCode,
            paymentRule, paymentAmount, paymentTerm,
            contact
        }
        if (financialCode) {
            let fn = find(options, { code: financialCode })
            data.financialAssessment = fn
        }

        var files = [...uploadedFiles]
        let fileNoId = files.filter(item => !item?._id)
        let fileHasId = files.filter(item => item?._id)

        if (fileNoId.length > 0) {
            postFile(data, fileNoId, map(fileHasId, '_id'))
        }
        else {
            data.imageIdArr = map(fileHasId, '_id')
            props?.updatePartner(data, _id)
                .then(res => {
                    _showAlertSuccess(res?.message || GET_SUCCESS)
                    // _clearState()
                })
        }
    }


    const onChangeInput = (e) => {
        setInputValue(e.target.value)
        searchAuto(e.target.value)
    }

    const searchAuto = debounce((value) => {
        var data = {
            "condition": {},
            "sort": {
                "created": -1
            },
            "search": value,
            "limit": 10,
            "page": 1
        }
        props?.getPartner({ data, limit: 20 }).then(res => setListPartner(res.data))
    }, 1000)


    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        const imageUploadedTmp = [...imageUploaded];

        let limitExceeded = false;
        // eslint-disable-next-line 
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                imageUploadedTmp.push({ name: file?.name, path: URL.createObjectURL(file) })
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) {
            setState(state => ({ ...state, uploadedFiles: uploaded, imageUploaded: imageUploadedTmp }))
        }
    }

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }

    const postFile = async (data, files, hasId) => {
        // var files = [...uploadedFiles]
        var formData = new FormData();

        formData.append('name', 'File Upload');
        [...files].forEach(fl => {
            formData.append("files", fl);
        });

        if (files.length > 0) {
            await props?.uploadFile(formData)
                .then(res => {
                    var imgId = []
                    var index = 0
                    for (let dt of res.success) {
                        imgId.push(dt._id)
                        if (index === res.success.length - 1) {
                            data.imageIdArr = [...imgId, ...hasId]
                            props?.updatePartner(data, _id)
                                .then(res => {
                                    _clearState()
                                })
                        }
                        index++
                    }
                })
                .catch(err => {
                    console.log('uploadFile', err)
                })
        }
    }

    React.useImperativeHandle(ref, () => ({ handleSubmit }));

    const handleAddBank = () => {
        let bankT = [...bank]
        let indx = findIndex(bankT, { bankAccount: bankAccount })
        if (indx > -1) {
            bankT.splice(indx, 1)
        }
        else {
            bankT.push({
                bankName, bankAccount, bankBranch
            })
        }
        setState(state => ({ ...state, bank: bankT, bankAccount: '', bankName: '', bankBranch: '' }))
    }

    return (
        <Box sx={{
            bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 0,
            paddingBottom: 4,
            minHeight: '70vh'
        }}>
            <Tabs value={valueTab}
                sx={{
                    minHeight: 24,
                    mb: 2,
                    '& .MuiTab-root': {
                        padding: 0,
                        textTransform: 'capitalize',
                        minHeight: 24,
                    }
                }}
                onChange={(event, newValue) => { setValueTab(newValue) }} aria-label="disabled tabs example">
                <Tab label={'General'} {...a11yProps(0)} />
                <Tab label={'Bank'} {...a11yProps(1)} />
                <Tab label={t('More')} {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={valueTab} index={0}>

                <Grid container spacing={2}>

                    <Grid item xs={4}>
                        <FormControl sx={{ ml: 1 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) => setState(state => ({ ...state, type: e.target.value }))}
                                value={type}
                            >
                                <FormControlLabel value="individual" control={<Radio />} label="Individual" />
                                <FormControlLabel value="business" control={<Radio />} label="Business" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup sx={{ m: 1 }}>
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label="Active" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Code"
                                    id="code"
                                    value={code}
                                    name="code"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Tax Code"
                                    id="tax-name"
                                    value={taxCode}
                                    name="taxCode"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '50%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Vocative"
                                    id="tax-vocative"
                                    value={vocative}
                                    name="vocative"
                                    placeholder='Mr/Mrs...'
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="First Name"
                                    id="first-name"
                                    value={firstName}
                                    name="firstName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Last Name"
                                    id="last-name"
                                    value={lastName}
                                    name="lastName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Typography variant='body' width={'100%'} m={3} mb={0} sx={{ color: "#1976d2" }}>Information</Typography>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Phone"
                                    id="outlined-size-phone"
                                    value={phone}
                                    name="phone"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Fax"
                                    id="outlined-size-fax"
                                    value={fax}
                                    name="fax"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Website"
                                    id="outlined-size-website"
                                    value={website}
                                    name="website"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Email"
                                    id="outlined-size-email"
                                    value={email}
                                    name="email"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ m: 0.5, minWidth: '100%' }} size="small">
                            <InputLabel id="demo-select-small">Source</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={source}
                                name="source"
                                onChange={onChange}
                                label="Nguồn"
                            >
                                <MenuItem value="">
                                    <em>...</em>
                                </MenuItem>
                                {PartnerSource?.map((item, key) => {
                                    return item?.group === 'company' && <MenuItem key={key} value={item?.code}>{item?.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ m: 0.5, minWidth: '100%' }} size="small">
                            <InputLabel id="demo-select-Group">Group</InputLabel>
                            <Select
                                labelId="demo-select-Group"
                                id="demo-select-Group"
                                value={groupCode}
                                name="groupCode"
                                onChange={onChange}
                                label="Group"
                            >
                                {listGroup?.map((item, key) => {
                                    return <MenuItem key={key} value={item?.code}>{item?.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Address"
                                    id="outlined-size-address"
                                    value={address}
                                    name="address"
                                    onChange={onChange}
                                    size="small"
                                    fullWidth={true}
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Typography variant='body' width={'100%'} m={3} mb={0} sx={{ color: "#1976d2" }}>Identify</Typography>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="ID"
                                    id="outlined-size-idNumber"
                                    value={idNumber}
                                    name="idNumber"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Date"
                                    id="outlined-size-dateOfId"
                                    value={dateOfId}
                                    name="dateOfId"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Place of "
                                    id="outlined-size-placeOfId"
                                    value={placeOfId}
                                    name="placeOfId"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Description"
                                    id="outlined-size-description"
                                    value={description}
                                    name="description"
                                    onChange={onChange}
                                    multiline
                                    minRows={5}
                                    size="small"
                                    fullWidth={true}
                                />
                            </div>
                        </Box>
                    </Grid>


                </Grid>

            </TabPanel>

            <TabPanel value={valueTab} index={1}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Bank"
                                    id="outlined-size-bankName"
                                    value={bankName}
                                    name="bankName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Account Number"
                                    id="outlined-size-bankName"
                                    value={bankAccount}
                                    name="bankAccount"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Branch"
                                    id="outlined-size-bankBranch"
                                    value={bankBranch}
                                    name="bankBranch"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={handleAddBank}
                            variant='contained' color='primary' disableElevation sx={{ m: 1, mt: 0 }}>
                            Add
                        </Button>
                    </Grid>
                    <Divider></Divider>
                    <TableContainer sx={{ ml: 3, mt: 2 }}>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Bank</TableCell>
                                    <TableCell align="right">Account</TableCell>
                                    <TableCell align="right">Branch</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bank?.map((row, ind) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {ind + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.bankName}
                                        </TableCell>
                                        <TableCell align="right">{row.bankAccount}</TableCell>
                                        <TableCell align="right">{row.bankBranch}</TableCell>
                                        <TableCell>
                                            <Button color='error' onClick={() => {
                                                let tmp = [...bank]
                                                if (ind > -1) {
                                                    tmp.splice(ind, 1)
                                                    setState(state => ({ ...state, bank: tmp }))
                                                }
                                            }}>
                                                <CloseOutlinedIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </TabPanel>

            <TabPanel value={valueTab} index={2}>
                <Grid container spacing={2} padding={2}>
                    <Typography variant='body' width={'100%'} m={3} mb={0} mt={0} sx={{ color: "#1976d2" }}>Invoice</Typography>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Name"
                                    id="outlined-size-invoiceName"
                                    value={invoiceName}
                                    name="invoiceName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Phone"
                                    id="outlined-size-invoicePhone"
                                    value={invoicePhone}
                                    name="invoicePhone"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={8}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, ml: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Address"
                                    id="outlined-size-invoiceAddress"
                                    value={invoiceAddress}
                                    name="invoiceAddress"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Email"
                                    id="outlined-size-invoiceEmail"
                                    value={invoiceEmail}
                                    name="invoiceEmail"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Typography variant='body' width={'100%'} m={3} mb={0} mt={3} sx={{ color: "#1976d2" }}>Policy</Typography>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Payment Rule"
                                    id="outlined-size-rule"
                                    value={paymentRule}
                                    name="paymentRule"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Payment term"
                                    id="outlined-size-term"
                                    value={paymentTerm}
                                    name="paymentTerm"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Payment amount"
                                    id="outlined-size-amount"
                                    value={paymentAmount}
                                    name="paymentAmount"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Typography variant='body' width={'100%'} m={3} mb={0} mt={3} sx={{ color: "#1976d2" }}>Contact</Typography>
                    <Grid item xs={6}>
                        {listPartner?.length > 0 ?
                            <Autocomplete
                                sx={{}}
                                id="sale-select-partner"
                                options={[{ _id: '', firstName: t('frmChoose'), lastName: '...' }, ...listPartner]}
                                isOptionEqualToValue={(option, value) => option._id === value?._id}
                                size="small"
                                value={partner}
                                autoHighlight
                                getOptionLabel={(option) => !isEmpty(option?.firstName) ? `${option?.firstName} ${option?.lastName}` : ''}
                                onChange={(event, newValue) => {
                                    setPartner(newValue)
                                    let tmp = [...contact]
                                    let ind = tmp.indexOf(newValue?._id)
                                    if (ind > -1) {
                                        tmp.splice(ind, 1)
                                    }
                                    else {
                                        tmp.push(newValue?._id)
                                    }

                                    setState(state => ({ ...state, contact: tmp }))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Contact"
                                        value={inputValue}
                                        sx={{
                                            '& .MuiTextField-root': { display: 'flex' },
                                            '& .MuiInput-root': { mt: 1.5 }
                                        }}
                                        variant="outlined"
                                        type="text"
                                        onChange={onChangeInput}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )} /> : <></>}
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '85ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <input id='fileUpload' type='file' multiple
                                accept='application/pdf, image/png'
                                onChange={handleFileEvent}
                                disabled={fileLimit}
                            />

                            <div className="uploaded-files-list" >
                                {imageUploaded.map(file => (
                                    <div className='itemTemp'>
                                        <img src={`${file?.path}`} alt={file.name} />
                                        <span>{file.name}</span>
                                    </div>
                                ))}
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </TabPanel>
        </Box>
    );
})

const mapDispatchToProps = {
    getPartnerById, updatePartner, getOption, uploadFile, getPartner, getGroup
}

const mapStateToProps = (state) => {

    return {
        editObj: state.edit
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditForm);