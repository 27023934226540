const initialState = []

const projectReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_PROJECT":
            return action.payload
            
        case "CREATE_PROJECT":
            return [...state, action.payload]

        case "UPDATE_PROJECT":
            
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            
            return [...state]

        case "CHANGE_PASSWORD":
            
                const password = state.findIndex(per => per._id === action.payload._id)
                state[password] = action.payload;
                
                return [...state]

        case "UPDATE_PROJECT_ACTIVE":
            const project_ = [...state] 
            if(action.payload.length > 0)
            {
                (action.payload).forEach((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    project_[index] = item;
                })
            }
            return project_

        case "SET_CURRENT_PROJECT_IMG":
                return [...state]

        case "DELETE_PROJECT":
            const project_old = [...state] 
            var project_deleted = []
           
            if(action.payload.length > 0)
            {
                project_deleted = project_old.filter(del =>!(action.payload).includes(del._id) );
            }
            return project_deleted
        

        default:
            break;
    }

    return state
}

export default projectReducer