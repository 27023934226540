import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Backdrop, Button, ButtonBase, Grid, Link, ListSubheader, Menu, MenuItem, TextField } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// import InputBase from '@mui/material/InputBase';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha, styled, useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import MainAlert from './MainAlert';
import AccountMenu from './accountMenu';
import { menuData, menuData1, menuData2 } from './menu';
//icon
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
// import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Settings from '@mui/icons-material/Settings';
import { blue } from '@mui/material/colors';
import { animated, useSpring } from '@react-spring/web';
import { filter, find, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { getLastestMessage } from '../../actions/chat';
import { getAllData as getData } from '../../actions/data';
import { ccyFormat } from '../../untils/number';
import TopAlert from './TopAlert';
import LastedMessagePop from './chat/LastedMessagePop';
import ListMinimizeChat from './chat/ListMinimizeChat';
import FeedBack from './feedBack';
import VerticalMenu from './verticalMenu';

import 'moment/locale/en-au';
moment().locale('en');

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    // paddingLeft: 16,
    // paddingRight: 16,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        backgroundColor: theme?.palette?.customColor?.gray50,
        borderWidth: 0,
        // paddingLeft: 16,
        // paddingRight: 16,
      }
      ,
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper':
      {
        ...closedMixin(theme),
        backgroundColor: theme?.palette?.customColor?.gray50,
        borderWidth: 0,
        // paddingLeft: 16,
        // paddingRight: 16,
      },
    }),
  }),
);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.0),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.35),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    // width: 'auto',
    width: '100%',
  },
}));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   zIndex: 1,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

const SearchIconWrapper1 = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 0,
  top: 0,
  zIndex: 99,
  cursor: 'pointer'
}));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   width: '100%',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: '#eaf1fb',
//     // transition: theme.transitions.create('width'),
//     width: '100%',
//     height: '32px',
//     transition: '0.3s all ease-in-out',
//     [theme.breakpoints.up('sm')]: {
//       width: '100',
//       '&:focus': {
//         width: '100%',
//         backgroundColor: '#fff',
//         boxShadow: '0px 1px 2px 0px #8e8e8e'
//       },
//     },
//   },
// }));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


function DrawerExpand(props) {
  const { t } = useTranslation()
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  // const profile = useSelector(state => state.profile)
  // let states = useSelector(state => state?.enumRedux)
  let activeModule = useSelector(state => state?.enumRedux?.activeModule)
  let dataFromPage = useSelector(state => state?.enumRedux?.dataFromPage)
  let statusFilter = useSelector(state => state?.enumRedux?.statusFilter)
  let activeName = useSelector(state => state?.enumRedux?.activeName)
  activeModule = activeModule ? activeModule : localStorage.getItem('activeModule')
  activeName = activeName ? activeName : localStorage.getItem('activeName')
  let navigate = useSelector(state => state?.enumRedux?.navigate)
  navigate = navigate ? navigate : localStorage.getItem('navigate')

  const menu0 = filter(menuData, { module: activeModule })
  const menu1 = filter(menuData1, { module: activeModule })
  const menu2 = filter(menuData2, { module: activeModule })

  const { i18n } = useTranslation();
  const [lng, setLng] = React.useState(i18n.language);
  const [anchorElChat, setAnchorElChat] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElHelp, setAnchorElHelp] = React.useState(null);
  const openHelp = Boolean(anchorElHelp);

  const [anchorData, setAnchorData] = React.useState(null);
  const openAnchorData = Boolean(anchorData);

  const openMenuApp = Boolean(anchorEl);
  const openChat = Boolean(anchorElChat);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [keySearch, setKeySearch] = React.useState('');

  const dispatch = useDispatch()

  const openDrawerRedux = useSelector(state => state.enumRedux.openDrawer)
  const dataLocated = useSelector(state => state.data)

  const dataCurrent = JSON.parse(localStorage.getItem('dataCurrent'))//
  const dataCurrentRedux = useSelector(state => state.enumRedux.dataCurrent)
  const profile = useSelector(state => state.profile)

  useEffect(() => {
    setOpen(openDrawerRedux)
  }, [openDrawerRedux])

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const init = () => {
    props.getLastestMessage()
    props.getData()

    if (isEmpty(dataCurrent)) {
      let profileDataDefault = find(profile?.dataCode, { isDefault: true })
      console.log(profileDataDefault)
      if (profileDataDefault?.code) {
        let dataDef = find(dataLocated, { code: profileDataDefault?.code })
        localStorage.setItem('dataCurrent', JSON.stringify({ code: dataDef?.code, name: dataDef?.name }))
        dispatch({
          type: "SET_ENUM",
          payload: {
            dataCurrent: { code: dataLocated?.code, name: dataDef?.name }
          }
        })
      }
    } else {
      //when refresh page redux has removed, so set redux again
      if (dataCurrent?.code !== dataCurrentRedux?.code) {
        dispatch({
          type: "SET_ENUM",
          payload: {
            dataCurrent: { code: dataCurrent?.code, name: dataCurrent?.name }
          }
        })
      }

    }
  }

  const handleOpenApp = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseApp = () => {
    setAnchorEl(null);
  };

  const handleOpenChat = (event) => {
    setAnchorElChat(event.currentTarget);
  };

  const handleCloseOpenChat = () => {
    setAnchorElChat(null);
  }

  const handleOpenHelp = (event) => {
    setAnchorElHelp(event.currentTarget);
  };
  const handleCloseHelp = () => {
    setAnchorElHelp(null);
  };

  const handleOpenData = (event) => {
    setAnchorData(event.currentTarget);
  };
  const handleCloseData = () => {
    setAnchorData(null);
  };

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const handleChooseData = (item) => {
    localStorage.setItem('dataCurrent', JSON.stringify({ code: item.code, name: item.name }))
    dispatch({
      type: "SET_ENUM",
      payload: {
        dataCurrent: { code: item.code, name: item.name }
      }
    })
    handleCloseData()
  }

  const handleLng = (e) => {
    setLng(e.target.value)
    changeLanguage(e.target.value)
    localStorage.setItem('lng', e.target.value)
  }

  const handleDrawerOpen = () => {
    // setOpen(true);
    dispatch({
      type: "SET_ENUM",
      payload: {
        openDrawer: true
      }
    })
  };

  const handleDrawerClose = () => {
    // setOpen(false);
    dispatch({
      type: "SET_ENUM",
      payload: {
        openDrawer: false
      }
    })
  };

  const activeMenu = (moduleAc, name) => {
    dispatch({
      type: "SET_ENUM",
      payload: {
        activeModule: moduleAc,
        activeName: name
      }
    })
    localStorage.setItem('activeModule', moduleAc)
    localStorage.setItem('activeName', name)
  }

  const hanldeCreate = () => {
    dispatch({
      type: "SET_ENUM",
      payload: {
        actionAdd: true,
      }
    })
  }

  const handleSetNavigate = (item) => {

    dispatch({
      type: "SET_ENUM",
      payload: {
        navigate: item.navigate,
        activeModule: item.module,
        activeName: t(`${item.name}`)
      }
    })

    localStorage.setItem('navigate', item.navigate)
    localStorage.setItem('activeModule', item.module)
    localStorage.setItem('activeName', t(`${item.name}`))
  }


  const [openFeedBack, setOpenFeedBack] = React.useState(false);

  const handleFeedBack = () => {
    setOpenFeedBack(true);
  };

  const handleCloseFB = () => {
    setOpenFeedBack(false);
  };


  const langs = i18n.language || lng

  const _handleSearch = () => {
    setOpenBackDrop(true)
  }

  const handleClose = (e, reason) => { //"backdropClick" | "escapeKeyDown"
    if (e.target.classList.contains('bgDropCus')) {
      setOpenBackDrop(false);
    } else {
      console.log(e.target.nodeName)
    }
  };

  let dtTmp = [...menuData, ...menuData1, ...menuData2]
  let dtChild = dtTmp.filter(x => x.type === 'child')
  let dataSearchAll = dtChild.map(function (ele) {
    return { ...ele, lang: t(ele?.name) };
  })
  dataSearchAll = orderBy(dataSearchAll, ['module'], ['asc']);

  if (keySearch) {
    dataSearchAll = dataSearchAll.filter(item => {
      let main = item?.lang.toLowerCase()
      return main.includes(keySearch.toLowerCase())
    });
  }

  const styleTopBar = useSpring({
    opacity: !isEmpty(dataFromPage) ? 1 : 0,
    background: !isEmpty(dataFromPage) ? '#fff' : '#F7FAFC'
  })

  const styleTopClock = useSpring({
    opacity: !isEmpty(dataFromPage) ? 0 : 1,
    width: !isEmpty(dataFromPage) ? 0 : 'auto',
    overflow: !isEmpty(dataFromPage) ? 'hidden' : 'auto',
  })

  const _renderMainMenu = () => {
    return <Menu
      id="app-positioned-menu"
      aria-labelledby="app-positioned-button"
      anchorEl={anchorEl}
      open={openMenuApp}
      onClose={handleCloseApp}
      sx={{
        '& .MuiMenu-paper': {
          top: '48px!important',
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}>
      <Grid container spacing={0.5}
        sx={{
          p: 1,
          width: 330,
          pt: 3
        }}>

        <Grid item xs={4} justifyContent='center' mb={3}>
          <Box display={'flex'}
            onClick={() => activeMenu('WORK', t('headMenuWorking'))}
            flexDirection='column' justifyContent='center' alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <Avatar
              sx={{ width: 52, height: 52, mb: 1.5 }}
              alt="Working & Operating" src="/resources/icons/work.png" />
            <Typography variant='body1' textAlign={'center'} color="#5d5e60">
              {t('headMenuWorking')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} mb={3}>
          <Box display={'flex'} flexDirection='column'
            onClick={() => activeMenu('Accounting and Financial', t('headMenuAccounting'))}
            justifyContent='center' alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <Avatar
              sx={{ width: 52, height: 52, mb: 1.5 }}
              alt="Accounting & Financial" src="/resources/icons/invoice.png" />
            <Typography variant='body1' textAlign={'center'} color="#5d5e60">
              {t('headMenuAccounting')}
            </Typography>
          </Box>
        </Grid>



        <Grid item xs={4} justifyContent='center' mb={3}>
          <Box display={'flex'}
            onClick={() => activeMenu('SM', t('headMenuSales'))}
            flexDirection='column' justifyContent='center' alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <Avatar
              sx={{ width: 52, height: 52, mb: 1.5 }}
              alt="Human Development Management" src="/resources/icons/sale.png" />
            <Typography variant='body1' textAlign={'center'} color="#5d5e60">
              {t('headMenuSales')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4} justifyContent='center' mb={3}>
          <Box display={'flex'}
            onClick={() => activeMenu('WEB', t('headMenuWebsite'))}
            flexDirection='column' justifyContent='center' alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <Avatar
              sx={{ width: 52, height: 52, mb: 1.5 }}
              alt="Website Management" src="/resources/icons/internet.png" />
            <Typography variant='body1' textAlign={'center'} color="#5d5e60">
              {t('headMenuWebsite')}
            </Typography>
          </Box>
        </Grid>


        <Grid item xs={4} justifyContent='center' mb={3}>
          <Box display={'flex'}
            onClick={() => activeMenu('IM', t('headMenuWarehouse'))}
            flexDirection='column' justifyContent='center' alignItems={'center'}
            style={{
              cursor: 'pointer',
              transition: 'all .4s ease-in-out',
              '&hover': {
                transform: 'scale(1.2)'
              }
            }}>
            <Avatar
              sx={{ width: 52, height: 52, mb: 1.5 }}
              alt="Human Development Management" src="/resources/icons/warehouse.png" />
            <Typography variant='body1' textAlign={'center'} color="#5d5e60">
              {t('headMenuWarehouse')}
            </Typography>
          </Box>
        </Grid>



        <Grid item xs={4} justifyContent='center'>
          <Box display={'flex'}
            onClick={() => activeMenu('ASM', t('headMenuAssets'))}
            flexDirection='column' justifyContent='center' alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <Avatar
              sx={{ width: 52, height: 52, mb: 1.5 }}
              alt="Assets+ Management" src="/resources/icons/assets.png" />
            <Typography variant='body1' textAlign={'center'} color="#5d5e60">
              {t('headMenuAssets')}
            </Typography>
          </Box>
        </Grid>


        <Grid item xs={4} justifyContent='center' mb={3}>
          <Box display={'flex'}
            onClick={() => activeMenu('HDM', t('headMenuHuman'))}
            flexDirection='column' justifyContent='center' alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <Avatar
              sx={{ width: 52, height: 52, mb: 1.5 }}
              alt="Human Development Management" src="/resources/icons/hrm.png" />
            <Typography variant='body1' textAlign={'center'} color="#5d5e60">
              {t('headMenuHuman')}
            </Typography>
          </Box>
        </Grid>


        <Grid item xs={4} justifyContent='center'>
          <Box display={'flex'}
            onClick={() => activeMenu('MF', t('headMenuManfact'))}
            flexDirection='column' justifyContent='center' alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <Avatar
              sx={{ width: 52, height: 52, mb: 1.5 }}
              alt="Manufacturing" src="/resources/icons/facture.png" />
            <Typography variant='body1' textAlign={'center'} color="#5d5e60">
              {t('headMenuManfact')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Menu>
  }

  const _renderMainChat = () => {
    return <Menu
      id="app-positioned-chat"
      aria-labelledby="app-positioned-button"
      anchorEl={anchorElChat}
      open={openChat}
      onClose={handleCloseOpenChat}
      sx={{
        '& .MuiMenu-paper': {
          top: '48px!important',
          width: '320px'
        },
        '& .main-ListChat': {
          paddingRight: '12px!important',
          padding: '8px'
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}>
      <LastedMessagePop />
      <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 13, color: '#009688' }}>
        <Link href='/chat' onClick={() => handleSetNavigate({
          navigate: 'chat',
          module: localStorage.getItem('activeModule'),
          name: 'Chat'
        })} underline="none" color="inherit">
          View all
        </Link>
      </div>

    </Menu>
  }

  return (
    <>
      <Box sx={{ display: 'flex' }} className="topMenu">
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={0}>
          <Toolbar sx={{ paddingLeft: '16px!important', justifyContent: 'space-between' }}>
            <div className='itemNav'>
              <div className='titNav' style={{ width: drawerWidth - 24, display: 'flex', alignItems: 'center' }}>
                {open ? <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerClose}
                  edge="start"
                  sx={{
                    marginRight: 1,
                    marginLeft: 0,
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton> :
                  <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      marginRight: 3,
                      marginLeft: 0,
                    }}>
                    <MenuIcon />
                  </IconButton>
                }
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" lineHeight={1.1}>
                    {activeModule || '3BGROUP'}
                  </Typography>
                  <Typography variant="body2" fontSize={9} noWrap>{activeName}</Typography>
                </div>

              </div>

            </div>

            <Search sx={{
              mr: 0,
            }}>
              {/* <SearchIconWrapper>
                <SearchIcon sx={{ color: 'black' }} />
              </SearchIconWrapper> */}
              {/* <StyledInputBase
                placeholder="Search..."
                inputProps={{ 'aria-label': 'search' }}
              /> */}
              {
                !isEmpty(dataFromPage) ? <>
                  <animated.div className='topStatus' style={styleTopBar}>
                    <div className='itemTopStatus'>
                      <Typography variant='h5' color={'#009688'}>{dataFromPage?.totalSelectAll}</Typography>
                      <Typography variant='caption'>Documents</Typography>
                    </div>
                    <div className='itemTopStatus'>
                      <Typography variant='h5' color={'#2196F3'}>{ccyFormat(dataFromPage?.sumSelectAll)}</Typography>
                      <Typography variant='caption'>Total</Typography>
                    </div>
                  </animated.div>
                </> :
                  <animated.div style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: ' 6px 12px',
                    borderRadius: '4px',
                    justifyContent: 'space-between',
                    paddingRight: '48px',
                    ...styleTopClock
                  }}>
                    <div style={{ display: 'flex' }}>
                      {statusFilter?.isApply === true ?
                        <>
                          <div className='itemTopStatus'>
                            <Typography variant='h6' color={'#009688'}>{moment(statusFilter?.dateStart).format('DD/MM/YYYY')}</Typography>
                            <Typography variant='caption'>From</Typography>
                          </div>
                          <div className='itemTopStatus'>
                            <Typography variant='h6' color={'#009688'}>{moment(statusFilter?.dateEnd).format('DD/MM/YYYY')}</Typography>
                            <Typography variant='caption'>To</Typography>
                          </div>
                          <div className='itemTopStatus'>
                            <Typography variant='body2'>
                              Debit: {statusFilter?.debitAccount.length > 0 ? statusFilter?.debitAccount?.map(x => { return x + ', ' }) : 'All'}
                            </Typography>
                            <Typography variant='body2'>
                              Credit: {statusFilter?.creditAccount.length > 0 ? statusFilter?.creditAccount?.map(x => { return x + ', ' }) : 'All'}
                            </Typography>
                          </div>
                          <div className='itemTopStatus'>
                            <Typography variant='body2'>
                              Department: {!isEmpty(statusFilter?.department) ? statusFilter?.department?.name : 'All'}
                            </Typography>
                            <Typography variant='body2'>
                              Expense: {!isEmpty(statusFilter?.expense) ? statusFilter?.expense?.name : 'All'}
                            </Typography>
                          </div>
                        </> : <></>
                      }

                    </div>

                    {/* <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <div style={{ width: 100 }}>
                        <Clock format="HH:mm:ss"
                          locale='en'
                          style={{ fontSize: '1.75em', lineHeight: '24px' }}
                          interval={1000} ticking={true} />
                      </div>
                      <span style={{ marginLeft: 4 }}>{moment().format('dddd') + ', ' + moment().format('LL')}</span>
                    </div> */}
                  </animated.div>

              }


              <SearchIconWrapper1 onClick={() => _handleSearch()}>
                {/* <MoreVertOutlinedIcon sx={{ color: 'black' }} /> */}
                <SearchIcon sx={{ color: 'black' }} />
              </SearchIconWrapper1>
            </Search>

            <div className='itemNav'>
              <ButtonBase
                variant="text"
                color="inherit"
                aria-label="help"
                aria-controls={openAnchorData ? 'basic-menu-help' : undefined}
                aria-haspopup="true"
                aria-expanded={openAnchorData ? 'true' : undefined}
                onClick={handleOpenData}
                edge="start"
                sx={{
                  marginLeft: 0,
                  width: 150,
                  background: '#fff',
                  padding: 1,
                  border: '1px solid #fff',
                  borderRadius: 4,
                  justifyContent: 'flex-start',
                  paddingLeft: 2,
                  color: '#4444444'
                }}>
                <div style={{
                  width: 10, height: 10, background: '#009688', borderRadius: 10, marginRight: 8
                }}></div> Working on {dataCurrent?.code || ''}
              </ButtonBase>

              <Link underline="none" color="inherit">
                <IconButton
                  color="inherit"
                  aria-label="help"
                  aria-controls={openHelp ? 'basic-menu-help' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openHelp ? 'true' : undefined}
                  onClick={handleOpenHelp}
                  edge="start"
                  sx={{
                    marginLeft: 0,
                  }}>
                  <HelpOutlineOutlinedIcon />
                </IconButton>
              </Link>

              <Menu
                id="basic-menu-help"
                anchorEl={anchorElHelp}
                open={openHelp}
                onClose={handleCloseHelp}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem> <ListItemIcon>
                  <InfoOutlinedIcon fontSize="small" />
                </ListItemIcon> Help</MenuItem>
                <MenuItem onClick={handleFeedBack}> <ListItemIcon>
                  <ChatBubbleOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon> Feedback</MenuItem>
              </Menu>

              <Menu
                id="basic-menu-data-located"
                anchorEl={anchorData}
                open={openAnchorData}
                onClose={handleCloseData}
                sx={{ minWidth: 150 }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {dataLocated?.map((item, ind) => {
                  let isExistData = find(profile?.dataCode, { code: item?.code })
                  return isExistData && <MenuItem key={ind} onClick={() => handleChooseData(item)}>
                    <div className='lineMenuTop'>
                      <div className='topMenuTop'>
                        {dataCurrent?.code === item?.code ? <div className='online'></div> : <div className='offline'></div>}
                        {item?.code}
                      </div>
                      <div className='briefMenuTop'>
                        <Typography
                          noWrap={true}
                          variant="body2"
                          color="text.primary"
                        >
                          {item?.name}
                        </Typography>
                      </div>
                    </div>

                  </MenuItem>
                })}
              </Menu>

              {openFeedBack && <FeedBack handleClose={handleCloseFB} open={openFeedBack} />}

              <Link href="/setting" underline="none" color="inherit">
                <IconButton
                  color="inherit"
                  aria-label="close drawer"
                  edge="start"
                  sx={{
                    marginLeft: 0,
                  }}>
                  <Settings />
                </IconButton>
              </Link>

              <IconButton
                color="inherit"
                aria-label="close drawer"
                edge="start"
                onClick={handleOpenApp}
                sx={{
                  marginLeft: 0,
                }}>
                <AppsOutlinedIcon />
              </IconButton>

              <IconButton
                color="inherit"
                aria-label="close drawer"
                edge="start"
                onClick={handleOpenChat}
                sx={{
                  marginLeft: 0,
                }}>
                <ModeCommentOutlinedIcon />
              </IconButton>

              {_renderMainChat()}

              {_renderMainMenu()}

              <Select sx={{
                boxShadow: 'none',
                minWidth: 72,
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '& :focus': {

                }
              }}
                labelId="demo-select-lng"
                id="demo-select-lng"
                size="small"
                value={langs}
                onChange={handleLng}>
                <MenuItem value='la'>LA</MenuItem>
                <MenuItem value='en'>EN</MenuItem>
                <MenuItem value='cn'>CN</MenuItem>
              </Select>

              <AccountMenu />
            </div>

          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent" open={open}>
          <Toolbar />
          {open ? <Button variant='contained'
            onClick={hanldeCreate}
            disableElevation
            sx={{
              marginTop: 2,
              justifyContent: 'flex-start', height: 48, ml: 2, mr: 2,
              backgroundColor: blue[500],
              fontWeight: 'bold'
            }}>
            <AddOutlinedIcon sx={{ mr: 2 }} /> {t('btnCreate')}
          </Button> : <>
            <div className='btnCreateAll' onClick={hanldeCreate}>
              <AddOutlinedIcon />
            </div>
          </>}

          <Box sx={{ overflow: 'auto', height: "calc(100vh - 166px)" }}>

            <List
              subheader={<li />}
              sx={{ p: 1 }}>
              {menu0.map((item, index) => (
                item?.type === 'head' && open
                  ?
                  <ListSubheader disableSticky={true} sx={{ background: 'transparent' }} key={index}>{item?.name}</ListSubheader>
                  :
                  item?.type === 'child' && <ListItem
                    key={index}
                    className={`${item.navigate === navigate ? 'activeLeftNavigate' : ''}`}
                    onClick={() => handleSetNavigate(item)}
                    component={Link} disablePadding sx={{ display: 'block' }}
                    href={item?.type === 'child' ? item.path : ''}>
                    <ListItemButton
                      sx={{
                        minHeight: 36,
                        justifyContent: open ? 'initial' : 'center',
                        color: '#575757',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.common.white, 1),
                          borderRadius: 24,
                          boxShadow: 0.5
                        },
                      }} >

                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}>
                        {item?.icon}
                      </ListItemIcon>
                      <ListItemText primary={t(`${item?.name}`) || 'menu'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
              ))}
            </List>

            <Divider />

            <List sx={{ p: 1 }}>
              {menu1.map((item, index) => (
                item?.type === 'head' && open
                  ?
                  <ListSubheader key={index} disableSticky={true} sx={{ background: 'transparent' }}>{item?.name}</ListSubheader>
                  :
                  <ListItem
                    key={index}
                    className={`${item.navigate === navigate ? 'activeLeftNavigate' : ''}`}
                    onClick={() => handleSetNavigate(item)}
                    component={Link} disablePadding sx={{ display: 'block' }}
                    href={item?.type === 'child' ? item.path : ''}>
                    <ListItemButton
                      sx={{
                        minHeight: 36,
                        justifyContent: open ? 'initial' : 'center',
                        color: '#575757',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.common.white, 1),
                          borderRadius: 24,
                          boxShadow: 0.5
                        }
                      }} >

                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}>
                        {item?.icon}
                      </ListItemIcon>
                      <ListItemText primary={t(`${item?.name}`) || 'menu'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
              ))}
            </List>
            <Divider />

            <List sx={{ p: 1 }}>
              {menu2.map((item, index) => (
                <ListItem key={index} component={Link} disablePadding sx={{ display: 'block' }} href={item.path}>
                  <ListItemButton
                    sx={{
                      minHeight: 36,
                      justifyContent: open ? 'initial' : 'center',
                      color: '#575757',

                      '&:hover': {
                        backgroundColor: alpha(theme.palette.common.white, 1),
                        borderRadius: 24,
                        boxShadow: 0.5
                      }
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {item?.icon}
                    </ListItemIcon>
                    <ListItemText primary={t(`${item?.name}`) || 'menu'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

          </Box>
          <Box position='absolute' bottom={0}
            style={{
              display: 'flex', flexDirection: 'column',
              borderTop: '0.5px solid #e6e6e6', background: '#f8fafc',
              alignItems: 'center', padding: 16, width: '100%'
            }}>
            <Typography variant='caption' textAlign={'center'}>
              {open ? 'ONE IC Platform' : 'ONE IC'}
            </Typography>
          </Box>

        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 0, position: 'relative' }}>
          <DrawerHeader className="noneHeaderMain" />
          {props?.children}
          <VerticalMenu handleSetNavigate={handleSetNavigate} />
        </Box>
      </Box >

      <MainAlert />

      <TopAlert />

      <ListMinimizeChat />

      <Backdrop
        sx={{ color: '#fff', backgroundColor: '#000000d9', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
        className='bgDropCus'
        onClick={handleClose}>
        <Box sx={{
          p: 3, display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', height: '90vh', width: '85%', borderRadius: 2, background: '#ffffff03'
        }}>
          <div className='inputSearchAll'>
            <TextField
              sx={{
                input: {
                  "&::placeholder": {
                    color: "#ffff"
                  }
                }
              }}
              value={keySearch} onChange={(e) => setKeySearch(e.target.value)} placeholder='Can I help you?' />
          </div>

          <div className='containerSearchAll'>
            {dataSearchAll.map((item, index) => (
              item?.type === 'child' && <div
                key={index}
                className={`itemSearchAll`}
                onClick={() => handleSetNavigate(item)}>
                {item?.icon}
                <Typography>{t(`${item?.name}`) || 'menu'}</Typography>
                <Typography variant='caption'>{t(`${item?.module}`) || ''}</Typography>
              </div>
            ))}
          </div>
        </Box>
      </Backdrop>
    </>
  );
}

const mapDispatchToProps = {
  getLastestMessage, getData
}

export default connect(null, mapDispatchToProps)(DrawerExpand);
