import { combineReducers } from 'redux'
import auth from './auth/index'
import profile from './profile/index'
import alert from './alert/index'
import user from './user/index'
import edit from './edit/index'
import language from './language/index'
import accountant from './accountant/index'
import department from './department/index'
import expense from './expense/index'
import currency from './currency/index'
import income from './income/index'
import outcome from './outcome/index'
import creditNote from './creditNote/index'
import debitNote from './debitNote/index'
import advancePayment from './advancePayment/index'
import rule from './rule/index'
import vat from './vat/index'
import data from './data/index'
import financialYear from './financial/index'
import bankAccount from './bankAccount/index'
import beginningBalance from './beginningBalance/index'
import task from './task/index'
import taskDetail from './taskDetail/index'
import minimizeChat from './minimizeChat/index'
import detailMinimizeChat from './detailMinimizeChat/index'
import minimizeChatCode from './minimizeChatCode/index'
import taskComment from './taskComment/index'
import project from './project/index'
import demand from './demand/index'
import team from './team/index'
import partner from './partner/index'
import product from './product/index'
import chance from './chance/index'
import chanceNote from './chanceNote/index'
import permission from './permission/index'
import userPermission from './userPermission/index'
import additional from './additional/index'
import paymentDetail from './paymentDetail/index'
import editPaymentDetail from './editPaymentDetail/index'
import enumRedux from './enum/index'
import docComment from './documentComment/index'
import permissionGroup from './permissionGroup/index'
import duplicate from './duplicate/index'
import langProperty from './langProperty/index'
import journalEntrie from './journalEntrie/index'
import feedBack from './feedBack/index'
import requestQueue from './requestQueue/index'
import request from './request/index'
import requestTemplate from './requestTemplate/index'
import employee from './employee/index'
import config from './config/index'
import partnerGroup from './partnerGroup/index'
import settlement from './settlement/index'

import chatGroup from './chatGroup/index'
import chatMessageLasted from './chatMessageLasted/index'
import chatNewMessage from './chatNewMessage/index'
import currentChatMessage from './currentChatMessage/index'
import chatUserOnline from './chatUserOnline/index'
import typingMessage from './typingMessage/index'
import readMoney from './readMoney/index'
import log from './log/index'
import exchangeRate from './exchangeRate/index'
import updateLog from './updateLog/index'

const rootReducer = combineReducers({
    auth,
    profile,
    alert,
    user,
    edit,
    language,
    bankAccount,
    accountant,
    department,
    expense,
    currency,
    income,
    outcome,
    rule,
    product,
    vat,
    project,
    docComment,
    duplicate,
    demand,
    team,
    paymentDetail,
    editPaymentDetail,
    partner,
    chance,
    chanceNote,
    permission,
    userPermission,
    additional,
    enumRedux,
    data,
    financialYear,
    beginningBalance,
    permissionGroup,
    creditNote,
    debitNote,
    langProperty,
    journalEntrie,
    feedBack,
    requestQueue,
    request,
    requestTemplate,
    employee,
    task,
    taskDetail,
    config,
    taskComment,
    chatGroup,
    chatMessageLasted,
    chatNewMessage,
    chatUserOnline,
    currentChatMessage,
    typingMessage,
    readMoney,
    log,
    minimizeChat,
    detailMinimizeChat,
    minimizeChatCode,
    exchangeRate,
    updateLog,
    advancePayment,
    partnerGroup,
    settlement
})

export default rootReducer 
