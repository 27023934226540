import * as React from 'react';

import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { teal } from '@mui/material/colors';
import { connect, useSelector } from 'react-redux';
import { createChance } from '../../../actions/chance';
import { getAllData as getPartner, updatePartner } from '../../../actions/partner';
import '../partner/style.css';

import { getAllDataNoneRedux as getUser, getUserById } from '../../../actions/user';

import { uploadFile } from '../../../actions/file';
import { getAllData as getOption } from '../../../actions/language';
import moment from 'moment';



function EditForm(props) {
    const editObject = useSelector(state => state.edit)


    console.log(editObject)


    return (
        <Box sx={{
            bgcolor: 'background.paper', flex: 1, position: 'relative',
            paddingBottom: 4,
        }}>
            <Box sx={{ paddingX: 2 }}>

                <Grid container spacing={2}>
                  
                    <Grid item xs={12}>
                        <Typography variant='title' color={teal[500]}>
                            Khách hàng
                        </Typography>
                        <div className='contentBox'>
                            <div className='lineBox'>
                                <div className='headBox'>
                                    Tên:
                                </div>
                                <div className='detailBox'>
                                    {editObject?.customer?.firstName} {editObject?.customer?.lastName}
                                </div>
                            </div>
                            <div className='lineBox'>
                                <div className='headBox'>
                                    Điện thoại:
                                </div>
                                <div className='detailBox'>
                                    {editObject?.customer?.phone?.fullPhoneNumber}
                                </div>
                            </div>
                            <div className='lineBox'>
                                <div className='headBox'>
                                   Tài chính:
                                </div>
                                <div className='detailBox'>
                                    {editObject?.customer?.financialAssessment?.name}
                                </div>
                            </div>
                            <div className='lineBox'>
                                <div className='headBox'>
                                  Tạo:
                                </div>
                                <div className='detailBox'>
                                    {moment(editObject?.created).format('DD-MM-YYYY hh:ss')}
                                </div>
                            </div>

                            <div className='lineBox'>
                                <div className='detailBox' style={{borderLeft:'3px solid #5c5c5c', 
                                fontStyle:'italic',
                                paddingLeft: '12px'}}>
                                    {editObject?.description}
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='title' color={teal[500]}>
                           Dự án quan tâm
                        </Typography>
                        <div className='contentBox'>
                            {editObject?.projects?.map((dt,i)=>{
                                return <div className='lineBox' key={i}>
                                <div className='headBox'>
                                    {i+1}.
                                </div>
                                <div className='detailBox'>
                                    {dt?.name} 
                                </div>
                            </div>
                            })}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='title' color={teal[500]}>
                            Theo dõi
                        </Typography>
                        <div className='contentBox'>
                            {editObject?.trackers?.map((item, key)=>{
                                return  <div className='lineBox' key={key}>
                                      <div className='headBox'>
                                 {key+1}.
                                </div>
                                <div className='detailBox'>
                                    {item?.user?.firstName} {item?.user?.lastName}
                                </div>
                            </div>
                            })}
                           
                           
                        </div>
                    </Grid>

                </Grid>
            </Box>

 
        </Box>
    );
}


const mapDispatchToProps = {
    updatePartner, getOption, uploadFile, getUser, getUserById, createChance, getPartner
}

export default connect(null, mapDispatchToProps)(EditForm);