import { TextField, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { getAllData, createDocumentComment } from '../../../../actions/documentComment';
import { connect, useSelector } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';


const DialogComment = (props) => {
    const [open, setOpen] = React.useState(false);

    const listData = useSelector(state => state.docComment) || []
    const [content, setContent] = useState("")

    React.useEffect(() => {
        handleClickOpen()
    }, [props?.isOpen])

    React.useEffect(() => {
        const init = async () => {
            let condition = {
                condition: {
                    documentCode: { equal: props?.data?.code }
                },
                sort: {
                    created: -1
                }
            }
            await props?.getAllData(condition)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    console.log(listData);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props?.handleClose(false)
    };

    const handleComment = () => {
        props?.createDocumentComment({
            documentCode: props?.data?.code,
            content: content
        })
            .then(res => {
                console.log(listData)
            })
    }

    return (
        <Dialog
            open={open}
            maxWidth='sm'
            fullWidth={true}
            scroll={'paper'}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >

            <DialogTitle id="alert-dialog-title"
                sx={{
                    background: '#2196f3'
                }}>
                <Box display='flex' justifyContent={"space-between"} sx={{ color: '#fff' }}>
                    {"Comment"}
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display='flex' justifyContent={"space-between"} alignItems="center" mt={3}>
                    <TextField fullWidth id="fullWidth"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder='Write your comment...' />
                    <Button
                        onClick={handleComment}
                        sx={{
                            marginLeft: 1.5
                        }}>SEND</Button>
                </Box>
                <Box sx={{ paddingY: 2 }}>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="subttile1"
                        color="text.primary">
                        Comments
                    </Typography>
                    <List sx={{ width: '100%', bgcolor: 'background.paper', paddingY: 0 }}>
                        {listData?.map((res, index) => {
                            return <ListItem alignItems="flex-start" sx={{ paddingX: 0, pb: 0 }}>
                                <ListItemAvatar>
                                    <Avatar alt="U" src="/static/images/avatar/1.jpg" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                            {res?.creater?.firstName + ' ' + res?.creater?.lastName}
                                            <span style={{fontSize:'10px', color:'#808080'}}>
                                                {moment(res?.created).startOf('hour').fromNow()}
                                            </span>
                                        </div>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            {res?.content}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        })}

                    </List>
                </Box>
            </DialogContent>
            <DialogActions sx={{ borderTop: '1px solid #acacac' }}>
                <Button onClick={handleClose}>Cancel</Button>

            </DialogActions>
        </Dialog>
    );
}

const mapDispatchToProps = {
    getAllData, createDocumentComment
}

export default connect(null, mapDispatchToProps)(DialogComment);