const ccyFormat = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

const dollarFormat = (num) => {
  let check = num % 1
  if (check !== 0)
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  else
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const renderNumberDollar = (number) => {
  let isNeg = Number(number) < 0 ? true : false
 
  if (Number(number) === 0) {
    return <span className='fmNumber'>-</span>
  }

  if (isNeg) {
    return <span className='fmNumber' style={{ color: '#E91E63' }}>({dollarFormat(Math.abs(number))})</span>
  }
  return <span className='fmNumber'>{dollarFormat(Math.abs(number))}</span>
}

const renderNumberCcy = (number) => {
  let isNeg = Number(number) < 0 ? true : false

  if (Number(number) === 0) {
    return <span className='fmNumber'>-</span>
  }

  if (isNeg) {
    return <span className='fmNumber' style={{ color: '#E91E63' }}>({ccyFormat(Math.abs(number))})</span>
  }
  return <span className='fmNumber'>{ccyFormat(Math.abs(number))}</span>
}

export { ccyFormat, dollarFormat, renderNumberDollar, renderNumberCcy }