import { findIndex } from "lodash"

const initialState = []

const newMessageReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_CHAT_NEW_MESSAGE":
            return action.payload

        case "SET_CHAT_NEW_MESSAGE_DUPPLICATE":
            let tmp = [...state]
            let indexs = findIndex(tmp, { _id: action.payload._id })
            if (indexs > -1) {
                tmp.splice(indexs, 1)
                return [action.payload, ...state]
            }
            return [action.payload, ...state]

        case "CREATE_CHAT_NEW_MESSAGE":
            return [...state, action.payload]

        case "UPDATE_CHAT_NEW_MESSAGE":
            const index = state.findIndex(per => per.groupChatCode === action.payload.groupChatCode)
            state[index] = action.payload;

            return [...state]

        case "UPDATE_CHAT_NEW_MESSAGE_ACTIVE":
            if (action.payload.length > 0) {
                (action.payload).map((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    return state[index] = item;
                })
            }
            return [...state]

        case "DELETE_CHAT_NEW_MESSAGE":
            var data_deleted = []
            if (action.payload.length > 0) {
                data_deleted = state.filter(del => !(action.payload).includes(del._id));
            }
            return data_deleted


        default:
            break;
    }

    return state
}

export default newMessageReducer