import * as React from 'react';
import { useEffect, useState } from 'react';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { teal } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { differenceWith, find, findIndex, isEqual, mapValues, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { getAllDataNoneRedux as getEmployee } from '../../../actions/employee';
import { getAllData as getPermission } from '../../../actions/permission';
import { getAllData as getPermissionGroup } from '../../../actions/permissionGroup';
import { createUserTele, updateUserTele } from '../../../actions/telesale';
import { getUserById, getUserPermission, updateUser, updateUserPermission } from '../../../actions/user';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';

const initialState = {
    firstName: "",
    lastName: "",
    passwordNew: "",
    userName: "",
    userType: '',
    isActive: true,
    code: '',
    phone: '',
    _id: '',

    "leaderIdArr": [],
};


function EditForm(props) {
    const { t } = useTranslation();

    const theme = useTheme()
    const editObject = useSelector(state => state.edit)
    const listUser = useSelector(state => state.user)
    const [currentActions, setCurrentActions] = useState({})
    const [userPermission, setUserPermission] = useState([])
    const userPermissionRedux = useSelector(state => state.userPermission)

    const [
        { isCreateTele, firstName, lastName, userName, userType, isActive, employeeId,
            listEmployee,
            code, passwordNew, phone, _id, leaderIdArr, telesale, chooseGroup },
        setState
    ] = useState({ ...editObject, isCreateTele: false });

    const getDataById = () => {
        props?.getUserById(editObject?._id).then(res => {
            let telesale = res?.data?.telesale || {}

            let data = {
                leaderIdArr: telesale?.leaderIdArr || [],
                phone: res?.data?.phone?.phoneNumber
            }
            if (!telesale?._id) {
                data.isCreateTele = true
            }
            setState(state => ({ ...state, ...res.data, ...data }))
        })
            .catch(err => {
                return
            })
    }

    useEffect(() => {
        setUserPermission(userPermissionRedux)
    }, [userPermissionRedux])


    useEffect(() => {

        const init = async () => {
            await props?.getPermission()
            await props?.getPermissionGroup()
            await props?.getUserPermission(editObject?._id)
            await props?.getEmployee()
                .then(res => {
                    setState(state => ({ ...state, listEmployee: res?.data }))
                })
        }
        init()
        getDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeList = (e) => {
        let leaderIdArrTemp = [...leaderIdArr]
        let value = e.target.value
        let index = leaderIdArrTemp.indexOf(value)
        if (index > -1) {
            leaderIdArrTemp.splice(index, 1)
        }
        else {
            leaderIdArrTemp.push(value)
        }
        setState(state => ({ ...state, leaderIdArr: leaderIdArrTemp }));
    }

    const removeFromList = (index) => {
        let leaderIdArrTemp = [...leaderIdArr]
        leaderIdArrTemp.splice(index, 1)
        setState(state => ({ ...state, leaderIdArr: leaderIdArrTemp }));
    }

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const customeGroupChange = (name, value) => {

        if (name === 'chooseGroup') {
            if (value !== chooseGroup) {
                let group = find(props?.permissionGroup, { _id: value })
                let newPermission = []
                if (group) {
                    let details = group.details
                    mapValues(details, (vl, keys) => {
                        vl.userId = _id
                    })
                    newPermission = [...userPermission, ...details]
                    newPermission = uniqBy(newPermission, 'permissionId')
                }
                setState(state => ({ ...state, [name]: value }));
                setUserPermission(newPermission)
            }
            else {
                let group = find(props?.permissionGroup, { _id: value })
                let newPermission = []
                if (group) {
                    let details = group.details
                    mapValues(details, (vl, keys) => {
                        vl.userId = _id
                    })
                    newPermission = [...userPermission, ...details]
                    newPermission = differenceWith(userPermission, details, isEqual);
                }
                setState(state => ({ ...state, [name]: '' }));
                setUserPermission(newPermission)
            }
        }
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = () => {
        let data = {
            firstName, lastName, userName, passwordNew, employeeId, userType, isActive, code, phone: {
                "nationCode": "+84",
                "phoneNumber": phone
            }
        }

        if (userType === 'telesale') {
            data.userId = _id
            data.leaderIdArr = leaderIdArr
        }

        if(passwordNew)
        {
            data.password = passwordNew
        }

        props?.updateUser(data, _id)
            .then(res => {
                if (userType === 'telesale') {
                    if (isCreateTele === true) {
                        props?.createUserTele(data)
                    }
                    else {
                        props?.updateUserTele(data, telesale?._id)
                    }

                }
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }

    const choosePermission = (val) => {
        setCurrentActions({ ...val })
        let userPermissonTmp = [...userPermission]
        let index = findIndex(userPermissonTmp, { permissionId: val._id })
        let valTmp = { ...val }
        valTmp.permissionId = valTmp?._id
        if (index > -1) {
            userPermissonTmp.splice(index, 1)
        }
        else {
            userPermissonTmp.push(valTmp)
        }

        setUserPermission(userPermissonTmp)
    }

    const detailPermission = (val) => {
        setCurrentActions({ ...val })
    }

    const chooseActionPermisison = (val) => {
        let userPermissonTmp = [...userPermission]
        let index = findIndex(userPermissonTmp, { permissionId: currentActions._id })
        let actions = [...userPermissonTmp[index].actions]
        let indexAct = actions.indexOf(val)

        if (indexAct > -1) {
            actions.splice(indexAct, 1)
        }
        else {
            actions.push(val)
        }
        userPermissonTmp[index].actions = actions
        setUserPermission(userPermissonTmp)
    }

    const updateUserPermission = () => {
        let userPerTmp = [...userPermission]
        props?.updateUserPermission({ permissionArr: userPerTmp }, editObject?._id)
    }

    return (

        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8 }}>
            <Stack direction="row" spacing={1}
                justifyContent='space-between'
                alignItems="center" sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.main
                }}>
                <Stack direction={'row'}>
                    <Chip label={t('lblUpdate')} variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
                    <Typography variant='h5' color={'#fff'}>
                        {t('frmUserTitle')}
                    </Typography>
                </Stack>
                <Button variant='contained' disableElevation onClick={() => handleSubmit()}>
                    {t('btnSubmit')}
                </Button>
            </Stack>

            <Box sx={{ paddingX: 2, paddingY: 4, pb: 2 }}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color={teal[500]}>
                            {t('lblGeneralInfo')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserFirstName')}
                                    id="first-name"
                                    value={firstName}
                                    InputLabelProps={{ shrink: firstName ? true : false }}
                                    name="firstName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserLastName')}
                                    id="last-name"
                                    InputLabelProps={{ shrink: lastName ? true : false }}
                                    value={lastName}
                                    name="lastName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label={t('frmUserActive')} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserCode')}
                                    id="code"
                                    value={code}
                                    name="code"
                                    InputLabelProps={{ shrink: code ? true : false }}
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="demo-select-small">Loại</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={userType}
                                name="userType"
                                onChange={onChange}
                                label={t('frmUserType')}
                            >
                                <MenuItem value="">
                                    <em>{t('lblNone')}</em>
                                </MenuItem>
                                <MenuItem value='root'>{t('frmUserTypeRoot')}</MenuItem>
                                {/* <MenuItem value='manager'>Quản lý</MenuItem>
                                <MenuItem value='leader'>Trưởng nhóm</MenuItem>
                                <MenuItem value='telesale'>Tele sale</MenuItem>
                                <MenuItem value='saler'>Sale</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>




                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserPhone')}
                                    id="outlined-size-phone"
                                    InputLabelProps={{ shrink: phone ? true : false }}
                                    value={phone}
                                    name="phone"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="demo-select-employeeId">Employee</InputLabel>
                            <Select
                                labelId="demo-select-employeeId"
                                id="demo-select-employeeId"
                                value={employeeId}
                                name="employeeId"
                                onChange={(e) => {
                                    let u = find(listEmployee, { _id: e.target.value })
                                    setState((state) => ({
                                        ...state, firstName: u.firstName,
                                        phone: u?.phone,
                                        lastName: u.lastName, employeeId: u?._id
                                    }))
                                }}
                                label={t('frmUserType')}
                            >
                                <MenuItem value="">
                                    <em>{t('lblNone')}</em>
                                </MenuItem>
                                {listEmployee?.map((res, i) => {
                                    return <MenuItem value={res?._id} key={i}>{res?.code} - {res?.lastName} {res?.firstName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserUserName')}
                                    id="outlined-size-username"
                                    value={userName}
                                    InputLabelProps={{ shrink: userName ? true : false }}
                                    name="userName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>


                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserPassword')}
                                    id="outlined-size-passwordNew"
                                    type="passwordNew"
                                    value={passwordNew}
                                    name="passwordNew"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    {userType === 'telesale' ?
                        <>
                            <Grid item xs={12}>
                                <Typography variant='body' color={teal[500]}>
                                    Danh sách Lead
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ m: 1, minWidth: 360 }} size="small">
                                    <InputLabel id="demo-select-small">Nhân viên</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        onChange={changeList}
                                        label="Nhân viên"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {listUser?.map((res, ind) => {
                                            return <MenuItem key={ind} value={res?._id}>{res?.firstName + ' ' + res?.lastName}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    {
                                        leaderIdArr?.map((res, index) => {
                                            let user = find(listUser, { _id: res })
                                            return <Chip
                                                key={index}
                                                sx={{ ml: 1 }}
                                                label={`${user?.firstName} ${user?.lastName}`}
                                                size='small'
                                                color='primary'
                                                variant="filled" onDelete={() => removeFromList(index)} />
                                        })
                                    }
                                </Box>
                            </Grid>
                        </>
                        : <></>}



                    {/* <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Ghi chú"
                                    id="outlined-size-description"
                                    multiline={true}
                                    size="small"
                                    minRows={5}
                                    fullWidth={true}
                                />
                            </div>
                        </Box>
                    </Grid> */}

                </Grid>

            </Box>

            <Box sx={{ paddingX: 2, paddingY: 2 }}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color={teal[500]} >
                            {t('frmUserPermissionGroup')}
                            {/* <Button onClick={() => updateUserPermission()}>Cập nhật quyền</Button> */}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        {props?.permissionGroup?.map((res, ii) => {
                            return <FormGroup key={ii}>
                                <FormControlLabel control={<Checkbox />}
                                    checked={chooseGroup === res?._id ? true : false}
                                    name="chooseGroup"
                                    value={res?._id}
                                    onChange={(e) => customeGroupChange('chooseGroup', e.target.value)}
                                    label={res?.name} />
                            </FormGroup>
                        })}

                    </Grid>

                    <Grid item xs={6} >

                        <Grid item xs={12}>
                            <Typography variant='h6' color={teal[500]} sx={{ mb: 2 }}>
                                {t('frmUserPermission')} ({userPermission.length}) <Button
                                    size='small'
                                    sx={{ ml: 3 }}
                                    variant='outlined'
                                    onClick={() => updateUserPermission()}>{t('btnUpdate')}</Button>
                                <Button
                                    size='small'
                                    sx={{ ml: 3 }}
                                    variant='outlined'
                                    onClick={() => { setUserPermission([]) }}>{t('btnDelete')}</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ height: 280, overflowY: 'scroll' }}>


                            {props?.permission?.map((dt, i) => {
                                let uP = find(userPermission, { permissionId: dt?._id })
                                return <div
                                    style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12, flexDirection: 'row' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {findIndex(userPermission, { permissionId: dt._id }) > -1 ?
                                            <CheckBoxOutlinedIcon />
                                            : <CheckBoxOutlineBlankOutlinedIcon />}
                                        <div style={{ display: 'flex', marginLeft: 4, flexDirection: 'column' }}
                                            onClick={() => choosePermission(dt)}>
                                            <Typography variant='subtitle2'>{dt?.name}</Typography>
                                            <Typography variant='body2'>{dt?.modules}</Typography>
                                        </div>
                                    </div>
                                    <div onClick={() => detailPermission(dt)} style={{ cursor: 'pinter' }}>
                                        <Typography variant='body2' sx={{ color: '#00bcd4' }}>{t('lblDetailInfo')} ({uP?.actions?.length})</Typography>
                                    </div>
                                </div>
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>

                        <Grid item xs={12}>
                            <Typography variant='h6' color={teal[500]} sx={{ mb: 2, ml: 1 }}>
                                {currentActions?.name}
                            </Typography>
                        </Grid>
                        {currentActions?.actions?.map((dt, i) => {
                            let activePer = find(userPermission, { permissionId: currentActions?._id })
                            return <div
                                onClick={() => chooseActionPermisison(dt)}
                                style={{ display: 'flex', marginLeft: 12, marginBottom: 12, flexDirection: 'row' }}>
                                {activePer?.actions?.indexOf(dt) > -1 ?
                                    <CheckBoxOutlinedIcon />
                                    : <CheckBoxOutlineBlankOutlinedIcon />}
                                <Typography variant='subtitle2' style={{ marginLeft: 4 }}>{dt}</Typography>
                            </div>
                        })}
                    </Grid>

                </Grid>

            </Box>

        </Box>
    );
}

const mapDispatchToProps = {
    getUserById, updateUser, updateUserTele, createUserTele, getPermission,
    updateUserPermission, getUserPermission, getPermissionGroup, getEmployee
}

const mapStateToProps = (state) => {
    return {
        editObj: state.edit,
        permission: state.permission,
        permissionGroup: state.permissionGroup,
        userPermission: state.userPermission
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);