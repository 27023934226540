import { Box } from '@mui/material';
import React from 'react';
import A5 from './A5';

const FormA5 = React.forwardRef((props, ref) => {
    return <Box ref={ref} className="printTemplateA5">
        <A5/>
    </Box>
});


export default FormA5;