import * as React from 'react';
import { useState } from 'react';

import { FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { createAccountNumber } from '../../../actions/accountant';
import { getAllData as getLanguage } from '../../../actions/language';
import { getAllDataNoneRedux as getBankAccount } from '../../../actions/bankAccount';
import { getAllData as getDataPrimary } from '../../../actions/data';

import { find, findIndex, map } from 'lodash';
import { useEffect } from 'react';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import { typeAccount, kindAccount } from './type';
import { useTranslation } from 'react-i18next';
import { MenuProps } from '../../../untils/variable';

const initialState = {
    name: "",
    isActive: true,
    isFinal: false,
    code: '',
    description: '',
    parentCode: '',
    language: [],
    dataCode: [],
    defaultLanguage: ''
};

const AddForm = React.forwardRef((props, ref) => {

    const [
        { name, isActive, isFinal, code, description, parentCode, defaultLanguage, language, type, dataCode },
        setState
    ] = useState(initialState);
    const { i18n } = useTranslation();
    const listParent = useSelector(state => state?.accountant)
    const listLanguage = useSelector(state => state?.language)
    const [arrayType, setArrayType] = useState([])
    // eslint-disable-next-line
    const [listBank, setListBank] = useState([])

    useEffect(() => {
        const init = async () => {
            await props?.getLanguage()
            await props?.getBankAccount().then(res => {
                setListBank(res.data)
            })
            await props?.getDataPrimary()
        }

        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDefaultLanguage = (event) => {
        let langTmp = [...language]
        let stateNew = {}
        let index = findIndex(langTmp, { langCode: event.target.value })
        if (index > -1) {
            var newArr = map(langTmp, function (a) {
                return { ...a, default: false };
            });

            let tmp = { ...newArr[index] }
            tmp.default = true
            langTmp[index] = tmp
            stateNew.language = langTmp
            stateNew.name = newArr[index]?.value
        }
        setState(state => ({ ...state, defaultLanguage: event.target.value, ...stateNew }))
    };


    const onChangeLangue = (e, lang) => {
        let langTmp = [...language]
        let text = e.target.value
        let stateNew = {}
        if (lang.code === defaultLanguage) {
            stateNew.name = text
        }
        // get index in current language property in Accountant Object
        let index = findIndex(langTmp, { langCode: lang.code })
        if (index > -1) {
            let tmp = { ...langTmp[index] }
            tmp.value = text
            langTmp[index] = tmp
            stateNew.language = langTmp
        }
        else {
            langTmp.push({
                langCode: lang.code,
                default: false,
                value: text
            })
            stateNew.language = langTmp
        }
        setState(state => ({ ...state, ...stateNew }));
    }

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const kindChange = (code) => {
        let tmp = [...arrayType]
        let index = tmp.indexOf(code)
        if (index > -1) {
            tmp.splice(index)
        }
        else {
            tmp.push(code)
        }
        setArrayType(tmp)
    }

    const handleSubmit = () => {
        let data = {
            name, isActive, isFinal, code, description, parentCode, language, arrayType, type, dataCode
        }
        props?.createAccountNumber(data)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let arr = typeof value === 'string' ? value.split(',') : value
        setState(state => ({ ...state, dataCode: arr }))
    };

    React.useImperativeHandle(ref, () => ({ handleSubmit }));
    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, md: 12 }}>
            <Grid item md={8} >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, md: 12 }}>
                    <Grid item xs={4} md={4} >
                        <FormGroup >
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label="Active" />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={4} md={4} >
                        <FormGroup >
                            <FormControlLabel control={<Checkbox />}
                                checked={isFinal}
                                name="isFinal"
                                onChange={(e) => customeOnChange('isFinal', !isFinal)}
                                label="isFinal" />
                        </FormGroup>
                    </Grid>

                    <Grid item md={4} xs={4} >
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex' },
                            }}
                            noValidate
                            autoComplete="off" >
                            <TextField
                                label="Code "
                                id="code"
                                value={code}
                                name="code"
                                onChange={onChange}
                                size="small"
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                    <Typography sx={{ mb: 1, fontWeight: 600 }}>Name</Typography>
                    <RadioGroup
                        aria-labelledby="demo-defaultLanguage"
                        name="defaultLanguage"
                        value={defaultLanguage}
                        onChange={handleDefaultLanguage}>
                        {listLanguage?.map((item, index) => {
                            return <Box
                                key={index}
                                component="form"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    '& .MuiTextField-root': { flex: 1, mb: 2 },
                                }}
                                noValidate
                                autoComplete="off">
                                <TextField
                                    label={item?.name}
                                    id="last-name"
                                    value={find(language, { langCode: item.code })?.value}
                                    name="name"
                                    onChange={(e) => onChangeLangue(e, item)}
                                    size="small"
                                />
                                <FormControlLabel value={item.code} sx={{ ml: 1, mb: 2 }} control={<Radio />} label="" />
                            </Box>
                        })}
                    </RadioGroup>
                </Grid>

                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, md: 12 }}>
                    <Grid item xs={6} md={6}>
                        <Typography sx={{ mb: 1, fontWeight: 600 }}>Other</Typography>

                        <FormControl size="small" sx={{ display: 'flex' }}>
                            <InputLabel id="demo-select-parentCode">Parent</InputLabel>
                            <Select
                                labelId="demo-select-parentCode"
                                id="demo-select-parentCode"
                                value={parentCode || ""}
                                name="parentCode"
                                onChange={onChange}
                                label="Parent">
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {listParent?.map((res, ind) => {
                                    return <MenuItem key={ind} value={res?.code}>{res?.code + ' - ' + res?.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} md={6}>
                        <Typography sx={{ mb: 1, fontWeight: 600 }}>.</Typography>

                        <FormControl size="small" sx={{ display: 'flex' }}>
                            <InputLabel id="demo-select-Type">Type</InputLabel>
                            <Select
                                labelId="demo-select-Type"
                                id="demo-select-Type"
                                value={type || ""}
                                name="type"
                                onChange={onChange}
                                label="type">
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {typeAccount?.map((res, ind) => {
                                    return <MenuItem key={ind} value={res?.code}>
                                        {find(res.data, { lngCode: i18n.language })?.name || 'not found'}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ paddingLeft: 0 ,  mt: 1, mb:2}}>
                    <Typography variant='body2' >Apply for data:</Typography>
                    <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            size='small'
                            value={dataCode}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}>
                            {props?.data?.map((item) => (
                                <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                    <Checkbox checked={dataCode?.indexOf(item.code) > -1 ? 'checked' : ''} />
                                    <ListItemText primary={`${item.code} - ${item.name}`} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>


                <Grid item lg={12} xs={12}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { display: 'flex' },
                            mt: 2
                        }}
                        noValidate
                        autoComplete="off">

                        <TextField
                            label="Note"
                            id="last-description"
                            value={description}
                            multiline={true}
                            minRows={4}
                            name="description"
                            onChange={onChange}
                        />
                    </Box>
                </Grid>

            </Grid>

            <Grid item md={4}>
                <Typography sx={{ mb: 1, fontWeight: 600 }}>Kind</Typography>
                {
                    kindAccount?.map(res => {
                        return <FormGroup >
                            <FormControlLabel control={<Checkbox />}
                                checked={arrayType?.indexOf(res?.code) > -1 ? true : false}
                                name="kind"
                                onChange={(e) => kindChange(res.code)}
                                label={find(res.data, { lngCode: i18n.language })?.name || 'not found'} />
                        </FormGroup>
                    })
                }
            </Grid>


        </Grid>
    );
})

const mapDispatchToProps = {
    createAccountNumber, getLanguage, getBankAccount, getDataPrimary
}

const mapStateToProps = (state) => {
    return {
        data: state.data
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddForm);