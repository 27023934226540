import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { filter, find, groupBy, isEmpty, orderBy, sumBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { renderNumberDollar } from '../../../../untils/number';

import { MoreVertOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getCreditNoteByCode } from '../../../../actions/creditNote';
import { getDebitNoteByCode } from '../../../../actions/debitNote';
import { getIncomeByCode } from '../../../../actions/income';
import { getJournalEntrieByCode } from '../../../../actions/journalEntrie';
import { getOutcomeByCode } from '../../../../actions/outcome';
import { getPaymentByParent } from '../../../../actions/payment';
import DetailForm from '../../journalEntrie/DetailForm';
import DialogPrint from './DialogPrint';
import { useEffect } from 'react';
import EditableLabel from 'react-editable-label';

const DetailFormGroupSummary = React.forwardRef((props, ref) => {
    // let { beginBlance, data, condition, title, accountNumber, startDate, endDate, department, expense, partner } = props
    let { beginBlance, data, condition, accountNumber, isPrint, title, startDate, endDate } = props
    const [mainData, setMainData] = useState([])
    const [openDetail, setOpenDetail] = useState(false)
    const [listBottomSheet, setListBottomSheet] = React.useState([]);
    const [dataParent, setDataParent] = React.useState({});
    const [dataHeader, setDataHeader] = React.useState({
        date: moment().format("MMMM Do YYYY"),
        company: 'Company',
        address: 'Address',
        phone: 'Phone',
        title: title
    });

    useEffect(() => {
        setDataHeader(state => ({ ...state, title }))
    }, [title])


    const repareDataGroupByCode = async () => {
        let dataTmp = []

        await accountNumber.map(async (accountActive, index) => {
            let account = {
                code: accountActive,
                beginDebitAmount: 0,
                beginDebitOriginalAmount: 0,
                sumDebitAmount: 0,
                sumDebitOriginalAmount: 0,
                payment: []
            }

            let beginBalance = find(beginBlance, { accountNumber: accountActive })
            let payment = await data.filter(py => {
                return py.accountNumberDebit === accountActive || py.accountNumberCredit === accountActive;
            });
            if (!isEmpty(beginBalance)) {
                account.beginDebitAmount = beginBalance.beginDebitAmount
                account.beginDebitOriginalAmount = beginBalance.beginDebitOriginalAmount

                if (payment.length > 0) {
                    await reparePayment(payment, beginBalance.beginDebitAmount, beginBalance.beginDebitOriginalAmount, accountActive)
                        .then(async res => {
                            account.payment = res?.data
                            let dataGroup = Object.keys(res?.group).map(key => ({ code: key, detail: res?.group[key] }));
                            let sumAmount = 0
                            let sumOriginalAmount = 0
                            let sumCreditAmount = 0
                            let sumCreditOriginalAmount = 0

                            //get arr payment credit ===> has key === accountActive
                            let arrCredit = find(dataGroup, { code: accountActive }) || []

                            //sum all detail
                            let afterSum = await dataGroup.map((dt, index) => {
                                let isDebit = false
                                let account = {}
                                let childDel = dt.detail
                                if (childDel.length > 0) {
                                    isDebit = accountActive === dt?.code ? false : true
                                    account = isDebit ?
                                        childDel[0]?.accountingDebit :
                                        childDel[0]?.accountingCredit

                                    //check type payment bank because bank payment account number is deposit with other payment
                                    //account debit > credit || credit >> debit
                                    if (isDebit) {

                                    }

                                }

                                if (isDebit === true) {
                                    let amount = sumBy(childDel, 'amount')
                                    let originalAmount = sumBy(childDel, 'originalAmount')
                                    sumAmount += amount
                                    sumOriginalAmount += originalAmount

                                    let amountCredit = 0
                                    let originalAmountCredit = 0
                                    //let credit
                                    if (arrCredit) {
                                        let paymentCredit = arrCredit?.detail
                                        let arrOfPaymentCredit = filter(paymentCredit, { accountNumberCredit: dt.code })

                                        amountCredit = sumBy(arrOfPaymentCredit, 'amount')
                                        originalAmountCredit = sumBy(arrOfPaymentCredit, 'originalAmount')
                                        sumCreditAmount += amountCredit
                                        sumCreditOriginalAmount += originalAmountCredit
                                    }

                                    //yesterday 
                                    let startAmount = index === 0 ? beginBalance.beginDebitAmount : dataGroup[index - 1]?.yesterday

                                    let endAmount = startAmount
                                    if (isDebit === true) {
                                        endAmount += amount
                                    }
                                    else {
                                        endAmount -= amount
                                    }

                                    dt.amount = amount
                                    dt.originalAmount = originalAmount
                                    dt.isDebit = isDebit
                                    dt.end = endAmount
                                    dt.yesterday = endAmount
                                    dt.account = account
                                    dt.amountCredit = amountCredit
                                    dt.originalAmountCredit = originalAmountCredit

                                }

                                return dt
                            })
                            afterSum.sumAmount = sumAmount
                            afterSum.sumOriginalAmount = sumOriginalAmount
                            afterSum.sumCreditOriginalAmount = sumCreditOriginalAmount
                            afterSum.sumCreditAmount = sumCreditAmount

                            account.group = afterSum
                        })
                }
            }

            dataTmp.push(account)

            return account
        })

        return dataTmp
    }

    const sortData = async (data) => {
        const { sortBy, sortType, debitSearch, creditSearch, departmentSearch, expenseSearch } = condition
        let source = [...data]
        let tmp = [...data]
        if (!isEmpty(sortBy) && sortBy !== 'none') {
            tmp = await handleSort(source, sortBy, sortType || 'asc')
        }

        if (!isEmpty(debitSearch)) {
            tmp = await handleDebit(tmp, debitSearch)
        }

        if (!isEmpty(creditSearch)) {
            tmp = await handleCredit(tmp, creditSearch)
        }

        if (!isEmpty(departmentSearch)) {
            tmp = await handleDepartment(tmp, departmentSearch)
        }

        if (!isEmpty(expenseSearch)) {
            tmp = await handleExpense(tmp, expenseSearch)
        }
        return tmp
    }

    const sumPaymentType = (value, accountNumber, type) => {

        let debitAmount = 0
        let creditAmount = 0
        let amount = value.amount
        let originalAmount = value.originalAmount
        let isDebit = value?.accountNumberDebit === accountNumber ? true : false

        if (type === 'original') {
            debitAmount = isDebit ? originalAmount : 0
            creditAmount = !isDebit ? originalAmount : 0
        }
        else {
            debitAmount = isDebit ? amount : 0
            creditAmount = !isDebit ? amount : 0
        }

        return { debitAmount, creditAmount }

    }

    const reparePayment = async (data, beginDebitAmount, beginOriginalAmount, accountNumber) => {
        let sumDebit = 0
        let sumCredit = 0

        let sumDebitOri = 0
        let sumCreditOri = 0

        let tmp = await data?.map((item, index) => {
            //Accounting currency
            let startDebit = index === 0 ? beginDebitAmount : data[index - 1]?.yesterday
            let debitAmount = sumPaymentType(item, accountNumber, '')?.debitAmount
            let creditAmount = sumPaymentType(item, accountNumber, '')?.creditAmount
            let endAmount = startDebit + creditAmount - debitAmount
            sumDebit += debitAmount
            sumCredit += creditAmount
            item.end = endAmount
            item.yesterday = endAmount
            item.sumDebit = sumDebit
            item.sumCredit = sumCredit
            item.debit = debitAmount
            item.credit = creditAmount

            //Original currency
            let startOriginal = index === 0 ? beginOriginalAmount : data[index - 1]?.yesterdayOri
            let debitOri = sumPaymentType(item, accountNumber, 'original')?.debitAmount
            let creditOri = sumPaymentType(item, accountNumber, 'original')?.creditAmount
            let endOri = startOriginal + creditOri - debitOri
            sumDebitOri += debitOri
            sumCreditOri += creditOri
            item.endOri = endOri
            item.yesterdayOri = endOri
            item.sumDebitOri = sumDebitOri
            item.sumCreditOri = sumCreditOri
            item.debitOri = debitOri
            item.creditOri = creditOri

            return item
        })
        let group = groupBy(tmp, 'accountNumberDebit')

        return { data: tmp, group }
    }

    const handleSort = (data, sortBy, sortType) => {
        let temp = orderBy(data, sortBy, sortType);
        return temp
    }

    const handleDebit = (data, val) => {
        let temp = filter(data, { accountNumberDebit: val });
        return temp
    }

    const handleCredit = (data, val) => {
        let temp = filter(data, { accountNumberCredit: val });
        return temp
    }

    const handleDepartment = (data, val) => {
        let temp = filter(data, { departmentCode: val });
        return temp
    }

    const handleExpense = (data, val) => {
        let temp = filter(data, { expenseCode: val });
        return temp
    }

    React.useEffect(() => {
        repareDataGroupByCode().then(dt => {
            setMainData(dt)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    React.useEffect(() => {
        sortData(mainData)
            .then(res => {
                setMainData(res)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition])

    const handleClosePrint = () => {
        props?.handleClosePrint(false)
    }

    const componentPrint = () => {
        return <div className='printFileReport' ref={ref} >
            <div className='containHeaderPrint'>
                <div className='leftOfHead'>

                    <div className='rowLeftPrint' style={{ fontStyle: 'italic' }}>
                        <EditableLabel
                            initialValue={dataHeader?.date}
                            save={value => {
                                setDataHeader(state => ({ ...state, date: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.company}
                            save={value => {
                                setDataHeader(state => ({ ...state, company: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.address}
                            save={value => {
                                setDataHeader(state => ({ ...state, address: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.phone}
                            save={value => {
                                setDataHeader(state => ({ ...state, phone: value }))
                            }}
                        />
                    </div>
                    <div className='briefPrintLine'>(Tapping above lines to edit)</div>
                </div>
                <div className='topFileReport'>
                    <div className='titFileReport'>
                        <EditableLabel
                            initialValue={dataHeader?.title}
                            save={value => {
                                setDataHeader(state => ({ ...state, title: value }))
                            }}
                        />
                    </div>
                    <div className='dateFileReport'>From {startDate} to {endDate}</div>
                    <div className='containFileReport'>
                        <div className='colFileReport'>
                            <div className='lineColReport'>
                                <div className='detailColFile'>
                                    {accountNumber?.map((res, ind) => {
                                        return <span>{res} {ind < accountNumber?.length - 1 ? ', ' : ''}</span>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Table sx={{ width: '100%', border: '1px solid #d2d2d2' }} className='tableRp tblPrint'>
                <TableHead className='header-wrapper'>
                    <TableRow sx={{ background: '#F7FAFC', '& .MuiTableCell-root': { padding: '8px 12px!important' } }}>
                        <TableCell>#</TableCell>
                        <TableCell align="center" >Account
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{
                                    display: 'block',
                                    minWidth: '30px'
                                }}>CR </span> <span style={{
                                    display: 'block',
                                    minWidth: '30px',
                                }}>DR</span>
                            </div>
                        </TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="center" width={140}>Debit Amount
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{
                                    display: 'block',
                                    width: '70px'
                                }}>Original</span> <span style={{
                                    display: 'block',
                                    width: '70px',
                                }}>Currently</span>
                            </div>
                        </TableCell>
                        <TableCell align="center" width={140}>Credit Amount
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{
                                    display: 'block',
                                    width: '70px'
                                }}>Original</span> <span style={{
                                    display: 'block',
                                    width: '70px',
                                }}>Currently</span>
                            </div>
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        mainData?.map((itemMain, index) => {
                            let main = itemMain?.group || []
                            return <React.Fragment key={index} >
                                <TableRow
                                    sx={{ background: '#c2e7fe!important', '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left"></TableCell>

                                    <TableCell align="left" colSpan={1}>
                                        <b>{itemMain.code}</b>
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                                {
                                    main?.map((item, index) => {
                                        return item.isDebit === true && <TableRow
                                            key={index}
                                            className='rowTbRp'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {index + 1}
                                                </div>
                                            </TableCell>

                                            <TableCell align="left">
                                                {item.code}
                                            </TableCell>
                                            <TableCell align="left">{item?.account?.name}</TableCell>

                                            <TableCell align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <span style={{
                                                        display: 'block',
                                                        width: '70px'
                                                    }}>
                                                        <span className='fmNumber'> {item?.isDebit ? renderNumberDollar(item.originalAmount) : '-'}</span>
                                                    </span>
                                                    <span style={{
                                                        display: 'block',
                                                        width: '70px'
                                                    }}>
                                                        <span className='fmNumber'> {item?.isDebit ? renderNumberDollar(item.amount) : '-'}</span>
                                                    </span>
                                                </div>
                                            </TableCell>

                                            <TableCell align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <span style={{
                                                        display: 'block',
                                                        width: '70px'
                                                    }}>
                                                        {(item.credit > 0 && item?.currencyCode !== 'LAK') && <span style={{ fontSize: 10, marginLeft: 2 }}>{item?.currency?.symbol}</span>}
                                                        <span className='fmNumber'>{item?.amountCredit > 0 ? renderNumberDollar(item?.originalAmountCredit) : '-'}</span>
                                                    </span>
                                                    <span style={{
                                                        display: 'block',
                                                        width: '160px'
                                                    }}>
                                                        <span className='fmNumber'> {item?.amountCredit > 0 ? renderNumberDollar(item?.amountCredit) : '-'}</span>
                                                    </span>
                                                </div>

                                            </TableCell>

                                        </TableRow>
                                    })
                                }
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" colSpan={2}>
                                        <b>Total</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <span style={{
                                                display: 'block',
                                                width: '70px'
                                            }}>
                                                <b>
                                                    <span className='fmNumber'>
                                                        {main.length > 0 ? renderNumberDollar(sumBy(main, 'originalAmount')) : '-'}
                                                    </span>
                                                </b>
                                            </span>
                                            <span style={{
                                                display: 'block',
                                                width: '70px'
                                            }}>
                                                <b>
                                                    <span className='fmNumber'>
                                                        {main.length > 0 ? renderNumberDollar(sumBy(main, 'amount')) : '-'}
                                                    </span>
                                                </b>
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="right">
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <span style={{
                                                display: 'block',
                                                width: '70px'
                                            }}>
                                                <b>
                                                    <span className='fmNumber'>
                                                        {main.length > 0 ? renderNumberDollar(sumBy(main, 'originalAmountCredit')) : '-'}
                                                    </span>
                                                </b>
                                            </span>
                                            <span style={{
                                                display: 'block',
                                                width: '70px'
                                            }}>
                                                <b>
                                                    <span className='fmNumber'>
                                                        {main.length > 0 ? renderNumberDollar(sumBy(main, 'amountCredit')) : '-'}
                                                    </span>
                                                </b>
                                            </span>
                                        </div>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={7}>
                                        <div className='lineDashed'></div>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        })
                    }
                </TableBody>
            </Table>
        </div>
    }

    const getDataParent = async (val) => {

        switch (val.paymentType) {
            case 'CR':
                await props?.getIncomeByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            case 'CP':
                await props?.getOutcomeByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            case 'DRB':
                await props?.getDebitNoteByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            case 'CRB':
                await props?.getCreditNoteByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            case 'JE':
                await props?.getJournalEntrieByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            default:
                break;
        }
    }

    const handleOpenDetail = async (val) => {
        getDataParent(val)
        await props?.getPaymentByParent(val?.parentCode).then(res => {
            setListBottomSheet(res.data)
            setOpenDetail(true)
        })
    }

    return (
        <>
            <Box className="printTemplate">
                {isPrint ? <DialogPrint isOpen={isPrint} handleRenderData={componentPrint} handleCloseProps={handleClosePrint} /> : ""}
                <TableContainer component={Paper} elevation={0} sx={{ maxHeight: 'calc(100vh - 150px)' }}>
                    <Table sx={{ minWidth: 650 }} className='tableRp' stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow sx={{
                                '& .MuiTableCell-root': {
                                    padding: '8px 12px!important'
                                }
                            }}>
                                <TableCell width={60}>#</TableCell>
                                <TableCell align="left" width={100}>Account
                                </TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="center" width={320}>Debit Amount
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <span style={{
                                            display: 'block',
                                            width: 160,
                                        }}>Original</span> <span style={{
                                            display: 'block',
                                            width: 160,
                                        }}>Currently</span>
                                    </div>
                                </TableCell>
                                <TableCell align="center" width={320}>Credit Amount
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <span style={{
                                            display: 'block',
                                            width: 160
                                        }}>Original</span> <span style={{
                                            display: 'block',
                                            width: 160
                                        }}>Currently</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                mainData?.map((itemMain, index) => {
                                    let main = itemMain?.group || []
                                    return <React.Fragment key={index} >
                                        <TableRow
                                            sx={{ background: '#c2e7fe!important', '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left"></TableCell>

                                            <TableCell align="left" colSpan={1}>
                                                <b>{itemMain.code}</b>
                                            </TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                        {
                                            main?.map((item, index) => {
                                                return item.isDebit === true && <TableRow
                                                    key={index}
                                                    className='rowTbRp'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <MoreVertOutlined color='#000000de' onClick={() => handleOpenDetail(item)} />
                                                            {index + 1}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {item.code}
                                                    </TableCell>
                                                    <TableCell align="left">{item?.account?.name}</TableCell>

                                                    <TableCell align="right">
                                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                            <span style={{
                                                                display: 'block',
                                                                width: '160px'
                                                            }}>
                                                                {/* {(item.debit > 0 && item?.currencyCode !== 'LAK') && <span style={{ fontSize: 10, marginLeft: 2 }}>{item?.currency?.symbol}</span>} */}
                                                                <span className='fmNumber'> {item?.isDebit ? renderNumberDollar(item.originalAmount) : '-'}</span>
                                                            </span>
                                                            <span style={{
                                                                display: 'block',
                                                                width: '160px'
                                                            }}>
                                                                <span className='fmNumber'> {item?.isDebit ? renderNumberDollar(item.amount) : '-'}</span>
                                                            </span>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                            <span style={{
                                                                display: 'block',
                                                                width: '160px'
                                                            }}>
                                                                {(item.credit > 0 && item?.currencyCode !== 'LAK') && <span style={{ fontSize: 10, marginLeft: 2 }}>{item?.currency?.symbol}</span>}
                                                                <span className='fmNumber'>{item?.amountCredit > 0 ? renderNumberDollar(item?.originalAmountCredit) : '-'}</span>
                                                            </span>
                                                            <span style={{
                                                                display: 'block',
                                                                width: '160px'
                                                            }}>
                                                                <span className='fmNumber'> {item?.amountCredit > 0 ? renderNumberDollar(item?.amountCredit) : '-'}</span>
                                                            </span>
                                                        </div>

                                                    </TableCell>

                                                </TableRow>
                                            })
                                        }
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left" colSpan={2}>
                                                <b>Total</b>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <span style={{
                                                        display: 'block',
                                                        width: '160px'
                                                    }}>
                                                        <b>
                                                            <span className='fmNumber'>
                                                                {main.length > 0 ? renderNumberDollar(sumBy(main, 'originalAmount')) : '-'}
                                                            </span>
                                                        </b>
                                                    </span>
                                                    <span style={{
                                                        display: 'block',
                                                        width: '160px'
                                                    }}>
                                                        <b>
                                                            <span className='fmNumber'>
                                                                {main.length > 0 ? renderNumberDollar(sumBy(main, 'amount')) : '-'}
                                                            </span>
                                                        </b>
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <span style={{
                                                        display: 'block',
                                                        width: '160px'
                                                    }}>
                                                        <b>
                                                            <span className='fmNumber'>
                                                                {main.length > 0 ? renderNumberDollar(sumBy(main, 'originalAmountCredit')) : '-'}
                                                            </span>
                                                        </b>
                                                    </span>
                                                    <span style={{
                                                        display: 'block',
                                                        width: '160px'
                                                    }}>
                                                        <b>
                                                            <span className='fmNumber'>
                                                                {main.length > 0 ? renderNumberDollar(sumBy(main, 'amountCredit')) : '-'}
                                                            </span>
                                                        </b>
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow>

                                        {/* <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left" colSpan={2}>
                                                <b>Closing</b>
                                            </TableCell>
                                            <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                                            <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                                        </TableRow> */}

                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell colSpan={7}>
                                                <div className='lineDashed'></div>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box >
            {
                openDetail ? <DetailForm isOpen={openDetail}
                    listBottomSheet={listBottomSheet}
                    dataParent={dataParent} handleClose={() => setOpenDetail(false)} /> : <></>
            }
        </>
    );
})

const mapDispatchToProps = {
    getPaymentByParent, getIncomeByCode, getOutcomeByCode, getDebitNoteByCode,
    getCreditNoteByCode, getJournalEntrieByCode
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(DetailFormGroupSummary);