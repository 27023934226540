import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { getExchangeRateById, updateExchangeRate } from '../../../actions/exchangeRate';
import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getAllData as getCurrency } from '../../../actions/currency';

const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)

    const [
        { isActive, fromCode, toCode, buy, sell, _id },
        setState
    ] = useState({ ...editObject });

    const [date, setDate] = React.useState(new Date());


    const getDataById = () => {
        props?.getExchangeRateById(_id).then(res => {
            let data = res?.data
            setState(state => ({ ...state, ...data }))
            setDate(data?.date)
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        getDataById()
        const cond = {
            condition: {
                isActive: {
                    equal: true
                }
            }
        }
        props?.getCurrency(cond)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const handleSubmit = () => {
        let data = {
            isActive, fromCode, toCode, buy, sell, dataCode: ['C001'], date
        }
        props?.updateExchangeRate(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
            })
    }

    React.useImperativeHandle(ref, () => ({ handleSubmit }));

    return (
        <Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 12, md: 12 }}>

            <Grid item xs={12} md={12} >
                <FormGroup >
                    <FormControlLabel control={<Checkbox />}
                        checked={isActive}
                        name="isActive"
                        onChange={(e) => customeOnChange('isActive', !isActive)}
                        label="Active" />
                </FormGroup>
            </Grid>

            <Grid item md={6} xs={6}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">From</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={fromCode}
                        name='fromCode'
                        label="Age"
                        onChange={onChange}>
                        {props?.currency?.map((item, i) => {
                            return <MenuItem value={item?.code} key={i}>{item?.symbol} - {item?.name}</MenuItem>
                        })}

                    </Select>
                </FormControl>
            </Grid>

            <Grid item md={6} xs={6}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label2">To</InputLabel>
                    <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select2"
                        value={toCode}
                        name='toCode'
                        label="Age"
                        onChange={onChange}
                    >
                        {props?.currency?.map((item, i) => {
                            return <MenuItem value={item?.code} key={i}>{item?.symbol} - {item?.name}</MenuItem>
                        })}

                    </Select>
                </FormControl>
            </Grid>


            <Grid item md={6} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Buy"
                        id="last-buy"
                        value={buy}
                        name="buy"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item md={6} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Sell"
                        id="last-buy"
                        value={sell}
                        name="sell"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item xs={6}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                        '& .MuiInput-root': { mt: 1.5 }
                    }}
                    noValidate
                    autoComplete="off">

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            className='customeChooseDate'
                            format={'DD/MM/YYYY'}
                            value={dayjs(date)}
                            label={'Date'}
                            onChange={(newValue) => {
                                setDate(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            </Grid>

        </Grid>
    );
})


const mapDispatchToProps = {
    getExchangeRateById, updateExchangeRate, getCurrency
}

const mapStateToProps = (state) => {
    return {
        editObj: state.edit,
        currency: state.currency
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditForm);