import * as React from 'react';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { find, findIndex } from 'lodash';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { getAllDataNoneRedux as getUser } from '../../../actions/user';
import { createRequestQueue } from '../../../actions/requestQueue';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';



const initialState = {
    name: "",
    isActive: true,
    code: '',
    description: '',
    queue: [],
    type: '',
    actions: [],
    applyFor: [],
    member: []
};


const AddForm = React.forwardRef((props, ref) => {


    const [
        { name, isActive, code, description, queue,
            listUser, userChoose, type, actions, applyFor, member
        },
        setState
    ] = useState(initialState);

    useEffect(() => {
        const init = () => {
            props?.getUser()
                .then(res => {
                    setState(state => ({ ...state, listUser: res?.data }));
                })
        }
        init()
        // eslint-disable-next-line
    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    // const _clearState = () => {
    //     setState({ ...initialState });
    // };

    const handleSubmit = () => {
        let data = {
            name, code, isActive, description, queue, actions, type, applyFor, member
        }

        props?.createRequestQueue(data)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                // _clearState()
            })
    }

    const delQueue = (index) => {
        let que = [...queue]
        que.splice(index, 1)
        let tmp = que.map((item, i) => ({ ...item, order: i + 1 }))
        setState(state => ({ ...state, queue: tmp }));
    }

    const handleChange = (event) => {
        let name = event.target.name
        let actionTmp = [...actions]
        let index = actionTmp.indexOf(name)
        if (index > -1) {
            actionTmp.splice(index, 1)
        }
        else {
            actionTmp.push(name)
        }
        setState(state => ({
            ...state,
            actions: actionTmp,
        }));
    };

    const handleChangeApply = (event) => {
        let name = event.target.name
        let actionTmp = [...applyFor]
        let index = actionTmp.indexOf(name)
        if (index > -1) {
            actionTmp.splice(index, 1)
        }
        else {
            actionTmp.push(name)
        }
        setState(state => ({
            ...state,
            applyFor: actionTmp,
        }));
    };

    const delMember = (index) => {
        let que = [...member]
        que.splice(index, 1)
        setState(state => ({ ...state, member: que }));
    }


    React.useImperativeHandle(ref, () => ({ handleSubmit }));

    return (


        <Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 12, md: 12 }}>

            <Grid item xs={12} md={4} >
                <FormGroup >
                    <FormControlLabel control={<Checkbox />}
                        checked={isActive}
                        name="isActive"
                        onChange={(e) => customeOnChange('isActive', !isActive)}
                        label="Active" />
                </FormGroup>
            </Grid>

            <Grid item md={8} xs={12} >
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off" >
                    <TextField
                        label="Code "
                        id="code"
                        value={code}
                        name="code"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item md={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                        label="Name"
                        id="last-name"
                        value={name}
                        name="name"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>
            <Grid item lg={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Note"
                        id="last-description"
                        value={description}
                        multiline={true}
                        minRows={4}
                        name="description"
                        onChange={onChange}
                    />
                </Box>
            </Grid>

            <FormControl sx={{ m: 3, mb: 0 }} component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ fontWeight: 600 }}>Mode</FormLabel>
                <FormHelperText >The Auto will be applied when creating a document, Specific will be applied when creating a request.</FormHelperText>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox checked={applyFor.indexOf('auto') > -1} onChange={handleChangeApply} name="auto" />
                        }
                        label="Auto" />
                    <FormControlLabel
                        control={
                            <Checkbox checked={applyFor.indexOf('specific') > -1} onChange={handleChangeApply} name="specific" />
                        }
                        label="Specific" />
                </FormGroup>
            </FormControl>

            <Grid item md={12} xs={12} style={{ paddingTop: 12 }}>
                {member?.map((res, ii) => {
                    let u = find(listUser, { _id: res })
                    return <div key={ii} style={{
                        display: 'flex',
                        borderBottom: '0.5px dashed #ccc',
                        paddingBottom: '6px',
                        paddingTop: '6px'
                    }}>
                        <div style={{ width: 40, textAlign: 'center' }}>{ii + 1}.</div>
                        <div style={{ flex: 1 }}>{u?.firstName} {u?.lastName}</div>
                        <Button onClick={() => delMember(ii)} size='small' color='error' disableElevation>D</Button>
                    </div>
                })}
            </Grid>

            <Grid item md={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column'
                    }}
                    noValidate
                    autoComplete="off" >
                    <Typography variant='subtitle2' sx={{ width: '100%' }}>Choose list members can use this queue.</Typography>
                    <Autocomplete
                        id="country-select-member"
                        sx={{ width: 300, mt:1 }}
                        options={listUser}
                        autoHighlight
                        size='small'
                        value={userChoose}
                        getOptionLabel={(option) => option?.userName}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{}} {...props}>
                                {option?.userName} - ({option?.firstName})  {option?.lastName}
                            </Box>
                        )}
                        onChange={(event, newValue) => {
                            let listUserTmp = [...member]
                            let isExitst = listUserTmp.indexOf(newValue?._id)
                            if (isExitst < 0) {
                                listUserTmp.push(newValue?._id)
                                setState(state => ({
                                    ...state, member: listUserTmp, userChoose: newValue
                                }))
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('frmChoose')}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Box>
            </Grid>

            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ fontWeight: 600 }}>Actions</FormLabel>
                <FormHelperText >Actions allow users to approve the request.</FormHelperText>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox checked={actions.indexOf('CREATE') > -1} onChange={handleChange} name="CREATE" />
                        }
                        label="CREATE" />
                    <FormControlLabel
                        control={
                            <Checkbox checked={actions.indexOf('APPROVE') > -1} onChange={handleChange} name="APPROVE" />
                        }
                        label="APPROVE" />
                    <FormControlLabel
                        control={
                            <Checkbox checked={actions.indexOf('UPDATE') > -1} onChange={handleChange} name="UPDATE" />
                        }
                        label="UPDATE" />
                    <FormControlLabel
                        control={
                            <Checkbox checked={actions.indexOf('DELETE') > -1} onChange={handleChange} name="DELETE" />
                        }
                        label="DELETE" />
                </FormGroup>
            </FormControl>

            <FormControl sx={{ m: 3, mb: 0, mt:1 }} component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ fontWeight: 600 }}>Mode</FormLabel>
                <FormHelperText >The Auto will be applied when creating a document, Specific will be applied when creating a request.</FormHelperText>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox checked={applyFor.indexOf('auto') > -1} onChange={handleChangeApply} name="auto" />
                        }
                        label="Auto" />
                    <FormControlLabel
                        control={
                            <Checkbox checked={applyFor.indexOf('specific') > -1} onChange={handleChangeApply} name="specific" />
                        }
                        label="Specific" />
                </FormGroup>
            </FormControl>

            <Grid item md={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 0 }}>
                <div>
                    <Typography variant='title' fontWeight={600}>Order of Priority</Typography>
                    <Typography variant='subtitle2'>Requests will be approved in ascending order</Typography>
                </div>

                <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel id="demo-simple-select-label">Request for</InputLabel>
                    <Select
                        autoWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Type"
                        onChange={(e) => {
                            setState(state => ({ ...state, type: e.target.value }))
                        }}>
                        <MenuItem value={'IC'}>Income</MenuItem>
                        <MenuItem value={'OC'}>Outcome</MenuItem>
                        <MenuItem value={'DRB'}>Debit Note</MenuItem>
                        <MenuItem value={'CRB'}>Credit Note</MenuItem>
                        <MenuItem value={'JE'}>Journal Entries</MenuItem>
                    </Select>
                </FormControl>


            </Grid>

            <Grid item md={12} xs={12} style={{ paddingTop: 12 }}>
                {queue?.map((res, ii) => {
                    let u = find(listUser, { _id: res?.userId })
                    return <div key={ii} style={{
                        display: 'flex',
                        borderBottom: '0.5px dashed #ccc',
                        paddingBottom: '6px',
                        paddingTop: '6px'
                    }}>
                        <div style={{ width: 40, textAlign: 'center' }}>{res?.order}.</div>
                        <div style={{ flex: 1 }}>{u?.firstName} {u?.lastName}</div>
                        <Button onClick={() => delQueue(ii)} size='small' color='error' disableElevation>D</Button>
                    </div>
                })}
            </Grid>

            <Grid item md={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    noValidate
                    autoComplete="off" >
                    <Typography variant='body' style={{ width: 40, textAlign: 'center' }}>{queue.length + 1}.</Typography>
                    <Autocomplete
                        id="country-select-demo"
                        sx={{ width: 300 }}
                        options={listUser}
                        autoHighlight
                        size='small'
                        value={userChoose}
                        getOptionLabel={(option) => option?.userName}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{}} {...props}>
                                {option?.userName} - ({option?.firstName})  {option?.lastName}
                            </Box>
                        )}
                        onChange={(event, newValue) => {
                            let listUserTmp = [...queue]
                            let isExitst = findIndex(listUserTmp, { userId: newValue?._id })
                            if (isExitst < 0) {
                                let obj = {
                                    userId: newValue?._id,
                                    order: queue.length + 1,
                                    hasConfirm: false,
                                    note: '',
                                    hasDeny: false,
                                }
                                listUserTmp.push(obj)
                                setState(state => ({
                                    ...state, queue: listUserTmp, userChoose: newValue
                                }))
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('frmChoose')}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Box>
            </Grid>



        </Grid>
    );
})


const mapDispatchToProps = {
    createRequestQueue, getUser
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(AddForm);