import * as React from 'react';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Autocomplete, Button, Grid, Typography, useTheme } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import '../partner/style.css'
import Select from '@mui/material/Select';
import { teal } from '@mui/material/colors';
import { connect, useSelector } from 'react-redux';
import { updatePartner, getAllDataNoneRedux as getPartner } from '../../../actions/partner'
import { createChance } from '../../../actions/chance'

import { getAllData as getUser, getUserById } from '../../../actions/user'
import { getAllDataNoneRedux as getTeam } from '../../../actions/team'

import { getAllData as getProject } from '../../../actions/project';

import { uploadFile } from '../../../actions/file'

import { _showAlertSuccess } from '../../../untils/error';
import { GET_SUCCESS } from '../../../enums/vi';
import { debounce, find, findIndex, map, uniqBy } from 'lodash';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useTranslation } from 'react-i18next';

const initialState = {
    customerId: '',
    address: "",
    type: "A",
    description: '',
    isActive: true,
    code: '',
    phone: '',
    financialCode: '',
    assignedType: 'leader',
    leaderId: '',
    saleId: '',
    projects: [],
    partner: { _id: '', firstName: 'Choose', lastName: '...', phone: { phoneNumber: '----' } }
};

const MAX_COUNT = 5;

function AddChance(props) {
    const theme = useTheme()
    const profile = useSelector(state => state.profile)
    const edit = useSelector(state => state.edit)
    const projectsList = useSelector(state => state.project)
    const {t} = useTranslation()
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [imageUploaded, setImageUploaded] = useState([])
    const [fileLimit, setFileLimit] = useState(false)
    const [listPartner, setListPartner] = useState([])
    const [lead, setLead] = useState([])
    const [sale, setSale] = useState([])
    const [inputValue, setInputValue] = useState('')

    const [date, setDate] = React.useState(new Date());
    const [
        { customerId, type, projects, description, isActive, assignedType, leaderId, saleId, partner },
        setState
    ] = useState(initialState);


    useEffect(() => {

        const fetchData = async () => {
            await props?.getProject()
            await props?.getPartner({ condition: {}, limit: 20 }).then(res => setListPartner(res.data))
            if (profile?.userType === 'root') {
                await props?.getUser({
                    condition: {
                        userType: {
                            equal: 'leader'
                        }
                    }, limit: 200
                })
                    .then(async res => {
                        let leader = map(res?.data)
                        setLead(leader)
                    })
                    .catch(err => {
                        console.log({ err })
                    })
                await props?.getUser({
                    condition: {
                        userType: {
                            notIn: ['leader']
                        }
                    }, limit: 200
                })
                    .then(async res => {
                        let sale = map(res?.data)
                        setSale(sale)
                    })
                    .catch(err => {
                        console.log({ err })
                    })
            }
            else {
                await props?.getUserById(profile?._id)
                    .then(async res => {
                        let leader = res?.data?.telesale?.leaders || []
                        if(profile?.userType === 'leader')
                        {
                            leader.push(profile)
                        }
                        setLead(uniqBy(leader))
                        await props?.getTeam({
                            condition: {
                                leaderArr: map(leader,'_id')
                            }, limit: 100
                        }).then(res1 => {
                            let sale = []
                            res1?.data?.forEach(sal => {
                                sale = [...sale, ...sal.memberArr]
                            })
                            sale.push(profile)
                            setSale(uniqBy(sale, '_id'))
                        })
                    })
                    .catch(err => {
                        console.log({ err })
                    })
            }
        }



        const init = () => {
            let leaderId = ''
            let saleId = ''

            if (profile?.userType === 'saler') {
                leaderId = profile?._id
                saleId = profile?._id
            }
            setState(state => ({ ...state, customerId: edit?._id, partner: { ...edit }, saleId, leaderId }))
        }

        fetchData()
        init()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const chooseMultiValue = (name, value) => {
        switch (name) {
            case 'projects':
                let tmp1 = [...projects]
                let indExist1 = findIndex(tmp1, { code: value })
                if (indExist1 > -1) {
                    tmp1.splice(indExist1, 1)
                }
                else {
                    let project = find(projectsList, { code: value })
                    tmp1.push({ code: value, name: project?.name })
                }
                setState(state => ({ ...state, projects: tmp1 }))
                break;

            default:
                break;
        }
    }


    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = () => {
        let data = { customerId: partner?._id, type, description, isActive, timeToStart: date, projects }

        if (assignedType === 'leader') {
            data.leaderId = leaderId
        }

        if (assignedType === 'sale') {
            data.assignedUserId = saleId
        }

        if (profile?.userType === 'saler') {
            data.assignedUserId = saleId
        }


        props?.createChance(data)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
                props?.closeForm()
            })

        if (uploadedFiles.length > 0) {
            postFile()
        }

    }

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        const imageUploadedTmp = [...imageUploaded];

        let limitExceeded = false;
        // eslint-disable-next-line 
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                imageUploadedTmp.push({ name: file?.name, path: URL.createObjectURL(file) })
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) {
            setUploadedFiles(uploaded)
            setImageUploaded(imageUploadedTmp)
        }

    }

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }

    const postFile = async () => {
        var files = [...uploadedFiles]
        var formData = new FormData();
        formData.append('name', 'File Upload');
        [...files].forEach(fl => {
            formData.append("files", fl);
        });

        if (files.length > 0) {
            await props?.uploadFile(formData)
                .then(res => {
                    var imgId = []
                    var index = 0
                    for (let dt of res.success) {
                        imgId.push(dt._id)
                        if (index === res.success.length - 1) {
                            let partner = find(listPartner, { _id: customerId })
                            let data = {}
                            data.imageIdArr = [...imgId, ...partner.imageIdArr]
                            props?.updatePartner(data, customerId)
                                .then(res => {
                                    _clearState()
                                })
                        }
                        index++
                    }
                })
                .catch(err => {
                    console.log('uploadFile', err)
                })
        }
    }

    const onChangeInput = (e) => {
        setInputValue(e.target.value)
        searchAuto(e.target.value)
    }


    const searchAuto = debounce((value) => {
        var data = {
            "condition": {},
            "sort": {
                "created": -1
            },
            "search": value,
            "limit": 10,
            "page": 1
        }
        props?.getPartner({ data, limit: 20 }).then(res => setListPartner(res.data))
    }, 1000)


    return (
        <Box sx={{
            bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8,
            paddingBottom: 4,
        }}>
            <Stack direction="row" spacing={1}
                justifyContent='space-between'
                alignItems="center" sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.main
                }}>
                <Stack direction={'row'}>
                    <Chip label="Thêm" variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
                    <Typography variant='h5' color={'#fff'}>
                        Cơ hội
                    </Typography>
                </Stack>
                <Button variant='contained' disableElevation onClick={() => handleSubmit()}>
                    Lưu
                </Button>
            </Stack>

            <Box sx={{ paddingX: 2, paddingY: 4 }}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color={teal[500]}>
                            Thông tin cơ bản
                        </Typography>
                    </Grid>

                    <Grid item xs={8}>
                        {/* <FormControl sx={{ m: 1, minWidth: '95%' }} size="small">
                            <InputLabel id="demo-select-partner">Khách hàng</InputLabel>
                            <Select
                                labelId="demo-select-partner"
                                id="demo-select-partner"
                                value={customerId}
                                name="customerId"
                                fullWidth={true}
                                onChange={onChange}
                                label="Chọn khách hàng"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {listPartner?.map((res, ind)=>{
                                    return  <MenuItem key={ind} value={res.id}>{res.firstName} {res.lastName}</MenuItem>
                                })}
                               
                                
                            </Select>
                        </FormControl> */}

                        {listPartner?.length > 0 ?
                            <Autocomplete
                                sx={{ m: 1 }}
                                id="sale-select-partner"
                                options={[{ _id: '', firstName: t('frmChoose'), lastName: '...', phone: { phoneNumber: '----' } }, ...listPartner]}
                                isOptionEqualToValue={(option, value) => option._id === value?._id}
                                size="small"
                                value={partner}
                                autoHighlight
                                getOptionLabel={(option) => `${option?.firstName} ${option?.lastName} (${option?.phone?.phoneNumber})`}
                                onChange={(event, newValue) => {
                                    setState(state => ({ ...state, partner: newValue }))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Khách hàng"
                                        variant="outlined"
                                        value={inputValue}
                                        type="text"
                                        onChange={onChangeInput}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )} /> : <></>}
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label="Kích hoạt" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="demo-select-small">Loại</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={type}
                                name="type"
                                onChange={onChange}
                                label="Loại"
                            >
                                <MenuItem value='A'>A</MenuItem>
                                <MenuItem value='B'>B</MenuItem>
                                <MenuItem value='C'>C</MenuItem>
                                <MenuItem value='D'>D</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDatePicker
                                        label="Thời gian bắt đầu"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='body' color={teal[500]}>
                            Quan tâm
                        </Typography>
                        <Box
                            component="form"
                            sx={{
                                m: 1, width: '100%'
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <FormControl>
                                <FormGroup aria-label="position" row>
                                    {projectsList?.map((res, ind) => {
                                        return <FormControlLabel value={res.code} key={ind}
                                            checked={findIndex(projects, { code: res.code }) > -1 ? true : false}
                                            control={<Checkbox onChange={(e) => chooseMultiValue('projects', e.target.value)} />}
                                            label={`${res?.name}`} />
                                    })}
                                </FormGroup>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='body' color={teal[500]}>
                            Giao cho
                        </Typography>
                    </Grid>

                    {profile?.userType === 'saler' ?
                        <Grid item xs={4}>
                            {profile?.firstName} {profile?.lastName}
                        </Grid>
                        :
                        <Grid item xs={4}>
                            <FormControl sx={{ minWidth: 360, ml: 1.5 }}>
                                {/* <FormLabel id="demo-row-radio-buttons-group-label">Chịu trách nhiệm</FormLabel> */}
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    value={assignedType}
                                    name="assignedType"
                                >
                                    <FormControlLabel value="leader" control={<Radio
                                        onChange={(e) => customeOnChange('assignedType', e.target.value)}
                                    />} label="Leader" />
                                    <FormControlLabel value="sale" control={<Radio
                                        onChange={(e) => customeOnChange('assignedType', e.target.value)}
                                    />} label="Sale" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }



                    {profile?.userType !== 'saler' ? <Grid item xs={6}>
                        {assignedType === 'leader' ? <FormControl sx={{ m: 1, minWidth: 360, ml: 1.5 }} size="small">
                            <InputLabel id="demo-select-small">Lead</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                onChange={onChange}
                                name="leaderId"
                                value={leaderId}
                                label="Nhân viên"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {lead?.map((res, ind) => {
                                    return <MenuItem key={ind} value={res?._id}>{res?.firstName + ' ' + res?.lastName}</MenuItem>
                                })}
                            </Select>
                        </FormControl> :
                            <FormControl sx={{ m: 1, minWidth: 360, ml: 1.5 }} size="small">
                                <InputLabel id="demo-select-small">Sale</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    onChange={onChange}
                                    name="saleId"
                                    value={saleId}
                                    label="Nhân viên"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {sale?.map((res, ind) => {
                                        return <MenuItem key={ind} value={res?._id}>{res?.firstName + ' ' + res?.lastName}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>}
                    </Grid> : <></>}





                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '85ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Ghi chú"
                                    id="outlined-size-description"
                                    value={description}
                                    name="description"
                                    onChange={onChange}
                                    multiline
                                    minRows={5}
                                    size="small"
                                    fullWidth={true}
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6' color={teal[500]}>
                            Hình ảnh
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '85ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <input id='fileUpload' type='file' multiple
                                accept='application/pdf, image/png'
                                onChange={handleFileEvent}
                                disabled={fileLimit}
                            />


                            <div className="uploaded-files-list" >
                                {imageUploaded.map(file => (
                                    <div className='itemTemp'>
                                        <img src={`${file?.path}`} alt={file.name} />
                                        <span>{file.name}</span>
                                    </div>
                                ))}
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

        </Box>
    );
}


const mapDispatchToProps = {
    updatePartner, uploadFile, getUser, getUserById, createChance, getPartner, getTeam, getProject
}

export default connect(null, mapDispatchToProps)(AddChance);