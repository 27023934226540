import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { getProjectById, updateProject } from '../../../actions/project';
import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';
import { getAllData as getDataPrimary } from '../../../actions/data';
import { MenuProps } from '../../../untils/variable';

const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)
    const [
        { name, isActive, code, address, description, _id, dataCode },
        setState
    ] = useState({...editObject});

    const getDataById = () => {
        props?.getProjectById(_id).then(res => {
            setState(state => ({ ...state, ...res.data }))
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        getDataById()
        props?.getDataPrimary()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };


    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const handleSubmit = () => {
        let data = { name, isActive, code, address, description,dataCode }
        props?.updateProject(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
            })
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let arr = typeof value === 'string' ? value.split(',') : value
        setState(state => ({ ...state, dataCode: arr }))
    };

    React.useImperativeHandle(ref, () => ({ handleSubmit }));
    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative'}}>
            <Box sx={{ paddingX: 2, paddingY: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Name"
                                    id="last-name"
                                    value={name}
                                    name="name"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Code "
                                    id="code"
                                    value={code}
                                    name="code"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup sx={{ml:2}}>
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label="Active" />
                        </FormGroup>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    type="text"
                                    label="Địa chỉ"
                                    id="outlined-address"
                                    value={address}
                                    name="address"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid> */}
                      <Grid item xs={12} style={{ paddingLeft: 24 }}>
                        <Typography variant='body2' >Apply for data:</Typography>
                        <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                size='small'
                                value={dataCode}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}>
                                {props?.data?.map((item) => (
                                    <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                        <Checkbox checked={dataCode?.indexOf(item.code) > -1 ? 'checked' : ''} />
                                        <ListItemText primary={`${item.code} - ${item.name}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Description"
                                    id="outlined-size-description"
                                    multiline={true}
                                    name="description"
                                    onChange={onChange}
                                    size="small"
                                    minRows={5}
                                    fullWidth={true}
                                />
                            </div>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </Box>
    );
})
const mapDispatchToProps = {
    getProjectById, updateProject, getDataPrimary
}
const mapStateToProps = (state) => {
    return {
        editObj: state.edit,
        data: state.data
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditForm);