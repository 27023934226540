
import React, { Component } from "react";
class DocViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        const {source, type} = this.props
        return (
            <div className="holds-the-iframe">
 {type==='pdf'?<iframe title="my" src={`http://docs.google.com/gview?url=${source}&embedded=true`} width='100%' height='600' frameBorder='0'></iframe>:
 <iframe title="my" src={`https://view.officeapps.live.com/op/embed.aspx?src=${source}`} 
width='100%' height='600' frameBorder='0'>This is an embedded 
<a target='blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
}
            </div>
        );
    }
}

export default DocViewer;