const initialState = []

const employeeReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_EMPLOYEE":
            return action.payload

        case "CREATE_EMPLOYEE":
            return [action.payload, ...state]

        case "UPDATE_EMPLOYEE":
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            return [...state]

        case "CHANGE_PASSWORD":

            const password = state.findIndex(per => per._id === action.payload._id)
            state[password] = action.payload;
            return [...state]

        case "UPDATE_EMPLOYEE_ACTIVE":
            const employee_ = [...state]
            if (action.payload.length > 0) {
                (action.payload).forEach((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    employee_[index] = item;
                })
            }
            return employee_

        case "SET_CURRENT_EMPLOYEE_IMG":
            return [...state]

        case "DELETE_EMPLOYEE":
            const employee_old = [...state]
            var employee_deleted = []

            if (action.payload.length > 0) {
                employee_deleted = employee_old.filter(del => !(action.payload).includes(del._id));
            }
            return employee_deleted

        default:
            break;
    }

    return state
}

export default employeeReducer