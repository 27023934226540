import React from 'react';
import ItemMinimizePopChat from './ItemMinimizePopChat';
import { useSelector } from 'react-redux';

const ListMinimizeChat = props => {

    const minimizeChat = useSelector(state => state.minimizeChat)

    return (
        <div className='chatAbsolute'>
            {
                minimizeChat?.map((res, ind)=>{
                    return <ItemMinimizePopChat key={ind} data={res}/>
                })
            }
        </div>
    );
};


export default ListMinimizeChat;