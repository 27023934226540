import * as React from 'react';
import { useState } from 'react';

import { FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { createExpense } from '../../../actions/expense';
import { getAllData as getLanguage } from '../../../actions/language';
import { getAllData as getDataPrimary } from '../../../actions/data';
import { find, findIndex, map } from 'lodash';
import { useEffect } from 'react';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import { MenuProps } from '../../../untils/variable';


const initialState = {
    name: "",
    isActive: true,
    code: '',
    description: '',
    parentCode: '',
    language: [],
    dataCode: [],
    defaultLanguage: ''
};

const AddForm = React.forwardRef((props, ref) => {

    const [
        { name, isActive, code, description, parentCode, defaultLanguage, language, dataCode },
        setState
    ] = useState(initialState);

    const listParent = useSelector(state => state?.expense)
    const listLanguage = useSelector(state => state?.language)

    useEffect(() => {
        const init = () => {
            props?.getLanguage()
            props?.getDataPrimary()
        }

        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDefaultLanguage = (event) => {
        let langTmp = [...language]
        let stateNew = {}
        let index = findIndex(langTmp, { langCode: event.target.value })
        if (index > -1) {
            var newArr = map(langTmp, function (a) {
                return { ...a, default: false };
            });

            let tmp = { ...newArr[index] }
            tmp.default = true
            langTmp[index] = tmp
            stateNew.language = langTmp
            stateNew.name = newArr[index]?.value
        }
        setState(state => ({ ...state, defaultLanguage: event.target.value, ...stateNew }))
    };

    const onChangeLangue = (e, lang) => {
        let langTmp = [...language]
        let text = e.target.value
        let stateNew = {}
        if (lang.code === defaultLanguage) {
            stateNew.name = text
        }
        // get index in current language property in Accountant Object
        let index = findIndex(langTmp, { langCode: lang.code })
        if (index > -1) {
            let tmp = { ...langTmp[index] }
            tmp.value = text
            langTmp[index] = tmp
            stateNew.language = langTmp
        }
        else {
            langTmp.push({
                langCode: lang.code,
                default: false,
                value: text
            })
            stateNew.language = langTmp
        }
        setState(state => ({ ...state, ...stateNew }));
    }

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = () => {
        let data = {
            name, isActive, code, description, parentCode, language, dataCode
        }
        props?.createExpense(data)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let arr = typeof value === 'string' ? value.split(',') : value
        setState(state => ({ ...state, dataCode: arr }))
    };

    React.useImperativeHandle(ref, () => ({ handleSubmit }));
    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', padding: 2, borderRadius: 1 }}>
            <Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 12, md: 12 }}>

                <Grid item xs={12} md={6} >
                    <FormGroup >
                        <FormControlLabel control={<Checkbox />}
                            checked={isActive}
                            name="isActive"
                            onChange={(e) => customeOnChange('isActive', !isActive)}
                            label="Active" />
                    </FormGroup>
                </Grid>

                <Grid item md={6} xs={12} >
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { display: 'flex' },
                        }}
                        noValidate
                        autoComplete="off" >
                        <TextField
                            label="Code "
                            id="code"
                            value={code}
                            name="code"
                            onChange={onChange}
                            size="small"
                        />
                    </Box>
                </Grid>

                <Grid item md={12} xs={12}>
                    <Typography sx={{ mb: 1, fontWeight: 600 }}>Name</Typography>
                    <RadioGroup
                        aria-labelledby="demo-defaultLanguage"
                        name="defaultLanguage"
                        value={defaultLanguage}
                        onChange={handleDefaultLanguage}>
                        {listLanguage?.map((item, index) => {
                            return <Box
                                key={index}
                                component="form"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    '& .MuiTextField-root': { flex: 1, mb: 2 },
                                }}
                                noValidate
                                autoComplete="off">
                                <TextField
                                    label={item?.name}
                                    id="last-name"
                                    value={find(language, { langCode: item.code })?.value}
                                    name="name"
                                    onChange={(e) => onChangeLangue(e, item)}
                                    size="small"
                                />
                                <FormControlLabel value={item.code} sx={{ ml: 1, mb: 2 }} control={<Radio />} label="" />
                            </Box>
                        })}
                    </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={{ mb: 1, fontWeight: 600 }}>Other</Typography>

                    <FormControl size="small" sx={{ display: 'flex' }}>
                        <InputLabel id="demo-select-parentCode">Parent</InputLabel>
                        <Select
                            labelId="demo-select-parentCode"
                            id="demo-select-parentCode"
                            value={parentCode || ""}
                            name="parentCode"
                            onChange={onChange}
                            label="Parent">
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {listParent?.map((res, ind) => {
                                return <MenuItem key={ind} value={res?.code}>{res?.code + ' - ' + res?.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} style={{ paddingLeft: 24 }}>
                    <Typography variant='body2' >Apply for data:</Typography>
                    <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            size='small'
                            value={dataCode}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}>
                            {props?.data?.map((item) => (
                                <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                    <Checkbox checked={dataCode?.indexOf(item.code) > -1 ? 'checked' : ''} />
                                    <ListItemText primary={`${item.code} - ${item.name}`} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item lg={12} xs={12}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { display: 'flex' },
                        }}
                        noValidate
                        autoComplete="off">

                        <TextField
                            label="Note"
                            id="last-description"
                            value={description}
                            multiline={true}
                            minRows={4}
                            name="description"
                            onChange={onChange}
                        />
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
})

const mapDispatchToProps = {
    createExpense, getLanguage, getDataPrimary
}

const mapStateToProps = (state) => {
    return {
        data: state.data
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddForm);