import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { find, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { connect } from 'react-redux';
import { getCreditNoteByCode as getDataCRByCode } from '../../../actions/creditNote';
import { getDebitNoteByCode as getDataDRByCode } from '../../../actions/debitNote';
import { getIncomeByCode as getDataICByCode } from '../../../actions/income';
import { getJournalEntrieByCode as getDataJEByCode } from '../../../actions/journalEntrie';
import { getAllData as getDataLog, getMore } from '../../../actions/log';
import { getOutcomeByCode as getDataOCByCode } from '../../../actions/outcome';
import { getPaymentByParent } from '../../../actions/payment';
import { getAllDataNoneRedux as getUser } from '../../../actions/user';
import config from '../../../config';
import DrawerExpand from '../../elements/drawerExpand';
import DetailForm from './DetailForm';
import { dataModule } from './dataLog';
const listType = [
    {
        code: 'AUTH',
        name: 'Authentication'
    },
    {
        code: 'IC',
        name: 'Income'
    },
    {
        code: 'OC',
        name: 'Outcome'
    },
    {
        code: 'CRB',
        name: 'Credit Note'
    },
    {
        code: 'DRB',
        name: 'Debit Note'
    },
    {
        code: 'JE',
        name: 'Journal Entries'
    }
]

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Main = (params) => {
    const { props } = params
    const [from, setFrom] = React.useState(new Date());
    const [to, setTo] = React.useState(new Date());
    const [user, setUser] = React.useState('none');
    const [listUser, setListUser] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [isLoad, setIsLoad] = React.useState(false);
    const [totalDocuments, setTotalDocuments] = React.useState(0);
    const [arrayType, setArrayType] = React.useState(['AUTH', 'IC', 'OC', 'DRB', 'CRB', 'JE']);
    const [openBottom, setOpenBottom] = React.useState(false)
    const [dataParent, setDataParent] = React.useState({});
    const [listBottomSheet, setListBottomSheet] = React.useState([]);


    const handleBottomSheet = async (val) => {
        switch (val?.module) {
            case 'OC':
                await props?.getDataOCByCode(val?.documentCode).then(res => {
                    if (res?.data?.length > 0) {
                        setDataParent(res.data[0])
                    }
                })
                break;
            case 'IC':
                await props?.getDataICByCode(val?.documentCode).then(res => {
                    if (res?.data?.length > 0) {
                        setDataParent(res.data[0])
                    }
                })
                break;
            case 'DRB':
                await props?.getDataDRByCode(val?.documentCode).then(res => {
                    if (res?.data?.length > 0) {
                        setDataParent(res.data[0])
                    }
                })
                break;
            case 'CRB':
                await props?.getDataCRByCode(val?.documentCode).then(res => {
                    if (res?.data?.length > 0) {
                        setDataParent(res.data[0])
                    }
                })
                break;
            case 'JE':
                await props?.getDataJEByCode(val?.documentCode).then(res => {
                    if (res?.data?.length > 0) {
                        setDataParent(res.data[0])
                    }
                })
                break;
            default:
                break;
        }

        await props?.getPaymentByParent(val?.documentCode).then(res => {
            setListBottomSheet(res.data)
        })
        setOpenBottom(true)
    }

    const init = async () => {
        let condition = {
            "condition": {
                created: {
                    from: from,
                    to: to,
                },
            },
            "search": '',
            "sort": {
                "created": -1
            },
            "limit": 25,
            "page": page
        }
        await getData(condition)
        await props?.getUser().then(res1 => {
            setListUser(res1?.data)
        })
    }

    const getData = async (condition) => {
        await props?.getDataLog(condition).then(res => {
            setPage(res?.data?.meta?.page)
            setTotalDocuments(res?.data?.meta?.totalDocuments)
        })
    }

    const loadMore = async () => {
        setIsLoad(true)
        let condition = {
            "condition": {
                created: {
                    from: from,
                    to: to,
                },
            },
            "search": '',
            "sort": {
                "created": -1
            },
            "limit": 25,
            "page": page + 1
        }

        if (!isEmpty(user) && user !== 'none') {
            condition.condition.userCreate = {
                "equal": user
            }
        }

        await props.getMore(condition)
            .then(res => {
                setPage(res?.data?.meta?.page)
                setTotalDocuments(res?.data?.meta?.totalDocuments)
                setIsLoad(false)
            })
    }

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filter = async () => {
        let condition = {
            "condition": {
                created: {
                    from: from,
                    to: to,
                }
            },
            "search": '',
            "sort": {
                "created": -1
            },
            "limit": 25,
            "page": 1
        }

        if (!isEmpty(user) && user !== 'none') {
            condition.condition.userCreate = {
                "equal": user
            }
        }

        await getData(condition)
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setArrayType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Grid container p={2}>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center' }}>
                <Box ml={1} sx={{ maxWidth: '50%', overflow: 'hidden', display: 'flex', flexDirection: 'row' }} alignSelf={'center'}>
                    <FormControl sx={{ m: 1, width: 240 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={arrayType}
                            onChange={handleChange}
                            input={<OutlinedInput size='small' label="Type" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {listType.map((item, ind) => {
                                let isChecked = arrayType.indexOf(item?.code) > -1
                                return <MenuItem key={ind} value={item?.code}>
                                    {isChecked ? <CheckBoxIcon fontSize='small' /> : <CheckBoxOutlineBlankIcon fontSize='small' />}
                                    <ListItemText sx={{ ml: 0.5 }} primary={item?.name} />
                                </MenuItem>
                            }
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline', background: '#fff', padding: 1, borderRadius: 1 }}>
                    <FormControl sx={{ m: 0, minWidth: 180, }} size="small" >
                        <InputLabel id="demo-simple-select-label">User</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={user}
                            label="User"
                            onChange={(e) => setUser(e.target.value)}>
                            <MenuItem value={'none'}>Choose</MenuItem>
                            {listUser?.map((res, ind) => {
                                return <MenuItem key={ind} value={res?._id}>({res?.userName}) - {res?.firstName} {res?.lastName}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex', ml: 1.5 },
                                '& .MuiInput-root': { width: 130 }
                            }}
                            noValidate
                            autoComplete="off">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    className='customeChooseDate'
                                    format={'DD/MM/YYYY'}
                                    value={dayjs(from)}
                                    label={'From'}
                                    onChange={(newValue) => {
                                        setFrom(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex', ml: 1.5 },
                                '& .MuiInput-root': { width: 130 }
                            }}
                            noValidate
                            autoComplete="off">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    className='customeChooseDate'
                                    format={'DD/MM/YYYY'}
                                    value={dayjs(to)}
                                    label={'To'}
                                    onChange={(newValue) => {
                                        setTo(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Button sx={{ ml: 2, mr: 2 }}
                            onClick={filter}
                            variant='contained' size='small' disableElevation>Filter</Button>
                    </Box>
                </Box>
            </Box>
            <List sx={{
                width: '100%', bgcolor: 'background.paper', paddding: 16, overflowY: 'scroll',
                height: ' calc(100vh - 180px)'
            }}>
                {props?.data?.map((res, ind) => {
                    let isExist = arrayType.indexOf(res?.module) > -1

                    return isExist ? <ListItem key={ind} sx={{ borderBottom: '0.5px dashed #9a9494' }}>
                        <ListItemAvatar>
                            <Avatar src={config.baseURL + res?.creater?.profile?.fileAvatar?.path}> </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{ m: 0 }} primary={
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: "column"
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Chip sx={{ minWidth: 60 }} size="small" label={res?.action} />
                                        <Typography variant='body' sx={{ ml: 1 }}><b style={{ color: '#007adc' }}>
                                            {find(dataModule, { code: res?.module })?.name || res?.module}</b> {res?.documentCode ? ' - ' + res?.documentCode : ''}
                                        </Typography>
                                    </div>
                                    <Typography sx={{ mt: 0.5 }} variant='caption'>
                                        @{res?.creater?.userName} - {res?.creater?.firstName + ' ' + res?.creater?.lastName}
                                        &nbsp; at {moment(res?.created).format('DD-MM-YYYY HH:mm:ss')}
                                    </Typography >
                                </div>
                                {res?.module === 'AUTH' ?
                                    <div style={{ pl: 16 }}>
                                        <Typography variant='body2'>[{res?.moreInfo?.osName}, {res?.moreInfo?.browserName}, {res?.moreInfo?.osVersion}]</Typography>
                                        <Typography variant='caption'>{res?.moreInfo?.IPv4}, {res?.moreInfo?.userAgent}</Typography>
                                    </div> : <></>}

                                {['IC', 'OC', 'CRB', 'DRB', 'JE'].indexOf(res?.module) > -1 ?
                                    <Button onClick={() => handleBottomSheet(res)}>
                                        <MoreVertIcon />
                                    </Button>
                                    : <></>}
                            </div>
                        } secondary={
                            <>

                            </>
                        } />
                    </ListItem> : ''
                })}
            </List>

            <div style={{
                position: 'absolute',
                bottom: '0px',
                background: '#fff',
                width: '100%',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: 13,
                color: '#1b678c'
            }}>{props?.data?.length}/{totalDocuments} {props?.data?.length < totalDocuments ?
                <>
                    {isLoad === false ?
                        <Button size='small' variant='text' sx={{ textTransform: 'inherit' }}
                            onClick={() => loadMore()}
                            disableElevation >Get more...</Button> : <LoadingButton loading variant="text" >
                            Loading
                        </LoadingButton>
                    }

                </>
                : ''}</div>

            {openBottom ? <DetailForm isOpen={openBottom}
                listBottomSheet={listBottomSheet}
                dataParent={dataParent} handleClose={() => setOpenBottom(false)} /> : <></>}

        </Grid >
    );
};


const Log = (props) => {
    let title = "Log"
    document.title = title

    return (
        <>
            <DrawerExpand headerText={title}>
                <Main props={props} />
            </DrawerExpand>
        </>
    );
};

const mapDispatchToProps = {
    getDataLog, getUser, getMore, getDataOCByCode,
    getPaymentByParent, getDataICByCode,
    getDataJEByCode, getDataCRByCode, getDataDRByCode
}

const mapStateToProps = (state) => {
    return {
        data: state.log
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Log);