const initialState = []

const requestReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_REQUEST":
            return action.payload

        case "CREATE_REQUEST":
            return [action.payload, ...state]

        case "UPDATE_REQUEST":
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            return [...state]

        case "CHANGE_PASSWORD":

            const password = state.findIndex(per => per._id === action.payload._id)
            state[password] = action.payload;
            return [...state]

        case "UPDATE_REQUEST_ACTIVE":
            const request_ = [...state]
            if (action.payload.length > 0) {
                (action.payload).forEach((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    request_[index] = item;
                })
            }
            return request_

        case "SET_CURRENT_REQUEST_IMG":
            return [...state]

        case "DELETE_REQUEST":
            const request_old = [...state]
            var request_deleted = []

            if (action.payload.length > 0) {
                request_deleted = request_old.filter(del => !(action.payload).includes(del._id));
            }
            return request_deleted

        default:
            break;
    }

    return state
}

export default requestReducer