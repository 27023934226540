import { Box, Button, Checkbox, colors, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { updateCommunicateStage } from '../../../../actions/chance';
import ChanceNote from './ChanceNote';

const initData = {
  
        "hasInfoZalo": false,
        "hasInfoCall": false,
        "hasAppointment": false,
        "appointment": {
            "date": "",
            "location": "",
            "who": "",
            "description": ""
        },
        "additionalAttributes": {
            "customerName": "customerName",
            "customerAge": 5
        }
   
}


const Step1 = React.forwardRef((props, ref) => {
    const minDateTime = new Date();
    minDateTime.setHours(17);
    const maxDateTime = new Date();
    maxDateTime.setHours(20);

    const editObject = useSelector(state => state.edit)
    const [
        { hasInfoZalo, hasInfoCall, hasAppointment, appointment },
        setState
    ] = useState({...initData });

    useEffect(() => {
       let  communicationStage  = {...editObject?.communicationStage}
     setState(state=>({...state, ...editObject?.communicationStage, 
        hasAppointment: communicationStage?.appointment?.date?true:false}))
    }, [editObject?.communicationStage ])
    

    

    const onChange = e => {
        const { name, value } = e.target;
        let appointmentTmp = { ...appointment }

        switch (name) {
            case 'date':
                appointmentTmp.date = value
                break;

            case 'location':
                appointmentTmp.location = value
                break;

            case 'who':
                appointmentTmp.who = value
                break;

            case 'description':
                appointmentTmp.description = value
                break;

            default:
                break;
        }
        setState(state => ({ ...state, appointment: appointmentTmp }));
    };

    

    const handleSubmit = () =>{
        let data = {
            communicationStage:{},
            // stage:'communication'
        }

        if(hasInfoZalo)
        {
            data.communicationStage.hasInfoZalo = hasInfoZalo
        }
        if(hasInfoCall)
        {
            data.communicationStage.hasInfoCall = hasInfoCall
        }
        if(hasAppointment)
        {
            data.communicationStage.hasAppointment = hasAppointment
        }
        if(!isEmpty(appointment?.date))
        {
            data.communicationStage.appointment = appointment
        }

        if(data?.communicationStage)
        {
            props?.updateCommunicateStage(editObject?._id, data, {...editObject})
        }

    }


    return (
        <Box component={"div"} sx={{ minHeight: 450, padding: 2 }}>
            <Grid container spacing={2}>


                <Grid container sx={{ backgroundColor: grey[50], padding: 2, mt: 3 }}>
                    <Grid item xs={6} sx={{ padding: 0, mt: 1.5 }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name="hasInfoZalo"
                                value={true}
                                checked={hasInfoZalo?true:false}
                                onChange={(e) => setState(state => ({ ...state, hasInfoZalo: e.target.value }))}
                            />} label="Kết nối qua kênh Zalo" />

                            <FormControlLabel control={<Checkbox name="hasInfoCall"
                                disabled={!hasInfoZalo}
                                value={true}
                                checked={hasInfoCall?true:false}

                                onChange={(e) => setState(state => ({ ...state, hasInfoCall: e.target.value }))}
                            />} label="Kết nối qua cuộc gọi(Gọi điện)" />

                            <FormControlLabel control={<Checkbox name="hasAppointment"
                                value={true}
                                checked={hasAppointment?true:false}

                                disabled={!hasInfoZalo || !hasInfoCall}
                                onChange={(e) => setState(state => ({ ...state, hasAppointment: e.target.value }))}
                            />} label="Khách đồng ý hẹn gặp mặt (*)" />
                        </FormGroup>
                        {hasAppointment ?
                        <>
                         <Typography variant='subtitle1' sx={{ mt: 2, color: colors.teal[500] }}>Thông tin cuộc hẹn</Typography>
                         <Stack direction="column">
                             <Typography variant='subtitle2'>- <b>Thời gian:</b> {moment(appointment?.date).format('DD/MM/YYYY HH:mm') || '...'} </Typography>
                             <Typography variant='subtitle2'>- <b>Địa điểm:</b> {appointment?.location || '...'} </Typography>
                             <Typography variant='subtitle2'>- <b>Người liên hệ:</b> {appointment?.who || '...'} </Typography>
                             <Typography variant='subtitle2'>- <b>Ghi chú:</b> {appointment?.description || '...'} </Typography>
                         </Stack></>:<span></span>}
                        <Button variant="contained" disableElevation 
                        sx={{mt:3}} onClick={()=>handleSubmit()}
                        >Cập nhật</Button>
                    </Grid>
                    {hasAppointment ?
                        <Grid item xs={6} sx={{ padding: 0, mt: 1.5 }}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    label="Thời gian"
                                    id="last-time"
                                    value={appointment?.date}
                                    name="date"
                                    type="datetime-local"
                                    inputProps={{
                                        min: minDateTime.toISOString().slice(0, 16),
                                        // max: maxDateTime.toISOString().slice(0, 16)
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={onChange}
                                    size="small"
                                />
                            </Box>

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    label="Địa điểm"
                                    id="last-location"
                                    value={appointment?.location}
                                    name="location"
                                    onChange={onChange}
                                    size="small"
                                />
                            </Box>

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    label="Người hẹn"
                                    id="last-who"
                                    value={appointment?.who}
                                    name="who"
                                    onChange={onChange}
                                    size="small"
                                />
                            </Box>

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    label="Ghi chú"
                                    id="last-description"
                                    value={appointment?.description}
                                    name="description"
                                    onChange={onChange}
                                    size="small"
                                />
                            </Box>

                        </Grid>
                        :
                        <span></span>
                    }

                </Grid>



                <Grid item xs={12}>
                {props?.activeStep===1&&
                    <ChanceNote stage={props?.stage}></ChanceNote>
                }
                </Grid>
            </Grid>
        </Box>
    );
});

const mapDispatchToProps = {
    updateCommunicateStage
}


export default connect(null, mapDispatchToProps)(Step1);