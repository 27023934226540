const LOGIN_SUCCESS = 'Login successfully!'
const LOGIN_FAILURE = 'Something went wrong, please try again!'
const GET_SUCCESS = 'Successfully!'
const GET_FAILURE = 'Failure, Please try again!'
const DATA_NOT_FOUND = 'DATA NOT FOUND!'


export {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    GET_SUCCESS,
    GET_FAILURE,
    DATA_NOT_FOUND
}