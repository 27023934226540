import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Divider, Grid, IconButton, Tooltip } from '@mui/material';
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getMessageGroupByCode, removeMemberChatGroup } from "../../../actions/chat";
import { emitSeenMessage } from '../../../actions/socketInside';
import { getAllDataUser } from "../../../actions/user";
import config from "../../../config/index";
import { AlertConfirm } from "../../../untils/AlertConfirm";
import { parseError } from "../../../untils/parseError";
import DrawerExpand from '../../elements/drawerExpand';
import ContactList from './ContactList';
import CreateGroupChat from './CreateGroupChat';
import LastedMessage from './LastedMessage';
import MainChatDetail from './MainChatDetail';
import "./custom.css";

const initialState = {
    keyWord: '',
    showContactList: false,
    openAdd: false,
    isEdit: false
}

const Main = (params) => {
    const { props } = params
    const [
        { keyWord, showContactList, openAdd, isEdit },
        setState
    ] = useState(initialState);

    const dispatch = useDispatch()

    const { currentChatMessage, profile, chatUserOnline } = props

    useEffect(() => {
        init()
        dispatch({
            type: "SET_ENUM",
            payload: {
                openDrawer: false
            }
        })
        // eslint-disable-next-line
    }, [])

    const getDetailGroupAfterCreate = (groupChatCode) => {
        props.getMessageGroupByCode(groupChatCode)
    }

    const init = async () => {
        await props.getAllDataUser()
    }

    const _handleOpenAddGroup = () => setState(state => ({ ...state, openAdd: true }))
    const _handleOpenEditGroup = () => setState(state => ({ ...state, openAdd: true, isEdit: true }))
    const _handleCloseAddGroup = (isCreateGroup, groupCode) => {
        if (isCreateGroup === true) {
            console.log({ groupCode })
            getDetailGroupAfterCreate(groupCode)
            setState(state => ({ ...state, openAdd: false, isEdit: false, showContactList: false }))
        }
        else {
            setState(state => ({ ...state, openAdd: false, isEdit: false }))
        }
    }

    const showList = (show) => {
        setState(state => ({ ...state, showContactList: show === true ? false : true }))
    }

    const onChange = (e) => {
        setState(state => ({ ...state, [e.target.name]: e.target.value }))
    }

    const messageGroupDetail = (group) => {
        if (group.viewerIdArr.indexOf(profile.userId) < 0) {
            let data = {
                room: group.groupChatCode,
                messageIds: [group._id]
            };
            console.log('seenMassage')
            emitSeenMessage(data)
        }
        props.getMessageGroupByCode(group.groupChatCode)
    }


    const removeMember = (user) => {
        let data = {
            groupCode: currentChatMessage.code,
            userIds: [
                user.userId._id
            ]
        }

        if (window.confirm(`Are you sure?`)) {
            props.removeMemberChatGroup(data)
                .then(res => {
                    console.log('removeUserInChatGroup', res);
                })
                .catch(err => {
                    AlertConfirm(parseError(err.error))
                })
        }
    }

    const renderAvatarGroup = () => {
        return currentChatMessage?.memberArr?.map((receive, i) => {
            var prof = _.get(receive, 'employeeId', {})

            if (i < 2) {
                return <div className="avChild" key={i}>
                    {isEmpty(prof?.fileAvatar?.path) ? prof?.firstName.charAt(0) : <img src={config.baseURL + prof?.fileAvatar?.path} alt={_.get(prof, 'lastName', '')} />}
                </div>
            }
            else if (i === currentChatMessage?.memberArr.length - 1) {
                return <div className="avChild absol" key={i}>+{currentChatMessage?.memberArr.length - 2}</div>
            }
            else {
                return ''
            }
        })

    }

    var name = ''
    var status = 'Offline'
    var isSingle = false
    var isOnline = false
    if (_.get(currentChatMessage, 'type', '') === 'single') {

        var indexEmp = currentChatMessage.memberArr.findIndex(sss => sss.employeeCode !== profile.employeeCode)
        var prof = currentChatMessage.memberArr[indexEmp]
        name = `${_.get(prof, 'name', '')} `
        isSingle = true

        if (chatUserOnline.findIndex(us => us === prof.userId._id) > -1) {
            isOnline = true
            status = 'Đang hoạt động'
        }
    }


    return (
        <React.Fragment>
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start" spacing={1}>

                <Grid item xs={12} sm={3}>
                    <div className="leftColChat">
                        <div className="topLeft">
                            <div className="searchTool">
                                <div className="group-search" >
                                    <input
                                        className="input-Search"
                                        value={keyWord}
                                        name="keyWord"
                                        placeholder="Search ..."
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="icon-search">
                                    <SearchOutlinedIcon />
                                </div>
                            </div>
                            <div className="actionTool">
                                <Tooltip title="New message">
                                    <IconButton aria-label="delete"
                                        onClick={() => showList(showContactList)}>
                                        <AddOutlinedIcon color="inherit" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>

                        {
                            // chatMessageLasted?.length > 0 ?
                            <LastedMessage getListMessageDetail={messageGroupDetail} />
                            // :
                            // <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} >
                            //     <Button variant="contained"
                            //         onClick={() => showList(showContactList)}
                            //         disableElevation>
                            //         New message
                            //     </Button>
                            // </div>
                        }


                        <ContactList showContactList={showContactList}
                            actionShow={() => showList(showContactList)}
                            handleOpenAddGroup={_handleOpenAddGroup} />

                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {!isEmpty(currentChatMessage) ? <MainChatDetail /> :
                        <></>}
                </Grid>
                <Grid item xs={12} sm={3}>
                    {!isEmpty(currentChatMessage) ? <div className="rightColChat">
                        <div className="topInfoChat">
                            {isSingle === false && <div className="avatar group">
                                {renderAvatarGroup()}
                            </div>}

                            {isSingle === true && <div className="avatar alone">
                                {_.get(prof, 'employeeId.fileAvatar.path', '') === '' ? _.get(prof, 'employeeId.lastName', '').charAt(0) : <div className="ctImg"><img src={config.baseURL + _.get(prof, 'employeeId.fileAvatar.path', '')} alt={_.get(prof, 'employeeId.lastName', '')} /></div>}
                            </div>}

                            <div className="titGroup">{isSingle === false ? currentChatMessage?.name : name}
                                {isSingle === true && <p> <span className={`statusChat ${isOnline === true && "onl"}`}></span>{status}</p>}
                            </div>
                        </div>
                        <div className="bodyInfoChat">
                            {/* <ul className="toolInfo">
                                    <li>
                                        <div className="imgTool"><img src="/resources/icons/team.png" alt="change name group"/></div>
                                        <div className="textTool">Thay đổi tên nhóm</div>
                                    </li>
                                    <li>
                                        <div className="imgTool"><img src="/resources/icons/image.png" alt="change picture group"/></div>
                                        <div className="textTool">Thay đổi ảnh nền</div>
                                    </li>
                                </ul> */}
                            <Divider />
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="topToolInfo">Member list:
                                </div>
                                {_.findIndex(_.get(currentChatMessage, 'memberArr', []), { isMain: true, employeeCode: profile?.employeeCode }) > -1 &&
                                    currentChatMessage.type === "multiple" ?
                                    <div style={{ color: '#2196f3', cursor: 'pointer' }} onClick={() => _handleOpenEditGroup()}>+ Add</div> : ''}
                            </div>

                            <ul className="listMemberChat member">
                                {_.get(currentChatMessage, 'memberArr', []).map((receive, i) => {
                                    var prof = _.get(receive, 'employeeId', {})
                                    return <li key={`${i}rano`}>
                                        <div className="avatarImgMember">
                                            <div className="imgMemberChat">
                                                {isEmpty(receive?.employeeId?.fileAvatar?.path) ? prof.lastName.charAt(0) : <img src={config.baseURL + _.get(receive, 'employeeId.fileAvatar.path', '')} alt={prof.lastName} />}
                                            </div>
                                            {_.includes(chatUserOnline, receive?.userId?._id) === true ? <div className="online"></div> : <div className="offline"></div>}
                                            {receive.isMain === true && currentChatMessage.type === "multiple"
                                                ? <div className="ownerRomm"><img src="/resources/icons/crown.png" alt="crown" /></div> : ''}
                                            {currentChatMessage.type === "multiple" && _.findIndex(_.get(currentChatMessage, 'memberArr', []), { isMain: true, employeeCode: profile.employeeCode }) > -1 && profile.employeeCode !== receive.employeeCode
                                                ? <div className="removeList" onClick={() => removeMember(receive)}>-</div> : ''}
                                        </div>
                                        <div className="textTool">
                                            <span>{prof.firstName} {prof.lastName} <></> </span>
                                            <p>@{receive?.userId?.userName}</p>
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div> : <></>}
                </Grid>
            </Grid>

            {openAdd && <CreateGroupChat open="open" close={_handleCloseAddGroup} isEdit={isEdit} />}

        </React.Fragment >

    );
};

const ChatInside = (props) => {
    // const { t } = useTranslation()
    let title = 'Messenger'
    document.title = title
    return (
        <>
            <DrawerExpand headerText={title}>
                <Main props={props} />
            </DrawerExpand>
        </>
    );
};

const mapDispatchToProps = {
    getAllDataUser, emitSeenMessage, getMessageGroupByCode,
    removeMemberChatGroup
}

const mapStateToProps = (state) => {
    return {
        chatUserOnline: state.chatUserOnline,
        chatMessageLasted: state.chatMessageLasted,
        chatGroup: state.chatGroup,
        userAll: state.userAll,
        profile: state.profile,
        currentChatMessage: state.currentChatMessage
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatInside)