import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { connect } from 'react-redux';
import { store } from '../../store';
import { Box, Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MainAlert(props) {
  // const navigate = useNavigate()
 
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    store.dispatch({
      type: "SET_ALERT_VAL",
      payload: {
        type:'info',
          message: '',
          isOpen: false
      }
    })
  };

  // React.useEffect(() => {
  //   // if(props?.data.errorCode === 401)
  //   // {
  //   //   navigate('/login',{replace:true})
  //   // }
  //   // console.log('ssadsa', props?.data?.isOpen);
  //   // if(props?.data?.isOpen === true)
  //   // {
  //   //   const timer = window.setInterval(() => {
  //   //     console.log('1 second has passed');
  //   //   }, 5000);
  //   //   return () => { // Return callback to run on unmount.
  //   //     window.clearInterval(timer);
  //   //   };
  //   // }
  // }, [props?.data?.isOpen])

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={props?.data?.isOpen} autoHideDuration={4000} onClose={handleClose}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Alert onClose={handleClose} severity={props?.data?.type || 'success'} sx={{ width: '100%' }}>
            {props?.data?.message}
            {props?.data?.action?<Button 
             style={{ backgroundColor: '#ffff', marginLeft: 2, color: '#4db6ac' }}
            href={props?.data?.action}>
            {props?.data?.textHref}
          </Button>:''}
        </Alert>
        </Box>
      </Snackbar>
    </Stack>
  );
}

const mapStateToProps = (state) =>{
    return {
        data: state.alert
    }
}

export default connect(mapStateToProps)(MainAlert)