import { findIndex } from "lodash"

const initialState = []

const minimizeReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_MINI_CHAT":
            return action.payload

        case "CREATE_MINI_CHAT":
            return [...state, action.payload]

        case "UPDATE_MINI_CHAT":

            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;

            return [...state]

        case "CHANGE_PASSWORD":

            const password = state.findIndex(per => per._id === action.payload._id)
            state[password] = action.payload;

            return [...state]

        case "UPDATE_MINI_CHAT_ACTIVE":
            const rule_ = [...state]
            if (action.payload.length > 0) {
                (action.payload).forEach((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    rule_[index] = item;
                })
            }
            return rule_

        case "SET_CURRENT_MINI_CHAT_IMG":
            return [...state]

        case "DELETE_MINI_CHAT":
            let rule_old = [...state]
            let indexs = findIndex(rule_old, { groupChatCode: action.payload })
            console.log('DELETE_MINI_CHAT',indexs,  rule_old, action.payload)
            if (indexs > -1) {
                rule_old.splice(indexs, 1)
            }
            return [...rule_old]


        default:
            break;
    }

    return state
}

export default minimizeReducer