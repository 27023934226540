import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { filter, isEmpty, map, orderBy, sumBy } from 'lodash';
import * as React from 'react';
import { renderNumberDollar } from '../../../../untils/number';
import DialogPrint from './DialogPrint';

import EditableLabel from 'react-editable-label';
import { useEffect } from 'react';
import moment from 'moment';

export const DetailForm = React.forwardRef((props, ref) => {
    let { beginBlance, data, condition, isPrint, accountNumber, title, startDate, endDate } = props
    const [main, setMain] = React.useState([])


    const [dataHeader, setDataHeader] = React.useState({
        date: moment().format("MMMM Do YYYY"),
        company: 'Company',
        address: 'Address',
        phone: 'Phone',
        title: title
    });

    useEffect(() => {
        setDataHeader(state => ({ ...state, title }))
    }, [title])


    let beginBalanceMain = 0

    let tmp = beginBlance.filter(item => map(accountNumber, 'code').indexOf(item.accountNumber) > -1)
    if (tmp.length > 0) {
        beginBalanceMain = sumBy(tmp, 'beginDebitAmount')
    }

    let sumDebit = 0
    let sumCredit = 0

    const repare = async () => {
        let tmp = await data?.map((item, index) => {
            let start = index === 0 ? beginBalanceMain : data[index - 1]?.yesterday
            let ic = filter(item.detail, { paymentType: "DRB" })
            let oc = filter(item.detail, { paymentType: "CRB" })
            let debit = sumBy(ic, 'amount')
            let credit = sumBy(oc, 'amount')
            let end = start + credit - debit
            sumDebit += Math.abs(debit)
            sumCredit += Math.abs(credit)
            item.yesterday = end
            item.end = end
            item.sumDebit = sumDebit
            item.sumCredit = sumCredit
            item.debit = debit
            item.credit = credit
            return item
        })

        return tmp
    }


    const handleSort = (data, sortBy, sortType) => {
        let temp = orderBy(data, sortBy, sortType);
        return temp
    }

    const handleDebit = (data, val) => {
        let temp = filter(data, { accountNumberDebit: val });
        return temp
    }

    const handleCredit = (data, val) => {
        let temp = filter(data, { accountNumberCredit: val });
        return temp
    }

    const handleDepartment = (data, val) => {
        let temp = filter(data, { departmentCode: val });
        return temp
    }

    const handleExpense = (data, val) => {
        let temp = filter(data, { expenseCode: val });
        return temp
    }

    React.useEffect(() => {

        const init = async () => {
            const { sortBy, sortType, debitSearch, creditSearch, departmentSearch, expenseSearch } = condition
            let source = [...data]
            let tmp = [...data]
            if (!isEmpty(sortBy) && sortBy !== 'none') {
                tmp = await handleSort(source, sortBy, sortType || 'asc')
            }

            if (!isEmpty(debitSearch)) {
                tmp = await handleDebit(tmp, debitSearch)
            }

            if (!isEmpty(creditSearch)) {
                tmp = await handleCredit(tmp, creditSearch)
            }

            if (!isEmpty(departmentSearch)) {
                tmp = await handleDepartment(tmp, departmentSearch)
            }

            if (!isEmpty(expenseSearch)) {
                tmp = await handleExpense(tmp, expenseSearch)
            }
            setMain(tmp)
        }
        repare()
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition])

    let mainLength = main.length

    const handleClosePrint = () => {
        props?.handleClosePrint(false)
    }

    const componentPrint = () => {
        return <div className='printFileReport' ref={ref} >
             <div className='containHeaderPrint'>
                <div className='leftOfHead'>

                    <div className='rowLeftPrint' style={{ fontStyle: 'italic' }}>
                        <EditableLabel
                            initialValue={dataHeader?.date}
                            save={value => {
                                setDataHeader(state => ({ ...state, date: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.company}
                            save={value => {
                                setDataHeader(state => ({ ...state, company: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.address}
                            save={value => {
                                setDataHeader(state => ({ ...state, address: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.phone}
                            save={value => {
                                setDataHeader(state => ({ ...state, phone: value }))
                            }}
                        />
                    </div>
                    <div className='briefPrintLine'>(Tapping above lines to edit)</div>
                </div>
                <div className='topFileReport'>
                    <div className='titFileReport'>
                        <EditableLabel
                            initialValue={dataHeader?.title}
                            save={value => {
                                setDataHeader(state => ({ ...state, title: value }))
                            }}
                        />
                    </div>
                    <div className='dateFileReport'>From {startDate} to {endDate}</div>
                    <div className='containFileReport'>
                        <div className='colFileReport'>
                            <div className='lineColReport'>
                                <div className='detailColFile'>
                                    {accountNumber?.map((res, ind) => {
                                        return <span>{res?.code} {ind < accountNumber?.length - 1 ? ', ' : ''}</span>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Table sx={{ width: '100%', border: '1px solid #d2d2d2' }} className='tableRp tblPrint'>
                <TableHead>
                    <TableRow sx={{ background: '#F7FAFC' }}>
                        <TableCell>#</TableCell>
                        <TableCell align="left">Transaction Date</TableCell>
                        <TableCell align="right">Debit Amount </TableCell>
                        <TableCell align="right">Credit Amount</TableCell>
                        <TableCell align="right">Balance </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="right"></TableCell>
                        <TableCell align="left" >
                            <b>Begining</b>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"><span className='fmNumber'> <b>{renderNumberDollar(beginBalanceMain)}</b></span></TableCell>
                    </TableRow>
                    {main?.map((item, index) => {
                        return <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell align="left" >
                                {item?._id?.date}
                            </TableCell>
                            <TableCell align="right"><span className='fmNumber'> {renderNumberDollar(item?.debit)}</span></TableCell>
                            <TableCell align="right"><span className='fmNumber'> {renderNumberDollar(item?.credit)}</span></TableCell>
                            <TableCell align="right"><span className='fmNumber'> {renderNumberDollar(item?.end)}</span></TableCell>
                        </TableRow>
                    })}
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" >
                            <b>Total</b>
                        </TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(main[mainLength - 1].sumDebit) : '-'}</b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(main[mainLength - 1].sumCredit) : '-'}</b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" >
                            <b>Closing</b>
                        </TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(beginBalanceMain + main[mainLength - 1].sumCredit - main[mainLength - 1].sumDebit) : '-'}</b></span></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    }

    return (<Box className="printTemplate">
        {isPrint ? <DialogPrint isOpen={isPrint} handleRenderData={componentPrint} handleCloseProps={handleClosePrint} /> : ""}
        <TableContainer component={Paper} elevation={0} sx={{ maxHeight: 'calc(100vh - 150px)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className='tableRp' stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="left">Transaction Date</TableCell>
                        <TableCell align="right">Debit Amount </TableCell>
                        <TableCell align="right">Credit Amount</TableCell>
                        <TableCell align="right">Balance </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="right"></TableCell>
                        <TableCell align="left" >
                            <b>Begining</b>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"><span className='fmNumber'> <b>{renderNumberDollar(beginBalanceMain)}</b></span></TableCell>
                    </TableRow>
                    {main?.map((item, index) => {

                        return <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell align="left" >
                                {item?._id?.date}
                            </TableCell>
                            <TableCell align="right"><span className='fmNumber'> {renderNumberDollar(item?.debit)}</span></TableCell>
                            <TableCell align="right"><span className='fmNumber'> {renderNumberDollar(item?.credit)}</span></TableCell>
                            <TableCell align="right"><span className='fmNumber'> {renderNumberDollar(item?.end)}</span></TableCell>
                        </TableRow>
                    })}
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" >
                            <b>Total</b>
                        </TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(main[mainLength - 1].sumDebit) : '-'}</b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(main[mainLength - 1].sumCredit) : '-'}</b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" >
                            <b>Closing</b>
                        </TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(beginBalanceMain + main[mainLength - 1].sumCredit - main[mainLength - 1].sumDebit) : '-'}</b></span></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
    );
})
