import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { find, get, sumBy } from 'lodash';
import * as React from 'react';
import { renderNumberDollar } from '../../../../untils/number';
import DialogPrint from './DialogPrint';

import EditableLabel from 'react-editable-label';
import { useEffect } from 'react';
import moment from 'moment';

export const TreeForm = React.forwardRef((props, ref) => {
    const { beginBlance, data, tree, isPrint, accountNumber, endDate, startDate, title } = props

    const [dataHeader, setDataHeader] = React.useState({
        date: moment().format("MMMM Do YYYY"),
        company: 'Company',
        address: 'Address',
        phone: 'Phone',
        title: title
    });

    useEffect(() => {
        setDataHeader(state => ({ ...state, title }))
    }, [title])



    let treeMain = [...tree]
    let indexMain = 0

    const getBalance = (account) => {
        let tmp = beginBlance.find(item => item.accountNumber === account)
        return tmp?.beginDebitAmount ? tmp?.beginDebitAmount : 0
    }

    const renderChild = (dataChild, padding = 0, dashed = '') => {
        return dataChild.map((item, index) => {
            indexMain += 1
            let debit = find(data.dataDebit, { _id: item.code })?.sumAmount || 0
            let credit = find(data.dataCredit, { _id: item.code })?.sumAmount || 0
            let begin = getBalance(item.code)

            item.sum = begin + credit - debit //sumBy(item.children, 'sum') || 

            return <>
                <TableRow
                    key={indexMain + index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell align="left">{indexMain}</TableCell>
                    <TableCell align="left" >
                        <div style={{ lineHeight: 1.2, paddingLeft: padding }}>
                            {item?.code} - {item?.name}
                        </div>
                    </TableCell>
                    <TableCell align="right">
                        <span className='fmNumber'> {renderNumberDollar(begin || 0)}</span>
                    </TableCell> <TableCell align="right">
                        <span className='fmNumber'>{renderNumberDollar(debit || 0)}</span>
                    </TableCell> <TableCell align="right">
                        <span className='fmNumber'>{renderNumberDollar(credit || 0)}</span>
                    </TableCell>
                    <TableCell align="right">
                        <span className='fmNumber' style={{ fontWeight: item?.children?.length > 0 ? 'bold' : '' }}>
                            {renderNumberDollar(sumBy(item.children, 'sum') || item.sum)}
                        </span>
                    </TableCell>
                </TableRow>
                {(get(item, 'children', [])).length > 0 && renderChild(item.children, padding + 12, (dashed === '' ? `--${dashed}` : `${dashed}`))}
            </>
        })
    }

    const handleClosePrint = () => {
        props?.handleClosePrint(false)
    }

    const componentPrint = () => {
        return <div className='printFileReport' ref={ref} >
            <div className='containHeaderPrint'>
                <div className='leftOfHead'>

                    <div className='rowLeftPrint' style={{ fontStyle: 'italic' }}>
                        <EditableLabel
                            initialValue={dataHeader?.date}
                            save={value => {
                                setDataHeader(state => ({ ...state, date: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.company}
                            save={value => {
                                setDataHeader(state => ({ ...state, company: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.address}
                            save={value => {
                                setDataHeader(state => ({ ...state, address: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.phone}
                            save={value => {
                                setDataHeader(state => ({ ...state, phone: value }))
                            }}
                        />
                    </div>
                    <div className='briefPrintLine'>(Tapping above lines to edit)</div>
                </div>
                <div className='topFileReport'>
                    <div className='titFileReport'>
                        <EditableLabel
                            initialValue={dataHeader?.title}
                            save={value => {
                                setDataHeader(state => ({ ...state, title: value }))
                            }}
                        />
                    </div>
                    <div className='dateFileReport'>From {startDate} to {endDate}</div>
                    <div className='containFileReport'>
                        <div className='colFileReport'>
                            <div className='lineColReport'>
                                <div className='detailColFile'>
                                    {accountNumber?.map((res, ind) => {
                                        return <span>{res?.code} {ind < accountNumber?.length - 1 ? ', ' : ''}</span>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Table sx={{ width: '100%', border: '1px solid #d2d2d2' }} className='tableRp tblPrint'>
                <TableHead>
                    <TableRow>
                        <TableCell width={60}>#</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Beginning</TableCell>
                        <TableCell align="left">Debit Amount</TableCell>
                        <TableCell align="left">Credit Amount</TableCell>
                        <TableCell align="right">Balance </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {treeMain?.map((item, index) => {
                        indexMain += 1
                        let debit = find(data.dataDebit, { _id: item.code })?.sumAmount || 0
                        let credit = find(data.dataCredit, { _id: item.code })?.sumAmount || 0
                        let begin = getBalance(item.code)
                        item.sum = begin + sumBy(item.children, 'sum') || begin + debit - credit

                        return <><TableRow
                            key={indexMain + index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">{indexMain}</TableCell>
                            <TableCell align="left" >
                                <b style={{ color: '#009688' }}>{item?.code} - {item?.name}</b>
                            </TableCell>
                            <TableCell align="right">
                                <span className='fmNumber'> {renderNumberDollar(begin || 0)}</span>
                            </TableCell> <TableCell align="right">
                                <span className='fmNumber'>{renderNumberDollar(debit || 0)}</span>
                            </TableCell> <TableCell align="right">
                                <span className='fmNumber'>{renderNumberDollar(credit || 0)}</span>
                            </TableCell>
                            <TableCell align="right">
                                <span className='fmNumber' style={{ fontWeight: item?.children?.length > 0 ? 'bold' : '' }}>
                                    {renderNumberDollar(sumBy(item.children, 'sum') || item.sum)}
                                </span>
                            </TableCell>
                        </TableRow>
                            {(get(item, 'children', [])).length > 0 && renderChild(item.children, 12, '--')}
                        </>
                    })}
                </TableBody>
            </Table>
        </div>
    }

    return (
        <Box >
            {isPrint ? <DialogPrint isOpen={isPrint} handleRenderData={componentPrint} handleCloseProps={handleClosePrint} /> : ""}
            <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader className='tableRp'>
                    <TableHead>
                        <TableRow>
                            <TableCell width={60}>#</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="left">Beginning</TableCell>
                            <TableCell align="left">Debit Amount</TableCell>
                            <TableCell align="left">Credit Amount</TableCell>
                            <TableCell align="right">Balance </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {treeMain?.map((item, index) => {
                            indexMain += 1
                            let debit = find(data.dataDebit, { _id: item.code })?.sumAmount || 0
                            let credit = find(data.dataCredit, { _id: item.code })?.sumAmount || 0
                            let begin = getBalance(item.code)
                            item.sum = begin + sumBy(item.children, 'sum') || begin + debit - credit

                            return <><TableRow
                                key={indexMain + index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{indexMain}</TableCell>
                                <TableCell align="left" >
                                    <b style={{ color: '#009688' }}>{item?.code} - {item?.name}</b>
                                </TableCell>
                                <TableCell align="right">
                                    <span className='fmNumber'> {renderNumberDollar(begin || 0)}</span>
                                </TableCell> <TableCell align="right">
                                    <span className='fmNumber'>{renderNumberDollar(debit || 0)}</span>
                                </TableCell> <TableCell align="right">
                                    <span className='fmNumber'>{renderNumberDollar(credit || 0)}</span>
                                </TableCell>
                                <TableCell align="right">
                                    <span className='fmNumber' style={{ fontWeight: item?.children?.length > 0 ? 'bold' : '' }}>
                                        {renderNumberDollar(sumBy(item.children, 'sum') || item.sum)}
                                    </span>
                                </TableCell>
                            </TableRow>
                                {(get(item, 'children', [])).length > 0 && renderChild(item.children, 12, '--')}
                            </>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );

})