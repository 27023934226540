import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { filter, isEmpty, map, orderBy, sumBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { renderNumberDollar } from '../../../../untils/number';
import DialogPrint from './DialogPrint';

export const DetailFormGroupCondition = React.forwardRef((props, ref) => {
    let { beginBlance, data, condition, accountNumber, isPrint, startDate, endDate, title } = props
    const [main, setMain] = useState([])
    const [sumDebit, setSumDebit] = useState(0)
    const [sumCredit, setSumCredit] = useState(0)


    let beginBalanceMain = 0

    let tmp = beginBlance.filter(item => map(accountNumber, 'code').indexOf(item.accountNumber) > -1)
    if (tmp.length > 0) {
        beginBalanceMain = sumBy(tmp, 'beginDebitAmount')
    }

    const repare = async () => {

        let sumCredit = 0
        let sumDebit = 0

        let tmp = await data?.map((item, index) => {
            let debitP = 0
            let creditP = 0
            let sumDebitP = 0
            let sumCreditP = 0
            let endP = 0
            let yesterdayP = 0

            item?.detail.map((item1) => {
                let start = index === 0 ? beginBalanceMain : data[index - 1]?.yesterday
                let debit = item1.paymentType === "DRB" ? item1.amount : 0
                let credit = item1.paymentType === "CRB" ? item1.amount : 0
                // let end = start + debit - credit
                let end = start + credit - debit
                sumDebit += debit
                sumCredit += credit
                sumDebitP += debit
                sumCreditP += credit
                debitP += debit
                creditP += credit
                endP += end
                yesterdayP += end

                item1.end = end
                item1.yesterday = end
                item1.sumDebit = sumDebit
                item1.sumCredit = sumCredit
                item1.debit = debit
                item1.credit = credit

                return item1
            })
            item.end = endP
            item.yesterday = yesterdayP
            item.sumDebit = sumDebitP
            item.sumCredit = sumCreditP
            item.debit = debitP
            item.credit = creditP
            return item
        })
        setSumCredit(sumCredit)
        setSumDebit(sumDebit)
        return tmp
    }

    const handleSort = (data, sortBy, sortType) => {
        let temp = orderBy(data, sortBy, sortType);
        return temp
    }

    const handleDebit = (data, val) => {
        let temp = filter(data, { accountNumberDebit: val });
        return temp
    }

    const handleCredit = (data, val) => {
        let temp = filter(data, { accountNumberCredit: val });
        return temp
    }

    const handleDepartment = (data, val) => {
        let temp = filter(data, { departmentCode: val });
        return temp
    }

    const handleExpense = (data, val) => {
        let temp = filter(data, { expenseCode: val });
        return temp
    }

    React.useEffect(() => {

        const init = async () => {
            const { sortBy, sortType, debitSearch, creditSearch, departmentSearch, expenseSearch } = condition
            let source = [...data]
            let tmp = [...data]
            if (!isEmpty(sortBy) && sortBy !== 'none') {
                tmp = await handleSort(source, sortBy, sortType || 'asc')
            }

            if (!isEmpty(debitSearch)) {
                tmp = await handleDebit(tmp, debitSearch)
            }

            if (!isEmpty(creditSearch)) {
                tmp = await handleCredit(tmp, creditSearch)
            }

            if (!isEmpty(departmentSearch)) {
                tmp = await handleDepartment(tmp, departmentSearch)
            }

            if (!isEmpty(expenseSearch)) {
                tmp = await handleExpense(tmp, expenseSearch)
            }
            setMain(tmp)
        }
        let tmo = repare()
        setMain(tmo)
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition])


    const handleClosePrint = () => {
        props?.handleClosePrint(false)
    }

    const componentPrint = () => {
        return <div className='printFileReport' ref={ref} >
            <div className='topFileReport'>
                <div className='titFileReport'>{title}</div>
                <div className='dateFileReport'>From {startDate} to {endDate}</div>
                <div className='containFileReport'>
                    <div className='colFileReport'>
                        <div className='lineColReport'>
                            <p>
                                Account:
                            </p>
                            <div className='detailColFile'>
                                {accountNumber?.map((res, ind) => {
                                    return <span>{res?.code} {ind < accountNumber?.length - 1 ? ', ' : ''}</span>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Table sx={{ width: '100%', border: '1px solid #d2d2d2' }} className='tableRp tblPrint'>
                <TableHead>
                    <TableRow sx={{ background: '#F7FAFC' }}>
                        <TableCell>#</TableCell>
                        <TableCell align="left"> Date / No.</TableCell>
                        <TableCell align="center" >Account
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{
                                    display: 'block',
                                    minWidth: '30px'
                                }}>CR </span> <span style={{
                                    display: 'block',
                                    minWidth: '30px',
                                }}>DR</span>
                            </div>
                        </TableCell>
                        <TableCell align="left">Expense</TableCell>
                        <TableCell align="left">Department</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="right">Debit Amount </TableCell>
                        <TableCell align="right">Credit Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" >
                            <b>Begining</b>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{renderNumberDollar(beginBalanceMain)}</b></span></TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    {main?.map((item, index) => {
                        return <>
                            <TableRow
                                key={index}
                                sx={{ background: '#c9e7ff!important', '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left" colSpan={2}>
                                    <b>{item?._id?.expenseCode} {item?._id?.departmentCode} - {item?._id?.expense} {item?._id?.department} </b>
                                </TableCell>
                                <TableCell align="left">{item?.parentCode}
                                </TableCell>
                                <TableCell align="left">
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <span>{item?.accountNumberDebit} </span> <span>{item.accountNumberCredit}</span>
                                    </div></TableCell>
                                <TableCell align="left">{item?.description}</TableCell>
                                <TableCell align="right">{renderNumberDollar(item.debit)}</TableCell>
                                <TableCell align="right">{renderNumberDollar(item.credit)}</TableCell>
                            </TableRow>
                            {item?.detail?.map((item1, index1) => {
                                return <TableRow
                                    key={index1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span>
                                                {moment(item1?.date).format('DD/MM/YYYY')}
                                            </span>
                                            <span>{item1?.parentCode}</span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="left">
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <span style={{
                                                display: 'block',
                                                minWidth: '30px',
                                                textAlign: 'left',
                                            }}>{item1?.accountNumberDebit} </span> <span style={{
                                                display: 'block',
                                                minWidth: '30px',
                                                textAlign: 'right',
                                            }}>{item1.accountNumberCredit}</span>
                                        </div></TableCell>
                                    <TableCell align="left">{item1?.expense?.code}</TableCell>
                                    <TableCell align="left">{item1?.department?.code}</TableCell>
                                    <TableCell align="left">{item1?.description}</TableCell>
                                    <TableCell align="right">{renderNumberDollar(item1.debit)}</TableCell>
                                    <TableCell align="right">{renderNumberDollar(item1.credit)}</TableCell>
                                </TableRow>
                            })}</>
                    })}
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" colSpan={4}>
                            <b>Total</b>
                        </TableCell>
                        <TableCell align="right">
                            <b> {renderNumberDollar(sumDebit)}</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>  {renderNumberDollar(sumCredit)}</b>
                        </TableCell>
                        <TableCell align="right"><span className='fmNumber'> <b></b></span></TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" colSpan={4}>
                            <b>Closing</b>
                        </TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'>
                            <b>  {renderNumberDollar(beginBalanceMain + sumCredit - sumDebit)}</b>
                        </span></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    }

    return (
        <Box className="printTemplate">
            {isPrint ? <DialogPrint isOpen={isPrint} handleRenderData={componentPrint} handleCloseProps={handleClosePrint} /> : ""}
            <TableContainer component={Paper} elevation={0} sx={{ maxHeight: 'calc(100vh - 150px)' }}>
                <Table sx={{ minWidth: 650 }} className='tableRp' stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px 12px!important' } }}>
                            <TableCell>#</TableCell>
                            <TableCell align="left" width={90}>Transaction Date</TableCell>
                            <TableCell align="left" width={90}> No.</TableCell>
                            <TableCell align="center" >Account
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <span style={{
                                        display: 'block',
                                        minWidth: '30px'
                                    }}>CR </span> <span style={{
                                        display: 'block',
                                        minWidth: '30px',
                                    }}>DR</span>
                                </div>
                            </TableCell>
                            <TableCell align="left">Expense</TableCell>
                            <TableCell align="left">Department</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="right">Debit Amount </TableCell>
                            <TableCell align="right">Credit Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left"></TableCell>
                            <TableCell align="left" >
                                <b>Begining</b>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"><span className='fmNumber'><b>{renderNumberDollar(beginBalanceMain)}</b></span></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                        {main?.map((item, index) => {
                            return <><TableRow
                                key={index}
                                sx={{ background: '#c9e7ff!important', '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left" colSpan={2}>
                                    <b>{item?._id?.expenseCode} {item?._id?.departmentCode} - {item?._id?.expense} {item?._id?.department} </b>
                                </TableCell>
                                <TableCell align="left">{item?.parentCode}
                                </TableCell>
                                <TableCell align="left">
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <span style={{
                                            display: 'block',
                                            minWidth: '30px',
                                            textAlign: 'left',
                                        }}>{item?.accountNumberDebit} </span> <span style={{
                                            display: 'block',
                                            minWidth: '30px',
                                            textAlign: 'right',
                                        }}>{item.accountNumberCredit}</span>
                                    </div></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">{item?.description}</TableCell>
                                <TableCell align="right">{renderNumberDollar(item.debit)}</TableCell>
                                <TableCell align="right">{renderNumberDollar(item.credit)}</TableCell>
                            </TableRow>
                                {item?.detail?.map((item1, index1) => {
                                    return <TableRow
                                        key={index1}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left" >
                                            {item1?.date ? moment(item1?.date).format('DD/MM/YYYY') : ''}
                                        </TableCell>
                                        <TableCell align="left">{item1?.parentCode}
                                        </TableCell>
                                        <TableCell align="left">
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <span style={{
                                                    display: 'block',
                                                    minWidth: '30px',
                                                    textAlign: 'left',
                                                }}>{item1?.accountNumberDebit} </span> <span style={{
                                                    display: 'block',
                                                    minWidth: '30px',
                                                    textAlign: 'right',
                                                }}>{item1.accountNumberCredit}</span>
                                            </div></TableCell>
                                        <TableCell align="left">{item1?.expense?.name}</TableCell>
                                        <TableCell align="left">{item1?.department?.name}</TableCell>
                                        <TableCell align="left">{item1?.description}</TableCell>
                                        <TableCell align="right">{renderNumberDollar(item1.debit)}</TableCell>
                                        <TableCell align="right">{renderNumberDollar(item1.credit)}</TableCell>
                                    </TableRow>
                                })}</>
                        })}
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left"></TableCell>
                            <TableCell align="left" colSpan={6}>
                                <b>Total</b>
                            </TableCell>
                            <TableCell align="right">
                                <b> {renderNumberDollar(sumDebit)}</b>
                            </TableCell>
                            <TableCell align="right">
                                <b>  {renderNumberDollar(sumCredit)}</b>
                            </TableCell>
                            <TableCell align="right"><span className='fmNumber'> <b></b></span></TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left"></TableCell>
                            <TableCell align="left" colSpan={5}>
                                <b>Closing</b>
                            </TableCell>
                            <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                            <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                            <TableCell align="right">
                                <b>  {renderNumberDollar(beginBalanceMain + sumCredit - sumDebit)}</b>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );



})