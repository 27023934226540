import _ from 'lodash'
const initialState = []

const userOnlineReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_USER_ONLINE":
            return action.payload

        case "CREATE_USER_ONLINE":
            return [...state, action.payload]

        case "UPDATE_USER_ONLINE":
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            return [...state]
        
        case "UPDATE_USER_ONLINE_ACTIVE":
            if(action.payload.length > 0)
            {
                (action.payload).map((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    return state[index] = item;
                })
            }
            return [...state] 
        
        case "DELETE_USER_ONLINE":
            var data_deleted = []
          
            if(_.get(action.payload,'userId','') !== '')
            {
                data_deleted = state.filter(del => del !== action.payload.userId);
            }
            return data_deleted
        

        default:
            break;
    }

    return state
}

export default userOnlineReducer