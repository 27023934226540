
import api from "../api/index"
import _ from "lodash"
if (_.isEmpty(api.defaults.headers['token']) === true) {
    api.defaults.headers['token'] = localStorage.getItem('token')
}

export const getMyTask = (status) => (dispatch) => {
    return api.get("/task/mine?status=" + status)
        .then(res => {
            dispatch({
                type: "SET_TASK",
                payload: { type: status, data: res.data.data }
            })
            return Promise.resolve({ success: res.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const getTaskById = (id) => (dispatch) => {
    return api.get("/task/" + id)
        .then(res => {
            dispatch({
                type: "SET_TASK_DETAIL",
                payload: res.data.data
            })
            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const getTaskCommentBySubTask = (id) => (dispatch) => {
    return api.get("/task-comment/get-by-sub-task?subTaskId=" + id)
        .then(res => {
            dispatch({
                type: "SET_TASK_COMMENT",
                payload: res.data.data
            })
            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}


export const createCommentSubTask = (data) => (dispatch) => {
    return api.post("/task-comment", data)
        .then(res => {
            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}


export const createTask = (data) => (dispatch) => {
    return api.post("/task", data)
        .then(res => {
            // dispatch({
            //     type:"CREATE_TASK",
            //     payload: res.data.data
            // })
            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })

}

export const createSubTask = (data) => (dispatch) => {
    return api.post("/sub-task", data)
        .then(res => {
            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })

}

export const replaceTask = (data) => (dispatch) => {
    dispatch({
        type: "REPLACE_TASK",
        payload: data
    })
    return Promise.resolve({ success: data })
}


export const startTask = (data) => (dispatch) => {
    return api.post("/task/start", data)
        .then(res => {

            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const completeTask = (data) => (dispatch) => {
    return api.post("/task/make-complete", data)
        .then(res => {
            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const updateTask = (id, data) => (dispatch) => {

    return api.put(`/task/${id}`, data)
        .then(res => {
            return Promise.resolve({ success: res.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}


export const deleteTask = (id) => (dispatch) => {
    return api.delete(`/task/${id}`)
        .then(res => {
            return Promise.resolve({ success: res.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}


export const completeSubTask = (data) => (dispatch) => {
    return api.post("/sub-task/make-complete", data)
        .then(res => {
            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const deleteSubTask = (id) => (dispatch) => {
    return api.delete(`/sub-task/${id}`)
        .then(res => {
            return Promise.resolve({ success: res.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}