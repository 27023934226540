import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { updateDoneStage } from '../../../../actions/chance';
import '../style.css';
import ChanceNote from './ChanceNote';

const Step6 = (props) => {
    const editObject = useSelector(state => state.edit)
    const [result, setChecked] = React.useState('success');//'fail', 'cancel'
    const onChange = (event) => {
        setChecked(event.target.value);
    };
    useEffect(() => {
        const stateChange = () => {
            let { result } = { ...editObject?.doneStage }
            setChecked(result)
        }
        stateChange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleSubmit = () => {
        let data = {
            doneStage: {},
        }
        data.doneStage.result = result
        if (data?.doneStage) {
            props?.updateDoneStage(editObject?._id, data)
        }
    }
    const bgCode = () => {
        console.log(result, typeof (result))
        switch (result) {
            case 'success':
                return {
                    background: 'linear-gradient(114deg, rgba(18,169,233,1) 0%, rgba(16,132,140,1) 51%, rgba(134,250,141,1) 100%)',
                }
            case 'fail':
                return {
                    background: 'linear-gradient(114deg, rgba(233,26,18,1) 0%, rgba(139,87,43,1) 51%, rgba(255,213,91,1) 100%)'
                }
            case 'cancel':
                return {
                    background: 'linear-gradient(114deg, rgba(34,24,24,1) 0%, rgba(111,103,97,1) 51%, rgba(46,45,42,1) 100%)'
                }
            default:
                return {
                    background: 'linear-gradient(114deg, rgba(18,169,233,1) 0%, rgba(16,132,140,1) 51%, rgba(134,250,141,1) 100%)'
                }
        }
    }
    return (
        <Box component={'div'} sx={{ minHeight: 450, padding: 2 }}>
            <Grid container spacing={2}>
                <Box sx={{
                    marginY: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flex: 1,
                    ...bgCode(),
                    paddingY: 3,
                    paddingX: 1,
                    position: 'relative',
                    minHeight: 160,
                    borderRadius: 1.5,
                    padding: 3,
                }}>
                    <Box sx={{
                        background: '#ffffff7d',
                        p: 3, display: 'flex', flexDirection: 'column',
                        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, margin: 'auto'
                    }}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Kết quả</FormLabel>
                            <RadioGroup
                                sx={{
                                    '& .Mui-checked': { color: '#fff!important' }
                                }}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                value={result}
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    className={`${result === 'success' ? 'radioChecked' : ""}`}
                                    value="success" control={<Radio onChange={onChange} />} label="Thành công" />
                                <FormControlLabel
                                    className={`${result === 'fail' ? 'radioChecked' : ""}`}
                                    value="fail" control={<Radio onChange={onChange} />} label="Thất bại" />
                                <FormControlLabel
                                    className={`${result === 'cancel' ? 'radioChecked' : ""}`}
                                    value="cancel" control={<Radio onChange={onChange} />} label="Hủy" />
                            </RadioGroup>
                        </FormControl>
                        <Button variant="contained" disableElevation
                            sx={{
                                mt: 3, background: '#ffffff9e',
                                color: 'black'
                            }} onClick={() => handleSubmit()}
                        >Hoàn thành</Button>
                    </Box>
                </Box>
                {props?.activeStep === 6 &&
                    <ChanceNote stage={props?.stage}></ChanceNote>
                }
            </Grid>
        </Box>
    );
};
const mapDispatchToProps = {
    updateDoneStage
}
export default connect(null, mapDispatchToProps)(Step6);