import * as React from 'react';
import { useState } from 'react';

import { Autocomplete, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';

import { connect, useDispatch, useSelector } from 'react-redux';
import { getAllDataNoneRedux as getAccountNumber } from '../../../actions/accountant';
import { getAllDataNoneRedux as getCurrency } from '../../../actions/currency';
import { getAllDataNoneRedux as getDepartment } from '../../../actions/department';
import { getAllDataNoneRedux as getExpense } from '../../../actions/expense';
import { createJournalEntrie, generateCode } from '../../../actions/journalEntrie';
import { getAllDataNoneRedux as getPartner } from '../../../actions/partner';
import { getPaymentByParent } from '../../../actions/payment';
import { getAllDataNoneRedux as getProduct } from '../../../actions/product';
import { getAllDataNoneRedux as getProject } from '../../../actions/project';
import { getAllDataNoneRedux as getRule } from '../../../actions/rule';
import { getAllDataNoneRedux as getVAT } from '../../../actions/vat';

import { find, isEmpty, map, sumBy } from 'lodash';
import { useEffect } from 'react';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ccyFormat } from '../../../untils/number';
import AddRowDetail from './AddRowDetail';

import CreateIcon from '@mui/icons-material/Create';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { teal } from '@mui/material/colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContactLst from '../../elements/ContactLst';
import EditRowDetail from './EditRowDetail';
import dayjs from 'dayjs';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>

            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const initialState = {
    isActive: false,
    code: '',
    phone: '',
    name: '',
    partner: { _id: '', firstName: 'Choose', lastName: '...' },
    address: '',
    exchange: 1.00,
    description: ''
};

const AddForm = React.forwardRef((props, ref) => {
    const [
        { isActive, code, description, partner, name, phone, address, exchange },
        setState
    ] = useState(initialState);
    const { t } = useTranslation()
    const [listPartner, setListPartner] = useState([])
    const [listCurrency, setListCurrency] = useState([])
    const [listAccountNumber, setListAccountNumber] = useState([])
    const [listDepartment, setListDepartment] = useState([])
    const [listExpense, setListExpense] = useState([])
    const [listRule, setListRule] = useState([])
    const [listVAT, setListVAT] = useState([])
    const [listProduct, setListProduct] = useState([])
    const [listProject, setListProject] = useState([])

    const [currency, setCurrency] = useState({})
    const [currencyDefault, setCurrencyDefault] = useState({})

    const [department, setDepartment] = useState({})
    const [accountNumber, setAccountNumber] = useState('')
    const [rule, setRule] = useState({})
    const [payment, setPayment] = useState([])
    const [date, setDate] = React.useState(new Date());

    const [valueTab, setValueTab] = React.useState(0);
    const paymentDetail = useSelector(state => state?.paymentDetail)
    const [openAdd, setAdd] = React.useState(false);
    const [openEdit, setEdit] = React.useState(false);


    const dispatch = useDispatch();
    const editRowDetail = React.useRef()
    const addRowDetail = React.useRef()
    const editObjectPayment = useSelector(state => state.editPaymentDetail)
    let totalPaymentTmp = useSelector(state => state?.enumRedux?.totalAddPayment)
    let totalEditPayment = useSelector(state => state?.enumRedux?.totalEditPayment)
    let duplicate = useSelector(state => state?.duplicate)

    const inputRef = React.useRef();
    let dataCurrent = useSelector(state => state?.enumRedux?.dataCurrent)

    useEffect(() => {
        const init = async () => {
            await props?.getPartner({ condition: {}, limit: 20 }).then(res => setListPartner(res.data))
            await props?.getCurrency({ condition: { dataCode: { in: [dataCurrent?.code] } }, limit: 1000 }).then(res => {
                let defaultCurr = find(res.data, { isDefault: true })
                setCurrency(defaultCurr)
                setCurrencyDefault(defaultCurr)
                setListCurrency(res.data)
            })
            await props?.getDepartment({ condition: { dataCode: { in: [dataCurrent?.code] } }, limit: 1000 }).then(res => setListDepartment(res.data))
            await props?.getExpense({ condition: { dataCode: { in: [dataCurrent?.code] } }, limit: 1000 }).then(res => setListExpense(res.data))
            await props?.getAccountNumber({ dataCode: { in: [dataCurrent?.code] }, condition: { isFinal: { equal: true } }, limit: 1000 }).then(res => setListAccountNumber(res.data))
            await props?.getRule({ condition: { dataCode: { in: [dataCurrent?.code] }, applyFor: { equal: 'JE' } }, limit: 1000 }).then(res => setListRule(res.data))
            await props?.getVAT({ condition: { dataCode: { in: [dataCurrent?.code] } }, limit: 1000 }).then(res => setListVAT(res.data))
            await props?.getProduct({ condition: { dataCode: { in: [dataCurrent?.code] } }, limit: 1000 }).then(res => setListProduct(res.data))
            await props?.getProject({ condition: { dataCode: { in: [dataCurrent?.code] } }, limit: 1000 }).then(res => setListProject(res.data))

            await props?.generateCode().then(res => { setState(state => ({ ...state, code: res.data })) })
            dispatch({
                type: 'SET_PAYMENT_DETAIL',
                payload: []
            })
            dispatch({
                type: "SET_ENUM",
                payload: {
                    totalAddPayment: 0,
                    totalEditPayment: {}
                }
            })
            dispatch({
                type: 'SET_EDIT_PAYMENT_DETAIL',
                payload: {}
            })

            if (!isEmpty(duplicate?.code)) {
                let data = { ...duplicate }
                setDate(data?.date)
                setRule(data?.rule)
                setCurrency(data?.currencyMain)
                setAccountNumber(data?.accounting)
                setDepartment(data?.department)
                setState(state => ({
                    partner: data?.customer, _id: data?._id,
                    code: data?.code,
                    name: data?.name,
                    exchange: data?.exchangeRate,
                    phone: data?.phone, address: data?.address, description: data?.description
                }))
                await props?.getPaymentByParent(duplicate?.code).then(res => {
                    let data = res?.data
                    dispatch({
                        type: 'SET_PAYMENT_DETAIL',
                        payload: data
                    })
                })
            }


        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (paymentDetail.length > 0)
            setPayment([...paymentDetail])
    }, [paymentDetail])


    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const handleSubmit = () => {
        let dataCode = [JSON.parse(localStorage.getItem('dataCurrent')).code]
        let paymentTmp = [...payment]
        let dataPayTmp = {}
        if (openEdit) {
            dataPayTmp = editRowDetail?.current?.checkPayment()
            if (dataPayTmp?.paymentType) {
                paymentTmp[editObjectPayment?.index] = dataPayTmp
            }
        }
        else {
            dataPayTmp = addRowDetail?.current?.checkPayment()
            if (dataPayTmp?.paymentType) {
                paymentTmp.push(dataPayTmp)
            }
        }

        let data = {
            isActive, code, description, customerCode: partner?.code, customer: partner, accountNumber: accountNumber?.code, accounting: accountNumber,
            currencyMain: currency, currencyCode: currency?.code, departmentCode: department?.code, department, ruleCode: rule?.code, rule: rule,
            phone, name,
            exchangeRate: exchange,
            creditLst: map(paymentTmp, 'accountNumberCredit'),
            deditLst: map(paymentTmp, 'accountNumberDebit'),
            expenseLst: map(paymentTmp, 'expenseCode'),
            departmentLst: map(paymentTmp, 'departmentCode'),
            dataCode,
            date, amount: sumBy(paymentTmp, 'amount'), vat: sumBy(paymentTmp, 'amountVAT'), total: sumBy(paymentTmp, 'total'), payment: paymentTmp
        }
        props?.createJournalEntrie(data)
            .then(res => {
                dispatch({
                    type: "SET_ENUM",
                    payload: {
                        actionAdd: false,
                    }
                })
            })
    }

    const handleDelRow = (index) => {
        let pm = [...payment]
        pm.splice(index, 1)
        setPayment(pm)
        dispatch({
            type: 'SET_PAYMENT_DETAIL',
            payload: pm
        })
    }

    const handleEdit = (item, index) => {

        dispatch({
            type: 'SET_EDIT_PAYMENT_DETAIL',
            payload: { index, data: item }
        })
        dispatch({
            type: "SET_ENUM",
            payload: {
                totalEditPayment: {
                    index,
                    total: item?.total
                },
            }
        })
        setEdit(true)
        setAdd(false)
        editRowDetail.current?.handleClickOpen();
    }

    const handleOpen = () => {
        setAdd(true)
        setEdit(false)
        if (openAdd) {
            addRowDetail?.current?.handleAddPayment()
            addRowDetail?.current?.clearState()
        }
        addRowDetail.current?.handleClickOpen();
    }

    const handleCloseEdit = () => {
        setEdit(false)
    }

    const handleChangeTab = (val) => {
        setValueTab(val)
    }

    let tmpArr = [...payment]
    let totalHead = 0
    if (totalEditPayment?.total > 0) {
        tmpArr.splice(totalEditPayment?.index, 1)
        totalHead = sumBy(tmpArr, 'total') + Number(totalEditPayment?.total)
    }
    else {
        totalHead = sumBy(tmpArr, 'total') + Number(totalPaymentTmp)
    }


    const _handleSelectContact = (newValue) => {
        setState(state => ({
            ...state, partner: newValue,
            address: newValue?.address,
            name: `${newValue?.firstName} ${newValue?.lastName}`,
            phone: newValue?.phone?.phoneNumber
        }))
        inputRef?.current?.focus()
    }

    React.useImperativeHandle(ref, () => ({ handleSubmit }));
    return (
        <>
            <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, md: 12 }} sx={{ backgroundColor: '#FFF', p: 2, borderRadius: 2 }}>
                <Grid item xs={6}>
                    <Grid container spacing={3} sx={{ mb: 1.5 }}>
                        <Grid item xs={5}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { display: 'flex' },
                                    '& .MuiInput-root': { mt: 1.5 }
                                }}
                                noValidate
                                autoComplete="off">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        className='customeChooseDate'
                                        format={'DD/MM/YYYY'}
                                        value={dayjs(date)}
                                        label={t('frmBillDate')}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item xs={7}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { display: 'flex' },
                                }}
                                noValidate
                                autoComplete="off">

                                <TextField
                                    onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                    label={t('frmBillNumber')}
                                    id="last-code"
                                    value={code}
                                    sx={{
                                        '& .MuiTextField-root': { display: 'flex' },
                                        '& .MuiInput-root': { mt: 1.5 }
                                    }}
                                    variant="standard"
                                    size="small"
                                    name="code"
                                    onChange={onChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={{ xs: 0, md: 1 }}>
                        <Grid item xs={3} display={'flex'}>
                            <ContactLst selectContact={_handleSelectContact} contact={partner} />
                        </Grid>

                        <Grid item xs={9}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { display: 'flex' },
                                    '& .MuiInput-root': { mt: 1.5 }
                                }}
                                noValidate
                                autoComplete="off" >
                                <TextField
                                    onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                    label={t('frmName')}
                                    inputRef={inputRef}
                                    id="name"
                                    InputLabelProps={{ shrink: name ? true : false }}
                                    value={name}
                                    name="name"
                                    onChange={onChange}
                                    size="small"
                                    variant='standard'
                                />
                            </Box>
                        </Grid>


                        <Grid item md={12} xs={12} >
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { display: 'flex' },
                                    '& .MuiInput-root': { mt: 1.5 }
                                }}
                                noValidate
                                autoComplete="off" >
                                <TextField
                                    onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                    label={t('frmAddress')}
                                    id="address"
                                    InputLabelProps={{ shrink: address ? true : false }}
                                    value={address}
                                    name="address"
                                    onChange={onChange}
                                    size="small"
                                    variant='standard'
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={5}>
                            <Autocomplete
                                freeSolo
                                id="free-solo-rule"
                                disableClearable
                                value={rule}
                                onChange={(event, newValue) => {
                                    setRule(newValue);
                                    setAccountNumber(newValue?.accountNumberMain)
                                    setState(state => ({ ...state, description: newValue?.description }))
                                }}
                                size="small"
                                options={listRule}
                                getOptionLabel={(option) => !isEmpty(option) ? `${option?.code} - ${option?.name}` : ''}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            '& .MuiTextField-root': { display: 'flex' },
                                            '& .MuiInput-root': { mt: 1.5 }
                                        }}
                                        label={t('frmRule')}
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>


                        <Grid item md={7} xs={7}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { display: 'flex' },
                                    '& .MuiInput-root': { mt: 1.5, paddingBottom: '3px' }

                                }}
                                noValidate
                                autoComplete="off">

                                <TextField
                                    label={t('frmDescription')}
                                    id="last-description"
                                    value={description}
                                    variant="standard"
                                    minRows={1}
                                    multiline={true}
                                    maxRows={2}
                                    name="description"
                                    onChange={onChange}
                                />
                            </Box>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={3}>
                    <Grid container spacing={{ xs: 0, md: 2 }} sx={{ paddingLeft: 2, paddingRight: 2 }}>
                        {/* <Grid item xs={12}>
                            <Autocomplete
                                freeSolo
                                id="free-solo-department"
                                disableClearable
                                value={department}
                                onChange={(event, newValue) => {
                                    setDepartment(newValue);
                                }}
                                size="small"
                                options={listDepartment}
                                getOptionLabel={(option) => option ? `${option?.code} - ${option?.name}` : ''}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Department"
                                        sx={{
                                            '& .MuiTextField-root': { display: 'flex' },
                                            '& .MuiInput-root': { mt: 1.5 }
                                        }}
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid> */}
                    </Grid>
                </Grid>

                <Grid item xs={3}>
                    <Grid container spacing={{ xs: 0, md: 2 }}>

                        <Grid item xs={5}>
                            <Autocomplete
                                freeSolo
                                id="free-solo-currency"
                                disableClearable
                                value={currency}
                                onChange={(event, newValue) => {
                                    setCurrency(newValue);
                                }}
                                options={listCurrency}
                                getOptionLabel={(option) => option ? `${option?.code} - ${option?.name}` : ''}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                size="small"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            '& .MuiInput-root': { mt: 1.5 }
                                        }}
                                        label={t('frmCurrency')}
                                        variant='standard'
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={7}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { display: 'flex' },
                                    '& .MuiInput-root': { mt: 1.5 }
                                }}
                                noValidate
                                autoComplete="off" >
                                <TextField
                                    onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                    label={t('frmExchange')}
                                    id="Exchange"
                                    sx={{ input: { textAlign: "right" } }}
                                    value={exchange}
                                    name="exchange"
                                    onChange={onChange}
                                    type="number"
                                    size="small"
                                    variant='standard'
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                component="form"
                                sx={{
                                    display: 'flex', flexDirection: 'column'
                                }}
                                noValidate
                                autoComplete="off">
                                <div className="lineSum">
                                    <div>{t('frmSubTotal')}</div>
                                    <div>{ccyFormat(sumBy(payment, 'amount'))}</div>
                                </div>
                                <div className="lineSum">
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className="lineSum">
                                    <div>{t('frmSubTax')}</div>
                                    <div>{ccyFormat(sumBy(payment, 'amountVAT'))}</div>
                                </div>
                                <div className="lineSum total">
                                    <div>{t('frmTotal')}</div>
                                    <div>{ccyFormat(totalHead || 0)}</div>
                                </div>

                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                freeSolo
                                id="free-solo-accountNumber"
                                disableClearable
                                value={accountNumber}
                                onChange={(event, newValue) => {
                                    setAccountNumber(newValue);
                                }}
                                size="small"
                                options={listAccountNumber}
                                getOptionLabel={(option) => option ? `${option?.code} - ${option?.name}` : ''}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('frmDebit')}
                                        sx={{
                                            '& .MuiTextField-root': { display: 'flex' },
                                            '& .MuiInput-root': { mt: 1.5 }
                                        }}
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, md: 12 }} sx={{ backgroundColor: '#FFF', p: 2, mt: 2, borderRadius: 2 }}>
                <Grid item xs={12}>
                    <Tabs value={valueTab}
                        sx={{
                            minHeight: 24,
                            mb: 2,
                            '& .MuiTab-root': {
                                padding: 0,
                                textTransform: 'capitalize',
                                minHeight: 24,
                            }
                        }}
                        onChange={(event, newValue) => { setValueTab(newValue) }} aria-label="disabled tabs example">
                        <Tab label={t('tabInfomation')} {...a11yProps(0)} />
                        <Tab label={t('tabTax')} {...a11yProps(1)} />
                        <Tab label={t('tabMore')} {...a11yProps(2)} />
                        <Tab label={t('tabAttach')} {...a11yProps(3)} />
                    </Tabs>

                    <TabPanel value={valueTab} index={0}>
                        <div className="containerTableDetail">
                            <div className={`rowTableDetail`}>
                                <div className='colNameDetail'>{t('lblColNo')}</div>
                                <div className='colNameDetail'>{t('frmDebit')}</div>
                                <div className='colNameDetail'>{t('frmCredit')}</div>
                                <div className='colNameDetail'>{t('lblColAmount')}</div>
                                <div className='colNameDetail'>{t('frmContact')}</div>
                                <div className='colNameDetail'>{t('frmExpense')}</div>
                                <div className='colNameDetail'>{t('frmDepartment')}</div>
                                <div className='colNameDetail'>{t('lblColDescription')}</div>
                            </div>
                            {payment?.map((item, ind) => {
                                return <div className={`rowTableDetail ${editObjectPayment?.index === ind ? 'activeEdit' : ''}`} key={ind}>
                                    <div className='colContentDetail'>{ind + 1}.</div>
                                    <div className='colContentDetail'>{item?.accountNumberDebit}</div>
                                    <div className='colContentDetail'>{item?.accountNumberCredit}</div>
                                    <div className='colContentDetail'>{ccyFormat(item?.amount)}</div>
                                    <div className='colContentDetail'>{item?.customer?.firstName} {item?.customer?.lastName}</div>
                                    <div className='colContentDetail'>{item?.department?.name}</div>
                                    <div className='colContentDetail'>{item?.expense?.name}</div>
                                    <div className='colContentDetail'>{item?.description}</div>
                                    <div className='toolRowDetail'>
                                        <div className='editRowDetail'>
                                            <CreateIcon size='small' onClick={() => handleEdit(item, ind)} />
                                        </div>
                                        <div className='delRowDetail'>
                                            <RemoveCircleOutlineOutlinedIcon size='small' onClick={() => handleDelRow(ind)} />
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>

                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <div className="containerTableDetail">
                            <div className="rowTableDetail">
                                <div className='colNameDetail'>VAT</div>
                                <div className='colNameDetail'>{t('frmVATAmount')}</div>
                                <div className='colNameDetail'>{t('frmSubTotal')}</div>
                            </div>
                            {payment?.map((item, ind) => {
                                return <div className={`rowTableDetail ${editObjectPayment?.index === ind ? 'activeEdit' : ''}`} key={ind}>
                                    <div className='colContentDetail'>{item?.vatRatio}</div>
                                    <div className='colContentDetail'>{ccyFormat(item?.amountVAT)}</div>
                                    <div className='colContentDetail'>{ccyFormat(item?.total)}</div>
                                    <div className='toolRowDetail'>
                                        <div className='editRowDetail'>
                                            <CreateIcon size='small' onClick={() => handleEdit(item, ind)} />
                                        </div>
                                        <div className='delRowDetail'>
                                            <RemoveCircleOutlineOutlinedIcon size='small' onClick={() => handleDelRow(ind)} />
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>

                    </TabPanel>
                    <TabPanel value={valueTab} index={2}>
                        <div className="containerTableDetail">
                            <div className="rowTableDetail">
                                <div className='colNameDetail'>{t('frmProject')}</div>
                                <div className='colNameDetail'>{t('frmProduct')}</div>
                                {/* <div className='colNameDetail'>{t('frmExpense')}</div>
                                <div className='colNameDetail'>{t('frmDepartment')}</div> */}
                                <div className='colNameDetail'>{t('frmReference')}</div>
                            </div>
                            {payment?.map((item, ind) => {
                                return <div className={`rowTableDetail ${editObjectPayment?.index === ind ? 'activeEdit' : ''}`} key={ind}>
                                    <div className='colContentDetail'>{item?.project?.name}</div>
                                    <div className='colContentDetail'>{item?.product?.name}</div>
                                    {/* <div className='colContentDetail'>{item?.department?.name}</div>
                                    <div className='colContentDetail'>{item?.expense?.name}</div> */}
                                    <div className='colContentDetail'>{item?.reference}</div>
                                    <div className='toolRowDetail'>
                                        <div className='editRowDetail'>
                                            <CreateIcon size='small' onClick={() => handleEdit(item, ind)} />
                                        </div>
                                        <div className='delRowDetail'>
                                            <RemoveCircleOutlineOutlinedIcon size='small' onClick={() => handleDelRow(ind)} />
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </TabPanel>
                    <TabPanel value={valueTab} index={3}>
                        {t('frmAttach')}
                    </TabPanel>

                    <AddRowDetail
                        listAccountNumber={listAccountNumber}
                        listDepartment={listDepartment}
                        listCurrency={listCurrency}
                        listPartner={listPartner}
                        listProject={listProject}
                        listExpense={listExpense}
                        listVAT={listVAT}
                        exchange={exchange}
                        accountNumber={accountNumber}
                        currencyDefault={currencyDefault}
                        listProduct={listProduct}
                        rule={rule}
                        description={description}
                        currency={currency}
                        date={date}
                        partner={partner}
                        department={department}
                        openEdit={openEdit}
                        openAdd={openAdd}
                        typeTab={valueTab}
                        ref={addRowDetail}
                        onSetTab={handleChangeTab}
                    />

                    {openEdit && <EditRowDetail
                        listAccountNumber={listAccountNumber}
                        listDepartment={listDepartment}
                        listCurrency={listCurrency}
                        listProject={listProject}
                        currencyDefault={currencyDefault}
                        exchange={exchange}
                        listPartner={listPartner}
                        accountNumber={accountNumber}
                        listExpense={listExpense}
                        listVAT={listVAT}
                        listProduct={listProduct}
                        ref={editRowDetail}
                        handleCloseEdit={handleCloseEdit}
                        onSetTab={handleChangeTab}
                        typeTab={valueTab}
                    />}
                </Grid>

                {openAdd && <i style={{ display: 'flex', width: '100%', color: '#626364', fontSize: 11 }}>{t('alrAdding')}</i>}
                {openEdit && <i style={{ display: 'flex', width: '100%', color: '#626364', fontSize: 11 }}>{t('alrUpdating')}</i>}

                {valueTab !== 3 && <div className="rowTableDetailAdd" style={{ padding: '6px', justifyContent: 'space-between' }}>
                    <Typography fontSize={14} style={{ color: teal[600], cursor: 'pointer' }} onClick={handleOpen}>+ Add</Typography>
                </div>}
            </Grid>


        </>
    );
})



const mapDispatchToProps = {
    createJournalEntrie, generateCode, getPartner, getCurrency,
    getAccountNumber, getDepartment, getExpense, getProject,
    getRule, getVAT, getProduct, getPaymentByParent
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(AddForm);