import  {setTokenAxios } from "../api/index"
import {_showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";



export const getDemandById = (id) => (dispatch)=>{
    return setTokenAxios().get(`demand/${id}`)
      .then(async res => {
        return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
      })
      .catch((err) =>{
        _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
      })
  }

export const getAllData = (condition) => (dispatch)=> {
  return setTokenAxios().get(`demand`,condition)
    .then(async res => {
      dispatch({
        type: "SET_DEMAND",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}


export const createDemand = (data) => (dispatch)=> {
  return setTokenAxios().post(`demand`,data)
    .then(async res => {
      dispatch({
        type: "CREATE_DEMAND",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}

export const updateDemand = (data, _id) => (dispatch)=> {
  return setTokenAxios().put(`demand/${_id}`,data)
    .then(async res => {
      dispatch({
        type: "UPDATE_DEMAND",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}