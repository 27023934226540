import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const getExchangeRateById = (id) => (dispatch) => {
  return setTokenAxios().get(`exchangeRate/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`exchangeRate`, condition)
    .then(async res => {
      dispatch({
        type: "SET_EXCHANGE_RATE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`exchangeRate`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const createExchangeRate = (data) => (dispatch) => {
  return setTokenAxios().post(`exchangeRate`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_EXCHANGE_RATE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateExchangeRate = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`exchangeRate/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_EXCHANGE_RATE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteExchangeRate = ( _id) => (dispatch) => {
  return setTokenAxios().delete(`exchangeRate/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_EXCHANGE_RATE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}