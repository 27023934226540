import { findIndex } from "lodash"

const initialState = []

const minimizeDetailReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_DETAIL_MINI_CHAT":
            return action.payload

        case "CREATE_DETAIL_MINI_CHAT":
            return [...state, action.payload]

        case "UPDATE_DETAIL_MINI_CHAT":
            let old = [...state]

            const index = old.findIndex(per => per.code === action.payload.code)
            if (index > -1) {
                old[index].messages = action.payload.messages;
            }

            return [...old]

        case "CHANGE_PASSWORD":

            const password = state.findIndex(per => per._id === action.payload._id)
            state[password] = action.payload;
            return [...state]

        case "UPDATE_DETAIL_MINI_CHAT_ACTIVE":
            const rule_ = [...state]
            if (action.payload.length > 0) {
                (action.payload).forEach((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    rule_[index] = item;
                })
            }
            return rule_

        case "SET_CURRENT_DETAIL_MINI_CHAT_IMG":
            return [...state]

        case "DELETE_DETAIL_MINI_CHAT":
            const rule_old = [...state]
            let indexs = findIndex(rule_old, { code: action.payload })
            rule_old.splice(indexs, 1)
            return [...rule_old]


        default:
            break;
    }

    return state
}

export default minimizeDetailReducer