import * as React from 'react';
import { useEffect, useState } from 'react';

import { Autocomplete, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { find, findIndex, map } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { getRuleById, updateRule } from '../../../actions/rule';
import { getAllData as getLanguage } from '../../../actions/language';
import { getAllDataNoneRedux as getAccountNumber } from '../../../actions/accountant';
import { getAllDataNoneRedux as getDepartment } from '../../../actions/department';
import { getAllDataNoneRedux as getExpense } from '../../../actions/expense';
import { getAllDataNoneRedux as getProject } from '../../../actions/project';
import { getAllData as getDataPrimary } from '../../../actions/data';

import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';
import { MenuProps } from '../../../untils/variable';


const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)

    const [
        { name, isActive, code, description, defaultLanguage, language, _id, dataCode, isPayment },
        setState
    ] = useState({ ...editObject });

    const listLanguage = useSelector(state => state?.language)
    const [listAccountNumber, setListAccountNumber] = useState([])
    const [listDepartment, setListDepartment] = useState([])
    const [listExpense, setListExpense] = useState([])
    const [listProject, setListProject] = useState([])
    const [accountNumberTo, setAccountNumberTo] = useState('')
    const [accountNumberFrom, setAccountNumberFrom] = useState('')
    const [accountNumberMain, setAccountNumberMain] = useState('')
    const [departmentCode, setDepartmentCode] = useState('')
    const [expenseCode, setExpenseCode] = useState('')
    const [projectCode, setProjectCode] = useState('')
    const [applyFor, setApplyFor] = useState('')
    const [paymentFor, setPaymentFor] = useState('')

    const getDataById = () => {
        props?.getRuleById(_id).then(res => {
            let data = res?.data
            let lang = [...data?.language]
            let index = findIndex(lang, { default: true })
            setAccountNumberFrom(data?.accountNumberFrom)
            setAccountNumberTo(data?.accountNumberTo)
            setAccountNumberMain(data?.accountNumberMain)
            setDepartmentCode(data?.departmentCode)
            setExpenseCode(data?.expenseCode)
            setProjectCode(data?.projectCode)
            setApplyFor(data?.applyFor)
            setPaymentFor(data?.paymentFor)

            setState(state => ({ ...state, ...data, defaultLanguage: index > -1 ? lang[index]?.langCode : '' }))
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        const init = async () => {
            props?.getLanguage()
            await props?.getAccountNumber({ condition: {}, limit: 1000 }).then(res => setListAccountNumber(res.data))
            await props?.getDepartment({ condition: {}, limit: 100 }).then(res => setListDepartment(res.data))
            await props?.getExpense({ condition: {}, limit: 100 }).then(res => setListExpense(res.data))
            await props?.getProject({ condition: {}, limit: 100 }).then(res => setListProject(res.data))
            await props?.getDataPrimary()
        }

        init()
        getDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDefaultLanguage = (event) => {
        let langTmp = [...language]
        let stateNew = {}
        let index = findIndex(langTmp, { langCode: event.target.value })
        if (index > -1) {
            var newArr = map(langTmp, function (a) {
                return { ...a, default: false };
            });

            let tmp = { ...newArr[index] }
            tmp.default = true
            langTmp[index] = tmp
            stateNew.language = langTmp
            stateNew.name = newArr[index]?.value
        }
        setState(state => ({ ...state, defaultLanguage: event.target.value, ...stateNew }))
    };


    const onChangeLangue = (e, lang) => {
        let langTmp = [...language]
        let text = e.target.value
        let stateNew = {}
        if (lang.code === defaultLanguage) {
            stateNew.name = text
        }
        // get index in current language property in Accountant Object
        let index = findIndex(langTmp, { langCode: lang.code })
        if (index > -1) {
            let tmp = { ...langTmp[index] }
            tmp.value = text
            langTmp[index] = tmp
            stateNew.language = langTmp
        }
        else {
            langTmp.push({
                langCode: lang.code,
                default: false,
                value: text
            })
            stateNew.language = langTmp
        }
        setState(state => ({ ...state, ...stateNew }));
    }


    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const handleSubmit = () => {
        let data = {
            name, isActive, code, description, language, isPayment, paymentFor,
            accountFrom: accountNumberFrom?.code, accountTo: accountNumberTo?.code,
            accountMain: accountNumberMain?.code,
            departmentCode, projectCode, expenseCode,
            applyFor,
            dataCode
        }
        props?.updateRule(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
            })
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let arr = typeof value === 'string' ? value.split(',') : value
        setState(state => ({ ...state, dataCode: arr }))
    };

    React.useImperativeHandle(ref, () => ({ handleSubmit }));

    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', padding: 2 }}>
            <Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 12, md: 12 }}>

                <Grid item xs={12} md={6} >
                    <FormGroup >
                        <FormControlLabel control={<Checkbox />}
                            checked={isActive}
                            name="isActive"
                            onChange={(e) => customeOnChange('isActive', !isActive)}
                            label="Active" />
                    </FormGroup>
                </Grid>

                <Grid item md={6} xs={12} >
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { display: 'flex' },
                        }}
                        noValidate
                        autoComplete="off" >
                        <TextField
                            label="Code "
                            id="code"
                            value={code}
                            name="code"
                            onChange={onChange}
                            size="small"
                        />
                    </Box>
                </Grid>

                <Grid item md={12} xs={12}>
                    <Typography sx={{ mb: 1, fontWeight: 600 }}>Name</Typography>
                    <RadioGroup
                        aria-labelledby="demo-defaultLanguage"
                        name="defaultLanguage"
                        value={defaultLanguage}
                        onChange={handleDefaultLanguage}>
                        {listLanguage?.map((item, index) => {
                            return <Box
                                key={index}
                                component="form"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    '& .MuiTextField-root': { flex: 1, mb: 2 },
                                }}
                                noValidate
                                autoComplete="off">
                                <TextField
                                    label={item?.name}
                                    id="last-name"
                                    value={find(language, { langCode: item.code })?.value}
                                    name="name"
                                    onChange={(e) => onChangeLangue(e, item)}
                                    size="small"
                                />
                                <FormControlLabel value={item.code} sx={{ ml: 1, mb: 2 }} control={<Radio checked={Boolean(defaultLanguage === item.code)} />} label="" />
                            </Box>
                        })}
                    </RadioGroup>
                </Grid>

                <Grid item xs={6}>
                    <Typography sx={{ mb: 1, fontWeight: 600 }}>other</Typography>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { display: 'flex' },
                        }}
                        noValidate
                        autoComplete="off" >
                        <Autocomplete
                            freeSolo
                            id="free-solo-accountNumberFrom"
                            disableClearable
                            value={accountNumberFrom}
                            onChange={(event, newValue) => {
                                setAccountNumberFrom(newValue);
                            }}
                            size="small"
                            options={listAccountNumber}
                            getOptionLabel={(option) => option ? `${option?.code} - ${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Credit Account"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Typography sx={{ mb: 1, fontWeight: 600 }}>.</Typography>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { display: 'flex' },
                        }}
                        noValidate
                        autoComplete="off" >
                        <Autocomplete
                            freeSolo
                            id="free-solo-accountNumberTo"
                            disableClearable
                            value={accountNumberTo}
                            onChange={(event, newValue) => {
                                setAccountNumberTo(newValue);
                            }}
                            size="small"
                            options={listAccountNumber}
                            getOptionLabel={(option) => option ? `${option?.code} - ${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Debit Account"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { display: 'flex' },
                        }}
                        noValidate
                        autoComplete="off" >
                        <Autocomplete
                            freeSolo
                            id="free-solo-accountNumberMain"
                            disableClearable
                            value={accountNumberMain}
                            onChange={(event, newValue) => {
                                setAccountNumberMain(newValue);
                            }}
                            size="small"
                            options={listAccountNumber}
                            getOptionLabel={(option) => option ? `${option?.code} - ${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Main Account"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-Department">Department</InputLabel>
                        <Select
                            labelId="demo-simple-select-Department"
                            id="demo-simple-Department"
                            value={departmentCode}
                            label="Department"
                            onChange={(e) => {
                                setDepartmentCode(e.target.value)
                            }}
                        >
                            {listDepartment?.map((res, ind) => {
                                return <MenuItem key={ind} value={res?.code}>{res?.code} - {res?.name}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-Expense">Expense</InputLabel>
                        <Select
                            labelId="demo-simple-select-Expense"
                            id="demo-simple-Expense"
                            value={expenseCode}
                            label="Expense"
                            onChange={(e) => {
                                setExpenseCode(e.target.value)
                            }}
                        >
                            {listExpense?.map((res, ind) => {
                                return <MenuItem key={ind} value={res?.code}>{res?.code} - {res?.name}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-Project">Project</InputLabel>
                        <Select
                            labelId="demo-simple-select-Project"
                            id="demo-simple-Project"
                            value={projectCode}
                            label="Project"
                            onChange={(e) => {
                                setProjectCode(e.target.value)
                            }}
                        >
                            {listProject?.map((res, ind) => {
                                return <MenuItem key={ind} value={res?.code}>{res?.code} - {res?.name}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-Apply">Apply for</InputLabel>
                        <Select
                            labelId="demo-simple-select-Apply"
                            id="demo-simple-Apply"
                            value={applyFor}
                            label="Apply for"
                            onChange={(e) => {
                                setApplyFor(e.target.value)
                            }}>
                            <MenuItem value={'IC'}>Income</MenuItem>
                            <MenuItem value={'OC'}>Outcome</MenuItem>
                            <MenuItem value={'CRB'}>Credit note</MenuItem>
                            <MenuItem value={'DRB'}>Debit note</MenuItem>
                            <MenuItem value={'JE'}>Journal Entrie</MenuItem>
                            <MenuItem value={'AP'}>Advance Payment</MenuItem>
                            <MenuItem value={'SEL'}>Financial Settlement</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} style={{ paddingLeft: 24 }}>
                    <Typography variant='body2' >Apply for data:</Typography>
                    <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            size='small'
                            value={dataCode}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}>
                            {props?.data?.map((item) => (
                                <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                    <Checkbox checked={dataCode?.indexOf(item.code) > -1 ? 'checked' : ''} />
                                    <ListItemText primary={`${item.code} - ${item.name}`} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6} md={6} >
                    <FormGroup >
                        <FormControlLabel control={<Checkbox />}
                            checked={isPayment}
                            name="isPayment"
                            onChange={(e) => customeOnChange('isPayment', !isPayment)}
                            label="Payment" />
                    </FormGroup>
                </Grid>


                <Grid item xs={6}>
                    {isPayment && <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-paymentFor">Source</InputLabel>
                        <Select
                            labelId="demo-simple-select-paymentFor"
                            id="demo-simple-paymentFor"
                            value={paymentFor}
                            label="Source"
                            onChange={(e) => {
                                setPaymentFor(e.target.value)
                            }}>
                            <MenuItem value={'IC'}>Income</MenuItem>
                            <MenuItem value={'OC'}>Outcome</MenuItem>
                            <MenuItem value={'CRB'}>Credit note</MenuItem>
                            <MenuItem value={'DRB'}>Debit note</MenuItem>
                            <MenuItem value={'JE'}>Journal Entrie</MenuItem>
                            <MenuItem value={'AP'}>Advance Payment</MenuItem>
                            <MenuItem value={'SEL'}>Financial Settlement</MenuItem>
                        </Select>
                    </FormControl>}
                </Grid>


                <Grid item lg={12} xs={12}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { display: 'flex' },
                        }}
                        noValidate
                        autoComplete="off">

                        <TextField
                            label="Note"
                            id="last-description"
                            value={description}
                            multiline={true}
                            minRows={4}
                            name="description"
                            onChange={onChange}
                        />
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
})


const mapDispatchToProps = {
    getRuleById, updateRule, getLanguage, getAccountNumber,
    getDepartment, getExpense, getProject, getDataPrimary
}

const mapStateToProps = (state) => {
    return {
        editObj: state.edit,
        data: state.data
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditForm);