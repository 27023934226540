import { find } from "lodash";
import { dtDonvi, dtThousand } from "./variable";

const getTypeFile = (item) => {
  if (String(item.mimetype).includes('image')) {
    item.type = 'image';
  } else if (String(item.mimeType).includes('video')) {
    item.type = 'video';
  } else if (String(item.mimeType).includes('audio')) {
    item.type = 'audio';
  } else if (String(item.mimeType).includes('application')) {
    item.type = 'application';
  } else if ([
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp',
  ].includes(item.mimeType)) {
    item.type = 'image';
  }
  return item.type;
};

function randomStringX(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function deepSearch(object, key, predicate) {
  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object

  for (let i = 0; i < Object.keys(object).length; i++) {
    let value = object[Object.keys(object)[i]];
    if (typeof value === "object" && value !== null) {
      let o = deepSearch(object[Object.keys(object)[i]], key, predicate)
      if (o !== null) return o
    }
  }
  return null
}

function getAllChildrenAccount(data, code) {
  let result = []
  data?.forEach(item => {
    if (item?.children.length > 0) {
      let data = getAllChildrenAccount(item.children, item.code)
      result = [...result, ...data]
    }
    else {
      if (item.parentCode === code && item.isFinal === true) {
        result.push(item)
      }
    }
  })
  return result
}

var ChuSo = [" không ", " một ", " hai ", " ba ", " bốn ", " năm ", " sáu ", " bảy ", " tám ", " chín "];
var Tien = ["", " nghìn", " triệu", " tỷ", " nghìn tỷ", " triệu tỷ"];

function DocSo3ChuSo(baso) {
  var tram;
  var chuc;
  var donvi;
  var KetQua = "";
  tram = parseInt(baso / 100);
  chuc = parseInt((baso % 100) / 10);
  donvi = baso % 10;
  if (tram === 0 && chuc === 0 && donvi === 0) return "";
  if (tram !== 0) {
    KetQua += ChuSo[tram] + " trăm ";
    if ((chuc === 0) && (donvi !== 0)) KetQua += " linh ";
  }
  if ((chuc !== 0) && (chuc !== 1)) {
    KetQua += ChuSo[chuc] + " mươi";
    if ((chuc === 0) && (donvi !== 0)) KetQua = KetQua + " linh ";
  }
  if (chuc === 1) KetQua += " mười ";
  switch (donvi) {
    case 1:
      if ((chuc !== 0) && (chuc !== 1)) {
        KetQua += " mốt ";
      }
      else {
        KetQua += ChuSo[donvi];
      }
      break;
    case 5:
      if (chuc === 0) {
        KetQua += ChuSo[donvi];
      }
      else {
        KetQua += " lăm ";
      }
      break;
    default:
      if (donvi !== 0) {
        KetQua += ChuSo[donvi];
      }
      break;
  }
  return KetQua;
}


function NumberToText(SoTien, suffix = true) {

  var lan = 0;
  var i = 0;
  var so = 0;
  var KetQua = "";
  var tmp = "";
  var ViTri = [];
  if (SoTien < 0) return "Số tiền âm !";
  if (SoTien === 0) return "Không đồng !";
  if (SoTien > 0) {
    so = SoTien;
  }
  else {
    so = -SoTien;
  }
  if (SoTien > 8999999999999999) {
    //SoTien = 0;
    return "Số quá lớn!";
  }
  ViTri[5] = Math.floor(so / 1000000000000000);
  if (isNaN(ViTri[5]))
    ViTri[5] = "0";
  so = so - parseFloat(ViTri[5].toString()) * 1000000000000000;
  ViTri[4] = Math.floor(so / 1000000000000);
  if (isNaN(ViTri[4]))
    ViTri[4] = "0";
  so = so - parseFloat(ViTri[4].toString()) * 1000000000000;
  ViTri[3] = Math.floor(so / 1000000000);
  if (isNaN(ViTri[3]))
    ViTri[3] = "0";
  so = so - parseFloat(ViTri[3].toString()) * 1000000000;
  ViTri[2] = parseInt(so / 1000000);
  if (isNaN(ViTri[2]))
    ViTri[2] = "0";
  ViTri[1] = parseInt((so % 1000000) / 1000);
  if (isNaN(ViTri[1]))
    ViTri[1] = "0";
  ViTri[0] = parseInt(so % 1000);
  if (isNaN(ViTri[0]))
    ViTri[0] = "0";
  if (ViTri[5] > 0) {
    lan = 5;
  }
  else if (ViTri[4] > 0) {
    lan = 4;
  }
  else if (ViTri[3] > 0) {
    lan = 3;
  }
  else if (ViTri[2] > 0) {
    lan = 2;
  }
  else if (ViTri[1] > 0) {
    lan = 1;
  }
  else {
    lan = 0;
  }
  for (i = lan; i >= 0; i--) {
    tmp = DocSo3ChuSo(ViTri[i]);
    KetQua += tmp;
    if (ViTri[i] > 0) KetQua += Tien[i];
    if ((i > 0) && (tmp.length > 0)) KetQua += ',';//&& (!string.IsNullOrEmpty(tmp))
  }
  if (KetQua.substring(KetQua.length - 1) === ',') {
    KetQua = KetQua.substring(0, KetQua.length - 1);
  }
  KetQua = KetQua.substring(1, 2).toUpperCase() + KetQua.substring(2);
  return KetQua;//.substring(0, 1);//.toUpperCase();// + KetQua.substring(1);
}

function readDataDonVi(num, mot) {
  if (num === 1 && mot === true)
    return find(dtThousand, { key: 'x' })?.lng2
  else {
    return find(dtDonvi, { key: Number(num) })?.lng2
  }

}

function readDataThousand(num) {
  return find(dtThousand, { key: num })?.lng2
}
function readDataThousandLength(num) {
  return find(dtThousand, { length: num })?.lng2
}

function readHangChuc(val, tram = false) {
  let number = Number(val)
  let mod = number % 10
  let nguyen = Math.floor(number / 10)
  let result = ''
  if (nguyen > 0 || mod > 0) {
    if (nguyen === 0 && mod > 0)
      result = readDataDonVi(mod)
    else {
      switch (nguyen) {
        case 2:
          result = readDataDonVi(20)
          break;

        case 1:
          result = readDataDonVi(10)
          break;

        default:
          // if (tram === false)
          result = readDataDonVi(nguyen)
          break;
      }

      if (mod === 0 && nguyen !== 1 && nguyen !== 2) {
        let donvi = readDataDonVi(10)
        result += donvi
      }

      if (mod > 0) {
        if (nguyen !== 1 && nguyen !== 2) {
          let thous = readDataThousand('x0')
          result += thous
        }

        let donvi = readDataDonVi(mod, true)
        result += donvi
      }
    }

  }
  return result
}

function readHangTram(number) {
  let nguyen = Math.floor(number / 100)
  let result = ''
  let tram = readDataDonVi(nguyen)
  let thous = readDataThousand('x00')
  tram += thous
  result += tram
  return result
}

function readHangNghin(number) {
  let nguyen = Math.floor(number / 1000)
  let result = ''
  let nghin = readDataDonVi(nguyen)
  let thous = readDataThousand('x000')
  nghin += thous
  result += nghin
  return result
}

function DocTienLao(val) {
  let type = val.toString().length
  let result = ''
  let tmpVal = val.toString()

  result = caseNguyen(type, tmpVal)

  return result

}

function caseNguyen(type, tmpVal) {

  let result = ''
  switch (type) {
    case 1:
      result = readDataDonVi(tmpVal)
      break;

    case 2:
      result = readHangChuc(tmpVal)
      break;

    case 3:
      let hangChuc = tmpVal.substring(tmpVal.length - 2, tmpVal.length);
      let resultChuc = readHangChuc(hangChuc, true)
      result = readHangTram(Number(tmpVal) - Number(hangChuc))
      result += resultChuc
      break;

    case 4:
      let hangChuc1 = tmpVal.substring(tmpVal.length - 2, tmpVal.length);
      let resultChuc1 = readHangChuc(hangChuc1, true)
      let hangTram = tmpVal.substring(tmpVal.length - 3, tmpVal.length);
      let resultTram = ''
      if (Number(hangTram) - Number(hangChuc1) > 0) {
        resultTram += readHangTram(Number(hangTram) - Number(hangChuc1))
      }
      resultTram += resultChuc1
      result = readHangNghin(Number(tmpVal) - Number(hangTram))
      result += resultTram
      break;

    default:
      result = divide3(tmpVal)
      break;
  }

  return result
}

// function genLengthNguyen(num) {
//   let va = '1';
//   let lengthh = num.toString().length - 1
//   while (lengthh > 0) {
//     va += '0'
//     lengthh = lengthh - 1
//   }
//   return va
// }

function divide3(val) {
  let result = ''

  if (val.toString().length <= 6) {

    result = readTramNgan(val)
  }
  else if (val.toString().length <= 9 && val.toString().length > 6) {
    result = readTrieu(val)
  }
  else if (val.toString().length <= 12 && val.toString().length > 9) {
    result = readTy(val)
  }
  else if (val.toString().length > 12 && val.toString().length <= 15) {
    let tmp = val.toString().substring(0, val.toString().length - 9)
    result = DocTienLao(tmp)
    let subtringMode = val.toString().substring(val.toString().length - 9, val.toString())
    let modLength = subtringMode.length
    result += readDataThousandLength(modLength)
    result += readTrieu(subtringMode)
  }

  return result
}

function readTramNgan(val) {
  let nguyen = Math.floor(Number(val) / 1000)
  let mod = Number(val) % 1000
  let result = ''
  let subtringMode = val.toString().substring(val.toString().length - 3, val.toString())
  let modLength = subtringMode.length

  result += DocTienLao(nguyen)
  result += readDataThousandLength(modLength)

  if (mod !== 0) {
    result += DocTienLao(mod)
  }

  return result
}

function readTrieu(val) {
  let result = ''
  let nguyen = Math.floor(Number(val) / 1000000)
  let mod = Number(val) % 1000000
  let subtringMode = val.toString().substring(val.toString().length - 6, val.toString())
  let modLength = subtringMode.length
  result += DocTienLao(nguyen)
  result += readDataThousandLength(modLength)
  if (mod !== 0) {
    result += DocTienLao(mod)
  }

  return result
}

function readTy(val) {
  let result = ''
  let nguyen = Math.floor(Number(val) / 1000000000)
  let mod = Number(val) % 1000000000
  let subtringMode = val.toString().substring(val.toString().length - 9, val.toString())
  let modLength = subtringMode.length
  result += DocTienLao(nguyen)
  result += readDataThousandLength(modLength)
  if (mod !== 0) {
    result += DocTienLao(mod)
  }
  return result
}

// eslint-disable-next-line
export { deepSearch, getAllChildrenAccount, NumberToText, randomStringX, DocTienLao, getTypeFile}