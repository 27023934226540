import { Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { getAllDataNoneRedux as getLog } from '../../../actions/log';
import { ccyFormat } from '../../../untils/number';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';


const DetailForm = (props) => {

    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation()
    const [listBottomSheet, setListBottomSheet] = React.useState([]);

    React.useEffect(() => {
        handleOpen()
    }, [props?.isOpen])


    React.useEffect(() => {
        setListBottomSheet(props?.listBottomSheet)
    }, [props?.listBottomSheet])


    const handleClose = () => {
        setOpen(false);
        props?.handleClose(false)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleOpenDetail = () => {
        props?.handleOpenDetail()
    }

    const renderBottomSheet = () => {
        return listBottomSheet?.length > 0 ? listBottomSheet.map((row, index) => (
            <TableRow key={row._id}>
                <TableCell className="firstCell" align="left" sx={{ paddingLeft: '0px!important' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {index + 1}.
                    </div>
                </TableCell>
                <TableCell align="right"  style={{color: row?.isActive === false ? "red":"green"}}>{moment(row?.date).format('DD/MM/YYYY')}
                    <Stack direction="row" spacing={1}>
                        {row?.code}
                    </Stack>
                </TableCell>
                <TableCell align="left">{row.accountNumberCredit}</TableCell>
                <TableCell align="left">{row.accountNumberDebit}</TableCell>
                <TableCell align="right">{ccyFormat(row.amount)}</TableCell>
                <TableCell align="right">
                    {row?.vat?.name}
                </TableCell>
                <TableCell align="right">{ccyFormat(row.total)}</TableCell>
                <TableCell component="th" scope="row" sx={{
                    borderWidth: 0.5
                }}>
                    {row?.customer?.firstName} {row?.customer?.lastName}
                </TableCell>
                <TableCell align="left">
                    {row?.expense?.name ? <><Chip sx={{ height: 20, mr: 1 }} label={row?.department?.name} color="primary" size="small" />
                        <Typography variant='body' sx={{ lineHeight: 1.2 }}>
                            {row?.expense?.name}
                        </Typography></> : ''}
                </TableCell>
            </TableRow>
        )) :
            <TableRow>
                <TableCell colSpan={9}>
                    0 records
                </TableCell>
            </TableRow>
    }

    return (
        <BottomSheet
            open={open}
            className='customeBottom'
            onDismiss={handleClose}
            blocking={false}
            header={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                    <Typography variant='body2' fontWeight={600}>{props?.dataParent?.code}</Typography>
                    <div>
                        <Button onClick={handleOpenDetail} sx={{ minWidth: 32 }}>
                            <MoreVertOutlinedIcon size="small" />
                        </Button>
                        <Button onClick={handleClose} color="error" sx={{ minWidth: 32 }}>
                            <CloseOutlinedIcon size="small" />
                        </Button>
                    </div>
                </div>
            }
            snapPoints={({ maxHeight }) => [maxHeight / (listBottomSheet.length < 2 ? 4 : 3), maxHeight * 0.6]}
        >
            <Paper sx={{ padding: 2, border: "0.5px solid #eaeaea", borderTop: 'none', width: '100%' }} elevation={0} >
                <div className='headBottomSheet'>

                </div>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table sx={{ minWidth: 500 }} aria-label="sticky table">
                        <TableHead>
                            <TableRow sx={{
                                fontWeight: 'bold'
                            }}>
                                <TableCell sx={{ width: 60 }}>{t('lblColNo')}</TableCell>
                                <TableCell sx={{ width: 60 }}>{t('lblColDate')}</TableCell>
                                <TableCell sx={{ width: 120 }}>{t('lblColCredit')}</TableCell>
                                <TableCell sx={{ width: 120 }}>{t('lblColDebit')}</TableCell>
                                <TableCell sx={{ width: 140 }}>{t('lblColOriginal')}</TableCell>
                                <TableCell align="right">VAT</TableCell>
                                <TableCell align="right" sx={{ width: 120 }}>{t('lblColAmount')}</TableCell>
                                <TableCell >{t('lblColPartner')}</TableCell>
                                <TableCell >{t('lblColRefer')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='customTable'>
                            {renderBottomSheet()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

        </BottomSheet>
    )

};

const mapDispatchToProps = {
    getLog
}

const mapStateToProps = (state) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DetailForm);