import { Box, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { green, indigo, pink } from '@mui/material/colors';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { deleteBeginningBalance, getAllData } from '../../../actions/beginningBalance';
import { setEditObject } from '../../../actions/untils';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import { dollarFormat } from '../../../untils/number';
import MainForm from '../../elements/MainForm';
import BreadCrumb from '../../elements/breadCrumb';
import DrawerExpand from '../../elements/drawerExpand';
import RowMenu from '../../elements/rowMenu';
import AddForm from './AddForm';
import EditForm from './EditForm';

const Main = (params) => {
  const { props } = params
  const theme = useTheme()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [type, setType] = useState('add')
  const { t } = useTranslation()

  const mainFormRef = React.useRef();
  const childFormRef = React.useRef();

  let listData = [...props?.data]

  const actionAdd = useSelector(state => state?.enumRedux?.actionAdd)
  const editObject = useSelector(state => state?.edit)
  useEffect(() => {
    if (actionAdd === true) {
      if (type !== 'edit' && type !== 'delete') {
        setType('add')
      }
      mainFormRef.current?.openFromParent();
    }
    //eslint-disable-next-line
  }, [actionAdd])

  useEffect(() => {
    if (isEmpty(editObject)) {
      setType('add')
    }
  }, [editObject])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionMenu = async (value, type) => {
    mainFormRef.current?.openFromParent();
    setType(type)
    if (type === 'delete') {
      await props?.setEditObject({})
      deleteData(value)
    }
    else {
      if (type !== 'add') {
        dispatch({
          type: "SET_ENUM",
          payload: {
            actionAdd: true,
          }
        })
        await props?.setEditObject(value)
      }
    }
  }

  const deleteData = (value) => {
    props?.deleteBeginningBalance(value?._id)
      .then(async res => {
        setType('')
        await props?.setEditObject({})
        _showAlertSuccess(res?.message || GET_SUCCESS)
      })
  }

  useEffect(() => {
    props?.getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleSave = async () => {
    childFormRef.current?.handleSubmit();
  }

  const renderRow = () => {

    let list = rowsPerPage > 0
      ? listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : listData


    return list.map((row, index) => (
      <TableRow key={row._id} >
        <TableCell align="left">
          <RowMenu
            data={row}
            actionEdit={handleActionMenu}
            sx={{ mr: 2 }} />
          {page * rowsPerPage + index + 1}.
        </TableCell>
        <TableCell align="left">
          <div style={{ color: row?.isActive === true ? green[700] : pink[400] }}>
            {row?.year}
          </div>
        </TableCell>
        <TableCell component="th" scope="row" sx={{
          borderWidth: 0.5
        }}>
          <Typography variant='subtitle2' color={indigo[500]} sx={{ lineHeight: 1 }}>
            {row?.accountNumber}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <span>{dollarFormat(row.beginCreditAmount)}</span>
            <span>{dollarFormat(row.beginCreditOriginalAmount)}</span>
          </div>
        </TableCell>
        <TableCell align="right">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <span>{dollarFormat(row.beginDebitAmount)}</span>
            <span>{dollarFormat(row.beginDebitOriginalAmount)}</span>
          </div>
        </TableCell>


        <TableCell align="right">
          <Typography variant='caption'>
            {row?.dataCode?.join(', ')}
          </Typography>
        </TableCell>
      </TableRow>
    ))
  }

  let breadcrumb = [{ name: 'Home', href: '/' }, { name: 'Beginning Balance', href: '/beginning-balance' }]
  return (
    <>
      <BreadCrumb data={breadcrumb} />
      <TableContainer component={Paper} sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 0,
        padding: 2,
        borderRadius: 2
      }}>
        <Box sx={{ fontSize: 24 }}>
          {/* Table of Customer */}
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">

          <TableHead>
            <TableRow sx={{
              fontWeight: 'bold'
            }}>
              <TableCell width={90}>No.</TableCell>
              <TableCell width={180}>Financial Year</TableCell>
              <TableCell width={90}>Account</TableCell>
              <TableCell align="center">Credit
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                  <span>Accounting</span>
                  <span>Original</span>
                </div>
              </TableCell>
              <TableCell align="center">Debit
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                  <span>Accounting</span>
                  <span>Original</span>
                </div>
              </TableCell>
              <TableCell align="right">Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow()}

          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8, 16, 25, { label: 'Tất cả', value: -1 }]}
                colSpan={6}
                count={listData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': t('pagingPerPage'),
                  },
                  native: true,
                }}
                labelRowsPerPage="Quantity:"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {type !== 'delete' ?
        <MainForm bg={'#F7FAFC'} ref={mainFormRef} maxWidth={'md'} actionSave={handleSave} subtitle='Beginning Balance' title={type === 'add' ? 'Create' : 'Update'}>
          {type === 'add' ? <AddForm ref={childFormRef} /> : <EditForm ref={childFormRef} />}
        </MainForm>
        : <></>}
    </>
  )
}

const BeginningBalance = (props) => {
  let title = "BeginningBalance"
  document.title = title
  return (
    <>
      <DrawerExpand headerText={title}>
        <Main props={props} />
      </DrawerExpand>
    </>
  );
};

const mapDispatchToProps = {
  getAllData, setEditObject, deleteBeginningBalance
}

const mapStateToProps = (state) => {
  return {
    data: state.beginningBalance
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeginningBalance);