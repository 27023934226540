import { Box, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { green, indigo, pink } from '@mui/material/colors';
import { find, get, isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { deleteAccountNumber, getAllData } from '../../../actions/accountant';
import { setEditObject } from '../../../actions/untils';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import MainForm from '../../elements/MainForm';
import BreadCrumb from '../../elements/breadCrumb';
import DrawerExpand from '../../elements/drawerExpand';
import RowMenu from '../../elements/rowMenu';
import AddForm from './AddForm';
import EditForm from './EditForm';
import ImportForm from './ImportForm';


const Main = (params) => {
  const { props } = params
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const mainFormRef = React.useRef();
  const childFormRef = React.useRef();
  const [openImport, setOpenImport] = useState(false);

  const actionAdd = useSelector(state => state?.enumRedux?.actionAdd)

  let stt = 0

  const editObject = useSelector(state => state?.edit)
  useEffect(() => {
    if (actionAdd === true) {
      if (type !== 'edit' && type !== 'delete') {
        setType('add')
      }
      mainFormRef.current?.openFromParent();
    }
    //eslint-disable-next-line
  }, [actionAdd])

  useEffect(() => {
    if (isEmpty(editObject)) {
      setType('add')
    }
  }, [editObject])

  const [type, setType] = useState('add')

  let listData = [...props?.data]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionMenu = async (value, type) => {
    mainFormRef.current?.openFromParent();
    setType(type)
    if (type === 'delete') {
      await props?.setEditObject({})
      deleteData(value)
    }
    else {
      if (type !== 'add') {
        dispatch({
          type: "SET_ENUM",
          payload: {
            actionAdd: true,
          }
        })
        await props?.setEditObject(value)
      }
    }
  }

  const deleteData = (value) => {
    props?.deleteAccountNumber(value?._id)
      .then(async res => {
        setType('')
        await props?.setEditObject({})
        _showAlertSuccess(res?.message || GET_SUCCESS)
      })
  }

  const handleSave = async () => {
    childFormRef.current?.handleSubmit();
  }

  useEffect(() => {
    props?.getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const toTree = (items, code = '', link = 'parentCode') =>
    items
      .filter(item => item[link] === code)
      .map(item => ({ ...item, children: toTree(items, item.code) }));

  const renderDataRowChild = (data, padding = 0, dashed = '') => {

    let list = rowsPerPage > 0
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data

    return list.map((row, index) => {
      stt = stt + 1
      return <React.Fragment>
        <TableRow key={row._id} >
          <TableCell className="firstCell" align="left" sx={{ paddingLeft: '0px!important', minWidth: 90 }}>
            <RowMenu
              data={row}
              actionEdit={handleActionMenu}
              sx={{}} />
            {stt}.
          </TableCell>
          <TableCell align="left">
            <div style={{ color: row?.isActive === true ? green[700] : pink[400] }}>
              {row?.code}
            </div> {row?.isFinal === true ? <div className='circle'></div> : <></>}
          </TableCell>
          <TableCell component="th" scope="row" sx={{
            borderWidth: 0.5
          }}>
            <Typography variant='subtitle2' color={indigo[500]} sx={{ lineHeight: 1.2, paddingLeft: padding }}>
              {dashed} {row?.name}
            </Typography>
          </TableCell>
          <TableCell align="left">{find(row.language, { langCode: 'en' })?.value}</TableCell>
          <TableCell align="right">{row?.parentCode}</TableCell>
          <TableCell align="right">{row?.type}</TableCell>
          <TableCell align="right">
            <Typography variant='caption'>
              {row?.dataCode?.join(', ')}
            </Typography></TableCell>
        </TableRow>
        {(get(row, 'children', [])).length > 0 && renderDataRowChild(row.children, padding + 2, (dashed === '' ? `|----${dashed}` : `${dashed}`))}
      </React.Fragment>
    }
    )
  }

  const renderRow = (padding = 0, dashed = '') => {

    let list = rowsPerPage > 0
      ? listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : listData

    let tmp = toTree(list);
    return tmp.map((row, index) => {
      stt = stt + 1
      return <React.Fragment>
        <TableRow key={row._id} sx={{ background: '#e2e2e2' }}>
          <TableCell className="firstCell" align="left" sx={{ paddingLeft: '0px!important', minWidth: 90 }}>
            <RowMenu
              data={row}
              actionEdit={handleActionMenu}
              sx={{}} />
            {/* {page * rowsPerPage + index + 1}. */}
            {stt}
          </TableCell>
          <TableCell align="left">
            <div style={{ color: row?.isActive === true ? green[700] : pink[400] }}>
              {row?.code}
            </div>{row?.isFinal === true ? <div className='circle'></div> : <></>}
          </TableCell>
          <TableCell component="th" scope="row" sx={{
            borderWidth: 0.5
          }}>
            <Typography variant='subtitle2'
              color={indigo[500]} sx={{ lineHeight: 1.2 }}>
              {row?.name}
            </Typography>
          </TableCell>
          <TableCell align="left">{find(row.language, { langCode: 'en' })?.value}</TableCell>
          <TableCell align="right">{row?.parentCode}</TableCell>
          <TableCell align="right">{row?.type}</TableCell>
          <TableCell align="right">
            <Typography variant='caption'>
              {row?.dataCode?.join(', ')}
            </Typography></TableCell>
        </TableRow>
        {(get(row, 'children', [])).length > 0 && renderDataRowChild(row.children, padding + 2, (dashed === '' ? `|----${dashed}` : `${dashed}`))}
      </React.Fragment>
    }
    )
  }

  // const openHdImport = () => { setOpenImport(true)}

  const closeHdImport = () => { setOpenImport(false) }

  let breadcrumb = [{ name: t('lblHome'), href: '/' }, { name: t('frmSetAccountant'), href: '/language' }]
  return (
    <>
      <BreadCrumb data={breadcrumb} />
      <TableContainer component={Paper} sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 0,
        padding: 2,
        borderRadius: 2
      }}>
        <Box sx={{ fontSize: 24 }}>
          {/* Table of Customer */}
          {/* <Button variant="text" onClick={()=>openHdImport()}>Import</Button> */}
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">

          <TableHead>
            <TableRow sx={{
              fontWeight: 'bold'
            }}>
              <TableCell>{t('lblColNo')}</TableCell>
              <TableCell>{t('lblColCode')}</TableCell>
              <TableCell>{t('lblColName')}</TableCell>
              <TableCell align="left">{t('lblColEn')}</TableCell>
              <TableCell align="right" sx={{ minWidth: 120 }}>{t('lblColParent')}</TableCell>
              <TableCell align="right">{t('lblColType')}</TableCell>
              <TableCell align="right" sx={{ minWidth: 120 }}>Data</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {renderRow()}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8, 16, 25, { label: t('pagingAll'), value: -1 }]}
                colSpan={7}
                count={listData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': t('pagingPerPage'),
                  },
                  native: true,
                }}
                labelRowsPerPage={t('pagingPerPage')}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {type !== 'delete' ?
        <MainForm ref={mainFormRef} maxWidth={'md'} actionSave={handleSave} subtitle={t('frmSetAccountant')} title={type === 'add' ? t('lblCreate') : t('lblUpdate')}>
          {type === 'add' ? <AddForm ref={childFormRef} /> : <EditForm ref={childFormRef} />}
        </MainForm>
        : <></>}

      {openImport && <ImportForm isOpen={openImport} handleClose={closeHdImport} />}
    </>
  )
}

const Accountant = (props) => {
  const { t } = useTranslation()
  let title = t('frmSetAccountant')
  document.title = title
  return (
    <>
      <DrawerExpand headerText={title}>
        <Main props={props} />
      </DrawerExpand>
    </>
  );
};

const mapDispatchToProps = {
  getAllData, setEditObject, deleteAccountNumber
}

const mapStateToProps = (state) => {
  return {
    data: state.accountant
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accountant);