import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, useTheme } from '@mui/material';
import DrawerExpand from '../../elements/drawerExpand';
import BreadCrumb from '../../elements/breadCrumb';
import RowMenu from '../../elements/rowMenu';
import FormDrawer from '../../elements/formDrawer';
import { useEffect, useState } from 'react';
import EditForm from './EditForm';


function createData(stt, name, quantity, status) {
  return { stt, name, quantity, status };
}

const rows = [
  createData(1, 'Quy trình chuyển đổi khách hàng', 5, 'Đang hoạt động'),
  createData(2, 'Quy trình 2', 3, 'Đã khóa'),
  createData(3, 'Quy trình 3', 6, 'Đang hoạt động'),
];



const Processing = (props) => {

  // state
  const childRef = React.useRef();
  const [title] = useState('Tiềm năng')
  const [data, setData] = useState([])
  //
  document.title = title


  useEffect(() => {
    setData(rows)
  },[]);

  // function
  const handleActionMenu = (value, type) =>{
    childRef.current?.openFromParent();
  }

  //

  const Main = () => {
    const theme = useTheme()
    let breadcrumb = [{ name: 'Home', href: '/' }, { name: 'Quy trình', href: '/processing' }]
    return (
      <>
        <BreadCrumb data={breadcrumb} />
        <TableContainer component={Paper} sx={{
          backgroundColor: theme.palette.common.white,
          boxShadow: 0,
          padding: 2,
          borderRadius: 2
        }}>
          <Box sx={{ fontSize: 24 }}>
            {/* Table of Customer */}
          </Box>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <caption>A basic table example with a caption</caption>
            <TableHead>
              <TableRow sx={{
                fontWeight: 'bold'
              }}>
                <TableCell>No.</TableCell>
                <TableCell>Tên</TableCell>
                <TableCell align="right">Quantity:</TableCell>
                <TableCell align="right">Trạng thái</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.name} >
                  <TableCell align="left">
                    <RowMenu
                      actionEdit={handleActionMenu}
                      sx={{ mr: 2 }} data={row} />
                    {row.stt}</TableCell>
                  <TableCell component="th" scope="row" sx={{
                    borderWidth: 0.5
                  }}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FormDrawer ref={childRef}>
           <EditForm/>
        </FormDrawer>
      </>
    )
  }

  return (
    <>
      <DrawerExpand headerText={title}>
        <Main />
      </DrawerExpand>
    </>
  );
};


export default Processing;