import * as React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector, connect } from 'react-redux';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { setEditObject } from '../../actions/untils';

const MainFormLevel2 = React.forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [open, setOpen] = React.useState(false);
    const [fullScreen, setFull] = React.useState(false);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    // const fullScreenWidth = useMediaQuery(theme.breakpoints.down(maxWidth));


    // const descriptionElementRef = React.useRef(null);
    // React.useEffect(() => {
    //     if (open) {
    //         const { current: descriptionElement } = descriptionElementRef;
    //         if (descriptionElement !== null) {
    //             descriptionElement.focus();
    //         }
    //     }
    // }, [open]);
    const actionAddLv2 = useSelector(state => state?.enumRedux?.actionAddLv2)

    React.useEffect(() => {
        if (actionAddLv2 !== true) {
            closeFromParent()
        }
      }, [actionAddLv2])

    React.useEffect(() => {
        if (props.maxWidth) {
            setMaxWidth(props.maxWidth)
        }
    }, [props.maxWidth]);

    const handleSave = () => {
        props?.actionSave()
    }

    const handleClose = () => {
        setOpen(false);
        dispatch({
            type: "SET_ENUM",
            payload: {
                actionAddLv2: false,
            }
        })
        props?.setEditObject({})
    };

    function openFromParent() {
        setOpen(true);
    }

    function closeFromParent() {
        setOpen(false);
    }

    React.useImperativeHandle(ref, () => ({ openFromParent, closeFromParent }));
    return (
        <Dialog
            open={open}
            fullScreen={fullScreen ? fullScreen : false}
            maxWidth={maxWidth}
            fullWidth={true}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" className='dialogTitle' style={{ background: theme.palette.primary.main }}>
                <div className='titForm'>
                    {props?.subtitle} | {props?.title}
                    {/* <p className='subTitForm'>{props?.subtitle}</p> */}
                </div>
                <div className='toolForm'>
                    {
                        fullScreen ?
                            <FullscreenExitOutlinedIcon onClick={() => setFull(false)} />
                            :
                            <FullscreenOutlinedIcon onClick={() => setFull(true)} />
                    }
                    <CloseOutlinedIcon onClick={() => handleClose()} />
                </div>
            </DialogTitle>
            <DialogContent dividers={true} style={{ background: props?.bg || '#fff', padding: 16 }}>
                {props?.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
})

// export default MainFormLevel2;

const mapDispatchToProps = {
    setEditObject
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(MainFormLevel2);
