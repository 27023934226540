import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import SendIcon from '@material-ui/icons/Send';
import { emitChatting, emitTyping } from '../../../actions/socketInside'
import { isDocument, isImage, isVideo } from '../../../untils/Extension'
import * as chatAction from "../../../actions/chat"
import { AlertConfirm } from '../../../untils/AlertConfirm';
import { parseError } from '../../../untils/parseError';

let file = '';
let image = '';

class ChatAction extends Component {
    constructor(props) {
        super(props);

        this.state = { contentTextSend: "", isLoading: false, page: 1, files: [], openFile: false, images: [] };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.sendMessageChat()
        }
    }


    sendMessageChat = () => {
        if (this.state.contentTextSend.length > 0) {
            let data = {
                room: this.props.currentChatMessage.code,
                message: {
                    type: "text",
                    content: this.state.contentTextSend
                }
            };
            emitChatting(data)
            this.setState({ contentTextSend: "" })
            // this.props.scrollToBottom()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.contentTextSend.length === 0 && nextState.contentTextSend.length > 0) {
            let data = {
                room: this.props.currentChatMessage.code,
                data: {
                    userId: this.props.profile._id,
                    isTyping: true
                }
            };
            emitTyping(data)
        }

        if (this.state.contentTextSend.length > 0 && nextState.contentTextSend.length === 0) {
            let data = {
                room: this.props.currentChatMessage.code,
                data: {
                    userId: this.props.profile._id,
                    isTyping: false
                }
            };
            emitTyping(data)
        }


        return true
    }


    handleChangeFile = (event) => {
        let duplicateFile = {};
        const currentFiles = this.state.files;

        if (file.files.length > 0) {
            [...file.files].forEach((ss, i) => {
                duplicateFile = this.state.files.find((doc) => doc.fileName === ss.name);
                if (!duplicateFile) {
                    currentFiles.push(ss)
                }

                if (i === file.files.length - 1) {
                    // this.setState({
                    //     files: currentFiles,
                    //   }, () => {
                    var formData = new FormData();
                    formData.append('name', 'Image Upload');
                    [...currentFiles].forEach(fl => {
                        formData.append("files", fl);
                    });

                    this.props.uploadImageChat(formData)
                        .then(res => {
                            var imgId = []
                            var videoId = []
                            var documentId = []
                            var index = 0
                            var type = ''
                            for (let dt of res.success) {
                                if (isImage(dt.mimeType) === true) {
                                    imgId.push(dt._id)
                                    type = 'image'
                                }
                                if (isVideo(dt.mimeType) === true) {
                                    videoId.push(dt._id)
                                    type = 'video'
                                }
                                if (isDocument(dt.extension) === true) {
                                    documentId.push(dt._id)
                                    type = 'document'
                                }
                                if (index === res.success.length - 1) {
                                    //sendPhoto
                                    let data = {
                                        room: this.props.currentChatMessage.code,
                                        message: {
                                            type: type,
                                            images: imgId,
                                            videos: videoId,
                                            documents: documentId
                                        }
                                    };

                                    emitChatting(data)
                                    this.props.scrollToBottom()
                                    this.setState({ files: [] })
                                }
                                index++
                            }

                        })
                        .catch(err => {
                            AlertConfirm(parseError(err.error))
                        })
                    //   });
                }

            })

        }

    }


    handleChangeImage = (event) => {
        let duplicateFile = {};
        if (image.files.length > 0) {
            duplicateFile = this.state.images.find((doc) => doc.fileName === image.files[0].name);

            if (!duplicateFile) {
                let currentFiles = this.state.files;
                // currentFiles.push(image.files[0])
                currentFiles = [...image.files]
                
                var formData = new FormData();
                formData.append('name', 'Image Upload');
                [...currentFiles].forEach(fl => {
                    formData.append("files", fl);
                });
               
                this.props.uploadImageChat(formData)
                    .then(res => {
                        var imgId = []
                        var index = 0
                        for (let dt of res.success) {
                            imgId.push(dt._id)
                            if (index === res.success.length - 1) {
                                this.props.uploadImageChat(formData)
                                    .then(res => {
                                        var imgId = []
                                        var videoId = []
                                        var documentId = []
                                        var index = 0
                                        var type = 'image'
                                        for (let dt of res.success) {
                                          
                                            if (isImage(dt.mimeType) === true) {
                                                imgId.push(dt._id)
                                                type = 'image'
                                            }
                                            if (isVideo(dt.mimeType) === true) {
                                                videoId.push(dt._id)
                                                type = 'video'
                                            }
                                            if (isDocument(dt.mimeType) === true) {
                                                documentId.push(dt._id)
                                                type = 'document'
                                            }

                                            if (index === res.success.length - 1) {

                                                //sendPhoto
                                                let data = {
                                                    room: this.props.currentChatMessage.code,
                                                    message: {
                                                        type: type,
                                                        images: imgId,
                                                        videos: videoId,
                                                        document: documentId
                                                    }
                                                };

                                                emitChatting(data)
                                                this.props.scrollToBottom()
                                                this.setState({ images: [] })
                                            }
                                            index++
                                        }

                                    })
                                    .catch(err => {
                                        AlertConfirm(parseError(err.error))
                                    })
                            }
                            index++
                        }

                    })
                    .catch(err => {
                        AlertConfirm(parseError(err.error))
                    })

            }
        }

    }

    render() {
        return (
            <div className="bottomDetailChat">
                <div className="actionButtonChat">
                    <label htmlFor="upload-file"><AttachFileOutlinedIcon /></label>
                    <input type="file" name="file" id="upload-file" accept=".pdf,.xls,.doc,.xlsx,.docx" onChange={this.handleChangeFile} ref={(input) => { file = input; }} multiple />
                </div>
                <div className="actionButtonChat">
                    <label htmlFor="upload-photo"><ImageOutlinedIcon /></label>
                    <input type="file" name="photo" id="upload-photo" accept=".png,.jpg,.jpeg,.mp4" onChange={this.handleChangeImage} ref={(input) => { image = input; }} multiple />
                </div>
                <div className="actionMessage">
                    <input className="typingContain" name="contentTextSend"
                        onKeyDown={this._handleKeyDown}
                        value={this.state.contentTextSend} placeholder="Aa" onChange={this.onChange} />
                </div>
                <div className="actionSendChat">
                    <div className="btnSend" onClick={() => this.sendMessageChat()}>
                        <SendIcon />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        chatUserOnline: state.chatUserOnline,
        chatMessageLasted: state.chatMessageLasted,
        chatGroup: state.chatGroup,
        userAll: state.userAll,
        profile: state.profile,
        typingMessage: state.typingMessage,
        currentChatMessage: state.currentChatMessage
    };
}

export default connect(
    mapStateToProps, { ...chatAction }
)(ChatAction);