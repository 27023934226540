import { makeStyles } from "@material-ui/styles";
import { ClickAwayListener, Divider, Fade, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import React, { useEffect } from "react";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/* copied from https://github.com/mui-org/material-ui/blob/v4.3.2/packages/material-ui/src/Menu/Menu.js#L21 */
const useMenuStyles = makeStyles({
  /* Styles applied to the `Paper` component. */
  paper: {
    // specZ: The maximum height of a simple menu should be one or more rows less than the view
    // height. This ensures a tapable area outside of the simple menu with which to dismiss
    // the menu.
    maxHeight: "calc(100% - 96px)",
    // Add iOS momentum scrolling.
    WebkitOverflowScrolling: "touch"
  },
  /* Styles applied to the `List` component via `MenuList`. */
  list: {
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0
  }
});

export default function FakedReferencePopper(props) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleContextMenu(props.anchorEl)
  }, [props.anchorEl])


  const handleContextMenu = (e) => {
    e.preventDefault();
    const { clientX, clientY } = e;
    setOpen(true);
    const virtualElement = {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: clientY,
        right: clientX,
        bottom: clientY,
        left: clientX
      })
    };
    setAnchorEl(virtualElement);
  };

  const handleEdit = () => {
    props?.handleEdit(props?.data, 'edit')
    setAnchorEl(null)
    setOpen(false)
  }

  const handleDel = () => {
    props?.handleDelete(props?.data)
  }

  const handleDuplicate = () => {
    props?.handleDuplicate(props?.data)
  }
  const handleOpen = () => {
    props?.handleOpen(props?.data, props?.anchorEl?.target, props?.indexRow > 5 ? 'top-end' : 'bottom-end')
    setOpen(false)
  }

  const hanldeCreate = () => {
    dispatch({
      type: "SET_ENUM",
      payload: {
        actionAdd: true,
      }
    })
  }

  const handleLock = () => {
    props?.handleActive(props?.data)
    setOpen(false)
  }

  const handlePrint = () => {
    props?.handlePrint(true)
    setOpen(false)
  }

  const handleComment = () => {
    props?.handleComment(true)
    setOpen(false)
  }

  const handleStar = () => {
    props?.handleStar(props?.data)
    setOpen(false)
  }


  const id = open ? "faked-reference-popper" : undefined;
  const menuClasses = useMenuStyles();
  return open && <Popper
    id={id}
    open={open}
    anchorEl={anchorEl}
    transition
    sx={{ zIndex: 10000 }}
    placement={props?.indexRow > 3 ? 'right-end' : 'right-end'}
  >
    {({ TransitionProps }) => (
      <ClickAwayListener onClickAway={handleClose}>
        <Fade {...TransitionProps}>
          <Paper className={menuClasses.paper}>
            <MenuList className={menuClasses.list} autoFocus>
              {props?.type === 'transaction' &&
                <div>
                  <MenuItem onClick={handleOpen}>
                    <ListItemIcon>
                      <VisibilityOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('lblOpen')}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleDuplicate}> <ListItemIcon>
                    <ContentCopyOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                    <ListItemText>{t('lblDuplicate')}</ListItemText>
                  </MenuItem>
                  <Divider />
                </div>
              }

              {props?.type === 'transaction' && <MenuItem onClick={hanldeCreate}>
                <ListItemIcon>
                  <AddOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('lblCreateNew')}</ListItemText>
              </MenuItem>}

              <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                  <ModeEditOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('lblEdit')}</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleDel}> <ListItemIcon>
                <DeleteOutlinedIcon fontSize="small" />
              </ListItemIcon>
                <ListItemText>{t('lblDelete')}</ListItemText>
              </MenuItem>

              {props?.type === 'transaction' &&
                <div>
                  <Divider />
                  <MenuItem onClick={handleComment}>
                    <ListItemIcon>
                      <AddCommentOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('lblComment')}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleStar}>
                    <ListItemIcon>
                      <StarOutlineOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('lblStart')}</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <ListItemIcon>
                      <AttachFileOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('lblAttach')}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleLock}>
                    <ListItemIcon>
                      {
                        props?.activeIcon ?
                          props?.activeIcon :
                          <LockResetOutlinedIcon fontSize="small" />
                      }
                    </ListItemIcon>
                    <ListItemText>{props?.activeText ? props?.activeText : t('lblLock')}</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handlePrint}>
                    <ListItemIcon>
                      <PrintOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('lblViewPrint')}</ListItemText>
                  </MenuItem>
                </div>}
            </MenuList>
          </Paper>
        </Fade>
      </ClickAwayListener>
    )}
  </Popper>
}
