import { setTokenAxios } from "../api"

export const uploadFile = (data) => (dispatch) => {
    return setTokenAxios().post(`file/upload`, data)
        .then(res => {
            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const uploadFileUser = (data) => (dispatch) => {
    return setTokenAxios().post(`file/upload?moduleName=user`, data)
        .then(res => {
            return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const createFileFileBase = (data) => (dispatch) => {
    return setTokenAxios().post(`file/from-firebase`, data)
        .then(async res => {
            return Promise.resolve({ message: 'Successfully', data: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ message: 'Failed', error: err.data.message })
        })
}

export const updateFile = (data, _id) => (dispatch) => {
    return setTokenAxios().put(`file/${_id}`, data)
        .then(async res => {
            return Promise.resolve({ message: 'Successfully', data: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ message: 'Failed', error: err.data.message })
        })
}

export const deleteFile = (_id) => (dispatch) => {
    return setTokenAxios().delete(`file/${_id}`)
        .then(async res => {
            return Promise.resolve({ message: 'Successfully', data: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ message: 'Failed', error: err.data.message })
        })
}