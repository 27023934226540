import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { Typography } from '@mui/material';
import moment from 'moment';
import { connect } from 'react-redux';

const TimelineCus = (props) => {

  return (
    <Timeline position="right" className='timeLineCustom'>
      {props?.data.map((res, ind) => {
        return <TimelineItem key={ind}>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1" component="span" sx={{ fontWeight: 600 }}>
              {res?.title}
            </Typography>
            <Typography variant="subtitle2"
              color="text.secondary" mb={1}>
                {moment(res?.created).format('lll')}
            </Typography>
            <Typography variant="body2">
              {res?.content}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      })}


    </Timeline>
  );
}



const mapStateToProps = (state) => {
  return {
    data: state.chanceNote
  }
}

export default connect(mapStateToProps, null)(TimelineCus);