const initialState = []

const readMoneyReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_READ_MONEY":
            return action.payload
            
        case "CREATE_READ_MONEY":
            return [...state, action.payload]

        case "UPDATE_READ_MONEY":
            
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            
            return [...state]

        case "CHANGE_PASSWORD":
            
                const password = state.findIndex(per => per._id === action.payload._id)
                state[password] = action.payload;
                
                return [...state]

        case "UPDATE_READ_MONEY_ACTIVE":
            const readMoney_ = [...state] 
            if(action.payload.length > 0)
            {
                (action.payload).forEach((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    readMoney_[index] = item;
                })
            }
            return readMoney_

        case "SET_CURRENT_READ_MONEY_IMG":
                return [...state]

        case "DELETE_READ_MONEY":
            const readMoney_old = [...state] 
            var readMoney_deleted = []
           
            if(action.payload.length > 0)
            {
                readMoney_deleted = readMoney_old.filter(del =>!(action.payload).includes(del._id) );
            }
            return readMoney_deleted
        

        default:
            break;
    }

    return state
}

export default readMoneyReducer