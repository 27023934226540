
import Popper from '@mui/material/Popper';
import * as React from 'react';


import { CheckOutlined } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Box, ClickAwayListener, Divider, List, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material';
import { debounce, findIndex, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { getAllDataNoneRedux as getAccount, getAccountNumberByArrCode } from '../../actions/accountant';

const AccountNumberLst = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [listAccount, setListAccount] = React.useState([])
    const [inputValue, setInputValue] = React.useState('')
    const [account, setAccount] = React.useState([])

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    React.useEffect(() => {
        const init = async () => {
            await props?.getAccount({ condition: {}, limit: 20 }).then(async res => {

                let tmp = [...res?.data]
                let tmpNotExist = props?.account.filter(acc => {
                    return findIndex(tmp, { code: acc }) < 0
                });

                if (tmpNotExist.length > 0) {
                    await props?.getAccountNumberByArrCode({ codeArr: tmpNotExist })
                        .then(res => {
                            tmp = [...res.data, ...tmp,]
                        })
                }
                setListAccount(tmp)
            })
        }

        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {

        if (isEmpty(props?.account?.length > 0)) {
            setAccount(props?.account)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.account])


    const onChangeInput = (e) => {
        setInputValue(e.target.value)
        searchAuto(e.target.value)
    }

    const searchAuto = debounce((value) => {

        var data = {
            "condition": {
                code: { regex: '^' + value, options: 'm' }
            },
            "sort": {
                "created": -1
            },
            "limit": 20,
            "page": 1
        }
        props?.getAccount(data).then(res => setListAccount(res.data))
    }, 1200)


    const _handleChooseAccount = (val) => {
        if (!isEmpty(val?.code)) {
            let tmp = [...account]
            let exist = tmp.indexOf(val.code)
            if (exist > -1) {
                tmp.splice(exist, 1)
            }
            else {
                tmp.push(val.code)
            }
            setAccount(tmp)
            props?.selectAccount(tmp)
        }


    }

    const handleClose = () => {
        setAnchorEl(null)
    }


    return (
        <React.Fragment>
            <ClickAwayListener onClickAway={handleClose}>
                <div style={{
                    display: 'flex', justifyContent: !isEmpty(account?.code) ? 'flex-end' : 'flex-end', flexDirection: 'column', flex: 1
                }}>
                    <Box aria-describedby={id}
                        sx={{
                            color: '#2196F3',
                            cursor: 'pointer',
                        }}
                        onClick={handleClick}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
                            <SearchIcon sx={{ color: '#757575', position: 'absolute', left: 3, bottom: 6 }} />
                            {/* {account?.code || 'Choose account'} */}
                            <TextField size='small'
                                onChange={onChangeInput}
                                variant="outlined"
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                                type='text'
                                sx={{
                                    '& input': {
                                        paddingLeft: 3
                                    },
                                    width: 200
                                }}
                                placeholder='Choose account' value={inputValue} />

                            <span style={{marginLeft: 8}}>{account.join(', ')}</span>
                        </div>
                    </Box>

                    <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 15000 }} placement={"bottom-start"}>
                        <Paper sx={{
                            p: 1, mt: 1, minWidth: 480, borderRight: '1px solid #00000040',
                            minHeight: 'calc(100vh/3)'
                        }}>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            </Box>
                            <List sx={{
                                width: '100%', bgcolor: 'background.paper',
                                maxHeight: 'calc(100vh/3)', overflowY: 'scroll',
                                '& .MuiListItem-root': {
                                    paddingY: 0.2
                                }
                            }}>
                                {
                                    listAccount?.map((res, inde) => {
                                        return <React.Fragment key={inde}>
                                            <ListItem
                                                sx={{
                                                    '&:hover': {
                                                        background: '#f4faff'
                                                    },
                                                    paddingLeft: 0,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => _handleChooseAccount(res)}>
                                                <Box display={'flex'} flexDirection={'row'}
                                                    justifyContent={'flex-start'}
                                                    width={100} mr={2} pr={2}
                                                    borderColor={'#0000001f'}
                                                    borderRight={1}>
                                                    <div style={{ width: 24 }}>
                                                        {props?.account.indexOf(res?.code) > -1 && <CheckOutlined fontSize='small' />}
                                                    </div>
                                                    <Typography variant='title'>{res?.code}</Typography>
                                                </Box>
                                                <ListItemText primary={res?.name} secondary={res?.description} />
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    })
                                }

                            </List>
                        </Paper>

                    </Popper>
                </div>
            </ClickAwayListener >

        </React.Fragment>
    );
};

const mapDispatchToProps = {
    getAccount, getAccountNumberByArrCode
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(AccountNumberLst);
