import { FormControl, FormLabel, Grid, InputLabel, MenuItem, Radio, Select, Step, StepLabel, Stepper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { find, isEmpty, uniq } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createRequest} from '../../../actions/request';
import { getAllDataNoneRedux as getRequestTemplate } from '../../../actions/requestTemplate';
import { getAllDataNoneRedux as getUser } from '../../../actions/user';


const initialState = {
    name: "",
    isActive: true,
    description: '',
    type: '',
    templateActive: {},
    actions: [],
    typeInfo: '',
    nameInfo: '',
    listUser: []
};

const AddForm = React.forwardRef((props, ref) => {
    const [
        { name, isActive, description,
            listTemplate, listUser,
            templateActive,
        },
        setState
    ] = useState(initialState);

    useEffect(() => {
        const init = () => {
            props?.getUser()
                .then(res => {
                    setState(state => ({ ...state, listUser: res?.data }));
                })
       
            props?.getRequestTemplate()
                .then(res => {
                    setState(state => ({ ...state, listTemplate: res?.data }));
                })
        }
        init()
        // eslint-disable-next-line
    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    // const customeOnChange = (name, value) => {
    //     setState(state => ({ ...state, [name]: value }));
    // }

    const handleSubmit = () => {

        let queuQ = (templateActive?.queue?.queue).map((res) => ({ ...res, user: { ...res.userId } }))
        let followQ = (templateActive?.queue?.queue).map((res) => ({ ...res.userId }))

        let data = {
            name, 
            isActive, description,
            templateActive,
            requestForCode:'',
            type:'REQ',
            source:'Request',
            queue: queuQ,
            follow: followQ,
        }
  
        props?.createRequest(data)
            .then(res => {
                // _showAlertSuccess(res?.message || GET_SUCCESS)
            })
    }


    React.useImperativeHandle(ref, () => ({ handleSubmit }));


    const renderTemplate = () => {
        return <Grid item xs={12} >
            <Grid xs={12} item>
                <Typography variant='title' fontWeight={600}>Confirm</Typography>
                <Typography variant='subtitle2'>Please update all information below to create a request.</Typography>
            </Grid>
            {templateActive?.template?.map((row, i) => (
                renderObjectTemplate(row)
            ))}
        </Grid>
    }

    const renderObjectTemplate = (data) => {
        switch (data?.type) {
            case 'text':
                return <Grid item md={12} xs={12} mt={3} mb={3}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { display: 'flex' },
                        }}
                        noValidate
                        autoComplete="off" >
                        <TextField
                            label={data?.name}
                            id={data?.name}
                            value={data?.value}
                            onChange={(e) => {
                                let tmp = [...templateActive?.template]
                                tmp[data?.order - 1].value = e.target.value
                                let templateTmp = { ...templateActive }
                                templateTmp.template = tmp
                                setState(state => ({ ...state, template: templateTmp }))
                            }}
                            size="small"
                        />
                    </Box>
                </Grid>

            case 'select':
                return <Grid item md={12} xs={12} mt={3} mb={3}>
                    <FormControl sx={{
                        minWidth: '90%',
                        '& .MuiInputLabel-root': {
                            minWidth: 90,
                            background: '#fff'
                        }
                    }} size="small">
                        <InputLabel id="demo-simple-select-label">{data?.name}</InputLabel>
                        <Select
                            autoWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data?.value}
                            label="Type"
                            onChange={(e) => {
                                let tmp = [...templateActive?.template]
                                tmp[data?.order - 1].value = e.target.value
                                let templateTmp = { ...templateActive }
                                templateTmp.template = tmp
                                setState(state => ({ ...state, template: templateTmp }))
                            }}>
                            {
                                data?.data?.map((res, i) => {
                                    return <MenuItem value={res} key={i}>{res}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>

                </Grid>

            case 'checked':
                return <Grid item md={12} xs={12} mt={3} mb={3}>
                    <FormControl
                        component="fieldset" variant="standard">
                        <FormLabel component="legend">{data?.name}</FormLabel>
                        <FormGroup row={true}>
                            {
                                data?.data?.map((res, i) => {
                                    return <FormControlLabel key={i} control={
                                        <Checkbox checked={data?.value?.indexOf(res) > -1}
                                            onChange={(e) => {
                                                let tmp = [...templateActive?.template]
                                                let tmpVal = tmp[data?.order - 1].value ? [...tmp[data?.order - 1].value] : []
                                                tmpVal.push(e.target.value)
                                                tmp[data?.order - 1].value = uniq(tmpVal)
                                                let templateTmp = { ...templateActive }
                                                templateTmp.template = tmp
                                                setState(state => ({ ...state, template: templateTmp }))
                                            }}
                                            value={res} />
                                    } label={res} />
                                })
                            }
                        </FormGroup>
                    </FormControl>
                </Grid>

            case 'radio':
                return <Grid item md={12} xs={12} mt={3} mb={3}>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">{data?.name}</FormLabel>
                        <FormGroup row={true}>
                            {
                                data?.data?.map((res, i) => {
                                    return <FormControlLabel key={i} control={
                                        <Radio checked={data?.value === res} value={res}
                                            onChange={(e) => {
                                                let tmp = [...templateActive?.template]
                                                tmp[data?.order - 1].value = e.target.value
                                                let templateTmp = { ...templateActive }
                                                templateTmp.template = tmp
                                                setState(state => ({ ...state, template: templateTmp }))
                                            }}
                                        />
                                    } label={res} />
                                })
                            }
                        </FormGroup>
                    </FormControl>
                </Grid>

            default:
                break;
        }
    }

    return (
        <Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 12, md: 12 }}>
            <Grid item md={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 0, marginTop: 24 }}>
                <div>
                    <Typography variant='title' fontWeight={600}>Request template</Typography>
                    <Typography variant='subtitle2'>Template will be applied .</Typography>
                </div>

                <FormControl sx={{
                    minWidth: 235,
                    '& .MuiInputLabel-root': {
                        width: 90,
                        background: '#fff'
                    }
                }} size="small">
                    <InputLabel id="demo-simple-select-label">By template</InputLabel>
                    <Select
                        autoWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={templateActive?._id}
                        label="Type"
                        onChange={(e) => {
                            let tmp = find(listTemplate,{_id: e.target.value})
                            setState(state => ({ ...state, templateActive: tmp, name: tmp?.name }))
                        }}>
                        {
                            listTemplate?.map((res, i) => {
                                return <MenuItem value={res?._id} key={i}>{res?.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

            </Grid>

            <Grid item lg={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Name"
                        id="last-name"
                        value={name}
                        name="name"
                        onChange={onChange}
                    />
                </Box>
            </Grid>

            <Grid item lg={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Note"
                        id="last-description"
                        value={description}
                        multiline={true}
                        minRows={2}
                        name="description"
                        onChange={onChange}
                    />
                </Box>
            </Grid>

            {
                !isEmpty(templateActive) ? <>
                    {renderTemplate()}

                    <Grid item md={12} xs={12} style={{ paddingTop: 12 }}>
                        <div>
                            <Typography variant='title' fontWeight={600}>Request queue</Typography>
                            <Typography variant='subtitle2'>This request will be approved in order.</Typography>
                        </div>

                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={0} alternativeLabel>
                                {templateActive?.queue?.queue?.map((label) => {
                                    let u = find(listUser, { _id: label?.userId })
                                    return <Step key={label?.order}>
                                        <StepLabel>{u?.firstName} {u?.lastName}</StepLabel>
                                    </Step>
                                })}
                            </Stepper>
                        </Box>
                    </Grid>
                </> : ''
            }


        </Grid>
    );
})


const mapDispatchToProps = {
    createRequest, getUser,  getRequestTemplate
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(AddForm);