import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const generateCode = () => (dispatch) => {
  return setTokenAxios().get(`income/generate-code`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data.code })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getIncomeByCode = (id) => (dispatch) => {
  return setTokenAxios().get(`income/get-by-code/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getIncomeById = (id) => (dispatch) => {
  return setTokenAxios().get(`income/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`income`, {params: condition})
    .then(async res => {
      dispatch({
        type: "SET_INCOME",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`income`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataMore = (condition) => (dispatch) => {
  return setTokenAxios().get(`income`, {params: condition})
    .then(async res => {
      dispatch({
        type: "SET_MORE_INCOME",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const createIncome = (data) => (dispatch) => {
  return setTokenAxios().post(`income`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_INCOME",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateIncome = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`income/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_INCOME",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const UnActive = (data) => (dispatch) => {
  return setTokenAxios().post(`income/unactived`,data )
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateWithoutCondition = (data) => (dispatch) => {
  return setTokenAxios().post(`income/withoutCondition`,data )
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const activeIncome = (_id, data) => (dispatch) => {
  return setTokenAxios().put(`income/${_id}/actived`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_INCOME",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const starIncome = (_id, data) => (dispatch) => {
  return setTokenAxios().put(`income/${_id}/star`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_INCOME",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const starMultipleIncome = (data) => (dispatch) => {
  return setTokenAxios().post(`income/stared`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_INCOME_MULTI",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteIncome = (_id) => (dispatch) => {
  return setTokenAxios().delete(`income/${_id}`)
    .then(async res => {
      dispatch({
        type:"DELETE_INCOME",
        payload: res.data.data
    })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}