import { isArray, isObject } from "lodash"
import {store} from '../store'


const _checkError = (err) => {
    if (err?.message === 'Network Error') {
        return "Network error, please try again!"
    }

    if (err?.response?.status===401) {
        return {code: 401, message: `${err?.response?.status} ${err?.response?.data?.message}`}
    }

    if (err?.response?.data?.message) {
        if (err?.response?.data?.data?.modules) {
            if (isArray(err?.response?.data?.data?.actions)) {
                return `${err?.response?.data?.message} {${err?.response?.data?.data?.modules}- ${err?.response?.data?.data?.actions?.map(item => item)}}`
            }

            return `${err?.response?.data?.message} {${err?.response?.data?.data?.modules}- ${err?.response?.data?.data?.actions}}`
        }

        return `${err?.response?.data?.message}`
    }

    if (err?.response?.data?.errors) {
        return err?.response?.data?.errors
    }

    // console.log(err.response)

    return `${JSON.stringify(err?.response?.data?.error)}`
}

const _showAlertError = async (err) => {
    let errorData = await _checkError(err)
  
  
    let message = ''
    if(errorData?.code === 401)
    {
        message = errorData?.message
    }
    else
    {
        message = errorData
       
        if(isObject(errorData)&&Object.keys(errorData).length > 0)
        {
            message = Object.values(errorData)[0]?.message
        }
    }

    let data = {
        type:'error',
        message: message,
        isOpen: true
    }

    if(errorData?.code === 401)
    {
        data.action = '/login'
        data.textHref = 'Sign in'
        data.errorCode = 401
    }
  
    store.dispatch({
        type: "SET_ALERT_VAL",
        payload: data
      })
   
}


const _showAlertSuccess = (message) => {

    let data = {
        type:'success',
        message: message,
        isOpen: true
    }
    store.dispatch({
        type: "SET_ALERT_VAL",
        payload: data
      })
}

const _showAlertErrorMessage = (message) => {

    let data = {
        type:'error',
        message: message,
        isOpen: true
    }
    store.dispatch({
        type: "SET_ALERT_VAL",
        payload: data
      })
}

const _closeAlertMessage = () => {

    let data = {
        type:'info',
        message: '',
        isOpen: false
    }
    store.dispatch({
        type: "SET_ALERT_VAL",
        payload: data
      })
}

export {_checkError, _showAlertError, _showAlertSuccess, _showAlertErrorMessage, _closeAlertMessage}