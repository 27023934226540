const PartnerSource = [
    {
        code:'3BGROUP',
        group:'company',
        name:'3BGROUP'
    },
    {
        code:'GOOGLETN',
        group:'company',
        name:'Google'
    },
    {
        code:'Other',
        group:'self',
        name:'Other'
    }
]

export {PartnerSource}

