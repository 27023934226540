
import { PercentOutlined } from '@mui/icons-material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
// import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';

const workMenu = [
    {
        name: 'leftMenuAct',
        icon: <CelebrationOutlinedIcon />,
        path: '/',
        module: 'WORK',
        navigate: 'activities',
        type: 'child'
    },
    {
        name: 'leftMenuChat',
        icon: <MarkChatUnreadOutlinedIcon />,
        path: '/chat',
        module: 'WORK',
        navigate: 'chat',
        type: 'child'
    },
    {
        name: 'leftMenuWork',
        icon: <WorkspacesOutlinedIcon />,
        path: '/',
        module: 'WORK',
        navigate: 'work-space',
        type: 'child'
    },
    {
        name: 'leftMenuTask',
        icon: <AssignmentOutlinedIcon />,
        path: '/task',
        module: 'WORK',
        navigate: 'task',
        type: 'child'
    },
    {
        name: 'leftMenuKeep',
        icon: <NoteAltOutlinedIcon />,
        path: '/keep',
        module: 'WORK',
        navigate: 'keep',
        type: 'child'
    },
    {
        name: 'leftMenuContact',
        icon: <ContactPageOutlinedIcon />,
        path: '/',
        module: 'WORK',
        navigate: 'contact',
        type: 'child'
    },
    {
        name: 'leftMenuDoc',
        icon: <DocumentScannerOutlinedIcon />,
        path: '/',
        module: 'WORK',
        type: 'child'
    },
    {
        name: 'leftMenuReport',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'WORK',
        navigate: 'work-report',
        type: 'child'
    }
]

const amMenu1 = [
    {
        name: 'CASH',
        icon: <CurrencyExchangeOutlinedIcon />,
        path: '/',
        module: 'Accounting and Financial',
        type: 'head'
    },
    {
        name: 'leftMenuCR',
        icon: <AttachMoneyOutlinedIcon />,
        path: '/cash-received',
        module: 'Accounting and Financial',
        navigate: 'cash-receive',
        type: 'child'
    },
    {
        name: 'leftMenuCP',
        icon: <AttachMoneyOutlinedIcon />,
        path: '/cash-payment',
        module: 'Accounting and Financial',
        navigate: 'cash-payment',
        type: 'child'
    },
    {
        name: 'leftMenuDBN',
        icon: <PaymentIcon />,
        path: '/debit-note',
        module: 'Accounting and Financial',
        navigate: 'bank-debit',
        type: 'child'
    },
    {
        name: 'leftMenuCRN',
        icon: <CreditCardIcon />,
        path: '/credit-note',
        module: 'Accounting and Financial',
        navigate: 'bank-credit',
        type: 'child'
    },
    {
        name: 'leftMenuCANA',
        icon: <PieChartOutlinedIcon />,
        path: '/cash-analysis',
        module: 'Accounting and Financial',
        navigate: 'cash-analysis',
        type: 'child'
    }
]

const amMenu2 = [
    {
        name: 'GENERAL LEDGER',
        icon: <CurrencyExchangeOutlinedIcon />,
        path: '/',
        module: 'Accounting and Financial',
        type: 'head'
    },
    {
        name: 'leftMenuJE',
        icon: <CreditCardIcon />,
        path: '/journal-entries',
        module: 'Accounting and Financial',
        navigate: 'journal-entries',
        type: 'child'
    },
    {
        name: 'leftMenuGL',
        icon: <InsertChartOutlinedOutlinedIcon />,
        path: '/general-ledger',
        module: 'Accounting and Financial',
        navigate: 'general-ledger',
        type: 'child'
    },
    {
        name: 'leftMenuAA',
        icon: <PieChartOutlinedIcon />,
        path: '/',
        module: 'Accounting and Financial',
        navigate: 'account-analysis',
        type: 'child'
    }
]

const amMenu3 = [
    {
        name: 'leftMenuACP',
        icon: <ShoppingCartCheckoutIcon />,
        path: '/',
        module: 'Accounting and Financial',
        navigate: 'account-payable',
        type: 'child'
    },
    {
        name: 'leftMenuACR',
        icon: <ProductionQuantityLimitsIcon />,
        path: '/',
        module: 'Accounting and Financial',
        navigate: 'account-receive',
        type: 'child'
    },
    {
        name: 'leftMenuINV',
        icon: <Inventory2OutlinedIcon />,
        path: '/',
        module: 'Accounting and Financial',
        navigate: 'inventories',
        type: 'child'
    },
    {
        name: 'leftMenuTax',
        icon: <PercentOutlined />,
        path: '/',
        module: 'Accounting and Financial',
        navigate: 'taxes',
        type: 'child'
    },
    {
        name: 'leftMenuASSET',
        icon: <ConstructionOutlinedIcon />,
        path: '/',
        module: 'Accounting and Financial',
        navigate: 'asset-equipment',
        type: 'child'
    }
]

const hdmMenu = [
    {
        name: 'leftMenuEmp',
        icon: <BadgeOutlinedIcon />,
        path: '/',
        module: 'HDM',
        navigate: 'employee',
        type: 'child'
    },
    {
        name: 'leftMenuTimeOff',
        icon: <MoreTimeOutlinedIcon />,
        path: '/',
        module: 'HDM',
        navigate: 'time-off',
        type: 'child'
    },
    {
        name: 'leftMenuReq',
        icon: <RateReviewOutlinedIcon />,
        path: '/',
        module: 'HDM',
        navigate: 'request',
        type: 'child'
    },
    {
        name: 'leftMenuContract',
        icon: <FilePresentOutlinedIcon />,
        path: '/',
        module: 'HDM',
        navigate: 'contract',
        type: 'child'
    },
    {
        name: 'leftMenuAchi',
        icon: <EmojiEventsOutlinedIcon />,
        path: '/',
        module: 'HDM',
        navigate: 'achievement',
        type: 'child'
    },
    {
        name: 'leftMenuSala',
        icon: <AttachMoneyOutlinedIcon />,
        path: '/',
        module: 'HDM',
        navigate: 'salary',
        type: 'child'
    },
    {
        name: 'leftMenuHReport',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'MF',
        navigate: 'hdm-report',
        type: 'child'
    }
]

const smMenu = [
    {
        name: 'leftMenuCustomer',
        icon: <PeopleAltOutlinedIcon />,
        path: '/',
        module: 'SM',
        navigate: 'customer',
        type: 'child'
    },
    {
        name: 'leftMenuLead',
        icon: <PeopleAltOutlinedIcon />,
        path: '/',
        module: 'SM',
        navigate: 'lead',
        type: 'child'
    },
    {
        name: 'leftMenuOpp',
        icon: <StarBorderOutlinedIcon />,
        path: '/',
        module: 'SM',
        navigate: 'opportunity',
        type: 'child'
    },
    {
        name: 'leftMenuDeal',
        icon: <ReceiptLongOutlinedIcon />,
        path: '/',
        module: 'SM',
        navigate: 'deal',
        type: 'child'
    },
    {
        name: 'leftMenuSOrder',
        icon: <ReceiptLongOutlinedIcon />,
        path: '/',
        module: 'SM',
        navigate: 'sm-order',
        type: 'child'
    },
    {
        name: 'leftMenuSPayment',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'SM',
        navigate: 'sm-payment',
        type: 'child'
    },
    {
        name: 'leftMenuSContract',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'SM',
        type: 'child'
    },
    {
        name: 'leftMenuSReport',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'MF',
        navigate: 'sm-report',
        type: 'child'
    }
]

const mfMenu = [
    {
        name: 'leftMenuMProcess',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'MF',
        navigate: 'mf-processing',
        type: 'child'
    },
    {
        name: 'leftMenuMProduct',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'MF',
        navigate: 'mf-product',
        type: 'child'
    },
    {
        name: 'leftMenuStock',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'MF',
        navigate: 'mf-stock',
        type: 'child'
    },
    {
        name: 'leftMenuMPlanning',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'MF',
        navigate: 'mf-planning',
        type: 'child'
    },
    {
        name: 'leftMenuMReport',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'MF',
        navigate: 'mf-report',
        type: 'child'
    }
]

const imMenu = [
    {
        name: 'leftMenuIInv',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'IM',
        navigate: 'im-inventory',
        type: 'child'
    },
    {
        name: 'leftMenuImport',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'IM',
        navigate: 'im-import',
        type: 'child'
    },
    {
        name: 'leftMenuMExport',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'IM',
        navigate: 'im-export',
        type: 'child'
    },
    {
        name: 'leftMenuITransfer',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'MF',
        navigate: 'im-transfer',
        type: 'child'
    },
    {
        name: 'leftMenuIReport',
        icon: <InsertChartOutlinedIcon />,
        path: '/',
        module: 'MF',
        navigate: 'im-report',
        type: 'child'
    }
]

const menuData = [
    ...amMenu1, ...workMenu, ...hdmMenu, ...smMenu, ...mfMenu, ...imMenu
]

const menuData1 = [
    ...amMenu2
]


const menuData2 = [...amMenu3]

export { menuData, menuData1, menuData2 };

