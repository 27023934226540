import { setTokenAxios } from "../api/index";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";
import { _showAlertError } from "../untils/error";


export const getAdditionalById = (id) => (dispatch) => {
  return setTokenAxios().get(`config-chance-stage/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`config-chance-stage`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_CONFIG_CHANCE_STAGE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch(async (err) => {
      await _showAlertError(err)
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`config-chance-stage`, { params: condition })
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch(async (err) => {
      await _showAlertError(err)
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}



export const createAdditional = (data) => (dispatch) => {
  return setTokenAxios().post(`config-chance-stage`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_CONFIG_CHANCE_STAGE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateAdditional = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`config-chance-stage/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_CONFIG_CHANCE_STAGE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}
