import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { teal } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { getAllData as getPermission } from '../../../actions/permission';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { find, findIndex } from 'lodash';
import { getPermissionGroupById, updatePermissionGroup } from '../../../actions/permissionGroup';
import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';

const initialState = {
    name: "",
    description: '',
    isActive: true,
    code: '',
    address: ''
};

function EditForm(props) {
    const theme = useTheme()
    const editObject = useSelector(state => state.edit)
    const [currentActions, setCurrentActions] = useState({})
    const [userPermission, setUserPermission] = useState([])

    const [
        { name, isActive, code, _id },
        setState
    ] = useState({ ...editObject });

    const getDataById = () => {
        props?.getPermissionGroupById(_id).then(res => {
            setUserPermission(res?.data?.details)
            setState(state => ({ ...state, ...res.data }))
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        const init = async () => {
            await props?.getPermission()
            getDataById()
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = () => {
        let data = { name, isActive, code, details: userPermission }
        props?.updatePermissionGroup(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }

    const detailPermission = (val) => {
        setCurrentActions({ ...val })
    }

    const choosePermission = (val) => {
        setCurrentActions({ ...val })
        let userPermissonTmp = [...userPermission]
        let index = findIndex(userPermissonTmp, { permissionId: val._id })
        let valTmp = { ...val }
        valTmp.permissionId = valTmp?._id
        if (index > -1) {
            userPermissonTmp.splice(index, 1)
        }
        else {
            userPermissonTmp.push(valTmp)
        }

        setUserPermission(userPermissonTmp)
    }

    const chooseActionPermisison = (val) => {
        let userPermissonTmp = [...userPermission]
        let index = findIndex(userPermissonTmp, { permissionId: currentActions._id })
        let actions = [...userPermissonTmp[index].actions]
        let indexAct = actions.indexOf(val)

        if (indexAct > -1) {
            actions.splice(indexAct, 1)
        }
        else {
            actions.push(val)
        }
        userPermissonTmp[index].actions = actions
        setUserPermission(userPermissonTmp)
    }

    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8 }}>
            <Stack direction="row" spacing={1}
                justifyContent='space-between'
                alignItems="center" sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.main
                }}>
                <Stack direction={'row'}>
                    <Chip label="Sửa" variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
                    <Typography variant='h5' color={'#fff'}>
                        Nhóm quyền
                    </Typography>
                </Stack>
                <Button variant='contained' disableElevation onClick={() => handleSubmit()}>
                    Lưu
                </Button>
            </Stack>

            <Box sx={{ paddingX: 2, paddingY: 4 }}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color={teal[500]}>
                            Thông tin cơ bản
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Tên"
                                    id="last-name"
                                    value={name}
                                    name="name"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    {/* <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Mã "
                                    id="code"
                                    value={code}
                                    name="code"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid> */}
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label="Kích hoạt" />
                        </FormGroup>
                    </Grid>


                    <Grid item xs={6} >

                        <Grid item xs={12}>
                            <Typography variant='h6' color={teal[500]} sx={{ mb: 2 }}>
                                Quyền ({userPermission.length}) 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ height: 280, overflowY: 'scroll' }}>
                            {props?.permission?.map((dt, i) => {
                                let uP = find(userPermission, { permissionId: dt?._id })
                                return <div
                                    style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12, flexDirection: 'row' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {findIndex(userPermission, { permissionId: dt._id }) > -1 ?
                                            <CheckBoxOutlinedIcon />
                                            : <CheckBoxOutlineBlankOutlinedIcon />}
                                        <div style={{ display: 'flex', marginLeft: 4, flexDirection: 'column' }}
                                            onClick={() => choosePermission(dt)}>
                                            <Typography variant='subtitle2'>{dt?.name}</Typography>
                                            <Typography variant='body2'>{dt?.modules}</Typography>
                                        </div>
                                    </div>
                                    <div onClick={() => detailPermission(dt)} style={{ cursor: 'pinter' }}>
                                        <Typography variant='body2' sx={{ color: '#00bcd4' }}>Chi tiết ({uP?.actions?.length})</Typography>
                                    </div>
                                </div>
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Typography variant='h6' color={teal[500]} sx={{ mb: 2, ml: 1 }}>
                                {currentActions?.name}
                            </Typography>
                        </Grid>
                        {currentActions?.actions?.map((dt, i) => {
                            let activePer = find(userPermission, { permissionId: currentActions?._id })
                            return <div
                                onClick={() => chooseActionPermisison(dt)}
                                style={{ display: 'flex', marginLeft: 12, marginBottom: 12, flexDirection: 'row' }}>
                                {activePer?.actions?.indexOf(dt) > -1 ?
                                    <CheckBoxOutlinedIcon />
                                    : <CheckBoxOutlineBlankOutlinedIcon />}
                                <Typography variant='subtitle2' style={{ marginLeft: 4 }}>{dt}</Typography>
                            </div>
                        })}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

const mapDispatchToProps = {
    getPermissionGroupById, updatePermissionGroup, getPermission,
}

const mapStateToProps = (state) => {
    return {
        editObj: state.edit,
        permission: state.permission,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);