import _ from 'lodash'
const initialState = {}

const currentChatReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_CURRENT_CHAT_MESSAGE":
            action.payload.messages = _.reverse(action.payload.messages)
            return action.payload

        case "ADD_NEW_CHAT_MESSAGE":
            var tmpCurr = { ...state }
            var currMessages = [...tmpCurr.messages]
            var messagesNew = [...currMessages, action.payload]
            tmpCurr.messages = messagesNew
            return tmpCurr

        case "CREATE_CURRENT_CHAT_MESSAGE":
            var tmp = { ...state }
            var newMessage = [...action.payload]
            newMessage = _.reverse(newMessage)
            var messages = [...newMessage, ...state.messages]
            tmp.messages = messages
            return tmp

        case "UPDATE_CURRENT_CHAT_MESSAGE":
            if (state.code === action.payload.groupChatCode) {
                let index = (state.messages).findIndex(per => per._id === action.payload._id)
                state.messages[index] = action.payload;
                return { ...state }
            }
            return { ...state }

        case "UPDATE_CURRENT_CHAT_MEMBER":
            if (state.code === action.payload.code) {
                state.memberArr = action.payload.memberArr;
                return { ...state }
            }
            return { ...state }

        case "DELETE_CURRENT_CHAT_MESSAGE":
            var data_deleted = []
            if (action.payload.length > 0) {
                data_deleted = state.filter(del => !(action.payload).includes(del._id));
            }
            return data_deleted


        default:
            break;
    }

    return state
}

export default currentChatReducer