import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const generateCode = () => (dispatch) => {
  return setTokenAxios().get(`product/generate-code`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data.code })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getProductById = (id) => (dispatch) => {
  return setTokenAxios().get(`product/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`product`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_PRODUCT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`product`, { params: condition })
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}



export const createProduct = (data) => (dispatch) => {
  return setTokenAxios().post(`product`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_PRODUCT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateProduct = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`product/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_PRODUCT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteProduct = (_id) => (dispatch) => {
  return setTokenAxios().delete(`product/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_PRODUCT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}