import { findIndex } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import MainChatPop from './MainChatPop';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDispatch } from 'react-redux';

const ItemMinimizePopChat = props => {

    const { data, profile, detailMinimizeChat, minimizeChatCode } = props
    const dispatch = useDispatch()

    let type = data?.groupChat?.type
    let name = ""
    if (type === "multiple") {
        name = data?.groupChat?.name
    }
    else {
        let partner = data?.groupChat?.memberArr.find(item => item?.userId?._id !== profile?._id)
        name = partner?.name
    }

    const hiddenChat = () => {
        //hidden
        dispatch({
            type: "DELETE_MINI_CHAT_CODE",
            payload: data?.groupChat?.code
        })
    }

    const clearChat = () => {

        dispatch({
            type: "DELETE_MINI_CHAT",
            payload: data?.groupChat?.code
        })

        dispatch({
            type: "DELETE_DETAIL_MINI_CHAT",
            payload: data?.groupChat?.code
        })

        //hidden
        dispatch({
            type: "DELETE_MINI_CHAT_CODE",
            payload: data?.groupChat?.code
        })

    }
    let isHidden = findIndex(minimizeChatCode, { code: data?.groupChat?.code })
    return (
        detailMinimizeChat?.length > 0 && <div className='itemMiniPopChat'>
            {isHidden > -1 ?
                <div className='headerPopChat'>
                    {name} {data?.groupChat?.code}
                    <div className='topToolChatHead'>
                        <div onClick={() => hiddenChat()}>
                            <ZoomOutMapOutlinedIcon fontSize='small' />
                        </div>
                        <div onClick={() => clearChat()}>
                            <CloseOutlinedIcon sx={{ fontSize: 20 }} />
                        </div>
                    </div>
                </div> :
                <MainChatPop data={data?.groupChat} />
            }


        </div>
    );
};

const mapDispatchToProps = {

}
const mapStateToProps = (state) => {

    return {
        chatUserOnline: state.chatUserOnline,
        profile: state.profile,
        currentChatMessage: state.currentChatMessage,
        detailMinimizeChat: state.detailMinimizeChat,
        minimizeChatCode: state.minimizeChatCode,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemMinimizePopChat);