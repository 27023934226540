import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormLabel, Grid, ListItemIcon, Menu, Popover, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import moment from 'moment';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllDataNoneRedux as getBeginBlance } from '../../../actions/beginningBalance';
import {
    getReportAllPaymentGroupDepartment, getReportAllPaymentGroupExpense,
    getReportCashBalanceReport,
    getReportCashPaymentGroup, getReportCashPaymentHistory
} from '../../../actions/report';

import ListItemText from '@mui/material/ListItemText';
import DrawerExpand from '../../elements/drawerExpand';
import { DetailForm } from './components/DetailForm';
import DetailFormGroup from './components/DetailFormGroup';
import { DetailFormGroupCondition } from './components/DetailFormGroupCondition';
import { TreeForm } from './components/TreeForm';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';

import { getAllDataNoneRedux as getAccountNumber } from '../../../actions/accountant';
import { getAllDataNoneRedux as getDepartment } from '../../../actions/department';
import { getAllDataNoneRedux as getExpense } from '../../../actions/expense';
import { getAllDataNoneRedux as getPartner } from '../../../actions/partner';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { filter, isEmpty, map, orderBy, uniq, uniqBy } from 'lodash';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllChildrenAccount } from '../../../untils/functions';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import ContactLst from '../../elements/ContactLst';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const arrayToTreeCustome = (items, code = '', link = 'parentCode') =>
    items
        .filter(item => item[link] === code)
        .map(item => ({ ...item, children: arrayToTreeCustome(items, item.code) }));

const Main = (params) => {
    const { props } = params
    let navigate = useNavigate();
    const { t } = useTranslation()
    const search = useLocation().search;
    const idActive = new URLSearchParams(search).get("active");
    const [openFormCashInHand, setOpenFormCashInHand] = React.useState(false);
    const [openFormCashInHandBalance, setOpenFormCashInHandBalance] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [data, setData] = React.useState([]);
    const [active, setActive] = React.useState(null);
    const [title, setTitle] = React.useState(null);
    const [accountFormDetail, setAccountFormDetail] = React.useState([]);
    const [listAccount, setListAccount] = React.useState([]);
    const [listDepartment, setListDepartment] = React.useState([]);
    const [listExpense, setListExpense] = React.useState([]);
    const [department, setDepartment] = useState({})
    const [expense, setExpense] = useState({})
    const [partner, setPartner] = useState({})
    const [groupByType, setGroupByType] = useState('all')
    const [anchorElSearch, setAnchorElSearch] = React.useState(null);
    const [anchorElMonth, setAnchorElMonth] = React.useState(null);
    const [anchorElQuarter, setAnchorElQuarter] = React.useState(null);

    const [debitSearch, setDebitSearch] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [sortType, setSortType] = useState('')
    const [departmentSearch, setDepartmentSearch] = useState('')
    const [creditSearch, setCreditSearch] = useState('')
    const [expenseSearch, setExpenseSearch] = useState('')

    const [treeAccount, setTreeAccount] = React.useState([]);
    const [activeAccount, setActiveAccount] = React.useState();
    const [isPrint, setPrint] = React.useState(false);
    const componentPrintRef = React.useRef();


    const [startDate, setStartDate] = React.useState(dayjs(moment().startOf('month').format('YYYY-MM-DD')));
    const [endDate, setEndDate] = React.useState(dayjs(moment().endOf('day').format('YYYY-MM-DD')));


    const dispatch = useDispatch()

    const [beginBlance, setBeginBlance] = useState([])

    const openSearch = (event) => {
        setAnchorElSearch(event.currentTarget);
    };

    const closeSearch = () => {
        setAnchorElSearch(null);
    };

    const getReportCashPaymentGroup = async (cond) => {
        return await props?.getReportCashPaymentGroup(cond)
            .then(res => {
                return res.data
            })
    }

    const getReportCashPaymentHistory = async (cond) => {
        return await props?.getReportCashPaymentHistory(cond)
            .then(res => {
                return res.data
            })
    }

    const getReportCashBalanceReport = async (cond) => {
        return await props?.getReportCashBalanceReport(cond)
            .then(res => {
                return res.data
            })
    }

    const getBeginBlance = async () => {
        let cond = {
            condition: {
                year: { equal: '2023' }
            }
        }
        await props?.getBeginBlance(cond).then(res => {
            if (res?.data?.length > 0) {
                setBeginBlance(res?.data)
            }
        })
    }

    useEffect(() => {
        handleCloseMenu(Number(idActive))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idActive])

    useEffect(() => {
        getBeginBlance()
        const init = async () => {
            let condition = {
                condition:
                {
                    // isFinal: { equal: true },
                    arrayType: {
                        in: ['bank']
                    }
                },
                limit: 1000
            }
            await props?.getAccountNumber(condition).then(res => {
                let tree = arrayToTreeCustome(res.data)
                setListAccount(res.data)
                setTreeAccount(tree)
            })
            await props?.getDepartment({ condition: {}, limit: 100 }).then(res => setListDepartment(res.data))
            await props?.getExpense({ condition: {}, limit: 100 }).then(res => setListExpense(res.data))
            // await props?.getPartner({ condition: {}, limit: 20 }).then(res => setListPartner(res.data))

            dispatch({
                type: "SET_ENUM",
                payload: {
                    openDrawer: false
                }
            })
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getGroupByExpense = async (cond) => {
        return await props?.getReportAllPaymentGroupExpense(cond)
            .then(res => {
                return res.data
            })
    }

    const getGroupByDepartment = async (cond) => {
        return await props?.getReportAllPaymentGroupDepartment(cond)
            .then(res => {
                return res.data
            })
    }

    const clearCondition = () => {
        setDepartment({})
        setExpense({})
        setPartner({})
    }

    const handleCashHistory = async () => {
        let cond = {
            condition: {}
        }

        if (startDate !== '' && endDate !== '') {
            cond.condition.date = {
                "from": startDate,
                "to": endDate
            }
        }

        if (!isEmpty(partner)) {
            cond.condition.partnerCode = partner?.code
        }

        if (!isEmpty(expense)) {
            cond.condition.expenseCode = expense?.code
        }

        if (!isEmpty(department)) {
            cond.condition.departmentCode = department?.code
        }

        cond.condition.accountNumber = activeAccount

        cond.condition.paymentType = {
            in: ['CRB', 'DRB']
        }

        let data = []
        switch (groupByType) {
            case 'groupByDepartment':
                data = await getGroupByDepartment(cond)
                break;

            case 'groupByExpense':
                data = await getGroupByExpense(cond)
                break;

            default:
                data = await getReportCashPaymentHistory(cond)
                break;
        }
        setData(data)
        setTitle('Bank book')
        setAccountFormDetail(activeAccount)
    }

    const handleCashLedger = async () => {
        let cond = {
            condition: {}
        }

        if (startDate !== '' && endDate !== '') {
            cond.condition.date = {
                "from": startDate,
                "to": endDate
            }
        }
        if (!isEmpty(partner)) {
            cond.condition.partnerCode = partner?.code
        }

        if (!isEmpty(expense)) {
            cond.condition.expenseCode = expense?.code
        }

        if (!isEmpty(department)) {
            cond.condition.departmentCode = department?.code
        }
        cond.condition.accountNumber = activeAccount
        cond.condition.paymentType = {
            in: ['CRB', 'DRB']
        }

        let data = await getReportCashPaymentGroup(cond)
        setData(data)
        setTitle('Bank summary')
        setAccountFormDetail(activeAccount)
    }

    const handleCashInHandReceive = async () => {
        let cond = {
            condition: {}
        }

        if (startDate !== '' && endDate !== '') {
            cond.condition.date = {
                "from": startDate,
                "to": endDate
            }
        }

        cond.condition.paymentType = {
            equal: "DRB"
        }

        cond.condition.accountNumber = activeAccount

        let data = await getReportCashPaymentGroup(cond)
        setData(data)
        setTitle('Debit note')
        setAccountFormDetail(activeAccount)
    }

    const handleCashInHandPayment = async () => {
        let cond = {
            condition: {}
        }

        if (startDate !== '' && endDate !== '') {
            cond.condition.date = {
                "from": startDate,
                "to": endDate
            }
        }

        cond.condition.paymentType = {
            equal: "CRB"
        }

        cond.condition.accountNumber = activeAccount

        let data = await getReportCashPaymentGroup(cond)
        setData(data)
        setTitle('Credit note')
        setAccountFormDetail(activeAccount)
    }

    const handleCashBalance = async () => {
        let cond = {
            condition: {}
        }

        if (startDate !== '' && endDate !== '') {
            cond.condition.date = {
                "from": startDate,
                "to": endDate
            }
        }

        cond.condition.paymentType = {
            equal: "CRB"
        }

        let child = getAllChildrenAccount(treeAccount, '102')
        let account = map(child, 'code')

        cond.condition.accountNumber = account

        let data = await getReportCashBalanceReport(cond)

        setData(data)
        setTitle('Bank balance')
        setAccountFormDetail(['102'])
    }

    const setOpenType = (active, openFormCashInHand, openFormCashInHandBalance) => {
        setActive(active)
        clearCondition()
        if (openFormCashInHandBalance) {
            setOpenFormCashInHand('')
            setOpenFormCashInHandBalance(openFormCashInHandBalance)
            if (isEmpty(activeAccount))
                setActiveAccount(['102'])
        }
        else {
            setOpenFormCashInHand(openFormCashInHand)
            setOpenFormCashInHandBalance('')
            if (isEmpty(activeAccount))
                setActiveAccount(["10211", "10212", "10221", "10222", "10223", "10224"])
        }
    }

    const handleFilter = async () => {
        let val = ''
        if (openFormCashInHandBalance) {
            val = openFormCashInHandBalance
        }
        else {
            val = openFormCashInHand
        }

        switch (val) {
            case 'handleCashLedger':
                handleCashLedger()
                setOpenFormCashInHand(false)
                setOpenFormCashInHandBalance(false)
                break;

            case 'handleCashHistory':
                handleCashHistory()
                setOpenFormCashInHand(false)
                setOpenFormCashInHandBalance(false)
                break;
            case 'handleCashInHandReceive':
                handleCashInHandReceive()
                setOpenFormCashInHand(false)
                setOpenFormCashInHandBalance(false)
                break;

            case 'handleCashInHandPayment':
                handleCashInHandPayment()
                setOpenFormCashInHand(false)
                setOpenFormCashInHandBalance(false)
                break;

            case 'handleCashBalance':
                handleCashBalance()
                setOpenFormCashInHand(false)
                setOpenFormCashInHandBalance(false)
                break;
            default:
                break;
        }
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setActiveAccount(
            typeof value === 'string' ? value.split(',') : value,
        );

    };


    function getQuarterRange(quarter) {
        const start = moment().quarter(quarter).startOf('quarter').format('YYYY-MM-DD');
        const end = moment().quarter(quarter).endOf('quarter').format('YYYY-MM-DD');
        return { start, end };
    }

    const setDateRange = (key) => {
        let start = startDate
        let end = endDate
        switch (key) {
            case 1:
                start = dayjs(moment().startOf('month').format('YYYY-MM-DD'))
                end = dayjs(moment().endOf('month').format('YYYY-MM-DD'))
                setStartDate(start)
                setEndDate(end)
                break;

            case 2:
                start = dayjs(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
                end = dayjs(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
                setStartDate(start)
                setEndDate(end)
                break;

            case 3:
                let qua = getQuarterRange(1)
                start = dayjs(qua.start)
                end = dayjs(qua.end)
                setStartDate(start)
                setEndDate(end)
                break;

            case 4:
                let qua2 = getQuarterRange(2)
                start = dayjs(qua2.start)
                end = dayjs(qua2.end)
                setStartDate(start)
                setEndDate(end)
                break;

            case 5:
                let qua3 = getQuarterRange(3)
                start = dayjs(qua3.start)
                end = dayjs(qua3.end)
                setStartDate(start)
                setEndDate(end)
                break;

            case 6:
                let qua4 = getQuarterRange(4)
                start = dayjs(qua4.start)
                end = dayjs(qua4.end)
                setStartDate(start)
                setEndDate(end)
                break;


            case 7:
                start = dayjs(moment().subtract(30, 'days').startOf('month').format('YYYY-MM-DD'))
                end = dayjs(moment().subtract(30, 'days').endOf('month').format('YYYY-MM-DD'))
                setStartDate(start)
                setEndDate(end)
                break;

            case 8:
                start = dayjs(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'))
                end = dayjs(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
                setStartDate(start)
                setEndDate(end)
                break;

            case 9:
                start = dayjs(moment().startOf('year').format('YYYY-MM-DD'))
                end = dayjs(moment().endOf('day').format('YYYY-MM-DD'))
                setStartDate(start)
                setEndDate(end)
                break;

            default:
                break;
        }

    }

    const setMonthType = (key) => {
        let start = startDate
        let end = endDate
        start = dayjs(moment().set('month', key - 1).startOf('month').format('YYYY-MM-DD'))
        end = dayjs(moment().set('month', key - 1).endOf('month').format('YYYY-MM-DD'))
        setStartDate(start)
        setEndDate(end)
    }

    const renderMonth = () => {
        return <Popover
            id={Boolean(anchorElMonth) ? 'simple-popover' : undefined}
            open={Boolean(anchorElMonth)}
            anchorEl={anchorElMonth}
            onClose={() => setAnchorElMonth(null)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <div className='listMonthChoose'>
                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
                        return <div onClick={() => setMonthType(item)}>{item}</div>
                    })
                }
            </div>
        </Popover>
    }

    const renderQuarter = () => {
        return <Popover
            id={Boolean(anchorElQuarter) ? 'simple-popover' : undefined}
            open={Boolean(anchorElQuarter)}
            anchorEl={anchorElQuarter}
            onClose={() => setAnchorElQuarter(null)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <div className='listMonthChoose'>
                {
                    [3, 4, 5, 6].map((item, ind) => {
                        return <div onClick={() => setDateRange(item)}>{ind + 1}</div>
                    })
                }
            </div>
        </Popover>
    }

    const handleChangeGroup = (event) => {
        setGroupByType(event.target.value);
    };

    const _handleSelectContact = (val) => {
        setPartner(val)
    }

    const renderFormFilter = () => {
        return <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={openFormCashInHand ? true : false}
            onClose={() => setOpenFormCashInHand(false)}>
            <DialogTitle color="primary">Information</DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Grid container spacing={2} p={3} pt={0} pb={0}>

                    <Grid item xs={3}>
                        <Button variant='text' onClick={() => setDateRange(1)}>This month</Button>
                        <Button variant='text' onClick={() => setDateRange(2)}>Previous month</Button>
                        <Button variant='text' onClick={(e) => setAnchorElMonth(e.currentTarget)}>Month   <ArrowDropDownIcon fontSize='small' /></Button>
                        <Button variant='text' onClick={(e) => setAnchorElQuarter(e.currentTarget)}>Quarter
                            <ArrowDropDownIcon fontSize='small' />
                        </Button>
                        <Button variant='text' onClick={() => setDateRange(7)}>30 days ago</Button>
                        <Button variant='text' onClick={() => setDateRange(8)}>2 months ago</Button>
                        <Button variant='text' onClick={() => setDateRange(9)}>Beginning of the year</Button>
                        {renderQuarter()}
                        {renderMonth()}
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={2} p={3} pt={0} pb={0}>
                            <Typography variant='body2' width={'100%'} sx={{ marginTop: 1.5, marginLeft: 1.5 }}>Transaction Date:</Typography>
                            <Grid item xs={12} style={{ display: 'flex', paddingTop: 0 }}>
                                <Grid item xs={6} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker format={'DD/MM/YYYY'}
                                                label="From"
                                                value={dayjs(startDate)}
                                                slotProps={{ textField: { size: 'small' } }}
                                                onChange={(newValue) => setStartDate(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={6} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker format={'DD/MM/YYYY'}
                                                label="To"
                                                value={dayjs(endDate)}
                                                slotProps={{ textField: { size: 'small' } }}
                                                onChange={(newValue) => setEndDate(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body2' >Account Number:</Typography>
                                <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        size='small'
                                        value={activeAccount}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}>
                                        {filter(listAccount, { isFinal: true }).map((item) => (
                                            <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                                <Checkbox checked={activeAccount.indexOf(item.code) > -1 ? 'checked' : ''} />
                                                <ListItemText primary={`${item.code} - ${item.name}`} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {[0, 1, 2, 3].indexOf(active) > -1 ?
                                <>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            freeSolo
                                            id="free-solo-department"
                                            disableClearable
                                            clearOnBlur
                                            value={department}
                                            onChange={(event, newValue) => {
                                                if (newValue === null) {
                                                    setDepartment({})
                                                }
                                                else {
                                                    setDepartment(newValue)
                                                }
                                            }}
                                            size="small"
                                            options={listDepartment}
                                            getOptionLabel={(option) => !isEmpty(option?.code) ? `${option?.code} - ${option?.name}` : ''}
                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Department"
                                                    sx={{
                                                        '& .MuiTextField-root': { display: 'flex' },
                                                        '& .MuiInput-root': { mt: 1.5 }
                                                    }}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            freeSolo
                                            id="free-solo-expense"
                                            disableClearable
                                            value={expense}
                                            onChange={(event, newValue) => {
                                                if (newValue === null) {
                                                    setExpense({})
                                                }
                                                else {
                                                    setExpense(newValue)
                                                }
                                            }}
                                            size="small"
                                            options={listExpense}
                                            getOptionLabel={(option) => !isEmpty(option?.code) ? `${option?.code} - ${option?.name}` : ''}
                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Expense"
                                                    sx={{
                                                        '& .MuiTextField-root': { display: 'flex' },
                                                        '& .MuiInput-root': { mt: 1.5 }
                                                    }}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ marginTop: 1, display: 'flex', flexDirection: 'row' }}>
                                            <ContactLst selectContact={_handleSelectContact} contact={partner} />
                                            <Typography>{partner?.firstName} {partner?.lastName}</Typography>
                                        </Box>
                                    </Grid>
                                </> : <></>
                            }

                            {[0].indexOf(active) > -1 ?
                                <Grid item xs={12} mt={2}>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">Group by:</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="none"
                                            name="radio-buttons-group"
                                            value={groupByType}
                                            onChange={handleChangeGroup}
                                        >
                                            <FormControlLabel value="all" control={<Radio />} label="All" />
                                            <FormControlLabel value="groupByExpense" control={<Radio />} label="Expense" />
                                            <FormControlLabel value="groupByDepartment" control={<Radio />} label="Department" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid> : <></>
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenFormCashInHand(false)}>Close</Button>
                <Button onClick={() => handleFilter()}>Accept</Button>
            </DialogActions>
        </Dialog>
    }

    const renderFormFilterBalance = () => {
        return <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={openFormCashInHandBalance ? true : false}
            onClose={() => setOpenFormCashInHandBalance(false)}>
            <DialogTitle color="primary">Information</DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Grid container spacing={2} p={3} pt={0} pb={0}>
                    <Grid item xs={3}>
                        <Button variant='text' onClick={() => setDateRange(1)}>This month</Button>
                        <Button variant='text' onClick={() => setDateRange(2)}>Previous month</Button>
                        <Button variant='text' onClick={(e) => setAnchorElMonth(e.currentTarget)}>Month   <ArrowDropDownIcon fontSize='small' /></Button>
                        <Button variant='text' onClick={(e) => setAnchorElQuarter(e.currentTarget)}>Quarter
                            <ArrowDropDownIcon fontSize='small' />
                        </Button>
                        <Button variant='text' onClick={() => setDateRange(7)}>30 days ago</Button>
                        <Button variant='text' onClick={() => setDateRange(8)}>2 months ago</Button>
                        <Button variant='text' onClick={() => setDateRange(9)}>Beginning of the year</Button>
                        {renderQuarter()}
                        {renderMonth()}
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={2} p={3} pt={0} pb={0}>
                            <Typography variant='body2' width={'100%'} sx={{ marginTop: 1.5, marginLeft: 1.5 }}>Transaction Date:</Typography>
                            <Grid item xs={12} style={{ display: 'flex', paddingTop: 0 }}>
                                <Grid item xs={6} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker format={'DD/MM/YYYY'}
                                                label="From"
                                                value={dayjs(startDate)}
                                                slotProps={{ textField: { size: 'small' } }}
                                                onChange={(newValue) => setStartDate(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={6} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker format={'DD/MM/YYYY'}
                                                label="To"
                                                value={dayjs(endDate)}
                                                slotProps={{ textField: { size: 'small' } }}
                                                onChange={(newValue) => setEndDate(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2' >Account Number:</Typography>
                                <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        size='small'
                                        value={activeAccount}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}>
                                        {filter(listAccount, { parentCode: '' }).map((item) => (
                                            <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                                <Checkbox checked={activeAccount.indexOf(item.code) > -1 ? 'checked' : ''} />
                                                <ListItemText primary={`${item.code} - ${item.name}`} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenFormCashInHandBalance(false)}>Close</Button>
                <Button onClick={() => handleFilter()}>Accept</Button>
            </DialogActions>
        </Dialog>
    }

    const handleClosePrint = (val) => {
        setPrint(val)
    }

    const renderDetail = () => {
        let starTmp = dayjs(startDate).format('DD/MM/YYYY')
        let endTmp = dayjs(endDate).format('DD/MM/YYYY')
        switch (active) {
            case 0:
                if (!isEmpty(groupByType) && groupByType !== 'all') {
                    return <DetailFormGroupCondition data={data} beginBlance={beginBlance}
                        title={title}
                        accountNumber={listAccount.filter(item => accountFormDetail.indexOf(item.code) > -1)} //listAccount
                        condition={{ sortBy, sortType, debitSearch, creditSearch, departmentSearch, expenseSearch }}
                        partner={partner}
                        department={department}
                        isPrint={isPrint}
                        handleClosePrint={handleClosePrint}
                        ref={componentPrintRef}
                        expense={expense}
                        startDate={starTmp} endDate={endTmp}
                    />
                }

                return <DetailFormGroup data={data} beginBlance={beginBlance}
                    title={title}
                    accountNumber={listAccount.filter(item => accountFormDetail.indexOf(item.code) > -1)} //listAccount
                    condition={{ sortBy, sortType, debitSearch, creditSearch, departmentSearch, expenseSearch }}
                    partner={partner}
                    department={department}
                    isPrint={isPrint}
                    handleClosePrint={handleClosePrint}
                    ref={componentPrintRef}
                    expense={expense}
                    startDate={starTmp} endDate={endTmp}
                />

            case 1:
                return <DetailForm data={data} beginBlance={beginBlance}
                    partner={partner}
                    department={department}
                    expense={expense}
                    ref={componentPrintRef}
                    isPrint={isPrint}
                    handleClosePrint={handleClosePrint}
                    title={title}
                    accountNumber={listAccount.filter(item => accountFormDetail.indexOf(item.code) > -1)} //listAccount
                    condition={{ sortBy, sortType, debitSearch, creditSearch, departmentSearch, expenseSearch }}
                    startDate={starTmp} endDate={endTmp}
                />
            case 2:
                return <DetailForm data={data} beginBlance={beginBlance}
                    partner={partner}
                    department={department}
                    ref={componentPrintRef}
                    expense={expense}
                    isPrint={isPrint}
                    handleClosePrint={handleClosePrint}
                    title={title}
                    accountNumber={listAccount.filter(item => accountFormDetail.indexOf(item.code) > -1)} //listAccount
                    condition={{ sortBy, sortType, debitSearch, creditSearch, departmentSearch, expenseSearch }}
                    startDate={starTmp} endDate={endTmp}
                />
            case 3:
                return <DetailForm data={data} beginBlance={beginBlance}
                    partner={partner}
                    department={department}
                    ref={componentPrintRef}
                    expense={expense}
                    isPrint={isPrint}
                    handleClosePrint={handleClosePrint}
                    title={title}
                    accountNumber={listAccount.filter(item => accountFormDetail.indexOf(item.code) > -1)} //listAccount
                    condition={{ sortBy, sortType, debitSearch, creditSearch, departmentSearch, expenseSearch }}
                    startDate={starTmp} endDate={endTmp}
                />
            case 4:
                return <TreeForm data={data || []} beginBlance={beginBlance}
                    partner={partner}
                    department={department}
                    ref={componentPrintRef}
                    isPrint={isPrint}
                    handleClosePrint={handleClosePrint}
                    expense={expense}
                    title={title} accountNumber={listAccount.filter(item => accountFormDetail.indexOf(item.code) > -1)}
                    tree={treeAccount}
                    startDate={starTmp} endDate={endTmp}
                />
            default:
                break;
        }

    }

    const handleMenu = (event) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    const handleCloseMenu = (key) => {
        setAnchorEl(null);
        switch (key) {
            case 0:
                setOpenType(0, 'handleCashHistory', '');
                break;
            case 1:
                setOpenType(1, 'handleCashLedger', '')
                break;
            case 2:
                setOpenType(2, 'handleCashInHandReceive', '')
                break;
            case 3:
                setOpenType(3, 'handleCashInHandPayment', '')
                break;
            case 4:
                setOpenType(4, '', 'handleCashBalance')
                break;
            case 5:
                navigate("/report?active=0");
                break;
            case 6:
                navigate("/report?active=1");
                break;
            case 7:
                navigate("/report?active=2");
                break;
            case 8:
                navigate("/report?active=3");
                break;
            case 9:
                navigate("/report?active=4");
                break;
            default:
                break;
        }
    }

    const handlePrint = () => {
        setPrint(true)
        // actionPrint()
    }

    const handleChangeSearch = (key, e) => {
        switch (key) {
            case 'creditSearch':
                setCreditSearch(e.target.value)
                break;

            case 'debitSearch':
                setDebitSearch(e.target.value)
                break;

            case 'expenseSearch':
                setExpenseSearch(e.target.value)
                break;

            case 'departmentSearch':
                setDepartmentSearch(e.target.value)
                break;

            default:
                break;
        }
    }

    const clearSearch = () => {
        setSortBy('')
        setSortType('')
        setCreditSearch('')
        setDebitSearch('')
        setExpenseSearch('')
        setDepartment('')
    }

    const renderSearch = () => {
        return <Popover
            id={Boolean(anchorElSearch) ? 'simple-popover' : undefined}
            open={Boolean(anchorElSearch)}
            anchorEl={anchorElSearch}
            onClose={closeSearch}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }} >
            <Box p={1.5} sx={{ minWidth: 560, maxWidth: 560 }}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Sort by</FormLabel>
                            <RadioGroup row
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                            >
                                <FormControlLabel value="none" control={<Radio />} label="None" />
                                <FormControlLabel value="debit" control={<Radio />} label="Debit amount" />
                                <FormControlLabel value="credit" control={<Radio />} label="Credit amount" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">.</FormLabel>
                            <RadioGroup row
                                value={sortType}
                                onChange={(e) => setSortType(e.target.value)}
                            >
                                <FormControlLabel value="asc" control={<Radio disabled={isEmpty(sortBy) ? true : false} />} label="Ascending" />
                                <FormControlLabel value="desc" control={<Radio disabled={isEmpty(sortBy) ? true : false} />} label="Descending" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant='body2' >Debit Account</Typography>
                        <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                size='small'
                                value={debitSearch}
                                onChange={(e) => handleChangeSearch('debitSearch', e)}>

                                {uniq(map(data, 'accountNumberDebit')).map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item}>
                                        {item}
                                    </MenuItem>
                                ))}

                                <MenuItem
                                    key={'blank'}
                                    value={''}>
                                    Blank
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body2' >Credit Account</Typography>
                        <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                size='small'
                                value={creditSearch}
                                onChange={(e) => handleChangeSearch('creditSearch', e)}>

                                {uniq(map(data, 'accountNumberCredit')).map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                                <MenuItem
                                    key={'blank'}
                                    value={''}>
                                    Blank
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {
                        [0].indexOf(active) > -1 &&
                        <>

                            <Grid item xs={6} mt={1}>
                                <Typography variant='body2' >Department</Typography>
                                <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        size='small'
                                        value={departmentSearch}
                                        onChange={(e) => handleChangeSearch('departmentSearch', e)}>

                                        {orderBy(uniqBy(map(data, 'department'), 'code'), ['code'], ['asc']).map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={isEmpty(item?.code) ? '' : item?.code}>
                                                {isEmpty(item?.code) ? `Blank` : `${item?.code} ${item?.name}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} mt={1}>
                                <Typography variant='body2' >Expense</Typography>
                                <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        size='small'
                                        value={expenseSearch}
                                        onChange={(e) => handleChangeSearch('expenseSearch', e)}>

                                        {orderBy(uniqBy(map(data, 'expense'), 'code'), ['code'], ['asc']).map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={isEmpty(item?.code) ? '' : item?.code}>
                                                {isEmpty(item?.code) ? `Blank` : `${item?.code} ${item?.name}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    }


                </Grid>
                {!isEmpty(sortBy) || !isEmpty(creditSearch) || !isEmpty(debitSearch) || !isEmpty(expenseSearch) || !isEmpty(departmentSearch) ?
                    <Button
                        onClick={clearSearch}
                        variant='text' sx={{ mt: 2 }}> Clear</Button> : <></>
                }
            </Box>
        </Popover>
    }

    return (
        <>
            {renderFormFilter()}
            {renderFormFilterBalance()}

            <Box sx={{
                minHeight: 48,
                borderRadius: 2,
                background: '#fff',
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: 4
            }}>
                <div>
                    <Button variant='text' href="/cash-analysis"
                        sx={{ padding: '6px 14px' }}
                        startIcon={<DataSaverOffOutlinedIcon />}
                        className='tabAnalysis'>Analysis</Button>


                    <Button variant='text' className='tabAnalysis active'
                        sx={{ padding: '6px 14px' }}
                        startIcon={<AssessmentOutlinedIcon />}
                        aria-owns={anchorEl ? "simple-menu" : undefined}
                        aria-haspopup="true"
                        endIcon={<ArrowDropDownIcon fontSize='small' />}
                        onMouseOver={handleMenu}>{t('lblReport')}</Button>

                </div>

                <Box style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
                    <Box sx={{ marginLeft: 3 }} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Typography variant='h6' sx={{ marginRight: 3 }}>{title}</Typography>
                        <Typography variant='subtitle2'>{dayjs(startDate).format('DD/MM/YYYY')} - {dayjs(endDate).format('DD/MM/YYYY')}</Typography>
                    </Box>
                    {/* <Typography sx={{ marginLeft: 3 }} variant='caption'>{
                        listAccount.filter(item => accountFormDetail.indexOf(item.code) > -1)?.map(item => {
                            return <Chip size='small' sx={{ mr: 1 }} label={item.code} />
                        })
                    }</Typography> */}

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <div className='contentRightTopReport'>
                            <Button startIcon={<FilterListOutlinedIcon />} onClick={() => handleCloseMenu(active)} variant="text">FILTER</Button>
                            <Button startIcon={<LocalPrintshopOutlinedIcon />} onClick={handlePrint} sx={{ ml: 2 }} variant="text">PRINT</Button>
                            <Button startIcon={<FileDownloadOutlinedIcon />} variant="text" sx={{ ml: 2 }}>DOWNLOAD</Button>
                            <div className='btnTunel' onClick={(e) => openSearch(e)}>
                                <TuneRoundedIcon size="small" />
                            </div>
                        </div>
                    </div>
                </Box>
            </Box >

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                MenuListProps={{ onMouseLeave: handleCloseMenu }}>
                <MenuItem
                    sx={{ width: 250 }}
                    onClick={() => handleCloseMenu(0)}>
                    <ListItemIcon>
                        <DataUsageOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Bank flow"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseMenu(1)}>
                    <ListItemIcon>
                        <SummarizeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Bank summary"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseMenu(2)}>
                    <ListItemIcon>
                        <SaveAltOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Debit note"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseMenu(3)}>
                    <ListItemIcon>
                        <FileUploadOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Credit note"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseMenu(4)}>
                    <ListItemIcon>
                        <CurrencyExchangeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Bank balance"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>


                <div style={{ height: 0.5, backgroundColor: '#00000014', width: '100%' }}></div>
                <MenuItem
                    sx={{ width: 250 }}
                    onClick={() => handleCloseMenu(5)}>
                    <ListItemIcon>
                        <DataUsageOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashFlow')}
                        secondary={t('lblRpBriefCashFlow')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseMenu(6)}>
                    <ListItemIcon>
                        <SummarizeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashsum')}
                        secondary={t('lblRpBriefCashsum')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseMenu(7)}>
                    <ListItemIcon>
                        <SaveAltOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashInFlow')}
                        secondary={t('lblRpBriefCashInFlow')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseMenu(8)}>
                    <ListItemIcon>
                        <FileUploadOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashOutFlow')}
                        secondary={t('lblRpBriefCashOutFlow')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseMenu(9)}>
                    <ListItemIcon>
                        <CurrencyExchangeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashBal')}
                        secondary={t('lblRpBriefCashBal')}
                    />
                </MenuItem>
            </Menu>

            <Box sx={{}}>
                <Grid container>
                    <Grid item xs={12} sx={{ position: 'relative' }}>
                        {active !== null ?
                            <>
                                {renderSearch()}
                                {renderDetail()}
                            </>
                            : ""}
                    </Grid>

                </Grid>
            </Box>
        </>

    );
};


const Reporting = (props) => {
    let title = "Reporting"
    document.title = title

    return (
        <>
            <DrawerExpand headerText={title}>
                <Main props={props} />
            </DrawerExpand>
        </>
    );
};

const mapDispatchToProps = {
    getReportCashPaymentGroup,
    getReportCashBalanceReport,
    getReportCashPaymentHistory, getBeginBlance, getAccountNumber,
    getReportAllPaymentGroupDepartment, getReportAllPaymentGroupExpense,
    getDepartment,
    getExpense,
    getPartner
}

export default connect(null, mapDispatchToProps)(Reporting);