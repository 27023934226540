import { findIndex, map } from "lodash"

const initialState = []

const journalEntrieReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_JOURNAL_ENTRIE":
            return action.payload

        case "CREATE_JOURNAL_ENTRIE":
            return [action.payload, ...state]

        case "SET_MORE_JOURNAL_ENTRIE":
            return [...state, ...action.payload]

        case "UPDATE_JOURNAL_ENTRIE":

            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;

            return [...state]

        case "CHANGE_PASSWORD":

            const password = state.findIndex(per => per._id === action.payload._id)
            state[password] = action.payload;

            return [...state]

        case "UPDATE_JOURNAL_ENTRIE_MULTI":
            let dataNew = [...action.payload]
            let stateM = [...state]
            var newArr = map(stateM, function (v, k) {
                let ind = findIndex(dataNew, { _id: v._id })
                if (ind > -1) {
                    return { ...dataNew[ind] };
                }
                return v;
            });
            return [...newArr]


        case "UPDATE_JOURNAL_ENTRIE_ACTIVE":
            const journalEntrie_ = [...state]
            if (action.payload.length > 0) {
                (action.payload).forEach((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    journalEntrie_[index] = item;
                })
            }
            return journalEntrie_

        case "SET_CURRENT_JOURNAL_ENTRIE_IMG":
            return [...state]

        case "DELETE_JOURNAL_ENTRIE":
            const journalEntrie_old = [...state]
            var journalEntrie_deleted = []

            if (action.payload.length > 0) {
                journalEntrie_deleted = journalEntrie_old.filter(del => !(action.payload).includes(del._id));
            }
            return journalEntrie_deleted


        default:
            break;
    }

    return state
}

export default journalEntrieReducer