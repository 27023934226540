const initialState = []

const docCommentReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_DOC_COMMENT":
            return action.payload
            
        case "CREATE_DOC_COMMENT":
            return [ action.payload, ...state]

        case "UPDATE_DOC_COMMENT":
            
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            
            return [...state]

        case "UPDATE_DOC_COMMENT_ACTIVE":
            const docComment_ = [...state] 
            if(action.payload.length > 0)
            {
                (action.payload).forEach((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    docComment_[index] = item;
                })
            }
            return docComment_

        case "SET_CURRENT_DOC_COMMENT_IMG":
                return [...state]

        case "DELETE_DOC_COMMENT":
            const docComment_old = [...state] 
            var docComment_deleted = []
           
            if(action.payload.length > 0)
            {
                docComment_deleted = docComment_old.filter(del =>!(action.payload).includes(del._id) );
            }
            return docComment_deleted
        

        default:
            break;
    }

    return state
}

export default docCommentReducer