import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";



export const getEmployeeById = (id) => (dispatch) => {
  return setTokenAxios().get(`employee/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`employee`, condition)
    .then(async res => {
      dispatch({
        type: "SET_EMPLOYEE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`employee`, condition)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const createEmployee = (data) => (dispatch) => {
  return setTokenAxios().post(`employee`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_EMPLOYEE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateEmployee = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`employee/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_EMPLOYEE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateAvatarByAdmin = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`employee/change-avatar/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_EMPLOYEE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteEmployee = ( _id) => (dispatch) => {
  return setTokenAxios().delete(`employee/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_EMPLOYEE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}