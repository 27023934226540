import { Box, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { green, pink } from '@mui/material/colors';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { deleteFinancialYear, getAllData } from '../../../actions/financialYear';
import { setEditObject } from '../../../actions/untils';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import MainForm from '../../elements/MainForm';
import BreadCrumb from '../../elements/breadCrumb';
import DrawerExpand from '../../elements/drawerExpand';
import RowMenu from '../../elements/rowMenu';
import AddForm from './AddForm';
import EditForm from './EditForm';

const Main = (params) => {
  const { props } = params
  const theme = useTheme()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const mainFormRef = React.useRef();
  const childFormRef = React.useRef();
  const [type, setType] = useState('add')
  const actionAdd = useSelector(state => state?.enumRedux?.actionAdd)
  const editObject = useSelector(state => state.edit)

  let listData = [...props?.data]

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (actionAdd === true) {
      if (type !== 'edit' && type !== 'delete') {
        setType('add')
      }
      mainFormRef.current?.openFromParent();
    }
    //eslint-disable-next-line
  }, [actionAdd])

  useEffect(() => {
    if (isEmpty(editObject)) {
      setType('add')
    }
  }, [editObject])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionMenu = async (value, type) => {
    mainFormRef.current?.openFromParent();
    setType(type)
    if (type === 'delete') {
      await props?.setEditObject({})
      deleteData(value)
    }
    else {
      if (type !== 'add') {
        dispatch({
          type: "SET_ENUM",
          payload: {
            actionAdd: true,
          }
        })
        await props?.setEditObject(value)
      }
    }
  }

  const deleteData = (value) => {
    props?.deleteFinancialYear(value?._id)
      .then(async res => {
        setType('')
        await props?.setEditObject({})
        _showAlertSuccess(res?.message || GET_SUCCESS)
      })
  }

  useEffect(() => {
    props?.getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderRow = () => {

    let list = rowsPerPage > 0
      ? listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : listData


    return list.map((row, index) => (
      <TableRow key={row._id} >
        <TableCell align="left">
          <RowMenu
            data={row}
            actionEdit={handleActionMenu}
            sx={{ mr: 2 }} />
          {page * rowsPerPage + index + 1}.
        </TableCell>
        <TableCell align="left">
        <div style={{ color: row?.isActive === true ? green[700] : pink[400]}}>
            {row?.code}
          </div>
        </TableCell>
        <TableCell component="th" scope="row" sx={{
          borderWidth: 0.5
        }}>

          {moment(row?.start).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell align="right"> {moment(row?.end).format('DD/MM/YYYY')}</TableCell>
        <TableCell align="right">
          <Typography variant='caption'>
            {row?.dataCode?.join(', ')}
          </Typography>
        </TableCell>
      </TableRow>
    ))
  }

  const handleSave = async () => {
    childFormRef?.current?.handleSubmit();
  }


  let breadcrumb = [{ name: 'Home', href: '/' }, { name: 'Financial', href: '/financial-year' }]
  return (
    <>
      <BreadCrumb data={breadcrumb} />
      <TableContainer component={Paper} sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 0,
        padding: 2,
        borderRadius: 2
      }}>
        <Box sx={{ fontSize: 24 }}>
          {/* Table of Customer */}
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">

          <TableHead>
            <TableRow sx={{
              fontWeight: 'bold'
            }}>
              <TableCell width={90}>No.</TableCell>
              <TableCell width={180} >Code.</TableCell>
              <TableCell>Start</TableCell>
              <TableCell align="right">End</TableCell>
              <TableCell align="right">Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow()}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8, 16, 25, { label: 'Tất cả', value: -1 }]}
                colSpan={6}
                count={listData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': t('pagingPerPage'),
                  },
                  native: true,
                }}
                labelRowsPerPage="Quantity:"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {type !== 'delete' ?
        <MainForm bg={'#F7FAFC'} ref={mainFormRef} maxWidth={'md'} actionSave={handleSave} subtitle={'Financial'} title={type === 'add' && isEmpty(editObject) ? t('lblCreate') : t('lblUpdate')}>
          {type === 'add' && isEmpty(editObject) ? <AddForm ref={childFormRef} /> : <EditForm ref={childFormRef} />}
        </MainForm>
        : <></>}
    </>
  )
}

const Financial = (props) => {
  let title = "Financial"
  document.title = title
  return (
    <>
      <DrawerExpand headerText={title}>
        <Main props={props} />
      </DrawerExpand>
    </>
  );
};

const mapDispatchToProps = {
  getAllData, setEditObject, deleteFinancialYear
}

const mapStateToProps = (state) => {
  return {
    data: state.financialYear
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Financial);