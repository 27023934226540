import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import React, { useEffect, useState } from 'react';

import { CloseOutlined } from '@mui/icons-material';
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogTitle, Fade, FormControl, Grid, IconButton, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { filter, isEmpty } from 'lodash';
import moment from 'moment';
import { defaultStaticRanges } from "../../../enums/DefaultRange";

import { connect } from 'react-redux';
import { getAllDataNoneRedux as getAccountNumber } from '../../../actions/accountant';
import { getAllDataNoneRedux as getDepartment } from '../../../actions/department';
import { getAllDataNoneRedux as getExpense } from '../../../actions/expense';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const staticRangesLabels = {
  Today: "Today",
  Yesterday: "Yesterday",
  "This Week": "This Week",
  "This Month": "This Month",
  "Last Month": "Last Month"
};

const now = new Date();

const FilterForm = props => {
  const [openDate, setOpenDate] = React.useState(false);
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(),
      key: "selection"
    }])
  const [creditAccount, setCreditAccount] = React.useState([]);
  const [debitAccount, setDebitAccount] = React.useState([]);
  const [listAccount, setListAccount] = React.useState([]);
  const [listDepartment, setListDepartment] = React.useState([]);
  const [listExpense, setListExpense] = React.useState([]);
  const [department, setDepartment] = useState({})
  const [expense, setExpense] = useState({})

  useEffect(() => {
    const init = async () => {
      await props?.getAccountNumber({}).then(res => {
        setListAccount(res.data)
      })
      await props?.getDepartment({ condition: {}, limit: 100 }).then(res => setListDepartment(res.data))
      await props?.getExpense({ condition: {}, limit: 100 }).then(res => setListExpense(res.data))
    }
    init()
    setRangeDate([{
      startDate: new Date(props?.dateStart),
      endDate: new Date(props?.dateEnd),
      key: "selection"
    }]);
    setDepartment(props?.department)
    setExpense(props?.expense)
    setDebitAccount(props?.debitAccount)
    setCreditAccount(props?.creditAccount)
    // eslint-disable-next-line
  }, [])

  const onChangeRange = (ranges) => {
    const { selection } = ranges;
    setRangeDate([selection]);
  };

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label]
        ? { ...item, label: dictionary[item.label] }
        : item;
  }

  const ruStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );

  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  const endDate =
    rangeDate[0].endDate === null
      ? formatDate(rangeDate[0].startDate)
      : formatDate(rangeDate[0].endDate);
  const startDate = rangeDate[0].startDate ? formatDate(rangeDate[0].startDate) : "";

  const handleFilter = async () => {
    const cond = {
      condition: {
        date: {
          from: startDate,
          to: endDate
        }
      }
    }

    if (expense?.code) {
      cond.condition.expenseLst = {
        in: [expense?.code]
      }
    }

    if (department?.code) {
      cond.condition.departmentLst = {
        in: [department?.code]
      }
    }

    if (creditAccount.length > 0) {
      cond.condition.creditLst = {
        in: creditAccount
      }
    }

    if (debitAccount.length > 0) {
      cond.condition.deditLst = {
        in: debitAccount
      }
    }

    props?.handleFilter(cond, { dateStart: startDate, dateEnd: endDate, debitAccount, creditAccount, department, expense })

  }

  const creditAccountChange = (event) => {
    const {
      target: { value },
    } = event;
    setCreditAccount(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const debitAccountChange = (event) => {
    const {
      target: { value },
    } = event;
    setDebitAccount(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const renderPicker = () => {
    return <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={openDate}
      onClose={() => setOpenDate(false)}>
      <DialogTitle>Date</DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <DateRangePicker
          onChange={onChangeRange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          editableDateInputs={true}
          dateDisplayFormat="dd-MM-yyyy"
          maxDate={new Date()}
          ranges={rangeDate}
          direction="horizontal"
          // locale={vi}
          rangeColors={["#00a870"]}
          staticRanges={ruStaticRanges}
        />


      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDate(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  }

  const _handleClear = () => {
    setDepartment({})
    setExpense({})
    setDebitAccount([])
    setCreditAccount([])
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={true}
        onClose={props.handleClose}
        TransitionComponent={Transition}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <Box display={'flex'} justifyContent='space-between' alignItems={'center'} backgroundColor="#2196f3" padding="6px 24px" color="#fff">
          {'Filter'}
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseOutlined />
          </IconButton>
        </Box>
        <DialogContent >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={{ display: 'flex', flexDirection: 'column', }} onClick={() => setOpenDate(true)}>
                <Typography variant='body2' >Date:</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant='body' color={"#2196f3"}>
                    {
                      moment(startDate).format('DD/MM/YYYY') + ' - ' + moment(endDate).format('DD/MM/YYYY')
                    }
                  </Typography>
                  <FilterListOutlinedIcon fontSize='medium' sx={{ color: "#2196f3", ml: 2 }} />
                </div>
              </div>
            </Grid>
            <Grid item xs={6} justifyContent={'flex-end'} display={'flex'} flexDirection={'row'}>
              <Button onClick={() => _handleClear()} disableElevation variant='contained'>Clear</Button>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >Credit Account:</Typography>
              <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  size='small'
                  value={debitAccount}
                  onChange={debitAccountChange}
                  input={<OutlinedInput id="select-multiple-chip" size='small' />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} size='small' />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {filter(listAccount, { isFinal: true }).map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.code}
                    >
                      {item.code} - {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >Credit Account:</Typography>
              <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  size='small'
                  value={creditAccount}
                  onChange={creditAccountChange}
                  input={<OutlinedInput id="select-multiple-chip" size='small' />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} size='small' />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {filter(listAccount, { isFinal: true }).map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.code}
                    >
                      {item.code} - {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                id="free-solo-department"
                disableClearable
                clearOnBlur
                value={department}
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    setDepartment({})
                  }
                  else {
                    setDepartment(newValue)
                  }
                }}
                onReset={() => setDepartment('')}
                size="small"
                options={listDepartment}
                getOptionLabel={(option) => !isEmpty(option?.code) ? `${option?.code} - ${option?.name}` : ''}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Department"
                    sx={{
                      '& .MuiTextField-root': { display: 'flex' },
                      '& .MuiInput-root': { mt: 1.5 }
                    }}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                id="free-solo-expense"
                disableClearable
                value={expense}
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    setExpense({})
                  }
                  else {
                    setExpense(newValue)
                  }
                }}
                onReset={() => setExpense('')}
                size="small"
                options={listExpense}
                getOptionLabel={(option) => !isEmpty(option?.code) ? `${option?.code} - ${option?.name}` : ''}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Expense"
                    sx={{
                      '& .MuiTextField-root': { display: 'flex' },
                      '& .MuiInput-root': { mt: 1.5 }
                    }}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDate(false)}>Close</Button>
          <Button onClick={() => handleFilter()}>Accept</Button>
        </DialogActions>
      </Dialog>
      {renderPicker()}
    </React.Fragment>
  )
};

const mapDispatchToProps = {
  getAccountNumber,
  getDepartment,
  getExpense
}

export default connect(null, mapDispatchToProps)(FilterForm);
