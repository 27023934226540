import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import 'sweetalert2/src/sweetalert2.scss';
import { getAllDataNoneRedux as getPartner } from '../../../actions/partner';
import ContactLst from '../../elements/ContactLst';

const EditRowDetail = React.forwardRef((props, ref) => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false);
    const [accountNumberFrom, setAccountNumberFrom] = useState('')
    const [accountNumberTo, setAccountNumberTo] = useState('')
    const [product, setProduct] = useState('')
    const [expense, setExpense] = useState('')
    const [department, setDepartment] = useState('')
    const [partner, setPartner] = useState('')
    const [amount, setAmount] = useState('')
    const [project, setProject] = useState('')
    const [vat, setVat] = useState('')
    const [total, setTotal] = useState(0)
    const [vatAmount, setVATAmount] = useState('')
    const [description, setDescription] = useState('')
    const [reference, setReference] = useState('')
    const [originalAmount, setOriginalAmount] = useState(0)


    const dispatch = useDispatch();
    const editObject = useSelector(state => state.editPaymentDetail)
    const paymentDetail = useSelector(state => state?.paymentDetail)
    let totalPaymentTmp = useSelector(state => state?.enumRedux?.totalEditPayment)


    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setAccountNumberFrom(props?.accountNumber)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.accountNumber])


    useEffect(() => {
        let data = { ...editObject?.data }
        setPartner(data?.customer)
        setAccountNumberFrom(data?.accountingDebit)
        setAccountNumberTo(data?.accountingCredit)
        setProduct(data?.product)
        setProject(data?.project)
        setExpense(data?.expense)
        setDepartment(data?.department)
        setOriginalAmount(data?.originalAmount)
        setAmount(data?.amount)
        setVat(data?.vat)
        setVATAmount(data?.amountVAT)
        setDescription(data?.description)
        setReference(data?.reference)
        setTotal(data?.total)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editObject])


    useEffect(() => {
        dispatch({
            type: "SET_ENUM",
            payload: {
                totalEditPayment: {
                    index: totalPaymentTmp?.index,
                    total: total
                },
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props?.handleCloseEdit(false)
    };

    const handleEditPayment = () => {
        let data = {
            paymentType: "CP",
            description: description,
            customerCode: partner?.code,
            customer: partner,
            accountNumberCredit: accountNumberTo?.code,
            accountingCredit: accountNumberTo,
            accountNumberDebit: accountNumberFrom?.code,
            accountingDebit: accountNumberFrom,
            currencyCode: props?.currency?.code,
            currency: props?.currency,
            departmentCode: department?.code,
            department: department,
            ruleCode: props?.rule?.code,
            rule: props?.rule,
            date: props?.date,
            productCode: product?.code,
            product: product,
            expenseCode: expense?.code,
            expense: expense,
            projectCode: project?.code,
            project: project,
            amount: Number(amount),
            originalAmount: Number(originalAmount),
            vatCode: vat?.code,
            vat: vat,
            vatRatio: vat?.value,
            amountVAT: Number(vatAmount),
            total: Number(total),
            reference: reference,
            exchange: props?.exchange
        }
        let tmp = [...paymentDetail]
        let ind = editObject?.index
        tmp[ind] = data

        dispatch({
            type: 'SET_PAYMENT_DETAIL',
            payload: tmp
        })
        dispatch({
            type: "SET_ENUM",
            payload: {
                totalEditPayment: {},
            }
        })
        handleClose()
        // props?.handleEditPayment(data)
    }

    const onKeyPressChange = (e, valTab) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleEditPayment()
            if (props?.typeTab !== 0) {
                props?.onSetTab(0)
            }
        }
        if (e.key === 'Tab') {
            props?.onSetTab(valTab)
            e.preventDefault()
        }
    }

    const checkPayment = () => {
        let data = {}

        if (!isEmpty(accountNumberTo?.code) && open===true) {
            data = {
                paymentType: "CP",
                description: description,
                customerCode: partner?.code || '',
                customer: partner,
                accountNumberCredit: accountNumberTo?.code || '',
                accountingCredit: accountNumberTo,
                accountNumberDebit: accountNumberFrom?.code || '',
                accountingDebit: accountNumberFrom,
                currencyCode: props?.currency?.code || '',
                currency: props?.currency,
                departmentCode: department?.code || '',
                department: department,
                ruleCode: props?.rule?.code || '',
                rule: props?.rule,
                date: props?.date,
                productCode: product?.code || '',
                product: product,
                expenseCode: expense?.code,
                expense: expense,
                projectCode: project?.code,
                project: project,
                amount: Number(amount),
                originalAmount: Number(originalAmount),
                vatCode: vat?.code,
                vat: vat,
                vatRatio: vat?.value,
                amountVAT: Number(vatAmount),
                total: Number(total),
                reference: reference,
                exchange: props?.exchange
            }
        }

        return data
    }

    const clearState = () => {
        setAccountNumberTo('')
        setOriginalAmount(0)
        setAmount(0)
        setPartner('')
        setExpense('')
        setDepartment('')
        setDescription('')
        setVat(0)
        setVATAmount(0)
        setTotal(0)
        setProduct('')
        setProject('')
        setReference('')
    }

    const _handleSelectContact = (newValue) => {
        setPartner(newValue)
    }

    React.useImperativeHandle(ref, () => ({ handleClickOpen, checkPayment, clearState, handleEditPayment }));
    return (
        <>
            <Grid container spacing={1.5} sx={{ pt: 0 }}>
                {props?.typeTab === 0 ? <>
                    <Grid item xs={1}>
                        <Autocomplete
                            disabled
                            freeSolo
                            id="free-solo-accountNumberFrom"
                            disableClearable
                            value={accountNumberFrom}
                            onChange={(event, newValue) => {
                                setAccountNumberFrom(newValue);
                            }}
                            size="small"
                            options={props?.listAccountNumber}
                            getOptionLabel={(option) => !isEmpty(option) ? `${option?.code} - ${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('frmDebit')}
                                    sx={{
                                        '& .MuiInput-root': { mt: 1.5 }
                                    }}
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        <Autocomplete
                            freeSolo
                            id="free-solo-accountNumberTo"
                            disableClearable
                            value={accountNumberTo}
                            onChange={(event, newValue) => {
                                setAccountNumberTo(newValue);
                            }}
                            size="small"
                            options={props?.listAccountNumber}
                            getOptionLabel={(option) => !isEmpty(option) ? `${option?.code} - ${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('frmCredit')}
                                    sx={{
                                        '& .MuiInput-root': { mt: 1.5 }
                                    }}
                                    autoFocus
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={1.5}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex' },
                                '& .MuiInput-root': { mt: 1.5 }
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                label={t('frmOriginal')}
                                id="original-amount"
                                value={originalAmount}
                                size="small"
                                variant="standard"
                                type={'number'}
                                onChange={(e) => {
                                    let amoutTmp = Number(e.target.value)
                                    setOriginalAmount(Number(amoutTmp))
                                    if (props?.currency !== props?.currencyDefault) {
                                        amoutTmp = amoutTmp * parseFloat(props?.exchange)
                                    }

                                    setAmount(amoutTmp)
                                    if (!isEmpty(vat)) {
                                        let amountV = Number(vat?.value) / 100 * amoutTmp
                                        setVATAmount(amountV)
                                        setTotal(Number(amountV) + amoutTmp)
                                    }
                                    else {
                                        setTotal(amoutTmp)
                                    }
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={1.5}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex' },
                                '& .MuiInput-root': { mt: 1.5 }
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                label={t('frmAmount')}
                                id="last-amount"
                                value={amount}
                                size="small"
                                variant="standard"
                                type={'number'}
                                onChange={(e) => {
                                    let amoutTmp = Number(e.target.value)
                                    setAmount(amoutTmp)
                                    if (vat) {
                                        let amountV = Number(vat?.value) / 100 * amoutTmp
                                        setVATAmount(amountV)
                                        setTotal(Number(amountV) + amoutTmp)
                                    }
                                    else {
                                        setTotal(amoutTmp)
                                    }
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={2} display={'flex'} justifyContent={'flex-end'} flexDirection={'column'}>
                        <ContactLst selectContact={_handleSelectContact} contact={partner} showName={true} />
                    </Grid>

                    <Grid item xs={1.5}>
                        <Autocomplete
                            freeSolo
                            id="free-solo-expense"
                            disableClearable
                            value={expense}
                            onChange={(event, newValue) => {
                                setExpense(newValue);
                            }}
                            size="small"
                            options={props?.listExpense}
                            getOptionLabel={(option) => option ? `${option?.code} ${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('frmExpense')}
                                    variant='standard'
                                    sx={{
                                        '& .MuiInput-root': { mt: 1.5 }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={1.5}>
                        <Autocomplete
                            freeSolo
                            id="free-solo-department"
                            disableClearable
                            value={department}
                            onChange={(event, newValue) => {
                                setDepartment(newValue);
                            }}
                            size="small"
                            options={props?.listDepartment}
                            getOptionLabel={(option) => option ? `${option?.code} ${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('frmDepartment')}
                                    variant='standard'
                                    sx={{
                                        '& .MuiInput-root': { mt: 1.5 }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Box
                            // component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label={t('frmDescription')}
                                id="last-description"
                                sx={{
                                    '& .MuiInput-root': { mt: 1.5 }
                                }}
                                value={description}
                                size="small"
                                variant='standard'
                                name="description"
                                onChange={(e) => setDescription(e.target.value)}
                                onKeyDown={(e) => onKeyPressChange(e, 1)}
                            />
                        </Box>
                    </Grid>
                </> : <></>}

                {props?.typeTab === 1 ? <>
                    <Grid item xs={3}>
                        <Autocomplete
                            freeSolo
                            id="free-solo-vat"
                            disableClearable
                            value={vat}
                            onChange={(event, newValue) => {
                                let amountV = Number(newValue?.value) / 100 * amount
                                setVATAmount(amountV)
                                setTotal(Number(amountV) + Number(amount))
                                setVat(newValue);
                            }}
                            size="small"
                            options={props?.listVAT}
                            getOptionLabel={(option) => option ? `${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='standard'
                                    sx={{
                                        '& .MuiInput-root': { mt: 1.5 }
                                    }}
                                    autoFocus
                                    label="VAT (%)"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                label={t('frmVATAmount')}
                                id="last-vat"
                                value={vatAmount}
                                variant='standard'
                                sx={{
                                    '& .MuiInput-root': { mt: 1.5 }
                                }}
                                size="small"
                                name="vatAmount"
                                onChange={(e) => setVATAmount(Number(e.target.value))}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            // component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label={t('frmTotal')}
                                id="last-Total"
                                value={total}
                                size="small" variant='standard'
                                sx={{
                                    '& .MuiInput-root': { mt: 1.5 }
                                }}
                                name="total"
                                type={'number'}
                                onChange={(e) => setTotal(Number(e.target.value))}
                                onKeyDown={(e) => onKeyPressChange(e, 2)}
                            />
                        </Box>
                    </Grid>
                </> : <></>}
                {props?.typeTab === 2 ? <>

                    <Grid item xs={3}>
                        <Autocomplete
                            freeSolo
                            id="free-solo-project"
                            disableClearable
                            value={project}
                            onChange={(event, newValue) => {
                                setProject(newValue);
                            }}
                            size="small"
                            options={props?.listProject}
                            variant='standard'
                            sx={{
                                '& .MuiInput-root': { mt: 1.5 }
                            }}
                            getOptionLabel={(option) => option ? `${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('frmProject')}
                                    variant='standard'
                                    sx={{
                                        '& .MuiInput-root': { mt: 1.5 }
                                    }}
                                    autoFocus
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={3}>
                        <Autocomplete
                            freeSolo
                            id="free-solo-product"
                            disableClearable
                            value={product}
                            onChange={(event, newValue) => {
                                setProduct(newValue);
                            }}
                            size="small"
                            options={props?.listProduct}
                            variant='standard'
                            sx={{
                                '& .MuiInput-root': { mt: 1.5 }
                            }}
                            getOptionLabel={(option) => option ? `${option?.name}` : ''}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('frmProduct')}
                                    variant='standard'
                                    sx={{
                                        '& .MuiInput-root': { mt: 1.5 }
                                    }}
                                    autoFocus
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={3}>
                        <Box
                            // component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                onKeyDown={(e) => onKeyPressChange(e, 10)}
                                label={t('frmReference')}
                                id="last-reference"
                                value={reference}
                                size="small" variant='standard'
                                sx={{
                                    '& .MuiInput-root': { mt: 1.5 }
                                }}
                                name="reference"
                                onChange={(e) => setReference(e.target.value)}
                            />
                        </Box>
                    </Grid>
                </> : <></>}

                {open &&
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        marginTop: '8px',
                        justifyContent: 'flex-end'
                    }}>
                        {/* <Button variants="text" onClick={handleClose}>Close</Button>
                        <Button variants="text" onClick={handleEditPayment}>Done</Button> */}
                    </div>
                }
            </Grid>
        </>
    );
})

const mapDispatchToProps = {
    getPartner
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(EditRowDetail);
