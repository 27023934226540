import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import _, { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getMessageGroupByReceiverId } from "../../../actions/chat";
import config from "../../../config/index";
import { Box, Button, Typography, IconButton, Slide } from '@mui/material';

const ContactList = (props) => {
    const [
        { keyContact },
        setState
    ] = useState({});

    const { userAll, chatUserOnline } = props

    const getMessageGroupByReceiverId = (_id) => {
        props.getMessageGroupByReceiverId(_id)
    }

    const onChange = (e) => {
        setState((state) => ({ ...state, [e.target.name]: e.target.value }))
    }

    const handleCreateGroup = () =>{
        props?.handleOpenAddGroup()
    }

    return (
        <Slide direction="right" in={props.showContactList} mountOnEnter unmountOnExit>
            <div className="contactList">
                <div className="topContactList">
                    <IconButton aria-label="delete" onClick={() => props.actionShow(props.showContactList)}>
                        <ArrowBackOutlinedIcon color='inherit' />
                    </IconButton>
                    <div className="searchTool">
                        <div className="group-search" >
                            <input
                                className="input-Search"
                                value={keyContact}
                                name="keyContact"
                                placeholder="Search ..."
                                onChange={onChange}
                            />
                        </div>
                        <div className="icon-search">
                            <SearchOutlinedIcon />
                        </div>
                    </div>
                </div>


                <div className="mainContactList">
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <Box sx={{display:'flex', flexDirection:'column', paddingRight: 1}}>
                            <Typography variant='title2' color={'primary'}>
                                Private message:
                            </Typography>
                            <Typography variant='caption'>
                            Choose member below to chat privately or create a group with button
                            </Typography>
                        </Box>
                        <Button variant="contained" color='primary' size='small' disableElevation onClick={handleCreateGroup}>
                            CREATE GROUP
                        </Button>
                    </Box>
                    <ul className="toolInfo member">
                        {userAll.map(item => {
                            return <li
                                key={item._id} onClick={() => getMessageGroupByReceiverId(item._id)}>
                                <div className="imgTool">
                                    <div className="contetImgTool">
                                        {!isEmpty(_.get(item, 'profile.fileAvatar', '')) ? _.get(item.profile, 'lastName', '').charAt(0) : <img src={config.baseURL + _.get(item, 'profile.fileAvatar.path', '')} alt={_.get(item.profile, 'lastName', '')} />}
                                    </div>
                                    {_.includes(chatUserOnline, item._id) === true && <div className="online"></div>}
                                </div>
                                <div className="textTool">
                                    <span className="contactName">{_.get(item.profile, 'firstName', '')} {_.get(item.profile, 'lastName', '')}</span>
                                    <p>@{_.get(item, 'userName', '')} - {_.get(item.profile, 'department.name', '')}</p>
                                </div>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        </Slide>
    );
};

const mapStateToProps = (state) => {

    return {
        chatUserOnline: state.chatUserOnline,
        chatMessageLasted: state.chatMessageLasted,
        chatGroup: state.chatGroup,
        userAll: state.user
    }
}

const mapDispatchToProps = {
    getMessageGroupByReceiverId
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);