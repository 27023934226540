const initialState = []

const chatGroupReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_CHATGROUP":
            return action.payload

        case "CREATE_CHATGROUP":
            return [...state, action.payload]

        case "UPDATE_CHATGROUP":
            const index = state.findIndex(per => per.code === action.payload.code)
            state[index] = action.payload;
            return [...state]
        
       
        
        case "DELETE_CHATGROUP":
            var data_deleted = []
            if(action.payload.length > 0)
            {
                data_deleted = state.filter(del =>!(action.payload).includes(del._id) );
            }
            return data_deleted
        

        default:
            break;
    }

    return state
}

export default chatGroupReducer