import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createCommentSubTask } from "../../../actions/task";
import config from '../../../config';
import i18next from 'i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Comment = (props) => {
    const [comment, setComment] = useState('')

    const makeComment = () => {
        var data = {
            taskId: props.taskDetail.id,
            subTaskId: props.subTaskId,
            content: comment
        }
        props.createCommentSubTask(data)
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            makeComment()
        }
    }

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={props?.openComment}
            onClose={props.closeComment}
            TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle sx={{background:'#2196F3'}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <IconButton edge="start" sx={{color:'#fff'}} onClick={props.closeComment} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{color:'#fff'}} >
                        Comment
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent style={{ minHeight: 400, background: '#fff', padding: 16 }}>
                {
                    props.subTaskId !== "" && <div className="commnetTaskDetail">
                        <div className="commentInput">
                            <input className="commentAction" name="comment" onChange={(e) => setComment(e.target.value)} value={comment} onKeyDown={_handleKeyDown} />
                            <div className="btnComment" onClick={() => makeComment()}>
                                <img alt="" src="/resources/icons/send.png" />
                            </div>
                        </div>
                        <div className="titSubTask" style={{ marginTop: 16 }}>{i18next.t('lblComment')}</div>
                        <p className="explainForUp">{props.taskDetail.name}</p>
                        <ul className="listComment">
                            {props.taskComment.map((item, i) => {
                                return <li key={`taskC` + item._id}>
                                    <div className="avatarComment">
                                        <img alt="" src={`${config.baseURL + _.get(item, 'userCreated[0].fileAvatar.path', '')}`} />
                                    </div>
                                    <div className="contentComment">
                                        <div className="nameOfComment">{_.get(item, 'userCreated[0].firstName', '')} {_.get(item, 'userCreated[0].lastName', '')}</div>
                                        <div className="contentOfComment">
                                            {_.get(item, 'content', '')}
                                        </div>
                                    </div>
                                    <div className="timeSubComment">
                                        {moment(_.get(item, 'created', '')).format('DD/MM/YYYY, h:mm:ss a')}
                                    </div>
                                </li>
                            })}
                        </ul>

                    </div>
                }
            </DialogContent>

        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        userAll: state.user,
        profile: state.profile,
        taskDetail: state.taskDetail,
        taskComment: state.taskComment
    }
}

const mapDispatchToProps = {
    createCommentSubTask
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
