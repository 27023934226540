const initialState = {}

const partnerReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_PROFILE":
               return action.payload
        default:
            break;
    }

    return state
}

export default partnerReducer