const typeAccount = [
    {
        code:'dbb',
        data: [
            {
                lngCode:'en',
                name:'Debit balance',
            },
            {
                lngCode:'cn',
                name:'Debit balance',
            },
            {
                lngCode:'la',
                name:'Debit balance',
            }
        ]
    },
    {
        code:'cdb',
        data: [
            {
                lngCode:'en',
                name:'Credit balance',
            },
            {
                lngCode:'cn',
                name:'Credit balance',
            },
            {
                lngCode:'la',
                name:'Credit balance',
            }
        ]
    },
    {
        code:'bb',
        data: [
            {
                lngCode:'en',
                name:'Both balance',
            },
            {
                lngCode:'cn',
                name:'Both balance',
            },
            {
                lngCode:'la',
                name:'Both balance',
            }
        ]
    },
]

const kindAccount = [
    {
        code:'object',
        data: [
            {
                lngCode:'en',
                name:'Object',
            },
            {
                lngCode:'cn',
                name:'Object',
            },
            {
                lngCode:'la',
                name:'Object',
            }
        ]
    },
    {
        code:'bank',
        data: [
            {
                lngCode:'en',
                name:'Bank',
            },
            {
                lngCode:'cn',
                name:'Bank',
            },
            {
                lngCode:'la',
                name:'Bank',
            }
        ]
    },
    {
        code:'liabilitie',
        data: [
            {
                lngCode:'en',
                name:'Liabilities',
            },
            {
                lngCode:'cn',
                name:'Liabilities',
            },
            {
                lngCode:'la',
                name:'Liabilities',
            }
        ]
    },
    {
        code:'asset',
        data: [
            {
                lngCode:'en',
                name:'Assets',
            },
            {
                lngCode:'cn',
                name:'Assets',
            },
            {
                lngCode:'la',
                name:'Assets',
            }
        ]
    },
    {
        code:'equity',
        data: [
            {
                lngCode:'en',
                name:'Equity',
            },
            {
                lngCode:'cn',
                name:'Equity',
            },
            {
                lngCode:'la',
                name:'Equity',
            }
        ]
    },
    {
        code:'revenue',
        data: [
            {
                lngCode:'en',
                name:'Revenues',
            },
            {
                lngCode:'cn',
                name:'Revenues',
            },
            {
                lngCode:'la',
                name:'Revenues',
            }
        ]
    },
    {
        code:'sale',
        data: [
            {
                lngCode:'en',
                name:'Cost of Sales',
            },
            {
                lngCode:'cn',
                name:'Cost of Sales',
            },
            {
                lngCode:'la',
                name:'Cost of Sales',
            }
        ]
    },
    {
        code:'expense',
        data: [
            {
                lngCode:'en',
                name:'Expenses',
            },
            {
                lngCode:'cn',
                name:'Expenses',
            },
            {
                lngCode:'la',
                name:'Expenses',
            }
        ]
    },
    {
        code:'operating',
        data: [
            {
                lngCode:'en',
                name:'Operating Costs',
            },
            {
                lngCode:'cn',
                name:'Operating Costs',
            },
            {
                lngCode:'la',
                name:'Operating Costs',
            }
        ]
    },
    {
        code:'foreign',
        data: [
            {
                lngCode:'en',
                name:'Foreign currency',
            },
            {
                lngCode:'cn',
                name:'Foreign currency',
            },
            {
                lngCode:'la',
                name:'Foreign currency',
            }
        ]
    }
]

export {typeAccount, kindAccount}