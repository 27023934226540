import React, { Component } from 'react';

import { CloseOutlined } from '@mui/icons-material';
import { Box, Dialog, IconButton, Link, Slide } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { DropzoneArea } from 'material-ui-dropzone';
import api from "../../../api/index"


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class ImportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: 'paper',
      open: false,
      labelWidth: 0,
      isActive: false,
      error: {},
      message: ""
    }
  }

  handleDrop = files => {
    const uploaders = files.map(file => {
      // Initial FormData
      // console.log('file', file)
      const formData = new FormData();
      formData.append("file", file);
      // console.log('formData', formData)
      return api.post('/income/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
          this.setState({ message: response.data.message })
        return 'done'
      })
    })
    console.log(uploaders)
    // Once all the files are uploaded 
    // axios.all(uploaders).then(() => {
    //   // ... perform after upload is successful operation
    // });
  }


  render() {

    return (
      <React.Fragment>
        <Dialog
          maxWidth="md"
          open={true}
          onClose={this.props.handleClose}
          TransitionComponent={Transition}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <Box display={'flex'} justifyContent='space-between' alignItems={'center'} backgroundColor="#2196f3" padding="6px 24px" color="#fff">
            {'IMPORT'}
            <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
              <CloseOutlined />
            </IconButton>
          </Box>
          <DialogContent >
            <div>
              <Link href="" target="_blank">
                {/* Mẫu import (Các mã phải tồn tại trong phần mềm để tránh xảy ra lỗi) */}
              </Link>
              {this.state.message !== "" && <div style={{
                padding: 12, marginBottom: 10, background: '#d2f3d2',
                border: '1px solid #a6e0a6',
                borderRadius: 3
              }}>{this.state.message}</div>}
              <DropzoneArea
                showPreviewsInDropzone={false}
                onChange={this.handleDrop}
              />
            </div>
          </DialogContent>

        </Dialog>
      </React.Fragment>
    );
  }
}

export default (ImportForm);