import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { connect, useSelector } from 'react-redux';
import { updateChance } from '../../../actions/chance';
import { getAllData as getDemand } from '../../../actions/demand';
import { getAllData as getProject } from '../../../actions/project';
import { getAllDataByType as getUser } from '../../../actions/user';
import { allStage } from '../../../enums/activeStatus';
import { isEmpty } from 'lodash';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useTranslation } from 'react-i18next';


const initialState = {
    saleId: { _id: '', firstName: 'Choose', lastName: '...' },
    leadId: { _id: '', firstName: 'Choose', lastName: '...' },
    status: '',
    hasInfoZalo: false,
    hasInfoCall: false,
    hasAppointment: false,
    hasMet: false,
    hasOrder: false,
    needCareMore: false,
    projectAmount: '',
    projectAmountDeposit: ''
}


const FilterForm = (props) => {
    const sale = useSelector(state => state.user)
    const theme = useTheme()
    const {t} = useTranslation()
    const [date, setDate] = React.useState('');
    const [
        { saleId, leadId, status, isAssign, financialAmount,
            hasInfoZalo, hasInfoCall, hasAppointment,
            amountBooking, amountDeposit, depositType,
            signedContract, doneResult,
            hasMet, hasOrder, needCareMore, demandCodeMeeting, demandCodeBooking,
            demandCodeDeposit, projectAmount, projectAmountDeposit,
            projectCodeMeeting, projectCodeBooking, projectCodeDeposit },
        setState
    ] = useState(initialState);


    // const searchAuto = debounce((value) => {
    //     var data = {
    //         "condition": {},
    //         "sort": {
    //             "created": -1
    //         },
    //         "search": value,
    //         "limit": 10,
    //         "page": 1
    //     }
    //     props?.getDemand(data)
    // }, 500)

    useEffect(() => {
        const init = async () => {
            await props?.getDemand()
            await props?.getProject()
        }
        init()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.isOpenFilter])


    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const closeDialogMain = () => {
        props?.openFilter(false);
    };

    const handleFilter = () => {
        let cond = {
            condition: {}
        }

        if (!isEmpty(status)) {
            cond.condition.stage = { equal: status }
        }
        if (!isEmpty(leadId?._id)) {
            cond.condition.leaderId = { equal: leadId?._id }
        }
        if (!isEmpty(saleId?._id)) {
            cond.condition.assignedUserId = { equal: saleId?._id }
        }
        if (isAssign) {
            cond.condition.assignedUserId = { equal: "" }
        }
        if (date) {
            cond.condition.created = { equal: date}
        }
        
        //communication
        let communicationStage = {
            type: 'object',
            object: {}
        }
        if (hasInfoZalo) {
            communicationStage.object.hasInfoZalo = { equal: hasInfoZalo }
        }
        if (hasInfoCall) {
            communicationStage.object.hasInfoCall = { equal: hasInfoCall }
        }
        if (hasAppointment) {
            communicationStage.object.hasAppointment = { equal: hasAppointment }
        }
        if (!isEmpty(communicationStage?.object)) {
            cond.condition.communicationStage = communicationStage
        }

        //meeting
        let meetingStage = {
            type: 'object',
            object: {}
        }
        if (hasMet) {
            meetingStage.object.hasMet = { equal: hasMet }
        }
        if (demandCodeMeeting) {
            meetingStage.object.demands = {
                type: 'arrayObject',
                object: {
                    code: { equal: demandCodeMeeting }
                }
            }
        }
        if (projectCodeMeeting) {
            meetingStage.object.projects = {
                type: 'arrayObject',
                object: {
                    code: { equal: projectCodeMeeting }
                }
            }
        }
        if (financialAmount) {
            meetingStage.object.financialAmount = { gte: financialAmount }
        }
        if (needCareMore) {
            meetingStage.object.needCareMore = { equal: needCareMore }
        }
        if (hasOrder) {
            meetingStage.object.hasOrder = { equal: hasOrder }
        }
        if (!isEmpty(meetingStage?.object)) {
            cond.condition.meetingStage = meetingStage
        }

        //bookingStage
        let bookingStage = {
            type: 'object',
            object: {}
        }
        if (demandCodeBooking) {
            bookingStage.object.demands = {
                type: 'arrayObject',
                object: {
                    code: { equal: demandCodeBooking }
                }
            }
        }
        if (projectCodeBooking) {
            bookingStage.object.projects = {
                type: 'arrayObject',
                object: {
                    code: { equal: projectCodeBooking }
                }
            }
        }
        if (projectAmount) {
            bookingStage.object.projectAmount = { gte: projectAmount }
        }
        if (amountBooking) {
            bookingStage.object.amount = { gte: amountBooking }
        }

        if (!isEmpty(bookingStage?.object)) {
            cond.condition.bookingStage = bookingStage
        }

        //depositStage
        let depositStage = {
            type: 'object',
            object: {}
        }
        if (demandCodeDeposit) {
            depositStage.object.demands = {
                type: 'arrayObject',
                object: {
                    code: { equal: demandCodeDeposit }
                }
            }
        }
        if (projectCodeDeposit) {
            depositStage.object.projects = {
                type: 'arrayObject',
                object: {
                    code: { equal: projectCodeDeposit }
                }
            }
        }

        if (projectAmountDeposit) {
            depositStage.object.projectAmount = { gte: projectAmountDeposit }
        }

        if (amountDeposit) {
            depositStage.object.amount = { gte: amountDeposit }
        }

        if (depositType) {
            depositStage.object.paymentType = {
                type: 'object',
                object: {
                    code: { equal: depositType }
                }
            }
        }

        if (!isEmpty(depositStage?.object)) {
            cond.condition.depositStage = depositStage
        }

        //contractStage
        let contractStage = {}
        if (signedContract) {
            contractStage.object = {
                signedContract: { equal: signedContract }
            }
        }

        //doneStage
        let doneStage = {
            type: 'object',
            object: {}
        }

        if (doneResult) {
            doneStage.object = {
                result: { equal: doneResult }
            }
        }

        if (!isEmpty(doneStage?.object)) {
            cond.condition.doneStage = doneStage
        }

        if (!isEmpty(cond?.object)) {
            cond.type = 'object'
        }

        props?.handleFilter(cond)
    }

    return (
        <div>
            <Dialog
                open={props?.isOpenFilter}
                fullWidth={true}
                maxWidth={'md'}
                onClose={closeDialogMain}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ background: theme.palette.primary.main, color: '#fff' }}>
                    {"Lọc dữ liệu"}
                </DialogTitle>
                <DialogContent style={{ paddingTop: 32 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { width: '100%' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            label="Thời gian tạo"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            {sale?.length > 0 ?
                                <Autocomplete
                                    id="sale-select"
                                    options={[{ _id: '', firstName: t('frmChoose'), lastName: '...' }, ...sale]}
                                    isOptionEqualToValue={(option, value) => option._id === value?._id}
                                    size="small"
                                    value={saleId}
                                    autoHighlight
                                    getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
                                    onChange={(event, newValue) => {
                                        setState(state => ({ ...state, saleId: newValue }))
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Nhân viên Sale"
                                            variant="outlined"
                                            // onChange={onChangeInput}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )} /> : <></>}

                        </Grid>

                        <Grid item xs={6}>
                            {sale?.length > 0 ?
                                <Autocomplete
                                    id="sale-select-lead"
                                    options={[{ _id: '', firstName: t('frmChoose'), lastName: '...' }, ...sale] || ''}
                                    isOptionEqualToValue={(option, value) => option._id === value?._id}
                                    size="small"
                                    value={leadId}
                                    autoHighlight
                                    getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
                                    onChange={(event, newValue) => {
                                        setState(state => ({ ...state, leadId: newValue }))
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Quản lý nhóm - Leader"
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )} /> : <></>}
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: '100%' }} size="small">
                                <InputLabel id="demo-select-status">Trạng thái</InputLabel>
                                <Select
                                    leadId="demo-select-status"
                                    id="demo-select-status"
                                    onChange={onChange}
                                    name="status"
                                    value={status}
                                    label="Trạng thái">
                                    <MenuItem value={''}>
                                        ...
                                    </MenuItem>
                                    {allStage?.map((res, iii) => {
                                        return <MenuItem key={iii} value={res?.code}>
                                            {res?.name}
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: '100%' }} size="small">
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={isAssign} onChange={(e) => customeOnChange('isAssign', e.target.checked)} name="isAssign" />
                                    }
                                    label="Chưa assign"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='body2' ml={1} mt={2} mb={2} sx={{ fontWeight: 500 }}>Liên hệ</Typography>
                            <Grid container>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={hasInfoZalo} onChange={(e) => customeOnChange('hasInfoZalo', e.target.checked)} name="hasInfoZalo" />
                                            }
                                            label="Tương tác Zalo"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={hasInfoCall} onChange={(e) => customeOnChange('hasInfoCall', e.target.checked)} name="hasInfoCall" />
                                            }
                                            label="Tương tác cuộc gọi"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={hasAppointment} onChange={(e) => customeOnChange('hasAppointment', e.target.checked)} name="hasAppointment" />
                                            }
                                            label="Có hẹn"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='body2' ml={1} sx={{ fontWeight: 500 }}>Gặp mặt</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={hasMet} onChange={(e) => customeOnChange('hasMet', e.target.checked)} name="hasMet" />
                                            }
                                            label="Đã hẹn"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={hasOrder} onChange={(e) => customeOnChange('hasOrder', e.target.checked)} name="hasOrder" />
                                            }
                                            label="Có đơn hàng"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={needCareMore} onChange={(e) => customeOnChange('needCareMore', e.target.checked)} name="needCareMore" />
                                            }
                                            label="Chăm sóc thêm"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <InputLabel id="demo-select-small">Dự án</InputLabel>
                                        <Select
                                            leadId="demo-select-small-project"
                                            id="demo-select-small-project"
                                            onChange={onChange}
                                            name="projectCodeMeeting"
                                            value={projectCodeMeeting || ''}
                                            label="Dự án">
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {props?.project?.map((res, ind) => {
                                                return <MenuItem key={ind} value={res?.code}>{res?.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <InputLabel id="demo-select-small">Nhu cầu</InputLabel>
                                        <Select
                                            leadId="demo-select-small"
                                            id="demo-select-small"
                                            onChange={onChange}
                                            name="demandCodeMeeting"
                                            value={demandCodeMeeting || ''}
                                            label="Nhu cầu">
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {props?.demand?.map((res, ind) => {
                                                return <MenuItem key={ind} value={res?.code}>{res?.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={4}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField
                                            label="Tài chính (> hoặc =)"
                                            id="outlined-size-financialAmount"
                                            value={financialAmount}
                                            name="financialAmount"
                                            type={'number'}
                                            onChange={onChange}
                                            size="small"
                                            fullWidth={true}
                                        />
                                    </Box>
                                </Grid>



                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='body2' ml={1} mt={2} mb={2} sx={{ fontWeight: 500 }}>Booking</Typography>
                            <Grid container spacing={2}>


                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <InputLabel id="demo-select-small">Dự án</InputLabel>
                                        <Select
                                            leadId="demo-select-small-project"
                                            id="demo-select-small-project"
                                            onChange={onChange}
                                            name="projectCodeBooking"
                                            value={projectCodeBooking || ''}
                                            label="Dự án">
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {props?.project?.map((res, ind) => {
                                                return <MenuItem key={ind} value={res?.code}>{res?.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <InputLabel id="demo-select-small">Nhu cầu</InputLabel>
                                        <Select
                                            leadId="demo-select-small"
                                            id="demo-select-small"
                                            onChange={onChange}
                                            name="demandCodeBooking"
                                            value={demandCodeBooking || ''}
                                            label="Nhu cầu">
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {props?.demand?.map((res, ind) => {
                                                return <MenuItem key={ind} value={res?.code}>{res?.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={4}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField
                                            label="Tiền booking (> hoặc =)"
                                            id="outlined-size-amountBooking"
                                            value={amountBooking}
                                            type={'number'}
                                            name="amountBooking"
                                            onChange={onChange}
                                            size="small"
                                            fullWidth={true}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={4}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField
                                            label="Tiền dự kiến  (> hoặc =)"
                                            id="outlined-size-projectAmount"
                                            value={projectAmount}
                                            type={'number'}
                                            name="projectAmount"
                                            onChange={onChange}
                                            size="small"
                                            fullWidth={true}
                                        />
                                    </Box>
                                </Grid>



                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='body2' ml={1} mt={2} mb={2} sx={{ fontWeight: 500 }}>Cọc</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <InputLabel id="demo-select-small">Dự án</InputLabel>
                                        <Select
                                            leadId="demo-select-small-project"
                                            id="demo-select-small-project"
                                            onChange={onChange}
                                            name="projectCodeDeposit"
                                            value={projectCodeDeposit || ''}
                                            label="Dự án">
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {props?.project?.map((res, ind) => {
                                                return <MenuItem key={ind} value={res?.code}>{res?.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <InputLabel id="demo-select-type">Loại thanh toán</InputLabel>
                                        <Select
                                            leadId="demo-select-small-type"
                                            id="demo-select-small-type"
                                            onChange={onChange}
                                            name="depositType"
                                            value={depositType || ''}
                                            label="Dự án">
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="payByStage">
                                                Tiến độ
                                            </MenuItem>
                                            <MenuItem value="payByBank">
                                                Vay ngân hàng
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>



                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <InputLabel id="demo-select-small">Nhu cầu</InputLabel>
                                        <Select
                                            leadId="demo-select-small"
                                            id="demo-select-small"
                                            onChange={onChange}
                                            name="demandCodeDeposit"
                                            value={demandCodeDeposit || ''}
                                            label="Nhu cầu">
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {props?.demand?.map((res, ind) => {
                                                return <MenuItem key={ind} value={res?.code}>{res?.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={4}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField
                                            label="Tiền cọc (> hoặc =)"
                                            id="outlined-size-amountDeposit"
                                            value={amountDeposit}
                                            name="amountDeposit"
                                            onChange={onChange}
                                            size="small"
                                            fullWidth={true}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={4}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField
                                            label="Tiền dự kiến  (> hoặc =)"
                                            id="outlined-size-projectAmountDeposit"
                                            value={projectAmountDeposit}
                                            type={'number'}
                                            name="projectAmountDeposit"
                                            onChange={onChange}
                                            size="small"
                                            fullWidth={true}
                                        />
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='body2' ml={1} mt={2} mb={2} sx={{ fontWeight: 500 }}>Hợp đồng</Typography>
                            <Grid container>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={signedContract} onChange={(e) => customeOnChange('signedContract', e.target.checked)} name="signedContract" />
                                            }
                                            label="Đã ký hợp đồng"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='body2' ml={1} sx={{ fontWeight: 500 }}>Hoàn thành</Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-done-label"
                                            name="doneResult"
                                            value={doneResult}
                                            onChange={onChange}
                                        >
                                            <FormControlLabel value="success" control={<Radio />} label="Thành công" />
                                            <FormControlLabel value="fail" control={<Radio />} label="Thất bại" />
                                            <FormControlLabel value="cancel" control={<Radio />} label="Hủy" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions sx={{ paddingX: 2 }}>
                    <Button onClick={closeDialogMain}>Đóng</Button>
                    <Button onClick={handleFilter} variant={'contained'}
                        sx={{ minWidth: 120 }}
                        disableElevation>Lọc</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


const mapDispatchToProps = {
    updateChance, getUser, getDemand, getProject
}

const mapStateToProps = (state) => {
    return {
        data: state.edit,
        demand: state.demand,
        project: state.project
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);