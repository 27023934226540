import { Chip, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { find, isEmpty } from 'lodash';
import React from 'react';
import DrawerExpand from '../../elements/drawerExpand';
// import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import EditableLabel from 'react-editable-label';
import { connect } from 'react-redux';
import { getAllData, updateLanguage } from '../../../actions/language';
import { getAllData as getAllProperty } from '../../../actions/langVar';
import lngDescription from './langDescription.json'
// var fs = require('browserify-fs');

const Main = params => {
    const { props } = params
    const [en, setEn] = useState([])
    const [la, setLa] = useState([])
    const [cn, setCn] = useState([])
    const [enMain, setEnMain] = useState([])
    const [laMain, setLaMain] = useState([])
    const [cnMain, setCnMain] = useState([])
    // const [lstPro, setLstPro] = useState([])
    // const [open, setOpen] = React.useState(false);

    const init = () => {
        props?.getAllData()
            .then(res => {
                let enT = find(res.data, { code: 'en' })
                setEnMain(enT)
                let enTmp = []
                if (!isEmpty(enT?.variables)) {
                    Object.entries(enT?.variables).forEach(([key, value]) =>
                        enTmp.push({ name: key, data: value, type: enT?.code, main: { ...enT } })
                    )
                    setEn(enTmp)
                }
                let laT = find(res.data, { code: 'la' })
                let laTmp = []
                if (!isEmpty(laT?.variables)) {
                    Object.entries(laT?.variables).forEach(([key, value]) =>
                        laTmp.push({ name: key, data: value, type: laT?.code, main: { ...enT } })
                    )
                    setLa(laTmp)
                }
                setLaMain(laT)
                let cnT = find(res.data, { code: 'cn' })
                let cnTmp = []
                if (!isEmpty(cnT?.variables)) {
                    Object.entries(cnT?.variables).forEach(([key, value]) =>
                        cnTmp.push({ name: key, data: value, type: cnT?.code, main: { ...enT } })
                    )
                    setCn(cnTmp)
                }
                setCnMain(cnT)
            })
    }

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const actionSave = (item, val) => {
        let data = {}
        switch (item?.type) {
            case 'en':
                data = { ...enMain }
                break;

            case 'la':
                data = { ...laMain }
                break;

            case 'cn':
                data = { ...cnMain }
                break;

            default:
                break;
        }

        data.variables[item.name] = val
        props?.updateLanguage(data, data?._id)
            .then(res => {
                init()
            })

        // var blob = new Blob([JSON.stringify(data)], { type: "text/plain;charset=utf-8" });
        // saveAs(blob, "../data.json");

    }

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleClickOpen = () => {
    //     setOpen(true);
    //     props?.getAllProperty()
    //         .then(res => {
    //             setLstPro(res?.data)
    //         })
    // };

    // const descriptionElementRef = React.useRef(null);
    // React.useEffect(() => {
    //     if (open) {
    //         const { current: descriptionElement } = descriptionElementRef;
    //         if (descriptionElement !== null) {
    //             descriptionElement.focus();
    //         }
    //     }
    // }, [open]);

    // const renderListProperty = () => {
    //     return <Dialog
    //         open={open}
    //         maxWidth="sm"
    //         fullWidth={true}
    //         onClose={handleClose}
    //         scroll={'paper'}
    //         aria-labelledby="scroll-dialog-title"
    //         aria-describedby="scroll-dialog-description" >
    //         <DialogTitle id="scroll-dialog-title">List Property </DialogTitle>
    //         <DialogContent dividers={true}>
    //             <Grid container>
    //                 <Grid item xs={12}  style={{ paddingLeft:0, justifyContent: "space-between", alignContent: 'center', display: 'flex' }}>
    //                     <TextField placeholder='Name' variant='outlined' size='small' style={{
    //                         width: '15%'
    //                     }}>

    //                     </TextField>
    //                     <TextField placeholder='Description' variant='outlined' size='small' style={{ width: '70%' }}>

    //                     </TextField>
    //                     <Button variant='contained' styles={{ marginLeft: 2 }} disableElevation>Add</Button>
    //                 </Grid>
    //                 <Grid item xs={12}>
    //                     {
    //                         lstPro.map((item, index) => {
    //                             return <div className='rowLangs' key={index}>
    //                                 <div style={{ width: 60 }}>{index + 1}</div>
    //                                 <div className='colLangs'>
    //                                     <EditableLabel
    //                                         initialValue={item?.name}
    //                                         save={value => {
    //                                             console.log(`Saving '${value}'`);
    //                                         }}
    //                                     />
    //                                     <br />
    //                                     <EditableLabel
    //                                         initialValue={item?.name}
    //                                         style={{ fontSize: 13 }}
    //                                         save={value => {
    //                                             console.log(`Saving '${value}'`);
    //                                         }}
    //                                     />
    //                                 </div>
    //                             </div>
    //                         })
    //                     }
    //                 </Grid>
    //             </Grid>
    //         </DialogContent>
    //         <DialogActions>
    //             <Button onClick={handleClose}>Cancel</Button>
    //             <Button onClick={handleClose}>Subscribe</Button>
    //         </DialogActions>
    //     </Dialog>
    // }

    return (
        <Grid container>
            <Grid item xs={12}>
                {/* <Button onClick={actionSave}>Save</Button>
                <Button onClick={handleClickOpen}>PROPERTY</Button> */}
            </Grid>
            <Grid item xs={12} padding={2} style={{ background: '#fff' }}>
                <TableContainer >
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Description</TableCell>
                                <TableCell align="left">English</TableCell>
                                <TableCell align="left">Laos</TableCell>
                                <TableCell align="left">Chinese</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {en.map((item, index) => (
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='body2'>
                                            {lngDescription[item.name]?.description || item?.name}
                                        </Typography>
                                      
                                        {lngDescription[item.name]?.modules?.map(res => {
                                            return <Chip color='primary' label={res} size="small" style={{ marginRight: 4 }} />
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <EditableLabel
                                            initialValue={item?.data}
                                            save={value => {
                                                actionSave(item, value)
                                            }}
                                        />
                                     </TableCell>
                                     <TableCell>
                                        <EditableLabel
                                            initialValue={find(la, { name: item.name })?.data}
                                            save={value => {
                                                actionSave(find(la, { name: item.name }), value)
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <EditableLabel
                                            initialValue={find(cn, { name: item.name })?.data}
                                            save={value => {
                                                actionSave(find(cn, { name: item.name }), value)
                                            }}
                                        />
                                     </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

             
            </Grid>
            {/* {renderListProperty()} */}
        </Grid>
    );
};


const lngVar = (props) => {
    let title = "Language variables"
    document.title = title
    return (
        <>
            <DrawerExpand headerText={title}>
                <Main props={props} />
            </DrawerExpand>
        </>
    );
};


const mapDispatchToProps = {
    getAllData, getAllProperty, updateLanguage
}

const mapStateToProps = (state) => {
    return {
        data: state.language
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(lngVar);
