

const typeUser = [
    {code:'root', name: 'Quản trị', color:"#3c3c3c"},
    {code:'leader', name: 'Trưởng nhóm', color:'#009688'},
    {code:'manager', name: 'Quản lý', color:'#e91e63'},
    {code:'saler', name: 'Sale', color:'#43627b'},
    {code:'telesale', name: 'Tele Sale', color:'#420ba4'}
]

export {typeUser}