import _ from 'lodash';
import moment from 'moment';
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { getLastestMessage } from "../../../actions/chat";
import config from '../../../config/index';

const LastedMessage = memo((props) => {

    const { lastedMessage, profile, currentChatMessage, typingMessage } = props
    let chatMessageLasted = [...lastedMessage]

    useEffect(() => {
        // loadMessage('')
        // eslint-disable-next-line
    }, [])

    // const loadMessage = (message) => {
    //     props.getLastestMessage(message)
    // }

    const loadDetail = (item) => {
        props.getListMessageDetail(item)
    }

    let indexActive = 0;

    //when select other lasted message
    if (_.has(currentChatMessage, 'code') === false && chatMessageLasted.length > 0) {
        props.getListMessageDetail(chatMessageLasted[0])
    }

    //check active lasted message
    if (_.has(currentChatMessage, 'code') === true && chatMessageLasted.length > 0) {
        indexActive = _.findIndex(chatMessageLasted, (e) => {
            return e.groupChatCode === currentChatMessage.code;
        });
    }

    var typing = {}
    if (typingMessage.length > 0) {
        typing = typingMessage[0]
    }

    return (
        <div className="main-ListChat">
            <ul className="listMessage">
                {chatMessageLasted.map((item, index) => {
                    if (_.get(item, 'groupChat.type', '') === 'single') {
                        var indexEmp = item.receiverEmployeeCodeArr.findIndex(sss => sss !== profile.employeeCode)
                        var name = ''
                        var prof = item.receiverChatArr[indexEmp].profile
                        name = `${_.get(prof, 'firstName', '')} ${_.get(prof, 'lastName', '')}`
                        return <li className={`itemMess ${indexActive === index ? `active ` : ""}`} key={item._id} onClick={() => loadDetail(item)}>
                            <div className="avatar alone">
                                {_.get(prof, 'fileAvatar.path', '') === '' ? _.get(prof, 'lastName', '').charAt(0) : <div className="ctImg"><img src={config.baseURL + _.get(prof, 'fileAvatar.path', '')} alt={_.get(prof, 'lastName', '')} /></div>}
                                {_.includes(props.chatUserOnline, item.receiverIdArr[indexEmp]) === true && <div className="online"></div>}
                            </div>
                            <div className={`bodyMess ${_.findIndex(item.viewerChatArr, { id: profile._id }) < 0 ? "none-read" : ""}`}>
                                <div className='circleNone'></div>
                                <div className="nameMess">{name !== '' ? name : item.groupChat.name}</div>
                                <div className="briefMess">
                                    {profile.employeeCode === item.senderEmployeeCode ? 'You: ' : ''}{item.type === 'text' ? item.content : item.type === 'image' ? '[Hình ảnh]' : item.type === 'video' ? '[Video]' : '[Tệp]'}
                                    {(typingMessage.length > 0 && typing.data.isTyping === true && item.groupChatCode === typing.room)
                                        && <div className="typingGroup">
                                            <div className="spinner">
                                                <div className="bounce1"></div>
                                                <div className="bounce2"></div>
                                                <div className="bounce3"></div>
                                                <div className="bounce4"></div>
                                            </div>
                                        </div>}

                                </div>
                                <div className="timeMess">{moment(item.created).locale('en').startOf('minute').fromNow()}</div>
                            </div>
                        </li>
                    }
                    else {
                        return <li className={`itemMess ${indexActive === index ? `active ` : ""}`} key={item._id} onClick={() => loadDetail(item)}>
                            <div className="avatar group">
                                {_.get(item, 'receiverChatArr', []).map((receive, i) => {
                                    if (i < 2) {
                                        var prof = _.get(receive, 'profile', {})
                                        return <div className="avChild" key={i}>
                                            {_.get(prof, 'fileAvatar.path', '') === '' ? _.get(prof, 'lastName', '').charAt(0) : <img src={config.baseURL + _.get(prof, 'fileAvatar.path', '')} alt={_.get(prof, 'lastName', '')} />}
                                        </div>
                                    }
                                    else if (i === _.get(item, 'receiverChatArr', []).length - 1) {
                                        return <div className="avChild absol" key={i}>+{_.get(item, 'receiverChatArr', []).length - 2}</div>
                                    }
                                    else {
                                        return <span key={i}></span>
                                    }
                                })}
                            </div>
                            <div className={`bodyMess ${_.findIndex(item.viewerChatArr, { id: profile._id }) < 0 ? "none-read" : ""}`}>
                                <div className='circleNone'></div>
                                <div className="nameMess">{item.groupChat.name}</div>
                                <div className="briefMess">
                                    {profile.employeeCode === item.senderEmployeeCode ? 'You: ' : ''}{item.type === 'text' ? item.content : item.type === 'image' ? '[Hình ảnh]' : item.type === 'video' ? '[Video]' : '[Tệp]'}
                                    {(typingMessage.length > 0 && typing.data.isTyping === true && item.groupChatCode === typing.room)
                                        && <div className="typingGroup">
                                            <div className="spinner">
                                                <div className="bounce1"></div>
                                                <div className="bounce2"></div>
                                                <div className="bounce3"></div>
                                                <div className="bounce4"></div>

                                            </div>
                                        </div>}
                                </div>
                                <div className="timeMess">{moment(item.created).locale('en').startOf('minute').fromNow()}</div>

                            </div>
                        </li>
                    }
                })}
            </ul>

        </div>
    );
});

const mapDispatchToProps = {
    getLastestMessage
}
const mapStateToProps = (state) => {

    return {
        chatUserOnline: state.chatUserOnline,
        lastedMessage: state.chatMessageLasted,
        chatGroup: state.chatGroup,
        userAll: state.user,
        profile: state.profile,
        typingMessage: state.typingMessage,
        currentChatMessage: state.currentChatMessage
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LastedMessage);
