import { Avatar, Link } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DrawerExpand from '../../elements/drawerExpand';



const Block = (props) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box component={Link} sx={{
                p: 2,
                flex: 1,
                backgroundColor: '#fff',
                alignItems: 'center',
                borderRadius: 2,
                display: 'flex',
                textDecoration: 'auto',
                color: grey[600],borderWidth: 0.5, borderColor: '#f0f1f2', borderStyle: 'solid', boxShadow:'0px 2px 5px #eaf1fb'

            }}
                href={props?.href}
            >
                <Avatar
                    variant="square"
                    sx={{ width: 38, height: 38, mr: 1.5 }}
                    alt="Working & Operating" src={props?.icon} />
                {props?.name}
            </Box>
        </Box>
    )
}

const Main = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        dispatch({
            type: "SET_ENUM",
            payload: {
                navigate: '',
                activeName: '',
                activeModule: ''
            }
        })
        localStorage.removeItem('navigate')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>

            <Box sx={{ p: 2, paddingLeft:0 }}>
                <Box
                    sx={{
                        display: 'grid',
                        gap: 2,
                        mb: 2,
                        gridTemplateColumns: 'repeat(5, 1fr)',
                    }}>
                    <Block name={t('frmSetPartner')} href="/partner" icon={"/resources/icons/contact.png"} />
                    <Block name={t('frmSetData')} href="/data" icon={"/resources/icons/data.png"} />
                    <Block name={t('frmSetFinacial')} href="/financial-year"icon={"/resources/icons/year.png"} />
                    <Block name={t('frmSetBeginning')} href="/beginning-balance" icon={"/resources/icons/begining.png"} />
                    <Block name={t('frmSetAccountant')} href="/accountant" icon={"/resources/icons/account.png"} />
                    <Block name={t('frmSetBank')} href="/bank-account" icon={"/resources/icons/bank.png"} />
                    <Block name={t('frmSetDepartment')} href="/department" icon={"/resources/icons/department.png"} />
                    <Block name={t('frmSetEmployee')} href="/employee" icon={"/resources/icons/employee.png"} />
                    <Block name={t('frmSetUser')} href="/user" icon={"/resources/icons/user.png"} />
                    <Block name={t('frmSetLanguage')} href="/language" icon={"/resources/icons/language.png"} />
                    <Block name={t('frmSetExpense')} href="/expense" icon={"/resources/icons/expense.png"} />
                    <Block name={t('frmSetCurrency')} href="/currency" icon={"/resources/icons/currency.png"} />
                    <Block name={t('frmSetTaxPolicy')} href="/vat" icon={"/resources/icons/tax.png"} />
                    <Block name={t('frmSetAccountPolicy')} href="/rule" icon={"/resources/icons/transaction.png"} />
                    <Block name={t('frmSetProject')} href="/project" icon={"/resources/icons/product.png"} />
                    <Block name={t('frmSetProduct')} href="/product" icon={"/resources/icons/product.png"} />
                    <Block name={t('frmSetPerGroup')} href="/permission-group" icon={"/resources/icons/group.png"} />
                    <Block name={t('frmSetLangVariable')} href="/langs" icon={"/resources/icons/langvariable.png"} />
                    <Block name={t('frmSetRequest')} href="/request" icon={"/resources/icons/request.png"} />
                    <Block name={t('frmSetRequestQueue')} href="/req-queue" icon={"/resources/icons/queue.png"} />
                    <Block name={t('frmSetRequestTemplate')} href="/req-template" icon={"/resources/icons/queue.png"} />
                    <Block name={t('frmSetPartnerGroup')} href="/partner-group" icon={"/resources/icons/contact.png"} />
                </Box>
              
            </Box>
        </>
    )
}

const Setting = (props) => {
    const { t } = useTranslation()

    let title = t("lblSetting")
    document.title = title
    return (
        <>
            <DrawerExpand headerText={title}>
                <Main />
            </DrawerExpand>
        </>
    );
};


export default Setting;