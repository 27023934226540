import React, { useState } from 'react';

import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import StreamOutlinedIcon from '@mui/icons-material/StreamOutlined';
import { Badge, Divider, IconButton } from '@mui/material';
const VerticalMenu = props => {

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <div className='verticalMenuRight'>
            <div className='contentVertical'>
                {open ?
                    <div className='toolRight'>
                        <IconButton aria-label="calendar" onClick={()=>{
                            props?.handleSetNavigate({
                                path: '/task',
                                navigate:'task',
                                module: 'WORK',
                                name: 'Task',
                                type: 'child'
                            })
                        }}>
                            <CalendarMonthOutlinedIcon />
                        </IconButton>
                        <IconButton aria-label="calendar" onClick={()=>{
                            window.location.href = '/request'
                        }}>
                            <Badge color="primary"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                badgeContent=" " variant="dot">
                                <AssignmentTurnedInOutlinedIcon />
                            </Badge>
                        </IconButton>
                        <IconButton aria-label="calendar">
                            <NoteAddOutlinedIcon />
                        </IconButton>
                        <IconButton aria-label="calendar">
                            <StreamOutlinedIcon />
                        </IconButton>
                        <IconButton aria-label="calendar">
                            <AddTaskOutlinedIcon />
                        </IconButton>
                        <IconButton aria-label="calendar">
                            <ChatBubbleOutlineOutlinedIcon />
                        </IconButton>
                        <Divider />
                    </div>
                    :
                    <>
                    </>}

                {open === true ? <IconButton variant="contained" color='primary' aria-label="calendar" onClick={handleClose} className="fabRightBtn">
                    <NavigateBeforeOutlinedIcon />
                </IconButton> : <IconButton aria-label="calendar" onClick={handleOpen} className="fabRightBtn">
                    <NavigateNextOutlinedIcon />
                </IconButton>}


            </div>
        </div>
    );
};



export default VerticalMenu;