
import api from "../api/index"
import _ from "lodash"
if(_.isEmpty(api.defaults.headers['token'])===true)
{
    api.defaults.headers['token'] = localStorage.getItem('token')
}

export const getLastestMessage = (message) => (dispatch) => {
    var str = ''
    if(message)
    {
        str = `?lastMessageId=${message}`
    }
    return api.get(`/chat-message/get-latest-messages${str}`)
        .then(res => {
            dispatch({
                type:"SET_CHATMESSAGE_LASTED",
                payload: res.data.data
            })
        return Promise.resolve({ success: res.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const getMessageGroupByCode = (code) => (dispatch) => {
    var str = ''
    if(code)
    {
        str = `&groupCode=${code}`
    }
    return api.get(`/chat-group/get-by-code?limit=20${str}`)
        .then(res => {
            dispatch({
                type:"SET_CURRENT_CHAT_MESSAGE",
                payload: res.data.data
            })
        return Promise.resolve({ success: res.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err })
        })
}

export const getMessageGroupByReceiverId = (receiverId) => (dispatch) => {
   
    return api.get(`/chat-group/get-by-receiver-id?receiverId=${receiverId}`)
        .then(res => {
            dispatch({
                type:"SET_CURRENT_CHAT_MESSAGE",
                payload: res.data.data
            })
        return Promise.resolve({ success: res.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const getMessageGroupLoadMore = (lastMessageId, groupCode) => (dispatch) => {
    return api.get(`/chat-message/get-for-group?lastMessageId=${lastMessageId}&groupCode=${groupCode}`)
        .then(res => {
            if(res.data.data.length>0)
            {
                dispatch({
                    type:"CREATE_CURRENT_CHAT_MESSAGE",
                    payload: res.data.data
                })
            }
        return Promise.resolve({ success: res.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const getCreateChatGroup = (data) => (dispatch) => {
    
    return api.post(`/chat-group`, data)
        .then(res => {
        return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const addMemberChatGroup = (data) => (dispatch) => {
    
    return api.post(`/chat-group/add-member`, data)
        .then(res => {
        return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}

export const removeMemberChatGroup = (data) => (dispatch) => {
    
    return api.post(`/chat-group/remove-member`, data)
        .then(res => {
        return Promise.resolve({ success: res.data.data })
        })
        .catch((err) => {
            return Promise.reject({ error: err.response.data })
        })
}


export const uploadImageChat = (data) => (dispatch) => {
  
    
    return api.post(`/file/upload?moduleName=chatMessage`,data)
    .then(res => {
        return Promise.resolve({ success: res.data.data })
    })
    .catch((err) => {
        return Promise.reject({ error: err.response.data })
    })
}


export const deleteChat = (data) => (dispatch) => {
    return api.post(`/chat-message/withdraw-message`,data)
    .then(res => {
        return Promise.resolve({ success: res.data.data })
    })
    .catch((err) => {
        return Promise.reject({ error: err.response.data })
    })
}
