import { Chip, Stack, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { orange, teal } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import * as React from 'react';

const ListNeedCare = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);

  const openDialogMain = () => {
    setOpen(true);
  };

  const closeDialogMain = () => {
    setOpen(false);
  };

  React.useImperativeHandle(ref, () => ({
    openDialog: () => {
        openDialogMain()
    }
  }))

  const theme = useTheme()

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={closeDialogMain}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{background: theme.palette.primary.main, color:'#fff'}}>
          {"Danh sách lịch hẹn!"}
        </DialogTitle>
        <DialogContent style={{paddingTop: 32}}>
          {/* <DialogContentText id="alert-dialog-description">
            Thông tin cuộc hẹn đã tạo
          </DialogContentText> */}
            {props?.data?.map((res, ind)=>{
                return <Stack direction={'column'} key={ind} mb={2} pb={2} sx={{borderBottom:'0.5px dashed #ccc'}}>
                    <Stack direction={'row'} justifyContent="space-between">
                        <Typography variant='subtitle1' sx={{color: orange[500]}}>{moment(res?.date).format('DD-MM-YYYY HH:ss')}</Typography>
                        <Chip label= {res?.type==="normal"?'Cuộc hẹn': 'Xem dự án'} color="primary"/>
                    </Stack>
                    <Typography variant='subtitle2' sx={{color: teal[500], mb: 1}}>K/H: {res?.who}</Typography>
                    <Typography variant='subtitle2'>{res?.projectName}</Typography>
                    <Typography variant='body'>Đ/c đưa đón: {res?.pickUpLocation}</Typography>
                    <Typography variant='body'>Đ/c hẹn:{res?.location}</Typography>
                    <Typography variant='body'>Th/gia: {res?.numberParticipants} (người)</Typography>
                    <Typography variant='body2'><i>{res?.description}</i></Typography>
                </Stack>
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogMain}>Đóng</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})


export default  ListNeedCare;