import * as React from 'react';
import { useState, useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonGroup, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { getRequestTemplateById, updateRequestTemplate } from '../../../actions/requestTemplate';
import { getAllDataNoneRedux as getQueue } from '../../../actions/requestQueue';
import { getAllDataNoneRedux as getUser } from '../../../actions/user';
import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';
import { find } from 'lodash';


let typeArr = [
    {
        name: 'Text',
        value: 'text'
    },
    {
        name: 'Single choice',
        value: 'radio'
    },
    {
        name: 'Multiple choice',
        value: 'checked'
    },
    {
        name: 'Select',
        value: 'select'
    }
]

const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)

    const [
        { name, isActive, code, description,
            listQueue, dataExample,
            isRequired, queueId,
            type, actions, template, typeInfo, nameInfo,
            _id
        },
        setState
    ] = useState({ ...editObject });

    const getDataById = () => {
        props?.getRequestTemplateById(_id).then(res => {
            let data = res?.data
            setState(state => ({ ...state, ...data }))
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        const init = () => {
            props?.getUser()
                .then(res => {
                    setState(state => ({ ...state, listUser: res?.data }));
                })

            props?.getQueue()
                .then(res => {
                    setState(state => ({ ...state, listQueue: res?.data }));
                })
        }
        init()
        getDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const handleSubmit = () => {
        let data = {
            name, code, isActive, description, template, actions, type, queueId
        }
        props?.updateRequestTemplate(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
            })
    }
    const delQueue = (index) => {
        let que = [...template]
        que.splice(index, 1)
        let tmp = que.map((item, i) => ({ ...item, order: i + 1 }))
        setState(state => ({ ...state, template: tmp }));
    }

    const onRowAdd = () => {
        let listUserTmp = [...template]
        let templ = []

        if (dataExample) {
            templ = dataExample.split(',')
        }

        let obj = {
            order: template.length + 1,
            note: '',
            type: typeInfo,
            name: nameInfo,
            data: templ,
            isRequired: isRequired,
            default: ''
        }
        listUserTmp.push(obj)
        setState(state => ({
            ...state, template: listUserTmp,
            typeInfo: '', nameInfo: '', dataExample: ''
        }))
    }

    React.useImperativeHandle(ref, () => ({ handleSubmit }));

    return (


        <Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 12, md: 12 }}>

            <Grid item xs={12} md={4} >
                <FormGroup >
                    <FormControlLabel control={<Checkbox />}
                        checked={isActive}
                        name="isActive"
                        onChange={(e) => customeOnChange('isActive', !isActive)}
                        label="Active" />
                </FormGroup>
            </Grid>

            <Grid item md={8} xs={12} >
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off" >
                    <TextField
                        label="Code "
                        id="code"
                        value={code}
                        name="code"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item md={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                        label="Name"
                        id="last-name"
                        value={name}
                        name="name"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item lg={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Note"
                        id="last-description"
                        value={description}
                        multiline={true}
                        minRows={4}
                        name="description"
                        onChange={onChange}
                    />
                </Box>
            </Grid>

       
            <Grid item md={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 0, marginTop: 24 }}>
                <div>
                    <Typography variant='title' fontWeight={600}>Request queue</Typography>
                    <Typography variant='subtitle2'>Queue will be applied when the request is created.</Typography>
                </div>

                <FormControl sx={{
                    minWidth: 235,
                    '& .MuiInputLabel-root': {
                        width: 90,
                        background: '#fff'
                    }
                }} size="small">
                    <InputLabel id="demo-simple-select-label">Queue by</InputLabel>
                    <Select
                        autoWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={queueId}
                        label="Type"
                        onChange={(e) => {
                            setState(state => ({ ...state, queueId: e.target.value }))
                        }}>
                        {
                            listQueue?.map((res, i) => {
                                return <MenuItem value={res?._id} key={i}>{res?.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

            </Grid>


            <Grid item md={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 0, marginTop: 24 }}>
                <div>
                    <Typography variant='title' fontWeight={600}>Required information</Typography>
                    <Typography variant='subtitle2'>Information will be required when the request is created.</Typography>
                </div>

                <FormControl sx={{
                    minWidth: 235,
                    '& .MuiInputLabel-root': {
                        width: 90,
                        background: '#fff'
                    }
                }} size="small">
                    <InputLabel id="demo-simple-select-label">Request for</InputLabel>
                    <Select
                        autoWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Type"
                        onChange={(e) => {
                            setState(state => ({ ...state, type: e.target.value }))
                        }}>
                        <MenuItem value={'IC'}>Income</MenuItem>
                        <MenuItem value={'OC'}>Outcome</MenuItem>
                        <MenuItem value={'DRB'}>Debit Note</MenuItem>
                        <MenuItem value={'CRB'}>Credit Note</MenuItem>
                        <MenuItem value={'JE'}>Journal Entries</MenuItem>
                    </Select>
                </FormControl>

            </Grid>

            <Grid item md={12} xs={12} style={{ paddingTop: 12 }}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="left">Required</TableCell>
                                <TableCell align="left">Data</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {template.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {i + 1}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{find(typeArr,{value: row.type})?.name}</TableCell>
                                    <TableCell align="left">{row.isRequired ? 'Yes' : 'None'}</TableCell>
                                    <TableCell align="left">{row?.data.toString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => delQueue(i)} color="error" aria-label="Cancel" component="label" disableElevation>
                                            <CloseIcon />
                                        </IconButton></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>

            <Grid item md={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                    noValidate
                    autoComplete="off" >
                    <Typography variant='body' style={{ width: 40, textAlign: 'center' }}>{template.length + 1}.</Typography>

                    <TextField
                        required
                        id="outlined-required"
                        label="Name"
                        sx={{ minWidth: 240 }}
                        defaultValue=""
                        size='small'
                        value={nameInfo}
                        onChange={(e) => {
                            setState(state => ({ ...state, nameInfo: e.target.value }))
                        }}
                    />

                    <FormControl sx={{
                        minWidth: 120,
                        '& .MuiInputLabel-root': {
                            width: 60,
                            background: '#fff'
                        }
                    }} size="small">
                        <InputLabel id="demo-simple-select-label2" >Type</InputLabel>
                        <Select
                            required
                            autoWidth
                            labelId="demo-simple-select-label2"
                            id="demo-simple-select2"
                            value={typeInfo}
                            onChange={(e) => {
                                setState(state => ({ ...state, typeInfo: e.target.value }))
                            }}>
                             {typeArr?.map((res,ii)=>{
                                return <MenuItem value={res?.value}>{res?.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    <FormControlLabel
                        value={isRequired}
                        control={<Checkbox onChange={(e) => {
                            setState(state => ({ ...state, isRequired: e.target.checked }))
                        }} />}
                        label={<Typography variant='body2' color='primary'>Required</Typography>}
                        labelPlacement="top"
                    />

                    <ButtonGroup
                        disableElevation
                        variant="text"
                        aria-label="Disabled elevation buttons">
                        <IconButton color="primary"
                            onClick={() => onRowAdd()}
                            aria-label="Save" component="label">
                            <CheckIcon />
                        </IconButton>
                    </ButtonGroup>


                </Box>

                {['select', 'radio', 'checked'].indexOf(typeInfo) > -1 ?
                    <TextField
                        id="outlined-required-data"
                        label="The data is separated by commas (,) "
                        sx={{ minWidth: '100%', marginTop: 1.5 }}
                        defaultValue=""
                        size='small'
                        value={dataExample}
                        onChange={(e) => {
                            setState(state => ({ ...state, dataExample: e.target.value }))
                        }}
                    />
                    : <></>}
            </Grid>
        </Grid>
    );
})


const mapDispatchToProps = {
    getRequestTemplateById, updateRequestTemplate, getUser, getQueue
}

const mapStateToProps = (state) => {
    return {
        editObj: state.edit
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditForm);