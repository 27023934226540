import _ from "lodash";

const initialState = {
    isAuthenticated: false,
    token:''
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CURRENT_PARTNER":
        return {
            ...state,
            isAuthenticated: !_.isEmpty(action.payload),
            token: action.payload.token
        }
        default:
            return {
                ...state
            }
    }

    // return state
}

export default authReducer