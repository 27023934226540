import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const generateCode = () => (dispatch) => {
  return setTokenAxios().get(`credit-note/generate-code`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data.code })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getCreditNoteByCode = (id) => (dispatch) => {
  return setTokenAxios().get(`credit-note/get-by-code/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getCreditNoteById = (id) => (dispatch) => {
  return setTokenAxios().get(`credit-note/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`credit-note`, {params: condition})
    .then(async res => {
      dispatch({
        type: "SET_CREDIT_NOTE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`credit-note`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataMore = (condition) => (dispatch) => {
  return setTokenAxios().get(`credit-note`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_MORE_CREDIT_NOTE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const createCreditNote = (data) => (dispatch) => {
  return setTokenAxios().post(`credit-note`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_CREDIT_NOTE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateCreditNote = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`credit-note/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_CREDIT_NOTE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const activeCreditNote = (_id, data) => (dispatch) => {
  return setTokenAxios().put(`credit-note/${_id}/actived`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_CREDIT_NOTE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const starCreditNote = (_id, data) => (dispatch) => {
  return setTokenAxios().put(`credit-note/${_id}/star`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_CREDIT_NOTE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const deleteCreditNote = (_id) => (dispatch) => {
  return setTokenAxios().delete(`credit-note/${_id}`)
    .then(async res => {
      dispatch({
        type:"DELETE_CREDIT_NOTE",
        payload: res.data.data
    })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const starMultipleCreditNote = (data) => (dispatch) => {
  return setTokenAxios().post(`credit-note/stared`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_CREDIT_NOTE_MULTI",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const UnActive = (data) => (dispatch) => {
  return setTokenAxios().post(`credit-note/unactived`,data )
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}