import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";



export const getBeginningBalanceById = (id) => (dispatch) => {
  return setTokenAxios().get(`beginning-balance/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`beginning-balance`, {params:condition})
    .then(async res => {
      dispatch({
        type: "SET_BEGINNING_BALANCE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`beginning-balance`, {params:condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const createBeginningBalance = (data) => (dispatch) => {
  return setTokenAxios().post(`beginning-balance`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_BEGINNING_BALANCE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateBeginningBalance = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`beginning-balance/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_BEGINNING_BALANCE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteBeginningBalance = ( _id) => (dispatch) => {
  return setTokenAxios().delete(`beginning-balance/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_BEGINNING_BALANCE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}