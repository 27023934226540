import axios from "axios";
import jwtDecode from "jwt-decode";


const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['token'] = token;
  } 
  // else {
  //   delete axios.defaults.headers.common['token']
  // }
}

const checkTokenValid = () => {
  const token = localStorage.getItem("token");
  if(!token) return false;
  const decoded = jwtDecode(token);
  if (decoded.exp < new Date().getTime() / 1000) {
      return false;
  }
  return true;
}


export {setAuthToken, checkTokenValid};