import SearchIcon from '@mui/icons-material/Search';
import { FormControl, InputAdornment, OutlinedInput, Stack, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useState } from 'react';
import BreadCrumb from '../../elements/breadCrumb';
import DrawerExpand from '../../elements/drawerExpand';
import RowMenu from '../../elements/rowMenu';

import { green, indigo, pink } from '@mui/material/colors';
import { debounce, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { deleteEmployee, getAllData } from '../../../actions/employee';
import { setEditObject } from '../../../actions/untils';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import MainForm from '../../elements/MainForm';
import AddForm from './AddForm';
import EditForm from './EditForm';


const Main = (params) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { props } = params
  const theme = useTheme()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [type, setType] = useState('add')
  const [inputValue, setInputValue] = useState('')
  const actionAdd = useSelector(state => state?.enumRedux?.actionAdd)
  const editObject = useSelector(state => state.edit)

  const mainFormRef = React.useRef();
  const childFormRef = React.useRef();

  let listData = [...props?.data]


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (actionAdd === true) {
      if (type !== 'edit' && type !== 'delete') {
        setType('add')
      }
      mainFormRef.current?.openFromParent();
    }
    //eslint-disable-next-line
  }, [actionAdd])

  useEffect(() => {
    if (isEmpty(editObject)) {
      setType('add')
    }
  }, [editObject])

  const handleActionMenu = async (value, type) => {
    mainFormRef.current?.openFromParent();
    setType(type)
    if (type === 'delete') {
      await props?.setEditObject({})
      deleteData(value)
    }
    else {
      if (type !== 'add') {
        dispatch({
          type: "SET_ENUM",
          payload: {
            actionAdd: true,
          }
        })
        await props?.setEditObject(value)
      }
    }
  }

  const deleteData = (value) => {
    props?.deleteEmployee(value?._id)
      .then(async res => {
        setType('')
        await props?.setEditObject({})
        _showAlertSuccess(res?.message || GET_SUCCESS)
      })
  }

  useEffect(() => {
    props?.getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderRow = () => {

    let list = rowsPerPage > 0
      ? listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : listData

    return list.map((row, index) => (
      <TableRow key={row._id} >
        <TableCell align="left">
          <RowMenu
            data={row}
            actionEdit={handleActionMenu}
            sx={{ mr: 2 }} />
          {page * rowsPerPage + index + 1}.
        </TableCell>
        <TableCell align="left">
          <div style={{ color: row?.isActive === true ? green[700] : pink[400] }}>
            {row?.code}
          </div>
        </TableCell>
        <TableCell component="th" scope="row" sx={{
          borderWidth: 0.5
        }}>
          <Typography variant='subtitle2' color={indigo[500]} sx={{ lineHeight: 1 }}>
            {row?.firstName} {row?.lastName}
          </Typography>

          <Typography variant='caption'>
            {row?.phone?.phoneNumber}
          </Typography>
        </TableCell>

      </TableRow>
    ))
  }

  const onChangeInput = (e) => {
    const { value } = e.target
    setInputValue(value)
  }

  const searchAuto = debounce((e) => {
    if (e.keyCode === 13) {
      var data = {
        "condition": {},
        "sort": {
          "created": -1
        },
        "search": inputValue,
        "limit": 20,
        "page": 1
      }
      props?.getAllData(data)
    }

  }, 1000)

  const handleSave = async () => {
    childFormRef?.current?.handleSubmit();
  }

  let breadcrumb = [{ name: t('lblHome'), href: '/' }, { name: t('frmEmployeeTitle'), href: '/employee' }]
  return (
    <>
      <Stack direction={'row'} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <BreadCrumb data={breadcrumb} />
        <Stack direction={'row'} alignItems="center">

          <FormControl sx={{ m: 1, width: '25ch', background: '#ffffffa8', borderRadius: 1 }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-search"
              value={inputValue}
              name="inputValue"
              placeholder='...'
              onKeyDown={searchAuto}
              size='small'
              sx={{
                '& fieldset': {
                  border: 'none'
                },
                '& input': {
                  fontSize: '.9rem'
                }
              }}
              onChange={onChangeInput}
              endAdornment={<InputAdornment position="end"><SearchIcon sx={{ color: '#979797' }} /></InputAdornment>}
              inputProps={{
                'aria-label': 'search',
              }}
            />
          </FormControl>

        </Stack>
      </Stack>
      <TableContainer component={Paper} sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 0,
        padding: 2,
        borderRadius: 2
      }}>
        {/* <Box sx={{ fontSize: 24 }}>
        
        </Box> */}
        <Table sx={{ minWidth: 650 }} aria-label="caption table">

          <TableHead>
            <TableRow sx={{
              fontWeight: 'bold'
            }}>
              <TableCell sx={{ width: 90 }}>{t('lblColNo')}</TableCell>
              <TableCell sx={{ width: 180 }}>{t('lblColCode')}</TableCell>
              <TableCell>{t('lblColName')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow()}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8, 16, 25, { label: t('pagingAll'), value: -1 }]}
                colSpan={6}
                count={listData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': t('pagingPerPage'),
                  },
                  native: true,
                }}
                labelRowsPerPage={t('pagingTotal')}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {type !== 'delete' ?
        <MainForm ref={mainFormRef} maxWidth={'lg'} actionSave={handleSave} subtitle={t('titEmployee')} title={type === 'add' && isEmpty(editObject) ? t('lblCreate') : t('lblUpdate')} bg={'#DDE3EA'}>
          {type === 'add' && isEmpty(editObject) ? <AddForm ref={childFormRef} /> : <EditForm ref={childFormRef} />}
        </MainForm>
        : <></>}

    </>
  )
}

const Employee = (props) => {
  const { t } = useTranslation();
  let title = t('frmEmployeeTitle')
  document.title = title
  return (
    <>
      <DrawerExpand headerText={title}>
        <Main props={props} />
      </DrawerExpand>
    </>
  );
};

const mapDispatchToProps = {
  getAllData, setEditObject, deleteEmployee
}

const mapStateToProps = (state) => {
  return {
    data: state.employee
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Employee);