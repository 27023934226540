import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import moment from 'moment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllDataNoneRedux as getBeginBlance } from '../../../actions/beginningBalance';
import { getAllData as getExchangeRate } from '../../../actions/exchangeRate';
import { getReportBalanceCash, getReportCashPaymentGroupDepartment, getReportCashPaymentGroupExpense } from '../../../actions/report';

import CountUp from 'react-countup';
import { ccyFormat, dollarFormat } from '../../../untils/number';
import DrawerExpand from '../../elements/drawerExpand';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// import vi from "date-fns/locale/vi";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { find, sumBy } from 'lodash';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useNavigate } from 'react-router-dom';
import { defaultStaticRanges } from "../../../enums/DefaultRange";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const staticRangesLabels = {
    Today: "Today",
    Yesterday: "Yesterday",
    "This Week": "This Week",
    "This Month": "This Month",
    "Last Month": "Last Month"
};

const now = new Date();

const exDate = {
    startDate: new Date(now.getFullYear(), now.getMonth(), 1),
    endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
}

const Main = (params) => {
    let navigate = useNavigate();
    const { props } = params
    const [openDate, setOpenDate] = React.useState(false);
    const [rangeDate, setRangeDate] = useState([
        {
            startDate: new Date(now.getFullYear(), now.getMonth(), 1),
            endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
            key: "selection"
        }
    ]);
    const { t } = useTranslation()
    const [beginBlance, setBeginBlance] = useState([])
    const [topExpense, setTopExpense] = useState([])
    const [topDepartment, setTopDepartment] = useState([])
    const [exchange, setExchange] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dataCurrent = useSelector(state => state?.enumRedux?.dataCurrent)

    // const [anchorElBank, setAnchorElBank] = React.useState(null);

    const [cashDebit1011, setCashDebit1011] = useState(0)
    const [cashCredit1011, setCashCredit1011] = useState(0)
    const [cashDebit10121, setCashDebit10121] = useState(0)
    const [cashCredit10121, setCashCredit10121] = useState(0)
    const [cashDebit10122, setCashDebit10122] = useState(0)
    const [cashCredit10122, setCashCredit10122] = useState(0)

    const [cashDebitOri10121, setCashDebitOri10121] = useState(0)
    const [cashCreditOri10121, setCashCreditOri10121] = useState(0)
    const [cashDebitOri10122, setCashDebitOri10122] = useState(0)
    const [cashCreditOri10122, setCashCreditOri10122] = useState(0)
    const [load1, setLoad1] = useState(false)
    const [load2, setLoad2] = useState(false)
    const [load3, setLoad3] = useState(false)

    const onChangeRange = (ranges) => {
        const { selection } = ranges;
        setRangeDate([selection]);
    };

    function translateRange(dictionary) {
        return (item) =>
            dictionary[item.label]
                ? { ...item, label: dictionary[item.label] }
                : item;
    }

    const ruStaticRanges = defaultStaticRanges.map(
        translateRange(staticRangesLabels)
    );

    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    const endDate =
        rangeDate[0].endDate === null
            ? formatDate(rangeDate[0].startDate)
            : formatDate(rangeDate[0].endDate);
    const startDate = rangeDate[0].startDate ? formatDate(rangeDate[0].startDate) : "";

    const handleFilter = async () => {
        await init()
        setOpenDate(false)
    }

    const getBalance = async (cond) => {
        return await props?.getReportBalanceCash(cond)
            .then(res => {
                return res.data
            })
    }

    const getExpense = async (cond) => {
        return await props?.getReportCashPaymentGroupExpense(cond)
            .then(res => {
                return res.data
            })
    }

    const getExchange = async (cond) => {
        return await props?.getExchangeRate()
            .then(res => {
                setExchange(res.data)
            })
    }


    const getDepartment = async (cond) => {
        return await props?.getReportCashPaymentGroupDepartment(cond)
            .then(res => {
                return res.data
            })
    }

    const getBeginBlance = async () => {
        let cond = {
            condition: {
                year: { equal: '2023' }
            }
        }
        await props?.getBeginBlance(cond).then(res => {
            setBeginBlance(res?.data)
        })
    }

    const init = async () => {
        let cond = {
            condition: {}
        }

        let condExp = {
            condition: {}
        }

        if (startDate !== '' && endDate !== '') {
            cond.condition.date = {
                "from": startDate,
                "to": endDate
            }
            condExp.condition.date = {
                "from": startDate,
                "to": endDate
            }
        }
        cond.condition.accountNumber = '1011'
        cond.condition.dataCode = {
            in: [JSON.parse(localStorage.getItem('dataCurrent'))?.code]
        }

        condExp.condition.dataCode = {
            in: [JSON.parse(localStorage.getItem('dataCurrent'))?.code]
        }

        let data1011 = await getBalance(cond)
        setCashDebit1011(data1011.dataDebit.sumAmount)
        setCashCredit1011(data1011.dataCredit.sumAmount)

        cond.condition.accountNumber = '10121'
        let data10121 = await getBalance(cond)
        setCashDebit10121(data10121.dataDebit.sumAmount)
        setCashCredit10121(data10121.dataCredit.sumAmount)
        setCashDebitOri10121(data10121.dataDebit.sumOriginal)
        setCashCreditOri10121(data10121.dataCredit.sumOriginal)

        cond.condition.accountNumber = '10122'
        let data10122 = await getBalance(cond)
        setCashDebit10122(data10122.dataDebit.sumAmount)
        setCashCredit10122(data10122.dataCredit.sumAmount)
        setCashDebitOri10122(data10122.dataDebit.sumOriginal)
        setCashCreditOri10122(data10122.dataCredit.sumOriginal)

        condExp.condition.accountNumber = ['1011', '10121', '10122']
        let expense = await getExpense(condExp)
        setTopExpense(expense)

        let department = await getDepartment(condExp)
        setTopDepartment(department)
    }

    useEffect(() => {
        getBeginBlance()
        getExchange()
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        init()
        // eslint-disable-next-line
    }, [dataCurrent?.code])


    const renderPicker = () => {
        return <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={openDate}
            onClose={() => setOpenDate(false)}
        >
            <DialogTitle>Date</DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <DateRangePicker
                    onChange={onChangeRange}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    editableDateInputs={true}
                    dateDisplayFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    ranges={rangeDate}
                    direction="horizontal"
                    // locale={vi}
                    rangeColors={["#00a870"]}
                    staticRanges={ruStaticRanges}
                />


            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDate(false)}>Close</Button>
                <Button onClick={() => handleFilter()}>Accept</Button>
            </DialogActions>
        </Dialog>
    }

    let begin = find(beginBlance, { accountNumber: '1011' })?.beginDebitAmount || 0
    let resAll = begin + (cashDebit1011 - cashCredit1011) + (cashDebit10121 - cashCredit10121) + (cashDebit10122 - cashCredit10122)
    let res1011 = cashDebit1011 - cashCredit1011

    let ori10121 = cashDebitOri10121 - cashCreditOri10121
    let ori10122 = cashDebitOri10122 - cashCreditOri10122

    let cashIncome = cashDebit1011 + cashDebit10121 + cashDebit10122
    let cashOutcome = cashCredit1011 + cashCredit10121 + cashCredit10122

    let sumExpense = sumBy(topExpense, 'total')
    let sumDepartment = sumBy(topDepartment, 'total')

    const handleMenu = (event) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    // const handleMenuBank = (event) => {
    //     if (anchorElBank !== event.currentTarget) {
    //         setAnchorElBank(event.currentTarget);
    //     }
    // }

    const handleClose = (key) => {
        setAnchorEl(null);
        switch (key) {
            case 0:
                navigate("/report?active=0");
                break;
            case 1:
                navigate("/report?active=1");
                break;
            case 2:
                navigate("/report?active=2");
                break;
            case 3:
                navigate("/report?active=3");
                break;
            case 4:
                navigate("/report?active=4");
                break;
            case 5:
                navigate("/report-bank?active=0");
                break;
            case 6:
                navigate("/report-bank?active=1");
                break;
            case 7:
                navigate("/report-bank?active=2");
                break;
            case 8:
                navigate("/report-bank?active=3");
                break;
            case 9:
                navigate("/report-bank?active=4");
                break;
            default:
                break;
        }
    }

    // const handleCloseBank = (key) => {
    //     setAnchorElBank(null);
    //     switch (key) {
    //         case 0:
    //             navigate("/report-bank?active=0");
    //             break;
    //         case 1:
    //             navigate("/report-bank?active=1");
    //             break;
    //         case 2:
    //             navigate("/report-bank?active=2");
    //             break;
    //         case 3:
    //             navigate("/report-bank?active=3");
    //             break;
    //         case 4:
    //             navigate("/report-bank?active=4");
    //             break;
    //         default:
    //             break;
    //     }
    // }

    return (
        <>
            {renderPicker()}
            <Box sx={{ minHeight: 48, display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: 4 }}>
                <div>
                    <Button variant='text' href="/cash-analysis"
                        sx={{ padding: '6px 14px' }}
                        startIcon={<DataSaverOffOutlinedIcon />}
                        className='tabAnalysis active'>{t('lblAnalysis')}</Button>

                    <Button variant='text' className='tabAnalysis'
                        sx={{ padding: '6px 14px' }}
                        startIcon={<AssessmentOutlinedIcon />}
                        aria-owns={anchorEl ? "simple-menu" : undefined}
                        aria-haspopup="true"
                        endIcon={<ArrowDropDownIcon fontSize='small' />}
                        onMouseOver={handleMenu}>{t('lblReport')}</Button>

                    {/* <Button variant='text' className='tabAnalysis'
                        sx={{ padding: '6px 14px' }}
                        startIcon={<AssessmentOutlinedIcon />}
                        aria-owns={anchorEl ? "simple-menu-bank" : undefined}
                        aria-haspopup="true"
                        endIcon={<ArrowDropDownIcon fontSize='small' />}
                        onMouseOver={handleMenuBank}>Bank</Button> */}
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => setOpenDate(true)}>
                    <Typography variant='body' color={"#2196f3"}>
                        {
                            (moment(startDate).format('DD-MM-YYYY') === moment(exDate.startDate).format('DD-MM-YYYY') &&
                                moment(endDate).format('DD-MM-YYYY') === moment(exDate.endDate).format('DD-MM-YYYY'))
                                ?
                                <>{t('lblCurrentMonth')} - {moment().format("MMM YYYY")}</>
                                :
                                moment(startDate).format('DD/MM/YYYY') + ' - ' + moment(endDate).format('DD/MM/YYYY')
                        }

                    </Typography>
                    <FilterListOutlinedIcon fontSize='medium' sx={{ color: "#2196f3", ml: 2 }} />
                </div>
            </Box>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}>
                <MenuItem
                    sx={{ width: 250 }}
                    onClick={() => handleClose(0)}>
                    <ListItemIcon>
                        <DataUsageOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashFlow')}
                        secondary={t('lblRpBriefCashFlow')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleClose(1)}>
                    <ListItemIcon>
                        <SummarizeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashsum')}
                        secondary={t('lblRpBriefCashsum')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleClose(2)}>
                    <ListItemIcon>
                        <SaveAltOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashInFlow')}
                        secondary={t('lblRpBriefCashInFlow')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleClose(3)}>
                    <ListItemIcon>
                        <FileUploadOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashOutFlow')}
                        secondary={t('lblRpBriefCashOutFlow')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleClose(4)}>
                    <ListItemIcon>
                        <CurrencyExchangeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpCashBal')}
                        secondary={t('lblRpBriefCashBal')}
                    />
                </MenuItem>

                <div style={{ height: 0.5, backgroundColor: '#00000014', width: '100%' }}></div>

                <MenuItem
                    sx={{ width: 250 }}
                    onClick={() => handleClose(5)}>
                    <ListItemIcon>
                        <DataUsageOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpBankFlow')}
                        secondary={t('lblRpBriefBankFlow')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleClose(6)}>
                    <ListItemIcon>
                        <SummarizeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpBanksum')}
                        secondary={t('lblRpBriefBanksum')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleClose(7)}>
                    <ListItemIcon>
                        <SaveAltOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpBankInFlow')}
                        secondary={t('lblRpBriefBankInFlow')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleClose(8)}>
                    <ListItemIcon>
                        <FileUploadOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpBankOutFlow')}
                        secondary={t('lblRpBankOutFlow')}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleClose(9)}>
                    <ListItemIcon>
                        <CurrencyExchangeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('lblRpBankBal')}
                        secondary={t('lblRpBriefBankBal')}
                    />
                </MenuItem>

            </Menu>

            {/* <Menu
                id="simple-menu-bank"
                anchorEl={anchorElBank}
                open={Boolean(anchorElBank)}
                onClose={handleCloseBank}
                MenuListProps={{ onMouseLeave: handleCloseBank }}>
                <MenuItem
                    sx={{ width: 250 }}
                    onClick={() => handleCloseBank(0)}>
                    <ListItemIcon>
                        <DataUsageOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Bank flow"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseBank(1)}>
                    <ListItemIcon>
                        <SummarizeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Bank summary"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseBank(2)}>
                    <ListItemIcon>
                        <SaveAltOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Bank inflow"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseBank(3)}>
                    <ListItemIcon>
                        <FileUploadOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Bank outflow"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => handleCloseBank(4)}>
                    <ListItemIcon>
                        <CurrencyExchangeOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Bank balance"
                        secondary={'Show all notification content'}
                    />
                </MenuItem>
            </Menu> */}

            <Box sx={{ p: 2 }}>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', boxShadow: '3px 3px 10px #f2f2f2',
                                    borderLeft: '8px solid #03a9f4',
                                    p: 2, borderRadius: 3, background: 'white'
                                }}>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant='button' >{t('lblAnaCashBal')}</Typography>
                                        <FilterListOutlinedIcon fontSize='small' sx={{ color: "#2196f3" }} />
                                    </Box>
                                    <Typography variant='subtitle2' color={"#2196f3"}>{t('lblCurrentDay')} - {moment().format("MMM Do YYYY")}</Typography>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row',
                                        color: '#2196f3',
                                        justifyContent: 'flex-end', marginTop: 1, marginBottom: 1
                                    }}>
                                        <Typography variant='h3' sx={{ mr: 4 }}>
                                            {load1 ?
                                                resAll < 0 ? `-` + ccyFormat(Math.abs(resAll)) : ccyFormat(resAll)
                                                :
                                                <CountUp
                                                    end={Math.abs(cashDebit1011 - cashCredit1011)}
                                                    duration={0.9}
                                                    delay={0}
                                                    onEnd={() => setLoad1(true)}
                                                />
                                            }



                                        </Typography>
                                        <Typography variant='h5' sx={{ position: 'relative' }}>LAK
                                            <div style={{ position: "absolute", right: '-16px', top: 0, fontSize: 14 }}>(*)</div>
                                        </Typography>
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row', justifyContent: 'space-between'
                                    }}>

                                        <Typography variant='caption' fontStyle={'italic'} color={'#2e2e2e'}
                                            sx={{
                                                alignSelf: 'flex-end',
                                                flex: '0.6',
                                                display: 'flex',
                                                textAlign: 'justify'
                                            }}
                                        >
                                            (*) {t('lblAnaDesExchange')}, {moment().format('MMM Do YYYY')}
                                        </Typography>

                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row', justifyContent: 'flex-end'
                                            }}>
                                                <Typography variant='subtitle1' sx={{ mr: 4 }}>
                                                    <span className="fmNumber">
                                                        {dollarFormat(ori10121)}
                                                    </span></Typography>
                                                <Typography variant='subtitle1'>USD</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                <Typography variant='subtitle1' sx={{ mr: 4 }}>
                                                    <span className="fmNumber">{dollarFormat(ori10122)}
                                                    </span></Typography>
                                                <Typography variant='subtitle1' >THB</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                <Typography variant='subtitle1' sx={{ mr: 6 }} >
                                                    <span className="fmNumber">{ccyFormat(res1011)}</span></Typography>
                                                <Typography variant='subtitle1'>LAK</Typography>
                                            </Box>
                                        </Box>

                                    </Box>

                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', boxShadow: '3px 3px 10px #f2f2f2', p: 2, borderRadius: 3, background: 'white' }}>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant='button'>{t('lblAnaCashIn')}</Typography>
                                        <FilterListOutlinedIcon fontSize='small' sx={{ color: "#2196f3" }} />
                                    </Box>
                                    <Typography variant='subtitle2' color={"#2196f3"}>{t('lblCurrentMonth')} - {moment().format("MMM YYYY")}</Typography>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row',
                                        color: '#137333',
                                        justifyContent: 'flex-end', marginTop: 1, marginBottom: 1
                                    }}>
                                        <Typography variant='h6' sx={{ mr: 2 }}>
                                            {load2 ?
                                                ccyFormat(cashIncome)
                                                :
                                                <CountUp
                                                    end={cashIncome}
                                                    duration={0.7}
                                                    delay={0}
                                                    onEnd={() => setLoad2(true)}
                                                />
                                            }
                                        </Typography>
                                        <Typography variant='h6'>LAK</Typography>
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row', justifyContent: 'flex-end'
                                    }}>
                                        <Typography variant='body1' sx={{ mr: 2 }}>
                                            <span className="fmNumber">
                                                {dollarFormat(cashDebitOri10121)}
                                            </span></Typography>
                                        <Typography variant='body1'>USD</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Typography variant='body1' sx={{ mr: 2 }}>
                                            <span className="fmNumber">{dollarFormat(cashDebitOri10122)}</span></Typography>
                                        <Typography variant='body1'>THB</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Typography variant='body1' sx={{ mr: 4 }}><span className="fmNumber">{ccyFormat(cashDebit1011)}</span></Typography>
                                        <Typography variant='body1'>LAK</Typography>
                                    </Box>

                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', boxShadow: '3px 3px 10px #f2f2f2', p: 2, borderRadius: 3, background: 'white' }}>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant='button' >{t('lblAnaCashOut')}</Typography>
                                        <FilterListOutlinedIcon fontSize='small' sx={{ color: "#2196f3" }} />
                                    </Box>
                                    <Typography variant='subtitle2' color={"#2196f3"}>{t('lblCurrentMonth')} - {moment().format("MMM YYYY")}</Typography>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row',
                                        color: '#a50e0e',
                                        justifyContent: 'flex-end', marginTop: 1, marginBottom: 1
                                    }}>
                                        <Typography variant='h6' sx={{ mr: 2 }}>
                                            {load3 ?
                                                ccyFormat(cashOutcome)
                                                :
                                                <CountUp
                                                    end={cashOutcome}
                                                    duration={0.9}
                                                    delay={0}
                                                    onEnd={() => setLoad3(true)}
                                                />
                                            }
                                        </Typography>
                                        <Typography variant='h6'>LAK</Typography>
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row', justifyContent: 'flex-end'
                                    }}>
                                        <Typography variant='body1' sx={{ mr: 2 }}>
                                            <span className="fmNumber">
                                                {dollarFormat(cashCreditOri10121)}
                                            </span></Typography>
                                        <Typography variant='body1'>USD</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Typography variant='body1' sx={{ mr: 2 }}>
                                            <span className="fmNumber">
                                                {dollarFormat(cashCreditOri10122)}</span></Typography>
                                        <Typography variant='body1'>THB</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Typography variant='body1' sx={{ mr: 4 }}>
                                            <span className="fmNumber">
                                                {ccyFormat(cashCredit1011)}</span></Typography>
                                        <Typography variant='body1'>LAK</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%', boxShadow: '3px 3px 10px #f2f2f2', p: 2, borderRadius: 3, background: 'white' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='button' >{t('lblAnaTopEx')}</Typography>
                                <FilterListOutlinedIcon fontSize='small' sx={{ color: "#2196f3" }} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
                                <Typography variant='subtitle2' color={"#2196f3"}>{t('lblCurrentMonth')} - {moment().format("MMM YYYY")}</Typography>
                                <Typography variant='subtitle2' >Total (LAK)</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {topExpense?.map((item, index) => {
                                    return index < 10 && <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1, borderBottom: '1px solid #f6f6f6' }}>
                                        <div className='leftTableAnalysis'>
                                            <Typography sx={{ width: '20px' }}>{index + 1}.</Typography>
                                            <Typography sx={{ width: '250px' }}>{item?._id?.expenseCode ? item?._id?.expenseCode + ' - ' + item?._id?.expense : 'Other'}</Typography>
                                        </div>
                                        <div className='rangeAnalysis'>
                                            <div className='widthRange' style={{ width: Math.round(item.total * 100 / sumExpense) }}></div>
                                        </div>
                                        <Typography sx={{
                                            color: '#202124',
                                            fontWeight: 600,
                                            letterSpacing: '.00625em'
                                        }}><div className="fmNumber">{ccyFormat(item?.total)}</div></Typography>
                                    </Box>
                                })}

                                {topExpense.length > 10 ? <Typography variant='subtitle1' color={"#2196f3"} alignSelf="flex-end">...more</Typography> : ""}
                            </Box>

                        </Box>
                    </Grid>

                    <Grid item xs={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%', boxShadow: '3px 3px 10px #f2f2f2', p: 2, borderRadius: 3, background: 'white' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='button' >{t('lblAnaTopDep')}</Typography>
                                <FilterListOutlinedIcon fontSize='small' sx={{ color: "#2196f3" }} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
                                <Typography variant='subtitle2' color={"#2196f3"}>{t('lblCurrentMonth')} - {moment().format("MMM YYYY")}</Typography>
                                <Typography variant='subtitle2' >{t('lblAnaTotal')} (LAK)</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {topDepartment?.map((item, index) => {
                                    return index < 10 && <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1, borderBottom: '1px solid #f6f6f6' }}>
                                        <div className='leftTableAnalysis'>
                                            <Typography sx={{ width: '20px' }}>{index + 1}.</Typography>
                                            <Typography sx={{ width: '250px' }}>{item?._id?.departmentCode ? item?._id?.departmentCode + ' - ' + item?._id?.department : 'Other'}</Typography>
                                        </div>
                                        <div className='rangeAnalysis'>
                                            <div className='widthRange' style={{ width: Math.round(item.total * 100 / sumDepartment) }}></div>
                                        </div>
                                        <Typography sx={{
                                            color: '#202124',
                                            fontWeight: 600,
                                            letterSpacing: '.00625em'
                                        }}><span className="fmNumber">{ccyFormat(item?.total)}</span></Typography>
                                    </Box>
                                })}

                                {topDepartment.length > 10 ? <Typography variant='subtitle1' color={"#2196f3"} alignSelf="flex-end">...more</Typography> : ""}
                            </Box>

                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', boxShadow: '3px 3px 10px #f2f2f2', p: 2, borderRadius: 3, background: 'white' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='button' >{t('lblAnaExchange')}</Typography>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant='button' sx={{ color: "#2196f3", mr: 1, borderBottom: '0.5px solid #2196f3' }} >BCEL</Typography>
                                    <FilterListOutlinedIcon fontSize='small' sx={{ color: "#2196f3" }} />
                                </div>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
                                <Typography variant='subtitle2' color={"#2196f3"}>{t('lblCurrentDay')} - {moment().format("MMM Do YYYY")}</Typography>
                                <Typography variant='subtitle2' ></Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {exchange.map((item, ind) => {
                                    return <Box key={ind} sx={{
                                        display: 'flex', flexDirection: 'row',
                                        alignContent: 'center', alignItems: 'center',
                                        padding: '6px 0px',
                                        justifyContent: 'space-between', mb: 1, borderBottom: '1px solid #f6f6f6'
                                    }}>
                                        <Typography sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                            <div className='iconCurrency'>
                                                {item?.from?.symbol}
                                            </div> {item?.from?.name}</Typography>
                                        <Typography>{dollarFormat(item?.buy)}</Typography>
                                        <Typography>{dollarFormat(item?.sell)}</Typography>
                                    </Box>
                                })}
                                <Typography variant='subtitle1' color={"#2196f3"} alignSelf="flex-end">...more</Typography>

                            </Box>

                        </Box>
                    </Grid>


                </Grid>
            </Box>
        </>

    );
};


const Analytics = (props) => {
    let title = "Cash Analytics"
    document.title = title

    return (
        <>
            <DrawerExpand headerText={title}>
                <Main props={props} />
            </DrawerExpand>
        </>
    );
};

const mapDispatchToProps = {
    getReportBalanceCash, getBeginBlance, getReportCashPaymentGroupExpense, getReportCashPaymentGroupDepartment, getExchangeRate
}

export default connect(null, mapDispatchToProps)(Analytics);