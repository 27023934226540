import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Badge, Box, Chip, IconButton, Link, Stack, Typography, useTheme } from '@mui/material';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { UnActive, activeAdvancePayment, deleteAdvancePayment, getAllData, getAllDataMore, starAdvancePayment, starMultipleAdvancePayment } from '../../../actions/advancePayment';
import { getPaymentByParent } from '../../../actions/payment';
import { setEditObject } from '../../../actions/untils';
import { ccyFormat } from '../../../untils/number';

// import { BottomSheet } from 'react-spring-bottom-sheet';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import 'react-spring-bottom-sheet/dist/style.css';
import MainForm from '../../elements/MainForm';
import DrawerExpand from '../../elements/drawerExpand';
import CustomizedMenus from '../../elements/rightMenu';
import AddForm from './AddForm';
import EditForm from './EditForm';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { green, pink } from '@mui/material/colors';
import Swal from 'sweetalert2';
// import { NumberToText } from '../../../untils/functions';
import { find, isEmpty, map, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { _showAlertError, _showAlertSuccess } from '../../../untils/error';
import Request from '../advancePayment/Request';
import BottomDetail from './BottomDetail';
import DetailForm from './DetailForm';
import FilterForm from './FilterForm';
import ImportForm from './ImportForm';
import DialogComment from './components/DialogComment';
import DialogPrint from './components/DialogPrint';

const Main = (params) => {
  const { props } = params
  // const [dateStart, setDateStart] = React.useState(moment().startOf('year').format('YYYY-MM-DD'));//new Date(new Date().getFullYear(), 0, 1)
  // const [dateEnd, setDateEnd] = React.useState(moment().endOf('day').format('YYYY-MM-DD'));//new Date(new Date().getFullYear(), 0, 1)
  const [dateStart, setDateStart] = React.useState(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'));//
  const [dateEnd, setDateEnd] = React.useState(moment().endOf('day').format('YYYY-MM-DD'));//
  const theme = useTheme()
  const { t } = useTranslation()
  const [page, setPage] = useState(1);
  //eslint-disable-next-line 
  const [rowsPerPage, setRowsPerPage] = useState(24);
  const mainFormRef = React.useRef();
  const childFormRef = React.useRef();
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [indexRow, setIndexRow] = React.useState(null);
  const [dataMenu, setDataMenu] = React.useState({});
  const [openDetail, setOpenDetail] = useState(false)
  const [openBottom, setOpenBottom] = useState(false)
  const [openImport, setOpenImport] = useState(false);
  const [listChecked, setListChecked] = React.useState([]);
  const [debitAccount, setDebitAccount] = React.useState([]);
  const [creditAccount, setCreditAccount] = React.useState([]);
  const [department, setDepartment] = React.useState({});
  const [expense, setExpense] = React.useState({});
  const [totalDocuments, setTotalDocuments] = React.useState(0);

  //eslint-disable-next-line 
  const [isPrint, setIsPrint] = useState(false)
  const [isComment, setIsComment] = useState(false)
  const [isRequest, setIsRequest] = useState(false)
  const [listBottomSheet, setListBottomSheet] = React.useState([]);
  const [dataParent, setDataParent] = React.useState({});
  const actionAdd = useSelector(state => state?.enumRedux?.actionAdd)
  const dataCurrent = useSelector(state => state?.enumRedux?.dataCurrent)

  const editObject = useSelector(state => state.edit)

  const dispatch = useDispatch()
  const [type, setType] = useState('add')

  let listData = [...props?.data]

  useEffect(() => {
    if (actionAdd === true) {
      setType('add')
      mainFormRef.current?.openFromParent();
    }
  }, [actionAdd])

  const handleChangePage = (event, newPage) => {
    if (newPage > page && listData.length < totalDocuments) {
      getMoreData(newPage)
    }
  };

  const handleActionMenu = async (value, type) => {
    mainFormRef.current?.openFromParent();
    setType(type)
    if (type !== 'add') {
      dispatch({
        type: "SET_ENUM",
        payload: {
          actionAdd: true,
        }
      })
      await props?.setEditObject(value)
      setOpenBottom(false)
    }
  }

  const handleSave = async () => {
    childFormRef.current?.handleSubmit();
  }
  const getAllDataWithCondition = async (cond) => {
    cond.condition.dataCode = {
      in: [JSON.parse(localStorage.getItem('dataCurrent'))?.code]
    }
    await props?.getAllData(cond)
      .then(res => {
        setPage(res?.meta?.page)
        setTotalDocuments(res?.meta?.totalDocuments)
      })
  }

  const getMoreData = (newPage) => {
    const cond = {
      condition: {
        date: {
          from: dateStart,
          to: dateEnd,
        }
      },
      "search": '',
      "sort": {
        "created": -1
      },
      "limit": rowsPerPage,
      "page": newPage
    }

    if (expense?.code) {
      cond.condition.expenseLst = {
        in: [expense?.code]
      }
    }

    if (department?.code) {
      cond.condition.departmentLst = {
        in: [department?.code]
      }
    }

    if (creditAccount.length > 0) {
      cond.condition.creditLst = {
        in: creditAccount
      }
    }

    if (debitAccount.length > 0) {
      cond.condition.deditLst = {
        in: debitAccount
      }
    }

    cond.condition.dataCode = {
      in: [JSON.parse(localStorage.getItem('dataCurrent'))?.code]
    }

    props?.getAllDataMore(cond)
      .then(res => {
        setPage(res?.meta?.page)
      })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [dataCurrent?.code])


  const init = async () => {
    const cond = {
      condition: {
        date: {
          from: dateStart,
          to: dateEnd,
        },
      },
      "sort": {
        "created": -1
      },
      "limit": rowsPerPage,
      "page": page
    }

    await getAllDataWithCondition(cond)
  }

  useEffect(() => {
    init()
    dispatch({
      type: "SET_ENUM",
      payload: {
        dataFromPage: {}
      }
    })

    dispatch({
      type: "SET_ENUM",
      payload: {
        statusFilter: {
          dateStart,
          dateEnd,
          debitAccount,
          creditAccount,
          department,
          expense,
          isApply: false
        }
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilter = async (cond, value) => {
    await getAllDataWithCondition(cond)
    closeFilter()
    setDateStart(value.dateStart)
    setDateEnd(value.dateEnd)
    setDebitAccount(value?.debitAccount)
    setCreditAccount(value?.creditAccount)
    setDepartment(value?.department)
    setExpense(value?.expense)
    setListChecked([])
    dispatch({
      type: "SET_ENUM",
      payload: {
        statusFilter: {
          dateStart: value?.dateStart,
          dateEnd: value?.dateEnd,
          debitAccount: value?.debitAccount,
          creditAccount: value?.creditAccount,
          department: value?.department,
          expense: value?.expense,
          isApply: true
        }
      }
    })
  }

  const handleOpenFilter = () => { setOpenFilter(true) }

  const closeFilter = () => { setOpenFilter(false) }


  const handleRightClick = (event, val, index) => {
    event.preventDefault();
    setAnchorEl(event);
    setDataMenu(val)
    setIndexRow(index)
  }

  const renderRow = () => {

    return listData.map((row, index) => (
      <TableRow key={row._id} onContextMenu={(e) => handleRightClick(e, row, index)} className={`${dataParent?._id === row?._id ? 'activeRow' : ''}`}>
        <TableCell className="firstCell" align="left" sx={{ paddingX: '0px!important' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <IconButton onClick={() => handleCheckedRow(row)}>
              {
                listChecked.indexOf(row?._id) > -1 ?
                  <CheckBoxOutlinedIcon />
                  :
                  <CheckBoxOutlineBlankOutlinedIcon />
              }
            </IconButton>
          </div>
        </TableCell>
        <TableCell sx={{ paddingX: '0px!important' }}>
          <IconButton onClick={() => handleStar(row)}>
            {row?.isStar ? <StarOutlinedIcon sx={{ color: '#ff9800' }} fontSize='small' /> : <StarOutlineOutlinedIcon fontSize="small" />}
          </IconButton>
        </TableCell>
        <TableCell align="right" onClick={(e) => handleOpenBottom(row)}>{moment(row?.date).format('DD/MM/YYYY')}</TableCell>
        <TableCell align="left" onClick={(e) => handleOpenBottom(row)}>
          <div style={{ color: row?.isActive === true ? green[700] : pink[400] }}>
            {row?.code}
          </div>
        </TableCell>
        <TableCell align="right" onClick={(e) => handleOpenBottom(row)}>
          <div className='fmNumberB'>
            {ccyFormat(row.amount)}
          </div>
        </TableCell>
        <TableCell align="left" onClick={(e) => handleOpenBottom(row)}>
          <Typography noWrap maxWidth={350}>
            {row?.description}
          </Typography>
          <Stack direction="row" spacing={1}>
            {row?.department?.name ? <Chip sx={{ height: 20 }} variant="outlined" label={row?.department?.name} color="primary" size="small" /> : <></>}
            {/* <Chip sx={{height:20}} label={row?.project?.name} color="primary" size="small" /> */}
          </Stack>
        </TableCell>
        <TableCell component="th" scope="row" sx={{
          borderWidth: 0.5
        }} onClick={(e) => handleOpenBottom(row)}>
          <Typography noWrap >{row?.customer?.firstName} {row?.customer?.lastName}</Typography>
        </TableCell>

      </TableRow>
    ))
  }


  const handleOpenDetail = async (val) => {
    await props?.getPaymentByParent(val?.code).then(res => {
      setDataParent(val)
      setListBottomSheet(res.data)
      setOpenDetail(true)
    })
  }

  const handleOpenBottom = async (val) => {
    await props?.getPaymentByParent(val?.code).then(res => {
      setDataParent(val)
      setListBottomSheet(res.data)
      setOpenBottom(true)
      dispatch({
        type: "SET_ENUM",
        payload: {
          openDrawer: false,
        }
      })
    })
  }
  const handleDelete = (val) => {
    Swal.fire({
      title: 'Warning!',
      html: `<span style="font-size:15px">${t('alrDelete')}</span>`,
      showDenyButton: true,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: `${t('btnConfirm')}`,
      denyButtonText: `${t('btnDelete')}`,
      focusConfirm: false,
      focusDeny: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        props?.deleteAdvancePayment(val._id).then(res => {
          _showAlertSuccess(`${t('lblSuccess')}` || '')
        })
          .catch(err => {
            _showAlertError(`${t('lbError')}` || '')
          })
      } else if (result.isDenied) {
        //   Swal.fire('Changes are not saved', '', 'info')
      }
    })

  }

  const handleActive = (val) => {
    setIsRequest(true)
  }

  const handleApprove = () => {
    setIsRequest(false)
  }

  const handleStar = (val) => {
    props?.starAdvancePayment(val._id, { isStar: !val.isStar }).then(res => {
      _showAlertSuccess(`${t('alrSuccess')}` || '')
    }).catch(err => {
      _showAlertError(`${t('alrError')}` || '')
    })
  }

  const handleComment = () => {
    setIsComment(true)
  }

  const closeComment = () => { setIsComment(false) }

  const handlePrint = async (val) => {
    // eslint-disable-next-line
    //setIsPrint(val)
    console.log(val)
  }

  const handleDuplicate = (val) => {
    dispatch({
      type: 'SET_DUPPLICATE',
      payload: { ...val }
    })

    dispatch({
      type: "SET_ENUM",
      payload: {
        actionAdd: true,
      }
    })
  }

  // const handleImport = () => { setOpenImport(true) }

  const closeHdImport = () => { setOpenImport(false) }
  const closeRequest = () => { setIsRequest(false) }


  const handleCheckedRow = (val) => {
    let tmp = [...listChecked]
    let ind = tmp.indexOf(val?._id)
    if (ind > -1) {
      tmp.splice(ind, 1)
    }
    else {
      tmp.push(val?._id)
    }
    dispatchSelectAll(tmp)
    setListChecked(tmp)
  }

  const handleCheckedAll = (val) => {
    let tmp = [...listChecked]
    if (listData?.length === tmp.length) {
      tmp = []
    }
    else {
      tmp = map(listData, '_id')
    }
    dispatchSelectAll(tmp)
    setListChecked(tmp)
  }


  const dispatchSelectAll = (tmp) => {
    let sumSelectAll = 0
    let totalSelectAll = 0

    if (tmp.length > 0) {
      let tmp1 = listData.filter(x => tmp.indexOf(x?._id) > -1)
      totalSelectAll = tmp1.length
      sumSelectAll = sumBy(tmp1, 'total')
      dispatch({
        type: "SET_ENUM",
        payload: {
          dataFromPage: { totalSelectAll, sumSelectAll }
        }
      })
    }
    else {
      dispatch({
        type: "SET_ENUM",
        payload: {
          dataFromPage: {}
        }
      })
    }

  }

  const handleStarAll = () => {
    let data = { arrayId: listChecked, isStar: true }
    props?.starMultipleAdvancePayment(data).then(res => {
      _showAlertSuccess(`${t('alrSuccess')}` || '')
    }).catch(err => {
      _showAlertError(`${t('alrError')}` || '')
    })
  }

  // const handleUnactiveAll = () => {
  //   let data = { arrayId: listChecked }
  //   props?.UnActive(data).then(res => {
  //     _showAlertSuccess(`${t('alrSuccess')}` || '')
  //   }).catch(err => {
  //     _showAlertError(`${t('alrError')}` || '')
  //   })
  // }

  const handleApproveAll = () => {
    let tmp = [...listChecked]
    let data = find(listData, { _id: tmp[0] })

    if (data?._id) {
      setDataMenu(data)
      handleActive()
    }
  }

  const handleSetNavigate = (item) => {
    dispatch({
      type: "SET_ENUM",
      payload: {
        navigate: item.navigate,
        activeModule: item.module,
        activeName: t(`${item.name}`)
      }
    })

    localStorage.setItem('navigate', item.navigate)
    localStorage.setItem('activeModule', item.module)
    localStorage.setItem('activeName', t(`${item.name}`))
  }



  return (
    <>
      <Box sx={{ justifyContent: 'space-between', display: 'flex', pt: 1, pl: 2 }}>
        <div className='toolTop'>
          <IconButton onClick={() => handleCheckedAll()} style={{ backgroundColor: "transparent" }}>
            <Badge badgeContent={listChecked.length} color="primary">
              {listChecked.length > 0 ?
                <CheckBoxOutlinedIcon />
                :
                <CheckBoxOutlineBlankOutlinedIcon />
              }
            </Badge>
          </IconButton>
          |
          <IconButton sx={{ ml: 0.5 }}>
            <ReplayOutlinedIcon />
          </IconButton>
          {
            listChecked.length > 0 ?
              <>
                {listChecked.length === 1 &&
                  <IconButton onClick={() => handleApproveAll()}>
                    <BeenhereOutlinedIcon />
                  </IconButton>
                }
                <IconButton onClick={() => handleStarAll()}>
                  <StarOutlineOutlinedIcon />
                </IconButton>
              </>
              :
              <>
                <IconButton disabled>
                  <BeenhereOutlinedIcon />
                </IconButton>
                <IconButton disabled>
                  <StarOutlineOutlinedIcon />
                </IconButton>
              </>
          }

          {/* <IconButton>
              <ImportExportOutlinedIcon />
            </IconButton> */}

          <div className='blockTopBtn'>
            <IconButton onClick={handleOpenFilter}>
              <FilterListOutlinedIcon />
            </IconButton>
          </div>

          <IconButton>
            <MoreVertOutlinedIcon />
          </IconButton>
        </div>
        <div className='rightTop'>
          <Link href="/journal-entries" onClick={() => handleSetNavigate({
            name: 'leftMenuJE',
            module: 'Accounting and Financial',
            navigate: 'journal-entries',
          })}>Journal Entries</Link>
          <Link href="/financial-settlement" onClick={() => handleSetNavigate({
            name: 'leftMenuFS',
            module: 'Accounting and Financial',
            navigate: 'settlement',
          })}>Final Settlement</Link>
        </div>
      </Box>
      <TableContainer className='customTable2' component={Paper} sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 0,
        padding: 2,
        paddingTop: 0,
        height: 'calc(100vh - 155px)',
        borderRadius: theme.shape.borderRadius
      }}>

        <Table sx={{ minWidth: 650 }} aria-label="caption table" stickyHeader className='customerTableHover'>

          <TableHead sx={{
            fontWeight: 'bold',
            '& .MuiTableCell-root': {
              backgroundColor: '#Fff'
            }
          }}>
            <TableRow >
              <TableCell sx={{ width: 56 }}>{t('lblColNo')}</TableCell>
              <TableCell sx={{ width: 40 }}></TableCell>
              <TableCell sx={{ width: 60 }}>{t('lblColDate')}</TableCell>
              <TableCell sx={{ width: 120 }}>{t('lblColCode')}</TableCell>
              <TableCell sx={{ width: 150 }} align="right">{t('lblColAmount')} (₭)</TableCell>
              <TableCell align="left">{t('lblColDescription')}</TableCell>
              <TableCell >{t('lblColPartner')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow()}
          </TableBody>

        </Table>


      </TableContainer>


      <MainForm ref={mainFormRef} maxWidth={'lg'} actionSave={handleSave} subtitle={t('titAdvancePayment')} title={type === 'add' && isEmpty(editObject) ? t('lblCreate') : t('lblUpdate')} bg={'#DDE3EA'}>
        {type === 'add' && isEmpty(editObject) ? <AddForm ref={childFormRef} /> : <EditForm ref={childFormRef} />}
      </MainForm>

      <div className='bottomPaging'>
        {listData?.length} / {totalDocuments}
        <IconButton aria-label="delete" onClick={(e) => handleChangePage(e, page + 1)}>
          <KeyboardArrowDownOutlinedIcon fontSize='small' />
        </IconButton>
      </div>

      {anchorEl ? <CustomizedMenus anchorEl={anchorEl} data={dataMenu}
        handleActive={handleActive}
        activeText={t('lblApprove')}
        activeIcon={<BeenhereOutlinedIcon fontSize="small" />}
        closeRequest={closeRequest}
        handleApprove={handleApprove}
        handleStar={handleStar}
        handleDelete={handleDelete}
        handlePrint={handlePrint}
        handleComment={handleComment}
        handleDuplicate={handleDuplicate}
        indexRow={indexRow}
        handleOpen={handleOpenDetail}
        handleEdit={handleActionMenu} type="transaction" /> : <></>}

      {openImport && <ImportForm isOpen={openImport} handleClose={closeHdImport} />}
      {isComment && <DialogComment isOpen={isComment} handleClose={closeComment} data={dataMenu} />}
      {isPrint ? <DialogPrint isOpen={isPrint} handlePrint={handlePrint} /> : <></>}
      {openFilter && <FilterForm
        dateStart={dateStart}
        dateEnd={dateEnd}
        debitAccount={debitAccount}
        creditAccount={creditAccount}
        department={department}
        expense={expense}
        isOpen={openFilter} handleClose={closeFilter} handleFilter={handleFilter} />}
      {isRequest ? <Request isOpen={isRequest} data={dataMenu} closeRequest={closeRequest} /> : <></>}
      {openDetail ? <DetailForm isOpen={openDetail}
        listBottomSheet={listBottomSheet}
        handleActionMenu={handleActionMenu}
        dataParent={dataParent} handleClose={() => setOpenDetail(false)} /> : <></>}

      {openBottom ? <BottomDetail isOpen={openBottom}
        listBottomSheet={listBottomSheet}
        handleActionMenu={handleActionMenu}
        handleOpenDetail={() => setOpenDetail(true)}
        dataParent={dataParent} handleClose={() => setOpenBottom(false)} /> : <></>}
    </>
  )
}

const AdvancePayment = (props) => {
  const { t } = useTranslation()
  let title = t('titAdvancePayment')
  document.title = title
  return (
    <>
      <DrawerExpand headerText={title}>
        <Main props={props} />
      </DrawerExpand>
    </>
  );
};

const mapDispatchToProps = {
  getAllData, setEditObject, getAllDataMore,
  getPaymentByParent, activeAdvancePayment, deleteAdvancePayment,
  starAdvancePayment, starMultipleAdvancePayment, UnActive
}

const mapStateToProps = (state) => {
  return {
    data: state.advancePayment
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancePayment);