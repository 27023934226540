import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const getReportBalanceCash = (condition) => (dispatch) => {
  return setTokenAxios().get(`report/balance-cash`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getReportCashPaymentGroup= (condition) => (dispatch) => {
  return setTokenAxios().get(`report/all-payment-group`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getReportCashPaymentHistory = (condition) => (dispatch) => {
  return setTokenAxios().get(`report/all-payment-history`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getReportCashPaymentGroupExpense= (condition) => (dispatch) => {
  return setTokenAxios().get(`report/all-payment-group-expense`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getReportCashPaymentGroupDepartment= (condition) => (dispatch) => {
  return setTokenAxios().get(`report/all-payment-group-department`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getReportCashBalanceReport= (condition) => (dispatch) => {
  return setTokenAxios().get(`report/balance-report`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getReportAllPaymentGroupExpense= (condition) => (dispatch) => {
  return setTokenAxios().get(`report/all-payment-group-expense-report`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getReportAllPaymentGroupDepartment= (condition) => (dispatch) => {
  return setTokenAxios().get(`report/all-payment-group-department-report`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


/// ledger

export const getLedgerHistory = (condition) => (dispatch) => {
  return setTokenAxios().get(`report-ledger/ledger-history`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getLedgerTrialBalance = (condition) => (dispatch) => {
  return setTokenAxios().get(`report-ledger/ledger-accounting-balance`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getReportCashPaymentHistoryLedger = (condition) => (dispatch) => {
  return setTokenAxios().get(`report-ledger/all-payment-history`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getReportPaymentHistoryWithChild = (condition) => (dispatch) => {
  return setTokenAxios().get(`report-ledger/all-payment-history-with-child`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

///temp

export const getUserCreateDoc = (condition) => {
  return setTokenAxios().get(`report/all-doc-groupByUser`, {params: condition})
    .then(async res => {
      return { message: GET_SUCCESS, data: res.data.data }
    })
    .catch((err) => {
      _showAlertError(err)
      return { message: GET_FAILURE, error: err.data.message }
    })
}

