const initialState = {
    isInit: false
}

const configReducer = (state = initialState, action) => {
   
    switch (action.type) {

        case "SET_IS_INIT":
            return {
                ...state,
                isInit: action.payload
            }

       
        default:
            break;
    }

    return state
}

export default configReducer