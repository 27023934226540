import { FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getAllData as getDataPrimary } from '../../../actions/data';
import { getFinancialYearById, updateFinancialYear } from '../../../actions/financialYear';
import { DATA_NOT_FOUND } from '../../../enums/vi';
import { _showAlertErrorMessage } from '../../../untils/error';
import { MenuProps } from '../../../untils/variable';



const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)
    const [
        { name, isActive, code, description, _id, dataCode },
        setState
    ] = useState({ ...editObject });

    const dispatch = useDispatch()
    const [start, setStart] = React.useState(new Date(new Date().getFullYear(), 0, 1));
    const [end, setEnd] = React.useState(new Date(new Date().getFullYear(), 11, 31));
    const listData = props?.data

    const getDataById = () => {
        props?.getFinancialYearById(_id).then(res => {
            setState(state => ({ ...state, ...res.data }))
            setStart(res.data.start)
            setEnd(res.data.end)
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    const init = () => {
        props?.getDataPrimary()
    }

    useEffect(() => {
        getDataById()
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const handleSubmit = () => {
        let tmp = code + ''
        let tmp2 = name + ''
        let data = { name: tmp2, isActive, code: tmp, start, end, description , dataCode}

        props?.updateFinancialYear(data, _id)
            .then(res => {
                dispatch({
                    type: "SET_ENUM",
                    payload: {
                        actionAdd: false,
                    }
                })
            })
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let arr = typeof value === 'string' ? value.split(',') : value
        setState(state => ({ ...state, dataCode: arr }))
    };
 
    React.useImperativeHandle(ref, () => ({ handleSubmit }));

    return (
        <Box sx={{ bgcolor: '#fff', flex: 1, padding: 1.5, position: 'relative' }}>
            <Grid container spacing={2}>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label="Name"
                                id="last-name"
                                value={name}
                                name="name"
                                onChange={onChange}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label="Code "
                                id="code"
                                value={code}
                                name="code"
                                onChange={onChange}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={4} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />}
                            checked={isActive}
                            name="isActive"
                            onChange={(e) => customeOnChange('isActive', !isActive)}
                            label="Active" />
                    </FormGroup>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                className='customeChooseDate'
                                format={'DD/MM/YYYY'}
                                value={dayjs(start)}
                                label={'Start'}
                                onChange={(newValue) => {
                                    setStart(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                className='customeChooseDate'
                                format={'DD/MM/YYYY'}
                                value={dayjs(end)}
                                label={'End'}
                                onChange={(newValue) => {
                                    setEnd(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>

                <Grid item xs={12} style={{ paddingLeft: 24 }}>
                    <Typography variant='body2' >Apply for data:</Typography>
                    <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            size='small'
                            value={dataCode}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}>
                            {listData.map((item) => (
                                <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                    <Checkbox checked={dataCode?.indexOf(item.code) > -1 ? 'checked' : ''} />
                                    <ListItemText primary={`${item.code} - ${item.name}`} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label="Note"
                                id="outlined-size-description"
                                multiline={true}
                                name="description"
                                onChange={onChange}
                                size="small"
                                minRows={5}
                                fullWidth={true}
                            />
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
})
const mapDispatchToProps = {
    getFinancialYearById, updateFinancialYear, getDataPrimary
}

const mapStateToProps = (state) => {
    return {
        editObj: state.edit,
        data: state.data
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditForm);