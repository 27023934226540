import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
// import { grey } from '@mui/material/colors';
import CustomeStepper from './elements/Stepper';


export default function EditForm() {
  const theme = useTheme()
  return (
    <>
    <Stack direction="row" spacing={1}
    justifyContent='space-between'
    alignItems="center" sx={{
        padding: 2,
        backgroundColor: theme.palette.primary.main
    }}>
        <Stack direction={'row'}>
            <Chip label="Sửa" variant="filled" sx={{mr: 2, backgroundColor: theme.palette.common.white}}/>
            <Typography variant='h5' color={'#fff'}>
            Quy trình    
            </Typography>  
        </Stack> 
        <Button variant='contained' disableElevation>
            Lưu
        </Button>
    </Stack>
    <Box sx={{paddingX: 2, paddingY: 4 }}>
    <Grid container spacing={2}>
        <Grid item xs={4}>
                <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                label="Tên"
                id="outlined-size-name"
                defaultValue="Select campaign settings"
                size="small"
                />
            </div>
            </Box>
        </Grid>
        <Grid item xs={4}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                label="Thứ tự"
                id="outlined-size-number"
                defaultValue="1"
                size="small"
                />
            </div>
            </Box>
        </Grid>
        <Grid item xs={4}>
            <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Kích hoạt" />
            </FormGroup>
        </Grid>
        <Grid item xs={12}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                label="Ghi chú"
                id="outlined-size-description"
                defaultValue="Select campaign settings"
                multiline={true}
                size="small"
                minRows={5}
                fullWidth={true}
                />
            </div>
            </Box>
        </Grid>


        <Grid item xs={12}>
            <CustomeStepper/>
        </Grid>
    </Grid>
    </Box>
    </>
  );
}
