import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";



export const getLanguageById = (id) => (dispatch) => {
  return setTokenAxios().get(`language/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`language`, condition)
    .then(async res => {
      dispatch({
        type: "SET_LANGUAGE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const createLanguage = (data) => (dispatch) => {
  return setTokenAxios().post(`language`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_LANGUAGE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateLanguage = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`language/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_LANGUAGE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteLanguage = ( _id) => (dispatch) => {
  return setTokenAxios().delete(`language/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_LANGUAGE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}