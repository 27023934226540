import * as React from 'react';
import { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import DrawerExpand from '../../elements/drawerExpand';
import BreadCrumb from '../../elements/breadCrumb';
import RowMenu from '../../elements/rowMenu';
import FormDrawer from '../../elements/formDrawer';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { connect, useSelector } from 'react-redux';
import { getAllData } from '../../../actions/chance';
import { getAllData as getUser } from '../../../actions/user';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect } from 'react';
import AddForm from './AddForm'
import { setEditObject } from '../../../actions/untils';
import { ActiveStatus, allStage, getNameOfStage, getStageOfType, getStatusDone } from '../../../enums/activeStatus';
import { find, findIndex } from 'lodash';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import AssignForm from './assignForm';
import DetailForm from './DetailForm';
import FilterForm from './FilterForm';
import { indigo } from '@mui/material/colors';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


const Main = (params) => {
  const { props } = params
  const theme = useTheme()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const childRef = React.useRef();
  const [type, setType] = useState('add')
  const listUser = useSelector(state => state.user)
  const [condition, setCondition] = useState({ condition: {} })
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [status, setStatus] = useState('all')
  const {t} = useTranslation()



  let listData = [...props?.data]

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionMenu = async (value, type) => {
    childRef.current?.openFromParent();
    setType(type)
    if (type !== 'add') {
      await props?.setEditObject(value)
    }
    if (type !== 'assign') {
      await props?.setEditObject(value)
    }
  }

  useEffect(() => {
    props?.getUser()
    props?.getAllData(condition)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderRow = () => {

    let list = rowsPerPage > 0
      ? listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : listData


    return list.map((row, index) => {

      let leader = find(listUser, { _id: row.leaderId })
      let sale = find(listUser, { _id: row.assignedUserId })
      let stage = getStageOfType(row.stage)

      return <TableRow key={row._id} >
        <TableCell align="left">
          <RowMenu
            data={row}
            actionEdit={handleActionMenu}
            sx={{ mr: 2 }} />
          {page * rowsPerPage + index + 1}.
        </TableCell>
        <TableCell align="left" >
          {row?.code}
        </TableCell>
        <TableCell component="th" scope="row" sx={{
          borderWidth: 0.5
        }}>
          <Typography variant='subtitle2' color={indigo[500]} sx={{ textTransform: 'uppercase', lineHeight:1 }}>
            {row?.customer?.firstName} {row?.customer?.lastName}
          </Typography>
          <Typography variant='caption'>
            {row?.customer?.phone?.phoneNumber}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ position: 'relative' }}>
          {getNameOfStage(row.stage)}
          <ul className='stageContain'>
            {allStage?.map((res, ii) => {

              let currenIndex = findIndex(allStage, { code: row.stage })
              return ii <= currenIndex ? <li className='activeProgress' style={{ background: stage?.color }} key={ii}></li> : <li key={ii}></li>
            })}
          </ul>
          {
            stage.code === 'done' ? getStatusDone(row.doneStage?.result) : ""
          }
        </TableCell>
        <TableCell align="right">{leader?.firstName + ' ' + leader?.lastName || 'Không xác định'}</TableCell>
        <TableCell align="right">{sale ? sale?.firstName + ' ' + sale?.lastName : <>  <div style={{ display: 'flex', float: 'right', alignItems:'center' }}>Chưa assign
          <AddCircleIcon sx={{ cursor: 'pointer', ml: 1, color:'#ff5722' }} onClick={() => handleActionMenu(row, 'assign')} />
        </div></>}</TableCell>
        <TableCell align="right">{ActiveStatus(row.isActive)}<br/>
        <Typography variant='caption'>Cre: {moment(row?.created).format('DD/MM/YYYY HH:ss')}</Typography>
        </TableCell>
      </TableRow>
    })
  }

  const openFilter = (value) => {
    setIsOpenFilter(value)
  }

  const handleFilter = (conditionValue) => {
    console.log({conditionValue})
     props?.getAllData(conditionValue)
     setCondition(conditionValue)
  }

  const handleStatus = (code) =>{
    let cond = {
      condition:{}
    }
    if(code!=='all')
    {
      cond.condition.stage = {equal: code}
    }
    handleFilter(cond)
    setStatus(code)
  }

  const closeForm = () => {
    childRef.current?.closeFromParent();
    setType('add')
  }


  let breadcrumb = [{ name: 'Home', href: '/' }, { name: 'Cơ hội', href: '/opportunity' }]
  return (
    <>
      <Stack direction={'row'} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <BreadCrumb data={breadcrumb} />
        <Stack direction={'row'} alignItems="center">
          <ul className='topStatusOp'>
            <li className={`childTopStatusOp ${status === 'all'?'active':''}`} onClick={()=>handleStatus('all')}>
              <div className='smallCircle'></div>
              <Typography variant='body2'>Tất cả</Typography>
            </li>
            {allStage?.map((res, iii) => {
              return <li className={`childTopStatusOp ${status === res?.code?'active':''}`} 
              key={iii} onClick={()=>handleStatus(res.code)}>
                <div className='smallCircle' style={{ background: res?.color }}></div>
                <Typography variant='body2' sx={{ color: res?.color }}>{res?.name}</Typography>
              </li>
            })}
          </ul>
          <IconButton aria-label="filter" onClick={() => openFilter(true)}>
            <FilterListOutlinedIcon />
          </IconButton>
        </Stack>
      </Stack>
      <TableContainer component={Paper} sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 0,
        padding: 2,
        borderRadius: 2
      }}>
        <Box sx={{ fontSize: 24 }}>
          {/* Table of Customer */}
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">

          <TableHead>
            <TableRow sx={{
              fontWeight: 'bold'
            }}>
              <TableCell sx={{ width: 100 }}>No.</TableCell>
              <TableCell sx={{ width: 120 }}>Code.</TableCell>
              <TableCell>Khách hàng</TableCell>
              <TableCell align="right">Tiến độ</TableCell>
              <TableCell align="right">Trưởng nhóm</TableCell>
              <TableCell align="right">Phụ trách</TableCell>
              <TableCell align="right">Trạng thái</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow()}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8, 16, 25, { label: 'Tất cả', value: -1 }]}
                colSpan={7}
                count={listData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': t('pagingPerPage'),
                  },
                  native: true,
                }}
                labelRowsPerPage="Quantity:"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Box sx={{
        '& > :not(style)': {
          position: 'fixed',
          right: 16,
          bottom: 42
        }
      }}>
        <Fab color="primary" aria-label="add" onClick={() => handleActionMenu('', 'add')}>
          <AddIcon />
        </Fab>
      </Box>
      <FormDrawer ref={childRef}>
        {type === 'assign' ? <AssignForm /> : type === 'add' ? <AddForm closeForm={closeForm}/> : <DetailForm />}
      </FormDrawer>
      {isOpenFilter && <FilterForm isOpenFilter={isOpenFilter} openFilter={openFilter} handleFilter={handleFilter} />}
    </>
  )
}

const Chance = (props) => {
  let title = "Cơ hội"
  document.title = title
  return (
    <>
      <DrawerExpand headerText={title}>
        <Main props={props} />
      </DrawerExpand>
    </>
  );
};

const mapDispatchToProps = {
  getAllData, setEditObject, getUser
}

const mapStateToProps = (state) => {
  return {
    data: state.chance
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chance);