
const initialState = {
    isOpen: false,
    message: '',
    type:'info',
    textHref:'',
    action:''
}

const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ALERT_VAL":
            return {...action.payload}

        default:
            return {...state}
    }

}

export default alertReducer