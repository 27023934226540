import { AppBar, Dialog, DialogContent, Grid, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, Button, Fade } from '@mui/material';
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { addMemberChatGroup, getCreateChatGroup } from "../../../actions/chat";
import config from '../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />;
});

const initState = {
  name: "",
  isActive: true,
  keyContact: "",
  memberList: [],
}

const CreateGroupChat = props => {
  //state
  const [
    { name, isActive, memberList, keyContact },
    setState
  ] = useState(initState);

  const { currentChatMessage, userAll, isEdit, profile, chatUserOnline } = props

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const init = () => {
    if (isEdit === true) {
      var memberArr = [...currentChatMessage.memberArr]
      var memberList = userAll.filter(ft => {
        return memberArr.findIndex(arr => arr.userId._id === ft._id) > -1
      })
      setState(state => ({ ...state, memberList: memberList, name: currentChatMessage.name }))
    }
  }

  const onChange = (e) => {
    setState(state => ({ ...state, [e.target.name]: e.target.value }));
  }

  const filterByNames = async (data, inputValue) => {
    // Create a dynamic regex expression object with ignore case sensitivity

    const re = new RegExp(_.escapeRegExp(inputValue), "i");
    const results = await data.filter((item) => {
      if (re.test(item.profile?.lastName)) {
        const matches = match(item?.profile?.lastName, inputValue);
        item["parts"] = parse(item?.profile?.lastName, matches);
        return true;
      }
      if (re.test(item?.profile?.firstName)) {
        const matches = match(item?.profile?.firstName, inputValue);
        item["parts"] = parse(item?.profile?.firstName, matches);
        return true;
      }
      else {
        return false;
      }

    });
    return results;
  };

  const onSubmit = () => {
    var memberArr = []
    memberList.map(mn => {
      return memberArr.push({
        userId: mn._id,
        employeeId: mn.profile._id,
        employeeCode: mn.profile.code,
        name: `${mn.profile.firstName} ${mn.profile.lastName} `,
        isMain: false
      })
    })

    if (isEdit === true) {
      props.addMemberChatGroup({ groupCode: currentChatMessage.code, users: memberArr })
        .then(res => {
          props?.close(true)
          //AlertConfirm(_.get(res, 'success.message', ''))
        })
        .catch(err => {
          //AlertConfirm(parseError(err.error))
        })
    }
    else {
      props.getCreateChatGroup({ name, type: 'multiple', memberArr: memberArr, isActive })
        .then(res => {
          props?.close(true, res?.success?.code)
          // AlertConfirm(_.get(res, 'success.message', ''))
        })
        .catch(err => {
          // AlertConfirm(parseError(err.error))
        })
    }

  }

  const addGroup = (item) => {
    var memberLst = [...memberList]
    var index = _.findIndex(memberLst, { _id: item._id })
    if (item._id !== profile.userId) {
      if (index > -1) {
        memberLst.splice(index, 1)

      }
      else {
        memberLst.push(item)
      }
      setState(state => ({ ...state, memberList: memberLst }))
    }
  };

  const results = !keyContact ? userAll : filterByNames(userAll, keyContact);

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        open={true}
        onClose={props.close}
        TransitionComponent={Transition}
        // scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <AppBar elevation={0} style={{ position: 'relative' }}>
          <Toolbar variant='regular' style={{ display: 'flex', justifyContent: 'space-between', background: '#009688', color: '#fff' }}>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconButton edge="start" color="inherit" onClick={props.close} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" sx={{ marginLeft: 2 }}>
                CHAT GROUP
              </Typography>
            </Box>

            <Button disableElevation variant='outlined' sx={{ color: '#fff' }} onClick={() => onSubmit()} startIcon={<SaveIcon />}>
              SUBMIT
            </Button>

          </Toolbar>
        </AppBar>
        <DialogContent style={{ background: '#F7FAFC' }}>
          <form noValidate autoComplete="off" onSubmit={onSubmit}>

            <div className="contactList addFrm" style={{ minHeight: '400px' }}>
              <div className="topContactList">
                <div className="searchTool">
                  <div className="group-search" >
                    <input
                      className="input-Search"
                      value={keyContact}
                      name="keyContact"
                      placeholder="Search ..."
                      onChange={onChange}
                    />
                  </div>
                  <div className="icon-search">
                    <SearchOutlinedIcon />
                  </div>
                </div>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <ul className="toolInfo member">
                    {results.map(item => {
                      var isExist = _.findIndex(memberList, { _id: item._id })
                      return item._id !== profile._id && <li className={`addMem ${isExist > -1 ? 'active' : ""}`} key={item._id} onClick={() => addGroup(item)}>
                        <div className="imgTool">
                          <div className="contetImgTool">
                            {_.isEmpty(_.get(item, 'fileAvatar', '')) ? _.get(item, 'profile.lastName', '').charAt(0) : <img src={config.baseURL + _.get(item, 'fileAvatar.path', '')} alt={item.profile.lastName} />}
                          </div>
                          {_.includes(chatUserOnline, item._id) === true && <div className="online"></div>}
                        </div>
                        <div className="textTool">
                          <span className="contactName">{item?.profile?.firstName} {item?.profile?.lastName}</span>
                          <p>@{_.get(item, 'userName', '')}</p>
                        </div>
                      </li>
                    })}
                  </ul>
                </Grid>

                <Grid item xs={7} sx={{ marginTop: 2 }}>
                  <TextField id="outlined-search"
                    sx={{ marginBottom: 3 }}
                    label="Name"
                    type="text" variant="outlined"
                    size="small"
                    name="name"
                    onChange={onChange}
                    value={name}
                    // readOnly={this.props.edit}
                    disabled={isEdit}
                    fullWidth
                  />

                  <Typography variant='title2' fontWeight={'bold'} color={'#009688'}>
                    Member list:
                  </Typography>

                  <div className="memberList" style={{ marginTop: 3 }}>
                    {memberList.map((item, index) => {
                      return <div className="itemMemember" key={`${index}c`}>
                        <div className="imgMember">
                          <div className="contentImg">
                            {isEmpty(_.get(item, 'fileAvatar', '')) ? item?.profile?.lastName.charAt(0) : <img src={config.baseURL + _.get(item, 'fileAvatar.path', '')} alt={item.profile.lastName} />}
                          </div>
                          {
                            isEdit !== true && <div className="delIt" onClick={() => addGroup(item)}>-</div>
                          }
                        </div>
                        <div className="nameMember">{item?.profile?.firstName} {item?.profile?.lastName}</div>
                      </div>
                    })}
                  </div>
                </Grid>

              </Grid>

            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: state.user,
    currentChatMessage: state.currentChatMessage,
    profile: state.profile
  }
}


const mapDispatchToProps = {
  addMemberChatGroup, getCreateChatGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupChat);