
import { io } from "socket.io-client";
import config from '../config/index';
import { store } from '../store'
import _, { findIndex } from "lodash";


const ENUMS = {
    CONNECT: "connect",
    CONNECT_ERROR: "connect_error",
    CONNECTION: "connection",
    DISCONNECT: "disconnect",
    SSC_ASSIGN_TASK: "SSC_ASSIGN_TASK",
    SSC_REMOVE_TASK: "SSC_REMOVE_TASK",
    SSC_UPDATE_TASK: "SSC_UPDATE_TASK",
    SSC_START_TASK: "SSC_START_TASK",
    SSC_COMPLETE_TASK: "SSC_COMPLETE_TASK",
    SSC_NEW_TASK_COMMENT: "SSC_NEW_TASK_COMMENT",
    SSC_REMOVE_TASK_COMMENT: "SSC_REMOVE_TASK_COMMENT",
    SSC_UPDATE_TASK_COMMENT: "SSC_UPDATE_TASK_COMMENT",
    SSC_ONLINE_USERS: "SSC_ONLINE_USERS",
    CSS_SEND_MESSAGE: "CSS_SEND_MESSAGE",
    CSS_USER_SEEN_MESSAGE: "CSS_USER_SEEN_MESSAGE",
    SSC_USER_SEEN_MESSAGE: "SSC_USER_SEEN_MESSAGE",
    SSC_SEND_LIST_LATEST_MESSAGES: "SSC_SEND_LIST_LATEST_MESSAGES",
    SSC_SEND_NEW_MESSAGE: "SSC_SEND_NEW_MESSAGE",
    SSC_USER_WITHDRAW_MESSAGE: "SSC_USER_WITHDRAW_MESSAGE",
    CSS_USER_TYPING: "CSS_USER_TYPING",
    SSC_USER_TYPING: "SSC_USER_TYPING",
    SSC_USER_JOIN_ROOM: "SSC_USER_JOIN_ROOM",
    SSC_USER_LEFT_ROOM: "SSC_USER_LEFT_ROOM",
    SSC_USER_REMOVED_FROM_ROOM: "SSC_USER_REMOVED_FROM_ROOM",
    SSC_ROOM_LISTS: "SSC_ROOM_LISTS"
};

var token = localStorage.getItem('token');
const socket = io(`${config.baseURL}`, {
    query: {
        accessToken: token
    },
    transports: ['websocket']
});


const connectSocketMng = () => {

    console.log('connectSocketMng')

    socket.on(ENUMS.CONNECT, () => {
        console.log('--------------*SOCKET_MNG_CONNECTED*--------------');
    });

    socket.on(ENUMS.CONNECT_ERROR, (err) => {
        console.log("************ Error ************")
        console.log(err)
        console.log("************ Error ************")
    });


    socket.on(ENUMS.DISCONNECT, function () {
        console.log("************socket disconnect ************")
    });

    socket.on(ENUMS.SSC_ONLINE_USERS, (data) => {
        console.log("************ onlineUsers ************")
        store.dispatch({
            type: 'SET_USER_ONLINE',
            payload: data
        })
        console.log("************ onlineUsers ************")
    });


    socket.on(ENUMS.SSC_ASSIGN_TASK, (task) => {
        console.log("************ SSC_ASSIGN_TASK ************")
        store.dispatch({
            type: 'UPDATE_TASK',
            payload: task.task
        })
        console.log(task.task)
        console.log("************ SSC_ASSIGN_TASK ************")
    });

    socket.on(ENUMS.SSC_REMOVE_TASK, (task) => {
        console.log("************ SSC_REMOVE_TASK ************")
        store.dispatch({
            type: 'DELETE_TASK',
            payload: task.taskIds[0]
        })
        console.log(task.taskIds)
        console.log("************ SSC_REMOVE_TASK ************")
    });

    socket.on(ENUMS.SSC_UPDATE_TASK, (task) => {
        console.log("************ SSC_UPDATE_TASK ************")
        var gobalState = store.getState()
        store.dispatch({
            type: 'UPDATE_TASK',
            payload: task.task
        })

        if (_.isEmpty(gobalState.taskDetail) === false && gobalState.taskDetail._id === task.task._id) {
            store.dispatch({
                type: 'SET_TASK_DETAIL',
                payload: task.task
            })
        }
        console.log(task.task);

        console.log("************ SSC_UPDATE_TASK ************")
    });

    socket.on(ENUMS.SSC_START_TASK, (task) => {
        console.log("************ SSC_START_TASK ************")
        console.log(task)
        store.dispatch({
            type: 'UPDATE_START_TASK',
            payload: task.task
        })
        console.log("************ SSC_START_TASK ************")
    });

    socket.on(ENUMS.SSC_COMPLETE_TASK, (task) => {
        console.log("************ SSC_COMPLETE_TASK ************")
        console.log(task)
        store.dispatch({
            type: 'UPDATE_COMPLETED_TASK',
            payload: task.task
        })

        console.log("************ SSC_COMPLETE_TASK ************")
    });

    socket.on(ENUMS.SSC_NEW_TASK_COMMENT, (comment) => {
        console.log("************ SSC_NEW_TASK_COMMENT ************")
        var oldState = store.getState().taskComment
        if (oldState.length > 0) {
            if (oldState[0].subTaskId === comment.taskComment.subTaskId) {
                store.dispatch({
                    type: 'CREATE_TASK_COMMENT',
                    payload: comment.taskComment
                })
            }
        }
        else {
            let tmp = []
            tmp.push(comment.taskComment)
            store.dispatch({
                type: 'SET_TASK_COMMENT',
                payload: tmp
            })
        }
        console.log("************ SSC_NEW_TASK_COMMENT ************")
    });

    socket.on(ENUMS.SSC_REMOVE_TASK_COMMENT, (task) => {
        console.log("************ SSC_REMOVE_TASK_COMMENT ************")
        // console.log(task)
        console.log("************ SSC_REMOVE_TASK_COMMENT ************")
    });

    socket.on(ENUMS.SSC_UPDATE_TASK_COMMENT, (task) => {
        console.log("************ SSC_UPDATE_TASK_COMMENT ************")
        // console.log(task)
        console.log("************ SSC_UPDATE_TASK_COMMENT ************")
    });

    /*"------------------------------START CHAT-----------------------"*/

    socket.on(ENUMS.SSC_ROOM_LISTS, (data) => {
        console.log("************ roomLists ************")
        console.log('roomLists', data)
        console.log("************ roomLists ************")
    });

    socket.on(ENUMS.SSC_SEND_NEW_MESSAGE, (data) => {
        console.log("************ SSC_SEND_NEW_MESSAGE ************")
        //lasted message logical
        var gobalState = store.getState()
        let tmp = gobalState.chatMessageLasted
        const indexTmp = tmp.findIndex(per => per.groupChatCode === data.room)
        console.log(indexTmp, tmp, data.room)
        if (indexTmp > -1) {
            store.dispatch({
                type: 'UPDATE_CHATMESSAGE_LASTED',
                payload: data.message
            })
        }
        else {
            store.dispatch({
                type: 'CREATE_CHATMESSAGE_LASTED',
                payload: data.message
            })
        }
        //current chat message logical
        if (data?.message?.groupChatCode === gobalState?.currentChatMessage?.code) {
            store.dispatch({
                type: 'ADD_NEW_CHAT_MESSAGE',
                payload: data?.message
            })
        }
        //  console.log('data',data);

        //check array message minimize
        let detailMinimizeChat = gobalState.detailMinimizeChat
        if (detailMinimizeChat.length > 0) {
            let indexMinim = findIndex(detailMinimizeChat, { code: data?.room })
            let messages = [...detailMinimizeChat[indexMinim]?.messages]
            store.dispatch({
                type: 'UPDATE_DETAIL_MINI_CHAT',
                payload: {
                    code: data?.room,
                    messages: [...messages, data?.message]
                }
            })
            console.log('messages', messages)
        }

        console.log("************ SSC_SEND_NEW_MESSAGE ************")
    });
    socket.on(ENUMS.SSC_USER_SEEN_MESSAGE, (msg) => {
        console.log("************ SSC_USER_SEEN_MESSAGE ************")
        //  console.log(msg)
        store.dispatch({
            type: 'UPDATE_CHATMESSAGE_LASTED_SEEN',
            payload: msg.data.messages
        })
        console.log("************ SSC_USER_SEEN_MESSAGE ************")
    });

    socket.on(ENUMS.SSC_USER_TYPING, (msg) => {
        console.log("************ SSC_USER_TYPING ************")
        console.log(msg)
        store.dispatch({
            type: 'SET_TYPING_MESSAGE',
            payload: msg
        })
        console.log("************ SSC_USER_TYPING ************")
    });

    socket.on(ENUMS.SSC_USER_JOIN_ROOM, (msg) => {
        console.log("************ SSC_USER_JOIN_ROOM ************")
        console.log(msg)
        store.dispatch({
            type: 'UPDATE_CURRENT_CHAT_MEMBER',
            payload: msg.data
        })
        console.log("************ SSC_USER_JOIN_ROOM ************")
    });

    socket.on(ENUMS.SSC_USER_LEFT_ROOM, (msg) => {
        console.log("************ SSC_USER_LEFT_ROOM ************")
        console.log(msg)
        store.dispatch({
            type: 'UPDATE_CURRENT_CHAT_MEMBER',
            payload: msg.data
        })
        console.log("************ SSC_USER_LEFT_ROOM ************")
    });

    socket.on(ENUMS.SSC_USER_REMOVED_FROM_ROOM, (msg) => {
        console.log("************ SSC_USER_REMOVED_FROM_ROOM ************")
        console.log(msg)
        store.dispatch({
            type: 'DELETE_CHATMESSAGE_LASTED',
            payload: msg.room
        })
        console.log("************ SSC_USER_REMOVED_FROM_ROOM ************")
    });

    socket.on(ENUMS.SSC_USER_WITHDRAW_MESSAGE, (msg) => {
        console.log("************ SSC_USER_WITHDRAW_MESSAGE ************")
        console.log(msg.message)
        store.dispatch({
            type: 'UPDATE_CURRENT_CHAT_MESSAGE',
            payload: msg.message
        })
        console.log("************ SSC_USER_WITHDRAW_MESSAGE ************")
    });

    /*"------------------------------END CHAT-----------------------"*/

}

export const emitChatting = (data) => {
    console.log("************ CSS_SEND_MESSAGE ************")
    socket.emit(ENUMS.CSS_SEND_MESSAGE, data)
    console.log("************ CSS_SEND_MESSAGE ************")

}
export const emitTyping = (data) => {
    console.log("************ CSS_USER_TYPING ************")
    socket.emit(ENUMS.CSS_USER_TYPING, data)
    console.log("************CSS_USER_TYPING ************")
}
export const emitSeenMessage = (data) => {
    console.log("************ CSS_USER_SEEN_MESSAGE ************")
    socket.emit(ENUMS.CSS_USER_SEEN_MESSAGE, data);
    console.log("************CSS_USER_SEEN_MESSAGE ************")
}



export { socket, connectSocketMng }
