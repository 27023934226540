import  {setTokenAxios } from "../api/index"
import {_showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";



export const generateCode = () => (dispatch) => {
  return setTokenAxios().get(`feedBack/generate-code`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data.code })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getFeedBackById = (id) => (dispatch)=>{
    return setTokenAxios().get(`feedBack/${id}`)
      .then(async res => {
        return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
      })
      .catch((err) =>{
        _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
      })
  }

export const getAllData = (condition) => (dispatch)=> {
  return setTokenAxios().get(`feedBack`,{params:condition})
    .then(async res => {
      dispatch({
        type: "SET_FEED_BACK",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}


export const getAllDataNoneRedux = (condition) => (dispatch)=> {
  return setTokenAxios().get(`feedBack`,{params:condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}

export const createFeedBack = (data) => (dispatch)=> {
  return setTokenAxios().post(`feedBack`,data)
    .then(async res => {
      dispatch({
        type: "CREATE_FEED_BACK",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}

export const updateFeedBack = (data, _id) => (dispatch)=> {
  return setTokenAxios().put(`feedBack/${_id}`,data)
    .then(async res => {
      dispatch({
        type: "UPDATE_FEED_BACK",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}