import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const generateCode = () => (dispatch) => {
  return setTokenAxios().get(`journal-entries/generate-code`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data.code })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getJournalEntrieByCode = (id) => (dispatch) => {
  return setTokenAxios().get(`journal-entries/get-by-code/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}



export const getJournalEntrieById = (id) => (dispatch) => {
  return setTokenAxios().get(`journal-entries/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`journal-entries`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_JOURNAL_ENTRIE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`journal-entries`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataMore = (condition) => (dispatch) => {
  return setTokenAxios().get(`journal-entries`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_MORE_JOURNAL_ENTRIE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}



export const createJournalEntrie = (data) => (dispatch) => {
  return setTokenAxios().post(`journal-entries`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_JOURNAL_ENTRIE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateJournalEntrie = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`journal-entries/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_JOURNAL_ENTRIE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const activeJournalEntrie = (_id, data) => (dispatch) => {
  return setTokenAxios().put(`journal-entries/${_id}/actived`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_JOURNAL_ENTRIE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const starJournalEntrie = (_id, data) => (dispatch) => {
  return setTokenAxios().put(`journal-entries/${_id}/star`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_JOURNAL_ENTRIE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteJournalEntrie = (_id) => (dispatch) => {
  return setTokenAxios().delete(`journal-entries/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_JOURNAL_ENTRIE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const starMultipleJournalEntrie = (data) => (dispatch) => {
  return setTokenAxios().post(`journal-entries/stared`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_JOURNAL_ENTRIE_MULTI",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}
export const starMultipleDebitNote = (data) => (dispatch) => {
  return setTokenAxios().post(`journal-entries/stared`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_DEBIT_NOTE_MULTI",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const UnActive = (data) => (dispatch) => {
  return setTokenAxios().post(`journal-entries/unactived`,data )
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}