import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { renderNumberDollar } from '../../../../untils/number';

import { MoreVertOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getCreditNoteByCode } from '../../../../actions/creditNote';
import { getDebitNoteByCode } from '../../../../actions/debitNote';
import { getIncomeByCode } from '../../../../actions/income';
import { getJournalEntrieByCode } from '../../../../actions/journalEntrie';
import { getOutcomeByCode } from '../../../../actions/outcome';
import { getReportPaymentHistoryWithChild } from '../../../../actions/report';
import DetailForm from '../detail/DetailForm';
import DialogPrint from './DialogPrint';
import { find, isArray } from 'lodash';
import dayjs from 'dayjs';

import EditableLabel from 'react-editable-label';
import { useEffect } from 'react';

const TrialBalance = React.forwardRef((props, ref) => {
    // let { beginBlance, data, condition, title, accountNumber, startDate, endDate, department, expense, partner } = props
    let { beginBlance, data, condition, accountNumber, isPrint, title, startDate, endDate } = props
    const [mainData, setMainData] = useState([])
    const [openDetail, setOpenDetail] = useState(false)
    const [dataDetail, setDataDetail] = React.useState([]);
    const [accountNumberDetail, setAccountNumberDetail] = React.useState('');

    const [dataHeader, setDataHeader] = React.useState({
        date: moment().format("MMMM Do YYYY"),
        company: 'Company',
        address: 'Address',
        phone: 'Phone',
        title: title
    });

    useEffect(() => {
        setDataHeader(state => ({ ...state, title }))
    }, [title])
      

    // const repareDataGroupByCode = async () => {
    //     let dataTmp = []
    //     let dt = data.data
    //     let sumDebitChild = 0
    //     let sumCreditChild = 0
    //     let begin = 0

    //     if (dt.length > 0) {
    //         let beginTmp = find(beginBlance, { accountNumber: dt[0]?._id })
    //         if (beginTmp) {
    //             begin = beginTmp?.beginDebitAmount
    //         }
    //         await Promise.all(
    //             await dt[0].children.map(async (dataActive, index) => {
    //                 await getDataInChild(dataActive).then(res => {
    //                     dataActive = { ...res }
    //                     sumDebitChild += res?.sumDebitChild + res?.sumDebit
    //                     sumCreditChild += res?.sumCreditChild + res?.sumCredit
    //                     dataTmp.push({ ...res })
    //                 })
    //                 return dataActive
    //             })
    //         )
    //         //calculator sum debit of dt[0]
    //         let sumDebit = 0
    //         let sumCredit = 0
    //         await Promise.all(
    //             await dt[0]?.payment.map(item => {
    //                 if (item.paymentType === 'CRB') {
    //                     sumCredit += item.amount
    //                 }
    //                 else if (item.paymentType === 'DRB') {
    //                     sumDebit += item.amount
    //                 }
    //                 else {
    //                     if (dt[0]._id === item.accountNumberDebit) {
    //                         sumDebit += item.amount
    //                     }

    //                     if (dt[0]._id === item.accountNumberCredit) {
    //                         sumCredit += item.amount
    //                     }
    //                 }
    //                 return item
    //             })
    //         )
    //         dt[0].sumDebit = sumDebit
    //         dt[0].sumCredit = sumCredit
    //         ///////////////

    //         dt[0].children = dataTmp
    //         dt[0].sumDebitChild = sumDebitChild
    //         dt[0].sumCreditChild = sumCreditChild
    //         dt[0].begin = begin
    //         setMainData(dt)
    //     }
    // }

    const repareDataGroupByCode = async () => {

        let dataMain = []
        let dt = data.data
        let isDone = false

        await Promise.all(
            await dt.map(async (itemMain, index) => {

                let dataTmp = []
                let sumDebitChild = 0
                let sumCreditChild = 0
                let begin = 0

                if (dt.length > 0) {


                    let beginTmp = find(beginBlance, { accountNumber: itemMain?._id })
                    if (beginTmp) {
                        begin = beginTmp?.beginDebitAmount
                    }
                    await Promise.all(
                        await itemMain.children.map(async (dataActive, index) => {
                            await getDataInChild(dataActive).then(res => {
                                dataActive = { ...res }
                                sumDebitChild += res?.sumDebitChild + res?.sumDebit
                                sumCreditChild += res?.sumCreditChild + res?.sumCredit
                                dataTmp.push({ ...res })
                            })
                            return dataActive
                        })
                    )
                    //calculator sum debit of itemMain
                    let sumDebit = 0
                    let sumCredit = 0

                    await Promise.all(
                        await itemMain?.payment.map(item => {
                            if (item.paymentType === 'CRB') {
                                sumCredit += item.amount
                            }
                            else if (item.paymentType === 'DRB') {
                                sumDebit += item.amount
                            }
                            else {
                                if (itemMain._id === item.accountNumberDebit) {
                                    sumDebit += item.amount
                                }

                                if (itemMain._id === item.accountNumberCredit) {
                                    sumCredit += item.amount
                                }
                            }
                            return item
                        })
                    )

                    itemMain.sumDebit = sumDebit
                    itemMain.sumCredit = sumCredit
                    ///////////////
                    itemMain.sumDebitChild = sumDebitChild
                    itemMain.sumCreditChild = sumCreditChild
                    itemMain.begin = begin
                }
                dataMain.push(itemMain)
                if (index === dt.length - 1) {
                    isDone = true
                }
            })

        )
        if (isDone === true) {
            setMainData(dataMain)
        }

        //end

    }


    const getDataInChild = async (dataChild, sumDebitChildMain, sumCreditChildMain) => {
        let data = dataChild.children
        let payment = dataChild.payment
        let sumDebit = 0
        let sumCredit = 0
        let sumDebitChild = sumDebitChildMain || 0
        let sumCreditChild = sumDebitChildMain || 0
        let begin = 0
        //get begin
        let beginTmp = find(beginBlance, { accountNumber: dataChild?.code })
        if (beginTmp) {
            begin = beginTmp?.beginDebitAmount
        }

        await payment.map(item => {
            if (item.paymentType === 'CRB') {
                sumCredit += item.amount
            }
            else if (item.paymentType === 'DRB') {
                sumDebit += item.amount
            }
            else {
                if (dataChild.code === item.accountNumberDebit) {
                    sumDebit += item.amount
                }

                if (dataChild.code === item.accountNumberCredit) {
                    sumCredit += item.amount
                }
            }
            return item
        })

        dataChild.sumDebit = sumDebit
        dataChild.sumCredit = sumCredit

        if (data.length > 0) {
            await Promise.all(
                await data.map(async ite => {
                    await getDataInChild(ite, sumDebitChild, sumCreditChild).then(res => {
                        sumDebitChild += res.sumDebit
                        sumCreditChild += res.sumCredit
                        ite = { ...res }
                    })
                    return ite
                })
            )
        }

        dataChild.sumDebitChild = sumDebitChild
        dataChild.sumCreditChild = sumCreditChild
        dataChild.children = data
        dataChild.begin = begin

        return dataChild
    }

    React.useEffect(() => {
        repareDataGroupByCode()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    React.useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition])

    const handleClosePrint = () => {
        props?.handleClosePrint(false)
    }

    const componentPrint = () => {
        return <div className='printFileReport' ref={ref} >
            <div className='containHeaderPrint'>
                <div className='leftOfHead'>

                    <div className='rowLeftPrint' style={{ fontStyle: 'italic' }}>
                        <EditableLabel
                            initialValue={dataHeader?.date}
                            save={value => {
                                setDataHeader(state => ({ ...state, date: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.company}
                            save={value => {
                                setDataHeader(state => ({ ...state, company: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.address}
                            save={value => {
                                setDataHeader(state => ({ ...state, address: value }))
                            }}
                        />
                    </div>

                    <div className='rowLeftPrint'>
                        <EditableLabel
                            initialValue={dataHeader?.phone}
                            save={value => {
                                setDataHeader(state => ({ ...state, phone: value }))
                            }}
                        />
                    </div>
                    <div className='briefPrintLine'>(Tapping above lines to edit)</div>
                </div>
                <div className='topFileReport'>
                    <div className='titFileReport'>
                        <EditableLabel
                            initialValue={dataHeader?.title}
                            save={value => {
                                setDataHeader(state => ({ ...state, title: value }))
                            }}
                        />
                    </div>
                    <div className='dateFileReport'>From {dayjs(startDate).format('DD/MM/YYYY')} to {dayjs(endDate).format('DD/MM/YYYY')}</div>
                    <div className='containFileReport'>
                        <div className='colFileReport'>
                            <div className='lineColReport'>
                                <div className='detailColFile'>
                                    {accountNumber?.map((res, ind) => {
                                        return <span>{res} {ind < accountNumber?.length - 1 ? ', ' : ''}</span>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Table sx={{ width: '100%', border: '1px solid #d2d2d2' }} className='tableRp tblPrint'>
                <TableHead className='header-wrapper'>
                    <TableRow sx={{
                        '& .MuiTableCell-root': {
                            padding: '8px 12px!important'
                        }
                    }}>
                        <TableCell width={60}>#</TableCell>
                        <TableCell align="left" width={100}>Account
                        </TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="center" width={160}>Begin
                        </TableCell>
                        <TableCell align="center" width={160}>Debit Amount
                        </TableCell>
                        <TableCell align="center" width={160}>Credit Amount
                        </TableCell>
                        <TableCell align="center" width={160}>Balance
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        mainData?.map((itemMain, index) => {
                            let child = itemMain?.children || []
                            let isBank = -1
                            let closing = 0
                            if (isArray(itemMain?.type)) {
                                isBank = itemMain?.type.indexOf('bank')
                            }

                            if (isBank > -1) { // if bank
                                closing = itemMain.begin + (itemMain.sumCreditChild + itemMain.sumCredit) - (itemMain.sumDebitChild + itemMain.sumDebit)
                            }
                            else {
                                closing = itemMain.begin + (itemMain.sumDebitChild + itemMain.sumDebit) - (itemMain.sumCreditChild + itemMain.sumCredit)
                            }
                            return <React.Fragment key={index} >
                                <TableRow
                                    sx={{ background: '#c2e7fe!important', '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left"></TableCell>

                                    <TableCell align="left" colSpan={1}>
                                        <b>{itemMain._id}</b>
                                    </TableCell>
                                    <TableCell align="left"> <b>{itemMain.name}</b></TableCell>
                                    <TableCell align="center">
                                        <span className='fmNumber' style={{ fontWeight: 'bold' }}> {itemMain.begin ? renderNumberDollar(itemMain.begin) : '-'}</span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span className='fmNumber' style={{ fontWeight: 'bold' }}>
                                            {itemMain.sumDebit || itemMain.sumDebitChild ? renderNumberDollar(itemMain.sumDebit + itemMain.sumDebitChild) : '-'}
                                        </span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span className='fmNumber' style={{ fontWeight: 'bold' }}>
                                            {itemMain.sumCredit || itemMain.sumCreditChild ? renderNumberDollar(itemMain.sumCredit + itemMain.sumCreditChild) : '-'}
                                        </span>
                                    </TableCell>
                                    <TableCell align="right">
                                        {closing ? renderNumberDollar(closing) : '-'}
                                    </TableCell>
                                </TableRow>
                                {
                                    renderChild(child, 24, index)
                                }
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">
                                    </TableCell>
                                    <TableCell align="left" colSpan={2}> <b>Total</b></TableCell>
                                    <TableCell align="center">  <span className='fmNumber' style={{ fontWeight: 'bold' }}> {itemMain.sumDebitChild ? renderNumberDollar(itemMain.sumDebitChild) : '-'}</span></TableCell>
                                    <TableCell align="center">  <span className='fmNumber' style={{ fontWeight: 'bold' }}> {itemMain.sumCreditChild ? renderNumberDollar(itemMain.sumCreditChild) : '-'}</span></TableCell>
                                    <TableCell align="left"> </TableCell>
                                </TableRow>

                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left"></TableCell>

                                    <TableCell align="left" colSpan={1}>

                                    </TableCell>
                                    <TableCell align="left" colSpan={2}> <b>Closing</b></TableCell>
                                    <TableCell align="center">  <span className='fmNumber' style={{ fontWeight: 'bold' }}>
                                        {closing ? renderNumberDollar(closing) : '-'}
                                    </span></TableCell>
                                    <TableCell align="center"> </TableCell>
                                    <TableCell align="center"> </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={7}>
                                        <div className='lineDashed'></div>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        })
                    }

                </TableBody>
            </Table>
        </div>
    }

    const handleOpenDetail = async (val) => {
        let cond = { accountNumber: val.code }
        if (startDate !== '' && endDate !== '') {
            cond.date = {
                "from": startDate,
                "to": endDate
            }
        }

        await props?.getReportPaymentHistoryWithChild({ condition: cond }).then(res => {
            setDataDetail(res.data)
            setAccountNumberDetail(val.code)
            setOpenDetail(true)
        })
    }

    const renderChild = (data, dashed, indexM) => {
        let indexC = indexM
        return (
            data?.map((item, index) => {
                let child = item.children
                indexC += 1

                let isBank = -1
                let closing = 0
                if (isArray(item?.type)) {
                    isBank = item?.type.indexOf('bank')
                }

                if (isBank > -1) { // if bank
                    closing = item.begin + (item.sumCreditChild + item.sumCredit) - (item.sumDebitChild + item.sumDebit)
                }
                else {
                    closing = item.begin + (item.sumDebitChild + item.sumDebit) - (item.sumCreditChild + item.sumCredit)
                }

                return item?.code && <>
                    <TableRow
                        key={index}
                        className='rowTbRp'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <MoreVertOutlined color='#000000de' onClick={() => handleOpenDetail(item)} />
                                {indexC}
                            </div>
                        </TableCell>

                        <TableCell align="left" >
                            <span style={{ paddingLeft: dashed }}>{item.code}</span>
                        </TableCell>
                        <TableCell align="left">{item?.name}</TableCell>
                        <TableCell align="center">
                            <span className='fmNumber'> {item?.begin ? renderNumberDollar(item.begin) : '-'}</span>
                        </TableCell>

                        <TableCell align="center">
                            <span className='fmNumber'> {item?.sumDebit || item.sumDebitChild ? renderNumberDollar(item.sumDebit + item.sumDebitChild) : '-'}</span>
                        </TableCell>

                        <TableCell align="center">
                            <span className='fmNumber'> {item?.sumCredit || item.sumCreditChild ? renderNumberDollar(item.sumCredit + item.sumCreditChild) : '-'}</span>
                        </TableCell>
                        <TableCell align="right">
                            <span className='fmNumber'> {closing ? renderNumberDollar(closing) : '-'}</span>
                        </TableCell>
                    </TableRow >
                    {
                        child.length > 0 && renderChild(child, 24 + dashed, indexC)
                    }
                </>
            })
        )
    }

    return (
        <>
            <Box className="printTemplate">
                {isPrint ? <DialogPrint isOpen={isPrint} handleRenderData={componentPrint} handleCloseProps={handleClosePrint} /> : ""}
                <TableContainer component={Paper} elevation={0} sx={{ maxHeight: 'calc(100vh - 150px)' }}>
                    <Table sx={{ minWidth: 650 }} className='tableRp' stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow sx={{
                                '& .MuiTableCell-root': {
                                    padding: '8px 12px!important'
                                }
                            }}>
                                <TableCell width={60}>#</TableCell>
                                <TableCell align="left" width={100}>Account
                                </TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="center" width={160}>Begin
                                </TableCell>
                                <TableCell align="center" width={160}>Debit Amount
                                </TableCell>
                                <TableCell align="center" width={160}>Credit Amount
                                </TableCell>
                                <TableCell align="center" width={160}>Balance
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                mainData?.map((itemMain, index) => {
                                    let child = itemMain?.children || []
                                    let isBank = -1
                                    let closing = 0
                                    if (isArray(itemMain?.type)) {
                                        isBank = itemMain?.type.indexOf('bank')
                                    }

                                    if (isBank > -1) { // if bank
                                        closing = itemMain.begin + (itemMain.sumCreditChild + itemMain.sumCredit) - (itemMain.sumDebitChild + itemMain.sumDebit)
                                    }
                                    else {
                                        closing = itemMain.begin + (itemMain.sumDebitChild + itemMain.sumDebit) - (itemMain.sumCreditChild + itemMain.sumCredit)
                                    }
                                    return <React.Fragment key={index} >
                                        <TableRow
                                            sx={{ background: '#c2e7fe!important', '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left"></TableCell>

                                            <TableCell align="left" colSpan={1}>
                                                <b>{itemMain._id}</b>
                                            </TableCell>
                                            <TableCell align="left"> <b>{itemMain.name}</b></TableCell>
                                            <TableCell align="center">
                                                <span className='fmNumber' style={{ fontWeight: 'bold' }}> {itemMain.begin ? renderNumberDollar(itemMain.begin) : '-'}</span>
                                            </TableCell>
                                            <TableCell align="center">
                                                <span className='fmNumber' style={{ fontWeight: 'bold' }}>
                                                    {itemMain.sumDebit || itemMain.sumDebitChild ? renderNumberDollar(itemMain.sumDebit + itemMain.sumDebitChild) : '-'}
                                                </span>
                                            </TableCell>
                                            <TableCell align="center">
                                                <span className='fmNumber' style={{ fontWeight: 'bold' }}>
                                                    {itemMain.sumCredit || itemMain.sumCreditChild ? renderNumberDollar(itemMain.sumCredit + itemMain.sumCreditChild) : '-'}
                                                </span>
                                            </TableCell>
                                            <TableCell align="right">
                                                {closing ? renderNumberDollar(closing) : '-'}
                                            </TableCell>
                                        </TableRow>
                                        {
                                            renderChild(child, 24, index)
                                        }
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left">
                                            </TableCell>
                                            <TableCell align="left" colSpan={2}> <b>Total</b></TableCell>
                                            <TableCell align="center">  <span className='fmNumber' style={{ fontWeight: 'bold' }}> {itemMain.sumDebitChild ? renderNumberDollar(itemMain.sumDebitChild) : '-'}</span></TableCell>
                                            <TableCell align="center">  <span className='fmNumber' style={{ fontWeight: 'bold' }}> {itemMain.sumCreditChild ? renderNumberDollar(itemMain.sumCreditChild) : '-'}</span></TableCell>
                                            <TableCell align="left"> </TableCell>
                                        </TableRow>

                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left"></TableCell>

                                            <TableCell align="left" colSpan={1}>

                                            </TableCell>
                                            <TableCell align="left" colSpan={2}> <b>Closing</b></TableCell>
                                            <TableCell align="center">  <span className='fmNumber' style={{ fontWeight: 'bold' }}>
                                                {closing ? renderNumberDollar(closing) : '-'}
                                            </span></TableCell>
                                            <TableCell align="center"> </TableCell>
                                            <TableCell align="center"> </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell colSpan={7}>
                                                <div className='lineDashed'></div>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box >
            {
                openDetail ? <DetailForm isOpen={openDetail}
                    startDate={dayjs(startDate).format('DD/MM/YYYY')}
                    endDate={dayjs(endDate).format('DD/MM/YYYY')}
                    beginBlance={beginBlance}
                    accountNumber={accountNumberDetail}
                    data={dataDetail} handleClose={() => setOpenDetail(false)} /> : <></>
            }
        </>
    );
})

const mapDispatchToProps = {
    getReportPaymentHistoryWithChild, getIncomeByCode, getOutcomeByCode, getDebitNoteByCode,
    getCreditNoteByCode, getJournalEntrieByCode
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(TrialBalance);