const initialState = []

const departmentReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_DEPARTMENT":
            return action.payload
            
        case "CREATE_DEPARTMENT":
            return [...state, action.payload]

        case "UPDATE_DEPARTMENT":
            
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            
            return [...state]

        case "CHANGE_PASSWORD":
            
                const password = state.findIndex(per => per._id === action.payload._id)
                state[password] = action.payload;
                
                return [...state]

        case "UPDATE_DEPARTMENT_ACTIVE":
            const department_ = [...state] 
            if(action.payload.length > 0)
            {
                (action.payload).forEach((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    department_[index] = item;
                })
            }
            return department_

        case "SET_CURRENT_DEPARTMENT_IMG":
                return [...state]

        case "DELETE_DEPARTMENT":
            const department_old = [...state] 
            var department_deleted = []
           
            if(action.payload.length > 0)
            {
                department_deleted = department_old.filter(del =>!(action.payload).includes(del._id) );
            }
            return department_deleted
        

        default:
            break;
    }

    return state
}

export default departmentReducer