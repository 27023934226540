import * as React from 'react';
import { useState } from 'react';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import { createLanguage } from '../../../actions/language';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';


const initialState = {
    name: "",
    isActive: true,
    code: '',
    description: ''
};


const AddForm = React.forwardRef((props, ref) => {
    

    const [
        { name,  isActive, code, description },
        setState
    ] = useState(initialState);


    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };


    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = () => {
        let data = {
            name, isActive, code, description
        }
        props?.createLanguage(data)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }

    React.useImperativeHandle(ref, () => ({ handleSubmit}));

    return (


        <Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 12, md: 12 }}>

            <Grid item xs={12} md={4} >
                <FormGroup >
                    <FormControlLabel control={<Checkbox />}
                        checked={isActive}
                        name="isActive"
                        onChange={(e) => customeOnChange('isActive', !isActive)}
                        label="Active" />
                </FormGroup>
            </Grid>

            <Grid item md={4} xs={12} >
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off" >
                    <TextField
                        label="Code "
                        id="code"
                        value={code}
                        name="code"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item  md={4} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Name"
                        id="last-name"
                        value={name}
                        name="name"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item lg={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Note"
                        id="last-description"
                        value={description}
                        multiline={true}
                        minRows={4}
                        name="description"
                        onChange={onChange}
                    />
                </Box>
            </Grid>

        </Grid>
    );
})


const mapDispatchToProps = {
    createLanguage
}

export default connect(null, mapDispatchToProps, null,  {forwardRef: true})(AddForm);