import  {setTokenAxios } from "../api/index"
import {_showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";


export const getAllData = (condition) => (dispatch)=> {
  return setTokenAxios().get(`telesale`,condition)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}


export const createUserTele = (data) => (dispatch)=> {
  return setTokenAxios().post(`telesale`,data)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}

export const updateUserTele = (data, _id) => (dispatch)=> {
  return setTokenAxios().put(`telesale/${_id}`,data)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS , data:res.data.data})
    })
    .catch((err) =>{
      _showAlertError(err) 
return  Promise.reject({ message: GET_FAILURE , error: err.data.message })
    })
}
