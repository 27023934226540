
import Popper from '@mui/material/Popper';
import * as React from 'react';


import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, ClickAwayListener, Divider, List, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material';
import { debounce, findIndex, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { getAllDataNoneRedux as getPartner } from '../../actions/partner';
import AddPartner from '../pages/partner/AddPartner';
import MainFormLevel2 from './MainFormLevel2';
import { useTranslation } from 'react-i18next';

const ContactLst = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [listPartner, setListPartner] = React.useState([])
    const [inputValue, setInputValue] = React.useState('')
    const [contact, setContact] = React.useState({})
    const mainFormRef = React.useRef();
    const childFormRef = React.useRef();

    const { t } = useTranslation()

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    React.useEffect(() => {
        const init = async () => {
            await props?.getPartner({ condition: {}, limit: 20 }).then(res => setListPartner(res.data))
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (!isEmpty(props?.contact?.code)) {
            setContact(props?.contact)
            let str = props?.contact?.code
            if (props?.showName === true) {
                str += ' - ' + props?.contact?.firstName + ' ' + props?.contact?.lastName
            }
            setInputValue(str)

            let tmp = [...listPartner]
            let index = findIndex(tmp, { _id: props?.contact?._id })
            if (index < 0) {
                tmp = [...tmp, props?.contact]
            }
            setListPartner(tmp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.contact])

    const onChangeInput = (e) => {
        setInputValue(e.target.value)
        searchAuto(e.target.value)
    }

    const searchAuto = debounce((value) => {

        var data = {
            "condition": {},
            "sort": {
                "created": -1
            },
            "search": '' + value,
            "limit": 20,
            "page": 1
        }
        props?.getPartner(data).then(res => setListPartner(res.data))
    }, 1200)


    const _handleChooseContact = (val) => {
        if (!isEmpty(val?.code)) {
            setContact(val)
            props?.selectContact(val)
            handleClose()
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSetPartnerAfterAdd = (partner) => {
        setContact(partner)
        props?.selectContact(partner)
        setListPartner([partner, ...listPartner])
        mainFormRef.current?.closeFromParent();
    }

    const handleSavePartner = async () => {
        childFormRef.current?.handleSubmit();
    }

    const handleOpenAddPartner = () => {
        mainFormRef.current?.openFromParent()
        handleClose()
    }

    return (
        <React.Fragment>
            <ClickAwayListener onClickAway={handleClose}>
                <div style={{
                    display: 'flex', justifyContent: !isEmpty(contact?.code) ? 'flex-end' : 'flex-end', flexDirection: 'column'
                }}>
                    <Box aria-describedby={id}
                        sx={{
                            color: '#2196F3',
                            cursor: 'pointer',
                        }}
                        onClick={handleClick}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', position: 'relative' }}>
                            <SearchIcon sx={{ color: '#757575', position: 'absolute', left: 3, bottom: 6 }} />
                            {/* {contact?.code || 'Choose contact'} */}
                            <TextField size='small'
                                fullWidth
                                onChange={onChangeInput}
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                                type='text'
                                sx={{
                                    '& input': {
                                        paddingLeft: 3
                                    }
                                }}
                                variant='standard' placeholder='Choose contact' value={inputValue} />
                        </div>
                    </Box>

                    <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 15000 }} placement={"bottom-start"}>
                        <Paper sx={{
                            p: 1, mt: 1, minWidth: 480, borderRight: '1px solid #00000040',
                            minHeight: 'calc(100vh/3)'
                        }}>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography textTransform={'uppercase'} ml={1.5} color={'#009688'}>Contact List</Typography>
                                <Button style={{ textTransform: 'none' }} variant='text' onClick={() => handleOpenAddPartner()}>+ Add</Button>
                            </Box>
                            <List sx={{
                                width: '100%', bgcolor: 'background.paper',
                                maxHeight: 'calc(100vh/2)', overflowY: 'scroll',
                                '& .MuiListItem-root': {
                                    paddingY: 0.2
                                }
                            }}>
                                {
                                    listPartner?.map((res, inde) => {
                                        return <React.Fragment key={inde}>
                                            <ListItem
                                                sx={{
                                                    '&:hover': {
                                                        background: '#f4faff'
                                                    },
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => _handleChooseContact(res)}>
                                                <Box display={'flex'} flexDirection={'column'} width={100} mr={2} pr={2}
                                                    borderColor={'#0000001f'}
                                                    borderRight={1}>
                                                    <Typography variant='title'>{res?.code}</Typography>
                                                    <Typography variant='caption'>{res?.phone?.phoneNumber}</Typography>
                                                </Box>
                                                <ListItemText primary={res?.firstName + ' ' + res?.lastName} secondary={res?.address} />
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    })
                                }

                            </List>
                        </Paper>

                    </Popper>
                </div>
            </ClickAwayListener >

            <MainFormLevel2 ref={mainFormRef} maxWidth={'md'} actionSave={handleSavePartner} subtitle={t('titContact')} title={t('lblCreate')}>
                <AddPartner ref={childFormRef} afterAddPartner={handleSetPartnerAfterAdd} />
            </MainFormLevel2>
        </React.Fragment>
    );
};

const mapDispatchToProps = {
    getPartner
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(ContactLst);
