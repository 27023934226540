const initialState = {
    actionAdd: false,
    actionAddLv2: false,
    activeModule:'',
    activeName:'',
    navigate:'',
    openDrawer: true,
    openTopAlert: false,
    totalAddPayment: 0,
    totalEditPayment:{},
    dataFromPage:{},
    statusFilter: {},
    isBubbleChat: false,
    bubbleChatArr: [],
    dataCurrent: {}
}

const vatReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ENUM":
            return {...state, ...action.payload}

        default:
            return state
    }

}

export default vatReducer