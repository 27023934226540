import { setTokenAxios } from "../api/index"
import { _showAlertError, _showAlertSuccess } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";
import { isEmpty } from "lodash";

export const getChanceById = (id) => (dispatch) => {
  return setTokenAxios().get(`chance/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`chance`, {params:condition})
    .then(async res => {
      dispatch({
        type: "SET_CHANCE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const createChance = (data) => (dispatch) => {
  return setTokenAxios().post(`chance`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_CHANCE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateChance = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`chance/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_CHANCE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const assignToChance = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`chance/${_id}/assign`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_CHANCE",
        payload: res.data.data
      })
      dispatch({
        type: 'UPDATE_CHANCE',
        payload: data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getChanceNoteByChance = (_id, condition) => (dispatch) => {
  return setTokenAxios().get(`chance/${_id}/chance-note`, {params: condition})
    .then(async res => {
      dispatch({
        type: "SET_CHANCE_NOTE",
        payload: res.data.data
      })
      dispatch({
        type: 'UPDATE_CHANCE',
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateNotStartStage = (_id, data) => (dispatch) => {
  return setTokenAxios().put(`chance/${_id}/not-start-stage`, data)
    .then(async res => {
      
      _showAlertSuccess(res?.data?.message || GET_SUCCESS)
      dispatch({
        type: 'SET_EDIT_OBJ',
        payload: res.data.data
      })
      dispatch({
        type: 'UPDATE_CHANCE',
        payload: data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateCommunicateStage = (_id, data, done) => (dispatch) => {
  return setTokenAxios().put(`chance/${_id}/communication-stage`, data)
    .then(async res => {
      _showAlertSuccess(res?.data?.message || GET_SUCCESS)
      let data = {...res.data.data}
     
      if(!isEmpty(data?.communicationStage?.appointment?.date))
      { 
        data.communicationStage.hasAppointment = true
      }
      else
      {
        data.communicationStage.hasAppointment = false
      }
      
      dispatch({
        type: 'SET_EDIT_OBJ',
        payload: data
      })

      dispatch({
        type: 'UPDATE_CHANCE',
        payload: data
      })

      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}



export const updateMeetingStage = (_id, data, done) => (dispatch) => {
  return setTokenAxios().put(`chance/${_id}/meeting-stage`, data)
    .then(async res => {
      _showAlertSuccess(res?.data?.message || GET_SUCCESS)
      let data = {...res.data.data}
      
      dispatch({
        type: 'SET_EDIT_OBJ',
        payload: data
      })
      dispatch({
        type: 'UPDATE_CHANCE',
        payload: data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}




export const updateBookingStage = (_id, data, done) => (dispatch) => {
  return setTokenAxios().put(`chance/${_id}/booking-stage`, data)
    .then(async res => {
      _showAlertSuccess(res?.data?.message || GET_SUCCESS)
      let data = {...res.data.data}
      
      dispatch({
        type: 'SET_EDIT_OBJ',
        payload: data
      })
      dispatch({
        type: 'UPDATE_CHANCE',
        payload: data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const updateDepositStage = (_id, data, done) => (dispatch) => {
  return setTokenAxios().put(`chance/${_id}/deposit-stage`, data)
    .then(async res => {
      _showAlertSuccess(res?.data?.message || GET_SUCCESS)
      let data = {...res.data.data}
      
      dispatch({
        type: 'SET_EDIT_OBJ',
        payload: data
      })
      dispatch({
        type: 'UPDATE_CHANCE',
        payload: data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const updateContractStage = (_id, data, done) => (dispatch) => {
  return setTokenAxios().put(`chance/${_id}/contract-stage`, data)
    .then(async res => {
      _showAlertSuccess(res?.data?.message || GET_SUCCESS)
      let data = {...res.data.data}
      
      dispatch({
        type: 'SET_EDIT_OBJ',
        payload: data
      })
      dispatch({
        type: 'UPDATE_CHANCE',
        payload: data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateDoneStage = (_id, data, done) => (dispatch) => {
  return setTokenAxios().put(`chance/${_id}/done-stage`, data)
    .then(async res => {
      _showAlertSuccess(res?.data?.message || GET_SUCCESS)
      let data = {...res.data.data}
      
      dispatch({
        type: 'SET_EDIT_OBJ',
        payload: data
      })
      dispatch({
        type: 'UPDATE_CHANCE',
        payload: data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}
