const initialState = []

const chatMessageLastedReducer = (state = initialState, action) => {

    switch (action.type) {

        case "SET_CHATMESSAGE_LASTED":
            return action.payload

        case "CREATE_CHATMESSAGE_LASTED":
            return [action.payload, ...state]

        case "UPDATE_CHATMESSAGE_LASTED":
            let tpmLasted = [...state]
            const indexTMp = state.findIndex(per => per.groupChatCode === action.payload.groupChatCode)
            if (indexTMp > -1) {
                tpmLasted.splice(indexTMp, 1)
                // tpmLasted[indexTMp] = action.payload;
                return [action.payload, ...tpmLasted]
            }
            return [...state]

        case "UPDATE_CHATMESSAGE_LASTED_SEEN":

            if (action.payload.length > 0) {
                (action.payload).map((item) => {
                    const index = state.findIndex(per => per.groupChatCode === item.groupChatCode)
                    state[index] = item
                    return [...state];
                })
            }

            return [...state]

        case "UPDATE_CHATMESSAGE_LASTED_ACTIVE":
            if (action.payload.length > 0) {
                (action.payload).map((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    return state[index] = item;
                })
            }
            return [...state]

        case "DELETE_CHATMESSAGE_LASTED":
            var data_deleted = []

            if (action.payload !== '') {
                //const index = state.findIndex(per => per.groupChatCode === action.payload.groupChatCode)

                data_deleted = state.filter(del => del.groupChatCode !== action.payload);//!(action.payload).includes(del._id) );
            }

            return data_deleted


        default:
            break;
    }

    return state
}

export default chatMessageLastedReducer