import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const generateCode = () => (dispatch) => {
  return setTokenAxios().get(`advance-payment/generate-code`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data.code })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getAdvancePaymentByCode = (id) => (dispatch) => {
  return setTokenAxios().get(`advance-payment/get-by-code/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getAdvancePaymentById = (id) => (dispatch) => {
  return setTokenAxios().get(`advance-payment/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`advance-payment`, {params: condition})
    .then(async res => {
      dispatch({
        type: "SET_ADVANCE_PAYMENT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`advance-payment`, {params: condition})
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataMore = (condition) => (dispatch) => {
  return setTokenAxios().get(`advance-payment`, {params: condition})
    .then(async res => {
      dispatch({
        type: "SET_MORE_ADVANCE_PAYMENT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data, meta: res.data.meta })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const createAdvancePayment = (data) => (dispatch) => {
  return setTokenAxios().post(`advance-payment`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_ADVANCE_PAYMENT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateAdvancePayment = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`advance-payment/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_ADVANCE_PAYMENT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const UnActive = (data) => (dispatch) => {
  return setTokenAxios().post(`advance-payment/unactived`,data )
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateWithoutCondition = (data) => (dispatch) => {
  return setTokenAxios().post(`advance-payment/withoutCondition`,data )
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const activeAdvancePayment = (_id, data) => (dispatch) => {
  return setTokenAxios().put(`advance-payment/${_id}/actived`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_ADVANCE_PAYMENT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const starAdvancePayment = (_id, data) => (dispatch) => {
  return setTokenAxios().put(`advance-payment/${_id}/star`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_ADVANCE_PAYMENT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const starMultipleAdvancePayment = (data) => (dispatch) => {
  return setTokenAxios().post(`advance-payment/stared`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_ADVANCE_PAYMENT_MULTI",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteAdvancePayment = (_id) => (dispatch) => {
  return setTokenAxios().delete(`advance-payment/${_id}`)
    .then(async res => {
      dispatch({
        type:"DELETE_ADVANCE_PAYMENT",
        payload: res.data.data
    })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}