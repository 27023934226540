import { createStore, applyMiddleware, compose } from 'redux'
import thunk from "redux-thunk";
import rootReducer from './reducers/index'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// const initialState = {};
const initialState = {
  auth: JSON.parse(window.sessionStorage.getItem('auth') || '{}'),
  profile: JSON.parse(window.sessionStorage.getItem('profile') || '{}'),
 // token: 
}
const middlewares = [thunk];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'profile']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,//rootReducer,
  initialState,
  compose(
    applyMiddleware(...middlewares),
    //    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

const persistor = persistStore(store)
//console.log({initialState})
const storeDataToSessionStorage = () => {
  window.sessionStorage.setItem('auth', JSON.stringify(store.getState().auth));
  window.sessionStorage.setItem('profile', JSON.stringify(store.getState().profile));
  window.sessionStorage.setItem('token', window.localStorage.getItem('token') || '');
  window.sessionStorage.setItem('initApp', window.localStorage.getItem('initApp') || '');
};
store.subscribe(storeDataToSessionStorage);

export { store, persistor };
