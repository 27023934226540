import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { connect } from 'react-redux';
import { getAllDataNoneRedux as getLog } from '../../../../actions/log';
import DetailFormAccountWithChild from './DetailFormAccountWithChild';
import moment from 'moment';

const DetailForm = props => {
    const { data, startDate, endDate, beginBlance, accountNumber } = props
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        handleOpen()
    }, [props?.isOpen])

    React.useEffect(() => {
        // eslint-disable-next-line
    }, [props?.data])


    React.useEffect(() => {
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        setOpen(false);
        props?.handleClose(false)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Dialog
            open={open}
            maxWidth='llg'
            fullWidth={true}
            scroll='paper'
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >

            <DialogTitle id="alert-dialog-title"
                sx={{
                    background: '#2196f3'
                }}>
                <Box display='flex' justifyContent={"space-between"} flexDirection={'column'} alignItems="flex-start" sx={{ color: '#fff' }}>
                    <Typography variant='body'>
                        {`Detailed for ${props?.accountNumber || ''}`}
                    </Typography>
                    <Typography variant='caption'>
                        {`from ${startDate} to ${endDate}`}
                    </Typography>
                </Box>

            </DialogTitle>
            <DialogContent style={{ minHeight: '400px', backgroundColor: '#F7FAFC', paddingTop: 24 }}>
                <Paper sx={{ padding: 2, border: "0.5px solid #eaeaea", borderTop: 'none' }} elevation={0} >
                    <DetailFormAccountWithChild
                        startDate={startDate}
                        accountNumber={accountNumber}
                        accountNumberArr={data?.accountNumber}
                        endDate={endDate}
                        beginBlance={beginBlance}
                        data={data?.data}
                    />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog >
    );
};

const mapDispatchToProps = {
    getLog
}

const mapStateToProps = (state) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DetailForm);