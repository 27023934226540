import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, Grid, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { indigo, pink, teal } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getAdditionalById, updateAdditional } from '../../../actions/additional';
import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';
import { findIndex, isEmpty } from 'lodash';
const initialState = {
    "code": "",
    "name": "",
    "additionalAttributes": [],
    "isActive": true
};
function EditForm(props) {
    const theme = useTheme()
    const editObject = useSelector(state => state.edit)
    const [open, setOpen] = React.useState(false);
    const [currIndex, setCurrIndex] = React.useState('');
    const [currRow, setCurrRow] = React.useState(null);

    const [
        { name, additionalAttributes, isActive, code, _id },
        setState
    ] = useState({ ...editObject });



    const handleClose = () => {
        setOpen(false);
    };

    const getDataById = () => {
        props?.getAdditionalById(_id).then(res => {
            res?.data?.additionalAttributes?.forEach(item => {
                if (item?.type === 'radio' || item?.type === 'checkbox') {
                    let dataTmp = ''
                    let i = 0
                    item?.data?.forEach(val => {
                        dataTmp += JSON.stringify(val) + (i < item?.data?.length - 1 ? '|' : '')
                        i++
                    })
                    item.dataTmp = dataTmp
                }
            })
            setState(state => ({ ...state, ...res.data }))
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        getDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = () => {
        let data = { name, isActive, code, additionalAttributes }
        props?.updateAdditional(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }

    const handleEditRow = (ind) => {
        let reAdd = { ...additionalAttributes[ind] }
        setCurrIndex(ind);
        setCurrRow({ ...reAdd })
        setOpen(true);
    }

    const handleAddRow = (ind) => {
        setCurrIndex(ind);
        setCurrRow({
            code: '', name: '', type: 'text', options: { isRequired: false }, data: []
        })
        setOpen(true);
    }

    const handleCancel = () => {
        setCurrRow(null)
        handleClose()
    }

    const handleSave = () => {
        if (currIndex !== '' && !isEmpty(currRow)) {
            let reAdd = [...additionalAttributes]
            let ext = findIndex(reAdd, { code: currRow?.code })

            if (ext > -1) {
                if (currRow?.type === "radio" || currRow?.type === "checkbox") {
                    let dataTmp = currRow?.dataTmp.split("|")
                    let tmp = []
                    dataTmp?.forEach(item => {
                        tmp.push(JSON.parse(item))
                    })
                    currRow.data = tmp
                }
                reAdd[currIndex] = currRow
                setState(state => ({ ...state, additionalAttributes: reAdd }))
            }
            else {
                let reAdd = [...additionalAttributes]
                setState(state => ({ ...state, additionalAttributes: [...reAdd, currRow] }))
            }
        }

        setCurrRow(null)
        setCurrIndex('')
        handleClose()
    }

    const handleCurrRow = (key, value) => {
        let data = { ...currRow }
        switch (key) {
            case 'isRequired':
                data.options.isRequired = value
                setCurrRow(data)
                break;

            case 'isMultipleFile':
                data.options.isMultipleFile = value
                setCurrRow(data)
                break;

            case 'code':
                data.code = value
                setCurrRow(data)
                break;

            case 'name':
                data.name = value
                setCurrRow(data)
                break;

            case 'type':
                data.type = value
                setCurrRow(data)
                break;

            case 'data':
                data.dataTmp = value
                setCurrRow(data)
                break;

            default:
                break;
        }
    }


    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8 }}>
            <Stack direction="row" spacing={1}
                justifyContent='space-between'
                alignItems="center" sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.main
                }}>
                <Stack direction={'row'}>
                    <Chip label="Thêm" variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
                    <Typography variant='h5' color={'#fff'}>
                        Cấu hình Stage
                    </Typography>
                </Stack>
                <Button variant='contained' disableElevation onClick={() => handleSubmit()}>
                    Lưu
                </Button>
            </Stack>
            <Box sx={{ paddingX: 2, paddingY: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color={teal[500]}>
                            Thông tin cơ bản
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    disabled
                                    label="Tên"
                                    id="last-name"
                                    value={name}
                                    name="name"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Mã "
                                    disabled
                                    id="code"
                                    value={code}
                                    name="code"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label="Kích hoạt" />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='body' color={teal[500]}>
                            Danh sách thuộc tính
                        </Typography>

                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell align="left">Tên</TableCell>
                                        <TableCell align="left">Type</TableCell>
                                        <TableCell align="left">Bắt buộc</TableCell>
                                        <TableCell align="left">Dữ liệu <i>(nếu có)</i></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {additionalAttributes.map((row, ind) => (
                                        <TableRow
                                            key={row?.code}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left" width={100}>
                                                {row?.code}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant='subtitle2' color={indigo[500]} sx={{ lineHeight: 1, display: 'flex', justifyContent: 'space-between' }}>
                                                    {row?.name} <EditOutlinedIcon sx={{ fontSize: 18, cursor: 'pointer' }} onClick={() => handleEditRow(ind)} />
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">{row?.type}{row?.type === 'file' && row?.options?.isMultiple ? ' (n)' : ''}</TableCell>
                                            <TableCell align="left">{row?.options?.isRequired === true ? <CheckOutlinedIcon sx={{ color: teal[500] }} /> : <CloseOutlinedIcon sx={{ color: pink[500] }} />}</TableCell>
                                            <TableCell align="left">{row?.data?.map((res, index) => {
                                                return `${res?.name}${index !== row?.data?.length - 1 ? ', ' : ''}`
                                            })}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={5} sx={{
                                            border: 'none'
                                        }}>
                                            <div style={{
                                                cursor: 'pointer',
                                                color: '#009578'
                                            }} onClick={() => handleAddRow()}>Thêm...</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Grid>

                </Grid>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {currRow?.name ? `${currRow?.name} <i>(${currRow?.code})</i>` : 'Thêm thuộc tính'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />}
                            checked={currRow?.options?.isRequired}
                            name="isRequired"
                            onChange={(e) => handleCurrRow('isRequired', e.target.checked)}
                            label="Bắt buộc" />
                    </FormGroup>
                    <TextField
                        margin="dense"
                        id="codeRow"
                        label="Mã"
                        size='small'
                        type="text"
                        sx={{ mb: 1.5 }}
                        onChange={(e) => handleCurrRow('code', e.target.value)}
                        value={currRow?.code}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        id="nameRow"
                        label="Tên"
                        size='small'
                        type="text"
                        sx={{ mb: 1.5 }}
                        onChange={(e) => handleCurrRow('name', e.target.value)}
                        value={currRow?.name}
                        fullWidth
                        variant="outlined"
                    />

                    <FormControl >
                        <FormLabel id="demo-controlled-radio-buttons-group">Loại dữ liệu</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={currRow?.type || ''}
                            onChange={e => handleCurrRow('type', e.target.value)}>
                            <FormControlLabel value="text" control={<Radio />} label="Text" />
                            <FormControlLabel value="number" control={<Radio />} label="Number" />
                            <FormControlLabel value="checkbox" control={<Radio />} label="Checkbox" />
                            <FormControlLabel value="radio" control={<Radio />} label="Radio" />
                            <FormControlLabel value="file" control={<Radio />} label="File" />
                        </RadioGroup>
                    </FormControl>
                    {currRow?.type === 'file' ?
                        <FormGroup sx={{ borderTop: '0.5px solid #ccc', mt: 1, pt: 1 }}>
                            <Typography variant='caption'><i>Cho phép lưu nhiều file?</i></Typography>
                            <FormControlLabel control={<Checkbox />}
                                checked={currRow?.options?.isMultiple}
                                name="isMultiple"
                                onChange={(e) => handleCurrRow('isMultipleFile', !currRow?.options?.isMultiple)}
                                label="Có" />
                        </FormGroup> : <></>
                    }

                    {currRow?.type === 'checkbox' || currRow?.type === 'radio' ?
                        <input
                            rows={4}
                            style={{ width: '100%' }}
                            onChange={(e) => handleCurrRow('data', e.target.value)}
                            value={currRow?.dataTmp}></input>
                        : <></>}

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Đóng</Button>
                    <Button onClick={handleSave}>Cập nhật</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
const mapDispatchToProps = {
    getAdditionalById, updateAdditional
}
const mapStateToProps = (state) => {
    return {
        editObj: state.edit
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditForm);