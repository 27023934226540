import { Typography } from "@mui/material";
import { orange, teal } from "@mui/material/colors";
import { find } from "lodash";
import i18n from '../i18n';

const ActiveStatus = (isActive) => {

    if (isActive) {
        return <Typography variant="body" sx={{ color: teal[500] }}>{i18n.t('lblActive')}</Typography>;
    }
    else {
        return <Typography variant="body" sx={{ color: orange[700] }}>{i18n.t('lblLock')}</Typography>;
    }
}

const allStage = [
    {
        code: 'notStart',
        name: 'Khởi động',
        color: '#f44336',
    }, {
        code: 'communication',
        name: 'Liên hệ',
        color: '#304FFE'
    },
    {
        code: 'meeting',
        name: 'Hẹn gặp mặt',
        color: '#9c27b0'
    },
    {
        code: 'booking',
        name: 'Booking',
        color: '#009688'
    },
    {
        code: 'deposit',
        name: 'Đặt cọc',
        color: '#2196f3'
    },
    {
        code: 'contract',
        name: 'Hợp đồng',
        color: '#e91e63'
    },
    {
        code: 'done',
        name: 'Hoàn thành',
        color: '#009688'
    }]

const getNameOfStage = (code) => {
    let data = find(allStage, { code })
    return <Typography variant="body2" sx={{ color: data?.color }}>{data?.name}</Typography>
}

const getStageOfType = (code) => {
    let data = find(allStage, { code })
    return data
}

const getStatusDone = (code) => {
    let css = {
        
            padding: '0px 12px',
            fontSize: '11px',
            borderRadius: '6px',
            position: 'absolute', left: 0, top: '50%', color: '#fff' 
    }
    
    switch (code) {
        case 'fail':
            return <Typography sx={{background: '#f44336', ...css}}>Thất bại</Typography>
        case 'success':
            return <Typography sx={{background: '#009688', ...css}}>Thành công</Typography>
        case 'cancel':
            return <Typography sx={{background: '#616161', ...css}}>Đã hủy</Typography>

        default:
            break;
    }
}


export { ActiveStatus, getNameOfStage, allStage, getStageOfType, getStatusDone }