import { Box, Typography, useTheme } from '@mui/material';
import { indigo } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getAllData, deleteLanguage } from '../../../actions/language';
import { setEditObject } from '../../../actions/untils';
import { ActiveStatus } from '../../../enums/activeStatus';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import BreadCrumb from '../../elements/breadCrumb';
import DrawerExpand from '../../elements/drawerExpand';
import MainForm from '../../elements/MainForm';
import RowMenu from '../../elements/rowMenu';
import AddForm from './AddForm';
import EditForm from './EditForm';

const Main = (params) => {
  const { props } = params
  const theme = useTheme()
  const {t} = useTranslation() 
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const mainFormRef = React.useRef();
  const childFormRef = React.useRef();

  const [type, setType] = useState('add')

  const actionAdd = useSelector(state => state?.enumRedux?.actionAdd)

  useEffect(() => {
    if (actionAdd === true) {
      setType('add')
      mainFormRef.current?.openFromParent();
    }
  }, [actionAdd])


  let listData = [...props?.data]

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionMenu = async (value, type) => {
    mainFormRef.current?.openFromParent();
    setType(type)
    if (type === 'delete') {
      await props?.setEditObject({})
      deleteData(value)
    }
    else {
      if (type !== 'add') {
        dispatch({
          type: "SET_ENUM",
          payload: {
            actionAdd: true,
          }
        })
        await props?.setEditObject(value)
      }
    }
  }

  const deleteData = (value) => {
    props?.deleteLanguage(value?._id)
      .then(res => {
        setType('')
        _showAlertSuccess(res?.message || GET_SUCCESS)
      })
  }

  const handleSave = async () =>{
    childFormRef.current?.handleSubmit();
  }

  useEffect(() => {
    props?.getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])  



  const renderRow = () => {

    let list = rowsPerPage > 0
      ? listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : listData


    return list.map((row, index) => (
      <TableRow key={row._id} >
        <TableCell align="left">
          <RowMenu 
          data={row}
          actionEdit={handleActionMenu}
          sx={{ mr: 2 }} />
          {page * rowsPerPage + index + 1}.
        </TableCell>
        <TableCell align="left">
          {row?.code}
        </TableCell>
        <TableCell component="th" scope="row" sx={{
          borderWidth: 0.5
        }}>
          <Typography variant='subtitle2' color={indigo[500]} sx={{ lineHeight:1 }}>
            {row?.name}
          </Typography>
        </TableCell>
        <TableCell align="right">{row?.description}</TableCell>
        <TableCell align="right">{ActiveStatus(row.isActive)}</TableCell>
      </TableRow>
    ))
  }

  let breadcrumb = [{ name: 'Home', href: '/' }, { name: 'Language', href: '/language' }]
  return (
    <>
      <BreadCrumb data={breadcrumb} />
      <TableContainer component={Paper} sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 0,
        padding: 2,
        borderRadius: 2
      }}>
        <Box sx={{ fontSize: 24 }}>
          {/* Table of Customer */}
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">

          <TableHead>
            <TableRow sx={{
              fontWeight: 'bold'
            }}>
              <TableCell>No.</TableCell>
              <TableCell>Code.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow()}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                 rowsPerPageOptions={[8, 16, 25, { label: `${t('pagingAll')}`, value: -1 }]}
                 colSpan={6}
                 count={listData.length}
                 rowsPerPage={rowsPerPage}
                 page={page}
                 SelectProps={{
                   inputProps: {
                     'aria-label': t('pagingPerPage'),
                   },
                   native: true,
                 }}
                 labelRowsPerPage={t('pagingPerPage')}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {type !== 'delete'?
      <MainForm ref={mainFormRef} maxWidth={'sm'} actionSave={handleSave} subtitle={t('titLanguage')} title={type === 'add' ?t('lblCreate') : t('lblUpdate')}>
        {type === 'add' ? <AddForm ref={childFormRef}/> : <EditForm ref={childFormRef}/>}
      </MainForm>:<></>}
    </>
  )
}

const Language = (props) => {
  const {t} = useTranslation() 
  let title = t('titLanguage')
  document.title = title
  return (
    <>
      <DrawerExpand headerText={title}>
        <Main props={props} />
      </DrawerExpand>
    </>
  );
};

const mapDispatchToProps = {
  getAllData, setEditObject, deleteLanguage
}

const mapStateToProps = (state) => {
  return {
    data: state.language
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Language);