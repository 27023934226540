import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import {
    deleteObject,
    getDownloadURL,
    getMetadata,
    ref,
    uploadBytes
} from "firebase/storage";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createFileFileBase, deleteFile } from "../../../actions/file";
import { storage } from "../../../config/firebase";
import { getTypeFile, randomStringX } from "../../../untils/functions";
import { isArray } from 'lodash';
import { DetailsOutlined } from '@mui/icons-material';
import ViewDocumentSingle from './ViewDocumentSingle';


const AttachFileForm = (props) => {
    // const [imageUpload, setImageUpload] = useState(null);
    let folder = props?.module || 'files'
    let attachFile = []
    if (isArray(props?.attachFile)) {
        attachFile = [...attachFile, ...props?.attachFile]
    }
    const [isUpload, setIsUpload] = useState(false);
    const [viewDocument, setViewDocument] = useState(false);
    const [url, setUrl] = useState('');

    // const imagesListRef = ref(storage, "advancedPayment/"); list all file in folder in firebase
    const uploadFile = async (imageUpload) => {
        if (imageUpload == null) return;
        setIsUpload(true)
        const imageRef = ref(storage, `${folder}/${randomStringX(4)}-${imageUpload?.name}`);

        let data = {
            source: 'LINK',
            from: 'FIREBASE',
        }

        uploadBytes(imageRef, imageUpload).then(async (snapshot) => {
            let exten = imageUpload?.name.split('.')
            if (exten.length > 0) {
                data.extension = exten[exten.length - 1]
            }

            //add information of data insert
            await getMetadata(imageRef).then((metadata) => {
                data.originalName = metadata?.name
                data.name = metadata?.name
                data.path = metadata?.fullPath
                data.mimeType = metadata?.contentType
                data.size = metadata?.size + ''
                data.dimensions = {}
                data.encoding = metadata?.contentEncoding
                data.type = getTypeFile(data)
            })

            await getDownloadURL(snapshot.ref).then((url) => {
                data.link = url
            });

            await props?.createFileFileBase(data).then(res => {
                setIsUpload(false)
                props?.updateData(res?.data)
            })
        });
    };

    useEffect(() => {
        // listAll(imagesListRef).then((response) => {
        //     response.items.forEach((item) => {
        //         getDownloadURL(item).then((url) => {
        //             setImageUrls((prev) => [...prev, url]);
        //         });
        //     });
        // });
    }, []);

    const deleteFile = (item) => {
        // Create a reference to the file to delete
        const desertRef = ref(storage, props?.module + '/' + item?.name);

        // Delete the file
        deleteObject(desertRef).then(() => {
            // File deleted successfully
            console.log('File deleted fire successfully')
            props?.deleteFile(item?._id).then(res => {
                console.log('File deleted local successfully')
                props?.deleteData(item)
            })

        }).catch((error) => {
            // Uh-oh, an error occurred!
        });

    }

    const openDocument = (item) => {
        setViewDocument(true)
        setUrl(item.link)
    }

    const closeDocument = () => {
        setViewDocument(false)
    }

    return (
        <div className="AttachFileForm">
            <div className="lineUpload">
                <div class="upload-btn-wrapper">
                    {isUpload ?
                        <LoadingButton
                            loading
                            size="small"
                            loadingPosition="start"
                            startIcon={<CloudUploadOutlinedIcon />}
                            variant="outlined">
                            Uploading...
                        </LoadingButton>
                        :
                        <IconButton
                            size="small"
                            aria-label="Choose file" color="primary">
                            <AttachmentOutlinedIcon sx={{ marginRight: 1 }} />
                            Choose file
                        </IconButton>}
                    <input
                        type="file"
                        onChange={(event) => {
                            // setImageUpload(event.target.files[0]);
                            uploadFile(event.target.files[0])
                        }}
                    />
                </div>
            </div>
            <div className="lineResult">
                {
                    attachFile.map((item, ind) => {
                        return <div className="lineFileDocument" key={ind}>
                            <div className="sttFile">
                                {ind + 1}
                            </div>
                            <div className="nameOfFile">
                                {item.name}
                            </div>
                            <div className="typeOfFile">
                                {item.extension}
                            </div>
                            <DeleteOutlineOutlinedIcon
                                onClick={() => deleteFile(item)}
                                fontSize="small" sx={{ marginLeft: 2, color: '#9E9E9E' }} />
                            <DetailsOutlined
                                onClick={() => openDocument(item)}
                                fontSize="small" sx={{ marginLeft: 2, color: '#9E9E9E' }} />
                        </div>
                    })
                }
            </div>
            {viewDocument && <ViewDocumentSingle url={url} close={closeDocument} />}
        </div>
    );
}

const mapDispatchToProps = {
    createFileFileBase, deleteFile
}
export default connect(null, mapDispatchToProps)(AttachFileForm);