import { Box } from '@mui/material';
import React from 'react';
import A402 from './A402';
//ແບບຟອມ ເຄຍເງິນ 
const FormA402 = React.forwardRef((props, ref) => {
    return <Box ref={ref} className="printTemplate pixA4">
        <A402 />
    </Box>
});

export default FormA402;