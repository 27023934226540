import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SaveIcon from '@material-ui/icons/Save';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from "react-redux"
import _, { isEmpty } from "lodash"
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import * as taskAction from "../../../actions/task"
import * as userAction from "../../../actions/user"
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { registerLocale } from "react-datepicker";
import vi from 'date-fns/locale/vi';
import DatePicker from "react-datepicker";
import config from '../../../config/index'
import "react-datepicker/dist/react-datepicker.css";
import { Divider, FormControl, FormLabel } from '@mui/material';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import i18next from 'i18next';

registerLocale('vi', vi)
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }
});


class AddingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: 'paper',
      open: false,
      name: "",
      modules: "",
      labelWidth: 0,
      isActive: false,
      memberList: [],
      error: {},
      parentCode: "null",
      subTasks: [],
      newSubTask: '',
      descriptionSubTask: '',
      description: '',
      deadline: new Date()
    }
  }


  init = async () => {
    await this.props.getAllData()
  }

  componentDidMount() {
    this.init()
    if (this.props.edit === true) {
      this.props.getTaskById(this.props.task.id)
        .then(res => {

          var memberArr = res.success.assignedUsers
          var memberList = this.props.userAll.filter(ft => {
            return memberArr.findIndex(arr => arr.userId._id === ft._id) > -1
          })
          var isMainTmp = _.find(memberArr, { isMain: true })

          this.setState({
            memberList: memberList,
            name: res.success.name,
            description: res.success.description,
            deadline: new Date(res.success.deadline),
            ismain: isMainTmp?.userId?._id
          })
        })
        .catch(err => console.log('editTask', err))
    }
    else {
      var memberList = this.props.userAll.filter(ft => {
        return this.props.profile?.userId === ft._id
      })
      this.setState({ memberList: memberList })
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  activeChange = e => {
    this.setState({ isActive: e.target.checked })
  };


  addGroup = (item) => {
    var memberList = this.state.memberList
    var index = _.findIndex(memberList, { _id: item._id })
    if (item._id !== this.props.profile.userId) {
      if (index > -1) {
        memberList.splice(index, 1)
        this.setState({ memberList: memberList })
      }
      else {
        memberList.push(item)
      }

      this.setState({ memberList: memberList })
    }
  };

  onSubmit = () => {
    const { name, description, deadline, memberList, subTasks } = this.state
    var assignedUsers = []

    memberList.map(mn => {
      return assignedUsers.push({
        userId: mn._id,
        isMain: this.state.ismain === mn._id ? true : false
      })
    })

    var post = { name, description, deadline, assignedUsers, subTasks }

    if (this.props.edit === true) {
      this.props.updateTask(this.props.task._id, post)
        .then(res => {
          this.props.close()
        })
        .catch(error => {
          this.setState(error)
        })
    }
    else {
      this.props.createTask(post)
        .then(res => {
          console.log('task', res);
          //this.props.close()
        })
        .catch(error => {
          this.setState(error)
        })
    }

  }

  filterByNames = (data, inputValue) => {
    // Create a dynamic regex expression object with ignore case sensitivity
    const re = new RegExp(_.escapeRegExp(inputValue), "i");
    const results = data.filter((item) => {
      if (re.test(item?.lastName)) {
        const matches = match(item?.lastName, inputValue);
        item["parts"] = parse(item?.lastName, matches);
        return true;
      }
      if (re.test(item?.firstName)) {
        const matches = match(item?.firstName, inputValue);
        item["parts"] = parse(item?.firstName, matches);
        return true;
      }
      else {
        return false;
      }

    });
    return results;
  };

  createSubTask = () => {
    const tmp = [...this.state.subTasks]
    if (!isEmpty(this.state.newSubTask)) {
      tmp.push({
        name: this.state.newSubTask,
        description: this.state.descriptionSubTask
      })
      this.setState({ subTasks: tmp })
    }
  }

  deleteTask = (index) => {
    const tmp = [...this.state.subTasks]
    tmp.splice(index, 1)
    this.setState({ subTasks: tmp })
  }

  render() {
    const { classes, userAll, profile } = this.props
    const { memberList, name, keyContact } = this.state
    const results = !keyContact ? userAll : this.filterByNames(userAll, keyContact);

    return (
      <React.Fragment>
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={true}
          onClose={this.props.close}
          TransitionComponent={Transition}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <AppBar className={classes.appBar} elevation={0}>
            <Toolbar style={{ justifyContent: 'space-between' }}>
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <IconButton edge="start" color="inherit" onClick={this.props.close} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" >
                  {i18next.t('frmTaskTitle')}
                </Typography>
              </div>

              <Button
                variant="contained"
                color="secondary"
                elevation={0}
                startIcon={<SaveIcon />}
                onClick={() => this.onSubmit()}
              >
                {i18next.t('btnSave')}
              </Button>

            </Toolbar>
          </AppBar>
          <DialogContent style={{ minHeight: 400, background: '#d7d7d7', padding: 16 }}>
            <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
              <Grid container spacing={2}>

                <Grid item xs={8} style={{ padding: '0px 12px' }}>
                  <div className="mainContactList">
                    <FormControl component="fieldset">
                      <FormLabel component="legend" className='titleBold' sx={{ fontWeight: 550 }}>
                        {i18next.t('frmTaskName')}
                      </FormLabel>
                      <TextField id="outlined-search"
                        type="text" variant="outlined"
                        size="small"
                        name="name"
                        onChange={this.onChange}
                        value={name}
                        style={{ width: '100%', marginBottom: 16 }} />
                    </FormControl>

                    <FormControl component="fieldset">
                      <FormLabel component="legend" className='titleBold' sx={{ fontWeight: 550 }}>
                        {i18next.t('frmDescription')}
                      </FormLabel>
                      <TextareaAutosize aria-label="minimum height"
                        style={{ width: '100%', border: '1px solid #ccc', borderRadius: '5px', padding: ' 8px 12px' }}
                        name="description"
                        minRows={4} onChange={this.onChange}
                        value={this.state.description}
                      />
                    </FormControl>

                  </div>

                  <div className='detailTaskInput'>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth component="fieldset" sx={{
                          marginTop: 2,
                          '& input': {
                            marginTop: 0,
                            width: '100%'
                          }
                        }}>
                          <FormLabel component="legend" className='titleBold' sx={{ fontWeight: 550 }}>Deadline</FormLabel>
                          <DatePicker
                            selected={this.state.deadline}
                            locale="en"
                            className="dateCustom"
                            dateFormat="dd-MM-yyyy"
                            onChange={date => this.setState({ deadline: date })} />
                        </FormControl>

                        <FormLabel component="legend" className='titleBold' sx={{ fontWeight: 550, marginTop: 1 }}>
                          {i18next.t('frmTaskSubTit')}
                        </FormLabel>
                        <div className="addSubTask">
                          <div className="inputSubLeft">
                            <input className="contentSubTaskInput" value={this.state.newSubTask} name="newSubTask" placeholder={i18next.t('frmName')} onChange={this.onChange} />
                            <textarea className="contentSubTaskInput desc" value={this.state.descriptionSubTask} placeholder={i18next.t('frmDescription')} name="descriptionSubTask" onChange={this.onChange}> </textarea>
                          </div>
                          <div className="btnSubTask">
                            <span onClick={() => this.createSubTask()} style={{ color: "#fff", background: '#009688' }}>
                              {i18next.t('btnCreate')}
                            </span>
                            <span onClick={() => this.setState({ isEdit: false })} style={{ color: '#fff', background: '#E91E63' }}>
                              {i18next.t('btnClose')}
                            </span>
                          </div>
                        </div>

                      </Grid>

                      <Grid item xs={6}>
                        <FormLabel component="legend" className='titleBold' sx={{ fontWeight: 550, marginTop: 1 }}>
                          {i18next.t('frmTaskSubLst')}:
                        </FormLabel>
                        <ul className="listSubTask">
                          {this.state.subTasks.map((dt, index) => {
                            return <li className={`${dt?.isComplete === true && 'finshed'}`} key={`sub${index}`}>
                              <div className="chkComplete">{dt?.isComplete === true ? <CheckOutlinedIcon /> : <CodeOutlinedIcon />}</div>
                              <div className="ctSub">
                                <label className="contentSubTask">{dt?.name}</label>
                                <p>{dt?.description}</p>
                                <div className="subTaskTool">
                                  {/* <div className="itemSub" onClick={() => this.getComment(dt)}>
                                    <span className="icoItem"><img alt="img" src="/resources/icons/comment.png" /></span>
                                  </div> */}
                                  {dt?.isComplete !== true && <div className="itemSub" onClick={() => { if (window.confirm('Bạn có chắc chắn muốn xóa dữ liệu không?')) { this.deleteTask(index) } }}>
                                    <span className="icoItem"><img alt="img" src="/resources/icons/delete.png" /></span>
                                  </div>}
                                </div>
                              </div>
                            </li>
                          })}
                        </ul>
                      </Grid>

                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={4} style={{ padding: 16, background: '#fff', borderRadius: 6 }}>

                  <div className="topContactList">
                    {/* <IconButton aria-label="delete" onClick={()=> this.props.actionShow(this.props.showContactList)}>
                        <img src="/assets/icon/back.png" alt="back to chat"/>
                    </IconButton> */}
                    <div className="searchTool">
                      <div className="group-search" >
                        <input
                          className="input-Search"
                          value={this.state.keyContact}
                          name="keyContact"
                          placeholder="Tìm kiếm ..."
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="icon-search">
                        <SearchOutlinedIcon />
                      </div>
                    </div>
                  </div>
                  <div className="memberList" style={{ minHeight: 80 }}>
                    <div className="memberListTask">
                      {i18next.t('frmTaskMemLst')} ({memberList.length}):</div>
                    {memberList.map((item, index) => {
                      return <div className="lineMember" key={`${index}c`}>
                        {/*<div className="itemMember">
                                <div className="imgMember">
                                  <div className="contentImg">
                                    {_.get(item, 'fileAvatar.path', '') === '' ? item?.lastName.charAt(0) : <img src={`${config.baseURL}${_.get(item, 'fileAvatar.path', '')}`} alt={item?.lastName} />}
                                  </div>
                                  {index > 0 && <div className="delIt" onClick={() => this.addGroup(item)}>-</div>}
                                </div> 
                              </div> */}
                        <div className="nameMember">{index + 1}. {item?.firstName} {item?.lastName}</div>
                        <div className="checkIsLead">
                          <input type="radio" name="ismain" value={item._id} checked={this.state.ismain === item._id} onChange={this.onChange} />
                        </div>
                      </div>
                    })}
                  </div>
                  <Divider />
                  <div className="memberListTask">{i18next.t('frmUserLst')}:</div>
                  <ul className="toolInfo member">
                    {results.map(item => {
                      var isExist = _.findIndex(memberList, { _id: item._id })
                      return item?.employeeId !== profile?.employeeId && <li className={`addMem ${isExist > -1 ? 'active' : ""}`} key={item._id} onClick={() => this.addGroup(item)}>
                        <div className="imgMember">
                          <div className="contentImg">
                            {_.get(item, 'fileAvatar.path', '') === '' ? item?.lastName.charAt(0) : <img src={`${config.baseURL}${_.get(item, 'fileAvatar.path', '')}`} alt={item?.lastName} />}
                          </div>
                        </div>
                        <div className="textTool">
                          <span className="contactName">{item?.firstName} {item?.lastName}</span>
                          {/* <p>{_.get(item, 'profile.department.name', '')}</p> */}
                        </div>
                      </li>
                    })}
                  </ul>
                </Grid>
              </Grid>
            </form>
          </DialogContent>

        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAll: state.user,
    profile: state.profile
  }
}


export default withStyles(styles)(connect(mapStateToProps, { ...userAction, ...taskAction })(AddingForm));