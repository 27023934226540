import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { updateContractStage } from '../../../../actions/chance';
import ChanceNote from './ChanceNote';


const Step5 = (props) => {

    const editObject = useSelector(state => state.edit)
    const [signedContract, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  useEffect(() => {

    const stateChange = () => {
        let { signedContract } = { ...editObject?.contractStage }
        setChecked(signedContract)
    }
    stateChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
    
const handleSubmit = () => {
    let data = {
        contractStage: {},
        // stage:'communication'
    }
    data.contractStage.signedContract = signedContract
    // console.log({data})
    if (data?.contractStage) {
        props?.updateContractStage(editObject?._id, data)
    }

}

    return (
        <Box component={'div'} sx={{minHeight: 450, padding:2}}>
            <Grid container spacing={2}>
                <Box sx={{
                    marginY: 4,
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    flex:1,
                    background:'#F7FAFC',
                    paddingY:3,
                    paddingX: 1
                }}>
                <FormGroup>
                    <FormControlLabel control={ <Checkbox
                        checked={signedContract}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />} label="Đã ký hợp đồng" />
                </FormGroup>
                <Button variant="contained" disableElevation
                            sx={{ mt: 3 }} onClick={() => handleSubmit()}
                        >Cập nhật</Button>
                </Box>
                {props?.activeStep===5&&
                    <ChanceNote stage={props?.stage}></ChanceNote>
                }
            </Grid> 
        </Box>
    );
};


const mapDispatchToProps = {
    updateContractStage
  }
  export default connect(null, mapDispatchToProps)(Step5);