import React, { useState } from 'react';
import { useEffect } from 'react';
import "./custom.css";
import DrawerExpand from "../../elements/drawerExpand";
import { getMyTask, startTask, replaceTask, completeTask, deleteTask, getTaskById } from "../../../actions/task";
import { getAllData as getUser } from "../../../actions/user";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import moment from 'moment';
import AddingForm from "./AddingForm";
import TaskDetail from "./TaskDetail";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import { Typography } from '@mui/material';
import AddIcon from "@material-ui/icons/Add";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const grid = 8;


const initialState = {
    openAdd: false,
    isEdit: false,
    notStarted: [],
    inProcessing: [],
    completed: [],
    editItem: {},
    detailTask: {}
}

const Main = (params) => {
    const { props } = params
    const {t} = useTranslation()
    const [
        { openAdd, isEdit, editItem },
        setState
    ] = useState({ ...initialState });

    const handleOpenAdd = () => setState(state => ({ ...state, openAdd: true }));
    const handleOpenEdit = (item) => setState(state => ({ ...state, openAdd: true, isEdit: true, editItem: item }));
    //    const handleCloseEdit = () => setState(state=>({...state, openEdit: false, isEdit: false, editItem: {} }));
    const handleCloseAdd = () => setState(state => ({ ...state, openAdd: false, isEdit: false }));

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "#2196f3" : "#e1f5fe",
        padding: grid,
        width: 250,
        borderRadius: 8,
        maxHeight: 'calc(100vh - 115px)',
        overflowY: 'scroll',
        paddingBottom: '0px'
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        borderRadius: 8,
        // change background colour if dragging
        background: isDragging ? "#f2f2f2" : "#fff",
        color: isDragging ? "black" : "black",
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const init = async () => {
        await props?.getMyTask('NOT_STARTED')
        await props?.getMyTask('IN_PROGRESS')
        await props?.getMyTask('COMPLETED')
        await props?.getUser()
    }

    useEffect(() => {
        init()
        // eslint-disable-next-line
    }, [])

    const id2List = {
        notStarted: "notStarted",
        inProcessing: "inProcessing",
        completed: "completed",
    };

    const getList = (id) => props.tasks[id2List[id]];

    /**
     * Moves an item from one list to another list.
     */
    const moveItem = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        var dist = [droppableSource.droppableId, droppableDestination.droppableId];
        var main = ["notStarted", "inProcessing", "completed"];
        var remain = main.filter((mn) => dist.indexOf(mn) < 0);
        result[remain[0]] = props.tasks[remain[0]];

        return result;
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const notStarted = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            let stateTmp = { notStarted };

            if (source.droppableId === "inProcessing") {
                stateTmp = { inProcessing: notStarted };
            }

            if (source.droppableId === "completed") {
                stateTmp = { completed: notStarted };
            }

            setState(state => ({ ...state, ...stateTmp }));
        } else {
            if (source.droppableId === 'notStarted' && destination.droppableId === 'inProcessing') {
                var sourceData = getList(source.droppableId)
                var destinationData = getList(destination.droppableId)

                const result = moveItem(
                    sourceData,
                    destinationData,
                    source,
                    destination
                );
                // console.log("start", source, sourceData[source.index]);
                props?.startTask({ taskId: sourceData[source.index]._id })
                props?.replaceTask(result)
            }

            if (source.droppableId === 'inProcessing' && destination.droppableId === 'completed') {
                var sourceData1 = getList(source.droppableId)
                var destinationData1 = getList(destination.droppableId)

                const result = moveItem(
                    sourceData1,
                    destinationData1,
                    source,
                    destination
                );
                // console.log("completed");
                props?.completeTask({ taskId: sourceData1[source.index]._id })
                props?.replaceTask(result)
            }

        }
    };

    const deleteTask = (item) => {
        props?.deleteTask(item._id)
    }

    const loadDetail = (item) => {
        props?.getTaskById(item._id)
    }

    return (
        <>
            <Grid
                container
                style={{ marginTop: 16 }}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >

                <Grid item xs={12} sm={8} className="allTask">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className="columnTask">
                            <div className="titleTask">
                               { t('frmTaskNotStart')}
                            </div>

                            {props.tasks?.notStarted &&
                                <Droppable droppableId="notStarted">
                                    {(provided, snapshot) => {
                                        const style = snapshot.isDraggingOver
                                            ? {
                                                "pointer-events": "none"
                                            }
                                            : {};
                                        return <div
                                            ref={provided.innerRef}
                                            className="listTask"
                                            style={{ ...style, ...getListStyle(snapshot.isDraggingOver) }}>
                                            {props.tasks?.notStarted.map((item, index) => {
                                                return <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            className="itemTask">
                                                            <Typography color={'#009688'} fontWeight={600} variant="body1">{item.name}</Typography>
                                                            <ul className="toolItem">
                                                                <li>
                                                                    <span className="icoItem"><img alt="img" src="/resources/icons/clock.png" /></span>
                                                                    <span>{moment(item.deadline).format('L')}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="icoItem"><img alt="img" src="/resources/icons/list.png" /></span>
                                                                    <span>{_.get(item, 'subTaskIdArr', []).length}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="icoItem"><img alt="img" src="/resources/icons/team2.png" /></span>
                                                                    <span>{_.get(item, 'assignedUsers', []).length}</span>
                                                                </li>
                                                                <li className="remainTime">
                                                                    <span>{moment(item.updated).lang('en').startOf('minute').fromNow()}</span>
                                                                </li>
                                                            </ul>
                                                            <ul className="actionItem" >
                                                                <li onClick={() => loadDetail(item)}>
                                                                    <VisibilityOutlinedIcon sx={{ fontSize: 12, color:'#fff'}} />
                                                                </li>
                                                                <li onClick={() => handleOpenEdit(item)}>
                                                                    <EditOutlinedIcon sx={{ fontSize: 12, color:'#fff' }} />
                                                                </li>
                                                                <li onClick={() => { if (window.confirm('Bạn có chắc chắn muốn xóa dữ liệu không?')) { deleteTask(item) } }}>
                                                                    <DeleteOutlinedIcon sx={{ fontSize: 12, color:'#fff' }} />
                                                                </li>
                                                            </ul>
                                                            <div className="desItem">{item.description}</div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            }
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    }}
                                </Droppable>}

                        </div>
                        <div className="columnTask">
                            <div className="titleTask">
                            { t('frmTaskInProcessing')}
                            </div>
                            {props.tasks?.inProcessing &&
                                <Droppable droppableId="inProcessing">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}>
                                            {props.tasks?.inProcessing.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            className="itemTask"
                                                        >
                                                            <Typography color={'#009688'} fontWeight={600} variant="body1">{item.name}</Typography>
                                                            <ul className="toolItem">
                                                                <li>
                                                                    <span className="icoItem"><img alt="img" src="/resources/icons/clock.png" /></span>
                                                                    <span>{moment(item.deadline).format('L')}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="icoItem"><img alt="img" src="/resources/icons/list.png" /></span>
                                                                    <span>{_.get(item, 'subTaskIdArr', []).length}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="icoItem"><img alt="img" src="/resources/icons/team2.png" /></span>
                                                                    <span>{_.get(item, 'assignedUsers', []).length}</span>
                                                                </li>
                                                                <li className="remainTime">
                                                                    <span>{moment(item.updated).lang('en').startOf('minute').fromNow()}</span>
                                                                </li>
                                                            </ul>
                                                            <ul className="actionItem" >
                                                            <ul className="actionItem" >
                                                                <li onClick={() => loadDetail(item)}>
                                                                    <VisibilityOutlinedIcon sx={{ fontSize: 12, color:'#fff'}} />
                                                                </li>
                                                                <li onClick={() => handleOpenEdit(item)}>
                                                                    <EditOutlinedIcon sx={{ fontSize: 12, color:'#fff' }} />
                                                                </li>
                                                                <li onClick={() => { if (window.confirm('Bạn có chắc chắn muốn xóa dữ liệu không?')) { deleteTask(item) } }}>
                                                                    <DeleteOutlinedIcon sx={{ fontSize: 12, color:'#fff' }} />
                                                                </li>
                                                            </ul>
                                                            </ul>
                                                            <div className="desItem">{item.description}</div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>}

                        </div>
                        <div className="columnTask">
                            <div className="titleTask">
                            { t('frmTaskCompleted')}
                            </div>
                            {props.tasks?.completed &&
                                <Droppable droppableId="completed">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {props.tasks?.completed.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            className="itemTask"
                                                        >
                                                            <Typography color={'#009688'} fontWeight={600} variant="body1">{item.name}</Typography>
                                                            <ul className="toolItem">
                                                                <li>
                                                                    <span className="icoItem"><img alt="img" src="/resources/icons/clock.png" /></span>
                                                                    <span>{moment(item.deadline).format('L')}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="icoItem"><img alt="img" src="/resources/icons/list.png" /></span>
                                                                    <span>{_.get(item, 'subTaskIdArr', []).length}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="icoItem"><img alt="img" src="/resources/icons/team2.png" /></span>
                                                                    <span>{_.get(item, 'assignedUsers', []).length}</span>
                                                                </li>
                                                                <li className="remainTime">
                                                                    <span>{moment(item.updated).lang('en').startOf('minute').fromNow()}</span>
                                                                </li>
                                                            </ul>
                                                            <ul className="actionItem" >
                                                            <li onClick={() => loadDetail(item)}>
                                                                    <VisibilityOutlinedIcon sx={{ fontSize: 12, color:'#fff'}} />
                                                                </li>
                                                            </ul>
                                                            <div className="desItem">{item.description}</div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>}

                        </div>
                    </DragDropContext>
                </Grid>

                <Grid item xs={12} sm={4} className="detailTask">
                    <TaskDetail edit={handleOpenEdit} />
                </Grid>
            </Grid>

            <Fab
                aria-label=""
                className="fabTask"
                color="primary"
                onClick={() => handleOpenAdd()}
            >
                <AddIcon />
            </Fab>

            {openAdd && (
                <AddingForm
                    open="open"
                    close={handleCloseAdd}
                    edit={isEdit}
                    task={editItem}
                />
            )}
        </>
    );

}

const TaskMain = (props) => {
    const { t } = useTranslation();
    let title = t('frmTaskTitle')
    document.title = title
    return (
        <>
            <DrawerExpand headerText={title}>
                <Main props={props} />
            </DrawerExpand>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userAll: state.user,
        profile: state.profile,
        tasks: state.task,
    };
};

const mapDispatchToProps = {
    getMyTask, startTask, replaceTask, completeTask, deleteTask, getTaskById, getUser
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskMain)
