const initialState = []

const projectReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_CONTACT_GROUP":
            return action.payload
            
        case "CREATE_CONTACT_GROUP":
            return [...state, action.payload]

        case "UPDATE_CONTACT_GROUP":
            
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            
            return [...state]


        case "UPDATE_CONTACT_GROUP_ACTIVE":
            const project_ = [...state] 
            if(action.payload.length > 0)
            {
                (action.payload).forEach((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    project_[index] = item;
                })
            }
            return project_

        case "SET_CURRENT_CONTACT_GROUP_IMG":
                return [...state]

        case "DELETE_CONTACT_GROUP":
            const project_old = [...state] 
            var project_deleted = []
           
            if(action.payload.length > 0)
            {
                project_deleted = project_old.filter(del =>!(action.payload).includes(del._id) );
            }
            return project_deleted
        

        default:
            break;
    }

    return state
}

export default projectReducer