import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const getLanguageVarById = (id) => (dispatch) => {
  return setTokenAxios().get(`lang-property/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`lang-property`, condition)
    .then(async res => {
      dispatch({
        type: "SET_LANGUAGE_PROPERTY",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const createLanguageVar = (data) => (dispatch) => {
  return setTokenAxios().post(`lang-property`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_LANGUAGE_PROPERTY",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateLanguageVar = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`lang-property/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_LANGUAGE_PROPERTY",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteLanguageVar = (_id) => (dispatch) => {
  return setTokenAxios().delete(`lang-property/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_LANGUAGE_PROPERTY",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}