import * as React from 'react';
import { useEffect, useState } from 'react';

import { AddAPhoto } from '@mui/icons-material';
import { Grid, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { teal } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getAllDataNoneRedux as getDepartment } from '../../../actions/department';
import { getEmployeeById, updateEmployee, updateAvatarByAdmin } from '../../../actions/employee';
import { uploadFileUser } from '../../../actions/file';
import config from '../../../config';
import { setEditObject } from '../../../actions/untils';


const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [
        { _id, firstName, lastName, isActive, code, email, phone,
            listDepartment, departmentCode, cmnd, address,
            date_cmnd, birthday, gender, literacy, description, position,
            fileImg, avatarImg, fileAvatar
        },
        setState
    ] = useState({ ...editObject });


    const getDataById = () => {
        props?.getEmployeeById(editObject?._id).then(res => {
            setState(state => ({ ...state, ...res.data }))
        })
            .catch(err => {
                return
            })
    }

    const init = async () => {
        await props?.getDepartment()
            .then(res => {
                setState(state => ({ ...state, listDepartment: res?.data || [] }))
            })
    }

    useEffect(() => {
        getDataById()
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        handleChangeAvatar()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarImg])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const handleSubmit = () => {
        let data = {
            _id,
            code,
            firstName, lastName, isActive,
            email, departmentCode, cmnd, address, date_cmnd, birthday, gender,
            literacy, description, position,
            phone, fileAvatar
        }

        props?.updateEmployee(data, _id)
            .then(res => {
                dispatch({
                    type: "SET_ENUM",
                    payload: {
                        actionAdd: false,
                    }
                })
            })
    }

    const   handleChangeAvatar = () =>{
        let data ={ fileAvatar}
        props?.updateAvatarByAdmin(data, _id)
        .then(res=>{
            console.log('success');
        })

    }

    const handleChangeFileAvatar = (event) => {
        var files = event.target.files
        var formData = new FormData();
        formData.append('name', 'File Upload');
        [...files].forEach(file => {
            formData.append("files", file);
        });
        if (files.length > 0) {
            props.uploadFileUser(formData)
                .then(res => {
                    if (res?.success.length > 0) {
                        setState((state) => ({
                            ...state,
                            avatarImg: res.success[0],
                            fileAvatar: res.success[0]?._id
                        }))
                    }
                })
        }
    }

    React.useImperativeHandle(ref, () => ({ handleSubmit }));
    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', p: 4, borderRadius: 4 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='body1' color={teal[500]} sx={{ fontWeight: 600 }}>
                        {t('lblGeneralInfo')}
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off" >
                        <TextField
                            label={t('frmUserFirstName')}
                            id="first-name"
                            value={firstName}
                            name="firstName"
                            onChange={onChange}
                            size="small"
                        />
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            label={t('frmUserLastName')}
                            id="last-name"
                            value={lastName}
                            name="lastName"
                            onChange={onChange}
                            size="small"
                        />
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <div className="wrap-avatar-input">
                        <div className="avatar-input">
                            {fileImg?.length > 0 && <img src={fileImg[0].url} alt=""></img>}
                            <img id="parseAvatar" src={`${get(avatarImg, 'path', '') !== '' ?
                                config.baseURL + get(avatarImg, 'path', '') : get(editObject, 'avatarImg', '')}` || 'assets/avatar.png'} alt="Avatar" />
                            <input type="file" name="photo" id="icon-button-photo" style={{
                                position: 'absolute',
                                zIndex: -1,
                                opacity: 0
                            }}
                                accept=".png,.jpg,.jpeg" onChange={handleChangeFileAvatar} multiple />
                            <label className="btn-change-avatar" htmlFor="icon-button-photo">
                                <IconButton color="primary" component="span">
                                    <AddAPhoto />
                                </IconButton>
                            </label>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label={t('frmUserCode')}
                                id="code"
                                value={code}
                                name="code"
                                onChange={onChange}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            label={t('frmUserBirthday')}
                            id="outlined-size-birthday"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={birthday}
                            name="birthday"
                            onChange={onChange}
                            size="small"
                        />
                    </Box>
                </Grid>

                <Grid item xs={4}></Grid>

                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', ml: 1.5 }}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />}
                            checked={isActive}
                            name="isActive"
                            onChange={(e) => customeOnChange('isActive', !isActive)}
                            label={t('frmActive')}
                        />
                    </FormGroup>

                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="male"
                            value={gender}
                            name="radio-buttons-group">
                            <FormControlLabel value="male" control={<Radio onChange={(e) => setState(state => ({ ...state, gender: e.target.value }))} />} label="Male" />
                            <FormControlLabel value="female" control={<Radio onChange={(e) => setState(state => ({ ...state, gender: e.target.value }))} />} label="Female" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1' color={teal[500]} sx={{ fontWeight: 600 }}>
                        {t('frmInformation')}
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label={t('frmUserPhone')}
                                id="outlined-size-phone"
                                value={phone}
                                name="phone"
                                onChange={onChange}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            label={t('frmUserEmail')}
                            id="outlined-size-email"
                            type="email"
                            value={email}
                            name="email"
                            onChange={onChange}
                            size="small"
                        />
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label={t('frmUserIdNumber')}
                                id="outlined-size-idNumber"
                                value={cmnd}
                                name="cmnd"
                                onChange={onChange}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off">
                        <TextField
                            label={t('frmUserDateId')}
                            id="outlined-size-date_cmnd"
                            type="date"
                            value={date_cmnd}
                            InputLabelProps={{ shrink: true }}
                            name="date_cmnd"
                            onChange={onChange}
                            size="small"
                        />
                    </Box>
                </Grid>

                <Grid item xs={8}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label={t('frmUserAddress')}
                                id="outlined-size-address"
                                size="small"
                                value={address}
                                name="address"
                                onChange={onChange}
                                fullWidth={true}
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <FormControl sx={{ m: 1, minWidth: '90%' }} size="small">
                        <InputLabel id="demo-select-small">{t('frmUserDepartment')}</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={departmentCode}
                            name="departmentCode"
                            onChange={onChange}
                            label={t('frmUserDepartment')}
                        >
                            <MenuItem value="">
                                <em>{t('lblNone')}</em>
                            </MenuItem>
                            {
                                listDepartment?.map((res, i) => {
                                    return <MenuItem value={res?.code} key={i}>{res?.code} - {res?.name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label={t('frmUserPosition')}
                                id="outlined-size-position"
                                size="small"
                                value={position}
                                name="position"
                                onChange={onChange}
                                fullWidth={true}
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label={t('frmUserLiteracy')}
                                id="outlined-size-literacy"
                                size="small"
                                value={literacy}
                                name="literacy"
                                onChange={onChange}
                                fullWidth={true}
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label={t('frmUserDescription')}
                                id="outlined-size-description"
                                multiline={true}
                                size="small"
                                name="description"
                                onChange={onChange}
                                minRows={3}
                                value={description}
                                fullWidth={true}
                            />
                        </div>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
})

const mapDispatchToProps = {
    getEmployeeById, updateEmployee, uploadFileUser, getDepartment, setEditObject, updateAvatarByAdmin
}

const mapStateToProps = (state) => {
    return {
        editObj: state.edit,
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditForm);