import { CloseOutlined } from '@mui/icons-material';
import { AppBar, Dialog, DialogContent, Fade, IconButton, Toolbar, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} />;
});


const ViewDocumentSingle = props => {

    // const [docs, setDocs] = useState([])

    const docs = [
      { uri: "https://firebasestorage.googleapis.com/v0/b/oic-web-automation.appspot.com/o/cashReceived%2FDHsG-b79e96d074a9a7f7feb8.jpg?alt=media" }, // Remote file
    //   { uri: require("./example-files/pdf.pdf") }, // Local File
    ];

    // useEffect(() => {
    //     if (props?.url)
    //         setDocs([
    //             { uri: props?.url }
    //         ])
    // }, [props?.url])

    console.log(docs)
    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            open={true}
            onClose={props.close}
            TransitionComponent={Transition}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <AppBar elevation={0} position='relative' sx={{ background: '#1976d2' }} color='transparent'>
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <IconButton edge="start" color="inherit" onClick={props.close} aria-label="close">
                            <CloseOutlined />
                        </IconButton>
                        <Typography variant="h6" color="#fff">
                            View detail
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ minHeight: 400, background: '#dde3ea', padding: 16 }}>
                {docs?.length > 0 && <DocViewer className='renderDocView' documents={docs} pluginRenderers={DocViewerRenderers} />}
            </DialogContent>
        </Dialog>
    );
};

const mapDispatchToProps = {

}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(ViewDocumentSingle);