import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Radio, Select, Stack, TextField, Typography, useTheme } from '@mui/material';
import { blueGrey, grey, orange, teal } from '@mui/material/colors';
import { find, isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { updateDepositStage } from '../../../../actions/chance';
import { getAllData as getDemand } from '../../../../actions/demand';
import { getAllData as getProject } from '../../../../actions/project';
import { _showAlertErrorMessage } from '../../../../untils/error';
import { ccyFormat } from '../../../../untils/number';

import ChanceNote from './ChanceNote';


const initData = {
    "deposits": [],
    "additionalAttributes": {
        "customerName": "customerName",
        "customerAge": 5
    },
    "paymentDetails": [],
    "paymentType": "payByStage"
}


const Step3 = React.forwardRef((props, ref) => {

    const theme = useTheme()
    const editObject = useSelector(state => state.edit)
    const [demandCode, setDemandCode] = useState("")
    const [indexEdit, setIndexEdit] = useState(-1)
    const [projectCode, setProjectCode] = useState("")

    const [
        { deposits, amount, projectAmount, numberOfPayments, paymentType },
        setState
    ] = useState({ ...initData });

    useEffect(() => {

        const init = async () => {
            await props?.getDemand()
            await props?.getProject()
        }
        const stateChange = () => {
            let { deposits } = { ...editObject?.depositStage }
            setState(state => ({ ...state, deposits }))
        }
        init()
        stateChange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };



    const handleSubmit = () => {
        let data = {
            depositStage: {},
            // stage:'communication'
        }
        data.depositStage.deposits = deposits
        // console.log({data})
        if (data?.depositStage) {
            props?.updateDepositStage(editObject?._id, data, { ...editObject })
        }

    }


    const addDeposit = () => {
        let deposit = {
            "demand": {
                "code": "code",
                "name": "name"
            },
            "project": {
                "code": "code",
                "name": "name"
            },
            "numberOfPayments": 1,
            "projectAmount": 0,
            "amount": 0,
            "paymentType": {
                "code": "code",
                "name": "name"
            }
            // "fileIdArr": ["62dbaf3b174d0564ec3e2722"]
        }

        if (isEmpty(amount) || isEmpty(projectAmount)) {
            _showAlertErrorMessage('Chưa nhập tiền cọc')
        }
        deposit.amount = amount
        deposit.projectAmount = projectAmount
        deposit.numberOfPayments = numberOfPayments

        deposit.demand = {
            code: demandCode,
            name: find(props?.demand, { code: demandCode })?.name
        }
        deposit.project = {
            code: projectCode,
            name: find(props?.project, { code: projectCode })?.name
        }

        deposit.paymentType = {
            code: paymentType,
            name: paymentType === 'payByStage' ? 'Tiến độ' : 'Vay ngân hàng'
        }

        let tmpArr = [...deposits]

        if (indexEdit > -1) {
            tmpArr[indexEdit] = deposit
        }
        else {
            tmpArr.push(deposit)
        }
        setIndexEdit(-1)
        setState(state => ({ ...state, deposits: tmpArr }))


    }

    const editBooking = (data, index) => {
        setIndexEdit(index)
        setDemandCode(data.demand.code)
        setProjectCode(data.project.code)
        setState(state => ({
            ...state, projectAmount: data?.projectAmount || 0, amount: data?.amount || 0, numberOfPayments: data?.numberOfPayments | 1
        }))
    }

    const deleteBooking = (index) => {
        let tmpArr = [...deposits]
        if (index > -1) {
            tmpArr.splice(index, 1)
        }
        setState(state => ({ ...state, deposits: tmpArr }))
    }


    return (
        <Box component={"div"} sx={{ minHeight: 450, padding: 2 }}>
            <Grid container spacing={2}>


                <Grid container sx={{ backgroundColor: grey[50], padding: 2, mt: 3 }}>
                    <Grid item xs={6} sx={{ padding: 0, mt: 1.5, mb: 1.5 }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name="hasMet"
                                value={true}
                                disabled
                                checked={deposits?.length > 0 ? true : false}
                            />} label="Đã có cọc" />


                            {deposits?.length > 0 ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1.5, mb: 2, pr: 3 }}>
                                    {
                                        deposits?.map((res, index) => {
                                            return <Stack direction="column" key={index} sx={{
                                                width: '100%',
                                                mb: 2,
                                                pd: 2,
                                                borderBottom: '0.5px dashed #ccc'
                                            }}>
                                                <Stack direction={'row'} justifyContent="space-between" sx={{ width: '100%' }}>

                                                    <Typography variant='body1' sx={{ color: teal[600], fontWeight: 500 }}>
                                                        {index + 1}.   {res?.project?.name}
                                                    </Typography>

                                                    <Chip label={res?.demand?.name} variant="primary" color="primary" />

                                                </Stack>
                                                <Stack direction={'row'} justifyContent="space-between">
                                                    <Typography variant='subtitle2' sx={{
                                                        color: orange[500],
                                                        fontSize: '1.25em',
                                                        fontWeight: 500
                                                    }}>
                                                        {ccyFormat(res?.amount || 0)}
                                                    </Typography>
                                                    <Typography variant='subtitle1' sx={{
                                                        color: blueGrey[500],
                                                        fontSize: '1.25em',
                                                        fontWeight: 500
                                                    }}>
                                                        {ccyFormat(res?.projectAmount || 0)}
                                                    </Typography>
                                                </Stack>

                                                <Stack direction={'row'} justifyContent="space-between">
                                                    <Typography variant='body2' sx={{
                                                    }}>
                                                        <i>Hình thức thanh toán ({res?.paymentType?.name})</i>
                                                    </Typography>
                                                    <Typography variant='body2' sx={{
                                                    }}>
                                                        <i>Số lần thanh toán ({res?.numberOfPayments})</i>
                                                    </Typography>
                                                </Stack>

                                                <Stack direction={'row'} sx={{mt:1, mb:1}}>
                                                    <Typography
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => editBooking(res, index)}
                                                        variant="body2" color="primary">Sửa</Typography>

                                                    <Typography
                                                        sx={{
                                                            cursor: 'pointer',
                                                            ml: 4,
                                                            color: theme.palette.error.main
                                                        }}
                                                        onClick={() => deleteBooking(index)}
                                                        variant="body2">Xóa</Typography>

{/* <Typography
                                                        sx={{
                                                            cursor: 'pointer',
                                                            ml: 4,
                                                            color: theme.palette.error.main
                                                        }}
                                                        onClick={() => deleteBooking(index)}
                                                        variant="body2">Chi tiết</Typography> */}
                                                </Stack>

                                            </Stack>
                                        })
                                    }
                                </Box> : <span></span>}



                        </FormGroup>
                        <Button variant="contained" disableElevation
                            sx={{ mt: 3 }} onClick={() => handleSubmit()}
                        >Cập nhật</Button>
                    </Grid>
                    {/* {needCareMore ? */}
                    <Grid item xs={6} sx={{
                        background: '#fff',
                        borderRadius: 1.5,
                        padding: '4px 12px',
                        border: indexEdit > -1 ? '0.5px solid red' : 'none'
                    }}>
                        <FormControl sx={{ minWidth: 360, m: 1, mt: 1, flexDirection:'row', justifyContent:'space-between' }}>
                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Thông tin Cọc</span>

                            </FormLabel>
                            {
                                indexEdit > -1 && <Chip label="Đang sửa" size="small" sx={{ background: theme.palette.error.main, color: '#fff' }} />
                            }
                        </FormControl>
                        <FormControl sx={{ minWidth: 360, m: 1, mt: 2 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Hình thức thanh toán</FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel value={true} control={<Radio
                                    checked={paymentType === 'payByStage' ? true : false}
                                    onChange={(e) => setState(state => ({ ...state, paymentType: 'payByStage' }))}
                                />} label="Theo tiến độ" />

                                <FormControlLabel value={true} control={<Radio
                                    checked={paymentType === 'payByBank' ? true : false}
                                    onChange={(e) => setState(state => ({ ...state, paymentType: 'payByBank' }))}
                                />} label="Vay ngân hàng" />
                            </FormGroup>
                        </FormControl>

                        <FormControl sx={{
                            mb: 1.5, mt: 1.5, width: '35ch',
                            '& .MuiInputBase-root': {
                                background: '#fff'
                            }
                        }} size="small">
                            <InputLabel id="demo-select-small">Dự án</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={projectCode}
                                onChange={(e) => setProjectCode(e.target.value)}
                                label="Dự án"
                            >
                                <MenuItem value="" >Chọn dự án quan tâm...</MenuItem>
                                {props?.project?.map((res, ind) => {
                                    return <MenuItem value={res.code} key={ind}>{res.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>


                        <Box
                            component="form"
                            sx={{
                                m: 1, mt: 2, width: '100%'
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Nhu cầu</FormLabel>

                                <FormGroup aria-label="position" row>
                                    {props?.demand?.map((res, ind) => {

                                        return <FormControlLabel value={res.code} key={ind}
                                            checked={demandCode === res.code ? true : false}
                                            control={<Checkbox onChange={(e) => setDemandCode(res.code)} />}
                                            label={`${res?.name}`} />
                                    })}
                                </FormGroup>
                            </FormControl>
                        </Box>

                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label="Giá trị bất động sản"
                                id="last-location-project"
                                value={projectAmount}
                                type="number"
                                name="projectAmount"
                                onChange={onChange}
                                InputLabelProps={{ shrink: projectAmount ? true : false }}

                                size="small"
                            /> <Typography variant='body' sx={{ display: 'flex', mt: 0.5, ml: 1, fontSize: '0.8em', width: '100%' }}>
                                ~{ccyFormat(projectAmount || 0)}
                            </Typography>

                        </Box>



                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label="Thanh toán "
                                id="last-location-pay"
                                value={amount}
                                name="amount"
                                onChange={onChange}
                                InputLabelProps={{ shrink: amount ? true : false }}
                                size="small"
                            />
                            <Typography variant='body' sx={{ display: 'flex', mt: 0.5, fontSize: '0.8em', width: '100%', ml: 1, }}>
                                ~{ccyFormat(amount || 0)}
                            </Typography>
                        </Box>

                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label="Số lần thanh toán "
                                id="last-location-of-pay"
                                value={numberOfPayments}
                                name="numberOfPayments"
                                onChange={onChange}
                                InputLabelProps={{ shrink: numberOfPayments ? true : false }}
                                size="small"
                            />
                        </Box>

                        {indexEdit > -1 ? <Button variant='contained' sx={{ mt: 2.2, width: '40%' }}
                            onClick={() => addDeposit()}
                            color={'primary'} disableElevation>Cập nhật</Button> :
                            <Button variant='contained' sx={{ mt: 2.2, width: '40%' }}
                                onClick={() => addDeposit()}
                                color={'primary'} disableElevation>Thêm</Button>}

                    </Grid>
                    {/* :
                        <span></span>
                    } */}

                </Grid>

          

                <Grid item xs={12}>
                    {props?.activeStep === 4 &&
                        <ChanceNote stage={props?.stage}></ChanceNote>
                    }
                </Grid>
            </Grid>
        </Box>
    );
});

const mapDispatchToProps = {
    updateDepositStage, getDemand, getProject
}


const mapStateToProps = (state) => {
    return {
        demand: state.demand,
        project: state.project
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3);