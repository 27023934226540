const ward = [
    {
        "name": "Phường Phúc Xá",
        "code": "phuong_phuc_xa",
        "division_type": "phường",
        "short_codename": "phuc_xa",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Trúc Bạch",
        "code": "phuong_truc_bach",
        "division_type": "phường",
        "short_codename": "truc_bach",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Vĩnh Phúc",
        "code": "phuong_vinh_phuc",
        "division_type": "phường",
        "short_codename": "vinh_phuc",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Cống Vị",
        "code": "phuong_cong_vi",
        "division_type": "phường",
        "short_codename": "cong_vi",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Liễu Giai",
        "code": "phuong_lieu_giai",
        "division_type": "phường",
        "short_codename": "lieu_giai",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Nguyễn Trung Trực",
        "code": "phuong_nguyen_trung_truc",
        "division_type": "phường",
        "short_codename": "nguyen_trung_truc",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Quán Thánh",
        "code": "phuong_quan_thanh",
        "division_type": "phường",
        "short_codename": "quan_thanh",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Ngọc Hà",
        "code": "phuong_ngoc_ha",
        "division_type": "phường",
        "short_codename": "ngoc_ha",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Điện Biên",
        "code": "phuong_dien_bien",
        "division_type": "phường",
        "short_codename": "dien_bien",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Đội Cấn",
        "code": "phuong_doi_can",
        "division_type": "phường",
        "short_codename": "doi_can",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Ngọc Khánh",
        "code": "phuong_ngoc_khanh",
        "division_type": "phường",
        "short_codename": "ngoc_khanh",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Kim Mã",
        "code": "phuong_kim_ma",
        "division_type": "phường",
        "short_codename": "kim_ma",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Giảng Võ",
        "code": "phuong_giang_vo",
        "division_type": "phường",
        "short_codename": "giang_vo",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Thành Công",
        "code": "phuong_thanh_cong",
        "division_type": "phường",
        "short_codename": "thanh_cong",
        "parentCode": "quan_ba_dinh"
    },
    {
        "name": "Phường Phúc Tân",
        "code": "phuong_phuc_tan",
        "division_type": "phường",
        "short_codename": "phuc_tan",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Đồng Xuân",
        "code": "phuong_dong_xuan",
        "division_type": "phường",
        "short_codename": "dong_xuan",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Hàng Mã",
        "code": "phuong_hang_ma",
        "division_type": "phường",
        "short_codename": "hang_ma",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Hàng Buồm",
        "code": "phuong_hang_buom",
        "division_type": "phường",
        "short_codename": "hang_buom",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Hàng Đào",
        "code": "phuong_hang_dao",
        "division_type": "phường",
        "short_codename": "hang_dao",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Hàng Bồ",
        "code": "phuong_hang_bo",
        "division_type": "phường",
        "short_codename": "hang_bo",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Cửa Đông",
        "code": "phuong_cua_dong",
        "division_type": "phường",
        "short_codename": "cua_dong",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Lý Thái Tổ",
        "code": "phuong_ly_thai_to",
        "division_type": "phường",
        "short_codename": "ly_thai_to",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Hàng Bạc",
        "code": "phuong_hang_bac",
        "division_type": "phường",
        "short_codename": "hang_bac",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Hàng Gai",
        "code": "phuong_hang_gai",
        "division_type": "phường",
        "short_codename": "hang_gai",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Chương Dương",
        "code": "phuong_chuong_duong",
        "division_type": "phường",
        "short_codename": "chuong_duong",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Hàng Trống",
        "code": "phuong_hang_trong",
        "division_type": "phường",
        "short_codename": "hang_trong",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Cửa Nam",
        "code": "phuong_cua_nam",
        "division_type": "phường",
        "short_codename": "cua_nam",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Hàng Bông",
        "code": "phuong_hang_bong",
        "division_type": "phường",
        "short_codename": "hang_bong",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Tràng Tiền",
        "code": "phuong_trang_tien",
        "division_type": "phường",
        "short_codename": "trang_tien",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Trần Hưng Đạo",
        "code": "phuong_tran_hung_dao",
        "division_type": "phường",
        "short_codename": "tran_hung_dao",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Phan Chu Trinh",
        "code": "phuong_phan_chu_trinh",
        "division_type": "phường",
        "short_codename": "phan_chu_trinh",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Hàng Bài",
        "code": "phuong_hang_bai",
        "division_type": "phường",
        "short_codename": "hang_bai",
        "parentCode": "quan_hoan_kiem"
    },
    {
        "name": "Phường Phú Thượng",
        "code": "phuong_phu_thuong",
        "division_type": "phường",
        "short_codename": "phu_thuong",
        "parentCode": "quan_tay_ho"
    },
    {
        "name": "Phường Nhật Tân",
        "code": "phuong_nhat_tan",
        "division_type": "phường",
        "short_codename": "nhat_tan",
        "parentCode": "quan_tay_ho"
    },
    {
        "name": "Phường Tứ Liên",
        "code": "phuong_tu_lien",
        "division_type": "phường",
        "short_codename": "tu_lien",
        "parentCode": "quan_tay_ho"
    },
    {
        "name": "Phường Quảng An",
        "code": "phuong_quang_an",
        "division_type": "phường",
        "short_codename": "quang_an",
        "parentCode": "quan_tay_ho"
    },
    {
        "name": "Phường Xuân La",
        "code": "phuong_xuan_la",
        "division_type": "phường",
        "short_codename": "xuan_la",
        "parentCode": "quan_tay_ho"
    },
    {
        "name": "Phường Yên Phụ",
        "code": "phuong_yen_phu",
        "division_type": "phường",
        "short_codename": "yen_phu",
        "parentCode": "quan_tay_ho"
    },
    {
        "name": "Phường Bưởi",
        "code": "phuong_buoi",
        "division_type": "phường",
        "short_codename": "buoi",
        "parentCode": "quan_tay_ho"
    },
    {
        "name": "Phường Thụy Khuê",
        "code": "phuong_thuy_khue",
        "division_type": "phường",
        "short_codename": "thuy_khue",
        "parentCode": "quan_tay_ho"
    },
    {
        "name": "Phường Thượng Thanh",
        "code": "phuong_thuong_thanh",
        "division_type": "phường",
        "short_codename": "thuong_thanh",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Ngọc Thụy",
        "code": "phuong_ngoc_thuy",
        "division_type": "phường",
        "short_codename": "ngoc_thuy",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Giang Biên",
        "code": "phuong_giang_bien",
        "division_type": "phường",
        "short_codename": "giang_bien",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Đức Giang",
        "code": "phuong_duc_giang",
        "division_type": "phường",
        "short_codename": "duc_giang",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Việt Hưng",
        "code": "phuong_viet_hung",
        "division_type": "phường",
        "short_codename": "viet_hung",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Gia Thụy",
        "code": "phuong_gia_thuy",
        "division_type": "phường",
        "short_codename": "gia_thuy",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Ngọc Lâm",
        "code": "phuong_ngoc_lam",
        "division_type": "phường",
        "short_codename": "ngoc_lam",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Phúc Lợi",
        "code": "phuong_phuc_loi",
        "division_type": "phường",
        "short_codename": "phuc_loi",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Bồ Đề",
        "code": "phuong_bo_de",
        "division_type": "phường",
        "short_codename": "bo_de",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Sài Đồng",
        "code": "phuong_sai_dong",
        "division_type": "phường",
        "short_codename": "sai_dong",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Long Biên",
        "code": "phuong_long_bien",
        "division_type": "phường",
        "short_codename": "long_bien",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Thạch Bàn",
        "code": "phuong_thach_ban",
        "division_type": "phường",
        "short_codename": "thach_ban",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Phúc Đồng",
        "code": "phuong_phuc_dong",
        "division_type": "phường",
        "short_codename": "phuc_dong",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Cự Khối",
        "code": "phuong_cu_khoi",
        "division_type": "phường",
        "short_codename": "cu_khoi",
        "parentCode": "quan_long_bien"
    },
    {
        "name": "Phường Nghĩa Đô",
        "code": "phuong_nghia_do",
        "division_type": "phường",
        "short_codename": "nghia_do",
        "parentCode": "quan_cau_giay"
    },
    {
        "name": "Phường Nghĩa Tân",
        "code": "phuong_nghia_tan",
        "division_type": "phường",
        "short_codename": "nghia_tan",
        "parentCode": "quan_cau_giay"
    },
    {
        "name": "Phường Mai Dịch",
        "code": "phuong_mai_dich",
        "division_type": "phường",
        "short_codename": "mai_dich",
        "parentCode": "quan_cau_giay"
    },
    {
        "name": "Phường Dịch Vọng",
        "code": "phuong_dich_vong",
        "division_type": "phường",
        "short_codename": "dich_vong",
        "parentCode": "quan_cau_giay"
    },
    {
        "name": "Phường Dịch Vọng Hậu",
        "code": "phuong_dich_vong_hau",
        "division_type": "phường",
        "short_codename": "dich_vong_hau",
        "parentCode": "quan_cau_giay"
    },
    {
        "name": "Phường Quan Hoa",
        "code": "phuong_quan_hoa",
        "division_type": "phường",
        "short_codename": "quan_hoa",
        "parentCode": "quan_cau_giay"
    },
    {
        "name": "Phường Yên Hoà",
        "code": "phuong_yen_hoa",
        "division_type": "phường",
        "short_codename": "yen_hoa",
        "parentCode": "quan_cau_giay"
    },
    {
        "name": "Phường Trung Hoà",
        "code": "phuong_trung_hoa",
        "division_type": "phường",
        "short_codename": "trung_hoa",
        "parentCode": "quan_cau_giay"
    },
    {
        "name": "Phường Cát Linh",
        "code": "phuong_cat_linh",
        "division_type": "phường",
        "short_codename": "cat_linh",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Văn Miếu",
        "code": "phuong_van_mieu",
        "division_type": "phường",
        "short_codename": "van_mieu",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Quốc Tử Giám",
        "code": "phuong_quoc_tu_giam",
        "division_type": "phường",
        "short_codename": "quoc_tu_giam",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Láng Thượng",
        "code": "phuong_lang_thuong",
        "division_type": "phường",
        "short_codename": "lang_thuong",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Ô Chợ Dừa",
        "code": "phuong_o_cho_dua",
        "division_type": "phường",
        "short_codename": "o_cho_dua",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Văn Chương",
        "code": "phuong_van_chuong",
        "division_type": "phường",
        "short_codename": "van_chuong",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Hàng Bột",
        "code": "phuong_hang_bot",
        "division_type": "phường",
        "short_codename": "hang_bot",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Láng Hạ",
        "code": "phuong_lang_ha",
        "division_type": "phường",
        "short_codename": "lang_ha",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Khâm Thiên",
        "code": "phuong_kham_thien",
        "division_type": "phường",
        "short_codename": "kham_thien",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Thổ Quan",
        "code": "phuong_tho_quan",
        "division_type": "phường",
        "short_codename": "tho_quan",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Nam Đồng",
        "code": "phuong_nam_dong",
        "division_type": "phường",
        "short_codename": "nam_dong",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Trung Phụng",
        "code": "phuong_trung_phung",
        "division_type": "phường",
        "short_codename": "trung_phung",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Trung Liệt",
        "code": "phuong_trung_liet",
        "division_type": "phường",
        "short_codename": "trung_liet",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Phương Liên",
        "code": "phuong_phuong_lien",
        "division_type": "phường",
        "short_codename": "phuong_lien",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Thịnh Quang",
        "code": "phuong_thinh_quang",
        "division_type": "phường",
        "short_codename": "thinh_quang",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Trung Tự",
        "code": "phuong_trung_tu",
        "division_type": "phường",
        "short_codename": "trung_tu",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Kim Liên",
        "code": "phuong_kim_lien",
        "division_type": "phường",
        "short_codename": "kim_lien",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Phương Mai",
        "code": "phuong_phuong_mai",
        "division_type": "phường",
        "short_codename": "phuong_mai",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Ngã Tư Sở",
        "code": "phuong_nga_tu_so",
        "division_type": "phường",
        "short_codename": "nga_tu_so",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Khương Thượng",
        "code": "phuong_khuong_thuong",
        "division_type": "phường",
        "short_codename": "khuong_thuong",
        "parentCode": "quan_dong_da"
    },
    {
        "name": "Phường Nguyễn Du",
        "code": "phuong_nguyen_du",
        "division_type": "phường",
        "short_codename": "nguyen_du",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Bạch Đằng",
        "code": "phuong_bach_dang",
        "division_type": "phường",
        "short_codename": "bach_dang",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Phạm Đình Hổ",
        "code": "phuong_pham_dinh_ho",
        "division_type": "phường",
        "short_codename": "pham_dinh_ho",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Lê Đại Hành",
        "code": "phuong_le_dai_hanh",
        "division_type": "phường",
        "short_codename": "le_dai_hanh",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Đồng Nhân",
        "code": "phuong_dong_nhan",
        "division_type": "phường",
        "short_codename": "dong_nhan",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Phố Huế",
        "code": "phuong_pho_hue",
        "division_type": "phường",
        "short_codename": "pho_hue",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Đống Mác",
        "code": "phuong_dong_mac",
        "division_type": "phường",
        "short_codename": "dong_mac",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Thanh Lương",
        "code": "phuong_thanh_luong",
        "division_type": "phường",
        "short_codename": "thanh_luong",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Thanh Nhàn",
        "code": "phuong_thanh_nhan",
        "division_type": "phường",
        "short_codename": "thanh_nhan",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Cầu Dền",
        "code": "phuong_cau_den",
        "division_type": "phường",
        "short_codename": "cau_den",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Bách Khoa",
        "code": "phuong_bach_khoa",
        "division_type": "phường",
        "short_codename": "bach_khoa",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Đồng Tâm",
        "code": "phuong_dong_tam",
        "division_type": "phường",
        "short_codename": "dong_tam",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Vĩnh Tuy",
        "code": "phuong_vinh_tuy",
        "division_type": "phường",
        "short_codename": "vinh_tuy",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Bạch Mai",
        "code": "phuong_bach_mai",
        "division_type": "phường",
        "short_codename": "bach_mai",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Quỳnh Mai",
        "code": "phuong_quynh_mai",
        "division_type": "phường",
        "short_codename": "quynh_mai",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Quỳnh Lôi",
        "code": "phuong_quynh_loi",
        "division_type": "phường",
        "short_codename": "quynh_loi",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Minh Khai",
        "code": "phuong_minh_khai",
        "division_type": "phường",
        "short_codename": "minh_khai",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Trương Định",
        "code": "phuong_truong_dinh",
        "division_type": "phường",
        "short_codename": "truong_dinh",
        "parentCode": "quan_hai_ba_trung"
    },
    {
        "name": "Phường Thanh Trì",
        "code": "phuong_thanh_tri",
        "division_type": "phường",
        "short_codename": "thanh_tri",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Vĩnh Hưng",
        "code": "phuong_vinh_hung",
        "division_type": "phường",
        "short_codename": "vinh_hung",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Định Công",
        "code": "phuong_dinh_cong",
        "division_type": "phường",
        "short_codename": "dinh_cong",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Mai Động",
        "code": "phuong_mai_dong",
        "division_type": "phường",
        "short_codename": "mai_dong",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Tương Mai",
        "code": "phuong_tuong_mai",
        "division_type": "phường",
        "short_codename": "tuong_mai",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Đại Kim",
        "code": "phuong_dai_kim",
        "division_type": "phường",
        "short_codename": "dai_kim",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Tân Mai",
        "code": "phuong_tan_mai",
        "division_type": "phường",
        "short_codename": "tan_mai",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Hoàng Văn Thụ",
        "code": "phuong_hoang_van_thu",
        "division_type": "phường",
        "short_codename": "hoang_van_thu",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Giáp Bát",
        "code": "phuong_giap_bat",
        "division_type": "phường",
        "short_codename": "giap_bat",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Lĩnh Nam",
        "code": "phuong_linh_nam",
        "division_type": "phường",
        "short_codename": "linh_nam",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Thịnh Liệt",
        "code": "phuong_thinh_liet",
        "division_type": "phường",
        "short_codename": "thinh_liet",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Trần Phú",
        "code": "phuong_tran_phu",
        "division_type": "phường",
        "short_codename": "tran_phu",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Hoàng Liệt",
        "code": "phuong_hoang_liet",
        "division_type": "phường",
        "short_codename": "hoang_liet",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Yên Sở",
        "code": "phuong_yen_so",
        "division_type": "phường",
        "short_codename": "yen_so",
        "parentCode": "quan_hoang_mai"
    },
    {
        "name": "Phường Nhân Chính",
        "code": "phuong_nhan_chinh",
        "division_type": "phường",
        "short_codename": "nhan_chinh",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Thượng Đình",
        "code": "phuong_thuong_dinh",
        "division_type": "phường",
        "short_codename": "thuong_dinh",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Khương Trung",
        "code": "phuong_khuong_trung",
        "division_type": "phường",
        "short_codename": "khuong_trung",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Khương Mai",
        "code": "phuong_khuong_mai",
        "division_type": "phường",
        "short_codename": "khuong_mai",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Thanh Xuân Trung",
        "code": "phuong_thanh_xuan_trung",
        "division_type": "phường",
        "short_codename": "thanh_xuan_trung",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Phương Liệt",
        "code": "phuong_phuong_liet",
        "division_type": "phường",
        "short_codename": "phuong_liet",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Hạ Đình",
        "code": "phuong_ha_dinh",
        "division_type": "phường",
        "short_codename": "ha_dinh",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Khương Đình",
        "code": "phuong_khuong_dinh",
        "division_type": "phường",
        "short_codename": "khuong_dinh",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Thanh Xuân Bắc",
        "code": "phuong_thanh_xuan_bac",
        "division_type": "phường",
        "short_codename": "thanh_xuan_bac",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Thanh Xuân Nam",
        "code": "phuong_thanh_xuan_nam",
        "division_type": "phường",
        "short_codename": "thanh_xuan_nam",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Phường Kim Giang",
        "code": "phuong_kim_giang",
        "division_type": "phường",
        "short_codename": "kim_giang",
        "parentCode": "quan_thanh_xuan"
    },
    {
        "name": "Thị trấn Sóc Sơn",
        "code": "thi_tran_soc_son",
        "division_type": "thị trấn",
        "short_codename": "soc_son",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Minh Trí",
        "code": "xa_minh_tri",
        "division_type": "xã",
        "short_codename": "minh_tri",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Hồng Kỳ",
        "code": "xa_hong_ky",
        "division_type": "xã",
        "short_codename": "hong_ky",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Nam Sơn",
        "code": "xa_nam_son",
        "division_type": "xã",
        "short_codename": "nam_son",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Trung Giã",
        "code": "xa_trung_gia",
        "division_type": "xã",
        "short_codename": "trung_gia",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Minh Phú",
        "code": "xa_minh_phu",
        "division_type": "xã",
        "short_codename": "minh_phu",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Phù Linh",
        "code": "xa_phu_linh",
        "division_type": "xã",
        "short_codename": "phu_linh",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Bắc Phú",
        "code": "xa_bac_phu",
        "division_type": "xã",
        "short_codename": "bac_phu",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Tân Minh",
        "code": "xa_tan_minh",
        "division_type": "xã",
        "short_codename": "tan_minh",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Quang Tiến",
        "code": "xa_quang_tien",
        "division_type": "xã",
        "short_codename": "quang_tien",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Hiền Ninh",
        "code": "xa_hien_ninh",
        "division_type": "xã",
        "short_codename": "hien_ninh",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Tân Dân",
        "code": "xa_tan_dan",
        "division_type": "xã",
        "short_codename": "tan_dan",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Tiên Dược",
        "code": "xa_tien_duoc",
        "division_type": "xã",
        "short_codename": "tien_duoc",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Việt Long",
        "code": "xa_viet_long",
        "division_type": "xã",
        "short_codename": "viet_long",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Xuân Giang",
        "code": "xa_xuan_giang",
        "division_type": "xã",
        "short_codename": "xuan_giang",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Mai Đình",
        "code": "xa_mai_dinh",
        "division_type": "xã",
        "short_codename": "mai_dinh",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Đức Hoà",
        "code": "xa_duc_hoa",
        "division_type": "xã",
        "short_codename": "duc_hoa",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Thanh Xuân",
        "code": "xa_thanh_xuan",
        "division_type": "xã",
        "short_codename": "thanh_xuan",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Đông Xuân",
        "code": "xa_dong_xuan",
        "division_type": "xã",
        "short_codename": "dong_xuan",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Kim Lũ",
        "code": "xa_kim_lu",
        "division_type": "xã",
        "short_codename": "kim_lu",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Phú Cường",
        "code": "xa_phu_cuong",
        "division_type": "xã",
        "short_codename": "phu_cuong",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Phú Minh",
        "code": "xa_phu_minh",
        "division_type": "xã",
        "short_codename": "phu_minh",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Phù Lỗ",
        "code": "xa_phu_lo",
        "division_type": "xã",
        "short_codename": "phu_lo",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Xã Xuân Thu",
        "code": "xa_xuan_thu",
        "division_type": "xã",
        "short_codename": "xuan_thu",
        "parentCode": "huyen_soc_son"
    },
    {
        "name": "Thị trấn Đông Anh",
        "code": "thi_tran_dong_anh",
        "division_type": "thị trấn",
        "short_codename": "dong_anh",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Xuân Nộn",
        "code": "xa_xuan_non",
        "division_type": "xã",
        "short_codename": "xuan_non",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Thuỵ Lâm",
        "code": "xa_thuy_lam",
        "division_type": "xã",
        "short_codename": "thuy_lam",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Bắc Hồng",
        "code": "xa_bac_hong",
        "division_type": "xã",
        "short_codename": "bac_hong",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Nguyên Khê",
        "code": "xa_nguyen_khe",
        "division_type": "xã",
        "short_codename": "nguyen_khe",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Nam Hồng",
        "code": "xa_nam_hong",
        "division_type": "xã",
        "short_codename": "nam_hong",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Tiên Dương",
        "code": "xa_tien_duong",
        "division_type": "xã",
        "short_codename": "tien_duong",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Vân Hà",
        "code": "xa_van_ha",
        "division_type": "xã",
        "short_codename": "van_ha",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Uy Nỗ",
        "code": "xa_uy_no",
        "division_type": "xã",
        "short_codename": "uy_no",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Vân Nội",
        "code": "xa_van_noi",
        "division_type": "xã",
        "short_codename": "van_noi",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Liên Hà",
        "code": "xa_lien_ha",
        "division_type": "xã",
        "short_codename": "lien_ha",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Việt Hùng",
        "code": "xa_viet_hung",
        "division_type": "xã",
        "short_codename": "viet_hung",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Kim Nỗ",
        "code": "xa_kim_no",
        "division_type": "xã",
        "short_codename": "kim_no",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Kim Chung",
        "code": "xa_kim_chung",
        "division_type": "xã",
        "short_codename": "kim_chung",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Dục Tú",
        "code": "xa_duc_tu",
        "division_type": "xã",
        "short_codename": "duc_tu",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Đại Mạch",
        "code": "xa_dai_mach",
        "division_type": "xã",
        "short_codename": "dai_mach",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Vĩnh Ngọc",
        "code": "xa_vinh_ngoc",
        "division_type": "xã",
        "short_codename": "vinh_ngoc",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Cổ Loa",
        "code": "xa_co_loa",
        "division_type": "xã",
        "short_codename": "co_loa",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Hải Bối",
        "code": "xa_hai_boi",
        "division_type": "xã",
        "short_codename": "hai_boi",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Xuân Canh",
        "code": "xa_xuan_canh",
        "division_type": "xã",
        "short_codename": "xuan_canh",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Võng La",
        "code": "xa_vong_la",
        "division_type": "xã",
        "short_codename": "vong_la",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Tàm Xá",
        "code": "xa_tam_xa",
        "division_type": "xã",
        "short_codename": "tam_xa",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Mai Lâm",
        "code": "xa_mai_lam",
        "division_type": "xã",
        "short_codename": "mai_lam",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Xã Đông Hội",
        "code": "xa_dong_hoi",
        "division_type": "xã",
        "short_codename": "dong_hoi",
        "parentCode": "huyen_dong_anh"
    },
    {
        "name": "Thị trấn Yên Viên",
        "code": "thi_tran_yen_vien",
        "division_type": "thị trấn",
        "short_codename": "yen_vien",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Yên Thường",
        "code": "xa_yen_thuong",
        "division_type": "xã",
        "short_codename": "yen_thuong",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Yên Viên",
        "code": "xa_yen_vien",
        "division_type": "xã",
        "short_codename": "xa_yen_vien",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Ninh Hiệp",
        "code": "xa_ninh_hiep",
        "division_type": "xã",
        "short_codename": "ninh_hiep",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Đình Xuyên",
        "code": "xa_dinh_xuyen",
        "division_type": "xã",
        "short_codename": "dinh_xuyen",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Dương Hà",
        "code": "xa_duong_ha",
        "division_type": "xã",
        "short_codename": "duong_ha",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Phù Đổng",
        "code": "xa_phu_dong",
        "division_type": "xã",
        "short_codename": "phu_dong",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Trung Mầu",
        "code": "xa_trung_mau",
        "division_type": "xã",
        "short_codename": "trung_mau",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Lệ Chi",
        "code": "xa_le_chi",
        "division_type": "xã",
        "short_codename": "le_chi",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Cổ Bi",
        "code": "xa_co_bi",
        "division_type": "xã",
        "short_codename": "co_bi",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Đặng Xá",
        "code": "xa_dang_xa",
        "division_type": "xã",
        "short_codename": "dang_xa",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Phú Thị",
        "code": "xa_phu_thi",
        "division_type": "xã",
        "short_codename": "phu_thi",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Kim Sơn",
        "code": "xa_kim_son",
        "division_type": "xã",
        "short_codename": "kim_son",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Thị trấn Trâu Quỳ",
        "code": "thi_tran_trau_quy",
        "division_type": "thị trấn",
        "short_codename": "trau_quy",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Dương Quang",
        "code": "xa_duong_quang",
        "division_type": "xã",
        "short_codename": "duong_quang",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Dương Xá",
        "code": "xa_duong_xa",
        "division_type": "xã",
        "short_codename": "duong_xa",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Đông Dư",
        "code": "xa_dong_du",
        "division_type": "xã",
        "short_codename": "dong_du",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Đa Tốn",
        "code": "xa_da_ton",
        "division_type": "xã",
        "short_codename": "da_ton",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Kiêu Kỵ",
        "code": "xa_kieu_ky",
        "division_type": "xã",
        "short_codename": "kieu_ky",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Bát Tràng",
        "code": "xa_bat_trang",
        "division_type": "xã",
        "short_codename": "bat_trang",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Kim Lan",
        "code": "xa_kim_lan",
        "division_type": "xã",
        "short_codename": "kim_lan",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Xã Văn Đức",
        "code": "xa_van_duc",
        "division_type": "xã",
        "short_codename": "van_duc",
        "parentCode": "huyen_gia_lam"
    },
    {
        "name": "Phường Cầu Diễn",
        "code": "phuong_cau_dien",
        "division_type": "phường",
        "short_codename": "cau_dien",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Phường Xuân Phương",
        "code": "phuong_xuan_phuong",
        "division_type": "phường",
        "short_codename": "xuan_phuong",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Phường Phương Canh",
        "code": "phuong_phuong_canh",
        "division_type": "phường",
        "short_codename": "phuong_canh",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Phường Mỹ Đình 1",
        "code": "phuong_my_dinh_1",
        "division_type": "phường",
        "short_codename": "my_dinh_1",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Phường Mỹ Đình 2",
        "code": "phuong_my_dinh_2",
        "division_type": "phường",
        "short_codename": "my_dinh_2",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Phường Tây Mỗ",
        "code": "phuong_tay_mo",
        "division_type": "phường",
        "short_codename": "tay_mo",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Phường Mễ Trì",
        "code": "phuong_me_tri",
        "division_type": "phường",
        "short_codename": "me_tri",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Phường Phú Đô",
        "code": "phuong_phu_do",
        "division_type": "phường",
        "short_codename": "phu_do",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Phường Đại Mỗ",
        "code": "phuong_dai_mo",
        "division_type": "phường",
        "short_codename": "dai_mo",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Phường Trung Văn",
        "code": "phuong_trung_van",
        "division_type": "phường",
        "short_codename": "trung_van",
        "parentCode": "quan_nam_tu_liem"
    },
    {
        "name": "Thị trấn Văn Điển",
        "code": "thi_tran_van_dien",
        "division_type": "thị trấn",
        "short_codename": "van_dien",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Tân Triều",
        "code": "xa_tan_trieu",
        "division_type": "xã",
        "short_codename": "tan_trieu",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Thanh Liệt",
        "code": "xa_thanh_liet",
        "division_type": "xã",
        "short_codename": "thanh_liet",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Tả Thanh Oai",
        "code": "xa_ta_thanh_oai",
        "division_type": "xã",
        "short_codename": "ta_thanh_oai",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Hữu Hoà",
        "code": "xa_huu_hoa",
        "division_type": "xã",
        "short_codename": "huu_hoa",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Tam Hiệp",
        "code": "xa_tam_hiep",
        "division_type": "xã",
        "short_codename": "tam_hiep",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Tứ Hiệp",
        "code": "xa_tu_hiep",
        "division_type": "xã",
        "short_codename": "tu_hiep",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Yên Mỹ",
        "code": "xa_yen_my",
        "division_type": "xã",
        "short_codename": "yen_my",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Vĩnh Quỳnh",
        "code": "xa_vinh_quynh",
        "division_type": "xã",
        "short_codename": "vinh_quynh",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Ngũ Hiệp",
        "code": "xa_ngu_hiep",
        "division_type": "xã",
        "short_codename": "ngu_hiep",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Duyên Hà",
        "code": "xa_duyen_ha",
        "division_type": "xã",
        "short_codename": "duyen_ha",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Ngọc Hồi",
        "code": "xa_ngoc_hoi",
        "division_type": "xã",
        "short_codename": "ngoc_hoi",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Vạn Phúc",
        "code": "xa_van_phuc",
        "division_type": "xã",
        "short_codename": "van_phuc",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Đại áng",
        "code": "xa_dai_ang",
        "division_type": "xã",
        "short_codename": "dai_ang",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Liên Ninh",
        "code": "xa_lien_ninh",
        "division_type": "xã",
        "short_codename": "lien_ninh",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Đông Mỹ",
        "code": "xa_dong_my",
        "division_type": "xã",
        "short_codename": "dong_my",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Phường Thượng Cát",
        "code": "phuong_thuong_cat",
        "division_type": "phường",
        "short_codename": "thuong_cat",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Liên Mạc",
        "code": "phuong_lien_mac",
        "division_type": "phường",
        "short_codename": "lien_mac",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Đông Ngạc",
        "code": "phuong_dong_ngac",
        "division_type": "phường",
        "short_codename": "dong_ngac",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Đức Thắng",
        "code": "phuong_duc_thang",
        "division_type": "phường",
        "short_codename": "duc_thang",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Thụy Phương",
        "code": "phuong_thuy_phuong",
        "division_type": "phường",
        "short_codename": "thuy_phuong",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Tây Tựu",
        "code": "phuong_tay_tuu",
        "division_type": "phường",
        "short_codename": "tay_tuu",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Xuân Đỉnh",
        "code": "phuong_xuan_dinh",
        "division_type": "phường",
        "short_codename": "xuan_dinh",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Xuân Tảo",
        "code": "phuong_xuan_tao",
        "division_type": "phường",
        "short_codename": "xuan_tao",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Minh Khai",
        "code": "phuong_minh_khai",
        "division_type": "phường",
        "short_codename": "minh_khai",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Cổ Nhuế 1",
        "code": "phuong_co_nhue_1",
        "division_type": "phường",
        "short_codename": "co_nhue_1",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Cổ Nhuế 2",
        "code": "phuong_co_nhue_2",
        "division_type": "phường",
        "short_codename": "co_nhue_2",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Phú Diễn",
        "code": "phuong_phu_dien",
        "division_type": "phường",
        "short_codename": "phu_dien",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Phường Phúc Diễn",
        "code": "phuong_phuc_dien",
        "division_type": "phường",
        "short_codename": "phuc_dien",
        "parentCode": "quan_bac_tu_liem"
    },
    {
        "name": "Thị trấn Chi Đông",
        "code": "thi_tran_chi_dong",
        "division_type": "thị trấn",
        "short_codename": "chi_dong",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Đại Thịnh",
        "code": "xa_dai_thinh",
        "division_type": "xã",
        "short_codename": "dai_thinh",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Kim Hoa",
        "code": "xa_kim_hoa",
        "division_type": "xã",
        "short_codename": "kim_hoa",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Thạch Đà",
        "code": "xa_thach_da",
        "division_type": "xã",
        "short_codename": "thach_da",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Tiến Thắng",
        "code": "xa_tien_thang",
        "division_type": "xã",
        "short_codename": "tien_thang",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Tự Lập",
        "code": "xa_tu_lap",
        "division_type": "xã",
        "short_codename": "tu_lap",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Thị trấn Quang Minh",
        "code": "thi_tran_quang_minh",
        "division_type": "thị trấn",
        "short_codename": "quang_minh",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Thanh Lâm",
        "code": "xa_thanh_lam",
        "division_type": "xã",
        "short_codename": "thanh_lam",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Tam Đồng",
        "code": "xa_tam_dong",
        "division_type": "xã",
        "short_codename": "tam_dong",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Liên Mạc",
        "code": "xa_lien_mac",
        "division_type": "xã",
        "short_codename": "lien_mac",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Vạn Yên",
        "code": "xa_van_yen",
        "division_type": "xã",
        "short_codename": "van_yen",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Chu Phan",
        "code": "xa_chu_phan",
        "division_type": "xã",
        "short_codename": "chu_phan",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Tiến Thịnh",
        "code": "xa_tien_thinh",
        "division_type": "xã",
        "short_codename": "tien_thinh",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Mê Linh",
        "code": "xa_me_linh",
        "division_type": "xã",
        "short_codename": "me_linh",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Văn Khê",
        "code": "xa_van_khe",
        "division_type": "xã",
        "short_codename": "van_khe",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Hoàng Kim",
        "code": "xa_hoang_kim",
        "division_type": "xã",
        "short_codename": "hoang_kim",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Tiền Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Xã Tráng Việt",
        "code": "xa_trang_viet",
        "division_type": "xã",
        "short_codename": "trang_viet",
        "parentCode": "huyen_me_linh"
    },
    {
        "name": "Phường Nguyễn Trãi",
        "code": "phuong_nguyen_trai",
        "division_type": "phường",
        "short_codename": "nguyen_trai",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Mộ Lao",
        "code": "phuong_mo_lao",
        "division_type": "phường",
        "short_codename": "mo_lao",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Văn Quán",
        "code": "phuong_van_quan",
        "division_type": "phường",
        "short_codename": "van_quan",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Vạn Phúc",
        "code": "phuong_van_phuc",
        "division_type": "phường",
        "short_codename": "van_phuc",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Yết Kiêu",
        "code": "phuong_yet_kieu",
        "division_type": "phường",
        "short_codename": "yet_kieu",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường La Khê",
        "code": "phuong_la_khe",
        "division_type": "phường",
        "short_codename": "la_khe",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Phú La",
        "code": "phuong_phu_la",
        "division_type": "phường",
        "short_codename": "phu_la",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Phúc La",
        "code": "phuong_phuc_la",
        "division_type": "phường",
        "short_codename": "phuc_la",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Hà Cầu",
        "code": "phuong_ha_cau",
        "division_type": "phường",
        "short_codename": "ha_cau",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Yên Nghĩa",
        "code": "phuong_yen_nghia",
        "division_type": "phường",
        "short_codename": "yen_nghia",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Kiến Hưng",
        "code": "phuong_kien_hung",
        "division_type": "phường",
        "short_codename": "kien_hung",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Phú Lãm",
        "code": "phuong_phu_lam",
        "division_type": "phường",
        "short_codename": "phu_lam",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Phú Lương",
        "code": "phuong_phu_luong",
        "division_type": "phường",
        "short_codename": "phu_luong",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Dương Nội",
        "code": "phuong_duong_noi",
        "division_type": "phường",
        "short_codename": "duong_noi",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Đồng Mai",
        "code": "phuong_dong_mai",
        "division_type": "phường",
        "short_codename": "dong_mai",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Biên Giang",
        "code": "phuong_bien_giang",
        "division_type": "phường",
        "short_codename": "bien_giang",
        "parentCode": "quan_ha_dong"
    },
    {
        "name": "Phường Lê Lợi",
        "code": "phuong_le_loi",
        "division_type": "phường",
        "short_codename": "le_loi",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Phường Phú Thịnh",
        "code": "phuong_phu_thinh",
        "division_type": "phường",
        "short_codename": "phu_thinh",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Phường Ngô Quyền",
        "code": "phuong_ngo_quyen",
        "division_type": "phường",
        "short_codename": "ngo_quyen",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Phường Sơn Lộc",
        "code": "phuong_son_loc",
        "division_type": "phường",
        "short_codename": "son_loc",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Phường Xuân Khanh",
        "code": "phuong_xuan_khanh",
        "division_type": "phường",
        "short_codename": "xuan_khanh",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Xã Đường Lâm",
        "code": "xa_duong_lam",
        "division_type": "xã",
        "short_codename": "duong_lam",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Phường Viên Sơn",
        "code": "phuong_vien_son",
        "division_type": "phường",
        "short_codename": "vien_son",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Xã Xuân Sơn",
        "code": "xa_xuan_son",
        "division_type": "xã",
        "short_codename": "xuan_son",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Phường Trung Hưng",
        "code": "phuong_trung_hung",
        "division_type": "phường",
        "short_codename": "trung_hung",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Xã Thanh Mỹ",
        "code": "xa_thanh_my",
        "division_type": "xã",
        "short_codename": "thanh_my",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Phường Trung Sơn Trầm",
        "code": "phuong_trung_son_tram",
        "division_type": "phường",
        "short_codename": "trung_son_tram",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Xã Kim Sơn",
        "code": "xa_kim_son",
        "division_type": "xã",
        "short_codename": "kim_son",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Xã Sơn Đông",
        "code": "xa_son_dong",
        "division_type": "xã",
        "short_codename": "son_dong",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Xã Cổ Đông",
        "code": "xa_co_dong",
        "division_type": "xã",
        "short_codename": "co_dong",
        "parentCode": "thi_xa_son_tay"
    },
    {
        "name": "Thị trấn Tây Đằng",
        "code": "thi_tran_tay_dang",
        "division_type": "thị trấn",
        "short_codename": "tay_dang",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Phú Cường",
        "code": "xa_phu_cuong",
        "division_type": "xã",
        "short_codename": "phu_cuong",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Cổ Đô",
        "code": "xa_co_do",
        "division_type": "xã",
        "short_codename": "co_do",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Tản Hồng",
        "code": "xa_tan_hong",
        "division_type": "xã",
        "short_codename": "tan_hong",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Vạn Thắng",
        "code": "xa_van_thang",
        "division_type": "xã",
        "short_codename": "van_thang",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Châu Sơn",
        "code": "xa_chau_son",
        "division_type": "xã",
        "short_codename": "chau_son",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Phong Vân",
        "code": "xa_phong_van",
        "division_type": "xã",
        "short_codename": "phong_van",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Phú Đông",
        "code": "xa_phu_dong",
        "division_type": "xã",
        "short_codename": "phu_dong",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Phú Phương",
        "code": "xa_phu_phuong",
        "division_type": "xã",
        "short_codename": "phu_phuong",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Phú Châu",
        "code": "xa_phu_chau",
        "division_type": "xã",
        "short_codename": "phu_chau",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Thái Hòa",
        "code": "xa_thai_hoa",
        "division_type": "xã",
        "short_codename": "thai_hoa",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Đồng Thái",
        "code": "xa_dong_thai",
        "division_type": "xã",
        "short_codename": "dong_thai",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Minh Châu",
        "code": "xa_minh_chau",
        "division_type": "xã",
        "short_codename": "minh_chau",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Vật Lại",
        "code": "xa_vat_lai",
        "division_type": "xã",
        "short_codename": "vat_lai",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Chu Minh",
        "code": "xa_chu_minh",
        "division_type": "xã",
        "short_codename": "chu_minh",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Tòng Bạt",
        "code": "xa_tong_bat",
        "division_type": "xã",
        "short_codename": "tong_bat",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Cẩm Lĩnh",
        "code": "xa_cam_linh",
        "division_type": "xã",
        "short_codename": "cam_linh",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Sơn Đà",
        "code": "xa_son_da",
        "division_type": "xã",
        "short_codename": "son_da",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Đông Quang",
        "code": "xa_dong_quang",
        "division_type": "xã",
        "short_codename": "dong_quang",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Tiên Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Thụy An",
        "code": "xa_thuy_an",
        "division_type": "xã",
        "short_codename": "thuy_an",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Cam Thượng",
        "code": "xa_cam_thuong",
        "division_type": "xã",
        "short_codename": "cam_thuong",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Thuần Mỹ",
        "code": "xa_thuan_my",
        "division_type": "xã",
        "short_codename": "thuan_my",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Tản Lĩnh",
        "code": "xa_tan_linh",
        "division_type": "xã",
        "short_codename": "tan_linh",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Ba Trại",
        "code": "xa_ba_trai",
        "division_type": "xã",
        "short_codename": "ba_trai",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Minh Quang",
        "code": "xa_minh_quang",
        "division_type": "xã",
        "short_codename": "minh_quang",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Ba Vì",
        "code": "xa_ba_vi",
        "division_type": "xã",
        "short_codename": "ba_vi",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Vân Hòa",
        "code": "xa_van_hoa",
        "division_type": "xã",
        "short_codename": "van_hoa",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Yên Bài",
        "code": "xa_yen_bai",
        "division_type": "xã",
        "short_codename": "yen_bai",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Xã Khánh Thượng",
        "code": "xa_khanh_thuong",
        "division_type": "xã",
        "short_codename": "khanh_thuong",
        "parentCode": "huyen_ba_vi"
    },
    {
        "name": "Thị trấn Phúc Thọ",
        "code": "thi_tran_phuc_tho",
        "division_type": "thị trấn",
        "short_codename": "phuc_tho",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Vân Hà",
        "code": "xa_van_ha",
        "division_type": "xã",
        "short_codename": "van_ha",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Vân Phúc",
        "code": "xa_van_phuc",
        "division_type": "xã",
        "short_codename": "van_phuc",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Vân Nam",
        "code": "xa_van_nam",
        "division_type": "xã",
        "short_codename": "van_nam",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Xuân Đình",
        "code": "xa_xuan_dinh",
        "division_type": "xã",
        "short_codename": "xuan_dinh",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Sen Phương",
        "code": "xa_sen_phuong",
        "division_type": "xã",
        "short_codename": "sen_phuong",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Võng Xuyên",
        "code": "xa_vong_xuyen",
        "division_type": "xã",
        "short_codename": "vong_xuyen",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Thọ Lộc",
        "code": "xa_tho_loc",
        "division_type": "xã",
        "short_codename": "tho_loc",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Long Xuyên",
        "code": "xa_long_xuyen",
        "division_type": "xã",
        "short_codename": "long_xuyen",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Thượng Cốc",
        "code": "xa_thuong_coc",
        "division_type": "xã",
        "short_codename": "thuong_coc",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Hát Môn",
        "code": "xa_hat_mon",
        "division_type": "xã",
        "short_codename": "hat_mon",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Tích Giang",
        "code": "xa_tich_giang",
        "division_type": "xã",
        "short_codename": "tich_giang",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Thanh Đa",
        "code": "xa_thanh_da",
        "division_type": "xã",
        "short_codename": "thanh_da",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Trạch Mỹ Lộc",
        "code": "xa_trach_my_loc",
        "division_type": "xã",
        "short_codename": "trach_my_loc",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Phúc Hòa",
        "code": "xa_phuc_hoa",
        "division_type": "xã",
        "short_codename": "phuc_hoa",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Ngọc Tảo",
        "code": "xa_ngoc_tao",
        "division_type": "xã",
        "short_codename": "ngoc_tao",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Phụng Thượng",
        "code": "xa_phung_thuong",
        "division_type": "xã",
        "short_codename": "phung_thuong",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Tam Thuấn",
        "code": "xa_tam_thuan",
        "division_type": "xã",
        "short_codename": "tam_thuan",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Tam Hiệp",
        "code": "xa_tam_hiep",
        "division_type": "xã",
        "short_codename": "tam_hiep",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Hiệp Thuận",
        "code": "xa_hiep_thuan",
        "division_type": "xã",
        "short_codename": "hiep_thuan",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Xã Liên Hiệp",
        "code": "xa_lien_hiep",
        "division_type": "xã",
        "short_codename": "lien_hiep",
        "parentCode": "huyen_phuc_tho"
    },
    {
        "name": "Thị trấn Phùng",
        "code": "thi_tran_phung",
        "division_type": "thị trấn",
        "short_codename": "phung",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Trung Châu",
        "code": "xa_trung_chau",
        "division_type": "xã",
        "short_codename": "trung_chau",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Thọ An",
        "code": "xa_tho_an",
        "division_type": "xã",
        "short_codename": "tho_an",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Thọ Xuân",
        "code": "xa_tho_xuan",
        "division_type": "xã",
        "short_codename": "tho_xuan",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Hồng Hà",
        "code": "xa_hong_ha",
        "division_type": "xã",
        "short_codename": "hong_ha",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Liên Hồng",
        "code": "xa_lien_hong",
        "division_type": "xã",
        "short_codename": "lien_hong",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Liên Hà",
        "code": "xa_lien_ha",
        "division_type": "xã",
        "short_codename": "lien_ha",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Hạ Mỗ",
        "code": "xa_ha_mo",
        "division_type": "xã",
        "short_codename": "ha_mo",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Liên Trung",
        "code": "xa_lien_trung",
        "division_type": "xã",
        "short_codename": "lien_trung",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Phương Đình",
        "code": "xa_phuong_dinh",
        "division_type": "xã",
        "short_codename": "phuong_dinh",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Thượng Mỗ",
        "code": "xa_thuong_mo",
        "division_type": "xã",
        "short_codename": "thuong_mo",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Tân Hội",
        "code": "xa_tan_hoi",
        "division_type": "xã",
        "short_codename": "tan_hoi",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Đan Phượng",
        "code": "xa_dan_phuong",
        "division_type": "xã",
        "short_codename": "dan_phuong",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Đồng Tháp",
        "code": "xa_dong_thap",
        "division_type": "xã",
        "short_codename": "dong_thap",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Xã Song Phượng",
        "code": "xa_song_phuong",
        "division_type": "xã",
        "short_codename": "song_phuong",
        "parentCode": "huyen_dan_phuong"
    },
    {
        "name": "Thị trấn Trạm Trôi",
        "code": "thi_tran_tram_troi",
        "division_type": "thị trấn",
        "short_codename": "tram_troi",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Đức Thượng",
        "code": "xa_duc_thuong",
        "division_type": "xã",
        "short_codename": "duc_thuong",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Minh Khai",
        "code": "xa_minh_khai",
        "division_type": "xã",
        "short_codename": "minh_khai",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Dương Liễu",
        "code": "xa_duong_lieu",
        "division_type": "xã",
        "short_codename": "duong_lieu",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Di Trạch",
        "code": "xa_di_trach",
        "division_type": "xã",
        "short_codename": "di_trach",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Đức Giang",
        "code": "xa_duc_giang",
        "division_type": "xã",
        "short_codename": "duc_giang",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Cát Quế",
        "code": "xa_cat_que",
        "division_type": "xã",
        "short_codename": "cat_que",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Kim Chung",
        "code": "xa_kim_chung",
        "division_type": "xã",
        "short_codename": "kim_chung",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Yên Sở",
        "code": "xa_yen_so",
        "division_type": "xã",
        "short_codename": "yen_so",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Sơn Đồng",
        "code": "xa_son_dong",
        "division_type": "xã",
        "short_codename": "son_dong",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Vân Canh",
        "code": "xa_van_canh",
        "division_type": "xã",
        "short_codename": "van_canh",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Đắc Sở",
        "code": "xa_dac_so",
        "division_type": "xã",
        "short_codename": "dac_so",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Lại Yên",
        "code": "xa_lai_yen",
        "division_type": "xã",
        "short_codename": "lai_yen",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Tiền Yên",
        "code": "xa_tien_yen",
        "division_type": "xã",
        "short_codename": "tien_yen",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Song Phương",
        "code": "xa_song_phuong",
        "division_type": "xã",
        "short_codename": "song_phuong",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã An Khánh",
        "code": "xa_an_khanh",
        "division_type": "xã",
        "short_codename": "an_khanh",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã An Thượng",
        "code": "xa_an_thuong",
        "division_type": "xã",
        "short_codename": "an_thuong",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Vân Côn",
        "code": "xa_van_con",
        "division_type": "xã",
        "short_codename": "van_con",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã La Phù",
        "code": "xa_la_phu",
        "division_type": "xã",
        "short_codename": "la_phu",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Đông La",
        "code": "xa_dong_la",
        "division_type": "xã",
        "short_codename": "dong_la",
        "parentCode": "huyen_hoai_duc"
    },
    {
        "name": "Xã Đông Xuân",
        "code": "xa_dong_xuan",
        "division_type": "xã",
        "short_codename": "dong_xuan",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Thị trấn Quốc Oai",
        "code": "thi_tran_quoc_oai",
        "division_type": "thị trấn",
        "short_codename": "quoc_oai",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Sài Sơn",
        "code": "xa_sai_son",
        "division_type": "xã",
        "short_codename": "sai_son",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Phượng Cách",
        "code": "xa_phuong_cach",
        "division_type": "xã",
        "short_codename": "phuong_cach",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Yên Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Ngọc Liệp",
        "code": "xa_ngoc_liep",
        "division_type": "xã",
        "short_codename": "ngoc_liep",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Ngọc Mỹ",
        "code": "xa_ngoc_my",
        "division_type": "xã",
        "short_codename": "ngoc_my",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Liệp Tuyết",
        "code": "xa_liep_tuyet",
        "division_type": "xã",
        "short_codename": "liep_tuyet",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Thạch Thán",
        "code": "xa_thach_than",
        "division_type": "xã",
        "short_codename": "thach_than",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Đồng Quang",
        "code": "xa_dong_quang",
        "division_type": "xã",
        "short_codename": "dong_quang",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Phú Cát",
        "code": "xa_phu_cat",
        "division_type": "xã",
        "short_codename": "phu_cat",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Tuyết Nghĩa",
        "code": "xa_tuyet_nghia",
        "division_type": "xã",
        "short_codename": "tuyet_nghia",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Nghĩa Hương",
        "code": "xa_nghia_huong",
        "division_type": "xã",
        "short_codename": "nghia_huong",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Cộng Hòa",
        "code": "xa_cong_hoa",
        "division_type": "xã",
        "short_codename": "cong_hoa",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Đại Thành",
        "code": "xa_dai_thanh",
        "division_type": "xã",
        "short_codename": "dai_thanh",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Phú Mãn",
        "code": "xa_phu_man",
        "division_type": "xã",
        "short_codename": "phu_man",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Cấn Hữu",
        "code": "xa_can_huu",
        "division_type": "xã",
        "short_codename": "can_huu",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Hòa Thạch",
        "code": "xa_hoa_thach",
        "division_type": "xã",
        "short_codename": "hoa_thach",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Đông Yên",
        "code": "xa_dong_yen",
        "division_type": "xã",
        "short_codename": "dong_yen",
        "parentCode": "huyen_quoc_oai"
    },
    {
        "name": "Xã Yên Trung",
        "code": "xa_yen_trung",
        "division_type": "xã",
        "short_codename": "yen_trung",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Yên Bình",
        "code": "xa_yen_binh",
        "division_type": "xã",
        "short_codename": "yen_binh",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Tiến Xuân",
        "code": "xa_tien_xuan",
        "division_type": "xã",
        "short_codename": "tien_xuan",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Thị trấn Liên Quan",
        "code": "thi_tran_lien_quan",
        "division_type": "thị trấn",
        "short_codename": "lien_quan",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Đại Đồng",
        "code": "xa_dai_dong",
        "division_type": "xã",
        "short_codename": "dai_dong",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Cẩm Yên",
        "code": "xa_cam_yen",
        "division_type": "xã",
        "short_codename": "cam_yen",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Lại Thượng",
        "code": "xa_lai_thuong",
        "division_type": "xã",
        "short_codename": "lai_thuong",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Phú Kim",
        "code": "xa_phu_kim",
        "division_type": "xã",
        "short_codename": "phu_kim",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Hương Ngải",
        "code": "xa_huong_ngai",
        "division_type": "xã",
        "short_codename": "huong_ngai",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Canh Nậu",
        "code": "xa_canh_nau",
        "division_type": "xã",
        "short_codename": "canh_nau",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Kim Quan",
        "code": "xa_kim_quan",
        "division_type": "xã",
        "short_codename": "kim_quan",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Dị Nậu",
        "code": "xa_di_nau",
        "division_type": "xã",
        "short_codename": "di_nau",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Bình Yên",
        "code": "xa_binh_yen",
        "division_type": "xã",
        "short_codename": "binh_yen",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Chàng Sơn",
        "code": "xa_chang_son",
        "division_type": "xã",
        "short_codename": "chang_son",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Thạch Hoà",
        "code": "xa_thach_hoa",
        "division_type": "xã",
        "short_codename": "thach_hoa",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Cần Kiệm",
        "code": "xa_can_kiem",
        "division_type": "xã",
        "short_codename": "can_kiem",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Hữu Bằng",
        "code": "xa_huu_bang",
        "division_type": "xã",
        "short_codename": "huu_bang",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Phùng Xá",
        "code": "xa_phung_xa",
        "division_type": "xã",
        "short_codename": "phung_xa",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Tân Xã",
        "code": "xa_tan_xa",
        "division_type": "xã",
        "short_codename": "tan_xa",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Thạch Xá",
        "code": "xa_thach_xa",
        "division_type": "xã",
        "short_codename": "thach_xa",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Hạ Bằng",
        "code": "xa_ha_bang",
        "division_type": "xã",
        "short_codename": "ha_bang",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Xã Đồng Trúc",
        "code": "xa_dong_truc",
        "division_type": "xã",
        "short_codename": "dong_truc",
        "parentCode": "huyen_thach_that"
    },
    {
        "name": "Thị trấn Chúc Sơn",
        "code": "thi_tran_chuc_son",
        "division_type": "thị trấn",
        "short_codename": "chuc_son",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Thị trấn Xuân Mai",
        "code": "thi_tran_xuan_mai",
        "division_type": "thị trấn",
        "short_codename": "xuan_mai",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Phụng Châu",
        "code": "xa_phung_chau",
        "division_type": "xã",
        "short_codename": "phung_chau",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Tiên Phương",
        "code": "xa_tien_phuong",
        "division_type": "xã",
        "short_codename": "tien_phuong",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Đông Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Đông Phương Yên",
        "code": "xa_dong_phuong_yen",
        "division_type": "xã",
        "short_codename": "dong_phuong_yen",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Phú Nghĩa",
        "code": "xa_phu_nghia",
        "division_type": "xã",
        "short_codename": "phu_nghia",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Trường Yên",
        "code": "xa_truong_yen",
        "division_type": "xã",
        "short_codename": "truong_yen",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Ngọc Hòa",
        "code": "xa_ngoc_hoa",
        "division_type": "xã",
        "short_codename": "ngoc_hoa",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Thủy Xuân Tiên",
        "code": "xa_thuy_xuan_tien",
        "division_type": "xã",
        "short_codename": "thuy_xuan_tien",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Thanh Bình",
        "code": "xa_thanh_binh",
        "division_type": "xã",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Trung Hòa",
        "code": "xa_trung_hoa",
        "division_type": "xã",
        "short_codename": "trung_hoa",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Đại Yên",
        "code": "xa_dai_yen",
        "division_type": "xã",
        "short_codename": "dai_yen",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Thụy Hương",
        "code": "xa_thuy_huong",
        "division_type": "xã",
        "short_codename": "thuy_huong",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Tốt Động",
        "code": "xa_tot_dong",
        "division_type": "xã",
        "short_codename": "tot_dong",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Lam Điền",
        "code": "xa_lam_dien",
        "division_type": "xã",
        "short_codename": "lam_dien",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Nam Phương Tiến",
        "code": "xa_nam_phuong_tien",
        "division_type": "xã",
        "short_codename": "nam_phuong_tien",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Hợp Đồng",
        "code": "xa_hop_dong",
        "division_type": "xã",
        "short_codename": "hop_dong",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Hoàng Văn Thụ",
        "code": "xa_hoang_van_thu",
        "division_type": "xã",
        "short_codename": "hoang_van_thu",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Hoàng Diệu",
        "code": "xa_hoang_dieu",
        "division_type": "xã",
        "short_codename": "hoang_dieu",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Hữu Văn",
        "code": "xa_huu_van",
        "division_type": "xã",
        "short_codename": "huu_van",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Quảng Bị",
        "code": "xa_quang_bi",
        "division_type": "xã",
        "short_codename": "quang_bi",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Mỹ Lương",
        "code": "xa_my_luong",
        "division_type": "xã",
        "short_codename": "my_luong",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Thượng Vực",
        "code": "xa_thuong_vuc",
        "division_type": "xã",
        "short_codename": "thuong_vuc",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Hồng Phong",
        "code": "xa_hong_phong",
        "division_type": "xã",
        "short_codename": "hong_phong",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Đồng Phú",
        "code": "xa_dong_phu",
        "division_type": "xã",
        "short_codename": "dong_phu",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Trần Phú",
        "code": "xa_tran_phu",
        "division_type": "xã",
        "short_codename": "tran_phu",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Văn Võ",
        "code": "xa_van_vo",
        "division_type": "xã",
        "short_codename": "van_vo",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Đồng Lạc",
        "code": "xa_dong_lac",
        "division_type": "xã",
        "short_codename": "dong_lac",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Hòa Chính",
        "code": "xa_hoa_chinh",
        "division_type": "xã",
        "short_codename": "hoa_chinh",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Xã Phú Nam An",
        "code": "xa_phu_nam_an",
        "division_type": "xã",
        "short_codename": "phu_nam_an",
        "parentCode": "huyen_chuong_my"
    },
    {
        "name": "Thị trấn Kim Bài",
        "code": "thi_tran_kim_bai",
        "division_type": "thị trấn",
        "short_codename": "kim_bai",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Cự Khê",
        "code": "xa_cu_khe",
        "division_type": "xã",
        "short_codename": "cu_khe",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Bích Hòa",
        "code": "xa_bich_hoa",
        "division_type": "xã",
        "short_codename": "bich_hoa",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Mỹ Hưng",
        "code": "xa_my_hung",
        "division_type": "xã",
        "short_codename": "my_hung",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Cao Viên",
        "code": "xa_cao_vien",
        "division_type": "xã",
        "short_codename": "cao_vien",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Tam Hưng",
        "code": "xa_tam_hung",
        "division_type": "xã",
        "short_codename": "tam_hung",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Thanh Cao",
        "code": "xa_thanh_cao",
        "division_type": "xã",
        "short_codename": "thanh_cao",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Thanh Thùy",
        "code": "xa_thanh_thuy",
        "division_type": "xã",
        "short_codename": "thanh_thuy",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Thanh Mai",
        "code": "xa_thanh_mai",
        "division_type": "xã",
        "short_codename": "thanh_mai",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Thanh Văn",
        "code": "xa_thanh_van",
        "division_type": "xã",
        "short_codename": "thanh_van",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Đỗ Động",
        "code": "xa_do_dong",
        "division_type": "xã",
        "short_codename": "do_dong",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Kim An",
        "code": "xa_kim_an",
        "division_type": "xã",
        "short_codename": "kim_an",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Kim Thư",
        "code": "xa_kim_thu",
        "division_type": "xã",
        "short_codename": "kim_thu",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Phương Trung",
        "code": "xa_phuong_trung",
        "division_type": "xã",
        "short_codename": "phuong_trung",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Tân Ước",
        "code": "xa_tan_uoc",
        "division_type": "xã",
        "short_codename": "tan_uoc",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Dân Hòa",
        "code": "xa_dan_hoa",
        "division_type": "xã",
        "short_codename": "dan_hoa",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Liên Châu",
        "code": "xa_lien_chau",
        "division_type": "xã",
        "short_codename": "lien_chau",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Cao Dương",
        "code": "xa_cao_duong",
        "division_type": "xã",
        "short_codename": "cao_duong",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Xuân Dương",
        "code": "xa_xuan_duong",
        "division_type": "xã",
        "short_codename": "xuan_duong",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Xã Hồng Dương",
        "code": "xa_hong_duong",
        "division_type": "xã",
        "short_codename": "hong_duong",
        "parentCode": "huyen_thanh_oai"
    },
    {
        "name": "Thị trấn Thường Tín",
        "code": "thi_tran_thuong_tin",
        "division_type": "thị trấn",
        "short_codename": "thuong_tin",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Ninh Sở",
        "code": "xa_ninh_so",
        "division_type": "xã",
        "short_codename": "ninh_so",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Nhị Khê",
        "code": "xa_nhi_khe",
        "division_type": "xã",
        "short_codename": "nhi_khe",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Duyên Thái",
        "code": "xa_duyen_thai",
        "division_type": "xã",
        "short_codename": "duyen_thai",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Khánh Hà",
        "code": "xa_khanh_ha",
        "division_type": "xã",
        "short_codename": "khanh_ha",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Văn Bình",
        "code": "xa_van_binh",
        "division_type": "xã",
        "short_codename": "van_binh",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Hiền Giang",
        "code": "xa_hien_giang",
        "division_type": "xã",
        "short_codename": "hien_giang",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Hồng Vân",
        "code": "xa_hong_van",
        "division_type": "xã",
        "short_codename": "hong_van",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Vân Tảo",
        "code": "xa_van_tao",
        "division_type": "xã",
        "short_codename": "van_tao",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Liên Phương",
        "code": "xa_lien_phuong",
        "division_type": "xã",
        "short_codename": "lien_phuong",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Văn Phú",
        "code": "xa_van_phu",
        "division_type": "xã",
        "short_codename": "van_phu",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Tự Nhiên",
        "code": "xa_tu_nhien",
        "division_type": "xã",
        "short_codename": "tu_nhien",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Tiền Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Hà Hồi",
        "code": "xa_ha_hoi",
        "division_type": "xã",
        "short_codename": "ha_hoi",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Thư Phú",
        "code": "xa_thu_phu",
        "division_type": "xã",
        "short_codename": "thu_phu",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Nguyễn Trãi",
        "code": "xa_nguyen_trai",
        "division_type": "xã",
        "short_codename": "nguyen_trai",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Quất Động",
        "code": "xa_quat_dong",
        "division_type": "xã",
        "short_codename": "quat_dong",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Chương Dương",
        "code": "xa_chuong_duong",
        "division_type": "xã",
        "short_codename": "chuong_duong",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Tân Minh",
        "code": "xa_tan_minh",
        "division_type": "xã",
        "short_codename": "tan_minh",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Lê Lợi",
        "code": "xa_le_loi",
        "division_type": "xã",
        "short_codename": "le_loi",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Thắng Lợi",
        "code": "xa_thang_loi",
        "division_type": "xã",
        "short_codename": "thang_loi",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Dũng Tiến",
        "code": "xa_dung_tien",
        "division_type": "xã",
        "short_codename": "dung_tien",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Thống Nhất",
        "code": "xa_thong_nhat",
        "division_type": "xã",
        "short_codename": "thong_nhat",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Nghiêm Xuyên",
        "code": "xa_nghiem_xuyen",
        "division_type": "xã",
        "short_codename": "nghiem_xuyen",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Tô Hiệu",
        "code": "xa_to_hieu",
        "division_type": "xã",
        "short_codename": "to_hieu",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Văn Tự",
        "code": "xa_van_tu",
        "division_type": "xã",
        "short_codename": "van_tu",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Vạn Điểm",
        "code": "xa_van_diem",
        "division_type": "xã",
        "short_codename": "van_diem",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Xã Minh Cường",
        "code": "xa_minh_cuong",
        "division_type": "xã",
        "short_codename": "minh_cuong",
        "parentCode": "huyen_thuong_tin"
    },
    {
        "name": "Thị trấn Phú Minh",
        "code": "thi_tran_phu_minh",
        "division_type": "thị trấn",
        "short_codename": "phu_minh",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Thị trấn Phú Xuyên",
        "code": "thi_tran_phu_xuyen",
        "division_type": "thị trấn",
        "short_codename": "phu_xuyen",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Hồng Minh",
        "code": "xa_hong_minh",
        "division_type": "xã",
        "short_codename": "hong_minh",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Phượng Dực",
        "code": "xa_phuong_duc",
        "division_type": "xã",
        "short_codename": "phuong_duc",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Nam Tiến",
        "code": "xa_nam_tien",
        "division_type": "xã",
        "short_codename": "nam_tien",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Tri Trung",
        "code": "xa_tri_trung",
        "division_type": "xã",
        "short_codename": "tri_trung",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Đại Thắng",
        "code": "xa_dai_thang",
        "division_type": "xã",
        "short_codename": "dai_thang",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Phú Túc",
        "code": "xa_phu_tuc",
        "division_type": "xã",
        "short_codename": "phu_tuc",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Văn Hoàng",
        "code": "xa_van_hoang",
        "division_type": "xã",
        "short_codename": "van_hoang",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Hồng Thái",
        "code": "xa_hong_thai",
        "division_type": "xã",
        "short_codename": "hong_thai",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Hoàng Long",
        "code": "xa_hoang_long",
        "division_type": "xã",
        "short_codename": "hoang_long",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Nam Phong",
        "code": "xa_nam_phong",
        "division_type": "xã",
        "short_codename": "nam_phong",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Nam Triều",
        "code": "xa_nam_trieu",
        "division_type": "xã",
        "short_codename": "nam_trieu",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Tân Dân",
        "code": "xa_tan_dan",
        "division_type": "xã",
        "short_codename": "tan_dan",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Sơn Hà",
        "code": "xa_son_ha",
        "division_type": "xã",
        "short_codename": "son_ha",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Chuyên Mỹ",
        "code": "xa_chuyen_my",
        "division_type": "xã",
        "short_codename": "chuyen_my",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Khai Thái",
        "code": "xa_khai_thai",
        "division_type": "xã",
        "short_codename": "khai_thai",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Phúc Tiến",
        "code": "xa_phuc_tien",
        "division_type": "xã",
        "short_codename": "phuc_tien",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Vân Từ",
        "code": "xa_van_tu",
        "division_type": "xã",
        "short_codename": "van_tu",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Tri Thủy",
        "code": "xa_tri_thuy",
        "division_type": "xã",
        "short_codename": "tri_thuy",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Đại Xuyên",
        "code": "xa_dai_xuyen",
        "division_type": "xã",
        "short_codename": "dai_xuyen",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Phú Yên",
        "code": "xa_phu_yen",
        "division_type": "xã",
        "short_codename": "phu_yen",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Bạch Hạ",
        "code": "xa_bach_ha",
        "division_type": "xã",
        "short_codename": "bach_ha",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Quang Lãng",
        "code": "xa_quang_lang",
        "division_type": "xã",
        "short_codename": "quang_lang",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Châu Can",
        "code": "xa_chau_can",
        "division_type": "xã",
        "short_codename": "chau_can",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_phu_xuyen"
    },
    {
        "name": "Thị trấn Vân Đình",
        "code": "thi_tran_van_dinh",
        "division_type": "thị trấn",
        "short_codename": "van_dinh",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Viên An",
        "code": "xa_vien_an",
        "division_type": "xã",
        "short_codename": "vien_an",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Viên Nội",
        "code": "xa_vien_noi",
        "division_type": "xã",
        "short_codename": "vien_noi",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Hoa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Quảng Phú Cầu",
        "code": "xa_quang_phu_cau",
        "division_type": "xã",
        "short_codename": "quang_phu_cau",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Trường Thịnh",
        "code": "xa_truong_thinh",
        "division_type": "xã",
        "short_codename": "truong_thinh",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Cao Thành",
        "code": "xa_cao_thanh",
        "division_type": "xã",
        "short_codename": "cao_thanh",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Liên Bạt",
        "code": "xa_lien_bat",
        "division_type": "xã",
        "short_codename": "lien_bat",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Sơn Công",
        "code": "xa_son_cong",
        "division_type": "xã",
        "short_codename": "son_cong",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Đồng Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Phương Tú",
        "code": "xa_phuong_tu",
        "division_type": "xã",
        "short_codename": "phuong_tu",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Trung Tú",
        "code": "xa_trung_tu",
        "division_type": "xã",
        "short_codename": "trung_tu",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Đồng Tân",
        "code": "xa_dong_tan",
        "division_type": "xã",
        "short_codename": "dong_tan",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Tảo Dương Văn",
        "code": "xa_tao_duong_van",
        "division_type": "xã",
        "short_codename": "tao_duong_van",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Vạn Thái",
        "code": "xa_van_thai",
        "division_type": "xã",
        "short_codename": "van_thai",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Minh Đức",
        "code": "xa_minh_duc",
        "division_type": "xã",
        "short_codename": "minh_duc",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Hòa Lâm",
        "code": "xa_hoa_lam",
        "division_type": "xã",
        "short_codename": "hoa_lam",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Hòa Xá",
        "code": "xa_hoa_xa",
        "division_type": "xã",
        "short_codename": "hoa_xa",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Trầm Lộng",
        "code": "xa_tram_long",
        "division_type": "xã",
        "short_codename": "tram_long",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Kim Đường",
        "code": "xa_kim_duong",
        "division_type": "xã",
        "short_codename": "kim_duong",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Hòa Nam",
        "code": "xa_hoa_nam",
        "division_type": "xã",
        "short_codename": "hoa_nam",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Hòa Phú",
        "code": "xa_hoa_phu",
        "division_type": "xã",
        "short_codename": "hoa_phu",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Đội Bình",
        "code": "xa_doi_binh",
        "division_type": "xã",
        "short_codename": "doi_binh",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Đại Hùng",
        "code": "xa_dai_hung",
        "division_type": "xã",
        "short_codename": "dai_hung",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Đông Lỗ",
        "code": "xa_dong_lo",
        "division_type": "xã",
        "short_codename": "dong_lo",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Phù Lưu",
        "code": "xa_phu_luu",
        "division_type": "xã",
        "short_codename": "phu_luu",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Đại Cường",
        "code": "xa_dai_cuong",
        "division_type": "xã",
        "short_codename": "dai_cuong",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Lưu Hoàng",
        "code": "xa_luu_hoang",
        "division_type": "xã",
        "short_codename": "luu_hoang",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Xã Hồng Quang",
        "code": "xa_hong_quang",
        "division_type": "xã",
        "short_codename": "hong_quang",
        "parentCode": "huyen_ung_hoa"
    },
    {
        "name": "Thị trấn Đại Nghĩa",
        "code": "thi_tran_dai_nghia",
        "division_type": "thị trấn",
        "short_codename": "dai_nghia",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Đồng Tâm",
        "code": "xa_dong_tam",
        "division_type": "xã",
        "short_codename": "dong_tam",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Thượng Lâm",
        "code": "xa_thuong_lam",
        "division_type": "xã",
        "short_codename": "thuong_lam",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Tuy Lai",
        "code": "xa_tuy_lai",
        "division_type": "xã",
        "short_codename": "tuy_lai",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Phúc Lâm",
        "code": "xa_phuc_lam",
        "division_type": "xã",
        "short_codename": "phuc_lam",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Mỹ Thành",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Bột Xuyên",
        "code": "xa_bot_xuyen",
        "division_type": "xã",
        "short_codename": "bot_xuyen",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã An Mỹ",
        "code": "xa_an_my",
        "division_type": "xã",
        "short_codename": "an_my",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Hồng Sơn",
        "code": "xa_hong_son",
        "division_type": "xã",
        "short_codename": "hong_son",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Lê Thanh",
        "code": "xa_le_thanh",
        "division_type": "xã",
        "short_codename": "le_thanh",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Xuy Xá",
        "code": "xa_xuy_xa",
        "division_type": "xã",
        "short_codename": "xuy_xa",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Phùng Xá",
        "code": "xa_phung_xa",
        "division_type": "xã",
        "short_codename": "phung_xa",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Phù Lưu Tế",
        "code": "xa_phu_luu_te",
        "division_type": "xã",
        "short_codename": "phu_luu_te",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Đại Hưng",
        "code": "xa_dai_hung",
        "division_type": "xã",
        "short_codename": "dai_hung",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Vạn Kim",
        "code": "xa_van_kim",
        "division_type": "xã",
        "short_codename": "van_kim",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Đốc Tín",
        "code": "xa_doc_tin",
        "division_type": "xã",
        "short_codename": "doc_tin",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Hương Sơn",
        "code": "xa_huong_son",
        "division_type": "xã",
        "short_codename": "huong_son",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Hùng Tiến",
        "code": "xa_hung_tien",
        "division_type": "xã",
        "short_codename": "hung_tien",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã An Tiến",
        "code": "xa_an_tien",
        "division_type": "xã",
        "short_codename": "an_tien",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Hợp Tiến",
        "code": "xa_hop_tien",
        "division_type": "xã",
        "short_codename": "hop_tien",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã Hợp Thanh",
        "code": "xa_hop_thanh",
        "division_type": "xã",
        "short_codename": "hop_thanh",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Xã An Phú",
        "code": "xa_an_phu",
        "division_type": "xã",
        "short_codename": "an_phu",
        "parentCode": "huyen_my_duc"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_ha_giang"
    },
    {
        "name": "Phường Trần Phú",
        "code": "phuong_tran_phu",
        "division_type": "phường",
        "short_codename": "tran_phu",
        "parentCode": "thanh_pho_ha_giang"
    },
    {
        "name": "Phường Ngọc Hà",
        "code": "phuong_ngoc_ha",
        "division_type": "phường",
        "short_codename": "ngoc_ha",
        "parentCode": "thanh_pho_ha_giang"
    },
    {
        "name": "Phường Nguyễn Trãi",
        "code": "phuong_nguyen_trai",
        "division_type": "phường",
        "short_codename": "nguyen_trai",
        "parentCode": "thanh_pho_ha_giang"
    },
    {
        "name": "Phường Minh Khai",
        "code": "phuong_minh_khai",
        "division_type": "phường",
        "short_codename": "minh_khai",
        "parentCode": "thanh_pho_ha_giang"
    },
    {
        "name": "Xã Ngọc Đường",
        "code": "xa_ngoc_duong",
        "division_type": "xã",
        "short_codename": "ngoc_duong",
        "parentCode": "thanh_pho_ha_giang"
    },
    {
        "name": "Xã Phương Độ",
        "code": "xa_phuong_do",
        "division_type": "xã",
        "short_codename": "phuong_do",
        "parentCode": "thanh_pho_ha_giang"
    },
    {
        "name": "Xã Phương Thiện",
        "code": "xa_phuong_thien",
        "division_type": "xã",
        "short_codename": "phuong_thien",
        "parentCode": "thanh_pho_ha_giang"
    },
    {
        "name": "Thị trấn Phó Bảng",
        "code": "thi_tran_pho_bang",
        "division_type": "thị trấn",
        "short_codename": "pho_bang",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Lũng Cú",
        "code": "xa_lung_cu",
        "division_type": "xã",
        "short_codename": "lung_cu",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Má Lé",
        "code": "xa_ma_le",
        "division_type": "xã",
        "short_codename": "ma_le",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Thị trấn Đồng Văn",
        "code": "thi_tran_dong_van",
        "division_type": "thị trấn",
        "short_codename": "dong_van",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Lũng Táo",
        "code": "xa_lung_tao",
        "division_type": "xã",
        "short_codename": "lung_tao",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Phố Là",
        "code": "xa_pho_la",
        "division_type": "xã",
        "short_codename": "pho_la",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Thài Phìn Tủng",
        "code": "xa_thai_phin_tung",
        "division_type": "xã",
        "short_codename": "thai_phin_tung",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Sủng Là",
        "code": "xa_sung_la",
        "division_type": "xã",
        "short_codename": "sung_la",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Xà Phìn",
        "code": "xa_xa_phin",
        "division_type": "xã",
        "short_codename": "xa_phin",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Tả Phìn",
        "code": "xa_ta_phin",
        "division_type": "xã",
        "short_codename": "ta_phin",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Tả Lủng",
        "code": "xa_ta_lung",
        "division_type": "xã",
        "short_codename": "ta_lung",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Phố Cáo",
        "code": "xa_pho_cao",
        "division_type": "xã",
        "short_codename": "pho_cao",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Sính Lủng",
        "code": "xa_sinh_lung",
        "division_type": "xã",
        "short_codename": "sinh_lung",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Sảng Tủng",
        "code": "xa_sang_tung",
        "division_type": "xã",
        "short_codename": "sang_tung",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Lũng Thầu",
        "code": "xa_lung_thau",
        "division_type": "xã",
        "short_codename": "lung_thau",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Hố Quáng Phìn",
        "code": "xa_ho_quang_phin",
        "division_type": "xã",
        "short_codename": "ho_quang_phin",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Vần Chải",
        "code": "xa_van_chai",
        "division_type": "xã",
        "short_codename": "van_chai",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Lũng Phìn",
        "code": "xa_lung_phin",
        "division_type": "xã",
        "short_codename": "lung_phin",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Xã Sủng Trái",
        "code": "xa_sung_trai",
        "division_type": "xã",
        "short_codename": "sung_trai",
        "parentCode": "huyen_dong_van"
    },
    {
        "name": "Thị trấn Mèo Vạc",
        "code": "thi_tran_meo_vac",
        "division_type": "thị trấn",
        "short_codename": "meo_vac",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Thượng Phùng",
        "code": "xa_thuong_phung",
        "division_type": "xã",
        "short_codename": "thuong_phung",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Pải Lủng",
        "code": "xa_pai_lung",
        "division_type": "xã",
        "short_codename": "pai_lung",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Xín Cái",
        "code": "xa_xin_cai",
        "division_type": "xã",
        "short_codename": "xin_cai",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Pả Vi",
        "code": "xa_pa_vi",
        "division_type": "xã",
        "short_codename": "pa_vi",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Giàng Chu Phìn",
        "code": "xa_giang_chu_phin",
        "division_type": "xã",
        "short_codename": "giang_chu_phin",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Sủng Trà",
        "code": "xa_sung_tra",
        "division_type": "xã",
        "short_codename": "sung_tra",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Sủng Máng",
        "code": "xa_sung_mang",
        "division_type": "xã",
        "short_codename": "sung_mang",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Sơn Vĩ",
        "code": "xa_son_vi",
        "division_type": "xã",
        "short_codename": "son_vi",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Tả Lủng",
        "code": "xa_ta_lung",
        "division_type": "xã",
        "short_codename": "ta_lung",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Cán Chu Phìn",
        "code": "xa_can_chu_phin",
        "division_type": "xã",
        "short_codename": "can_chu_phin",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Lũng Pù",
        "code": "xa_lung_pu",
        "division_type": "xã",
        "short_codename": "lung_pu",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Lũng Chinh",
        "code": "xa_lung_chinh",
        "division_type": "xã",
        "short_codename": "lung_chinh",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Tát Ngà",
        "code": "xa_tat_nga",
        "division_type": "xã",
        "short_codename": "tat_nga",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Nậm Ban",
        "code": "xa_nam_ban",
        "division_type": "xã",
        "short_codename": "nam_ban",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Khâu Vai",
        "code": "xa_khau_vai",
        "division_type": "xã",
        "short_codename": "khau_vai",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Niêm Tòng",
        "code": "xa_niem_tong",
        "division_type": "xã",
        "short_codename": "niem_tong",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Xã Niêm Sơn",
        "code": "xa_niem_son",
        "division_type": "xã",
        "short_codename": "niem_son",
        "parentCode": "huyen_meo_vac"
    },
    {
        "name": "Thị trấn Yên Minh",
        "code": "thi_tran_yen_minh",
        "division_type": "thị trấn",
        "short_codename": "yen_minh",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Thắng Mố",
        "code": "xa_thang_mo",
        "division_type": "xã",
        "short_codename": "thang_mo",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Phú Lũng",
        "code": "xa_phu_lung",
        "division_type": "xã",
        "short_codename": "phu_lung",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Sủng Tráng",
        "code": "xa_sung_trang",
        "division_type": "xã",
        "short_codename": "sung_trang",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Bạch Đích",
        "code": "xa_bach_dich",
        "division_type": "xã",
        "short_codename": "bach_dich",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Na Khê",
        "code": "xa_na_khe",
        "division_type": "xã",
        "short_codename": "na_khe",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Sủng Thài",
        "code": "xa_sung_thai",
        "division_type": "xã",
        "short_codename": "sung_thai",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Hữu Vinh",
        "code": "xa_huu_vinh",
        "division_type": "xã",
        "short_codename": "huu_vinh",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Lao Và Chải",
        "code": "xa_lao_va_chai",
        "division_type": "xã",
        "short_codename": "lao_va_chai",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Mậu Duệ",
        "code": "xa_mau_due",
        "division_type": "xã",
        "short_codename": "mau_due",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Đông Minh",
        "code": "xa_dong_minh",
        "division_type": "xã",
        "short_codename": "dong_minh",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Mậu Long",
        "code": "xa_mau_long",
        "division_type": "xã",
        "short_codename": "mau_long",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Ngam La",
        "code": "xa_ngam_la",
        "division_type": "xã",
        "short_codename": "ngam_la",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Ngọc Long",
        "code": "xa_ngoc_long",
        "division_type": "xã",
        "short_codename": "ngoc_long",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Đường Thượng",
        "code": "xa_duong_thuong",
        "division_type": "xã",
        "short_codename": "duong_thuong",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Lũng Hồ",
        "code": "xa_lung_ho",
        "division_type": "xã",
        "short_codename": "lung_ho",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Du Tiến",
        "code": "xa_du_tien",
        "division_type": "xã",
        "short_codename": "du_tien",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Xã Du Già",
        "code": "xa_du_gia",
        "division_type": "xã",
        "short_codename": "du_gia",
        "parentCode": "huyen_yen_minh"
    },
    {
        "name": "Thị trấn Tam Sơn",
        "code": "thi_tran_tam_son",
        "division_type": "thị trấn",
        "short_codename": "tam_son",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Bát Đại Sơn",
        "code": "xa_bat_dai_son",
        "division_type": "xã",
        "short_codename": "bat_dai_son",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Nghĩa Thuận",
        "code": "xa_nghia_thuan",
        "division_type": "xã",
        "short_codename": "nghia_thuan",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Cán Tỷ",
        "code": "xa_can_ty",
        "division_type": "xã",
        "short_codename": "can_ty",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Cao Mã Pờ",
        "code": "xa_cao_ma_po",
        "division_type": "xã",
        "short_codename": "cao_ma_po",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Thanh Vân",
        "code": "xa_thanh_van",
        "division_type": "xã",
        "short_codename": "thanh_van",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Tùng Vài",
        "code": "xa_tung_vai",
        "division_type": "xã",
        "short_codename": "tung_vai",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Đông Hà",
        "code": "xa_dong_ha",
        "division_type": "xã",
        "short_codename": "dong_ha",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Quản Bạ",
        "code": "xa_quan_ba",
        "division_type": "xã",
        "short_codename": "quan_ba",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Lùng Tám",
        "code": "xa_lung_tam",
        "division_type": "xã",
        "short_codename": "lung_tam",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Quyết Tiến",
        "code": "xa_quyet_tien",
        "division_type": "xã",
        "short_codename": "quyet_tien",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Tả Ván",
        "code": "xa_ta_van",
        "division_type": "xã",
        "short_codename": "ta_van",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Thái An",
        "code": "xa_thai_an",
        "division_type": "xã",
        "short_codename": "thai_an",
        "parentCode": "huyen_quan_ba"
    },
    {
        "name": "Xã Kim Thạch",
        "code": "xa_kim_thach",
        "division_type": "xã",
        "short_codename": "kim_thach",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Phú Linh",
        "code": "xa_phu_linh",
        "division_type": "xã",
        "short_codename": "phu_linh",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Kim Linh",
        "code": "xa_kim_linh",
        "division_type": "xã",
        "short_codename": "kim_linh",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Thị trấn Vị Xuyên",
        "code": "thi_tran_vi_xuyen",
        "division_type": "thị trấn",
        "short_codename": "vi_xuyen",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Thị trấn Nông Trường Việt Lâm",
        "code": "thi_tran_nong_truong_viet_lam",
        "division_type": "thị trấn",
        "short_codename": "nong_truong_viet_lam",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Thuận Hoà",
        "code": "xa_thuan_hoa",
        "division_type": "xã",
        "short_codename": "thuan_hoa",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Tùng Bá",
        "code": "xa_tung_ba",
        "division_type": "xã",
        "short_codename": "tung_ba",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Thanh Thủy",
        "code": "xa_thanh_thuy",
        "division_type": "xã",
        "short_codename": "thanh_thuy",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Thanh Đức",
        "code": "xa_thanh_duc",
        "division_type": "xã",
        "short_codename": "thanh_duc",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Phong Quang",
        "code": "xa_phong_quang",
        "division_type": "xã",
        "short_codename": "phong_quang",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Xín Chải",
        "code": "xa_xin_chai",
        "division_type": "xã",
        "short_codename": "xin_chai",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Phương Tiến",
        "code": "xa_phuong_tien",
        "division_type": "xã",
        "short_codename": "phuong_tien",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Lao Chải",
        "code": "xa_lao_chai",
        "division_type": "xã",
        "short_codename": "lao_chai",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Cao Bồ",
        "code": "xa_cao_bo",
        "division_type": "xã",
        "short_codename": "cao_bo",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Đạo Đức",
        "code": "xa_dao_duc",
        "division_type": "xã",
        "short_codename": "dao_duc",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Thượng Sơn",
        "code": "xa_thuong_son",
        "division_type": "xã",
        "short_codename": "thuong_son",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Linh Hồ",
        "code": "xa_linh_ho",
        "division_type": "xã",
        "short_codename": "linh_ho",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Quảng Ngần",
        "code": "xa_quang_ngan",
        "division_type": "xã",
        "short_codename": "quang_ngan",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Việt Lâm",
        "code": "xa_viet_lam",
        "division_type": "xã",
        "short_codename": "viet_lam",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Ngọc Linh",
        "code": "xa_ngoc_linh",
        "division_type": "xã",
        "short_codename": "ngoc_linh",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Ngọc Minh",
        "code": "xa_ngoc_minh",
        "division_type": "xã",
        "short_codename": "ngoc_minh",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Bạch Ngọc",
        "code": "xa_bach_ngoc",
        "division_type": "xã",
        "short_codename": "bach_ngoc",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Trung Thành",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "huyen_vi_xuyen"
    },
    {
        "name": "Xã Minh Sơn",
        "code": "xa_minh_son",
        "division_type": "xã",
        "short_codename": "minh_son",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Giáp Trung",
        "code": "xa_giap_trung",
        "division_type": "xã",
        "short_codename": "giap_trung",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Yên Định",
        "code": "xa_yen_dinh",
        "division_type": "xã",
        "short_codename": "yen_dinh",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Thị trấn Yên Phú",
        "code": "thi_tran_yen_phu",
        "division_type": "thị trấn",
        "short_codename": "yen_phu",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Minh Ngọc",
        "code": "xa_minh_ngoc",
        "division_type": "xã",
        "short_codename": "minh_ngoc",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Yên Phong",
        "code": "xa_yen_phong",
        "division_type": "xã",
        "short_codename": "yen_phong",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Lạc Nông",
        "code": "xa_lac_nong",
        "division_type": "xã",
        "short_codename": "lac_nong",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Phú Nam",
        "code": "xa_phu_nam",
        "division_type": "xã",
        "short_codename": "phu_nam",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Yên Cường",
        "code": "xa_yen_cuong",
        "division_type": "xã",
        "short_codename": "yen_cuong",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Thượng Tân",
        "code": "xa_thuong_tan",
        "division_type": "xã",
        "short_codename": "thuong_tan",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Đường Âm",
        "code": "xa_duong_am",
        "division_type": "xã",
        "short_codename": "duong_am",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Đường Hồng",
        "code": "xa_duong_hong",
        "division_type": "xã",
        "short_codename": "duong_hong",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Xã Phiêng Luông",
        "code": "xa_phieng_luong",
        "division_type": "xã",
        "short_codename": "phieng_luong",
        "parentCode": "huyen_bac_me"
    },
    {
        "name": "Thị trấn Vinh Quang",
        "code": "thi_tran_vinh_quang",
        "division_type": "thị trấn",
        "short_codename": "vinh_quang",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Bản Máy",
        "code": "xa_ban_may",
        "division_type": "xã",
        "short_codename": "ban_may",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Thàng Tín",
        "code": "xa_thang_tin",
        "division_type": "xã",
        "short_codename": "thang_tin",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Thèn Chu Phìn",
        "code": "xa_then_chu_phin",
        "division_type": "xã",
        "short_codename": "then_chu_phin",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Pố Lồ",
        "code": "xa_po_lo",
        "division_type": "xã",
        "short_codename": "po_lo",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Bản Phùng",
        "code": "xa_ban_phung",
        "division_type": "xã",
        "short_codename": "ban_phung",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Túng Sán",
        "code": "xa_tung_san",
        "division_type": "xã",
        "short_codename": "tung_san",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Chiến Phố",
        "code": "xa_chien_pho",
        "division_type": "xã",
        "short_codename": "chien_pho",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Đản Ván",
        "code": "xa_dan_van",
        "division_type": "xã",
        "short_codename": "dan_van",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Tụ Nhân",
        "code": "xa_tu_nhan",
        "division_type": "xã",
        "short_codename": "tu_nhan",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Nàng Đôn",
        "code": "xa_nang_don",
        "division_type": "xã",
        "short_codename": "nang_don",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Pờ Ly Ngài",
        "code": "xa_po_ly_ngai",
        "division_type": "xã",
        "short_codename": "po_ly_ngai",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Sán Xả Hồ",
        "code": "xa_san_xa_ho",
        "division_type": "xã",
        "short_codename": "san_xa_ho",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Bản Luốc",
        "code": "xa_ban_luoc",
        "division_type": "xã",
        "short_codename": "ban_luoc",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Ngàm Đăng Vài",
        "code": "xa_ngam_dang_vai",
        "division_type": "xã",
        "short_codename": "ngam_dang_vai",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Bản Nhùng",
        "code": "xa_ban_nhung",
        "division_type": "xã",
        "short_codename": "ban_nhung",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Tả Sử Choóng",
        "code": "xa_ta_su_choong",
        "division_type": "xã",
        "short_codename": "ta_su_choong",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Nậm Dịch",
        "code": "xa_nam_dich",
        "division_type": "xã",
        "short_codename": "nam_dich",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Hồ Thầu",
        "code": "xa_ho_thau",
        "division_type": "xã",
        "short_codename": "ho_thau",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Nam Sơn",
        "code": "xa_nam_son",
        "division_type": "xã",
        "short_codename": "nam_son",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Nậm Tỵ",
        "code": "xa_nam_ty",
        "division_type": "xã",
        "short_codename": "nam_ty",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Thông Nguyên",
        "code": "xa_thong_nguyen",
        "division_type": "xã",
        "short_codename": "thong_nguyen",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Xã Nậm Khòa",
        "code": "xa_nam_khoa",
        "division_type": "xã",
        "short_codename": "nam_khoa",
        "parentCode": "huyen_hoang_su_phi"
    },
    {
        "name": "Thị trấn Cốc Pài",
        "code": "thi_tran_coc_pai",
        "division_type": "thị trấn",
        "short_codename": "coc_pai",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Nàn Xỉn",
        "code": "xa_nan_xin",
        "division_type": "xã",
        "short_codename": "nan_xin",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Bản Díu",
        "code": "xa_ban_diu",
        "division_type": "xã",
        "short_codename": "ban_diu",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Chí Cà",
        "code": "xa_chi_ca",
        "division_type": "xã",
        "short_codename": "chi_ca",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Xín Mần",
        "code": "xa_xin_man",
        "division_type": "xã",
        "short_codename": "xin_man",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Thèn Phàng",
        "code": "xa_then_phang",
        "division_type": "xã",
        "short_codename": "then_phang",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Trung Thịnh",
        "code": "xa_trung_thinh",
        "division_type": "xã",
        "short_codename": "trung_thinh",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Pà Vầy Sủ",
        "code": "xa_pa_vay_su",
        "division_type": "xã",
        "short_codename": "pa_vay_su",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Cốc Rế",
        "code": "xa_coc_re",
        "division_type": "xã",
        "short_codename": "coc_re",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Thu Tà",
        "code": "xa_thu_ta",
        "division_type": "xã",
        "short_codename": "thu_ta",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Nàn Ma",
        "code": "xa_nan_ma",
        "division_type": "xã",
        "short_codename": "nan_ma",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Tả Nhìu",
        "code": "xa_ta_nhiu",
        "division_type": "xã",
        "short_codename": "ta_nhiu",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Bản Ngò",
        "code": "xa_ban_ngo",
        "division_type": "xã",
        "short_codename": "ban_ngo",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Chế Là",
        "code": "xa_che_la",
        "division_type": "xã",
        "short_codename": "che_la",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Nấm Dẩn",
        "code": "xa_nam_dan",
        "division_type": "xã",
        "short_codename": "nam_dan",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Quảng Nguyên",
        "code": "xa_quang_nguyen",
        "division_type": "xã",
        "short_codename": "quang_nguyen",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Nà Chì",
        "code": "xa_na_chi",
        "division_type": "xã",
        "short_codename": "na_chi",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Xã Khuôn Lùng",
        "code": "xa_khuon_lung",
        "division_type": "xã",
        "short_codename": "khuon_lung",
        "parentCode": "huyen_xin_man"
    },
    {
        "name": "Thị trấn Việt Quang",
        "code": "thi_tran_viet_quang",
        "division_type": "thị trấn",
        "short_codename": "viet_quang",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Thị trấn Vĩnh Tuy",
        "code": "thi_tran_vinh_tuy",
        "division_type": "thị trấn",
        "short_codename": "vinh_tuy",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Đồng Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Đồng Tâm",
        "code": "xa_dong_tam",
        "division_type": "xã",
        "short_codename": "dong_tam",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Tân Quang",
        "code": "xa_tan_quang",
        "division_type": "xã",
        "short_codename": "tan_quang",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Thượng Bình",
        "code": "xa_thuong_binh",
        "division_type": "xã",
        "short_codename": "thuong_binh",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Hữu Sản",
        "code": "xa_huu_san",
        "division_type": "xã",
        "short_codename": "huu_san",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Kim Ngọc",
        "code": "xa_kim_ngoc",
        "division_type": "xã",
        "short_codename": "kim_ngoc",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Việt Vinh",
        "code": "xa_viet_vinh",
        "division_type": "xã",
        "short_codename": "viet_vinh",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Bằng Hành",
        "code": "xa_bang_hanh",
        "division_type": "xã",
        "short_codename": "bang_hanh",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Quang Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Liên Hiệp",
        "code": "xa_lien_hiep",
        "division_type": "xã",
        "short_codename": "lien_hiep",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Vô Điếm",
        "code": "xa_vo_diem",
        "division_type": "xã",
        "short_codename": "vo_diem",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Việt Hồng",
        "code": "xa_viet_hong",
        "division_type": "xã",
        "short_codename": "viet_hong",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Hùng An",
        "code": "xa_hung_an",
        "division_type": "xã",
        "short_codename": "hung_an",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Đức Xuân",
        "code": "xa_duc_xuan",
        "division_type": "xã",
        "short_codename": "duc_xuan",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Tiên Kiều",
        "code": "xa_tien_kieu",
        "division_type": "xã",
        "short_codename": "tien_kieu",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Vĩnh Hảo",
        "code": "xa_vinh_hao",
        "division_type": "xã",
        "short_codename": "vinh_hao",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Vĩnh Phúc",
        "code": "xa_vinh_phuc",
        "division_type": "xã",
        "short_codename": "vinh_phuc",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Đồng Yên",
        "code": "xa_dong_yen",
        "division_type": "xã",
        "short_codename": "dong_yen",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Đông Thành",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_bac_quang"
    },
    {
        "name": "Xã Xuân Minh",
        "code": "xa_xuan_minh",
        "division_type": "xã",
        "short_codename": "xuan_minh",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Tiên Nguyên",
        "code": "xa_tien_nguyen",
        "division_type": "xã",
        "short_codename": "tien_nguyen",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Tân Nam",
        "code": "xa_tan_nam",
        "division_type": "xã",
        "short_codename": "tan_nam",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Bản Rịa",
        "code": "xa_ban_ria",
        "division_type": "xã",
        "short_codename": "ban_ria",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Yên Thành",
        "code": "xa_yen_thanh",
        "division_type": "xã",
        "short_codename": "yen_thanh",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Thị trấn Yên Bình",
        "code": "thi_tran_yen_binh",
        "division_type": "thị trấn",
        "short_codename": "yen_binh",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Tân Trịnh",
        "code": "xa_tan_trinh",
        "division_type": "xã",
        "short_codename": "tan_trinh",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Tân Bắc",
        "code": "xa_tan_bac",
        "division_type": "xã",
        "short_codename": "tan_bac",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Bằng Lang",
        "code": "xa_bang_lang",
        "division_type": "xã",
        "short_codename": "bang_lang",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Yên Hà",
        "code": "xa_yen_ha",
        "division_type": "xã",
        "short_codename": "yen_ha",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Hương Sơn",
        "code": "xa_huong_son",
        "division_type": "xã",
        "short_codename": "huong_son",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Xuân Giang",
        "code": "xa_xuan_giang",
        "division_type": "xã",
        "short_codename": "xuan_giang",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Nà Khương",
        "code": "xa_na_khuong",
        "division_type": "xã",
        "short_codename": "na_khuong",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Tiên Yên",
        "code": "xa_tien_yen",
        "division_type": "xã",
        "short_codename": "tien_yen",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Xã Vĩ Thượng",
        "code": "xa_vi_thuong",
        "division_type": "xã",
        "short_codename": "vi_thuong",
        "parentCode": "huyen_quang_binh"
    },
    {
        "name": "Phường Sông Hiến",
        "code": "phuong_song_hien",
        "division_type": "phường",
        "short_codename": "song_hien",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Phường Sông Bằng",
        "code": "phuong_song_bang",
        "division_type": "phường",
        "short_codename": "song_bang",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Phường Hợp Giang",
        "code": "phuong_hop_giang",
        "division_type": "phường",
        "short_codename": "hop_giang",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Phường Tân Giang",
        "code": "phuong_tan_giang",
        "division_type": "phường",
        "short_codename": "tan_giang",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Phường Ngọc Xuân",
        "code": "phuong_ngoc_xuan",
        "division_type": "phường",
        "short_codename": "ngoc_xuan",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Phường Đề Thám",
        "code": "phuong_de_tham",
        "division_type": "phường",
        "short_codename": "de_tham",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Phường Hoà Chung",
        "code": "phuong_hoa_chung",
        "division_type": "phường",
        "short_codename": "hoa_chung",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Phường Duyệt Trung",
        "code": "phuong_duyet_trung",
        "division_type": "phường",
        "short_codename": "duyet_trung",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Xã Vĩnh Quang",
        "code": "xa_vinh_quang",
        "division_type": "xã",
        "short_codename": "vinh_quang",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Xã Hưng Đạo",
        "code": "xa_hung_dao",
        "division_type": "xã",
        "short_codename": "hung_dao",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Xã Chu Trinh",
        "code": "xa_chu_trinh",
        "division_type": "xã",
        "short_codename": "chu_trinh",
        "parentCode": "thanh_pho_cao_bang"
    },
    {
        "name": "Thị trấn Pác Miầu",
        "code": "thi_tran_pac_miau",
        "division_type": "thị trấn",
        "short_codename": "pac_miau",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Đức Hạnh",
        "code": "xa_duc_hanh",
        "division_type": "xã",
        "short_codename": "duc_hanh",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lý Bôn",
        "code": "xa_ly_bon",
        "division_type": "xã",
        "short_codename": "ly_bon",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Nam Cao",
        "code": "xa_nam_cao",
        "division_type": "xã",
        "short_codename": "nam_cao",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Nam Quang",
        "code": "xa_nam_quang",
        "division_type": "xã",
        "short_codename": "nam_quang",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Vĩnh Quang",
        "code": "xa_vinh_quang",
        "division_type": "xã",
        "short_codename": "vinh_quang",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Quảng Lâm",
        "code": "xa_quang_lam",
        "division_type": "xã",
        "short_codename": "quang_lam",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Thạch Lâm",
        "code": "xa_thach_lam",
        "division_type": "xã",
        "short_codename": "thach_lam",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Vĩnh Phong",
        "code": "xa_vinh_phong",
        "division_type": "xã",
        "short_codename": "vinh_phong",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Mông Ân",
        "code": "xa_mong_an",
        "division_type": "xã",
        "short_codename": "mong_an",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Thái Học",
        "code": "xa_thai_hoc",
        "division_type": "xã",
        "short_codename": "thai_hoc",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Thái Sơn",
        "code": "xa_thai_son",
        "division_type": "xã",
        "short_codename": "thai_son",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Yên Thổ",
        "code": "xa_yen_tho",
        "division_type": "xã",
        "short_codename": "yen_tho",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Thị trấn Bảo Lạc",
        "code": "thi_tran_bao_lac",
        "division_type": "thị trấn",
        "short_codename": "bao_lac",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Cốc Pàng",
        "code": "xa_coc_pang",
        "division_type": "xã",
        "short_codename": "coc_pang",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Thượng Hà",
        "code": "xa_thuong_ha",
        "division_type": "xã",
        "short_codename": "thuong_ha",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Cô Ba",
        "code": "xa_co_ba",
        "division_type": "xã",
        "short_codename": "co_ba",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Bảo Toàn",
        "code": "xa_bao_toan",
        "division_type": "xã",
        "short_codename": "bao_toan",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Khánh Xuân",
        "code": "xa_khanh_xuan",
        "division_type": "xã",
        "short_codename": "khanh_xuan",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Xuân Trường",
        "code": "xa_xuan_truong",
        "division_type": "xã",
        "short_codename": "xuan_truong",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Hồng Trị",
        "code": "xa_hong_tri",
        "division_type": "xã",
        "short_codename": "hong_tri",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Kim Cúc",
        "code": "xa_kim_cuc",
        "division_type": "xã",
        "short_codename": "kim_cuc",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Phan Thanh",
        "code": "xa_phan_thanh",
        "division_type": "xã",
        "short_codename": "phan_thanh",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Hồng An",
        "code": "xa_hong_an",
        "division_type": "xã",
        "short_codename": "hong_an",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Hưng Đạo",
        "code": "xa_hung_dao",
        "division_type": "xã",
        "short_codename": "hung_dao",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Hưng Thịnh",
        "code": "xa_hung_thinh",
        "division_type": "xã",
        "short_codename": "hung_thinh",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Huy Giáp",
        "code": "xa_huy_giap",
        "division_type": "xã",
        "short_codename": "huy_giap",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Đình Phùng",
        "code": "xa_dinh_phung",
        "division_type": "xã",
        "short_codename": "dinh_phung",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Sơn Lập",
        "code": "xa_son_lap",
        "division_type": "xã",
        "short_codename": "son_lap",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Xã Sơn Lộ",
        "code": "xa_son_lo",
        "division_type": "xã",
        "short_codename": "son_lo",
        "parentCode": "huyen_bao_lac"
    },
    {
        "name": "Thị trấn Thông Nông",
        "code": "thi_tran_thong_nong",
        "division_type": "thị trấn",
        "short_codename": "thong_nong",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Cần Yên",
        "code": "xa_can_yen",
        "division_type": "xã",
        "short_codename": "can_yen",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Cần Nông",
        "code": "xa_can_nong",
        "division_type": "xã",
        "short_codename": "can_nong",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Lương Thông",
        "code": "xa_luong_thong",
        "division_type": "xã",
        "short_codename": "luong_thong",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Đa Thông",
        "code": "xa_da_thong",
        "division_type": "xã",
        "short_codename": "da_thong",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Ngọc Động",
        "code": "xa_ngoc_dong",
        "division_type": "xã",
        "short_codename": "ngoc_dong",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Yên Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Lương Can",
        "code": "xa_luong_can",
        "division_type": "xã",
        "short_codename": "luong_can",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Thanh Long",
        "code": "xa_thanh_long",
        "division_type": "xã",
        "short_codename": "thanh_long",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Thị trấn Xuân Hòa",
        "code": "thi_tran_xuan_hoa",
        "division_type": "thị trấn",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Lũng Nặm",
        "code": "xa_lung_nam",
        "division_type": "xã",
        "short_codename": "lung_nam",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Trường Hà",
        "code": "xa_truong_ha",
        "division_type": "xã",
        "short_codename": "truong_ha",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Cải Viên",
        "code": "xa_cai_vien",
        "division_type": "xã",
        "short_codename": "cai_vien",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Nội Thôn",
        "code": "xa_noi_thon",
        "division_type": "xã",
        "short_codename": "noi_thon",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Tổng Cọt",
        "code": "xa_tong_cot",
        "division_type": "xã",
        "short_codename": "tong_cot",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Sóc Hà",
        "code": "xa_soc_ha",
        "division_type": "xã",
        "short_codename": "soc_ha",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Thượng Thôn",
        "code": "xa_thuong_thon",
        "division_type": "xã",
        "short_codename": "thuong_thon",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Hồng Sỹ",
        "code": "xa_hong_sy",
        "division_type": "xã",
        "short_codename": "hong_sy",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Quý Quân",
        "code": "xa_quy_quan",
        "division_type": "xã",
        "short_codename": "quy_quan",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Mã Ba",
        "code": "xa_ma_ba",
        "division_type": "xã",
        "short_codename": "ma_ba",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Xã Ngọc Đào",
        "code": "xa_ngoc_dao",
        "division_type": "xã",
        "short_codename": "ngoc_dao",
        "parentCode": "huyen_ha_quang"
    },
    {
        "name": "Thị trấn Trà Lĩnh",
        "code": "thi_tran_tra_linh",
        "division_type": "thị trấn",
        "short_codename": "tra_linh",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Tri Phương",
        "code": "xa_tri_phuong",
        "division_type": "xã",
        "short_codename": "tri_phuong",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Quang Hán",
        "code": "xa_quang_han",
        "division_type": "xã",
        "short_codename": "quang_han",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Xuân Nội",
        "code": "xa_xuan_noi",
        "division_type": "xã",
        "short_codename": "xuan_noi",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Quang Vinh",
        "code": "xa_quang_vinh",
        "division_type": "xã",
        "short_codename": "quang_vinh",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Cao Chương",
        "code": "xa_cao_chuong",
        "division_type": "xã",
        "short_codename": "cao_chuong",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Thị trấn Trùng Khánh",
        "code": "thi_tran_trung_khanh",
        "division_type": "thị trấn",
        "short_codename": "trung_khanh",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Ngọc Khê",
        "code": "xa_ngoc_khe",
        "division_type": "xã",
        "short_codename": "ngoc_khe",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Ngọc Côn",
        "code": "xa_ngoc_con",
        "division_type": "xã",
        "short_codename": "ngoc_con",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Phong Nậm",
        "code": "xa_phong_nam",
        "division_type": "xã",
        "short_codename": "phong_nam",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Đình Phong",
        "code": "xa_dinh_phong",
        "division_type": "xã",
        "short_codename": "dinh_phong",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Đàm Thuỷ",
        "code": "xa_dam_thuy",
        "division_type": "xã",
        "short_codename": "dam_thuy",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Khâm Thành",
        "code": "xa_kham_thanh",
        "division_type": "xã",
        "short_codename": "kham_thanh",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Chí Viễn",
        "code": "xa_chi_vien",
        "division_type": "xã",
        "short_codename": "chi_vien",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Lăng Hiếu",
        "code": "xa_lang_hieu",
        "division_type": "xã",
        "short_codename": "lang_hieu",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Phong Châu",
        "code": "xa_phong_chau",
        "division_type": "xã",
        "short_codename": "phong_chau",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Trung Phúc",
        "code": "xa_trung_phuc",
        "division_type": "xã",
        "short_codename": "trung_phuc",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Cao Thăng",
        "code": "xa_cao_thang",
        "division_type": "xã",
        "short_codename": "cao_thang",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Đức Hồng",
        "code": "xa_duc_hong",
        "division_type": "xã",
        "short_codename": "duc_hong",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Đoài Dương",
        "code": "xa_doai_duong",
        "division_type": "xã",
        "short_codename": "doai_duong",
        "parentCode": "huyen_trung_khanh"
    },
    {
        "name": "Xã Minh Long",
        "code": "xa_minh_long",
        "division_type": "xã",
        "short_codename": "minh_long",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Lý Quốc",
        "code": "xa_ly_quoc",
        "division_type": "xã",
        "short_codename": "ly_quoc",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Thắng Lợi",
        "code": "xa_thang_loi",
        "division_type": "xã",
        "short_codename": "thang_loi",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Đồng Loan",
        "code": "xa_dong_loan",
        "division_type": "xã",
        "short_codename": "dong_loan",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Đức Quang",
        "code": "xa_duc_quang",
        "division_type": "xã",
        "short_codename": "duc_quang",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Kim Loan",
        "code": "xa_kim_loan",
        "division_type": "xã",
        "short_codename": "kim_loan",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Quang Long",
        "code": "xa_quang_long",
        "division_type": "xã",
        "short_codename": "quang_long",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã An Lạc",
        "code": "xa_an_lac",
        "division_type": "xã",
        "short_codename": "an_lac",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Thị trấn Thanh Nhật",
        "code": "thi_tran_thanh_nhat",
        "division_type": "thị trấn",
        "short_codename": "thanh_nhat",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Vinh Quý",
        "code": "xa_vinh_quy",
        "division_type": "xã",
        "short_codename": "vinh_quy",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Thống Nhất",
        "code": "xa_thong_nhat",
        "division_type": "xã",
        "short_codename": "thong_nhat",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Cô Ngân",
        "code": "xa_co_ngan",
        "division_type": "xã",
        "short_codename": "co_ngan",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Thị Hoa",
        "code": "xa_thi_hoa",
        "division_type": "xã",
        "short_codename": "thi_hoa",
        "parentCode": "huyen_ha_lang"
    },
    {
        "name": "Xã Quốc Toản",
        "code": "xa_quoc_toan",
        "division_type": "xã",
        "short_codename": "quoc_toan",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Thị trấn Quảng Uyên",
        "code": "thi_tran_quang_uyen",
        "division_type": "thị trấn",
        "short_codename": "quang_uyen",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Phi Hải",
        "code": "xa_phi_hai",
        "division_type": "xã",
        "short_codename": "phi_hai",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Quảng Hưng",
        "code": "xa_quang_hung",
        "division_type": "xã",
        "short_codename": "quang_hung",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Độc Lập",
        "code": "xa_doc_lap",
        "division_type": "xã",
        "short_codename": "doc_lap",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Cai Bộ",
        "code": "xa_cai_bo",
        "division_type": "xã",
        "short_codename": "cai_bo",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Phúc Sen",
        "code": "xa_phuc_sen",
        "division_type": "xã",
        "short_codename": "phuc_sen",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Chí Thảo",
        "code": "xa_chi_thao",
        "division_type": "xã",
        "short_codename": "chi_thao",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Tự Do",
        "code": "xa_tu_do",
        "division_type": "xã",
        "short_codename": "tu_do",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Hồng Quang",
        "code": "xa_hong_quang",
        "division_type": "xã",
        "short_codename": "hong_quang",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Ngọc Động",
        "code": "xa_ngoc_dong",
        "division_type": "xã",
        "short_codename": "ngoc_dong",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Hạnh Phúc",
        "code": "xa_hanh_phuc",
        "division_type": "xã",
        "short_codename": "hanh_phuc",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Thị trấn Tà Lùng",
        "code": "thi_tran_ta_lung",
        "division_type": "thị trấn",
        "short_codename": "ta_lung",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Bế Văn Đàn",
        "code": "xa_be_van_dan",
        "division_type": "xã",
        "short_codename": "be_van_dan",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Cách Linh",
        "code": "xa_cach_linh",
        "division_type": "xã",
        "short_codename": "cach_linh",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Đại Sơn",
        "code": "xa_dai_son",
        "division_type": "xã",
        "short_codename": "dai_son",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Tiên Thành",
        "code": "xa_tien_thanh",
        "division_type": "xã",
        "short_codename": "tien_thanh",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Thị trấn Hoà Thuận",
        "code": "thi_tran_hoa_thuan",
        "division_type": "thị trấn",
        "short_codename": "hoa_thuan",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Xã Mỹ Hưng",
        "code": "xa_my_hung",
        "division_type": "xã",
        "short_codename": "my_hung",
        "parentCode": "huyen_quang_hoa"
    },
    {
        "name": "Thị trấn Nước Hai",
        "code": "thi_tran_nuoc_hai",
        "division_type": "thị trấn",
        "short_codename": "nuoc_hai",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Dân Chủ",
        "code": "xa_dan_chu",
        "division_type": "xã",
        "short_codename": "dan_chu",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Nam Tuấn",
        "code": "xa_nam_tuan",
        "division_type": "xã",
        "short_codename": "nam_tuan",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Đại Tiến",
        "code": "xa_dai_tien",
        "division_type": "xã",
        "short_codename": "dai_tien",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Đức Long",
        "code": "xa_duc_long",
        "division_type": "xã",
        "short_codename": "duc_long",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Ngũ Lão",
        "code": "xa_ngu_lao",
        "division_type": "xã",
        "short_codename": "ngu_lao",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Trương Lương",
        "code": "xa_truong_luong",
        "division_type": "xã",
        "short_codename": "truong_luong",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Hồng Việt",
        "code": "xa_hong_viet",
        "division_type": "xã",
        "short_codename": "hong_viet",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Hoàng Tung",
        "code": "xa_hoang_tung",
        "division_type": "xã",
        "short_codename": "hoang_tung",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Nguyễn Huệ",
        "code": "xa_nguyen_hue",
        "division_type": "xã",
        "short_codename": "nguyen_hue",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Bạch Đằng",
        "code": "xa_bach_dang",
        "division_type": "xã",
        "short_codename": "bach_dang",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Bình Dương",
        "code": "xa_binh_duong",
        "division_type": "xã",
        "short_codename": "binh_duong",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Lê Chung",
        "code": "xa_le_chung",
        "division_type": "xã",
        "short_codename": "le_chung",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Xã Hồng Nam",
        "code": "xa_hong_nam",
        "division_type": "xã",
        "short_codename": "hong_nam",
        "parentCode": "huyen_hoa_an"
    },
    {
        "name": "Thị trấn Nguyên Bình",
        "code": "thi_tran_nguyen_binh",
        "division_type": "thị trấn",
        "short_codename": "nguyen_binh",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Thị trấn Tĩnh Túc",
        "code": "thi_tran_tinh_tuc",
        "division_type": "thị trấn",
        "short_codename": "tinh_tuc",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Yên Lạc",
        "code": "xa_yen_lac",
        "division_type": "xã",
        "short_codename": "yen_lac",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Triệu Nguyên",
        "code": "xa_trieu_nguyen",
        "division_type": "xã",
        "short_codename": "trieu_nguyen",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Ca Thành",
        "code": "xa_ca_thanh",
        "division_type": "xã",
        "short_codename": "ca_thanh",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Vũ Nông",
        "code": "xa_vu_nong",
        "division_type": "xã",
        "short_codename": "vu_nong",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Minh Tâm",
        "code": "xa_minh_tam",
        "division_type": "xã",
        "short_codename": "minh_tam",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Thể Dục",
        "code": "xa_the_duc",
        "division_type": "xã",
        "short_codename": "the_duc",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Mai Long",
        "code": "xa_mai_long",
        "division_type": "xã",
        "short_codename": "mai_long",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Vũ Minh",
        "code": "xa_vu_minh",
        "division_type": "xã",
        "short_codename": "vu_minh",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Hoa Thám",
        "code": "xa_hoa_tham",
        "division_type": "xã",
        "short_codename": "hoa_tham",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Phan Thanh",
        "code": "xa_phan_thanh",
        "division_type": "xã",
        "short_codename": "phan_thanh",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Quang Thành",
        "code": "xa_quang_thanh",
        "division_type": "xã",
        "short_codename": "quang_thanh",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Tam Kim",
        "code": "xa_tam_kim",
        "division_type": "xã",
        "short_codename": "tam_kim",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Thành Công",
        "code": "xa_thanh_cong",
        "division_type": "xã",
        "short_codename": "thanh_cong",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Thịnh Vượng",
        "code": "xa_thinh_vuong",
        "division_type": "xã",
        "short_codename": "thinh_vuong",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Xã Hưng Đạo",
        "code": "xa_hung_dao",
        "division_type": "xã",
        "short_codename": "hung_dao",
        "parentCode": "huyen_nguyen_binh"
    },
    {
        "name": "Thị trấn Đông Khê",
        "code": "thi_tran_dong_khe",
        "division_type": "thị trấn",
        "short_codename": "dong_khe",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Canh Tân",
        "code": "xa_canh_tan",
        "division_type": "xã",
        "short_codename": "canh_tan",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Kim Đồng",
        "code": "xa_kim_dong",
        "division_type": "xã",
        "short_codename": "kim_dong",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Minh Khai",
        "code": "xa_minh_khai",
        "division_type": "xã",
        "short_codename": "minh_khai",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Đức Thông",
        "code": "xa_duc_thong",
        "division_type": "xã",
        "short_codename": "duc_thong",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Thái Cường",
        "code": "xa_thai_cuong",
        "division_type": "xã",
        "short_codename": "thai_cuong",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Vân Trình",
        "code": "xa_van_trinh",
        "division_type": "xã",
        "short_codename": "van_trinh",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Thụy Hùng",
        "code": "xa_thuy_hung",
        "division_type": "xã",
        "short_codename": "thuy_hung",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Quang Trọng",
        "code": "xa_quang_trong",
        "division_type": "xã",
        "short_codename": "quang_trong",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Trọng Con",
        "code": "xa_trong_con",
        "division_type": "xã",
        "short_codename": "trong_con",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Lê Lai",
        "code": "xa_le_lai",
        "division_type": "xã",
        "short_codename": "le_lai",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Đức Long",
        "code": "xa_duc_long",
        "division_type": "xã",
        "short_codename": "duc_long",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Lê Lợi",
        "code": "xa_le_loi",
        "division_type": "xã",
        "short_codename": "le_loi",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Xã Đức Xuân",
        "code": "xa_duc_xuan",
        "division_type": "xã",
        "short_codename": "duc_xuan",
        "parentCode": "huyen_thach_an"
    },
    {
        "name": "Phường Nguyễn Thị Minh Khai",
        "code": "phuong_nguyen_thi_minh_khai",
        "division_type": "phường",
        "short_codename": "nguyen_thi_minh_khai",
        "parentCode": "thanh_pho_bac_kan"
    },
    {
        "name": "Phường Sông Cầu",
        "code": "phuong_song_cau",
        "division_type": "phường",
        "short_codename": "song_cau",
        "parentCode": "thanh_pho_bac_kan"
    },
    {
        "name": "Phường Đức Xuân",
        "code": "phuong_duc_xuan",
        "division_type": "phường",
        "short_codename": "duc_xuan",
        "parentCode": "thanh_pho_bac_kan"
    },
    {
        "name": "Phường Phùng Chí Kiên",
        "code": "phuong_phung_chi_kien",
        "division_type": "phường",
        "short_codename": "phung_chi_kien",
        "parentCode": "thanh_pho_bac_kan"
    },
    {
        "name": "Phường Huyền Tụng",
        "code": "phuong_huyen_tung",
        "division_type": "phường",
        "short_codename": "huyen_tung",
        "parentCode": "thanh_pho_bac_kan"
    },
    {
        "name": "Xã Dương Quang",
        "code": "xa_duong_quang",
        "division_type": "xã",
        "short_codename": "duong_quang",
        "parentCode": "thanh_pho_bac_kan"
    },
    {
        "name": "Xã Nông Thượng",
        "code": "xa_nong_thuong",
        "division_type": "xã",
        "short_codename": "nong_thuong",
        "parentCode": "thanh_pho_bac_kan"
    },
    {
        "name": "Phường Xuất Hóa",
        "code": "phuong_xuat_hoa",
        "division_type": "phường",
        "short_codename": "xuat_hoa",
        "parentCode": "thanh_pho_bac_kan"
    },
    {
        "name": "Xã Bằng Thành",
        "code": "xa_bang_thanh",
        "division_type": "xã",
        "short_codename": "bang_thanh",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Xã Nhạn Môn",
        "code": "xa_nhan_mon",
        "division_type": "xã",
        "short_codename": "nhan_mon",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Xã Bộc Bố",
        "code": "xa_boc_bo",
        "division_type": "xã",
        "short_codename": "boc_bo",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Xã Công Bằng",
        "code": "xa_cong_bang",
        "division_type": "xã",
        "short_codename": "cong_bang",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Xã Giáo Hiệu",
        "code": "xa_giao_hieu",
        "division_type": "xã",
        "short_codename": "giao_hieu",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Xã Xuân La",
        "code": "xa_xuan_la",
        "division_type": "xã",
        "short_codename": "xuan_la",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Xã An Thắng",
        "code": "xa_an_thang",
        "division_type": "xã",
        "short_codename": "an_thang",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Xã Cổ Linh",
        "code": "xa_co_linh",
        "division_type": "xã",
        "short_codename": "co_linh",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Xã Nghiên Loan",
        "code": "xa_nghien_loan",
        "division_type": "xã",
        "short_codename": "nghien_loan",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Xã Cao Tân",
        "code": "xa_cao_tan",
        "division_type": "xã",
        "short_codename": "cao_tan",
        "parentCode": "huyen_pac_nam"
    },
    {
        "name": "Thị trấn Chợ Rã",
        "code": "thi_tran_cho_ra",
        "division_type": "thị trấn",
        "short_codename": "cho_ra",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Bành Trạch",
        "code": "xa_banh_trach",
        "division_type": "xã",
        "short_codename": "banh_trach",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Phúc Lộc",
        "code": "xa_phuc_loc",
        "division_type": "xã",
        "short_codename": "phuc_loc",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Hà Hiệu",
        "code": "xa_ha_hieu",
        "division_type": "xã",
        "short_codename": "ha_hieu",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Cao Thượng",
        "code": "xa_cao_thuong",
        "division_type": "xã",
        "short_codename": "cao_thuong",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Khang Ninh",
        "code": "xa_khang_ninh",
        "division_type": "xã",
        "short_codename": "khang_ninh",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Nam Mẫu",
        "code": "xa_nam_mau",
        "division_type": "xã",
        "short_codename": "nam_mau",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Thượng Giáo",
        "code": "xa_thuong_giao",
        "division_type": "xã",
        "short_codename": "thuong_giao",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Địa Linh",
        "code": "xa_dia_linh",
        "division_type": "xã",
        "short_codename": "dia_linh",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Yến Dương",
        "code": "xa_yen_duong",
        "division_type": "xã",
        "short_codename": "yen_duong",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Chu Hương",
        "code": "xa_chu_huong",
        "division_type": "xã",
        "short_codename": "chu_huong",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Quảng Khê",
        "code": "xa_quang_khe",
        "division_type": "xã",
        "short_codename": "quang_khe",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Mỹ Phương",
        "code": "xa_my_phuong",
        "division_type": "xã",
        "short_codename": "my_phuong",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Hoàng Trĩ",
        "code": "xa_hoang_tri",
        "division_type": "xã",
        "short_codename": "hoang_tri",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Xã Đồng Phúc",
        "code": "xa_dong_phuc",
        "division_type": "xã",
        "short_codename": "dong_phuc",
        "parentCode": "huyen_ba_be"
    },
    {
        "name": "Thị trấn Nà Phặc",
        "code": "thi_tran_na_phac",
        "division_type": "thị trấn",
        "short_codename": "na_phac",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Xã Thượng Ân",
        "code": "xa_thuong_an",
        "division_type": "xã",
        "short_codename": "thuong_an",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Xã Bằng Vân",
        "code": "xa_bang_van",
        "division_type": "xã",
        "short_codename": "bang_van",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Xã Cốc Đán",
        "code": "xa_coc_dan",
        "division_type": "xã",
        "short_codename": "coc_dan",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Xã Trung Hoà",
        "code": "xa_trung_hoa",
        "division_type": "xã",
        "short_codename": "trung_hoa",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Xã Đức Vân",
        "code": "xa_duc_van",
        "division_type": "xã",
        "short_codename": "duc_van",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Xã Vân Tùng",
        "code": "xa_van_tung",
        "division_type": "xã",
        "short_codename": "van_tung",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Xã Thượng Quan",
        "code": "xa_thuong_quan",
        "division_type": "xã",
        "short_codename": "thuong_quan",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Xã Hiệp Lực",
        "code": "xa_hiep_luc",
        "division_type": "xã",
        "short_codename": "hiep_luc",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Xã Thuần Mang",
        "code": "xa_thuan_mang",
        "division_type": "xã",
        "short_codename": "thuan_mang",
        "parentCode": "huyen_ngan_son"
    },
    {
        "name": "Thị trấn Phủ Thông",
        "code": "thi_tran_phu_thong",
        "division_type": "thị trấn",
        "short_codename": "phu_thong",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Vi Hương",
        "code": "xa_vi_huong",
        "division_type": "xã",
        "short_codename": "vi_huong",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Sĩ Bình",
        "code": "xa_si_binh",
        "division_type": "xã",
        "short_codename": "si_binh",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Vũ Muộn",
        "code": "xa_vu_muon",
        "division_type": "xã",
        "short_codename": "vu_muon",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Đôn Phong",
        "code": "xa_don_phong",
        "division_type": "xã",
        "short_codename": "don_phong",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Lục Bình",
        "code": "xa_luc_binh",
        "division_type": "xã",
        "short_codename": "luc_binh",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Tân Tú",
        "code": "xa_tan_tu",
        "division_type": "xã",
        "short_codename": "tan_tu",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Nguyên Phúc",
        "code": "xa_nguyen_phuc",
        "division_type": "xã",
        "short_codename": "nguyen_phuc",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Cao Sơn",
        "code": "xa_cao_son",
        "division_type": "xã",
        "short_codename": "cao_son",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Quân Hà",
        "code": "xa_quan_ha",
        "division_type": "xã",
        "short_codename": "quan_ha",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Cẩm Giàng",
        "code": "xa_cam_giang",
        "division_type": "xã",
        "short_codename": "cam_giang",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Mỹ Thanh",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Dương Phong",
        "code": "xa_duong_phong",
        "division_type": "xã",
        "short_codename": "duong_phong",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Xã Quang Thuận",
        "code": "xa_quang_thuan",
        "division_type": "xã",
        "short_codename": "quang_thuan",
        "parentCode": "huyen_bach_thong"
    },
    {
        "name": "Thị trấn Bằng Lũng",
        "code": "thi_tran_bang_lung",
        "division_type": "thị trấn",
        "short_codename": "bang_lung",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Xuân Lạc",
        "code": "xa_xuan_lac",
        "division_type": "xã",
        "short_codename": "xuan_lac",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Nam Cường",
        "code": "xa_nam_cuong",
        "division_type": "xã",
        "short_codename": "nam_cuong",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Đồng Lạc",
        "code": "xa_dong_lac",
        "division_type": "xã",
        "short_codename": "dong_lac",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Bản Thi",
        "code": "xa_ban_thi",
        "division_type": "xã",
        "short_codename": "ban_thi",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Quảng Bạch",
        "code": "xa_quang_bach",
        "division_type": "xã",
        "short_codename": "quang_bach",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Bằng Phúc",
        "code": "xa_bang_phuc",
        "division_type": "xã",
        "short_codename": "bang_phuc",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Yên Thịnh",
        "code": "xa_yen_thinh",
        "division_type": "xã",
        "short_codename": "yen_thinh",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Yên Thượng",
        "code": "xa_yen_thuong",
        "division_type": "xã",
        "short_codename": "yen_thuong",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Phương Viên",
        "code": "xa_phuong_vien",
        "division_type": "xã",
        "short_codename": "phuong_vien",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Ngọc Phái",
        "code": "xa_ngoc_phai",
        "division_type": "xã",
        "short_codename": "ngoc_phai",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Đồng Thắng",
        "code": "xa_dong_thang",
        "division_type": "xã",
        "short_codename": "dong_thang",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Lương Bằng",
        "code": "xa_luong_bang",
        "division_type": "xã",
        "short_codename": "luong_bang",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Bằng Lãng",
        "code": "xa_bang_lang",
        "division_type": "xã",
        "short_codename": "bang_lang",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Đại Sảo",
        "code": "xa_dai_sao",
        "division_type": "xã",
        "short_codename": "dai_sao",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Nghĩa Tá",
        "code": "xa_nghia_ta",
        "division_type": "xã",
        "short_codename": "nghia_ta",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Yên Mỹ",
        "code": "xa_yen_my",
        "division_type": "xã",
        "short_codename": "yen_my",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Bình Trung",
        "code": "xa_binh_trung",
        "division_type": "xã",
        "short_codename": "binh_trung",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Xã Yên Phong",
        "code": "xa_yen_phong",
        "division_type": "xã",
        "short_codename": "yen_phong",
        "parentCode": "huyen_cho_don"
    },
    {
        "name": "Thị trấn Đồng Tâm",
        "code": "thi_tran_dong_tam",
        "division_type": "thị trấn",
        "short_codename": "dong_tam",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Tân Sơn",
        "code": "xa_tan_son",
        "division_type": "xã",
        "short_codename": "tan_son",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Thanh Vận",
        "code": "xa_thanh_van",
        "division_type": "xã",
        "short_codename": "thanh_van",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Mai Lạp",
        "code": "xa_mai_lap",
        "division_type": "xã",
        "short_codename": "mai_lap",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Hoà Mục",
        "code": "xa_hoa_muc",
        "division_type": "xã",
        "short_codename": "hoa_muc",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Thanh Mai",
        "code": "xa_thanh_mai",
        "division_type": "xã",
        "short_codename": "thanh_mai",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Cao Kỳ",
        "code": "xa_cao_ky",
        "division_type": "xã",
        "short_codename": "cao_ky",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Nông Hạ",
        "code": "xa_nong_ha",
        "division_type": "xã",
        "short_codename": "nong_ha",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Yên Cư",
        "code": "xa_yen_cu",
        "division_type": "xã",
        "short_codename": "yen_cu",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Thanh Thịnh",
        "code": "xa_thanh_thinh",
        "division_type": "xã",
        "short_codename": "thanh_thinh",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Yên Hân",
        "code": "xa_yen_han",
        "division_type": "xã",
        "short_codename": "yen_han",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Như Cố",
        "code": "xa_nhu_co",
        "division_type": "xã",
        "short_codename": "nhu_co",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Bình Văn",
        "code": "xa_binh_van",
        "division_type": "xã",
        "short_codename": "binh_van",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Quảng Chu",
        "code": "xa_quang_chu",
        "division_type": "xã",
        "short_codename": "quang_chu",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Văn Vũ",
        "code": "xa_van_vu",
        "division_type": "xã",
        "short_codename": "van_vu",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Văn Lang",
        "code": "xa_van_lang",
        "division_type": "xã",
        "short_codename": "van_lang",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Lương Thượng",
        "code": "xa_luong_thuong",
        "division_type": "xã",
        "short_codename": "luong_thuong",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Kim Hỷ",
        "code": "xa_kim_hy",
        "division_type": "xã",
        "short_codename": "kim_hy",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Cường Lợi",
        "code": "xa_cuong_loi",
        "division_type": "xã",
        "short_codename": "cuong_loi",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Thị trấn Yến Lạc",
        "code": "thi_tran_yen_lac",
        "division_type": "thị trấn",
        "short_codename": "yen_lac",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Kim Lư",
        "code": "xa_kim_lu",
        "division_type": "xã",
        "short_codename": "kim_lu",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Sơn Thành",
        "code": "xa_son_thanh",
        "division_type": "xã",
        "short_codename": "son_thanh",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Văn Minh",
        "code": "xa_van_minh",
        "division_type": "xã",
        "short_codename": "van_minh",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Côn Minh",
        "code": "xa_con_minh",
        "division_type": "xã",
        "short_codename": "con_minh",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Cư Lễ",
        "code": "xa_cu_le",
        "division_type": "xã",
        "short_codename": "cu_le",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Trần Phú",
        "code": "xa_tran_phu",
        "division_type": "xã",
        "short_codename": "tran_phu",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Quang Phong",
        "code": "xa_quang_phong",
        "division_type": "xã",
        "short_codename": "quang_phong",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Dương Sơn",
        "code": "xa_duong_son",
        "division_type": "xã",
        "short_codename": "duong_son",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Xuân Dương",
        "code": "xa_xuan_duong",
        "division_type": "xã",
        "short_codename": "xuan_duong",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Đổng Xá",
        "code": "xa_dong_xa",
        "division_type": "xã",
        "short_codename": "dong_xa",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Xã Liêm Thuỷ",
        "code": "xa_liem_thuy",
        "division_type": "xã",
        "short_codename": "liem_thuy",
        "parentCode": "huyen_na_ri"
    },
    {
        "name": "Phường Phan Thiết",
        "code": "phuong_phan_thiet",
        "division_type": "phường",
        "short_codename": "phan_thiet",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Phường Minh Xuân",
        "code": "phuong_minh_xuan",
        "division_type": "phường",
        "short_codename": "minh_xuan",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Phường Tân Quang",
        "code": "phuong_tan_quang",
        "division_type": "phường",
        "short_codename": "tan_quang",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Xã Tràng Đà",
        "code": "xa_trang_da",
        "division_type": "xã",
        "short_codename": "trang_da",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Phường Nông Tiến",
        "code": "phuong_nong_tien",
        "division_type": "phường",
        "short_codename": "nong_tien",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Phường Ỷ La",
        "code": "phuong_y_la",
        "division_type": "phường",
        "short_codename": "y_la",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Phường Tân Hà",
        "code": "phuong_tan_ha",
        "division_type": "phường",
        "short_codename": "tan_ha",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Phường Hưng Thành",
        "code": "phuong_hung_thanh",
        "division_type": "phường",
        "short_codename": "hung_thanh",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Xã Kim Phú",
        "code": "xa_kim_phu",
        "division_type": "xã",
        "short_codename": "kim_phu",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Xã An Khang",
        "code": "xa_an_khang",
        "division_type": "xã",
        "short_codename": "an_khang",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Phường Mỹ Lâm",
        "code": "phuong_my_lam",
        "division_type": "phường",
        "short_codename": "my_lam",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Phường An Tường",
        "code": "phuong_an_tuong",
        "division_type": "phường",
        "short_codename": "an_tuong",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Xã Lưỡng Vượng",
        "code": "xa_luong_vuong",
        "division_type": "xã",
        "short_codename": "luong_vuong",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Xã Thái Long",
        "code": "xa_thai_long",
        "division_type": "xã",
        "short_codename": "thai_long",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Phường Đội Cấn",
        "code": "phuong_doi_can",
        "division_type": "phường",
        "short_codename": "doi_can",
        "parentCode": "thanh_pho_tuyen_quang"
    },
    {
        "name": "Xã Phúc Yên",
        "code": "xa_phuc_yen",
        "division_type": "xã",
        "short_codename": "phuc_yen",
        "parentCode": "huyen_lam_binh"
    },
    {
        "name": "Xã Xuân Lập",
        "code": "xa_xuan_lap",
        "division_type": "xã",
        "short_codename": "xuan_lap",
        "parentCode": "huyen_lam_binh"
    },
    {
        "name": "Xã Khuôn Hà",
        "code": "xa_khuon_ha",
        "division_type": "xã",
        "short_codename": "khuon_ha",
        "parentCode": "huyen_lam_binh"
    },
    {
        "name": "Xã Lăng Can",
        "code": "xa_lang_can",
        "division_type": "xã",
        "short_codename": "lang_can",
        "parentCode": "huyen_lam_binh"
    },
    {
        "name": "Xã Thượng Lâm",
        "code": "xa_thuong_lam",
        "division_type": "xã",
        "short_codename": "thuong_lam",
        "parentCode": "huyen_lam_binh"
    },
    {
        "name": "Xã Bình An",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_lam_binh"
    },
    {
        "name": "Xã Hồng Quang",
        "code": "xa_hong_quang",
        "division_type": "xã",
        "short_codename": "hong_quang",
        "parentCode": "huyen_lam_binh"
    },
    {
        "name": "Xã Thổ Bình",
        "code": "xa_tho_binh",
        "division_type": "xã",
        "short_codename": "tho_binh",
        "parentCode": "huyen_lam_binh"
    },
    {
        "name": "Thị trấn Na Hang",
        "code": "thi_tran_na_hang",
        "division_type": "thị trấn",
        "short_codename": "na_hang",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Sinh Long",
        "code": "xa_sinh_long",
        "division_type": "xã",
        "short_codename": "sinh_long",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Thượng Giáp",
        "code": "xa_thuong_giap",
        "division_type": "xã",
        "short_codename": "thuong_giap",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Thượng Nông",
        "code": "xa_thuong_nong",
        "division_type": "xã",
        "short_codename": "thuong_nong",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Côn Lôn",
        "code": "xa_con_lon",
        "division_type": "xã",
        "short_codename": "con_lon",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Yên Hoa",
        "code": "xa_yen_hoa",
        "division_type": "xã",
        "short_codename": "yen_hoa",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Hồng Thái",
        "code": "xa_hong_thai",
        "division_type": "xã",
        "short_codename": "hong_thai",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Đà Vị",
        "code": "xa_da_vi",
        "division_type": "xã",
        "short_codename": "da_vi",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Khau Tinh",
        "code": "xa_khau_tinh",
        "division_type": "xã",
        "short_codename": "khau_tinh",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Sơn Phú",
        "code": "xa_son_phu",
        "division_type": "xã",
        "short_codename": "son_phu",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Năng Khả",
        "code": "xa_nang_kha",
        "division_type": "xã",
        "short_codename": "nang_kha",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Xã Thanh Tương",
        "code": "xa_thanh_tuong",
        "division_type": "xã",
        "short_codename": "thanh_tuong",
        "parentCode": "huyen_na_hang"
    },
    {
        "name": "Thị trấn Vĩnh Lộc",
        "code": "thi_tran_vinh_loc",
        "division_type": "thị trấn",
        "short_codename": "vinh_loc",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Phúc Sơn",
        "code": "xa_phuc_son",
        "division_type": "xã",
        "short_codename": "phuc_son",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Minh Quang",
        "code": "xa_minh_quang",
        "division_type": "xã",
        "short_codename": "minh_quang",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Trung Hà",
        "code": "xa_trung_ha",
        "division_type": "xã",
        "short_codename": "trung_ha",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Hà Lang",
        "code": "xa_ha_lang",
        "division_type": "xã",
        "short_codename": "ha_lang",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Hùng Mỹ",
        "code": "xa_hung_my",
        "division_type": "xã",
        "short_codename": "hung_my",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Yên Lập",
        "code": "xa_yen_lap",
        "division_type": "xã",
        "short_codename": "yen_lap",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Tân An",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Xuân Quang",
        "code": "xa_xuan_quang",
        "division_type": "xã",
        "short_codename": "xuan_quang",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Ngọc Hội",
        "code": "xa_ngoc_hoi",
        "division_type": "xã",
        "short_codename": "ngoc_hoi",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Phú Bình",
        "code": "xa_phu_binh",
        "division_type": "xã",
        "short_codename": "phu_binh",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Hòa Phú",
        "code": "xa_hoa_phu",
        "division_type": "xã",
        "short_codename": "hoa_phu",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Phúc Thịnh",
        "code": "xa_phuc_thinh",
        "division_type": "xã",
        "short_codename": "phuc_thinh",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Kiên Đài",
        "code": "xa_kien_dai",
        "division_type": "xã",
        "short_codename": "kien_dai",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Tân Thịnh",
        "code": "xa_tan_thinh",
        "division_type": "xã",
        "short_codename": "tan_thinh",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Trung Hòa",
        "code": "xa_trung_hoa",
        "division_type": "xã",
        "short_codename": "trung_hoa",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Kim Bình",
        "code": "xa_kim_binh",
        "division_type": "xã",
        "short_codename": "kim_binh",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Hòa An",
        "code": "xa_hoa_an",
        "division_type": "xã",
        "short_codename": "hoa_an",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Vinh Quang",
        "code": "xa_vinh_quang",
        "division_type": "xã",
        "short_codename": "vinh_quang",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Tri Phú",
        "code": "xa_tri_phu",
        "division_type": "xã",
        "short_codename": "tri_phu",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Nhân Lý",
        "code": "xa_nhan_ly",
        "division_type": "xã",
        "short_codename": "nhan_ly",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Yên Nguyên",
        "code": "xa_yen_nguyen",
        "division_type": "xã",
        "short_codename": "yen_nguyen",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Linh Phú",
        "code": "xa_linh_phu",
        "division_type": "xã",
        "short_codename": "linh_phu",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Xã Bình Nhân",
        "code": "xa_binh_nhan",
        "division_type": "xã",
        "short_codename": "binh_nhan",
        "parentCode": "huyen_chiem_hoa"
    },
    {
        "name": "Thị trấn Tân Yên",
        "code": "thi_tran_tan_yen",
        "division_type": "thị trấn",
        "short_codename": "tan_yen",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Yên Thuận",
        "code": "xa_yen_thuan",
        "division_type": "xã",
        "short_codename": "yen_thuan",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Bạch Xa",
        "code": "xa_bach_xa",
        "division_type": "xã",
        "short_codename": "bach_xa",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Minh Khương",
        "code": "xa_minh_khuong",
        "division_type": "xã",
        "short_codename": "minh_khuong",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Yên Lâm",
        "code": "xa_yen_lam",
        "division_type": "xã",
        "short_codename": "yen_lam",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Minh Dân",
        "code": "xa_minh_dan",
        "division_type": "xã",
        "short_codename": "minh_dan",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Phù Lưu",
        "code": "xa_phu_luu",
        "division_type": "xã",
        "short_codename": "phu_luu",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Minh Hương",
        "code": "xa_minh_huong",
        "division_type": "xã",
        "short_codename": "minh_huong",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Yên Phú",
        "code": "xa_yen_phu",
        "division_type": "xã",
        "short_codename": "yen_phu",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Bình Xa",
        "code": "xa_binh_xa",
        "division_type": "xã",
        "short_codename": "binh_xa",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Thái Sơn",
        "code": "xa_thai_son",
        "division_type": "xã",
        "short_codename": "thai_son",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Nhân Mục",
        "code": "xa_nhan_muc",
        "division_type": "xã",
        "short_codename": "nhan_muc",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Thành Long",
        "code": "xa_thanh_long",
        "division_type": "xã",
        "short_codename": "thanh_long",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Bằng Cốc",
        "code": "xa_bang_coc",
        "division_type": "xã",
        "short_codename": "bang_coc",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Thái Hòa",
        "code": "xa_thai_hoa",
        "division_type": "xã",
        "short_codename": "thai_hoa",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Đức Ninh",
        "code": "xa_duc_ninh",
        "division_type": "xã",
        "short_codename": "duc_ninh",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Hùng Đức",
        "code": "xa_hung_duc",
        "division_type": "xã",
        "short_codename": "hung_duc",
        "parentCode": "huyen_ham_yen"
    },
    {
        "name": "Xã Quí Quân",
        "code": "xa_qui_quan",
        "division_type": "xã",
        "short_codename": "qui_quan",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Lực Hành",
        "code": "xa_luc_hanh",
        "division_type": "xã",
        "short_codename": "luc_hanh",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Kiến Thiết",
        "code": "xa_kien_thiet",
        "division_type": "xã",
        "short_codename": "kien_thiet",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Trung Minh",
        "code": "xa_trung_minh",
        "division_type": "xã",
        "short_codename": "trung_minh",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Chiêu Yên",
        "code": "xa_chieu_yen",
        "division_type": "xã",
        "short_codename": "chieu_yen",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Trung Trực",
        "code": "xa_trung_truc",
        "division_type": "xã",
        "short_codename": "trung_truc",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Xuân Vân",
        "code": "xa_xuan_van",
        "division_type": "xã",
        "short_codename": "xuan_van",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Phúc Ninh",
        "code": "xa_phuc_ninh",
        "division_type": "xã",
        "short_codename": "phuc_ninh",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Hùng Lợi",
        "code": "xa_hung_loi",
        "division_type": "xã",
        "short_codename": "hung_loi",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Trung Sơn",
        "code": "xa_trung_son",
        "division_type": "xã",
        "short_codename": "trung_son",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Tứ Quận",
        "code": "xa_tu_quan",
        "division_type": "xã",
        "short_codename": "tu_quan",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Đạo Viện",
        "code": "xa_dao_vien",
        "division_type": "xã",
        "short_codename": "dao_vien",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Thắng Quân",
        "code": "xa_thang_quan",
        "division_type": "xã",
        "short_codename": "thang_quan",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Kim Quan",
        "code": "xa_kim_quan",
        "division_type": "xã",
        "short_codename": "kim_quan",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Lang Quán",
        "code": "xa_lang_quan",
        "division_type": "xã",
        "short_codename": "lang_quan",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Phú Thịnh",
        "code": "xa_phu_thinh",
        "division_type": "xã",
        "short_codename": "phu_thinh",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Công Đa",
        "code": "xa_cong_da",
        "division_type": "xã",
        "short_codename": "cong_da",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Trung Môn",
        "code": "xa_trung_mon",
        "division_type": "xã",
        "short_codename": "trung_mon",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Chân Sơn",
        "code": "xa_chan_son",
        "division_type": "xã",
        "short_codename": "chan_son",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Thái Bình",
        "code": "xa_thai_binh",
        "division_type": "xã",
        "short_codename": "thai_binh",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Tiến Bộ",
        "code": "xa_tien_bo",
        "division_type": "xã",
        "short_codename": "tien_bo",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Mỹ Bằng",
        "code": "xa_my_bang",
        "division_type": "xã",
        "short_codename": "my_bang",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Hoàng Khai",
        "code": "xa_hoang_khai",
        "division_type": "xã",
        "short_codename": "hoang_khai",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Nhữ Hán",
        "code": "xa_nhu_han",
        "division_type": "xã",
        "short_codename": "nhu_han",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Nhữ Khê",
        "code": "xa_nhu_khe",
        "division_type": "xã",
        "short_codename": "nhu_khe",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Xã Đội Bình",
        "code": "xa_doi_binh",
        "division_type": "xã",
        "short_codename": "doi_binh",
        "parentCode": "huyen_yen_son"
    },
    {
        "name": "Thị trấn Sơn Dương",
        "code": "thi_tran_son_duong",
        "division_type": "thị trấn",
        "short_codename": "son_duong",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Trung Yên",
        "code": "xa_trung_yen",
        "division_type": "xã",
        "short_codename": "trung_yen",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Minh Thanh",
        "code": "xa_minh_thanh",
        "division_type": "xã",
        "short_codename": "minh_thanh",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Tân Trào",
        "code": "xa_tan_trao",
        "division_type": "xã",
        "short_codename": "tan_trao",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Vĩnh Lợi",
        "code": "xa_vinh_loi",
        "division_type": "xã",
        "short_codename": "vinh_loi",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Thượng Ấm",
        "code": "xa_thuong_am",
        "division_type": "xã",
        "short_codename": "thuong_am",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Bình Yên",
        "code": "xa_binh_yen",
        "division_type": "xã",
        "short_codename": "binh_yen",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Lương Thiện",
        "code": "xa_luong_thien",
        "division_type": "xã",
        "short_codename": "luong_thien",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Tú Thịnh",
        "code": "xa_tu_thinh",
        "division_type": "xã",
        "short_codename": "tu_thinh",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Cấp Tiến",
        "code": "xa_cap_tien",
        "division_type": "xã",
        "short_codename": "cap_tien",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Hợp Thành",
        "code": "xa_hop_thanh",
        "division_type": "xã",
        "short_codename": "hop_thanh",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Phúc Ứng",
        "code": "xa_phuc_ung",
        "division_type": "xã",
        "short_codename": "phuc_ung",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Đông Thọ",
        "code": "xa_dong_tho",
        "division_type": "xã",
        "short_codename": "dong_tho",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Kháng Nhật",
        "code": "xa_khang_nhat",
        "division_type": "xã",
        "short_codename": "khang_nhat",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Hợp Hòa",
        "code": "xa_hop_hoa",
        "division_type": "xã",
        "short_codename": "hop_hoa",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Quyết Thắng",
        "code": "xa_quyet_thang",
        "division_type": "xã",
        "short_codename": "quyet_thang",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Đồng Quý",
        "code": "xa_dong_quy",
        "division_type": "xã",
        "short_codename": "dong_quy",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Tân Thanh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Vân Sơn",
        "code": "xa_van_son",
        "division_type": "xã",
        "short_codename": "van_son",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Văn Phú",
        "code": "xa_van_phu",
        "division_type": "xã",
        "short_codename": "van_phu",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Chi Thiết",
        "code": "xa_chi_thiet",
        "division_type": "xã",
        "short_codename": "chi_thiet",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Đông Lợi",
        "code": "xa_dong_loi",
        "division_type": "xã",
        "short_codename": "dong_loi",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Thiện Kế",
        "code": "xa_thien_ke",
        "division_type": "xã",
        "short_codename": "thien_ke",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Hồng Lạc",
        "code": "xa_hong_lac",
        "division_type": "xã",
        "short_codename": "hong_lac",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Phú Lương",
        "code": "xa_phu_luong",
        "division_type": "xã",
        "short_codename": "phu_luong",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Ninh Lai",
        "code": "xa_ninh_lai",
        "division_type": "xã",
        "short_codename": "ninh_lai",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Đại Phú",
        "code": "xa_dai_phu",
        "division_type": "xã",
        "short_codename": "dai_phu",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Sơn Nam",
        "code": "xa_son_nam",
        "division_type": "xã",
        "short_codename": "son_nam",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Hào Phú",
        "code": "xa_hao_phu",
        "division_type": "xã",
        "short_codename": "hao_phu",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Tam Đa",
        "code": "xa_tam_da",
        "division_type": "xã",
        "short_codename": "tam_da",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Xã Trường Sinh",
        "code": "xa_truong_sinh",
        "division_type": "xã",
        "short_codename": "truong_sinh",
        "parentCode": "huyen_son_duong"
    },
    {
        "name": "Phường Duyên Hải",
        "code": "phuong_duyen_hai",
        "division_type": "phường",
        "short_codename": "duyen_hai",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Phường Lào Cai",
        "code": "phuong_lao_cai",
        "division_type": "phường",
        "short_codename": "lao_cai",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Phường Cốc Lếu",
        "code": "phuong_coc_leu",
        "division_type": "phường",
        "short_codename": "coc_leu",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Phường Kim Tân",
        "code": "phuong_kim_tan",
        "division_type": "phường",
        "short_codename": "kim_tan",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Phường Bắc Lệnh",
        "code": "phuong_bac_lenh",
        "division_type": "phường",
        "short_codename": "bac_lenh",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Phường Pom Hán",
        "code": "phuong_pom_han",
        "division_type": "phường",
        "short_codename": "pom_han",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Phường Xuân Tăng",
        "code": "phuong_xuan_tang",
        "division_type": "phường",
        "short_codename": "xuan_tang",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Phường Bình Minh",
        "code": "phuong_binh_minh",
        "division_type": "phường",
        "short_codename": "binh_minh",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Xã Thống Nhất",
        "code": "xa_thong_nhat",
        "division_type": "xã",
        "short_codename": "thong_nhat",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Xã Đồng Tuyển",
        "code": "xa_dong_tuyen",
        "division_type": "xã",
        "short_codename": "dong_tuyen",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Xã Vạn Hoà",
        "code": "xa_van_hoa",
        "division_type": "xã",
        "short_codename": "van_hoa",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Phường Bắc Cường",
        "code": "phuong_bac_cuong",
        "division_type": "phường",
        "short_codename": "bac_cuong",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Phường Nam Cường",
        "code": "phuong_nam_cuong",
        "division_type": "phường",
        "short_codename": "nam_cuong",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Xã Cam Đường",
        "code": "xa_cam_duong",
        "division_type": "xã",
        "short_codename": "cam_duong",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Xã Tả Phời",
        "code": "xa_ta_phoi",
        "division_type": "xã",
        "short_codename": "ta_phoi",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Xã Hợp Thành",
        "code": "xa_hop_thanh",
        "division_type": "xã",
        "short_codename": "hop_thanh",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Xã Cốc San",
        "code": "xa_coc_san",
        "division_type": "xã",
        "short_codename": "coc_san",
        "parentCode": "thanh_pho_lao_cai"
    },
    {
        "name": "Thị trấn Bát Xát",
        "code": "thi_tran_bat_xat",
        "division_type": "thị trấn",
        "short_codename": "bat_xat",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã A Mú Sung",
        "code": "xa_a_mu_sung",
        "division_type": "xã",
        "short_codename": "a_mu_sung",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Nậm Chạc",
        "code": "xa_nam_chac",
        "division_type": "xã",
        "short_codename": "nam_chac",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã A Lù",
        "code": "xa_a_lu",
        "division_type": "xã",
        "short_codename": "a_lu",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Trịnh Tường",
        "code": "xa_trinh_tuong",
        "division_type": "xã",
        "short_codename": "trinh_tuong",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Y Tý",
        "code": "xa_y_ty",
        "division_type": "xã",
        "short_codename": "y_ty",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Cốc Mỳ",
        "code": "xa_coc_my",
        "division_type": "xã",
        "short_codename": "coc_my",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Dền Sáng",
        "code": "xa_den_sang",
        "division_type": "xã",
        "short_codename": "den_sang",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Bản Vược",
        "code": "xa_ban_vuoc",
        "division_type": "xã",
        "short_codename": "ban_vuoc",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Sàng Ma Sáo",
        "code": "xa_sang_ma_sao",
        "division_type": "xã",
        "short_codename": "sang_ma_sao",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Bản Qua",
        "code": "xa_ban_qua",
        "division_type": "xã",
        "short_codename": "ban_qua",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Mường Vi",
        "code": "xa_muong_vi",
        "division_type": "xã",
        "short_codename": "muong_vi",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Dền Thàng",
        "code": "xa_den_thang",
        "division_type": "xã",
        "short_codename": "den_thang",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Bản Xèo",
        "code": "xa_ban_xeo",
        "division_type": "xã",
        "short_codename": "ban_xeo",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Mường Hum",
        "code": "xa_muong_hum",
        "division_type": "xã",
        "short_codename": "muong_hum",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Trung Lèng Hồ",
        "code": "xa_trung_leng_ho",
        "division_type": "xã",
        "short_codename": "trung_leng_ho",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Quang Kim",
        "code": "xa_quang_kim",
        "division_type": "xã",
        "short_codename": "quang_kim",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Pa Cheo",
        "code": "xa_pa_cheo",
        "division_type": "xã",
        "short_codename": "pa_cheo",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Nậm Pung",
        "code": "xa_nam_pung",
        "division_type": "xã",
        "short_codename": "nam_pung",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Phìn Ngan",
        "code": "xa_phin_ngan",
        "division_type": "xã",
        "short_codename": "phin_ngan",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Tòng Sành",
        "code": "xa_tong_sanh",
        "division_type": "xã",
        "short_codename": "tong_sanh",
        "parentCode": "huyen_bat_xat"
    },
    {
        "name": "Xã Pha Long",
        "code": "xa_pha_long",
        "division_type": "xã",
        "short_codename": "pha_long",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Tả Ngải Chồ",
        "code": "xa_ta_ngai_cho",
        "division_type": "xã",
        "short_codename": "ta_ngai_cho",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Tung Chung Phố",
        "code": "xa_tung_chung_pho",
        "division_type": "xã",
        "short_codename": "tung_chung_pho",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Thị trấn Mường Khương",
        "code": "thi_tran_muong_khuong",
        "division_type": "thị trấn",
        "short_codename": "muong_khuong",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Dìn Chin",
        "code": "xa_din_chin",
        "division_type": "xã",
        "short_codename": "din_chin",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Tả Gia Khâu",
        "code": "xa_ta_gia_khau",
        "division_type": "xã",
        "short_codename": "ta_gia_khau",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Nậm Chảy",
        "code": "xa_nam_chay",
        "division_type": "xã",
        "short_codename": "nam_chay",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Nấm Lư",
        "code": "xa_nam_lu",
        "division_type": "xã",
        "short_codename": "nam_lu",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Lùng Khấu Nhin",
        "code": "xa_lung_khau_nhin",
        "division_type": "xã",
        "short_codename": "lung_khau_nhin",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Thanh Bình",
        "code": "xa_thanh_binh",
        "division_type": "xã",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Cao Sơn",
        "code": "xa_cao_son",
        "division_type": "xã",
        "short_codename": "cao_son",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Lùng Vai",
        "code": "xa_lung_vai",
        "division_type": "xã",
        "short_codename": "lung_vai",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Bản Lầu",
        "code": "xa_ban_lau",
        "division_type": "xã",
        "short_codename": "ban_lau",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã La Pan Tẩn",
        "code": "xa_la_pan_tan",
        "division_type": "xã",
        "short_codename": "la_pan_tan",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Tả Thàng",
        "code": "xa_ta_thang",
        "division_type": "xã",
        "short_codename": "ta_thang",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Bản Sen",
        "code": "xa_ban_sen",
        "division_type": "xã",
        "short_codename": "ban_sen",
        "parentCode": "huyen_muong_khuong"
    },
    {
        "name": "Xã Nàn Sán",
        "code": "xa_nan_san",
        "division_type": "xã",
        "short_codename": "nan_san",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Xã Thào Chư Phìn",
        "code": "xa_thao_chu_phin",
        "division_type": "xã",
        "short_codename": "thao_chu_phin",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Xã Bản Mế",
        "code": "xa_ban_me",
        "division_type": "xã",
        "short_codename": "ban_me",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Thị trấn Si Ma Cai",
        "code": "thi_tran_si_ma_cai",
        "division_type": "thị trấn",
        "short_codename": "si_ma_cai",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Xã Sán Chải",
        "code": "xa_san_chai",
        "division_type": "xã",
        "short_codename": "san_chai",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Xã Lùng Thẩn",
        "code": "xa_lung_than",
        "division_type": "xã",
        "short_codename": "lung_than",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Xã Cán Cấu",
        "code": "xa_can_cau",
        "division_type": "xã",
        "short_codename": "can_cau",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Xã Sín Chéng",
        "code": "xa_sin_cheng",
        "division_type": "xã",
        "short_codename": "sin_cheng",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Xã Quan Hồ Thẩn",
        "code": "xa_quan_ho_than",
        "division_type": "xã",
        "short_codename": "quan_ho_than",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Xã Nàn Xín",
        "code": "xa_nan_xin",
        "division_type": "xã",
        "short_codename": "nan_xin",
        "parentCode": "huyen_si_ma_cai"
    },
    {
        "name": "Thị trấn Bắc Hà",
        "code": "thi_tran_bac_ha",
        "division_type": "thị trấn",
        "short_codename": "bac_ha",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Lùng Cải",
        "code": "xa_lung_cai",
        "division_type": "xã",
        "short_codename": "lung_cai",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Lùng Phình",
        "code": "xa_lung_phinh",
        "division_type": "xã",
        "short_codename": "lung_phinh",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Tả Van Chư",
        "code": "xa_ta_van_chu",
        "division_type": "xã",
        "short_codename": "ta_van_chu",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Tả Củ Tỷ",
        "code": "xa_ta_cu_ty",
        "division_type": "xã",
        "short_codename": "ta_cu_ty",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Thải Giàng Phố",
        "code": "xa_thai_giang_pho",
        "division_type": "xã",
        "short_codename": "thai_giang_pho",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Hoàng Thu Phố",
        "code": "xa_hoang_thu_pho",
        "division_type": "xã",
        "short_codename": "hoang_thu_pho",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Bản Phố",
        "code": "xa_ban_pho",
        "division_type": "xã",
        "short_codename": "ban_pho",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Bản Liền",
        "code": "xa_ban_lien",
        "division_type": "xã",
        "short_codename": "ban_lien",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Tà Chải",
        "code": "xa_ta_chai",
        "division_type": "xã",
        "short_codename": "ta_chai",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Na Hối",
        "code": "xa_na_hoi",
        "division_type": "xã",
        "short_codename": "na_hoi",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Cốc Ly",
        "code": "xa_coc_ly",
        "division_type": "xã",
        "short_codename": "coc_ly",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Nậm Mòn",
        "code": "xa_nam_mon",
        "division_type": "xã",
        "short_codename": "nam_mon",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Nậm Đét",
        "code": "xa_nam_det",
        "division_type": "xã",
        "short_codename": "nam_det",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Nậm Khánh",
        "code": "xa_nam_khanh",
        "division_type": "xã",
        "short_codename": "nam_khanh",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Bảo Nhai",
        "code": "xa_bao_nhai",
        "division_type": "xã",
        "short_codename": "bao_nhai",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Nậm Lúc",
        "code": "xa_nam_luc",
        "division_type": "xã",
        "short_codename": "nam_luc",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Cốc Lầu",
        "code": "xa_coc_lau",
        "division_type": "xã",
        "short_codename": "coc_lau",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Xã Bản Cái",
        "code": "xa_ban_cai",
        "division_type": "xã",
        "short_codename": "ban_cai",
        "parentCode": "huyen_bac_ha"
    },
    {
        "name": "Thị trấn N.T Phong Hải",
        "code": "thi_tran_n_t_phong_hai",
        "division_type": "thị trấn",
        "short_codename": "n_t_phong_hai",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Thị trấn Phố Lu",
        "code": "thi_tran_pho_lu",
        "division_type": "thị trấn",
        "short_codename": "pho_lu",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Thị trấn Tằng Loỏng",
        "code": "thi_tran_tang_loong",
        "division_type": "thị trấn",
        "short_codename": "tang_loong",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Bản Phiệt",
        "code": "xa_ban_phiet",
        "division_type": "xã",
        "short_codename": "ban_phiet",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Bản Cầm",
        "code": "xa_ban_cam",
        "division_type": "xã",
        "short_codename": "ban_cam",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Thái Niên",
        "code": "xa_thai_nien",
        "division_type": "xã",
        "short_codename": "thai_nien",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Phong Niên",
        "code": "xa_phong_nien",
        "division_type": "xã",
        "short_codename": "phong_nien",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Gia Phú",
        "code": "xa_gia_phu",
        "division_type": "xã",
        "short_codename": "gia_phu",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Xuân Quang",
        "code": "xa_xuan_quang",
        "division_type": "xã",
        "short_codename": "xuan_quang",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Sơn Hải",
        "code": "xa_son_hai",
        "division_type": "xã",
        "short_codename": "son_hai",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Xuân Giao",
        "code": "xa_xuan_giao",
        "division_type": "xã",
        "short_codename": "xuan_giao",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Trì Quang",
        "code": "xa_tri_quang",
        "division_type": "xã",
        "short_codename": "tri_quang",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Sơn Hà",
        "code": "xa_son_ha",
        "division_type": "xã",
        "short_codename": "son_ha",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Xã Phú Nhuận",
        "code": "xa_phu_nhuan",
        "division_type": "xã",
        "short_codename": "phu_nhuan",
        "parentCode": "huyen_bao_thang"
    },
    {
        "name": "Thị trấn Phố Ràng",
        "code": "thi_tran_pho_rang",
        "division_type": "thị trấn",
        "short_codename": "pho_rang",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Nghĩa Đô",
        "code": "xa_nghia_do",
        "division_type": "xã",
        "short_codename": "nghia_do",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Vĩnh Yên",
        "code": "xa_vinh_yen",
        "division_type": "xã",
        "short_codename": "vinh_yen",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Điện Quan",
        "code": "xa_dien_quan",
        "division_type": "xã",
        "short_codename": "dien_quan",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Xuân Hoà",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Tân Dương",
        "code": "xa_tan_duong",
        "division_type": "xã",
        "short_codename": "tan_duong",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Thượng Hà",
        "code": "xa_thuong_ha",
        "division_type": "xã",
        "short_codename": "thuong_ha",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Kim Sơn",
        "code": "xa_kim_son",
        "division_type": "xã",
        "short_codename": "kim_son",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Cam Cọn",
        "code": "xa_cam_con",
        "division_type": "xã",
        "short_codename": "cam_con",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Xuân Thượng",
        "code": "xa_xuan_thuong",
        "division_type": "xã",
        "short_codename": "xuan_thuong",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Việt Tiến",
        "code": "xa_viet_tien",
        "division_type": "xã",
        "short_codename": "viet_tien",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Yên Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Bảo Hà",
        "code": "xa_bao_ha",
        "division_type": "xã",
        "short_codename": "bao_ha",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Lương Sơn",
        "code": "xa_luong_son",
        "division_type": "xã",
        "short_codename": "luong_son",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Xã Phúc Khánh",
        "code": "xa_phuc_khanh",
        "division_type": "xã",
        "short_codename": "phuc_khanh",
        "parentCode": "huyen_bao_yen"
    },
    {
        "name": "Phường Sa Pa",
        "code": "phuong_sa_pa",
        "division_type": "phường",
        "short_codename": "sa_pa",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Phường Sa Pả",
        "code": "phuong_sa_pa",
        "division_type": "phường",
        "short_codename": "sa_pa",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Phường Ô Quý Hồ",
        "code": "phuong_o_quy_ho",
        "division_type": "phường",
        "short_codename": "o_quy_ho",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Ngũ Chỉ Sơn",
        "code": "xa_ngu_chi_son",
        "division_type": "xã",
        "short_codename": "ngu_chi_son",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Phường Phan Si Păng",
        "code": "phuong_phan_si_pang",
        "division_type": "phường",
        "short_codename": "phan_si_pang",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Trung Chải",
        "code": "xa_trung_chai",
        "division_type": "xã",
        "short_codename": "trung_chai",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Tả Phìn",
        "code": "xa_ta_phin",
        "division_type": "xã",
        "short_codename": "ta_phin",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Phường Hàm Rồng",
        "code": "phuong_ham_rong",
        "division_type": "phường",
        "short_codename": "ham_rong",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Hoàng Liên",
        "code": "xa_hoang_lien",
        "division_type": "xã",
        "short_codename": "hoang_lien",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Thanh Bình",
        "code": "xa_thanh_binh",
        "division_type": "xã",
        "short_codename": "thanh_binh",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Phường Cầu Mây",
        "code": "phuong_cau_may",
        "division_type": "phường",
        "short_codename": "cau_may",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Mường Hoa",
        "code": "xa_muong_hoa",
        "division_type": "xã",
        "short_codename": "muong_hoa",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Tả Van",
        "code": "xa_ta_van",
        "division_type": "xã",
        "short_codename": "ta_van",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Mường Bo",
        "code": "xa_muong_bo",
        "division_type": "xã",
        "short_codename": "muong_bo",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Bản Hồ",
        "code": "xa_ban_ho",
        "division_type": "xã",
        "short_codename": "ban_ho",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Xã Liên Minh",
        "code": "xa_lien_minh",
        "division_type": "xã",
        "short_codename": "lien_minh",
        "parentCode": "thi_xa_sa_pa"
    },
    {
        "name": "Thị trấn Khánh Yên",
        "code": "thi_tran_khanh_yen",
        "division_type": "thị trấn",
        "short_codename": "khanh_yen",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Võ Lao",
        "code": "xa_vo_lao",
        "division_type": "xã",
        "short_codename": "vo_lao",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Sơn Thuỷ",
        "code": "xa_son_thuy",
        "division_type": "xã",
        "short_codename": "son_thuy",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Nậm Mả",
        "code": "xa_nam_ma",
        "division_type": "xã",
        "short_codename": "nam_ma",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Tân Thượng",
        "code": "xa_tan_thuong",
        "division_type": "xã",
        "short_codename": "tan_thuong",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Nậm Rạng",
        "code": "xa_nam_rang",
        "division_type": "xã",
        "short_codename": "nam_rang",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Nậm Chầy",
        "code": "xa_nam_chay",
        "division_type": "xã",
        "short_codename": "nam_chay",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Tân An",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Khánh Yên Thượng",
        "code": "xa_khanh_yen_thuong",
        "division_type": "xã",
        "short_codename": "khanh_yen_thuong",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Nậm Xé",
        "code": "xa_nam_xe",
        "division_type": "xã",
        "short_codename": "nam_xe",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Dần Thàng",
        "code": "xa_dan_thang",
        "division_type": "xã",
        "short_codename": "dan_thang",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Chiềng Ken",
        "code": "xa_chieng_ken",
        "division_type": "xã",
        "short_codename": "chieng_ken",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Làng Giàng",
        "code": "xa_lang_giang",
        "division_type": "xã",
        "short_codename": "lang_giang",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Hoà Mạc",
        "code": "xa_hoa_mac",
        "division_type": "xã",
        "short_codename": "hoa_mac",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Khánh Yên Trung",
        "code": "xa_khanh_yen_trung",
        "division_type": "xã",
        "short_codename": "khanh_yen_trung",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Khánh Yên Hạ",
        "code": "xa_khanh_yen_ha",
        "division_type": "xã",
        "short_codename": "khanh_yen_ha",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Dương Quỳ",
        "code": "xa_duong_quy",
        "division_type": "xã",
        "short_codename": "duong_quy",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Nậm Tha",
        "code": "xa_nam_tha",
        "division_type": "xã",
        "short_codename": "nam_tha",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Minh Lương",
        "code": "xa_minh_luong",
        "division_type": "xã",
        "short_codename": "minh_luong",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Thẩm Dương",
        "code": "xa_tham_duong",
        "division_type": "xã",
        "short_codename": "tham_duong",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Liêm Phú",
        "code": "xa_liem_phu",
        "division_type": "xã",
        "short_codename": "liem_phu",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Xã Nậm Xây",
        "code": "xa_nam_xay",
        "division_type": "xã",
        "short_codename": "nam_xay",
        "parentCode": "huyen_van_ban"
    },
    {
        "name": "Phường Noong Bua",
        "code": "phuong_noong_bua",
        "division_type": "phường",
        "short_codename": "noong_bua",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Phường Him Lam",
        "code": "phuong_him_lam",
        "division_type": "phường",
        "short_codename": "him_lam",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Phường Thanh Bình",
        "code": "phuong_thanh_binh",
        "division_type": "phường",
        "short_codename": "thanh_binh",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Phường Tân Thanh",
        "code": "phuong_tan_thanh",
        "division_type": "phường",
        "short_codename": "tan_thanh",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Phường Mường Thanh",
        "code": "phuong_muong_thanh",
        "division_type": "phường",
        "short_codename": "muong_thanh",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Phường Nam Thanh",
        "code": "phuong_nam_thanh",
        "division_type": "phường",
        "short_codename": "nam_thanh",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Phường Thanh Trường",
        "code": "phuong_thanh_truong",
        "division_type": "phường",
        "short_codename": "thanh_truong",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Xã Thanh Minh",
        "code": "xa_thanh_minh",
        "division_type": "xã",
        "short_codename": "thanh_minh",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Xã Nà Tấu",
        "code": "xa_na_tau",
        "division_type": "xã",
        "short_codename": "na_tau",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Xã Nà Nhạn",
        "code": "xa_na_nhan",
        "division_type": "xã",
        "short_codename": "na_nhan",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Xã Mường Phăng",
        "code": "xa_muong_phang",
        "division_type": "xã",
        "short_codename": "muong_phang",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Xã Pá Khoang",
        "code": "xa_pa_khoang",
        "division_type": "xã",
        "short_codename": "pa_khoang",
        "parentCode": "thanh_pho_dien_bien_phu"
    },
    {
        "name": "Phường Sông Đà",
        "code": "phuong_song_da",
        "division_type": "phường",
        "short_codename": "song_da",
        "parentCode": "thi_xa_muong_lay"
    },
    {
        "name": "Phường Na Lay",
        "code": "phuong_na_lay",
        "division_type": "phường",
        "short_codename": "na_lay",
        "parentCode": "thi_xa_muong_lay"
    },
    {
        "name": "Xã Lay Nưa",
        "code": "xa_lay_nua",
        "division_type": "xã",
        "short_codename": "lay_nua",
        "parentCode": "thi_xa_muong_lay"
    },
    {
        "name": "Xã Sín Thầu",
        "code": "xa_sin_thau",
        "division_type": "xã",
        "short_codename": "sin_thau",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Sen Thượng",
        "code": "xa_sen_thuong",
        "division_type": "xã",
        "short_codename": "sen_thuong",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Chung Chải",
        "code": "xa_chung_chai",
        "division_type": "xã",
        "short_codename": "chung_chai",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Leng Su Sìn",
        "code": "xa_leng_su_sin",
        "division_type": "xã",
        "short_codename": "leng_su_sin",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Pá Mỳ",
        "code": "xa_pa_my",
        "division_type": "xã",
        "short_codename": "pa_my",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Mường Nhé",
        "code": "xa_muong_nhe",
        "division_type": "xã",
        "short_codename": "muong_nhe",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Nậm Vì",
        "code": "xa_nam_vi",
        "division_type": "xã",
        "short_codename": "nam_vi",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Nậm Kè",
        "code": "xa_nam_ke",
        "division_type": "xã",
        "short_codename": "nam_ke",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Mường Toong",
        "code": "xa_muong_toong",
        "division_type": "xã",
        "short_codename": "muong_toong",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Quảng Lâm",
        "code": "xa_quang_lam",
        "division_type": "xã",
        "short_codename": "quang_lam",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Xã Huổi Lếnh",
        "code": "xa_huoi_lenh",
        "division_type": "xã",
        "short_codename": "huoi_lenh",
        "parentCode": "huyen_muong_nhe"
    },
    {
        "name": "Thị Trấn Mường Chà",
        "code": "thi_tran_muong_cha",
        "division_type": "thị trấn",
        "short_codename": "muong_cha",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Xá Tổng",
        "code": "xa_xa_tong",
        "division_type": "xã",
        "short_codename": "xa_tong",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Mường Tùng",
        "code": "xa_muong_tung",
        "division_type": "xã",
        "short_codename": "muong_tung",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Hừa Ngài",
        "code": "xa_hua_ngai",
        "division_type": "xã",
        "short_codename": "hua_ngai",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Huổi Mí",
        "code": "xa_huoi_mi",
        "division_type": "xã",
        "short_codename": "huoi_mi",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Pa Ham",
        "code": "xa_pa_ham",
        "division_type": "xã",
        "short_codename": "pa_ham",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Nậm Nèn",
        "code": "xa_nam_nen",
        "division_type": "xã",
        "short_codename": "nam_nen",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Huổi Lèng",
        "code": "xa_huoi_leng",
        "division_type": "xã",
        "short_codename": "huoi_leng",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Sa Lông",
        "code": "xa_sa_long",
        "division_type": "xã",
        "short_codename": "sa_long",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Ma Thì Hồ",
        "code": "xa_ma_thi_ho",
        "division_type": "xã",
        "short_codename": "ma_thi_ho",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Na Sang",
        "code": "xa_na_sang",
        "division_type": "xã",
        "short_codename": "na_sang",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Xã Mường Mươn",
        "code": "xa_muong_muon",
        "division_type": "xã",
        "short_codename": "muong_muon",
        "parentCode": "huyen_muong_cha"
    },
    {
        "name": "Thị trấn Tủa Chùa",
        "code": "thi_tran_tua_chua",
        "division_type": "thị trấn",
        "short_codename": "tua_chua",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Huổi Só",
        "code": "xa_huoi_so",
        "division_type": "xã",
        "short_codename": "huoi_so",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Xín Chải",
        "code": "xa_xin_chai",
        "division_type": "xã",
        "short_codename": "xin_chai",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Tả Sìn Thàng",
        "code": "xa_ta_sin_thang",
        "division_type": "xã",
        "short_codename": "ta_sin_thang",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Lao Xả Phình",
        "code": "xa_lao_xa_phinh",
        "division_type": "xã",
        "short_codename": "lao_xa_phinh",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Tả Phìn",
        "code": "xa_ta_phin",
        "division_type": "xã",
        "short_codename": "ta_phin",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Tủa Thàng",
        "code": "xa_tua_thang",
        "division_type": "xã",
        "short_codename": "tua_thang",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Trung Thu",
        "code": "xa_trung_thu",
        "division_type": "xã",
        "short_codename": "trung_thu",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Sính Phình",
        "code": "xa_sinh_phinh",
        "division_type": "xã",
        "short_codename": "sinh_phinh",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Sáng Nhè",
        "code": "xa_sang_nhe",
        "division_type": "xã",
        "short_codename": "sang_nhe",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Mường Đun",
        "code": "xa_muong_dun",
        "division_type": "xã",
        "short_codename": "muong_dun",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Xã Mường Báng",
        "code": "xa_muong_bang",
        "division_type": "xã",
        "short_codename": "muong_bang",
        "parentCode": "huyen_tua_chua"
    },
    {
        "name": "Thị trấn Tuần Giáo",
        "code": "thi_tran_tuan_giao",
        "division_type": "thị trấn",
        "short_codename": "tuan_giao",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Phình Sáng",
        "code": "xa_phinh_sang",
        "division_type": "xã",
        "short_codename": "phinh_sang",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Rạng Đông",
        "code": "xa_rang_dong",
        "division_type": "xã",
        "short_codename": "rang_dong",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Mùn Chung",
        "code": "xa_mun_chung",
        "division_type": "xã",
        "short_codename": "mun_chung",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Nà Tòng",
        "code": "xa_na_tong",
        "division_type": "xã",
        "short_codename": "na_tong",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Ta Ma",
        "code": "xa_ta_ma",
        "division_type": "xã",
        "short_codename": "ta_ma",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Mường Mùn",
        "code": "xa_muong_mun",
        "division_type": "xã",
        "short_codename": "muong_mun",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Pú Xi",
        "code": "xa_pu_xi",
        "division_type": "xã",
        "short_codename": "pu_xi",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Pú Nhung",
        "code": "xa_pu_nhung",
        "division_type": "xã",
        "short_codename": "pu_nhung",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Quài Nưa",
        "code": "xa_quai_nua",
        "division_type": "xã",
        "short_codename": "quai_nua",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Mường Thín",
        "code": "xa_muong_thin",
        "division_type": "xã",
        "short_codename": "muong_thin",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Tỏa Tình",
        "code": "xa_toa_tinh",
        "division_type": "xã",
        "short_codename": "toa_tinh",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Nà Sáy",
        "code": "xa_na_say",
        "division_type": "xã",
        "short_codename": "na_say",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Mường Khong",
        "code": "xa_muong_khong",
        "division_type": "xã",
        "short_codename": "muong_khong",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Quài Cang",
        "code": "xa_quai_cang",
        "division_type": "xã",
        "short_codename": "quai_cang",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Quài Tở",
        "code": "xa_quai_to",
        "division_type": "xã",
        "short_codename": "quai_to",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Chiềng Sinh",
        "code": "xa_chieng_sinh",
        "division_type": "xã",
        "short_codename": "chieng_sinh",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Chiềng Đông",
        "code": "xa_chieng_dong",
        "division_type": "xã",
        "short_codename": "chieng_dong",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Tênh Phông",
        "code": "xa_tenh_phong",
        "division_type": "xã",
        "short_codename": "tenh_phong",
        "parentCode": "huyen_tuan_giao"
    },
    {
        "name": "Xã Mường Pồn",
        "code": "xa_muong_pon",
        "division_type": "xã",
        "short_codename": "muong_pon",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Thanh Nưa",
        "code": "xa_thanh_nua",
        "division_type": "xã",
        "short_codename": "thanh_nua",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Hua Thanh",
        "code": "xa_hua_thanh",
        "division_type": "xã",
        "short_codename": "hua_thanh",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Thanh Luông",
        "code": "xa_thanh_luong",
        "division_type": "xã",
        "short_codename": "thanh_luong",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Thanh Hưng",
        "code": "xa_thanh_hung",
        "division_type": "xã",
        "short_codename": "thanh_hung",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Thanh Xương",
        "code": "xa_thanh_xuong",
        "division_type": "xã",
        "short_codename": "thanh_xuong",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Thanh Chăn",
        "code": "xa_thanh_chan",
        "division_type": "xã",
        "short_codename": "thanh_chan",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Pa Thơm",
        "code": "xa_pa_thom",
        "division_type": "xã",
        "short_codename": "pa_thom",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Thanh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Thanh Yên",
        "code": "xa_thanh_yen",
        "division_type": "xã",
        "short_codename": "thanh_yen",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Noong Luống",
        "code": "xa_noong_luong",
        "division_type": "xã",
        "short_codename": "noong_luong",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Noọng Hẹt",
        "code": "xa_noong_het",
        "division_type": "xã",
        "short_codename": "noong_het",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Sam Mứn",
        "code": "xa_sam_mun",
        "division_type": "xã",
        "short_codename": "sam_mun",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Pom Lót",
        "code": "xa_pom_lot",
        "division_type": "xã",
        "short_codename": "pom_lot",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Núa Ngam",
        "code": "xa_nua_ngam",
        "division_type": "xã",
        "short_codename": "nua_ngam",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Hẹ Muông",
        "code": "xa_he_muong",
        "division_type": "xã",
        "short_codename": "he_muong",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Na Ư",
        "code": "xa_na_u",
        "division_type": "xã",
        "short_codename": "na_u",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Mường Nhà",
        "code": "xa_muong_nha",
        "division_type": "xã",
        "short_codename": "muong_nha",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Na Tông",
        "code": "xa_na_tong",
        "division_type": "xã",
        "short_codename": "na_tong",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Mường Lói",
        "code": "xa_muong_loi",
        "division_type": "xã",
        "short_codename": "muong_loi",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Xã Phu Luông",
        "code": "xa_phu_luong",
        "division_type": "xã",
        "short_codename": "phu_luong",
        "parentCode": "huyen_dien_bien"
    },
    {
        "name": "Thị trấn Điện Biên Đông",
        "code": "thi_tran_dien_bien_dong",
        "division_type": "thị trấn",
        "short_codename": "dien_bien_dong",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Na Son",
        "code": "xa_na_son",
        "division_type": "xã",
        "short_codename": "na_son",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Phì Nhừ",
        "code": "xa_phi_nhu",
        "division_type": "xã",
        "short_codename": "phi_nhu",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Chiềng Sơ",
        "code": "xa_chieng_so",
        "division_type": "xã",
        "short_codename": "chieng_so",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Mường Luân",
        "code": "xa_muong_luan",
        "division_type": "xã",
        "short_codename": "muong_luan",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Pú Nhi",
        "code": "xa_pu_nhi",
        "division_type": "xã",
        "short_codename": "pu_nhi",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Nong U",
        "code": "xa_nong_u",
        "division_type": "xã",
        "short_codename": "nong_u",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Xa Dung",
        "code": "xa_xa_dung",
        "division_type": "xã",
        "short_codename": "xa_dung",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Keo Lôm",
        "code": "xa_keo_lom",
        "division_type": "xã",
        "short_codename": "keo_lom",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Luân Giới",
        "code": "xa_luan_gioi",
        "division_type": "xã",
        "short_codename": "luan_gioi",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Phình Giàng",
        "code": "xa_phinh_giang",
        "division_type": "xã",
        "short_codename": "phinh_giang",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Pú Hồng",
        "code": "xa_pu_hong",
        "division_type": "xã",
        "short_codename": "pu_hong",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Tìa Dình",
        "code": "xa_tia_dinh",
        "division_type": "xã",
        "short_codename": "tia_dinh",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Xã Háng Lìa",
        "code": "xa_hang_lia",
        "division_type": "xã",
        "short_codename": "hang_lia",
        "parentCode": "huyen_dien_bien_dong"
    },
    {
        "name": "Thị trấn Mường Ảng",
        "code": "thi_tran_muong_ang",
        "division_type": "thị trấn",
        "short_codename": "muong_ang",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Mường Đăng",
        "code": "xa_muong_dang",
        "division_type": "xã",
        "short_codename": "muong_dang",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Ngối Cáy",
        "code": "xa_ngoi_cay",
        "division_type": "xã",
        "short_codename": "ngoi_cay",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Ẳng Tở",
        "code": "xa_ang_to",
        "division_type": "xã",
        "short_codename": "ang_to",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Búng Lao",
        "code": "xa_bung_lao",
        "division_type": "xã",
        "short_codename": "bung_lao",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Xuân Lao",
        "code": "xa_xuan_lao",
        "division_type": "xã",
        "short_codename": "xuan_lao",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Ẳng Nưa",
        "code": "xa_ang_nua",
        "division_type": "xã",
        "short_codename": "ang_nua",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Ẳng Cang",
        "code": "xa_ang_cang",
        "division_type": "xã",
        "short_codename": "ang_cang",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Nặm Lịch",
        "code": "xa_nam_lich",
        "division_type": "xã",
        "short_codename": "nam_lich",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Mường Lạn",
        "code": "xa_muong_lan",
        "division_type": "xã",
        "short_codename": "muong_lan",
        "parentCode": "huyen_muong_ang"
    },
    {
        "name": "Xã Nậm Tin",
        "code": "xa_nam_tin",
        "division_type": "xã",
        "short_codename": "nam_tin",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Pa Tần",
        "code": "xa_pa_tan",
        "division_type": "xã",
        "short_codename": "pa_tan",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Chà Cang",
        "code": "xa_cha_cang",
        "division_type": "xã",
        "short_codename": "cha_cang",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Na Cô Sa",
        "code": "xa_na_co_sa",
        "division_type": "xã",
        "short_codename": "na_co_sa",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Nà Khoa",
        "code": "xa_na_khoa",
        "division_type": "xã",
        "short_codename": "na_khoa",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Nà Hỳ",
        "code": "xa_na_hy",
        "division_type": "xã",
        "short_codename": "na_hy",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Nà Bủng",
        "code": "xa_na_bung",
        "division_type": "xã",
        "short_codename": "na_bung",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Nậm Nhừ",
        "code": "xa_nam_nhu",
        "division_type": "xã",
        "short_codename": "nam_nhu",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Nậm Chua",
        "code": "xa_nam_chua",
        "division_type": "xã",
        "short_codename": "nam_chua",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Nậm Khăn",
        "code": "xa_nam_khan",
        "division_type": "xã",
        "short_codename": "nam_khan",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Chà Tở",
        "code": "xa_cha_to",
        "division_type": "xã",
        "short_codename": "cha_to",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Vàng Đán",
        "code": "xa_vang_dan",
        "division_type": "xã",
        "short_codename": "vang_dan",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Chà Nưa",
        "code": "xa_cha_nua",
        "division_type": "xã",
        "short_codename": "cha_nua",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Phìn Hồ",
        "code": "xa_phin_ho",
        "division_type": "xã",
        "short_codename": "phin_ho",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Xã Si Pa Phìn",
        "code": "xa_si_pa_phin",
        "division_type": "xã",
        "short_codename": "si_pa_phin",
        "parentCode": "huyen_nam_po"
    },
    {
        "name": "Phường Quyết Thắng",
        "code": "phuong_quyet_thang",
        "division_type": "phường",
        "short_codename": "quyet_thang",
        "parentCode": "thanh_pho_lai_chau"
    },
    {
        "name": "Phường Tân Phong",
        "code": "phuong_tan_phong",
        "division_type": "phường",
        "short_codename": "tan_phong",
        "parentCode": "thanh_pho_lai_chau"
    },
    {
        "name": "Phường Quyết Tiến",
        "code": "phuong_quyet_tien",
        "division_type": "phường",
        "short_codename": "quyet_tien",
        "parentCode": "thanh_pho_lai_chau"
    },
    {
        "name": "Phường Đoàn Kết",
        "code": "phuong_doan_ket",
        "division_type": "phường",
        "short_codename": "doan_ket",
        "parentCode": "thanh_pho_lai_chau"
    },
    {
        "name": "Xã Sùng Phài",
        "code": "xa_sung_phai",
        "division_type": "xã",
        "short_codename": "sung_phai",
        "parentCode": "thanh_pho_lai_chau"
    },
    {
        "name": "Phường Đông Phong",
        "code": "phuong_dong_phong",
        "division_type": "phường",
        "short_codename": "dong_phong",
        "parentCode": "thanh_pho_lai_chau"
    },
    {
        "name": "Xã San Thàng",
        "code": "xa_san_thang",
        "division_type": "xã",
        "short_codename": "san_thang",
        "parentCode": "thanh_pho_lai_chau"
    },
    {
        "name": "Thị trấn Tam Đường",
        "code": "thi_tran_tam_duong",
        "division_type": "thị trấn",
        "short_codename": "tam_duong",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Thèn Sin",
        "code": "xa_then_sin",
        "division_type": "xã",
        "short_codename": "then_sin",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Tả Lèng",
        "code": "xa_ta_leng",
        "division_type": "xã",
        "short_codename": "ta_leng",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Giang Ma",
        "code": "xa_giang_ma",
        "division_type": "xã",
        "short_codename": "giang_ma",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Hồ Thầu",
        "code": "xa_ho_thau",
        "division_type": "xã",
        "short_codename": "ho_thau",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Bình Lư",
        "code": "xa_binh_lu",
        "division_type": "xã",
        "short_codename": "binh_lu",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Sơn Bình",
        "code": "xa_son_binh",
        "division_type": "xã",
        "short_codename": "son_binh",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Nùng Nàng",
        "code": "xa_nung_nang",
        "division_type": "xã",
        "short_codename": "nung_nang",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Bản Giang",
        "code": "xa_ban_giang",
        "division_type": "xã",
        "short_codename": "ban_giang",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Bản Hon",
        "code": "xa_ban_hon",
        "division_type": "xã",
        "short_codename": "ban_hon",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Bản Bo",
        "code": "xa_ban_bo",
        "division_type": "xã",
        "short_codename": "ban_bo",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Nà Tăm",
        "code": "xa_na_tam",
        "division_type": "xã",
        "short_codename": "na_tam",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Khun Há",
        "code": "xa_khun_ha",
        "division_type": "xã",
        "short_codename": "khun_ha",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Thị trấn Mường Tè",
        "code": "thi_tran_muong_te",
        "division_type": "thị trấn",
        "short_codename": "muong_te",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Thu Lũm",
        "code": "xa_thu_lum",
        "division_type": "xã",
        "short_codename": "thu_lum",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Ka Lăng",
        "code": "xa_ka_lang",
        "division_type": "xã",
        "short_codename": "ka_lang",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Tá Bạ",
        "code": "xa_ta_ba",
        "division_type": "xã",
        "short_codename": "ta_ba",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Pa ủ",
        "code": "xa_pa_u",
        "division_type": "xã",
        "short_codename": "pa_u",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Mường Tè",
        "code": "xa_muong_te",
        "division_type": "xã",
        "short_codename": "xa_muong_te",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Pa Vệ Sử",
        "code": "xa_pa_ve_su",
        "division_type": "xã",
        "short_codename": "pa_ve_su",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Mù Cả",
        "code": "xa_mu_ca",
        "division_type": "xã",
        "short_codename": "mu_ca",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Bum Tở",
        "code": "xa_bum_to",
        "division_type": "xã",
        "short_codename": "bum_to",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Nậm Khao",
        "code": "xa_nam_khao",
        "division_type": "xã",
        "short_codename": "nam_khao",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Tà Tổng",
        "code": "xa_ta_tong",
        "division_type": "xã",
        "short_codename": "ta_tong",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Bum Nưa",
        "code": "xa_bum_nua",
        "division_type": "xã",
        "short_codename": "bum_nua",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Vàng San",
        "code": "xa_vang_san",
        "division_type": "xã",
        "short_codename": "vang_san",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Xã Kan Hồ",
        "code": "xa_kan_ho",
        "division_type": "xã",
        "short_codename": "kan_ho",
        "parentCode": "huyen_muong_te"
    },
    {
        "name": "Thị trấn Sìn Hồ",
        "code": "thi_tran_sin_ho",
        "division_type": "thị trấn",
        "short_codename": "sin_ho",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Chăn Nưa",
        "code": "xa_chan_nua",
        "division_type": "xã",
        "short_codename": "chan_nua",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Pa Tần",
        "code": "xa_pa_tan",
        "division_type": "xã",
        "short_codename": "pa_tan",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Phìn Hồ",
        "code": "xa_phin_ho",
        "division_type": "xã",
        "short_codename": "phin_ho",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Hồng Thu",
        "code": "xa_hong_thu",
        "division_type": "xã",
        "short_codename": "hong_thu",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Phăng Sô Lin",
        "code": "xa_phang_so_lin",
        "division_type": "xã",
        "short_codename": "phang_so_lin",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Ma Quai",
        "code": "xa_ma_quai",
        "division_type": "xã",
        "short_codename": "ma_quai",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Lùng Thàng",
        "code": "xa_lung_thang",
        "division_type": "xã",
        "short_codename": "lung_thang",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Tả Phìn",
        "code": "xa_ta_phin",
        "division_type": "xã",
        "short_codename": "ta_phin",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Sà Dề Phìn",
        "code": "xa_sa_de_phin",
        "division_type": "xã",
        "short_codename": "sa_de_phin",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Nậm Tăm",
        "code": "xa_nam_tam",
        "division_type": "xã",
        "short_codename": "nam_tam",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Tả Ngảo",
        "code": "xa_ta_ngao",
        "division_type": "xã",
        "short_codename": "ta_ngao",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Pu Sam Cáp",
        "code": "xa_pu_sam_cap",
        "division_type": "xã",
        "short_codename": "pu_sam_cap",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Nậm Cha",
        "code": "xa_nam_cha",
        "division_type": "xã",
        "short_codename": "nam_cha",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Pa Khoá",
        "code": "xa_pa_khoa",
        "division_type": "xã",
        "short_codename": "pa_khoa",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Làng Mô",
        "code": "xa_lang_mo",
        "division_type": "xã",
        "short_codename": "lang_mo",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Noong Hẻo",
        "code": "xa_noong_heo",
        "division_type": "xã",
        "short_codename": "noong_heo",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Nậm Mạ",
        "code": "xa_nam_ma",
        "division_type": "xã",
        "short_codename": "nam_ma",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Căn Co",
        "code": "xa_can_co",
        "division_type": "xã",
        "short_codename": "can_co",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Tủa Sín Chải",
        "code": "xa_tua_sin_chai",
        "division_type": "xã",
        "short_codename": "tua_sin_chai",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Nậm Cuổi",
        "code": "xa_nam_cuoi",
        "division_type": "xã",
        "short_codename": "nam_cuoi",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Nậm Hăn",
        "code": "xa_nam_han",
        "division_type": "xã",
        "short_codename": "nam_han",
        "parentCode": "huyen_sin_ho"
    },
    {
        "name": "Xã Lả Nhì Thàng",
        "code": "xa_la_nhi_thang",
        "division_type": "xã",
        "short_codename": "la_nhi_thang",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Huổi Luông",
        "code": "xa_huoi_luong",
        "division_type": "xã",
        "short_codename": "huoi_luong",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Thị trấn Phong Thổ",
        "code": "thi_tran_phong_tho",
        "division_type": "thị trấn",
        "short_codename": "phong_tho",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Sì Lở Lầu",
        "code": "xa_si_lo_lau",
        "division_type": "xã",
        "short_codename": "si_lo_lau",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Mồ Sì San",
        "code": "xa_mo_si_san",
        "division_type": "xã",
        "short_codename": "mo_si_san",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Pa Vây Sử",
        "code": "xa_pa_vay_su",
        "division_type": "xã",
        "short_codename": "pa_vay_su",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Vàng Ma Chải",
        "code": "xa_vang_ma_chai",
        "division_type": "xã",
        "short_codename": "vang_ma_chai",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Tông Qua Lìn",
        "code": "xa_tong_qua_lin",
        "division_type": "xã",
        "short_codename": "tong_qua_lin",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Mù Sang",
        "code": "xa_mu_sang",
        "division_type": "xã",
        "short_codename": "mu_sang",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Dào San",
        "code": "xa_dao_san",
        "division_type": "xã",
        "short_codename": "dao_san",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Ma Ly Pho",
        "code": "xa_ma_ly_pho",
        "division_type": "xã",
        "short_codename": "ma_ly_pho",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Bản Lang",
        "code": "xa_ban_lang",
        "division_type": "xã",
        "short_codename": "ban_lang",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Hoang Thèn",
        "code": "xa_hoang_then",
        "division_type": "xã",
        "short_codename": "hoang_then",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Khổng Lào",
        "code": "xa_khong_lao",
        "division_type": "xã",
        "short_codename": "khong_lao",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Nậm Xe",
        "code": "xa_nam_xe",
        "division_type": "xã",
        "short_codename": "nam_xe",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Mường So",
        "code": "xa_muong_so",
        "division_type": "xã",
        "short_codename": "muong_so",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Xã Sin Suối Hồ",
        "code": "xa_sin_suoi_ho",
        "division_type": "xã",
        "short_codename": "sin_suoi_ho",
        "parentCode": "huyen_phong_tho"
    },
    {
        "name": "Thị trấn Than Uyên",
        "code": "thi_tran_than_uyen",
        "division_type": "thị trấn",
        "short_codename": "than_uyen",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Phúc Than",
        "code": "xa_phuc_than",
        "division_type": "xã",
        "short_codename": "phuc_than",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Mường Than",
        "code": "xa_muong_than",
        "division_type": "xã",
        "short_codename": "muong_than",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Mường Mít",
        "code": "xa_muong_mit",
        "division_type": "xã",
        "short_codename": "muong_mit",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Pha Mu",
        "code": "xa_pha_mu",
        "division_type": "xã",
        "short_codename": "pha_mu",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Mường Cang",
        "code": "xa_muong_cang",
        "division_type": "xã",
        "short_codename": "muong_cang",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Hua Nà",
        "code": "xa_hua_na",
        "division_type": "xã",
        "short_codename": "hua_na",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Tà Hừa",
        "code": "xa_ta_hua",
        "division_type": "xã",
        "short_codename": "ta_hua",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Mường Kim",
        "code": "xa_muong_kim",
        "division_type": "xã",
        "short_codename": "muong_kim",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Tà Mung",
        "code": "xa_ta_mung",
        "division_type": "xã",
        "short_codename": "ta_mung",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Tà Gia",
        "code": "xa_ta_gia",
        "division_type": "xã",
        "short_codename": "ta_gia",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Xã Khoen On",
        "code": "xa_khoen_on",
        "division_type": "xã",
        "short_codename": "khoen_on",
        "parentCode": "huyen_than_uyen"
    },
    {
        "name": "Thị trấn Tân Uyên",
        "code": "thi_tran_tan_uyen",
        "division_type": "thị trấn",
        "short_codename": "tan_uyen",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Xã Mường Khoa",
        "code": "xa_muong_khoa",
        "division_type": "xã",
        "short_codename": "muong_khoa",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Xã Phúc Khoa",
        "code": "xa_phuc_khoa",
        "division_type": "xã",
        "short_codename": "phuc_khoa",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Xã Thân Thuộc",
        "code": "xa_than_thuoc",
        "division_type": "xã",
        "short_codename": "than_thuoc",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Xã Trung Đồng",
        "code": "xa_trung_dong",
        "division_type": "xã",
        "short_codename": "trung_dong",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Xã Hố Mít",
        "code": "xa_ho_mit",
        "division_type": "xã",
        "short_codename": "ho_mit",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Xã Nậm Cần",
        "code": "xa_nam_can",
        "division_type": "xã",
        "short_codename": "nam_can",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Xã Nậm Sỏ",
        "code": "xa_nam_so",
        "division_type": "xã",
        "short_codename": "nam_so",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Xã Pắc Ta",
        "code": "xa_pac_ta",
        "division_type": "xã",
        "short_codename": "pac_ta",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Xã Tà Mít",
        "code": "xa_ta_mit",
        "division_type": "xã",
        "short_codename": "ta_mit",
        "parentCode": "huyen_tan_uyen"
    },
    {
        "name": "Thị trấn Nậm Nhùn",
        "code": "thi_tran_nam_nhun",
        "division_type": "thị trấn",
        "short_codename": "nam_nhun",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Hua Bun",
        "code": "xa_hua_bun",
        "division_type": "xã",
        "short_codename": "hua_bun",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Mường Mô",
        "code": "xa_muong_mo",
        "division_type": "xã",
        "short_codename": "muong_mo",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Nậm Chà",
        "code": "xa_nam_cha",
        "division_type": "xã",
        "short_codename": "nam_cha",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Nậm Manh",
        "code": "xa_nam_manh",
        "division_type": "xã",
        "short_codename": "nam_manh",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Nậm Hàng",
        "code": "xa_nam_hang",
        "division_type": "xã",
        "short_codename": "nam_hang",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Lê Lợi",
        "code": "xa_le_loi",
        "division_type": "xã",
        "short_codename": "le_loi",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Pú Đao",
        "code": "xa_pu_dao",
        "division_type": "xã",
        "short_codename": "pu_dao",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Nậm Pì",
        "code": "xa_nam_pi",
        "division_type": "xã",
        "short_codename": "nam_pi",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Nậm Ban",
        "code": "xa_nam_ban",
        "division_type": "xã",
        "short_codename": "nam_ban",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Xã Trung Chải",
        "code": "xa_trung_chai",
        "division_type": "xã",
        "short_codename": "trung_chai",
        "parentCode": "huyen_nam_nhun"
    },
    {
        "name": "Phường Chiềng Lề",
        "code": "phuong_chieng_le",
        "division_type": "phường",
        "short_codename": "chieng_le",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Phường Tô Hiệu",
        "code": "phuong_to_hieu",
        "division_type": "phường",
        "short_codename": "to_hieu",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Phường Quyết Thắng",
        "code": "phuong_quyet_thang",
        "division_type": "phường",
        "short_codename": "quyet_thang",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Phường Quyết Tâm",
        "code": "phuong_quyet_tam",
        "division_type": "phường",
        "short_codename": "quyet_tam",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Xã Chiềng Cọ",
        "code": "xa_chieng_co",
        "division_type": "xã",
        "short_codename": "chieng_co",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Xã Chiềng Đen",
        "code": "xa_chieng_den",
        "division_type": "xã",
        "short_codename": "chieng_den",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Xã Chiềng Xôm",
        "code": "xa_chieng_xom",
        "division_type": "xã",
        "short_codename": "chieng_xom",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Phường Chiềng An",
        "code": "phuong_chieng_an",
        "division_type": "phường",
        "short_codename": "chieng_an",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Phường Chiềng Cơi",
        "code": "phuong_chieng_coi",
        "division_type": "phường",
        "short_codename": "chieng_coi",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Xã Chiềng Ngần",
        "code": "xa_chieng_ngan",
        "division_type": "xã",
        "short_codename": "chieng_ngan",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Xã Hua La",
        "code": "xa_hua_la",
        "division_type": "xã",
        "short_codename": "hua_la",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Phường Chiềng Sinh",
        "code": "phuong_chieng_sinh",
        "division_type": "phường",
        "short_codename": "chieng_sinh",
        "parentCode": "thanh_pho_son_la"
    },
    {
        "name": "Xã Mường Chiên",
        "code": "xa_muong_chien",
        "division_type": "xã",
        "short_codename": "muong_chien",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Cà Nàng",
        "code": "xa_ca_nang",
        "division_type": "xã",
        "short_codename": "ca_nang",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Chiềng Khay",
        "code": "xa_chieng_khay",
        "division_type": "xã",
        "short_codename": "chieng_khay",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Mường Giôn",
        "code": "xa_muong_gion",
        "division_type": "xã",
        "short_codename": "muong_gion",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Pá Ma Pha Khinh",
        "code": "xa_pa_ma_pha_khinh",
        "division_type": "xã",
        "short_codename": "pa_ma_pha_khinh",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Chiềng Ơn",
        "code": "xa_chieng_on",
        "division_type": "xã",
        "short_codename": "chieng_on",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Mường Giàng",
        "code": "xa_muong_giang",
        "division_type": "xã",
        "short_codename": "muong_giang",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Chiềng Bằng",
        "code": "xa_chieng_bang",
        "division_type": "xã",
        "short_codename": "chieng_bang",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Mường Sại",
        "code": "xa_muong_sai",
        "division_type": "xã",
        "short_codename": "muong_sai",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Nậm ét",
        "code": "xa_nam_et",
        "division_type": "xã",
        "short_codename": "nam_et",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Xã Chiềng Khoang",
        "code": "xa_chieng_khoang",
        "division_type": "xã",
        "short_codename": "chieng_khoang",
        "parentCode": "huyen_quynh_nhai"
    },
    {
        "name": "Thị trấn Thuận Châu",
        "code": "thi_tran_thuan_chau",
        "division_type": "thị trấn",
        "short_codename": "thuan_chau",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Phổng Lái",
        "code": "xa_phong_lai",
        "division_type": "xã",
        "short_codename": "phong_lai",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Mường é",
        "code": "xa_muong_e",
        "division_type": "xã",
        "short_codename": "muong_e",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Chiềng Pha",
        "code": "xa_chieng_pha",
        "division_type": "xã",
        "short_codename": "chieng_pha",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Chiềng La",
        "code": "xa_chieng_la",
        "division_type": "xã",
        "short_codename": "chieng_la",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Chiềng Ngàm",
        "code": "xa_chieng_ngam",
        "division_type": "xã",
        "short_codename": "chieng_ngam",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Liệp Tè",
        "code": "xa_liep_te",
        "division_type": "xã",
        "short_codename": "liep_te",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã é Tòng",
        "code": "xa_e_tong",
        "division_type": "xã",
        "short_codename": "e_tong",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Phổng Lập",
        "code": "xa_phong_lap",
        "division_type": "xã",
        "short_codename": "phong_lap",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Phổng Lăng",
        "code": "xa_phong_lang",
        "division_type": "xã",
        "short_codename": "phong_lang",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Chiềng Ly",
        "code": "xa_chieng_ly",
        "division_type": "xã",
        "short_codename": "chieng_ly",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Noong Lay",
        "code": "xa_noong_lay",
        "division_type": "xã",
        "short_codename": "noong_lay",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Mường Khiêng",
        "code": "xa_muong_khieng",
        "division_type": "xã",
        "short_codename": "muong_khieng",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Mường Bám",
        "code": "xa_muong_bam",
        "division_type": "xã",
        "short_codename": "muong_bam",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Long Hẹ",
        "code": "xa_long_he",
        "division_type": "xã",
        "short_codename": "long_he",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Chiềng Bôm",
        "code": "xa_chieng_bom",
        "division_type": "xã",
        "short_codename": "chieng_bom",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Thôm Mòn",
        "code": "xa_thom_mon",
        "division_type": "xã",
        "short_codename": "thom_mon",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Tông Lạnh",
        "code": "xa_tong_lanh",
        "division_type": "xã",
        "short_codename": "tong_lanh",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Tông Cọ",
        "code": "xa_tong_co",
        "division_type": "xã",
        "short_codename": "tong_co",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Bó Mười",
        "code": "xa_bo_muoi",
        "division_type": "xã",
        "short_codename": "bo_muoi",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Co Mạ",
        "code": "xa_co_ma",
        "division_type": "xã",
        "short_codename": "co_ma",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Púng Tra",
        "code": "xa_pung_tra",
        "division_type": "xã",
        "short_codename": "pung_tra",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Chiềng Pấc",
        "code": "xa_chieng_pac",
        "division_type": "xã",
        "short_codename": "chieng_pac",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Nậm Lầu",
        "code": "xa_nam_lau",
        "division_type": "xã",
        "short_codename": "nam_lau",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Bon Phặng",
        "code": "xa_bon_phang",
        "division_type": "xã",
        "short_codename": "bon_phang",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Co Tòng",
        "code": "xa_co_tong",
        "division_type": "xã",
        "short_codename": "co_tong",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Muổi Nọi",
        "code": "xa_muoi_noi",
        "division_type": "xã",
        "short_codename": "muoi_noi",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Pá Lông",
        "code": "xa_pa_long",
        "division_type": "xã",
        "short_codename": "pa_long",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Xã Bản Lầm",
        "code": "xa_ban_lam",
        "division_type": "xã",
        "short_codename": "ban_lam",
        "parentCode": "huyen_thuan_chau"
    },
    {
        "name": "Thị trấn Ít Ong",
        "code": "thi_tran_it_ong",
        "division_type": "thị trấn",
        "short_codename": "it_ong",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Nậm Giôn",
        "code": "xa_nam_gion",
        "division_type": "xã",
        "short_codename": "nam_gion",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Chiềng Lao",
        "code": "xa_chieng_lao",
        "division_type": "xã",
        "short_codename": "chieng_lao",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Hua Trai",
        "code": "xa_hua_trai",
        "division_type": "xã",
        "short_codename": "hua_trai",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Ngọc Chiến",
        "code": "xa_ngoc_chien",
        "division_type": "xã",
        "short_codename": "ngoc_chien",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Mường Trai",
        "code": "xa_muong_trai",
        "division_type": "xã",
        "short_codename": "muong_trai",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Nậm Păm",
        "code": "xa_nam_pam",
        "division_type": "xã",
        "short_codename": "nam_pam",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Chiềng Muôn",
        "code": "xa_chieng_muon",
        "division_type": "xã",
        "short_codename": "chieng_muon",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Chiềng Ân",
        "code": "xa_chieng_an",
        "division_type": "xã",
        "short_codename": "chieng_an",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Pi Toong",
        "code": "xa_pi_toong",
        "division_type": "xã",
        "short_codename": "pi_toong",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Chiềng Công",
        "code": "xa_chieng_cong",
        "division_type": "xã",
        "short_codename": "chieng_cong",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Tạ Bú",
        "code": "xa_ta_bu",
        "division_type": "xã",
        "short_codename": "ta_bu",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Chiềng San",
        "code": "xa_chieng_san",
        "division_type": "xã",
        "short_codename": "chieng_san",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Mường Bú",
        "code": "xa_muong_bu",
        "division_type": "xã",
        "short_codename": "muong_bu",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Chiềng Hoa",
        "code": "xa_chieng_hoa",
        "division_type": "xã",
        "short_codename": "chieng_hoa",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Xã Mường Chùm",
        "code": "xa_muong_chum",
        "division_type": "xã",
        "short_codename": "muong_chum",
        "parentCode": "huyen_muong_la"
    },
    {
        "name": "Thị trấn Bắc Yên",
        "code": "thi_tran_bac_yen",
        "division_type": "thị trấn",
        "short_codename": "bac_yen",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Phiêng Ban",
        "code": "xa_phieng_ban",
        "division_type": "xã",
        "short_codename": "phieng_ban",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Hang Chú",
        "code": "xa_hang_chu",
        "division_type": "xã",
        "short_codename": "hang_chu",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Xím Vàng",
        "code": "xa_xim_vang",
        "division_type": "xã",
        "short_codename": "xim_vang",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Tà Xùa",
        "code": "xa_ta_xua",
        "division_type": "xã",
        "short_codename": "ta_xua",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Háng Đồng",
        "code": "xa_hang_dong",
        "division_type": "xã",
        "short_codename": "hang_dong",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Pắc Ngà",
        "code": "xa_pac_nga",
        "division_type": "xã",
        "short_codename": "pac_nga",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Làng Chếu",
        "code": "xa_lang_cheu",
        "division_type": "xã",
        "short_codename": "lang_cheu",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Chim Vàn",
        "code": "xa_chim_van",
        "division_type": "xã",
        "short_codename": "chim_van",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Mường Khoa",
        "code": "xa_muong_khoa",
        "division_type": "xã",
        "short_codename": "muong_khoa",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Song Pe",
        "code": "xa_song_pe",
        "division_type": "xã",
        "short_codename": "song_pe",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Hồng Ngài",
        "code": "xa_hong_ngai",
        "division_type": "xã",
        "short_codename": "hong_ngai",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Tạ Khoa",
        "code": "xa_ta_khoa",
        "division_type": "xã",
        "short_codename": "ta_khoa",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Hua Nhàn",
        "code": "xa_hua_nhan",
        "division_type": "xã",
        "short_codename": "hua_nhan",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Phiêng Côn",
        "code": "xa_phieng_con",
        "division_type": "xã",
        "short_codename": "phieng_con",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Xã Chiềng Sại",
        "code": "xa_chieng_sai",
        "division_type": "xã",
        "short_codename": "chieng_sai",
        "parentCode": "huyen_bac_yen"
    },
    {
        "name": "Thị trấn Phù Yên",
        "code": "thi_tran_phu_yen",
        "division_type": "thị trấn",
        "short_codename": "phu_yen",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Suối Tọ",
        "code": "xa_suoi_to",
        "division_type": "xã",
        "short_codename": "suoi_to",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Mường Thải",
        "code": "xa_muong_thai",
        "division_type": "xã",
        "short_codename": "muong_thai",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Mường Cơi",
        "code": "xa_muong_coi",
        "division_type": "xã",
        "short_codename": "muong_coi",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Quang Huy",
        "code": "xa_quang_huy",
        "division_type": "xã",
        "short_codename": "quang_huy",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Huy Bắc",
        "code": "xa_huy_bac",
        "division_type": "xã",
        "short_codename": "huy_bac",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Huy Thượng",
        "code": "xa_huy_thuong",
        "division_type": "xã",
        "short_codename": "huy_thuong",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Tân Lang",
        "code": "xa_tan_lang",
        "division_type": "xã",
        "short_codename": "tan_lang",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Gia Phù",
        "code": "xa_gia_phu",
        "division_type": "xã",
        "short_codename": "gia_phu",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Tường Phù",
        "code": "xa_tuong_phu",
        "division_type": "xã",
        "short_codename": "tuong_phu",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Huy Hạ",
        "code": "xa_huy_ha",
        "division_type": "xã",
        "short_codename": "huy_ha",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Huy Tân",
        "code": "xa_huy_tan",
        "division_type": "xã",
        "short_codename": "huy_tan",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Mường Lang",
        "code": "xa_muong_lang",
        "division_type": "xã",
        "short_codename": "muong_lang",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Suối Bau",
        "code": "xa_suoi_bau",
        "division_type": "xã",
        "short_codename": "suoi_bau",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Huy Tường",
        "code": "xa_huy_tuong",
        "division_type": "xã",
        "short_codename": "huy_tuong",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Mường Do",
        "code": "xa_muong_do",
        "division_type": "xã",
        "short_codename": "muong_do",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Sập Xa",
        "code": "xa_sap_xa",
        "division_type": "xã",
        "short_codename": "sap_xa",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Tường Thượng",
        "code": "xa_tuong_thuong",
        "division_type": "xã",
        "short_codename": "tuong_thuong",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Tường Tiến",
        "code": "xa_tuong_tien",
        "division_type": "xã",
        "short_codename": "tuong_tien",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Tường Phong",
        "code": "xa_tuong_phong",
        "division_type": "xã",
        "short_codename": "tuong_phong",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Tường Hạ",
        "code": "xa_tuong_ha",
        "division_type": "xã",
        "short_codename": "tuong_ha",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Kim Bon",
        "code": "xa_kim_bon",
        "division_type": "xã",
        "short_codename": "kim_bon",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Mường Bang",
        "code": "xa_muong_bang",
        "division_type": "xã",
        "short_codename": "muong_bang",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Đá Đỏ",
        "code": "xa_da_do",
        "division_type": "xã",
        "short_codename": "da_do",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Tân Phong",
        "code": "xa_tan_phong",
        "division_type": "xã",
        "short_codename": "tan_phong",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Nam Phong",
        "code": "xa_nam_phong",
        "division_type": "xã",
        "short_codename": "nam_phong",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Xã Bắc Phong",
        "code": "xa_bac_phong",
        "division_type": "xã",
        "short_codename": "bac_phong",
        "parentCode": "huyen_phu_yen"
    },
    {
        "name": "Thị trấn Mộc Châu",
        "code": "thi_tran_moc_chau",
        "division_type": "thị trấn",
        "short_codename": "moc_chau",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Thị trấn NT Mộc Châu",
        "code": "thi_tran_nt_moc_chau",
        "division_type": "thị trấn",
        "short_codename": "nt_moc_chau",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Chiềng Sơn",
        "code": "xa_chieng_son",
        "division_type": "xã",
        "short_codename": "chieng_son",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Tân Hợp",
        "code": "xa_tan_hop",
        "division_type": "xã",
        "short_codename": "tan_hop",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Qui Hướng",
        "code": "xa_qui_huong",
        "division_type": "xã",
        "short_codename": "qui_huong",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Nà Mường",
        "code": "xa_na_muong",
        "division_type": "xã",
        "short_codename": "na_muong",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Tà Lai",
        "code": "xa_ta_lai",
        "division_type": "xã",
        "short_codename": "ta_lai",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Chiềng Hắc",
        "code": "xa_chieng_hac",
        "division_type": "xã",
        "short_codename": "chieng_hac",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Hua Păng",
        "code": "xa_hua_pang",
        "division_type": "xã",
        "short_codename": "hua_pang",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Chiềng Khừa",
        "code": "xa_chieng_khua",
        "division_type": "xã",
        "short_codename": "chieng_khua",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Mường Sang",
        "code": "xa_muong_sang",
        "division_type": "xã",
        "short_codename": "muong_sang",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Đông Sang",
        "code": "xa_dong_sang",
        "division_type": "xã",
        "short_codename": "dong_sang",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Phiêng Luông",
        "code": "xa_phieng_luong",
        "division_type": "xã",
        "short_codename": "phieng_luong",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Xã Lóng Sập",
        "code": "xa_long_sap",
        "division_type": "xã",
        "short_codename": "long_sap",
        "parentCode": "huyen_moc_chau"
    },
    {
        "name": "Thị trấn Yên Châu",
        "code": "thi_tran_yen_chau",
        "division_type": "thị trấn",
        "short_codename": "yen_chau",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Chiềng Đông",
        "code": "xa_chieng_dong",
        "division_type": "xã",
        "short_codename": "chieng_dong",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Sập Vạt",
        "code": "xa_sap_vat",
        "division_type": "xã",
        "short_codename": "sap_vat",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Chiềng Sàng",
        "code": "xa_chieng_sang",
        "division_type": "xã",
        "short_codename": "chieng_sang",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Chiềng Pằn",
        "code": "xa_chieng_pan",
        "division_type": "xã",
        "short_codename": "chieng_pan",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Viêng Lán",
        "code": "xa_vieng_lan",
        "division_type": "xã",
        "short_codename": "vieng_lan",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Chiềng Hặc",
        "code": "xa_chieng_hac",
        "division_type": "xã",
        "short_codename": "chieng_hac",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Mường Lựm",
        "code": "xa_muong_lum",
        "division_type": "xã",
        "short_codename": "muong_lum",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Chiềng On",
        "code": "xa_chieng_on",
        "division_type": "xã",
        "short_codename": "chieng_on",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Yên Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Chiềng Khoi",
        "code": "xa_chieng_khoi",
        "division_type": "xã",
        "short_codename": "chieng_khoi",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Tú Nang",
        "code": "xa_tu_nang",
        "division_type": "xã",
        "short_codename": "tu_nang",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Lóng Phiêng",
        "code": "xa_long_phieng",
        "division_type": "xã",
        "short_codename": "long_phieng",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Phiêng Khoài",
        "code": "xa_phieng_khoai",
        "division_type": "xã",
        "short_codename": "phieng_khoai",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Xã Chiềng Tương",
        "code": "xa_chieng_tuong",
        "division_type": "xã",
        "short_codename": "chieng_tuong",
        "parentCode": "huyen_yen_chau"
    },
    {
        "name": "Thị trấn Hát Lót",
        "code": "thi_tran_hat_lot",
        "division_type": "thị trấn",
        "short_codename": "hat_lot",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Sung",
        "code": "xa_chieng_sung",
        "division_type": "xã",
        "short_codename": "chieng_sung",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Mường Bằng",
        "code": "xa_muong_bang",
        "division_type": "xã",
        "short_codename": "muong_bang",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Chăn",
        "code": "xa_chieng_chan",
        "division_type": "xã",
        "short_codename": "chieng_chan",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Mương Chanh",
        "code": "xa_muong_chanh",
        "division_type": "xã",
        "short_codename": "muong_chanh",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Ban",
        "code": "xa_chieng_ban",
        "division_type": "xã",
        "short_codename": "chieng_ban",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Mung",
        "code": "xa_chieng_mung",
        "division_type": "xã",
        "short_codename": "chieng_mung",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Mường Bon",
        "code": "xa_muong_bon",
        "division_type": "xã",
        "short_codename": "muong_bon",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Chung",
        "code": "xa_chieng_chung",
        "division_type": "xã",
        "short_codename": "chieng_chung",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Mai",
        "code": "xa_chieng_mai",
        "division_type": "xã",
        "short_codename": "chieng_mai",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Hát Lót",
        "code": "xa_hat_lot",
        "division_type": "xã",
        "short_codename": "xa_hat_lot",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Nà Pó",
        "code": "xa_na_po",
        "division_type": "xã",
        "short_codename": "na_po",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Cò Nòi",
        "code": "xa_co_noi",
        "division_type": "xã",
        "short_codename": "co_noi",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Nơi",
        "code": "xa_chieng_noi",
        "division_type": "xã",
        "short_codename": "chieng_noi",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Phiêng Cằm",
        "code": "xa_phieng_cam",
        "division_type": "xã",
        "short_codename": "phieng_cam",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Dong",
        "code": "xa_chieng_dong",
        "division_type": "xã",
        "short_codename": "chieng_dong",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Kheo",
        "code": "xa_chieng_kheo",
        "division_type": "xã",
        "short_codename": "chieng_kheo",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Ve",
        "code": "xa_chieng_ve",
        "division_type": "xã",
        "short_codename": "chieng_ve",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Chiềng Lương",
        "code": "xa_chieng_luong",
        "division_type": "xã",
        "short_codename": "chieng_luong",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Phiêng Pằn",
        "code": "xa_phieng_pan",
        "division_type": "xã",
        "short_codename": "phieng_pan",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Nà Ơt",
        "code": "xa_na_ot",
        "division_type": "xã",
        "short_codename": "na_ot",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Xã Tà Hộc",
        "code": "xa_ta_hoc",
        "division_type": "xã",
        "short_codename": "ta_hoc",
        "parentCode": "huyen_mai_son"
    },
    {
        "name": "Thị trấn Sông Mã",
        "code": "thi_tran_song_ma",
        "division_type": "thị trấn",
        "short_codename": "song_ma",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Bó Sinh",
        "code": "xa_bo_sinh",
        "division_type": "xã",
        "short_codename": "bo_sinh",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Pú Pẩu",
        "code": "xa_pu_pau",
        "division_type": "xã",
        "short_codename": "pu_pau",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Chiềng Phung",
        "code": "xa_chieng_phung",
        "division_type": "xã",
        "short_codename": "chieng_phung",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Chiềng En",
        "code": "xa_chieng_en",
        "division_type": "xã",
        "short_codename": "chieng_en",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Mường Lầm",
        "code": "xa_muong_lam",
        "division_type": "xã",
        "short_codename": "muong_lam",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Nậm Ty",
        "code": "xa_nam_ty",
        "division_type": "xã",
        "short_codename": "nam_ty",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Đứa Mòn",
        "code": "xa_dua_mon",
        "division_type": "xã",
        "short_codename": "dua_mon",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Yên Hưng",
        "code": "xa_yen_hung",
        "division_type": "xã",
        "short_codename": "yen_hung",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Chiềng Sơ",
        "code": "xa_chieng_so",
        "division_type": "xã",
        "short_codename": "chieng_so",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Nà Nghịu",
        "code": "xa_na_nghiu",
        "division_type": "xã",
        "short_codename": "na_nghiu",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Nậm Mằn",
        "code": "xa_nam_man",
        "division_type": "xã",
        "short_codename": "nam_man",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Chiềng Khoong",
        "code": "xa_chieng_khoong",
        "division_type": "xã",
        "short_codename": "chieng_khoong",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Chiềng Cang",
        "code": "xa_chieng_cang",
        "division_type": "xã",
        "short_codename": "chieng_cang",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Huổi Một",
        "code": "xa_huoi_mot",
        "division_type": "xã",
        "short_codename": "huoi_mot",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Mường Sai",
        "code": "xa_muong_sai",
        "division_type": "xã",
        "short_codename": "muong_sai",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Mường Cai",
        "code": "xa_muong_cai",
        "division_type": "xã",
        "short_codename": "muong_cai",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Mường Hung",
        "code": "xa_muong_hung",
        "division_type": "xã",
        "short_codename": "muong_hung",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Chiềng Khương",
        "code": "xa_chieng_khuong",
        "division_type": "xã",
        "short_codename": "chieng_khuong",
        "parentCode": "huyen_song_ma"
    },
    {
        "name": "Xã Sam Kha",
        "code": "xa_sam_kha",
        "division_type": "xã",
        "short_codename": "sam_kha",
        "parentCode": "huyen_sop_cop"
    },
    {
        "name": "Xã Púng Bánh",
        "code": "xa_pung_banh",
        "division_type": "xã",
        "short_codename": "pung_banh",
        "parentCode": "huyen_sop_cop"
    },
    {
        "name": "Xã Sốp Cộp",
        "code": "xa_sop_cop",
        "division_type": "xã",
        "short_codename": "sop_cop",
        "parentCode": "huyen_sop_cop"
    },
    {
        "name": "Xã Dồm Cang",
        "code": "xa_dom_cang",
        "division_type": "xã",
        "short_codename": "dom_cang",
        "parentCode": "huyen_sop_cop"
    },
    {
        "name": "Xã Nậm Lạnh",
        "code": "xa_nam_lanh",
        "division_type": "xã",
        "short_codename": "nam_lanh",
        "parentCode": "huyen_sop_cop"
    },
    {
        "name": "Xã Mường Lèo",
        "code": "xa_muong_leo",
        "division_type": "xã",
        "short_codename": "muong_leo",
        "parentCode": "huyen_sop_cop"
    },
    {
        "name": "Xã Mường Và",
        "code": "xa_muong_va",
        "division_type": "xã",
        "short_codename": "muong_va",
        "parentCode": "huyen_sop_cop"
    },
    {
        "name": "Xã Mường Lạn",
        "code": "xa_muong_lan",
        "division_type": "xã",
        "short_codename": "muong_lan",
        "parentCode": "huyen_sop_cop"
    },
    {
        "name": "Xã Suối Bàng",
        "code": "xa_suoi_bang",
        "division_type": "xã",
        "short_codename": "suoi_bang",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Song Khủa",
        "code": "xa_song_khua",
        "division_type": "xã",
        "short_codename": "song_khua",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Liên Hoà",
        "code": "xa_lien_hoa",
        "division_type": "xã",
        "short_codename": "lien_hoa",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Tô Múa",
        "code": "xa_to_mua",
        "division_type": "xã",
        "short_codename": "to_mua",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Mường Tè",
        "code": "xa_muong_te",
        "division_type": "xã",
        "short_codename": "muong_te",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Chiềng Khoa",
        "code": "xa_chieng_khoa",
        "division_type": "xã",
        "short_codename": "chieng_khoa",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Mường Men",
        "code": "xa_muong_men",
        "division_type": "xã",
        "short_codename": "muong_men",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Quang Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Vân Hồ",
        "code": "xa_van_ho",
        "division_type": "xã",
        "short_codename": "van_ho",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Lóng Luông",
        "code": "xa_long_luong",
        "division_type": "xã",
        "short_codename": "long_luong",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Chiềng Yên",
        "code": "xa_chieng_yen",
        "division_type": "xã",
        "short_codename": "chieng_yen",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Chiềng Xuân",
        "code": "xa_chieng_xuan",
        "division_type": "xã",
        "short_codename": "chieng_xuan",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Xuân Nha",
        "code": "xa_xuan_nha",
        "division_type": "xã",
        "short_codename": "xuan_nha",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Xã Tân Xuân",
        "code": "xa_tan_xuan",
        "division_type": "xã",
        "short_codename": "tan_xuan",
        "parentCode": "huyen_van_ho"
    },
    {
        "name": "Phường Yên Thịnh",
        "code": "phuong_yen_thinh",
        "division_type": "phường",
        "short_codename": "yen_thinh",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Phường Yên Ninh",
        "code": "phuong_yen_ninh",
        "division_type": "phường",
        "short_codename": "yen_ninh",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Phường Minh Tân",
        "code": "phuong_minh_tan",
        "division_type": "phường",
        "short_codename": "minh_tan",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Phường Nguyễn Thái Học",
        "code": "phuong_nguyen_thai_hoc",
        "division_type": "phường",
        "short_codename": "nguyen_thai_hoc",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Phường Đồng Tâm",
        "code": "phuong_dong_tam",
        "division_type": "phường",
        "short_codename": "dong_tam",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Phường Nguyễn Phúc",
        "code": "phuong_nguyen_phuc",
        "division_type": "phường",
        "short_codename": "nguyen_phuc",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Phường Hồng Hà",
        "code": "phuong_hong_ha",
        "division_type": "phường",
        "short_codename": "hong_ha",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Xã Minh Bảo",
        "code": "xa_minh_bao",
        "division_type": "xã",
        "short_codename": "minh_bao",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Phường Nam Cường",
        "code": "phuong_nam_cuong",
        "division_type": "phường",
        "short_codename": "nam_cuong",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Xã Tuy Lộc",
        "code": "xa_tuy_loc",
        "division_type": "xã",
        "short_codename": "tuy_loc",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Xã Tân Thịnh",
        "code": "xa_tan_thinh",
        "division_type": "xã",
        "short_codename": "tan_thinh",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Xã Âu Lâu",
        "code": "xa_au_lau",
        "division_type": "xã",
        "short_codename": "au_lau",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Xã Giới Phiên",
        "code": "xa_gioi_phien",
        "division_type": "xã",
        "short_codename": "gioi_phien",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Phường Hợp Minh",
        "code": "phuong_hop_minh",
        "division_type": "phường",
        "short_codename": "hop_minh",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Xã Văn Phú",
        "code": "xa_van_phu",
        "division_type": "xã",
        "short_codename": "van_phu",
        "parentCode": "thanh_pho_yen_bai"
    },
    {
        "name": "Phường Pú Trạng",
        "code": "phuong_pu_trang",
        "division_type": "phường",
        "short_codename": "pu_trang",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Phường Trung Tâm",
        "code": "phuong_trung_tam",
        "division_type": "phường",
        "short_codename": "trung_tam",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Phường Tân An",
        "code": "phuong_tan_an",
        "division_type": "phường",
        "short_codename": "tan_an",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Phường Cầu Thia",
        "code": "phuong_cau_thia",
        "division_type": "phường",
        "short_codename": "cau_thia",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Nghĩa Lợi",
        "code": "xa_nghia_loi",
        "division_type": "xã",
        "short_codename": "nghia_loi",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Nghĩa Phúc",
        "code": "xa_nghia_phuc",
        "division_type": "xã",
        "short_codename": "nghia_phuc",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Nghĩa An",
        "code": "xa_nghia_an",
        "division_type": "xã",
        "short_codename": "nghia_an",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Nghĩa Lộ",
        "code": "xa_nghia_lo",
        "division_type": "xã",
        "short_codename": "nghia_lo",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Sơn A",
        "code": "xa_son_a",
        "division_type": "xã",
        "short_codename": "son_a",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Phù Nham",
        "code": "xa_phu_nham",
        "division_type": "xã",
        "short_codename": "phu_nham",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Thanh Lương",
        "code": "xa_thanh_luong",
        "division_type": "xã",
        "short_codename": "thanh_luong",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Hạnh Sơn",
        "code": "xa_hanh_son",
        "division_type": "xã",
        "short_codename": "hanh_son",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Phúc Sơn",
        "code": "xa_phuc_son",
        "division_type": "xã",
        "short_codename": "phuc_son",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Xã Thạch Lương",
        "code": "xa_thach_luong",
        "division_type": "xã",
        "short_codename": "thach_luong",
        "parentCode": "thi_xa_nghia_lo"
    },
    {
        "name": "Thị trấn Yên Thế",
        "code": "thi_tran_yen_the",
        "division_type": "thị trấn",
        "short_codename": "yen_the",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Tân Phượng",
        "code": "xa_tan_phuong",
        "division_type": "xã",
        "short_codename": "tan_phuong",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Lâm Thượng",
        "code": "xa_lam_thuong",
        "division_type": "xã",
        "short_codename": "lam_thuong",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Khánh Thiện",
        "code": "xa_khanh_thien",
        "division_type": "xã",
        "short_codename": "khanh_thien",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Minh Chuẩn",
        "code": "xa_minh_chuan",
        "division_type": "xã",
        "short_codename": "minh_chuan",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Mai Sơn",
        "code": "xa_mai_son",
        "division_type": "xã",
        "short_codename": "mai_son",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Khai Trung",
        "code": "xa_khai_trung",
        "division_type": "xã",
        "short_codename": "khai_trung",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Mường Lai",
        "code": "xa_muong_lai",
        "division_type": "xã",
        "short_codename": "muong_lai",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã An Lạc",
        "code": "xa_an_lac",
        "division_type": "xã",
        "short_codename": "an_lac",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Minh Xuân",
        "code": "xa_minh_xuan",
        "division_type": "xã",
        "short_codename": "minh_xuan",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Tô Mậu",
        "code": "xa_to_mau",
        "division_type": "xã",
        "short_codename": "to_mau",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Tân Lĩnh",
        "code": "xa_tan_linh",
        "division_type": "xã",
        "short_codename": "tan_linh",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Yên Thắng",
        "code": "xa_yen_thang",
        "division_type": "xã",
        "short_codename": "yen_thang",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Khánh Hoà",
        "code": "xa_khanh_hoa",
        "division_type": "xã",
        "short_codename": "khanh_hoa",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Vĩnh Lạc",
        "code": "xa_vinh_lac",
        "division_type": "xã",
        "short_codename": "vinh_lac",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Liễu Đô",
        "code": "xa_lieu_do",
        "division_type": "xã",
        "short_codename": "lieu_do",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Động Quan",
        "code": "xa_dong_quan",
        "division_type": "xã",
        "short_codename": "dong_quan",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Minh Tiến",
        "code": "xa_minh_tien",
        "division_type": "xã",
        "short_codename": "minh_tien",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Trúc Lâu",
        "code": "xa_truc_lau",
        "division_type": "xã",
        "short_codename": "truc_lau",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Phúc Lợi",
        "code": "xa_phuc_loi",
        "division_type": "xã",
        "short_codename": "phuc_loi",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Phan Thanh",
        "code": "xa_phan_thanh",
        "division_type": "xã",
        "short_codename": "phan_thanh",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã An Phú",
        "code": "xa_an_phu",
        "division_type": "xã",
        "short_codename": "an_phu",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Xã Trung Tâm",
        "code": "xa_trung_tam",
        "division_type": "xã",
        "short_codename": "trung_tam",
        "parentCode": "huyen_luc_yen"
    },
    {
        "name": "Thị trấn Mậu A",
        "code": "thi_tran_mau_a",
        "division_type": "thị trấn",
        "short_codename": "mau_a",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Lang Thíp",
        "code": "xa_lang_thip",
        "division_type": "xã",
        "short_codename": "lang_thip",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Lâm Giang",
        "code": "xa_lam_giang",
        "division_type": "xã",
        "short_codename": "lam_giang",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Châu Quế Thượng",
        "code": "xa_chau_que_thuong",
        "division_type": "xã",
        "short_codename": "chau_que_thuong",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Châu Quế Hạ",
        "code": "xa_chau_que_ha",
        "division_type": "xã",
        "short_codename": "chau_que_ha",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Quang Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Đông An",
        "code": "xa_dong_an",
        "division_type": "xã",
        "short_codename": "dong_an",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Đông Cuông",
        "code": "xa_dong_cuong",
        "division_type": "xã",
        "short_codename": "dong_cuong",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Phong Dụ Hạ",
        "code": "xa_phong_du_ha",
        "division_type": "xã",
        "short_codename": "phong_du_ha",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Mậu Đông",
        "code": "xa_mau_dong",
        "division_type": "xã",
        "short_codename": "mau_dong",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Ngòi A",
        "code": "xa_ngoi_a",
        "division_type": "xã",
        "short_codename": "ngoi_a",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Xuân Tầm",
        "code": "xa_xuan_tam",
        "division_type": "xã",
        "short_codename": "xuan_tam",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Tân Hợp",
        "code": "xa_tan_hop",
        "division_type": "xã",
        "short_codename": "tan_hop",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã An Thịnh",
        "code": "xa_an_thinh",
        "division_type": "xã",
        "short_codename": "an_thinh",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Yên Thái",
        "code": "xa_yen_thai",
        "division_type": "xã",
        "short_codename": "yen_thai",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Phong Dụ Thượng",
        "code": "xa_phong_du_thuong",
        "division_type": "xã",
        "short_codename": "phong_du_thuong",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Yên Hợp",
        "code": "xa_yen_hop",
        "division_type": "xã",
        "short_codename": "yen_hop",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Đại Sơn",
        "code": "xa_dai_son",
        "division_type": "xã",
        "short_codename": "dai_son",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Đại Phác",
        "code": "xa_dai_phac",
        "division_type": "xã",
        "short_codename": "dai_phac",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Yên Phú",
        "code": "xa_yen_phu",
        "division_type": "xã",
        "short_codename": "yen_phu",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Xuân Ái",
        "code": "xa_xuan_ai",
        "division_type": "xã",
        "short_codename": "xuan_ai",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Viễn Sơn",
        "code": "xa_vien_son",
        "division_type": "xã",
        "short_codename": "vien_son",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Mỏ Vàng",
        "code": "xa_mo_vang",
        "division_type": "xã",
        "short_codename": "mo_vang",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Xã Nà Hẩu",
        "code": "xa_na_hau",
        "division_type": "xã",
        "short_codename": "na_hau",
        "parentCode": "huyen_van_yen"
    },
    {
        "name": "Thị trấn Mù Căng Chải",
        "code": "thi_tran_mu_cang_chai",
        "division_type": "thị trấn",
        "short_codename": "mu_cang_chai",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Hồ Bốn",
        "code": "xa_ho_bon",
        "division_type": "xã",
        "short_codename": "ho_bon",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Nậm Có",
        "code": "xa_nam_co",
        "division_type": "xã",
        "short_codename": "nam_co",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Khao Mang",
        "code": "xa_khao_mang",
        "division_type": "xã",
        "short_codename": "khao_mang",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Mồ Dề",
        "code": "xa_mo_de",
        "division_type": "xã",
        "short_codename": "mo_de",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Chế Cu Nha",
        "code": "xa_che_cu_nha",
        "division_type": "xã",
        "short_codename": "che_cu_nha",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Lao Chải",
        "code": "xa_lao_chai",
        "division_type": "xã",
        "short_codename": "lao_chai",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Kim Nọi",
        "code": "xa_kim_noi",
        "division_type": "xã",
        "short_codename": "kim_noi",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Cao Phạ",
        "code": "xa_cao_pha",
        "division_type": "xã",
        "short_codename": "cao_pha",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã La Pán Tẩn",
        "code": "xa_la_pan_tan",
        "division_type": "xã",
        "short_codename": "la_pan_tan",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Dế Su Phình",
        "code": "xa_de_su_phinh",
        "division_type": "xã",
        "short_codename": "de_su_phinh",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Chế Tạo",
        "code": "xa_che_tao",
        "division_type": "xã",
        "short_codename": "che_tao",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Púng Luông",
        "code": "xa_pung_luong",
        "division_type": "xã",
        "short_codename": "pung_luong",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Xã Nậm Khắt",
        "code": "xa_nam_khat",
        "division_type": "xã",
        "short_codename": "nam_khat",
        "parentCode": "huyen_mu_cang_chai"
    },
    {
        "name": "Thị trấn Cổ Phúc",
        "code": "thi_tran_co_phuc",
        "division_type": "thị trấn",
        "short_codename": "co_phuc",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Tân Đồng",
        "code": "xa_tan_dong",
        "division_type": "xã",
        "short_codename": "tan_dong",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Báo Đáp",
        "code": "xa_bao_dap",
        "division_type": "xã",
        "short_codename": "bao_dap",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Đào Thịnh",
        "code": "xa_dao_thinh",
        "division_type": "xã",
        "short_codename": "dao_thinh",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Việt Thành",
        "code": "xa_viet_thanh",
        "division_type": "xã",
        "short_codename": "viet_thanh",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Hòa Cuông",
        "code": "xa_hoa_cuong",
        "division_type": "xã",
        "short_codename": "hoa_cuong",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Minh Quán",
        "code": "xa_minh_quan",
        "division_type": "xã",
        "short_codename": "xa_minh_quan",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Quy Mông",
        "code": "xa_quy_mong",
        "division_type": "xã",
        "short_codename": "quy_mong",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Cường Thịnh",
        "code": "xa_cuong_thinh",
        "division_type": "xã",
        "short_codename": "cuong_thinh",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Kiên Thành",
        "code": "xa_kien_thanh",
        "division_type": "xã",
        "short_codename": "kien_thanh",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Nga Quán",
        "code": "xa_nga_quan",
        "division_type": "xã",
        "short_codename": "nga_quan",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Y Can",
        "code": "xa_y_can",
        "division_type": "xã",
        "short_codename": "y_can",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Lương Thịnh",
        "code": "xa_luong_thinh",
        "division_type": "xã",
        "short_codename": "luong_thinh",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Bảo Hưng",
        "code": "xa_bao_hung",
        "division_type": "xã",
        "short_codename": "bao_hung",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Việt Cường",
        "code": "xa_viet_cuong",
        "division_type": "xã",
        "short_codename": "viet_cuong",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Minh Quân",
        "code": "xa_minh_quan",
        "division_type": "xã",
        "short_codename": "xa_minh_quan",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Hồng Ca",
        "code": "xa_hong_ca",
        "division_type": "xã",
        "short_codename": "hong_ca",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Hưng Thịnh",
        "code": "xa_hung_thinh",
        "division_type": "xã",
        "short_codename": "hung_thinh",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Hưng Khánh",
        "code": "xa_hung_khanh",
        "division_type": "xã",
        "short_codename": "hung_khanh",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Việt Hồng",
        "code": "xa_viet_hong",
        "division_type": "xã",
        "short_codename": "viet_hong",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Xã Vân Hội",
        "code": "xa_van_hoi",
        "division_type": "xã",
        "short_codename": "van_hoi",
        "parentCode": "huyen_tran_yen"
    },
    {
        "name": "Thị trấn Trạm Tấu",
        "code": "thi_tran_tram_tau",
        "division_type": "thị trấn",
        "short_codename": "tram_tau",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Túc Đán",
        "code": "xa_tuc_dan",
        "division_type": "xã",
        "short_codename": "tuc_dan",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Pá Lau",
        "code": "xa_pa_lau",
        "division_type": "xã",
        "short_codename": "pa_lau",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Xà Hồ",
        "code": "xa_xa_ho",
        "division_type": "xã",
        "short_codename": "xa_ho",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Phình Hồ",
        "code": "xa_phinh_ho",
        "division_type": "xã",
        "short_codename": "phinh_ho",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Trạm Tấu",
        "code": "xa_tram_tau",
        "division_type": "xã",
        "short_codename": "xa_tram_tau",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Tà Si Láng",
        "code": "xa_ta_si_lang",
        "division_type": "xã",
        "short_codename": "ta_si_lang",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Pá Hu",
        "code": "xa_pa_hu",
        "division_type": "xã",
        "short_codename": "pa_hu",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Làng Nhì",
        "code": "xa_lang_nhi",
        "division_type": "xã",
        "short_codename": "lang_nhi",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Bản Công",
        "code": "xa_ban_cong",
        "division_type": "xã",
        "short_codename": "ban_cong",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Bản Mù",
        "code": "xa_ban_mu",
        "division_type": "xã",
        "short_codename": "ban_mu",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Xã Hát Lìu",
        "code": "xa_hat_liu",
        "division_type": "xã",
        "short_codename": "hat_liu",
        "parentCode": "huyen_tram_tau"
    },
    {
        "name": "Thị trấn NT Liên Sơn",
        "code": "thi_tran_nt_lien_son",
        "division_type": "thị trấn",
        "short_codename": "nt_lien_son",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Thị trấn NT Trần Phú",
        "code": "thi_tran_nt_tran_phu",
        "division_type": "thị trấn",
        "short_codename": "nt_tran_phu",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Tú Lệ",
        "code": "xa_tu_le",
        "division_type": "xã",
        "short_codename": "tu_le",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Nậm Búng",
        "code": "xa_nam_bung",
        "division_type": "xã",
        "short_codename": "nam_bung",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Gia Hội",
        "code": "xa_gia_hoi",
        "division_type": "xã",
        "short_codename": "gia_hoi",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Sùng Đô",
        "code": "xa_sung_do",
        "division_type": "xã",
        "short_codename": "sung_do",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Nậm Mười",
        "code": "xa_nam_muoi",
        "division_type": "xã",
        "short_codename": "nam_muoi",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã An Lương",
        "code": "xa_an_luong",
        "division_type": "xã",
        "short_codename": "an_luong",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Nậm Lành",
        "code": "xa_nam_lanh",
        "division_type": "xã",
        "short_codename": "nam_lanh",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Sơn Lương",
        "code": "xa_son_luong",
        "division_type": "xã",
        "short_codename": "son_luong",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Suối Quyền",
        "code": "xa_suoi_quyen",
        "division_type": "xã",
        "short_codename": "suoi_quyen",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Suối Giàng",
        "code": "xa_suoi_giang",
        "division_type": "xã",
        "short_codename": "suoi_giang",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Nghĩa Sơn",
        "code": "xa_nghia_son",
        "division_type": "xã",
        "short_codename": "nghia_son",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Suối Bu",
        "code": "xa_suoi_bu",
        "division_type": "xã",
        "short_codename": "suoi_bu",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Thị trấn Sơn Thịnh",
        "code": "thi_tran_son_thinh",
        "division_type": "thị trấn",
        "short_codename": "son_thinh",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Đại Lịch",
        "code": "xa_dai_lich",
        "division_type": "xã",
        "short_codename": "dai_lich",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Đồng Khê",
        "code": "xa_dong_khe",
        "division_type": "xã",
        "short_codename": "dong_khe",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Cát Thịnh",
        "code": "xa_cat_thinh",
        "division_type": "xã",
        "short_codename": "cat_thinh",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Tân Thịnh",
        "code": "xa_tan_thinh",
        "division_type": "xã",
        "short_codename": "tan_thinh",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Chấn Thịnh",
        "code": "xa_chan_thinh",
        "division_type": "xã",
        "short_codename": "chan_thinh",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Bình Thuận",
        "code": "xa_binh_thuan",
        "division_type": "xã",
        "short_codename": "binh_thuan",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Thượng Bằng La",
        "code": "xa_thuong_bang_la",
        "division_type": "xã",
        "short_codename": "thuong_bang_la",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Minh An",
        "code": "xa_minh_an",
        "division_type": "xã",
        "short_codename": "minh_an",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Xã Nghĩa Tâm",
        "code": "xa_nghia_tam",
        "division_type": "xã",
        "short_codename": "nghia_tam",
        "parentCode": "huyen_van_chan"
    },
    {
        "name": "Thị trấn Yên Bình",
        "code": "thi_tran_yen_binh",
        "division_type": "thị trấn",
        "short_codename": "yen_binh",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Thị trấn Thác Bà",
        "code": "thi_tran_thac_ba",
        "division_type": "thị trấn",
        "short_codename": "thac_ba",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Xuân Long",
        "code": "xa_xuan_long",
        "division_type": "xã",
        "short_codename": "xuan_long",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Cảm Nhân",
        "code": "xa_cam_nhan",
        "division_type": "xã",
        "short_codename": "cam_nhan",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Ngọc Chấn",
        "code": "xa_ngoc_chan",
        "division_type": "xã",
        "short_codename": "ngoc_chan",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Tân Nguyên",
        "code": "xa_tan_nguyen",
        "division_type": "xã",
        "short_codename": "tan_nguyen",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Phúc Ninh",
        "code": "xa_phuc_ninh",
        "division_type": "xã",
        "short_codename": "phuc_ninh",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Bảo Ái",
        "code": "xa_bao_ai",
        "division_type": "xã",
        "short_codename": "bao_ai",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Mỹ Gia",
        "code": "xa_my_gia",
        "division_type": "xã",
        "short_codename": "my_gia",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Xuân Lai",
        "code": "xa_xuan_lai",
        "division_type": "xã",
        "short_codename": "xuan_lai",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Mông Sơn",
        "code": "xa_mong_son",
        "division_type": "xã",
        "short_codename": "mong_son",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Cảm Ân",
        "code": "xa_cam_an",
        "division_type": "xã",
        "short_codename": "cam_an",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Yên Thành",
        "code": "xa_yen_thanh",
        "division_type": "xã",
        "short_codename": "yen_thanh",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Tân Hương",
        "code": "xa_tan_huong",
        "division_type": "xã",
        "short_codename": "tan_huong",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Phúc An",
        "code": "xa_phuc_an",
        "division_type": "xã",
        "short_codename": "phuc_an",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Bạch Hà",
        "code": "xa_bach_ha",
        "division_type": "xã",
        "short_codename": "bach_ha",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Vũ Linh",
        "code": "xa_vu_linh",
        "division_type": "xã",
        "short_codename": "vu_linh",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Đại Đồng",
        "code": "xa_dai_dong",
        "division_type": "xã",
        "short_codename": "dai_dong",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Vĩnh Kiên",
        "code": "xa_vinh_kien",
        "division_type": "xã",
        "short_codename": "vinh_kien",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Yên Bình",
        "code": "xa_yen_binh",
        "division_type": "xã",
        "short_codename": "xa_yen_binh",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Thịnh Hưng",
        "code": "xa_thinh_hung",
        "division_type": "xã",
        "short_codename": "thinh_hung",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Hán Đà",
        "code": "xa_han_da",
        "division_type": "xã",
        "short_codename": "han_da",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Phú Thịnh",
        "code": "xa_phu_thinh",
        "division_type": "xã",
        "short_codename": "phu_thinh",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Xã Đại Minh",
        "code": "xa_dai_minh",
        "division_type": "xã",
        "short_codename": "dai_minh",
        "parentCode": "huyen_yen_binh"
    },
    {
        "name": "Phường Thái Bình",
        "code": "phuong_thai_binh",
        "division_type": "phường",
        "short_codename": "thai_binh",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Tân Hòa",
        "code": "phuong_tan_hoa",
        "division_type": "phường",
        "short_codename": "tan_hoa",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Thịnh Lang",
        "code": "phuong_thinh_lang",
        "division_type": "phường",
        "short_codename": "thinh_lang",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Hữu Nghị",
        "code": "phuong_huu_nghi",
        "division_type": "phường",
        "short_codename": "huu_nghi",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Tân Thịnh",
        "code": "phuong_tan_thinh",
        "division_type": "phường",
        "short_codename": "tan_thinh",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Đồng Tiến",
        "code": "phuong_dong_tien",
        "division_type": "phường",
        "short_codename": "dong_tien",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Phương Lâm",
        "code": "phuong_phuong_lam",
        "division_type": "phường",
        "short_codename": "phuong_lam",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Xã Yên Mông",
        "code": "xa_yen_mong",
        "division_type": "xã",
        "short_codename": "yen_mong",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Quỳnh Lâm",
        "code": "phuong_quynh_lam",
        "division_type": "phường",
        "short_codename": "quynh_lam",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Dân Chủ",
        "code": "phuong_dan_chu",
        "division_type": "phường",
        "short_codename": "dan_chu",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Thống Nhất",
        "code": "phuong_thong_nhat",
        "division_type": "phường",
        "short_codename": "thong_nhat",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Kỳ Sơn",
        "code": "phuong_ky_son",
        "division_type": "phường",
        "short_codename": "ky_son",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Xã Thịnh Minh",
        "code": "xa_thinh_minh",
        "division_type": "xã",
        "short_codename": "thinh_minh",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Xã Hợp Thành",
        "code": "xa_hop_thanh",
        "division_type": "xã",
        "short_codename": "hop_thanh",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Xã Quang Tiến",
        "code": "xa_quang_tien",
        "division_type": "xã",
        "short_codename": "quang_tien",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Xã Mông Hóa",
        "code": "xa_mong_hoa",
        "division_type": "xã",
        "short_codename": "mong_hoa",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Phường Trung Minh",
        "code": "phuong_trung_minh",
        "division_type": "phường",
        "short_codename": "trung_minh",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Xã Độc Lập",
        "code": "xa_doc_lap",
        "division_type": "xã",
        "short_codename": "doc_lap",
        "parentCode": "thanh_pho_hoa_binh"
    },
    {
        "name": "Thị trấn Đà Bắc",
        "code": "thi_tran_da_bac",
        "division_type": "thị trấn",
        "short_codename": "da_bac",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Nánh Nghê",
        "code": "xa_nanh_nghe",
        "division_type": "xã",
        "short_codename": "nanh_nghe",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Giáp Đắt",
        "code": "xa_giap_dat",
        "division_type": "xã",
        "short_codename": "giap_dat",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Mường Chiềng",
        "code": "xa_muong_chieng",
        "division_type": "xã",
        "short_codename": "muong_chieng",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Tân Pheo",
        "code": "xa_tan_pheo",
        "division_type": "xã",
        "short_codename": "tan_pheo",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Đồng Chum",
        "code": "xa_dong_chum",
        "division_type": "xã",
        "short_codename": "dong_chum",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Tân Minh",
        "code": "xa_tan_minh",
        "division_type": "xã",
        "short_codename": "tan_minh",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Đoàn Kết",
        "code": "xa_doan_ket",
        "division_type": "xã",
        "short_codename": "doan_ket",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Đồng Ruộng",
        "code": "xa_dong_ruong",
        "division_type": "xã",
        "short_codename": "dong_ruong",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Tú Lý",
        "code": "xa_tu_ly",
        "division_type": "xã",
        "short_codename": "tu_ly",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Trung Thành",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Yên Hòa",
        "code": "xa_yen_hoa",
        "division_type": "xã",
        "short_codename": "yen_hoa",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Cao Sơn",
        "code": "xa_cao_son",
        "division_type": "xã",
        "short_codename": "cao_son",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Toàn Sơn",
        "code": "xa_toan_son",
        "division_type": "xã",
        "short_codename": "toan_son",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Hiền Lương",
        "code": "xa_hien_luong",
        "division_type": "xã",
        "short_codename": "hien_luong",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Tiền Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Xã Vầy Nưa",
        "code": "xa_vay_nua",
        "division_type": "xã",
        "short_codename": "vay_nua",
        "parentCode": "huyen_da_bac"
    },
    {
        "name": "Thị trấn Lương Sơn",
        "code": "thi_tran_luong_son",
        "division_type": "thị trấn",
        "short_codename": "luong_son",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Lâm Sơn",
        "code": "xa_lam_son",
        "division_type": "xã",
        "short_codename": "lam_son",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Hòa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Tân Vinh",
        "code": "xa_tan_vinh",
        "division_type": "xã",
        "short_codename": "tan_vinh",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Nhuận Trạch",
        "code": "xa_nhuan_trach",
        "division_type": "xã",
        "short_codename": "nhuan_trach",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Cao Sơn",
        "code": "xa_cao_son",
        "division_type": "xã",
        "short_codename": "cao_son",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Cư Yên",
        "code": "xa_cu_yen",
        "division_type": "xã",
        "short_codename": "cu_yen",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Liên Sơn",
        "code": "xa_lien_son",
        "division_type": "xã",
        "short_codename": "lien_son",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Cao Dương",
        "code": "xa_cao_duong",
        "division_type": "xã",
        "short_codename": "cao_duong",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Xã Thanh Cao",
        "code": "xa_thanh_cao",
        "division_type": "xã",
        "short_codename": "thanh_cao",
        "parentCode": "huyen_luong_son"
    },
    {
        "name": "Thị trấn Bo",
        "code": "thi_tran_bo",
        "division_type": "thị trấn",
        "short_codename": "bo",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Đú Sáng",
        "code": "xa_du_sang",
        "division_type": "xã",
        "short_codename": "du_sang",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Hùng Sơn",
        "code": "xa_hung_son",
        "division_type": "xã",
        "short_codename": "hung_son",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Bình Sơn",
        "code": "xa_binh_son",
        "division_type": "xã",
        "short_codename": "binh_son",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Tú Sơn",
        "code": "xa_tu_son",
        "division_type": "xã",
        "short_codename": "tu_son",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Vĩnh Tiến",
        "code": "xa_vinh_tien",
        "division_type": "xã",
        "short_codename": "vinh_tien",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Đông Bắc",
        "code": "xa_dong_bac",
        "division_type": "xã",
        "short_codename": "dong_bac",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Xuân Thủy",
        "code": "xa_xuan_thuy",
        "division_type": "xã",
        "short_codename": "xuan_thuy",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Vĩnh Đồng",
        "code": "xa_vinh_dong",
        "division_type": "xã",
        "short_codename": "vinh_dong",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Kim Lập",
        "code": "xa_kim_lap",
        "division_type": "xã",
        "short_codename": "kim_lap",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Hợp Tiến",
        "code": "xa_hop_tien",
        "division_type": "xã",
        "short_codename": "hop_tien",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Kim Bôi",
        "code": "xa_kim_boi",
        "division_type": "xã",
        "short_codename": "kim_boi",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Nam Thượng",
        "code": "xa_nam_thuong",
        "division_type": "xã",
        "short_codename": "nam_thuong",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Cuối Hạ",
        "code": "xa_cuoi_ha",
        "division_type": "xã",
        "short_codename": "cuoi_ha",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Sào Báy",
        "code": "xa_sao_bay",
        "division_type": "xã",
        "short_codename": "sao_bay",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Mi Hòa",
        "code": "xa_mi_hoa",
        "division_type": "xã",
        "short_codename": "mi_hoa",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Xã Nuông Dăm",
        "code": "xa_nuong_dam",
        "division_type": "xã",
        "short_codename": "nuong_dam",
        "parentCode": "huyen_kim_boi"
    },
    {
        "name": "Thị trấn Cao Phong",
        "code": "thi_tran_cao_phong",
        "division_type": "thị trấn",
        "short_codename": "cao_phong",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Xã Bình Thanh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Xã Thung Nai",
        "code": "xa_thung_nai",
        "division_type": "xã",
        "short_codename": "thung_nai",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Xã Bắc Phong",
        "code": "xa_bac_phong",
        "division_type": "xã",
        "short_codename": "bac_phong",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Xã Thu Phong",
        "code": "xa_thu_phong",
        "division_type": "xã",
        "short_codename": "thu_phong",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Xã Hợp Phong",
        "code": "xa_hop_phong",
        "division_type": "xã",
        "short_codename": "hop_phong",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Xã Tây Phong",
        "code": "xa_tay_phong",
        "division_type": "xã",
        "short_codename": "tay_phong",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Xã Dũng Phong",
        "code": "xa_dung_phong",
        "division_type": "xã",
        "short_codename": "dung_phong",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Xã Nam Phong",
        "code": "xa_nam_phong",
        "division_type": "xã",
        "short_codename": "nam_phong",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Xã Thạch Yên",
        "code": "xa_thach_yen",
        "division_type": "xã",
        "short_codename": "thach_yen",
        "parentCode": "huyen_cao_phong"
    },
    {
        "name": "Thị trấn Mãn Đức",
        "code": "thi_tran_man_duc",
        "division_type": "thị trấn",
        "short_codename": "man_duc",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Suối Hoa",
        "code": "xa_suoi_hoa",
        "division_type": "xã",
        "short_codename": "suoi_hoa",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Phú Vinh",
        "code": "xa_phu_vinh",
        "division_type": "xã",
        "short_codename": "phu_vinh",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Phú Cường",
        "code": "xa_phu_cuong",
        "division_type": "xã",
        "short_codename": "phu_cuong",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Mỹ Hòa",
        "code": "xa_my_hoa",
        "division_type": "xã",
        "short_codename": "my_hoa",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Quyết Chiến",
        "code": "xa_quyet_chien",
        "division_type": "xã",
        "short_codename": "quyet_chien",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Phong Phú",
        "code": "xa_phong_phu",
        "division_type": "xã",
        "short_codename": "phong_phu",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Tử Nê",
        "code": "xa_tu_ne",
        "division_type": "xã",
        "short_codename": "tu_ne",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Thanh Hối",
        "code": "xa_thanh_hoi",
        "division_type": "xã",
        "short_codename": "thanh_hoi",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Ngọc Mỹ",
        "code": "xa_ngoc_my",
        "division_type": "xã",
        "short_codename": "ngoc_my",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Đông Lai",
        "code": "xa_dong_lai",
        "division_type": "xã",
        "short_codename": "dong_lai",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Vân Sơn",
        "code": "xa_van_son",
        "division_type": "xã",
        "short_codename": "van_son",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Nhân Mỹ",
        "code": "xa_nhan_my",
        "division_type": "xã",
        "short_codename": "nhan_my",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Lỗ Sơn",
        "code": "xa_lo_son",
        "division_type": "xã",
        "short_codename": "lo_son",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Ngổ Luông",
        "code": "xa_ngo_luong",
        "division_type": "xã",
        "short_codename": "ngo_luong",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Gia Mô",
        "code": "xa_gia_mo",
        "division_type": "xã",
        "short_codename": "gia_mo",
        "parentCode": "huyen_tan_lac"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Thị trấn Mai Châu",
        "code": "thi_tran_mai_chau",
        "division_type": "thị trấn",
        "short_codename": "mai_chau",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Sơn Thủy",
        "code": "xa_son_thuy",
        "division_type": "xã",
        "short_codename": "son_thuy",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Pà Cò",
        "code": "xa_pa_co",
        "division_type": "xã",
        "short_codename": "pa_co",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Hang Kia",
        "code": "xa_hang_kia",
        "division_type": "xã",
        "short_codename": "hang_kia",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Đồng Tân",
        "code": "xa_dong_tan",
        "division_type": "xã",
        "short_codename": "dong_tan",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Cun Pheo",
        "code": "xa_cun_pheo",
        "division_type": "xã",
        "short_codename": "cun_pheo",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Bao La",
        "code": "xa_bao_la",
        "division_type": "xã",
        "short_codename": "bao_la",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Tòng Đậu",
        "code": "xa_tong_dau",
        "division_type": "xã",
        "short_codename": "tong_dau",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Nà Phòn",
        "code": "xa_na_phon",
        "division_type": "xã",
        "short_codename": "na_phon",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Săm Khóe",
        "code": "xa_sam_khoe",
        "division_type": "xã",
        "short_codename": "sam_khoe",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Chiềng Châu",
        "code": "xa_chieng_chau",
        "division_type": "xã",
        "short_codename": "chieng_chau",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Mai Hạ",
        "code": "xa_mai_ha",
        "division_type": "xã",
        "short_codename": "mai_ha",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Thành Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Mai Hịch",
        "code": "xa_mai_hich",
        "division_type": "xã",
        "short_codename": "mai_hich",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Xã Vạn Mai",
        "code": "xa_van_mai",
        "division_type": "xã",
        "short_codename": "van_mai",
        "parentCode": "huyen_mai_chau"
    },
    {
        "name": "Thị trấn Vụ Bản",
        "code": "thi_tran_vu_ban",
        "division_type": "thị trấn",
        "short_codename": "vu_ban",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Quý Hòa",
        "code": "xa_quy_hoa",
        "division_type": "xã",
        "short_codename": "quy_hoa",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Miền Đồi",
        "code": "xa_mien_doi",
        "division_type": "xã",
        "short_codename": "mien_doi",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Mỹ Thành",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Tuân Đạo",
        "code": "xa_tuan_dao",
        "division_type": "xã",
        "short_codename": "tuan_dao",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Văn Nghĩa",
        "code": "xa_van_nghia",
        "division_type": "xã",
        "short_codename": "van_nghia",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Văn Sơn",
        "code": "xa_van_son",
        "division_type": "xã",
        "short_codename": "van_son",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Nhân Nghĩa",
        "code": "xa_nhan_nghia",
        "division_type": "xã",
        "short_codename": "nhan_nghia",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Thượng Cốc",
        "code": "xa_thuong_coc",
        "division_type": "xã",
        "short_codename": "thuong_coc",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Quyết Thắng",
        "code": "xa_quyet_thang",
        "division_type": "xã",
        "short_codename": "quyet_thang",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Xuất Hóa",
        "code": "xa_xuat_hoa",
        "division_type": "xã",
        "short_codename": "xuat_hoa",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Yên Phú",
        "code": "xa_yen_phu",
        "division_type": "xã",
        "short_codename": "yen_phu",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Bình Hẻm",
        "code": "xa_binh_hem",
        "division_type": "xã",
        "short_codename": "binh_hem",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Định Cư",
        "code": "xa_dinh_cu",
        "division_type": "xã",
        "short_codename": "dinh_cu",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Chí Đạo",
        "code": "xa_chi_dao",
        "division_type": "xã",
        "short_codename": "chi_dao",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Ngọc Sơn",
        "code": "xa_ngoc_son",
        "division_type": "xã",
        "short_codename": "ngoc_son",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Hương Nhượng",
        "code": "xa_huong_nhuong",
        "division_type": "xã",
        "short_codename": "huong_nhuong",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Vũ Bình",
        "code": "xa_vu_binh",
        "division_type": "xã",
        "short_codename": "vu_binh",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Tự Do",
        "code": "xa_tu_do",
        "division_type": "xã",
        "short_codename": "tu_do",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Yên Nghiệp",
        "code": "xa_yen_nghiep",
        "division_type": "xã",
        "short_codename": "yen_nghiep",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Ân Nghĩa",
        "code": "xa_an_nghia",
        "division_type": "xã",
        "short_codename": "an_nghia",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Xã Ngọc Lâu",
        "code": "xa_ngoc_lau",
        "division_type": "xã",
        "short_codename": "ngoc_lau",
        "parentCode": "huyen_lac_son"
    },
    {
        "name": "Thị trấn Hàng Trạm",
        "code": "thi_tran_hang_tram",
        "division_type": "thị trấn",
        "short_codename": "hang_tram",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Lạc Sỹ",
        "code": "xa_lac_sy",
        "division_type": "xã",
        "short_codename": "lac_sy",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Lạc Lương",
        "code": "xa_lac_luong",
        "division_type": "xã",
        "short_codename": "lac_luong",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Bảo Hiệu",
        "code": "xa_bao_hieu",
        "division_type": "xã",
        "short_codename": "bao_hieu",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Đa Phúc",
        "code": "xa_da_phuc",
        "division_type": "xã",
        "short_codename": "da_phuc",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Hữu Lợi",
        "code": "xa_huu_loi",
        "division_type": "xã",
        "short_codename": "huu_loi",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Lạc Thịnh",
        "code": "xa_lac_thinh",
        "division_type": "xã",
        "short_codename": "lac_thinh",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Đoàn Kết",
        "code": "xa_doan_ket",
        "division_type": "xã",
        "short_codename": "doan_ket",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Phú Lai",
        "code": "xa_phu_lai",
        "division_type": "xã",
        "short_codename": "phu_lai",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Yên Trị",
        "code": "xa_yen_tri",
        "division_type": "xã",
        "short_codename": "yen_tri",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Xã Ngọc Lương",
        "code": "xa_ngoc_luong",
        "division_type": "xã",
        "short_codename": "ngoc_luong",
        "parentCode": "huyen_yen_thuy"
    },
    {
        "name": "Thị trấn Ba Hàng Đồi",
        "code": "thi_tran_ba_hang_doi",
        "division_type": "thị trấn",
        "short_codename": "ba_hang_doi",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Thị trấn Chi Nê",
        "code": "thi_tran_chi_ne",
        "division_type": "thị trấn",
        "short_codename": "chi_ne",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Xã Phú Nghĩa",
        "code": "xa_phu_nghia",
        "division_type": "xã",
        "short_codename": "phu_nghia",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Xã Phú Thành",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "phu_thanh",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Xã Hưng Thi",
        "code": "xa_hung_thi",
        "division_type": "xã",
        "short_codename": "hung_thi",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Xã Khoan Dụ",
        "code": "xa_khoan_du",
        "division_type": "xã",
        "short_codename": "khoan_du",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Xã Đồng Tâm",
        "code": "xa_dong_tam",
        "division_type": "xã",
        "short_codename": "dong_tam",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Xã Yên Bồng",
        "code": "xa_yen_bong",
        "division_type": "xã",
        "short_codename": "yen_bong",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Xã Thống Nhất",
        "code": "xa_thong_nhat",
        "division_type": "xã",
        "short_codename": "thong_nhat",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_lac_thuy"
    },
    {
        "name": "Phường Quán Triều",
        "code": "phuong_quan_trieu",
        "division_type": "phường",
        "short_codename": "quan_trieu",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Quang Vinh",
        "code": "phuong_quang_vinh",
        "division_type": "phường",
        "short_codename": "quang_vinh",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Túc Duyên",
        "code": "phuong_tuc_duyen",
        "division_type": "phường",
        "short_codename": "tuc_duyen",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Hoàng Văn Thụ",
        "code": "phuong_hoang_van_thu",
        "division_type": "phường",
        "short_codename": "hoang_van_thu",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Trưng Vương",
        "code": "phuong_trung_vuong",
        "division_type": "phường",
        "short_codename": "trung_vuong",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Phan Đình Phùng",
        "code": "phuong_phan_dinh_phung",
        "division_type": "phường",
        "short_codename": "phan_dinh_phung",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Tân Thịnh",
        "code": "phuong_tan_thinh",
        "division_type": "phường",
        "short_codename": "tan_thinh",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Thịnh Đán",
        "code": "phuong_thinh_dan",
        "division_type": "phường",
        "short_codename": "thinh_dan",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Đồng Quang",
        "code": "phuong_dong_quang",
        "division_type": "phường",
        "short_codename": "dong_quang",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Gia Sàng",
        "code": "phuong_gia_sang",
        "division_type": "phường",
        "short_codename": "gia_sang",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Tân Lập",
        "code": "phuong_tan_lap",
        "division_type": "phường",
        "short_codename": "tan_lap",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Cam Giá",
        "code": "phuong_cam_gia",
        "division_type": "phường",
        "short_codename": "cam_gia",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Phú Xá",
        "code": "phuong_phu_xa",
        "division_type": "phường",
        "short_codename": "phu_xa",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Hương Sơn",
        "code": "phuong_huong_son",
        "division_type": "phường",
        "short_codename": "huong_son",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Trung Thành",
        "code": "phuong_trung_thanh",
        "division_type": "phường",
        "short_codename": "trung_thanh",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Tân Thành",
        "code": "phuong_tan_thanh",
        "division_type": "phường",
        "short_codename": "tan_thanh",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Tân Long",
        "code": "phuong_tan_long",
        "division_type": "phường",
        "short_codename": "tan_long",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Phúc Hà",
        "code": "xa_phuc_ha",
        "division_type": "xã",
        "short_codename": "phuc_ha",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Phúc Xuân",
        "code": "xa_phuc_xuan",
        "division_type": "xã",
        "short_codename": "phuc_xuan",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Quyết Thắng",
        "code": "xa_quyet_thang",
        "division_type": "xã",
        "short_codename": "quyet_thang",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Phúc Trìu",
        "code": "xa_phuc_triu",
        "division_type": "xã",
        "short_codename": "phuc_triu",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Thịnh Đức",
        "code": "xa_thinh_duc",
        "division_type": "xã",
        "short_codename": "thinh_duc",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Tích Lương",
        "code": "phuong_tich_luong",
        "division_type": "phường",
        "short_codename": "tich_luong",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Tân Cương",
        "code": "xa_tan_cuong",
        "division_type": "xã",
        "short_codename": "tan_cuong",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Sơn Cẩm",
        "code": "xa_son_cam",
        "division_type": "xã",
        "short_codename": "son_cam",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Chùa Hang",
        "code": "phuong_chua_hang",
        "division_type": "phường",
        "short_codename": "chua_hang",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Cao Ngạn",
        "code": "xa_cao_ngan",
        "division_type": "xã",
        "short_codename": "cao_ngan",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Linh Sơn",
        "code": "xa_linh_son",
        "division_type": "xã",
        "short_codename": "linh_son",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Đồng Bẩm",
        "code": "phuong_dong_bam",
        "division_type": "phường",
        "short_codename": "dong_bam",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Huống Thượng",
        "code": "xa_huong_thuong",
        "division_type": "xã",
        "short_codename": "huong_thuong",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Xã Đồng Liên",
        "code": "xa_dong_lien",
        "division_type": "xã",
        "short_codename": "dong_lien",
        "parentCode": "thanh_pho_thai_nguyen"
    },
    {
        "name": "Phường Lương Sơn",
        "code": "phuong_luong_son",
        "division_type": "phường",
        "short_codename": "luong_son",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Phường Châu Sơn",
        "code": "phuong_chau_son",
        "division_type": "phường",
        "short_codename": "chau_son",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Phường Mỏ Chè",
        "code": "phuong_mo_che",
        "division_type": "phường",
        "short_codename": "mo_che",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Phường Cải Đan",
        "code": "phuong_cai_dan",
        "division_type": "phường",
        "short_codename": "cai_dan",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Phường Thắng Lợi",
        "code": "phuong_thang_loi",
        "division_type": "phường",
        "short_codename": "thang_loi",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Phường Phố Cò",
        "code": "phuong_pho_co",
        "division_type": "phường",
        "short_codename": "pho_co",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Xã Tân Quang",
        "code": "xa_tan_quang",
        "division_type": "xã",
        "short_codename": "tan_quang",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Phường Bách Quang",
        "code": "phuong_bach_quang",
        "division_type": "phường",
        "short_codename": "bach_quang",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Xã Bình Sơn",
        "code": "xa_binh_son",
        "division_type": "xã",
        "short_codename": "binh_son",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Xã Bá Xuyên",
        "code": "xa_ba_xuyen",
        "division_type": "xã",
        "short_codename": "ba_xuyen",
        "parentCode": "thanh_pho_song_cong"
    },
    {
        "name": "Thị trấn Chợ Chu",
        "code": "thi_tran_cho_chu",
        "division_type": "thị trấn",
        "short_codename": "cho_chu",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Linh Thông",
        "code": "xa_linh_thong",
        "division_type": "xã",
        "short_codename": "linh_thong",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Lam Vỹ",
        "code": "xa_lam_vy",
        "division_type": "xã",
        "short_codename": "lam_vy",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Quy Kỳ",
        "code": "xa_quy_ky",
        "division_type": "xã",
        "short_codename": "quy_ky",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Tân Thịnh",
        "code": "xa_tan_thinh",
        "division_type": "xã",
        "short_codename": "tan_thinh",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Kim Phượng",
        "code": "xa_kim_phuong",
        "division_type": "xã",
        "short_codename": "kim_phuong",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Bảo Linh",
        "code": "xa_bao_linh",
        "division_type": "xã",
        "short_codename": "bao_linh",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Phúc Chu",
        "code": "xa_phuc_chu",
        "division_type": "xã",
        "short_codename": "phuc_chu",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Tân Dương",
        "code": "xa_tan_duong",
        "division_type": "xã",
        "short_codename": "tan_duong",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Phượng Tiến",
        "code": "xa_phuong_tien",
        "division_type": "xã",
        "short_codename": "phuong_tien",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Bảo Cường",
        "code": "xa_bao_cuong",
        "division_type": "xã",
        "short_codename": "bao_cuong",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Đồng Thịnh",
        "code": "xa_dong_thinh",
        "division_type": "xã",
        "short_codename": "dong_thinh",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Định Biên",
        "code": "xa_dinh_bien",
        "division_type": "xã",
        "short_codename": "dinh_bien",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Thanh Định",
        "code": "xa_thanh_dinh",
        "division_type": "xã",
        "short_codename": "thanh_dinh",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Trung Hội",
        "code": "xa_trung_hoi",
        "division_type": "xã",
        "short_codename": "trung_hoi",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Trung Lương",
        "code": "xa_trung_luong",
        "division_type": "xã",
        "short_codename": "trung_luong",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Bình Yên",
        "code": "xa_binh_yen",
        "division_type": "xã",
        "short_codename": "binh_yen",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Điềm Mặc",
        "code": "xa_diem_mac",
        "division_type": "xã",
        "short_codename": "diem_mac",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Phú Tiến",
        "code": "xa_phu_tien",
        "division_type": "xã",
        "short_codename": "phu_tien",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Bộc Nhiêu",
        "code": "xa_boc_nhieu",
        "division_type": "xã",
        "short_codename": "boc_nhieu",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Sơn Phú",
        "code": "xa_son_phu",
        "division_type": "xã",
        "short_codename": "son_phu",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Phú Đình",
        "code": "xa_phu_dinh",
        "division_type": "xã",
        "short_codename": "phu_dinh",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Xã Bình Thành",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_dinh_hoa"
    },
    {
        "name": "Thị trấn Giang Tiên",
        "code": "thi_tran_giang_tien",
        "division_type": "thị trấn",
        "short_codename": "giang_tien",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Thị trấn Đu",
        "code": "thi_tran_du",
        "division_type": "thị trấn",
        "short_codename": "du",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Yên Ninh",
        "code": "xa_yen_ninh",
        "division_type": "xã",
        "short_codename": "yen_ninh",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Yên Trạch",
        "code": "xa_yen_trach",
        "division_type": "xã",
        "short_codename": "yen_trach",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Yên Đổ",
        "code": "xa_yen_do",
        "division_type": "xã",
        "short_codename": "yen_do",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Yên Lạc",
        "code": "xa_yen_lac",
        "division_type": "xã",
        "short_codename": "yen_lac",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Ôn Lương",
        "code": "xa_on_luong",
        "division_type": "xã",
        "short_codename": "on_luong",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Động Đạt",
        "code": "xa_dong_dat",
        "division_type": "xã",
        "short_codename": "dong_dat",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Phủ Lý",
        "code": "xa_phu_ly",
        "division_type": "xã",
        "short_codename": "phu_ly",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Phú Đô",
        "code": "xa_phu_do",
        "division_type": "xã",
        "short_codename": "phu_do",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Hợp Thành",
        "code": "xa_hop_thanh",
        "division_type": "xã",
        "short_codename": "hop_thanh",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Tức Tranh",
        "code": "xa_tuc_tranh",
        "division_type": "xã",
        "short_codename": "tuc_tranh",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Phấn Mễ",
        "code": "xa_phan_me",
        "division_type": "xã",
        "short_codename": "phan_me",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Vô Tranh",
        "code": "xa_vo_tranh",
        "division_type": "xã",
        "short_codename": "vo_tranh",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Xã Cổ Lũng",
        "code": "xa_co_lung",
        "division_type": "xã",
        "short_codename": "co_lung",
        "parentCode": "huyen_phu_luong"
    },
    {
        "name": "Thị trấn Sông Cầu",
        "code": "thi_tran_song_cau",
        "division_type": "thị trấn",
        "short_codename": "song_cau",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Thị trấn Trại Cau",
        "code": "thi_tran_trai_cau",
        "division_type": "thị trấn",
        "short_codename": "trai_cau",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Văn Lăng",
        "code": "xa_van_lang",
        "division_type": "xã",
        "short_codename": "van_lang",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Quang Sơn",
        "code": "xa_quang_son",
        "division_type": "xã",
        "short_codename": "quang_son",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Minh Lập",
        "code": "xa_minh_lap",
        "division_type": "xã",
        "short_codename": "minh_lap",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Văn Hán",
        "code": "xa_van_han",
        "division_type": "xã",
        "short_codename": "van_han",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Hóa Trung",
        "code": "xa_hoa_trung",
        "division_type": "xã",
        "short_codename": "hoa_trung",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Khe Mo",
        "code": "xa_khe_mo",
        "division_type": "xã",
        "short_codename": "khe_mo",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Cây Thị",
        "code": "xa_cay_thi",
        "division_type": "xã",
        "short_codename": "cay_thi",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Hóa Thượng",
        "code": "xa_hoa_thuong",
        "division_type": "xã",
        "short_codename": "hoa_thuong",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Hợp Tiến",
        "code": "xa_hop_tien",
        "division_type": "xã",
        "short_codename": "hop_tien",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Tân Lợi",
        "code": "xa_tan_loi",
        "division_type": "xã",
        "short_codename": "tan_loi",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Xã Nam Hòa",
        "code": "xa_nam_hoa",
        "division_type": "xã",
        "short_codename": "nam_hoa",
        "parentCode": "huyen_dong_hy"
    },
    {
        "name": "Thị trấn Đình Cả",
        "code": "thi_tran_dinh_ca",
        "division_type": "thị trấn",
        "short_codename": "dinh_ca",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Sảng Mộc",
        "code": "xa_sang_moc",
        "division_type": "xã",
        "short_codename": "sang_moc",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Nghinh Tường",
        "code": "xa_nghinh_tuong",
        "division_type": "xã",
        "short_codename": "nghinh_tuong",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Thần Xa",
        "code": "xa_than_xa",
        "division_type": "xã",
        "short_codename": "than_xa",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Vũ Chấn",
        "code": "xa_vu_chan",
        "division_type": "xã",
        "short_codename": "vu_chan",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Thượng Nung",
        "code": "xa_thuong_nung",
        "division_type": "xã",
        "short_codename": "thuong_nung",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Phú Thượng",
        "code": "xa_phu_thuong",
        "division_type": "xã",
        "short_codename": "phu_thuong",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Cúc Đường",
        "code": "xa_cuc_duong",
        "division_type": "xã",
        "short_codename": "cuc_duong",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã La Hiên",
        "code": "xa_la_hien",
        "division_type": "xã",
        "short_codename": "la_hien",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Lâu Thượng",
        "code": "xa_lau_thuong",
        "division_type": "xã",
        "short_codename": "lau_thuong",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Tràng Xá",
        "code": "xa_trang_xa",
        "division_type": "xã",
        "short_codename": "trang_xa",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Phương Giao",
        "code": "xa_phuong_giao",
        "division_type": "xã",
        "short_codename": "phuong_giao",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Liên Minh",
        "code": "xa_lien_minh",
        "division_type": "xã",
        "short_codename": "lien_minh",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Dân Tiến",
        "code": "xa_dan_tien",
        "division_type": "xã",
        "short_codename": "dan_tien",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Xã Bình Long",
        "code": "xa_binh_long",
        "division_type": "xã",
        "short_codename": "binh_long",
        "parentCode": "huyen_vo_nhai"
    },
    {
        "name": "Thị trấn Hùng Sơn",
        "code": "thi_tran_hung_son",
        "division_type": "thị trấn",
        "short_codename": "hung_son",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Thị trấn Quân Chu",
        "code": "thi_tran_quan_chu",
        "division_type": "thị trấn",
        "short_codename": "quan_chu",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Phúc Lương",
        "code": "xa_phuc_luong",
        "division_type": "xã",
        "short_codename": "phuc_luong",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Minh Tiến",
        "code": "xa_minh_tien",
        "division_type": "xã",
        "short_codename": "minh_tien",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Yên Lãng",
        "code": "xa_yen_lang",
        "division_type": "xã",
        "short_codename": "yen_lang",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Đức Lương",
        "code": "xa_duc_luong",
        "division_type": "xã",
        "short_codename": "duc_luong",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Phú Cường",
        "code": "xa_phu_cuong",
        "division_type": "xã",
        "short_codename": "phu_cuong",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Na Mao",
        "code": "xa_na_mao",
        "division_type": "xã",
        "short_codename": "na_mao",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Phú Lạc",
        "code": "xa_phu_lac",
        "division_type": "xã",
        "short_codename": "phu_lac",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Tân Linh",
        "code": "xa_tan_linh",
        "division_type": "xã",
        "short_codename": "tan_linh",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Phú Thịnh",
        "code": "xa_phu_thinh",
        "division_type": "xã",
        "short_codename": "phu_thinh",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Phục Linh",
        "code": "xa_phuc_linh",
        "division_type": "xã",
        "short_codename": "phuc_linh",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Phú Xuyên",
        "code": "xa_phu_xuyen",
        "division_type": "xã",
        "short_codename": "phu_xuyen",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Bản Ngoại",
        "code": "xa_ban_ngoai",
        "division_type": "xã",
        "short_codename": "ban_ngoai",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Tiên Hội",
        "code": "xa_tien_hoi",
        "division_type": "xã",
        "short_codename": "tien_hoi",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Cù Vân",
        "code": "xa_cu_van",
        "division_type": "xã",
        "short_codename": "cu_van",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Hà Thượng",
        "code": "xa_ha_thuong",
        "division_type": "xã",
        "short_codename": "ha_thuong",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã La Bằng",
        "code": "xa_la_bang",
        "division_type": "xã",
        "short_codename": "la_bang",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Hoàng Nông",
        "code": "xa_hoang_nong",
        "division_type": "xã",
        "short_codename": "hoang_nong",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Khôi Kỳ",
        "code": "xa_khoi_ky",
        "division_type": "xã",
        "short_codename": "khoi_ky",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã An Khánh",
        "code": "xa_an_khanh",
        "division_type": "xã",
        "short_codename": "an_khanh",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Tân Thái",
        "code": "xa_tan_thai",
        "division_type": "xã",
        "short_codename": "tan_thai",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Bình Thuận",
        "code": "xa_binh_thuan",
        "division_type": "xã",
        "short_codename": "binh_thuan",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Lục Ba",
        "code": "xa_luc_ba",
        "division_type": "xã",
        "short_codename": "luc_ba",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Mỹ Yên",
        "code": "xa_my_yen",
        "division_type": "xã",
        "short_codename": "my_yen",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Vạn Thọ",
        "code": "xa_van_tho",
        "division_type": "xã",
        "short_codename": "van_tho",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Văn Yên",
        "code": "xa_van_yen",
        "division_type": "xã",
        "short_codename": "van_yen",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Ký Phú",
        "code": "xa_ky_phu",
        "division_type": "xã",
        "short_codename": "ky_phu",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Cát Nê",
        "code": "xa_cat_ne",
        "division_type": "xã",
        "short_codename": "cat_ne",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Xã Quân Chu",
        "code": "xa_quan_chu",
        "division_type": "xã",
        "short_codename": "xa_quan_chu",
        "parentCode": "huyen_dai_tu"
    },
    {
        "name": "Phường Bãi Bông",
        "code": "phuong_bai_bong",
        "division_type": "phường",
        "short_codename": "bai_bong",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Phường Bắc Sơn",
        "code": "phuong_bac_son",
        "division_type": "phường",
        "short_codename": "bac_son",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Phường Ba Hàng",
        "code": "phuong_ba_hang",
        "division_type": "phường",
        "short_codename": "ba_hang",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Phúc Tân",
        "code": "xa_phuc_tan",
        "division_type": "xã",
        "short_codename": "phuc_tan",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Phúc Thuận",
        "code": "xa_phuc_thuan",
        "division_type": "xã",
        "short_codename": "phuc_thuan",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Hồng Tiến",
        "code": "xa_hong_tien",
        "division_type": "xã",
        "short_codename": "hong_tien",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Minh Đức",
        "code": "xa_minh_duc",
        "division_type": "xã",
        "short_codename": "minh_duc",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Đắc Sơn",
        "code": "xa_dac_son",
        "division_type": "xã",
        "short_codename": "dac_son",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Phường Đồng Tiến",
        "code": "phuong_dong_tien",
        "division_type": "phường",
        "short_codename": "dong_tien",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Thành Công",
        "code": "xa_thanh_cong",
        "division_type": "xã",
        "short_codename": "thanh_cong",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Tiên Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Vạn Phái",
        "code": "xa_van_phai",
        "division_type": "xã",
        "short_codename": "van_phai",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Nam Tiến",
        "code": "xa_nam_tien",
        "division_type": "xã",
        "short_codename": "nam_tien",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Tân Hương",
        "code": "xa_tan_huong",
        "division_type": "xã",
        "short_codename": "tan_huong",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Đông Cao",
        "code": "xa_dong_cao",
        "division_type": "xã",
        "short_codename": "dong_cao",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Trung Thành",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Xã Thuận Thành",
        "code": "xa_thuan_thanh",
        "division_type": "xã",
        "short_codename": "thuan_thanh",
        "parentCode": "thi_xa_pho_yen"
    },
    {
        "name": "Thị trấn Hương Sơn",
        "code": "thi_tran_huong_son",
        "division_type": "thị trấn",
        "short_codename": "huong_son",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Bàn Đạt",
        "code": "xa_ban_dat",
        "division_type": "xã",
        "short_codename": "ban_dat",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Tân Khánh",
        "code": "xa_tan_khanh",
        "division_type": "xã",
        "short_codename": "tan_khanh",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Tân Kim",
        "code": "xa_tan_kim",
        "division_type": "xã",
        "short_codename": "tan_kim",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Đào Xá",
        "code": "xa_dao_xa",
        "division_type": "xã",
        "short_codename": "dao_xa",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Bảo Lý",
        "code": "xa_bao_ly",
        "division_type": "xã",
        "short_codename": "bao_ly",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Thượng Đình",
        "code": "xa_thuong_dinh",
        "division_type": "xã",
        "short_codename": "thuong_dinh",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Nhã Lộng",
        "code": "xa_nha_long",
        "division_type": "xã",
        "short_codename": "nha_long",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Điềm Thụy",
        "code": "xa_diem_thuy",
        "division_type": "xã",
        "short_codename": "diem_thuy",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Xuân Phương",
        "code": "xa_xuan_phuong",
        "division_type": "xã",
        "short_codename": "xuan_phuong",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Tân Đức",
        "code": "xa_tan_duc",
        "division_type": "xã",
        "short_codename": "tan_duc",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Úc Kỳ",
        "code": "xa_uc_ky",
        "division_type": "xã",
        "short_codename": "uc_ky",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Lương Phú",
        "code": "xa_luong_phu",
        "division_type": "xã",
        "short_codename": "luong_phu",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Nga My",
        "code": "xa_nga_my",
        "division_type": "xã",
        "short_codename": "nga_my",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Kha Sơn",
        "code": "xa_kha_son",
        "division_type": "xã",
        "short_codename": "kha_son",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Thanh Ninh",
        "code": "xa_thanh_ninh",
        "division_type": "xã",
        "short_codename": "thanh_ninh",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Dương Thành",
        "code": "xa_duong_thanh",
        "division_type": "xã",
        "short_codename": "duong_thanh",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Xã Hà Châu",
        "code": "xa_ha_chau",
        "division_type": "xã",
        "short_codename": "ha_chau",
        "parentCode": "huyen_phu_binh"
    },
    {
        "name": "Phường Hoàng Văn Thụ",
        "code": "phuong_hoang_van_thu",
        "division_type": "phường",
        "short_codename": "hoang_van_thu",
        "parentCode": "thanh_pho_lang_son"
    },
    {
        "name": "Phường Tam Thanh",
        "code": "phuong_tam_thanh",
        "division_type": "phường",
        "short_codename": "tam_thanh",
        "parentCode": "thanh_pho_lang_son"
    },
    {
        "name": "Phường Vĩnh Trại",
        "code": "phuong_vinh_trai",
        "division_type": "phường",
        "short_codename": "vinh_trai",
        "parentCode": "thanh_pho_lang_son"
    },
    {
        "name": "Phường Đông Kinh",
        "code": "phuong_dong_kinh",
        "division_type": "phường",
        "short_codename": "dong_kinh",
        "parentCode": "thanh_pho_lang_son"
    },
    {
        "name": "Phường Chi Lăng",
        "code": "phuong_chi_lang",
        "division_type": "phường",
        "short_codename": "chi_lang",
        "parentCode": "thanh_pho_lang_son"
    },
    {
        "name": "Xã Hoàng Đồng",
        "code": "xa_hoang_dong",
        "division_type": "xã",
        "short_codename": "hoang_dong",
        "parentCode": "thanh_pho_lang_son"
    },
    {
        "name": "Xã Quảng Lạc",
        "code": "xa_quang_lac",
        "division_type": "xã",
        "short_codename": "quang_lac",
        "parentCode": "thanh_pho_lang_son"
    },
    {
        "name": "Xã Mai Pha",
        "code": "xa_mai_pha",
        "division_type": "xã",
        "short_codename": "mai_pha",
        "parentCode": "thanh_pho_lang_son"
    },
    {
        "name": "Thị trấn Thất Khê",
        "code": "thi_tran_that_khe",
        "division_type": "thị trấn",
        "short_codename": "that_khe",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Khánh Long",
        "code": "xa_khanh_long",
        "division_type": "xã",
        "short_codename": "khanh_long",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Đoàn Kết",
        "code": "xa_doan_ket",
        "division_type": "xã",
        "short_codename": "doan_ket",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Quốc Khánh",
        "code": "xa_quoc_khanh",
        "division_type": "xã",
        "short_codename": "quoc_khanh",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Vĩnh Tiến",
        "code": "xa_vinh_tien",
        "division_type": "xã",
        "short_codename": "vinh_tien",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Cao Minh",
        "code": "xa_cao_minh",
        "division_type": "xã",
        "short_codename": "cao_minh",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Chí Minh",
        "code": "xa_chi_minh",
        "division_type": "xã",
        "short_codename": "chi_minh",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Tri Phương",
        "code": "xa_tri_phuong",
        "division_type": "xã",
        "short_codename": "tri_phuong",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Tân Yên",
        "code": "xa_tan_yen",
        "division_type": "xã",
        "short_codename": "tan_yen",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Đội Cấn",
        "code": "xa_doi_can",
        "division_type": "xã",
        "short_codename": "doi_can",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Tân Minh",
        "code": "xa_tan_minh",
        "division_type": "xã",
        "short_codename": "tan_minh",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Kim Đồng",
        "code": "xa_kim_dong",
        "division_type": "xã",
        "short_codename": "kim_dong",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Chi Lăng",
        "code": "xa_chi_lang",
        "division_type": "xã",
        "short_codename": "chi_lang",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Trung Thành",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Đại Đồng",
        "code": "xa_dai_dong",
        "division_type": "xã",
        "short_codename": "dai_dong",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Đào Viên",
        "code": "xa_dao_vien",
        "division_type": "xã",
        "short_codename": "dao_vien",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Đề Thám",
        "code": "xa_de_tham",
        "division_type": "xã",
        "short_codename": "de_tham",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Kháng Chiến",
        "code": "xa_khang_chien",
        "division_type": "xã",
        "short_codename": "khang_chien",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Hùng Sơn",
        "code": "xa_hung_son",
        "division_type": "xã",
        "short_codename": "hung_son",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Quốc Việt",
        "code": "xa_quoc_viet",
        "division_type": "xã",
        "short_codename": "quoc_viet",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Hùng Việt",
        "code": "xa_hung_viet",
        "division_type": "xã",
        "short_codename": "hung_viet",
        "parentCode": "huyen_trang_dinh"
    },
    {
        "name": "Xã Hưng Đạo",
        "code": "xa_hung_dao",
        "division_type": "xã",
        "short_codename": "hung_dao",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Vĩnh Yên",
        "code": "xa_vinh_yen",
        "division_type": "xã",
        "short_codename": "vinh_yen",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Hoa Thám",
        "code": "xa_hoa_tham",
        "division_type": "xã",
        "short_codename": "hoa_tham",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Quý Hòa",
        "code": "xa_quy_hoa",
        "division_type": "xã",
        "short_codename": "quy_hoa",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Hồng Phong",
        "code": "xa_hong_phong",
        "division_type": "xã",
        "short_codename": "hong_phong",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Yên Lỗ",
        "code": "xa_yen_lo",
        "division_type": "xã",
        "short_codename": "yen_lo",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Thiện Hòa",
        "code": "xa_thien_hoa",
        "division_type": "xã",
        "short_codename": "thien_hoa",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Thiện Thuật",
        "code": "xa_thien_thuat",
        "division_type": "xã",
        "short_codename": "thien_thuat",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Minh Khai",
        "code": "xa_minh_khai",
        "division_type": "xã",
        "short_codename": "minh_khai",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Thiện Long",
        "code": "xa_thien_long",
        "division_type": "xã",
        "short_codename": "thien_long",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Hoàng Văn Thụ",
        "code": "xa_hoang_van_thu",
        "division_type": "xã",
        "short_codename": "hoang_van_thu",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Mông Ân",
        "code": "xa_mong_an",
        "division_type": "xã",
        "short_codename": "mong_an",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Thị trấn Bình Gia",
        "code": "thi_tran_binh_gia",
        "division_type": "thị trấn",
        "short_codename": "binh_gia",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Hồng Thái",
        "code": "xa_hong_thai",
        "division_type": "xã",
        "short_codename": "hong_thai",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Bình La",
        "code": "xa_binh_la",
        "division_type": "xã",
        "short_codename": "binh_la",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Xã Tân Văn",
        "code": "xa_tan_van",
        "division_type": "xã",
        "short_codename": "tan_van",
        "parentCode": "huyen_binh_gia"
    },
    {
        "name": "Thị trấn Na Sầm",
        "code": "thi_tran_na_sam",
        "division_type": "thị trấn",
        "short_codename": "na_sam",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Trùng Khánh",
        "code": "xa_trung_khanh",
        "division_type": "xã",
        "short_codename": "trung_khanh",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Bắc La",
        "code": "xa_bac_la",
        "division_type": "xã",
        "short_codename": "bac_la",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Thụy Hùng",
        "code": "xa_thuy_hung",
        "division_type": "xã",
        "short_codename": "thuy_hung",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Bắc Hùng",
        "code": "xa_bac_hung",
        "division_type": "xã",
        "short_codename": "bac_hung",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Tân Tác",
        "code": "xa_tan_tac",
        "division_type": "xã",
        "short_codename": "tan_tac",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Thanh Long",
        "code": "xa_thanh_long",
        "division_type": "xã",
        "short_codename": "thanh_long",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Hội Hoan",
        "code": "xa_hoi_hoan",
        "division_type": "xã",
        "short_codename": "hoi_hoan",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Bắc Việt",
        "code": "xa_bac_viet",
        "division_type": "xã",
        "short_codename": "bac_viet",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Hoàng Việt",
        "code": "xa_hoang_viet",
        "division_type": "xã",
        "short_codename": "hoang_viet",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Gia Miễn",
        "code": "xa_gia_mien",
        "division_type": "xã",
        "short_codename": "gia_mien",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Thành Hòa",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Tân Thanh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Hồng Thái",
        "code": "xa_hong_thai",
        "division_type": "xã",
        "short_codename": "hong_thai",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Hoàng Văn Thụ",
        "code": "xa_hoang_van_thu",
        "division_type": "xã",
        "short_codename": "hoang_van_thu",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Xã Nhạc Kỳ",
        "code": "xa_nhac_ky",
        "division_type": "xã",
        "short_codename": "nhac_ky",
        "parentCode": "huyen_van_lang"
    },
    {
        "name": "Thị trấn Đồng Đăng",
        "code": "thi_tran_dong_dang",
        "division_type": "thị trấn",
        "short_codename": "dong_dang",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Thị trấn Cao Lộc",
        "code": "thi_tran_cao_loc",
        "division_type": "thị trấn",
        "short_codename": "cao_loc",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Bảo Lâm",
        "code": "xa_bao_lam",
        "division_type": "xã",
        "short_codename": "bao_lam",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Thanh Lòa",
        "code": "xa_thanh_loa",
        "division_type": "xã",
        "short_codename": "thanh_loa",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Cao Lâu",
        "code": "xa_cao_lau",
        "division_type": "xã",
        "short_codename": "cao_lau",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Thạch Đạn",
        "code": "xa_thach_dan",
        "division_type": "xã",
        "short_codename": "thach_dan",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Xuất Lễ",
        "code": "xa_xuat_le",
        "division_type": "xã",
        "short_codename": "xuat_le",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Hồng Phong",
        "code": "xa_hong_phong",
        "division_type": "xã",
        "short_codename": "hong_phong",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Thụy Hùng",
        "code": "xa_thuy_hung",
        "division_type": "xã",
        "short_codename": "thuy_hung",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Lộc Yên",
        "code": "xa_loc_yen",
        "division_type": "xã",
        "short_codename": "loc_yen",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Phú Xá",
        "code": "xa_phu_xa",
        "division_type": "xã",
        "short_codename": "phu_xa",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Bình Trung",
        "code": "xa_binh_trung",
        "division_type": "xã",
        "short_codename": "binh_trung",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Hải Yến",
        "code": "xa_hai_yen",
        "division_type": "xã",
        "short_codename": "hai_yen",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Hòa Cư",
        "code": "xa_hoa_cu",
        "division_type": "xã",
        "short_codename": "hoa_cu",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Hợp Thành",
        "code": "xa_hop_thanh",
        "division_type": "xã",
        "short_codename": "hop_thanh",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Công Sơn",
        "code": "xa_cong_son",
        "division_type": "xã",
        "short_codename": "cong_son",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Gia Cát",
        "code": "xa_gia_cat",
        "division_type": "xã",
        "short_codename": "gia_cat",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Mẫu Sơn",
        "code": "xa_mau_son",
        "division_type": "xã",
        "short_codename": "mau_son",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Xuân Long",
        "code": "xa_xuan_long",
        "division_type": "xã",
        "short_codename": "xuan_long",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Tân Liên",
        "code": "xa_tan_lien",
        "division_type": "xã",
        "short_codename": "tan_lien",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Yên Trạch",
        "code": "xa_yen_trach",
        "division_type": "xã",
        "short_codename": "yen_trach",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_cao_loc"
    },
    {
        "name": "Thị trấn Văn Quan",
        "code": "thi_tran_van_quan",
        "division_type": "thị trấn",
        "short_codename": "van_quan",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Trấn Ninh",
        "code": "xa_tran_ninh",
        "division_type": "xã",
        "short_codename": "tran_ninh",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Liên Hội",
        "code": "xa_lien_hoi",
        "division_type": "xã",
        "short_codename": "lien_hoi",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Tú Xuyên",
        "code": "xa_tu_xuyen",
        "division_type": "xã",
        "short_codename": "tu_xuyen",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Điềm He",
        "code": "xa_diem_he",
        "division_type": "xã",
        "short_codename": "diem_he",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã An Sơn",
        "code": "xa_an_son",
        "division_type": "xã",
        "short_codename": "an_son",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Khánh Khê",
        "code": "xa_khanh_khe",
        "division_type": "xã",
        "short_codename": "khanh_khe",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Lương Năng",
        "code": "xa_luong_nang",
        "division_type": "xã",
        "short_codename": "luong_nang",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Đồng Giáp",
        "code": "xa_dong_giap",
        "division_type": "xã",
        "short_codename": "dong_giap",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Bình Phúc",
        "code": "xa_binh_phuc",
        "division_type": "xã",
        "short_codename": "binh_phuc",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Tràng Các",
        "code": "xa_trang_cac",
        "division_type": "xã",
        "short_codename": "trang_cac",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Tân Đoàn",
        "code": "xa_tan_doan",
        "division_type": "xã",
        "short_codename": "tan_doan",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Tri Lễ",
        "code": "xa_tri_le",
        "division_type": "xã",
        "short_codename": "tri_le",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Tràng Phái",
        "code": "xa_trang_phai",
        "division_type": "xã",
        "short_codename": "trang_phai",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Yên Phúc",
        "code": "xa_yen_phuc",
        "division_type": "xã",
        "short_codename": "yen_phuc",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Xã Hữu Lễ",
        "code": "xa_huu_le",
        "division_type": "xã",
        "short_codename": "huu_le",
        "parentCode": "huyen_van_quan"
    },
    {
        "name": "Thị trấn Bắc Sơn",
        "code": "thi_tran_bac_son",
        "division_type": "thị trấn",
        "short_codename": "bac_son",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Long Đống",
        "code": "xa_long_dong",
        "division_type": "xã",
        "short_codename": "long_dong",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Vạn Thủy",
        "code": "xa_van_thuy",
        "division_type": "xã",
        "short_codename": "van_thuy",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Đồng ý",
        "code": "xa_dong_y",
        "division_type": "xã",
        "short_codename": "dong_y",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Tân Tri",
        "code": "xa_tan_tri",
        "division_type": "xã",
        "short_codename": "tan_tri",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Bắc Quỳnh",
        "code": "xa_bac_quynh",
        "division_type": "xã",
        "short_codename": "bac_quynh",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Hưng Vũ",
        "code": "xa_hung_vu",
        "division_type": "xã",
        "short_codename": "hung_vu",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Vũ Sơn",
        "code": "xa_vu_son",
        "division_type": "xã",
        "short_codename": "vu_son",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Chiêu Vũ",
        "code": "xa_chieu_vu",
        "division_type": "xã",
        "short_codename": "chieu_vu",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Tân Hương",
        "code": "xa_tan_huong",
        "division_type": "xã",
        "short_codename": "tan_huong",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Chiến Thắng",
        "code": "xa_chien_thang",
        "division_type": "xã",
        "short_codename": "chien_thang",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Vũ Lăng",
        "code": "xa_vu_lang",
        "division_type": "xã",
        "short_codename": "vu_lang",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Trấn Yên",
        "code": "xa_tran_yen",
        "division_type": "xã",
        "short_codename": "tran_yen",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Vũ Lễ",
        "code": "xa_vu_le",
        "division_type": "xã",
        "short_codename": "vu_le",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Nhất Hòa",
        "code": "xa_nhat_hoa",
        "division_type": "xã",
        "short_codename": "nhat_hoa",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Xã Nhất Tiến",
        "code": "xa_nhat_tien",
        "division_type": "xã",
        "short_codename": "nhat_tien",
        "parentCode": "huyen_bac_son"
    },
    {
        "name": "Thị trấn Hữu Lũng",
        "code": "thi_tran_huu_lung",
        "division_type": "thị trấn",
        "short_codename": "huu_lung",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Hữu Liên",
        "code": "xa_huu_lien",
        "division_type": "xã",
        "short_codename": "huu_lien",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Yên Bình",
        "code": "xa_yen_binh",
        "division_type": "xã",
        "short_codename": "yen_binh",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Quyết Thắng",
        "code": "xa_quyet_thang",
        "division_type": "xã",
        "short_codename": "quyet_thang",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Yên Thịnh",
        "code": "xa_yen_thinh",
        "division_type": "xã",
        "short_codename": "yen_thinh",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Yên Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Thiện Tân",
        "code": "xa_thien_tan",
        "division_type": "xã",
        "short_codename": "thien_tan",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Yên Vượng",
        "code": "xa_yen_vuong",
        "division_type": "xã",
        "short_codename": "yen_vuong",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Minh Tiến",
        "code": "xa_minh_tien",
        "division_type": "xã",
        "short_codename": "minh_tien",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Nhật Tiến",
        "code": "xa_nhat_tien",
        "division_type": "xã",
        "short_codename": "nhat_tien",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Đồng Tân",
        "code": "xa_dong_tan",
        "division_type": "xã",
        "short_codename": "dong_tan",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Cai Kinh",
        "code": "xa_cai_kinh",
        "division_type": "xã",
        "short_codename": "cai_kinh",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Hòa Lạc",
        "code": "xa_hoa_lac",
        "division_type": "xã",
        "short_codename": "hoa_lac",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Vân Nham",
        "code": "xa_van_nham",
        "division_type": "xã",
        "short_codename": "van_nham",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Đồng Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Hòa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Minh Sơn",
        "code": "xa_minh_son",
        "division_type": "xã",
        "short_codename": "minh_son",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Hồ Sơn",
        "code": "xa_ho_son",
        "division_type": "xã",
        "short_codename": "ho_son",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Sơn Hà",
        "code": "xa_son_ha",
        "division_type": "xã",
        "short_codename": "son_ha",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Minh Hòa",
        "code": "xa_minh_hoa",
        "division_type": "xã",
        "short_codename": "minh_hoa",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Xã Hòa Thắng",
        "code": "xa_hoa_thang",
        "division_type": "xã",
        "short_codename": "hoa_thang",
        "parentCode": "huyen_huu_lung"
    },
    {
        "name": "Thị trấn Đồng Mỏ",
        "code": "thi_tran_dong_mo",
        "division_type": "thị trấn",
        "short_codename": "dong_mo",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Thị trấn Chi Lăng",
        "code": "thi_tran_chi_lang",
        "division_type": "thị trấn",
        "short_codename": "chi_lang",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Vân An",
        "code": "xa_van_an",
        "division_type": "xã",
        "short_codename": "van_an",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Vân Thủy",
        "code": "xa_van_thuy",
        "division_type": "xã",
        "short_codename": "van_thuy",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Gia Lộc",
        "code": "xa_gia_loc",
        "division_type": "xã",
        "short_codename": "gia_loc",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Bắc Thủy",
        "code": "xa_bac_thuy",
        "division_type": "xã",
        "short_codename": "bac_thuy",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Chiến Thắng",
        "code": "xa_chien_thang",
        "division_type": "xã",
        "short_codename": "chien_thang",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Mai Sao",
        "code": "xa_mai_sao",
        "division_type": "xã",
        "short_codename": "mai_sao",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Bằng Hữu",
        "code": "xa_bang_huu",
        "division_type": "xã",
        "short_codename": "bang_huu",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Thượng Cường",
        "code": "xa_thuong_cuong",
        "division_type": "xã",
        "short_codename": "thuong_cuong",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Bằng Mạc",
        "code": "xa_bang_mac",
        "division_type": "xã",
        "short_codename": "bang_mac",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Nhân Lý",
        "code": "xa_nhan_ly",
        "division_type": "xã",
        "short_codename": "nhan_ly",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Lâm Sơn",
        "code": "xa_lam_son",
        "division_type": "xã",
        "short_codename": "lam_son",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Liên Sơn",
        "code": "xa_lien_son",
        "division_type": "xã",
        "short_codename": "lien_son",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Vạn Linh",
        "code": "xa_van_linh",
        "division_type": "xã",
        "short_codename": "van_linh",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Hữu Kiên",
        "code": "xa_huu_kien",
        "division_type": "xã",
        "short_codename": "huu_kien",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Quan Sơn",
        "code": "xa_quan_son",
        "division_type": "xã",
        "short_codename": "quan_son",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Y Tịch",
        "code": "xa_y_tich",
        "division_type": "xã",
        "short_codename": "y_tich",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Xã Chi Lăng",
        "code": "xa_chi_lang",
        "division_type": "xã",
        "short_codename": "xa_chi_lang",
        "parentCode": "huyen_chi_lang"
    },
    {
        "name": "Thị trấn Na Dương",
        "code": "thi_tran_na_duong",
        "division_type": "thị trấn",
        "short_codename": "na_duong",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Thị trấn Lộc Bình",
        "code": "thi_tran_loc_binh",
        "division_type": "thị trấn",
        "short_codename": "loc_binh",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Mẫu Sơn",
        "code": "xa_mau_son",
        "division_type": "xã",
        "short_codename": "mau_son",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Yên Khoái",
        "code": "xa_yen_khoai",
        "division_type": "xã",
        "short_codename": "yen_khoai",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Khánh Xuân",
        "code": "xa_khanh_xuan",
        "division_type": "xã",
        "short_codename": "khanh_xuan",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Tú Mịch",
        "code": "xa_tu_mich",
        "division_type": "xã",
        "short_codename": "tu_mich",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Hữu Khánh",
        "code": "xa_huu_khanh",
        "division_type": "xã",
        "short_codename": "huu_khanh",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Đồng Bục",
        "code": "xa_dong_buc",
        "division_type": "xã",
        "short_codename": "dong_buc",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Tam Gia",
        "code": "xa_tam_gia",
        "division_type": "xã",
        "short_codename": "tam_gia",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Tú Đoạn",
        "code": "xa_tu_doan",
        "division_type": "xã",
        "short_codename": "tu_doan",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Khuất Xá",
        "code": "xa_khuat_xa",
        "division_type": "xã",
        "short_codename": "khuat_xa",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Tĩnh Bắc",
        "code": "xa_tinh_bac",
        "division_type": "xã",
        "short_codename": "tinh_bac",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Thống Nhất",
        "code": "xa_thong_nhat",
        "division_type": "xã",
        "short_codename": "thong_nhat",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Sàn Viên",
        "code": "xa_san_vien",
        "division_type": "xã",
        "short_codename": "san_vien",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Đông Quan",
        "code": "xa_dong_quan",
        "division_type": "xã",
        "short_codename": "dong_quan",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Minh Hiệp",
        "code": "xa_minh_hiep",
        "division_type": "xã",
        "short_codename": "minh_hiep",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Hữu Lân",
        "code": "xa_huu_lan",
        "division_type": "xã",
        "short_codename": "huu_lan",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Lợi Bác",
        "code": "xa_loi_bac",
        "division_type": "xã",
        "short_codename": "loi_bac",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Nam Quan",
        "code": "xa_nam_quan",
        "division_type": "xã",
        "short_codename": "nam_quan",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Xuân Dương",
        "code": "xa_xuan_duong",
        "division_type": "xã",
        "short_codename": "xuan_duong",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Xã Ái Quốc",
        "code": "xa_ai_quoc",
        "division_type": "xã",
        "short_codename": "ai_quoc",
        "parentCode": "huyen_loc_binh"
    },
    {
        "name": "Thị trấn Đình Lập",
        "code": "thi_tran_dinh_lap",
        "division_type": "thị trấn",
        "short_codename": "dinh_lap",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Thị trấn NT Thái Bình",
        "code": "thi_tran_nt_thai_binh",
        "division_type": "thị trấn",
        "short_codename": "nt_thai_binh",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Bắc Xa",
        "code": "xa_bac_xa",
        "division_type": "xã",
        "short_codename": "bac_xa",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Bính Xá",
        "code": "xa_binh_xa",
        "division_type": "xã",
        "short_codename": "binh_xa",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Kiên Mộc",
        "code": "xa_kien_moc",
        "division_type": "xã",
        "short_codename": "kien_moc",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Đình Lập",
        "code": "xa_dinh_lap",
        "division_type": "xã",
        "short_codename": "xa_dinh_lap",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Thái Bình",
        "code": "xa_thai_binh",
        "division_type": "xã",
        "short_codename": "thai_binh",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Cường Lợi",
        "code": "xa_cuong_loi",
        "division_type": "xã",
        "short_codename": "cuong_loi",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Châu Sơn",
        "code": "xa_chau_son",
        "division_type": "xã",
        "short_codename": "chau_son",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Lâm Ca",
        "code": "xa_lam_ca",
        "division_type": "xã",
        "short_codename": "lam_ca",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Đồng Thắng",
        "code": "xa_dong_thang",
        "division_type": "xã",
        "short_codename": "dong_thang",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Xã Bắc Lãng",
        "code": "xa_bac_lang",
        "division_type": "xã",
        "short_codename": "bac_lang",
        "parentCode": "huyen_dinh_lap"
    },
    {
        "name": "Phường Hà Khánh",
        "code": "phuong_ha_khanh",
        "division_type": "phường",
        "short_codename": "ha_khanh",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hà Phong",
        "code": "phuong_ha_phong",
        "division_type": "phường",
        "short_codename": "ha_phong",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hà Khẩu",
        "code": "phuong_ha_khau",
        "division_type": "phường",
        "short_codename": "ha_khau",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Cao Xanh",
        "code": "phuong_cao_xanh",
        "division_type": "phường",
        "short_codename": "cao_xanh",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Giếng Đáy",
        "code": "phuong_gieng_day",
        "division_type": "phường",
        "short_codename": "gieng_day",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hà Tu",
        "code": "phuong_ha_tu",
        "division_type": "phường",
        "short_codename": "ha_tu",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hà Trung",
        "code": "phuong_ha_trung",
        "division_type": "phường",
        "short_codename": "ha_trung",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hà Lầm",
        "code": "phuong_ha_lam",
        "division_type": "phường",
        "short_codename": "ha_lam",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Bãi Cháy",
        "code": "phuong_bai_chay",
        "division_type": "phường",
        "short_codename": "bai_chay",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Cao Thắng",
        "code": "phuong_cao_thang",
        "division_type": "phường",
        "short_codename": "cao_thang",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hùng Thắng",
        "code": "phuong_hung_thang",
        "division_type": "phường",
        "short_codename": "hung_thang",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Yết Kiêu",
        "code": "phuong_yet_kieu",
        "division_type": "phường",
        "short_codename": "yet_kieu",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Trần Hưng Đạo",
        "code": "phuong_tran_hung_dao",
        "division_type": "phường",
        "short_codename": "tran_hung_dao",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hồng Hải",
        "code": "phuong_hong_hai",
        "division_type": "phường",
        "short_codename": "hong_hai",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hồng Gai",
        "code": "phuong_hong_gai",
        "division_type": "phường",
        "short_codename": "hong_gai",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Bạch Đằng",
        "code": "phuong_bach_dang",
        "division_type": "phường",
        "short_codename": "bach_dang",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hồng Hà",
        "code": "phuong_hong_ha",
        "division_type": "phường",
        "short_codename": "hong_ha",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Tuần Châu",
        "code": "phuong_tuan_chau",
        "division_type": "phường",
        "short_codename": "tuan_chau",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Việt Hưng",
        "code": "phuong_viet_hung",
        "division_type": "phường",
        "short_codename": "viet_hung",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Đại Yên",
        "code": "phuong_dai_yen",
        "division_type": "phường",
        "short_codename": "dai_yen",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Hoành Bồ",
        "code": "phuong_hoanh_bo",
        "division_type": "phường",
        "short_codename": "hoanh_bo",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Kỳ Thượng",
        "code": "xa_ky_thuong",
        "division_type": "xã",
        "short_codename": "ky_thuong",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Đồng Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Tân Dân",
        "code": "xa_tan_dan",
        "division_type": "xã",
        "short_codename": "tan_dan",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Đồng Lâm",
        "code": "xa_dong_lam",
        "division_type": "xã",
        "short_codename": "dong_lam",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Vũ Oai",
        "code": "xa_vu_oai",
        "division_type": "xã",
        "short_codename": "vu_oai",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Dân Chủ",
        "code": "xa_dan_chu",
        "division_type": "xã",
        "short_codename": "dan_chu",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Quảng La",
        "code": "xa_quang_la",
        "division_type": "xã",
        "short_codename": "quang_la",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Bằng Cả",
        "code": "xa_bang_ca",
        "division_type": "xã",
        "short_codename": "bang_ca",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Thống Nhất",
        "code": "xa_thong_nhat",
        "division_type": "xã",
        "short_codename": "thong_nhat",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Sơn Dương",
        "code": "xa_son_duong",
        "division_type": "xã",
        "short_codename": "son_duong",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Xã Lê Lợi",
        "code": "xa_le_loi",
        "division_type": "xã",
        "short_codename": "le_loi",
        "parentCode": "thanh_pho_ha_long"
    },
    {
        "name": "Phường Ka Long",
        "code": "phuong_ka_long",
        "division_type": "phường",
        "short_codename": "ka_long",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Phường Trần Phú",
        "code": "phuong_tran_phu",
        "division_type": "phường",
        "short_codename": "tran_phu",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Phường Ninh Dương",
        "code": "phuong_ninh_duong",
        "division_type": "phường",
        "short_codename": "ninh_duong",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Phường Hoà Lạc",
        "code": "phuong_hoa_lac",
        "division_type": "phường",
        "short_codename": "hoa_lac",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Phường Trà Cổ",
        "code": "phuong_tra_co",
        "division_type": "phường",
        "short_codename": "tra_co",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Xã Hải Sơn",
        "code": "xa_hai_son",
        "division_type": "xã",
        "short_codename": "hai_son",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Xã Hải Đông",
        "code": "xa_hai_dong",
        "division_type": "xã",
        "short_codename": "hai_dong",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Xã Hải Tiến",
        "code": "xa_hai_tien",
        "division_type": "xã",
        "short_codename": "hai_tien",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Phường Hải Yên",
        "code": "phuong_hai_yen",
        "division_type": "phường",
        "short_codename": "hai_yen",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Xã Quảng Nghĩa",
        "code": "xa_quang_nghia",
        "division_type": "xã",
        "short_codename": "quang_nghia",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Phường Hải Hoà",
        "code": "phuong_hai_hoa",
        "division_type": "phường",
        "short_codename": "hai_hoa",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Xã Hải Xuân",
        "code": "xa_hai_xuan",
        "division_type": "xã",
        "short_codename": "hai_xuan",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Xã Vạn Ninh",
        "code": "xa_van_ninh",
        "division_type": "xã",
        "short_codename": "van_ninh",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Phường Bình Ngọc",
        "code": "phuong_binh_ngoc",
        "division_type": "phường",
        "short_codename": "binh_ngoc",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Xã Vĩnh Trung",
        "code": "xa_vinh_trung",
        "division_type": "xã",
        "short_codename": "vinh_trung",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Xã Vĩnh Thực",
        "code": "xa_vinh_thuc",
        "division_type": "xã",
        "short_codename": "vinh_thuc",
        "parentCode": "thanh_pho_mong_cai"
    },
    {
        "name": "Phường Mông Dương",
        "code": "phuong_mong_duong",
        "division_type": "phường",
        "short_codename": "mong_duong",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cửa Ông",
        "code": "phuong_cua_ong",
        "division_type": "phường",
        "short_codename": "cua_ong",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Sơn",
        "code": "phuong_cam_son",
        "division_type": "phường",
        "short_codename": "cam_son",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Đông",
        "code": "phuong_cam_dong",
        "division_type": "phường",
        "short_codename": "cam_dong",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Phú",
        "code": "phuong_cam_phu",
        "division_type": "phường",
        "short_codename": "cam_phu",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Tây",
        "code": "phuong_cam_tay",
        "division_type": "phường",
        "short_codename": "cam_tay",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Quang Hanh",
        "code": "phuong_quang_hanh",
        "division_type": "phường",
        "short_codename": "quang_hanh",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Thịnh",
        "code": "phuong_cam_thinh",
        "division_type": "phường",
        "short_codename": "cam_thinh",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Thủy",
        "code": "phuong_cam_thuy",
        "division_type": "phường",
        "short_codename": "cam_thuy",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Thạch",
        "code": "phuong_cam_thach",
        "division_type": "phường",
        "short_codename": "cam_thach",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Thành",
        "code": "phuong_cam_thanh",
        "division_type": "phường",
        "short_codename": "cam_thanh",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Trung",
        "code": "phuong_cam_trung",
        "division_type": "phường",
        "short_codename": "cam_trung",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Cẩm Bình",
        "code": "phuong_cam_binh",
        "division_type": "phường",
        "short_codename": "cam_binh",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Xã Cộng Hòa",
        "code": "xa_cong_hoa",
        "division_type": "xã",
        "short_codename": "cong_hoa",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Xã Cẩm Hải",
        "code": "xa_cam_hai",
        "division_type": "xã",
        "short_codename": "cam_hai",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Xã Dương Huy",
        "code": "xa_duong_huy",
        "division_type": "xã",
        "short_codename": "duong_huy",
        "parentCode": "thanh_pho_cam_pha"
    },
    {
        "name": "Phường Vàng Danh",
        "code": "phuong_vang_danh",
        "division_type": "phường",
        "short_codename": "vang_danh",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Phường Thanh Sơn",
        "code": "phuong_thanh_son",
        "division_type": "phường",
        "short_codename": "thanh_son",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Phường Bắc Sơn",
        "code": "phuong_bac_son",
        "division_type": "phường",
        "short_codename": "bac_son",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Phường Trưng Vương",
        "code": "phuong_trung_vuong",
        "division_type": "phường",
        "short_codename": "trung_vuong",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Phường Nam Khê",
        "code": "phuong_nam_khe",
        "division_type": "phường",
        "short_codename": "nam_khe",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Phường Yên Thanh",
        "code": "phuong_yen_thanh",
        "division_type": "phường",
        "short_codename": "yen_thanh",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Xã Thượng Yên Công",
        "code": "xa_thuong_yen_cong",
        "division_type": "xã",
        "short_codename": "thuong_yen_cong",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Phường Phương Đông",
        "code": "phuong_phuong_dong",
        "division_type": "phường",
        "short_codename": "phuong_dong",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Phường Phương Nam",
        "code": "phuong_phuong_nam",
        "division_type": "phường",
        "short_codename": "phuong_nam",
        "parentCode": "thanh_pho_uong_bi"
    },
    {
        "name": "Thị trấn Bình Liêu",
        "code": "thi_tran_binh_lieu",
        "division_type": "thị trấn",
        "short_codename": "binh_lieu",
        "parentCode": "huyen_binh_lieu"
    },
    {
        "name": "Xã Hoành Mô",
        "code": "xa_hoanh_mo",
        "division_type": "xã",
        "short_codename": "hoanh_mo",
        "parentCode": "huyen_binh_lieu"
    },
    {
        "name": "Xã Đồng Tâm",
        "code": "xa_dong_tam",
        "division_type": "xã",
        "short_codename": "dong_tam",
        "parentCode": "huyen_binh_lieu"
    },
    {
        "name": "Xã Đồng Văn",
        "code": "xa_dong_van",
        "division_type": "xã",
        "short_codename": "dong_van",
        "parentCode": "huyen_binh_lieu"
    },
    {
        "name": "Xã Vô Ngại",
        "code": "xa_vo_ngai",
        "division_type": "xã",
        "short_codename": "vo_ngai",
        "parentCode": "huyen_binh_lieu"
    },
    {
        "name": "Xã Lục Hồn",
        "code": "xa_luc_hon",
        "division_type": "xã",
        "short_codename": "luc_hon",
        "parentCode": "huyen_binh_lieu"
    },
    {
        "name": "Xã Húc Động",
        "code": "xa_huc_dong",
        "division_type": "xã",
        "short_codename": "huc_dong",
        "parentCode": "huyen_binh_lieu"
    },
    {
        "name": "Thị trấn Tiên Yên",
        "code": "thi_tran_tien_yen",
        "division_type": "thị trấn",
        "short_codename": "tien_yen",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Hà Lâu",
        "code": "xa_ha_lau",
        "division_type": "xã",
        "short_codename": "ha_lau",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Đại Dực",
        "code": "xa_dai_duc",
        "division_type": "xã",
        "short_codename": "dai_duc",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Phong Dụ",
        "code": "xa_phong_du",
        "division_type": "xã",
        "short_codename": "phong_du",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Điền Xá",
        "code": "xa_dien_xa",
        "division_type": "xã",
        "short_codename": "dien_xa",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Đông Ngũ",
        "code": "xa_dong_ngu",
        "division_type": "xã",
        "short_codename": "dong_ngu",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Yên Than",
        "code": "xa_yen_than",
        "division_type": "xã",
        "short_codename": "yen_than",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Đông Hải",
        "code": "xa_dong_hai",
        "division_type": "xã",
        "short_codename": "dong_hai",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Hải Lạng",
        "code": "xa_hai_lang",
        "division_type": "xã",
        "short_codename": "hai_lang",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Tiên Lãng",
        "code": "xa_tien_lang",
        "division_type": "xã",
        "short_codename": "tien_lang",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Xã Đồng Rui",
        "code": "xa_dong_rui",
        "division_type": "xã",
        "short_codename": "dong_rui",
        "parentCode": "huyen_tien_yen"
    },
    {
        "name": "Thị trấn Đầm Hà",
        "code": "thi_tran_dam_ha",
        "division_type": "thị trấn",
        "short_codename": "dam_ha",
        "parentCode": "huyen_dam_ha"
    },
    {
        "name": "Xã Quảng Lâm",
        "code": "xa_quang_lam",
        "division_type": "xã",
        "short_codename": "quang_lam",
        "parentCode": "huyen_dam_ha"
    },
    {
        "name": "Xã Quảng An",
        "code": "xa_quang_an",
        "division_type": "xã",
        "short_codename": "quang_an",
        "parentCode": "huyen_dam_ha"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_dam_ha"
    },
    {
        "name": "Xã Dực Yên",
        "code": "xa_duc_yen",
        "division_type": "xã",
        "short_codename": "duc_yen",
        "parentCode": "huyen_dam_ha"
    },
    {
        "name": "Xã Quảng Tân",
        "code": "xa_quang_tan",
        "division_type": "xã",
        "short_codename": "quang_tan",
        "parentCode": "huyen_dam_ha"
    },
    {
        "name": "Xã Đầm Hà",
        "code": "xa_dam_ha",
        "division_type": "xã",
        "short_codename": "xa_dam_ha",
        "parentCode": "huyen_dam_ha"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_dam_ha"
    },
    {
        "name": "Xã Đại Bình",
        "code": "xa_dai_binh",
        "division_type": "xã",
        "short_codename": "dai_binh",
        "parentCode": "huyen_dam_ha"
    },
    {
        "name": "Thị trấn Quảng Hà",
        "code": "thi_tran_quang_ha",
        "division_type": "thị trấn",
        "short_codename": "quang_ha",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Quảng Đức",
        "code": "xa_quang_duc",
        "division_type": "xã",
        "short_codename": "quang_duc",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Quảng Sơn",
        "code": "xa_quang_son",
        "division_type": "xã",
        "short_codename": "quang_son",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Quảng Thành",
        "code": "xa_quang_thanh",
        "division_type": "xã",
        "short_codename": "quang_thanh",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Quảng Thịnh",
        "code": "xa_quang_thinh",
        "division_type": "xã",
        "short_codename": "quang_thinh",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Quảng Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Quảng Chính",
        "code": "xa_quang_chinh",
        "division_type": "xã",
        "short_codename": "quang_chinh",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Quảng Long",
        "code": "xa_quang_long",
        "division_type": "xã",
        "short_codename": "quang_long",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Đường Hoa",
        "code": "xa_duong_hoa",
        "division_type": "xã",
        "short_codename": "duong_hoa",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Quảng Phong",
        "code": "xa_quang_phong",
        "division_type": "xã",
        "short_codename": "quang_phong",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Xã Cái Chiên",
        "code": "xa_cai_chien",
        "division_type": "xã",
        "short_codename": "cai_chien",
        "parentCode": "huyen_hai_ha"
    },
    {
        "name": "Thị trấn Ba Chẽ",
        "code": "thi_tran_ba_che",
        "division_type": "thị trấn",
        "short_codename": "ba_che",
        "parentCode": "huyen_ba_che"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_ba_che"
    },
    {
        "name": "Xã Thanh Lâm",
        "code": "xa_thanh_lam",
        "division_type": "xã",
        "short_codename": "thanh_lam",
        "parentCode": "huyen_ba_che"
    },
    {
        "name": "Xã Đạp Thanh",
        "code": "xa_dap_thanh",
        "division_type": "xã",
        "short_codename": "dap_thanh",
        "parentCode": "huyen_ba_che"
    },
    {
        "name": "Xã Nam Sơn",
        "code": "xa_nam_son",
        "division_type": "xã",
        "short_codename": "nam_son",
        "parentCode": "huyen_ba_che"
    },
    {
        "name": "Xã Lương Mông",
        "code": "xa_luong_mong",
        "division_type": "xã",
        "short_codename": "luong_mong",
        "parentCode": "huyen_ba_che"
    },
    {
        "name": "Xã Đồn Đạc",
        "code": "xa_don_dac",
        "division_type": "xã",
        "short_codename": "don_dac",
        "parentCode": "huyen_ba_che"
    },
    {
        "name": "Xã Minh Cầm",
        "code": "xa_minh_cam",
        "division_type": "xã",
        "short_codename": "minh_cam",
        "parentCode": "huyen_ba_che"
    },
    {
        "name": "Thị trấn Cái Rồng",
        "code": "thi_tran_cai_rong",
        "division_type": "thị trấn",
        "short_codename": "cai_rong",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Đài Xuyên",
        "code": "xa_dai_xuyen",
        "division_type": "xã",
        "short_codename": "dai_xuyen",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Bình Dân",
        "code": "xa_binh_dan",
        "division_type": "xã",
        "short_codename": "binh_dan",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Vạn Yên",
        "code": "xa_van_yen",
        "division_type": "xã",
        "short_codename": "van_yen",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Minh Châu",
        "code": "xa_minh_chau",
        "division_type": "xã",
        "short_codename": "minh_chau",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Đoàn Kết",
        "code": "xa_doan_ket",
        "division_type": "xã",
        "short_codename": "doan_ket",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Hạ Long",
        "code": "xa_ha_long",
        "division_type": "xã",
        "short_codename": "ha_long",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Đông Xá",
        "code": "xa_dong_xa",
        "division_type": "xã",
        "short_codename": "dong_xa",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Bản Sen",
        "code": "xa_ban_sen",
        "division_type": "xã",
        "short_codename": "ban_sen",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Thắng Lợi",
        "code": "xa_thang_loi",
        "division_type": "xã",
        "short_codename": "thang_loi",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Quan Lạn",
        "code": "xa_quan_lan",
        "division_type": "xã",
        "short_codename": "quan_lan",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Xã Ngọc Vừng",
        "code": "xa_ngoc_vung",
        "division_type": "xã",
        "short_codename": "ngoc_vung",
        "parentCode": "huyen_van_don"
    },
    {
        "name": "Phường Mạo Khê",
        "code": "phuong_mao_khe",
        "division_type": "phường",
        "short_codename": "mao_khe",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Đông Triều",
        "code": "phuong_dong_trieu",
        "division_type": "phường",
        "short_codename": "dong_trieu",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã An Sinh",
        "code": "xa_an_sinh",
        "division_type": "xã",
        "short_codename": "an_sinh",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Tràng Lương",
        "code": "xa_trang_luong",
        "division_type": "xã",
        "short_codename": "trang_luong",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Bình Khê",
        "code": "xa_binh_khe",
        "division_type": "xã",
        "short_codename": "binh_khe",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Việt Dân",
        "code": "xa_viet_dan",
        "division_type": "xã",
        "short_codename": "viet_dan",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Tân Việt",
        "code": "xa_tan_viet",
        "division_type": "xã",
        "short_codename": "tan_viet",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Bình Dương",
        "code": "xa_binh_duong",
        "division_type": "xã",
        "short_codename": "binh_duong",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Đức Chính",
        "code": "phuong_duc_chinh",
        "division_type": "phường",
        "short_codename": "duc_chinh",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Tràng An",
        "code": "phuong_trang_an",
        "division_type": "phường",
        "short_codename": "trang_an",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Nguyễn Huệ",
        "code": "xa_nguyen_hue",
        "division_type": "xã",
        "short_codename": "nguyen_hue",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Thủy An",
        "code": "xa_thuy_an",
        "division_type": "xã",
        "short_codename": "thuy_an",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Xuân Sơn",
        "code": "phuong_xuan_son",
        "division_type": "phường",
        "short_codename": "xuan_son",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Hồng Thái Tây",
        "code": "xa_hong_thai_tay",
        "division_type": "xã",
        "short_codename": "hong_thai_tay",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Hồng Thái Đông",
        "code": "xa_hong_thai_dong",
        "division_type": "xã",
        "short_codename": "hong_thai_dong",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Hoàng Quế",
        "code": "phuong_hoang_que",
        "division_type": "phường",
        "short_codename": "hoang_que",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Yên Thọ",
        "code": "phuong_yen_tho",
        "division_type": "phường",
        "short_codename": "yen_tho",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Hồng Phong",
        "code": "phuong_hong_phong",
        "division_type": "phường",
        "short_codename": "hong_phong",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Kim Sơn",
        "code": "phuong_kim_son",
        "division_type": "phường",
        "short_codename": "kim_son",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Hưng Đạo",
        "code": "phuong_hung_dao",
        "division_type": "phường",
        "short_codename": "hung_dao",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Xã Yên Đức",
        "code": "xa_yen_duc",
        "division_type": "xã",
        "short_codename": "yen_duc",
        "parentCode": "thi_xa_dong_trieu"
    },
    {
        "name": "Phường Quảng Yên",
        "code": "phuong_quang_yen",
        "division_type": "phường",
        "short_codename": "quang_yen",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Đông Mai",
        "code": "phuong_dong_mai",
        "division_type": "phường",
        "short_codename": "dong_mai",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Minh Thành",
        "code": "phuong_minh_thanh",
        "division_type": "phường",
        "short_codename": "minh_thanh",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Xã Sông Khoai",
        "code": "xa_song_khoai",
        "division_type": "xã",
        "short_codename": "song_khoai",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Xã Hiệp Hòa",
        "code": "xa_hiep_hoa",
        "division_type": "xã",
        "short_codename": "hiep_hoa",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Cộng Hòa",
        "code": "phuong_cong_hoa",
        "division_type": "phường",
        "short_codename": "cong_hoa",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Xã Tiền An",
        "code": "xa_tien_an",
        "division_type": "xã",
        "short_codename": "tien_an",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Xã Hoàng Tân",
        "code": "xa_hoang_tan",
        "division_type": "xã",
        "short_codename": "hoang_tan",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Tân An",
        "code": "phuong_tan_an",
        "division_type": "phường",
        "short_codename": "tan_an",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Yên Giang",
        "code": "phuong_yen_giang",
        "division_type": "phường",
        "short_codename": "yen_giang",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Nam Hoà",
        "code": "phuong_nam_hoa",
        "division_type": "phường",
        "short_codename": "nam_hoa",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Hà An",
        "code": "phuong_ha_an",
        "division_type": "phường",
        "short_codename": "ha_an",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Xã Cẩm La",
        "code": "xa_cam_la",
        "division_type": "xã",
        "short_codename": "cam_la",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Phong Hải",
        "code": "phuong_phong_hai",
        "division_type": "phường",
        "short_codename": "phong_hai",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Yên Hải",
        "code": "phuong_yen_hai",
        "division_type": "phường",
        "short_codename": "yen_hai",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Xã Liên Hòa",
        "code": "xa_lien_hoa",
        "division_type": "xã",
        "short_codename": "lien_hoa",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Phường Phong Cốc",
        "code": "phuong_phong_coc",
        "division_type": "phường",
        "short_codename": "phong_coc",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Xã Liên Vị",
        "code": "xa_lien_vi",
        "division_type": "xã",
        "short_codename": "lien_vi",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Xã Tiền Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "thi_xa_quang_yen"
    },
    {
        "name": "Thị trấn Cô Tô",
        "code": "thi_tran_co_to",
        "division_type": "thị trấn",
        "short_codename": "co_to",
        "parentCode": "huyen_co_to"
    },
    {
        "name": "Xã Đồng Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_co_to"
    },
    {
        "name": "Xã Thanh Lân",
        "code": "xa_thanh_lan",
        "division_type": "xã",
        "short_codename": "thanh_lan",
        "parentCode": "huyen_co_to"
    },
    {
        "name": "Phường Thọ Xương",
        "code": "phuong_tho_xuong",
        "division_type": "phường",
        "short_codename": "tho_xuong",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Phường Trần Nguyên Hãn",
        "code": "phuong_tran_nguyen_han",
        "division_type": "phường",
        "short_codename": "tran_nguyen_han",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Phường Ngô Quyền",
        "code": "phuong_ngo_quyen",
        "division_type": "phường",
        "short_codename": "ngo_quyen",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Phường Hoàng Văn Thụ",
        "code": "phuong_hoang_van_thu",
        "division_type": "phường",
        "short_codename": "hoang_van_thu",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Phường Trần Phú",
        "code": "phuong_tran_phu",
        "division_type": "phường",
        "short_codename": "tran_phu",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Phường Mỹ Độ",
        "code": "phuong_my_do",
        "division_type": "phường",
        "short_codename": "my_do",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Phường Lê Lợi",
        "code": "phuong_le_loi",
        "division_type": "phường",
        "short_codename": "le_loi",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Xã Song Mai",
        "code": "xa_song_mai",
        "division_type": "xã",
        "short_codename": "song_mai",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Phường Xương Giang",
        "code": "phuong_xuong_giang",
        "division_type": "phường",
        "short_codename": "xuong_giang",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Phường Đa Mai",
        "code": "phuong_da_mai",
        "division_type": "phường",
        "short_codename": "da_mai",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Phường Dĩnh Kế",
        "code": "phuong_dinh_ke",
        "division_type": "phường",
        "short_codename": "dinh_ke",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Xã Dĩnh Trì",
        "code": "xa_dinh_tri",
        "division_type": "xã",
        "short_codename": "dinh_tri",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Xã Đồng Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Xã Song Khê",
        "code": "xa_song_khe",
        "division_type": "xã",
        "short_codename": "song_khe",
        "parentCode": "thanh_pho_bac_giang"
    },
    {
        "name": "Xã Đồng Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Canh Nậu",
        "code": "xa_canh_nau",
        "division_type": "xã",
        "short_codename": "canh_nau",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Xuân Lương",
        "code": "xa_xuan_luong",
        "division_type": "xã",
        "short_codename": "xuan_luong",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Tam Tiến",
        "code": "xa_tam_tien",
        "division_type": "xã",
        "short_codename": "tam_tien",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Đồng Vương",
        "code": "xa_dong_vuong",
        "division_type": "xã",
        "short_codename": "dong_vuong",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Đồng Hưu",
        "code": "xa_dong_huu",
        "division_type": "xã",
        "short_codename": "dong_huu",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Đồng Tâm",
        "code": "xa_dong_tam",
        "division_type": "xã",
        "short_codename": "dong_tam",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Tam Hiệp",
        "code": "xa_tam_hiep",
        "division_type": "xã",
        "short_codename": "tam_hiep",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Tiến Thắng",
        "code": "xa_tien_thang",
        "division_type": "xã",
        "short_codename": "tien_thang",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Hồng Kỳ",
        "code": "xa_hong_ky",
        "division_type": "xã",
        "short_codename": "hong_ky",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Đồng Lạc",
        "code": "xa_dong_lac",
        "division_type": "xã",
        "short_codename": "dong_lac",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Đông Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Tân Hiệp",
        "code": "xa_tan_hiep",
        "division_type": "xã",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Hương Vĩ",
        "code": "xa_huong_vi",
        "division_type": "xã",
        "short_codename": "huong_vi",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Đồng Kỳ",
        "code": "xa_dong_ky",
        "division_type": "xã",
        "short_codename": "dong_ky",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã An Thượng",
        "code": "xa_an_thuong",
        "division_type": "xã",
        "short_codename": "an_thuong",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Thị trấn Phồn Xương",
        "code": "thi_tran_phon_xuong",
        "division_type": "thị trấn",
        "short_codename": "phon_xuong",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Tân Sỏi",
        "code": "xa_tan_soi",
        "division_type": "xã",
        "short_codename": "tan_soi",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Thị trấn Bố Hạ",
        "code": "thi_tran_bo_ha",
        "division_type": "thị trấn",
        "short_codename": "bo_ha",
        "parentCode": "huyen_yen_the"
    },
    {
        "name": "Xã Lan Giới",
        "code": "xa_lan_gioi",
        "division_type": "xã",
        "short_codename": "lan_gioi",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Thị trấn Nhã Nam",
        "code": "thi_tran_nha_nam",
        "division_type": "thị trấn",
        "short_codename": "nha_nam",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Tân Trung",
        "code": "xa_tan_trung",
        "division_type": "xã",
        "short_codename": "tan_trung",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Đại Hóa",
        "code": "xa_dai_hoa",
        "division_type": "xã",
        "short_codename": "dai_hoa",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Quang Tiến",
        "code": "xa_quang_tien",
        "division_type": "xã",
        "short_codename": "quang_tien",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Phúc Sơn",
        "code": "xa_phuc_son",
        "division_type": "xã",
        "short_codename": "phuc_son",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã An Dương",
        "code": "xa_an_duong",
        "division_type": "xã",
        "short_codename": "an_duong",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Phúc Hòa",
        "code": "xa_phuc_hoa",
        "division_type": "xã",
        "short_codename": "phuc_hoa",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Liên Sơn",
        "code": "xa_lien_son",
        "division_type": "xã",
        "short_codename": "lien_son",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Hợp Đức",
        "code": "xa_hop_duc",
        "division_type": "xã",
        "short_codename": "hop_duc",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Lam Cốt",
        "code": "xa_lam_cot",
        "division_type": "xã",
        "short_codename": "lam_cot",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Cao Xá",
        "code": "xa_cao_xa",
        "division_type": "xã",
        "short_codename": "cao_xa",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Thị trấn Cao Thượng",
        "code": "thi_tran_cao_thuong",
        "division_type": "thị trấn",
        "short_codename": "cao_thuong",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Việt Ngọc",
        "code": "xa_viet_ngoc",
        "division_type": "xã",
        "short_codename": "viet_ngoc",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Song Vân",
        "code": "xa_song_van",
        "division_type": "xã",
        "short_codename": "song_van",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Ngọc Châu",
        "code": "xa_ngoc_chau",
        "division_type": "xã",
        "short_codename": "ngoc_chau",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Ngọc Vân",
        "code": "xa_ngoc_van",
        "division_type": "xã",
        "short_codename": "ngoc_van",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Việt Lập",
        "code": "xa_viet_lap",
        "division_type": "xã",
        "short_codename": "viet_lap",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Liên Chung",
        "code": "xa_lien_chung",
        "division_type": "xã",
        "short_codename": "lien_chung",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Ngọc Thiện",
        "code": "xa_ngoc_thien",
        "division_type": "xã",
        "short_codename": "ngoc_thien",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Ngọc Lý",
        "code": "xa_ngoc_ly",
        "division_type": "xã",
        "short_codename": "ngoc_ly",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Xã Quế Nham",
        "code": "xa_que_nham",
        "division_type": "xã",
        "short_codename": "que_nham",
        "parentCode": "huyen_tan_yen"
    },
    {
        "name": "Thị trấn Vôi",
        "code": "thi_tran_voi",
        "division_type": "thị trấn",
        "short_codename": "voi",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Nghĩa Hòa",
        "code": "xa_nghia_hoa",
        "division_type": "xã",
        "short_codename": "nghia_hoa",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Nghĩa Hưng",
        "code": "xa_nghia_hung",
        "division_type": "xã",
        "short_codename": "nghia_hung",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Quang Thịnh",
        "code": "xa_quang_thinh",
        "division_type": "xã",
        "short_codename": "quang_thinh",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Hương Sơn",
        "code": "xa_huong_son",
        "division_type": "xã",
        "short_codename": "huong_son",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Đào Mỹ",
        "code": "xa_dao_my",
        "division_type": "xã",
        "short_codename": "dao_my",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Tiên Lục",
        "code": "xa_tien_luc",
        "division_type": "xã",
        "short_codename": "tien_luc",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã An Hà",
        "code": "xa_an_ha",
        "division_type": "xã",
        "short_codename": "an_ha",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Thị trấn Kép",
        "code": "thi_tran_kep",
        "division_type": "thị trấn",
        "short_codename": "kep",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Mỹ Hà",
        "code": "xa_my_ha",
        "division_type": "xã",
        "short_codename": "my_ha",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Hương Lạc",
        "code": "xa_huong_lac",
        "division_type": "xã",
        "short_codename": "huong_lac",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Dương Đức",
        "code": "xa_duong_duc",
        "division_type": "xã",
        "short_codename": "duong_duc",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Tân Thanh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Yên Mỹ",
        "code": "xa_yen_my",
        "division_type": "xã",
        "short_codename": "yen_my",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Mỹ Thái",
        "code": "xa_my_thai",
        "division_type": "xã",
        "short_codename": "my_thai",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Xương Lâm",
        "code": "xa_xuong_lam",
        "division_type": "xã",
        "short_codename": "xuong_lam",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Xuân Hương",
        "code": "xa_xuan_huong",
        "division_type": "xã",
        "short_codename": "xuan_huong",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Tân Dĩnh",
        "code": "xa_tan_dinh",
        "division_type": "xã",
        "short_codename": "tan_dinh",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Đại Lâm",
        "code": "xa_dai_lam",
        "division_type": "xã",
        "short_codename": "dai_lam",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Xã Thái Đào",
        "code": "xa_thai_dao",
        "division_type": "xã",
        "short_codename": "thai_dao",
        "parentCode": "huyen_lang_giang"
    },
    {
        "name": "Thị trấn Đồi Ngô",
        "code": "thi_tran_doi_ngo",
        "division_type": "thị trấn",
        "short_codename": "doi_ngo",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Đông Hưng",
        "code": "xa_dong_hung",
        "division_type": "xã",
        "short_codename": "dong_hung",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Đông Phú",
        "code": "xa_dong_phu",
        "division_type": "xã",
        "short_codename": "dong_phu",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Tam Dị",
        "code": "xa_tam_di",
        "division_type": "xã",
        "short_codename": "tam_di",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Bảo Sơn",
        "code": "xa_bao_son",
        "division_type": "xã",
        "short_codename": "bao_son",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Bảo Đài",
        "code": "xa_bao_dai",
        "division_type": "xã",
        "short_codename": "bao_dai",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Thanh Lâm",
        "code": "xa_thanh_lam",
        "division_type": "xã",
        "short_codename": "thanh_lam",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Tiên Nha",
        "code": "xa_tien_nha",
        "division_type": "xã",
        "short_codename": "tien_nha",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Trường Giang",
        "code": "xa_truong_giang",
        "division_type": "xã",
        "short_codename": "truong_giang",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Phương Sơn",
        "code": "xa_phuong_son",
        "division_type": "xã",
        "short_codename": "phuong_son",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Chu Điện",
        "code": "xa_chu_dien",
        "division_type": "xã",
        "short_codename": "chu_dien",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Cương Sơn",
        "code": "xa_cuong_son",
        "division_type": "xã",
        "short_codename": "cuong_son",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Nghĩa Phương",
        "code": "xa_nghia_phuong",
        "division_type": "xã",
        "short_codename": "nghia_phuong",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Vô Tranh",
        "code": "xa_vo_tranh",
        "division_type": "xã",
        "short_codename": "vo_tranh",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Bình Sơn",
        "code": "xa_binh_son",
        "division_type": "xã",
        "short_codename": "binh_son",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Lan Mẫu",
        "code": "xa_lan_mau",
        "division_type": "xã",
        "short_codename": "lan_mau",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Yên Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Khám Lạng",
        "code": "xa_kham_lang",
        "division_type": "xã",
        "short_codename": "kham_lang",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Huyền Sơn",
        "code": "xa_huyen_son",
        "division_type": "xã",
        "short_codename": "huyen_son",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Trường Sơn",
        "code": "xa_truong_son",
        "division_type": "xã",
        "short_codename": "truong_son",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Lục Sơn",
        "code": "xa_luc_son",
        "division_type": "xã",
        "short_codename": "luc_son",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Bắc Lũng",
        "code": "xa_bac_lung",
        "division_type": "xã",
        "short_codename": "bac_lung",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Vũ Xá",
        "code": "xa_vu_xa",
        "division_type": "xã",
        "short_codename": "vu_xa",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Cẩm Lý",
        "code": "xa_cam_ly",
        "division_type": "xã",
        "short_codename": "cam_ly",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Xã Đan Hội",
        "code": "xa_dan_hoi",
        "division_type": "xã",
        "short_codename": "dan_hoi",
        "parentCode": "huyen_luc_nam"
    },
    {
        "name": "Thị trấn Chũ",
        "code": "thi_tran_chu",
        "division_type": "thị trấn",
        "short_codename": "chu",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Cấm Sơn",
        "code": "xa_cam_son",
        "division_type": "xã",
        "short_codename": "cam_son",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Tân Sơn",
        "code": "xa_tan_son",
        "division_type": "xã",
        "short_codename": "tan_son",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Phong Minh",
        "code": "xa_phong_minh",
        "division_type": "xã",
        "short_codename": "phong_minh",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Phong Vân",
        "code": "xa_phong_van",
        "division_type": "xã",
        "short_codename": "phong_van",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Xa Lý",
        "code": "xa_xa_ly",
        "division_type": "xã",
        "short_codename": "xa_ly",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Hộ Đáp",
        "code": "xa_ho_dap",
        "division_type": "xã",
        "short_codename": "ho_dap",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Sơn Hải",
        "code": "xa_son_hai",
        "division_type": "xã",
        "short_codename": "son_hai",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Thanh Hải",
        "code": "xa_thanh_hai",
        "division_type": "xã",
        "short_codename": "thanh_hai",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Kiên Lao",
        "code": "xa_kien_lao",
        "division_type": "xã",
        "short_codename": "kien_lao",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Biên Sơn",
        "code": "xa_bien_son",
        "division_type": "xã",
        "short_codename": "bien_son",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Kiên Thành",
        "code": "xa_kien_thanh",
        "division_type": "xã",
        "short_codename": "kien_thanh",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Hồng Giang",
        "code": "xa_hong_giang",
        "division_type": "xã",
        "short_codename": "hong_giang",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Kim Sơn",
        "code": "xa_kim_son",
        "division_type": "xã",
        "short_codename": "kim_son",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Tân Hoa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Giáp Sơn",
        "code": "xa_giap_son",
        "division_type": "xã",
        "short_codename": "giap_son",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Biển Động",
        "code": "xa_bien_dong",
        "division_type": "xã",
        "short_codename": "bien_dong",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Quý Sơn",
        "code": "xa_quy_son",
        "division_type": "xã",
        "short_codename": "quy_son",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Trù Hựu",
        "code": "xa_tru_huu",
        "division_type": "xã",
        "short_codename": "tru_huu",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Phì Điền",
        "code": "xa_phi_dien",
        "division_type": "xã",
        "short_codename": "phi_dien",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Tân Quang",
        "code": "xa_tan_quang",
        "division_type": "xã",
        "short_codename": "tan_quang",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Đồng Cốc",
        "code": "xa_dong_coc",
        "division_type": "xã",
        "short_codename": "dong_coc",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Phú Nhuận",
        "code": "xa_phu_nhuan",
        "division_type": "xã",
        "short_codename": "phu_nhuan",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Mỹ An",
        "code": "xa_my_an",
        "division_type": "xã",
        "short_codename": "my_an",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Nam Dương",
        "code": "xa_nam_duong",
        "division_type": "xã",
        "short_codename": "nam_duong",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Tân Mộc",
        "code": "xa_tan_moc",
        "division_type": "xã",
        "short_codename": "tan_moc",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Đèo Gia",
        "code": "xa_deo_gia",
        "division_type": "xã",
        "short_codename": "deo_gia",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Xã Phượng Sơn",
        "code": "xa_phuong_son",
        "division_type": "xã",
        "short_codename": "phuong_son",
        "parentCode": "huyen_luc_ngan"
    },
    {
        "name": "Thị trấn An Châu",
        "code": "thi_tran_an_chau",
        "division_type": "thị trấn",
        "short_codename": "an_chau",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Thị trấn Tây Yên Tử",
        "code": "thi_tran_tay_yen_tu",
        "division_type": "thị trấn",
        "short_codename": "tay_yen_tu",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Vân Sơn",
        "code": "xa_van_son",
        "division_type": "xã",
        "short_codename": "van_son",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Hữu Sản",
        "code": "xa_huu_san",
        "division_type": "xã",
        "short_codename": "huu_san",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Đại Sơn",
        "code": "xa_dai_son",
        "division_type": "xã",
        "short_codename": "dai_son",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Phúc Sơn",
        "code": "xa_phuc_son",
        "division_type": "xã",
        "short_codename": "phuc_son",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Giáo Liêm",
        "code": "xa_giao_liem",
        "division_type": "xã",
        "short_codename": "giao_liem",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Cẩm Đàn",
        "code": "xa_cam_dan",
        "division_type": "xã",
        "short_codename": "cam_dan",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã An Lạc",
        "code": "xa_an_lac",
        "division_type": "xã",
        "short_codename": "an_lac",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Vĩnh An",
        "code": "xa_vinh_an",
        "division_type": "xã",
        "short_codename": "vinh_an",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Yên Định",
        "code": "xa_yen_dinh",
        "division_type": "xã",
        "short_codename": "yen_dinh",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Lệ Viễn",
        "code": "xa_le_vien",
        "division_type": "xã",
        "short_codename": "le_vien",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã An Bá",
        "code": "xa_an_ba",
        "division_type": "xã",
        "short_codename": "an_ba",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Tuấn Đạo",
        "code": "xa_tuan_dao",
        "division_type": "xã",
        "short_codename": "tuan_dao",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Dương Hưu",
        "code": "xa_duong_huu",
        "division_type": "xã",
        "short_codename": "duong_huu",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Long Sơn",
        "code": "xa_long_son",
        "division_type": "xã",
        "short_codename": "long_son",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Xã Thanh Luận",
        "code": "xa_thanh_luan",
        "division_type": "xã",
        "short_codename": "thanh_luan",
        "parentCode": "huyen_son_dong"
    },
    {
        "name": "Thị trấn Nham Biền",
        "code": "thi_tran_nham_bien",
        "division_type": "thị trấn",
        "short_codename": "nham_bien",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Thị trấn Tân An",
        "code": "thi_tran_tan_an",
        "division_type": "thị trấn",
        "short_codename": "tan_an",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Lão Hộ",
        "code": "xa_lao_ho",
        "division_type": "xã",
        "short_codename": "lao_ho",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Hương Gián",
        "code": "xa_huong_gian",
        "division_type": "xã",
        "short_codename": "huong_gian",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Quỳnh Sơn",
        "code": "xa_quynh_son",
        "division_type": "xã",
        "short_codename": "quynh_son",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Nội Hoàng",
        "code": "xa_noi_hoang",
        "division_type": "xã",
        "short_codename": "noi_hoang",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Tiền Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Xuân Phú",
        "code": "xa_xuan_phu",
        "division_type": "xã",
        "short_codename": "xuan_phu",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Tân Liễu",
        "code": "xa_tan_lieu",
        "division_type": "xã",
        "short_codename": "tan_lieu",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Trí Yên",
        "code": "xa_tri_yen",
        "division_type": "xã",
        "short_codename": "tri_yen",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Lãng Sơn",
        "code": "xa_lang_son",
        "division_type": "xã",
        "short_codename": "lang_son",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Yên Lư",
        "code": "xa_yen_lu",
        "division_type": "xã",
        "short_codename": "yen_lu",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Tiến Dũng",
        "code": "xa_tien_dung",
        "division_type": "xã",
        "short_codename": "tien_dung",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Đức Giang",
        "code": "xa_duc_giang",
        "division_type": "xã",
        "short_codename": "duc_giang",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Cảnh Thụy",
        "code": "xa_canh_thuy",
        "division_type": "xã",
        "short_codename": "canh_thuy",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Tư Mại",
        "code": "xa_tu_mai",
        "division_type": "xã",
        "short_codename": "tu_mai",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Đồng Việt",
        "code": "xa_dong_viet",
        "division_type": "xã",
        "short_codename": "dong_viet",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Đồng Phúc",
        "code": "xa_dong_phuc",
        "division_type": "xã",
        "short_codename": "dong_phuc",
        "parentCode": "huyen_yen_dung"
    },
    {
        "name": "Xã Thượng Lan",
        "code": "xa_thuong_lan",
        "division_type": "xã",
        "short_codename": "thuong_lan",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Việt Tiến",
        "code": "xa_viet_tien",
        "division_type": "xã",
        "short_codename": "viet_tien",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Nghĩa Trung",
        "code": "xa_nghia_trung",
        "division_type": "xã",
        "short_codename": "nghia_trung",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Minh Đức",
        "code": "xa_minh_duc",
        "division_type": "xã",
        "short_codename": "minh_duc",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Hương Mai",
        "code": "xa_huong_mai",
        "division_type": "xã",
        "short_codename": "huong_mai",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Tự Lạn",
        "code": "xa_tu_lan",
        "division_type": "xã",
        "short_codename": "tu_lan",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Thị trấn Bích Động",
        "code": "thi_tran_bich_dong",
        "division_type": "thị trấn",
        "short_codename": "bich_dong",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Trung Sơn",
        "code": "xa_trung_son",
        "division_type": "xã",
        "short_codename": "trung_son",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Hồng Thái",
        "code": "xa_hong_thai",
        "division_type": "xã",
        "short_codename": "hong_thai",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Tiên Sơn",
        "code": "xa_tien_son",
        "division_type": "xã",
        "short_codename": "tien_son",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Tăng Tiến",
        "code": "xa_tang_tien",
        "division_type": "xã",
        "short_codename": "tang_tien",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Quảng Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Thị trấn Nếnh",
        "code": "thi_tran_nenh",
        "division_type": "thị trấn",
        "short_codename": "nenh",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Ninh Sơn",
        "code": "xa_ninh_son",
        "division_type": "xã",
        "short_codename": "ninh_son",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Vân Trung",
        "code": "xa_van_trung",
        "division_type": "xã",
        "short_codename": "van_trung",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Vân Hà",
        "code": "xa_van_ha",
        "division_type": "xã",
        "short_codename": "van_ha",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Quang Châu",
        "code": "xa_quang_chau",
        "division_type": "xã",
        "short_codename": "quang_chau",
        "parentCode": "huyen_viet_yen"
    },
    {
        "name": "Xã Đồng Tân",
        "code": "xa_dong_tan",
        "division_type": "xã",
        "short_codename": "dong_tan",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Thanh Vân",
        "code": "xa_thanh_van",
        "division_type": "xã",
        "short_codename": "thanh_van",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Hoàng Lương",
        "code": "xa_hoang_luong",
        "division_type": "xã",
        "short_codename": "hoang_luong",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Hoàng Vân",
        "code": "xa_hoang_van",
        "division_type": "xã",
        "short_codename": "hoang_van",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Hoàng Thanh",
        "code": "xa_hoang_thanh",
        "division_type": "xã",
        "short_codename": "hoang_thanh",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Hoàng An",
        "code": "xa_hoang_an",
        "division_type": "xã",
        "short_codename": "hoang_an",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Ngọc Sơn",
        "code": "xa_ngoc_son",
        "division_type": "xã",
        "short_codename": "ngoc_son",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Thái Sơn",
        "code": "xa_thai_son",
        "division_type": "xã",
        "short_codename": "thai_son",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Hòa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Thị trấn Thắng",
        "code": "thi_tran_thang",
        "division_type": "thị trấn",
        "short_codename": "thang",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Quang Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Lương Phong",
        "code": "xa_luong_phong",
        "division_type": "xã",
        "short_codename": "luong_phong",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Hùng Sơn",
        "code": "xa_hung_son",
        "division_type": "xã",
        "short_codename": "hung_son",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Đại Thành",
        "code": "xa_dai_thanh",
        "division_type": "xã",
        "short_codename": "dai_thanh",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Thường Thắng",
        "code": "xa_thuong_thang",
        "division_type": "xã",
        "short_codename": "thuong_thang",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Hợp Thịnh",
        "code": "xa_hop_thinh",
        "division_type": "xã",
        "short_codename": "hop_thinh",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Danh Thắng",
        "code": "xa_danh_thang",
        "division_type": "xã",
        "short_codename": "danh_thang",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Mai Trung",
        "code": "xa_mai_trung",
        "division_type": "xã",
        "short_codename": "mai_trung",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Đoan Bái",
        "code": "xa_doan_bai",
        "division_type": "xã",
        "short_codename": "doan_bai",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Bắc Lý",
        "code": "xa_bac_ly",
        "division_type": "xã",
        "short_codename": "bac_ly",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Xuân Cẩm",
        "code": "xa_xuan_cam",
        "division_type": "xã",
        "short_codename": "xuan_cam",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Hương Lâm",
        "code": "xa_huong_lam",
        "division_type": "xã",
        "short_codename": "huong_lam",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Đông Lỗ",
        "code": "xa_dong_lo",
        "division_type": "xã",
        "short_codename": "dong_lo",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Châu Minh",
        "code": "xa_chau_minh",
        "division_type": "xã",
        "short_codename": "chau_minh",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Xã Mai Đình",
        "code": "xa_mai_dinh",
        "division_type": "xã",
        "short_codename": "mai_dinh",
        "parentCode": "huyen_hiep_hoa"
    },
    {
        "name": "Phường Dữu Lâu",
        "code": "phuong_duu_lau",
        "division_type": "phường",
        "short_codename": "duu_lau",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Vân Cơ",
        "code": "phuong_van_co",
        "division_type": "phường",
        "short_codename": "van_co",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Nông Trang",
        "code": "phuong_nong_trang",
        "division_type": "phường",
        "short_codename": "nong_trang",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Tân Dân",
        "code": "phuong_tan_dan",
        "division_type": "phường",
        "short_codename": "tan_dan",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Gia Cẩm",
        "code": "phuong_gia_cam",
        "division_type": "phường",
        "short_codename": "gia_cam",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Tiên Cát",
        "code": "phuong_tien_cat",
        "division_type": "phường",
        "short_codename": "tien_cat",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Thọ Sơn",
        "code": "phuong_tho_son",
        "division_type": "phường",
        "short_codename": "tho_son",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Thanh Miếu",
        "code": "phuong_thanh_mieu",
        "division_type": "phường",
        "short_codename": "thanh_mieu",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Bạch Hạc",
        "code": "phuong_bach_hac",
        "division_type": "phường",
        "short_codename": "bach_hac",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Bến Gót",
        "code": "phuong_ben_got",
        "division_type": "phường",
        "short_codename": "ben_got",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Vân Phú",
        "code": "phuong_van_phu",
        "division_type": "phường",
        "short_codename": "van_phu",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Xã Phượng Lâu",
        "code": "xa_phuong_lau",
        "division_type": "xã",
        "short_codename": "phuong_lau",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Xã Thụy Vân",
        "code": "xa_thuy_van",
        "division_type": "xã",
        "short_codename": "thuy_van",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Minh Phương",
        "code": "phuong_minh_phuong",
        "division_type": "phường",
        "short_codename": "minh_phuong",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Xã Trưng Vương",
        "code": "xa_trung_vuong",
        "division_type": "xã",
        "short_codename": "trung_vuong",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Minh Nông",
        "code": "phuong_minh_nong",
        "division_type": "phường",
        "short_codename": "minh_nong",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Xã Sông Lô",
        "code": "xa_song_lo",
        "division_type": "xã",
        "short_codename": "song_lo",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Xã Kim Đức",
        "code": "xa_kim_duc",
        "division_type": "xã",
        "short_codename": "kim_duc",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Xã Hùng Lô",
        "code": "xa_hung_lo",
        "division_type": "xã",
        "short_codename": "hung_lo",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Xã Hy Cương",
        "code": "xa_hy_cuong",
        "division_type": "xã",
        "short_codename": "hy_cuong",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Xã Chu Hóa",
        "code": "xa_chu_hoa",
        "division_type": "xã",
        "short_codename": "chu_hoa",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Xã Thanh Đình",
        "code": "xa_thanh_dinh",
        "division_type": "xã",
        "short_codename": "thanh_dinh",
        "parentCode": "thanh_pho_viet_tri"
    },
    {
        "name": "Phường Hùng Vương",
        "code": "phuong_hung_vuong",
        "division_type": "phường",
        "short_codename": "hung_vuong",
        "parentCode": "thi_xa_phu_tho"
    },
    {
        "name": "Phường Phong Châu",
        "code": "phuong_phong_chau",
        "division_type": "phường",
        "short_codename": "phong_chau",
        "parentCode": "thi_xa_phu_tho"
    },
    {
        "name": "Phường Âu Cơ",
        "code": "phuong_au_co",
        "division_type": "phường",
        "short_codename": "au_co",
        "parentCode": "thi_xa_phu_tho"
    },
    {
        "name": "Xã Hà Lộc",
        "code": "xa_ha_loc",
        "division_type": "xã",
        "short_codename": "ha_loc",
        "parentCode": "thi_xa_phu_tho"
    },
    {
        "name": "Xã Phú Hộ",
        "code": "xa_phu_ho",
        "division_type": "xã",
        "short_codename": "phu_ho",
        "parentCode": "thi_xa_phu_tho"
    },
    {
        "name": "Xã Văn Lung",
        "code": "xa_van_lung",
        "division_type": "xã",
        "short_codename": "van_lung",
        "parentCode": "thi_xa_phu_tho"
    },
    {
        "name": "Xã Thanh Minh",
        "code": "xa_thanh_minh",
        "division_type": "xã",
        "short_codename": "thanh_minh",
        "parentCode": "thi_xa_phu_tho"
    },
    {
        "name": "Xã Hà Thạch",
        "code": "xa_ha_thach",
        "division_type": "xã",
        "short_codename": "ha_thach",
        "parentCode": "thi_xa_phu_tho"
    },
    {
        "name": "Phường Thanh Vinh",
        "code": "phuong_thanh_vinh",
        "division_type": "phường",
        "short_codename": "thanh_vinh",
        "parentCode": "thi_xa_phu_tho"
    },
    {
        "name": "Thị trấn Đoan Hùng",
        "code": "thi_tran_doan_hung",
        "division_type": "thị trấn",
        "short_codename": "doan_hung",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Hùng Xuyên",
        "code": "xa_hung_xuyen",
        "division_type": "xã",
        "short_codename": "hung_xuyen",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Bằng Luân",
        "code": "xa_bang_luan",
        "division_type": "xã",
        "short_codename": "bang_luan",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Vân Du",
        "code": "xa_van_du",
        "division_type": "xã",
        "short_codename": "van_du",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Phú Lâm",
        "code": "xa_phu_lam",
        "division_type": "xã",
        "short_codename": "phu_lam",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Minh Lương",
        "code": "xa_minh_luong",
        "division_type": "xã",
        "short_codename": "minh_luong",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Bằng Doãn",
        "code": "xa_bang_doan",
        "division_type": "xã",
        "short_codename": "bang_doan",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Chí Đám",
        "code": "xa_chi_dam",
        "division_type": "xã",
        "short_codename": "chi_dam",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Phúc Lai",
        "code": "xa_phuc_lai",
        "division_type": "xã",
        "short_codename": "phuc_lai",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Ngọc Quan",
        "code": "xa_ngoc_quan",
        "division_type": "xã",
        "short_codename": "ngoc_quan",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Hợp Nhất",
        "code": "xa_hop_nhat",
        "division_type": "xã",
        "short_codename": "hop_nhat",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Sóc Đăng",
        "code": "xa_soc_dang",
        "division_type": "xã",
        "short_codename": "soc_dang",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Tây Cốc",
        "code": "xa_tay_coc",
        "division_type": "xã",
        "short_codename": "tay_coc",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Yên Kiện",
        "code": "xa_yen_kien",
        "division_type": "xã",
        "short_codename": "yen_kien",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Hùng Long",
        "code": "xa_hung_long",
        "division_type": "xã",
        "short_codename": "hung_long",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Vụ Quang",
        "code": "xa_vu_quang",
        "division_type": "xã",
        "short_codename": "vu_quang",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Vân Đồn",
        "code": "xa_van_don",
        "division_type": "xã",
        "short_codename": "van_don",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Tiêu Sơn",
        "code": "xa_tieu_son",
        "division_type": "xã",
        "short_codename": "tieu_son",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Minh Tiến",
        "code": "xa_minh_tien",
        "division_type": "xã",
        "short_codename": "minh_tien",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Minh Phú",
        "code": "xa_minh_phu",
        "division_type": "xã",
        "short_codename": "minh_phu",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Chân Mộng",
        "code": "xa_chan_mong",
        "division_type": "xã",
        "short_codename": "chan_mong",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Xã Ca Đình",
        "code": "xa_ca_dinh",
        "division_type": "xã",
        "short_codename": "ca_dinh",
        "parentCode": "huyen_doan_hung"
    },
    {
        "name": "Thị trấn Hạ Hoà",
        "code": "thi_tran_ha_hoa",
        "division_type": "thị trấn",
        "short_codename": "ha_hoa",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Đại Phạm",
        "code": "xa_dai_pham",
        "division_type": "xã",
        "short_codename": "dai_pham",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Đan Thượng",
        "code": "xa_dan_thuong",
        "division_type": "xã",
        "short_codename": "dan_thuong",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Hà Lương",
        "code": "xa_ha_luong",
        "division_type": "xã",
        "short_codename": "ha_luong",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Tứ Hiệp",
        "code": "xa_tu_hiep",
        "division_type": "xã",
        "short_codename": "tu_hiep",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Hiền Lương",
        "code": "xa_hien_luong",
        "division_type": "xã",
        "short_codename": "hien_luong",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Phương Viên",
        "code": "xa_phuong_vien",
        "division_type": "xã",
        "short_codename": "phuong_vien",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Gia Điền",
        "code": "xa_gia_dien",
        "division_type": "xã",
        "short_codename": "gia_dien",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Ấm Hạ",
        "code": "xa_am_ha",
        "division_type": "xã",
        "short_codename": "am_ha",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Hương Xạ",
        "code": "xa_huong_xa",
        "division_type": "xã",
        "short_codename": "huong_xa",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Xuân Áng",
        "code": "xa_xuan_ang",
        "division_type": "xã",
        "short_codename": "xuan_ang",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Yên Kỳ",
        "code": "xa_yen_ky",
        "division_type": "xã",
        "short_codename": "yen_ky",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Minh Hạc",
        "code": "xa_minh_hac",
        "division_type": "xã",
        "short_codename": "minh_hac",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Lang Sơn",
        "code": "xa_lang_son",
        "division_type": "xã",
        "short_codename": "lang_son",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Bằng Giã",
        "code": "xa_bang_gia",
        "division_type": "xã",
        "short_codename": "bang_gia",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Yên Luật",
        "code": "xa_yen_luat",
        "division_type": "xã",
        "short_codename": "yen_luat",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Vô Tranh",
        "code": "xa_vo_tranh",
        "division_type": "xã",
        "short_codename": "vo_tranh",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Văn Lang",
        "code": "xa_van_lang",
        "division_type": "xã",
        "short_codename": "van_lang",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Minh Côi",
        "code": "xa_minh_coi",
        "division_type": "xã",
        "short_codename": "minh_coi",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Xã Vĩnh Chân",
        "code": "xa_vinh_chan",
        "division_type": "xã",
        "short_codename": "vinh_chan",
        "parentCode": "huyen_ha_hoa"
    },
    {
        "name": "Thị trấn Thanh Ba",
        "code": "thi_tran_thanh_ba",
        "division_type": "thị trấn",
        "short_codename": "thanh_ba",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Vân Lĩnh",
        "code": "xa_van_linh",
        "division_type": "xã",
        "short_codename": "van_linh",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Đông Lĩnh",
        "code": "xa_dong_linh",
        "division_type": "xã",
        "short_codename": "dong_linh",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Đại An",
        "code": "xa_dai_an",
        "division_type": "xã",
        "short_codename": "dai_an",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Hanh Cù",
        "code": "xa_hanh_cu",
        "division_type": "xã",
        "short_codename": "hanh_cu",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Đồng Xuân",
        "code": "xa_dong_xuan",
        "division_type": "xã",
        "short_codename": "dong_xuan",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Quảng Yên",
        "code": "xa_quang_yen",
        "division_type": "xã",
        "short_codename": "quang_yen",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Ninh Dân",
        "code": "xa_ninh_dan",
        "division_type": "xã",
        "short_codename": "ninh_dan",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Võ Lao",
        "code": "xa_vo_lao",
        "division_type": "xã",
        "short_codename": "vo_lao",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Khải Xuân",
        "code": "xa_khai_xuan",
        "division_type": "xã",
        "short_codename": "khai_xuan",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Mạn Lạn",
        "code": "xa_man_lan",
        "division_type": "xã",
        "short_codename": "man_lan",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Hoàng Cương",
        "code": "xa_hoang_cuong",
        "division_type": "xã",
        "short_codename": "hoang_cuong",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Chí Tiên",
        "code": "xa_chi_tien",
        "division_type": "xã",
        "short_codename": "chi_tien",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Đông Thành",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Sơn Cương",
        "code": "xa_son_cuong",
        "division_type": "xã",
        "short_codename": "son_cuong",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Thanh Hà",
        "code": "xa_thanh_ha",
        "division_type": "xã",
        "short_codename": "thanh_ha",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Đỗ Sơn",
        "code": "xa_do_son",
        "division_type": "xã",
        "short_codename": "do_son",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Đỗ Xuyên",
        "code": "xa_do_xuyen",
        "division_type": "xã",
        "short_codename": "do_xuyen",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Xã Lương Lỗ",
        "code": "xa_luong_lo",
        "division_type": "xã",
        "short_codename": "luong_lo",
        "parentCode": "huyen_thanh_ba"
    },
    {
        "name": "Thị trấn Phong Châu",
        "code": "thi_tran_phong_chau",
        "division_type": "thị trấn",
        "short_codename": "phong_chau",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Phú Mỹ",
        "code": "xa_phu_my",
        "division_type": "xã",
        "short_codename": "phu_my",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Lệ Mỹ",
        "code": "xa_le_my",
        "division_type": "xã",
        "short_codename": "le_my",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Liên Hoa",
        "code": "xa_lien_hoa",
        "division_type": "xã",
        "short_codename": "lien_hoa",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Trạm Thản",
        "code": "xa_tram_than",
        "division_type": "xã",
        "short_codename": "tram_than",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Trị Quận",
        "code": "xa_tri_quan",
        "division_type": "xã",
        "short_codename": "tri_quan",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Trung Giáp",
        "code": "xa_trung_giap",
        "division_type": "xã",
        "short_codename": "trung_giap",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tiên Phú",
        "code": "xa_tien_phu",
        "division_type": "xã",
        "short_codename": "tien_phu",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Hạ Giáp",
        "code": "xa_ha_giap",
        "division_type": "xã",
        "short_codename": "ha_giap",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Bảo Thanh",
        "code": "xa_bao_thanh",
        "division_type": "xã",
        "short_codename": "bao_thanh",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Phú Lộc",
        "code": "xa_phu_loc",
        "division_type": "xã",
        "short_codename": "phu_loc",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Gia Thanh",
        "code": "xa_gia_thanh",
        "division_type": "xã",
        "short_codename": "gia_thanh",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tiên Du",
        "code": "xa_tien_du",
        "division_type": "xã",
        "short_codename": "tien_du",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Phú Nham",
        "code": "xa_phu_nham",
        "division_type": "xã",
        "short_codename": "phu_nham",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã An Đạo",
        "code": "xa_an_dao",
        "division_type": "xã",
        "short_codename": "an_dao",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Phù Ninh",
        "code": "xa_phu_ninh",
        "division_type": "xã",
        "short_codename": "phu_ninh",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Thị trấn Yên Lập",
        "code": "thi_tran_yen_lap",
        "division_type": "thị trấn",
        "short_codename": "yen_lap",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Mỹ Lung",
        "code": "xa_my_lung",
        "division_type": "xã",
        "short_codename": "my_lung",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Mỹ Lương",
        "code": "xa_my_luong",
        "division_type": "xã",
        "short_codename": "my_luong",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Lương Sơn",
        "code": "xa_luong_son",
        "division_type": "xã",
        "short_codename": "luong_son",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Xuân An",
        "code": "xa_xuan_an",
        "division_type": "xã",
        "short_codename": "xuan_an",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Xuân Viên",
        "code": "xa_xuan_vien",
        "division_type": "xã",
        "short_codename": "xuan_vien",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Xuân Thủy",
        "code": "xa_xuan_thuy",
        "division_type": "xã",
        "short_codename": "xuan_thuy",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Trung Sơn",
        "code": "xa_trung_son",
        "division_type": "xã",
        "short_codename": "trung_son",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Hưng Long",
        "code": "xa_hung_long",
        "division_type": "xã",
        "short_codename": "hung_long",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Nga Hoàng",
        "code": "xa_nga_hoang",
        "division_type": "xã",
        "short_codename": "nga_hoang",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Đồng Lạc",
        "code": "xa_dong_lac",
        "division_type": "xã",
        "short_codename": "dong_lac",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Thượng Long",
        "code": "xa_thuong_long",
        "division_type": "xã",
        "short_codename": "thuong_long",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Đồng Thịnh",
        "code": "xa_dong_thinh",
        "division_type": "xã",
        "short_codename": "dong_thinh",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Phúc Khánh",
        "code": "xa_phuc_khanh",
        "division_type": "xã",
        "short_codename": "phuc_khanh",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Minh Hòa",
        "code": "xa_minh_hoa",
        "division_type": "xã",
        "short_codename": "minh_hoa",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Ngọc Lập",
        "code": "xa_ngoc_lap",
        "division_type": "xã",
        "short_codename": "ngoc_lap",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Xã Ngọc Đồng",
        "code": "xa_ngoc_dong",
        "division_type": "xã",
        "short_codename": "ngoc_dong",
        "parentCode": "huyen_yen_lap"
    },
    {
        "name": "Thị trấn Cẩm Khê",
        "code": "thi_tran_cam_khe",
        "division_type": "thị trấn",
        "short_codename": "cam_khe",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Tiên Lương",
        "code": "xa_tien_luong",
        "division_type": "xã",
        "short_codename": "tien_luong",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Tuy Lộc",
        "code": "xa_tuy_loc",
        "division_type": "xã",
        "short_codename": "tuy_loc",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Ngô Xá",
        "code": "xa_ngo_xa",
        "division_type": "xã",
        "short_codename": "ngo_xa",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Phượng Vĩ",
        "code": "xa_phuong_vi",
        "division_type": "xã",
        "short_codename": "phuong_vi",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Thụy Liễu",
        "code": "xa_thuy_lieu",
        "division_type": "xã",
        "short_codename": "thuy_lieu",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Tùng Khê",
        "code": "xa_tung_khe",
        "division_type": "xã",
        "short_codename": "tung_khe",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Tam Sơn",
        "code": "xa_tam_son",
        "division_type": "xã",
        "short_codename": "tam_son",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Văn Bán",
        "code": "xa_van_ban",
        "division_type": "xã",
        "short_codename": "van_ban",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Cấp Dẫn",
        "code": "xa_cap_dan",
        "division_type": "xã",
        "short_codename": "cap_dan",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Xương Thịnh",
        "code": "xa_xuong_thinh",
        "division_type": "xã",
        "short_codename": "xuong_thinh",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Phú Khê",
        "code": "xa_phu_khe",
        "division_type": "xã",
        "short_codename": "phu_khe",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Sơn Tình",
        "code": "xa_son_tinh",
        "division_type": "xã",
        "short_codename": "son_tinh",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Yên Tập",
        "code": "xa_yen_tap",
        "division_type": "xã",
        "short_codename": "yen_tap",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Hương Lung",
        "code": "xa_huong_lung",
        "division_type": "xã",
        "short_codename": "huong_lung",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Tạ Xá",
        "code": "xa_ta_xa",
        "division_type": "xã",
        "short_codename": "ta_xa",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Phú Lạc",
        "code": "xa_phu_lac",
        "division_type": "xã",
        "short_codename": "phu_lac",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Chương Xá",
        "code": "xa_chuong_xa",
        "division_type": "xã",
        "short_codename": "chuong_xa",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Hùng Việt",
        "code": "xa_hung_viet",
        "division_type": "xã",
        "short_codename": "hung_viet",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Văn Khúc",
        "code": "xa_van_khuc",
        "division_type": "xã",
        "short_codename": "van_khuc",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Yên Dưỡng",
        "code": "xa_yen_duong",
        "division_type": "xã",
        "short_codename": "yen_duong",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Điêu Lương",
        "code": "xa_dieu_luong",
        "division_type": "xã",
        "short_codename": "dieu_luong",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Xã Đồng Lương",
        "code": "xa_dong_luong",
        "division_type": "xã",
        "short_codename": "dong_luong",
        "parentCode": "huyen_cam_khe"
    },
    {
        "name": "Thị trấn Hưng Hoá",
        "code": "thi_tran_hung_hoa",
        "division_type": "thị trấn",
        "short_codename": "hung_hoa",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Hiền Quan",
        "code": "xa_hien_quan",
        "division_type": "xã",
        "short_codename": "hien_quan",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Thanh Uyên",
        "code": "xa_thanh_uyen",
        "division_type": "xã",
        "short_codename": "thanh_uyen",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Lam Sơn",
        "code": "xa_lam_son",
        "division_type": "xã",
        "short_codename": "lam_son",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Vạn Xuân",
        "code": "xa_van_xuan",
        "division_type": "xã",
        "short_codename": "van_xuan",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Quang Húc",
        "code": "xa_quang_huc",
        "division_type": "xã",
        "short_codename": "quang_huc",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Hương Nộn",
        "code": "xa_huong_non",
        "division_type": "xã",
        "short_codename": "huong_non",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Tề Lễ",
        "code": "xa_te_le",
        "division_type": "xã",
        "short_codename": "te_le",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Thọ Văn",
        "code": "xa_tho_van",
        "division_type": "xã",
        "short_codename": "tho_van",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Dị Nậu",
        "code": "xa_di_nau",
        "division_type": "xã",
        "short_codename": "di_nau",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Dân Quyền",
        "code": "xa_dan_quyen",
        "division_type": "xã",
        "short_codename": "dan_quyen",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Thị trấn Lâm Thao",
        "code": "thi_tran_lam_thao",
        "division_type": "thị trấn",
        "short_codename": "lam_thao",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Tiên Kiên",
        "code": "xa_tien_kien",
        "division_type": "xã",
        "short_codename": "tien_kien",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Thị trấn Hùng Sơn",
        "code": "thi_tran_hung_son",
        "division_type": "thị trấn",
        "short_codename": "hung_son",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Xuân Lũng",
        "code": "xa_xuan_lung",
        "division_type": "xã",
        "short_codename": "xuan_lung",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Xuân Huy",
        "code": "xa_xuan_huy",
        "division_type": "xã",
        "short_codename": "xuan_huy",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Thạch Sơn",
        "code": "xa_thach_son",
        "division_type": "xã",
        "short_codename": "thach_son",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Sơn Vi",
        "code": "xa_son_vi",
        "division_type": "xã",
        "short_codename": "son_vi",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Phùng Nguyên",
        "code": "xa_phung_nguyen",
        "division_type": "xã",
        "short_codename": "phung_nguyen",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Cao Xá",
        "code": "xa_cao_xa",
        "division_type": "xã",
        "short_codename": "cao_xa",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Vĩnh Lại",
        "code": "xa_vinh_lai",
        "division_type": "xã",
        "short_codename": "vinh_lai",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Tứ Xã",
        "code": "xa_tu_xa",
        "division_type": "xã",
        "short_codename": "tu_xa",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Xã Bản Nguyên",
        "code": "xa_ban_nguyen",
        "division_type": "xã",
        "short_codename": "ban_nguyen",
        "parentCode": "huyen_lam_thao"
    },
    {
        "name": "Thị trấn Thanh Sơn",
        "code": "thi_tran_thanh_son",
        "division_type": "thị trấn",
        "short_codename": "thanh_son",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Sơn Hùng",
        "code": "xa_son_hung",
        "division_type": "xã",
        "short_codename": "son_hung",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Địch Quả",
        "code": "xa_dich_qua",
        "division_type": "xã",
        "short_codename": "dich_qua",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Giáp Lai",
        "code": "xa_giap_lai",
        "division_type": "xã",
        "short_codename": "giap_lai",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Thục Luyện",
        "code": "xa_thuc_luyen",
        "division_type": "xã",
        "short_codename": "thuc_luyen",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Võ Miếu",
        "code": "xa_vo_mieu",
        "division_type": "xã",
        "short_codename": "vo_mieu",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Thạch Khoán",
        "code": "xa_thach_khoan",
        "division_type": "xã",
        "short_codename": "thach_khoan",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Cự Thắng",
        "code": "xa_cu_thang",
        "division_type": "xã",
        "short_codename": "cu_thang",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Tất Thắng",
        "code": "xa_tat_thang",
        "division_type": "xã",
        "short_codename": "tat_thang",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Văn Miếu",
        "code": "xa_van_mieu",
        "division_type": "xã",
        "short_codename": "van_mieu",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Cự Đồng",
        "code": "xa_cu_dong",
        "division_type": "xã",
        "short_codename": "cu_dong",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Thắng Sơn",
        "code": "xa_thang_son",
        "division_type": "xã",
        "short_codename": "thang_son",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Tân Minh",
        "code": "xa_tan_minh",
        "division_type": "xã",
        "short_codename": "tan_minh",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Hương Cần",
        "code": "xa_huong_can",
        "division_type": "xã",
        "short_codename": "huong_can",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Khả Cửu",
        "code": "xa_kha_cuu",
        "division_type": "xã",
        "short_codename": "kha_cuu",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Đông Cửu",
        "code": "xa_dong_cuu",
        "division_type": "xã",
        "short_codename": "dong_cuu",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Yên Lãng",
        "code": "xa_yen_lang",
        "division_type": "xã",
        "short_codename": "yen_lang",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Yên Lương",
        "code": "xa_yen_luong",
        "division_type": "xã",
        "short_codename": "yen_luong",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Thượng Cửu",
        "code": "xa_thuong_cuu",
        "division_type": "xã",
        "short_codename": "thuong_cuu",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Lương Nha",
        "code": "xa_luong_nha",
        "division_type": "xã",
        "short_codename": "luong_nha",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Yên Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Tinh Nhuệ",
        "code": "xa_tinh_nhue",
        "division_type": "xã",
        "short_codename": "tinh_nhue",
        "parentCode": "huyen_thanh_son"
    },
    {
        "name": "Xã Đào Xá",
        "code": "xa_dao_xa",
        "division_type": "xã",
        "short_codename": "dao_xa",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Thạch Đồng",
        "code": "xa_thach_dong",
        "division_type": "xã",
        "short_codename": "thach_dong",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Xuân Lộc",
        "code": "xa_xuan_loc",
        "division_type": "xã",
        "short_codename": "xuan_loc",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Tân Phương",
        "code": "xa_tan_phuong",
        "division_type": "xã",
        "short_codename": "tan_phuong",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Thị trấn Thanh Thủy",
        "code": "thi_tran_thanh_thuy",
        "division_type": "thị trấn",
        "short_codename": "thanh_thuy",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Sơn Thủy",
        "code": "xa_son_thuy",
        "division_type": "xã",
        "short_codename": "son_thuy",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Bảo Yên",
        "code": "xa_bao_yen",
        "division_type": "xã",
        "short_codename": "bao_yen",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Đoan Hạ",
        "code": "xa_doan_ha",
        "division_type": "xã",
        "short_codename": "doan_ha",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Đồng Trung",
        "code": "xa_dong_trung",
        "division_type": "xã",
        "short_codename": "dong_trung",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Hoàng Xá",
        "code": "xa_hoang_xa",
        "division_type": "xã",
        "short_codename": "hoang_xa",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Tu Vũ",
        "code": "xa_tu_vu",
        "division_type": "xã",
        "short_codename": "tu_vu",
        "parentCode": "huyen_thanh_thuy"
    },
    {
        "name": "Xã Thu Cúc",
        "code": "xa_thu_cuc",
        "division_type": "xã",
        "short_codename": "thu_cuc",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Thạch Kiệt",
        "code": "xa_thach_kiet",
        "division_type": "xã",
        "short_codename": "thach_kiet",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Thu Ngạc",
        "code": "xa_thu_ngac",
        "division_type": "xã",
        "short_codename": "thu_ngac",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Kiệt Sơn",
        "code": "xa_kiet_son",
        "division_type": "xã",
        "short_codename": "kiet_son",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Đồng Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Lai Đồng",
        "code": "xa_lai_dong",
        "division_type": "xã",
        "short_codename": "lai_dong",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Mỹ Thuận",
        "code": "xa_my_thuan",
        "division_type": "xã",
        "short_codename": "my_thuan",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Tân Sơn",
        "code": "xa_tan_son",
        "division_type": "xã",
        "short_codename": "tan_son",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Xuân Đài",
        "code": "xa_xuan_dai",
        "division_type": "xã",
        "short_codename": "xuan_dai",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Minh Đài",
        "code": "xa_minh_dai",
        "division_type": "xã",
        "short_codename": "minh_dai",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Văn Luông",
        "code": "xa_van_luong",
        "division_type": "xã",
        "short_codename": "van_luong",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Xuân Sơn",
        "code": "xa_xuan_son",
        "division_type": "xã",
        "short_codename": "xuan_son",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Long Cốc",
        "code": "xa_long_coc",
        "division_type": "xã",
        "short_codename": "long_coc",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Kim Thượng",
        "code": "xa_kim_thuong",
        "division_type": "xã",
        "short_codename": "kim_thuong",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Tam Thanh",
        "code": "xa_tam_thanh",
        "division_type": "xã",
        "short_codename": "tam_thanh",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Xã Vinh Tiền",
        "code": "xa_vinh_tien",
        "division_type": "xã",
        "short_codename": "vinh_tien",
        "parentCode": "huyen_tan_son"
    },
    {
        "name": "Phường Tích Sơn",
        "code": "phuong_tich_son",
        "division_type": "phường",
        "short_codename": "tich_son",
        "parentCode": "thanh_pho_vinh_yen"
    },
    {
        "name": "Phường Liên Bảo",
        "code": "phuong_lien_bao",
        "division_type": "phường",
        "short_codename": "lien_bao",
        "parentCode": "thanh_pho_vinh_yen"
    },
    {
        "name": "Phường Hội Hợp",
        "code": "phuong_hoi_hop",
        "division_type": "phường",
        "short_codename": "hoi_hop",
        "parentCode": "thanh_pho_vinh_yen"
    },
    {
        "name": "Phường Đống Đa",
        "code": "phuong_dong_da",
        "division_type": "phường",
        "short_codename": "dong_da",
        "parentCode": "thanh_pho_vinh_yen"
    },
    {
        "name": "Phường Ngô Quyền",
        "code": "phuong_ngo_quyen",
        "division_type": "phường",
        "short_codename": "ngo_quyen",
        "parentCode": "thanh_pho_vinh_yen"
    },
    {
        "name": "Phường Đồng Tâm",
        "code": "phuong_dong_tam",
        "division_type": "phường",
        "short_codename": "dong_tam",
        "parentCode": "thanh_pho_vinh_yen"
    },
    {
        "name": "Xã Định Trung",
        "code": "xa_dinh_trung",
        "division_type": "xã",
        "short_codename": "dinh_trung",
        "parentCode": "thanh_pho_vinh_yen"
    },
    {
        "name": "Phường Khai Quang",
        "code": "phuong_khai_quang",
        "division_type": "phường",
        "short_codename": "khai_quang",
        "parentCode": "thanh_pho_vinh_yen"
    },
    {
        "name": "Xã Thanh Trù",
        "code": "xa_thanh_tru",
        "division_type": "xã",
        "short_codename": "thanh_tru",
        "parentCode": "thanh_pho_vinh_yen"
    },
    {
        "name": "Phường Trưng Trắc",
        "code": "phuong_trung_trac",
        "division_type": "phường",
        "short_codename": "trung_trac",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Phường Hùng Vương",
        "code": "phuong_hung_vuong",
        "division_type": "phường",
        "short_codename": "hung_vuong",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Phường Trưng Nhị",
        "code": "phuong_trung_nhi",
        "division_type": "phường",
        "short_codename": "trung_nhi",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Phường Phúc Thắng",
        "code": "phuong_phuc_thang",
        "division_type": "phường",
        "short_codename": "phuc_thang",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Phường Xuân Hoà",
        "code": "phuong_xuan_hoa",
        "division_type": "phường",
        "short_codename": "xuan_hoa",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Phường Đồng Xuân",
        "code": "phuong_dong_xuan",
        "division_type": "phường",
        "short_codename": "dong_xuan",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Xã Ngọc Thanh",
        "code": "xa_ngoc_thanh",
        "division_type": "xã",
        "short_codename": "ngoc_thanh",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Xã Cao Minh",
        "code": "xa_cao_minh",
        "division_type": "xã",
        "short_codename": "cao_minh",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Phường Nam Viêm",
        "code": "phuong_nam_viem",
        "division_type": "phường",
        "short_codename": "nam_viem",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Phường Tiền Châu",
        "code": "phuong_tien_chau",
        "division_type": "phường",
        "short_codename": "tien_chau",
        "parentCode": "thanh_pho_phuc_yen"
    },
    {
        "name": "Thị trấn Lập Thạch",
        "code": "thi_tran_lap_thach",
        "division_type": "thị trấn",
        "short_codename": "lap_thach",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Quang Sơn",
        "code": "xa_quang_son",
        "division_type": "xã",
        "short_codename": "quang_son",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Ngọc Mỹ",
        "code": "xa_ngoc_my",
        "division_type": "xã",
        "short_codename": "ngoc_my",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Hợp Lý",
        "code": "xa_hop_ly",
        "division_type": "xã",
        "short_codename": "hop_ly",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Bắc Bình",
        "code": "xa_bac_binh",
        "division_type": "xã",
        "short_codename": "bac_binh",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Thái Hòa",
        "code": "xa_thai_hoa",
        "division_type": "xã",
        "short_codename": "thai_hoa",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Thị trấn Hoa Sơn",
        "code": "thi_tran_hoa_son",
        "division_type": "thị trấn",
        "short_codename": "hoa_son",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Liễn Sơn",
        "code": "xa_lien_son",
        "division_type": "xã",
        "short_codename": "lien_son",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Xuân Hòa",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Vân Trục",
        "code": "xa_van_truc",
        "division_type": "xã",
        "short_codename": "van_truc",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Liên Hòa",
        "code": "xa_lien_hoa",
        "division_type": "xã",
        "short_codename": "lien_hoa",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Tử Du",
        "code": "xa_tu_du",
        "division_type": "xã",
        "short_codename": "tu_du",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Bàn Giản",
        "code": "xa_ban_gian",
        "division_type": "xã",
        "short_codename": "ban_gian",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Xuân Lôi",
        "code": "xa_xuan_loi",
        "division_type": "xã",
        "short_codename": "xuan_loi",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Đồng Ích",
        "code": "xa_dong_ich",
        "division_type": "xã",
        "short_codename": "dong_ich",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Tiên Lữ",
        "code": "xa_tien_lu",
        "division_type": "xã",
        "short_codename": "tien_lu",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Văn Quán",
        "code": "xa_van_quan",
        "division_type": "xã",
        "short_codename": "van_quan",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Đình Chu",
        "code": "xa_dinh_chu",
        "division_type": "xã",
        "short_codename": "dinh_chu",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Triệu Đề",
        "code": "xa_trieu_de",
        "division_type": "xã",
        "short_codename": "trieu_de",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Xã Sơn Đông",
        "code": "xa_son_dong",
        "division_type": "xã",
        "short_codename": "son_dong",
        "parentCode": "huyen_lap_thach"
    },
    {
        "name": "Thị trấn Hợp Hòa",
        "code": "thi_tran_hop_hoa",
        "division_type": "thị trấn",
        "short_codename": "hop_hoa",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Hoàng Hoa",
        "code": "xa_hoang_hoa",
        "division_type": "xã",
        "short_codename": "hoang_hoa",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Đồng Tĩnh",
        "code": "xa_dong_tinh",
        "division_type": "xã",
        "short_codename": "dong_tinh",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Kim Long",
        "code": "xa_kim_long",
        "division_type": "xã",
        "short_codename": "kim_long",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Hướng Đạo",
        "code": "xa_huong_dao",
        "division_type": "xã",
        "short_codename": "huong_dao",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Đạo Tú",
        "code": "xa_dao_tu",
        "division_type": "xã",
        "short_codename": "dao_tu",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã An Hòa",
        "code": "xa_an_hoa",
        "division_type": "xã",
        "short_codename": "an_hoa",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Thanh Vân",
        "code": "xa_thanh_van",
        "division_type": "xã",
        "short_codename": "thanh_van",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Duy Phiên",
        "code": "xa_duy_phien",
        "division_type": "xã",
        "short_codename": "duy_phien",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Hoàng Đan",
        "code": "xa_hoang_dan",
        "division_type": "xã",
        "short_codename": "hoang_dan",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Hoàng Lâu",
        "code": "xa_hoang_lau",
        "division_type": "xã",
        "short_codename": "hoang_lau",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Vân Hội",
        "code": "xa_van_hoi",
        "division_type": "xã",
        "short_codename": "van_hoi",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Xã Hợp Thịnh",
        "code": "xa_hop_thinh",
        "division_type": "xã",
        "short_codename": "hop_thinh",
        "parentCode": "huyen_tam_duong"
    },
    {
        "name": "Thị trấn Tam Đảo",
        "code": "thi_tran_tam_dao",
        "division_type": "thị trấn",
        "short_codename": "tam_dao",
        "parentCode": "huyen_tam_dao"
    },
    {
        "name": "Thị trấn Hợp Châu",
        "code": "thi_tran_hop_chau",
        "division_type": "thị trấn",
        "short_codename": "hop_chau",
        "parentCode": "huyen_tam_dao"
    },
    {
        "name": "Xã Đạo Trù",
        "code": "xa_dao_tru",
        "division_type": "xã",
        "short_codename": "dao_tru",
        "parentCode": "huyen_tam_dao"
    },
    {
        "name": "Xã Yên Dương",
        "code": "xa_yen_duong",
        "division_type": "xã",
        "short_codename": "yen_duong",
        "parentCode": "huyen_tam_dao"
    },
    {
        "name": "Xã Bồ Lý",
        "code": "xa_bo_ly",
        "division_type": "xã",
        "short_codename": "bo_ly",
        "parentCode": "huyen_tam_dao"
    },
    {
        "name": "Thị trấn Đại Đình",
        "code": "thi_tran_dai_dinh",
        "division_type": "thị trấn",
        "short_codename": "dai_dinh",
        "parentCode": "huyen_tam_dao"
    },
    {
        "name": "Xã Tam Quan",
        "code": "xa_tam_quan",
        "division_type": "xã",
        "short_codename": "tam_quan",
        "parentCode": "huyen_tam_dao"
    },
    {
        "name": "Xã Hồ Sơn",
        "code": "xa_ho_son",
        "division_type": "xã",
        "short_codename": "ho_son",
        "parentCode": "huyen_tam_dao"
    },
    {
        "name": "Xã Minh Quang",
        "code": "xa_minh_quang",
        "division_type": "xã",
        "short_codename": "minh_quang",
        "parentCode": "huyen_tam_dao"
    },
    {
        "name": "Thị trấn Hương Canh",
        "code": "thi_tran_huong_canh",
        "division_type": "thị trấn",
        "short_codename": "huong_canh",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Thị trấn Gia Khánh",
        "code": "thi_tran_gia_khanh",
        "division_type": "thị trấn",
        "short_codename": "gia_khanh",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Xã Trung Mỹ",
        "code": "xa_trung_my",
        "division_type": "xã",
        "short_codename": "trung_my",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Thị trấn Bá Hiến",
        "code": "thi_tran_ba_hien",
        "division_type": "thị trấn",
        "short_codename": "ba_hien",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Xã Thiện Kế",
        "code": "xa_thien_ke",
        "division_type": "xã",
        "short_codename": "thien_ke",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Xã Hương Sơn",
        "code": "xa_huong_son",
        "division_type": "xã",
        "short_codename": "huong_son",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Xã Tam Hợp",
        "code": "xa_tam_hop",
        "division_type": "xã",
        "short_codename": "tam_hop",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Xã Quất Lưu",
        "code": "xa_quat_luu",
        "division_type": "xã",
        "short_codename": "quat_luu",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Xã Sơn Lôi",
        "code": "xa_son_loi",
        "division_type": "xã",
        "short_codename": "son_loi",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Thị trấn Đạo Đức",
        "code": "thi_tran_dao_duc",
        "division_type": "thị trấn",
        "short_codename": "dao_duc",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Xã Tân Phong",
        "code": "xa_tan_phong",
        "division_type": "xã",
        "short_codename": "tan_phong",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Thị trấn Thanh Lãng",
        "code": "thi_tran_thanh_lang",
        "division_type": "thị trấn",
        "short_codename": "thanh_lang",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Xã Phú Xuân",
        "code": "xa_phu_xuan",
        "division_type": "xã",
        "short_codename": "phu_xuan",
        "parentCode": "huyen_binh_xuyen"
    },
    {
        "name": "Thị trấn Yên Lạc",
        "code": "thi_tran_yen_lac",
        "division_type": "thị trấn",
        "short_codename": "yen_lac",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Đồng Cương",
        "code": "xa_dong_cuong",
        "division_type": "xã",
        "short_codename": "dong_cuong",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Đồng Văn",
        "code": "xa_dong_van",
        "division_type": "xã",
        "short_codename": "dong_van",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Bình Định",
        "code": "xa_binh_dinh",
        "division_type": "xã",
        "short_codename": "binh_dinh",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Trung Nguyên",
        "code": "xa_trung_nguyen",
        "division_type": "xã",
        "short_codename": "trung_nguyen",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Tề Lỗ",
        "code": "xa_te_lo",
        "division_type": "xã",
        "short_codename": "te_lo",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Tam Hồng",
        "code": "xa_tam_hong",
        "division_type": "xã",
        "short_codename": "tam_hong",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Yên Đồng",
        "code": "xa_yen_dong",
        "division_type": "xã",
        "short_codename": "yen_dong",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Văn Tiến",
        "code": "xa_van_tien",
        "division_type": "xã",
        "short_codename": "van_tien",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Nguyệt Đức",
        "code": "xa_nguyet_duc",
        "division_type": "xã",
        "short_codename": "nguyet_duc",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Yên Phương",
        "code": "xa_yen_phuong",
        "division_type": "xã",
        "short_codename": "yen_phuong",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Hồng Phương",
        "code": "xa_hong_phuong",
        "division_type": "xã",
        "short_codename": "hong_phuong",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Trung Kiên",
        "code": "xa_trung_kien",
        "division_type": "xã",
        "short_codename": "trung_kien",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Liên Châu",
        "code": "xa_lien_chau",
        "division_type": "xã",
        "short_codename": "lien_chau",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Đại Tự",
        "code": "xa_dai_tu",
        "division_type": "xã",
        "short_codename": "dai_tu",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Hồng Châu",
        "code": "xa_hong_chau",
        "division_type": "xã",
        "short_codename": "hong_chau",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Xã Trung Hà",
        "code": "xa_trung_ha",
        "division_type": "xã",
        "short_codename": "trung_ha",
        "parentCode": "huyen_yen_lac"
    },
    {
        "name": "Thị trấn Vĩnh Tường",
        "code": "thi_tran_vinh_tuong",
        "division_type": "thị trấn",
        "short_codename": "vinh_tuong",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Kim Xá",
        "code": "xa_kim_xa",
        "division_type": "xã",
        "short_codename": "kim_xa",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Yên Bình",
        "code": "xa_yen_binh",
        "division_type": "xã",
        "short_codename": "yen_binh",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Chấn Hưng",
        "code": "xa_chan_hung",
        "division_type": "xã",
        "short_codename": "chan_hung",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Nghĩa Hưng",
        "code": "xa_nghia_hung",
        "division_type": "xã",
        "short_codename": "nghia_hung",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Yên Lập",
        "code": "xa_yen_lap",
        "division_type": "xã",
        "short_codename": "yen_lap",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Việt Xuân",
        "code": "xa_viet_xuan",
        "division_type": "xã",
        "short_codename": "viet_xuan",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Bồ Sao",
        "code": "xa_bo_sao",
        "division_type": "xã",
        "short_codename": "bo_sao",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Đại Đồng",
        "code": "xa_dai_dong",
        "division_type": "xã",
        "short_codename": "dai_dong",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Lũng Hoà",
        "code": "xa_lung_hoa",
        "division_type": "xã",
        "short_codename": "lung_hoa",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Cao Đại",
        "code": "xa_cao_dai",
        "division_type": "xã",
        "short_codename": "cao_dai",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Thị Trấn Thổ Tang",
        "code": "thi_tran_tho_tang",
        "division_type": "thị trấn",
        "short_codename": "tho_tang",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Vĩnh Sơn",
        "code": "xa_vinh_son",
        "division_type": "xã",
        "short_codename": "vinh_son",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Bình Dương",
        "code": "xa_binh_duong",
        "division_type": "xã",
        "short_codename": "binh_duong",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Thượng Trưng",
        "code": "xa_thuong_trung",
        "division_type": "xã",
        "short_codename": "thuong_trung",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Vũ Di",
        "code": "xa_vu_di",
        "division_type": "xã",
        "short_codename": "vu_di",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Lý Nhân",
        "code": "xa_ly_nhan",
        "division_type": "xã",
        "short_codename": "ly_nhan",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Tuân Chính",
        "code": "xa_tuan_chinh",
        "division_type": "xã",
        "short_codename": "tuan_chinh",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Vân Xuân",
        "code": "xa_van_xuan",
        "division_type": "xã",
        "short_codename": "van_xuan",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Tam Phúc",
        "code": "xa_tam_phuc",
        "division_type": "xã",
        "short_codename": "tam_phuc",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Thị trấn Tứ Trưng",
        "code": "thi_tran_tu_trung",
        "division_type": "thị trấn",
        "short_codename": "tu_trung",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Ngũ Kiên",
        "code": "xa_ngu_kien",
        "division_type": "xã",
        "short_codename": "ngu_kien",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã An Tường",
        "code": "xa_an_tuong",
        "division_type": "xã",
        "short_codename": "an_tuong",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Vĩnh Thịnh",
        "code": "xa_vinh_thinh",
        "division_type": "xã",
        "short_codename": "vinh_thinh",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Phú Đa",
        "code": "xa_phu_da",
        "division_type": "xã",
        "short_codename": "phu_da",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Vĩnh Ninh",
        "code": "xa_vinh_ninh",
        "division_type": "xã",
        "short_codename": "vinh_ninh",
        "parentCode": "huyen_vinh_tuong"
    },
    {
        "name": "Xã Lãng Công",
        "code": "xa_lang_cong",
        "division_type": "xã",
        "short_codename": "lang_cong",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Quang Yên",
        "code": "xa_quang_yen",
        "division_type": "xã",
        "short_codename": "quang_yen",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Bạch Lưu",
        "code": "xa_bach_luu",
        "division_type": "xã",
        "short_codename": "bach_luu",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Hải Lựu",
        "code": "xa_hai_luu",
        "division_type": "xã",
        "short_codename": "hai_luu",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Đồng Quế",
        "code": "xa_dong_que",
        "division_type": "xã",
        "short_codename": "dong_que",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Nhân Đạo",
        "code": "xa_nhan_dao",
        "division_type": "xã",
        "short_codename": "nhan_dao",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Đôn Nhân",
        "code": "xa_don_nhan",
        "division_type": "xã",
        "short_codename": "don_nhan",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Phương Khoan",
        "code": "xa_phuong_khoan",
        "division_type": "xã",
        "short_codename": "phuong_khoan",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Nhạo Sơn",
        "code": "xa_nhao_son",
        "division_type": "xã",
        "short_codename": "nhao_son",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Thị trấn Tam Sơn",
        "code": "thi_tran_tam_son",
        "division_type": "thị trấn",
        "short_codename": "tam_son",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Như Thụy",
        "code": "xa_nhu_thuy",
        "division_type": "xã",
        "short_codename": "nhu_thuy",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Yên Thạch",
        "code": "xa_yen_thach",
        "division_type": "xã",
        "short_codename": "yen_thach",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Đồng Thịnh",
        "code": "xa_dong_thinh",
        "division_type": "xã",
        "short_codename": "dong_thinh",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Tứ Yên",
        "code": "xa_tu_yen",
        "division_type": "xã",
        "short_codename": "tu_yen",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Đức Bác",
        "code": "xa_duc_bac",
        "division_type": "xã",
        "short_codename": "duc_bac",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Xã Cao Phong",
        "code": "xa_cao_phong",
        "division_type": "xã",
        "short_codename": "cao_phong",
        "parentCode": "huyen_song_lo"
    },
    {
        "name": "Phường Vũ Ninh",
        "code": "phuong_vu_ninh",
        "division_type": "phường",
        "short_codename": "vu_ninh",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Đáp Cầu",
        "code": "phuong_dap_cau",
        "division_type": "phường",
        "short_codename": "dap_cau",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Thị Cầu",
        "code": "phuong_thi_cau",
        "division_type": "phường",
        "short_codename": "thi_cau",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Kinh Bắc",
        "code": "phuong_kinh_bac",
        "division_type": "phường",
        "short_codename": "kinh_bac",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Vệ An",
        "code": "phuong_ve_an",
        "division_type": "phường",
        "short_codename": "ve_an",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Tiền An",
        "code": "phuong_tien_an",
        "division_type": "phường",
        "short_codename": "tien_an",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Đại Phúc",
        "code": "phuong_dai_phuc",
        "division_type": "phường",
        "short_codename": "dai_phuc",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Ninh Xá",
        "code": "phuong_ninh_xa",
        "division_type": "phường",
        "short_codename": "ninh_xa",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Suối Hoa",
        "code": "phuong_suoi_hoa",
        "division_type": "phường",
        "short_codename": "suoi_hoa",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Võ Cường",
        "code": "phuong_vo_cuong",
        "division_type": "phường",
        "short_codename": "vo_cuong",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Hòa Long",
        "code": "phuong_hoa_long",
        "division_type": "phường",
        "short_codename": "hoa_long",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Vạn An",
        "code": "phuong_van_an",
        "division_type": "phường",
        "short_codename": "van_an",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Khúc Xuyên",
        "code": "phuong_khuc_xuyen",
        "division_type": "phường",
        "short_codename": "khuc_xuyen",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Phong Khê",
        "code": "phuong_phong_khe",
        "division_type": "phường",
        "short_codename": "phong_khe",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Kim Chân",
        "code": "phuong_kim_chan",
        "division_type": "phường",
        "short_codename": "kim_chan",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Vân Dương",
        "code": "phuong_van_duong",
        "division_type": "phường",
        "short_codename": "van_duong",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Nam Sơn",
        "code": "phuong_nam_son",
        "division_type": "phường",
        "short_codename": "nam_son",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Khắc Niệm",
        "code": "phuong_khac_niem",
        "division_type": "phường",
        "short_codename": "khac_niem",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Phường Hạp Lĩnh",
        "code": "phuong_hap_linh",
        "division_type": "phường",
        "short_codename": "hap_linh",
        "parentCode": "thanh_pho_bac_ninh"
    },
    {
        "name": "Thị trấn Chờ",
        "code": "thi_tran_cho",
        "division_type": "thị trấn",
        "short_codename": "cho",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Dũng Liệt",
        "code": "xa_dung_liet",
        "division_type": "xã",
        "short_codename": "dung_liet",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Tam Đa",
        "code": "xa_tam_da",
        "division_type": "xã",
        "short_codename": "tam_da",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Tam Giang",
        "code": "xa_tam_giang",
        "division_type": "xã",
        "short_codename": "tam_giang",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Yên Trung",
        "code": "xa_yen_trung",
        "division_type": "xã",
        "short_codename": "yen_trung",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Thụy Hòa",
        "code": "xa_thuy_hoa",
        "division_type": "xã",
        "short_codename": "thuy_hoa",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Hòa Tiến",
        "code": "xa_hoa_tien",
        "division_type": "xã",
        "short_codename": "hoa_tien",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Đông Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Yên Phụ",
        "code": "xa_yen_phu",
        "division_type": "xã",
        "short_codename": "yen_phu",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Trung Nghĩa",
        "code": "xa_trung_nghia",
        "division_type": "xã",
        "short_codename": "trung_nghia",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Đông Phong",
        "code": "xa_dong_phong",
        "division_type": "xã",
        "short_codename": "dong_phong",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Long Châu",
        "code": "xa_long_chau",
        "division_type": "xã",
        "short_codename": "long_chau",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Văn Môn",
        "code": "xa_van_mon",
        "division_type": "xã",
        "short_codename": "van_mon",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Xã Đông Thọ",
        "code": "xa_dong_tho",
        "division_type": "xã",
        "short_codename": "dong_tho",
        "parentCode": "huyen_yen_phong"
    },
    {
        "name": "Thị trấn Phố Mới",
        "code": "thi_tran_pho_moi",
        "division_type": "thị trấn",
        "short_codename": "pho_moi",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Việt Thống",
        "code": "xa_viet_thong",
        "division_type": "xã",
        "short_codename": "viet_thong",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Đại Xuân",
        "code": "xa_dai_xuan",
        "division_type": "xã",
        "short_codename": "dai_xuan",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Nhân Hòa",
        "code": "xa_nhan_hoa",
        "division_type": "xã",
        "short_codename": "nhan_hoa",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Bằng An",
        "code": "xa_bang_an",
        "division_type": "xã",
        "short_codename": "bang_an",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Phương Liễu",
        "code": "xa_phuong_lieu",
        "division_type": "xã",
        "short_codename": "phuong_lieu",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Quế Tân",
        "code": "xa_que_tan",
        "division_type": "xã",
        "short_codename": "que_tan",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Phù Lương",
        "code": "xa_phu_luong",
        "division_type": "xã",
        "short_codename": "phu_luong",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Phù Lãng",
        "code": "xa_phu_lang",
        "division_type": "xã",
        "short_codename": "phu_lang",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Phượng Mao",
        "code": "xa_phuong_mao",
        "division_type": "xã",
        "short_codename": "phuong_mao",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Việt Hùng",
        "code": "xa_viet_hung",
        "division_type": "xã",
        "short_codename": "viet_hung",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Ngọc Xá",
        "code": "xa_ngoc_xa",
        "division_type": "xã",
        "short_codename": "ngoc_xa",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Châu Phong",
        "code": "xa_chau_phong",
        "division_type": "xã",
        "short_codename": "chau_phong",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Bồng Lai",
        "code": "xa_bong_lai",
        "division_type": "xã",
        "short_codename": "bong_lai",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Cách Bi",
        "code": "xa_cach_bi",
        "division_type": "xã",
        "short_codename": "cach_bi",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Đào Viên",
        "code": "xa_dao_vien",
        "division_type": "xã",
        "short_codename": "dao_vien",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Yên Giả",
        "code": "xa_yen_gia",
        "division_type": "xã",
        "short_codename": "yen_gia",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Mộ Đạo",
        "code": "xa_mo_dao",
        "division_type": "xã",
        "short_codename": "mo_dao",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Đức Long",
        "code": "xa_duc_long",
        "division_type": "xã",
        "short_codename": "duc_long",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Chi Lăng",
        "code": "xa_chi_lang",
        "division_type": "xã",
        "short_codename": "chi_lang",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Xã Hán Quảng",
        "code": "xa_han_quang",
        "division_type": "xã",
        "short_codename": "han_quang",
        "parentCode": "huyen_que_vo"
    },
    {
        "name": "Thị trấn Lim",
        "code": "thi_tran_lim",
        "division_type": "thị trấn",
        "short_codename": "lim",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Phú Lâm",
        "code": "xa_phu_lam",
        "division_type": "xã",
        "short_codename": "phu_lam",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Nội Duệ",
        "code": "xa_noi_due",
        "division_type": "xã",
        "short_codename": "noi_due",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Liên Bão",
        "code": "xa_lien_bao",
        "division_type": "xã",
        "short_codename": "lien_bao",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Hiên Vân",
        "code": "xa_hien_van",
        "division_type": "xã",
        "short_codename": "hien_van",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Hoàn Sơn",
        "code": "xa_hoan_son",
        "division_type": "xã",
        "short_codename": "hoan_son",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Lạc Vệ",
        "code": "xa_lac_ve",
        "division_type": "xã",
        "short_codename": "lac_ve",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Việt Đoàn",
        "code": "xa_viet_doan",
        "division_type": "xã",
        "short_codename": "viet_doan",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Phật Tích",
        "code": "xa_phat_tich",
        "division_type": "xã",
        "short_codename": "phat_tich",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Tân Chi",
        "code": "xa_tan_chi",
        "division_type": "xã",
        "short_codename": "tan_chi",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Đại Đồng",
        "code": "xa_dai_dong",
        "division_type": "xã",
        "short_codename": "dai_dong",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Tri Phương",
        "code": "xa_tri_phuong",
        "division_type": "xã",
        "short_codename": "tri_phuong",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Minh Đạo",
        "code": "xa_minh_dao",
        "division_type": "xã",
        "short_codename": "minh_dao",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Xã Cảnh Hưng",
        "code": "xa_canh_hung",
        "division_type": "xã",
        "short_codename": "canh_hung",
        "parentCode": "huyen_tien_du"
    },
    {
        "name": "Phường Đông Ngàn",
        "code": "phuong_dong_ngan",
        "division_type": "phường",
        "short_codename": "dong_ngan",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Tam Sơn",
        "code": "phuong_tam_son",
        "division_type": "phường",
        "short_codename": "tam_son",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Hương Mạc",
        "code": "phuong_huong_mac",
        "division_type": "phường",
        "short_codename": "huong_mac",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Tương Giang",
        "code": "phuong_tuong_giang",
        "division_type": "phường",
        "short_codename": "tuong_giang",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Phù Khê",
        "code": "phuong_phu_khe",
        "division_type": "phường",
        "short_codename": "phu_khe",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Đồng Kỵ",
        "code": "phuong_dong_ky",
        "division_type": "phường",
        "short_codename": "dong_ky",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Trang Hạ",
        "code": "phuong_trang_ha",
        "division_type": "phường",
        "short_codename": "trang_ha",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Đồng Nguyên",
        "code": "phuong_dong_nguyen",
        "division_type": "phường",
        "short_codename": "dong_nguyen",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Châu Khê",
        "code": "phuong_chau_khe",
        "division_type": "phường",
        "short_codename": "chau_khe",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Tân Hồng",
        "code": "phuong_tan_hong",
        "division_type": "phường",
        "short_codename": "tan_hong",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Đình Bảng",
        "code": "phuong_dinh_bang",
        "division_type": "phường",
        "short_codename": "dinh_bang",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Phường Phù Chẩn",
        "code": "phuong_phu_chan",
        "division_type": "phường",
        "short_codename": "phu_chan",
        "parentCode": "thi_xa_tu_son"
    },
    {
        "name": "Thị trấn Hồ",
        "code": "thi_tran_ho",
        "division_type": "thị trấn",
        "short_codename": "ho",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Hoài Thượng",
        "code": "xa_hoai_thuong",
        "division_type": "xã",
        "short_codename": "hoai_thuong",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Đại Đồng Thành",
        "code": "xa_dai_dong_thanh",
        "division_type": "xã",
        "short_codename": "dai_dong_thanh",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Mão Điền",
        "code": "xa_mao_dien",
        "division_type": "xã",
        "short_codename": "mao_dien",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Song Hồ",
        "code": "xa_song_ho",
        "division_type": "xã",
        "short_codename": "song_ho",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Đình Tổ",
        "code": "xa_dinh_to",
        "division_type": "xã",
        "short_codename": "dinh_to",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Trí Quả",
        "code": "xa_tri_qua",
        "division_type": "xã",
        "short_codename": "tri_qua",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Gia Đông",
        "code": "xa_gia_dong",
        "division_type": "xã",
        "short_codename": "gia_dong",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Thanh Khương",
        "code": "xa_thanh_khuong",
        "division_type": "xã",
        "short_codename": "thanh_khuong",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Trạm Lộ",
        "code": "xa_tram_lo",
        "division_type": "xã",
        "short_codename": "tram_lo",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Xuân Lâm",
        "code": "xa_xuan_lam",
        "division_type": "xã",
        "short_codename": "xuan_lam",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Hà Mãn",
        "code": "xa_ha_man",
        "division_type": "xã",
        "short_codename": "ha_man",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Ngũ Thái",
        "code": "xa_ngu_thai",
        "division_type": "xã",
        "short_codename": "ngu_thai",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Nguyệt Đức",
        "code": "xa_nguyet_duc",
        "division_type": "xã",
        "short_codename": "nguyet_duc",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Ninh Xá",
        "code": "xa_ninh_xa",
        "division_type": "xã",
        "short_codename": "ninh_xa",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Nghĩa Đạo",
        "code": "xa_nghia_dao",
        "division_type": "xã",
        "short_codename": "nghia_dao",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Xã Song Liễu",
        "code": "xa_song_lieu",
        "division_type": "xã",
        "short_codename": "song_lieu",
        "parentCode": "huyen_thuan_thanh"
    },
    {
        "name": "Thị trấn Gia Bình",
        "code": "thi_tran_gia_binh",
        "division_type": "thị trấn",
        "short_codename": "gia_binh",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Vạn Ninh",
        "code": "xa_van_ninh",
        "division_type": "xã",
        "short_codename": "van_ninh",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Thái Bảo",
        "code": "xa_thai_bao",
        "division_type": "xã",
        "short_codename": "thai_bao",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Giang Sơn",
        "code": "xa_giang_son",
        "division_type": "xã",
        "short_codename": "giang_son",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Cao Đức",
        "code": "xa_cao_duc",
        "division_type": "xã",
        "short_codename": "cao_duc",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Đại Lai",
        "code": "xa_dai_lai",
        "division_type": "xã",
        "short_codename": "dai_lai",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Song Giang",
        "code": "xa_song_giang",
        "division_type": "xã",
        "short_codename": "song_giang",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Bình Dương",
        "code": "xa_binh_duong",
        "division_type": "xã",
        "short_codename": "binh_duong",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Lãng Ngâm",
        "code": "xa_lang_ngam",
        "division_type": "xã",
        "short_codename": "lang_ngam",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Nhân Thắng",
        "code": "xa_nhan_thang",
        "division_type": "xã",
        "short_codename": "nhan_thang",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Xuân Lai",
        "code": "xa_xuan_lai",
        "division_type": "xã",
        "short_codename": "xuan_lai",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Đông Cứu",
        "code": "xa_dong_cuu",
        "division_type": "xã",
        "short_codename": "dong_cuu",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Đại Bái",
        "code": "xa_dai_bai",
        "division_type": "xã",
        "short_codename": "dai_bai",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Xã Quỳnh Phú",
        "code": "xa_quynh_phu",
        "division_type": "xã",
        "short_codename": "quynh_phu",
        "parentCode": "huyen_gia_binh"
    },
    {
        "name": "Thị trấn Thứa",
        "code": "thi_tran_thua",
        "division_type": "thị trấn",
        "short_codename": "thua",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã An Thịnh",
        "code": "xa_an_thinh",
        "division_type": "xã",
        "short_codename": "an_thinh",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Trung Kênh",
        "code": "xa_trung_kenh",
        "division_type": "xã",
        "short_codename": "trung_kenh",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Phú Hòa",
        "code": "xa_phu_hoa",
        "division_type": "xã",
        "short_codename": "phu_hoa",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Mỹ Hương",
        "code": "xa_my_huong",
        "division_type": "xã",
        "short_codename": "my_huong",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Tân Lãng",
        "code": "xa_tan_lang",
        "division_type": "xã",
        "short_codename": "tan_lang",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Quảng Phú",
        "code": "xa_quang_phu",
        "division_type": "xã",
        "short_codename": "quang_phu",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Trừng Xá",
        "code": "xa_trung_xa",
        "division_type": "xã",
        "short_codename": "trung_xa",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Lai Hạ",
        "code": "xa_lai_ha",
        "division_type": "xã",
        "short_codename": "lai_ha",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Trung Chính",
        "code": "xa_trung_chinh",
        "division_type": "xã",
        "short_codename": "trung_chinh",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Bình Định",
        "code": "xa_binh_dinh",
        "division_type": "xã",
        "short_codename": "binh_dinh",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Phú Lương",
        "code": "xa_phu_luong",
        "division_type": "xã",
        "short_codename": "phu_luong",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Xã Lâm Thao",
        "code": "xa_lam_thao",
        "division_type": "xã",
        "short_codename": "lam_thao",
        "parentCode": "huyen_luong_tai"
    },
    {
        "name": "Phường Cẩm Thượng",
        "code": "phuong_cam_thuong",
        "division_type": "phường",
        "short_codename": "cam_thuong",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Bình Hàn",
        "code": "phuong_binh_han",
        "division_type": "phường",
        "short_codename": "binh_han",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Ngọc Châu",
        "code": "phuong_ngoc_chau",
        "division_type": "phường",
        "short_codename": "ngoc_chau",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Nhị Châu",
        "code": "phuong_nhi_chau",
        "division_type": "phường",
        "short_codename": "nhi_chau",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Nguyễn Trãi",
        "code": "phuong_nguyen_trai",
        "division_type": "phường",
        "short_codename": "nguyen_trai",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Phạm Ngũ Lão",
        "code": "phuong_pham_ngu_lao",
        "division_type": "phường",
        "short_codename": "pham_ngu_lao",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Trần Hưng Đạo",
        "code": "phuong_tran_hung_dao",
        "division_type": "phường",
        "short_codename": "tran_hung_dao",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Trần Phú",
        "code": "phuong_tran_phu",
        "division_type": "phường",
        "short_codename": "tran_phu",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Thanh Bình",
        "code": "phuong_thanh_binh",
        "division_type": "phường",
        "short_codename": "thanh_binh",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Tân Bình",
        "code": "phuong_tan_binh",
        "division_type": "phường",
        "short_codename": "tan_binh",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Lê Thanh Nghị",
        "code": "phuong_le_thanh_nghi",
        "division_type": "phường",
        "short_codename": "le_thanh_nghi",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Hải Tân",
        "code": "phuong_hai_tan",
        "division_type": "phường",
        "short_codename": "hai_tan",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Tứ Minh",
        "code": "phuong_tu_minh",
        "division_type": "phường",
        "short_codename": "tu_minh",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Việt Hoà",
        "code": "phuong_viet_hoa",
        "division_type": "phường",
        "short_codename": "viet_hoa",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Ái Quốc",
        "code": "phuong_ai_quoc",
        "division_type": "phường",
        "short_codename": "ai_quoc",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Xã An Thượng",
        "code": "xa_an_thuong",
        "division_type": "xã",
        "short_codename": "an_thuong",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Nam Đồng",
        "code": "phuong_nam_dong",
        "division_type": "phường",
        "short_codename": "nam_dong",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Xã Quyết Thắng",
        "code": "xa_quyet_thang",
        "division_type": "xã",
        "short_codename": "quyet_thang",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Xã Tiền Tiến",
        "code": "xa_tien_tien",
        "division_type": "xã",
        "short_codename": "tien_tien",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Thạch Khôi",
        "code": "phuong_thach_khoi",
        "division_type": "phường",
        "short_codename": "thach_khoi",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Xã Liên Hồng",
        "code": "xa_lien_hong",
        "division_type": "xã",
        "short_codename": "lien_hong",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Tân Hưng",
        "code": "phuong_tan_hung",
        "division_type": "phường",
        "short_codename": "tan_hung",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Xã Gia Xuyên",
        "code": "xa_gia_xuyen",
        "division_type": "xã",
        "short_codename": "gia_xuyen",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Xã Ngọc Sơn",
        "code": "xa_ngoc_son",
        "division_type": "xã",
        "short_codename": "ngoc_son",
        "parentCode": "thanh_pho_hai_duong"
    },
    {
        "name": "Phường Phả Lại",
        "code": "phuong_pha_lai",
        "division_type": "phường",
        "short_codename": "pha_lai",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Sao Đỏ",
        "code": "phuong_sao_do",
        "division_type": "phường",
        "short_codename": "sao_do",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Bến Tắm",
        "code": "phuong_ben_tam",
        "division_type": "phường",
        "short_codename": "ben_tam",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Xã Hoàng Hoa Thám",
        "code": "xa_hoang_hoa_tham",
        "division_type": "xã",
        "short_codename": "hoang_hoa_tham",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Xã Bắc An",
        "code": "xa_bac_an",
        "division_type": "xã",
        "short_codename": "bac_an",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Xã Hưng Đạo",
        "code": "xa_hung_dao",
        "division_type": "xã",
        "short_codename": "hung_dao",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Xã Lê Lợi",
        "code": "xa_le_loi",
        "division_type": "xã",
        "short_codename": "le_loi",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Hoàng Tiến",
        "code": "phuong_hoang_tien",
        "division_type": "phường",
        "short_codename": "hoang_tien",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Cộng Hoà",
        "code": "phuong_cong_hoa",
        "division_type": "phường",
        "short_codename": "cong_hoa",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Hoàng Tân",
        "code": "phuong_hoang_tan",
        "division_type": "phường",
        "short_codename": "hoang_tan",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Cổ Thành",
        "code": "phuong_co_thanh",
        "division_type": "phường",
        "short_codename": "co_thanh",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Văn An",
        "code": "phuong_van_an",
        "division_type": "phường",
        "short_codename": "van_an",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Chí Minh",
        "code": "phuong_chi_minh",
        "division_type": "phường",
        "short_codename": "chi_minh",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Văn Đức",
        "code": "phuong_van_duc",
        "division_type": "phường",
        "short_codename": "van_duc",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Thái Học",
        "code": "phuong_thai_hoc",
        "division_type": "phường",
        "short_codename": "thai_hoc",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Xã Nhân Huệ",
        "code": "xa_nhan_hue",
        "division_type": "xã",
        "short_codename": "nhan_hue",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường An Lạc",
        "code": "phuong_an_lac",
        "division_type": "phường",
        "short_codename": "an_lac",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Đồng Lạc",
        "code": "phuong_dong_lac",
        "division_type": "phường",
        "short_codename": "dong_lac",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Phường Tân Dân",
        "code": "phuong_tan_dan",
        "division_type": "phường",
        "short_codename": "tan_dan",
        "parentCode": "thanh_pho_chi_linh"
    },
    {
        "name": "Thị trấn Nam Sách",
        "code": "thi_tran_nam_sach",
        "division_type": "thị trấn",
        "short_codename": "nam_sach",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Nam Hưng",
        "code": "xa_nam_hung",
        "division_type": "xã",
        "short_codename": "nam_hung",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Nam Tân",
        "code": "xa_nam_tan",
        "division_type": "xã",
        "short_codename": "nam_tan",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Hợp Tiến",
        "code": "xa_hop_tien",
        "division_type": "xã",
        "short_codename": "hop_tien",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Hiệp Cát",
        "code": "xa_hiep_cat",
        "division_type": "xã",
        "short_codename": "hiep_cat",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Thanh Quang",
        "code": "xa_thanh_quang",
        "division_type": "xã",
        "short_codename": "thanh_quang",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Quốc Tuấn",
        "code": "xa_quoc_tuan",
        "division_type": "xã",
        "short_codename": "quoc_tuan",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Nam Chính",
        "code": "xa_nam_chinh",
        "division_type": "xã",
        "short_codename": "nam_chinh",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Nam Trung",
        "code": "xa_nam_trung",
        "division_type": "xã",
        "short_codename": "nam_trung",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã An Sơn",
        "code": "xa_an_son",
        "division_type": "xã",
        "short_codename": "an_son",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Cộng Hòa",
        "code": "xa_cong_hoa",
        "division_type": "xã",
        "short_codename": "cong_hoa",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Thái Tân",
        "code": "xa_thai_tan",
        "division_type": "xã",
        "short_codename": "thai_tan",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã An Lâm",
        "code": "xa_an_lam",
        "division_type": "xã",
        "short_codename": "an_lam",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Phú Điền",
        "code": "xa_phu_dien",
        "division_type": "xã",
        "short_codename": "phu_dien",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Nam Hồng",
        "code": "xa_nam_hong",
        "division_type": "xã",
        "short_codename": "nam_hong",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Hồng Phong",
        "code": "xa_hong_phong",
        "division_type": "xã",
        "short_codename": "hong_phong",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Đồng Lạc",
        "code": "xa_dong_lac",
        "division_type": "xã",
        "short_codename": "dong_lac",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_nam_sach"
    },
    {
        "name": "Phường An Lưu",
        "code": "phuong_an_luu",
        "division_type": "phường",
        "short_codename": "an_luu",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Xã Bạch Đằng",
        "code": "xa_bach_dang",
        "division_type": "xã",
        "short_codename": "bach_dang",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Thất Hùng",
        "code": "phuong_that_hung",
        "division_type": "phường",
        "short_codename": "that_hung",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Xã Lê Ninh",
        "code": "xa_le_ninh",
        "division_type": "xã",
        "short_codename": "le_ninh",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Xã Hoành Sơn",
        "code": "xa_hoanh_son",
        "division_type": "xã",
        "short_codename": "hoanh_son",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Phạm Thái",
        "code": "phuong_pham_thai",
        "division_type": "phường",
        "short_codename": "pham_thai",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Duy Tân",
        "code": "phuong_duy_tan",
        "division_type": "phường",
        "short_codename": "duy_tan",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Tân Dân",
        "code": "phuong_tan_dan",
        "division_type": "phường",
        "short_codename": "tan_dan",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Minh Tân",
        "code": "phuong_minh_tan",
        "division_type": "phường",
        "short_codename": "minh_tan",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Xã Quang Thành",
        "code": "xa_quang_thanh",
        "division_type": "xã",
        "short_codename": "quang_thanh",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Xã Hiệp Hòa",
        "code": "xa_hiep_hoa",
        "division_type": "xã",
        "short_codename": "hiep_hoa",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Phú Thứ",
        "code": "phuong_phu_thu",
        "division_type": "phường",
        "short_codename": "phu_thu",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Xã Thăng Long",
        "code": "xa_thang_long",
        "division_type": "xã",
        "short_codename": "thang_long",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Xã Lạc Long",
        "code": "xa_lac_long",
        "division_type": "xã",
        "short_codename": "lac_long",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường An Sinh",
        "code": "phuong_an_sinh",
        "division_type": "phường",
        "short_codename": "an_sinh",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Hiệp Sơn",
        "code": "phuong_hiep_son",
        "division_type": "phường",
        "short_codename": "hiep_son",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Xã Thượng Quận",
        "code": "xa_thuong_quan",
        "division_type": "xã",
        "short_codename": "thuong_quan",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường An Phụ",
        "code": "phuong_an_phu",
        "division_type": "phường",
        "short_codename": "an_phu",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Hiệp An",
        "code": "phuong_hiep_an",
        "division_type": "phường",
        "short_codename": "hiep_an",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Long Xuyên",
        "code": "phuong_long_xuyen",
        "division_type": "phường",
        "short_codename": "long_xuyen",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Thái Thịnh",
        "code": "phuong_thai_thinh",
        "division_type": "phường",
        "short_codename": "thai_thinh",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Phường Hiến Thành",
        "code": "phuong_hien_thanh",
        "division_type": "phường",
        "short_codename": "hien_thanh",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Xã Minh Hòa",
        "code": "xa_minh_hoa",
        "division_type": "xã",
        "short_codename": "minh_hoa",
        "parentCode": "thi_xa_kinh_mon"
    },
    {
        "name": "Thị trấn Phú Thái",
        "code": "thi_tran_phu_thai",
        "division_type": "thị trấn",
        "short_codename": "phu_thai",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Lai Vu",
        "code": "xa_lai_vu",
        "division_type": "xã",
        "short_codename": "lai_vu",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Cộng Hòa",
        "code": "xa_cong_hoa",
        "division_type": "xã",
        "short_codename": "cong_hoa",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Thượng Vũ",
        "code": "xa_thuong_vu",
        "division_type": "xã",
        "short_codename": "thuong_vu",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Cổ Dũng",
        "code": "xa_co_dung",
        "division_type": "xã",
        "short_codename": "co_dung",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Tuấn Việt",
        "code": "xa_tuan_viet",
        "division_type": "xã",
        "short_codename": "tuan_viet",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Kim Xuyên",
        "code": "xa_kim_xuyen",
        "division_type": "xã",
        "short_codename": "kim_xuyen",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Phúc Thành A",
        "code": "xa_phuc_thanh_a",
        "division_type": "xã",
        "short_codename": "phuc_thanh_a",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Ngũ Phúc",
        "code": "xa_ngu_phuc",
        "division_type": "xã",
        "short_codename": "ngu_phuc",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Kim Anh",
        "code": "xa_kim_anh",
        "division_type": "xã",
        "short_codename": "kim_anh",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Kim Liên",
        "code": "xa_kim_lien",
        "division_type": "xã",
        "short_codename": "kim_lien",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Kim Tân",
        "code": "xa_kim_tan",
        "division_type": "xã",
        "short_codename": "kim_tan",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Kim Đính",
        "code": "xa_kim_dinh",
        "division_type": "xã",
        "short_codename": "kim_dinh",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Bình Dân",
        "code": "xa_binh_dan",
        "division_type": "xã",
        "short_codename": "binh_dan",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Tam Kỳ",
        "code": "xa_tam_ky",
        "division_type": "xã",
        "short_codename": "tam_ky",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Đồng Cẩm",
        "code": "xa_dong_cam",
        "division_type": "xã",
        "short_codename": "dong_cam",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Liên Hòa",
        "code": "xa_lien_hoa",
        "division_type": "xã",
        "short_codename": "lien_hoa",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Xã Đại Đức",
        "code": "xa_dai_duc",
        "division_type": "xã",
        "short_codename": "dai_duc",
        "parentCode": "huyen_kim_thanh"
    },
    {
        "name": "Thị trấn Thanh Hà",
        "code": "thi_tran_thanh_ha",
        "division_type": "thị trấn",
        "short_codename": "thanh_ha",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Hồng Lạc",
        "code": "xa_hong_lac",
        "division_type": "xã",
        "short_codename": "hong_lac",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Việt Hồng",
        "code": "xa_viet_hong",
        "division_type": "xã",
        "short_codename": "viet_hong",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Tân Việt",
        "code": "xa_tan_viet",
        "division_type": "xã",
        "short_codename": "tan_viet",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Cẩm Chế",
        "code": "xa_cam_che",
        "division_type": "xã",
        "short_codename": "cam_che",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Lang",
        "code": "xa_thanh_lang",
        "division_type": "xã",
        "short_codename": "thanh_lang",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Tân An",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Liên Mạc",
        "code": "xa_lien_mac",
        "division_type": "xã",
        "short_codename": "lien_mac",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Hải",
        "code": "xa_thanh_hai",
        "division_type": "xã",
        "short_codename": "thanh_hai",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Khê",
        "code": "xa_thanh_khe",
        "division_type": "xã",
        "short_codename": "thanh_khe",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Xá",
        "code": "xa_thanh_xa",
        "division_type": "xã",
        "short_codename": "thanh_xa",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Xuân",
        "code": "xa_thanh_xuan",
        "division_type": "xã",
        "short_codename": "thanh_xuan",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Thủy",
        "code": "xa_thanh_thuy",
        "division_type": "xã",
        "short_codename": "thanh_thuy",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã An Phượng",
        "code": "xa_an_phuong",
        "division_type": "xã",
        "short_codename": "an_phuong",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Quang",
        "code": "xa_thanh_quang",
        "division_type": "xã",
        "short_codename": "thanh_quang",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Hồng",
        "code": "xa_thanh_hong",
        "division_type": "xã",
        "short_codename": "thanh_hong",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Thanh Cường",
        "code": "xa_thanh_cuong",
        "division_type": "xã",
        "short_codename": "thanh_cuong",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Xã Vĩnh Lập",
        "code": "xa_vinh_lap",
        "division_type": "xã",
        "short_codename": "vinh_lap",
        "parentCode": "huyen_thanh_ha"
    },
    {
        "name": "Thị trấn Cẩm Giang",
        "code": "thi_tran_cam_giang",
        "division_type": "thị trấn",
        "short_codename": "cam_giang",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Thị trấn Lai Cách",
        "code": "thi_tran_lai_cach",
        "division_type": "thị trấn",
        "short_codename": "lai_cach",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Cẩm Hưng",
        "code": "xa_cam_hung",
        "division_type": "xã",
        "short_codename": "cam_hung",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Cẩm Hoàng",
        "code": "xa_cam_hoang",
        "division_type": "xã",
        "short_codename": "cam_hoang",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Cẩm Văn",
        "code": "xa_cam_van",
        "division_type": "xã",
        "short_codename": "cam_van",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Ngọc Liên",
        "code": "xa_ngoc_lien",
        "division_type": "xã",
        "short_codename": "ngoc_lien",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Thạch Lỗi",
        "code": "xa_thach_loi",
        "division_type": "xã",
        "short_codename": "thach_loi",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Cẩm Vũ",
        "code": "xa_cam_vu",
        "division_type": "xã",
        "short_codename": "cam_vu",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Đức Chính",
        "code": "xa_duc_chinh",
        "division_type": "xã",
        "short_codename": "duc_chinh",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Định Sơn",
        "code": "xa_dinh_son",
        "division_type": "xã",
        "short_codename": "dinh_son",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Lương Điền",
        "code": "xa_luong_dien",
        "division_type": "xã",
        "short_codename": "luong_dien",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Cao An",
        "code": "xa_cao_an",
        "division_type": "xã",
        "short_codename": "cao_an",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Tân Trường",
        "code": "xa_tan_truong",
        "division_type": "xã",
        "short_codename": "tan_truong",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Cẩm Phúc",
        "code": "xa_cam_phuc",
        "division_type": "xã",
        "short_codename": "cam_phuc",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Cẩm Điền",
        "code": "xa_cam_dien",
        "division_type": "xã",
        "short_codename": "cam_dien",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Cẩm Đông",
        "code": "xa_cam_dong",
        "division_type": "xã",
        "short_codename": "cam_dong",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Xã Cẩm Đoài",
        "code": "xa_cam_doai",
        "division_type": "xã",
        "short_codename": "cam_doai",
        "parentCode": "huyen_cam_giang"
    },
    {
        "name": "Thị trấn Kẻ Sặt",
        "code": "thi_tran_ke_sat",
        "division_type": "thị trấn",
        "short_codename": "ke_sat",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Vĩnh Hưng",
        "code": "xa_vinh_hung",
        "division_type": "xã",
        "short_codename": "vinh_hung",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Hùng Thắng",
        "code": "xa_hung_thang",
        "division_type": "xã",
        "short_codename": "hung_thang",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Vĩnh Hồng",
        "code": "xa_vinh_hong",
        "division_type": "xã",
        "short_codename": "vinh_hong",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Long Xuyên",
        "code": "xa_long_xuyen",
        "division_type": "xã",
        "short_codename": "long_xuyen",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Tân Việt",
        "code": "xa_tan_viet",
        "division_type": "xã",
        "short_codename": "tan_viet",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Thúc Kháng",
        "code": "xa_thuc_khang",
        "division_type": "xã",
        "short_codename": "thuc_khang",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Tân Hồng",
        "code": "xa_tan_hong",
        "division_type": "xã",
        "short_codename": "tan_hong",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Hồng Khê",
        "code": "xa_hong_khe",
        "division_type": "xã",
        "short_codename": "hong_khe",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Thái Học",
        "code": "xa_thai_hoc",
        "division_type": "xã",
        "short_codename": "thai_hoc",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Cổ Bì",
        "code": "xa_co_bi",
        "division_type": "xã",
        "short_codename": "co_bi",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Nhân Quyền",
        "code": "xa_nhan_quyen",
        "division_type": "xã",
        "short_codename": "nhan_quyen",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Thái Dương",
        "code": "xa_thai_duong",
        "division_type": "xã",
        "short_codename": "thai_duong",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Thái Hòa",
        "code": "xa_thai_hoa",
        "division_type": "xã",
        "short_codename": "thai_hoa",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Xã Bình Xuyên",
        "code": "xa_binh_xuyen",
        "division_type": "xã",
        "short_codename": "binh_xuyen",
        "parentCode": "huyen_binh_giang"
    },
    {
        "name": "Thị trấn Gia Lộc",
        "code": "thi_tran_gia_loc",
        "division_type": "thị trấn",
        "short_codename": "gia_loc",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Thống Nhất",
        "code": "xa_thong_nhat",
        "division_type": "xã",
        "short_codename": "thong_nhat",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Yết Kiêu",
        "code": "xa_yet_kieu",
        "division_type": "xã",
        "short_codename": "yet_kieu",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Gia Tân",
        "code": "xa_gia_tan",
        "division_type": "xã",
        "short_codename": "gia_tan",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Gia Khánh",
        "code": "xa_gia_khanh",
        "division_type": "xã",
        "short_codename": "gia_khanh",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Gia Lương",
        "code": "xa_gia_luong",
        "division_type": "xã",
        "short_codename": "gia_luong",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Lê Lợi",
        "code": "xa_le_loi",
        "division_type": "xã",
        "short_codename": "le_loi",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Toàn Thắng",
        "code": "xa_toan_thang",
        "division_type": "xã",
        "short_codename": "toan_thang",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Hoàng Diệu",
        "code": "xa_hoang_dieu",
        "division_type": "xã",
        "short_codename": "hoang_dieu",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Hồng Hưng",
        "code": "xa_hong_hung",
        "division_type": "xã",
        "short_codename": "hong_hung",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Phạm Trấn",
        "code": "xa_pham_tran",
        "division_type": "xã",
        "short_codename": "pham_tran",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Đoàn Thượng",
        "code": "xa_doan_thuong",
        "division_type": "xã",
        "short_codename": "doan_thuong",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Thống Kênh",
        "code": "xa_thong_kenh",
        "division_type": "xã",
        "short_codename": "thong_kenh",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Quang Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Đồng Quang",
        "code": "xa_dong_quang",
        "division_type": "xã",
        "short_codename": "dong_quang",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Nhật Tân",
        "code": "xa_nhat_tan",
        "division_type": "xã",
        "short_codename": "nhat_tan",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Xã Đức Xương",
        "code": "xa_duc_xuong",
        "division_type": "xã",
        "short_codename": "duc_xuong",
        "parentCode": "huyen_gia_loc"
    },
    {
        "name": "Thị trấn Tứ Kỳ",
        "code": "thi_tran_tu_ky",
        "division_type": "thị trấn",
        "short_codename": "tu_ky",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Đại Sơn",
        "code": "xa_dai_son",
        "division_type": "xã",
        "short_codename": "dai_son",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Hưng Đạo",
        "code": "xa_hung_dao",
        "division_type": "xã",
        "short_codename": "hung_dao",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Ngọc Kỳ",
        "code": "xa_ngoc_ky",
        "division_type": "xã",
        "short_codename": "ngoc_ky",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Bình Lăng",
        "code": "xa_binh_lang",
        "division_type": "xã",
        "short_codename": "binh_lang",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Chí Minh",
        "code": "xa_chi_minh",
        "division_type": "xã",
        "short_codename": "chi_minh",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Tái Sơn",
        "code": "xa_tai_son",
        "division_type": "xã",
        "short_codename": "tai_son",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Quang Phục",
        "code": "xa_quang_phuc",
        "division_type": "xã",
        "short_codename": "quang_phuc",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Dân Chủ",
        "code": "xa_dan_chu",
        "division_type": "xã",
        "short_codename": "dan_chu",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Tân Kỳ",
        "code": "xa_tan_ky",
        "division_type": "xã",
        "short_codename": "tan_ky",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Quang Khải",
        "code": "xa_quang_khai",
        "division_type": "xã",
        "short_codename": "quang_khai",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Đại Hợp",
        "code": "xa_dai_hop",
        "division_type": "xã",
        "short_codename": "dai_hop",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Quảng Nghiệp",
        "code": "xa_quang_nghiep",
        "division_type": "xã",
        "short_codename": "quang_nghiep",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã An Thanh",
        "code": "xa_an_thanh",
        "division_type": "xã",
        "short_codename": "an_thanh",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Minh Đức",
        "code": "xa_minh_duc",
        "division_type": "xã",
        "short_codename": "minh_duc",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Văn Tố",
        "code": "xa_van_to",
        "division_type": "xã",
        "short_codename": "van_to",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Phượng Kỳ",
        "code": "xa_phuong_ky",
        "division_type": "xã",
        "short_codename": "phuong_ky",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Cộng Lạc",
        "code": "xa_cong_lac",
        "division_type": "xã",
        "short_codename": "cong_lac",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Tiên Động",
        "code": "xa_tien_dong",
        "division_type": "xã",
        "short_codename": "tien_dong",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Nguyên Giáp",
        "code": "xa_nguyen_giap",
        "division_type": "xã",
        "short_codename": "nguyen_giap",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Hà Kỳ",
        "code": "xa_ha_ky",
        "division_type": "xã",
        "short_codename": "ha_ky",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Xã Hà Thanh",
        "code": "xa_ha_thanh",
        "division_type": "xã",
        "short_codename": "ha_thanh",
        "parentCode": "huyen_tu_ky"
    },
    {
        "name": "Thị trấn Ninh Giang",
        "code": "thi_tran_ninh_giang",
        "division_type": "thị trấn",
        "short_codename": "ninh_giang",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Ứng Hoè",
        "code": "xa_ung_hoe",
        "division_type": "xã",
        "short_codename": "ung_hoe",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Nghĩa An",
        "code": "xa_nghia_an",
        "division_type": "xã",
        "short_codename": "nghia_an",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Hồng Đức",
        "code": "xa_hong_duc",
        "division_type": "xã",
        "short_codename": "hong_duc",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã An Đức",
        "code": "xa_an_duc",
        "division_type": "xã",
        "short_codename": "an_duc",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Vạn Phúc",
        "code": "xa_van_phuc",
        "division_type": "xã",
        "short_codename": "van_phuc",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Tân Hương",
        "code": "xa_tan_huong",
        "division_type": "xã",
        "short_codename": "tan_huong",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Vĩnh Hòa",
        "code": "xa_vinh_hoa",
        "division_type": "xã",
        "short_codename": "vinh_hoa",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Đông Xuyên",
        "code": "xa_dong_xuyen",
        "division_type": "xã",
        "short_codename": "dong_xuyen",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Tân Phong",
        "code": "xa_tan_phong",
        "division_type": "xã",
        "short_codename": "tan_phong",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Ninh Hải",
        "code": "xa_ninh_hai",
        "division_type": "xã",
        "short_codename": "ninh_hai",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Đồng Tâm",
        "code": "xa_dong_tam",
        "division_type": "xã",
        "short_codename": "dong_tam",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Tân Quang",
        "code": "xa_tan_quang",
        "division_type": "xã",
        "short_codename": "tan_quang",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Kiến Quốc",
        "code": "xa_kien_quoc",
        "division_type": "xã",
        "short_codename": "kien_quoc",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Hồng Dụ",
        "code": "xa_hong_du",
        "division_type": "xã",
        "short_codename": "hong_du",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Văn Hội",
        "code": "xa_van_hoi",
        "division_type": "xã",
        "short_codename": "van_hoi",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Hồng Phong",
        "code": "xa_hong_phong",
        "division_type": "xã",
        "short_codename": "hong_phong",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Hiệp Lực",
        "code": "xa_hiep_luc",
        "division_type": "xã",
        "short_codename": "hiep_luc",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Hồng Phúc",
        "code": "xa_hong_phuc",
        "division_type": "xã",
        "short_codename": "hong_phuc",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Xã Hưng Long",
        "code": "xa_hung_long",
        "division_type": "xã",
        "short_codename": "hung_long",
        "parentCode": "huyen_ninh_giang"
    },
    {
        "name": "Thị trấn Thanh Miện",
        "code": "thi_tran_thanh_mien",
        "division_type": "thị trấn",
        "short_codename": "thanh_mien",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Thanh Tùng",
        "code": "xa_thanh_tung",
        "division_type": "xã",
        "short_codename": "thanh_tung",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Phạm Kha",
        "code": "xa_pham_kha",
        "division_type": "xã",
        "short_codename": "pham_kha",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Ngô Quyền",
        "code": "xa_ngo_quyen",
        "division_type": "xã",
        "short_codename": "ngo_quyen",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Đoàn Tùng",
        "code": "xa_doan_tung",
        "division_type": "xã",
        "short_codename": "doan_tung",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Hồng Quang",
        "code": "xa_hong_quang",
        "division_type": "xã",
        "short_codename": "hong_quang",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Tân Trào",
        "code": "xa_tan_trao",
        "division_type": "xã",
        "short_codename": "tan_trao",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Lam Sơn",
        "code": "xa_lam_son",
        "division_type": "xã",
        "short_codename": "lam_son",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Đoàn Kết",
        "code": "xa_doan_ket",
        "division_type": "xã",
        "short_codename": "doan_ket",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Lê Hồng",
        "code": "xa_le_hong",
        "division_type": "xã",
        "short_codename": "le_hong",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Tứ Cường",
        "code": "xa_tu_cuong",
        "division_type": "xã",
        "short_codename": "tu_cuong",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Ngũ Hùng",
        "code": "xa_ngu_hung",
        "division_type": "xã",
        "short_codename": "ngu_hung",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Cao Thắng",
        "code": "xa_cao_thang",
        "division_type": "xã",
        "short_codename": "cao_thang",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Chi Lăng Bắc",
        "code": "xa_chi_lang_bac",
        "division_type": "xã",
        "short_codename": "chi_lang_bac",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Chi Lăng Nam",
        "code": "xa_chi_lang_nam",
        "division_type": "xã",
        "short_codename": "chi_lang_nam",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Thanh Giang",
        "code": "xa_thanh_giang",
        "division_type": "xã",
        "short_codename": "thanh_giang",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Xã Hồng Phong",
        "code": "xa_hong_phong",
        "division_type": "xã",
        "short_codename": "hong_phong",
        "parentCode": "huyen_thanh_mien"
    },
    {
        "name": "Phường Quán Toan",
        "code": "phuong_quan_toan",
        "division_type": "phường",
        "short_codename": "quan_toan",
        "parentCode": "quan_hong_bang"
    },
    {
        "name": "Phường Hùng Vương",
        "code": "phuong_hung_vuong",
        "division_type": "phường",
        "short_codename": "hung_vuong",
        "parentCode": "quan_hong_bang"
    },
    {
        "name": "Phường Sở Dầu",
        "code": "phuong_so_dau",
        "division_type": "phường",
        "short_codename": "so_dau",
        "parentCode": "quan_hong_bang"
    },
    {
        "name": "Phường Thượng Lý",
        "code": "phuong_thuong_ly",
        "division_type": "phường",
        "short_codename": "thuong_ly",
        "parentCode": "quan_hong_bang"
    },
    {
        "name": "Phường Hạ Lý",
        "code": "phuong_ha_ly",
        "division_type": "phường",
        "short_codename": "ha_ly",
        "parentCode": "quan_hong_bang"
    },
    {
        "name": "Phường Minh Khai",
        "code": "phuong_minh_khai",
        "division_type": "phường",
        "short_codename": "minh_khai",
        "parentCode": "quan_hong_bang"
    },
    {
        "name": "Phường Trại Chuối",
        "code": "phuong_trai_chuoi",
        "division_type": "phường",
        "short_codename": "trai_chuoi",
        "parentCode": "quan_hong_bang"
    },
    {
        "name": "Phường Hoàng Văn Thụ",
        "code": "phuong_hoang_van_thu",
        "division_type": "phường",
        "short_codename": "hoang_van_thu",
        "parentCode": "quan_hong_bang"
    },
    {
        "name": "Phường Phan Bội Châu",
        "code": "phuong_phan_boi_chau",
        "division_type": "phường",
        "short_codename": "phan_boi_chau",
        "parentCode": "quan_hong_bang"
    },
    {
        "name": "Phường Máy Chai",
        "code": "phuong_may_chai",
        "division_type": "phường",
        "short_codename": "may_chai",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Máy Tơ",
        "code": "phuong_may_to",
        "division_type": "phường",
        "short_codename": "may_to",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Vạn Mỹ",
        "code": "phuong_van_my",
        "division_type": "phường",
        "short_codename": "van_my",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Cầu Tre",
        "code": "phuong_cau_tre",
        "division_type": "phường",
        "short_codename": "cau_tre",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Lạc Viên",
        "code": "phuong_lac_vien",
        "division_type": "phường",
        "short_codename": "lac_vien",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Gia Viên",
        "code": "phuong_gia_vien",
        "division_type": "phường",
        "short_codename": "gia_vien",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Đông Khê",
        "code": "phuong_dong_khe",
        "division_type": "phường",
        "short_codename": "dong_khe",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Cầu Đất",
        "code": "phuong_cau_dat",
        "division_type": "phường",
        "short_codename": "cau_dat",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Lê Lợi",
        "code": "phuong_le_loi",
        "division_type": "phường",
        "short_codename": "le_loi",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Đằng Giang",
        "code": "phuong_dang_giang",
        "division_type": "phường",
        "short_codename": "dang_giang",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Lạch Tray",
        "code": "phuong_lach_tray",
        "division_type": "phường",
        "short_codename": "lach_tray",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Đổng Quốc Bình",
        "code": "phuong_dong_quoc_binh",
        "division_type": "phường",
        "short_codename": "dong_quoc_binh",
        "parentCode": "quan_ngo_quyen"
    },
    {
        "name": "Phường Cát Dài",
        "code": "phuong_cat_dai",
        "division_type": "phường",
        "short_codename": "cat_dai",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường An Biên",
        "code": "phuong_an_bien",
        "division_type": "phường",
        "short_codename": "an_bien",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Lam Sơn",
        "code": "phuong_lam_son",
        "division_type": "phường",
        "short_codename": "lam_son",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường An Dương",
        "code": "phuong_an_duong",
        "division_type": "phường",
        "short_codename": "an_duong",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Trần Nguyên Hãn",
        "code": "phuong_tran_nguyen_han",
        "division_type": "phường",
        "short_codename": "tran_nguyen_han",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Hồ Nam",
        "code": "phuong_ho_nam",
        "division_type": "phường",
        "short_codename": "ho_nam",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Trại Cau",
        "code": "phuong_trai_cau",
        "division_type": "phường",
        "short_codename": "trai_cau",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Dư Hàng",
        "code": "phuong_du_hang",
        "division_type": "phường",
        "short_codename": "du_hang",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Hàng Kênh",
        "code": "phuong_hang_kenh",
        "division_type": "phường",
        "short_codename": "hang_kenh",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Đông Hải",
        "code": "phuong_dong_hai",
        "division_type": "phường",
        "short_codename": "dong_hai",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Niệm Nghĩa",
        "code": "phuong_niem_nghia",
        "division_type": "phường",
        "short_codename": "niem_nghia",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Nghĩa Xá",
        "code": "phuong_nghia_xa",
        "division_type": "phường",
        "short_codename": "nghia_xa",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Dư Hàng Kênh",
        "code": "phuong_du_hang_kenh",
        "division_type": "phường",
        "short_codename": "du_hang_kenh",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Kênh Dương",
        "code": "phuong_kenh_duong",
        "division_type": "phường",
        "short_codename": "kenh_duong",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Vĩnh Niệm",
        "code": "phuong_vinh_niem",
        "division_type": "phường",
        "short_codename": "vinh_niem",
        "parentCode": "quan_le_chan"
    },
    {
        "name": "Phường Đông Hải 1",
        "code": "phuong_dong_hai_1",
        "division_type": "phường",
        "short_codename": "dong_hai_1",
        "parentCode": "quan_hai_an"
    },
    {
        "name": "Phường Đông Hải 2",
        "code": "phuong_dong_hai_2",
        "division_type": "phường",
        "short_codename": "dong_hai_2",
        "parentCode": "quan_hai_an"
    },
    {
        "name": "Phường Đằng Lâm",
        "code": "phuong_dang_lam",
        "division_type": "phường",
        "short_codename": "dang_lam",
        "parentCode": "quan_hai_an"
    },
    {
        "name": "Phường Thành Tô",
        "code": "phuong_thanh_to",
        "division_type": "phường",
        "short_codename": "thanh_to",
        "parentCode": "quan_hai_an"
    },
    {
        "name": "Phường Đằng Hải",
        "code": "phuong_dang_hai",
        "division_type": "phường",
        "short_codename": "dang_hai",
        "parentCode": "quan_hai_an"
    },
    {
        "name": "Phường Nam Hải",
        "code": "phuong_nam_hai",
        "division_type": "phường",
        "short_codename": "nam_hai",
        "parentCode": "quan_hai_an"
    },
    {
        "name": "Phường Cát Bi",
        "code": "phuong_cat_bi",
        "division_type": "phường",
        "short_codename": "cat_bi",
        "parentCode": "quan_hai_an"
    },
    {
        "name": "Phường Tràng Cát",
        "code": "phuong_trang_cat",
        "division_type": "phường",
        "short_codename": "trang_cat",
        "parentCode": "quan_hai_an"
    },
    {
        "name": "Phường Quán Trữ",
        "code": "phuong_quan_tru",
        "division_type": "phường",
        "short_codename": "quan_tru",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Lãm Hà",
        "code": "phuong_lam_ha",
        "division_type": "phường",
        "short_codename": "lam_ha",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Đồng Hoà",
        "code": "phuong_dong_hoa",
        "division_type": "phường",
        "short_codename": "dong_hoa",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Bắc Sơn",
        "code": "phuong_bac_son",
        "division_type": "phường",
        "short_codename": "bac_son",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Nam Sơn",
        "code": "phuong_nam_son",
        "division_type": "phường",
        "short_codename": "nam_son",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Ngọc Sơn",
        "code": "phuong_ngoc_son",
        "division_type": "phường",
        "short_codename": "ngoc_son",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Trần Thành Ngọ",
        "code": "phuong_tran_thanh_ngo",
        "division_type": "phường",
        "short_codename": "tran_thanh_ngo",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Văn Đẩu",
        "code": "phuong_van_dau",
        "division_type": "phường",
        "short_codename": "van_dau",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Phù Liễn",
        "code": "phuong_phu_lien",
        "division_type": "phường",
        "short_codename": "phu_lien",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Tràng Minh",
        "code": "phuong_trang_minh",
        "division_type": "phường",
        "short_codename": "trang_minh",
        "parentCode": "quan_kien_an"
    },
    {
        "name": "Phường Ngọc Xuyên",
        "code": "phuong_ngoc_xuyen",
        "division_type": "phường",
        "short_codename": "ngoc_xuyen",
        "parentCode": "quan_do_son"
    },
    {
        "name": "Phường Hải Sơn",
        "code": "phuong_hai_son",
        "division_type": "phường",
        "short_codename": "hai_son",
        "parentCode": "quan_do_son"
    },
    {
        "name": "Phường Vạn Hương",
        "code": "phuong_van_huong",
        "division_type": "phường",
        "short_codename": "van_huong",
        "parentCode": "quan_do_son"
    },
    {
        "name": "Phường Minh Đức",
        "code": "phuong_minh_duc",
        "division_type": "phường",
        "short_codename": "minh_duc",
        "parentCode": "quan_do_son"
    },
    {
        "name": "Phường Bàng La",
        "code": "phuong_bang_la",
        "division_type": "phường",
        "short_codename": "bang_la",
        "parentCode": "quan_do_son"
    },
    {
        "name": "Phường Hợp Đức",
        "code": "phuong_hop_duc",
        "division_type": "phường",
        "short_codename": "hop_duc",
        "parentCode": "quan_do_son"
    },
    {
        "name": "Phường Đa Phúc",
        "code": "phuong_da_phuc",
        "division_type": "phường",
        "short_codename": "da_phuc",
        "parentCode": "quan_duong_kinh"
    },
    {
        "name": "Phường Hưng Đạo",
        "code": "phuong_hung_dao",
        "division_type": "phường",
        "short_codename": "hung_dao",
        "parentCode": "quan_duong_kinh"
    },
    {
        "name": "Phường Anh Dũng",
        "code": "phuong_anh_dung",
        "division_type": "phường",
        "short_codename": "anh_dung",
        "parentCode": "quan_duong_kinh"
    },
    {
        "name": "Phường Hải Thành",
        "code": "phuong_hai_thanh",
        "division_type": "phường",
        "short_codename": "hai_thanh",
        "parentCode": "quan_duong_kinh"
    },
    {
        "name": "Phường Hoà Nghĩa",
        "code": "phuong_hoa_nghia",
        "division_type": "phường",
        "short_codename": "hoa_nghia",
        "parentCode": "quan_duong_kinh"
    },
    {
        "name": "Phường Tân Thành",
        "code": "phuong_tan_thanh",
        "division_type": "phường",
        "short_codename": "tan_thanh",
        "parentCode": "quan_duong_kinh"
    },
    {
        "name": "Thị trấn Núi Đèo",
        "code": "thi_tran_nui_deo",
        "division_type": "thị trấn",
        "short_codename": "nui_deo",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Thị trấn Minh Đức",
        "code": "thi_tran_minh_duc",
        "division_type": "thị trấn",
        "short_codename": "minh_duc",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Lại Xuân",
        "code": "xa_lai_xuan",
        "division_type": "xã",
        "short_codename": "lai_xuan",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã An Sơn",
        "code": "xa_an_son",
        "division_type": "xã",
        "short_codename": "an_son",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Kỳ Sơn",
        "code": "xa_ky_son",
        "division_type": "xã",
        "short_codename": "ky_son",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Liên Khê",
        "code": "xa_lien_khe",
        "division_type": "xã",
        "short_codename": "lien_khe",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Lưu Kiếm",
        "code": "xa_luu_kiem",
        "division_type": "xã",
        "short_codename": "luu_kiem",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Lưu Kỳ",
        "code": "xa_luu_ky",
        "division_type": "xã",
        "short_codename": "luu_ky",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Gia Minh",
        "code": "xa_gia_minh",
        "division_type": "xã",
        "short_codename": "gia_minh",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Gia Đức",
        "code": "xa_gia_duc",
        "division_type": "xã",
        "short_codename": "gia_duc",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Phù Ninh",
        "code": "xa_phu_ninh",
        "division_type": "xã",
        "short_codename": "phu_ninh",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Quảng Thanh",
        "code": "xa_quang_thanh",
        "division_type": "xã",
        "short_codename": "quang_thanh",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Chính Mỹ",
        "code": "xa_chinh_my",
        "division_type": "xã",
        "short_codename": "chinh_my",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Kênh Giang",
        "code": "xa_kenh_giang",
        "division_type": "xã",
        "short_codename": "kenh_giang",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Hợp Thành",
        "code": "xa_hop_thanh",
        "division_type": "xã",
        "short_codename": "hop_thanh",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Cao Nhân",
        "code": "xa_cao_nhan",
        "division_type": "xã",
        "short_codename": "cao_nhan",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Mỹ Đồng",
        "code": "xa_my_dong",
        "division_type": "xã",
        "short_codename": "my_dong",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Đông Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Hoà Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Trung Hà",
        "code": "xa_trung_ha",
        "division_type": "xã",
        "short_codename": "trung_ha",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã An Lư",
        "code": "xa_an_lu",
        "division_type": "xã",
        "short_codename": "an_lu",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Thuỷ Triều",
        "code": "xa_thuy_trieu",
        "division_type": "xã",
        "short_codename": "thuy_trieu",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Ngũ Lão",
        "code": "xa_ngu_lao",
        "division_type": "xã",
        "short_codename": "ngu_lao",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Phục Lễ",
        "code": "xa_phuc_le",
        "division_type": "xã",
        "short_codename": "phuc_le",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Tam Hưng",
        "code": "xa_tam_hung",
        "division_type": "xã",
        "short_codename": "tam_hung",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Phả Lễ",
        "code": "xa_pha_le",
        "division_type": "xã",
        "short_codename": "pha_le",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Lập Lễ",
        "code": "xa_lap_le",
        "division_type": "xã",
        "short_codename": "lap_le",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Kiền Bái",
        "code": "xa_kien_bai",
        "division_type": "xã",
        "short_codename": "kien_bai",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Thiên Hương",
        "code": "xa_thien_huong",
        "division_type": "xã",
        "short_codename": "thien_huong",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Thuỷ Sơn",
        "code": "xa_thuy_son",
        "division_type": "xã",
        "short_codename": "thuy_son",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Thuỷ Đường",
        "code": "xa_thuy_duong",
        "division_type": "xã",
        "short_codename": "thuy_duong",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Hoàng Động",
        "code": "xa_hoang_dong",
        "division_type": "xã",
        "short_codename": "hoang_dong",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Lâm Động",
        "code": "xa_lam_dong",
        "division_type": "xã",
        "short_codename": "lam_dong",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Hoa Động",
        "code": "xa_hoa_dong",
        "division_type": "xã",
        "short_codename": "hoa_dong",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Tân Dương",
        "code": "xa_tan_duong",
        "division_type": "xã",
        "short_codename": "tan_duong",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Xã Dương Quan",
        "code": "xa_duong_quan",
        "division_type": "xã",
        "short_codename": "duong_quan",
        "parentCode": "huyen_thuy_nguyen"
    },
    {
        "name": "Thị trấn An Dương",
        "code": "thi_tran_an_duong",
        "division_type": "thị trấn",
        "short_codename": "an_duong",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Lê Thiện",
        "code": "xa_le_thien",
        "division_type": "xã",
        "short_codename": "le_thien",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Đại Bản",
        "code": "xa_dai_ban",
        "division_type": "xã",
        "short_codename": "dai_ban",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã An Hoà",
        "code": "xa_an_hoa",
        "division_type": "xã",
        "short_codename": "an_hoa",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Hồng Phong",
        "code": "xa_hong_phong",
        "division_type": "xã",
        "short_codename": "hong_phong",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã An Hưng",
        "code": "xa_an_hung",
        "division_type": "xã",
        "short_codename": "an_hung",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã An Hồng",
        "code": "xa_an_hong",
        "division_type": "xã",
        "short_codename": "an_hong",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Nam Sơn",
        "code": "xa_nam_son",
        "division_type": "xã",
        "short_codename": "nam_son",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Lê Lợi",
        "code": "xa_le_loi",
        "division_type": "xã",
        "short_codename": "le_loi",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Đặng Cương",
        "code": "xa_dang_cuong",
        "division_type": "xã",
        "short_codename": "dang_cuong",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Đồng Thái",
        "code": "xa_dong_thai",
        "division_type": "xã",
        "short_codename": "dong_thai",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Quốc Tuấn",
        "code": "xa_quoc_tuan",
        "division_type": "xã",
        "short_codename": "quoc_tuan",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã An Đồng",
        "code": "xa_an_dong",
        "division_type": "xã",
        "short_codename": "an_dong",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Xã Hồng Thái",
        "code": "xa_hong_thai",
        "division_type": "xã",
        "short_codename": "hong_thai",
        "parentCode": "huyen_an_duong"
    },
    {
        "name": "Thị trấn An Lão",
        "code": "thi_tran_an_lao",
        "division_type": "thị trấn",
        "short_codename": "an_lao",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Bát Trang",
        "code": "xa_bat_trang",
        "division_type": "xã",
        "short_codename": "bat_trang",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Trường Thọ",
        "code": "xa_truong_tho",
        "division_type": "xã",
        "short_codename": "truong_tho",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Trường Thành",
        "code": "xa_truong_thanh",
        "division_type": "xã",
        "short_codename": "truong_thanh",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Tiến",
        "code": "xa_an_tien",
        "division_type": "xã",
        "short_codename": "an_tien",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Quang Hưng",
        "code": "xa_quang_hung",
        "division_type": "xã",
        "short_codename": "quang_hung",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Quốc Tuấn",
        "code": "xa_quoc_tuan",
        "division_type": "xã",
        "short_codename": "quoc_tuan",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Thắng",
        "code": "xa_an_thang",
        "division_type": "xã",
        "short_codename": "an_thang",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Thị trấn Trường Sơn",
        "code": "thi_tran_truong_son",
        "division_type": "thị trấn",
        "short_codename": "truong_son",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Tân Dân",
        "code": "xa_tan_dan",
        "division_type": "xã",
        "short_codename": "tan_dan",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Thái Sơn",
        "code": "xa_thai_son",
        "division_type": "xã",
        "short_codename": "thai_son",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Tân Viên",
        "code": "xa_tan_vien",
        "division_type": "xã",
        "short_codename": "tan_vien",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Mỹ Đức",
        "code": "xa_my_duc",
        "division_type": "xã",
        "short_codename": "my_duc",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã Chiến Thắng",
        "code": "xa_chien_thang",
        "division_type": "xã",
        "short_codename": "chien_thang",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Thọ",
        "code": "xa_an_tho",
        "division_type": "xã",
        "short_codename": "an_tho",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Thái",
        "code": "xa_an_thai",
        "division_type": "xã",
        "short_codename": "an_thai",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Thị trấn Núi Đối",
        "code": "thi_tran_nui_doi",
        "division_type": "thị trấn",
        "short_codename": "nui_doi",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Đông Phương",
        "code": "xa_dong_phuong",
        "division_type": "xã",
        "short_codename": "dong_phuong",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Thuận Thiên",
        "code": "xa_thuan_thien",
        "division_type": "xã",
        "short_codename": "thuan_thien",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Hữu Bằng",
        "code": "xa_huu_bang",
        "division_type": "xã",
        "short_codename": "huu_bang",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Đại Đồng",
        "code": "xa_dai_dong",
        "division_type": "xã",
        "short_codename": "dai_dong",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Ngũ Phúc",
        "code": "xa_ngu_phuc",
        "division_type": "xã",
        "short_codename": "ngu_phuc",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Kiến Quốc",
        "code": "xa_kien_quoc",
        "division_type": "xã",
        "short_codename": "kien_quoc",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Du Lễ",
        "code": "xa_du_le",
        "division_type": "xã",
        "short_codename": "du_le",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Thuỵ Hương",
        "code": "xa_thuy_huong",
        "division_type": "xã",
        "short_codename": "thuy_huong",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Đại Hà",
        "code": "xa_dai_ha",
        "division_type": "xã",
        "short_codename": "dai_ha",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Ngũ Đoan",
        "code": "xa_ngu_doan",
        "division_type": "xã",
        "short_codename": "ngu_doan",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Tân Phong",
        "code": "xa_tan_phong",
        "division_type": "xã",
        "short_codename": "tan_phong",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Tân Trào",
        "code": "xa_tan_trao",
        "division_type": "xã",
        "short_codename": "tan_trao",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Đoàn Xá",
        "code": "xa_doan_xa",
        "division_type": "xã",
        "short_codename": "doan_xa",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Tú Sơn",
        "code": "xa_tu_son",
        "division_type": "xã",
        "short_codename": "tu_son",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Xã Đại Hợp",
        "code": "xa_dai_hop",
        "division_type": "xã",
        "short_codename": "dai_hop",
        "parentCode": "huyen_kien_thuy"
    },
    {
        "name": "Thị trấn Tiên Lãng",
        "code": "thi_tran_tien_lang",
        "division_type": "thị trấn",
        "short_codename": "tien_lang",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Đại Thắng",
        "code": "xa_dai_thang",
        "division_type": "xã",
        "short_codename": "dai_thang",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Tiên Cường",
        "code": "xa_tien_cuong",
        "division_type": "xã",
        "short_codename": "tien_cuong",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Tự Cường",
        "code": "xa_tu_cuong",
        "division_type": "xã",
        "short_codename": "tu_cuong",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Quyết Tiến",
        "code": "xa_quyet_tien",
        "division_type": "xã",
        "short_codename": "quyet_tien",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Khởi Nghĩa",
        "code": "xa_khoi_nghia",
        "division_type": "xã",
        "short_codename": "khoi_nghia",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Tiên Thanh",
        "code": "xa_tien_thanh",
        "division_type": "xã",
        "short_codename": "tien_thanh",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Cấp Tiến",
        "code": "xa_cap_tien",
        "division_type": "xã",
        "short_codename": "cap_tien",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Kiến Thiết",
        "code": "xa_kien_thiet",
        "division_type": "xã",
        "short_codename": "kien_thiet",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Đoàn Lập",
        "code": "xa_doan_lap",
        "division_type": "xã",
        "short_codename": "doan_lap",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Bạch Đằng",
        "code": "xa_bach_dang",
        "division_type": "xã",
        "short_codename": "bach_dang",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Quang Phục",
        "code": "xa_quang_phuc",
        "division_type": "xã",
        "short_codename": "quang_phuc",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Toàn Thắng",
        "code": "xa_toan_thang",
        "division_type": "xã",
        "short_codename": "toan_thang",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Tiên Thắng",
        "code": "xa_tien_thang",
        "division_type": "xã",
        "short_codename": "tien_thang",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Tiên Minh",
        "code": "xa_tien_minh",
        "division_type": "xã",
        "short_codename": "tien_minh",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Bắc Hưng",
        "code": "xa_bac_hung",
        "division_type": "xã",
        "short_codename": "bac_hung",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Nam Hưng",
        "code": "xa_nam_hung",
        "division_type": "xã",
        "short_codename": "nam_hung",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Hùng Thắng",
        "code": "xa_hung_thang",
        "division_type": "xã",
        "short_codename": "hung_thang",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Tây Hưng",
        "code": "xa_tay_hung",
        "division_type": "xã",
        "short_codename": "tay_hung",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Đông Hưng",
        "code": "xa_dong_hung",
        "division_type": "xã",
        "short_codename": "dong_hung",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Xã Vinh Quang",
        "code": "xa_vinh_quang",
        "division_type": "xã",
        "short_codename": "vinh_quang",
        "parentCode": "huyen_tien_lang"
    },
    {
        "name": "Thị trấn Vĩnh Bảo",
        "code": "thi_tran_vinh_bao",
        "division_type": "thị trấn",
        "short_codename": "vinh_bao",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Dũng Tiến",
        "code": "xa_dung_tien",
        "division_type": "xã",
        "short_codename": "dung_tien",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Giang Biên",
        "code": "xa_giang_bien",
        "division_type": "xã",
        "short_codename": "giang_bien",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Thắng Thuỷ",
        "code": "xa_thang_thuy",
        "division_type": "xã",
        "short_codename": "thang_thuy",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Trung Lập",
        "code": "xa_trung_lap",
        "division_type": "xã",
        "short_codename": "trung_lap",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Việt Tiến",
        "code": "xa_viet_tien",
        "division_type": "xã",
        "short_codename": "viet_tien",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Vĩnh An",
        "code": "xa_vinh_an",
        "division_type": "xã",
        "short_codename": "vinh_an",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Vĩnh Long",
        "code": "xa_vinh_long",
        "division_type": "xã",
        "short_codename": "vinh_long",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Hiệp Hoà",
        "code": "xa_hiep_hoa",
        "division_type": "xã",
        "short_codename": "hiep_hoa",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Hùng Tiến",
        "code": "xa_hung_tien",
        "division_type": "xã",
        "short_codename": "hung_tien",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã An Hoà",
        "code": "xa_an_hoa",
        "division_type": "xã",
        "short_codename": "an_hoa",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Tân Liên",
        "code": "xa_tan_lien",
        "division_type": "xã",
        "short_codename": "tan_lien",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Nhân Hoà",
        "code": "xa_nhan_hoa",
        "division_type": "xã",
        "short_codename": "nhan_hoa",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Tam Đa",
        "code": "xa_tam_da",
        "division_type": "xã",
        "short_codename": "tam_da",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Hưng Nhân",
        "code": "xa_hung_nhan",
        "division_type": "xã",
        "short_codename": "hung_nhan",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Vinh Quang",
        "code": "xa_vinh_quang",
        "division_type": "xã",
        "short_codename": "vinh_quang",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Đồng Minh",
        "code": "xa_dong_minh",
        "division_type": "xã",
        "short_codename": "dong_minh",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Thanh Lương",
        "code": "xa_thanh_luong",
        "division_type": "xã",
        "short_codename": "thanh_luong",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Liên Am",
        "code": "xa_lien_am",
        "division_type": "xã",
        "short_codename": "lien_am",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Lý Học",
        "code": "xa_ly_hoc",
        "division_type": "xã",
        "short_codename": "ly_hoc",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Tam Cường",
        "code": "xa_tam_cuong",
        "division_type": "xã",
        "short_codename": "tam_cuong",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Hoà Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Tiền Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Vĩnh Phong",
        "code": "xa_vinh_phong",
        "division_type": "xã",
        "short_codename": "vinh_phong",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Cộng Hiền",
        "code": "xa_cong_hien",
        "division_type": "xã",
        "short_codename": "cong_hien",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Cao Minh",
        "code": "xa_cao_minh",
        "division_type": "xã",
        "short_codename": "cao_minh",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Cổ Am",
        "code": "xa_co_am",
        "division_type": "xã",
        "short_codename": "co_am",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Vĩnh Tiến",
        "code": "xa_vinh_tien",
        "division_type": "xã",
        "short_codename": "vinh_tien",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Xã Trấn Dương",
        "code": "xa_tran_duong",
        "division_type": "xã",
        "short_codename": "tran_duong",
        "parentCode": "huyen_vinh_bao"
    },
    {
        "name": "Thị trấn Cát Bà",
        "code": "thi_tran_cat_ba",
        "division_type": "thị trấn",
        "short_codename": "cat_ba",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Thị trấn Cát Hải",
        "code": "thi_tran_cat_hai",
        "division_type": "thị trấn",
        "short_codename": "cat_hai",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Nghĩa Lộ",
        "code": "xa_nghia_lo",
        "division_type": "xã",
        "short_codename": "nghia_lo",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Đồng Bài",
        "code": "xa_dong_bai",
        "division_type": "xã",
        "short_codename": "dong_bai",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Hoàng Châu",
        "code": "xa_hoang_chau",
        "division_type": "xã",
        "short_codename": "hoang_chau",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Văn Phong",
        "code": "xa_van_phong",
        "division_type": "xã",
        "short_codename": "van_phong",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Phù Long",
        "code": "xa_phu_long",
        "division_type": "xã",
        "short_codename": "phu_long",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Gia Luận",
        "code": "xa_gia_luan",
        "division_type": "xã",
        "short_codename": "gia_luan",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Hiền Hào",
        "code": "xa_hien_hao",
        "division_type": "xã",
        "short_codename": "hien_hao",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Trân Châu",
        "code": "xa_tran_chau",
        "division_type": "xã",
        "short_codename": "tran_chau",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Việt Hải",
        "code": "xa_viet_hai",
        "division_type": "xã",
        "short_codename": "viet_hai",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Xã Xuân Đám",
        "code": "xa_xuan_dam",
        "division_type": "xã",
        "short_codename": "xuan_dam",
        "parentCode": "huyen_cat_hai"
    },
    {
        "name": "Phường Lam Sơn",
        "code": "phuong_lam_son",
        "division_type": "phường",
        "short_codename": "lam_son",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Phường Hiến Nam",
        "code": "phuong_hien_nam",
        "division_type": "phường",
        "short_codename": "hien_nam",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Phường An Tảo",
        "code": "phuong_an_tao",
        "division_type": "phường",
        "short_codename": "an_tao",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Phường Lê Lợi",
        "code": "phuong_le_loi",
        "division_type": "phường",
        "short_codename": "le_loi",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Phường Minh Khai",
        "code": "phuong_minh_khai",
        "division_type": "phường",
        "short_codename": "minh_khai",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Phường Hồng Châu",
        "code": "phuong_hong_chau",
        "division_type": "phường",
        "short_codename": "hong_chau",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Trung Nghĩa",
        "code": "xa_trung_nghia",
        "division_type": "xã",
        "short_codename": "trung_nghia",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Liên Phương",
        "code": "xa_lien_phuong",
        "division_type": "xã",
        "short_codename": "lien_phuong",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Hồng Nam",
        "code": "xa_hong_nam",
        "division_type": "xã",
        "short_codename": "hong_nam",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Quảng Châu",
        "code": "xa_quang_chau",
        "division_type": "xã",
        "short_codename": "quang_chau",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Bảo Khê",
        "code": "xa_bao_khe",
        "division_type": "xã",
        "short_codename": "bao_khe",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Phú Cường",
        "code": "xa_phu_cuong",
        "division_type": "xã",
        "short_codename": "phu_cuong",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Hùng Cường",
        "code": "xa_hung_cuong",
        "division_type": "xã",
        "short_codename": "hung_cuong",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Phương Chiểu",
        "code": "xa_phuong_chieu",
        "division_type": "xã",
        "short_codename": "phuong_chieu",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Xã Hoàng Hanh",
        "code": "xa_hoang_hanh",
        "division_type": "xã",
        "short_codename": "hoang_hanh",
        "parentCode": "thanh_pho_hung_yen"
    },
    {
        "name": "Thị trấn Như Quỳnh",
        "code": "thi_tran_nhu_quynh",
        "division_type": "thị trấn",
        "short_codename": "nhu_quynh",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Lạc Đạo",
        "code": "xa_lac_dao",
        "division_type": "xã",
        "short_codename": "lac_dao",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Chỉ Đạo",
        "code": "xa_chi_dao",
        "division_type": "xã",
        "short_codename": "chi_dao",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Đại Đồng",
        "code": "xa_dai_dong",
        "division_type": "xã",
        "short_codename": "dai_dong",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Việt Hưng",
        "code": "xa_viet_hung",
        "division_type": "xã",
        "short_codename": "viet_hung",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Tân Quang",
        "code": "xa_tan_quang",
        "division_type": "xã",
        "short_codename": "tan_quang",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Đình Dù",
        "code": "xa_dinh_du",
        "division_type": "xã",
        "short_codename": "dinh_du",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Minh Hải",
        "code": "xa_minh_hai",
        "division_type": "xã",
        "short_codename": "minh_hai",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Lương Tài",
        "code": "xa_luong_tai",
        "division_type": "xã",
        "short_codename": "luong_tai",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Trưng Trắc",
        "code": "xa_trung_trac",
        "division_type": "xã",
        "short_codename": "trung_trac",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Xã Lạc Hồng",
        "code": "xa_lac_hong",
        "division_type": "xã",
        "short_codename": "lac_hong",
        "parentCode": "huyen_van_lam"
    },
    {
        "name": "Thị trấn Văn Giang",
        "code": "thi_tran_van_giang",
        "division_type": "thị trấn",
        "short_codename": "van_giang",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Xuân Quan",
        "code": "xa_xuan_quan",
        "division_type": "xã",
        "short_codename": "xuan_quan",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Cửu Cao",
        "code": "xa_cuu_cao",
        "division_type": "xã",
        "short_codename": "cuu_cao",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Phụng Công",
        "code": "xa_phung_cong",
        "division_type": "xã",
        "short_codename": "phung_cong",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Nghĩa Trụ",
        "code": "xa_nghia_tru",
        "division_type": "xã",
        "short_codename": "nghia_tru",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Long Hưng",
        "code": "xa_long_hung",
        "division_type": "xã",
        "short_codename": "long_hung",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Vĩnh Khúc",
        "code": "xa_vinh_khuc",
        "division_type": "xã",
        "short_codename": "vinh_khuc",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Liên Nghĩa",
        "code": "xa_lien_nghia",
        "division_type": "xã",
        "short_codename": "lien_nghia",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Thắng Lợi",
        "code": "xa_thang_loi",
        "division_type": "xã",
        "short_codename": "thang_loi",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Xã Mễ Sở",
        "code": "xa_me_so",
        "division_type": "xã",
        "short_codename": "me_so",
        "parentCode": "huyen_van_giang"
    },
    {
        "name": "Thị trấn Yên Mỹ",
        "code": "thi_tran_yen_my",
        "division_type": "thị trấn",
        "short_codename": "yen_my",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Giai Phạm",
        "code": "xa_giai_pham",
        "division_type": "xã",
        "short_codename": "giai_pham",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Nghĩa Hiệp",
        "code": "xa_nghia_hiep",
        "division_type": "xã",
        "short_codename": "nghia_hiep",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Đồng Than",
        "code": "xa_dong_than",
        "division_type": "xã",
        "short_codename": "dong_than",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Ngọc Long",
        "code": "xa_ngoc_long",
        "division_type": "xã",
        "short_codename": "ngoc_long",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Liêu Xá",
        "code": "xa_lieu_xa",
        "division_type": "xã",
        "short_codename": "lieu_xa",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Hoàn Long",
        "code": "xa_hoan_long",
        "division_type": "xã",
        "short_codename": "hoan_long",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Thanh Long",
        "code": "xa_thanh_long",
        "division_type": "xã",
        "short_codename": "thanh_long",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Yên Phú",
        "code": "xa_yen_phu",
        "division_type": "xã",
        "short_codename": "yen_phu",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Việt Cường",
        "code": "xa_viet_cuong",
        "division_type": "xã",
        "short_codename": "viet_cuong",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Trung Hòa",
        "code": "xa_trung_hoa",
        "division_type": "xã",
        "short_codename": "trung_hoa",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Yên Hòa",
        "code": "xa_yen_hoa",
        "division_type": "xã",
        "short_codename": "yen_hoa",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Minh Châu",
        "code": "xa_minh_chau",
        "division_type": "xã",
        "short_codename": "minh_chau",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Trung Hưng",
        "code": "xa_trung_hung",
        "division_type": "xã",
        "short_codename": "trung_hung",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Lý Thường Kiệt",
        "code": "xa_ly_thuong_kiet",
        "division_type": "xã",
        "short_codename": "ly_thuong_kiet",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Xã Tân Việt",
        "code": "xa_tan_viet",
        "division_type": "xã",
        "short_codename": "tan_viet",
        "parentCode": "huyen_yen_my"
    },
    {
        "name": "Phường Bần Yên Nhân",
        "code": "phuong_ban_yen_nhan",
        "division_type": "phường",
        "short_codename": "ban_yen_nhan",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Phường Phan Đình Phùng",
        "code": "phuong_phan_dinh_phung",
        "division_type": "phường",
        "short_codename": "phan_dinh_phung",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Xã Cẩm Xá",
        "code": "xa_cam_xa",
        "division_type": "xã",
        "short_codename": "cam_xa",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Xã Dương Quang",
        "code": "xa_duong_quang",
        "division_type": "xã",
        "short_codename": "duong_quang",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Xã Hòa Phong",
        "code": "xa_hoa_phong",
        "division_type": "xã",
        "short_codename": "hoa_phong",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Phường Nhân Hòa",
        "code": "phuong_nhan_hoa",
        "division_type": "phường",
        "short_codename": "nhan_hoa",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Phường Dị Sử",
        "code": "phuong_di_su",
        "division_type": "phường",
        "short_codename": "di_su",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Phường Bạch Sam",
        "code": "phuong_bach_sam",
        "division_type": "phường",
        "short_codename": "bach_sam",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Phường Minh Đức",
        "code": "phuong_minh_duc",
        "division_type": "phường",
        "short_codename": "minh_duc",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Phường Phùng Chí Kiên",
        "code": "phuong_phung_chi_kien",
        "division_type": "phường",
        "short_codename": "phung_chi_kien",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Xã Xuân Dục",
        "code": "xa_xuan_duc",
        "division_type": "xã",
        "short_codename": "xuan_duc",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Xã Ngọc Lâm",
        "code": "xa_ngoc_lam",
        "division_type": "xã",
        "short_codename": "ngoc_lam",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Xã Hưng Long",
        "code": "xa_hung_long",
        "division_type": "xã",
        "short_codename": "hung_long",
        "parentCode": "thi_xa_my_hao"
    },
    {
        "name": "Thị trấn Ân Thi",
        "code": "thi_tran_an_thi",
        "division_type": "thị trấn",
        "short_codename": "an_thi",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Phù Ủng",
        "code": "xa_phu_ung",
        "division_type": "xã",
        "short_codename": "phu_ung",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Bãi Sậy",
        "code": "xa_bai_say",
        "division_type": "xã",
        "short_codename": "bai_say",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Đào Dương",
        "code": "xa_dao_duong",
        "division_type": "xã",
        "short_codename": "dao_duong",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Tân Phúc",
        "code": "xa_tan_phuc",
        "division_type": "xã",
        "short_codename": "tan_phuc",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Vân Du",
        "code": "xa_van_du",
        "division_type": "xã",
        "short_codename": "van_du",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Quang Vinh",
        "code": "xa_quang_vinh",
        "division_type": "xã",
        "short_codename": "quang_vinh",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Xuân Trúc",
        "code": "xa_xuan_truc",
        "division_type": "xã",
        "short_codename": "xuan_truc",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Hoàng Hoa Thám",
        "code": "xa_hoang_hoa_tham",
        "division_type": "xã",
        "short_codename": "hoang_hoa_tham",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Quảng Lãng",
        "code": "xa_quang_lang",
        "division_type": "xã",
        "short_codename": "quang_lang",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Văn Nhuệ",
        "code": "xa_van_nhue",
        "division_type": "xã",
        "short_codename": "van_nhue",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Đặng Lễ",
        "code": "xa_dang_le",
        "division_type": "xã",
        "short_codename": "dang_le",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Cẩm Ninh",
        "code": "xa_cam_ninh",
        "division_type": "xã",
        "short_codename": "cam_ninh",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Nguyễn Trãi",
        "code": "xa_nguyen_trai",
        "division_type": "xã",
        "short_codename": "nguyen_trai",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Đa Lộc",
        "code": "xa_da_loc",
        "division_type": "xã",
        "short_codename": "da_loc",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Hồ Tùng Mậu",
        "code": "xa_ho_tung_mau",
        "division_type": "xã",
        "short_codename": "ho_tung_mau",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Tiền Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Hồng Vân",
        "code": "xa_hong_van",
        "division_type": "xã",
        "short_codename": "hong_van",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Hồng Quang",
        "code": "xa_hong_quang",
        "division_type": "xã",
        "short_codename": "hong_quang",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Xã Hạ Lễ",
        "code": "xa_ha_le",
        "division_type": "xã",
        "short_codename": "ha_le",
        "parentCode": "huyen_an_thi"
    },
    {
        "name": "Thị trấn Khoái Châu",
        "code": "thi_tran_khoai_chau",
        "division_type": "thị trấn",
        "short_codename": "khoai_chau",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Đông Tảo",
        "code": "xa_dong_tao",
        "division_type": "xã",
        "short_codename": "dong_tao",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Dạ Trạch",
        "code": "xa_da_trach",
        "division_type": "xã",
        "short_codename": "da_trach",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Hàm Tử",
        "code": "xa_ham_tu",
        "division_type": "xã",
        "short_codename": "ham_tu",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Ông Đình",
        "code": "xa_ong_dinh",
        "division_type": "xã",
        "short_codename": "ong_dinh",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Tân Dân",
        "code": "xa_tan_dan",
        "division_type": "xã",
        "short_codename": "tan_dan",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Tứ Dân",
        "code": "xa_tu_dan",
        "division_type": "xã",
        "short_codename": "tu_dan",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã An Vĩ",
        "code": "xa_an_vi",
        "division_type": "xã",
        "short_codename": "an_vi",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Đông Kết",
        "code": "xa_dong_ket",
        "division_type": "xã",
        "short_codename": "dong_ket",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Bình Kiều",
        "code": "xa_binh_kieu",
        "division_type": "xã",
        "short_codename": "binh_kieu",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Dân Tiến",
        "code": "xa_dan_tien",
        "division_type": "xã",
        "short_codename": "dan_tien",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Đồng Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Hồng Tiến",
        "code": "xa_hong_tien",
        "division_type": "xã",
        "short_codename": "hong_tien",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Tân Châu",
        "code": "xa_tan_chau",
        "division_type": "xã",
        "short_codename": "tan_chau",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Liên Khê",
        "code": "xa_lien_khe",
        "division_type": "xã",
        "short_codename": "lien_khe",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Phùng Hưng",
        "code": "xa_phung_hung",
        "division_type": "xã",
        "short_codename": "phung_hung",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Việt Hòa",
        "code": "xa_viet_hoa",
        "division_type": "xã",
        "short_codename": "viet_hoa",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Đông Ninh",
        "code": "xa_dong_ninh",
        "division_type": "xã",
        "short_codename": "dong_ninh",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Đại Tập",
        "code": "xa_dai_tap",
        "division_type": "xã",
        "short_codename": "dai_tap",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Chí Tân",
        "code": "xa_chi_tan",
        "division_type": "xã",
        "short_codename": "chi_tan",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Đại Hưng",
        "code": "xa_dai_hung",
        "division_type": "xã",
        "short_codename": "dai_hung",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Thuần Hưng",
        "code": "xa_thuan_hung",
        "division_type": "xã",
        "short_codename": "thuan_hung",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Thành Công",
        "code": "xa_thanh_cong",
        "division_type": "xã",
        "short_codename": "thanh_cong",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Xã Nhuế Dương",
        "code": "xa_nhue_duong",
        "division_type": "xã",
        "short_codename": "nhue_duong",
        "parentCode": "huyen_khoai_chau"
    },
    {
        "name": "Thị trấn Lương Bằng",
        "code": "thi_tran_luong_bang",
        "division_type": "thị trấn",
        "short_codename": "luong_bang",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Nghĩa Dân",
        "code": "xa_nghia_dan",
        "division_type": "xã",
        "short_codename": "nghia_dan",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Toàn Thắng",
        "code": "xa_toan_thang",
        "division_type": "xã",
        "short_codename": "toan_thang",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Vĩnh Xá",
        "code": "xa_vinh_xa",
        "division_type": "xã",
        "short_codename": "vinh_xa",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Phạm Ngũ Lão",
        "code": "xa_pham_ngu_lao",
        "division_type": "xã",
        "short_codename": "pham_ngu_lao",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Thọ Vinh",
        "code": "xa_tho_vinh",
        "division_type": "xã",
        "short_codename": "tho_vinh",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Đồng Thanh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Song Mai",
        "code": "xa_song_mai",
        "division_type": "xã",
        "short_codename": "song_mai",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Chính Nghĩa",
        "code": "xa_chinh_nghia",
        "division_type": "xã",
        "short_codename": "chinh_nghia",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Nhân La",
        "code": "xa_nhan_la",
        "division_type": "xã",
        "short_codename": "nhan_la",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Phú Thịnh",
        "code": "xa_phu_thinh",
        "division_type": "xã",
        "short_codename": "phu_thinh",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Mai Động",
        "code": "xa_mai_dong",
        "division_type": "xã",
        "short_codename": "mai_dong",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Đức Hợp",
        "code": "xa_duc_hop",
        "division_type": "xã",
        "short_codename": "duc_hop",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Hùng An",
        "code": "xa_hung_an",
        "division_type": "xã",
        "short_codename": "hung_an",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Ngọc Thanh",
        "code": "xa_ngoc_thanh",
        "division_type": "xã",
        "short_codename": "ngoc_thanh",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Vũ Xá",
        "code": "xa_vu_xa",
        "division_type": "xã",
        "short_codename": "vu_xa",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Xã Hiệp Cường",
        "code": "xa_hiep_cuong",
        "division_type": "xã",
        "short_codename": "hiep_cuong",
        "parentCode": "huyen_kim_dong"
    },
    {
        "name": "Thị trấn Vương",
        "code": "thi_tran_vuong",
        "division_type": "thị trấn",
        "short_codename": "vuong",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Hưng Đạo",
        "code": "xa_hung_dao",
        "division_type": "xã",
        "short_codename": "hung_dao",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Ngô Quyền",
        "code": "xa_ngo_quyen",
        "division_type": "xã",
        "short_codename": "ngo_quyen",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Nhật Tân",
        "code": "xa_nhat_tan",
        "division_type": "xã",
        "short_codename": "nhat_tan",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Dị Chế",
        "code": "xa_di_che",
        "division_type": "xã",
        "short_codename": "di_che",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Lệ Xá",
        "code": "xa_le_xa",
        "division_type": "xã",
        "short_codename": "le_xa",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã An Viên",
        "code": "xa_an_vien",
        "division_type": "xã",
        "short_codename": "an_vien",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Đức Thắng",
        "code": "xa_duc_thang",
        "division_type": "xã",
        "short_codename": "duc_thang",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Trung Dũng",
        "code": "xa_trung_dung",
        "division_type": "xã",
        "short_codename": "trung_dung",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Hải Triều",
        "code": "xa_hai_trieu",
        "division_type": "xã",
        "short_codename": "hai_trieu",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Thủ Sỹ",
        "code": "xa_thu_sy",
        "division_type": "xã",
        "short_codename": "thu_sy",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Thiện Phiến",
        "code": "xa_thien_phien",
        "division_type": "xã",
        "short_codename": "thien_phien",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Thụy Lôi",
        "code": "xa_thuy_loi",
        "division_type": "xã",
        "short_codename": "thuy_loi",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Cương Chính",
        "code": "xa_cuong_chinh",
        "division_type": "xã",
        "short_codename": "cuong_chinh",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Xã Minh Phượng",
        "code": "xa_minh_phuong",
        "division_type": "xã",
        "short_codename": "minh_phuong",
        "parentCode": "huyen_tien_lu"
    },
    {
        "name": "Thị trấn Trần Cao",
        "code": "thi_tran_tran_cao",
        "division_type": "thị trấn",
        "short_codename": "tran_cao",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Phan Sào Nam",
        "code": "xa_phan_sao_nam",
        "division_type": "xã",
        "short_codename": "phan_sao_nam",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Quang Hưng",
        "code": "xa_quang_hung",
        "division_type": "xã",
        "short_codename": "quang_hung",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Minh Hoàng",
        "code": "xa_minh_hoang",
        "division_type": "xã",
        "short_codename": "minh_hoang",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Đoàn Đào",
        "code": "xa_doan_dao",
        "division_type": "xã",
        "short_codename": "doan_dao",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Tống Phan",
        "code": "xa_tong_phan",
        "division_type": "xã",
        "short_codename": "tong_phan",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Đình Cao",
        "code": "xa_dinh_cao",
        "division_type": "xã",
        "short_codename": "dinh_cao",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Nhật Quang",
        "code": "xa_nhat_quang",
        "division_type": "xã",
        "short_codename": "nhat_quang",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Tiền Tiến",
        "code": "xa_tien_tien",
        "division_type": "xã",
        "short_codename": "tien_tien",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Tam Đa",
        "code": "xa_tam_da",
        "division_type": "xã",
        "short_codename": "tam_da",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Minh Tiến",
        "code": "xa_minh_tien",
        "division_type": "xã",
        "short_codename": "minh_tien",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Nguyên Hòa",
        "code": "xa_nguyen_hoa",
        "division_type": "xã",
        "short_codename": "nguyen_hoa",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Xã Tống Trân",
        "code": "xa_tong_tran",
        "division_type": "xã",
        "short_codename": "tong_tran",
        "parentCode": "huyen_phu_cu"
    },
    {
        "name": "Phường Lê Hồng Phong",
        "code": "phuong_le_hong_phong",
        "division_type": "phường",
        "short_codename": "le_hong_phong",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Phường Bồ Xuyên",
        "code": "phuong_bo_xuyen",
        "division_type": "phường",
        "short_codename": "bo_xuyen",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Phường Đề Thám",
        "code": "phuong_de_tham",
        "division_type": "phường",
        "short_codename": "de_tham",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Phường Kỳ Bá",
        "code": "phuong_ky_ba",
        "division_type": "phường",
        "short_codename": "ky_ba",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Phường Phú Khánh",
        "code": "phuong_phu_khanh",
        "division_type": "phường",
        "short_codename": "phu_khanh",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Phường Tiền Phong",
        "code": "phuong_tien_phong",
        "division_type": "phường",
        "short_codename": "tien_phong",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Phường Trần Hưng Đạo",
        "code": "phuong_tran_hung_dao",
        "division_type": "phường",
        "short_codename": "tran_hung_dao",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Phường Trần Lãm",
        "code": "phuong_tran_lam",
        "division_type": "phường",
        "short_codename": "tran_lam",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Xã Đông Hòa",
        "code": "xa_dong_hoa",
        "division_type": "xã",
        "short_codename": "dong_hoa",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Phường Hoàng Diệu",
        "code": "phuong_hoang_dieu",
        "division_type": "phường",
        "short_codename": "hoang_dieu",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Xã Phú Xuân",
        "code": "xa_phu_xuan",
        "division_type": "xã",
        "short_codename": "phu_xuan",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Xã Vũ Phúc",
        "code": "xa_vu_phuc",
        "division_type": "xã",
        "short_codename": "vu_phuc",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Xã Vũ Chính",
        "code": "xa_vu_chinh",
        "division_type": "xã",
        "short_codename": "vu_chinh",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Xã Đông Mỹ",
        "code": "xa_dong_my",
        "division_type": "xã",
        "short_codename": "dong_my",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Xã Đông Thọ",
        "code": "xa_dong_tho",
        "division_type": "xã",
        "short_codename": "dong_tho",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Xã Vũ Đông",
        "code": "xa_vu_dong",
        "division_type": "xã",
        "short_codename": "vu_dong",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Xã Vũ Lạc",
        "code": "xa_vu_lac",
        "division_type": "xã",
        "short_codename": "vu_lac",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "thanh_pho_thai_binh"
    },
    {
        "name": "Thị trấn Quỳnh Côi",
        "code": "thi_tran_quynh_coi",
        "division_type": "thị trấn",
        "short_codename": "quynh_coi",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Khê",
        "code": "xa_an_khe",
        "division_type": "xã",
        "short_codename": "an_khe",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Đồng",
        "code": "xa_an_dong",
        "division_type": "xã",
        "short_codename": "an_dong",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Hoa",
        "code": "xa_quynh_hoa",
        "division_type": "xã",
        "short_codename": "quynh_hoa",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Lâm",
        "code": "xa_quynh_lam",
        "division_type": "xã",
        "short_codename": "quynh_lam",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Thọ",
        "code": "xa_quynh_tho",
        "division_type": "xã",
        "short_codename": "quynh_tho",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Hiệp",
        "code": "xa_an_hiep",
        "division_type": "xã",
        "short_codename": "an_hiep",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Hoàng",
        "code": "xa_quynh_hoang",
        "division_type": "xã",
        "short_codename": "quynh_hoang",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Giao",
        "code": "xa_quynh_giao",
        "division_type": "xã",
        "short_codename": "quynh_giao",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Thái",
        "code": "xa_an_thai",
        "division_type": "xã",
        "short_codename": "an_thai",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Cầu",
        "code": "xa_an_cau",
        "division_type": "xã",
        "short_codename": "an_cau",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Hồng",
        "code": "xa_quynh_hong",
        "division_type": "xã",
        "short_codename": "quynh_hong",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Khê",
        "code": "xa_quynh_khe",
        "division_type": "xã",
        "short_codename": "quynh_khe",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Minh",
        "code": "xa_quynh_minh",
        "division_type": "xã",
        "short_codename": "quynh_minh",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Ninh",
        "code": "xa_an_ninh",
        "division_type": "xã",
        "short_codename": "an_ninh",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Ngọc",
        "code": "xa_quynh_ngoc",
        "division_type": "xã",
        "short_codename": "quynh_ngoc",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Hải",
        "code": "xa_quynh_hai",
        "division_type": "xã",
        "short_codename": "quynh_hai",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Thị trấn An Bài",
        "code": "thi_tran_an_bai",
        "division_type": "thị trấn",
        "short_codename": "an_bai",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Ấp",
        "code": "xa_an_ap",
        "division_type": "xã",
        "short_codename": "an_ap",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Hội",
        "code": "xa_quynh_hoi",
        "division_type": "xã",
        "short_codename": "quynh_hoi",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Châu Sơn",
        "code": "xa_chau_son",
        "division_type": "xã",
        "short_codename": "chau_son",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Mỹ",
        "code": "xa_quynh_my",
        "division_type": "xã",
        "short_codename": "quynh_my",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Quí",
        "code": "xa_an_qui",
        "division_type": "xã",
        "short_codename": "an_qui",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Thanh",
        "code": "xa_an_thanh",
        "division_type": "xã",
        "short_codename": "an_thanh",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Vũ",
        "code": "xa_an_vu",
        "division_type": "xã",
        "short_codename": "an_vu",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Lễ",
        "code": "xa_an_le",
        "division_type": "xã",
        "short_codename": "an_le",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Hưng",
        "code": "xa_quynh_hung",
        "division_type": "xã",
        "short_codename": "quynh_hung",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Bảo",
        "code": "xa_quynh_bao",
        "division_type": "xã",
        "short_codename": "quynh_bao",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Mỹ",
        "code": "xa_an_my",
        "division_type": "xã",
        "short_codename": "an_my",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Nguyên",
        "code": "xa_quynh_nguyen",
        "division_type": "xã",
        "short_codename": "quynh_nguyen",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Vinh",
        "code": "xa_an_vinh",
        "division_type": "xã",
        "short_codename": "an_vinh",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Xá",
        "code": "xa_quynh_xa",
        "division_type": "xã",
        "short_codename": "quynh_xa",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Dục",
        "code": "xa_an_duc",
        "division_type": "xã",
        "short_codename": "an_duc",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Đông Hải",
        "code": "xa_dong_hai",
        "division_type": "xã",
        "short_codename": "dong_hai",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Quỳnh Trang",
        "code": "xa_quynh_trang",
        "division_type": "xã",
        "short_codename": "quynh_trang",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã An Tràng",
        "code": "xa_an_trang",
        "division_type": "xã",
        "short_codename": "an_trang",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Xã Đồng Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_quynh_phu"
    },
    {
        "name": "Thị trấn Hưng Hà",
        "code": "thi_tran_hung_ha",
        "division_type": "thị trấn",
        "short_codename": "hung_ha",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Điệp Nông",
        "code": "xa_diep_nong",
        "division_type": "xã",
        "short_codename": "diep_nong",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Tân Lễ",
        "code": "xa_tan_le",
        "division_type": "xã",
        "short_codename": "tan_le",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Cộng Hòa",
        "code": "xa_cong_hoa",
        "division_type": "xã",
        "short_codename": "cong_hoa",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Dân Chủ",
        "code": "xa_dan_chu",
        "division_type": "xã",
        "short_codename": "dan_chu",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Canh Tân",
        "code": "xa_canh_tan",
        "division_type": "xã",
        "short_codename": "canh_tan",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Hòa Tiến",
        "code": "xa_hoa_tien",
        "division_type": "xã",
        "short_codename": "hoa_tien",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Hùng Dũng",
        "code": "xa_hung_dung",
        "division_type": "xã",
        "short_codename": "hung_dung",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Thị trấn Hưng Nhân",
        "code": "thi_tran_hung_nhan",
        "division_type": "thị trấn",
        "short_codename": "hung_nhan",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Đoan Hùng",
        "code": "xa_doan_hung",
        "division_type": "xã",
        "short_codename": "doan_hung",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Duyên Hải",
        "code": "xa_duyen_hai",
        "division_type": "xã",
        "short_codename": "duyen_hai",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Văn Cẩm",
        "code": "xa_van_cam",
        "division_type": "xã",
        "short_codename": "van_cam",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Đông Đô",
        "code": "xa_dong_do",
        "division_type": "xã",
        "short_codename": "dong_do",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Phúc Khánh",
        "code": "xa_phuc_khanh",
        "division_type": "xã",
        "short_codename": "phuc_khanh",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Liên Hiệp",
        "code": "xa_lien_hiep",
        "division_type": "xã",
        "short_codename": "lien_hiep",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Tây Đô",
        "code": "xa_tay_do",
        "division_type": "xã",
        "short_codename": "tay_do",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Thống Nhất",
        "code": "xa_thong_nhat",
        "division_type": "xã",
        "short_codename": "thong_nhat",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Tiến Đức",
        "code": "xa_tien_duc",
        "division_type": "xã",
        "short_codename": "tien_duc",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Thái Hưng",
        "code": "xa_thai_hung",
        "division_type": "xã",
        "short_codename": "thai_hung",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Thái Phương",
        "code": "xa_thai_phuong",
        "division_type": "xã",
        "short_codename": "thai_phuong",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Chi Lăng",
        "code": "xa_chi_lang",
        "division_type": "xã",
        "short_codename": "chi_lang",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Minh Khai",
        "code": "xa_minh_khai",
        "division_type": "xã",
        "short_codename": "minh_khai",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Hồng An",
        "code": "xa_hong_an",
        "division_type": "xã",
        "short_codename": "hong_an",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Kim Chung",
        "code": "xa_kim_chung",
        "division_type": "xã",
        "short_codename": "kim_chung",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Hồng Lĩnh",
        "code": "xa_hong_linh",
        "division_type": "xã",
        "short_codename": "hong_linh",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Văn Lang",
        "code": "xa_van_lang",
        "division_type": "xã",
        "short_codename": "van_lang",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Độc Lập",
        "code": "xa_doc_lap",
        "division_type": "xã",
        "short_codename": "doc_lap",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Chí Hòa",
        "code": "xa_chi_hoa",
        "division_type": "xã",
        "short_codename": "chi_hoa",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Minh Hòa",
        "code": "xa_minh_hoa",
        "division_type": "xã",
        "short_codename": "minh_hoa",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Xã Hồng Minh",
        "code": "xa_hong_minh",
        "division_type": "xã",
        "short_codename": "hong_minh",
        "parentCode": "huyen_hung_ha"
    },
    {
        "name": "Thị trấn Đông Hưng",
        "code": "thi_tran_dong_hung",
        "division_type": "thị trấn",
        "short_codename": "dong_hung",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đô Lương",
        "code": "xa_do_luong",
        "division_type": "xã",
        "short_codename": "do_luong",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Phương",
        "code": "xa_dong_phuong",
        "division_type": "xã",
        "short_codename": "dong_phuong",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Liên Giang",
        "code": "xa_lien_giang",
        "division_type": "xã",
        "short_codename": "lien_giang",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã An Châu",
        "code": "xa_an_chau",
        "division_type": "xã",
        "short_codename": "an_chau",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Cường",
        "code": "xa_dong_cuong",
        "division_type": "xã",
        "short_codename": "dong_cuong",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Phú Lương",
        "code": "xa_phu_luong",
        "division_type": "xã",
        "short_codename": "phu_luong",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Mê Linh",
        "code": "xa_me_linh",
        "division_type": "xã",
        "short_codename": "me_linh",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Lô Giang",
        "code": "xa_lo_giang",
        "division_type": "xã",
        "short_codename": "lo_giang",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông La",
        "code": "xa_dong_la",
        "division_type": "xã",
        "short_codename": "dong_la",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Xá",
        "code": "xa_dong_xa",
        "division_type": "xã",
        "short_codename": "dong_xa",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Chương Dương",
        "code": "xa_chuong_duong",
        "division_type": "xã",
        "short_codename": "chuong_duong",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Nguyên Xá",
        "code": "xa_nguyen_xa",
        "division_type": "xã",
        "short_codename": "nguyen_xa",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Phong Châu",
        "code": "xa_phong_chau",
        "division_type": "xã",
        "short_codename": "phong_chau",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Hợp Tiến",
        "code": "xa_hop_tien",
        "division_type": "xã",
        "short_codename": "hop_tien",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Hồng Việt",
        "code": "xa_hong_viet",
        "division_type": "xã",
        "short_codename": "hong_viet",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Hà Giang",
        "code": "xa_ha_giang",
        "division_type": "xã",
        "short_codename": "ha_giang",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Kinh",
        "code": "xa_dong_kinh",
        "division_type": "xã",
        "short_codename": "dong_kinh",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Hợp",
        "code": "xa_dong_hop",
        "division_type": "xã",
        "short_codename": "dong_hop",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Thăng Long",
        "code": "xa_thang_long",
        "division_type": "xã",
        "short_codename": "thang_long",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Các",
        "code": "xa_dong_cac",
        "division_type": "xã",
        "short_codename": "dong_cac",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Phú Châu",
        "code": "xa_phu_chau",
        "division_type": "xã",
        "short_codename": "phu_chau",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Liên Hoa",
        "code": "xa_lien_hoa",
        "division_type": "xã",
        "short_codename": "lien_hoa",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Tân",
        "code": "xa_dong_tan",
        "division_type": "xã",
        "short_codename": "dong_tan",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Vinh",
        "code": "xa_dong_vinh",
        "division_type": "xã",
        "short_codename": "dong_vinh",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Động",
        "code": "xa_dong_dong",
        "division_type": "xã",
        "short_codename": "dong_dong",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Hồng Bạch",
        "code": "xa_hong_bach",
        "division_type": "xã",
        "short_codename": "hong_bach",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Trọng Quan",
        "code": "xa_trong_quan",
        "division_type": "xã",
        "short_codename": "trong_quan",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Hồng Giang",
        "code": "xa_hong_giang",
        "division_type": "xã",
        "short_codename": "hong_giang",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Quan",
        "code": "xa_dong_quan",
        "division_type": "xã",
        "short_codename": "dong_quan",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Quang",
        "code": "xa_dong_quang",
        "division_type": "xã",
        "short_codename": "dong_quang",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Xuân",
        "code": "xa_dong_xuan",
        "division_type": "xã",
        "short_codename": "dong_xuan",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Á",
        "code": "xa_dong_a",
        "division_type": "xã",
        "short_codename": "dong_a",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Hoàng",
        "code": "xa_dong_hoang",
        "division_type": "xã",
        "short_codename": "dong_hoang",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Đông Dương",
        "code": "xa_dong_duong",
        "division_type": "xã",
        "short_codename": "dong_duong",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Xã Minh Phú",
        "code": "xa_minh_phu",
        "division_type": "xã",
        "short_codename": "minh_phu",
        "parentCode": "huyen_dong_hung"
    },
    {
        "name": "Thị trấn Diêm Điền",
        "code": "thi_tran_diem_dien",
        "division_type": "thị trấn",
        "short_codename": "diem_dien",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Trường",
        "code": "xa_thuy_truong",
        "division_type": "xã",
        "short_codename": "thuy_truong",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Hồng Dũng",
        "code": "xa_hong_dung",
        "division_type": "xã",
        "short_codename": "hong_dung",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Quỳnh",
        "code": "xa_thuy_quynh",
        "division_type": "xã",
        "short_codename": "thuy_quynh",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã An Tân",
        "code": "xa_an_tan",
        "division_type": "xã",
        "short_codename": "an_tan",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Ninh",
        "code": "xa_thuy_ninh",
        "division_type": "xã",
        "short_codename": "thuy_ninh",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Hưng",
        "code": "xa_thuy_hung",
        "division_type": "xã",
        "short_codename": "thuy_hung",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Việt",
        "code": "xa_thuy_viet",
        "division_type": "xã",
        "short_codename": "thuy_viet",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Văn",
        "code": "xa_thuy_van",
        "division_type": "xã",
        "short_codename": "thuy_van",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Xuân",
        "code": "xa_thuy_xuan",
        "division_type": "xã",
        "short_codename": "thuy_xuan",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Dương Phúc",
        "code": "xa_duong_phuc",
        "division_type": "xã",
        "short_codename": "duong_phuc",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Trình",
        "code": "xa_thuy_trinh",
        "division_type": "xã",
        "short_codename": "thuy_trinh",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Bình",
        "code": "xa_thuy_binh",
        "division_type": "xã",
        "short_codename": "thuy_binh",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Chính",
        "code": "xa_thuy_chinh",
        "division_type": "xã",
        "short_codename": "thuy_chinh",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Dân",
        "code": "xa_thuy_dan",
        "division_type": "xã",
        "short_codename": "thuy_dan",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Hải",
        "code": "xa_thuy_hai",
        "division_type": "xã",
        "short_codename": "thuy_hai",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Liên",
        "code": "xa_thuy_lien",
        "division_type": "xã",
        "short_codename": "thuy_lien",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Duyên",
        "code": "xa_thuy_duyen",
        "division_type": "xã",
        "short_codename": "thuy_duyen",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Thanh",
        "code": "xa_thuy_thanh",
        "division_type": "xã",
        "short_codename": "thuy_thanh",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Sơn",
        "code": "xa_thuy_son",
        "division_type": "xã",
        "short_codename": "thuy_son",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thụy Phong",
        "code": "xa_thuy_phong",
        "division_type": "xã",
        "short_codename": "thuy_phong",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thái Thượng",
        "code": "xa_thai_thuong",
        "division_type": "xã",
        "short_codename": "thai_thuong",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thái Nguyên",
        "code": "xa_thai_nguyen",
        "division_type": "xã",
        "short_codename": "thai_nguyen",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Dương Hồng Thủy",
        "code": "xa_duong_hong_thuy",
        "division_type": "xã",
        "short_codename": "duong_hong_thuy",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thái Giang",
        "code": "xa_thai_giang",
        "division_type": "xã",
        "short_codename": "thai_giang",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Hòa An",
        "code": "xa_hoa_an",
        "division_type": "xã",
        "short_codename": "hoa_an",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Sơn Hà",
        "code": "xa_son_ha",
        "division_type": "xã",
        "short_codename": "son_ha",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thái Phúc",
        "code": "xa_thai_phuc",
        "division_type": "xã",
        "short_codename": "thai_phuc",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thái Hưng",
        "code": "xa_thai_hung",
        "division_type": "xã",
        "short_codename": "thai_hung",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thái Đô",
        "code": "xa_thai_do",
        "division_type": "xã",
        "short_codename": "thai_do",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thái Xuyên",
        "code": "xa_thai_xuyen",
        "division_type": "xã",
        "short_codename": "thai_xuyen",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Mỹ Lộc",
        "code": "xa_my_loc",
        "division_type": "xã",
        "short_codename": "my_loc",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Tân Học",
        "code": "xa_tan_hoc",
        "division_type": "xã",
        "short_codename": "tan_hoc",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thái Thịnh",
        "code": "xa_thai_thinh",
        "division_type": "xã",
        "short_codename": "thai_thinh",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thuần Thành",
        "code": "xa_thuan_thanh",
        "division_type": "xã",
        "short_codename": "thuan_thanh",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Xã Thái Thọ",
        "code": "xa_thai_tho",
        "division_type": "xã",
        "short_codename": "thai_tho",
        "parentCode": "huyen_thai_thuy"
    },
    {
        "name": "Thị trấn Tiền Hải",
        "code": "thi_tran_tien_hai",
        "division_type": "thị trấn",
        "short_codename": "tien_hai",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Trà",
        "code": "xa_dong_tra",
        "division_type": "xã",
        "short_codename": "dong_tra",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Long",
        "code": "xa_dong_long",
        "division_type": "xã",
        "short_codename": "dong_long",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Quí",
        "code": "xa_dong_qui",
        "division_type": "xã",
        "short_codename": "dong_qui",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Vũ Lăng",
        "code": "xa_vu_lang",
        "division_type": "xã",
        "short_codename": "vu_lang",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Xuyên",
        "code": "xa_dong_xuyen",
        "division_type": "xã",
        "short_codename": "dong_xuyen",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Tây Lương",
        "code": "xa_tay_luong",
        "division_type": "xã",
        "short_codename": "tay_luong",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Tây Ninh",
        "code": "xa_tay_ninh",
        "division_type": "xã",
        "short_codename": "tay_ninh",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Trung",
        "code": "xa_dong_trung",
        "division_type": "xã",
        "short_codename": "dong_trung",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Hoàng",
        "code": "xa_dong_hoang",
        "division_type": "xã",
        "short_codename": "dong_hoang",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Minh",
        "code": "xa_dong_minh",
        "division_type": "xã",
        "short_codename": "dong_minh",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Phong",
        "code": "xa_dong_phong",
        "division_type": "xã",
        "short_codename": "dong_phong",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã An Ninh",
        "code": "xa_an_ninh",
        "division_type": "xã",
        "short_codename": "an_ninh",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Cơ",
        "code": "xa_dong_co",
        "division_type": "xã",
        "short_codename": "dong_co",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Tây Giang",
        "code": "xa_tay_giang",
        "division_type": "xã",
        "short_codename": "tay_giang",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Đông Lâm",
        "code": "xa_dong_lam",
        "division_type": "xã",
        "short_codename": "dong_lam",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Phương Công",
        "code": "xa_phuong_cong",
        "division_type": "xã",
        "short_codename": "phuong_cong",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Tây Phong",
        "code": "xa_tay_phong",
        "division_type": "xã",
        "short_codename": "tay_phong",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Tây Tiến",
        "code": "xa_tay_tien",
        "division_type": "xã",
        "short_codename": "tay_tien",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Cường",
        "code": "xa_nam_cuong",
        "division_type": "xã",
        "short_codename": "nam_cuong",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Vân Trường",
        "code": "xa_van_truong",
        "division_type": "xã",
        "short_codename": "van_truong",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Thắng",
        "code": "xa_nam_thang",
        "division_type": "xã",
        "short_codename": "nam_thang",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Chính",
        "code": "xa_nam_chinh",
        "division_type": "xã",
        "short_codename": "nam_chinh",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Bắc Hải",
        "code": "xa_bac_hai",
        "division_type": "xã",
        "short_codename": "bac_hai",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Thịnh",
        "code": "xa_nam_thinh",
        "division_type": "xã",
        "short_codename": "nam_thinh",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Hà",
        "code": "xa_nam_ha",
        "division_type": "xã",
        "short_codename": "nam_ha",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Thanh",
        "code": "xa_nam_thanh",
        "division_type": "xã",
        "short_codename": "nam_thanh",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Trung",
        "code": "xa_nam_trung",
        "division_type": "xã",
        "short_codename": "nam_trung",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Hồng",
        "code": "xa_nam_hong",
        "division_type": "xã",
        "short_codename": "nam_hong",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Hưng",
        "code": "xa_nam_hung",
        "division_type": "xã",
        "short_codename": "nam_hung",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Hải",
        "code": "xa_nam_hai",
        "division_type": "xã",
        "short_codename": "nam_hai",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Xã Nam Phú",
        "code": "xa_nam_phu",
        "division_type": "xã",
        "short_codename": "nam_phu",
        "parentCode": "huyen_tien_hai"
    },
    {
        "name": "Thị trấn Kiến Xương",
        "code": "thi_tran_kien_xuong",
        "division_type": "thị trấn",
        "short_codename": "kien_xuong",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Trà Giang",
        "code": "xa_tra_giang",
        "division_type": "xã",
        "short_codename": "tra_giang",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Quốc Tuấn",
        "code": "xa_quoc_tuan",
        "division_type": "xã",
        "short_codename": "quoc_tuan",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Tây Sơn",
        "code": "xa_tay_son",
        "division_type": "xã",
        "short_codename": "tay_son",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Hồng Thái",
        "code": "xa_hong_thai",
        "division_type": "xã",
        "short_codename": "hong_thai",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Bình Nguyên",
        "code": "xa_binh_nguyen",
        "division_type": "xã",
        "short_codename": "binh_nguyen",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Lê Lợi",
        "code": "xa_le_loi",
        "division_type": "xã",
        "short_codename": "le_loi",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Vũ Lễ",
        "code": "xa_vu_le",
        "division_type": "xã",
        "short_codename": "vu_le",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Thanh Tân",
        "code": "xa_thanh_tan",
        "division_type": "xã",
        "short_codename": "thanh_tan",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Thượng Hiền",
        "code": "xa_thuong_hien",
        "division_type": "xã",
        "short_codename": "thuong_hien",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Nam Cao",
        "code": "xa_nam_cao",
        "division_type": "xã",
        "short_codename": "nam_cao",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Đình Phùng",
        "code": "xa_dinh_phung",
        "division_type": "xã",
        "short_codename": "dinh_phung",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Vũ Ninh",
        "code": "xa_vu_ninh",
        "division_type": "xã",
        "short_codename": "vu_ninh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Vũ An",
        "code": "xa_vu_an",
        "division_type": "xã",
        "short_codename": "vu_an",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Quang Lịch",
        "code": "xa_quang_lich",
        "division_type": "xã",
        "short_codename": "quang_lich",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Vũ Quí",
        "code": "xa_vu_qui",
        "division_type": "xã",
        "short_codename": "vu_qui",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Quang Bình",
        "code": "xa_quang_binh",
        "division_type": "xã",
        "short_codename": "quang_binh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Vũ Trung",
        "code": "xa_vu_trung",
        "division_type": "xã",
        "short_codename": "vu_trung",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Vũ Thắng",
        "code": "xa_vu_thang",
        "division_type": "xã",
        "short_codename": "vu_thang",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Vũ Công",
        "code": "xa_vu_cong",
        "division_type": "xã",
        "short_codename": "vu_cong",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Vũ Hòa",
        "code": "xa_vu_hoa",
        "division_type": "xã",
        "short_codename": "vu_hoa",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Quang Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Minh Quang",
        "code": "xa_minh_quang",
        "division_type": "xã",
        "short_codename": "minh_quang",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Vũ Bình",
        "code": "xa_vu_binh",
        "division_type": "xã",
        "short_codename": "vu_binh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Nam Bình",
        "code": "xa_nam_binh",
        "division_type": "xã",
        "short_codename": "nam_binh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Bình Thanh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Bình Định",
        "code": "xa_binh_dinh",
        "division_type": "xã",
        "short_codename": "binh_dinh",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Xã Hồng Tiến",
        "code": "xa_hong_tien",
        "division_type": "xã",
        "short_codename": "hong_tien",
        "parentCode": "huyen_kien_xuong"
    },
    {
        "name": "Thị trấn Vũ Thư",
        "code": "thi_tran_vu_thu",
        "division_type": "thị trấn",
        "short_codename": "vu_thu",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Hồng Lý",
        "code": "xa_hong_ly",
        "division_type": "xã",
        "short_codename": "hong_ly",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Đồng Thanh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Xuân Hòa",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Hiệp Hòa",
        "code": "xa_hiep_hoa",
        "division_type": "xã",
        "short_codename": "hiep_hoa",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Phúc Thành",
        "code": "xa_phuc_thanh",
        "division_type": "xã",
        "short_codename": "phuc_thanh",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Tân Phong",
        "code": "xa_tan_phong",
        "division_type": "xã",
        "short_codename": "tan_phong",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Song Lãng",
        "code": "xa_song_lang",
        "division_type": "xã",
        "short_codename": "song_lang",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Việt Hùng",
        "code": "xa_viet_hung",
        "division_type": "xã",
        "short_codename": "viet_hung",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Minh Lãng",
        "code": "xa_minh_lang",
        "division_type": "xã",
        "short_codename": "minh_lang",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Minh Khai",
        "code": "xa_minh_khai",
        "division_type": "xã",
        "short_codename": "minh_khai",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Dũng Nghĩa",
        "code": "xa_dung_nghia",
        "division_type": "xã",
        "short_codename": "dung_nghia",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Minh Quang",
        "code": "xa_minh_quang",
        "division_type": "xã",
        "short_codename": "minh_quang",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Tam Quang",
        "code": "xa_tam_quang",
        "division_type": "xã",
        "short_codename": "tam_quang",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Bách Thuận",
        "code": "xa_bach_thuan",
        "division_type": "xã",
        "short_codename": "bach_thuan",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Tự Tân",
        "code": "xa_tu_tan",
        "division_type": "xã",
        "short_codename": "tu_tan",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Song An",
        "code": "xa_song_an",
        "division_type": "xã",
        "short_codename": "song_an",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Trung An",
        "code": "xa_trung_an",
        "division_type": "xã",
        "short_codename": "trung_an",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Vũ Hội",
        "code": "xa_vu_hoi",
        "division_type": "xã",
        "short_codename": "vu_hoi",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Nguyên Xá",
        "code": "xa_nguyen_xa",
        "division_type": "xã",
        "short_codename": "nguyen_xa",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Việt Thuận",
        "code": "xa_viet_thuan",
        "division_type": "xã",
        "short_codename": "viet_thuan",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Vũ Vinh",
        "code": "xa_vu_vinh",
        "division_type": "xã",
        "short_codename": "vu_vinh",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Vũ Đoài",
        "code": "xa_vu_doai",
        "division_type": "xã",
        "short_codename": "vu_doai",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Vũ Tiến",
        "code": "xa_vu_tien",
        "division_type": "xã",
        "short_codename": "vu_tien",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Vũ Vân",
        "code": "xa_vu_van",
        "division_type": "xã",
        "short_codename": "vu_van",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Duy Nhất",
        "code": "xa_duy_nhat",
        "division_type": "xã",
        "short_codename": "duy_nhat",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Xã Hồng Phong",
        "code": "xa_hong_phong",
        "division_type": "xã",
        "short_codename": "hong_phong",
        "parentCode": "huyen_vu_thu"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Lương Khánh Thiện",
        "code": "phuong_luong_khanh_thien",
        "division_type": "phường",
        "short_codename": "luong_khanh_thien",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Lê Hồng Phong",
        "code": "phuong_le_hong_phong",
        "division_type": "phường",
        "short_codename": "le_hong_phong",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Minh Khai",
        "code": "phuong_minh_khai",
        "division_type": "phường",
        "short_codename": "minh_khai",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Hai Bà Trưng",
        "code": "phuong_hai_ba_trung",
        "division_type": "phường",
        "short_codename": "hai_ba_trung",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Trần Hưng Đạo",
        "code": "phuong_tran_hung_dao",
        "division_type": "phường",
        "short_codename": "tran_hung_dao",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Lam Hạ",
        "code": "phuong_lam_ha",
        "division_type": "phường",
        "short_codename": "lam_ha",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Phù Vân",
        "code": "xa_phu_van",
        "division_type": "xã",
        "short_codename": "phu_van",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Liêm Chính",
        "code": "phuong_liem_chinh",
        "division_type": "phường",
        "short_codename": "liem_chinh",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Liêm Chung",
        "code": "xa_liem_chung",
        "division_type": "xã",
        "short_codename": "liem_chung",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Thanh Châu",
        "code": "phuong_thanh_chau",
        "division_type": "phường",
        "short_codename": "thanh_chau",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Châu Sơn",
        "code": "phuong_chau_son",
        "division_type": "phường",
        "short_codename": "chau_son",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Tiên Tân",
        "code": "xa_tien_tan",
        "division_type": "xã",
        "short_codename": "tien_tan",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Tiên Hiệp",
        "code": "xa_tien_hiep",
        "division_type": "xã",
        "short_codename": "tien_hiep",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Tiên Hải",
        "code": "xa_tien_hai",
        "division_type": "xã",
        "short_codename": "tien_hai",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Kim Bình",
        "code": "xa_kim_binh",
        "division_type": "xã",
        "short_codename": "kim_binh",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Liêm Tuyền",
        "code": "xa_liem_tuyen",
        "division_type": "xã",
        "short_codename": "liem_tuyen",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Liêm Tiết",
        "code": "xa_liem_tiet",
        "division_type": "xã",
        "short_codename": "liem_tiet",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Thanh Tuyền",
        "code": "phuong_thanh_tuyen",
        "division_type": "phường",
        "short_codename": "thanh_tuyen",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Đinh Xá",
        "code": "xa_dinh_xa",
        "division_type": "xã",
        "short_codename": "dinh_xa",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Xã Trịnh Xá",
        "code": "xa_trinh_xa",
        "division_type": "xã",
        "short_codename": "trinh_xa",
        "parentCode": "thanh_pho_phu_ly"
    },
    {
        "name": "Phường Đồng Văn",
        "code": "phuong_dong_van",
        "division_type": "phường",
        "short_codename": "dong_van",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Phường Hòa Mạc",
        "code": "phuong_hoa_mac",
        "division_type": "phường",
        "short_codename": "hoa_mac",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Xã Mộc Bắc",
        "code": "xa_moc_bac",
        "division_type": "xã",
        "short_codename": "moc_bac",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Phường Châu Giang",
        "code": "phuong_chau_giang",
        "division_type": "phường",
        "short_codename": "chau_giang",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Phường Bạch Thượng",
        "code": "phuong_bach_thuong",
        "division_type": "phường",
        "short_codename": "bach_thuong",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Phường Duy Minh",
        "code": "phuong_duy_minh",
        "division_type": "phường",
        "short_codename": "duy_minh",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Xã Mộc Nam",
        "code": "xa_moc_nam",
        "division_type": "xã",
        "short_codename": "moc_nam",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Phường Duy Hải",
        "code": "phuong_duy_hai",
        "division_type": "phường",
        "short_codename": "duy_hai",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Xã Chuyên Ngoại",
        "code": "xa_chuyen_ngoai",
        "division_type": "xã",
        "short_codename": "chuyen_ngoai",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Phường Yên Bắc",
        "code": "phuong_yen_bac",
        "division_type": "phường",
        "short_codename": "yen_bac",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Xã Trác Văn",
        "code": "xa_trac_van",
        "division_type": "xã",
        "short_codename": "trac_van",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Phường Tiên Nội",
        "code": "phuong_tien_noi",
        "division_type": "phường",
        "short_codename": "tien_noi",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Phường Hoàng Đông",
        "code": "phuong_hoang_dong",
        "division_type": "phường",
        "short_codename": "hoang_dong",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Xã Yên Nam",
        "code": "xa_yen_nam",
        "division_type": "xã",
        "short_codename": "yen_nam",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Xã Tiên Ngoại",
        "code": "xa_tien_ngoai",
        "division_type": "xã",
        "short_codename": "tien_ngoai",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Xã Tiên Sơn",
        "code": "xa_tien_son",
        "division_type": "xã",
        "short_codename": "tien_son",
        "parentCode": "thi_xa_duy_tien"
    },
    {
        "name": "Thị trấn Quế",
        "code": "thi_tran_que",
        "division_type": "thị trấn",
        "short_codename": "que",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Nguyễn Úy",
        "code": "xa_nguyen_uy",
        "division_type": "xã",
        "short_codename": "nguyen_uy",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Đại Cương",
        "code": "xa_dai_cuong",
        "division_type": "xã",
        "short_codename": "dai_cuong",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Lê Hồ",
        "code": "xa_le_ho",
        "division_type": "xã",
        "short_codename": "le_ho",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Tượng Lĩnh",
        "code": "xa_tuong_linh",
        "division_type": "xã",
        "short_codename": "tuong_linh",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Nhật Tựu",
        "code": "xa_nhat_tuu",
        "division_type": "xã",
        "short_codename": "nhat_tuu",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Nhật Tân",
        "code": "xa_nhat_tan",
        "division_type": "xã",
        "short_codename": "nhat_tan",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Đồng Hóa",
        "code": "xa_dong_hoa",
        "division_type": "xã",
        "short_codename": "dong_hoa",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Hoàng Tây",
        "code": "xa_hoang_tay",
        "division_type": "xã",
        "short_codename": "hoang_tay",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Tân Sơn",
        "code": "xa_tan_son",
        "division_type": "xã",
        "short_codename": "tan_son",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Thụy Lôi",
        "code": "xa_thuy_loi",
        "division_type": "xã",
        "short_codename": "thuy_loi",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Văn Xá",
        "code": "xa_van_xa",
        "division_type": "xã",
        "short_codename": "van_xa",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Khả Phong",
        "code": "xa_kha_phong",
        "division_type": "xã",
        "short_codename": "kha_phong",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Ngọc Sơn",
        "code": "xa_ngoc_son",
        "division_type": "xã",
        "short_codename": "ngoc_son",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Thị trấn Ba Sao",
        "code": "thi_tran_ba_sao",
        "division_type": "thị trấn",
        "short_codename": "ba_sao",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Liên Sơn",
        "code": "xa_lien_son",
        "division_type": "xã",
        "short_codename": "lien_son",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Thi Sơn",
        "code": "xa_thi_son",
        "division_type": "xã",
        "short_codename": "thi_son",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_kim_bang"
    },
    {
        "name": "Thị trấn Kiện Khê",
        "code": "thi_tran_kien_khe",
        "division_type": "thị trấn",
        "short_codename": "kien_khe",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Liêm Phong",
        "code": "xa_liem_phong",
        "division_type": "xã",
        "short_codename": "liem_phong",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Thanh Hà",
        "code": "xa_thanh_ha",
        "division_type": "xã",
        "short_codename": "thanh_ha",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Liêm Cần",
        "code": "xa_liem_can",
        "division_type": "xã",
        "short_codename": "liem_can",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Liêm Thuận",
        "code": "xa_liem_thuan",
        "division_type": "xã",
        "short_codename": "liem_thuan",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Thanh Thủy",
        "code": "xa_thanh_thuy",
        "division_type": "xã",
        "short_codename": "thanh_thuy",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Thanh Phong",
        "code": "xa_thanh_phong",
        "division_type": "xã",
        "short_codename": "thanh_phong",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Thị trấn Tân Thanh",
        "code": "thi_tran_tan_thanh",
        "division_type": "thị trấn",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Thanh Tân",
        "code": "xa_thanh_tan",
        "division_type": "xã",
        "short_codename": "thanh_tan",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Liêm Túc",
        "code": "xa_liem_tuc",
        "division_type": "xã",
        "short_codename": "liem_tuc",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Liêm Sơn",
        "code": "xa_liem_son",
        "division_type": "xã",
        "short_codename": "liem_son",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Thanh Hương",
        "code": "xa_thanh_huong",
        "division_type": "xã",
        "short_codename": "thanh_huong",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Thanh Nghị",
        "code": "xa_thanh_nghi",
        "division_type": "xã",
        "short_codename": "thanh_nghi",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Thanh Tâm",
        "code": "xa_thanh_tam",
        "division_type": "xã",
        "short_codename": "thanh_tam",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Thanh Nguyên",
        "code": "xa_thanh_nguyen",
        "division_type": "xã",
        "short_codename": "thanh_nguyen",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Xã Thanh Hải",
        "code": "xa_thanh_hai",
        "division_type": "xã",
        "short_codename": "thanh_hai",
        "parentCode": "huyen_thanh_liem"
    },
    {
        "name": "Thị trấn Bình Mỹ",
        "code": "thi_tran_binh_my",
        "division_type": "thị trấn",
        "short_codename": "binh_my",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Bình Nghĩa",
        "code": "xa_binh_nghia",
        "division_type": "xã",
        "short_codename": "binh_nghia",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Tràng An",
        "code": "xa_trang_an",
        "division_type": "xã",
        "short_codename": "trang_an",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Đồng Du",
        "code": "xa_dong_du",
        "division_type": "xã",
        "short_codename": "dong_du",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Ngọc Lũ",
        "code": "xa_ngoc_lu",
        "division_type": "xã",
        "short_codename": "ngoc_lu",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Hưng Công",
        "code": "xa_hung_cong",
        "division_type": "xã",
        "short_codename": "hung_cong",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Đồn Xá",
        "code": "xa_don_xa",
        "division_type": "xã",
        "short_codename": "don_xa",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã An Ninh",
        "code": "xa_an_ninh",
        "division_type": "xã",
        "short_codename": "an_ninh",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Bồ Đề",
        "code": "xa_bo_de",
        "division_type": "xã",
        "short_codename": "bo_de",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Bối Cầu",
        "code": "xa_boi_cau",
        "division_type": "xã",
        "short_codename": "boi_cau",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã An Nội",
        "code": "xa_an_noi",
        "division_type": "xã",
        "short_codename": "an_noi",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Vũ Bản",
        "code": "xa_vu_ban",
        "division_type": "xã",
        "short_codename": "vu_ban",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Trung Lương",
        "code": "xa_trung_luong",
        "division_type": "xã",
        "short_codename": "trung_luong",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã An Đổ",
        "code": "xa_an_do",
        "division_type": "xã",
        "short_codename": "an_do",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã La Sơn",
        "code": "xa_la_son",
        "division_type": "xã",
        "short_codename": "la_son",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Tiêu Động",
        "code": "xa_tieu_dong",
        "division_type": "xã",
        "short_codename": "tieu_dong",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã An Lão",
        "code": "xa_an_lao",
        "division_type": "xã",
        "short_codename": "an_lao",
        "parentCode": "huyen_binh_luc"
    },
    {
        "name": "Xã Hợp Lý",
        "code": "xa_hop_ly",
        "division_type": "xã",
        "short_codename": "hop_ly",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Nguyên Lý",
        "code": "xa_nguyen_ly",
        "division_type": "xã",
        "short_codename": "nguyen_ly",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Chính Lý",
        "code": "xa_chinh_ly",
        "division_type": "xã",
        "short_codename": "chinh_ly",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Chân Lý",
        "code": "xa_chan_ly",
        "division_type": "xã",
        "short_codename": "chan_ly",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Đạo Lý",
        "code": "xa_dao_ly",
        "division_type": "xã",
        "short_codename": "dao_ly",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Công Lý",
        "code": "xa_cong_ly",
        "division_type": "xã",
        "short_codename": "cong_ly",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Văn Lý",
        "code": "xa_van_ly",
        "division_type": "xã",
        "short_codename": "van_ly",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Bắc Lý",
        "code": "xa_bac_ly",
        "division_type": "xã",
        "short_codename": "bac_ly",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Đức Lý",
        "code": "xa_duc_ly",
        "division_type": "xã",
        "short_codename": "duc_ly",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Trần Hưng Đạo",
        "code": "xa_tran_hung_dao",
        "division_type": "xã",
        "short_codename": "tran_hung_dao",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Thị trấn Vĩnh Trụ",
        "code": "thi_tran_vinh_tru",
        "division_type": "thị trấn",
        "short_codename": "vinh_tru",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Nhân Thịnh",
        "code": "xa_nhan_thinh",
        "division_type": "xã",
        "short_codename": "nhan_thinh",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Nhân Khang",
        "code": "xa_nhan_khang",
        "division_type": "xã",
        "short_codename": "nhan_khang",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Nhân Mỹ",
        "code": "xa_nhan_my",
        "division_type": "xã",
        "short_codename": "nhan_my",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Nhân Nghĩa",
        "code": "xa_nhan_nghia",
        "division_type": "xã",
        "short_codename": "nhan_nghia",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Nhân Chính",
        "code": "xa_nhan_chinh",
        "division_type": "xã",
        "short_codename": "nhan_chinh",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Nhân Bình",
        "code": "xa_nhan_binh",
        "division_type": "xã",
        "short_codename": "nhan_binh",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Phú Phúc",
        "code": "xa_phu_phuc",
        "division_type": "xã",
        "short_codename": "phu_phuc",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Xuân Khê",
        "code": "xa_xuan_khe",
        "division_type": "xã",
        "short_codename": "xuan_khe",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Tiến Thắng",
        "code": "xa_tien_thang",
        "division_type": "xã",
        "short_codename": "tien_thang",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Xã Hòa Hậu",
        "code": "xa_hoa_hau",
        "division_type": "xã",
        "short_codename": "hoa_hau",
        "parentCode": "huyen_ly_nhan"
    },
    {
        "name": "Phường Hạ Long",
        "code": "phuong_ha_long",
        "division_type": "phường",
        "short_codename": "ha_long",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Trần Tế Xương",
        "code": "phuong_tran_te_xuong",
        "division_type": "phường",
        "short_codename": "tran_te_xuong",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Vị Hoàng",
        "code": "phuong_vi_hoang",
        "division_type": "phường",
        "short_codename": "vi_hoang",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Vị Xuyên",
        "code": "phuong_vi_xuyen",
        "division_type": "phường",
        "short_codename": "vi_xuyen",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Cửa Bắc",
        "code": "phuong_cua_bac",
        "division_type": "phường",
        "short_codename": "cua_bac",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Nguyễn Du",
        "code": "phuong_nguyen_du",
        "division_type": "phường",
        "short_codename": "nguyen_du",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Bà Triệu",
        "code": "phuong_ba_trieu",
        "division_type": "phường",
        "short_codename": "ba_trieu",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Trường Thi",
        "code": "phuong_truong_thi",
        "division_type": "phường",
        "short_codename": "truong_thi",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Phan Đình Phùng",
        "code": "phuong_phan_dinh_phung",
        "division_type": "phường",
        "short_codename": "phan_dinh_phung",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Ngô Quyền",
        "code": "phuong_ngo_quyen",
        "division_type": "phường",
        "short_codename": "ngo_quyen",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Trần Hưng Đạo",
        "code": "phuong_tran_hung_dao",
        "division_type": "phường",
        "short_codename": "tran_hung_dao",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Trần Đăng Ninh",
        "code": "phuong_tran_dang_ninh",
        "division_type": "phường",
        "short_codename": "tran_dang_ninh",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Năng Tĩnh",
        "code": "phuong_nang_tinh",
        "division_type": "phường",
        "short_codename": "nang_tinh",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Văn Miếu",
        "code": "phuong_van_mieu",
        "division_type": "phường",
        "short_codename": "van_mieu",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Trần Quang Khải",
        "code": "phuong_tran_quang_khai",
        "division_type": "phường",
        "short_codename": "tran_quang_khai",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Thống Nhất",
        "code": "phuong_thong_nhat",
        "division_type": "phường",
        "short_codename": "thong_nhat",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Lộc Hạ",
        "code": "phuong_loc_ha",
        "division_type": "phường",
        "short_codename": "loc_ha",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Lộc Vượng",
        "code": "phuong_loc_vuong",
        "division_type": "phường",
        "short_codename": "loc_vuong",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Cửa Nam",
        "code": "phuong_cua_nam",
        "division_type": "phường",
        "short_codename": "cua_nam",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Lộc Hòa",
        "code": "phuong_loc_hoa",
        "division_type": "phường",
        "short_codename": "loc_hoa",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Xã Nam Phong",
        "code": "xa_nam_phong",
        "division_type": "xã",
        "short_codename": "nam_phong",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Phường Mỹ Xá",
        "code": "phuong_my_xa",
        "division_type": "phường",
        "short_codename": "my_xa",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Xã Lộc An",
        "code": "xa_loc_an",
        "division_type": "xã",
        "short_codename": "loc_an",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Xã Nam Vân",
        "code": "xa_nam_van",
        "division_type": "xã",
        "short_codename": "nam_van",
        "parentCode": "thanh_pho_nam_dinh"
    },
    {
        "name": "Thị trấn Mỹ Lộc",
        "code": "thi_tran_my_loc",
        "division_type": "thị trấn",
        "short_codename": "my_loc",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Hà",
        "code": "xa_my_ha",
        "division_type": "xã",
        "short_codename": "my_ha",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Tiến",
        "code": "xa_my_tien",
        "division_type": "xã",
        "short_codename": "my_tien",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Thắng",
        "code": "xa_my_thang",
        "division_type": "xã",
        "short_codename": "my_thang",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Trung",
        "code": "xa_my_trung",
        "division_type": "xã",
        "short_codename": "my_trung",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Tân",
        "code": "xa_my_tan",
        "division_type": "xã",
        "short_codename": "my_tan",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Phúc",
        "code": "xa_my_phuc",
        "division_type": "xã",
        "short_codename": "my_phuc",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Hưng",
        "code": "xa_my_hung",
        "division_type": "xã",
        "short_codename": "my_hung",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Thuận",
        "code": "xa_my_thuan",
        "division_type": "xã",
        "short_codename": "my_thuan",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Thịnh",
        "code": "xa_my_thinh",
        "division_type": "xã",
        "short_codename": "my_thinh",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Xã Mỹ Thành",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_my_loc"
    },
    {
        "name": "Thị trấn Gôi",
        "code": "thi_tran_goi",
        "division_type": "thị trấn",
        "short_codename": "goi",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Minh Thuận",
        "code": "xa_minh_thuan",
        "division_type": "xã",
        "short_codename": "minh_thuan",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Hiển Khánh",
        "code": "xa_hien_khanh",
        "division_type": "xã",
        "short_codename": "hien_khanh",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Tân Khánh",
        "code": "xa_tan_khanh",
        "division_type": "xã",
        "short_codename": "tan_khanh",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Hợp Hưng",
        "code": "xa_hop_hung",
        "division_type": "xã",
        "short_codename": "hop_hung",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Đại An",
        "code": "xa_dai_an",
        "division_type": "xã",
        "short_codename": "dai_an",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Cộng Hòa",
        "code": "xa_cong_hoa",
        "division_type": "xã",
        "short_codename": "cong_hoa",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Trung Thành",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Liên Bảo",
        "code": "xa_lien_bao",
        "division_type": "xã",
        "short_codename": "lien_bao",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Thành Lợi",
        "code": "xa_thanh_loi",
        "division_type": "xã",
        "short_codename": "thanh_loi",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Kim Thái",
        "code": "xa_kim_thai",
        "division_type": "xã",
        "short_codename": "kim_thai",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Liên Minh",
        "code": "xa_lien_minh",
        "division_type": "xã",
        "short_codename": "lien_minh",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Đại Thắng",
        "code": "xa_dai_thang",
        "division_type": "xã",
        "short_codename": "dai_thang",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Tam Thanh",
        "code": "xa_tam_thanh",
        "division_type": "xã",
        "short_codename": "tam_thanh",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Xã Vĩnh Hào",
        "code": "xa_vinh_hao",
        "division_type": "xã",
        "short_codename": "vinh_hao",
        "parentCode": "huyen_vu_ban"
    },
    {
        "name": "Thị trấn Lâm",
        "code": "thi_tran_lam",
        "division_type": "thị trấn",
        "short_codename": "lam",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Trung",
        "code": "xa_yen_trung",
        "division_type": "xã",
        "short_codename": "yen_trung",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Thành",
        "code": "xa_yen_thanh",
        "division_type": "xã",
        "short_codename": "yen_thanh",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Tân",
        "code": "xa_yen_tan",
        "division_type": "xã",
        "short_codename": "yen_tan",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Lợi",
        "code": "xa_yen_loi",
        "division_type": "xã",
        "short_codename": "yen_loi",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Thọ",
        "code": "xa_yen_tho",
        "division_type": "xã",
        "short_codename": "yen_tho",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Nghĩa",
        "code": "xa_yen_nghia",
        "division_type": "xã",
        "short_codename": "yen_nghia",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Minh",
        "code": "xa_yen_minh",
        "division_type": "xã",
        "short_codename": "yen_minh",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Phương",
        "code": "xa_yen_phuong",
        "division_type": "xã",
        "short_codename": "yen_phuong",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Chính",
        "code": "xa_yen_chinh",
        "division_type": "xã",
        "short_codename": "yen_chinh",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Bình",
        "code": "xa_yen_binh",
        "division_type": "xã",
        "short_codename": "yen_binh",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Phú",
        "code": "xa_yen_phu",
        "division_type": "xã",
        "short_codename": "yen_phu",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Mỹ",
        "code": "xa_yen_my",
        "division_type": "xã",
        "short_codename": "yen_my",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Dương",
        "code": "xa_yen_duong",
        "division_type": "xã",
        "short_codename": "yen_duong",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Hưng",
        "code": "xa_yen_hung",
        "division_type": "xã",
        "short_codename": "yen_hung",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Khánh",
        "code": "xa_yen_khanh",
        "division_type": "xã",
        "short_codename": "yen_khanh",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Phong",
        "code": "xa_yen_phong",
        "division_type": "xã",
        "short_codename": "yen_phong",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Ninh",
        "code": "xa_yen_ninh",
        "division_type": "xã",
        "short_codename": "yen_ninh",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Lương",
        "code": "xa_yen_luong",
        "division_type": "xã",
        "short_codename": "yen_luong",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Hồng",
        "code": "xa_yen_hong",
        "division_type": "xã",
        "short_codename": "yen_hong",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Quang",
        "code": "xa_yen_quang",
        "division_type": "xã",
        "short_codename": "yen_quang",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Tiến",
        "code": "xa_yen_tien",
        "division_type": "xã",
        "short_codename": "yen_tien",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Thắng",
        "code": "xa_yen_thang",
        "division_type": "xã",
        "short_codename": "yen_thang",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Phúc",
        "code": "xa_yen_phuc",
        "division_type": "xã",
        "short_codename": "yen_phuc",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Cường",
        "code": "xa_yen_cuong",
        "division_type": "xã",
        "short_codename": "yen_cuong",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Lộc",
        "code": "xa_yen_loc",
        "division_type": "xã",
        "short_codename": "yen_loc",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Bằng",
        "code": "xa_yen_bang",
        "division_type": "xã",
        "short_codename": "yen_bang",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Đồng",
        "code": "xa_yen_dong",
        "division_type": "xã",
        "short_codename": "yen_dong",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Khang",
        "code": "xa_yen_khang",
        "division_type": "xã",
        "short_codename": "yen_khang",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Nhân",
        "code": "xa_yen_nhan",
        "division_type": "xã",
        "short_codename": "yen_nhan",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Xã Yên Trị",
        "code": "xa_yen_tri",
        "division_type": "xã",
        "short_codename": "yen_tri",
        "parentCode": "huyen_y_yen"
    },
    {
        "name": "Thị trấn Liễu Đề",
        "code": "thi_tran_lieu_de",
        "division_type": "thị trấn",
        "short_codename": "lieu_de",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Thị trấn Rạng Đông",
        "code": "thi_tran_rang_dong",
        "division_type": "thị trấn",
        "short_codename": "rang_dong",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Đồng",
        "code": "xa_nghia_dong",
        "division_type": "xã",
        "short_codename": "nghia_dong",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Thịnh",
        "code": "xa_nghia_thinh",
        "division_type": "xã",
        "short_codename": "nghia_thinh",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Minh",
        "code": "xa_nghia_minh",
        "division_type": "xã",
        "short_codename": "nghia_minh",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Thái",
        "code": "xa_nghia_thai",
        "division_type": "xã",
        "short_codename": "nghia_thai",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Hoàng Nam",
        "code": "xa_hoang_nam",
        "division_type": "xã",
        "short_codename": "hoang_nam",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Châu",
        "code": "xa_nghia_chau",
        "division_type": "xã",
        "short_codename": "nghia_chau",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Trung",
        "code": "xa_nghia_trung",
        "division_type": "xã",
        "short_codename": "nghia_trung",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Sơn",
        "code": "xa_nghia_son",
        "division_type": "xã",
        "short_codename": "nghia_son",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Lạc",
        "code": "xa_nghia_lac",
        "division_type": "xã",
        "short_codename": "nghia_lac",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Hồng",
        "code": "xa_nghia_hong",
        "division_type": "xã",
        "short_codename": "nghia_hong",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Phong",
        "code": "xa_nghia_phong",
        "division_type": "xã",
        "short_codename": "nghia_phong",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Phú",
        "code": "xa_nghia_phu",
        "division_type": "xã",
        "short_codename": "nghia_phu",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Bình",
        "code": "xa_nghia_binh",
        "division_type": "xã",
        "short_codename": "nghia_binh",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Thị trấn Quỹ Nhất",
        "code": "thi_tran_quy_nhat",
        "division_type": "thị trấn",
        "short_codename": "quy_nhat",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Tân",
        "code": "xa_nghia_tan",
        "division_type": "xã",
        "short_codename": "nghia_tan",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Hùng",
        "code": "xa_nghia_hung",
        "division_type": "xã",
        "short_codename": "nghia_hung",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Lâm",
        "code": "xa_nghia_lam",
        "division_type": "xã",
        "short_codename": "nghia_lam",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Thành",
        "code": "xa_nghia_thanh",
        "division_type": "xã",
        "short_codename": "nghia_thanh",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Phúc Thắng",
        "code": "xa_phuc_thang",
        "division_type": "xã",
        "short_codename": "phuc_thang",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Lợi",
        "code": "xa_nghia_loi",
        "division_type": "xã",
        "short_codename": "nghia_loi",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nghĩa Hải",
        "code": "xa_nghia_hai",
        "division_type": "xã",
        "short_codename": "nghia_hai",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Xã Nam Điền",
        "code": "xa_nam_dien",
        "division_type": "xã",
        "short_codename": "nam_dien",
        "parentCode": "huyen_nghia_hung"
    },
    {
        "name": "Thị trấn Nam Giang",
        "code": "thi_tran_nam_giang",
        "division_type": "thị trấn",
        "short_codename": "nam_giang",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Mỹ",
        "code": "xa_nam_my",
        "division_type": "xã",
        "short_codename": "nam_my",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Điền Xá",
        "code": "xa_dien_xa",
        "division_type": "xã",
        "short_codename": "dien_xa",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nghĩa An",
        "code": "xa_nghia_an",
        "division_type": "xã",
        "short_codename": "nghia_an",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Thắng",
        "code": "xa_nam_thang",
        "division_type": "xã",
        "short_codename": "nam_thang",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Toàn",
        "code": "xa_nam_toan",
        "division_type": "xã",
        "short_codename": "nam_toan",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Hồng Quang",
        "code": "xa_hong_quang",
        "division_type": "xã",
        "short_codename": "hong_quang",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Tân Thịnh",
        "code": "xa_tan_thinh",
        "division_type": "xã",
        "short_codename": "tan_thinh",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Cường",
        "code": "xa_nam_cuong",
        "division_type": "xã",
        "short_codename": "nam_cuong",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Hồng",
        "code": "xa_nam_hong",
        "division_type": "xã",
        "short_codename": "nam_hong",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Hùng",
        "code": "xa_nam_hung",
        "division_type": "xã",
        "short_codename": "nam_hung",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Hoa",
        "code": "xa_nam_hoa",
        "division_type": "xã",
        "short_codename": "nam_hoa",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Dương",
        "code": "xa_nam_duong",
        "division_type": "xã",
        "short_codename": "nam_duong",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Thanh",
        "code": "xa_nam_thanh",
        "division_type": "xã",
        "short_codename": "nam_thanh",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Lợi",
        "code": "xa_nam_loi",
        "division_type": "xã",
        "short_codename": "nam_loi",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Đồng Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Tiến",
        "code": "xa_nam_tien",
        "division_type": "xã",
        "short_codename": "nam_tien",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Hải",
        "code": "xa_nam_hai",
        "division_type": "xã",
        "short_codename": "nam_hai",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Xã Nam Thái",
        "code": "xa_nam_thai",
        "division_type": "xã",
        "short_codename": "nam_thai",
        "parentCode": "huyen_nam_truc"
    },
    {
        "name": "Thị trấn Cổ Lễ",
        "code": "thi_tran_co_le",
        "division_type": "thị trấn",
        "short_codename": "co_le",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Phương Định",
        "code": "xa_phuong_dinh",
        "division_type": "xã",
        "short_codename": "phuong_dinh",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Chính",
        "code": "xa_truc_chinh",
        "division_type": "xã",
        "short_codename": "truc_chinh",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trung Đông",
        "code": "xa_trung_dong",
        "division_type": "xã",
        "short_codename": "trung_dong",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Liêm Hải",
        "code": "xa_liem_hai",
        "division_type": "xã",
        "short_codename": "liem_hai",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Tuấn",
        "code": "xa_truc_tuan",
        "division_type": "xã",
        "short_codename": "truc_tuan",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Việt Hùng",
        "code": "xa_viet_hung",
        "division_type": "xã",
        "short_codename": "viet_hung",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Đạo",
        "code": "xa_truc_dao",
        "division_type": "xã",
        "short_codename": "truc_dao",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Hưng",
        "code": "xa_truc_hung",
        "division_type": "xã",
        "short_codename": "xa_truc_hung",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Nội",
        "code": "xa_truc_noi",
        "division_type": "xã",
        "short_codename": "truc_noi",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Thị trấn Cát Thành",
        "code": "thi_tran_cat_thanh",
        "division_type": "thị trấn",
        "short_codename": "cat_thanh",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Thanh",
        "code": "xa_truc_thanh",
        "division_type": "xã",
        "short_codename": "truc_thanh",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Khang",
        "code": "xa_truc_khang",
        "division_type": "xã",
        "short_codename": "truc_khang",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Thuận",
        "code": "xa_truc_thuan",
        "division_type": "xã",
        "short_codename": "truc_thuan",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Mỹ",
        "code": "xa_truc_my",
        "division_type": "xã",
        "short_codename": "truc_my",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Đại",
        "code": "xa_truc_dai",
        "division_type": "xã",
        "short_codename": "truc_dai",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Cường",
        "code": "xa_truc_cuong",
        "division_type": "xã",
        "short_codename": "truc_cuong",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Thị trấn Ninh Cường",
        "code": "thi_tran_ninh_cuong",
        "division_type": "thị trấn",
        "short_codename": "ninh_cuong",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Thái",
        "code": "xa_truc_thai",
        "division_type": "xã",
        "short_codename": "truc_thai",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Hùng",
        "code": "xa_truc_hung",
        "division_type": "xã",
        "short_codename": "xa_truc_hung",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Xã Trực Thắng",
        "code": "xa_truc_thang",
        "division_type": "xã",
        "short_codename": "truc_thang",
        "parentCode": "huyen_truc_ninh"
    },
    {
        "name": "Thị trấn Xuân Trường",
        "code": "thi_tran_xuan_truong",
        "division_type": "thị trấn",
        "short_codename": "xuan_truong",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Châu",
        "code": "xa_xuan_chau",
        "division_type": "xã",
        "short_codename": "xuan_chau",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Hồng",
        "code": "xa_xuan_hong",
        "division_type": "xã",
        "short_codename": "xuan_hong",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Thành",
        "code": "xa_xuan_thanh",
        "division_type": "xã",
        "short_codename": "xuan_thanh",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Thượng",
        "code": "xa_xuan_thuong",
        "division_type": "xã",
        "short_codename": "xuan_thuong",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Phong",
        "code": "xa_xuan_phong",
        "division_type": "xã",
        "short_codename": "xuan_phong",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Đài",
        "code": "xa_xuan_dai",
        "division_type": "xã",
        "short_codename": "xuan_dai",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Tân",
        "code": "xa_xuan_tan",
        "division_type": "xã",
        "short_codename": "xuan_tan",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Thủy",
        "code": "xa_xuan_thuy",
        "division_type": "xã",
        "short_codename": "xuan_thuy",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Ngọc",
        "code": "xa_xuan_ngoc",
        "division_type": "xã",
        "short_codename": "xuan_ngoc",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Bắc",
        "code": "xa_xuan_bac",
        "division_type": "xã",
        "short_codename": "xuan_bac",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Phương",
        "code": "xa_xuan_phuong",
        "division_type": "xã",
        "short_codename": "xuan_phuong",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Thọ Nghiệp",
        "code": "xa_tho_nghiep",
        "division_type": "xã",
        "short_codename": "tho_nghiep",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Phú",
        "code": "xa_xuan_phu",
        "division_type": "xã",
        "short_codename": "xuan_phu",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Trung",
        "code": "xa_xuan_trung",
        "division_type": "xã",
        "short_codename": "xuan_trung",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Vinh",
        "code": "xa_xuan_vinh",
        "division_type": "xã",
        "short_codename": "xuan_vinh",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Kiên",
        "code": "xa_xuan_kien",
        "division_type": "xã",
        "short_codename": "xuan_kien",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Tiến",
        "code": "xa_xuan_tien",
        "division_type": "xã",
        "short_codename": "xuan_tien",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Ninh",
        "code": "xa_xuan_ninh",
        "division_type": "xã",
        "short_codename": "xuan_ninh",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Xã Xuân Hòa",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_xuan_truong"
    },
    {
        "name": "Thị trấn Ngô Đồng",
        "code": "thi_tran_ngo_dong",
        "division_type": "thị trấn",
        "short_codename": "ngo_dong",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Thị trấn Quất Lâm",
        "code": "thi_tran_quat_lam",
        "division_type": "thị trấn",
        "short_codename": "quat_lam",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Hương",
        "code": "xa_giao_huong",
        "division_type": "xã",
        "short_codename": "giao_huong",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Hồng Thuận",
        "code": "xa_hong_thuan",
        "division_type": "xã",
        "short_codename": "hong_thuan",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Thiện",
        "code": "xa_giao_thien",
        "division_type": "xã",
        "short_codename": "giao_thien",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Thanh",
        "code": "xa_giao_thanh",
        "division_type": "xã",
        "short_codename": "giao_thanh",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Hoành Sơn",
        "code": "xa_hoanh_son",
        "division_type": "xã",
        "short_codename": "hoanh_son",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Bình Hòa",
        "code": "xa_binh_hoa",
        "division_type": "xã",
        "short_codename": "binh_hoa",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Tiến",
        "code": "xa_giao_tien",
        "division_type": "xã",
        "short_codename": "giao_tien",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Hà",
        "code": "xa_giao_ha",
        "division_type": "xã",
        "short_codename": "giao_ha",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Nhân",
        "code": "xa_giao_nhan",
        "division_type": "xã",
        "short_codename": "giao_nhan",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao An",
        "code": "xa_giao_an",
        "division_type": "xã",
        "short_codename": "giao_an",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Lạc",
        "code": "xa_giao_lac",
        "division_type": "xã",
        "short_codename": "giao_lac",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Châu",
        "code": "xa_giao_chau",
        "division_type": "xã",
        "short_codename": "giao_chau",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Tân",
        "code": "xa_giao_tan",
        "division_type": "xã",
        "short_codename": "giao_tan",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Yến",
        "code": "xa_giao_yen",
        "division_type": "xã",
        "short_codename": "giao_yen",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Xuân",
        "code": "xa_giao_xuan",
        "division_type": "xã",
        "short_codename": "giao_xuan",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Thịnh",
        "code": "xa_giao_thinh",
        "division_type": "xã",
        "short_codename": "giao_thinh",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Hải",
        "code": "xa_giao_hai",
        "division_type": "xã",
        "short_codename": "giao_hai",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Bạch Long",
        "code": "xa_bach_long",
        "division_type": "xã",
        "short_codename": "bach_long",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Long",
        "code": "xa_giao_long",
        "division_type": "xã",
        "short_codename": "giao_long",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Xã Giao Phong",
        "code": "xa_giao_phong",
        "division_type": "xã",
        "short_codename": "giao_phong",
        "parentCode": "huyen_giao_thuy"
    },
    {
        "name": "Thị trấn Yên Định",
        "code": "thi_tran_yen_dinh",
        "division_type": "thị trấn",
        "short_codename": "yen_dinh",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Thị trấn Cồn",
        "code": "thi_tran_con",
        "division_type": "thị trấn",
        "short_codename": "con",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Thị trấn Thịnh Long",
        "code": "thi_tran_thinh_long",
        "division_type": "thị trấn",
        "short_codename": "thinh_long",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Nam",
        "code": "xa_hai_nam",
        "division_type": "xã",
        "short_codename": "hai_nam",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Trung",
        "code": "xa_hai_trung",
        "division_type": "xã",
        "short_codename": "hai_trung",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Vân",
        "code": "xa_hai_van",
        "division_type": "xã",
        "short_codename": "hai_van",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Minh",
        "code": "xa_hai_minh",
        "division_type": "xã",
        "short_codename": "hai_minh",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Anh",
        "code": "xa_hai_anh",
        "division_type": "xã",
        "short_codename": "hai_anh",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Hưng",
        "code": "xa_hai_hung",
        "division_type": "xã",
        "short_codename": "hai_hung",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Bắc",
        "code": "xa_hai_bac",
        "division_type": "xã",
        "short_codename": "hai_bac",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Phúc",
        "code": "xa_hai_phuc",
        "division_type": "xã",
        "short_codename": "hai_phuc",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Thanh",
        "code": "xa_hai_thanh",
        "division_type": "xã",
        "short_codename": "hai_thanh",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Hà",
        "code": "xa_hai_ha",
        "division_type": "xã",
        "short_codename": "hai_ha",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Long",
        "code": "xa_hai_long",
        "division_type": "xã",
        "short_codename": "hai_long",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Phương",
        "code": "xa_hai_phuong",
        "division_type": "xã",
        "short_codename": "hai_phuong",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Đường",
        "code": "xa_hai_duong",
        "division_type": "xã",
        "short_codename": "hai_duong",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Lộc",
        "code": "xa_hai_loc",
        "division_type": "xã",
        "short_codename": "hai_loc",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Quang",
        "code": "xa_hai_quang",
        "division_type": "xã",
        "short_codename": "hai_quang",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Đông",
        "code": "xa_hai_dong",
        "division_type": "xã",
        "short_codename": "hai_dong",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Sơn",
        "code": "xa_hai_son",
        "division_type": "xã",
        "short_codename": "hai_son",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Tân",
        "code": "xa_hai_tan",
        "division_type": "xã",
        "short_codename": "hai_tan",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Phong",
        "code": "xa_hai_phong",
        "division_type": "xã",
        "short_codename": "hai_phong",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải An",
        "code": "xa_hai_an",
        "division_type": "xã",
        "short_codename": "hai_an",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Tây",
        "code": "xa_hai_tay",
        "division_type": "xã",
        "short_codename": "hai_tay",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Lý",
        "code": "xa_hai_ly",
        "division_type": "xã",
        "short_codename": "hai_ly",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Phú",
        "code": "xa_hai_phu",
        "division_type": "xã",
        "short_codename": "hai_phu",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Giang",
        "code": "xa_hai_giang",
        "division_type": "xã",
        "short_codename": "hai_giang",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Cường",
        "code": "xa_hai_cuong",
        "division_type": "xã",
        "short_codename": "hai_cuong",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Ninh",
        "code": "xa_hai_ninh",
        "division_type": "xã",
        "short_codename": "hai_ninh",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Chính",
        "code": "xa_hai_chinh",
        "division_type": "xã",
        "short_codename": "hai_chinh",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Xuân",
        "code": "xa_hai_xuan",
        "division_type": "xã",
        "short_codename": "hai_xuan",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Châu",
        "code": "xa_hai_chau",
        "division_type": "xã",
        "short_codename": "hai_chau",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Triều",
        "code": "xa_hai_trieu",
        "division_type": "xã",
        "short_codename": "hai_trieu",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Xã Hải Hòa",
        "code": "xa_hai_hoa",
        "division_type": "xã",
        "short_codename": "hai_hoa",
        "parentCode": "huyen_hai_hau"
    },
    {
        "name": "Phường Đông Thành",
        "code": "phuong_dong_thanh",
        "division_type": "phường",
        "short_codename": "dong_thanh",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Tân Thành",
        "code": "phuong_tan_thanh",
        "division_type": "phường",
        "short_codename": "tan_thanh",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Thanh Bình",
        "code": "phuong_thanh_binh",
        "division_type": "phường",
        "short_codename": "thanh_binh",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Vân Giang",
        "code": "phuong_van_giang",
        "division_type": "phường",
        "short_codename": "van_giang",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Bích Đào",
        "code": "phuong_bich_dao",
        "division_type": "phường",
        "short_codename": "bich_dao",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Phúc Thành",
        "code": "phuong_phuc_thanh",
        "division_type": "phường",
        "short_codename": "phuc_thanh",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Nam Bình",
        "code": "phuong_nam_binh",
        "division_type": "phường",
        "short_codename": "nam_binh",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Nam Thành",
        "code": "phuong_nam_thanh",
        "division_type": "phường",
        "short_codename": "nam_thanh",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Ninh Khánh",
        "code": "phuong_ninh_khanh",
        "division_type": "phường",
        "short_codename": "ninh_khanh",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Xã Ninh Nhất",
        "code": "xa_ninh_nhat",
        "division_type": "xã",
        "short_codename": "ninh_nhat",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Xã Ninh Tiến",
        "code": "xa_ninh_tien",
        "division_type": "xã",
        "short_codename": "ninh_tien",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Xã Ninh Phúc",
        "code": "xa_ninh_phuc",
        "division_type": "xã",
        "short_codename": "ninh_phuc",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Ninh Sơn",
        "code": "phuong_ninh_son",
        "division_type": "phường",
        "short_codename": "ninh_son",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Ninh Phong",
        "code": "phuong_ninh_phong",
        "division_type": "phường",
        "short_codename": "ninh_phong",
        "parentCode": "thanh_pho_ninh_binh"
    },
    {
        "name": "Phường Bắc Sơn",
        "code": "phuong_bac_son",
        "division_type": "phường",
        "short_codename": "bac_son",
        "parentCode": "thanh_pho_tam_diep"
    },
    {
        "name": "Phường Trung Sơn",
        "code": "phuong_trung_son",
        "division_type": "phường",
        "short_codename": "trung_son",
        "parentCode": "thanh_pho_tam_diep"
    },
    {
        "name": "Phường Nam Sơn",
        "code": "phuong_nam_son",
        "division_type": "phường",
        "short_codename": "nam_son",
        "parentCode": "thanh_pho_tam_diep"
    },
    {
        "name": "Phường Tây Sơn",
        "code": "phuong_tay_son",
        "division_type": "phường",
        "short_codename": "tay_son",
        "parentCode": "thanh_pho_tam_diep"
    },
    {
        "name": "Xã Yên Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "thanh_pho_tam_diep"
    },
    {
        "name": "Phường Yên Bình",
        "code": "phuong_yen_binh",
        "division_type": "phường",
        "short_codename": "yen_binh",
        "parentCode": "thanh_pho_tam_diep"
    },
    {
        "name": "Phường Tân Bình",
        "code": "phuong_tan_binh",
        "division_type": "phường",
        "short_codename": "tan_binh",
        "parentCode": "thanh_pho_tam_diep"
    },
    {
        "name": "Xã Quang Sơn",
        "code": "xa_quang_son",
        "division_type": "xã",
        "short_codename": "quang_son",
        "parentCode": "thanh_pho_tam_diep"
    },
    {
        "name": "Xã Đông Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "thanh_pho_tam_diep"
    },
    {
        "name": "Thị trấn Nho Quan",
        "code": "thi_tran_nho_quan",
        "division_type": "thị trấn",
        "short_codename": "nho_quan",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Xích Thổ",
        "code": "xa_xich_tho",
        "division_type": "xã",
        "short_codename": "xich_tho",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Gia Lâm",
        "code": "xa_gia_lam",
        "division_type": "xã",
        "short_codename": "gia_lam",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Gia Sơn",
        "code": "xa_gia_son",
        "division_type": "xã",
        "short_codename": "gia_son",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Thạch Bình",
        "code": "xa_thach_binh",
        "division_type": "xã",
        "short_codename": "thach_binh",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Gia Thủy",
        "code": "xa_gia_thuy",
        "division_type": "xã",
        "short_codename": "gia_thuy",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Gia Tường",
        "code": "xa_gia_tuong",
        "division_type": "xã",
        "short_codename": "gia_tuong",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Cúc Phương",
        "code": "xa_cuc_phuong",
        "division_type": "xã",
        "short_codename": "cuc_phuong",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Đức Long",
        "code": "xa_duc_long",
        "division_type": "xã",
        "short_codename": "duc_long",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Lạc Vân",
        "code": "xa_lac_van",
        "division_type": "xã",
        "short_codename": "lac_van",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Đồng Phong",
        "code": "xa_dong_phong",
        "division_type": "xã",
        "short_codename": "dong_phong",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Yên Quang",
        "code": "xa_yen_quang",
        "division_type": "xã",
        "short_codename": "yen_quang",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Lạng Phong",
        "code": "xa_lang_phong",
        "division_type": "xã",
        "short_codename": "lang_phong",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Thượng Hòa",
        "code": "xa_thuong_hoa",
        "division_type": "xã",
        "short_codename": "thuong_hoa",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Văn Phong",
        "code": "xa_van_phong",
        "division_type": "xã",
        "short_codename": "van_phong",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Văn Phương",
        "code": "xa_van_phuong",
        "division_type": "xã",
        "short_codename": "van_phuong",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Thanh Lạc",
        "code": "xa_thanh_lac",
        "division_type": "xã",
        "short_codename": "thanh_lac",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Sơn Lai",
        "code": "xa_son_lai",
        "division_type": "xã",
        "short_codename": "son_lai",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Sơn Thành",
        "code": "xa_son_thanh",
        "division_type": "xã",
        "short_codename": "son_thanh",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Văn Phú",
        "code": "xa_van_phu",
        "division_type": "xã",
        "short_codename": "van_phu",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Phú Lộc",
        "code": "xa_phu_loc",
        "division_type": "xã",
        "short_codename": "phu_loc",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Kỳ Phú",
        "code": "xa_ky_phu",
        "division_type": "xã",
        "short_codename": "ky_phu",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Quỳnh Lưu",
        "code": "xa_quynh_luu",
        "division_type": "xã",
        "short_codename": "quynh_luu",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Sơn Hà",
        "code": "xa_son_ha",
        "division_type": "xã",
        "short_codename": "son_ha",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Phú Long",
        "code": "xa_phu_long",
        "division_type": "xã",
        "short_codename": "phu_long",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Xã Quảng Lạc",
        "code": "xa_quang_lac",
        "division_type": "xã",
        "short_codename": "quang_lac",
        "parentCode": "huyen_nho_quan"
    },
    {
        "name": "Thị trấn Me",
        "code": "thi_tran_me",
        "division_type": "thị trấn",
        "short_codename": "me",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Hòa",
        "code": "xa_gia_hoa",
        "division_type": "xã",
        "short_codename": "gia_hoa",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Hưng",
        "code": "xa_gia_hung",
        "division_type": "xã",
        "short_codename": "gia_hung",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Liên Sơn",
        "code": "xa_lien_son",
        "division_type": "xã",
        "short_codename": "lien_son",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Thanh",
        "code": "xa_gia_thanh",
        "division_type": "xã",
        "short_codename": "gia_thanh",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Vân",
        "code": "xa_gia_van",
        "division_type": "xã",
        "short_codename": "gia_van",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Phú",
        "code": "xa_gia_phu",
        "division_type": "xã",
        "short_codename": "gia_phu",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Xuân",
        "code": "xa_gia_xuan",
        "division_type": "xã",
        "short_codename": "gia_xuan",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Lập",
        "code": "xa_gia_lap",
        "division_type": "xã",
        "short_codename": "gia_lap",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Vượng",
        "code": "xa_gia_vuong",
        "division_type": "xã",
        "short_codename": "gia_vuong",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Trấn",
        "code": "xa_gia_tran",
        "division_type": "xã",
        "short_codename": "gia_tran",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Thịnh",
        "code": "xa_gia_thinh",
        "division_type": "xã",
        "short_codename": "gia_thinh",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Phương",
        "code": "xa_gia_phuong",
        "division_type": "xã",
        "short_codename": "gia_phuong",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Tân",
        "code": "xa_gia_tan",
        "division_type": "xã",
        "short_codename": "gia_tan",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Thắng",
        "code": "xa_gia_thang",
        "division_type": "xã",
        "short_codename": "gia_thang",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Trung",
        "code": "xa_gia_trung",
        "division_type": "xã",
        "short_codename": "gia_trung",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Minh",
        "code": "xa_gia_minh",
        "division_type": "xã",
        "short_codename": "gia_minh",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Lạc",
        "code": "xa_gia_lac",
        "division_type": "xã",
        "short_codename": "gia_lac",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Tiến",
        "code": "xa_gia_tien",
        "division_type": "xã",
        "short_codename": "gia_tien",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Sinh",
        "code": "xa_gia_sinh",
        "division_type": "xã",
        "short_codename": "gia_sinh",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Xã Gia Phong",
        "code": "xa_gia_phong",
        "division_type": "xã",
        "short_codename": "gia_phong",
        "parentCode": "huyen_gia_vien"
    },
    {
        "name": "Thị trấn Thiên Tôn",
        "code": "thi_tran_thien_ton",
        "division_type": "thị trấn",
        "short_codename": "thien_ton",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Ninh Giang",
        "code": "xa_ninh_giang",
        "division_type": "xã",
        "short_codename": "ninh_giang",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Trường Yên",
        "code": "xa_truong_yen",
        "division_type": "xã",
        "short_codename": "truong_yen",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Ninh Khang",
        "code": "xa_ninh_khang",
        "division_type": "xã",
        "short_codename": "ninh_khang",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Ninh Mỹ",
        "code": "xa_ninh_my",
        "division_type": "xã",
        "short_codename": "ninh_my",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Ninh Hòa",
        "code": "xa_ninh_hoa",
        "division_type": "xã",
        "short_codename": "ninh_hoa",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Ninh Xuân",
        "code": "xa_ninh_xuan",
        "division_type": "xã",
        "short_codename": "ninh_xuan",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Ninh Hải",
        "code": "xa_ninh_hai",
        "division_type": "xã",
        "short_codename": "ninh_hai",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Ninh Thắng",
        "code": "xa_ninh_thang",
        "division_type": "xã",
        "short_codename": "ninh_thang",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Ninh Vân",
        "code": "xa_ninh_van",
        "division_type": "xã",
        "short_codename": "ninh_van",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Xã Ninh An",
        "code": "xa_ninh_an",
        "division_type": "xã",
        "short_codename": "ninh_an",
        "parentCode": "huyen_hoa_lu"
    },
    {
        "name": "Thị trấn Yên Ninh",
        "code": "thi_tran_yen_ninh",
        "division_type": "thị trấn",
        "short_codename": "yen_ninh",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Tiên",
        "code": "xa_khanh_tien",
        "division_type": "xã",
        "short_codename": "khanh_tien",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Phú",
        "code": "xa_khanh_phu",
        "division_type": "xã",
        "short_codename": "khanh_phu",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Hòa",
        "code": "xa_khanh_hoa",
        "division_type": "xã",
        "short_codename": "khanh_hoa",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Lợi",
        "code": "xa_khanh_loi",
        "division_type": "xã",
        "short_codename": "khanh_loi",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh An",
        "code": "xa_khanh_an",
        "division_type": "xã",
        "short_codename": "khanh_an",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Cường",
        "code": "xa_khanh_cuong",
        "division_type": "xã",
        "short_codename": "khanh_cuong",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Cư",
        "code": "xa_khanh_cu",
        "division_type": "xã",
        "short_codename": "khanh_cu",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Thiện",
        "code": "xa_khanh_thien",
        "division_type": "xã",
        "short_codename": "khanh_thien",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Hải",
        "code": "xa_khanh_hai",
        "division_type": "xã",
        "short_codename": "khanh_hai",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Trung",
        "code": "xa_khanh_trung",
        "division_type": "xã",
        "short_codename": "khanh_trung",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Mậu",
        "code": "xa_khanh_mau",
        "division_type": "xã",
        "short_codename": "khanh_mau",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Vân",
        "code": "xa_khanh_van",
        "division_type": "xã",
        "short_codename": "khanh_van",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Hội",
        "code": "xa_khanh_hoi",
        "division_type": "xã",
        "short_codename": "khanh_hoi",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Công",
        "code": "xa_khanh_cong",
        "division_type": "xã",
        "short_codename": "khanh_cong",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Thành",
        "code": "xa_khanh_thanh",
        "division_type": "xã",
        "short_codename": "khanh_thanh",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Nhạc",
        "code": "xa_khanh_nhac",
        "division_type": "xã",
        "short_codename": "khanh_nhac",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Thủy",
        "code": "xa_khanh_thuy",
        "division_type": "xã",
        "short_codename": "khanh_thuy",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Xã Khánh Hồng",
        "code": "xa_khanh_hong",
        "division_type": "xã",
        "short_codename": "khanh_hong",
        "parentCode": "huyen_yen_khanh"
    },
    {
        "name": "Thị trấn Phát Diệm",
        "code": "thi_tran_phat_diem",
        "division_type": "thị trấn",
        "short_codename": "phat_diem",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Thị trấn Bình Minh",
        "code": "thi_tran_binh_minh",
        "division_type": "thị trấn",
        "short_codename": "binh_minh",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Hồi Ninh",
        "code": "xa_hoi_ninh",
        "division_type": "xã",
        "short_codename": "hoi_ninh",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Xuân Chính",
        "code": "xa_xuan_chinh",
        "division_type": "xã",
        "short_codename": "xuan_chinh",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Kim Định",
        "code": "xa_kim_dinh",
        "division_type": "xã",
        "short_codename": "kim_dinh",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Ân Hòa",
        "code": "xa_an_hoa",
        "division_type": "xã",
        "short_codename": "an_hoa",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Hùng Tiến",
        "code": "xa_hung_tien",
        "division_type": "xã",
        "short_codename": "hung_tien",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Quang Thiện",
        "code": "xa_quang_thien",
        "division_type": "xã",
        "short_codename": "quang_thien",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Như Hòa",
        "code": "xa_nhu_hoa",
        "division_type": "xã",
        "short_codename": "nhu_hoa",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Chất Bình",
        "code": "xa_chat_binh",
        "division_type": "xã",
        "short_codename": "chat_binh",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Đồng Hướng",
        "code": "xa_dong_huong",
        "division_type": "xã",
        "short_codename": "dong_huong",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Kim Chính",
        "code": "xa_kim_chinh",
        "division_type": "xã",
        "short_codename": "kim_chinh",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Thượng Kiệm",
        "code": "xa_thuong_kiem",
        "division_type": "xã",
        "short_codename": "thuong_kiem",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Lưu Phương",
        "code": "xa_luu_phuong",
        "division_type": "xã",
        "short_codename": "luu_phuong",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Yên Lộc",
        "code": "xa_yen_loc",
        "division_type": "xã",
        "short_codename": "yen_loc",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Lai Thành",
        "code": "xa_lai_thanh",
        "division_type": "xã",
        "short_codename": "lai_thanh",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Định Hóa",
        "code": "xa_dinh_hoa",
        "division_type": "xã",
        "short_codename": "dinh_hoa",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Văn Hải",
        "code": "xa_van_hai",
        "division_type": "xã",
        "short_codename": "van_hai",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Kim Tân",
        "code": "xa_kim_tan",
        "division_type": "xã",
        "short_codename": "kim_tan",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Kim Mỹ",
        "code": "xa_kim_my",
        "division_type": "xã",
        "short_codename": "kim_my",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Cồn Thoi",
        "code": "xa_con_thoi",
        "division_type": "xã",
        "short_codename": "con_thoi",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Kim Hải",
        "code": "xa_kim_hai",
        "division_type": "xã",
        "short_codename": "kim_hai",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Kim Trung",
        "code": "xa_kim_trung",
        "division_type": "xã",
        "short_codename": "kim_trung",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Xã Kim Đông",
        "code": "xa_kim_dong",
        "division_type": "xã",
        "short_codename": "kim_dong",
        "parentCode": "huyen_kim_son"
    },
    {
        "name": "Thị trấn Yên Thịnh",
        "code": "thi_tran_yen_thinh",
        "division_type": "thị trấn",
        "short_codename": "yen_thinh",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Khánh Thượng",
        "code": "xa_khanh_thuong",
        "division_type": "xã",
        "short_codename": "khanh_thuong",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Khánh Dương",
        "code": "xa_khanh_duong",
        "division_type": "xã",
        "short_codename": "khanh_duong",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Mai Sơn",
        "code": "xa_mai_son",
        "division_type": "xã",
        "short_codename": "mai_son",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Khánh Thịnh",
        "code": "xa_khanh_thinh",
        "division_type": "xã",
        "short_codename": "khanh_thinh",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Phong",
        "code": "xa_yen_phong",
        "division_type": "xã",
        "short_codename": "yen_phong",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Hòa",
        "code": "xa_yen_hoa",
        "division_type": "xã",
        "short_codename": "yen_hoa",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Thắng",
        "code": "xa_yen_thang",
        "division_type": "xã",
        "short_codename": "yen_thang",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Từ",
        "code": "xa_yen_tu",
        "division_type": "xã",
        "short_codename": "yen_tu",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Hưng",
        "code": "xa_yen_hung",
        "division_type": "xã",
        "short_codename": "yen_hung",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Thành",
        "code": "xa_yen_thanh",
        "division_type": "xã",
        "short_codename": "yen_thanh",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Nhân",
        "code": "xa_yen_nhan",
        "division_type": "xã",
        "short_codename": "yen_nhan",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Mỹ",
        "code": "xa_yen_my",
        "division_type": "xã",
        "short_codename": "yen_my",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Mạc",
        "code": "xa_yen_mac",
        "division_type": "xã",
        "short_codename": "yen_mac",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Đồng",
        "code": "xa_yen_dong",
        "division_type": "xã",
        "short_codename": "yen_dong",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Thái",
        "code": "xa_yen_thai",
        "division_type": "xã",
        "short_codename": "yen_thai",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Xã Yên Lâm",
        "code": "xa_yen_lam",
        "division_type": "xã",
        "short_codename": "yen_lam",
        "parentCode": "huyen_yen_mo"
    },
    {
        "name": "Phường Hàm Rồng",
        "code": "phuong_ham_rong",
        "division_type": "phường",
        "short_codename": "ham_rong",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Đông Thọ",
        "code": "phuong_dong_tho",
        "division_type": "phường",
        "short_codename": "dong_tho",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Nam Ngạn",
        "code": "phuong_nam_ngan",
        "division_type": "phường",
        "short_codename": "nam_ngan",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Trường Thi",
        "code": "phuong_truong_thi",
        "division_type": "phường",
        "short_codename": "truong_thi",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Điện Biên",
        "code": "phuong_dien_bien",
        "division_type": "phường",
        "short_codename": "dien_bien",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Phú Sơn",
        "code": "phuong_phu_son",
        "division_type": "phường",
        "short_codename": "phu_son",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Lam Sơn",
        "code": "phuong_lam_son",
        "division_type": "phường",
        "short_codename": "lam_son",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Ba Đình",
        "code": "phuong_ba_dinh",
        "division_type": "phường",
        "short_codename": "ba_dinh",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Ngọc Trạo",
        "code": "phuong_ngoc_trao",
        "division_type": "phường",
        "short_codename": "ngoc_trao",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Đông Vệ",
        "code": "phuong_dong_ve",
        "division_type": "phường",
        "short_codename": "dong_ve",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Đông Sơn",
        "code": "phuong_dong_son",
        "division_type": "phường",
        "short_codename": "dong_son",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Tân Sơn",
        "code": "phuong_tan_son",
        "division_type": "phường",
        "short_codename": "tan_son",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Đông Cương",
        "code": "phuong_dong_cuong",
        "division_type": "phường",
        "short_codename": "dong_cuong",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Đông Hương",
        "code": "phuong_dong_huong",
        "division_type": "phường",
        "short_codename": "dong_huong",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Đông Hải",
        "code": "phuong_dong_hai",
        "division_type": "phường",
        "short_codename": "dong_hai",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Quảng Hưng",
        "code": "phuong_quang_hung",
        "division_type": "phường",
        "short_codename": "quang_hung",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Quảng Thắng",
        "code": "phuong_quang_thang",
        "division_type": "phường",
        "short_codename": "quang_thang",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Quảng Thành",
        "code": "phuong_quang_thanh",
        "division_type": "phường",
        "short_codename": "quang_thanh",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Xã Thiệu Vân",
        "code": "xa_thieu_van",
        "division_type": "xã",
        "short_codename": "thieu_van",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Thiệu Khánh",
        "code": "phuong_thieu_khanh",
        "division_type": "phường",
        "short_codename": "thieu_khanh",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Thiệu Dương",
        "code": "phuong_thieu_duong",
        "division_type": "phường",
        "short_codename": "thieu_duong",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Tào Xuyên",
        "code": "phuong_tao_xuyen",
        "division_type": "phường",
        "short_codename": "tao_xuyen",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Long Anh",
        "code": "phuong_long_anh",
        "division_type": "phường",
        "short_codename": "long_anh",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Xã Hoằng Quang",
        "code": "xa_hoang_quang",
        "division_type": "xã",
        "short_codename": "hoang_quang",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Xã Hoằng Đại",
        "code": "xa_hoang_dai",
        "division_type": "xã",
        "short_codename": "hoang_dai",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Đông Lĩnh",
        "code": "phuong_dong_linh",
        "division_type": "phường",
        "short_codename": "dong_linh",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Xã Đông Vinh",
        "code": "xa_dong_vinh",
        "division_type": "xã",
        "short_codename": "dong_vinh",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Đông Tân",
        "code": "phuong_dong_tan",
        "division_type": "phường",
        "short_codename": "dong_tan",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường An Hưng",
        "code": "phuong_an_hung",
        "division_type": "phường",
        "short_codename": "an_hung",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Quảng Thịnh",
        "code": "phuong_quang_thinh",
        "division_type": "phường",
        "short_codename": "quang_thinh",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Quảng Đông",
        "code": "phuong_quang_dong",
        "division_type": "phường",
        "short_codename": "quang_dong",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Quảng Cát",
        "code": "phuong_quang_cat",
        "division_type": "phường",
        "short_codename": "quang_cat",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Quảng Phú",
        "code": "phuong_quang_phu",
        "division_type": "phường",
        "short_codename": "quang_phu",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Quảng Tâm",
        "code": "phuong_quang_tam",
        "division_type": "phường",
        "short_codename": "quang_tam",
        "parentCode": "thanh_pho_thanh_hoa"
    },
    {
        "name": "Phường Bắc Sơn",
        "code": "phuong_bac_son",
        "division_type": "phường",
        "short_codename": "bac_son",
        "parentCode": "thi_xa_bim_son"
    },
    {
        "name": "Phường Ba Đình",
        "code": "phuong_ba_dinh",
        "division_type": "phường",
        "short_codename": "ba_dinh",
        "parentCode": "thi_xa_bim_son"
    },
    {
        "name": "Phường Lam Sơn",
        "code": "phuong_lam_son",
        "division_type": "phường",
        "short_codename": "lam_son",
        "parentCode": "thi_xa_bim_son"
    },
    {
        "name": "Phường Ngọc Trạo",
        "code": "phuong_ngoc_trao",
        "division_type": "phường",
        "short_codename": "ngoc_trao",
        "parentCode": "thi_xa_bim_son"
    },
    {
        "name": "Phường Đông Sơn",
        "code": "phuong_dong_son",
        "division_type": "phường",
        "short_codename": "dong_son",
        "parentCode": "thi_xa_bim_son"
    },
    {
        "name": "Phường Phú Sơn",
        "code": "phuong_phu_son",
        "division_type": "phường",
        "short_codename": "phu_son",
        "parentCode": "thi_xa_bim_son"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "thi_xa_bim_son"
    },
    {
        "name": "Phường Trung Sơn",
        "code": "phuong_trung_son",
        "division_type": "phường",
        "short_codename": "trung_son",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Phường Bắc Sơn",
        "code": "phuong_bac_son",
        "division_type": "phường",
        "short_codename": "bac_son",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Phường Trường Sơn",
        "code": "phuong_truong_son",
        "division_type": "phường",
        "short_codename": "truong_son",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Phường Quảng Cư",
        "code": "phuong_quang_cu",
        "division_type": "phường",
        "short_codename": "quang_cu",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Phường Quảng Tiến",
        "code": "phuong_quang_tien",
        "division_type": "phường",
        "short_codename": "quang_tien",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Xã Quảng Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Xã Quảng Hùng",
        "code": "xa_quang_hung",
        "division_type": "xã",
        "short_codename": "quang_hung",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Phường Quảng Thọ",
        "code": "phuong_quang_tho",
        "division_type": "phường",
        "short_codename": "quang_tho",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Phường Quảng Châu",
        "code": "phuong_quang_chau",
        "division_type": "phường",
        "short_codename": "quang_chau",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Phường Quảng Vinh",
        "code": "phuong_quang_vinh",
        "division_type": "phường",
        "short_codename": "quang_vinh",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Xã Quảng Đại",
        "code": "xa_quang_dai",
        "division_type": "xã",
        "short_codename": "quang_dai",
        "parentCode": "thanh_pho_sam_son"
    },
    {
        "name": "Thị trấn Mường Lát",
        "code": "thi_tran_muong_lat",
        "division_type": "thị trấn",
        "short_codename": "muong_lat",
        "parentCode": "huyen_muong_lat"
    },
    {
        "name": "Xã Tam Chung",
        "code": "xa_tam_chung",
        "division_type": "xã",
        "short_codename": "tam_chung",
        "parentCode": "huyen_muong_lat"
    },
    {
        "name": "Xã Mường Lý",
        "code": "xa_muong_ly",
        "division_type": "xã",
        "short_codename": "muong_ly",
        "parentCode": "huyen_muong_lat"
    },
    {
        "name": "Xã Trung Lý",
        "code": "xa_trung_ly",
        "division_type": "xã",
        "short_codename": "trung_ly",
        "parentCode": "huyen_muong_lat"
    },
    {
        "name": "Xã Quang Chiểu",
        "code": "xa_quang_chieu",
        "division_type": "xã",
        "short_codename": "quang_chieu",
        "parentCode": "huyen_muong_lat"
    },
    {
        "name": "Xã Pù Nhi",
        "code": "xa_pu_nhi",
        "division_type": "xã",
        "short_codename": "pu_nhi",
        "parentCode": "huyen_muong_lat"
    },
    {
        "name": "Xã Nhi Sơn",
        "code": "xa_nhi_son",
        "division_type": "xã",
        "short_codename": "nhi_son",
        "parentCode": "huyen_muong_lat"
    },
    {
        "name": "Xã Mường Chanh",
        "code": "xa_muong_chanh",
        "division_type": "xã",
        "short_codename": "muong_chanh",
        "parentCode": "huyen_muong_lat"
    },
    {
        "name": "Thị trấn Hồi Xuân",
        "code": "thi_tran_hoi_xuan",
        "division_type": "thị trấn",
        "short_codename": "hoi_xuan",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Thành Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Trung Sơn",
        "code": "xa_trung_son",
        "division_type": "xã",
        "short_codename": "trung_son",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Phú Thanh",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "phu_thanh",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Trung Thành",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Phú Lệ",
        "code": "xa_phu_le",
        "division_type": "xã",
        "short_codename": "phu_le",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Phú Xuân",
        "code": "xa_phu_xuan",
        "division_type": "xã",
        "short_codename": "phu_xuan",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Hiền Chung",
        "code": "xa_hien_chung",
        "division_type": "xã",
        "short_codename": "hien_chung",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Hiền Kiệt",
        "code": "xa_hien_kiet",
        "division_type": "xã",
        "short_codename": "hien_kiet",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Nam Tiến",
        "code": "xa_nam_tien",
        "division_type": "xã",
        "short_codename": "nam_tien",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Thiên Phủ",
        "code": "xa_thien_phu",
        "division_type": "xã",
        "short_codename": "thien_phu",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Phú Nghiêm",
        "code": "xa_phu_nghiem",
        "division_type": "xã",
        "short_codename": "phu_nghiem",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Nam Xuân",
        "code": "xa_nam_xuan",
        "division_type": "xã",
        "short_codename": "nam_xuan",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Xã Nam Động",
        "code": "xa_nam_dong",
        "division_type": "xã",
        "short_codename": "nam_dong",
        "parentCode": "huyen_quan_hoa"
    },
    {
        "name": "Thị trấn Cành Nàng",
        "code": "thi_tran_canh_nang",
        "division_type": "thị trấn",
        "short_codename": "canh_nang",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Điền Thượng",
        "code": "xa_dien_thuong",
        "division_type": "xã",
        "short_codename": "dien_thuong",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Điền Hạ",
        "code": "xa_dien_ha",
        "division_type": "xã",
        "short_codename": "dien_ha",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Điền Quang",
        "code": "xa_dien_quang",
        "division_type": "xã",
        "short_codename": "dien_quang",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Điền Trung",
        "code": "xa_dien_trung",
        "division_type": "xã",
        "short_codename": "dien_trung",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Thành Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Lương Ngoại",
        "code": "xa_luong_ngoai",
        "division_type": "xã",
        "short_codename": "luong_ngoai",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Ái Thượng",
        "code": "xa_ai_thuong",
        "division_type": "xã",
        "short_codename": "ai_thuong",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Lương Nội",
        "code": "xa_luong_noi",
        "division_type": "xã",
        "short_codename": "luong_noi",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Điền Lư",
        "code": "xa_dien_lu",
        "division_type": "xã",
        "short_codename": "dien_lu",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Lương Trung",
        "code": "xa_luong_trung",
        "division_type": "xã",
        "short_codename": "luong_trung",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Lũng Niêm",
        "code": "xa_lung_niem",
        "division_type": "xã",
        "short_codename": "lung_niem",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Lũng Cao",
        "code": "xa_lung_cao",
        "division_type": "xã",
        "short_codename": "lung_cao",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Hạ Trung",
        "code": "xa_ha_trung",
        "division_type": "xã",
        "short_codename": "ha_trung",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Cổ Lũng",
        "code": "xa_co_lung",
        "division_type": "xã",
        "short_codename": "co_lung",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Thành Lâm",
        "code": "xa_thanh_lam",
        "division_type": "xã",
        "short_codename": "thanh_lam",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Ban Công",
        "code": "xa_ban_cong",
        "division_type": "xã",
        "short_codename": "ban_cong",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Kỳ Tân",
        "code": "xa_ky_tan",
        "division_type": "xã",
        "short_codename": "ky_tan",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Văn Nho",
        "code": "xa_van_nho",
        "division_type": "xã",
        "short_codename": "van_nho",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Thiết Ống",
        "code": "xa_thiet_ong",
        "division_type": "xã",
        "short_codename": "thiet_ong",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Thiết Kế",
        "code": "xa_thiet_ke",
        "division_type": "xã",
        "short_codename": "thiet_ke",
        "parentCode": "huyen_ba_thuoc"
    },
    {
        "name": "Xã Trung Xuân",
        "code": "xa_trung_xuan",
        "division_type": "xã",
        "short_codename": "trung_xuan",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Trung Thượng",
        "code": "xa_trung_thuong",
        "division_type": "xã",
        "short_codename": "trung_thuong",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Trung Tiến",
        "code": "xa_trung_tien",
        "division_type": "xã",
        "short_codename": "trung_tien",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Trung Hạ",
        "code": "xa_trung_ha",
        "division_type": "xã",
        "short_codename": "trung_ha",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Sơn Hà",
        "code": "xa_son_ha",
        "division_type": "xã",
        "short_codename": "son_ha",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Tam Thanh",
        "code": "xa_tam_thanh",
        "division_type": "xã",
        "short_codename": "tam_thanh",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Sơn Thủy",
        "code": "xa_son_thuy",
        "division_type": "xã",
        "short_codename": "son_thuy",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Na Mèo",
        "code": "xa_na_meo",
        "division_type": "xã",
        "short_codename": "na_meo",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Thị trấn Sơn Lư",
        "code": "thi_tran_son_lu",
        "division_type": "thị trấn",
        "short_codename": "son_lu",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Tam Lư",
        "code": "xa_tam_lu",
        "division_type": "xã",
        "short_codename": "tam_lu",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Sơn Điện",
        "code": "xa_son_dien",
        "division_type": "xã",
        "short_codename": "son_dien",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Mường Mìn",
        "code": "xa_muong_min",
        "division_type": "xã",
        "short_codename": "muong_min",
        "parentCode": "huyen_quan_son"
    },
    {
        "name": "Xã Yên Khương",
        "code": "xa_yen_khuong",
        "division_type": "xã",
        "short_codename": "yen_khuong",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Xã Yên Thắng",
        "code": "xa_yen_thang",
        "division_type": "xã",
        "short_codename": "yen_thang",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Xã Trí Nang",
        "code": "xa_tri_nang",
        "division_type": "xã",
        "short_codename": "tri_nang",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Xã Giao An",
        "code": "xa_giao_an",
        "division_type": "xã",
        "short_codename": "giao_an",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Xã Giao Thiện",
        "code": "xa_giao_thien",
        "division_type": "xã",
        "short_codename": "giao_thien",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Xã Tân Phúc",
        "code": "xa_tan_phuc",
        "division_type": "xã",
        "short_codename": "tan_phuc",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Xã Tam Văn",
        "code": "xa_tam_van",
        "division_type": "xã",
        "short_codename": "tam_van",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Xã Lâm Phú",
        "code": "xa_lam_phu",
        "division_type": "xã",
        "short_codename": "lam_phu",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Thị trấn Lang Chánh",
        "code": "thi_tran_lang_chanh",
        "division_type": "thị trấn",
        "short_codename": "lang_chanh",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Xã Đồng Lương",
        "code": "xa_dong_luong",
        "division_type": "xã",
        "short_codename": "dong_luong",
        "parentCode": "huyen_lang_chanh"
    },
    {
        "name": "Thị Trấn Ngọc Lặc",
        "code": "thi_tran_ngoc_lac",
        "division_type": "thị trấn",
        "short_codename": "ngoc_lac",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Lam Sơn",
        "code": "xa_lam_son",
        "division_type": "xã",
        "short_codename": "lam_son",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Mỹ Tân",
        "code": "xa_my_tan",
        "division_type": "xã",
        "short_codename": "my_tan",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Thúy Sơn",
        "code": "xa_thuy_son",
        "division_type": "xã",
        "short_codename": "thuy_son",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Thạch Lập",
        "code": "xa_thach_lap",
        "division_type": "xã",
        "short_codename": "thach_lap",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Vân Âm",
        "code": "xa_van_am",
        "division_type": "xã",
        "short_codename": "van_am",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Cao Ngọc",
        "code": "xa_cao_ngoc",
        "division_type": "xã",
        "short_codename": "cao_ngoc",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Đồng Thịnh",
        "code": "xa_dong_thinh",
        "division_type": "xã",
        "short_codename": "dong_thinh",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Ngọc Liên",
        "code": "xa_ngoc_lien",
        "division_type": "xã",
        "short_codename": "ngoc_lien",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Ngọc Sơn",
        "code": "xa_ngoc_son",
        "division_type": "xã",
        "short_codename": "ngoc_son",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Lộc Thịnh",
        "code": "xa_loc_thinh",
        "division_type": "xã",
        "short_codename": "loc_thinh",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Cao Thịnh",
        "code": "xa_cao_thinh",
        "division_type": "xã",
        "short_codename": "cao_thinh",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Ngọc Trung",
        "code": "xa_ngoc_trung",
        "division_type": "xã",
        "short_codename": "ngoc_trung",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Phùng Giáo",
        "code": "xa_phung_giao",
        "division_type": "xã",
        "short_codename": "phung_giao",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Phùng Minh",
        "code": "xa_phung_minh",
        "division_type": "xã",
        "short_codename": "phung_minh",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Phúc Thịnh",
        "code": "xa_phuc_thinh",
        "division_type": "xã",
        "short_codename": "phuc_thinh",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Nguyệt Ấn",
        "code": "xa_nguyet_an",
        "division_type": "xã",
        "short_codename": "nguyet_an",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Kiên Thọ",
        "code": "xa_kien_tho",
        "division_type": "xã",
        "short_codename": "kien_tho",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Minh Tiến",
        "code": "xa_minh_tien",
        "division_type": "xã",
        "short_codename": "minh_tien",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Xã Minh Sơn",
        "code": "xa_minh_son",
        "division_type": "xã",
        "short_codename": "minh_son",
        "parentCode": "huyen_ngoc_lac"
    },
    {
        "name": "Thị trấn Phong Sơn",
        "code": "thi_tran_phong_son",
        "division_type": "thị trấn",
        "short_codename": "phong_son",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Thành",
        "code": "xa_cam_thanh",
        "division_type": "xã",
        "short_codename": "cam_thanh",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Quý",
        "code": "xa_cam_quy",
        "division_type": "xã",
        "short_codename": "cam_quy",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Lương",
        "code": "xa_cam_luong",
        "division_type": "xã",
        "short_codename": "cam_luong",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Thạch",
        "code": "xa_cam_thach",
        "division_type": "xã",
        "short_codename": "cam_thach",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Liên",
        "code": "xa_cam_lien",
        "division_type": "xã",
        "short_codename": "cam_lien",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Giang",
        "code": "xa_cam_giang",
        "division_type": "xã",
        "short_codename": "cam_giang",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Bình",
        "code": "xa_cam_binh",
        "division_type": "xã",
        "short_codename": "cam_binh",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Tú",
        "code": "xa_cam_tu",
        "division_type": "xã",
        "short_codename": "cam_tu",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Châu",
        "code": "xa_cam_chau",
        "division_type": "xã",
        "short_codename": "cam_chau",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Tâm",
        "code": "xa_cam_tam",
        "division_type": "xã",
        "short_codename": "cam_tam",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Ngọc",
        "code": "xa_cam_ngoc",
        "division_type": "xã",
        "short_codename": "cam_ngoc",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Long",
        "code": "xa_cam_long",
        "division_type": "xã",
        "short_codename": "cam_long",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Yên",
        "code": "xa_cam_yen",
        "division_type": "xã",
        "short_codename": "cam_yen",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Tân",
        "code": "xa_cam_tan",
        "division_type": "xã",
        "short_codename": "cam_tan",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Phú",
        "code": "xa_cam_phu",
        "division_type": "xã",
        "short_codename": "cam_phu",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Xã Cẩm Vân",
        "code": "xa_cam_van",
        "division_type": "xã",
        "short_codename": "cam_van",
        "parentCode": "huyen_cam_thuy"
    },
    {
        "name": "Thị trấn Kim Tân",
        "code": "thi_tran_kim_tan",
        "division_type": "thị trấn",
        "short_codename": "kim_tan",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Thị trấn Vân Du",
        "code": "thi_tran_van_du",
        "division_type": "thị trấn",
        "short_codename": "van_du",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thạch Lâm",
        "code": "xa_thach_lam",
        "division_type": "xã",
        "short_codename": "thach_lam",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thạch Quảng",
        "code": "xa_thach_quang",
        "division_type": "xã",
        "short_codename": "thach_quang",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thạch Tượng",
        "code": "xa_thach_tuong",
        "division_type": "xã",
        "short_codename": "thach_tuong",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thạch Cẩm",
        "code": "xa_thach_cam",
        "division_type": "xã",
        "short_codename": "thach_cam",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thạch Sơn",
        "code": "xa_thach_son",
        "division_type": "xã",
        "short_codename": "thach_son",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thạch Bình",
        "code": "xa_thach_binh",
        "division_type": "xã",
        "short_codename": "thach_binh",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thạch Định",
        "code": "xa_thach_dinh",
        "division_type": "xã",
        "short_codename": "thach_dinh",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thạch Đồng",
        "code": "xa_thach_dong",
        "division_type": "xã",
        "short_codename": "thach_dong",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thạch Long",
        "code": "xa_thach_long",
        "division_type": "xã",
        "short_codename": "thach_long",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Mỹ",
        "code": "xa_thanh_my",
        "division_type": "xã",
        "short_codename": "thanh_my",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Yên",
        "code": "xa_thanh_yen",
        "division_type": "xã",
        "short_codename": "thanh_yen",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Vinh",
        "code": "xa_thanh_vinh",
        "division_type": "xã",
        "short_codename": "thanh_vinh",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Minh",
        "code": "xa_thanh_minh",
        "division_type": "xã",
        "short_codename": "thanh_minh",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Công",
        "code": "xa_thanh_cong",
        "division_type": "xã",
        "short_codename": "thanh_cong",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Tân",
        "code": "xa_thanh_tan",
        "division_type": "xã",
        "short_codename": "thanh_tan",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Trực",
        "code": "xa_thanh_truc",
        "division_type": "xã",
        "short_codename": "thanh_truc",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Tâm",
        "code": "xa_thanh_tam",
        "division_type": "xã",
        "short_codename": "thanh_tam",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Thọ",
        "code": "xa_thanh_tho",
        "division_type": "xã",
        "short_codename": "thanh_tho",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Tiến",
        "code": "xa_thanh_tien",
        "division_type": "xã",
        "short_codename": "thanh_tien",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Long",
        "code": "xa_thanh_long",
        "division_type": "xã",
        "short_codename": "thanh_long",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Thành Hưng",
        "code": "xa_thanh_hung",
        "division_type": "xã",
        "short_codename": "thanh_hung",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Xã Ngọc Trạo",
        "code": "xa_ngoc_trao",
        "division_type": "xã",
        "short_codename": "ngoc_trao",
        "parentCode": "huyen_thach_thanh"
    },
    {
        "name": "Thị trấn Hà Trung",
        "code": "thi_tran_ha_trung",
        "division_type": "thị trấn",
        "short_codename": "ha_trung",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Long",
        "code": "xa_ha_long",
        "division_type": "xã",
        "short_codename": "ha_long",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Vinh",
        "code": "xa_ha_vinh",
        "division_type": "xã",
        "short_codename": "ha_vinh",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Bắc",
        "code": "xa_ha_bac",
        "division_type": "xã",
        "short_codename": "ha_bac",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hoạt Giang",
        "code": "xa_hoat_giang",
        "division_type": "xã",
        "short_codename": "hoat_giang",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Yên Dương",
        "code": "xa_yen_duong",
        "division_type": "xã",
        "short_codename": "yen_duong",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Giang",
        "code": "xa_ha_giang",
        "division_type": "xã",
        "short_codename": "ha_giang",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Lĩnh Toại",
        "code": "xa_linh_toai",
        "division_type": "xã",
        "short_codename": "linh_toai",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Ngọc",
        "code": "xa_ha_ngoc",
        "division_type": "xã",
        "short_codename": "ha_ngoc",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Yến Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Sơn",
        "code": "xa_ha_son",
        "division_type": "xã",
        "short_codename": "ha_son",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Lĩnh",
        "code": "xa_ha_linh",
        "division_type": "xã",
        "short_codename": "ha_linh",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Đông",
        "code": "xa_ha_dong",
        "division_type": "xã",
        "short_codename": "ha_dong",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Tân",
        "code": "xa_ha_tan",
        "division_type": "xã",
        "short_codename": "ha_tan",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Tiến",
        "code": "xa_ha_tien",
        "division_type": "xã",
        "short_codename": "ha_tien",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Bình",
        "code": "xa_ha_binh",
        "division_type": "xã",
        "short_codename": "ha_binh",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Lai",
        "code": "xa_ha_lai",
        "division_type": "xã",
        "short_codename": "ha_lai",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Châu",
        "code": "xa_ha_chau",
        "division_type": "xã",
        "short_codename": "ha_chau",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Thái",
        "code": "xa_ha_thai",
        "division_type": "xã",
        "short_codename": "ha_thai",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Xã Hà Hải",
        "code": "xa_ha_hai",
        "division_type": "xã",
        "short_codename": "ha_hai",
        "parentCode": "huyen_ha_trung"
    },
    {
        "name": "Thị trấn Vĩnh Lộc",
        "code": "thi_tran_vinh_loc",
        "division_type": "thị trấn",
        "short_codename": "vinh_loc",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh Quang",
        "code": "xa_vinh_quang",
        "division_type": "xã",
        "short_codename": "vinh_quang",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh Yên",
        "code": "xa_vinh_yen",
        "division_type": "xã",
        "short_codename": "vinh_yen",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh Tiến",
        "code": "xa_vinh_tien",
        "division_type": "xã",
        "short_codename": "vinh_tien",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh Long",
        "code": "xa_vinh_long",
        "division_type": "xã",
        "short_codename": "vinh_long",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh Phúc",
        "code": "xa_vinh_phuc",
        "division_type": "xã",
        "short_codename": "vinh_phuc",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh Hưng",
        "code": "xa_vinh_hung",
        "division_type": "xã",
        "short_codename": "xa_vinh_hung",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh Hòa",
        "code": "xa_vinh_hoa",
        "division_type": "xã",
        "short_codename": "vinh_hoa",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh Hùng",
        "code": "xa_vinh_hung",
        "division_type": "xã",
        "short_codename": "xa_vinh_hung",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Ninh Khang",
        "code": "xa_ninh_khang",
        "division_type": "xã",
        "short_codename": "ninh_khang",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh Thịnh",
        "code": "xa_vinh_thinh",
        "division_type": "xã",
        "short_codename": "vinh_thinh",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Xã Vĩnh An",
        "code": "xa_vinh_an",
        "division_type": "xã",
        "short_codename": "vinh_an",
        "parentCode": "huyen_vinh_loc"
    },
    {
        "name": "Thị trấn Thống Nhất",
        "code": "thi_tran_thong_nhat",
        "division_type": "thị trấn",
        "short_codename": "thong_nhat",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Lâm",
        "code": "xa_yen_lam",
        "division_type": "xã",
        "short_codename": "yen_lam",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Tâm",
        "code": "xa_yen_tam",
        "division_type": "xã",
        "short_codename": "yen_tam",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Phú",
        "code": "xa_yen_phu",
        "division_type": "xã",
        "short_codename": "yen_phu",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Quí Lộc",
        "code": "xa_qui_loc",
        "division_type": "xã",
        "short_codename": "qui_loc",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Thọ",
        "code": "xa_yen_tho",
        "division_type": "xã",
        "short_codename": "yen_tho",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Trung",
        "code": "xa_yen_trung",
        "division_type": "xã",
        "short_codename": "yen_trung",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Trường",
        "code": "xa_yen_truong",
        "division_type": "xã",
        "short_codename": "yen_truong",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Phong",
        "code": "xa_yen_phong",
        "division_type": "xã",
        "short_codename": "yen_phong",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Thái",
        "code": "xa_yen_thai",
        "division_type": "xã",
        "short_codename": "yen_thai",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Hùng",
        "code": "xa_yen_hung",
        "division_type": "xã",
        "short_codename": "yen_hung",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Thịnh",
        "code": "xa_yen_thinh",
        "division_type": "xã",
        "short_codename": "yen_thinh",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Ninh",
        "code": "xa_yen_ninh",
        "division_type": "xã",
        "short_codename": "yen_ninh",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Yên Lạc",
        "code": "xa_yen_lac",
        "division_type": "xã",
        "short_codename": "yen_lac",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Tăng",
        "code": "xa_dinh_tang",
        "division_type": "xã",
        "short_codename": "dinh_tang",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Hòa",
        "code": "xa_dinh_hoa",
        "division_type": "xã",
        "short_codename": "dinh_hoa",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Thành",
        "code": "xa_dinh_thanh",
        "division_type": "xã",
        "short_codename": "dinh_thanh",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Công",
        "code": "xa_dinh_cong",
        "division_type": "xã",
        "short_codename": "dinh_cong",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Tân",
        "code": "xa_dinh_tan",
        "division_type": "xã",
        "short_codename": "dinh_tan",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Tiến",
        "code": "xa_dinh_tien",
        "division_type": "xã",
        "short_codename": "dinh_tien",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Long",
        "code": "xa_dinh_long",
        "division_type": "xã",
        "short_codename": "dinh_long",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Liên",
        "code": "xa_dinh_lien",
        "division_type": "xã",
        "short_codename": "dinh_lien",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Thị trấn Quán Lào",
        "code": "thi_tran_quan_lao",
        "division_type": "thị trấn",
        "short_codename": "quan_lao",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Hưng",
        "code": "xa_dinh_hung",
        "division_type": "xã",
        "short_codename": "dinh_hung",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Hải",
        "code": "xa_dinh_hai",
        "division_type": "xã",
        "short_codename": "dinh_hai",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Định Bình",
        "code": "xa_dinh_binh",
        "division_type": "xã",
        "short_codename": "dinh_binh",
        "parentCode": "huyen_yen_dinh"
    },
    {
        "name": "Xã Xuân Hồng",
        "code": "xa_xuan_hong",
        "division_type": "xã",
        "short_codename": "xuan_hong",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Thị trấn Thọ Xuân",
        "code": "thi_tran_tho_xuan",
        "division_type": "thị trấn",
        "short_codename": "tho_xuan",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Bắc Lương",
        "code": "xa_bac_luong",
        "division_type": "xã",
        "short_codename": "bac_luong",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Nam Giang",
        "code": "xa_nam_giang",
        "division_type": "xã",
        "short_codename": "nam_giang",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Phong",
        "code": "xa_xuan_phong",
        "division_type": "xã",
        "short_codename": "xuan_phong",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Thọ Lộc",
        "code": "xa_tho_loc",
        "division_type": "xã",
        "short_codename": "tho_loc",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Trường",
        "code": "xa_xuan_truong",
        "division_type": "xã",
        "short_codename": "xuan_truong",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Hòa",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Thọ Hải",
        "code": "xa_tho_hai",
        "division_type": "xã",
        "short_codename": "tho_hai",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Tây Hồ",
        "code": "xa_tay_ho",
        "division_type": "xã",
        "short_codename": "tay_ho",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Giang",
        "code": "xa_xuan_giang",
        "division_type": "xã",
        "short_codename": "xuan_giang",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Sinh",
        "code": "xa_xuan_sinh",
        "division_type": "xã",
        "short_codename": "xuan_sinh",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Hưng",
        "code": "xa_xuan_hung",
        "division_type": "xã",
        "short_codename": "xuan_hung",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Thọ Diên",
        "code": "xa_tho_dien",
        "division_type": "xã",
        "short_codename": "tho_dien",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Thọ Lâm",
        "code": "xa_tho_lam",
        "division_type": "xã",
        "short_codename": "tho_lam",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Thọ Xương",
        "code": "xa_tho_xuong",
        "division_type": "xã",
        "short_codename": "tho_xuong",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Bái",
        "code": "xa_xuan_bai",
        "division_type": "xã",
        "short_codename": "xuan_bai",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Phú",
        "code": "xa_xuan_phu",
        "division_type": "xã",
        "short_codename": "xuan_phu",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Thị trấn Sao Vàng",
        "code": "thi_tran_sao_vang",
        "division_type": "thị trấn",
        "short_codename": "sao_vang",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Thị trấn Lam Sơn",
        "code": "thi_tran_lam_son",
        "division_type": "thị trấn",
        "short_codename": "lam_son",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Thiên",
        "code": "xa_xuan_thien",
        "division_type": "xã",
        "short_codename": "xuan_thien",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Thuận Minh",
        "code": "xa_thuan_minh",
        "division_type": "xã",
        "short_codename": "thuan_minh",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Thọ Lập",
        "code": "xa_tho_lap",
        "division_type": "xã",
        "short_codename": "tho_lap",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Quảng Phú",
        "code": "xa_quang_phu",
        "division_type": "xã",
        "short_codename": "quang_phu",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Tín",
        "code": "xa_xuan_tin",
        "division_type": "xã",
        "short_codename": "xuan_tin",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Phú Xuân",
        "code": "xa_phu_xuan",
        "division_type": "xã",
        "short_codename": "phu_xuan",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Lai",
        "code": "xa_xuan_lai",
        "division_type": "xã",
        "short_codename": "xuan_lai",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Lập",
        "code": "xa_xuan_lap",
        "division_type": "xã",
        "short_codename": "xuan_lap",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Xuân Minh",
        "code": "xa_xuan_minh",
        "division_type": "xã",
        "short_codename": "xuan_minh",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Trường Xuân",
        "code": "xa_truong_xuan",
        "division_type": "xã",
        "short_codename": "truong_xuan",
        "parentCode": "huyen_tho_xuan"
    },
    {
        "name": "Xã Bát Mọt",
        "code": "xa_bat_mot",
        "division_type": "xã",
        "short_codename": "bat_mot",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Yên Nhân",
        "code": "xa_yen_nhan",
        "division_type": "xã",
        "short_codename": "yen_nhan",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Xuân Lẹ",
        "code": "xa_xuan_le",
        "division_type": "xã",
        "short_codename": "xuan_le",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Vạn Xuân",
        "code": "xa_van_xuan",
        "division_type": "xã",
        "short_codename": "van_xuan",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Lương Sơn",
        "code": "xa_luong_son",
        "division_type": "xã",
        "short_codename": "luong_son",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Xuân Cao",
        "code": "xa_xuan_cao",
        "division_type": "xã",
        "short_codename": "xuan_cao",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Luận Thành",
        "code": "xa_luan_thanh",
        "division_type": "xã",
        "short_codename": "luan_thanh",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Luận Khê",
        "code": "xa_luan_khe",
        "division_type": "xã",
        "short_codename": "luan_khe",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Xuân Thắng",
        "code": "xa_xuan_thang",
        "division_type": "xã",
        "short_codename": "xuan_thang",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Xuân Lộc",
        "code": "xa_xuan_loc",
        "division_type": "xã",
        "short_codename": "xuan_loc",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Thị trấn Thường Xuân",
        "code": "thi_tran_thuong_xuan",
        "division_type": "thị trấn",
        "short_codename": "thuong_xuan",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Xuân Dương",
        "code": "xa_xuan_duong",
        "division_type": "xã",
        "short_codename": "xuan_duong",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Thọ Thanh",
        "code": "xa_tho_thanh",
        "division_type": "xã",
        "short_codename": "tho_thanh",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Ngọc Phụng",
        "code": "xa_ngoc_phung",
        "division_type": "xã",
        "short_codename": "ngoc_phung",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Xuân Chinh",
        "code": "xa_xuan_chinh",
        "division_type": "xã",
        "short_codename": "xuan_chinh",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_thuong_xuan"
    },
    {
        "name": "Thị trấn Triệu Sơn",
        "code": "thi_tran_trieu_son",
        "division_type": "thị trấn",
        "short_codename": "trieu_son",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Sơn",
        "code": "xa_tho_son",
        "division_type": "xã",
        "short_codename": "tho_son",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Bình",
        "code": "xa_tho_binh",
        "division_type": "xã",
        "short_codename": "tho_binh",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Tiến",
        "code": "xa_tho_tien",
        "division_type": "xã",
        "short_codename": "tho_tien",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Hợp Lý",
        "code": "xa_hop_ly",
        "division_type": "xã",
        "short_codename": "hop_ly",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Hợp Tiến",
        "code": "xa_hop_tien",
        "division_type": "xã",
        "short_codename": "hop_tien",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Hợp Thành",
        "code": "xa_hop_thanh",
        "division_type": "xã",
        "short_codename": "hop_thanh",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Triệu Thành",
        "code": "xa_trieu_thanh",
        "division_type": "xã",
        "short_codename": "trieu_thanh",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Hợp Thắng",
        "code": "xa_hop_thang",
        "division_type": "xã",
        "short_codename": "hop_thang",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Minh Sơn",
        "code": "xa_minh_son",
        "division_type": "xã",
        "short_codename": "minh_son",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Dân Lực",
        "code": "xa_dan_luc",
        "division_type": "xã",
        "short_codename": "dan_luc",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Dân Lý",
        "code": "xa_dan_ly",
        "division_type": "xã",
        "short_codename": "dan_ly",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Dân Quyền",
        "code": "xa_dan_quyen",
        "division_type": "xã",
        "short_codename": "dan_quyen",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã An Nông",
        "code": "xa_an_nong",
        "division_type": "xã",
        "short_codename": "an_nong",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Văn Sơn",
        "code": "xa_van_son",
        "division_type": "xã",
        "short_codename": "van_son",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thái Hòa",
        "code": "xa_thai_hoa",
        "division_type": "xã",
        "short_codename": "thai_hoa",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Thị trấn Nưa",
        "code": "thi_tran_nua",
        "division_type": "thị trấn",
        "short_codename": "nua",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Đồng Lợi",
        "code": "xa_dong_loi",
        "division_type": "xã",
        "short_codename": "dong_loi",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Đồng Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Đồng Thắng",
        "code": "xa_dong_thang",
        "division_type": "xã",
        "short_codename": "dong_thang",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Tiến Nông",
        "code": "xa_tien_nong",
        "division_type": "xã",
        "short_codename": "tien_nong",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Khuyến Nông",
        "code": "xa_khuyen_nong",
        "division_type": "xã",
        "short_codename": "khuyen_nong",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Xuân Thịnh",
        "code": "xa_xuan_thinh",
        "division_type": "xã",
        "short_codename": "xuan_thinh",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Xuân Lộc",
        "code": "xa_xuan_loc",
        "division_type": "xã",
        "short_codename": "xuan_loc",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Dân",
        "code": "xa_tho_dan",
        "division_type": "xã",
        "short_codename": "tho_dan",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Xuân Thọ",
        "code": "xa_xuan_tho",
        "division_type": "xã",
        "short_codename": "xuan_tho",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Tân",
        "code": "xa_tho_tan",
        "division_type": "xã",
        "short_codename": "tho_tan",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Ngọc",
        "code": "xa_tho_ngoc",
        "division_type": "xã",
        "short_codename": "tho_ngoc",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Cường",
        "code": "xa_tho_cuong",
        "division_type": "xã",
        "short_codename": "tho_cuong",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Phú",
        "code": "xa_tho_phu",
        "division_type": "xã",
        "short_codename": "tho_phu",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Vực",
        "code": "xa_tho_vuc",
        "division_type": "xã",
        "short_codename": "tho_vuc",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Thọ Thế",
        "code": "xa_tho_the",
        "division_type": "xã",
        "short_codename": "tho_the",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Nông Trường",
        "code": "xa_nong_truong",
        "division_type": "xã",
        "short_codename": "nong_truong",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Xã Bình Sơn",
        "code": "xa_binh_son",
        "division_type": "xã",
        "short_codename": "binh_son",
        "parentCode": "huyen_trieu_son"
    },
    {
        "name": "Thị trấn Thiệu Hóa",
        "code": "thi_tran_thieu_hoa",
        "division_type": "thị trấn",
        "short_codename": "thieu_hoa",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Ngọc",
        "code": "xa_thieu_ngoc",
        "division_type": "xã",
        "short_codename": "thieu_ngoc",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Vũ",
        "code": "xa_thieu_vu",
        "division_type": "xã",
        "short_codename": "thieu_vu",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Phúc",
        "code": "xa_thieu_phuc",
        "division_type": "xã",
        "short_codename": "thieu_phuc",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Tiến",
        "code": "xa_thieu_tien",
        "division_type": "xã",
        "short_codename": "thieu_tien",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Công",
        "code": "xa_thieu_cong",
        "division_type": "xã",
        "short_codename": "thieu_cong",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Phú",
        "code": "xa_thieu_phu",
        "division_type": "xã",
        "short_codename": "thieu_phu",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Long",
        "code": "xa_thieu_long",
        "division_type": "xã",
        "short_codename": "thieu_long",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Giang",
        "code": "xa_thieu_giang",
        "division_type": "xã",
        "short_codename": "thieu_giang",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Duy",
        "code": "xa_thieu_duy",
        "division_type": "xã",
        "short_codename": "thieu_duy",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Nguyên",
        "code": "xa_thieu_nguyen",
        "division_type": "xã",
        "short_codename": "thieu_nguyen",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Hợp",
        "code": "xa_thieu_hop",
        "division_type": "xã",
        "short_codename": "thieu_hop",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Thịnh",
        "code": "xa_thieu_thinh",
        "division_type": "xã",
        "short_codename": "thieu_thinh",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Quang",
        "code": "xa_thieu_quang",
        "division_type": "xã",
        "short_codename": "thieu_quang",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Thành",
        "code": "xa_thieu_thanh",
        "division_type": "xã",
        "short_codename": "thieu_thanh",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Toán",
        "code": "xa_thieu_toan",
        "division_type": "xã",
        "short_codename": "thieu_toan",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Chính",
        "code": "xa_thieu_chinh",
        "division_type": "xã",
        "short_codename": "thieu_chinh",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Hòa",
        "code": "xa_thieu_hoa",
        "division_type": "xã",
        "short_codename": "xa_thieu_hoa",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Minh Tâm",
        "code": "xa_minh_tam",
        "division_type": "xã",
        "short_codename": "minh_tam",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Viên",
        "code": "xa_thieu_vien",
        "division_type": "xã",
        "short_codename": "thieu_vien",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Lý",
        "code": "xa_thieu_ly",
        "division_type": "xã",
        "short_codename": "thieu_ly",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Vận",
        "code": "xa_thieu_van",
        "division_type": "xã",
        "short_codename": "thieu_van",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Trung",
        "code": "xa_thieu_trung",
        "division_type": "xã",
        "short_codename": "thieu_trung",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Tân Châu",
        "code": "xa_tan_chau",
        "division_type": "xã",
        "short_codename": "tan_chau",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Xã Thiệu Giao",
        "code": "xa_thieu_giao",
        "division_type": "xã",
        "short_codename": "thieu_giao",
        "parentCode": "huyen_thieu_hoa"
    },
    {
        "name": "Thị trấn Bút Sơn",
        "code": "thi_tran_but_son",
        "division_type": "thị trấn",
        "short_codename": "but_son",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Giang",
        "code": "xa_hoang_giang",
        "division_type": "xã",
        "short_codename": "hoang_giang",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Xuân",
        "code": "xa_hoang_xuan",
        "division_type": "xã",
        "short_codename": "hoang_xuan",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Phượng",
        "code": "xa_hoang_phuong",
        "division_type": "xã",
        "short_codename": "hoang_phuong",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Phú",
        "code": "xa_hoang_phu",
        "division_type": "xã",
        "short_codename": "xa_hoang_phu",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Quỳ",
        "code": "xa_hoang_quy",
        "division_type": "xã",
        "short_codename": "xa_hoang_quy",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Kim",
        "code": "xa_hoang_kim",
        "division_type": "xã",
        "short_codename": "hoang_kim",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Trung",
        "code": "xa_hoang_trung",
        "division_type": "xã",
        "short_codename": "hoang_trung",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Trinh",
        "code": "xa_hoang_trinh",
        "division_type": "xã",
        "short_codename": "hoang_trinh",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Sơn",
        "code": "xa_hoang_son",
        "division_type": "xã",
        "short_codename": "hoang_son",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Cát",
        "code": "xa_hoang_cat",
        "division_type": "xã",
        "short_codename": "hoang_cat",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Xuyên",
        "code": "xa_hoang_xuyen",
        "division_type": "xã",
        "short_codename": "hoang_xuyen",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Quý",
        "code": "xa_hoang_quy",
        "division_type": "xã",
        "short_codename": "xa_hoang_quy",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Hợp",
        "code": "xa_hoang_hop",
        "division_type": "xã",
        "short_codename": "hoang_hop",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Đức",
        "code": "xa_hoang_duc",
        "division_type": "xã",
        "short_codename": "hoang_duc",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Hà",
        "code": "xa_hoang_ha",
        "division_type": "xã",
        "short_codename": "hoang_ha",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Đạt",
        "code": "xa_hoang_dat",
        "division_type": "xã",
        "short_codename": "hoang_dat",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Đạo",
        "code": "xa_hoang_dao",
        "division_type": "xã",
        "short_codename": "hoang_dao",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Thắng",
        "code": "xa_hoang_thang",
        "division_type": "xã",
        "short_codename": "hoang_thang",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Đồng",
        "code": "xa_hoang_dong",
        "division_type": "xã",
        "short_codename": "xa_hoang_dong",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Thái",
        "code": "xa_hoang_thai",
        "division_type": "xã",
        "short_codename": "hoang_thai",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Thịnh",
        "code": "xa_hoang_thinh",
        "division_type": "xã",
        "short_codename": "hoang_thinh",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Thành",
        "code": "xa_hoang_thanh",
        "division_type": "xã",
        "short_codename": "xa_hoang_thanh",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Lộc",
        "code": "xa_hoang_loc",
        "division_type": "xã",
        "short_codename": "hoang_loc",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Trạch",
        "code": "xa_hoang_trach",
        "division_type": "xã",
        "short_codename": "hoang_trach",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Phong",
        "code": "xa_hoang_phong",
        "division_type": "xã",
        "short_codename": "hoang_phong",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Lưu",
        "code": "xa_hoang_luu",
        "division_type": "xã",
        "short_codename": "hoang_luu",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Châu",
        "code": "xa_hoang_chau",
        "division_type": "xã",
        "short_codename": "hoang_chau",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Tân",
        "code": "xa_hoang_tan",
        "division_type": "xã",
        "short_codename": "hoang_tan",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Yến",
        "code": "xa_hoang_yen",
        "division_type": "xã",
        "short_codename": "hoang_yen",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Tiến",
        "code": "xa_hoang_tien",
        "division_type": "xã",
        "short_codename": "hoang_tien",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Hải",
        "code": "xa_hoang_hai",
        "division_type": "xã",
        "short_codename": "hoang_hai",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Ngọc",
        "code": "xa_hoang_ngoc",
        "division_type": "xã",
        "short_codename": "hoang_ngoc",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Đông",
        "code": "xa_hoang_dong",
        "division_type": "xã",
        "short_codename": "xa_hoang_dong",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Thanh",
        "code": "xa_hoang_thanh",
        "division_type": "xã",
        "short_codename": "xa_hoang_thanh",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Phụ",
        "code": "xa_hoang_phu",
        "division_type": "xã",
        "short_codename": "xa_hoang_phu",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Xã Hoằng Trường",
        "code": "xa_hoang_truong",
        "division_type": "xã",
        "short_codename": "hoang_truong",
        "parentCode": "huyen_hoang_hoa"
    },
    {
        "name": "Thị trấn Hậu Lộc",
        "code": "thi_tran_hau_loc",
        "division_type": "thị trấn",
        "short_codename": "hau_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Đồng Lộc",
        "code": "xa_dong_loc",
        "division_type": "xã",
        "short_codename": "dong_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Đại Lộc",
        "code": "xa_dai_loc",
        "division_type": "xã",
        "short_codename": "dai_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Triệu Lộc",
        "code": "xa_trieu_loc",
        "division_type": "xã",
        "short_codename": "trieu_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Tiến Lộc",
        "code": "xa_tien_loc",
        "division_type": "xã",
        "short_codename": "tien_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Lộc Sơn",
        "code": "xa_loc_son",
        "division_type": "xã",
        "short_codename": "loc_son",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Cầu Lộc",
        "code": "xa_cau_loc",
        "division_type": "xã",
        "short_codename": "cau_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Thành Lộc",
        "code": "xa_thanh_loc",
        "division_type": "xã",
        "short_codename": "thanh_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Tuy Lộc",
        "code": "xa_tuy_loc",
        "division_type": "xã",
        "short_codename": "tuy_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Phong Lộc",
        "code": "xa_phong_loc",
        "division_type": "xã",
        "short_codename": "phong_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Mỹ Lộc",
        "code": "xa_my_loc",
        "division_type": "xã",
        "short_codename": "my_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Thuần Lộc",
        "code": "xa_thuan_loc",
        "division_type": "xã",
        "short_codename": "thuan_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Xuân Lộc",
        "code": "xa_xuan_loc",
        "division_type": "xã",
        "short_codename": "xuan_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Hoa Lộc",
        "code": "xa_hoa_loc",
        "division_type": "xã",
        "short_codename": "xa_hoa_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Liên Lộc",
        "code": "xa_lien_loc",
        "division_type": "xã",
        "short_codename": "lien_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Quang Lộc",
        "code": "xa_quang_loc",
        "division_type": "xã",
        "short_codename": "quang_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Phú Lộc",
        "code": "xa_phu_loc",
        "division_type": "xã",
        "short_codename": "phu_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Hòa Lộc",
        "code": "xa_hoa_loc",
        "division_type": "xã",
        "short_codename": "xa_hoa_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Minh Lộc",
        "code": "xa_minh_loc",
        "division_type": "xã",
        "short_codename": "minh_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Hưng Lộc",
        "code": "xa_hung_loc",
        "division_type": "xã",
        "short_codename": "hung_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Hải Lộc",
        "code": "xa_hai_loc",
        "division_type": "xã",
        "short_codename": "hai_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Đa Lộc",
        "code": "xa_da_loc",
        "division_type": "xã",
        "short_codename": "da_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Xã Ngư Lộc",
        "code": "xa_ngu_loc",
        "division_type": "xã",
        "short_codename": "ngu_loc",
        "parentCode": "huyen_hau_loc"
    },
    {
        "name": "Thị trấn Nga Sơn",
        "code": "thi_tran_nga_son",
        "division_type": "thị trấn",
        "short_codename": "nga_son",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Ba Đình",
        "code": "xa_ba_dinh",
        "division_type": "xã",
        "short_codename": "ba_dinh",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Vịnh",
        "code": "xa_nga_vinh",
        "division_type": "xã",
        "short_codename": "nga_vinh",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Văn",
        "code": "xa_nga_van",
        "division_type": "xã",
        "short_codename": "nga_van",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Thiện",
        "code": "xa_nga_thien",
        "division_type": "xã",
        "short_codename": "nga_thien",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Tiến",
        "code": "xa_nga_tien",
        "division_type": "xã",
        "short_codename": "nga_tien",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Phượng",
        "code": "xa_nga_phuong",
        "division_type": "xã",
        "short_codename": "nga_phuong",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Trung",
        "code": "xa_nga_trung",
        "division_type": "xã",
        "short_codename": "nga_trung",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Bạch",
        "code": "xa_nga_bach",
        "division_type": "xã",
        "short_codename": "nga_bach",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Thanh",
        "code": "xa_nga_thanh",
        "division_type": "xã",
        "short_codename": "xa_nga_thanh",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Yên",
        "code": "xa_nga_yen",
        "division_type": "xã",
        "short_codename": "nga_yen",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Giáp",
        "code": "xa_nga_giap",
        "division_type": "xã",
        "short_codename": "nga_giap",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Hải",
        "code": "xa_nga_hai",
        "division_type": "xã",
        "short_codename": "nga_hai",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Thành",
        "code": "xa_nga_thanh",
        "division_type": "xã",
        "short_codename": "xa_nga_thanh",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga An",
        "code": "xa_nga_an",
        "division_type": "xã",
        "short_codename": "nga_an",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Phú",
        "code": "xa_nga_phu",
        "division_type": "xã",
        "short_codename": "nga_phu",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Điền",
        "code": "xa_nga_dien",
        "division_type": "xã",
        "short_codename": "nga_dien",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Tân",
        "code": "xa_nga_tan",
        "division_type": "xã",
        "short_codename": "nga_tan",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Thủy",
        "code": "xa_nga_thuy",
        "division_type": "xã",
        "short_codename": "nga_thuy",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Liên",
        "code": "xa_nga_lien",
        "division_type": "xã",
        "short_codename": "nga_lien",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Thái",
        "code": "xa_nga_thai",
        "division_type": "xã",
        "short_codename": "nga_thai",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Thạch",
        "code": "xa_nga_thach",
        "division_type": "xã",
        "short_codename": "nga_thach",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Thắng",
        "code": "xa_nga_thang",
        "division_type": "xã",
        "short_codename": "nga_thang",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Xã Nga Trường",
        "code": "xa_nga_truong",
        "division_type": "xã",
        "short_codename": "nga_truong",
        "parentCode": "huyen_nga_son"
    },
    {
        "name": "Thị trấn Yên Cát",
        "code": "thi_tran_yen_cat",
        "division_type": "thị trấn",
        "short_codename": "yen_cat",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Bãi Trành",
        "code": "xa_bai_tranh",
        "division_type": "xã",
        "short_codename": "bai_tranh",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Xuân Hòa",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Xuân Bình",
        "code": "xa_xuan_binh",
        "division_type": "xã",
        "short_codename": "xuan_binh",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Hóa Quỳ",
        "code": "xa_hoa_quy",
        "division_type": "xã",
        "short_codename": "hoa_quy",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Cát Vân",
        "code": "xa_cat_van",
        "division_type": "xã",
        "short_codename": "cat_van",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Cát Tân",
        "code": "xa_cat_tan",
        "division_type": "xã",
        "short_codename": "cat_tan",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Bình Lương",
        "code": "xa_binh_luong",
        "division_type": "xã",
        "short_codename": "binh_luong",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Thanh Quân",
        "code": "xa_thanh_quan",
        "division_type": "xã",
        "short_codename": "thanh_quan",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Thanh Xuân",
        "code": "xa_thanh_xuan",
        "division_type": "xã",
        "short_codename": "thanh_xuan",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Thanh Hòa",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Thanh Phong",
        "code": "xa_thanh_phong",
        "division_type": "xã",
        "short_codename": "thanh_phong",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Thanh Lâm",
        "code": "xa_thanh_lam",
        "division_type": "xã",
        "short_codename": "thanh_lam",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Xã Thượng Ninh",
        "code": "xa_thuong_ninh",
        "division_type": "xã",
        "short_codename": "thuong_ninh",
        "parentCode": "huyen_nhu_xuan"
    },
    {
        "name": "Thị trấn Bến Sung",
        "code": "thi_tran_ben_sung",
        "division_type": "thị trấn",
        "short_codename": "ben_sung",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Cán Khê",
        "code": "xa_can_khe",
        "division_type": "xã",
        "short_codename": "can_khe",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Xuân Du",
        "code": "xa_xuan_du",
        "division_type": "xã",
        "short_codename": "xuan_du",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Phượng Nghi",
        "code": "xa_phuong_nghi",
        "division_type": "xã",
        "short_codename": "phuong_nghi",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Mậu Lâm",
        "code": "xa_mau_lam",
        "division_type": "xã",
        "short_codename": "mau_lam",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Xuân Khang",
        "code": "xa_xuan_khang",
        "division_type": "xã",
        "short_codename": "xuan_khang",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Phú Nhuận",
        "code": "xa_phu_nhuan",
        "division_type": "xã",
        "short_codename": "phu_nhuan",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Hải Long",
        "code": "xa_hai_long",
        "division_type": "xã",
        "short_codename": "hai_long",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Xuân Thái",
        "code": "xa_xuan_thai",
        "division_type": "xã",
        "short_codename": "xuan_thai",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Xuân Phúc",
        "code": "xa_xuan_phuc",
        "division_type": "xã",
        "short_codename": "xuan_phuc",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Yên Thọ",
        "code": "xa_yen_tho",
        "division_type": "xã",
        "short_codename": "yen_tho",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Yên Lạc",
        "code": "xa_yen_lac",
        "division_type": "xã",
        "short_codename": "yen_lac",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Thanh Tân",
        "code": "xa_thanh_tan",
        "division_type": "xã",
        "short_codename": "thanh_tan",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Xã Thanh Kỳ",
        "code": "xa_thanh_ky",
        "division_type": "xã",
        "short_codename": "thanh_ky",
        "parentCode": "huyen_nhu_thanh"
    },
    {
        "name": "Thị trấn Nông Cống",
        "code": "thi_tran_nong_cong",
        "division_type": "thị trấn",
        "short_codename": "nong_cong",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Tân Phúc",
        "code": "xa_tan_phuc",
        "division_type": "xã",
        "short_codename": "tan_phuc",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Tân Thọ",
        "code": "xa_tan_tho",
        "division_type": "xã",
        "short_codename": "tan_tho",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Hoàng Sơn",
        "code": "xa_hoang_son",
        "division_type": "xã",
        "short_codename": "hoang_son",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Tân Khang",
        "code": "xa_tan_khang",
        "division_type": "xã",
        "short_codename": "tan_khang",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Hoàng Giang",
        "code": "xa_hoang_giang",
        "division_type": "xã",
        "short_codename": "hoang_giang",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Trung Chính",
        "code": "xa_trung_chinh",
        "division_type": "xã",
        "short_codename": "trung_chinh",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Trung Thành",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Tế Thắng",
        "code": "xa_te_thang",
        "division_type": "xã",
        "short_codename": "te_thang",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Tế Lợi",
        "code": "xa_te_loi",
        "division_type": "xã",
        "short_codename": "te_loi",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Tế Nông",
        "code": "xa_te_nong",
        "division_type": "xã",
        "short_codename": "te_nong",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Minh Nghĩa",
        "code": "xa_minh_nghia",
        "division_type": "xã",
        "short_codename": "minh_nghia",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Minh Khôi",
        "code": "xa_minh_khoi",
        "division_type": "xã",
        "short_codename": "minh_khoi",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Vạn Hòa",
        "code": "xa_van_hoa",
        "division_type": "xã",
        "short_codename": "van_hoa",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Trường Trung",
        "code": "xa_truong_trung",
        "division_type": "xã",
        "short_codename": "truong_trung",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Vạn Thắng",
        "code": "xa_van_thang",
        "division_type": "xã",
        "short_codename": "van_thang",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Trường Giang",
        "code": "xa_truong_giang",
        "division_type": "xã",
        "short_codename": "truong_giang",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Vạn Thiện",
        "code": "xa_van_thien",
        "division_type": "xã",
        "short_codename": "van_thien",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Thăng Long",
        "code": "xa_thang_long",
        "division_type": "xã",
        "short_codename": "thang_long",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Trường Minh",
        "code": "xa_truong_minh",
        "division_type": "xã",
        "short_codename": "truong_minh",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Trường Sơn",
        "code": "xa_truong_son",
        "division_type": "xã",
        "short_codename": "truong_son",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Thăng Bình",
        "code": "xa_thang_binh",
        "division_type": "xã",
        "short_codename": "thang_binh",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Công Liêm",
        "code": "xa_cong_liem",
        "division_type": "xã",
        "short_codename": "cong_liem",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Tượng Văn",
        "code": "xa_tuong_van",
        "division_type": "xã",
        "short_codename": "tuong_van",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Thăng Thọ",
        "code": "xa_thang_tho",
        "division_type": "xã",
        "short_codename": "thang_tho",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Tượng Lĩnh",
        "code": "xa_tuong_linh",
        "division_type": "xã",
        "short_codename": "tuong_linh",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Tượng Sơn",
        "code": "xa_tuong_son",
        "division_type": "xã",
        "short_codename": "tuong_son",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Công Chính",
        "code": "xa_cong_chinh",
        "division_type": "xã",
        "short_codename": "cong_chinh",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Xã Yên Mỹ",
        "code": "xa_yen_my",
        "division_type": "xã",
        "short_codename": "yen_my",
        "parentCode": "huyen_nong_cong"
    },
    {
        "name": "Thị trấn Rừng Thông",
        "code": "thi_tran_rung_thong",
        "division_type": "thị trấn",
        "short_codename": "rung_thong",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Hoàng",
        "code": "xa_dong_hoang",
        "division_type": "xã",
        "short_codename": "dong_hoang",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Ninh",
        "code": "xa_dong_ninh",
        "division_type": "xã",
        "short_codename": "dong_ninh",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Hòa",
        "code": "xa_dong_hoa",
        "division_type": "xã",
        "short_codename": "dong_hoa",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Yên",
        "code": "xa_dong_yen",
        "division_type": "xã",
        "short_codename": "dong_yen",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Minh",
        "code": "xa_dong_minh",
        "division_type": "xã",
        "short_codename": "dong_minh",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Thanh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Khê",
        "code": "xa_dong_khe",
        "division_type": "xã",
        "short_codename": "dong_khe",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Thịnh",
        "code": "xa_dong_thinh",
        "division_type": "xã",
        "short_codename": "dong_thinh",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Văn",
        "code": "xa_dong_van",
        "division_type": "xã",
        "short_codename": "dong_van",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Phú",
        "code": "xa_dong_phu",
        "division_type": "xã",
        "short_codename": "dong_phu",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Nam",
        "code": "xa_dong_nam",
        "division_type": "xã",
        "short_codename": "dong_nam",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Xã Đông Quang",
        "code": "xa_dong_quang",
        "division_type": "xã",
        "short_codename": "dong_quang",
        "parentCode": "huyen_dong_son"
    },
    {
        "name": "Thị trấn Tân Phong",
        "code": "thi_tran_tan_phong",
        "division_type": "thị trấn",
        "short_codename": "tan_phong",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Trạch",
        "code": "xa_quang_trach",
        "division_type": "xã",
        "short_codename": "quang_trach",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Đức",
        "code": "xa_quang_duc",
        "division_type": "xã",
        "short_codename": "quang_duc",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Định",
        "code": "xa_quang_dinh",
        "division_type": "xã",
        "short_codename": "quang_dinh",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Nhân",
        "code": "xa_quang_nhan",
        "division_type": "xã",
        "short_codename": "quang_nhan",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Ninh",
        "code": "xa_quang_ninh",
        "division_type": "xã",
        "short_codename": "quang_ninh",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Bình",
        "code": "xa_quang_binh",
        "division_type": "xã",
        "short_codename": "quang_binh",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Hợp",
        "code": "xa_quang_hop",
        "division_type": "xã",
        "short_codename": "quang_hop",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Văn",
        "code": "xa_quang_van",
        "division_type": "xã",
        "short_codename": "quang_van",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Long",
        "code": "xa_quang_long",
        "division_type": "xã",
        "short_codename": "quang_long",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Yên",
        "code": "xa_quang_yen",
        "division_type": "xã",
        "short_codename": "quang_yen",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Hòa",
        "code": "xa_quang_hoa",
        "division_type": "xã",
        "short_codename": "quang_hoa",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Khê",
        "code": "xa_quang_khe",
        "division_type": "xã",
        "short_codename": "quang_khe",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Chính",
        "code": "xa_quang_chinh",
        "division_type": "xã",
        "short_codename": "quang_chinh",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Ngọc",
        "code": "xa_quang_ngoc",
        "division_type": "xã",
        "short_codename": "quang_ngoc",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Trường",
        "code": "xa_quang_truong",
        "division_type": "xã",
        "short_codename": "quang_truong",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Phúc",
        "code": "xa_quang_phuc",
        "division_type": "xã",
        "short_codename": "quang_phuc",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Giao",
        "code": "xa_quang_giao",
        "division_type": "xã",
        "short_codename": "quang_giao",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Hải",
        "code": "xa_quang_hai",
        "division_type": "xã",
        "short_codename": "quang_hai",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Lưu",
        "code": "xa_quang_luu",
        "division_type": "xã",
        "short_codename": "quang_luu",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Lộc",
        "code": "xa_quang_loc",
        "division_type": "xã",
        "short_codename": "quang_loc",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Tiên Trang",
        "code": "xa_tien_trang",
        "division_type": "xã",
        "short_codename": "tien_trang",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Nham",
        "code": "xa_quang_nham",
        "division_type": "xã",
        "short_codename": "quang_nham",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Thạch",
        "code": "xa_quang_thach",
        "division_type": "xã",
        "short_codename": "quang_thach",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Xã Quảng Thái",
        "code": "xa_quang_thai",
        "division_type": "xã",
        "short_codename": "quang_thai",
        "parentCode": "huyen_quang_xuong"
    },
    {
        "name": "Phường Hải Hòa",
        "code": "phuong_hai_hoa",
        "division_type": "phường",
        "short_codename": "hai_hoa",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Hải Châu",
        "code": "phuong_hai_chau",
        "division_type": "phường",
        "short_codename": "hai_chau",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Thanh Thủy",
        "code": "xa_thanh_thuy",
        "division_type": "xã",
        "short_codename": "thanh_thuy",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Hải Ninh",
        "code": "phuong_hai_ninh",
        "division_type": "phường",
        "short_codename": "hai_ninh",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Anh Sơn",
        "code": "xa_anh_son",
        "division_type": "xã",
        "short_codename": "anh_son",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Ngọc Lĩnh",
        "code": "xa_ngoc_linh",
        "division_type": "xã",
        "short_codename": "ngoc_linh",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Hải An",
        "code": "phuong_hai_an",
        "division_type": "phường",
        "short_codename": "hai_an",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Các Sơn",
        "code": "xa_cac_son",
        "division_type": "xã",
        "short_codename": "cac_son",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Tân Dân",
        "code": "phuong_tan_dan",
        "division_type": "phường",
        "short_codename": "tan_dan",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Hải Lĩnh",
        "code": "phuong_hai_linh",
        "division_type": "phường",
        "short_codename": "hai_linh",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Định Hải",
        "code": "xa_dinh_hai",
        "division_type": "xã",
        "short_codename": "dinh_hai",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Ninh Hải",
        "code": "phuong_ninh_hai",
        "division_type": "phường",
        "short_codename": "ninh_hai",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Nguyên Bình",
        "code": "phuong_nguyen_binh",
        "division_type": "phường",
        "short_codename": "nguyen_binh",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Hải Nhân",
        "code": "xa_hai_nhan",
        "division_type": "xã",
        "short_codename": "hai_nhan",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Bình Minh",
        "code": "phuong_binh_minh",
        "division_type": "phường",
        "short_codename": "binh_minh",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Hải Thanh",
        "code": "phuong_hai_thanh",
        "division_type": "phường",
        "short_codename": "hai_thanh",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Phú Lâm",
        "code": "xa_phu_lam",
        "division_type": "xã",
        "short_codename": "phu_lam",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Xuân Lâm",
        "code": "phuong_xuan_lam",
        "division_type": "phường",
        "short_codename": "xuan_lam",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Trúc Lâm",
        "code": "phuong_truc_lam",
        "division_type": "phường",
        "short_codename": "truc_lam",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Hải Bình",
        "code": "phuong_hai_binh",
        "division_type": "phường",
        "short_codename": "hai_binh",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Tân Trường",
        "code": "xa_tan_truong",
        "division_type": "xã",
        "short_codename": "tan_truong",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Tùng Lâm",
        "code": "xa_tung_lam",
        "division_type": "xã",
        "short_codename": "tung_lam",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Tĩnh Hải",
        "code": "phuong_tinh_hai",
        "division_type": "phường",
        "short_codename": "tinh_hai",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Mai Lâm",
        "code": "phuong_mai_lam",
        "division_type": "phường",
        "short_codename": "mai_lam",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Trường Lâm",
        "code": "xa_truong_lam",
        "division_type": "xã",
        "short_codename": "truong_lam",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Hải Yến",
        "code": "xa_hai_yen",
        "division_type": "xã",
        "short_codename": "hai_yen",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Hải Thượng",
        "code": "phuong_hai_thuong",
        "division_type": "phường",
        "short_codename": "hai_thuong",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Nghi Sơn",
        "code": "xa_nghi_son",
        "division_type": "xã",
        "short_codename": "nghi_son",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Xã Hải Hà",
        "code": "xa_hai_ha",
        "division_type": "xã",
        "short_codename": "hai_ha",
        "parentCode": "thi_xa_nghi_son"
    },
    {
        "name": "Phường Đông Vĩnh",
        "code": "phuong_dong_vinh",
        "division_type": "phường",
        "short_codename": "dong_vinh",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Hà Huy Tập",
        "code": "phuong_ha_huy_tap",
        "division_type": "phường",
        "short_codename": "ha_huy_tap",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Lê Lợi",
        "code": "phuong_le_loi",
        "division_type": "phường",
        "short_codename": "le_loi",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Quán Bàu",
        "code": "phuong_quan_bau",
        "division_type": "phường",
        "short_codename": "quan_bau",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Hưng Bình",
        "code": "phuong_hung_binh",
        "division_type": "phường",
        "short_codename": "hung_binh",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Hưng Phúc",
        "code": "phuong_hung_phuc",
        "division_type": "phường",
        "short_codename": "hung_phuc",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Hưng Dũng",
        "code": "phuong_hung_dung",
        "division_type": "phường",
        "short_codename": "hung_dung",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Cửa Nam",
        "code": "phuong_cua_nam",
        "division_type": "phường",
        "short_codename": "cua_nam",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Đội Cung",
        "code": "phuong_doi_cung",
        "division_type": "phường",
        "short_codename": "doi_cung",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Lê Mao",
        "code": "phuong_le_mao",
        "division_type": "phường",
        "short_codename": "le_mao",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Trường Thi",
        "code": "phuong_truong_thi",
        "division_type": "phường",
        "short_codename": "truong_thi",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Bến Thủy",
        "code": "phuong_ben_thuy",
        "division_type": "phường",
        "short_codename": "ben_thuy",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Hồng Sơn",
        "code": "phuong_hong_son",
        "division_type": "phường",
        "short_codename": "hong_son",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Trung Đô",
        "code": "phuong_trung_do",
        "division_type": "phường",
        "short_codename": "trung_do",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Xã Nghi Phú",
        "code": "xa_nghi_phu",
        "division_type": "xã",
        "short_codename": "nghi_phu",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Xã Hưng Đông",
        "code": "xa_hung_dong",
        "division_type": "xã",
        "short_codename": "hung_dong",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Xã Hưng Lộc",
        "code": "xa_hung_loc",
        "division_type": "xã",
        "short_codename": "hung_loc",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Xã Hưng Hòa",
        "code": "xa_hung_hoa",
        "division_type": "xã",
        "short_codename": "hung_hoa",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Vinh Tân",
        "code": "phuong_vinh_tan",
        "division_type": "phường",
        "short_codename": "vinh_tan",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Xã Nghi Liên",
        "code": "xa_nghi_lien",
        "division_type": "xã",
        "short_codename": "nghi_lien",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Xã Nghi Ân",
        "code": "xa_nghi_an",
        "division_type": "xã",
        "short_codename": "nghi_an",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Xã Nghi Kim",
        "code": "xa_nghi_kim",
        "division_type": "xã",
        "short_codename": "nghi_kim",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Xã Nghi Đức",
        "code": "xa_nghi_duc",
        "division_type": "xã",
        "short_codename": "nghi_duc",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Xã Hưng Chính",
        "code": "xa_hung_chinh",
        "division_type": "xã",
        "short_codename": "hung_chinh",
        "parentCode": "thanh_pho_vinh"
    },
    {
        "name": "Phường Nghi Thuỷ",
        "code": "phuong_nghi_thuy",
        "division_type": "phường",
        "short_codename": "nghi_thuy",
        "parentCode": "thi_xa_cua_lo"
    },
    {
        "name": "Phường Nghi Tân",
        "code": "phuong_nghi_tan",
        "division_type": "phường",
        "short_codename": "nghi_tan",
        "parentCode": "thi_xa_cua_lo"
    },
    {
        "name": "Phường Thu Thuỷ",
        "code": "phuong_thu_thuy",
        "division_type": "phường",
        "short_codename": "thu_thuy",
        "parentCode": "thi_xa_cua_lo"
    },
    {
        "name": "Phường Nghi Hòa",
        "code": "phuong_nghi_hoa",
        "division_type": "phường",
        "short_codename": "nghi_hoa",
        "parentCode": "thi_xa_cua_lo"
    },
    {
        "name": "Phường Nghi Hải",
        "code": "phuong_nghi_hai",
        "division_type": "phường",
        "short_codename": "nghi_hai",
        "parentCode": "thi_xa_cua_lo"
    },
    {
        "name": "Phường Nghi Hương",
        "code": "phuong_nghi_huong",
        "division_type": "phường",
        "short_codename": "nghi_huong",
        "parentCode": "thi_xa_cua_lo"
    },
    {
        "name": "Phường Nghi Thu",
        "code": "phuong_nghi_thu",
        "division_type": "phường",
        "short_codename": "nghi_thu",
        "parentCode": "thi_xa_cua_lo"
    },
    {
        "name": "Phường Hoà Hiếu",
        "code": "phuong_hoa_hieu",
        "division_type": "phường",
        "short_codename": "hoa_hieu",
        "parentCode": "thi_xa_thai_hoa"
    },
    {
        "name": "Phường Quang Phong",
        "code": "phuong_quang_phong",
        "division_type": "phường",
        "short_codename": "quang_phong",
        "parentCode": "thi_xa_thai_hoa"
    },
    {
        "name": "Phường Quang Tiến",
        "code": "phuong_quang_tien",
        "division_type": "phường",
        "short_codename": "quang_tien",
        "parentCode": "thi_xa_thai_hoa"
    },
    {
        "name": "Phường Long Sơn",
        "code": "phuong_long_son",
        "division_type": "phường",
        "short_codename": "long_son",
        "parentCode": "thi_xa_thai_hoa"
    },
    {
        "name": "Xã Nghĩa Tiến",
        "code": "xa_nghia_tien",
        "division_type": "xã",
        "short_codename": "nghia_tien",
        "parentCode": "thi_xa_thai_hoa"
    },
    {
        "name": "Xã Nghĩa Mỹ",
        "code": "xa_nghia_my",
        "division_type": "xã",
        "short_codename": "nghia_my",
        "parentCode": "thi_xa_thai_hoa"
    },
    {
        "name": "Xã Tây Hiếu",
        "code": "xa_tay_hieu",
        "division_type": "xã",
        "short_codename": "tay_hieu",
        "parentCode": "thi_xa_thai_hoa"
    },
    {
        "name": "Xã Nghĩa Thuận",
        "code": "xa_nghia_thuan",
        "division_type": "xã",
        "short_codename": "nghia_thuan",
        "parentCode": "thi_xa_thai_hoa"
    },
    {
        "name": "Xã Đông Hiếu",
        "code": "xa_dong_hieu",
        "division_type": "xã",
        "short_codename": "dong_hieu",
        "parentCode": "thi_xa_thai_hoa"
    },
    {
        "name": "Thị trấn Kim Sơn",
        "code": "thi_tran_kim_son",
        "division_type": "thị trấn",
        "short_codename": "kim_son",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Thông Thụ",
        "code": "xa_thong_thu",
        "division_type": "xã",
        "short_codename": "thong_thu",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Đồng Văn",
        "code": "xa_dong_van",
        "division_type": "xã",
        "short_codename": "dong_van",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Hạnh Dịch",
        "code": "xa_hanh_dich",
        "division_type": "xã",
        "short_codename": "hanh_dich",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Tiền Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Nậm Giải",
        "code": "xa_nam_giai",
        "division_type": "xã",
        "short_codename": "nam_giai",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Tri Lễ",
        "code": "xa_tri_le",
        "division_type": "xã",
        "short_codename": "tri_le",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Châu Kim",
        "code": "xa_chau_kim",
        "division_type": "xã",
        "short_codename": "chau_kim",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Mường Nọc",
        "code": "xa_muong_noc",
        "division_type": "xã",
        "short_codename": "muong_noc",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Châu Thôn",
        "code": "xa_chau_thon",
        "division_type": "xã",
        "short_codename": "chau_thon",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Nậm Nhoóng",
        "code": "xa_nam_nhoong",
        "division_type": "xã",
        "short_codename": "nam_nhoong",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Quang Phong",
        "code": "xa_quang_phong",
        "division_type": "xã",
        "short_codename": "quang_phong",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Xã Căm Muộn",
        "code": "xa_cam_muon",
        "division_type": "xã",
        "short_codename": "cam_muon",
        "parentCode": "huyen_que_phong"
    },
    {
        "name": "Thị trấn Tân Lạc",
        "code": "thi_tran_tan_lac",
        "division_type": "thị trấn",
        "short_codename": "tan_lac",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Bính",
        "code": "xa_chau_binh",
        "division_type": "xã",
        "short_codename": "xa_chau_binh",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Thuận",
        "code": "xa_chau_thuan",
        "division_type": "xã",
        "short_codename": "chau_thuan",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Hội",
        "code": "xa_chau_hoi",
        "division_type": "xã",
        "short_codename": "chau_hoi",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Nga",
        "code": "xa_chau_nga",
        "division_type": "xã",
        "short_codename": "chau_nga",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Tiến",
        "code": "xa_chau_tien",
        "division_type": "xã",
        "short_codename": "chau_tien",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Hạnh",
        "code": "xa_chau_hanh",
        "division_type": "xã",
        "short_codename": "chau_hanh",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Thắng",
        "code": "xa_chau_thang",
        "division_type": "xã",
        "short_codename": "chau_thang",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Phong",
        "code": "xa_chau_phong",
        "division_type": "xã",
        "short_codename": "chau_phong",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Bình",
        "code": "xa_chau_binh",
        "division_type": "xã",
        "short_codename": "xa_chau_binh",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Châu Hoàn",
        "code": "xa_chau_hoan",
        "division_type": "xã",
        "short_codename": "chau_hoan",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Xã Diên Lãm",
        "code": "xa_dien_lam",
        "division_type": "xã",
        "short_codename": "dien_lam",
        "parentCode": "huyen_quy_chau"
    },
    {
        "name": "Thị trấn Mường Xén",
        "code": "thi_tran_muong_xen",
        "division_type": "thị trấn",
        "short_codename": "muong_xen",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Mỹ Lý",
        "code": "xa_my_ly",
        "division_type": "xã",
        "short_codename": "my_ly",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Bắc Lý",
        "code": "xa_bac_ly",
        "division_type": "xã",
        "short_codename": "bac_ly",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Keng Đu",
        "code": "xa_keng_du",
        "division_type": "xã",
        "short_codename": "keng_du",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Đoọc Mạy",
        "code": "xa_dooc_may",
        "division_type": "xã",
        "short_codename": "dooc_may",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Huồi Tụ",
        "code": "xa_huoi_tu",
        "division_type": "xã",
        "short_codename": "huoi_tu",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Mường Lống",
        "code": "xa_muong_long",
        "division_type": "xã",
        "short_codename": "muong_long",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Na Loi",
        "code": "xa_na_loi",
        "division_type": "xã",
        "short_codename": "na_loi",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Nậm Cắn",
        "code": "xa_nam_can",
        "division_type": "xã",
        "short_codename": "xa_nam_can",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Bảo Nam",
        "code": "xa_bao_nam",
        "division_type": "xã",
        "short_codename": "bao_nam",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Phà Đánh",
        "code": "xa_pha_danh",
        "division_type": "xã",
        "short_codename": "pha_danh",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Bảo Thắng",
        "code": "xa_bao_thang",
        "division_type": "xã",
        "short_codename": "bao_thang",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Hữu Lập",
        "code": "xa_huu_lap",
        "division_type": "xã",
        "short_codename": "huu_lap",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Tà Cạ",
        "code": "xa_ta_ca",
        "division_type": "xã",
        "short_codename": "ta_ca",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Chiêu Lưu",
        "code": "xa_chieu_luu",
        "division_type": "xã",
        "short_codename": "chieu_luu",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Mường Típ",
        "code": "xa_muong_tip",
        "division_type": "xã",
        "short_codename": "muong_tip",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Hữu Kiệm",
        "code": "xa_huu_kiem",
        "division_type": "xã",
        "short_codename": "huu_kiem",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Tây Sơn",
        "code": "xa_tay_son",
        "division_type": "xã",
        "short_codename": "tay_son",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Mường Ải",
        "code": "xa_muong_ai",
        "division_type": "xã",
        "short_codename": "muong_ai",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Na Ngoi",
        "code": "xa_na_ngoi",
        "division_type": "xã",
        "short_codename": "na_ngoi",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Xã Nậm Càn",
        "code": "xa_nam_can",
        "division_type": "xã",
        "short_codename": "xa_nam_can",
        "parentCode": "huyen_ky_son"
    },
    {
        "name": "Thị trấn Thạch Giám",
        "code": "thi_tran_thach_giam",
        "division_type": "thị trấn",
        "short_codename": "thach_giam",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Mai Sơn",
        "code": "xa_mai_son",
        "division_type": "xã",
        "short_codename": "mai_son",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Nhôn Mai",
        "code": "xa_nhon_mai",
        "division_type": "xã",
        "short_codename": "nhon_mai",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Hữu Khuông",
        "code": "xa_huu_khuong",
        "division_type": "xã",
        "short_codename": "huu_khuong",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Yên Tĩnh",
        "code": "xa_yen_tinh",
        "division_type": "xã",
        "short_codename": "yen_tinh",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Nga My",
        "code": "xa_nga_my",
        "division_type": "xã",
        "short_codename": "nga_my",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Xiêng My",
        "code": "xa_xieng_my",
        "division_type": "xã",
        "short_codename": "xieng_my",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Lưỡng Minh",
        "code": "xa_luong_minh",
        "division_type": "xã",
        "short_codename": "luong_minh",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Yên Hòa",
        "code": "xa_yen_hoa",
        "division_type": "xã",
        "short_codename": "yen_hoa",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Yên Na",
        "code": "xa_yen_na",
        "division_type": "xã",
        "short_codename": "yen_na",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Lưu Kiền",
        "code": "xa_luu_kien",
        "division_type": "xã",
        "short_codename": "luu_kien",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Xá Lượng",
        "code": "xa_xa_luong",
        "division_type": "xã",
        "short_codename": "xa_luong",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Tam Thái",
        "code": "xa_tam_thai",
        "division_type": "xã",
        "short_codename": "tam_thai",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Tam Đình",
        "code": "xa_tam_dinh",
        "division_type": "xã",
        "short_codename": "tam_dinh",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Yên Thắng",
        "code": "xa_yen_thang",
        "division_type": "xã",
        "short_codename": "yen_thang",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Tam Quang",
        "code": "xa_tam_quang",
        "division_type": "xã",
        "short_codename": "tam_quang",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Xã Tam Hợp",
        "code": "xa_tam_hop",
        "division_type": "xã",
        "short_codename": "tam_hop",
        "parentCode": "huyen_tuong_duong"
    },
    {
        "name": "Thị trấn Nghĩa Đàn",
        "code": "thi_tran_nghia_dan",
        "division_type": "thị trấn",
        "short_codename": "nghia_dan",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Mai",
        "code": "xa_nghia_mai",
        "division_type": "xã",
        "short_codename": "nghia_mai",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Yên",
        "code": "xa_nghia_yen",
        "division_type": "xã",
        "short_codename": "nghia_yen",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Lạc",
        "code": "xa_nghia_lac",
        "division_type": "xã",
        "short_codename": "nghia_lac",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Lâm",
        "code": "xa_nghia_lam",
        "division_type": "xã",
        "short_codename": "nghia_lam",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Sơn",
        "code": "xa_nghia_son",
        "division_type": "xã",
        "short_codename": "nghia_son",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Lợi",
        "code": "xa_nghia_loi",
        "division_type": "xã",
        "short_codename": "nghia_loi",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Bình",
        "code": "xa_nghia_binh",
        "division_type": "xã",
        "short_codename": "nghia_binh",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Thọ",
        "code": "xa_nghia_tho",
        "division_type": "xã",
        "short_codename": "nghia_tho",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Minh",
        "code": "xa_nghia_minh",
        "division_type": "xã",
        "short_codename": "nghia_minh",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Phú",
        "code": "xa_nghia_phu",
        "division_type": "xã",
        "short_codename": "nghia_phu",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Hưng",
        "code": "xa_nghia_hung",
        "division_type": "xã",
        "short_codename": "nghia_hung",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Hồng",
        "code": "xa_nghia_hong",
        "division_type": "xã",
        "short_codename": "nghia_hong",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Thịnh",
        "code": "xa_nghia_thinh",
        "division_type": "xã",
        "short_codename": "nghia_thinh",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Trung",
        "code": "xa_nghia_trung",
        "division_type": "xã",
        "short_codename": "nghia_trung",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Hội",
        "code": "xa_nghia_hoi",
        "division_type": "xã",
        "short_codename": "nghia_hoi",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Thành",
        "code": "xa_nghia_thanh",
        "division_type": "xã",
        "short_codename": "nghia_thanh",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Hiếu",
        "code": "xa_nghia_hieu",
        "division_type": "xã",
        "short_codename": "nghia_hieu",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Đức",
        "code": "xa_nghia_duc",
        "division_type": "xã",
        "short_codename": "nghia_duc",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa An",
        "code": "xa_nghia_an",
        "division_type": "xã",
        "short_codename": "nghia_an",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Long",
        "code": "xa_nghia_long",
        "division_type": "xã",
        "short_codename": "nghia_long",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Lộc",
        "code": "xa_nghia_loc",
        "division_type": "xã",
        "short_codename": "nghia_loc",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Xã Nghĩa Khánh",
        "code": "xa_nghia_khanh",
        "division_type": "xã",
        "short_codename": "nghia_khanh",
        "parentCode": "huyen_nghia_dan"
    },
    {
        "name": "Thị trấn Quỳ Hợp",
        "code": "thi_tran_quy_hop",
        "division_type": "thị trấn",
        "short_codename": "quy_hop",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Yên Hợp",
        "code": "xa_yen_hop",
        "division_type": "xã",
        "short_codename": "yen_hop",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Châu Tiến",
        "code": "xa_chau_tien",
        "division_type": "xã",
        "short_codename": "chau_tien",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Châu Hồng",
        "code": "xa_chau_hong",
        "division_type": "xã",
        "short_codename": "chau_hong",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Đồng Hợp",
        "code": "xa_dong_hop",
        "division_type": "xã",
        "short_codename": "dong_hop",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Châu Thành",
        "code": "xa_chau_thanh",
        "division_type": "xã",
        "short_codename": "chau_thanh",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Liên Hợp",
        "code": "xa_lien_hop",
        "division_type": "xã",
        "short_codename": "lien_hop",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Châu Lộc",
        "code": "xa_chau_loc",
        "division_type": "xã",
        "short_codename": "chau_loc",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Tam Hợp",
        "code": "xa_tam_hop",
        "division_type": "xã",
        "short_codename": "tam_hop",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Châu Cường",
        "code": "xa_chau_cuong",
        "division_type": "xã",
        "short_codename": "chau_cuong",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Châu Quang",
        "code": "xa_chau_quang",
        "division_type": "xã",
        "short_codename": "chau_quang",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Thọ Hợp",
        "code": "xa_tho_hop",
        "division_type": "xã",
        "short_codename": "tho_hop",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Minh Hợp",
        "code": "xa_minh_hop",
        "division_type": "xã",
        "short_codename": "minh_hop",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Nghĩa Xuân",
        "code": "xa_nghia_xuan",
        "division_type": "xã",
        "short_codename": "nghia_xuan",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Châu Thái",
        "code": "xa_chau_thai",
        "division_type": "xã",
        "short_codename": "chau_thai",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Châu Đình",
        "code": "xa_chau_dinh",
        "division_type": "xã",
        "short_codename": "chau_dinh",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Văn Lợi",
        "code": "xa_van_loi",
        "division_type": "xã",
        "short_codename": "van_loi",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Nam Sơn",
        "code": "xa_nam_son",
        "division_type": "xã",
        "short_codename": "nam_son",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Châu Lý",
        "code": "xa_chau_ly",
        "division_type": "xã",
        "short_codename": "chau_ly",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Hạ Sơn",
        "code": "xa_ha_son",
        "division_type": "xã",
        "short_codename": "ha_son",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "huyen_quy_hop"
    },
    {
        "name": "Thị trấn Cầu Giát",
        "code": "thi_tran_cau_giat",
        "division_type": "thị trấn",
        "short_codename": "cau_giat",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Thắng",
        "code": "xa_quynh_thang",
        "division_type": "xã",
        "short_codename": "quynh_thang",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Tân",
        "code": "xa_quynh_tan",
        "division_type": "xã",
        "short_codename": "quynh_tan",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Châu",
        "code": "xa_quynh_chau",
        "division_type": "xã",
        "short_codename": "quynh_chau",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Tân Sơn",
        "code": "xa_tan_son",
        "division_type": "xã",
        "short_codename": "tan_son",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Văn",
        "code": "xa_quynh_van",
        "division_type": "xã",
        "short_codename": "quynh_van",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Ngọc Sơn",
        "code": "xa_ngoc_son",
        "division_type": "xã",
        "short_codename": "ngoc_son",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Tam",
        "code": "xa_quynh_tam",
        "division_type": "xã",
        "short_codename": "quynh_tam",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Hoa",
        "code": "xa_quynh_hoa",
        "division_type": "xã",
        "short_codename": "quynh_hoa",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Thạch",
        "code": "xa_quynh_thach",
        "division_type": "xã",
        "short_codename": "quynh_thach",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Bảng",
        "code": "xa_quynh_bang",
        "division_type": "xã",
        "short_codename": "quynh_bang",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Mỹ",
        "code": "xa_quynh_my",
        "division_type": "xã",
        "short_codename": "quynh_my",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Thanh",
        "code": "xa_quynh_thanh",
        "division_type": "xã",
        "short_codename": "quynh_thanh",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Hậu",
        "code": "xa_quynh_hau",
        "division_type": "xã",
        "short_codename": "quynh_hau",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Lâm",
        "code": "xa_quynh_lam",
        "division_type": "xã",
        "short_codename": "quynh_lam",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Đôi",
        "code": "xa_quynh_doi",
        "division_type": "xã",
        "short_codename": "quynh_doi",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Lương",
        "code": "xa_quynh_luong",
        "division_type": "xã",
        "short_codename": "quynh_luong",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Hồng",
        "code": "xa_quynh_hong",
        "division_type": "xã",
        "short_codename": "quynh_hong",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Yên",
        "code": "xa_quynh_yen",
        "division_type": "xã",
        "short_codename": "quynh_yen",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Bá",
        "code": "xa_quynh_ba",
        "division_type": "xã",
        "short_codename": "quynh_ba",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Minh",
        "code": "xa_quynh_minh",
        "division_type": "xã",
        "short_codename": "quynh_minh",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Diễn",
        "code": "xa_quynh_dien",
        "division_type": "xã",
        "short_codename": "quynh_dien",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Hưng",
        "code": "xa_quynh_hung",
        "division_type": "xã",
        "short_codename": "quynh_hung",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Giang",
        "code": "xa_quynh_giang",
        "division_type": "xã",
        "short_codename": "quynh_giang",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Ngọc",
        "code": "xa_quynh_ngoc",
        "division_type": "xã",
        "short_codename": "quynh_ngoc",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Nghĩa",
        "code": "xa_quynh_nghia",
        "division_type": "xã",
        "short_codename": "quynh_nghia",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã An Hòa",
        "code": "xa_an_hoa",
        "division_type": "xã",
        "short_codename": "an_hoa",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Tiến Thủy",
        "code": "xa_tien_thuy",
        "division_type": "xã",
        "short_codename": "tien_thuy",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Sơn Hải",
        "code": "xa_son_hai",
        "division_type": "xã",
        "short_codename": "son_hai",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Thọ",
        "code": "xa_quynh_tho",
        "division_type": "xã",
        "short_codename": "quynh_tho",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Thuận",
        "code": "xa_quynh_thuan",
        "division_type": "xã",
        "short_codename": "quynh_thuan",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Quỳnh Long",
        "code": "xa_quynh_long",
        "division_type": "xã",
        "short_codename": "quynh_long",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Xã Tân Thắng",
        "code": "xa_tan_thang",
        "division_type": "xã",
        "short_codename": "tan_thang",
        "parentCode": "huyen_quynh_luu"
    },
    {
        "name": "Thị trấn Con Cuông",
        "code": "thi_tran_con_cuong",
        "division_type": "thị trấn",
        "short_codename": "con_cuong",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Bình Chuẩn",
        "code": "xa_binh_chuan",
        "division_type": "xã",
        "short_codename": "binh_chuan",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Lạng Khê",
        "code": "xa_lang_khe",
        "division_type": "xã",
        "short_codename": "lang_khe",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Cam Lâm",
        "code": "xa_cam_lam",
        "division_type": "xã",
        "short_codename": "cam_lam",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Thạch Ngàn",
        "code": "xa_thach_ngan",
        "division_type": "xã",
        "short_codename": "thach_ngan",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Đôn Phục",
        "code": "xa_don_phuc",
        "division_type": "xã",
        "short_codename": "don_phuc",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Mậu Đức",
        "code": "xa_mau_duc",
        "division_type": "xã",
        "short_codename": "mau_duc",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Châu Khê",
        "code": "xa_chau_khe",
        "division_type": "xã",
        "short_codename": "chau_khe",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Chi Khê",
        "code": "xa_chi_khe",
        "division_type": "xã",
        "short_codename": "chi_khe",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Bồng Khê",
        "code": "xa_bong_khe",
        "division_type": "xã",
        "short_codename": "bong_khe",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Yên Khê",
        "code": "xa_yen_khe",
        "division_type": "xã",
        "short_codename": "yen_khe",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Lục Dạ",
        "code": "xa_luc_da",
        "division_type": "xã",
        "short_codename": "luc_da",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Xã Môn Sơn",
        "code": "xa_mon_son",
        "division_type": "xã",
        "short_codename": "mon_son",
        "parentCode": "huyen_con_cuong"
    },
    {
        "name": "Thị trấn Tân Kỳ",
        "code": "thi_tran_tan_ky",
        "division_type": "thị trấn",
        "short_codename": "tan_ky",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Tân Hợp",
        "code": "xa_tan_hop",
        "division_type": "xã",
        "short_codename": "tan_hop",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Tân Xuân",
        "code": "xa_tan_xuan",
        "division_type": "xã",
        "short_codename": "tan_xuan",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Giai Xuân",
        "code": "xa_giai_xuan",
        "division_type": "xã",
        "short_codename": "giai_xuan",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Nghĩa Bình",
        "code": "xa_nghia_binh",
        "division_type": "xã",
        "short_codename": "nghia_binh",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Nghĩa Đồng",
        "code": "xa_nghia_dong",
        "division_type": "xã",
        "short_codename": "nghia_dong",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Đồng Văn",
        "code": "xa_dong_van",
        "division_type": "xã",
        "short_codename": "dong_van",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Nghĩa Thái",
        "code": "xa_nghia_thai",
        "division_type": "xã",
        "short_codename": "nghia_thai",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Nghĩa Hợp",
        "code": "xa_nghia_hop",
        "division_type": "xã",
        "short_codename": "nghia_hop",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Nghĩa Hoàn",
        "code": "xa_nghia_hoan",
        "division_type": "xã",
        "short_codename": "nghia_hoan",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Nghĩa Phúc",
        "code": "xa_nghia_phuc",
        "division_type": "xã",
        "short_codename": "nghia_phuc",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Tiên Kỳ",
        "code": "xa_tien_ky",
        "division_type": "xã",
        "short_codename": "tien_ky",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Tân An",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Nghĩa Dũng",
        "code": "xa_nghia_dung",
        "division_type": "xã",
        "short_codename": "nghia_dung",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Kỳ Sơn",
        "code": "xa_ky_son",
        "division_type": "xã",
        "short_codename": "ky_son",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Hương Sơn",
        "code": "xa_huong_son",
        "division_type": "xã",
        "short_codename": "huong_son",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Kỳ Tân",
        "code": "xa_ky_tan",
        "division_type": "xã",
        "short_codename": "ky_tan",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Tân Hương",
        "code": "xa_tan_huong",
        "division_type": "xã",
        "short_codename": "tan_huong",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Xã Nghĩa Hành",
        "code": "xa_nghia_hanh",
        "division_type": "xã",
        "short_codename": "nghia_hanh",
        "parentCode": "huyen_tan_ky"
    },
    {
        "name": "Thị trấn Anh Sơn",
        "code": "thi_tran_anh_son",
        "division_type": "thị trấn",
        "short_codename": "anh_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Thọ Sơn",
        "code": "xa_tho_son",
        "division_type": "xã",
        "short_codename": "tho_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Thành Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Bình Sơn",
        "code": "xa_binh_son",
        "division_type": "xã",
        "short_codename": "binh_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Tam Sơn",
        "code": "xa_tam_son",
        "division_type": "xã",
        "short_codename": "tam_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Đỉnh Sơn",
        "code": "xa_dinh_son",
        "division_type": "xã",
        "short_codename": "dinh_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Hùng Sơn",
        "code": "xa_hung_son",
        "division_type": "xã",
        "short_codename": "hung_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Cẩm Sơn",
        "code": "xa_cam_son",
        "division_type": "xã",
        "short_codename": "cam_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Đức Sơn",
        "code": "xa_duc_son",
        "division_type": "xã",
        "short_codename": "duc_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Tường Sơn",
        "code": "xa_tuong_son",
        "division_type": "xã",
        "short_codename": "tuong_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Hoa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Tào Sơn",
        "code": "xa_tao_son",
        "division_type": "xã",
        "short_codename": "tao_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Vĩnh Sơn",
        "code": "xa_vinh_son",
        "division_type": "xã",
        "short_codename": "vinh_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Lạng Sơn",
        "code": "xa_lang_son",
        "division_type": "xã",
        "short_codename": "lang_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Hội Sơn",
        "code": "xa_hoi_son",
        "division_type": "xã",
        "short_codename": "hoi_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Thạch Sơn",
        "code": "xa_thach_son",
        "division_type": "xã",
        "short_codename": "thach_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Phúc Sơn",
        "code": "xa_phuc_son",
        "division_type": "xã",
        "short_codename": "phuc_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Long Sơn",
        "code": "xa_long_son",
        "division_type": "xã",
        "short_codename": "long_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Khai Sơn",
        "code": "xa_khai_son",
        "division_type": "xã",
        "short_codename": "khai_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Lĩnh Sơn",
        "code": "xa_linh_son",
        "division_type": "xã",
        "short_codename": "linh_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Xã Cao Sơn",
        "code": "xa_cao_son",
        "division_type": "xã",
        "short_codename": "cao_son",
        "parentCode": "huyen_anh_son"
    },
    {
        "name": "Thị trấn Diễn Châu",
        "code": "thi_tran_dien_chau",
        "division_type": "thị trấn",
        "short_codename": "dien_chau",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Lâm",
        "code": "xa_dien_lam",
        "division_type": "xã",
        "short_codename": "dien_lam",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Đoài",
        "code": "xa_dien_doai",
        "division_type": "xã",
        "short_codename": "dien_doai",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Trường",
        "code": "xa_dien_truong",
        "division_type": "xã",
        "short_codename": "dien_truong",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Yên",
        "code": "xa_dien_yen",
        "division_type": "xã",
        "short_codename": "dien_yen",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Hoàng",
        "code": "xa_dien_hoang",
        "division_type": "xã",
        "short_codename": "dien_hoang",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Hùng",
        "code": "xa_dien_hung",
        "division_type": "xã",
        "short_codename": "dien_hung",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Mỹ",
        "code": "xa_dien_my",
        "division_type": "xã",
        "short_codename": "dien_my",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Hồng",
        "code": "xa_dien_hong",
        "division_type": "xã",
        "short_codename": "dien_hong",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Phong",
        "code": "xa_dien_phong",
        "division_type": "xã",
        "short_codename": "dien_phong",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Hải",
        "code": "xa_dien_hai",
        "division_type": "xã",
        "short_codename": "dien_hai",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Tháp",
        "code": "xa_dien_thap",
        "division_type": "xã",
        "short_codename": "dien_thap",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Liên",
        "code": "xa_dien_lien",
        "division_type": "xã",
        "short_codename": "dien_lien",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Vạn",
        "code": "xa_dien_van",
        "division_type": "xã",
        "short_codename": "dien_van",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Kim",
        "code": "xa_dien_kim",
        "division_type": "xã",
        "short_codename": "dien_kim",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Kỷ",
        "code": "xa_dien_ky",
        "division_type": "xã",
        "short_codename": "dien_ky",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Xuân",
        "code": "xa_dien_xuan",
        "division_type": "xã",
        "short_codename": "dien_xuan",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Thái",
        "code": "xa_dien_thai",
        "division_type": "xã",
        "short_codename": "dien_thai",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Đồng",
        "code": "xa_dien_dong",
        "division_type": "xã",
        "short_codename": "dien_dong",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Bích",
        "code": "xa_dien_bich",
        "division_type": "xã",
        "short_codename": "dien_bich",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Hạnh",
        "code": "xa_dien_hanh",
        "division_type": "xã",
        "short_codename": "dien_hanh",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Ngọc",
        "code": "xa_dien_ngoc",
        "division_type": "xã",
        "short_codename": "dien_ngoc",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Quảng",
        "code": "xa_dien_quang",
        "division_type": "xã",
        "short_codename": "dien_quang",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Nguyên",
        "code": "xa_dien_nguyen",
        "division_type": "xã",
        "short_codename": "dien_nguyen",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Hoa",
        "code": "xa_dien_hoa",
        "division_type": "xã",
        "short_codename": "dien_hoa",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Thành",
        "code": "xa_dien_thanh",
        "division_type": "xã",
        "short_codename": "dien_thanh",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Phúc",
        "code": "xa_dien_phuc",
        "division_type": "xã",
        "short_codename": "dien_phuc",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Cát",
        "code": "xa_dien_cat",
        "division_type": "xã",
        "short_codename": "dien_cat",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Thịnh",
        "code": "xa_dien_thinh",
        "division_type": "xã",
        "short_codename": "dien_thinh",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Tân",
        "code": "xa_dien_tan",
        "division_type": "xã",
        "short_codename": "dien_tan",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Minh Châu",
        "code": "xa_minh_chau",
        "division_type": "xã",
        "short_codename": "minh_chau",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Thọ",
        "code": "xa_dien_tho",
        "division_type": "xã",
        "short_codename": "dien_tho",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Lợi",
        "code": "xa_dien_loi",
        "division_type": "xã",
        "short_codename": "dien_loi",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Lộc",
        "code": "xa_dien_loc",
        "division_type": "xã",
        "short_codename": "dien_loc",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Trung",
        "code": "xa_dien_trung",
        "division_type": "xã",
        "short_codename": "dien_trung",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn An",
        "code": "xa_dien_an",
        "division_type": "xã",
        "short_codename": "dien_an",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Xã Diễn Phú",
        "code": "xa_dien_phu",
        "division_type": "xã",
        "short_codename": "dien_phu",
        "parentCode": "huyen_dien_chau"
    },
    {
        "name": "Thị trấn Yên Thành",
        "code": "thi_tran_yen_thanh",
        "division_type": "thị trấn",
        "short_codename": "yen_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Mã Thành",
        "code": "xa_ma_thanh",
        "division_type": "xã",
        "short_codename": "ma_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Tiến Thành",
        "code": "xa_tien_thanh",
        "division_type": "xã",
        "short_codename": "tien_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Lăng Thành",
        "code": "xa_lang_thanh",
        "division_type": "xã",
        "short_codename": "lang_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Đức Thành",
        "code": "xa_duc_thanh",
        "division_type": "xã",
        "short_codename": "duc_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Kim Thành",
        "code": "xa_kim_thanh",
        "division_type": "xã",
        "short_codename": "kim_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Hậu Thành",
        "code": "xa_hau_thanh",
        "division_type": "xã",
        "short_codename": "hau_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Hùng Thành",
        "code": "xa_hung_thanh",
        "division_type": "xã",
        "short_codename": "hung_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Đô Thành",
        "code": "xa_do_thanh",
        "division_type": "xã",
        "short_codename": "do_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Thọ Thành",
        "code": "xa_tho_thanh",
        "division_type": "xã",
        "short_codename": "tho_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Quang Thành",
        "code": "xa_quang_thanh",
        "division_type": "xã",
        "short_codename": "quang_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Tây Thành",
        "code": "xa_tay_thanh",
        "division_type": "xã",
        "short_codename": "tay_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Phúc Thành",
        "code": "xa_phuc_thanh",
        "division_type": "xã",
        "short_codename": "phuc_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Hồng Thành",
        "code": "xa_hong_thanh",
        "division_type": "xã",
        "short_codename": "hong_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Đồng Thành",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Phú Thành",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "phu_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Hoa Thành",
        "code": "xa_hoa_thanh",
        "division_type": "xã",
        "short_codename": "hoa_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Tăng Thành",
        "code": "xa_tang_thanh",
        "division_type": "xã",
        "short_codename": "tang_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Văn Thành",
        "code": "xa_van_thanh",
        "division_type": "xã",
        "short_codename": "van_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Thịnh Thành",
        "code": "xa_thinh_thanh",
        "division_type": "xã",
        "short_codename": "thinh_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Hợp Thành",
        "code": "xa_hop_thanh",
        "division_type": "xã",
        "short_codename": "hop_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Xuân Thành",
        "code": "xa_xuan_thanh",
        "division_type": "xã",
        "short_codename": "xuan_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Bắc Thành",
        "code": "xa_bac_thanh",
        "division_type": "xã",
        "short_codename": "bac_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Nhân Thành",
        "code": "xa_nhan_thanh",
        "division_type": "xã",
        "short_codename": "nhan_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Trung Thành",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Long Thành",
        "code": "xa_long_thanh",
        "division_type": "xã",
        "short_codename": "long_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Minh Thành",
        "code": "xa_minh_thanh",
        "division_type": "xã",
        "short_codename": "minh_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Nam Thành",
        "code": "xa_nam_thanh",
        "division_type": "xã",
        "short_codename": "nam_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Vĩnh Thành",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Lý Thành",
        "code": "xa_ly_thanh",
        "division_type": "xã",
        "short_codename": "ly_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Khánh Thành",
        "code": "xa_khanh_thanh",
        "division_type": "xã",
        "short_codename": "khanh_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Viên Thành",
        "code": "xa_vien_thanh",
        "division_type": "xã",
        "short_codename": "vien_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Đại Thành",
        "code": "xa_dai_thanh",
        "division_type": "xã",
        "short_codename": "dai_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Liên Thành",
        "code": "xa_lien_thanh",
        "division_type": "xã",
        "short_codename": "lien_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Bảo Thành",
        "code": "xa_bao_thanh",
        "division_type": "xã",
        "short_codename": "bao_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Mỹ Thành",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Công Thành",
        "code": "xa_cong_thanh",
        "division_type": "xã",
        "short_codename": "cong_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Xã Sơn Thành",
        "code": "xa_son_thanh",
        "division_type": "xã",
        "short_codename": "son_thanh",
        "parentCode": "huyen_yen_thanh"
    },
    {
        "name": "Thị trấn Đô Lương",
        "code": "thi_tran_do_luong",
        "division_type": "thị trấn",
        "short_codename": "do_luong",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Giang Sơn Đông",
        "code": "xa_giang_son_dong",
        "division_type": "xã",
        "short_codename": "giang_son_dong",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Giang Sơn Tây",
        "code": "xa_giang_son_tay",
        "division_type": "xã",
        "short_codename": "giang_son_tay",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Lam Sơn",
        "code": "xa_lam_son",
        "division_type": "xã",
        "short_codename": "lam_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Bồi Sơn",
        "code": "xa_boi_son",
        "division_type": "xã",
        "short_codename": "boi_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Hồng Sơn",
        "code": "xa_hong_son",
        "division_type": "xã",
        "short_codename": "hong_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Bài Sơn",
        "code": "xa_bai_son",
        "division_type": "xã",
        "short_codename": "bai_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Ngọc Sơn",
        "code": "xa_ngoc_son",
        "division_type": "xã",
        "short_codename": "ngoc_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Tràng Sơn",
        "code": "xa_trang_son",
        "division_type": "xã",
        "short_codename": "trang_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Thượng Sơn",
        "code": "xa_thuong_son",
        "division_type": "xã",
        "short_codename": "thuong_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Hòa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Đặng Sơn",
        "code": "xa_dang_son",
        "division_type": "xã",
        "short_codename": "dang_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Đông Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Nam Sơn",
        "code": "xa_nam_son",
        "division_type": "xã",
        "short_codename": "nam_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Lưu Sơn",
        "code": "xa_luu_son",
        "division_type": "xã",
        "short_codename": "luu_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Yên Sơn",
        "code": "xa_yen_son",
        "division_type": "xã",
        "short_codename": "yen_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Văn Sơn",
        "code": "xa_van_son",
        "division_type": "xã",
        "short_codename": "van_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Đà Sơn",
        "code": "xa_da_son",
        "division_type": "xã",
        "short_codename": "da_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Lạc Sơn",
        "code": "xa_lac_son",
        "division_type": "xã",
        "short_codename": "lac_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Tân Sơn",
        "code": "xa_tan_son",
        "division_type": "xã",
        "short_codename": "tan_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Thái Sơn",
        "code": "xa_thai_son",
        "division_type": "xã",
        "short_codename": "thai_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Quang Sơn",
        "code": "xa_quang_son",
        "division_type": "xã",
        "short_codename": "quang_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Thịnh Sơn",
        "code": "xa_thinh_son",
        "division_type": "xã",
        "short_codename": "thinh_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Trung Sơn",
        "code": "xa_trung_son",
        "division_type": "xã",
        "short_codename": "trung_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Xuân Sơn",
        "code": "xa_xuan_son",
        "division_type": "xã",
        "short_codename": "xuan_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Minh Sơn",
        "code": "xa_minh_son",
        "division_type": "xã",
        "short_codename": "minh_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Thuận Sơn",
        "code": "xa_thuan_son",
        "division_type": "xã",
        "short_codename": "thuan_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Nhân Sơn",
        "code": "xa_nhan_son",
        "division_type": "xã",
        "short_codename": "nhan_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Hiến Sơn",
        "code": "xa_hien_son",
        "division_type": "xã",
        "short_codename": "hien_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Mỹ Sơn",
        "code": "xa_my_son",
        "division_type": "xã",
        "short_codename": "my_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Trù Sơn",
        "code": "xa_tru_son",
        "division_type": "xã",
        "short_codename": "tru_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Xã Đại Sơn",
        "code": "xa_dai_son",
        "division_type": "xã",
        "short_codename": "dai_son",
        "parentCode": "huyen_do_luong"
    },
    {
        "name": "Thị trấn Thanh Chương",
        "code": "thi_tran_thanh_chuong",
        "division_type": "thị trấn",
        "short_codename": "thanh_chuong",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Cát Văn",
        "code": "xa_cat_van",
        "division_type": "xã",
        "short_codename": "cat_van",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Nho",
        "code": "xa_thanh_nho",
        "division_type": "xã",
        "short_codename": "thanh_nho",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Hạnh Lâm",
        "code": "xa_hanh_lam",
        "division_type": "xã",
        "short_codename": "hanh_lam",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Hòa",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Phong Thịnh",
        "code": "xa_phong_thinh",
        "division_type": "xã",
        "short_codename": "phong_thinh",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Phong",
        "code": "xa_thanh_phong",
        "division_type": "xã",
        "short_codename": "thanh_phong",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Mỹ",
        "code": "xa_thanh_my",
        "division_type": "xã",
        "short_codename": "thanh_my",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Tiên",
        "code": "xa_thanh_tien",
        "division_type": "xã",
        "short_codename": "thanh_tien",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Liên",
        "code": "xa_thanh_lien",
        "division_type": "xã",
        "short_codename": "thanh_lien",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Đại Đồng",
        "code": "xa_dai_dong",
        "division_type": "xã",
        "short_codename": "dai_dong",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Đồng",
        "code": "xa_thanh_dong",
        "division_type": "xã",
        "short_codename": "thanh_dong",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Ngọc",
        "code": "xa_thanh_ngoc",
        "division_type": "xã",
        "short_codename": "thanh_ngoc",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Hương",
        "code": "xa_thanh_huong",
        "division_type": "xã",
        "short_codename": "thanh_huong",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Ngọc Lâm",
        "code": "xa_ngoc_lam",
        "division_type": "xã",
        "short_codename": "ngoc_lam",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Lĩnh",
        "code": "xa_thanh_linh",
        "division_type": "xã",
        "short_codename": "thanh_linh",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Đồng Văn",
        "code": "xa_dong_van",
        "division_type": "xã",
        "short_codename": "dong_van",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Ngọc Sơn",
        "code": "xa_ngoc_son",
        "division_type": "xã",
        "short_codename": "ngoc_son",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Thịnh",
        "code": "xa_thanh_thinh",
        "division_type": "xã",
        "short_codename": "thanh_thinh",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Chi",
        "code": "xa_thanh_chi",
        "division_type": "xã",
        "short_codename": "thanh_chi",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Xuân Tường",
        "code": "xa_xuan_tuong",
        "division_type": "xã",
        "short_codename": "xuan_tuong",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Dương",
        "code": "xa_thanh_duong",
        "division_type": "xã",
        "short_codename": "thanh_duong",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Lương",
        "code": "xa_thanh_luong",
        "division_type": "xã",
        "short_codename": "thanh_luong",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Khê",
        "code": "xa_thanh_khe",
        "division_type": "xã",
        "short_codename": "thanh_khe",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Võ Liệt",
        "code": "xa_vo_liet",
        "division_type": "xã",
        "short_codename": "vo_liet",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Long",
        "code": "xa_thanh_long",
        "division_type": "xã",
        "short_codename": "thanh_long",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Thủy",
        "code": "xa_thanh_thuy",
        "division_type": "xã",
        "short_codename": "thanh_thuy",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Khai",
        "code": "xa_thanh_khai",
        "division_type": "xã",
        "short_codename": "thanh_khai",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Yên",
        "code": "xa_thanh_yen",
        "division_type": "xã",
        "short_codename": "thanh_yen",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Hà",
        "code": "xa_thanh_ha",
        "division_type": "xã",
        "short_codename": "thanh_ha",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Giang",
        "code": "xa_thanh_giang",
        "division_type": "xã",
        "short_codename": "thanh_giang",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Tùng",
        "code": "xa_thanh_tung",
        "division_type": "xã",
        "short_codename": "thanh_tung",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Lâm",
        "code": "xa_thanh_lam",
        "division_type": "xã",
        "short_codename": "thanh_lam",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Mai",
        "code": "xa_thanh_mai",
        "division_type": "xã",
        "short_codename": "thanh_mai",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Xuân",
        "code": "xa_thanh_xuan",
        "division_type": "xã",
        "short_codename": "thanh_xuan",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Xã Thanh Đức",
        "code": "xa_thanh_duc",
        "division_type": "xã",
        "short_codename": "thanh_duc",
        "parentCode": "huyen_thanh_chuong"
    },
    {
        "name": "Thị trấn Quán Hành",
        "code": "thi_tran_quan_hanh",
        "division_type": "thị trấn",
        "short_codename": "quan_hanh",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Văn",
        "code": "xa_nghi_van",
        "division_type": "xã",
        "short_codename": "xa_nghi_van",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Yên",
        "code": "xa_nghi_yen",
        "division_type": "xã",
        "short_codename": "nghi_yen",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Tiến",
        "code": "xa_nghi_tien",
        "division_type": "xã",
        "short_codename": "nghi_tien",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Hưng",
        "code": "xa_nghi_hung",
        "division_type": "xã",
        "short_codename": "nghi_hung",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Đồng",
        "code": "xa_nghi_dong",
        "division_type": "xã",
        "short_codename": "nghi_dong",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Thiết",
        "code": "xa_nghi_thiet",
        "division_type": "xã",
        "short_codename": "nghi_thiet",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Lâm",
        "code": "xa_nghi_lam",
        "division_type": "xã",
        "short_codename": "nghi_lam",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Quang",
        "code": "xa_nghi_quang",
        "division_type": "xã",
        "short_codename": "nghi_quang",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Kiều",
        "code": "xa_nghi_kieu",
        "division_type": "xã",
        "short_codename": "nghi_kieu",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Mỹ",
        "code": "xa_nghi_my",
        "division_type": "xã",
        "short_codename": "nghi_my",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Phương",
        "code": "xa_nghi_phuong",
        "division_type": "xã",
        "short_codename": "nghi_phuong",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Thuận",
        "code": "xa_nghi_thuan",
        "division_type": "xã",
        "short_codename": "nghi_thuan",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Long",
        "code": "xa_nghi_long",
        "division_type": "xã",
        "short_codename": "nghi_long",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Xá",
        "code": "xa_nghi_xa",
        "division_type": "xã",
        "short_codename": "nghi_xa",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Hoa",
        "code": "xa_nghi_hoa",
        "division_type": "xã",
        "short_codename": "nghi_hoa",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Khánh Hợp",
        "code": "xa_khanh_hop",
        "division_type": "xã",
        "short_codename": "khanh_hop",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Thịnh",
        "code": "xa_nghi_thinh",
        "division_type": "xã",
        "short_codename": "nghi_thinh",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Công Bắc",
        "code": "xa_nghi_cong_bac",
        "division_type": "xã",
        "short_codename": "nghi_cong_bac",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Công Nam",
        "code": "xa_nghi_cong_nam",
        "division_type": "xã",
        "short_codename": "nghi_cong_nam",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Thạch",
        "code": "xa_nghi_thach",
        "division_type": "xã",
        "short_codename": "nghi_thach",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Trung",
        "code": "xa_nghi_trung",
        "division_type": "xã",
        "short_codename": "nghi_trung",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Trường",
        "code": "xa_nghi_truong",
        "division_type": "xã",
        "short_codename": "nghi_truong",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Diên",
        "code": "xa_nghi_dien",
        "division_type": "xã",
        "short_codename": "nghi_dien",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Phong",
        "code": "xa_nghi_phong",
        "division_type": "xã",
        "short_codename": "nghi_phong",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Xuân",
        "code": "xa_nghi_xuan",
        "division_type": "xã",
        "short_codename": "nghi_xuan",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Vạn",
        "code": "xa_nghi_van",
        "division_type": "xã",
        "short_codename": "xa_nghi_van",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Phúc Thọ",
        "code": "xa_phuc_tho",
        "division_type": "xã",
        "short_codename": "phuc_tho",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nghi Thái",
        "code": "xa_nghi_thai",
        "division_type": "xã",
        "short_codename": "nghi_thai",
        "parentCode": "huyen_nghi_loc"
    },
    {
        "name": "Xã Nam Hưng",
        "code": "xa_nam_hung",
        "division_type": "xã",
        "short_codename": "nam_hung",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Nam Nghĩa",
        "code": "xa_nam_nghia",
        "division_type": "xã",
        "short_codename": "nam_nghia",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Nam Thanh",
        "code": "xa_nam_thanh",
        "division_type": "xã",
        "short_codename": "nam_thanh",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Nam Anh",
        "code": "xa_nam_anh",
        "division_type": "xã",
        "short_codename": "nam_anh",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Nam Xuân",
        "code": "xa_nam_xuan",
        "division_type": "xã",
        "short_codename": "nam_xuan",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Nam Thái",
        "code": "xa_nam_thai",
        "division_type": "xã",
        "short_codename": "nam_thai",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Thị trấn Nam Đàn",
        "code": "thi_tran_nam_dan",
        "division_type": "thị trấn",
        "short_codename": "nam_dan",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Nam Lĩnh",
        "code": "xa_nam_linh",
        "division_type": "xã",
        "short_codename": "nam_linh",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Nam Giang",
        "code": "xa_nam_giang",
        "division_type": "xã",
        "short_codename": "nam_giang",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Xuân Hòa",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Hùng Tiến",
        "code": "xa_hung_tien",
        "division_type": "xã",
        "short_codename": "hung_tien",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Thượng Tân Lộc",
        "code": "xa_thuong_tan_loc",
        "division_type": "xã",
        "short_codename": "thuong_tan_loc",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Kim Liên",
        "code": "xa_kim_lien",
        "division_type": "xã",
        "short_codename": "kim_lien",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Hồng Long",
        "code": "xa_hong_long",
        "division_type": "xã",
        "short_codename": "hong_long",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Xuân Lâm",
        "code": "xa_xuan_lam",
        "division_type": "xã",
        "short_codename": "xuan_lam",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Nam Cát",
        "code": "xa_nam_cat",
        "division_type": "xã",
        "short_codename": "nam_cat",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Khánh Sơn",
        "code": "xa_khanh_son",
        "division_type": "xã",
        "short_codename": "khanh_son",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Trung Phúc Cường",
        "code": "xa_trung_phuc_cuong",
        "division_type": "xã",
        "short_codename": "trung_phuc_cuong",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Xã Nam Kim",
        "code": "xa_nam_kim",
        "division_type": "xã",
        "short_codename": "nam_kim",
        "parentCode": "huyen_nam_dan"
    },
    {
        "name": "Thị trấn Hưng Nguyên",
        "code": "thi_tran_hung_nguyen",
        "division_type": "thị trấn",
        "short_codename": "hung_nguyen",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Trung",
        "code": "xa_hung_trung",
        "division_type": "xã",
        "short_codename": "hung_trung",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Yên",
        "code": "xa_hung_yen",
        "division_type": "xã",
        "short_codename": "hung_yen",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Yên Bắc",
        "code": "xa_hung_yen_bac",
        "division_type": "xã",
        "short_codename": "hung_yen_bac",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Tây",
        "code": "xa_hung_tay",
        "division_type": "xã",
        "short_codename": "hung_tay",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Đạo",
        "code": "xa_hung_dao",
        "division_type": "xã",
        "short_codename": "hung_dao",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Mỹ",
        "code": "xa_hung_my",
        "division_type": "xã",
        "short_codename": "hung_my",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Thịnh",
        "code": "xa_hung_thinh",
        "division_type": "xã",
        "short_codename": "hung_thinh",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Lĩnh",
        "code": "xa_hung_linh",
        "division_type": "xã",
        "short_codename": "hung_linh",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Thông",
        "code": "xa_hung_thong",
        "division_type": "xã",
        "short_codename": "hung_thong",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Tân",
        "code": "xa_hung_tan",
        "division_type": "xã",
        "short_codename": "hung_tan",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Lợi",
        "code": "xa_hung_loi",
        "division_type": "xã",
        "short_codename": "hung_loi",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Nghĩa",
        "code": "xa_hung_nghia",
        "division_type": "xã",
        "short_codename": "hung_nghia",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Phúc",
        "code": "xa_hung_phuc",
        "division_type": "xã",
        "short_codename": "hung_phuc",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Long Xá",
        "code": "xa_long_xa",
        "division_type": "xã",
        "short_codename": "long_xa",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Châu Nhân",
        "code": "xa_chau_nhan",
        "division_type": "xã",
        "short_codename": "chau_nhan",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Xuân Lam",
        "code": "xa_xuan_lam",
        "division_type": "xã",
        "short_codename": "xuan_lam",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Hưng Thành",
        "code": "xa_hung_thanh",
        "division_type": "xã",
        "short_codename": "hung_thanh",
        "parentCode": "huyen_hung_nguyen"
    },
    {
        "name": "Xã Quỳnh Vinh",
        "code": "xa_quynh_vinh",
        "division_type": "xã",
        "short_codename": "quynh_vinh",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Xã Quỳnh Lộc",
        "code": "xa_quynh_loc",
        "division_type": "xã",
        "short_codename": "quynh_loc",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Phường Quỳnh Thiện",
        "code": "phuong_quynh_thien",
        "division_type": "phường",
        "short_codename": "quynh_thien",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Xã Quỳnh Lập",
        "code": "xa_quynh_lap",
        "division_type": "xã",
        "short_codename": "quynh_lap",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Xã Quỳnh Trang",
        "code": "xa_quynh_trang",
        "division_type": "xã",
        "short_codename": "quynh_trang",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Phường Mai Hùng",
        "code": "phuong_mai_hung",
        "division_type": "phường",
        "short_codename": "mai_hung",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Phường Quỳnh Dị",
        "code": "phuong_quynh_di",
        "division_type": "phường",
        "short_codename": "quynh_di",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Phường Quỳnh Xuân",
        "code": "phuong_quynh_xuan",
        "division_type": "phường",
        "short_codename": "quynh_xuan",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Phường Quỳnh Phương",
        "code": "phuong_quynh_phuong",
        "division_type": "phường",
        "short_codename": "quynh_phuong",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Xã Quỳnh Liên",
        "code": "xa_quynh_lien",
        "division_type": "xã",
        "short_codename": "quynh_lien",
        "parentCode": "thi_xa_hoang_mai"
    },
    {
        "name": "Phường Trần Phú",
        "code": "phuong_tran_phu",
        "division_type": "phường",
        "short_codename": "tran_phu",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Nam Hà",
        "code": "phuong_nam_ha",
        "division_type": "phường",
        "short_codename": "nam_ha",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Bắc Hà",
        "code": "phuong_bac_ha",
        "division_type": "phường",
        "short_codename": "bac_ha",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Nguyễn Du",
        "code": "phuong_nguyen_du",
        "division_type": "phường",
        "short_codename": "nguyen_du",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Tân Giang",
        "code": "phuong_tan_giang",
        "division_type": "phường",
        "short_codename": "tan_giang",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Đại Nài",
        "code": "phuong_dai_nai",
        "division_type": "phường",
        "short_codename": "dai_nai",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Hà Huy Tập",
        "code": "phuong_ha_huy_tap",
        "division_type": "phường",
        "short_codename": "ha_huy_tap",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Xã Thạch Trung",
        "code": "xa_thach_trung",
        "division_type": "xã",
        "short_codename": "thach_trung",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Thạch Quý",
        "code": "phuong_thach_quy",
        "division_type": "phường",
        "short_codename": "thach_quy",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Thạch Linh",
        "code": "phuong_thach_linh",
        "division_type": "phường",
        "short_codename": "thach_linh",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Văn Yên",
        "code": "phuong_van_yen",
        "division_type": "phường",
        "short_codename": "van_yen",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Xã Thạch Hạ",
        "code": "xa_thach_ha",
        "division_type": "xã",
        "short_codename": "thach_ha",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Xã Đồng Môn",
        "code": "xa_dong_mon",
        "division_type": "xã",
        "short_codename": "dong_mon",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Xã Thạch Hưng",
        "code": "xa_thach_hung",
        "division_type": "xã",
        "short_codename": "thach_hung",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Xã Thạch Bình",
        "code": "xa_thach_binh",
        "division_type": "xã",
        "short_codename": "thach_binh",
        "parentCode": "thanh_pho_ha_tinh"
    },
    {
        "name": "Phường Bắc Hồng",
        "code": "phuong_bac_hong",
        "division_type": "phường",
        "short_codename": "bac_hong",
        "parentCode": "thi_xa_hong_linh"
    },
    {
        "name": "Phường Nam Hồng",
        "code": "phuong_nam_hong",
        "division_type": "phường",
        "short_codename": "nam_hong",
        "parentCode": "thi_xa_hong_linh"
    },
    {
        "name": "Phường Trung Lương",
        "code": "phuong_trung_luong",
        "division_type": "phường",
        "short_codename": "trung_luong",
        "parentCode": "thi_xa_hong_linh"
    },
    {
        "name": "Phường Đức Thuận",
        "code": "phuong_duc_thuan",
        "division_type": "phường",
        "short_codename": "duc_thuan",
        "parentCode": "thi_xa_hong_linh"
    },
    {
        "name": "Phường Đậu Liêu",
        "code": "phuong_dau_lieu",
        "division_type": "phường",
        "short_codename": "dau_lieu",
        "parentCode": "thi_xa_hong_linh"
    },
    {
        "name": "Xã Thuận Lộc",
        "code": "xa_thuan_loc",
        "division_type": "xã",
        "short_codename": "thuan_loc",
        "parentCode": "thi_xa_hong_linh"
    },
    {
        "name": "Thị trấn Phố Châu",
        "code": "thi_tran_pho_chau",
        "division_type": "thị trấn",
        "short_codename": "pho_chau",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Thị trấn Tây Sơn",
        "code": "thi_tran_tay_son",
        "division_type": "thị trấn",
        "short_codename": "tay_son",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Hồng",
        "code": "xa_son_hong",
        "division_type": "xã",
        "short_codename": "son_hong",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Tiến",
        "code": "xa_son_tien",
        "division_type": "xã",
        "short_codename": "son_tien",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Lâm",
        "code": "xa_son_lam",
        "division_type": "xã",
        "short_codename": "son_lam",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Lễ",
        "code": "xa_son_le",
        "division_type": "xã",
        "short_codename": "son_le",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Giang",
        "code": "xa_son_giang",
        "division_type": "xã",
        "short_codename": "son_giang",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Lĩnh",
        "code": "xa_son_linh",
        "division_type": "xã",
        "short_codename": "son_linh",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã An Hòa Thịnh",
        "code": "xa_an_hoa_thinh",
        "division_type": "xã",
        "short_codename": "an_hoa_thinh",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Tây",
        "code": "xa_son_tay",
        "division_type": "xã",
        "short_codename": "son_tay",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Ninh",
        "code": "xa_son_ninh",
        "division_type": "xã",
        "short_codename": "son_ninh",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Châu",
        "code": "xa_son_chau",
        "division_type": "xã",
        "short_codename": "son_chau",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Tân Mỹ Hà",
        "code": "xa_tan_my_ha",
        "division_type": "xã",
        "short_codename": "tan_my_ha",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Quang Diệm",
        "code": "xa_quang_diem",
        "division_type": "xã",
        "short_codename": "quang_diem",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Trung",
        "code": "xa_son_trung",
        "division_type": "xã",
        "short_codename": "son_trung",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Bằng",
        "code": "xa_son_bang",
        "division_type": "xã",
        "short_codename": "son_bang",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Bình",
        "code": "xa_son_binh",
        "division_type": "xã",
        "short_codename": "son_binh",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Kim 1",
        "code": "xa_son_kim_1",
        "division_type": "xã",
        "short_codename": "son_kim_1",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Kim 2",
        "code": "xa_son_kim_2",
        "division_type": "xã",
        "short_codename": "son_kim_2",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Trà",
        "code": "xa_son_tra",
        "division_type": "xã",
        "short_codename": "son_tra",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Long",
        "code": "xa_son_long",
        "division_type": "xã",
        "short_codename": "son_long",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Kim Hoa",
        "code": "xa_kim_hoa",
        "division_type": "xã",
        "short_codename": "kim_hoa",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Hàm",
        "code": "xa_son_ham",
        "division_type": "xã",
        "short_codename": "son_ham",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Phú",
        "code": "xa_son_phu",
        "division_type": "xã",
        "short_codename": "son_phu",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Xã Sơn Trường",
        "code": "xa_son_truong",
        "division_type": "xã",
        "short_codename": "son_truong",
        "parentCode": "huyen_huong_son"
    },
    {
        "name": "Thị trấn Đức Thọ",
        "code": "thi_tran_duc_tho",
        "division_type": "thị trấn",
        "short_codename": "duc_tho",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Quang Vĩnh",
        "code": "xa_quang_vinh",
        "division_type": "xã",
        "short_codename": "quang_vinh",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Tùng Châu",
        "code": "xa_tung_chau",
        "division_type": "xã",
        "short_codename": "tung_chau",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Trường Sơn",
        "code": "xa_truong_son",
        "division_type": "xã",
        "short_codename": "truong_son",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Liên Minh",
        "code": "xa_lien_minh",
        "division_type": "xã",
        "short_codename": "lien_minh",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Yên Hồ",
        "code": "xa_yen_ho",
        "division_type": "xã",
        "short_codename": "yen_ho",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Tùng Ảnh",
        "code": "xa_tung_anh",
        "division_type": "xã",
        "short_codename": "tung_anh",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Bùi La Nhân",
        "code": "xa_bui_la_nhan",
        "division_type": "xã",
        "short_codename": "bui_la_nhan",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Thanh Bình Thịnh",
        "code": "xa_thanh_binh_thinh",
        "division_type": "xã",
        "short_codename": "thanh_binh_thinh",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Lâm Trung Thủy",
        "code": "xa_lam_trung_thuy",
        "division_type": "xã",
        "short_codename": "lam_trung_thuy",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Hòa Lạc",
        "code": "xa_hoa_lac",
        "division_type": "xã",
        "short_codename": "hoa_lac",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Tân Dân",
        "code": "xa_tan_dan",
        "division_type": "xã",
        "short_codename": "tan_dan",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã An Dũng",
        "code": "xa_an_dung",
        "division_type": "xã",
        "short_codename": "an_dung",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Đức Đồng",
        "code": "xa_duc_dong",
        "division_type": "xã",
        "short_codename": "duc_dong",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Đức Lạng",
        "code": "xa_duc_lang",
        "division_type": "xã",
        "short_codename": "duc_lang",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Xã Tân Hương",
        "code": "xa_tan_huong",
        "division_type": "xã",
        "short_codename": "tan_huong",
        "parentCode": "huyen_duc_tho"
    },
    {
        "name": "Thị trấn Vũ Quang",
        "code": "thi_tran_vu_quang",
        "division_type": "thị trấn",
        "short_codename": "vu_quang",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Xã Ân Phú",
        "code": "xa_an_phu",
        "division_type": "xã",
        "short_codename": "an_phu",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Xã Đức Giang",
        "code": "xa_duc_giang",
        "division_type": "xã",
        "short_codename": "duc_giang",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Xã Đức Lĩnh",
        "code": "xa_duc_linh",
        "division_type": "xã",
        "short_codename": "duc_linh",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Xã Thọ Điền",
        "code": "xa_tho_dien",
        "division_type": "xã",
        "short_codename": "tho_dien",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Xã Đức Hương",
        "code": "xa_duc_huong",
        "division_type": "xã",
        "short_codename": "duc_huong",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Xã Đức Bồng",
        "code": "xa_duc_bong",
        "division_type": "xã",
        "short_codename": "duc_bong",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Xã Đức Liên",
        "code": "xa_duc_lien",
        "division_type": "xã",
        "short_codename": "duc_lien",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Xã Hương Minh",
        "code": "xa_huong_minh",
        "division_type": "xã",
        "short_codename": "huong_minh",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Xã Quang Thọ",
        "code": "xa_quang_tho",
        "division_type": "xã",
        "short_codename": "quang_tho",
        "parentCode": "huyen_vu_quang"
    },
    {
        "name": "Thị trấn Xuân An",
        "code": "thi_tran_xuan_an",
        "division_type": "thị trấn",
        "short_codename": "xuan_an",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Hội",
        "code": "xa_xuan_hoi",
        "division_type": "xã",
        "short_codename": "xuan_hoi",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Đan Trường",
        "code": "xa_dan_truong",
        "division_type": "xã",
        "short_codename": "dan_truong",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Phổ",
        "code": "xa_xuan_pho",
        "division_type": "xã",
        "short_codename": "xuan_pho",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Hải",
        "code": "xa_xuan_hai",
        "division_type": "xã",
        "short_codename": "xuan_hai",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Giang",
        "code": "xa_xuan_giang",
        "division_type": "xã",
        "short_codename": "xuan_giang",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Thị trấn Tiên Điền",
        "code": "thi_tran_tien_dien",
        "division_type": "thị trấn",
        "short_codename": "tien_dien",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Yên",
        "code": "xa_xuan_yen",
        "division_type": "xã",
        "short_codename": "xuan_yen",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Mỹ",
        "code": "xa_xuan_my",
        "division_type": "xã",
        "short_codename": "xuan_my",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Thành",
        "code": "xa_xuan_thanh",
        "division_type": "xã",
        "short_codename": "xuan_thanh",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Viên",
        "code": "xa_xuan_vien",
        "division_type": "xã",
        "short_codename": "xuan_vien",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Hồng",
        "code": "xa_xuan_hong",
        "division_type": "xã",
        "short_codename": "xuan_hong",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Cỗ Đạm",
        "code": "xa_co_dam",
        "division_type": "xã",
        "short_codename": "co_dam",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Liên",
        "code": "xa_xuan_lien",
        "division_type": "xã",
        "short_codename": "xuan_lien",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Lĩnh",
        "code": "xa_xuan_linh",
        "division_type": "xã",
        "short_codename": "xuan_linh",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Xuân Lam",
        "code": "xa_xuan_lam",
        "division_type": "xã",
        "short_codename": "xuan_lam",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Xã Cương Gián",
        "code": "xa_cuong_gian",
        "division_type": "xã",
        "short_codename": "cuong_gian",
        "parentCode": "huyen_nghi_xuan"
    },
    {
        "name": "Thị trấn Nghèn",
        "code": "thi_tran_nghen",
        "division_type": "thị trấn",
        "short_codename": "nghen",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Thiên Lộc",
        "code": "xa_thien_loc",
        "division_type": "xã",
        "short_codename": "thien_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Thuần Thiện",
        "code": "xa_thuan_thien",
        "division_type": "xã",
        "short_codename": "thuan_thien",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Vượng Lộc",
        "code": "xa_vuong_loc",
        "division_type": "xã",
        "short_codename": "vuong_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Thanh Lộc",
        "code": "xa_thanh_loc",
        "division_type": "xã",
        "short_codename": "thanh_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Kim Song Trường",
        "code": "xa_kim_song_truong",
        "division_type": "xã",
        "short_codename": "kim_song_truong",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Thường Nga",
        "code": "xa_thuong_nga",
        "division_type": "xã",
        "short_codename": "thuong_nga",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Tùng Lộc",
        "code": "xa_tung_loc",
        "division_type": "xã",
        "short_codename": "tung_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Phú Lộc",
        "code": "xa_phu_loc",
        "division_type": "xã",
        "short_codename": "phu_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Gia Hanh",
        "code": "xa_gia_hanh",
        "division_type": "xã",
        "short_codename": "gia_hanh",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Khánh Vĩnh Yên",
        "code": "xa_khanh_vinh_yen",
        "division_type": "xã",
        "short_codename": "khanh_vinh_yen",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Trung Lộc",
        "code": "xa_trung_loc",
        "division_type": "xã",
        "short_codename": "trung_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Xuân Lộc",
        "code": "xa_xuan_loc",
        "division_type": "xã",
        "short_codename": "xuan_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Thượng Lộc",
        "code": "xa_thuong_loc",
        "division_type": "xã",
        "short_codename": "thuong_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Quang Lộc",
        "code": "xa_quang_loc",
        "division_type": "xã",
        "short_codename": "quang_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Thị trấn Đồng Lộc",
        "code": "thi_tran_dong_loc",
        "division_type": "thị trấn",
        "short_codename": "dong_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Mỹ Lộc",
        "code": "xa_my_loc",
        "division_type": "xã",
        "short_codename": "my_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Xã Sơn Lộc",
        "code": "xa_son_loc",
        "division_type": "xã",
        "short_codename": "son_loc",
        "parentCode": "huyen_can_loc"
    },
    {
        "name": "Thị trấn Hương Khê",
        "code": "thi_tran_huong_khe",
        "division_type": "thị trấn",
        "short_codename": "huong_khe",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Điền Mỹ",
        "code": "xa_dien_my",
        "division_type": "xã",
        "short_codename": "dien_my",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hà Linh",
        "code": "xa_ha_linh",
        "division_type": "xã",
        "short_codename": "ha_linh",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Thủy",
        "code": "xa_huong_thuy",
        "division_type": "xã",
        "short_codename": "huong_thuy",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hòa Hải",
        "code": "xa_hoa_hai",
        "division_type": "xã",
        "short_codename": "hoa_hai",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Phúc Đồng",
        "code": "xa_phuc_dong",
        "division_type": "xã",
        "short_codename": "phuc_dong",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Giang",
        "code": "xa_huong_giang",
        "division_type": "xã",
        "short_codename": "huong_giang",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Lộc Yên",
        "code": "xa_loc_yen",
        "division_type": "xã",
        "short_codename": "loc_yen",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Bình",
        "code": "xa_huong_binh",
        "division_type": "xã",
        "short_codename": "huong_binh",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Long",
        "code": "xa_huong_long",
        "division_type": "xã",
        "short_codename": "huong_long",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Phú Gia",
        "code": "xa_phu_gia",
        "division_type": "xã",
        "short_codename": "phu_gia",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Gia Phố",
        "code": "xa_gia_pho",
        "division_type": "xã",
        "short_codename": "gia_pho",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Phú Phong",
        "code": "xa_phu_phong",
        "division_type": "xã",
        "short_codename": "phu_phong",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Đô",
        "code": "xa_huong_do",
        "division_type": "xã",
        "short_codename": "huong_do",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Vĩnh",
        "code": "xa_huong_vinh",
        "division_type": "xã",
        "short_codename": "huong_vinh",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Xuân",
        "code": "xa_huong_xuan",
        "division_type": "xã",
        "short_codename": "huong_xuan",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Phúc Trạch",
        "code": "xa_phuc_trach",
        "division_type": "xã",
        "short_codename": "phuc_trach",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Trà",
        "code": "xa_huong_tra",
        "division_type": "xã",
        "short_codename": "huong_tra",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Trạch",
        "code": "xa_huong_trach",
        "division_type": "xã",
        "short_codename": "huong_trach",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Lâm",
        "code": "xa_huong_lam",
        "division_type": "xã",
        "short_codename": "huong_lam",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Xã Hương Liên",
        "code": "xa_huong_lien",
        "division_type": "xã",
        "short_codename": "huong_lien",
        "parentCode": "huyen_huong_khe"
    },
    {
        "name": "Thị trấn Thạch Hà",
        "code": "thi_tran_thach_ha",
        "division_type": "thị trấn",
        "short_codename": "thach_ha",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Ngọc Sơn",
        "code": "xa_ngoc_son",
        "division_type": "xã",
        "short_codename": "ngoc_son",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Hải",
        "code": "xa_thach_hai",
        "division_type": "xã",
        "short_codename": "thach_hai",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Kênh",
        "code": "xa_thach_kenh",
        "division_type": "xã",
        "short_codename": "thach_kenh",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Sơn",
        "code": "xa_thach_son",
        "division_type": "xã",
        "short_codename": "thach_son",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Liên",
        "code": "xa_thach_lien",
        "division_type": "xã",
        "short_codename": "thach_lien",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Đỉnh Bàn",
        "code": "xa_dinh_ban",
        "division_type": "xã",
        "short_codename": "dinh_ban",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Việt Tiến",
        "code": "xa_viet_tien",
        "division_type": "xã",
        "short_codename": "viet_tien",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Khê",
        "code": "xa_thach_khe",
        "division_type": "xã",
        "short_codename": "thach_khe",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Long",
        "code": "xa_thach_long",
        "division_type": "xã",
        "short_codename": "thach_long",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Trị",
        "code": "xa_thach_tri",
        "division_type": "xã",
        "short_codename": "thach_tri",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Lạc",
        "code": "xa_thach_lac",
        "division_type": "xã",
        "short_codename": "thach_lac",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Ngọc",
        "code": "xa_thach_ngoc",
        "division_type": "xã",
        "short_codename": "thach_ngoc",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Tượng Sơn",
        "code": "xa_tuong_son",
        "division_type": "xã",
        "short_codename": "tuong_son",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Văn",
        "code": "xa_thach_van",
        "division_type": "xã",
        "short_codename": "thach_van",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Lưu Vĩnh Sơn",
        "code": "xa_luu_vinh_son",
        "division_type": "xã",
        "short_codename": "luu_vinh_son",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Thắng",
        "code": "xa_thach_thang",
        "division_type": "xã",
        "short_codename": "thach_thang",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Đài",
        "code": "xa_thach_dai",
        "division_type": "xã",
        "short_codename": "thach_dai",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Hội",
        "code": "xa_thach_hoi",
        "division_type": "xã",
        "short_codename": "thach_hoi",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Tân Lâm Hương",
        "code": "xa_tan_lam_huong",
        "division_type": "xã",
        "short_codename": "tan_lam_huong",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Thạch Xuân",
        "code": "xa_thach_xuan",
        "division_type": "xã",
        "short_codename": "thach_xuan",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Xã Nam Điền",
        "code": "xa_nam_dien",
        "division_type": "xã",
        "short_codename": "nam_dien",
        "parentCode": "huyen_thach_ha"
    },
    {
        "name": "Thị trấn Cẩm Xuyên",
        "code": "thi_tran_cam_xuyen",
        "division_type": "thị trấn",
        "short_codename": "cam_xuyen",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Thị trấn Thiên Cầm",
        "code": "thi_tran_thien_cam",
        "division_type": "thị trấn",
        "short_codename": "thien_cam",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Yên Hòa",
        "code": "xa_yen_hoa",
        "division_type": "xã",
        "short_codename": "yen_hoa",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Dương",
        "code": "xa_cam_duong",
        "division_type": "xã",
        "short_codename": "cam_duong",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Bình",
        "code": "xa_cam_binh",
        "division_type": "xã",
        "short_codename": "cam_binh",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Vĩnh",
        "code": "xa_cam_vinh",
        "division_type": "xã",
        "short_codename": "cam_vinh",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Thành",
        "code": "xa_cam_thanh",
        "division_type": "xã",
        "short_codename": "cam_thanh",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Quang",
        "code": "xa_cam_quang",
        "division_type": "xã",
        "short_codename": "cam_quang",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Thạch",
        "code": "xa_cam_thach",
        "division_type": "xã",
        "short_codename": "cam_thach",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Nhượng",
        "code": "xa_cam_nhuong",
        "division_type": "xã",
        "short_codename": "cam_nhuong",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Nam Phúc Thăng",
        "code": "xa_nam_phuc_thang",
        "division_type": "xã",
        "short_codename": "nam_phuc_thang",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Duệ",
        "code": "xa_cam_due",
        "division_type": "xã",
        "short_codename": "cam_due",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Lĩnh",
        "code": "xa_cam_linh",
        "division_type": "xã",
        "short_codename": "cam_linh",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Quan",
        "code": "xa_cam_quan",
        "division_type": "xã",
        "short_codename": "cam_quan",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Hà",
        "code": "xa_cam_ha",
        "division_type": "xã",
        "short_codename": "cam_ha",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Lộc",
        "code": "xa_cam_loc",
        "division_type": "xã",
        "short_codename": "cam_loc",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Hưng",
        "code": "xa_cam_hung",
        "division_type": "xã",
        "short_codename": "cam_hung",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Thịnh",
        "code": "xa_cam_thinh",
        "division_type": "xã",
        "short_codename": "cam_thinh",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Mỹ",
        "code": "xa_cam_my",
        "division_type": "xã",
        "short_codename": "cam_my",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Trung",
        "code": "xa_cam_trung",
        "division_type": "xã",
        "short_codename": "cam_trung",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Sơn",
        "code": "xa_cam_son",
        "division_type": "xã",
        "short_codename": "cam_son",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Lạc",
        "code": "xa_cam_lac",
        "division_type": "xã",
        "short_codename": "cam_lac",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Cẩm Minh",
        "code": "xa_cam_minh",
        "division_type": "xã",
        "short_codename": "cam_minh",
        "parentCode": "huyen_cam_xuyen"
    },
    {
        "name": "Xã Kỳ Xuân",
        "code": "xa_ky_xuan",
        "division_type": "xã",
        "short_codename": "ky_xuan",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Bắc",
        "code": "xa_ky_bac",
        "division_type": "xã",
        "short_codename": "ky_bac",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Phú",
        "code": "xa_ky_phu",
        "division_type": "xã",
        "short_codename": "ky_phu",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Phong",
        "code": "xa_ky_phong",
        "division_type": "xã",
        "short_codename": "ky_phong",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Tiến",
        "code": "xa_ky_tien",
        "division_type": "xã",
        "short_codename": "ky_tien",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Giang",
        "code": "xa_ky_giang",
        "division_type": "xã",
        "short_codename": "ky_giang",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Đồng",
        "code": "xa_ky_dong",
        "division_type": "xã",
        "short_codename": "ky_dong",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Khang",
        "code": "xa_ky_khang",
        "division_type": "xã",
        "short_codename": "ky_khang",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Văn",
        "code": "xa_ky_van",
        "division_type": "xã",
        "short_codename": "ky_van",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Trung",
        "code": "xa_ky_trung",
        "division_type": "xã",
        "short_codename": "ky_trung",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Thọ",
        "code": "xa_ky_tho",
        "division_type": "xã",
        "short_codename": "ky_tho",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Tây",
        "code": "xa_ky_tay",
        "division_type": "xã",
        "short_codename": "ky_tay",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Thượng",
        "code": "xa_ky_thuong",
        "division_type": "xã",
        "short_codename": "ky_thuong",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Hải",
        "code": "xa_ky_hai",
        "division_type": "xã",
        "short_codename": "ky_hai",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Thư",
        "code": "xa_ky_thu",
        "division_type": "xã",
        "short_codename": "ky_thu",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Châu",
        "code": "xa_ky_chau",
        "division_type": "xã",
        "short_codename": "ky_chau",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Tân",
        "code": "xa_ky_tan",
        "division_type": "xã",
        "short_codename": "ky_tan",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Lâm Hợp",
        "code": "xa_lam_hop",
        "division_type": "xã",
        "short_codename": "lam_hop",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Sơn",
        "code": "xa_ky_son",
        "division_type": "xã",
        "short_codename": "ky_son",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Kỳ Lạc",
        "code": "xa_ky_lac",
        "division_type": "xã",
        "short_codename": "ky_lac",
        "parentCode": "huyen_ky_anh"
    },
    {
        "name": "Xã Tân Lộc",
        "code": "xa_tan_loc",
        "division_type": "xã",
        "short_codename": "tan_loc",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Hồng Lộc",
        "code": "xa_hong_loc",
        "division_type": "xã",
        "short_codename": "hong_loc",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Thịnh Lộc",
        "code": "xa_thinh_loc",
        "division_type": "xã",
        "short_codename": "thinh_loc",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Bình An",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Ích Hậu",
        "code": "xa_ich_hau",
        "division_type": "xã",
        "short_codename": "ich_hau",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Phù Lưu",
        "code": "xa_phu_luu",
        "division_type": "xã",
        "short_codename": "phu_luu",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Thị trấn Lộc Hà",
        "code": "thi_tran_loc_ha",
        "division_type": "thị trấn",
        "short_codename": "loc_ha",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Thạch Mỹ",
        "code": "xa_thach_my",
        "division_type": "xã",
        "short_codename": "thach_my",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Thạch Kim",
        "code": "xa_thach_kim",
        "division_type": "xã",
        "short_codename": "thach_kim",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Thạch Châu",
        "code": "xa_thach_chau",
        "division_type": "xã",
        "short_codename": "thach_chau",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Hộ Độ",
        "code": "xa_ho_do",
        "division_type": "xã",
        "short_codename": "ho_do",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Xã Mai Phụ",
        "code": "xa_mai_phu",
        "division_type": "xã",
        "short_codename": "mai_phu",
        "parentCode": "huyen_loc_ha"
    },
    {
        "name": "Phường Hưng Trí",
        "code": "phuong_hung_tri",
        "division_type": "phường",
        "short_codename": "hung_tri",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Xã Kỳ Ninh",
        "code": "xa_ky_ninh",
        "division_type": "xã",
        "short_codename": "ky_ninh",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Xã Kỳ Lợi",
        "code": "xa_ky_loi",
        "division_type": "xã",
        "short_codename": "ky_loi",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Xã Kỳ Hà",
        "code": "xa_ky_ha",
        "division_type": "xã",
        "short_codename": "ky_ha",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Phường Kỳ Trinh",
        "code": "phuong_ky_trinh",
        "division_type": "phường",
        "short_codename": "ky_trinh",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Phường Kỳ Thịnh",
        "code": "phuong_ky_thinh",
        "division_type": "phường",
        "short_codename": "ky_thinh",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Xã Kỳ Hoa",
        "code": "xa_ky_hoa",
        "division_type": "xã",
        "short_codename": "ky_hoa",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Phường Kỳ Phương",
        "code": "phuong_ky_phuong",
        "division_type": "phường",
        "short_codename": "ky_phuong",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Phường Kỳ Long",
        "code": "phuong_ky_long",
        "division_type": "phường",
        "short_codename": "ky_long",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Phường Kỳ Liên",
        "code": "phuong_ky_lien",
        "division_type": "phường",
        "short_codename": "ky_lien",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Xã Kỳ Nam",
        "code": "xa_ky_nam",
        "division_type": "xã",
        "short_codename": "ky_nam",
        "parentCode": "thi_xa_ky_anh"
    },
    {
        "name": "Phường Hải Thành",
        "code": "phuong_hai_thanh",
        "division_type": "phường",
        "short_codename": "hai_thanh",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Phường Đồng Phú",
        "code": "phuong_dong_phu",
        "division_type": "phường",
        "short_codename": "dong_phu",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Phường Bắc Lý",
        "code": "phuong_bac_ly",
        "division_type": "phường",
        "short_codename": "bac_ly",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Phường Nam Lý",
        "code": "phuong_nam_ly",
        "division_type": "phường",
        "short_codename": "nam_ly",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Phường Đồng Hải",
        "code": "phuong_dong_hai",
        "division_type": "phường",
        "short_codename": "dong_hai",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Phường Đồng Sơn",
        "code": "phuong_dong_son",
        "division_type": "phường",
        "short_codename": "dong_son",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Phường Phú Hải",
        "code": "phuong_phu_hai",
        "division_type": "phường",
        "short_codename": "phu_hai",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Phường Bắc Nghĩa",
        "code": "phuong_bac_nghia",
        "division_type": "phường",
        "short_codename": "bac_nghia",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Phường Đức Ninh Đông",
        "code": "phuong_duc_ninh_dong",
        "division_type": "phường",
        "short_codename": "duc_ninh_dong",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Xã Quang Phú",
        "code": "xa_quang_phu",
        "division_type": "xã",
        "short_codename": "quang_phu",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Xã Lộc Ninh",
        "code": "xa_loc_ninh",
        "division_type": "xã",
        "short_codename": "loc_ninh",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Xã Bảo Ninh",
        "code": "xa_bao_ninh",
        "division_type": "xã",
        "short_codename": "bao_ninh",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Xã Nghĩa Ninh",
        "code": "xa_nghia_ninh",
        "division_type": "xã",
        "short_codename": "nghia_ninh",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Xã Thuận Đức",
        "code": "xa_thuan_duc",
        "division_type": "xã",
        "short_codename": "thuan_duc",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Xã Đức Ninh",
        "code": "xa_duc_ninh",
        "division_type": "xã",
        "short_codename": "duc_ninh",
        "parentCode": "thanh_pho_dong_hoi"
    },
    {
        "name": "Thị trấn Quy Đạt",
        "code": "thi_tran_quy_dat",
        "division_type": "thị trấn",
        "short_codename": "quy_dat",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Dân Hóa",
        "code": "xa_dan_hoa",
        "division_type": "xã",
        "short_codename": "dan_hoa",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Trọng Hóa",
        "code": "xa_trong_hoa",
        "division_type": "xã",
        "short_codename": "trong_hoa",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Hóa Phúc",
        "code": "xa_hoa_phuc",
        "division_type": "xã",
        "short_codename": "hoa_phuc",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Hồng Hóa",
        "code": "xa_hong_hoa",
        "division_type": "xã",
        "short_codename": "hong_hoa",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Hóa Thanh",
        "code": "xa_hoa_thanh",
        "division_type": "xã",
        "short_codename": "hoa_thanh",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Hóa Tiến",
        "code": "xa_hoa_tien",
        "division_type": "xã",
        "short_codename": "hoa_tien",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Hóa Hợp",
        "code": "xa_hoa_hop",
        "division_type": "xã",
        "short_codename": "hoa_hop",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Xuân Hóa",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Yên Hóa",
        "code": "xa_yen_hoa",
        "division_type": "xã",
        "short_codename": "yen_hoa",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Minh Hóa",
        "code": "xa_minh_hoa",
        "division_type": "xã",
        "short_codename": "minh_hoa",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Tân Hóa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Hóa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Trung Hóa",
        "code": "xa_trung_hoa",
        "division_type": "xã",
        "short_codename": "trung_hoa",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Xã Thượng Hóa",
        "code": "xa_thuong_hoa",
        "division_type": "xã",
        "short_codename": "thuong_hoa",
        "parentCode": "huyen_minh_hoa"
    },
    {
        "name": "Thị trấn Đồng Lê",
        "code": "thi_tran_dong_le",
        "division_type": "thị trấn",
        "short_codename": "dong_le",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Hương Hóa",
        "code": "xa_huong_hoa",
        "division_type": "xã",
        "short_codename": "huong_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Kim Hóa",
        "code": "xa_kim_hoa",
        "division_type": "xã",
        "short_codename": "kim_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Thanh Hóa",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Thanh Thạch",
        "code": "xa_thanh_thach",
        "division_type": "xã",
        "short_codename": "thanh_thach",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Thuận Hóa",
        "code": "xa_thuan_hoa",
        "division_type": "xã",
        "short_codename": "thuan_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Lâm Hóa",
        "code": "xa_lam_hoa",
        "division_type": "xã",
        "short_codename": "lam_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Lê Hóa",
        "code": "xa_le_hoa",
        "division_type": "xã",
        "short_codename": "le_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Sơn Hóa",
        "code": "xa_son_hoa",
        "division_type": "xã",
        "short_codename": "son_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Đồng Hóa",
        "code": "xa_dong_hoa",
        "division_type": "xã",
        "short_codename": "dong_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Ngư Hóa",
        "code": "xa_ngu_hoa",
        "division_type": "xã",
        "short_codename": "ngu_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Thạch Hóa",
        "code": "xa_thach_hoa",
        "division_type": "xã",
        "short_codename": "thach_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Đức Hóa",
        "code": "xa_duc_hoa",
        "division_type": "xã",
        "short_codename": "duc_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Phong Hóa",
        "code": "xa_phong_hoa",
        "division_type": "xã",
        "short_codename": "phong_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Mai Hóa",
        "code": "xa_mai_hoa",
        "division_type": "xã",
        "short_codename": "mai_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Tiến Hóa",
        "code": "xa_tien_hoa",
        "division_type": "xã",
        "short_codename": "tien_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Châu Hóa",
        "code": "xa_chau_hoa",
        "division_type": "xã",
        "short_codename": "chau_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Cao Quảng",
        "code": "xa_cao_quang",
        "division_type": "xã",
        "short_codename": "cao_quang",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Văn Hóa",
        "code": "xa_van_hoa",
        "division_type": "xã",
        "short_codename": "van_hoa",
        "parentCode": "huyen_tuyen_hoa"
    },
    {
        "name": "Xã Quảng Hợp",
        "code": "xa_quang_hop",
        "division_type": "xã",
        "short_codename": "quang_hop",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Kim",
        "code": "xa_quang_kim",
        "division_type": "xã",
        "short_codename": "quang_kim",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Đông",
        "code": "xa_quang_dong",
        "division_type": "xã",
        "short_codename": "quang_dong",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Phú",
        "code": "xa_quang_phu",
        "division_type": "xã",
        "short_codename": "quang_phu",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Châu",
        "code": "xa_quang_chau",
        "division_type": "xã",
        "short_codename": "quang_chau",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Thạch",
        "code": "xa_quang_thach",
        "division_type": "xã",
        "short_codename": "quang_thach",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Lưu",
        "code": "xa_quang_luu",
        "division_type": "xã",
        "short_codename": "quang_luu",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Tùng",
        "code": "xa_quang_tung",
        "division_type": "xã",
        "short_codename": "quang_tung",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Cảnh Dương",
        "code": "xa_canh_duong",
        "division_type": "xã",
        "short_codename": "canh_duong",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Tiến",
        "code": "xa_quang_tien",
        "division_type": "xã",
        "short_codename": "quang_tien",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Hưng",
        "code": "xa_quang_hung",
        "division_type": "xã",
        "short_codename": "quang_hung",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Xuân",
        "code": "xa_quang_xuan",
        "division_type": "xã",
        "short_codename": "quang_xuan",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Cảnh Hóa",
        "code": "xa_canh_hoa",
        "division_type": "xã",
        "short_codename": "canh_hoa",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Liên Trường",
        "code": "xa_lien_truong",
        "division_type": "xã",
        "short_codename": "lien_truong",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Phương",
        "code": "xa_quang_phuong",
        "division_type": "xã",
        "short_codename": "quang_phuong",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Phù Hóa",
        "code": "xa_phu_hoa",
        "division_type": "xã",
        "short_codename": "phu_hoa",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Xã Quảng Thanh",
        "code": "xa_quang_thanh",
        "division_type": "xã",
        "short_codename": "quang_thanh",
        "parentCode": "huyen_quang_trach"
    },
    {
        "name": "Thị trấn Hoàn Lão",
        "code": "thi_tran_hoan_lao",
        "division_type": "thị trấn",
        "short_codename": "hoan_lao",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Thị trấn NT Việt Trung",
        "code": "thi_tran_nt_viet_trung",
        "division_type": "thị trấn",
        "short_codename": "nt_viet_trung",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Xuân Trạch",
        "code": "xa_xuan_trach",
        "division_type": "xã",
        "short_codename": "xuan_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Mỹ Trạch",
        "code": "xa_my_trach",
        "division_type": "xã",
        "short_codename": "my_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Hạ Trạch",
        "code": "xa_ha_trach",
        "division_type": "xã",
        "short_codename": "ha_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Bắc Trạch",
        "code": "xa_bac_trach",
        "division_type": "xã",
        "short_codename": "bac_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Lâm Trạch",
        "code": "xa_lam_trach",
        "division_type": "xã",
        "short_codename": "lam_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Thanh Trạch",
        "code": "xa_thanh_trach",
        "division_type": "xã",
        "short_codename": "thanh_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Liên Trạch",
        "code": "xa_lien_trach",
        "division_type": "xã",
        "short_codename": "lien_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Phúc Trạch",
        "code": "xa_phuc_trach",
        "division_type": "xã",
        "short_codename": "phuc_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Cự Nẫm",
        "code": "xa_cu_nam",
        "division_type": "xã",
        "short_codename": "cu_nam",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Hải Phú",
        "code": "xa_hai_phu",
        "division_type": "xã",
        "short_codename": "hai_phu",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Thượng Trạch",
        "code": "xa_thuong_trach",
        "division_type": "xã",
        "short_codename": "thuong_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Sơn Lộc",
        "code": "xa_son_loc",
        "division_type": "xã",
        "short_codename": "son_loc",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Hưng Trạch",
        "code": "xa_hung_trach",
        "division_type": "xã",
        "short_codename": "hung_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Đồng Trạch",
        "code": "xa_dong_trach",
        "division_type": "xã",
        "short_codename": "dong_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Đức Trạch",
        "code": "xa_duc_trach",
        "division_type": "xã",
        "short_codename": "duc_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Thị trấn Phong Nha",
        "code": "thi_tran_phong_nha",
        "division_type": "thị trấn",
        "short_codename": "phong_nha",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Vạn Trạch",
        "code": "xa_van_trach",
        "division_type": "xã",
        "short_codename": "van_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Phú Định",
        "code": "xa_phu_dinh",
        "division_type": "xã",
        "short_codename": "phu_dinh",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Trung Trạch",
        "code": "xa_trung_trach",
        "division_type": "xã",
        "short_codename": "trung_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Tây Trạch",
        "code": "xa_tay_trach",
        "division_type": "xã",
        "short_codename": "tay_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Hòa Trạch",
        "code": "xa_hoa_trach",
        "division_type": "xã",
        "short_codename": "hoa_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Đại Trạch",
        "code": "xa_dai_trach",
        "division_type": "xã",
        "short_codename": "dai_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Nhân Trạch",
        "code": "xa_nhan_trach",
        "division_type": "xã",
        "short_codename": "nhan_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Tân Trạch",
        "code": "xa_tan_trach",
        "division_type": "xã",
        "short_codename": "tan_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Nam Trạch",
        "code": "xa_nam_trach",
        "division_type": "xã",
        "short_codename": "nam_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Xã Lý Trạch",
        "code": "xa_ly_trach",
        "division_type": "xã",
        "short_codename": "ly_trach",
        "parentCode": "huyen_bo_trach"
    },
    {
        "name": "Thị trấn Quán Hàu",
        "code": "thi_tran_quan_hau",
        "division_type": "thị trấn",
        "short_codename": "quan_hau",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Trường Sơn",
        "code": "xa_truong_son",
        "division_type": "xã",
        "short_codename": "truong_son",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Lương Ninh",
        "code": "xa_luong_ninh",
        "division_type": "xã",
        "short_codename": "luong_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Vĩnh Ninh",
        "code": "xa_vinh_ninh",
        "division_type": "xã",
        "short_codename": "vinh_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Võ Ninh",
        "code": "xa_vo_ninh",
        "division_type": "xã",
        "short_codename": "vo_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Hải Ninh",
        "code": "xa_hai_ninh",
        "division_type": "xã",
        "short_codename": "hai_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Hàm Ninh",
        "code": "xa_ham_ninh",
        "division_type": "xã",
        "short_codename": "ham_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Duy Ninh",
        "code": "xa_duy_ninh",
        "division_type": "xã",
        "short_codename": "duy_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Gia Ninh",
        "code": "xa_gia_ninh",
        "division_type": "xã",
        "short_codename": "gia_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Trường Xuân",
        "code": "xa_truong_xuan",
        "division_type": "xã",
        "short_codename": "truong_xuan",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Hiền Ninh",
        "code": "xa_hien_ninh",
        "division_type": "xã",
        "short_codename": "hien_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Tân Ninh",
        "code": "xa_tan_ninh",
        "division_type": "xã",
        "short_codename": "tan_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Xuân Ninh",
        "code": "xa_xuan_ninh",
        "division_type": "xã",
        "short_codename": "xuan_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã An Ninh",
        "code": "xa_an_ninh",
        "division_type": "xã",
        "short_codename": "an_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Xã Vạn Ninh",
        "code": "xa_van_ninh",
        "division_type": "xã",
        "short_codename": "van_ninh",
        "parentCode": "huyen_quang_ninh"
    },
    {
        "name": "Thị trấn NT Lệ Ninh",
        "code": "thi_tran_nt_le_ninh",
        "division_type": "thị trấn",
        "short_codename": "nt_le_ninh",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Thị trấn Kiến Giang",
        "code": "thi_tran_kien_giang",
        "division_type": "thị trấn",
        "short_codename": "kien_giang",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Hồng Thủy",
        "code": "xa_hong_thuy",
        "division_type": "xã",
        "short_codename": "hong_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Ngư Thủy Bắc",
        "code": "xa_ngu_thuy_bac",
        "division_type": "xã",
        "short_codename": "ngu_thuy_bac",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Hoa Thủy",
        "code": "xa_hoa_thuy",
        "division_type": "xã",
        "short_codename": "hoa_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Thanh Thủy",
        "code": "xa_thanh_thuy",
        "division_type": "xã",
        "short_codename": "thanh_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã An Thủy",
        "code": "xa_an_thuy",
        "division_type": "xã",
        "short_codename": "an_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Phong Thủy",
        "code": "xa_phong_thuy",
        "division_type": "xã",
        "short_codename": "phong_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Cam Thủy",
        "code": "xa_cam_thuy",
        "division_type": "xã",
        "short_codename": "cam_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Ngân Thủy",
        "code": "xa_ngan_thuy",
        "division_type": "xã",
        "short_codename": "ngan_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Sơn Thủy",
        "code": "xa_son_thuy",
        "division_type": "xã",
        "short_codename": "son_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Lộc Thủy",
        "code": "xa_loc_thuy",
        "division_type": "xã",
        "short_codename": "loc_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Liên Thủy",
        "code": "xa_lien_thuy",
        "division_type": "xã",
        "short_codename": "lien_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Hưng Thủy",
        "code": "xa_hung_thuy",
        "division_type": "xã",
        "short_codename": "hung_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Dương Thủy",
        "code": "xa_duong_thuy",
        "division_type": "xã",
        "short_codename": "duong_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Tân Thủy",
        "code": "xa_tan_thuy",
        "division_type": "xã",
        "short_codename": "tan_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Phú Thủy",
        "code": "xa_phu_thuy",
        "division_type": "xã",
        "short_codename": "phu_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Xuân Thủy",
        "code": "xa_xuan_thuy",
        "division_type": "xã",
        "short_codename": "xuan_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Mỹ Thủy",
        "code": "xa_my_thuy",
        "division_type": "xã",
        "short_codename": "my_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Ngư Thủy",
        "code": "xa_ngu_thuy",
        "division_type": "xã",
        "short_codename": "ngu_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Mai Thủy",
        "code": "xa_mai_thuy",
        "division_type": "xã",
        "short_codename": "mai_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Sen Thủy",
        "code": "xa_sen_thuy",
        "division_type": "xã",
        "short_codename": "sen_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Thái Thủy",
        "code": "xa_thai_thuy",
        "division_type": "xã",
        "short_codename": "thai_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Kim Thủy",
        "code": "xa_kim_thuy",
        "division_type": "xã",
        "short_codename": "kim_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Trường Thủy",
        "code": "xa_truong_thuy",
        "division_type": "xã",
        "short_codename": "truong_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Xã Lâm Thủy",
        "code": "xa_lam_thuy",
        "division_type": "xã",
        "short_codename": "lam_thuy",
        "parentCode": "huyen_le_thuy"
    },
    {
        "name": "Phường Ba Đồn",
        "code": "phuong_ba_don",
        "division_type": "phường",
        "short_codename": "ba_don",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Phường Quảng Long",
        "code": "phuong_quang_long",
        "division_type": "phường",
        "short_codename": "quang_long",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Phường Quảng Thọ",
        "code": "phuong_quang_tho",
        "division_type": "phường",
        "short_codename": "quang_tho",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Tiên",
        "code": "xa_quang_tien",
        "division_type": "xã",
        "short_codename": "quang_tien",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Phường Quảng Phong",
        "code": "phuong_quang_phong",
        "division_type": "phường",
        "short_codename": "quang_phong",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Phường Quảng Thuận",
        "code": "phuong_quang_thuan",
        "division_type": "phường",
        "short_codename": "quang_thuan",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Tân",
        "code": "xa_quang_tan",
        "division_type": "xã",
        "short_codename": "quang_tan",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Hải",
        "code": "xa_quang_hai",
        "division_type": "xã",
        "short_codename": "quang_hai",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Sơn",
        "code": "xa_quang_son",
        "division_type": "xã",
        "short_codename": "quang_son",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Lộc",
        "code": "xa_quang_loc",
        "division_type": "xã",
        "short_codename": "quang_loc",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Thủy",
        "code": "xa_quang_thuy",
        "division_type": "xã",
        "short_codename": "quang_thuy",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Văn",
        "code": "xa_quang_van",
        "division_type": "xã",
        "short_codename": "quang_van",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Phường Quảng Phúc",
        "code": "phuong_quang_phuc",
        "division_type": "phường",
        "short_codename": "quang_phuc",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Hòa",
        "code": "xa_quang_hoa",
        "division_type": "xã",
        "short_codename": "quang_hoa",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Xã Quảng Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "thi_xa_ba_don"
    },
    {
        "name": "Phường Đông Giang",
        "code": "phuong_dong_giang",
        "division_type": "phường",
        "short_codename": "dong_giang",
        "parentCode": "thanh_pho_dong_ha"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_dong_ha"
    },
    {
        "name": "Phường Đông Lễ",
        "code": "phuong_dong_le",
        "division_type": "phường",
        "short_codename": "dong_le",
        "parentCode": "thanh_pho_dong_ha"
    },
    {
        "name": "Phường Đông Thanh",
        "code": "phuong_dong_thanh",
        "division_type": "phường",
        "short_codename": "dong_thanh",
        "parentCode": "thanh_pho_dong_ha"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_dong_ha"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_dong_ha"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_dong_ha"
    },
    {
        "name": "Phường Đông Lương",
        "code": "phuong_dong_luong",
        "division_type": "phường",
        "short_codename": "dong_luong",
        "parentCode": "thanh_pho_dong_ha"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_dong_ha"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thi_xa_quang_tri"
    },
    {
        "name": "Phường An Đôn",
        "code": "phuong_an_don",
        "division_type": "phường",
        "short_codename": "an_don",
        "parentCode": "thi_xa_quang_tri"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thi_xa_quang_tri"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thi_xa_quang_tri"
    },
    {
        "name": "Xã Hải Lệ",
        "code": "xa_hai_le",
        "division_type": "xã",
        "short_codename": "hai_le",
        "parentCode": "thi_xa_quang_tri"
    },
    {
        "name": "Thị trấn Hồ Xá",
        "code": "thi_tran_ho_xa",
        "division_type": "thị trấn",
        "short_codename": "ho_xa",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Thị trấn Bến Quan",
        "code": "thi_tran_ben_quan",
        "division_type": "thị trấn",
        "short_codename": "ben_quan",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Thái",
        "code": "xa_vinh_thai",
        "division_type": "xã",
        "short_codename": "vinh_thai",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Tú",
        "code": "xa_vinh_tu",
        "division_type": "xã",
        "short_codename": "vinh_tu",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Chấp",
        "code": "xa_vinh_chap",
        "division_type": "xã",
        "short_codename": "vinh_chap",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Trung Nam",
        "code": "xa_trung_nam",
        "division_type": "xã",
        "short_codename": "trung_nam",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Kim Thạch",
        "code": "xa_kim_thach",
        "division_type": "xã",
        "short_codename": "kim_thach",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Long",
        "code": "xa_vinh_long",
        "division_type": "xã",
        "short_codename": "vinh_long",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Khê",
        "code": "xa_vinh_khe",
        "division_type": "xã",
        "short_codename": "vinh_khe",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Hòa",
        "code": "xa_vinh_hoa",
        "division_type": "xã",
        "short_codename": "vinh_hoa",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Thủy",
        "code": "xa_vinh_thuy",
        "division_type": "xã",
        "short_codename": "vinh_thuy",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Lâm",
        "code": "xa_vinh_lam",
        "division_type": "xã",
        "short_codename": "vinh_lam",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Hiền Thành",
        "code": "xa_hien_thanh",
        "division_type": "xã",
        "short_codename": "hien_thanh",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Thị trấn Cửa Tùng",
        "code": "thi_tran_cua_tung",
        "division_type": "thị trấn",
        "short_codename": "cua_tung",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Hà",
        "code": "xa_vinh_ha",
        "division_type": "xã",
        "short_codename": "vinh_ha",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Sơn",
        "code": "xa_vinh_son",
        "division_type": "xã",
        "short_codename": "vinh_son",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Giang",
        "code": "xa_vinh_giang",
        "division_type": "xã",
        "short_codename": "vinh_giang",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Xã Vĩnh Ô",
        "code": "xa_vinh_o",
        "division_type": "xã",
        "short_codename": "vinh_o",
        "parentCode": "huyen_vinh_linh"
    },
    {
        "name": "Thị trấn Khe Sanh",
        "code": "thi_tran_khe_sanh",
        "division_type": "thị trấn",
        "short_codename": "khe_sanh",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Thị trấn Lao Bảo",
        "code": "thi_tran_lao_bao",
        "division_type": "thị trấn",
        "short_codename": "lao_bao",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Hướng Lập",
        "code": "xa_huong_lap",
        "division_type": "xã",
        "short_codename": "huong_lap",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Hướng Việt",
        "code": "xa_huong_viet",
        "division_type": "xã",
        "short_codename": "huong_viet",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Hướng Phùng",
        "code": "xa_huong_phung",
        "division_type": "xã",
        "short_codename": "huong_phung",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Hướng Sơn",
        "code": "xa_huong_son",
        "division_type": "xã",
        "short_codename": "huong_son",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Hướng Linh",
        "code": "xa_huong_linh",
        "division_type": "xã",
        "short_codename": "huong_linh",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Tân Hợp",
        "code": "xa_tan_hop",
        "division_type": "xã",
        "short_codename": "tan_hop",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Hướng Tân",
        "code": "xa_huong_tan",
        "division_type": "xã",
        "short_codename": "huong_tan",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Tân Liên",
        "code": "xa_tan_lien",
        "division_type": "xã",
        "short_codename": "tan_lien",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Húc",
        "code": "xa_huc",
        "division_type": "xã",
        "short_codename": "huc",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Thuận",
        "code": "xa_thuan",
        "division_type": "xã",
        "short_codename": "thuan",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Hướng Lộc",
        "code": "xa_huong_loc",
        "division_type": "xã",
        "short_codename": "huong_loc",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Ba Tầng",
        "code": "xa_ba_tang",
        "division_type": "xã",
        "short_codename": "ba_tang",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Thanh",
        "code": "xa_thanh",
        "division_type": "xã",
        "short_codename": "thanh",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã A Dơi",
        "code": "xa_a_doi",
        "division_type": "xã",
        "short_codename": "a_doi",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Lìa",
        "code": "xa_lia",
        "division_type": "xã",
        "short_codename": "lia",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Xã Xy",
        "code": "xa_xy",
        "division_type": "xã",
        "short_codename": "xy",
        "parentCode": "huyen_huong_hoa"
    },
    {
        "name": "Thị trấn Gio Linh",
        "code": "thi_tran_gio_linh",
        "division_type": "thị trấn",
        "short_codename": "gio_linh",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Thị trấn Cửa Việt",
        "code": "thi_tran_cua_viet",
        "division_type": "thị trấn",
        "short_codename": "cua_viet",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Trung Giang",
        "code": "xa_trung_giang",
        "division_type": "xã",
        "short_codename": "trung_giang",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Trung Hải",
        "code": "xa_trung_hai",
        "division_type": "xã",
        "short_codename": "trung_hai",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Trung Sơn",
        "code": "xa_trung_son",
        "division_type": "xã",
        "short_codename": "trung_son",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Phong Bình",
        "code": "xa_phong_binh",
        "division_type": "xã",
        "short_codename": "phong_binh",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Gio Mỹ",
        "code": "xa_gio_my",
        "division_type": "xã",
        "short_codename": "gio_my",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Gio Hải",
        "code": "xa_gio_hai",
        "division_type": "xã",
        "short_codename": "gio_hai",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Gio An",
        "code": "xa_gio_an",
        "division_type": "xã",
        "short_codename": "gio_an",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Gio Châu",
        "code": "xa_gio_chau",
        "division_type": "xã",
        "short_codename": "gio_chau",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Gio Việt",
        "code": "xa_gio_viet",
        "division_type": "xã",
        "short_codename": "gio_viet",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Linh Trường",
        "code": "xa_linh_truong",
        "division_type": "xã",
        "short_codename": "linh_truong",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Gio Sơn",
        "code": "xa_gio_son",
        "division_type": "xã",
        "short_codename": "gio_son",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Gio Mai",
        "code": "xa_gio_mai",
        "division_type": "xã",
        "short_codename": "gio_mai",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Hải Thái",
        "code": "xa_hai_thai",
        "division_type": "xã",
        "short_codename": "hai_thai",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Linh Hải",
        "code": "xa_linh_hai",
        "division_type": "xã",
        "short_codename": "linh_hai",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Xã Gio Quang",
        "code": "xa_gio_quang",
        "division_type": "xã",
        "short_codename": "gio_quang",
        "parentCode": "huyen_gio_linh"
    },
    {
        "name": "Thị trấn Krông Klang",
        "code": "thi_tran_krong_klang",
        "division_type": "thị trấn",
        "short_codename": "krong_klang",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã Mò Ó",
        "code": "xa_mo_o",
        "division_type": "xã",
        "short_codename": "mo_o",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã Hướng Hiệp",
        "code": "xa_huong_hiep",
        "division_type": "xã",
        "short_codename": "huong_hiep",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã Đa Krông",
        "code": "xa_da_krong",
        "division_type": "xã",
        "short_codename": "da_krong",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã Triệu Nguyên",
        "code": "xa_trieu_nguyen",
        "division_type": "xã",
        "short_codename": "trieu_nguyen",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã Ba Lòng",
        "code": "xa_ba_long",
        "division_type": "xã",
        "short_codename": "ba_long",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã Ba Nang",
        "code": "xa_ba_nang",
        "division_type": "xã",
        "short_codename": "ba_nang",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã Tà Long",
        "code": "xa_ta_long",
        "division_type": "xã",
        "short_codename": "ta_long",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã Húc Nghì",
        "code": "xa_huc_nghi",
        "division_type": "xã",
        "short_codename": "huc_nghi",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã A Vao",
        "code": "xa_a_vao",
        "division_type": "xã",
        "short_codename": "a_vao",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã Tà Rụt",
        "code": "xa_ta_rut",
        "division_type": "xã",
        "short_codename": "ta_rut",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã A Bung",
        "code": "xa_a_bung",
        "division_type": "xã",
        "short_codename": "a_bung",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Xã A Ngo",
        "code": "xa_a_ngo",
        "division_type": "xã",
        "short_codename": "a_ngo",
        "parentCode": "huyen_da_krong"
    },
    {
        "name": "Thị trấn Cam Lộ",
        "code": "thi_tran_cam_lo",
        "division_type": "thị trấn",
        "short_codename": "cam_lo",
        "parentCode": "huyen_cam_lo"
    },
    {
        "name": "Xã Cam Tuyền",
        "code": "xa_cam_tuyen",
        "division_type": "xã",
        "short_codename": "cam_tuyen",
        "parentCode": "huyen_cam_lo"
    },
    {
        "name": "Xã Thanh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_cam_lo"
    },
    {
        "name": "Xã Cam Thủy",
        "code": "xa_cam_thuy",
        "division_type": "xã",
        "short_codename": "cam_thuy",
        "parentCode": "huyen_cam_lo"
    },
    {
        "name": "Xã Cam Thành",
        "code": "xa_cam_thanh",
        "division_type": "xã",
        "short_codename": "cam_thanh",
        "parentCode": "huyen_cam_lo"
    },
    {
        "name": "Xã Cam Hiếu",
        "code": "xa_cam_hieu",
        "division_type": "xã",
        "short_codename": "cam_hieu",
        "parentCode": "huyen_cam_lo"
    },
    {
        "name": "Xã Cam Chính",
        "code": "xa_cam_chinh",
        "division_type": "xã",
        "short_codename": "cam_chinh",
        "parentCode": "huyen_cam_lo"
    },
    {
        "name": "Xã Cam Nghĩa",
        "code": "xa_cam_nghia",
        "division_type": "xã",
        "short_codename": "cam_nghia",
        "parentCode": "huyen_cam_lo"
    },
    {
        "name": "Thị Trấn Ái Tử",
        "code": "thi_tran_ai_tu",
        "division_type": "thị trấn",
        "short_codename": "ai_tu",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu An",
        "code": "xa_trieu_an",
        "division_type": "xã",
        "short_codename": "trieu_an",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Vân",
        "code": "xa_trieu_van",
        "division_type": "xã",
        "short_codename": "trieu_van",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Phước",
        "code": "xa_trieu_phuoc",
        "division_type": "xã",
        "short_codename": "trieu_phuoc",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Độ",
        "code": "xa_trieu_do",
        "division_type": "xã",
        "short_codename": "trieu_do",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Trạch",
        "code": "xa_trieu_trach",
        "division_type": "xã",
        "short_codename": "trieu_trach",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Thuận",
        "code": "xa_trieu_thuan",
        "division_type": "xã",
        "short_codename": "trieu_thuan",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Đại",
        "code": "xa_trieu_dai",
        "division_type": "xã",
        "short_codename": "trieu_dai",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Hòa",
        "code": "xa_trieu_hoa",
        "division_type": "xã",
        "short_codename": "trieu_hoa",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Lăng",
        "code": "xa_trieu_lang",
        "division_type": "xã",
        "short_codename": "trieu_lang",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Sơn",
        "code": "xa_trieu_son",
        "division_type": "xã",
        "short_codename": "trieu_son",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Long",
        "code": "xa_trieu_long",
        "division_type": "xã",
        "short_codename": "trieu_long",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Tài",
        "code": "xa_trieu_tai",
        "division_type": "xã",
        "short_codename": "trieu_tai",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Trung",
        "code": "xa_trieu_trung",
        "division_type": "xã",
        "short_codename": "trieu_trung",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Ái",
        "code": "xa_trieu_ai",
        "division_type": "xã",
        "short_codename": "trieu_ai",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Thượng",
        "code": "xa_trieu_thuong",
        "division_type": "xã",
        "short_codename": "trieu_thuong",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Giang",
        "code": "xa_trieu_giang",
        "division_type": "xã",
        "short_codename": "trieu_giang",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Xã Triệu Thành",
        "code": "xa_trieu_thanh",
        "division_type": "xã",
        "short_codename": "trieu_thanh",
        "parentCode": "huyen_trieu_phong"
    },
    {
        "name": "Thị trấn Diên Sanh",
        "code": "thi_tran_dien_sanh",
        "division_type": "thị trấn",
        "short_codename": "dien_sanh",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải An",
        "code": "xa_hai_an",
        "division_type": "xã",
        "short_codename": "hai_an",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Ba",
        "code": "xa_hai_ba",
        "division_type": "xã",
        "short_codename": "hai_ba",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Quy",
        "code": "xa_hai_quy",
        "division_type": "xã",
        "short_codename": "hai_quy",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Quế",
        "code": "xa_hai_que",
        "division_type": "xã",
        "short_codename": "hai_que",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Hưng",
        "code": "xa_hai_hung",
        "division_type": "xã",
        "short_codename": "hai_hung",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Phú",
        "code": "xa_hai_phu",
        "division_type": "xã",
        "short_codename": "hai_phu",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Thượng",
        "code": "xa_hai_thuong",
        "division_type": "xã",
        "short_codename": "hai_thuong",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Dương",
        "code": "xa_hai_duong",
        "division_type": "xã",
        "short_codename": "hai_duong",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Định",
        "code": "xa_hai_dinh",
        "division_type": "xã",
        "short_codename": "hai_dinh",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Lâm",
        "code": "xa_hai_lam",
        "division_type": "xã",
        "short_codename": "hai_lam",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Phong",
        "code": "xa_hai_phong",
        "division_type": "xã",
        "short_codename": "hai_phong",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Trường",
        "code": "xa_hai_truong",
        "division_type": "xã",
        "short_codename": "hai_truong",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Sơn",
        "code": "xa_hai_son",
        "division_type": "xã",
        "short_codename": "hai_son",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Chánh",
        "code": "xa_hai_chanh",
        "division_type": "xã",
        "short_codename": "hai_chanh",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Xã Hải Khê",
        "code": "xa_hai_khe",
        "division_type": "xã",
        "short_codename": "hai_khe",
        "parentCode": "huyen_hai_lang"
    },
    {
        "name": "Phường Phú Thuận",
        "code": "phuong_phu_thuan",
        "division_type": "phường",
        "short_codename": "phu_thuan",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Phú Bình",
        "code": "phuong_phu_binh",
        "division_type": "phường",
        "short_codename": "phu_binh",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Tây Lộc",
        "code": "phuong_tay_loc",
        "division_type": "phường",
        "short_codename": "tay_loc",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Thuận Lộc",
        "code": "phuong_thuan_loc",
        "division_type": "phường",
        "short_codename": "thuan_loc",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Phú Hiệp",
        "code": "phuong_phu_hiep",
        "division_type": "phường",
        "short_codename": "phu_hiep",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Phú Hậu",
        "code": "phuong_phu_hau",
        "division_type": "phường",
        "short_codename": "phu_hau",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Thuận Hòa",
        "code": "phuong_thuan_hoa",
        "division_type": "phường",
        "short_codename": "thuan_hoa",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Thuận Thành",
        "code": "phuong_thuan_thanh",
        "division_type": "phường",
        "short_codename": "thuan_thanh",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Phú Hòa",
        "code": "phuong_phu_hoa",
        "division_type": "phường",
        "short_codename": "phu_hoa",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Phú Cát",
        "code": "phuong_phu_cat",
        "division_type": "phường",
        "short_codename": "phu_cat",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Kim Long",
        "code": "phuong_kim_long",
        "division_type": "phường",
        "short_codename": "kim_long",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Vỹ Dạ",
        "code": "phuong_vy_da",
        "division_type": "phường",
        "short_codename": "vy_da",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Phường Đúc",
        "code": "phuong_phuong_duc",
        "division_type": "phường",
        "short_codename": "phuong_duc",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Vĩnh Ninh",
        "code": "phuong_vinh_ninh",
        "division_type": "phường",
        "short_codename": "vinh_ninh",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Phú Hội",
        "code": "phuong_phu_hoi",
        "division_type": "phường",
        "short_codename": "phu_hoi",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Phú Nhuận",
        "code": "phuong_phu_nhuan",
        "division_type": "phường",
        "short_codename": "phu_nhuan",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Xuân Phú",
        "code": "phuong_xuan_phu",
        "division_type": "phường",
        "short_codename": "xuan_phu",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Trường An",
        "code": "phuong_truong_an",
        "division_type": "phường",
        "short_codename": "truong_an",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Phước Vĩnh",
        "code": "phuong_phuoc_vinh",
        "division_type": "phường",
        "short_codename": "phuoc_vinh",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường An Cựu",
        "code": "phuong_an_cuu",
        "division_type": "phường",
        "short_codename": "an_cuu",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường An Hòa",
        "code": "phuong_an_hoa",
        "division_type": "phường",
        "short_codename": "an_hoa",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Hương Sơ",
        "code": "phuong_huong_so",
        "division_type": "phường",
        "short_codename": "huong_so",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Thuỷ Biều",
        "code": "phuong_thuy_bieu",
        "division_type": "phường",
        "short_codename": "thuy_bieu",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Hương Long",
        "code": "phuong_huong_long",
        "division_type": "phường",
        "short_codename": "huong_long",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường Thuỷ Xuân",
        "code": "phuong_thuy_xuan",
        "division_type": "phường",
        "short_codename": "thuy_xuan",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường An Đông",
        "code": "phuong_an_dong",
        "division_type": "phường",
        "short_codename": "an_dong",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Phường An Tây",
        "code": "phuong_an_tay",
        "division_type": "phường",
        "short_codename": "an_tay",
        "parentCode": "thanh_pho_hue"
    },
    {
        "name": "Thị trấn Phong Điền",
        "code": "thi_tran_phong_dien",
        "division_type": "thị trấn",
        "short_codename": "phong_dien",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Điền Hương",
        "code": "xa_dien_huong",
        "division_type": "xã",
        "short_codename": "dien_huong",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Điền Môn",
        "code": "xa_dien_mon",
        "division_type": "xã",
        "short_codename": "dien_mon",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Điền Lộc",
        "code": "xa_dien_loc",
        "division_type": "xã",
        "short_codename": "dien_loc",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong Bình",
        "code": "xa_phong_binh",
        "division_type": "xã",
        "short_codename": "phong_binh",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Điền Hòa",
        "code": "xa_dien_hoa",
        "division_type": "xã",
        "short_codename": "dien_hoa",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong Chương",
        "code": "xa_phong_chuong",
        "division_type": "xã",
        "short_codename": "phong_chuong",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong Hải",
        "code": "xa_phong_hai",
        "division_type": "xã",
        "short_codename": "phong_hai",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Điền Hải",
        "code": "xa_dien_hai",
        "division_type": "xã",
        "short_codename": "dien_hai",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong Hòa",
        "code": "xa_phong_hoa",
        "division_type": "xã",
        "short_codename": "phong_hoa",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong Thu",
        "code": "xa_phong_thu",
        "division_type": "xã",
        "short_codename": "phong_thu",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong Hiền",
        "code": "xa_phong_hien",
        "division_type": "xã",
        "short_codename": "phong_hien",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong Mỹ",
        "code": "xa_phong_my",
        "division_type": "xã",
        "short_codename": "phong_my",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong An",
        "code": "xa_phong_an",
        "division_type": "xã",
        "short_codename": "phong_an",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong Xuân",
        "code": "xa_phong_xuan",
        "division_type": "xã",
        "short_codename": "phong_xuan",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Phong Sơn",
        "code": "xa_phong_son",
        "division_type": "xã",
        "short_codename": "phong_son",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Thị trấn Sịa",
        "code": "thi_tran_sia",
        "division_type": "thị trấn",
        "short_codename": "sia",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng Thái",
        "code": "xa_quang_thai",
        "division_type": "xã",
        "short_codename": "quang_thai",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng Ngạn",
        "code": "xa_quang_ngan",
        "division_type": "xã",
        "short_codename": "quang_ngan",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng Lợi",
        "code": "xa_quang_loi",
        "division_type": "xã",
        "short_codename": "quang_loi",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng Công",
        "code": "xa_quang_cong",
        "division_type": "xã",
        "short_codename": "quang_cong",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng Phước",
        "code": "xa_quang_phuoc",
        "division_type": "xã",
        "short_codename": "quang_phuoc",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng Vinh",
        "code": "xa_quang_vinh",
        "division_type": "xã",
        "short_codename": "quang_vinh",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng An",
        "code": "xa_quang_an",
        "division_type": "xã",
        "short_codename": "quang_an",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng Thành",
        "code": "xa_quang_thanh",
        "division_type": "xã",
        "short_codename": "quang_thanh",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng Thọ",
        "code": "xa_quang_tho",
        "division_type": "xã",
        "short_codename": "quang_tho",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Xã Quảng Phú",
        "code": "xa_quang_phu",
        "division_type": "xã",
        "short_codename": "quang_phu",
        "parentCode": "huyen_quang_dien"
    },
    {
        "name": "Thị trấn Thuận An",
        "code": "thi_tran_thuan_an",
        "division_type": "thị trấn",
        "short_codename": "thuan_an",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Thuận",
        "code": "xa_phu_thuan",
        "division_type": "xã",
        "short_codename": "phu_thuan",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Dương",
        "code": "xa_phu_duong",
        "division_type": "xã",
        "short_codename": "phu_duong",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Mậu",
        "code": "xa_phu_mau",
        "division_type": "xã",
        "short_codename": "phu_mau",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú An",
        "code": "xa_phu_an",
        "division_type": "xã",
        "short_codename": "phu_an",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Hải",
        "code": "xa_phu_hai",
        "division_type": "xã",
        "short_codename": "phu_hai",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Xuân",
        "code": "xa_phu_xuan",
        "division_type": "xã",
        "short_codename": "phu_xuan",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Diên",
        "code": "xa_phu_dien",
        "division_type": "xã",
        "short_codename": "phu_dien",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Thanh",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "phu_thanh",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Mỹ",
        "code": "xa_phu_my",
        "division_type": "xã",
        "short_codename": "phu_my",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Thượng",
        "code": "xa_phu_thuong",
        "division_type": "xã",
        "short_codename": "phu_thuong",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Hồ",
        "code": "xa_phu_ho",
        "division_type": "xã",
        "short_codename": "phu_ho",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Vinh Xuân",
        "code": "xa_vinh_xuan",
        "division_type": "xã",
        "short_codename": "vinh_xuan",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Lương",
        "code": "xa_phu_luong",
        "division_type": "xã",
        "short_codename": "phu_luong",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Thị trấn Phú Đa",
        "code": "thi_tran_phu_da",
        "division_type": "thị trấn",
        "short_codename": "phu_da",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Vinh Thanh",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Vinh An",
        "code": "xa_vinh_an",
        "division_type": "xã",
        "short_codename": "vinh_an",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Phú Gia",
        "code": "xa_phu_gia",
        "division_type": "xã",
        "short_codename": "phu_gia",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Xã Vinh Hà",
        "code": "xa_vinh_ha",
        "division_type": "xã",
        "short_codename": "vinh_ha",
        "parentCode": "huyen_phu_vang"
    },
    {
        "name": "Phường Phú Bài",
        "code": "phuong_phu_bai",
        "division_type": "phường",
        "short_codename": "phu_bai",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Xã Thủy Vân",
        "code": "xa_thuy_van",
        "division_type": "xã",
        "short_codename": "thuy_van",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Xã Thủy Thanh",
        "code": "xa_thuy_thanh",
        "division_type": "xã",
        "short_codename": "thuy_thanh",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Phường Thủy Dương",
        "code": "phuong_thuy_duong",
        "division_type": "phường",
        "short_codename": "thuy_duong",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Phường Thủy Phương",
        "code": "phuong_thuy_phuong",
        "division_type": "phường",
        "short_codename": "thuy_phuong",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Phường Thủy Châu",
        "code": "phuong_thuy_chau",
        "division_type": "phường",
        "short_codename": "thuy_chau",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Phường Thủy Lương",
        "code": "phuong_thuy_luong",
        "division_type": "phường",
        "short_codename": "thuy_luong",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Xã Thủy Bằng",
        "code": "xa_thuy_bang",
        "division_type": "xã",
        "short_codename": "thuy_bang",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Xã Thủy Tân",
        "code": "xa_thuy_tan",
        "division_type": "xã",
        "short_codename": "thuy_tan",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Xã Thủy Phù",
        "code": "xa_thuy_phu",
        "division_type": "xã",
        "short_codename": "thuy_phu",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Xã Dương Hòa",
        "code": "xa_duong_hoa",
        "division_type": "xã",
        "short_codename": "duong_hoa",
        "parentCode": "thi_xa_huong_thuy"
    },
    {
        "name": "Phường Tứ Hạ",
        "code": "phuong_tu_ha",
        "division_type": "phường",
        "short_codename": "tu_ha",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Xã Hải Dương",
        "code": "xa_hai_duong",
        "division_type": "xã",
        "short_codename": "hai_duong",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Xã Hương Phong",
        "code": "xa_huong_phong",
        "division_type": "xã",
        "short_codename": "huong_phong",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Xã Hương Toàn",
        "code": "xa_huong_toan",
        "division_type": "xã",
        "short_codename": "huong_toan",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Phường Hương Vân",
        "code": "phuong_huong_van",
        "division_type": "phường",
        "short_codename": "huong_van",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Phường Hương Văn",
        "code": "phuong_huong_van",
        "division_type": "phường",
        "short_codename": "huong_van",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Xã Hương Vinh",
        "code": "xa_huong_vinh",
        "division_type": "xã",
        "short_codename": "huong_vinh",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Phường Hương Xuân",
        "code": "phuong_huong_xuan",
        "division_type": "phường",
        "short_codename": "huong_xuan",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Phường Hương Chữ",
        "code": "phuong_huong_chu",
        "division_type": "phường",
        "short_codename": "huong_chu",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Phường Hương An",
        "code": "phuong_huong_an",
        "division_type": "phường",
        "short_codename": "huong_an",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Xã Hương Bình",
        "code": "xa_huong_binh",
        "division_type": "xã",
        "short_codename": "huong_binh",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Phường Hương Hồ",
        "code": "phuong_huong_ho",
        "division_type": "phường",
        "short_codename": "huong_ho",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Xã Hương Thọ",
        "code": "xa_huong_tho",
        "division_type": "xã",
        "short_codename": "huong_tho",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Xã Bình Tiến",
        "code": "xa_binh_tien",
        "division_type": "xã",
        "short_codename": "binh_tien",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Xã Bình Thành",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "thi_xa_huong_tra"
    },
    {
        "name": "Thị trấn A Lưới",
        "code": "thi_tran_a_luoi",
        "division_type": "thị trấn",
        "short_codename": "a_luoi",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Hồng Vân",
        "code": "xa_hong_van",
        "division_type": "xã",
        "short_codename": "hong_van",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Hồng Hạ",
        "code": "xa_hong_ha",
        "division_type": "xã",
        "short_codename": "hong_ha",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Hồng Kim",
        "code": "xa_hong_kim",
        "division_type": "xã",
        "short_codename": "hong_kim",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Trung Sơn",
        "code": "xa_trung_son",
        "division_type": "xã",
        "short_codename": "trung_son",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Hương Nguyên",
        "code": "xa_huong_nguyen",
        "division_type": "xã",
        "short_codename": "huong_nguyen",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Hồng Bắc",
        "code": "xa_hong_bac",
        "division_type": "xã",
        "short_codename": "hong_bac",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã A Ngo",
        "code": "xa_a_ngo",
        "division_type": "xã",
        "short_codename": "a_ngo",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Sơn Thủy",
        "code": "xa_son_thuy",
        "division_type": "xã",
        "short_codename": "son_thuy",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Phú Vinh",
        "code": "xa_phu_vinh",
        "division_type": "xã",
        "short_codename": "phu_vinh",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Hương Phong",
        "code": "xa_huong_phong",
        "division_type": "xã",
        "short_codename": "huong_phong",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Quảng Nhâm",
        "code": "xa_quang_nham",
        "division_type": "xã",
        "short_codename": "quang_nham",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Hồng Thượng",
        "code": "xa_hong_thuong",
        "division_type": "xã",
        "short_codename": "hong_thuong",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Hồng Thái",
        "code": "xa_hong_thai",
        "division_type": "xã",
        "short_codename": "hong_thai",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã A Roàng",
        "code": "xa_a_roang",
        "division_type": "xã",
        "short_codename": "a_roang",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Đông Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Lâm Đớt",
        "code": "xa_lam_dot",
        "division_type": "xã",
        "short_codename": "lam_dot",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Xã Hồng Thủy",
        "code": "xa_hong_thuy",
        "division_type": "xã",
        "short_codename": "hong_thuy",
        "parentCode": "huyen_a_luoi"
    },
    {
        "name": "Thị trấn Phú Lộc",
        "code": "thi_tran_phu_loc",
        "division_type": "thị trấn",
        "short_codename": "phu_loc",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Thị trấn Lăng Cô",
        "code": "thi_tran_lang_co",
        "division_type": "thị trấn",
        "short_codename": "lang_co",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Vinh Mỹ",
        "code": "xa_vinh_my",
        "division_type": "xã",
        "short_codename": "vinh_my",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Vinh Hưng",
        "code": "xa_vinh_hung",
        "division_type": "xã",
        "short_codename": "vinh_hung",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Giang Hải",
        "code": "xa_giang_hai",
        "division_type": "xã",
        "short_codename": "giang_hai",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Vinh Hiền",
        "code": "xa_vinh_hien",
        "division_type": "xã",
        "short_codename": "vinh_hien",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc Bổn",
        "code": "xa_loc_bon",
        "division_type": "xã",
        "short_codename": "loc_bon",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc Sơn",
        "code": "xa_loc_son",
        "division_type": "xã",
        "short_codename": "loc_son",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc Bình",
        "code": "xa_loc_binh",
        "division_type": "xã",
        "short_codename": "loc_binh",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc Vĩnh",
        "code": "xa_loc_vinh",
        "division_type": "xã",
        "short_codename": "loc_vinh",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc An",
        "code": "xa_loc_an",
        "division_type": "xã",
        "short_codename": "loc_an",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc Điền",
        "code": "xa_loc_dien",
        "division_type": "xã",
        "short_codename": "loc_dien",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc Thủy",
        "code": "xa_loc_thuy",
        "division_type": "xã",
        "short_codename": "loc_thuy",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc Trì",
        "code": "xa_loc_tri",
        "division_type": "xã",
        "short_codename": "loc_tri",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc Tiến",
        "code": "xa_loc_tien",
        "division_type": "xã",
        "short_codename": "loc_tien",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Lộc Hòa",
        "code": "xa_loc_hoa",
        "division_type": "xã",
        "short_codename": "loc_hoa",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Xã Xuân Lộc",
        "code": "xa_xuan_loc",
        "division_type": "xã",
        "short_codename": "xuan_loc",
        "parentCode": "huyen_phu_loc"
    },
    {
        "name": "Thị trấn Khe Tre",
        "code": "thi_tran_khe_tre",
        "division_type": "thị trấn",
        "short_codename": "khe_tre",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Xã Hương Phú",
        "code": "xa_huong_phu",
        "division_type": "xã",
        "short_codename": "huong_phu",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Xã Hương Sơn",
        "code": "xa_huong_son",
        "division_type": "xã",
        "short_codename": "huong_son",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Xã Hương Lộc",
        "code": "xa_huong_loc",
        "division_type": "xã",
        "short_codename": "huong_loc",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Xã Thượng Quảng",
        "code": "xa_thuong_quang",
        "division_type": "xã",
        "short_codename": "thuong_quang",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Xã Hương Xuân",
        "code": "xa_huong_xuan",
        "division_type": "xã",
        "short_codename": "huong_xuan",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Xã Hương Hữu",
        "code": "xa_huong_huu",
        "division_type": "xã",
        "short_codename": "huong_huu",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Xã Thượng Lộ",
        "code": "xa_thuong_lo",
        "division_type": "xã",
        "short_codename": "thuong_lo",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Xã Thượng Long",
        "code": "xa_thuong_long",
        "division_type": "xã",
        "short_codename": "thuong_long",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Xã Thượng Nhật",
        "code": "xa_thuong_nhat",
        "division_type": "xã",
        "short_codename": "thuong_nhat",
        "parentCode": "huyen_nam_dong"
    },
    {
        "name": "Phường Hòa Hiệp Bắc",
        "code": "phuong_hoa_hiep_bac",
        "division_type": "phường",
        "short_codename": "hoa_hiep_bac",
        "parentCode": "quan_lien_chieu"
    },
    {
        "name": "Phường Hòa Hiệp Nam",
        "code": "phuong_hoa_hiep_nam",
        "division_type": "phường",
        "short_codename": "hoa_hiep_nam",
        "parentCode": "quan_lien_chieu"
    },
    {
        "name": "Phường Hòa Khánh Bắc",
        "code": "phuong_hoa_khanh_bac",
        "division_type": "phường",
        "short_codename": "hoa_khanh_bac",
        "parentCode": "quan_lien_chieu"
    },
    {
        "name": "Phường Hòa Khánh Nam",
        "code": "phuong_hoa_khanh_nam",
        "division_type": "phường",
        "short_codename": "hoa_khanh_nam",
        "parentCode": "quan_lien_chieu"
    },
    {
        "name": "Phường Hòa Minh",
        "code": "phuong_hoa_minh",
        "division_type": "phường",
        "short_codename": "hoa_minh",
        "parentCode": "quan_lien_chieu"
    },
    {
        "name": "Phường Tam Thuận",
        "code": "phuong_tam_thuan",
        "division_type": "phường",
        "short_codename": "tam_thuan",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường Thanh Khê Tây",
        "code": "phuong_thanh_khe_tay",
        "division_type": "phường",
        "short_codename": "thanh_khe_tay",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường Thanh Khê Đông",
        "code": "phuong_thanh_khe_dong",
        "division_type": "phường",
        "short_codename": "thanh_khe_dong",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường Xuân Hà",
        "code": "phuong_xuan_ha",
        "division_type": "phường",
        "short_codename": "xuan_ha",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường Tân Chính",
        "code": "phuong_tan_chinh",
        "division_type": "phường",
        "short_codename": "tan_chinh",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường Chính Gián",
        "code": "phuong_chinh_gian",
        "division_type": "phường",
        "short_codename": "chinh_gian",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường Vĩnh Trung",
        "code": "phuong_vinh_trung",
        "division_type": "phường",
        "short_codename": "vinh_trung",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường Thạc Gián",
        "code": "phuong_thac_gian",
        "division_type": "phường",
        "short_codename": "thac_gian",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường An Khê",
        "code": "phuong_an_khe",
        "division_type": "phường",
        "short_codename": "an_khe",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường Hòa Khê",
        "code": "phuong_hoa_khe",
        "division_type": "phường",
        "short_codename": "hoa_khe",
        "parentCode": "quan_thanh_khe"
    },
    {
        "name": "Phường Thanh Bình",
        "code": "phuong_thanh_binh",
        "division_type": "phường",
        "short_codename": "thanh_binh",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Thuận Phước",
        "code": "phuong_thuan_phuoc",
        "division_type": "phường",
        "short_codename": "thuan_phuoc",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Thạch Thang",
        "code": "phuong_thach_thang",
        "division_type": "phường",
        "short_codename": "thach_thang",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Hải Châu I",
        "code": "phuong_hai_chau_i",
        "division_type": "phường",
        "short_codename": "hai_chau_i",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Hải Châu II",
        "code": "phuong_hai_chau_ii",
        "division_type": "phường",
        "short_codename": "hai_chau_ii",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Phước Ninh",
        "code": "phuong_phuoc_ninh",
        "division_type": "phường",
        "short_codename": "phuoc_ninh",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Hòa Thuận Tây",
        "code": "phuong_hoa_thuan_tay",
        "division_type": "phường",
        "short_codename": "hoa_thuan_tay",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Hòa Thuận Đông",
        "code": "phuong_hoa_thuan_dong",
        "division_type": "phường",
        "short_codename": "hoa_thuan_dong",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Nam Dương",
        "code": "phuong_nam_duong",
        "division_type": "phường",
        "short_codename": "nam_duong",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Bình Hiên",
        "code": "phuong_binh_hien",
        "division_type": "phường",
        "short_codename": "binh_hien",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Bình Thuận",
        "code": "phuong_binh_thuan",
        "division_type": "phường",
        "short_codename": "binh_thuan",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Hòa Cường Bắc",
        "code": "phuong_hoa_cuong_bac",
        "division_type": "phường",
        "short_codename": "hoa_cuong_bac",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Hòa Cường Nam",
        "code": "phuong_hoa_cuong_nam",
        "division_type": "phường",
        "short_codename": "hoa_cuong_nam",
        "parentCode": "quan_hai_chau"
    },
    {
        "name": "Phường Thọ Quang",
        "code": "phuong_tho_quang",
        "division_type": "phường",
        "short_codename": "tho_quang",
        "parentCode": "quan_son_tra"
    },
    {
        "name": "Phường Nại Hiên Đông",
        "code": "phuong_nai_hien_dong",
        "division_type": "phường",
        "short_codename": "nai_hien_dong",
        "parentCode": "quan_son_tra"
    },
    {
        "name": "Phường Mân Thái",
        "code": "phuong_man_thai",
        "division_type": "phường",
        "short_codename": "man_thai",
        "parentCode": "quan_son_tra"
    },
    {
        "name": "Phường An Hải Bắc",
        "code": "phuong_an_hai_bac",
        "division_type": "phường",
        "short_codename": "an_hai_bac",
        "parentCode": "quan_son_tra"
    },
    {
        "name": "Phường Phước Mỹ",
        "code": "phuong_phuoc_my",
        "division_type": "phường",
        "short_codename": "phuoc_my",
        "parentCode": "quan_son_tra"
    },
    {
        "name": "Phường An Hải Tây",
        "code": "phuong_an_hai_tay",
        "division_type": "phường",
        "short_codename": "an_hai_tay",
        "parentCode": "quan_son_tra"
    },
    {
        "name": "Phường An Hải Đông",
        "code": "phuong_an_hai_dong",
        "division_type": "phường",
        "short_codename": "an_hai_dong",
        "parentCode": "quan_son_tra"
    },
    {
        "name": "Phường Mỹ An",
        "code": "phuong_my_an",
        "division_type": "phường",
        "short_codename": "my_an",
        "parentCode": "quan_ngu_hanh_son"
    },
    {
        "name": "Phường Khuê Mỹ",
        "code": "phuong_khue_my",
        "division_type": "phường",
        "short_codename": "khue_my",
        "parentCode": "quan_ngu_hanh_son"
    },
    {
        "name": "Phường Hoà Quý",
        "code": "phuong_hoa_quy",
        "division_type": "phường",
        "short_codename": "hoa_quy",
        "parentCode": "quan_ngu_hanh_son"
    },
    {
        "name": "Phường Hoà Hải",
        "code": "phuong_hoa_hai",
        "division_type": "phường",
        "short_codename": "hoa_hai",
        "parentCode": "quan_ngu_hanh_son"
    },
    {
        "name": "Phường Khuê Trung",
        "code": "phuong_khue_trung",
        "division_type": "phường",
        "short_codename": "khue_trung",
        "parentCode": "quan_cam_le"
    },
    {
        "name": "Phường Hòa Phát",
        "code": "phuong_hoa_phat",
        "division_type": "phường",
        "short_codename": "hoa_phat",
        "parentCode": "quan_cam_le"
    },
    {
        "name": "Phường Hòa An",
        "code": "phuong_hoa_an",
        "division_type": "phường",
        "short_codename": "hoa_an",
        "parentCode": "quan_cam_le"
    },
    {
        "name": "Phường Hòa Thọ Tây",
        "code": "phuong_hoa_tho_tay",
        "division_type": "phường",
        "short_codename": "hoa_tho_tay",
        "parentCode": "quan_cam_le"
    },
    {
        "name": "Phường Hòa Thọ Đông",
        "code": "phuong_hoa_tho_dong",
        "division_type": "phường",
        "short_codename": "hoa_tho_dong",
        "parentCode": "quan_cam_le"
    },
    {
        "name": "Phường Hòa Xuân",
        "code": "phuong_hoa_xuan",
        "division_type": "phường",
        "short_codename": "hoa_xuan",
        "parentCode": "quan_cam_le"
    },
    {
        "name": "Xã Hòa Bắc",
        "code": "xa_hoa_bac",
        "division_type": "xã",
        "short_codename": "hoa_bac",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Liên",
        "code": "xa_hoa_lien",
        "division_type": "xã",
        "short_codename": "hoa_lien",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Ninh",
        "code": "xa_hoa_ninh",
        "division_type": "xã",
        "short_codename": "hoa_ninh",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Nhơn",
        "code": "xa_hoa_nhon",
        "division_type": "xã",
        "short_codename": "hoa_nhon",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Phú",
        "code": "xa_hoa_phu",
        "division_type": "xã",
        "short_codename": "hoa_phu",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Phong",
        "code": "xa_hoa_phong",
        "division_type": "xã",
        "short_codename": "hoa_phong",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Châu",
        "code": "xa_hoa_chau",
        "division_type": "xã",
        "short_codename": "hoa_chau",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Tiến",
        "code": "xa_hoa_tien",
        "division_type": "xã",
        "short_codename": "hoa_tien",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Phước",
        "code": "xa_hoa_phuoc",
        "division_type": "xã",
        "short_codename": "hoa_phuoc",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Xã Hòa Khương",
        "code": "xa_hoa_khuong",
        "division_type": "xã",
        "short_codename": "hoa_khuong",
        "parentCode": "huyen_hoa_vang"
    },
    {
        "name": "Phường Tân Thạnh",
        "code": "phuong_tan_thanh",
        "division_type": "phường",
        "short_codename": "tan_thanh",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Phường Phước Hòa",
        "code": "phuong_phuoc_hoa",
        "division_type": "phường",
        "short_codename": "phuoc_hoa",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Phường An Mỹ",
        "code": "phuong_an_my",
        "division_type": "phường",
        "short_codename": "an_my",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Phường Hòa Hương",
        "code": "phuong_hoa_huong",
        "division_type": "phường",
        "short_codename": "hoa_huong",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Phường An Xuân",
        "code": "phuong_an_xuan",
        "division_type": "phường",
        "short_codename": "an_xuan",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Phường An Sơn",
        "code": "phuong_an_son",
        "division_type": "phường",
        "short_codename": "an_son",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Phường Trường Xuân",
        "code": "phuong_truong_xuan",
        "division_type": "phường",
        "short_codename": "truong_xuan",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Phường An Phú",
        "code": "phuong_an_phu",
        "division_type": "phường",
        "short_codename": "an_phu",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Xã Tam Thanh",
        "code": "xa_tam_thanh",
        "division_type": "xã",
        "short_codename": "tam_thanh",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Xã Tam Thăng",
        "code": "xa_tam_thang",
        "division_type": "xã",
        "short_codename": "tam_thang",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Xã Tam Phú",
        "code": "xa_tam_phu",
        "division_type": "xã",
        "short_codename": "tam_phu",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Phường Hoà Thuận",
        "code": "phuong_hoa_thuan",
        "division_type": "phường",
        "short_codename": "hoa_thuan",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Xã Tam Ngọc",
        "code": "xa_tam_ngoc",
        "division_type": "xã",
        "short_codename": "tam_ngoc",
        "parentCode": "thanh_pho_tam_ky"
    },
    {
        "name": "Phường Minh An",
        "code": "phuong_minh_an",
        "division_type": "phường",
        "short_codename": "minh_an",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Phường Tân An",
        "code": "phuong_tan_an",
        "division_type": "phường",
        "short_codename": "tan_an",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Phường Cẩm Phô",
        "code": "phuong_cam_pho",
        "division_type": "phường",
        "short_codename": "cam_pho",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Phường Thanh Hà",
        "code": "phuong_thanh_ha",
        "division_type": "phường",
        "short_codename": "thanh_ha",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Phường Sơn Phong",
        "code": "phuong_son_phong",
        "division_type": "phường",
        "short_codename": "son_phong",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Phường Cẩm Châu",
        "code": "phuong_cam_chau",
        "division_type": "phường",
        "short_codename": "cam_chau",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Phường Cửa Đại",
        "code": "phuong_cua_dai",
        "division_type": "phường",
        "short_codename": "cua_dai",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Phường Cẩm An",
        "code": "phuong_cam_an",
        "division_type": "phường",
        "short_codename": "cam_an",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Xã Cẩm Hà",
        "code": "xa_cam_ha",
        "division_type": "xã",
        "short_codename": "cam_ha",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Xã Cẩm Kim",
        "code": "xa_cam_kim",
        "division_type": "xã",
        "short_codename": "cam_kim",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Phường Cẩm Nam",
        "code": "phuong_cam_nam",
        "division_type": "phường",
        "short_codename": "cam_nam",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Xã Cẩm Thanh",
        "code": "xa_cam_thanh",
        "division_type": "xã",
        "short_codename": "cam_thanh",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Xã Tân Hiệp",
        "code": "xa_tan_hiep",
        "division_type": "xã",
        "short_codename": "tan_hiep",
        "parentCode": "thanh_pho_hoi_an"
    },
    {
        "name": "Xã Ch'ơm",
        "code": "xa_chom",
        "division_type": "xã",
        "short_codename": "chom",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Xã Ga Ri",
        "code": "xa_ga_ri",
        "division_type": "xã",
        "short_codename": "ga_ri",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Xã A Xan",
        "code": "xa_a_xan",
        "division_type": "xã",
        "short_codename": "a_xan",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Xã Tr'Hy",
        "code": "xa_trhy",
        "division_type": "xã",
        "short_codename": "trhy",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Xã Lăng",
        "code": "xa_lang",
        "division_type": "xã",
        "short_codename": "lang",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Xã A Nông",
        "code": "xa_a_nong",
        "division_type": "xã",
        "short_codename": "a_nong",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Xã A Tiêng",
        "code": "xa_a_tieng",
        "division_type": "xã",
        "short_codename": "a_tieng",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Xã Bha Lê",
        "code": "xa_bha_le",
        "division_type": "xã",
        "short_codename": "bha_le",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Xã A Vương",
        "code": "xa_a_vuong",
        "division_type": "xã",
        "short_codename": "a_vuong",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Xã Dang",
        "code": "xa_dang",
        "division_type": "xã",
        "short_codename": "dang",
        "parentCode": "huyen_tay_giang"
    },
    {
        "name": "Thị trấn P Rao",
        "code": "thi_tran_p_rao",
        "division_type": "thị trấn",
        "short_codename": "p_rao",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã Tà Lu",
        "code": "xa_ta_lu",
        "division_type": "xã",
        "short_codename": "ta_lu",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã Sông Kôn",
        "code": "xa_song_kon",
        "division_type": "xã",
        "short_codename": "song_kon",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã Jơ Ngây",
        "code": "xa_jo_ngay",
        "division_type": "xã",
        "short_codename": "jo_ngay",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã A Ting",
        "code": "xa_a_ting",
        "division_type": "xã",
        "short_codename": "a_ting",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã Tư",
        "code": "xa_tu",
        "division_type": "xã",
        "short_codename": "tu",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã Ba",
        "code": "xa_ba",
        "division_type": "xã",
        "short_codename": "ba",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã A Rooi",
        "code": "xa_a_rooi",
        "division_type": "xã",
        "short_codename": "a_rooi",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã Za Hung",
        "code": "xa_za_hung",
        "division_type": "xã",
        "short_codename": "za_hung",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã Mà Cooi",
        "code": "xa_ma_cooi",
        "division_type": "xã",
        "short_codename": "ma_cooi",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Xã Ka Dăng",
        "code": "xa_ka_dang",
        "division_type": "xã",
        "short_codename": "ka_dang",
        "parentCode": "huyen_dong_giang"
    },
    {
        "name": "Thị Trấn Ái Nghĩa",
        "code": "thi_tran_ai_nghia",
        "division_type": "thị trấn",
        "short_codename": "ai_nghia",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Sơn",
        "code": "xa_dai_son",
        "division_type": "xã",
        "short_codename": "dai_son",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Lãnh",
        "code": "xa_dai_lanh",
        "division_type": "xã",
        "short_codename": "dai_lanh",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Hưng",
        "code": "xa_dai_hung",
        "division_type": "xã",
        "short_codename": "dai_hung",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Hồng",
        "code": "xa_dai_hong",
        "division_type": "xã",
        "short_codename": "dai_hong",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Đồng",
        "code": "xa_dai_dong",
        "division_type": "xã",
        "short_codename": "dai_dong",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Quang",
        "code": "xa_dai_quang",
        "division_type": "xã",
        "short_codename": "dai_quang",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Nghĩa",
        "code": "xa_dai_nghia",
        "division_type": "xã",
        "short_codename": "dai_nghia",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Hiệp",
        "code": "xa_dai_hiep",
        "division_type": "xã",
        "short_codename": "dai_hiep",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Thạnh",
        "code": "xa_dai_thanh",
        "division_type": "xã",
        "short_codename": "dai_thanh",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Chánh",
        "code": "xa_dai_chanh",
        "division_type": "xã",
        "short_codename": "dai_chanh",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Tân",
        "code": "xa_dai_tan",
        "division_type": "xã",
        "short_codename": "dai_tan",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Phong",
        "code": "xa_dai_phong",
        "division_type": "xã",
        "short_codename": "dai_phong",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Minh",
        "code": "xa_dai_minh",
        "division_type": "xã",
        "short_codename": "dai_minh",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Thắng",
        "code": "xa_dai_thang",
        "division_type": "xã",
        "short_codename": "dai_thang",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Cường",
        "code": "xa_dai_cuong",
        "division_type": "xã",
        "short_codename": "dai_cuong",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại An",
        "code": "xa_dai_an",
        "division_type": "xã",
        "short_codename": "dai_an",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Xã Đại Hòa",
        "code": "xa_dai_hoa",
        "division_type": "xã",
        "short_codename": "dai_hoa",
        "parentCode": "huyen_dai_loc"
    },
    {
        "name": "Phường Vĩnh Điện",
        "code": "phuong_vinh_dien",
        "division_type": "phường",
        "short_codename": "vinh_dien",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Tiến",
        "code": "xa_dien_tien",
        "division_type": "xã",
        "short_codename": "dien_tien",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Hòa",
        "code": "xa_dien_hoa",
        "division_type": "xã",
        "short_codename": "dien_hoa",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Thắng Bắc",
        "code": "xa_dien_thang_bac",
        "division_type": "xã",
        "short_codename": "dien_thang_bac",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Thắng Trung",
        "code": "xa_dien_thang_trung",
        "division_type": "xã",
        "short_codename": "dien_thang_trung",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Thắng Nam",
        "code": "xa_dien_thang_nam",
        "division_type": "xã",
        "short_codename": "dien_thang_nam",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Phường Điện Ngọc",
        "code": "phuong_dien_ngoc",
        "division_type": "phường",
        "short_codename": "dien_ngoc",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Hồng",
        "code": "xa_dien_hong",
        "division_type": "xã",
        "short_codename": "dien_hong",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Thọ",
        "code": "xa_dien_tho",
        "division_type": "xã",
        "short_codename": "dien_tho",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Phước",
        "code": "xa_dien_phuoc",
        "division_type": "xã",
        "short_codename": "dien_phuoc",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Phường Điện An",
        "code": "phuong_dien_an",
        "division_type": "phường",
        "short_codename": "dien_an",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Phường Điện Nam Bắc",
        "code": "phuong_dien_nam_bac",
        "division_type": "phường",
        "short_codename": "dien_nam_bac",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Phường Điện Nam Trung",
        "code": "phuong_dien_nam_trung",
        "division_type": "phường",
        "short_codename": "dien_nam_trung",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Phường Điện Nam Đông",
        "code": "phuong_dien_nam_dong",
        "division_type": "phường",
        "short_codename": "dien_nam_dong",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Phường Điện Dương",
        "code": "phuong_dien_duong",
        "division_type": "phường",
        "short_codename": "dien_duong",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Quang",
        "code": "xa_dien_quang",
        "division_type": "xã",
        "short_codename": "dien_quang",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Trung",
        "code": "xa_dien_trung",
        "division_type": "xã",
        "short_codename": "dien_trung",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Phong",
        "code": "xa_dien_phong",
        "division_type": "xã",
        "short_codename": "dien_phong",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Minh",
        "code": "xa_dien_minh",
        "division_type": "xã",
        "short_codename": "dien_minh",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Xã Điện Phương",
        "code": "xa_dien_phuong",
        "division_type": "xã",
        "short_codename": "dien_phuong",
        "parentCode": "thi_xa_dien_ban"
    },
    {
        "name": "Thị trấn Nam Phước",
        "code": "thi_tran_nam_phuoc",
        "division_type": "thị trấn",
        "short_codename": "nam_phuoc",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Thu",
        "code": "xa_duy_thu",
        "division_type": "xã",
        "short_codename": "duy_thu",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Phú",
        "code": "xa_duy_phu",
        "division_type": "xã",
        "short_codename": "duy_phu",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Tân",
        "code": "xa_duy_tan",
        "division_type": "xã",
        "short_codename": "duy_tan",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Hòa",
        "code": "xa_duy_hoa",
        "division_type": "xã",
        "short_codename": "duy_hoa",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Châu",
        "code": "xa_duy_chau",
        "division_type": "xã",
        "short_codename": "duy_chau",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Trinh",
        "code": "xa_duy_trinh",
        "division_type": "xã",
        "short_codename": "duy_trinh",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Sơn",
        "code": "xa_duy_son",
        "division_type": "xã",
        "short_codename": "duy_son",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Trung",
        "code": "xa_duy_trung",
        "division_type": "xã",
        "short_codename": "duy_trung",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Phước",
        "code": "xa_duy_phuoc",
        "division_type": "xã",
        "short_codename": "duy_phuoc",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Thành",
        "code": "xa_duy_thanh",
        "division_type": "xã",
        "short_codename": "duy_thanh",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Vinh",
        "code": "xa_duy_vinh",
        "division_type": "xã",
        "short_codename": "duy_vinh",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Nghĩa",
        "code": "xa_duy_nghia",
        "division_type": "xã",
        "short_codename": "duy_nghia",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Xã Duy Hải",
        "code": "xa_duy_hai",
        "division_type": "xã",
        "short_codename": "duy_hai",
        "parentCode": "huyen_duy_xuyen"
    },
    {
        "name": "Thị trấn Đông Phú",
        "code": "thi_tran_dong_phu",
        "division_type": "thị trấn",
        "short_codename": "dong_phu",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Xuân 1",
        "code": "xa_que_xuan_1",
        "division_type": "xã",
        "short_codename": "que_xuan_1",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Xuân 2",
        "code": "xa_que_xuan_2",
        "division_type": "xã",
        "short_codename": "que_xuan_2",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Phú",
        "code": "xa_que_phu",
        "division_type": "xã",
        "short_codename": "que_phu",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Thị trấn Hương An",
        "code": "thi_tran_huong_an",
        "division_type": "thị trấn",
        "short_codename": "huong_an",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Hiệp",
        "code": "xa_que_hiep",
        "division_type": "xã",
        "short_codename": "que_hiep",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Thuận",
        "code": "xa_que_thuan",
        "division_type": "xã",
        "short_codename": "que_thuan",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Mỹ",
        "code": "xa_que_my",
        "division_type": "xã",
        "short_codename": "que_my",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Long",
        "code": "xa_que_long",
        "division_type": "xã",
        "short_codename": "que_long",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Châu",
        "code": "xa_que_chau",
        "division_type": "xã",
        "short_codename": "que_chau",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Phong",
        "code": "xa_que_phong",
        "division_type": "xã",
        "short_codename": "que_phong",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế An",
        "code": "xa_que_an",
        "division_type": "xã",
        "short_codename": "que_an",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Xã Quế Minh",
        "code": "xa_que_minh",
        "division_type": "xã",
        "short_codename": "que_minh",
        "parentCode": "huyen_que_son"
    },
    {
        "name": "Thị trấn Thạnh Mỹ",
        "code": "thi_tran_thanh_my",
        "division_type": "thị trấn",
        "short_codename": "thanh_my",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Laêê",
        "code": "xa_laee",
        "division_type": "xã",
        "short_codename": "laee",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Chơ Chun",
        "code": "xa_cho_chun",
        "division_type": "xã",
        "short_codename": "cho_chun",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Zuôich",
        "code": "xa_zuoich",
        "division_type": "xã",
        "short_codename": "zuoich",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Tà Pơơ",
        "code": "xa_ta_poo",
        "division_type": "xã",
        "short_codename": "ta_poo",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã La Dêê",
        "code": "xa_la_dee",
        "division_type": "xã",
        "short_codename": "la_dee",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Đắc Tôi",
        "code": "xa_dac_toi",
        "division_type": "xã",
        "short_codename": "dac_toi",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Chà Vàl",
        "code": "xa_cha_val",
        "division_type": "xã",
        "short_codename": "cha_val",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Tà Bhinh",
        "code": "xa_ta_bhinh",
        "division_type": "xã",
        "short_codename": "ta_bhinh",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Cà Dy",
        "code": "xa_ca_dy",
        "division_type": "xã",
        "short_codename": "ca_dy",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Đắc Pre",
        "code": "xa_dac_pre",
        "division_type": "xã",
        "short_codename": "dac_pre",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Xã Đắc Pring",
        "code": "xa_dac_pring",
        "division_type": "xã",
        "short_codename": "dac_pring",
        "parentCode": "huyen_nam_giang"
    },
    {
        "name": "Thị trấn Khâm Đức",
        "code": "thi_tran_kham_duc",
        "division_type": "thị trấn",
        "short_codename": "kham_duc",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Xuân",
        "code": "xa_phuoc_xuan",
        "division_type": "xã",
        "short_codename": "phuoc_xuan",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Hiệp",
        "code": "xa_phuoc_hiep",
        "division_type": "xã",
        "short_codename": "phuoc_hiep",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Hoà",
        "code": "xa_phuoc_hoa",
        "division_type": "xã",
        "short_codename": "phuoc_hoa",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Đức",
        "code": "xa_phuoc_duc",
        "division_type": "xã",
        "short_codename": "phuoc_duc",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Năng",
        "code": "xa_phuoc_nang",
        "division_type": "xã",
        "short_codename": "phuoc_nang",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Mỹ",
        "code": "xa_phuoc_my",
        "division_type": "xã",
        "short_codename": "phuoc_my",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Chánh",
        "code": "xa_phuoc_chanh",
        "division_type": "xã",
        "short_codename": "phuoc_chanh",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Công",
        "code": "xa_phuoc_cong",
        "division_type": "xã",
        "short_codename": "phuoc_cong",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Kim",
        "code": "xa_phuoc_kim",
        "division_type": "xã",
        "short_codename": "phuoc_kim",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Lộc",
        "code": "xa_phuoc_loc",
        "division_type": "xã",
        "short_codename": "phuoc_loc",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Phước Thành",
        "code": "xa_phuoc_thanh",
        "division_type": "xã",
        "short_codename": "phuoc_thanh",
        "parentCode": "huyen_phuoc_son"
    },
    {
        "name": "Xã Hiệp Hòa",
        "code": "xa_hiep_hoa",
        "division_type": "xã",
        "short_codename": "hiep_hoa",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Xã Hiệp Thuận",
        "code": "xa_hiep_thuan",
        "division_type": "xã",
        "short_codename": "hiep_thuan",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Xã Quế Thọ",
        "code": "xa_que_tho",
        "division_type": "xã",
        "short_codename": "que_tho",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Xã Bình Lâm",
        "code": "xa_binh_lam",
        "division_type": "xã",
        "short_codename": "binh_lam",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Xã Sông Trà",
        "code": "xa_song_tra",
        "division_type": "xã",
        "short_codename": "song_tra",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Xã Phước Trà",
        "code": "xa_phuoc_tra",
        "division_type": "xã",
        "short_codename": "phuoc_tra",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Xã Phước Gia",
        "code": "xa_phuoc_gia",
        "division_type": "xã",
        "short_codename": "phuoc_gia",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Thị trấn Tân Bình",
        "code": "thi_tran_tan_binh",
        "division_type": "thị trấn",
        "short_codename": "tan_binh",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Xã Quế Lưu",
        "code": "xa_que_luu",
        "division_type": "xã",
        "short_codename": "que_luu",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Xã Thăng Phước",
        "code": "xa_thang_phuoc",
        "division_type": "xã",
        "short_codename": "thang_phuoc",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Xã Bình Sơn",
        "code": "xa_binh_son",
        "division_type": "xã",
        "short_codename": "binh_son",
        "parentCode": "huyen_hiep_duc"
    },
    {
        "name": "Thị trấn Hà Lam",
        "code": "thi_tran_ha_lam",
        "division_type": "thị trấn",
        "short_codename": "ha_lam",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Dương",
        "code": "xa_binh_duong",
        "division_type": "xã",
        "short_codename": "binh_duong",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Giang",
        "code": "xa_binh_giang",
        "division_type": "xã",
        "short_codename": "binh_giang",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Nguyên",
        "code": "xa_binh_nguyen",
        "division_type": "xã",
        "short_codename": "binh_nguyen",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Phục",
        "code": "xa_binh_phuc",
        "division_type": "xã",
        "short_codename": "binh_phuc",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Triều",
        "code": "xa_binh_trieu",
        "division_type": "xã",
        "short_codename": "binh_trieu",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Đào",
        "code": "xa_binh_dao",
        "division_type": "xã",
        "short_codename": "binh_dao",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Lãnh",
        "code": "xa_binh_lanh",
        "division_type": "xã",
        "short_codename": "binh_lanh",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Trị",
        "code": "xa_binh_tri",
        "division_type": "xã",
        "short_codename": "binh_tri",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Định Bắc",
        "code": "xa_binh_dinh_bac",
        "division_type": "xã",
        "short_codename": "binh_dinh_bac",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Định Nam",
        "code": "xa_binh_dinh_nam",
        "division_type": "xã",
        "short_codename": "binh_dinh_nam",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Quý",
        "code": "xa_binh_quy",
        "division_type": "xã",
        "short_codename": "binh_quy",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Chánh",
        "code": "xa_binh_chanh",
        "division_type": "xã",
        "short_codename": "binh_chanh",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Tú",
        "code": "xa_binh_tu",
        "division_type": "xã",
        "short_codename": "binh_tu",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Sa",
        "code": "xa_binh_sa",
        "division_type": "xã",
        "short_codename": "binh_sa",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Hải",
        "code": "xa_binh_hai",
        "division_type": "xã",
        "short_codename": "binh_hai",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Quế",
        "code": "xa_binh_que",
        "division_type": "xã",
        "short_codename": "binh_que",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình An",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Trung",
        "code": "xa_binh_trung",
        "division_type": "xã",
        "short_codename": "binh_trung",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Xã Bình Nam",
        "code": "xa_binh_nam",
        "division_type": "xã",
        "short_codename": "binh_nam",
        "parentCode": "huyen_thang_binh"
    },
    {
        "name": "Thị trấn Tiên Kỳ",
        "code": "thi_tran_tien_ky",
        "division_type": "thị trấn",
        "short_codename": "tien_ky",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Sơn",
        "code": "xa_tien_son",
        "division_type": "xã",
        "short_codename": "tien_son",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Hà",
        "code": "xa_tien_ha",
        "division_type": "xã",
        "short_codename": "tien_ha",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Cẩm",
        "code": "xa_tien_cam",
        "division_type": "xã",
        "short_codename": "tien_cam",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Châu",
        "code": "xa_tien_chau",
        "division_type": "xã",
        "short_codename": "tien_chau",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Lãnh",
        "code": "xa_tien_lanh",
        "division_type": "xã",
        "short_codename": "tien_lanh",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Ngọc",
        "code": "xa_tien_ngoc",
        "division_type": "xã",
        "short_codename": "tien_ngoc",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Hiệp",
        "code": "xa_tien_hiep",
        "division_type": "xã",
        "short_codename": "tien_hiep",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Cảnh",
        "code": "xa_tien_canh",
        "division_type": "xã",
        "short_codename": "tien_canh",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Mỹ",
        "code": "xa_tien_my",
        "division_type": "xã",
        "short_codename": "tien_my",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Phong",
        "code": "xa_tien_phong",
        "division_type": "xã",
        "short_codename": "tien_phong",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Thọ",
        "code": "xa_tien_tho",
        "division_type": "xã",
        "short_codename": "tien_tho",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên An",
        "code": "xa_tien_an",
        "division_type": "xã",
        "short_codename": "tien_an",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Lộc",
        "code": "xa_tien_loc",
        "division_type": "xã",
        "short_codename": "tien_loc",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Xã Tiên Lập",
        "code": "xa_tien_lap",
        "division_type": "xã",
        "short_codename": "tien_lap",
        "parentCode": "huyen_tien_phuoc"
    },
    {
        "name": "Thị trấn Trà My",
        "code": "thi_tran_tra_my",
        "division_type": "thị trấn",
        "short_codename": "tra_my",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Sơn",
        "code": "xa_tra_son",
        "division_type": "xã",
        "short_codename": "tra_son",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Kót",
        "code": "xa_tra_kot",
        "division_type": "xã",
        "short_codename": "tra_kot",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Nú",
        "code": "xa_tra_nu",
        "division_type": "xã",
        "short_codename": "tra_nu",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Đông",
        "code": "xa_tra_dong",
        "division_type": "xã",
        "short_codename": "tra_dong",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Dương",
        "code": "xa_tra_duong",
        "division_type": "xã",
        "short_codename": "tra_duong",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Giang",
        "code": "xa_tra_giang",
        "division_type": "xã",
        "short_codename": "tra_giang",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Bui",
        "code": "xa_tra_bui",
        "division_type": "xã",
        "short_codename": "tra_bui",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Đốc",
        "code": "xa_tra_doc",
        "division_type": "xã",
        "short_codename": "tra_doc",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Tân",
        "code": "xa_tra_tan",
        "division_type": "xã",
        "short_codename": "tra_tan",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Giác",
        "code": "xa_tra_giac",
        "division_type": "xã",
        "short_codename": "tra_giac",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Giáp",
        "code": "xa_tra_giap",
        "division_type": "xã",
        "short_codename": "tra_giap",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Ka",
        "code": "xa_tra_ka",
        "division_type": "xã",
        "short_codename": "tra_ka",
        "parentCode": "huyen_bac_tra_my"
    },
    {
        "name": "Xã Trà Leng",
        "code": "xa_tra_leng",
        "division_type": "xã",
        "short_codename": "tra_leng",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Xã Trà Dơn",
        "code": "xa_tra_don",
        "division_type": "xã",
        "short_codename": "xa_tra_don",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Xã Trà Tập",
        "code": "xa_tra_tap",
        "division_type": "xã",
        "short_codename": "tra_tap",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Xã Trà Mai",
        "code": "xa_tra_mai",
        "division_type": "xã",
        "short_codename": "tra_mai",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Xã Trà Cang",
        "code": "xa_tra_cang",
        "division_type": "xã",
        "short_codename": "tra_cang",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Xã Trà Linh",
        "code": "xa_tra_linh",
        "division_type": "xã",
        "short_codename": "tra_linh",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Xã Trà Nam",
        "code": "xa_tra_nam",
        "division_type": "xã",
        "short_codename": "tra_nam",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Xã Trà Don",
        "code": "xa_tra_don",
        "division_type": "xã",
        "short_codename": "xa_tra_don",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Xã Trà Vân",
        "code": "xa_tra_van",
        "division_type": "xã",
        "short_codename": "tra_van",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Xã Trà Vinh",
        "code": "xa_tra_vinh",
        "division_type": "xã",
        "short_codename": "tra_vinh",
        "parentCode": "huyen_nam_tra_my"
    },
    {
        "name": "Thị trấn Núi Thành",
        "code": "thi_tran_nui_thanh",
        "division_type": "thị trấn",
        "short_codename": "nui_thanh",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Xuân I",
        "code": "xa_tam_xuan_i",
        "division_type": "xã",
        "short_codename": "tam_xuan_i",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Xuân II",
        "code": "xa_tam_xuan_ii",
        "division_type": "xã",
        "short_codename": "tam_xuan_ii",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Tiến",
        "code": "xa_tam_tien",
        "division_type": "xã",
        "short_codename": "tam_tien",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Sơn",
        "code": "xa_tam_son",
        "division_type": "xã",
        "short_codename": "tam_son",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Thạnh",
        "code": "xa_tam_thanh",
        "division_type": "xã",
        "short_codename": "tam_thanh",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Anh Bắc",
        "code": "xa_tam_anh_bac",
        "division_type": "xã",
        "short_codename": "tam_anh_bac",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Anh Nam",
        "code": "xa_tam_anh_nam",
        "division_type": "xã",
        "short_codename": "tam_anh_nam",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Hòa",
        "code": "xa_tam_hoa",
        "division_type": "xã",
        "short_codename": "tam_hoa",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Hiệp",
        "code": "xa_tam_hiep",
        "division_type": "xã",
        "short_codename": "tam_hiep",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Hải",
        "code": "xa_tam_hai",
        "division_type": "xã",
        "short_codename": "tam_hai",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Giang",
        "code": "xa_tam_giang",
        "division_type": "xã",
        "short_codename": "tam_giang",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Quang",
        "code": "xa_tam_quang",
        "division_type": "xã",
        "short_codename": "tam_quang",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Nghĩa",
        "code": "xa_tam_nghia",
        "division_type": "xã",
        "short_codename": "tam_nghia",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Mỹ Tây",
        "code": "xa_tam_my_tay",
        "division_type": "xã",
        "short_codename": "tam_my_tay",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Mỹ Đông",
        "code": "xa_tam_my_dong",
        "division_type": "xã",
        "short_codename": "tam_my_dong",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Xã Tam Trà",
        "code": "xa_tam_tra",
        "division_type": "xã",
        "short_codename": "tam_tra",
        "parentCode": "huyen_nui_thanh"
    },
    {
        "name": "Thị trấn Phú Thịnh",
        "code": "thi_tran_phu_thinh",
        "division_type": "thị trấn",
        "short_codename": "phu_thinh",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam Thành",
        "code": "xa_tam_thanh",
        "division_type": "xã",
        "short_codename": "tam_thanh",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam An",
        "code": "xa_tam_an",
        "division_type": "xã",
        "short_codename": "tam_an",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam Đàn",
        "code": "xa_tam_dan",
        "division_type": "xã",
        "short_codename": "xa_tam_dan",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam Lộc",
        "code": "xa_tam_loc",
        "division_type": "xã",
        "short_codename": "tam_loc",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam Phước",
        "code": "xa_tam_phuoc",
        "division_type": "xã",
        "short_codename": "tam_phuoc",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam Vinh",
        "code": "xa_tam_vinh",
        "division_type": "xã",
        "short_codename": "tam_vinh",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam Thái",
        "code": "xa_tam_thai",
        "division_type": "xã",
        "short_codename": "tam_thai",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam Đại",
        "code": "xa_tam_dai",
        "division_type": "xã",
        "short_codename": "tam_dai",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam Dân",
        "code": "xa_tam_dan",
        "division_type": "xã",
        "short_codename": "xa_tam_dan",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Tam Lãnh",
        "code": "xa_tam_lanh",
        "division_type": "xã",
        "short_codename": "tam_lanh",
        "parentCode": "huyen_phu_ninh"
    },
    {
        "name": "Xã Quế Trung",
        "code": "xa_que_trung",
        "division_type": "xã",
        "short_codename": "que_trung",
        "parentCode": "huyen_nong_son"
    },
    {
        "name": "Xã Ninh Phước",
        "code": "xa_ninh_phuoc",
        "division_type": "xã",
        "short_codename": "ninh_phuoc",
        "parentCode": "huyen_nong_son"
    },
    {
        "name": "Xã Phước Ninh",
        "code": "xa_phuoc_ninh",
        "division_type": "xã",
        "short_codename": "phuoc_ninh",
        "parentCode": "huyen_nong_son"
    },
    {
        "name": "Xã Quế Lộc",
        "code": "xa_que_loc",
        "division_type": "xã",
        "short_codename": "que_loc",
        "parentCode": "huyen_nong_son"
    },
    {
        "name": "Xã Sơn Viên",
        "code": "xa_son_vien",
        "division_type": "xã",
        "short_codename": "son_vien",
        "parentCode": "huyen_nong_son"
    },
    {
        "name": "Xã Quế Lâm",
        "code": "xa_que_lam",
        "division_type": "xã",
        "short_codename": "que_lam",
        "parentCode": "huyen_nong_son"
    },
    {
        "name": "Phường Lê Hồng Phong",
        "code": "phuong_le_hong_phong",
        "division_type": "phường",
        "short_codename": "le_hong_phong",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Phường Trần Phú",
        "code": "phuong_tran_phu",
        "division_type": "phường",
        "short_codename": "tran_phu",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Phường Quảng Phú",
        "code": "phuong_quang_phu",
        "division_type": "phường",
        "short_codename": "quang_phu",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Phường Nghĩa Chánh",
        "code": "phuong_nghia_chanh",
        "division_type": "phường",
        "short_codename": "nghia_chanh",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Phường Trần Hưng Đạo",
        "code": "phuong_tran_hung_dao",
        "division_type": "phường",
        "short_codename": "tran_hung_dao",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Phường Nguyễn Nghiêm",
        "code": "phuong_nguyen_nghiem",
        "division_type": "phường",
        "short_codename": "nguyen_nghiem",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Phường Nghĩa Lộ",
        "code": "phuong_nghia_lo",
        "division_type": "phường",
        "short_codename": "nghia_lo",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Phường Chánh Lộ",
        "code": "phuong_chanh_lo",
        "division_type": "phường",
        "short_codename": "chanh_lo",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Nghĩa Dũng",
        "code": "xa_nghia_dung",
        "division_type": "xã",
        "short_codename": "nghia_dung",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Nghĩa Dõng",
        "code": "xa_nghia_dong",
        "division_type": "xã",
        "short_codename": "nghia_dong",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Phường Trương Quang Trọng",
        "code": "phuong_truong_quang_trong",
        "division_type": "phường",
        "short_codename": "truong_quang_trong",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Tịnh Hòa",
        "code": "xa_tinh_hoa",
        "division_type": "xã",
        "short_codename": "tinh_hoa",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Tịnh Kỳ",
        "code": "xa_tinh_ky",
        "division_type": "xã",
        "short_codename": "tinh_ky",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Tịnh Thiện",
        "code": "xa_tinh_thien",
        "division_type": "xã",
        "short_codename": "tinh_thien",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Tịnh Ấn Đông",
        "code": "xa_tinh_an_dong",
        "division_type": "xã",
        "short_codename": "tinh_an_dong",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Tịnh Châu",
        "code": "xa_tinh_chau",
        "division_type": "xã",
        "short_codename": "tinh_chau",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Tịnh Khê",
        "code": "xa_tinh_khe",
        "division_type": "xã",
        "short_codename": "tinh_khe",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Tịnh Long",
        "code": "xa_tinh_long",
        "division_type": "xã",
        "short_codename": "tinh_long",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Tịnh Ấn Tây",
        "code": "xa_tinh_an_tay",
        "division_type": "xã",
        "short_codename": "tinh_an_tay",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Tịnh An",
        "code": "xa_tinh_an",
        "division_type": "xã",
        "short_codename": "tinh_an",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Nghĩa Phú",
        "code": "xa_nghia_phu",
        "division_type": "xã",
        "short_codename": "nghia_phu",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Nghĩa Hà",
        "code": "xa_nghia_ha",
        "division_type": "xã",
        "short_codename": "nghia_ha",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Xã Nghĩa An",
        "code": "xa_nghia_an",
        "division_type": "xã",
        "short_codename": "nghia_an",
        "parentCode": "thanh_pho_quang_ngai"
    },
    {
        "name": "Thị Trấn Châu Ổ",
        "code": "thi_tran_chau_o",
        "division_type": "thị trấn",
        "short_codename": "chau_o",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Thuận",
        "code": "xa_binh_thuan",
        "division_type": "xã",
        "short_codename": "binh_thuan",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Thạnh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "xa_binh_thanh",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Đông",
        "code": "xa_binh_dong",
        "division_type": "xã",
        "short_codename": "binh_dong",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Chánh",
        "code": "xa_binh_chanh",
        "division_type": "xã",
        "short_codename": "binh_chanh",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Nguyên",
        "code": "xa_binh_nguyen",
        "division_type": "xã",
        "short_codename": "binh_nguyen",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Khương",
        "code": "xa_binh_khuong",
        "division_type": "xã",
        "short_codename": "binh_khuong",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Trị",
        "code": "xa_binh_tri",
        "division_type": "xã",
        "short_codename": "binh_tri",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình An",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Hải",
        "code": "xa_binh_hai",
        "division_type": "xã",
        "short_codename": "binh_hai",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Dương",
        "code": "xa_binh_duong",
        "division_type": "xã",
        "short_codename": "binh_duong",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Phước",
        "code": "xa_binh_phuoc",
        "division_type": "xã",
        "short_codename": "binh_phuoc",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Hòa",
        "code": "xa_binh_hoa",
        "division_type": "xã",
        "short_codename": "binh_hoa",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Trung",
        "code": "xa_binh_trung",
        "division_type": "xã",
        "short_codename": "binh_trung",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Long",
        "code": "xa_binh_long",
        "division_type": "xã",
        "short_codename": "binh_long",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Thanh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "xa_binh_thanh",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Chương",
        "code": "xa_binh_chuong",
        "division_type": "xã",
        "short_codename": "binh_chuong",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Hiệp",
        "code": "xa_binh_hiep",
        "division_type": "xã",
        "short_codename": "binh_hiep",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Mỹ",
        "code": "xa_binh_my",
        "division_type": "xã",
        "short_codename": "binh_my",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Tân Phú",
        "code": "xa_binh_tan_phu",
        "division_type": "xã",
        "short_codename": "binh_tan_phu",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Xã Bình Châu",
        "code": "xa_binh_chau",
        "division_type": "xã",
        "short_codename": "binh_chau",
        "parentCode": "huyen_binh_son"
    },
    {
        "name": "Thị trấn Trà Xuân",
        "code": "thi_tran_tra_xuan",
        "division_type": "thị trấn",
        "short_codename": "tra_xuan",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Giang",
        "code": "xa_tra_giang",
        "division_type": "xã",
        "short_codename": "tra_giang",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Thủy",
        "code": "xa_tra_thuy",
        "division_type": "xã",
        "short_codename": "tra_thuy",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Hiệp",
        "code": "xa_tra_hiep",
        "division_type": "xã",
        "short_codename": "tra_hiep",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Bình",
        "code": "xa_tra_binh",
        "division_type": "xã",
        "short_codename": "tra_binh",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Phú",
        "code": "xa_tra_phu",
        "division_type": "xã",
        "short_codename": "tra_phu",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Lâm",
        "code": "xa_tra_lam",
        "division_type": "xã",
        "short_codename": "tra_lam",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Tân",
        "code": "xa_tra_tan",
        "division_type": "xã",
        "short_codename": "tra_tan",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Sơn",
        "code": "xa_tra_son",
        "division_type": "xã",
        "short_codename": "tra_son",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Bùi",
        "code": "xa_tra_bui",
        "division_type": "xã",
        "short_codename": "tra_bui",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Thanh",
        "code": "xa_tra_thanh",
        "division_type": "xã",
        "short_codename": "tra_thanh",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Sơn Trà",
        "code": "xa_son_tra",
        "division_type": "xã",
        "short_codename": "son_tra",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Phong",
        "code": "xa_tra_phong",
        "division_type": "xã",
        "short_codename": "tra_phong",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Hương Trà",
        "code": "xa_huong_tra",
        "division_type": "xã",
        "short_codename": "huong_tra",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Xinh",
        "code": "xa_tra_xinh",
        "division_type": "xã",
        "short_codename": "tra_xinh",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Trà Tây",
        "code": "xa_tra_tay",
        "division_type": "xã",
        "short_codename": "tra_tay",
        "parentCode": "huyen_tra_bong"
    },
    {
        "name": "Xã Tịnh Thọ",
        "code": "xa_tinh_tho",
        "division_type": "xã",
        "short_codename": "tinh_tho",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Trà",
        "code": "xa_tinh_tra",
        "division_type": "xã",
        "short_codename": "tinh_tra",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Phong",
        "code": "xa_tinh_phong",
        "division_type": "xã",
        "short_codename": "tinh_phong",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Hiệp",
        "code": "xa_tinh_hiep",
        "division_type": "xã",
        "short_codename": "tinh_hiep",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Bình",
        "code": "xa_tinh_binh",
        "division_type": "xã",
        "short_codename": "tinh_binh",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Đông",
        "code": "xa_tinh_dong",
        "division_type": "xã",
        "short_codename": "tinh_dong",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Bắc",
        "code": "xa_tinh_bac",
        "division_type": "xã",
        "short_codename": "tinh_bac",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Sơn",
        "code": "xa_tinh_son",
        "division_type": "xã",
        "short_codename": "tinh_son",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Hà",
        "code": "xa_tinh_ha",
        "division_type": "xã",
        "short_codename": "tinh_ha",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Giang",
        "code": "xa_tinh_giang",
        "division_type": "xã",
        "short_codename": "tinh_giang",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Xã Tịnh Minh",
        "code": "xa_tinh_minh",
        "division_type": "xã",
        "short_codename": "tinh_minh",
        "parentCode": "huyen_son_tinh"
    },
    {
        "name": "Thị trấn La Hà",
        "code": "thi_tran_la_ha",
        "division_type": "thị trấn",
        "short_codename": "la_ha",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Thị trấn Sông Vệ",
        "code": "thi_tran_song_ve",
        "division_type": "thị trấn",
        "short_codename": "song_ve",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Lâm",
        "code": "xa_nghia_lam",
        "division_type": "xã",
        "short_codename": "nghia_lam",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Thắng",
        "code": "xa_nghia_thang",
        "division_type": "xã",
        "short_codename": "nghia_thang",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Thuận",
        "code": "xa_nghia_thuan",
        "division_type": "xã",
        "short_codename": "nghia_thuan",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Kỳ",
        "code": "xa_nghia_ky",
        "division_type": "xã",
        "short_codename": "nghia_ky",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Sơn",
        "code": "xa_nghia_son",
        "division_type": "xã",
        "short_codename": "nghia_son",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Hòa",
        "code": "xa_nghia_hoa",
        "division_type": "xã",
        "short_codename": "nghia_hoa",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Điền",
        "code": "xa_nghia_dien",
        "division_type": "xã",
        "short_codename": "nghia_dien",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Thương",
        "code": "xa_nghia_thuong",
        "division_type": "xã",
        "short_codename": "nghia_thuong",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Trung",
        "code": "xa_nghia_trung",
        "division_type": "xã",
        "short_codename": "nghia_trung",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Hiệp",
        "code": "xa_nghia_hiep",
        "division_type": "xã",
        "short_codename": "nghia_hiep",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Phương",
        "code": "xa_nghia_phuong",
        "division_type": "xã",
        "short_codename": "nghia_phuong",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Xã Nghĩa Mỹ",
        "code": "xa_nghia_my",
        "division_type": "xã",
        "short_codename": "nghia_my",
        "parentCode": "huyen_tu_nghia"
    },
    {
        "name": "Thị trấn Di Lăng",
        "code": "thi_tran_di_lang",
        "division_type": "thị trấn",
        "short_codename": "di_lang",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Hạ",
        "code": "xa_son_ha",
        "division_type": "xã",
        "short_codename": "son_ha",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Thành",
        "code": "xa_son_thanh",
        "division_type": "xã",
        "short_codename": "son_thanh",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Nham",
        "code": "xa_son_nham",
        "division_type": "xã",
        "short_codename": "son_nham",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Bao",
        "code": "xa_son_bao",
        "division_type": "xã",
        "short_codename": "son_bao",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Linh",
        "code": "xa_son_linh",
        "division_type": "xã",
        "short_codename": "son_linh",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Giang",
        "code": "xa_son_giang",
        "division_type": "xã",
        "short_codename": "son_giang",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Trung",
        "code": "xa_son_trung",
        "division_type": "xã",
        "short_codename": "son_trung",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Thượng",
        "code": "xa_son_thuong",
        "division_type": "xã",
        "short_codename": "son_thuong",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Cao",
        "code": "xa_son_cao",
        "division_type": "xã",
        "short_codename": "son_cao",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Hải",
        "code": "xa_son_hai",
        "division_type": "xã",
        "short_codename": "son_hai",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Thủy",
        "code": "xa_son_thuy",
        "division_type": "xã",
        "short_codename": "son_thuy",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Kỳ",
        "code": "xa_son_ky",
        "division_type": "xã",
        "short_codename": "son_ky",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Ba",
        "code": "xa_son_ba",
        "division_type": "xã",
        "short_codename": "son_ba",
        "parentCode": "huyen_son_ha"
    },
    {
        "name": "Xã Sơn Bua",
        "code": "xa_son_bua",
        "division_type": "xã",
        "short_codename": "son_bua",
        "parentCode": "huyen_son_tay"
    },
    {
        "name": "Xã Sơn Mùa",
        "code": "xa_son_mua",
        "division_type": "xã",
        "short_codename": "son_mua",
        "parentCode": "huyen_son_tay"
    },
    {
        "name": "Xã Sơn Liên",
        "code": "xa_son_lien",
        "division_type": "xã",
        "short_codename": "son_lien",
        "parentCode": "huyen_son_tay"
    },
    {
        "name": "Xã Sơn Tân",
        "code": "xa_son_tan",
        "division_type": "xã",
        "short_codename": "son_tan",
        "parentCode": "huyen_son_tay"
    },
    {
        "name": "Xã Sơn Màu",
        "code": "xa_son_mau",
        "division_type": "xã",
        "short_codename": "son_mau",
        "parentCode": "huyen_son_tay"
    },
    {
        "name": "Xã Sơn Dung",
        "code": "xa_son_dung",
        "division_type": "xã",
        "short_codename": "son_dung",
        "parentCode": "huyen_son_tay"
    },
    {
        "name": "Xã Sơn Long",
        "code": "xa_son_long",
        "division_type": "xã",
        "short_codename": "son_long",
        "parentCode": "huyen_son_tay"
    },
    {
        "name": "Xã Sơn Tinh",
        "code": "xa_son_tinh",
        "division_type": "xã",
        "short_codename": "son_tinh",
        "parentCode": "huyen_son_tay"
    },
    {
        "name": "Xã Sơn Lập",
        "code": "xa_son_lap",
        "division_type": "xã",
        "short_codename": "son_lap",
        "parentCode": "huyen_son_tay"
    },
    {
        "name": "Xã Long Sơn",
        "code": "xa_long_son",
        "division_type": "xã",
        "short_codename": "long_son",
        "parentCode": "huyen_minh_long"
    },
    {
        "name": "Xã Long Mai",
        "code": "xa_long_mai",
        "division_type": "xã",
        "short_codename": "long_mai",
        "parentCode": "huyen_minh_long"
    },
    {
        "name": "Xã Thanh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_minh_long"
    },
    {
        "name": "Xã Long Môn",
        "code": "xa_long_mon",
        "division_type": "xã",
        "short_codename": "long_mon",
        "parentCode": "huyen_minh_long"
    },
    {
        "name": "Xã Long Hiệp",
        "code": "xa_long_hiep",
        "division_type": "xã",
        "short_codename": "long_hiep",
        "parentCode": "huyen_minh_long"
    },
    {
        "name": "Thị trấn Chợ Chùa",
        "code": "thi_tran_cho_chua",
        "division_type": "thị trấn",
        "short_codename": "cho_chua",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Thuận",
        "code": "xa_hanh_thuan",
        "division_type": "xã",
        "short_codename": "hanh_thuan",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Dũng",
        "code": "xa_hanh_dung",
        "division_type": "xã",
        "short_codename": "hanh_dung",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Trung",
        "code": "xa_hanh_trung",
        "division_type": "xã",
        "short_codename": "hanh_trung",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Nhân",
        "code": "xa_hanh_nhan",
        "division_type": "xã",
        "short_codename": "hanh_nhan",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Đức",
        "code": "xa_hanh_duc",
        "division_type": "xã",
        "short_codename": "hanh_duc",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Minh",
        "code": "xa_hanh_minh",
        "division_type": "xã",
        "short_codename": "hanh_minh",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Phước",
        "code": "xa_hanh_phuoc",
        "division_type": "xã",
        "short_codename": "hanh_phuoc",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Thiện",
        "code": "xa_hanh_thien",
        "division_type": "xã",
        "short_codename": "hanh_thien",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Thịnh",
        "code": "xa_hanh_thinh",
        "division_type": "xã",
        "short_codename": "hanh_thinh",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Tín Tây",
        "code": "xa_hanh_tin_tay",
        "division_type": "xã",
        "short_codename": "hanh_tin_tay",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Xã Hành Tín Đông",
        "code": "xa_hanh_tin_dong",
        "division_type": "xã",
        "short_codename": "hanh_tin_dong",
        "parentCode": "huyen_nghia_hanh"
    },
    {
        "name": "Thị trấn Mộ Đức",
        "code": "thi_tran_mo_duc",
        "division_type": "thị trấn",
        "short_codename": "mo_duc",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Lợi",
        "code": "xa_duc_loi",
        "division_type": "xã",
        "short_codename": "duc_loi",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Thắng",
        "code": "xa_duc_thang",
        "division_type": "xã",
        "short_codename": "duc_thang",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Nhuận",
        "code": "xa_duc_nhuan",
        "division_type": "xã",
        "short_codename": "duc_nhuan",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Chánh",
        "code": "xa_duc_chanh",
        "division_type": "xã",
        "short_codename": "duc_chanh",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Hiệp",
        "code": "xa_duc_hiep",
        "division_type": "xã",
        "short_codename": "duc_hiep",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Minh",
        "code": "xa_duc_minh",
        "division_type": "xã",
        "short_codename": "duc_minh",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Thạnh",
        "code": "xa_duc_thanh",
        "division_type": "xã",
        "short_codename": "duc_thanh",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Hòa",
        "code": "xa_duc_hoa",
        "division_type": "xã",
        "short_codename": "duc_hoa",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Tân",
        "code": "xa_duc_tan",
        "division_type": "xã",
        "short_codename": "duc_tan",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Phú",
        "code": "xa_duc_phu",
        "division_type": "xã",
        "short_codename": "duc_phu",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Phong",
        "code": "xa_duc_phong",
        "division_type": "xã",
        "short_codename": "duc_phong",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Xã Đức Lân",
        "code": "xa_duc_lan",
        "division_type": "xã",
        "short_codename": "duc_lan",
        "parentCode": "huyen_mo_duc"
    },
    {
        "name": "Phường Nguyễn Nghiêm",
        "code": "phuong_nguyen_nghiem",
        "division_type": "phường",
        "short_codename": "nguyen_nghiem",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Xã Phổ An",
        "code": "xa_pho_an",
        "division_type": "xã",
        "short_codename": "pho_an",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Xã Phổ Phong",
        "code": "xa_pho_phong",
        "division_type": "xã",
        "short_codename": "pho_phong",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Xã Phổ Thuận",
        "code": "xa_pho_thuan",
        "division_type": "xã",
        "short_codename": "pho_thuan",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Phường Phổ Văn",
        "code": "phuong_pho_van",
        "division_type": "phường",
        "short_codename": "pho_van",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Phường Phổ Quang",
        "code": "phuong_pho_quang",
        "division_type": "phường",
        "short_codename": "pho_quang",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Xã Phổ Nhơn",
        "code": "xa_pho_nhon",
        "division_type": "xã",
        "short_codename": "pho_nhon",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Phường Phổ Ninh",
        "code": "phuong_pho_ninh",
        "division_type": "phường",
        "short_codename": "pho_ninh",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Phường Phổ Minh",
        "code": "phuong_pho_minh",
        "division_type": "phường",
        "short_codename": "pho_minh",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Phường Phổ Vinh",
        "code": "phuong_pho_vinh",
        "division_type": "phường",
        "short_codename": "pho_vinh",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Phường Phổ Hòa",
        "code": "phuong_pho_hoa",
        "division_type": "phường",
        "short_codename": "pho_hoa",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Xã Phổ Cường",
        "code": "xa_pho_cuong",
        "division_type": "xã",
        "short_codename": "pho_cuong",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Xã Phổ Khánh",
        "code": "xa_pho_khanh",
        "division_type": "xã",
        "short_codename": "pho_khanh",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Phường Phổ Thạnh",
        "code": "phuong_pho_thanh",
        "division_type": "phường",
        "short_codename": "pho_thanh",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Xã Phổ Châu",
        "code": "xa_pho_chau",
        "division_type": "xã",
        "short_codename": "pho_chau",
        "parentCode": "thi_xa_duc_pho"
    },
    {
        "name": "Thị trấn Ba Tơ",
        "code": "thi_tran_ba_to",
        "division_type": "thị trấn",
        "short_codename": "ba_to",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Điền",
        "code": "xa_ba_dien",
        "division_type": "xã",
        "short_codename": "ba_dien",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Vinh",
        "code": "xa_ba_vinh",
        "division_type": "xã",
        "short_codename": "ba_vinh",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Thành",
        "code": "xa_ba_thanh",
        "division_type": "xã",
        "short_codename": "ba_thanh",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Động",
        "code": "xa_ba_dong",
        "division_type": "xã",
        "short_codename": "ba_dong",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Dinh",
        "code": "xa_ba_dinh",
        "division_type": "xã",
        "short_codename": "ba_dinh",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Giang",
        "code": "xa_ba_giang",
        "division_type": "xã",
        "short_codename": "ba_giang",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Liên",
        "code": "xa_ba_lien",
        "division_type": "xã",
        "short_codename": "ba_lien",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Ngạc",
        "code": "xa_ba_ngac",
        "division_type": "xã",
        "short_codename": "ba_ngac",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Khâm",
        "code": "xa_ba_kham",
        "division_type": "xã",
        "short_codename": "ba_kham",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Cung",
        "code": "xa_ba_cung",
        "division_type": "xã",
        "short_codename": "ba_cung",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Tiêu",
        "code": "xa_ba_tieu",
        "division_type": "xã",
        "short_codename": "ba_tieu",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Trang",
        "code": "xa_ba_trang",
        "division_type": "xã",
        "short_codename": "ba_trang",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Tô",
        "code": "xa_ba_to",
        "division_type": "xã",
        "short_codename": "xa_ba_to",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Bích",
        "code": "xa_ba_bich",
        "division_type": "xã",
        "short_codename": "ba_bich",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Vì",
        "code": "xa_ba_vi",
        "division_type": "xã",
        "short_codename": "ba_vi",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Lế",
        "code": "xa_ba_le",
        "division_type": "xã",
        "short_codename": "ba_le",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Nam",
        "code": "xa_ba_nam",
        "division_type": "xã",
        "short_codename": "ba_nam",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Xã Ba Xa",
        "code": "xa_ba_xa",
        "division_type": "xã",
        "short_codename": "ba_xa",
        "parentCode": "huyen_ba_to"
    },
    {
        "name": "Phường Nhơn Bình",
        "code": "phuong_nhon_binh",
        "division_type": "phường",
        "short_codename": "nhon_binh",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Nhơn Phú",
        "code": "phuong_nhon_phu",
        "division_type": "phường",
        "short_codename": "nhon_phu",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Đống Đa",
        "code": "phuong_dong_da",
        "division_type": "phường",
        "short_codename": "dong_da",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Trần Quang Diệu",
        "code": "phuong_tran_quang_dieu",
        "division_type": "phường",
        "short_codename": "tran_quang_dieu",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Hải Cảng",
        "code": "phuong_hai_cang",
        "division_type": "phường",
        "short_codename": "hai_cang",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Thị Nại",
        "code": "phuong_thi_nai",
        "division_type": "phường",
        "short_codename": "thi_nai",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Lê Hồng Phong",
        "code": "phuong_le_hong_phong",
        "division_type": "phường",
        "short_codename": "le_hong_phong",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Trần Hưng Đạo",
        "code": "phuong_tran_hung_dao",
        "division_type": "phường",
        "short_codename": "tran_hung_dao",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Ngô Mây",
        "code": "phuong_ngo_may",
        "division_type": "phường",
        "short_codename": "ngo_may",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Lý Thường Kiệt",
        "code": "phuong_ly_thuong_kiet",
        "division_type": "phường",
        "short_codename": "ly_thuong_kiet",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Lê Lợi",
        "code": "phuong_le_loi",
        "division_type": "phường",
        "short_codename": "le_loi",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Trần Phú",
        "code": "phuong_tran_phu",
        "division_type": "phường",
        "short_codename": "tran_phu",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Bùi Thị Xuân",
        "code": "phuong_bui_thi_xuan",
        "division_type": "phường",
        "short_codename": "bui_thi_xuan",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Nguyễn Văn Cừ",
        "code": "phuong_nguyen_van_cu",
        "division_type": "phường",
        "short_codename": "nguyen_van_cu",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Phường Ghềnh Ráng",
        "code": "phuong_ghenh_rang",
        "division_type": "phường",
        "short_codename": "ghenh_rang",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Xã Nhơn Lý",
        "code": "xa_nhon_ly",
        "division_type": "xã",
        "short_codename": "nhon_ly",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Xã Nhơn Hội",
        "code": "xa_nhon_hoi",
        "division_type": "xã",
        "short_codename": "nhon_hoi",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Xã Nhơn Hải",
        "code": "xa_nhon_hai",
        "division_type": "xã",
        "short_codename": "nhon_hai",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Xã Nhơn Châu",
        "code": "xa_nhon_chau",
        "division_type": "xã",
        "short_codename": "nhon_chau",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Xã Phước Mỹ",
        "code": "xa_phuoc_my",
        "division_type": "xã",
        "short_codename": "phuoc_my",
        "parentCode": "thanh_pho_quy_nhon"
    },
    {
        "name": "Thị trấn An Lão",
        "code": "thi_tran_an_lao",
        "division_type": "thị trấn",
        "short_codename": "an_lao",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Hưng",
        "code": "xa_an_hung",
        "division_type": "xã",
        "short_codename": "an_hung",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Trung",
        "code": "xa_an_trung",
        "division_type": "xã",
        "short_codename": "an_trung",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Dũng",
        "code": "xa_an_dung",
        "division_type": "xã",
        "short_codename": "an_dung",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Vinh",
        "code": "xa_an_vinh",
        "division_type": "xã",
        "short_codename": "an_vinh",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Toàn",
        "code": "xa_an_toan",
        "division_type": "xã",
        "short_codename": "an_toan",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Tân",
        "code": "xa_an_tan",
        "division_type": "xã",
        "short_codename": "an_tan",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Hòa",
        "code": "xa_an_hoa",
        "division_type": "xã",
        "short_codename": "an_hoa",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Quang",
        "code": "xa_an_quang",
        "division_type": "xã",
        "short_codename": "an_quang",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Xã An Nghĩa",
        "code": "xa_an_nghia",
        "division_type": "xã",
        "short_codename": "an_nghia",
        "parentCode": "huyen_an_lao"
    },
    {
        "name": "Phường Tam Quan",
        "code": "phuong_tam_quan",
        "division_type": "phường",
        "short_codename": "tam_quan",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Bồng Sơn",
        "code": "phuong_bong_son",
        "division_type": "phường",
        "short_codename": "bong_son",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Xã Hoài Sơn",
        "code": "xa_hoai_son",
        "division_type": "xã",
        "short_codename": "hoai_son",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Xã Hoài Châu Bắc",
        "code": "xa_hoai_chau_bac",
        "division_type": "xã",
        "short_codename": "hoai_chau_bac",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Xã Hoài Châu",
        "code": "xa_hoai_chau",
        "division_type": "xã",
        "short_codename": "hoai_chau",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Xã Hoài Phú",
        "code": "xa_hoai_phu",
        "division_type": "xã",
        "short_codename": "hoai_phu",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Tam Quan Bắc",
        "code": "phuong_tam_quan_bac",
        "division_type": "phường",
        "short_codename": "tam_quan_bac",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Tam Quan Nam",
        "code": "phuong_tam_quan_nam",
        "division_type": "phường",
        "short_codename": "tam_quan_nam",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Hoài Hảo",
        "code": "phuong_hoai_hao",
        "division_type": "phường",
        "short_codename": "hoai_hao",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Hoài Thanh Tây",
        "code": "phuong_hoai_thanh_tay",
        "division_type": "phường",
        "short_codename": "hoai_thanh_tay",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Hoài Thanh",
        "code": "phuong_hoai_thanh",
        "division_type": "phường",
        "short_codename": "hoai_thanh",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Hoài Hương",
        "code": "phuong_hoai_huong",
        "division_type": "phường",
        "short_codename": "hoai_huong",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Hoài Tân",
        "code": "phuong_hoai_tan",
        "division_type": "phường",
        "short_codename": "hoai_tan",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Xã Hoài Hải",
        "code": "xa_hoai_hai",
        "division_type": "xã",
        "short_codename": "hoai_hai",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Hoài Xuân",
        "code": "phuong_hoai_xuan",
        "division_type": "phường",
        "short_codename": "hoai_xuan",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Xã Hoài Mỹ",
        "code": "xa_hoai_my",
        "division_type": "xã",
        "short_codename": "hoai_my",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Phường Hoài Đức",
        "code": "phuong_hoai_duc",
        "division_type": "phường",
        "short_codename": "hoai_duc",
        "parentCode": "thi_xa_hoai_nhon"
    },
    {
        "name": "Thị trấn Tăng Bạt Hổ",
        "code": "thi_tran_tang_bat_ho",
        "division_type": "thị trấn",
        "short_codename": "tang_bat_ho",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Hảo Tây",
        "code": "xa_an_hao_tay",
        "division_type": "xã",
        "short_codename": "an_hao_tay",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Hảo Đông",
        "code": "xa_an_hao_dong",
        "division_type": "xã",
        "short_codename": "an_hao_dong",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Sơn",
        "code": "xa_an_son",
        "division_type": "xã",
        "short_codename": "an_son",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Mỹ",
        "code": "xa_an_my",
        "division_type": "xã",
        "short_codename": "an_my",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Đak Mang",
        "code": "xa_dak_mang",
        "division_type": "xã",
        "short_codename": "dak_mang",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Tín",
        "code": "xa_an_tin",
        "division_type": "xã",
        "short_codename": "an_tin",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Thạnh",
        "code": "xa_an_thanh",
        "division_type": "xã",
        "short_codename": "an_thanh",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Phong",
        "code": "xa_an_phong",
        "division_type": "xã",
        "short_codename": "an_phong",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Đức",
        "code": "xa_an_duc",
        "division_type": "xã",
        "short_codename": "an_duc",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Hữu",
        "code": "xa_an_huu",
        "division_type": "xã",
        "short_codename": "an_huu",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Bok Tới",
        "code": "xa_bok_toi",
        "division_type": "xã",
        "short_codename": "bok_toi",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Tường Tây",
        "code": "xa_an_tuong_tay",
        "division_type": "xã",
        "short_codename": "an_tuong_tay",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Tường Đông",
        "code": "xa_an_tuong_dong",
        "division_type": "xã",
        "short_codename": "an_tuong_dong",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Xã Ân Nghĩa",
        "code": "xa_an_nghia",
        "division_type": "xã",
        "short_codename": "an_nghia",
        "parentCode": "huyen_hoai_an"
    },
    {
        "name": "Thị trấn Phù Mỹ",
        "code": "thi_tran_phu_my",
        "division_type": "thị trấn",
        "short_codename": "phu_my",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Thị trấn Bình Dương",
        "code": "thi_tran_binh_duong",
        "division_type": "thị trấn",
        "short_codename": "binh_duong",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Đức",
        "code": "xa_my_duc",
        "division_type": "xã",
        "short_codename": "my_duc",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Châu",
        "code": "xa_my_chau",
        "division_type": "xã",
        "short_codename": "my_chau",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Thắng",
        "code": "xa_my_thang",
        "division_type": "xã",
        "short_codename": "my_thang",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Lộc",
        "code": "xa_my_loc",
        "division_type": "xã",
        "short_codename": "my_loc",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Lợi",
        "code": "xa_my_loi",
        "division_type": "xã",
        "short_codename": "my_loi",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ An",
        "code": "xa_my_an",
        "division_type": "xã",
        "short_codename": "my_an",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Phong",
        "code": "xa_my_phong",
        "division_type": "xã",
        "short_codename": "my_phong",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Trinh",
        "code": "xa_my_trinh",
        "division_type": "xã",
        "short_codename": "my_trinh",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Thọ",
        "code": "xa_my_tho",
        "division_type": "xã",
        "short_codename": "my_tho",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Hòa",
        "code": "xa_my_hoa",
        "division_type": "xã",
        "short_codename": "my_hoa",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Thành",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Chánh",
        "code": "xa_my_chanh",
        "division_type": "xã",
        "short_codename": "my_chanh",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Quang",
        "code": "xa_my_quang",
        "division_type": "xã",
        "short_codename": "my_quang",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Hiệp",
        "code": "xa_my_hiep",
        "division_type": "xã",
        "short_codename": "my_hiep",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Tài",
        "code": "xa_my_tai",
        "division_type": "xã",
        "short_codename": "my_tai",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Cát",
        "code": "xa_my_cat",
        "division_type": "xã",
        "short_codename": "my_cat",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Xã Mỹ Chánh Tây",
        "code": "xa_my_chanh_tay",
        "division_type": "xã",
        "short_codename": "my_chanh_tay",
        "parentCode": "huyen_phu_my"
    },
    {
        "name": "Thị trấn Vĩnh Thạnh",
        "code": "thi_tran_vinh_thanh",
        "division_type": "thị trấn",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Vĩnh Sơn",
        "code": "xa_vinh_son",
        "division_type": "xã",
        "short_codename": "vinh_son",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Vĩnh Kim",
        "code": "xa_vinh_kim",
        "division_type": "xã",
        "short_codename": "vinh_kim",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Vĩnh Hiệp",
        "code": "xa_vinh_hiep",
        "division_type": "xã",
        "short_codename": "vinh_hiep",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Vĩnh Hảo",
        "code": "xa_vinh_hao",
        "division_type": "xã",
        "short_codename": "vinh_hao",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Vĩnh Hòa",
        "code": "xa_vinh_hoa",
        "division_type": "xã",
        "short_codename": "vinh_hoa",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Vĩnh Thịnh",
        "code": "xa_vinh_thinh",
        "division_type": "xã",
        "short_codename": "vinh_thinh",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Vĩnh Thuận",
        "code": "xa_vinh_thuan",
        "division_type": "xã",
        "short_codename": "vinh_thuan",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Vĩnh Quang",
        "code": "xa_vinh_quang",
        "division_type": "xã",
        "short_codename": "vinh_quang",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Thị trấn Phú Phong",
        "code": "thi_tran_phu_phong",
        "division_type": "thị trấn",
        "short_codename": "phu_phong",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Bình Tân",
        "code": "xa_binh_tan",
        "division_type": "xã",
        "short_codename": "binh_tan",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Tây Thuận",
        "code": "xa_tay_thuan",
        "division_type": "xã",
        "short_codename": "tay_thuan",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Bình Thuận",
        "code": "xa_binh_thuan",
        "division_type": "xã",
        "short_codename": "binh_thuan",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Tây Giang",
        "code": "xa_tay_giang",
        "division_type": "xã",
        "short_codename": "tay_giang",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Bình Thành",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Tây An",
        "code": "xa_tay_an",
        "division_type": "xã",
        "short_codename": "tay_an",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Bình Hòa",
        "code": "xa_binh_hoa",
        "division_type": "xã",
        "short_codename": "binh_hoa",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Tây Bình",
        "code": "xa_tay_binh",
        "division_type": "xã",
        "short_codename": "tay_binh",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Bình Tường",
        "code": "xa_binh_tuong",
        "division_type": "xã",
        "short_codename": "binh_tuong",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Tây Vinh",
        "code": "xa_tay_vinh",
        "division_type": "xã",
        "short_codename": "tay_vinh",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Vĩnh An",
        "code": "xa_vinh_an",
        "division_type": "xã",
        "short_codename": "vinh_an",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Tây Xuân",
        "code": "xa_tay_xuan",
        "division_type": "xã",
        "short_codename": "tay_xuan",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Bình Nghi",
        "code": "xa_binh_nghi",
        "division_type": "xã",
        "short_codename": "binh_nghi",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Xã Tây Phú",
        "code": "xa_tay_phu",
        "division_type": "xã",
        "short_codename": "tay_phu",
        "parentCode": "huyen_tay_son"
    },
    {
        "name": "Thị trấn Ngô Mây",
        "code": "thi_tran_ngo_may",
        "division_type": "thị trấn",
        "short_codename": "ngo_may",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Sơn",
        "code": "xa_cat_son",
        "division_type": "xã",
        "short_codename": "cat_son",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Minh",
        "code": "xa_cat_minh",
        "division_type": "xã",
        "short_codename": "cat_minh",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Khánh",
        "code": "xa_cat_khanh",
        "division_type": "xã",
        "short_codename": "cat_khanh",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Tài",
        "code": "xa_cat_tai",
        "division_type": "xã",
        "short_codename": "cat_tai",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Lâm",
        "code": "xa_cat_lam",
        "division_type": "xã",
        "short_codename": "cat_lam",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Hanh",
        "code": "xa_cat_hanh",
        "division_type": "xã",
        "short_codename": "cat_hanh",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Thành",
        "code": "xa_cat_thanh",
        "division_type": "xã",
        "short_codename": "cat_thanh",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Trinh",
        "code": "xa_cat_trinh",
        "division_type": "xã",
        "short_codename": "cat_trinh",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Hải",
        "code": "xa_cat_hai",
        "division_type": "xã",
        "short_codename": "cat_hai",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Hiệp",
        "code": "xa_cat_hiep",
        "division_type": "xã",
        "short_codename": "cat_hiep",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Nhơn",
        "code": "xa_cat_nhon",
        "division_type": "xã",
        "short_codename": "cat_nhon",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Hưng",
        "code": "xa_cat_hung",
        "division_type": "xã",
        "short_codename": "cat_hung",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Tường",
        "code": "xa_cat_tuong",
        "division_type": "xã",
        "short_codename": "cat_tuong",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Tân",
        "code": "xa_cat_tan",
        "division_type": "xã",
        "short_codename": "cat_tan",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Thị trấn Cát Tiến",
        "code": "thi_tran_cat_tien",
        "division_type": "thị trấn",
        "short_codename": "cat_tien",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Thắng",
        "code": "xa_cat_thang",
        "division_type": "xã",
        "short_codename": "cat_thang",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Xã Cát Chánh",
        "code": "xa_cat_chanh",
        "division_type": "xã",
        "short_codename": "cat_chanh",
        "parentCode": "huyen_phu_cat"
    },
    {
        "name": "Phường Bình Định",
        "code": "phuong_binh_dinh",
        "division_type": "phường",
        "short_codename": "binh_dinh",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Phường Đập Đá",
        "code": "phuong_dap_da",
        "division_type": "phường",
        "short_codename": "dap_da",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn Mỹ",
        "code": "xa_nhon_my",
        "division_type": "xã",
        "short_codename": "nhon_my",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Phường Nhơn Thành",
        "code": "phuong_nhon_thanh",
        "division_type": "phường",
        "short_codename": "nhon_thanh",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn Hạnh",
        "code": "xa_nhon_hanh",
        "division_type": "xã",
        "short_codename": "nhon_hanh",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn Hậu",
        "code": "xa_nhon_hau",
        "division_type": "xã",
        "short_codename": "nhon_hau",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn Phong",
        "code": "xa_nhon_phong",
        "division_type": "xã",
        "short_codename": "nhon_phong",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn An",
        "code": "xa_nhon_an",
        "division_type": "xã",
        "short_codename": "nhon_an",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn Phúc",
        "code": "xa_nhon_phuc",
        "division_type": "xã",
        "short_codename": "nhon_phuc",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Phường Nhơn Hưng",
        "code": "phuong_nhon_hung",
        "division_type": "phường",
        "short_codename": "nhon_hung",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn Khánh",
        "code": "xa_nhon_khanh",
        "division_type": "xã",
        "short_codename": "nhon_khanh",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn Lộc",
        "code": "xa_nhon_loc",
        "division_type": "xã",
        "short_codename": "nhon_loc",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Phường Nhơn Hoà",
        "code": "phuong_nhon_hoa",
        "division_type": "phường",
        "short_codename": "nhon_hoa",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn Tân",
        "code": "xa_nhon_tan",
        "division_type": "xã",
        "short_codename": "nhon_tan",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Xã Nhơn Thọ",
        "code": "xa_nhon_tho",
        "division_type": "xã",
        "short_codename": "nhon_tho",
        "parentCode": "thi_xa_an_nhon"
    },
    {
        "name": "Thị trấn Tuy Phước",
        "code": "thi_tran_tuy_phuoc",
        "division_type": "thị trấn",
        "short_codename": "tuy_phuoc",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Thị trấn Diêu Trì",
        "code": "thi_tran_dieu_tri",
        "division_type": "thị trấn",
        "short_codename": "dieu_tri",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Thắng",
        "code": "xa_phuoc_thang",
        "division_type": "xã",
        "short_codename": "phuoc_thang",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Hưng",
        "code": "xa_phuoc_hung",
        "division_type": "xã",
        "short_codename": "phuoc_hung",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Quang",
        "code": "xa_phuoc_quang",
        "division_type": "xã",
        "short_codename": "phuoc_quang",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Hòa",
        "code": "xa_phuoc_hoa",
        "division_type": "xã",
        "short_codename": "phuoc_hoa",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Sơn",
        "code": "xa_phuoc_son",
        "division_type": "xã",
        "short_codename": "phuoc_son",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Hiệp",
        "code": "xa_phuoc_hiep",
        "division_type": "xã",
        "short_codename": "phuoc_hiep",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Lộc",
        "code": "xa_phuoc_loc",
        "division_type": "xã",
        "short_codename": "phuoc_loc",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Nghĩa",
        "code": "xa_phuoc_nghia",
        "division_type": "xã",
        "short_codename": "phuoc_nghia",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Thuận",
        "code": "xa_phuoc_thuan",
        "division_type": "xã",
        "short_codename": "phuoc_thuan",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước An",
        "code": "xa_phuoc_an",
        "division_type": "xã",
        "short_codename": "phuoc_an",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Xã Phước Thành",
        "code": "xa_phuoc_thanh",
        "division_type": "xã",
        "short_codename": "phuoc_thanh",
        "parentCode": "huyen_tuy_phuoc"
    },
    {
        "name": "Thị trấn Vân Canh",
        "code": "thi_tran_van_canh",
        "division_type": "thị trấn",
        "short_codename": "van_canh",
        "parentCode": "huyen_van_canh"
    },
    {
        "name": "Xã Canh Liên",
        "code": "xa_canh_lien",
        "division_type": "xã",
        "short_codename": "canh_lien",
        "parentCode": "huyen_van_canh"
    },
    {
        "name": "Xã Canh Hiệp",
        "code": "xa_canh_hiep",
        "division_type": "xã",
        "short_codename": "canh_hiep",
        "parentCode": "huyen_van_canh"
    },
    {
        "name": "Xã Canh Vinh",
        "code": "xa_canh_vinh",
        "division_type": "xã",
        "short_codename": "canh_vinh",
        "parentCode": "huyen_van_canh"
    },
    {
        "name": "Xã Canh Hiển",
        "code": "xa_canh_hien",
        "division_type": "xã",
        "short_codename": "canh_hien",
        "parentCode": "huyen_van_canh"
    },
    {
        "name": "Xã Canh Thuận",
        "code": "xa_canh_thuan",
        "division_type": "xã",
        "short_codename": "canh_thuan",
        "parentCode": "huyen_van_canh"
    },
    {
        "name": "Xã Canh Hòa",
        "code": "xa_canh_hoa",
        "division_type": "xã",
        "short_codename": "canh_hoa",
        "parentCode": "huyen_van_canh"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường 9",
        "code": "phuong_9",
        "division_type": "phường",
        "short_codename": "phuong_9",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường Phú Thạnh",
        "code": "phuong_phu_thanh",
        "division_type": "phường",
        "short_codename": "phu_thanh",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường Phú Đông",
        "code": "phuong_phu_dong",
        "division_type": "phường",
        "short_codename": "phu_dong",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Xã Hòa Kiến",
        "code": "xa_hoa_kien",
        "division_type": "xã",
        "short_codename": "hoa_kien",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Xã Bình Kiến",
        "code": "xa_binh_kien",
        "division_type": "xã",
        "short_codename": "binh_kien",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Xã Bình Ngọc",
        "code": "xa_binh_ngoc",
        "division_type": "xã",
        "short_codename": "binh_ngoc",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Xã An Phú",
        "code": "xa_an_phu",
        "division_type": "xã",
        "short_codename": "an_phu",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường Phú Lâm",
        "code": "phuong_phu_lam",
        "division_type": "phường",
        "short_codename": "phu_lam",
        "parentCode": "thanh_pho_tuy_hoa"
    },
    {
        "name": "Phường Xuân Phú",
        "code": "phuong_xuan_phu",
        "division_type": "phường",
        "short_codename": "xuan_phu",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Xã Xuân Lâm",
        "code": "xa_xuan_lam",
        "division_type": "xã",
        "short_codename": "xuan_lam",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Phường Xuân Thành",
        "code": "phuong_xuan_thanh",
        "division_type": "phường",
        "short_codename": "xuan_thanh",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Xã Xuân Hải",
        "code": "xa_xuan_hai",
        "division_type": "xã",
        "short_codename": "xuan_hai",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Xã Xuân Lộc",
        "code": "xa_xuan_loc",
        "division_type": "xã",
        "short_codename": "xuan_loc",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Xã Xuân Bình",
        "code": "xa_xuan_binh",
        "division_type": "xã",
        "short_codename": "xuan_binh",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Xã Xuân Cảnh",
        "code": "xa_xuan_canh",
        "division_type": "xã",
        "short_codename": "xuan_canh",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Xã Xuân Thịnh",
        "code": "xa_xuan_thinh",
        "division_type": "xã",
        "short_codename": "xuan_thinh",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Xã Xuân Phương",
        "code": "xa_xuan_phuong",
        "division_type": "xã",
        "short_codename": "xuan_phuong",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Phường Xuân Yên",
        "code": "phuong_xuan_yen",
        "division_type": "phường",
        "short_codename": "xuan_yen",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Xã Xuân Thọ 1",
        "code": "xa_xuan_tho_1",
        "division_type": "xã",
        "short_codename": "xuan_tho_1",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Phường Xuân Đài",
        "code": "phuong_xuan_dai",
        "division_type": "phường",
        "short_codename": "xuan_dai",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Xã Xuân Thọ 2",
        "code": "xa_xuan_tho_2",
        "division_type": "xã",
        "short_codename": "xuan_tho_2",
        "parentCode": "thi_xa_song_cau"
    },
    {
        "name": "Thị trấn La Hai",
        "code": "thi_tran_la_hai",
        "division_type": "thị trấn",
        "short_codename": "la_hai",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Đa Lộc",
        "code": "xa_da_loc",
        "division_type": "xã",
        "short_codename": "da_loc",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Phú Mỡ",
        "code": "xa_phu_mo",
        "division_type": "xã",
        "short_codename": "phu_mo",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Xuân Lãnh",
        "code": "xa_xuan_lanh",
        "division_type": "xã",
        "short_codename": "xuan_lanh",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Xuân Long",
        "code": "xa_xuan_long",
        "division_type": "xã",
        "short_codename": "xuan_long",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Xuân Quang 1",
        "code": "xa_xuan_quang_1",
        "division_type": "xã",
        "short_codename": "xuan_quang_1",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Xuân Sơn Bắc",
        "code": "xa_xuan_son_bac",
        "division_type": "xã",
        "short_codename": "xuan_son_bac",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Xuân Quang 2",
        "code": "xa_xuan_quang_2",
        "division_type": "xã",
        "short_codename": "xuan_quang_2",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Xuân Sơn Nam",
        "code": "xa_xuan_son_nam",
        "division_type": "xã",
        "short_codename": "xuan_son_nam",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Xuân Quang 3",
        "code": "xa_xuan_quang_3",
        "division_type": "xã",
        "short_codename": "xuan_quang_3",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Xã Xuân Phước",
        "code": "xa_xuan_phuoc",
        "division_type": "xã",
        "short_codename": "xuan_phuoc",
        "parentCode": "huyen_dong_xuan"
    },
    {
        "name": "Thị trấn Chí Thạnh",
        "code": "thi_tran_chi_thanh",
        "division_type": "thị trấn",
        "short_codename": "chi_thanh",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Dân",
        "code": "xa_an_dan",
        "division_type": "xã",
        "short_codename": "an_dan",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Ninh Tây",
        "code": "xa_an_ninh_tay",
        "division_type": "xã",
        "short_codename": "an_ninh_tay",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Ninh Đông",
        "code": "xa_an_ninh_dong",
        "division_type": "xã",
        "short_codename": "an_ninh_dong",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Thạch",
        "code": "xa_an_thach",
        "division_type": "xã",
        "short_codename": "an_thach",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Định",
        "code": "xa_an_dinh",
        "division_type": "xã",
        "short_codename": "an_dinh",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Nghiệp",
        "code": "xa_an_nghiep",
        "division_type": "xã",
        "short_codename": "an_nghiep",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Cư",
        "code": "xa_an_cu",
        "division_type": "xã",
        "short_codename": "an_cu",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Xuân",
        "code": "xa_an_xuan",
        "division_type": "xã",
        "short_codename": "an_xuan",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Lĩnh",
        "code": "xa_an_linh",
        "division_type": "xã",
        "short_codename": "an_linh",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Hòa Hải",
        "code": "xa_an_hoa_hai",
        "division_type": "xã",
        "short_codename": "an_hoa_hai",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Hiệp",
        "code": "xa_an_hiep",
        "division_type": "xã",
        "short_codename": "an_hiep",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Mỹ",
        "code": "xa_an_my",
        "division_type": "xã",
        "short_codename": "an_my",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Chấn",
        "code": "xa_an_chan",
        "division_type": "xã",
        "short_codename": "an_chan",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Xã An Thọ",
        "code": "xa_an_tho",
        "division_type": "xã",
        "short_codename": "an_tho",
        "parentCode": "huyen_tuy_an"
    },
    {
        "name": "Thị trấn Củng Sơn",
        "code": "thi_tran_cung_son",
        "division_type": "thị trấn",
        "short_codename": "cung_son",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Phước Tân",
        "code": "xa_phuoc_tan",
        "division_type": "xã",
        "short_codename": "phuoc_tan",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Sơn Hội",
        "code": "xa_son_hoi",
        "division_type": "xã",
        "short_codename": "son_hoi",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Sơn Định",
        "code": "xa_son_dinh",
        "division_type": "xã",
        "short_codename": "son_dinh",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Sơn Long",
        "code": "xa_son_long",
        "division_type": "xã",
        "short_codename": "son_long",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Cà Lúi",
        "code": "xa_ca_lui",
        "division_type": "xã",
        "short_codename": "ca_lui",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Sơn Phước",
        "code": "xa_son_phuoc",
        "division_type": "xã",
        "short_codename": "son_phuoc",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Sơn Xuân",
        "code": "xa_son_xuan",
        "division_type": "xã",
        "short_codename": "son_xuan",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Sơn Nguyên",
        "code": "xa_son_nguyen",
        "division_type": "xã",
        "short_codename": "son_nguyen",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Eachà Rang",
        "code": "xa_eacha_rang",
        "division_type": "xã",
        "short_codename": "eacha_rang",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Krông Pa",
        "code": "xa_krong_pa",
        "division_type": "xã",
        "short_codename": "krong_pa",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Suối Bạc",
        "code": "xa_suoi_bac",
        "division_type": "xã",
        "short_codename": "suoi_bac",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Sơn Hà",
        "code": "xa_son_ha",
        "division_type": "xã",
        "short_codename": "son_ha",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Xã Suối Trai",
        "code": "xa_suoi_trai",
        "division_type": "xã",
        "short_codename": "suoi_trai",
        "parentCode": "huyen_son_hoa"
    },
    {
        "name": "Thị trấn Hai Riêng",
        "code": "thi_tran_hai_rieng",
        "division_type": "thị trấn",
        "short_codename": "hai_rieng",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã Ea Lâm",
        "code": "xa_ea_lam",
        "division_type": "xã",
        "short_codename": "ea_lam",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã Đức Bình Tây",
        "code": "xa_duc_binh_tay",
        "division_type": "xã",
        "short_codename": "duc_binh_tay",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã Ea Bá",
        "code": "xa_ea_ba",
        "division_type": "xã",
        "short_codename": "ea_ba",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã Sơn Giang",
        "code": "xa_son_giang",
        "division_type": "xã",
        "short_codename": "son_giang",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã Đức Bình Đông",
        "code": "xa_duc_binh_dong",
        "division_type": "xã",
        "short_codename": "duc_binh_dong",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã EaBar",
        "code": "xa_eabar",
        "division_type": "xã",
        "short_codename": "eabar",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã EaBia",
        "code": "xa_eabia",
        "division_type": "xã",
        "short_codename": "eabia",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã EaTrol",
        "code": "xa_eatrol",
        "division_type": "xã",
        "short_codename": "eatrol",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã Sông Hinh",
        "code": "xa_song_hinh",
        "division_type": "xã",
        "short_codename": "song_hinh",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã Ealy",
        "code": "xa_ealy",
        "division_type": "xã",
        "short_codename": "ealy",
        "parentCode": "huyen_song_hinh"
    },
    {
        "name": "Xã Sơn Thành Tây",
        "code": "xa_son_thanh_tay",
        "division_type": "xã",
        "short_codename": "son_thanh_tay",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Sơn Thành Đông",
        "code": "xa_son_thanh_dong",
        "division_type": "xã",
        "short_codename": "son_thanh_dong",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Hòa Bình 1",
        "code": "xa_hoa_binh_1",
        "division_type": "xã",
        "short_codename": "hoa_binh_1",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Thị trấn Phú Thứ",
        "code": "thi_tran_phu_thu",
        "division_type": "thị trấn",
        "short_codename": "phu_thu",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Hòa Phong",
        "code": "xa_hoa_phong",
        "division_type": "xã",
        "short_codename": "hoa_phong",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Hòa Phú",
        "code": "xa_hoa_phu",
        "division_type": "xã",
        "short_codename": "hoa_phu",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Hòa Tân Tây",
        "code": "xa_hoa_tan_tay",
        "division_type": "xã",
        "short_codename": "hoa_tan_tay",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Hòa Đồng",
        "code": "xa_hoa_dong",
        "division_type": "xã",
        "short_codename": "hoa_dong",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Hòa Mỹ Đông",
        "code": "xa_hoa_my_dong",
        "division_type": "xã",
        "short_codename": "hoa_my_dong",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Hòa Mỹ Tây",
        "code": "xa_hoa_my_tay",
        "division_type": "xã",
        "short_codename": "hoa_my_tay",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Hòa Thịnh",
        "code": "xa_hoa_thinh",
        "division_type": "xã",
        "short_codename": "hoa_thinh",
        "parentCode": "huyen_tay_hoa"
    },
    {
        "name": "Xã Hòa Quang Bắc",
        "code": "xa_hoa_quang_bac",
        "division_type": "xã",
        "short_codename": "hoa_quang_bac",
        "parentCode": "huyen_phu_hoa"
    },
    {
        "name": "Xã Hòa Quang Nam",
        "code": "xa_hoa_quang_nam",
        "division_type": "xã",
        "short_codename": "hoa_quang_nam",
        "parentCode": "huyen_phu_hoa"
    },
    {
        "name": "Xã Hòa Hội",
        "code": "xa_hoa_hoi",
        "division_type": "xã",
        "short_codename": "hoa_hoi",
        "parentCode": "huyen_phu_hoa"
    },
    {
        "name": "Xã Hòa Trị",
        "code": "xa_hoa_tri",
        "division_type": "xã",
        "short_codename": "hoa_tri",
        "parentCode": "huyen_phu_hoa"
    },
    {
        "name": "Xã Hòa An",
        "code": "xa_hoa_an",
        "division_type": "xã",
        "short_codename": "hoa_an",
        "parentCode": "huyen_phu_hoa"
    },
    {
        "name": "Xã Hòa Định Đông",
        "code": "xa_hoa_dinh_dong",
        "division_type": "xã",
        "short_codename": "hoa_dinh_dong",
        "parentCode": "huyen_phu_hoa"
    },
    {
        "name": "Thị Trấn Phú Hoà",
        "code": "thi_tran_phu_hoa",
        "division_type": "thị trấn",
        "short_codename": "phu_hoa",
        "parentCode": "huyen_phu_hoa"
    },
    {
        "name": "Xã Hòa Định Tây",
        "code": "xa_hoa_dinh_tay",
        "division_type": "xã",
        "short_codename": "hoa_dinh_tay",
        "parentCode": "huyen_phu_hoa"
    },
    {
        "name": "Xã Hòa Thắng",
        "code": "xa_hoa_thang",
        "division_type": "xã",
        "short_codename": "hoa_thang",
        "parentCode": "huyen_phu_hoa"
    },
    {
        "name": "Xã Hòa Thành",
        "code": "xa_hoa_thanh",
        "division_type": "xã",
        "short_codename": "hoa_thanh",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Phường Hòa Hiệp Bắc",
        "code": "phuong_hoa_hiep_bac",
        "division_type": "phường",
        "short_codename": "hoa_hiep_bac",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Phường Hoà Vinh",
        "code": "phuong_hoa_vinh",
        "division_type": "phường",
        "short_codename": "hoa_vinh",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Phường Hoà Hiệp Trung",
        "code": "phuong_hoa_hiep_trung",
        "division_type": "phường",
        "short_codename": "hoa_hiep_trung",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Xã Hòa Tân Đông",
        "code": "xa_hoa_tan_dong",
        "division_type": "xã",
        "short_codename": "hoa_tan_dong",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Phường Hòa Xuân Tây",
        "code": "phuong_hoa_xuan_tay",
        "division_type": "phường",
        "short_codename": "hoa_xuan_tay",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Phường Hòa Hiệp Nam",
        "code": "phuong_hoa_hiep_nam",
        "division_type": "phường",
        "short_codename": "hoa_hiep_nam",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Xã Hòa Xuân Đông",
        "code": "xa_hoa_xuan_dong",
        "division_type": "xã",
        "short_codename": "hoa_xuan_dong",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Xã Hòa Tâm",
        "code": "xa_hoa_tam",
        "division_type": "xã",
        "short_codename": "hoa_tam",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Xã Hòa Xuân Nam",
        "code": "xa_hoa_xuan_nam",
        "division_type": "xã",
        "short_codename": "hoa_xuan_nam",
        "parentCode": "thi_xa_dong_hoa"
    },
    {
        "name": "Phường Vĩnh Hòa",
        "code": "phuong_vinh_hoa",
        "division_type": "phường",
        "short_codename": "vinh_hoa",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Vĩnh Hải",
        "code": "phuong_vinh_hai",
        "division_type": "phường",
        "short_codename": "vinh_hai",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Vĩnh Phước",
        "code": "phuong_vinh_phuoc",
        "division_type": "phường",
        "short_codename": "vinh_phuoc",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Ngọc Hiệp",
        "code": "phuong_ngoc_hiep",
        "division_type": "phường",
        "short_codename": "ngoc_hiep",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Vĩnh Thọ",
        "code": "phuong_vinh_tho",
        "division_type": "phường",
        "short_codename": "vinh_tho",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Xương Huân",
        "code": "phuong_xuong_huan",
        "division_type": "phường",
        "short_codename": "xuong_huan",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Vạn Thắng",
        "code": "phuong_van_thang",
        "division_type": "phường",
        "short_codename": "van_thang",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Vạn Thạnh",
        "code": "phuong_van_thanh",
        "division_type": "phường",
        "short_codename": "van_thanh",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Phương Sài",
        "code": "phuong_phuong_sai",
        "division_type": "phường",
        "short_codename": "phuong_sai",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Phương Sơn",
        "code": "phuong_phuong_son",
        "division_type": "phường",
        "short_codename": "phuong_son",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Phước Hải",
        "code": "phuong_phuoc_hai",
        "division_type": "phường",
        "short_codename": "phuoc_hai",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Phước Tân",
        "code": "phuong_phuoc_tan",
        "division_type": "phường",
        "short_codename": "phuoc_tan",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Lộc Thọ",
        "code": "phuong_loc_tho",
        "division_type": "phường",
        "short_codename": "loc_tho",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Phước Tiến",
        "code": "phuong_phuoc_tien",
        "division_type": "phường",
        "short_codename": "phuoc_tien",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Tân Lập",
        "code": "phuong_tan_lap",
        "division_type": "phường",
        "short_codename": "tan_lap",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Phước Hòa",
        "code": "phuong_phuoc_hoa",
        "division_type": "phường",
        "short_codename": "phuoc_hoa",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Vĩnh Nguyên",
        "code": "phuong_vinh_nguyen",
        "division_type": "phường",
        "short_codename": "vinh_nguyen",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Phước Long",
        "code": "phuong_phuoc_long",
        "division_type": "phường",
        "short_codename": "phuoc_long",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Vĩnh Trường",
        "code": "phuong_vinh_truong",
        "division_type": "phường",
        "short_codename": "vinh_truong",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Xã Vĩnh Lương",
        "code": "xa_vinh_luong",
        "division_type": "xã",
        "short_codename": "vinh_luong",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Xã Vĩnh Phương",
        "code": "xa_vinh_phuong",
        "division_type": "xã",
        "short_codename": "vinh_phuong",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Xã Vĩnh Ngọc",
        "code": "xa_vinh_ngoc",
        "division_type": "xã",
        "short_codename": "vinh_ngoc",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Xã Vĩnh Thạnh",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Xã Vĩnh Trung",
        "code": "xa_vinh_trung",
        "division_type": "xã",
        "short_codename": "vinh_trung",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Xã Vĩnh Hiệp",
        "code": "xa_vinh_hiep",
        "division_type": "xã",
        "short_codename": "vinh_hiep",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Xã Vĩnh Thái",
        "code": "xa_vinh_thai",
        "division_type": "xã",
        "short_codename": "vinh_thai",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Xã Phước Đồng",
        "code": "xa_phuoc_dong",
        "division_type": "xã",
        "short_codename": "phuoc_dong",
        "parentCode": "thanh_pho_nha_trang"
    },
    {
        "name": "Phường Cam Nghĩa",
        "code": "phuong_cam_nghia",
        "division_type": "phường",
        "short_codename": "cam_nghia",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Phường Cam Phúc Bắc",
        "code": "phuong_cam_phuc_bac",
        "division_type": "phường",
        "short_codename": "cam_phuc_bac",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Phường Cam Phúc Nam",
        "code": "phuong_cam_phuc_nam",
        "division_type": "phường",
        "short_codename": "cam_phuc_nam",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Phường Cam Lộc",
        "code": "phuong_cam_loc",
        "division_type": "phường",
        "short_codename": "cam_loc",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Phường Cam Phú",
        "code": "phuong_cam_phu",
        "division_type": "phường",
        "short_codename": "cam_phu",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Phường Ba Ngòi",
        "code": "phuong_ba_ngoi",
        "division_type": "phường",
        "short_codename": "ba_ngoi",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Phường Cam Thuận",
        "code": "phuong_cam_thuan",
        "division_type": "phường",
        "short_codename": "cam_thuan",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Phường Cam Lợi",
        "code": "phuong_cam_loi",
        "division_type": "phường",
        "short_codename": "cam_loi",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Phường Cam Linh",
        "code": "phuong_cam_linh",
        "division_type": "phường",
        "short_codename": "cam_linh",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Xã Cam Thành Nam",
        "code": "xa_cam_thanh_nam",
        "division_type": "xã",
        "short_codename": "cam_thanh_nam",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Xã Cam Phước Đông",
        "code": "xa_cam_phuoc_dong",
        "division_type": "xã",
        "short_codename": "cam_phuoc_dong",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Xã Cam Thịnh Tây",
        "code": "xa_cam_thinh_tay",
        "division_type": "xã",
        "short_codename": "cam_thinh_tay",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Xã Cam Thịnh Đông",
        "code": "xa_cam_thinh_dong",
        "division_type": "xã",
        "short_codename": "cam_thinh_dong",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Xã Cam Lập",
        "code": "xa_cam_lap",
        "division_type": "xã",
        "short_codename": "cam_lap",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Xã Cam Bình",
        "code": "xa_cam_binh",
        "division_type": "xã",
        "short_codename": "cam_binh",
        "parentCode": "thanh_pho_cam_ranh"
    },
    {
        "name": "Xã Cam Tân",
        "code": "xa_cam_tan",
        "division_type": "xã",
        "short_codename": "cam_tan",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Cam Hòa",
        "code": "xa_cam_hoa",
        "division_type": "xã",
        "short_codename": "cam_hoa",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Cam Hải Đông",
        "code": "xa_cam_hai_dong",
        "division_type": "xã",
        "short_codename": "cam_hai_dong",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Cam Hải Tây",
        "code": "xa_cam_hai_tay",
        "division_type": "xã",
        "short_codename": "cam_hai_tay",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Sơn Tân",
        "code": "xa_son_tan",
        "division_type": "xã",
        "short_codename": "son_tan",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Cam Hiệp Bắc",
        "code": "xa_cam_hiep_bac",
        "division_type": "xã",
        "short_codename": "cam_hiep_bac",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Thị trấn Cam Đức",
        "code": "thi_tran_cam_duc",
        "division_type": "thị trấn",
        "short_codename": "cam_duc",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Cam Hiệp Nam",
        "code": "xa_cam_hiep_nam",
        "division_type": "xã",
        "short_codename": "cam_hiep_nam",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Cam Phước Tây",
        "code": "xa_cam_phuoc_tay",
        "division_type": "xã",
        "short_codename": "cam_phuoc_tay",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Cam Thành Bắc",
        "code": "xa_cam_thanh_bac",
        "division_type": "xã",
        "short_codename": "cam_thanh_bac",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Cam An Bắc",
        "code": "xa_cam_an_bac",
        "division_type": "xã",
        "short_codename": "cam_an_bac",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Cam An Nam",
        "code": "xa_cam_an_nam",
        "division_type": "xã",
        "short_codename": "cam_an_nam",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Suối Cát",
        "code": "xa_suoi_cat",
        "division_type": "xã",
        "short_codename": "suoi_cat",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Xã Suối Tân",
        "code": "xa_suoi_tan",
        "division_type": "xã",
        "short_codename": "suoi_tan",
        "parentCode": "huyen_cam_lam"
    },
    {
        "name": "Thị trấn Vạn Giã",
        "code": "thi_tran_van_gia",
        "division_type": "thị trấn",
        "short_codename": "van_gia",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Đại Lãnh",
        "code": "xa_dai_lanh",
        "division_type": "xã",
        "short_codename": "dai_lanh",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Phước",
        "code": "xa_van_phuoc",
        "division_type": "xã",
        "short_codename": "van_phuoc",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Long",
        "code": "xa_van_long",
        "division_type": "xã",
        "short_codename": "van_long",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Bình",
        "code": "xa_van_binh",
        "division_type": "xã",
        "short_codename": "van_binh",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Thọ",
        "code": "xa_van_tho",
        "division_type": "xã",
        "short_codename": "van_tho",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Khánh",
        "code": "xa_van_khanh",
        "division_type": "xã",
        "short_codename": "van_khanh",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Phú",
        "code": "xa_van_phu",
        "division_type": "xã",
        "short_codename": "van_phu",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Lương",
        "code": "xa_van_luong",
        "division_type": "xã",
        "short_codename": "van_luong",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Thắng",
        "code": "xa_van_thang",
        "division_type": "xã",
        "short_codename": "van_thang",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Thạnh",
        "code": "xa_van_thanh",
        "division_type": "xã",
        "short_codename": "van_thanh",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Xuân Sơn",
        "code": "xa_xuan_son",
        "division_type": "xã",
        "short_codename": "xuan_son",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Xã Vạn Hưng",
        "code": "xa_van_hung",
        "division_type": "xã",
        "short_codename": "van_hung",
        "parentCode": "huyen_van_ninh"
    },
    {
        "name": "Phường Ninh Hiệp",
        "code": "phuong_ninh_hiep",
        "division_type": "phường",
        "short_codename": "ninh_hiep",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Sơn",
        "code": "xa_ninh_son",
        "division_type": "xã",
        "short_codename": "ninh_son",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Tây",
        "code": "xa_ninh_tay",
        "division_type": "xã",
        "short_codename": "ninh_tay",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Thượng",
        "code": "xa_ninh_thuong",
        "division_type": "xã",
        "short_codename": "ninh_thuong",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh An",
        "code": "xa_ninh_an",
        "division_type": "xã",
        "short_codename": "ninh_an",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Phường Ninh Hải",
        "code": "phuong_ninh_hai",
        "division_type": "phường",
        "short_codename": "ninh_hai",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Thọ",
        "code": "xa_ninh_tho",
        "division_type": "xã",
        "short_codename": "ninh_tho",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Trung",
        "code": "xa_ninh_trung",
        "division_type": "xã",
        "short_codename": "ninh_trung",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Sim",
        "code": "xa_ninh_sim",
        "division_type": "xã",
        "short_codename": "ninh_sim",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Xuân",
        "code": "xa_ninh_xuan",
        "division_type": "xã",
        "short_codename": "ninh_xuan",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Thân",
        "code": "xa_ninh_than",
        "division_type": "xã",
        "short_codename": "ninh_than",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Phường Ninh Diêm",
        "code": "phuong_ninh_diem",
        "division_type": "phường",
        "short_codename": "ninh_diem",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Đông",
        "code": "xa_ninh_dong",
        "division_type": "xã",
        "short_codename": "ninh_dong",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Phường Ninh Thủy",
        "code": "phuong_ninh_thuy",
        "division_type": "phường",
        "short_codename": "ninh_thuy",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Phường Ninh Đa",
        "code": "phuong_ninh_da",
        "division_type": "phường",
        "short_codename": "ninh_da",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Phụng",
        "code": "xa_ninh_phung",
        "division_type": "xã",
        "short_codename": "ninh_phung",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Bình",
        "code": "xa_ninh_binh",
        "division_type": "xã",
        "short_codename": "ninh_binh",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Phước",
        "code": "xa_ninh_phuoc",
        "division_type": "xã",
        "short_codename": "ninh_phuoc",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Phú",
        "code": "xa_ninh_phu",
        "division_type": "xã",
        "short_codename": "ninh_phu",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Tân",
        "code": "xa_ninh_tan",
        "division_type": "xã",
        "short_codename": "ninh_tan",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Quang",
        "code": "xa_ninh_quang",
        "division_type": "xã",
        "short_codename": "ninh_quang",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Phường Ninh Giang",
        "code": "phuong_ninh_giang",
        "division_type": "phường",
        "short_codename": "ninh_giang",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Phường Ninh Hà",
        "code": "phuong_ninh_ha",
        "division_type": "phường",
        "short_codename": "ninh_ha",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Hưng",
        "code": "xa_ninh_hung",
        "division_type": "xã",
        "short_codename": "ninh_hung",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Lộc",
        "code": "xa_ninh_loc",
        "division_type": "xã",
        "short_codename": "ninh_loc",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Ích",
        "code": "xa_ninh_ich",
        "division_type": "xã",
        "short_codename": "ninh_ich",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Xã Ninh Vân",
        "code": "xa_ninh_van",
        "division_type": "xã",
        "short_codename": "ninh_van",
        "parentCode": "thi_xa_ninh_hoa"
    },
    {
        "name": "Thị trấn Khánh Vĩnh",
        "code": "thi_tran_khanh_vinh",
        "division_type": "thị trấn",
        "short_codename": "khanh_vinh",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Khánh Hiệp",
        "code": "xa_khanh_hiep",
        "division_type": "xã",
        "short_codename": "khanh_hiep",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Khánh Bình",
        "code": "xa_khanh_binh",
        "division_type": "xã",
        "short_codename": "khanh_binh",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Khánh Trung",
        "code": "xa_khanh_trung",
        "division_type": "xã",
        "short_codename": "khanh_trung",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Khánh Đông",
        "code": "xa_khanh_dong",
        "division_type": "xã",
        "short_codename": "khanh_dong",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Khánh Thượng",
        "code": "xa_khanh_thuong",
        "division_type": "xã",
        "short_codename": "khanh_thuong",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Khánh Nam",
        "code": "xa_khanh_nam",
        "division_type": "xã",
        "short_codename": "khanh_nam",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Sông Cầu",
        "code": "xa_song_cau",
        "division_type": "xã",
        "short_codename": "song_cau",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Giang Ly",
        "code": "xa_giang_ly",
        "division_type": "xã",
        "short_codename": "giang_ly",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Cầu Bà",
        "code": "xa_cau_ba",
        "division_type": "xã",
        "short_codename": "cau_ba",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Liên Sang",
        "code": "xa_lien_sang",
        "division_type": "xã",
        "short_codename": "lien_sang",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Khánh Thành",
        "code": "xa_khanh_thanh",
        "division_type": "xã",
        "short_codename": "khanh_thanh",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Khánh Phú",
        "code": "xa_khanh_phu",
        "division_type": "xã",
        "short_codename": "khanh_phu",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Xã Sơn Thái",
        "code": "xa_son_thai",
        "division_type": "xã",
        "short_codename": "son_thai",
        "parentCode": "huyen_khanh_vinh"
    },
    {
        "name": "Thị trấn Diên Khánh",
        "code": "thi_tran_dien_khanh",
        "division_type": "thị trấn",
        "short_codename": "dien_khanh",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Lâm",
        "code": "xa_dien_lam",
        "division_type": "xã",
        "short_codename": "dien_lam",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Điền",
        "code": "xa_dien_dien",
        "division_type": "xã",
        "short_codename": "dien_dien",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Xuân",
        "code": "xa_dien_xuan",
        "division_type": "xã",
        "short_codename": "dien_xuan",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Sơn",
        "code": "xa_dien_son",
        "division_type": "xã",
        "short_codename": "dien_son",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Đồng",
        "code": "xa_dien_dong",
        "division_type": "xã",
        "short_codename": "dien_dong",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Phú",
        "code": "xa_dien_phu",
        "division_type": "xã",
        "short_codename": "dien_phu",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Thọ",
        "code": "xa_dien_tho",
        "division_type": "xã",
        "short_codename": "dien_tho",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Phước",
        "code": "xa_dien_phuoc",
        "division_type": "xã",
        "short_codename": "dien_phuoc",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Lạc",
        "code": "xa_dien_lac",
        "division_type": "xã",
        "short_codename": "dien_lac",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Tân",
        "code": "xa_dien_tan",
        "division_type": "xã",
        "short_codename": "dien_tan",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Hòa",
        "code": "xa_dien_hoa",
        "division_type": "xã",
        "short_codename": "dien_hoa",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Thạnh",
        "code": "xa_dien_thanh",
        "division_type": "xã",
        "short_codename": "dien_thanh",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên Toàn",
        "code": "xa_dien_toan",
        "division_type": "xã",
        "short_codename": "dien_toan",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Diên An",
        "code": "xa_dien_an",
        "division_type": "xã",
        "short_codename": "dien_an",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Bình Lộc",
        "code": "xa_binh_loc",
        "division_type": "xã",
        "short_codename": "binh_loc",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Suối Hiệp",
        "code": "xa_suoi_hiep",
        "division_type": "xã",
        "short_codename": "suoi_hiep",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Xã Suối Tiên",
        "code": "xa_suoi_tien",
        "division_type": "xã",
        "short_codename": "suoi_tien",
        "parentCode": "huyen_dien_khanh"
    },
    {
        "name": "Thị trấn Tô Hạp",
        "code": "thi_tran_to_hap",
        "division_type": "thị trấn",
        "short_codename": "to_hap",
        "parentCode": "huyen_khanh_son"
    },
    {
        "name": "Xã Thành Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_khanh_son"
    },
    {
        "name": "Xã Sơn Lâm",
        "code": "xa_son_lam",
        "division_type": "xã",
        "short_codename": "son_lam",
        "parentCode": "huyen_khanh_son"
    },
    {
        "name": "Xã Sơn Hiệp",
        "code": "xa_son_hiep",
        "division_type": "xã",
        "short_codename": "son_hiep",
        "parentCode": "huyen_khanh_son"
    },
    {
        "name": "Xã Sơn Bình",
        "code": "xa_son_binh",
        "division_type": "xã",
        "short_codename": "son_binh",
        "parentCode": "huyen_khanh_son"
    },
    {
        "name": "Xã Sơn Trung",
        "code": "xa_son_trung",
        "division_type": "xã",
        "short_codename": "son_trung",
        "parentCode": "huyen_khanh_son"
    },
    {
        "name": "Xã Ba Cụm Bắc",
        "code": "xa_ba_cum_bac",
        "division_type": "xã",
        "short_codename": "ba_cum_bac",
        "parentCode": "huyen_khanh_son"
    },
    {
        "name": "Xã Ba Cụm Nam",
        "code": "xa_ba_cum_nam",
        "division_type": "xã",
        "short_codename": "ba_cum_nam",
        "parentCode": "huyen_khanh_son"
    },
    {
        "name": "Thị trấn Trường Sa",
        "code": "thi_tran_truong_sa",
        "division_type": "thị trấn",
        "short_codename": "truong_sa",
        "parentCode": "huyen_truong_sa"
    },
    {
        "name": "Xã Song Tử Tây",
        "code": "xa_song_tu_tay",
        "division_type": "xã",
        "short_codename": "song_tu_tay",
        "parentCode": "huyen_truong_sa"
    },
    {
        "name": "Xã Sinh Tồn",
        "code": "xa_sinh_ton",
        "division_type": "xã",
        "short_codename": "sinh_ton",
        "parentCode": "huyen_truong_sa"
    },
    {
        "name": "Phường Đô Vinh",
        "code": "phuong_do_vinh",
        "division_type": "phường",
        "short_codename": "do_vinh",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Phước Mỹ",
        "code": "phuong_phuoc_my",
        "division_type": "phường",
        "short_codename": "phuoc_my",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Bảo An",
        "code": "phuong_bao_an",
        "division_type": "phường",
        "short_codename": "bao_an",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Phủ Hà",
        "code": "phuong_phu_ha",
        "division_type": "phường",
        "short_codename": "phu_ha",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Thanh Sơn",
        "code": "phuong_thanh_son",
        "division_type": "phường",
        "short_codename": "thanh_son",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Mỹ Hương",
        "code": "phuong_my_huong",
        "division_type": "phường",
        "short_codename": "my_huong",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Tấn Tài",
        "code": "phuong_tan_tai",
        "division_type": "phường",
        "short_codename": "tan_tai",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Kinh Dinh",
        "code": "phuong_kinh_dinh",
        "division_type": "phường",
        "short_codename": "kinh_dinh",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Đạo Long",
        "code": "phuong_dao_long",
        "division_type": "phường",
        "short_codename": "dao_long",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Đài Sơn",
        "code": "phuong_dai_son",
        "division_type": "phường",
        "short_codename": "dai_son",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Đông Hải",
        "code": "phuong_dong_hai",
        "division_type": "phường",
        "short_codename": "dong_hai",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Mỹ Đông",
        "code": "phuong_my_dong",
        "division_type": "phường",
        "short_codename": "my_dong",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Xã Thành Hải",
        "code": "xa_thanh_hai",
        "division_type": "xã",
        "short_codename": "thanh_hai",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Văn Hải",
        "code": "phuong_van_hai",
        "division_type": "phường",
        "short_codename": "van_hai",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Mỹ Bình",
        "code": "phuong_my_binh",
        "division_type": "phường",
        "short_codename": "my_binh",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Phường Mỹ Hải",
        "code": "phuong_my_hai",
        "division_type": "phường",
        "short_codename": "my_hai",
        "parentCode": "thanh_pho_phan_rang_thap_cham"
    },
    {
        "name": "Xã Phước Bình",
        "code": "xa_phuoc_binh",
        "division_type": "xã",
        "short_codename": "phuoc_binh",
        "parentCode": "huyen_bac_ai"
    },
    {
        "name": "Xã Phước Hòa",
        "code": "xa_phuoc_hoa",
        "division_type": "xã",
        "short_codename": "phuoc_hoa",
        "parentCode": "huyen_bac_ai"
    },
    {
        "name": "Xã Phước Tân",
        "code": "xa_phuoc_tan",
        "division_type": "xã",
        "short_codename": "phuoc_tan",
        "parentCode": "huyen_bac_ai"
    },
    {
        "name": "Xã Phước Tiến",
        "code": "xa_phuoc_tien",
        "division_type": "xã",
        "short_codename": "phuoc_tien",
        "parentCode": "huyen_bac_ai"
    },
    {
        "name": "Xã Phước Thắng",
        "code": "xa_phuoc_thang",
        "division_type": "xã",
        "short_codename": "phuoc_thang",
        "parentCode": "huyen_bac_ai"
    },
    {
        "name": "Xã Phước Thành",
        "code": "xa_phuoc_thanh",
        "division_type": "xã",
        "short_codename": "phuoc_thanh",
        "parentCode": "huyen_bac_ai"
    },
    {
        "name": "Xã Phước Đại",
        "code": "xa_phuoc_dai",
        "division_type": "xã",
        "short_codename": "phuoc_dai",
        "parentCode": "huyen_bac_ai"
    },
    {
        "name": "Xã Phước Chính",
        "code": "xa_phuoc_chinh",
        "division_type": "xã",
        "short_codename": "phuoc_chinh",
        "parentCode": "huyen_bac_ai"
    },
    {
        "name": "Xã Phước Trung",
        "code": "xa_phuoc_trung",
        "division_type": "xã",
        "short_codename": "phuoc_trung",
        "parentCode": "huyen_bac_ai"
    },
    {
        "name": "Thị trấn Tân Sơn",
        "code": "thi_tran_tan_son",
        "division_type": "thị trấn",
        "short_codename": "tan_son",
        "parentCode": "huyen_ninh_son"
    },
    {
        "name": "Xã Lâm Sơn",
        "code": "xa_lam_son",
        "division_type": "xã",
        "short_codename": "lam_son",
        "parentCode": "huyen_ninh_son"
    },
    {
        "name": "Xã Lương Sơn",
        "code": "xa_luong_son",
        "division_type": "xã",
        "short_codename": "luong_son",
        "parentCode": "huyen_ninh_son"
    },
    {
        "name": "Xã Quảng Sơn",
        "code": "xa_quang_son",
        "division_type": "xã",
        "short_codename": "quang_son",
        "parentCode": "huyen_ninh_son"
    },
    {
        "name": "Xã Mỹ Sơn",
        "code": "xa_my_son",
        "division_type": "xã",
        "short_codename": "my_son",
        "parentCode": "huyen_ninh_son"
    },
    {
        "name": "Xã Hòa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_ninh_son"
    },
    {
        "name": "Xã Ma Nới",
        "code": "xa_ma_noi",
        "division_type": "xã",
        "short_codename": "ma_noi",
        "parentCode": "huyen_ninh_son"
    },
    {
        "name": "Xã Nhơn Sơn",
        "code": "xa_nhon_son",
        "division_type": "xã",
        "short_codename": "nhon_son",
        "parentCode": "huyen_ninh_son"
    },
    {
        "name": "Thị trấn Khánh Hải",
        "code": "thi_tran_khanh_hai",
        "division_type": "thị trấn",
        "short_codename": "khanh_hai",
        "parentCode": "huyen_ninh_hai"
    },
    {
        "name": "Xã Vĩnh Hải",
        "code": "xa_vinh_hai",
        "division_type": "xã",
        "short_codename": "vinh_hai",
        "parentCode": "huyen_ninh_hai"
    },
    {
        "name": "Xã Phương Hải",
        "code": "xa_phuong_hai",
        "division_type": "xã",
        "short_codename": "phuong_hai",
        "parentCode": "huyen_ninh_hai"
    },
    {
        "name": "Xã Tân Hải",
        "code": "xa_tan_hai",
        "division_type": "xã",
        "short_codename": "tan_hai",
        "parentCode": "huyen_ninh_hai"
    },
    {
        "name": "Xã Xuân Hải",
        "code": "xa_xuan_hai",
        "division_type": "xã",
        "short_codename": "xuan_hai",
        "parentCode": "huyen_ninh_hai"
    },
    {
        "name": "Xã Hộ Hải",
        "code": "xa_ho_hai",
        "division_type": "xã",
        "short_codename": "ho_hai",
        "parentCode": "huyen_ninh_hai"
    },
    {
        "name": "Xã Tri Hải",
        "code": "xa_tri_hai",
        "division_type": "xã",
        "short_codename": "tri_hai",
        "parentCode": "huyen_ninh_hai"
    },
    {
        "name": "Xã Nhơn Hải",
        "code": "xa_nhon_hai",
        "division_type": "xã",
        "short_codename": "nhon_hai",
        "parentCode": "huyen_ninh_hai"
    },
    {
        "name": "Xã Thanh Hải",
        "code": "xa_thanh_hai",
        "division_type": "xã",
        "short_codename": "thanh_hai",
        "parentCode": "huyen_ninh_hai"
    },
    {
        "name": "Thị trấn Phước Dân",
        "code": "thi_tran_phuoc_dan",
        "division_type": "thị trấn",
        "short_codename": "phuoc_dan",
        "parentCode": "huyen_ninh_phuoc"
    },
    {
        "name": "Xã Phước Sơn",
        "code": "xa_phuoc_son",
        "division_type": "xã",
        "short_codename": "phuoc_son",
        "parentCode": "huyen_ninh_phuoc"
    },
    {
        "name": "Xã Phước Thái",
        "code": "xa_phuoc_thai",
        "division_type": "xã",
        "short_codename": "phuoc_thai",
        "parentCode": "huyen_ninh_phuoc"
    },
    {
        "name": "Xã Phước Hậu",
        "code": "xa_phuoc_hau",
        "division_type": "xã",
        "short_codename": "phuoc_hau",
        "parentCode": "huyen_ninh_phuoc"
    },
    {
        "name": "Xã Phước Thuận",
        "code": "xa_phuoc_thuan",
        "division_type": "xã",
        "short_codename": "phuoc_thuan",
        "parentCode": "huyen_ninh_phuoc"
    },
    {
        "name": "Xã An Hải",
        "code": "xa_an_hai",
        "division_type": "xã",
        "short_codename": "an_hai",
        "parentCode": "huyen_ninh_phuoc"
    },
    {
        "name": "Xã Phước Hữu",
        "code": "xa_phuoc_huu",
        "division_type": "xã",
        "short_codename": "phuoc_huu",
        "parentCode": "huyen_ninh_phuoc"
    },
    {
        "name": "Xã Phước Hải",
        "code": "xa_phuoc_hai",
        "division_type": "xã",
        "short_codename": "phuoc_hai",
        "parentCode": "huyen_ninh_phuoc"
    },
    {
        "name": "Xã Phước Vinh",
        "code": "xa_phuoc_vinh",
        "division_type": "xã",
        "short_codename": "phuoc_vinh",
        "parentCode": "huyen_ninh_phuoc"
    },
    {
        "name": "Xã Phước Chiến",
        "code": "xa_phuoc_chien",
        "division_type": "xã",
        "short_codename": "phuoc_chien",
        "parentCode": "huyen_thuan_bac"
    },
    {
        "name": "Xã Công Hải",
        "code": "xa_cong_hai",
        "division_type": "xã",
        "short_codename": "cong_hai",
        "parentCode": "huyen_thuan_bac"
    },
    {
        "name": "Xã Phước Kháng",
        "code": "xa_phuoc_khang",
        "division_type": "xã",
        "short_codename": "phuoc_khang",
        "parentCode": "huyen_thuan_bac"
    },
    {
        "name": "Xã Lợi Hải",
        "code": "xa_loi_hai",
        "division_type": "xã",
        "short_codename": "loi_hai",
        "parentCode": "huyen_thuan_bac"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "huyen_thuan_bac"
    },
    {
        "name": "Xã Bắc Phong",
        "code": "xa_bac_phong",
        "division_type": "xã",
        "short_codename": "bac_phong",
        "parentCode": "huyen_thuan_bac"
    },
    {
        "name": "Xã Phước Hà",
        "code": "xa_phuoc_ha",
        "division_type": "xã",
        "short_codename": "phuoc_ha",
        "parentCode": "huyen_thuan_nam"
    },
    {
        "name": "Xã Phước Nam",
        "code": "xa_phuoc_nam",
        "division_type": "xã",
        "short_codename": "phuoc_nam",
        "parentCode": "huyen_thuan_nam"
    },
    {
        "name": "Xã Phước Ninh",
        "code": "xa_phuoc_ninh",
        "division_type": "xã",
        "short_codename": "phuoc_ninh",
        "parentCode": "huyen_thuan_nam"
    },
    {
        "name": "Xã Nhị Hà",
        "code": "xa_nhi_ha",
        "division_type": "xã",
        "short_codename": "nhi_ha",
        "parentCode": "huyen_thuan_nam"
    },
    {
        "name": "Xã Phước Dinh",
        "code": "xa_phuoc_dinh",
        "division_type": "xã",
        "short_codename": "phuoc_dinh",
        "parentCode": "huyen_thuan_nam"
    },
    {
        "name": "Xã Phước Minh",
        "code": "xa_phuoc_minh",
        "division_type": "xã",
        "short_codename": "phuoc_minh",
        "parentCode": "huyen_thuan_nam"
    },
    {
        "name": "Xã Phước Diêm",
        "code": "xa_phuoc_diem",
        "division_type": "xã",
        "short_codename": "phuoc_diem",
        "parentCode": "huyen_thuan_nam"
    },
    {
        "name": "Xã Cà Ná",
        "code": "xa_ca_na",
        "division_type": "xã",
        "short_codename": "ca_na",
        "parentCode": "huyen_thuan_nam"
    },
    {
        "name": "Phường Mũi Né",
        "code": "phuong_mui_ne",
        "division_type": "phường",
        "short_codename": "mui_ne",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Hàm Tiến",
        "code": "phuong_ham_tien",
        "division_type": "phường",
        "short_codename": "ham_tien",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Phú Hài",
        "code": "phuong_phu_hai",
        "division_type": "phường",
        "short_codename": "phu_hai",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Phú Thủy",
        "code": "phuong_phu_thuy",
        "division_type": "phường",
        "short_codename": "phu_thuy",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Phú Tài",
        "code": "phuong_phu_tai",
        "division_type": "phường",
        "short_codename": "phu_tai",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Phú Trinh",
        "code": "phuong_phu_trinh",
        "division_type": "phường",
        "short_codename": "phu_trinh",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Xuân An",
        "code": "phuong_xuan_an",
        "division_type": "phường",
        "short_codename": "xuan_an",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Thanh Hải",
        "code": "phuong_thanh_hai",
        "division_type": "phường",
        "short_codename": "thanh_hai",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Bình Hưng",
        "code": "phuong_binh_hung",
        "division_type": "phường",
        "short_codename": "binh_hung",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Đức Nghĩa",
        "code": "phuong_duc_nghia",
        "division_type": "phường",
        "short_codename": "duc_nghia",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Lạc Đạo",
        "code": "phuong_lac_dao",
        "division_type": "phường",
        "short_codename": "lac_dao",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Đức Thắng",
        "code": "phuong_duc_thang",
        "division_type": "phường",
        "short_codename": "duc_thang",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Hưng Long",
        "code": "phuong_hung_long",
        "division_type": "phường",
        "short_codename": "hung_long",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Đức Long",
        "code": "phuong_duc_long",
        "division_type": "phường",
        "short_codename": "duc_long",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Xã Thiện Nghiệp",
        "code": "xa_thien_nghiep",
        "division_type": "xã",
        "short_codename": "thien_nghiep",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Xã Phong Nẫm",
        "code": "xa_phong_nam",
        "division_type": "xã",
        "short_codename": "phong_nam",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Xã Tiến Lợi",
        "code": "xa_tien_loi",
        "division_type": "xã",
        "short_codename": "tien_loi",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Xã Tiến Thành",
        "code": "xa_tien_thanh",
        "division_type": "xã",
        "short_codename": "tien_thanh",
        "parentCode": "thanh_pho_phan_thiet"
    },
    {
        "name": "Phường Phước Hội",
        "code": "phuong_phuoc_hoi",
        "division_type": "phường",
        "short_codename": "phuoc_hoi",
        "parentCode": "thi_xa_la_gi"
    },
    {
        "name": "Phường Phước Lộc",
        "code": "phuong_phuoc_loc",
        "division_type": "phường",
        "short_codename": "phuoc_loc",
        "parentCode": "thi_xa_la_gi"
    },
    {
        "name": "Phường Tân Thiện",
        "code": "phuong_tan_thien",
        "division_type": "phường",
        "short_codename": "tan_thien",
        "parentCode": "thi_xa_la_gi"
    },
    {
        "name": "Phường Tân An",
        "code": "phuong_tan_an",
        "division_type": "phường",
        "short_codename": "tan_an",
        "parentCode": "thi_xa_la_gi"
    },
    {
        "name": "Phường Bình Tân",
        "code": "phuong_binh_tan",
        "division_type": "phường",
        "short_codename": "binh_tan",
        "parentCode": "thi_xa_la_gi"
    },
    {
        "name": "Xã Tân Hải",
        "code": "xa_tan_hai",
        "division_type": "xã",
        "short_codename": "tan_hai",
        "parentCode": "thi_xa_la_gi"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "thi_xa_la_gi"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "thi_xa_la_gi"
    },
    {
        "name": "Xã Tân Phước",
        "code": "xa_tan_phuoc",
        "division_type": "xã",
        "short_codename": "tan_phuoc",
        "parentCode": "thi_xa_la_gi"
    },
    {
        "name": "Thị trấn Liên Hương",
        "code": "thi_tran_lien_huong",
        "division_type": "thị trấn",
        "short_codename": "lien_huong",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Thị trấn Phan Rí Cửa",
        "code": "thi_tran_phan_ri_cua",
        "division_type": "thị trấn",
        "short_codename": "phan_ri_cua",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Xã Phan Dũng",
        "code": "xa_phan_dung",
        "division_type": "xã",
        "short_codename": "phan_dung",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Xã Phong Phú",
        "code": "xa_phong_phu",
        "division_type": "xã",
        "short_codename": "phong_phu",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Xã Vĩnh Hảo",
        "code": "xa_vinh_hao",
        "division_type": "xã",
        "short_codename": "vinh_hao",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Xã Vĩnh Tân",
        "code": "xa_vinh_tan",
        "division_type": "xã",
        "short_codename": "vinh_tan",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Xã Phú Lạc",
        "code": "xa_phu_lac",
        "division_type": "xã",
        "short_codename": "phu_lac",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Xã Phước Thể",
        "code": "xa_phuoc_the",
        "division_type": "xã",
        "short_codename": "phuoc_the",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Xã Hòa Minh",
        "code": "xa_hoa_minh",
        "division_type": "xã",
        "short_codename": "hoa_minh",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Xã Chí Công",
        "code": "xa_chi_cong",
        "division_type": "xã",
        "short_codename": "chi_cong",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Xã Bình Thạnh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_tuy_phong"
    },
    {
        "name": "Thị trấn Chợ Lầu",
        "code": "thi_tran_cho_lau",
        "division_type": "thị trấn",
        "short_codename": "cho_lau",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Phan Sơn",
        "code": "xa_phan_son",
        "division_type": "xã",
        "short_codename": "phan_son",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Phan Lâm",
        "code": "xa_phan_lam",
        "division_type": "xã",
        "short_codename": "phan_lam",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Bình An",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Phan Điền",
        "code": "xa_phan_dien",
        "division_type": "xã",
        "short_codename": "phan_dien",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Hải Ninh",
        "code": "xa_hai_ninh",
        "division_type": "xã",
        "short_codename": "hai_ninh",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Sông Lũy",
        "code": "xa_song_luy",
        "division_type": "xã",
        "short_codename": "song_luy",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Phan Tiến",
        "code": "xa_phan_tien",
        "division_type": "xã",
        "short_codename": "phan_tien",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Sông Bình",
        "code": "xa_song_binh",
        "division_type": "xã",
        "short_codename": "song_binh",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Thị trấn Lương Sơn",
        "code": "thi_tran_luong_son",
        "division_type": "thị trấn",
        "short_codename": "luong_son",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Phan Hòa",
        "code": "xa_phan_hoa",
        "division_type": "xã",
        "short_codename": "phan_hoa",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Phan Thanh",
        "code": "xa_phan_thanh",
        "division_type": "xã",
        "short_codename": "phan_thanh",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Hồng Thái",
        "code": "xa_hong_thai",
        "division_type": "xã",
        "short_codename": "hong_thai",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Phan Hiệp",
        "code": "xa_phan_hiep",
        "division_type": "xã",
        "short_codename": "phan_hiep",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Bình Tân",
        "code": "xa_binh_tan",
        "division_type": "xã",
        "short_codename": "binh_tan",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Phan Rí Thành",
        "code": "xa_phan_ri_thanh",
        "division_type": "xã",
        "short_codename": "phan_ri_thanh",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Hòa Thắng",
        "code": "xa_hoa_thang",
        "division_type": "xã",
        "short_codename": "hoa_thang",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Xã Hồng Phong",
        "code": "xa_hong_phong",
        "division_type": "xã",
        "short_codename": "hong_phong",
        "parentCode": "huyen_bac_binh"
    },
    {
        "name": "Thị trấn Ma Lâm",
        "code": "thi_tran_ma_lam",
        "division_type": "thị trấn",
        "short_codename": "ma_lam",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Thị trấn Phú Long",
        "code": "thi_tran_phu_long",
        "division_type": "thị trấn",
        "short_codename": "phu_long",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã La Dạ",
        "code": "xa_la_da",
        "division_type": "xã",
        "short_codename": "la_da",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Đông Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Thuận Hòa",
        "code": "xa_thuan_hoa",
        "division_type": "xã",
        "short_codename": "thuan_hoa",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Đông Giang",
        "code": "xa_dong_giang",
        "division_type": "xã",
        "short_codename": "dong_giang",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Hàm Phú",
        "code": "xa_ham_phu",
        "division_type": "xã",
        "short_codename": "ham_phu",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Hồng Liêm",
        "code": "xa_hong_liem",
        "division_type": "xã",
        "short_codename": "hong_liem",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Thuận Minh",
        "code": "xa_thuan_minh",
        "division_type": "xã",
        "short_codename": "thuan_minh",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Hồng Sơn",
        "code": "xa_hong_son",
        "division_type": "xã",
        "short_codename": "hong_son",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Hàm Trí",
        "code": "xa_ham_tri",
        "division_type": "xã",
        "short_codename": "ham_tri",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Hàm Đức",
        "code": "xa_ham_duc",
        "division_type": "xã",
        "short_codename": "ham_duc",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Hàm Liêm",
        "code": "xa_ham_liem",
        "division_type": "xã",
        "short_codename": "ham_liem",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Hàm Chính",
        "code": "xa_ham_chinh",
        "division_type": "xã",
        "short_codename": "ham_chinh",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Hàm Hiệp",
        "code": "xa_ham_hiep",
        "division_type": "xã",
        "short_codename": "ham_hiep",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Hàm Thắng",
        "code": "xa_ham_thang",
        "division_type": "xã",
        "short_codename": "ham_thang",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Xã Đa Mi",
        "code": "xa_da_mi",
        "division_type": "xã",
        "short_codename": "da_mi",
        "parentCode": "huyen_ham_thuan_bac"
    },
    {
        "name": "Thị trấn Thuận Nam",
        "code": "thi_tran_thuan_nam",
        "division_type": "thị trấn",
        "short_codename": "thuan_nam",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Mỹ Thạnh",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Hàm Cần",
        "code": "xa_ham_can",
        "division_type": "xã",
        "short_codename": "ham_can",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Mương Mán",
        "code": "xa_muong_man",
        "division_type": "xã",
        "short_codename": "muong_man",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Hàm Thạnh",
        "code": "xa_ham_thanh",
        "division_type": "xã",
        "short_codename": "ham_thanh",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Hàm Kiệm",
        "code": "xa_ham_kiem",
        "division_type": "xã",
        "short_codename": "ham_kiem",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Hàm Cường",
        "code": "xa_ham_cuong",
        "division_type": "xã",
        "short_codename": "ham_cuong",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Hàm Mỹ",
        "code": "xa_ham_my",
        "division_type": "xã",
        "short_codename": "ham_my",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Hàm Minh",
        "code": "xa_ham_minh",
        "division_type": "xã",
        "short_codename": "ham_minh",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Thuận Quí",
        "code": "xa_thuan_qui",
        "division_type": "xã",
        "short_codename": "thuan_qui",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Tân Thuận",
        "code": "xa_tan_thuan",
        "division_type": "xã",
        "short_codename": "tan_thuan",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_ham_thuan_nam"
    },
    {
        "name": "Thị trấn Lạc Tánh",
        "code": "thi_tran_lac_tanh",
        "division_type": "thị trấn",
        "short_codename": "lac_tanh",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Bắc Ruộng",
        "code": "xa_bac_ruong",
        "division_type": "xã",
        "short_codename": "bac_ruong",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Nghị Đức",
        "code": "xa_nghi_duc",
        "division_type": "xã",
        "short_codename": "nghi_duc",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã La Ngâu",
        "code": "xa_la_ngau",
        "division_type": "xã",
        "short_codename": "la_ngau",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Huy Khiêm",
        "code": "xa_huy_khiem",
        "division_type": "xã",
        "short_codename": "huy_khiem",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Măng Tố",
        "code": "xa_mang_to",
        "division_type": "xã",
        "short_codename": "mang_to",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Đức Phú",
        "code": "xa_duc_phu",
        "division_type": "xã",
        "short_codename": "duc_phu",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Đồng Kho",
        "code": "xa_dong_kho",
        "division_type": "xã",
        "short_codename": "dong_kho",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Gia An",
        "code": "xa_gia_an",
        "division_type": "xã",
        "short_codename": "gia_an",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Đức Bình",
        "code": "xa_duc_binh",
        "division_type": "xã",
        "short_codename": "duc_binh",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Gia Huynh",
        "code": "xa_gia_huynh",
        "division_type": "xã",
        "short_codename": "gia_huynh",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Đức Thuận",
        "code": "xa_duc_thuan",
        "division_type": "xã",
        "short_codename": "duc_thuan",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Xã Suối Kiết",
        "code": "xa_suoi_kiet",
        "division_type": "xã",
        "short_codename": "suoi_kiet",
        "parentCode": "huyen_tanh_linh"
    },
    {
        "name": "Thị trấn Võ Xu",
        "code": "thi_tran_vo_xu",
        "division_type": "thị trấn",
        "short_codename": "vo_xu",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Thị trấn Đức Tài",
        "code": "thi_tran_duc_tai",
        "division_type": "thị trấn",
        "short_codename": "duc_tai",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Đa Kai",
        "code": "xa_da_kai",
        "division_type": "xã",
        "short_codename": "da_kai",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Sùng Nhơn",
        "code": "xa_sung_nhon",
        "division_type": "xã",
        "short_codename": "sung_nhon",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Mê Pu",
        "code": "xa_me_pu",
        "division_type": "xã",
        "short_codename": "me_pu",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Nam Chính",
        "code": "xa_nam_chinh",
        "division_type": "xã",
        "short_codename": "nam_chinh",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Đức Hạnh",
        "code": "xa_duc_hanh",
        "division_type": "xã",
        "short_codename": "duc_hanh",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Đức Tín",
        "code": "xa_duc_tin",
        "division_type": "xã",
        "short_codename": "duc_tin",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Vũ Hoà",
        "code": "xa_vu_hoa",
        "division_type": "xã",
        "short_codename": "vu_hoa",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Tân Hà",
        "code": "xa_tan_ha",
        "division_type": "xã",
        "short_codename": "tan_ha",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Đông Hà",
        "code": "xa_dong_ha",
        "division_type": "xã",
        "short_codename": "dong_ha",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Xã Trà Tân",
        "code": "xa_tra_tan",
        "division_type": "xã",
        "short_codename": "tra_tan",
        "parentCode": "huyen_duc_linh"
    },
    {
        "name": "Thị trấn Tân Minh",
        "code": "thi_tran_tan_minh",
        "division_type": "thị trấn",
        "short_codename": "tan_minh",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Thị trấn Tân Nghĩa",
        "code": "thi_tran_tan_nghia",
        "division_type": "thị trấn",
        "short_codename": "tan_nghia",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Xã Sông Phan",
        "code": "xa_song_phan",
        "division_type": "xã",
        "short_codename": "song_phan",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Xã Tân Phúc",
        "code": "xa_tan_phuc",
        "division_type": "xã",
        "short_codename": "tan_phuc",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Xã Tân Đức",
        "code": "xa_tan_duc",
        "division_type": "xã",
        "short_codename": "tan_duc",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Xã Tân Thắng",
        "code": "xa_tan_thang",
        "division_type": "xã",
        "short_codename": "tan_thang",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Xã Thắng Hải",
        "code": "xa_thang_hai",
        "division_type": "xã",
        "short_codename": "thang_hai",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Xã Tân Hà",
        "code": "xa_tan_ha",
        "division_type": "xã",
        "short_codename": "tan_ha",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Xã Tân Xuân",
        "code": "xa_tan_xuan",
        "division_type": "xã",
        "short_codename": "tan_xuan",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Xã Sơn Mỹ",
        "code": "xa_son_my",
        "division_type": "xã",
        "short_codename": "son_my",
        "parentCode": "huyen_ham_tan"
    },
    {
        "name": "Xã Ngũ Phụng",
        "code": "xa_ngu_phung",
        "division_type": "xã",
        "short_codename": "ngu_phung",
        "parentCode": "huyen_phu_qui"
    },
    {
        "name": "Xã Long Hải",
        "code": "xa_long_hai",
        "division_type": "xã",
        "short_codename": "long_hai",
        "parentCode": "huyen_phu_qui"
    },
    {
        "name": "Xã Tam Thanh",
        "code": "xa_tam_thanh",
        "division_type": "xã",
        "short_codename": "tam_thanh",
        "parentCode": "huyen_phu_qui"
    },
    {
        "name": "Phường Quang Trung",
        "code": "phuong_quang_trung",
        "division_type": "phường",
        "short_codename": "quang_trung",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Phường Duy Tân",
        "code": "phuong_duy_tan",
        "division_type": "phường",
        "short_codename": "duy_tan",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Phường Quyết Thắng",
        "code": "phuong_quyet_thang",
        "division_type": "phường",
        "short_codename": "quyet_thang",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Phường Trường Chinh",
        "code": "phuong_truong_chinh",
        "division_type": "phường",
        "short_codename": "truong_chinh",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Phường Thắng Lợi",
        "code": "phuong_thang_loi",
        "division_type": "phường",
        "short_codename": "thang_loi",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Phường Ngô Mây",
        "code": "phuong_ngo_may",
        "division_type": "phường",
        "short_codename": "ngo_may",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Phường Thống Nhất",
        "code": "phuong_thong_nhat",
        "division_type": "phường",
        "short_codename": "thong_nhat",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Phường Lê Lợi",
        "code": "phuong_le_loi",
        "division_type": "phường",
        "short_codename": "le_loi",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Phường Nguyễn Trãi",
        "code": "phuong_nguyen_trai",
        "division_type": "phường",
        "short_codename": "nguyen_trai",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Phường Trần Hưng Đạo",
        "code": "phuong_tran_hung_dao",
        "division_type": "phường",
        "short_codename": "tran_hung_dao",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Đắk Cấm",
        "code": "xa_dak_cam",
        "division_type": "xã",
        "short_codename": "dak_cam",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Kroong",
        "code": "xa_kroong",
        "division_type": "xã",
        "short_codename": "kroong",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Ngọk Bay",
        "code": "xa_ngok_bay",
        "division_type": "xã",
        "short_codename": "ngok_bay",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Vinh Quang",
        "code": "xa_vinh_quang",
        "division_type": "xã",
        "short_codename": "vinh_quang",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Đắk Blà",
        "code": "xa_dak_bla",
        "division_type": "xã",
        "short_codename": "dak_bla",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Ia Chim",
        "code": "xa_ia_chim",
        "division_type": "xã",
        "short_codename": "ia_chim",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Đăk Năng",
        "code": "xa_dak_nang",
        "division_type": "xã",
        "short_codename": "dak_nang",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Đoàn Kết",
        "code": "xa_doan_ket",
        "division_type": "xã",
        "short_codename": "doan_ket",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Chư Hreng",
        "code": "xa_chu_hreng",
        "division_type": "xã",
        "short_codename": "chu_hreng",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Đắk Rơ Wa",
        "code": "xa_dak_ro_wa",
        "division_type": "xã",
        "short_codename": "dak_ro_wa",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "thanh_pho_kon_tum"
    },
    {
        "name": "Thị trấn Đắk Glei",
        "code": "thi_tran_dak_glei",
        "division_type": "thị trấn",
        "short_codename": "dak_glei",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Đắk Blô",
        "code": "xa_dak_blo",
        "division_type": "xã",
        "short_codename": "dak_blo",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Đắk Man",
        "code": "xa_dak_man",
        "division_type": "xã",
        "short_codename": "dak_man",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Đắk Nhoong",
        "code": "xa_dak_nhoong",
        "division_type": "xã",
        "short_codename": "dak_nhoong",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Đắk Pék",
        "code": "xa_dak_pek",
        "division_type": "xã",
        "short_codename": "dak_pek",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Đắk Choong",
        "code": "xa_dak_choong",
        "division_type": "xã",
        "short_codename": "dak_choong",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Xốp",
        "code": "xa_xop",
        "division_type": "xã",
        "short_codename": "xop",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Mường Hoong",
        "code": "xa_muong_hoong",
        "division_type": "xã",
        "short_codename": "muong_hoong",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Ngọc Linh",
        "code": "xa_ngoc_linh",
        "division_type": "xã",
        "short_codename": "ngoc_linh",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Đắk Long",
        "code": "xa_dak_long",
        "division_type": "xã",
        "short_codename": "dak_long",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Đắk KRoong",
        "code": "xa_dak_kroong",
        "division_type": "xã",
        "short_codename": "dak_kroong",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Xã Đắk Môn",
        "code": "xa_dak_mon",
        "division_type": "xã",
        "short_codename": "dak_mon",
        "parentCode": "huyen_dak_glei"
    },
    {
        "name": "Thị trấn Plei Kần",
        "code": "thi_tran_plei_kan",
        "division_type": "thị trấn",
        "short_codename": "plei_kan",
        "parentCode": "huyen_ngoc_hoi"
    },
    {
        "name": "Xã Đắk Ang",
        "code": "xa_dak_ang",
        "division_type": "xã",
        "short_codename": "dak_ang",
        "parentCode": "huyen_ngoc_hoi"
    },
    {
        "name": "Xã Đắk Dục",
        "code": "xa_dak_duc",
        "division_type": "xã",
        "short_codename": "dak_duc",
        "parentCode": "huyen_ngoc_hoi"
    },
    {
        "name": "Xã Đắk Nông",
        "code": "xa_dak_nong",
        "division_type": "xã",
        "short_codename": "dak_nong",
        "parentCode": "huyen_ngoc_hoi"
    },
    {
        "name": "Xã Đắk Xú",
        "code": "xa_dak_xu",
        "division_type": "xã",
        "short_codename": "dak_xu",
        "parentCode": "huyen_ngoc_hoi"
    },
    {
        "name": "Xã Đắk Kan",
        "code": "xa_dak_kan",
        "division_type": "xã",
        "short_codename": "dak_kan",
        "parentCode": "huyen_ngoc_hoi"
    },
    {
        "name": "Xã Bờ Y",
        "code": "xa_bo_y",
        "division_type": "xã",
        "short_codename": "bo_y",
        "parentCode": "huyen_ngoc_hoi"
    },
    {
        "name": "Xã Sa Loong",
        "code": "xa_sa_loong",
        "division_type": "xã",
        "short_codename": "sa_loong",
        "parentCode": "huyen_ngoc_hoi"
    },
    {
        "name": "Thị trấn Đắk Tô",
        "code": "thi_tran_dak_to",
        "division_type": "thị trấn",
        "short_codename": "dak_to",
        "parentCode": "huyen_dak_to"
    },
    {
        "name": "Xã Đắk Rơ Nga",
        "code": "xa_dak_ro_nga",
        "division_type": "xã",
        "short_codename": "dak_ro_nga",
        "parentCode": "huyen_dak_to"
    },
    {
        "name": "Xã Ngọk Tụ",
        "code": "xa_ngok_tu",
        "division_type": "xã",
        "short_codename": "ngok_tu",
        "parentCode": "huyen_dak_to"
    },
    {
        "name": "Xã Đắk Trăm",
        "code": "xa_dak_tram",
        "division_type": "xã",
        "short_codename": "dak_tram",
        "parentCode": "huyen_dak_to"
    },
    {
        "name": "Xã Văn Lem",
        "code": "xa_van_lem",
        "division_type": "xã",
        "short_codename": "van_lem",
        "parentCode": "huyen_dak_to"
    },
    {
        "name": "Xã Kon Đào",
        "code": "xa_kon_dao",
        "division_type": "xã",
        "short_codename": "kon_dao",
        "parentCode": "huyen_dak_to"
    },
    {
        "name": "Xã Tân Cảnh",
        "code": "xa_tan_canh",
        "division_type": "xã",
        "short_codename": "tan_canh",
        "parentCode": "huyen_dak_to"
    },
    {
        "name": "Xã Diên Bình",
        "code": "xa_dien_binh",
        "division_type": "xã",
        "short_codename": "dien_binh",
        "parentCode": "huyen_dak_to"
    },
    {
        "name": "Xã Pô Kô",
        "code": "xa_po_ko",
        "division_type": "xã",
        "short_codename": "po_ko",
        "parentCode": "huyen_dak_to"
    },
    {
        "name": "Xã Đắk Nên",
        "code": "xa_dak_nen",
        "division_type": "xã",
        "short_codename": "dak_nen",
        "parentCode": "huyen_kon_plong"
    },
    {
        "name": "Xã Đắk Ring",
        "code": "xa_dak_ring",
        "division_type": "xã",
        "short_codename": "dak_ring",
        "parentCode": "huyen_kon_plong"
    },
    {
        "name": "Xã Măng Buk",
        "code": "xa_mang_buk",
        "division_type": "xã",
        "short_codename": "mang_buk",
        "parentCode": "huyen_kon_plong"
    },
    {
        "name": "Xã Đắk Tăng",
        "code": "xa_dak_tang",
        "division_type": "xã",
        "short_codename": "dak_tang",
        "parentCode": "huyen_kon_plong"
    },
    {
        "name": "Xã Ngok Tem",
        "code": "xa_ngok_tem",
        "division_type": "xã",
        "short_codename": "ngok_tem",
        "parentCode": "huyen_kon_plong"
    },
    {
        "name": "Xã Pờ Ê",
        "code": "xa_po_e",
        "division_type": "xã",
        "short_codename": "po_e",
        "parentCode": "huyen_kon_plong"
    },
    {
        "name": "Xã Măng Cành",
        "code": "xa_mang_canh",
        "division_type": "xã",
        "short_codename": "mang_canh",
        "parentCode": "huyen_kon_plong"
    },
    {
        "name": "Thị trấn Măng Đen",
        "code": "thi_tran_mang_den",
        "division_type": "thị trấn",
        "short_codename": "mang_den",
        "parentCode": "huyen_kon_plong"
    },
    {
        "name": "Xã Hiếu",
        "code": "xa_hieu",
        "division_type": "xã",
        "short_codename": "hieu",
        "parentCode": "huyen_kon_plong"
    },
    {
        "name": "Thị trấn Đắk Rve",
        "code": "thi_tran_dak_rve",
        "division_type": "thị trấn",
        "short_codename": "dak_rve",
        "parentCode": "huyen_kon_ray"
    },
    {
        "name": "Xã Đắk Kôi",
        "code": "xa_dak_koi",
        "division_type": "xã",
        "short_codename": "dak_koi",
        "parentCode": "huyen_kon_ray"
    },
    {
        "name": "Xã Đắk Tơ Lung",
        "code": "xa_dak_to_lung",
        "division_type": "xã",
        "short_codename": "dak_to_lung",
        "parentCode": "huyen_kon_ray"
    },
    {
        "name": "Xã Đắk Ruồng",
        "code": "xa_dak_ruong",
        "division_type": "xã",
        "short_codename": "dak_ruong",
        "parentCode": "huyen_kon_ray"
    },
    {
        "name": "Xã Đắk Pne",
        "code": "xa_dak_pne",
        "division_type": "xã",
        "short_codename": "dak_pne",
        "parentCode": "huyen_kon_ray"
    },
    {
        "name": "Xã Đắk Tờ Re",
        "code": "xa_dak_to_re",
        "division_type": "xã",
        "short_codename": "dak_to_re",
        "parentCode": "huyen_kon_ray"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_kon_ray"
    },
    {
        "name": "Thị trấn Đắk Hà",
        "code": "thi_tran_dak_ha",
        "division_type": "thị trấn",
        "short_codename": "dak_ha",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Đắk PXi",
        "code": "xa_dak_pxi",
        "division_type": "xã",
        "short_codename": "dak_pxi",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Đăk Long",
        "code": "xa_dak_long",
        "division_type": "xã",
        "short_codename": "dak_long",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Đắk HRing",
        "code": "xa_dak_hring",
        "division_type": "xã",
        "short_codename": "dak_hring",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Đắk Ui",
        "code": "xa_dak_ui",
        "division_type": "xã",
        "short_codename": "dak_ui",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Đăk Ngọk",
        "code": "xa_dak_ngok",
        "division_type": "xã",
        "short_codename": "dak_ngok",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Đắk Mar",
        "code": "xa_dak_mar",
        "division_type": "xã",
        "short_codename": "dak_mar",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Ngok Wang",
        "code": "xa_ngok_wang",
        "division_type": "xã",
        "short_codename": "ngok_wang",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Ngok Réo",
        "code": "xa_ngok_reo",
        "division_type": "xã",
        "short_codename": "ngok_reo",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Hà Mòn",
        "code": "xa_ha_mon",
        "division_type": "xã",
        "short_codename": "ha_mon",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Xã Đắk La",
        "code": "xa_dak_la",
        "division_type": "xã",
        "short_codename": "dak_la",
        "parentCode": "huyen_dak_ha"
    },
    {
        "name": "Thị trấn Sa Thầy",
        "code": "thi_tran_sa_thay",
        "division_type": "thị trấn",
        "short_codename": "sa_thay",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Rơ Kơi",
        "code": "xa_ro_koi",
        "division_type": "xã",
        "short_codename": "ro_koi",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Sa Nhơn",
        "code": "xa_sa_nhon",
        "division_type": "xã",
        "short_codename": "sa_nhon",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Hơ Moong",
        "code": "xa_ho_moong",
        "division_type": "xã",
        "short_codename": "ho_moong",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Mô Rai",
        "code": "xa_mo_rai",
        "division_type": "xã",
        "short_codename": "mo_rai",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Sa Sơn",
        "code": "xa_sa_son",
        "division_type": "xã",
        "short_codename": "sa_son",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Sa Nghĩa",
        "code": "xa_sa_nghia",
        "division_type": "xã",
        "short_codename": "sa_nghia",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Sa Bình",
        "code": "xa_sa_binh",
        "division_type": "xã",
        "short_codename": "sa_binh",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Ya Xiêr",
        "code": "xa_ya_xier",
        "division_type": "xã",
        "short_codename": "ya_xier",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Ya Tăng",
        "code": "xa_ya_tang",
        "division_type": "xã",
        "short_codename": "ya_tang",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Ya ly",
        "code": "xa_ya_ly",
        "division_type": "xã",
        "short_codename": "ya_ly",
        "parentCode": "huyen_sa_thay"
    },
    {
        "name": "Xã Ngọc Lây",
        "code": "xa_ngoc_lay",
        "division_type": "xã",
        "short_codename": "ngoc_lay",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Đắk Na",
        "code": "xa_dak_na",
        "division_type": "xã",
        "short_codename": "dak_na",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Măng Ri",
        "code": "xa_mang_ri",
        "division_type": "xã",
        "short_codename": "mang_ri",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Ngọc Yêu",
        "code": "xa_ngoc_yeu",
        "division_type": "xã",
        "short_codename": "ngoc_yeu",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Đắk Sao",
        "code": "xa_dak_sao",
        "division_type": "xã",
        "short_codename": "dak_sao",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Đắk Rơ Ông",
        "code": "xa_dak_ro_ong",
        "division_type": "xã",
        "short_codename": "dak_ro_ong",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Đắk Tờ Kan",
        "code": "xa_dak_to_kan",
        "division_type": "xã",
        "short_codename": "dak_to_kan",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Tu Mơ Rông",
        "code": "xa_tu_mo_rong",
        "division_type": "xã",
        "short_codename": "tu_mo_rong",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Đắk Hà",
        "code": "xa_dak_ha",
        "division_type": "xã",
        "short_codename": "dak_ha",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Tê Xăng",
        "code": "xa_te_xang",
        "division_type": "xã",
        "short_codename": "te_xang",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Văn Xuôi",
        "code": "xa_van_xuoi",
        "division_type": "xã",
        "short_codename": "van_xuoi",
        "parentCode": "huyen_tu_mo_rong"
    },
    {
        "name": "Xã Ia Đal",
        "code": "xa_ia_dal",
        "division_type": "xã",
        "short_codename": "ia_dal",
        "parentCode": "huyen_ia_h_drai"
    },
    {
        "name": "Xã Ia Dom",
        "code": "xa_ia_dom",
        "division_type": "xã",
        "short_codename": "ia_dom",
        "parentCode": "huyen_ia_h_drai"
    },
    {
        "name": "Xã Ia Tơi",
        "code": "xa_ia_toi",
        "division_type": "xã",
        "short_codename": "ia_toi",
        "parentCode": "huyen_ia_h_drai"
    },
    {
        "name": "Phường Yên Đỗ",
        "code": "phuong_yen_do",
        "division_type": "phường",
        "short_codename": "yen_do",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Diên Hồng",
        "code": "phuong_dien_hong",
        "division_type": "phường",
        "short_codename": "dien_hong",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Ia Kring",
        "code": "phuong_ia_kring",
        "division_type": "phường",
        "short_codename": "ia_kring",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Hội Thương",
        "code": "phuong_hoi_thuong",
        "division_type": "phường",
        "short_codename": "hoi_thuong",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Hội Phú",
        "code": "phuong_hoi_phu",
        "division_type": "phường",
        "short_codename": "hoi_phu",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Phù Đổng",
        "code": "phuong_phu_dong",
        "division_type": "phường",
        "short_codename": "phu_dong",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Hoa Lư",
        "code": "phuong_hoa_lu",
        "division_type": "phường",
        "short_codename": "hoa_lu",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Tây Sơn",
        "code": "phuong_tay_son",
        "division_type": "phường",
        "short_codename": "tay_son",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Thống Nhất",
        "code": "phuong_thong_nhat",
        "division_type": "phường",
        "short_codename": "thong_nhat",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Đống Đa",
        "code": "phuong_dong_da",
        "division_type": "phường",
        "short_codename": "dong_da",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Trà Bá",
        "code": "phuong_tra_ba",
        "division_type": "phường",
        "short_codename": "tra_ba",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Thắng Lợi",
        "code": "phuong_thang_loi",
        "division_type": "phường",
        "short_codename": "thang_loi",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Yên Thế",
        "code": "phuong_yen_the",
        "division_type": "phường",
        "short_codename": "yen_the",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường Chi Lăng",
        "code": "phuong_chi_lang",
        "division_type": "phường",
        "short_codename": "chi_lang",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Xã Biển Hồ",
        "code": "xa_bien_ho",
        "division_type": "xã",
        "short_codename": "bien_ho",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Xã Tân Sơn",
        "code": "xa_tan_son",
        "division_type": "xã",
        "short_codename": "tan_son",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Xã Trà Đa",
        "code": "xa_tra_da",
        "division_type": "xã",
        "short_codename": "tra_da",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Xã Chư Á",
        "code": "xa_chu_a",
        "division_type": "xã",
        "short_codename": "chu_a",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Xã An Phú",
        "code": "xa_an_phu",
        "division_type": "xã",
        "short_codename": "an_phu",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Xã Diên Phú",
        "code": "xa_dien_phu",
        "division_type": "xã",
        "short_codename": "dien_phu",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Xã Ia Kênh",
        "code": "xa_ia_kenh",
        "division_type": "xã",
        "short_codename": "ia_kenh",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Xã Gào",
        "code": "xa_gao",
        "division_type": "xã",
        "short_codename": "gao",
        "parentCode": "thanh_pho_pleiku"
    },
    {
        "name": "Phường An Bình",
        "code": "phuong_an_binh",
        "division_type": "phường",
        "short_codename": "an_binh",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Phường Tây Sơn",
        "code": "phuong_tay_son",
        "division_type": "phường",
        "short_codename": "tay_son",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Phường An Phú",
        "code": "phuong_an_phu",
        "division_type": "phường",
        "short_codename": "an_phu",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Phường An Tân",
        "code": "phuong_an_tan",
        "division_type": "phường",
        "short_codename": "an_tan",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Xã Tú An",
        "code": "xa_tu_an",
        "division_type": "xã",
        "short_codename": "tu_an",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Xã Xuân An",
        "code": "xa_xuan_an",
        "division_type": "xã",
        "short_codename": "xuan_an",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Xã Cửu An",
        "code": "xa_cuu_an",
        "division_type": "xã",
        "short_codename": "cuu_an",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Phường An Phước",
        "code": "phuong_an_phuoc",
        "division_type": "phường",
        "short_codename": "an_phuoc",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Xã Song An",
        "code": "xa_song_an",
        "division_type": "xã",
        "short_codename": "song_an",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Phường Ngô Mây",
        "code": "phuong_ngo_may",
        "division_type": "phường",
        "short_codename": "ngo_may",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Xã Thành An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "thi_xa_an_khe"
    },
    {
        "name": "Phường Cheo Reo",
        "code": "phuong_cheo_reo",
        "division_type": "phường",
        "short_codename": "cheo_reo",
        "parentCode": "thi_xa_ayun_pa"
    },
    {
        "name": "Phường Hòa Bình",
        "code": "phuong_hoa_binh",
        "division_type": "phường",
        "short_codename": "hoa_binh",
        "parentCode": "thi_xa_ayun_pa"
    },
    {
        "name": "Phường Đoàn Kết",
        "code": "phuong_doan_ket",
        "division_type": "phường",
        "short_codename": "doan_ket",
        "parentCode": "thi_xa_ayun_pa"
    },
    {
        "name": "Phường Sông Bờ",
        "code": "phuong_song_bo",
        "division_type": "phường",
        "short_codename": "song_bo",
        "parentCode": "thi_xa_ayun_pa"
    },
    {
        "name": "Xã Ia RBol",
        "code": "xa_ia_rbol",
        "division_type": "xã",
        "short_codename": "ia_rbol",
        "parentCode": "thi_xa_ayun_pa"
    },
    {
        "name": "Xã Chư Băh",
        "code": "xa_chu_bah",
        "division_type": "xã",
        "short_codename": "chu_bah",
        "parentCode": "thi_xa_ayun_pa"
    },
    {
        "name": "Xã Ia RTô",
        "code": "xa_ia_rto",
        "division_type": "xã",
        "short_codename": "ia_rto",
        "parentCode": "thi_xa_ayun_pa"
    },
    {
        "name": "Xã Ia Sao",
        "code": "xa_ia_sao",
        "division_type": "xã",
        "short_codename": "ia_sao",
        "parentCode": "thi_xa_ayun_pa"
    },
    {
        "name": "Thị trấn KBang",
        "code": "thi_tran_kbang",
        "division_type": "thị trấn",
        "short_codename": "kbang",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Kon Pne",
        "code": "xa_kon_pne",
        "division_type": "xã",
        "short_codename": "kon_pne",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Đăk Roong",
        "code": "xa_dak_roong",
        "division_type": "xã",
        "short_codename": "dak_roong",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Sơn Lang",
        "code": "xa_son_lang",
        "division_type": "xã",
        "short_codename": "son_lang",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã KRong",
        "code": "xa_krong",
        "division_type": "xã",
        "short_codename": "krong",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Sơ Pai",
        "code": "xa_so_pai",
        "division_type": "xã",
        "short_codename": "so_pai",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Lơ Ku",
        "code": "xa_lo_ku",
        "division_type": "xã",
        "short_codename": "lo_ku",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Đông",
        "code": "xa_dong",
        "division_type": "xã",
        "short_codename": "dong",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Đak SMar",
        "code": "xa_dak_smar",
        "division_type": "xã",
        "short_codename": "dak_smar",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Nghĩa An",
        "code": "xa_nghia_an",
        "division_type": "xã",
        "short_codename": "nghia_an",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Tơ Tung",
        "code": "xa_to_tung",
        "division_type": "xã",
        "short_codename": "to_tung",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Kông Lơng Khơng",
        "code": "xa_kong_long_khong",
        "division_type": "xã",
        "short_codename": "kong_long_khong",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Kông Pla",
        "code": "xa_kong_pla",
        "division_type": "xã",
        "short_codename": "kong_pla",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Xã Đăk HLơ",
        "code": "xa_dak_hlo",
        "division_type": "xã",
        "short_codename": "dak_hlo",
        "parentCode": "huyen_kbang"
    },
    {
        "name": "Thị trấn Đăk Đoa",
        "code": "thi_tran_dak_doa",
        "division_type": "thị trấn",
        "short_codename": "dak_doa",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Hà Đông",
        "code": "xa_ha_dong",
        "division_type": "xã",
        "short_codename": "ha_dong",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Đăk Sơmei",
        "code": "xa_dak_somei",
        "division_type": "xã",
        "short_codename": "dak_somei",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Đăk Krong",
        "code": "xa_dak_krong",
        "division_type": "xã",
        "short_codename": "dak_krong",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Hải Yang",
        "code": "xa_hai_yang",
        "division_type": "xã",
        "short_codename": "hai_yang",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Kon Gang",
        "code": "xa_kon_gang",
        "division_type": "xã",
        "short_codename": "kon_gang",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Hà Bầu",
        "code": "xa_ha_bau",
        "division_type": "xã",
        "short_codename": "ha_bau",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Nam Yang",
        "code": "xa_nam_yang",
        "division_type": "xã",
        "short_codename": "nam_yang",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã K' Dang",
        "code": "xa_k_dang",
        "division_type": "xã",
        "short_codename": "k_dang",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã H' Neng",
        "code": "xa_h_neng",
        "division_type": "xã",
        "short_codename": "h_neng",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Glar",
        "code": "xa_glar",
        "division_type": "xã",
        "short_codename": "glar",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã A Dơk",
        "code": "xa_a_dok",
        "division_type": "xã",
        "short_codename": "a_dok",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Trang",
        "code": "xa_trang",
        "division_type": "xã",
        "short_codename": "trang",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã HNol",
        "code": "xa_hnol",
        "division_type": "xã",
        "short_codename": "hnol",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Ia Pết",
        "code": "xa_ia_pet",
        "division_type": "xã",
        "short_codename": "ia_pet",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Xã Ia Băng",
        "code": "xa_ia_bang",
        "division_type": "xã",
        "short_codename": "ia_bang",
        "parentCode": "huyen_dak_doa"
    },
    {
        "name": "Thị trấn Phú Hòa",
        "code": "thi_tran_phu_hoa",
        "division_type": "thị trấn",
        "short_codename": "phu_hoa",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Hà Tây",
        "code": "xa_ha_tay",
        "division_type": "xã",
        "short_codename": "ha_tay",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Ia Khươl",
        "code": "xa_ia_khuol",
        "division_type": "xã",
        "short_codename": "ia_khuol",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Ia Phí",
        "code": "xa_ia_phi",
        "division_type": "xã",
        "short_codename": "ia_phi",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Thị trấn Ia Ly",
        "code": "thi_tran_ia_ly",
        "division_type": "thị trấn",
        "short_codename": "ia_ly",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Ia Mơ Nông",
        "code": "xa_ia_mo_nong",
        "division_type": "xã",
        "short_codename": "ia_mo_nong",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Ia Kreng",
        "code": "xa_ia_kreng",
        "division_type": "xã",
        "short_codename": "ia_kreng",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Đăk Tơ Ver",
        "code": "xa_dak_to_ver",
        "division_type": "xã",
        "short_codename": "dak_to_ver",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Hòa Phú",
        "code": "xa_hoa_phu",
        "division_type": "xã",
        "short_codename": "hoa_phu",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Chư Đăng Ya",
        "code": "xa_chu_dang_ya",
        "division_type": "xã",
        "short_codename": "chu_dang_ya",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Ia Ka",
        "code": "xa_ia_ka",
        "division_type": "xã",
        "short_codename": "ia_ka",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Ia Nhin",
        "code": "xa_ia_nhin",
        "division_type": "xã",
        "short_codename": "ia_nhin",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Nghĩa Hòa",
        "code": "xa_nghia_hoa",
        "division_type": "xã",
        "short_codename": "nghia_hoa",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Xã Nghĩa Hưng",
        "code": "xa_nghia_hung",
        "division_type": "xã",
        "short_codename": "nghia_hung",
        "parentCode": "huyen_chu_pah"
    },
    {
        "name": "Thị trấn Ia Kha",
        "code": "thi_tran_ia_kha",
        "division_type": "thị trấn",
        "short_codename": "ia_kha",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Sao",
        "code": "xa_ia_sao",
        "division_type": "xã",
        "short_codename": "ia_sao",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Yok",
        "code": "xa_ia_yok",
        "division_type": "xã",
        "short_codename": "ia_yok",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Hrung",
        "code": "xa_ia_hrung",
        "division_type": "xã",
        "short_codename": "ia_hrung",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Bă",
        "code": "xa_ia_ba",
        "division_type": "xã",
        "short_codename": "ia_ba",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Khai",
        "code": "xa_ia_khai",
        "division_type": "xã",
        "short_codename": "ia_khai",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia KRai",
        "code": "xa_ia_krai",
        "division_type": "xã",
        "short_codename": "ia_krai",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Grăng",
        "code": "xa_ia_grang",
        "division_type": "xã",
        "short_codename": "ia_grang",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Tô",
        "code": "xa_ia_to",
        "division_type": "xã",
        "short_codename": "ia_to",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia O",
        "code": "xa_ia_o",
        "division_type": "xã",
        "short_codename": "ia_o",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Dêr",
        "code": "xa_ia_der",
        "division_type": "xã",
        "short_codename": "ia_der",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Chia",
        "code": "xa_ia_chia",
        "division_type": "xã",
        "short_codename": "ia_chia",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Xã Ia Pếch",
        "code": "xa_ia_pech",
        "division_type": "xã",
        "short_codename": "ia_pech",
        "parentCode": "huyen_ia_grai"
    },
    {
        "name": "Thị trấn Kon Dơng",
        "code": "thi_tran_kon_dong",
        "division_type": "thị trấn",
        "short_codename": "kon_dong",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Ayun",
        "code": "xa_ayun",
        "division_type": "xã",
        "short_codename": "ayun",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Đak Jơ Ta",
        "code": "xa_dak_jo_ta",
        "division_type": "xã",
        "short_codename": "dak_jo_ta",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Đak Ta Ley",
        "code": "xa_dak_ta_ley",
        "division_type": "xã",
        "short_codename": "dak_ta_ley",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Hra",
        "code": "xa_hra",
        "division_type": "xã",
        "short_codename": "hra",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Đăk Yă",
        "code": "xa_dak_ya",
        "division_type": "xã",
        "short_codename": "dak_ya",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Đăk Djrăng",
        "code": "xa_dak_djrang",
        "division_type": "xã",
        "short_codename": "dak_djrang",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Lơ Pang",
        "code": "xa_lo_pang",
        "division_type": "xã",
        "short_codename": "lo_pang",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Kon Thụp",
        "code": "xa_kon_thup",
        "division_type": "xã",
        "short_codename": "kon_thup",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Đê Ar",
        "code": "xa_de_ar",
        "division_type": "xã",
        "short_codename": "de_ar",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Kon Chiêng",
        "code": "xa_kon_chieng",
        "division_type": "xã",
        "short_codename": "kon_chieng",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Xã Đăk Trôi",
        "code": "xa_dak_troi",
        "division_type": "xã",
        "short_codename": "dak_troi",
        "parentCode": "huyen_mang_yang"
    },
    {
        "name": "Thị trấn Kông Chro",
        "code": "thi_tran_kong_chro",
        "division_type": "thị trấn",
        "short_codename": "kong_chro",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Chư Krêy",
        "code": "xa_chu_krey",
        "division_type": "xã",
        "short_codename": "chu_krey",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã An Trung",
        "code": "xa_an_trung",
        "division_type": "xã",
        "short_codename": "an_trung",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Kông Yang",
        "code": "xa_kong_yang",
        "division_type": "xã",
        "short_codename": "kong_yang",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Đăk Tơ Pang",
        "code": "xa_dak_to_pang",
        "division_type": "xã",
        "short_codename": "dak_to_pang",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã SRó",
        "code": "xa_sro",
        "division_type": "xã",
        "short_codename": "sro",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Đắk Kơ Ning",
        "code": "xa_dak_ko_ning",
        "division_type": "xã",
        "short_codename": "dak_ko_ning",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Đăk Song",
        "code": "xa_dak_song",
        "division_type": "xã",
        "short_codename": "dak_song",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Đăk Pling",
        "code": "xa_dak_pling",
        "division_type": "xã",
        "short_codename": "dak_pling",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Yang Trung",
        "code": "xa_yang_trung",
        "division_type": "xã",
        "short_codename": "yang_trung",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Đăk Pơ Pho",
        "code": "xa_dak_po_pho",
        "division_type": "xã",
        "short_codename": "dak_po_pho",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Ya Ma",
        "code": "xa_ya_ma",
        "division_type": "xã",
        "short_codename": "ya_ma",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Chơ Long",
        "code": "xa_cho_long",
        "division_type": "xã",
        "short_codename": "cho_long",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Xã Yang Nam",
        "code": "xa_yang_nam",
        "division_type": "xã",
        "short_codename": "yang_nam",
        "parentCode": "huyen_kong_chro"
    },
    {
        "name": "Thị trấn Chư Ty",
        "code": "thi_tran_chu_ty",
        "division_type": "thị trấn",
        "short_codename": "chu_ty",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Xã Ia Dơk",
        "code": "xa_ia_dok",
        "division_type": "xã",
        "short_codename": "ia_dok",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Xã Ia Krêl",
        "code": "xa_ia_krel",
        "division_type": "xã",
        "short_codename": "ia_krel",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Xã Ia Din",
        "code": "xa_ia_din",
        "division_type": "xã",
        "short_codename": "ia_din",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Xã Ia Kla",
        "code": "xa_ia_kla",
        "division_type": "xã",
        "short_codename": "ia_kla",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Xã Ia Dom",
        "code": "xa_ia_dom",
        "division_type": "xã",
        "short_codename": "ia_dom",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Xã Ia Lang",
        "code": "xa_ia_lang",
        "division_type": "xã",
        "short_codename": "ia_lang",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Xã Ia Kriêng",
        "code": "xa_ia_krieng",
        "division_type": "xã",
        "short_codename": "ia_krieng",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Xã Ia Pnôn",
        "code": "xa_ia_pnon",
        "division_type": "xã",
        "short_codename": "ia_pnon",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Xã Ia Nan",
        "code": "xa_ia_nan",
        "division_type": "xã",
        "short_codename": "ia_nan",
        "parentCode": "huyen_duc_co"
    },
    {
        "name": "Thị trấn Chư Prông",
        "code": "thi_tran_chu_prong",
        "division_type": "thị trấn",
        "short_codename": "chu_prong",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Kly",
        "code": "xa_ia_kly",
        "division_type": "xã",
        "short_codename": "ia_kly",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Bình Giáo",
        "code": "xa_binh_giao",
        "division_type": "xã",
        "short_codename": "binh_giao",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Drăng",
        "code": "xa_ia_drang",
        "division_type": "xã",
        "short_codename": "ia_drang",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Thăng Hưng",
        "code": "xa_thang_hung",
        "division_type": "xã",
        "short_codename": "thang_hung",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Bàu Cạn",
        "code": "xa_bau_can",
        "division_type": "xã",
        "short_codename": "bau_can",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Phìn",
        "code": "xa_ia_phin",
        "division_type": "xã",
        "short_codename": "ia_phin",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Băng",
        "code": "xa_ia_bang",
        "division_type": "xã",
        "short_codename": "xa_ia_bang",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Tôr",
        "code": "xa_ia_tor",
        "division_type": "xã",
        "short_codename": "ia_tor",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Boòng",
        "code": "xa_ia_boong",
        "division_type": "xã",
        "short_codename": "ia_boong",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia O",
        "code": "xa_ia_o",
        "division_type": "xã",
        "short_codename": "ia_o",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Púch",
        "code": "xa_ia_puch",
        "division_type": "xã",
        "short_codename": "ia_puch",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Me",
        "code": "xa_ia_me",
        "division_type": "xã",
        "short_codename": "ia_me",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Vê",
        "code": "xa_ia_ve",
        "division_type": "xã",
        "short_codename": "ia_ve",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Bang",
        "code": "xa_ia_bang",
        "division_type": "xã",
        "short_codename": "xa_ia_bang",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Pia",
        "code": "xa_ia_pia",
        "division_type": "xã",
        "short_codename": "ia_pia",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Ga",
        "code": "xa_ia_ga",
        "division_type": "xã",
        "short_codename": "ia_ga",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Lâu",
        "code": "xa_ia_lau",
        "division_type": "xã",
        "short_codename": "ia_lau",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Piơr",
        "code": "xa_ia_pior",
        "division_type": "xã",
        "short_codename": "ia_pior",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Xã Ia Mơ",
        "code": "xa_ia_mo",
        "division_type": "xã",
        "short_codename": "ia_mo",
        "parentCode": "huyen_chu_prong"
    },
    {
        "name": "Thị trấn Chư Sê",
        "code": "thi_tran_chu_se",
        "division_type": "thị trấn",
        "short_codename": "chu_se",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Ia Tiêm",
        "code": "xa_ia_tiem",
        "division_type": "xã",
        "short_codename": "ia_tiem",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Chư Pơng",
        "code": "xa_chu_pong",
        "division_type": "xã",
        "short_codename": "chu_pong",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Bar Măih",
        "code": "xa_bar_maih",
        "division_type": "xã",
        "short_codename": "bar_maih",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Bờ Ngoong",
        "code": "xa_bo_ngoong",
        "division_type": "xã",
        "short_codename": "bo_ngoong",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Ia Glai",
        "code": "xa_ia_glai",
        "division_type": "xã",
        "short_codename": "ia_glai",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã AL Bá",
        "code": "xa_al_ba",
        "division_type": "xã",
        "short_codename": "al_ba",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Kông HTok",
        "code": "xa_kong_htok",
        "division_type": "xã",
        "short_codename": "kong_htok",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã AYun",
        "code": "xa_ayun",
        "division_type": "xã",
        "short_codename": "ayun",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Ia HLốp",
        "code": "xa_ia_hlop",
        "division_type": "xã",
        "short_codename": "ia_hlop",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Ia Blang",
        "code": "xa_ia_blang",
        "division_type": "xã",
        "short_codename": "ia_blang",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Dun",
        "code": "xa_dun",
        "division_type": "xã",
        "short_codename": "dun",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Ia Pal",
        "code": "xa_ia_pal",
        "division_type": "xã",
        "short_codename": "ia_pal",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã H Bông",
        "code": "xa_h_bong",
        "division_type": "xã",
        "short_codename": "h_bong",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Ia Ko",
        "code": "xa_ia_ko",
        "division_type": "xã",
        "short_codename": "ia_ko",
        "parentCode": "huyen_chu_se"
    },
    {
        "name": "Xã Hà Tam",
        "code": "xa_ha_tam",
        "division_type": "xã",
        "short_codename": "ha_tam",
        "parentCode": "huyen_dak_po"
    },
    {
        "name": "Xã An Thành",
        "code": "xa_an_thanh",
        "division_type": "xã",
        "short_codename": "an_thanh",
        "parentCode": "huyen_dak_po"
    },
    {
        "name": "Thị trấn Đak Pơ",
        "code": "thi_tran_dak_po",
        "division_type": "thị trấn",
        "short_codename": "dak_po",
        "parentCode": "huyen_dak_po"
    },
    {
        "name": "Xã Yang Bắc",
        "code": "xa_yang_bac",
        "division_type": "xã",
        "short_codename": "yang_bac",
        "parentCode": "huyen_dak_po"
    },
    {
        "name": "Xã Cư An",
        "code": "xa_cu_an",
        "division_type": "xã",
        "short_codename": "cu_an",
        "parentCode": "huyen_dak_po"
    },
    {
        "name": "Xã Tân An",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_dak_po"
    },
    {
        "name": "Xã Phú An",
        "code": "xa_phu_an",
        "division_type": "xã",
        "short_codename": "phu_an",
        "parentCode": "huyen_dak_po"
    },
    {
        "name": "Xã Ya Hội",
        "code": "xa_ya_hoi",
        "division_type": "xã",
        "short_codename": "ya_hoi",
        "parentCode": "huyen_dak_po"
    },
    {
        "name": "Xã Pờ Tó",
        "code": "xa_po_to",
        "division_type": "xã",
        "short_codename": "po_to",
        "parentCode": "huyen_ia_pa"
    },
    {
        "name": "Xã Chư Răng",
        "code": "xa_chu_rang",
        "division_type": "xã",
        "short_codename": "chu_rang",
        "parentCode": "huyen_ia_pa"
    },
    {
        "name": "Xã Ia KDăm",
        "code": "xa_ia_kdam",
        "division_type": "xã",
        "short_codename": "ia_kdam",
        "parentCode": "huyen_ia_pa"
    },
    {
        "name": "Xã Kim Tân",
        "code": "xa_kim_tan",
        "division_type": "xã",
        "short_codename": "kim_tan",
        "parentCode": "huyen_ia_pa"
    },
    {
        "name": "Xã Chư Mố",
        "code": "xa_chu_mo",
        "division_type": "xã",
        "short_codename": "chu_mo",
        "parentCode": "huyen_ia_pa"
    },
    {
        "name": "Xã Ia Tul",
        "code": "xa_ia_tul",
        "division_type": "xã",
        "short_codename": "ia_tul",
        "parentCode": "huyen_ia_pa"
    },
    {
        "name": "Xã Ia Ma Rơn",
        "code": "xa_ia_ma_ron",
        "division_type": "xã",
        "short_codename": "ia_ma_ron",
        "parentCode": "huyen_ia_pa"
    },
    {
        "name": "Xã Ia Broăi",
        "code": "xa_ia_broai",
        "division_type": "xã",
        "short_codename": "ia_broai",
        "parentCode": "huyen_ia_pa"
    },
    {
        "name": "Xã Ia Trok",
        "code": "xa_ia_trok",
        "division_type": "xã",
        "short_codename": "ia_trok",
        "parentCode": "huyen_ia_pa"
    },
    {
        "name": "Thị trấn Phú Túc",
        "code": "thi_tran_phu_tuc",
        "division_type": "thị trấn",
        "short_codename": "phu_tuc",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Ia RSai",
        "code": "xa_ia_rsai",
        "division_type": "xã",
        "short_codename": "ia_rsai",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Ia RSươm",
        "code": "xa_ia_rsuom",
        "division_type": "xã",
        "short_codename": "ia_rsuom",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Chư Gu",
        "code": "xa_chu_gu",
        "division_type": "xã",
        "short_codename": "chu_gu",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Đất Bằng",
        "code": "xa_dat_bang",
        "division_type": "xã",
        "short_codename": "dat_bang",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Ia Mláh",
        "code": "xa_ia_mlah",
        "division_type": "xã",
        "short_codename": "ia_mlah",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Chư Drăng",
        "code": "xa_chu_drang",
        "division_type": "xã",
        "short_codename": "chu_drang",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Phú Cần",
        "code": "xa_phu_can",
        "division_type": "xã",
        "short_codename": "phu_can",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Ia HDreh",
        "code": "xa_ia_hdreh",
        "division_type": "xã",
        "short_codename": "ia_hdreh",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Ia RMok",
        "code": "xa_ia_rmok",
        "division_type": "xã",
        "short_codename": "ia_rmok",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Chư Ngọc",
        "code": "xa_chu_ngoc",
        "division_type": "xã",
        "short_codename": "chu_ngoc",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Uar",
        "code": "xa_uar",
        "division_type": "xã",
        "short_codename": "uar",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Chư Rcăm",
        "code": "xa_chu_rcam",
        "division_type": "xã",
        "short_codename": "chu_rcam",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Xã Krông Năng",
        "code": "xa_krong_nang",
        "division_type": "xã",
        "short_codename": "krong_nang",
        "parentCode": "huyen_krong_pa"
    },
    {
        "name": "Thị trấn Phú Thiện",
        "code": "thi_tran_phu_thien",
        "division_type": "thị trấn",
        "short_codename": "phu_thien",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Xã Chư A Thai",
        "code": "xa_chu_a_thai",
        "division_type": "xã",
        "short_codename": "chu_a_thai",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Xã Ayun Hạ",
        "code": "xa_ayun_ha",
        "division_type": "xã",
        "short_codename": "ayun_ha",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Xã Ia Ake",
        "code": "xa_ia_ake",
        "division_type": "xã",
        "short_codename": "ia_ake",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Xã Ia Sol",
        "code": "xa_ia_sol",
        "division_type": "xã",
        "short_codename": "ia_sol",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Xã Ia Piar",
        "code": "xa_ia_piar",
        "division_type": "xã",
        "short_codename": "ia_piar",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Xã Ia Peng",
        "code": "xa_ia_peng",
        "division_type": "xã",
        "short_codename": "ia_peng",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Xã Chrôh Pơnan",
        "code": "xa_chroh_ponan",
        "division_type": "xã",
        "short_codename": "chroh_ponan",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Xã Ia Hiao",
        "code": "xa_ia_hiao",
        "division_type": "xã",
        "short_codename": "ia_hiao",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Xã Ia Yeng",
        "code": "xa_ia_yeng",
        "division_type": "xã",
        "short_codename": "ia_yeng",
        "parentCode": "huyen_phu_thien"
    },
    {
        "name": "Thị trấn Nhơn Hoà",
        "code": "thi_tran_nhon_hoa",
        "division_type": "thị trấn",
        "short_codename": "nhon_hoa",
        "parentCode": "huyen_chu_puh"
    },
    {
        "name": "Xã Ia Hrú",
        "code": "xa_ia_hru",
        "division_type": "xã",
        "short_codename": "ia_hru",
        "parentCode": "huyen_chu_puh"
    },
    {
        "name": "Xã Ia Rong",
        "code": "xa_ia_rong",
        "division_type": "xã",
        "short_codename": "ia_rong",
        "parentCode": "huyen_chu_puh"
    },
    {
        "name": "Xã Ia Dreng",
        "code": "xa_ia_dreng",
        "division_type": "xã",
        "short_codename": "ia_dreng",
        "parentCode": "huyen_chu_puh"
    },
    {
        "name": "Xã Ia Hla",
        "code": "xa_ia_hla",
        "division_type": "xã",
        "short_codename": "ia_hla",
        "parentCode": "huyen_chu_puh"
    },
    {
        "name": "Xã Chư Don",
        "code": "xa_chu_don",
        "division_type": "xã",
        "short_codename": "chu_don",
        "parentCode": "huyen_chu_puh"
    },
    {
        "name": "Xã Ia Phang",
        "code": "xa_ia_phang",
        "division_type": "xã",
        "short_codename": "ia_phang",
        "parentCode": "huyen_chu_puh"
    },
    {
        "name": "Xã Ia Le",
        "code": "xa_ia_le",
        "division_type": "xã",
        "short_codename": "ia_le",
        "parentCode": "huyen_chu_puh"
    },
    {
        "name": "Xã Ia BLứ",
        "code": "xa_ia_blu",
        "division_type": "xã",
        "short_codename": "ia_blu",
        "parentCode": "huyen_chu_puh"
    },
    {
        "name": "Phường Tân Lập",
        "code": "phuong_tan_lap",
        "division_type": "phường",
        "short_codename": "tan_lap",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Tân Hòa",
        "code": "phuong_tan_hoa",
        "division_type": "phường",
        "short_codename": "tan_hoa",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Tân An",
        "code": "phuong_tan_an",
        "division_type": "phường",
        "short_codename": "tan_an",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Thống Nhất",
        "code": "phuong_thong_nhat",
        "division_type": "phường",
        "short_codename": "thong_nhat",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Thành Nhất",
        "code": "phuong_thanh_nhat",
        "division_type": "phường",
        "short_codename": "thanh_nhat",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Thắng Lợi",
        "code": "phuong_thang_loi",
        "division_type": "phường",
        "short_codename": "thang_loi",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Tân Lợi",
        "code": "phuong_tan_loi",
        "division_type": "phường",
        "short_codename": "tan_loi",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Thành Công",
        "code": "phuong_thanh_cong",
        "division_type": "phường",
        "short_codename": "thanh_cong",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Tân Thành",
        "code": "phuong_tan_thanh",
        "division_type": "phường",
        "short_codename": "tan_thanh",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Tân Tiến",
        "code": "phuong_tan_tien",
        "division_type": "phường",
        "short_codename": "tan_tien",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Tự An",
        "code": "phuong_tu_an",
        "division_type": "phường",
        "short_codename": "tu_an",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Ea Tam",
        "code": "phuong_ea_tam",
        "division_type": "phường",
        "short_codename": "ea_tam",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường Khánh Xuân",
        "code": "phuong_khanh_xuan",
        "division_type": "phường",
        "short_codename": "khanh_xuan",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Xã Hòa Thuận",
        "code": "xa_hoa_thuan",
        "division_type": "xã",
        "short_codename": "hoa_thuan",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Xã Cư ÊBur",
        "code": "xa_cu_ebur",
        "division_type": "xã",
        "short_codename": "cu_ebur",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Xã Ea Tu",
        "code": "xa_ea_tu",
        "division_type": "xã",
        "short_codename": "ea_tu",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Xã Hòa Thắng",
        "code": "xa_hoa_thang",
        "division_type": "xã",
        "short_codename": "hoa_thang",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Xã Ea Kao",
        "code": "xa_ea_kao",
        "division_type": "xã",
        "short_codename": "ea_kao",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Xã Hòa Phú",
        "code": "xa_hoa_phu",
        "division_type": "xã",
        "short_codename": "hoa_phu",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Xã Hòa Khánh",
        "code": "xa_hoa_khanh",
        "division_type": "xã",
        "short_codename": "hoa_khanh",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Xã Hòa Xuân",
        "code": "xa_hoa_xuan",
        "division_type": "xã",
        "short_codename": "hoa_xuan",
        "parentCode": "thanh_pho_buon_ma_thuot"
    },
    {
        "name": "Phường An Lạc",
        "code": "phuong_an_lac",
        "division_type": "phường",
        "short_codename": "an_lac",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Phường An Bình",
        "code": "phuong_an_binh",
        "division_type": "phường",
        "short_codename": "an_binh",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Phường Thiện An",
        "code": "phuong_thien_an",
        "division_type": "phường",
        "short_codename": "thien_an",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Phường Đạt Hiếu",
        "code": "phuong_dat_hieu",
        "division_type": "phường",
        "short_codename": "dat_hieu",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Phường Đoàn Kết",
        "code": "phuong_doan_ket",
        "division_type": "phường",
        "short_codename": "doan_ket",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Xã Ea Blang",
        "code": "xa_ea_blang",
        "division_type": "xã",
        "short_codename": "ea_blang",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Xã Ea Drông",
        "code": "xa_ea_drong",
        "division_type": "xã",
        "short_codename": "ea_drong",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Phường Thống Nhất",
        "code": "phuong_thong_nhat",
        "division_type": "phường",
        "short_codename": "thong_nhat",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Phường Bình Tân",
        "code": "phuong_binh_tan",
        "division_type": "phường",
        "short_codename": "binh_tan",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Xã Ea Siên",
        "code": "xa_ea_sien",
        "division_type": "xã",
        "short_codename": "ea_sien",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Xã Bình Thuận",
        "code": "xa_binh_thuan",
        "division_type": "xã",
        "short_codename": "binh_thuan",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Xã Cư Bao",
        "code": "xa_cu_bao",
        "division_type": "xã",
        "short_codename": "cu_bao",
        "parentCode": "thi_xa_buon_ho"
    },
    {
        "name": "Thị trấn Ea Drăng",
        "code": "thi_tran_ea_drang",
        "division_type": "thị trấn",
        "short_codename": "ea_drang",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Ea H'leo",
        "code": "xa_ea_hleo",
        "division_type": "xã",
        "short_codename": "ea_hleo",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Ea Sol",
        "code": "xa_ea_sol",
        "division_type": "xã",
        "short_codename": "ea_sol",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Ea Ral",
        "code": "xa_ea_ral",
        "division_type": "xã",
        "short_codename": "ea_ral",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Ea Wy",
        "code": "xa_ea_wy",
        "division_type": "xã",
        "short_codename": "ea_wy",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Cư A Mung",
        "code": "xa_cu_a_mung",
        "division_type": "xã",
        "short_codename": "cu_a_mung",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Cư Mốt",
        "code": "xa_cu_mot",
        "division_type": "xã",
        "short_codename": "cu_mot",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Ea Hiao",
        "code": "xa_ea_hiao",
        "division_type": "xã",
        "short_codename": "ea_hiao",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Ea Khal",
        "code": "xa_ea_khal",
        "division_type": "xã",
        "short_codename": "ea_khal",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Dliê Yang",
        "code": "xa_dlie_yang",
        "division_type": "xã",
        "short_codename": "dlie_yang",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Ea Tir",
        "code": "xa_ea_tir",
        "division_type": "xã",
        "short_codename": "ea_tir",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Xã Ea Nam",
        "code": "xa_ea_nam",
        "division_type": "xã",
        "short_codename": "ea_nam",
        "parentCode": "huyen_ea_hleo"
    },
    {
        "name": "Thị trấn Ea Súp",
        "code": "thi_tran_ea_sup",
        "division_type": "thị trấn",
        "short_codename": "ea_sup",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Ia Lốp",
        "code": "xa_ia_lop",
        "division_type": "xã",
        "short_codename": "ia_lop",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Ia JLơi",
        "code": "xa_ia_jloi",
        "division_type": "xã",
        "short_codename": "ia_jloi",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Ea Rốk",
        "code": "xa_ea_rok",
        "division_type": "xã",
        "short_codename": "ea_rok",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Ya Tờ Mốt",
        "code": "xa_ya_to_mot",
        "division_type": "xã",
        "short_codename": "ya_to_mot",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Ia RVê",
        "code": "xa_ia_rve",
        "division_type": "xã",
        "short_codename": "ia_rve",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Ea Lê",
        "code": "xa_ea_le",
        "division_type": "xã",
        "short_codename": "ea_le",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Cư KBang",
        "code": "xa_cu_kbang",
        "division_type": "xã",
        "short_codename": "cu_kbang",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Ea Bung",
        "code": "xa_ea_bung",
        "division_type": "xã",
        "short_codename": "ea_bung",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Cư M'Lan",
        "code": "xa_cu_mlan",
        "division_type": "xã",
        "short_codename": "cu_mlan",
        "parentCode": "huyen_ea_sup"
    },
    {
        "name": "Xã Krông Na",
        "code": "xa_krong_na",
        "division_type": "xã",
        "short_codename": "krong_na",
        "parentCode": "huyen_buon_don"
    },
    {
        "name": "Xã Ea Huar",
        "code": "xa_ea_huar",
        "division_type": "xã",
        "short_codename": "ea_huar",
        "parentCode": "huyen_buon_don"
    },
    {
        "name": "Xã Ea Wer",
        "code": "xa_ea_wer",
        "division_type": "xã",
        "short_codename": "ea_wer",
        "parentCode": "huyen_buon_don"
    },
    {
        "name": "Xã Tân Hoà",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_buon_don"
    },
    {
        "name": "Xã Cuôr KNia",
        "code": "xa_cuor_knia",
        "division_type": "xã",
        "short_codename": "cuor_knia",
        "parentCode": "huyen_buon_don"
    },
    {
        "name": "Xã Ea Bar",
        "code": "xa_ea_bar",
        "division_type": "xã",
        "short_codename": "ea_bar",
        "parentCode": "huyen_buon_don"
    },
    {
        "name": "Xã Ea Nuôl",
        "code": "xa_ea_nuol",
        "division_type": "xã",
        "short_codename": "ea_nuol",
        "parentCode": "huyen_buon_don"
    },
    {
        "name": "Thị trấn Ea Pốk",
        "code": "thi_tran_ea_pok",
        "division_type": "thị trấn",
        "short_codename": "ea_pok",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Thị trấn Quảng Phú",
        "code": "thi_tran_quang_phu",
        "division_type": "thị trấn",
        "short_codename": "quang_phu",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Quảng Tiến",
        "code": "xa_quang_tien",
        "division_type": "xã",
        "short_codename": "quang_tien",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Ea Kuêh",
        "code": "xa_ea_kueh",
        "division_type": "xã",
        "short_codename": "ea_kueh",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Ea Kiết",
        "code": "xa_ea_kiet",
        "division_type": "xã",
        "short_codename": "ea_kiet",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Ea Tar",
        "code": "xa_ea_tar",
        "division_type": "xã",
        "short_codename": "ea_tar",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Cư Dliê M'nông",
        "code": "xa_cu_dlie_mnong",
        "division_type": "xã",
        "short_codename": "cu_dlie_mnong",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Ea H'đinh",
        "code": "xa_ea_hdinh",
        "division_type": "xã",
        "short_codename": "ea_hdinh",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Ea Tul",
        "code": "xa_ea_tul",
        "division_type": "xã",
        "short_codename": "ea_tul",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Ea KPam",
        "code": "xa_ea_kpam",
        "division_type": "xã",
        "short_codename": "ea_kpam",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Ea M'DRóh",
        "code": "xa_ea_mdroh",
        "division_type": "xã",
        "short_codename": "ea_mdroh",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Quảng Hiệp",
        "code": "xa_quang_hiep",
        "division_type": "xã",
        "short_codename": "quang_hiep",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Cư M'gar",
        "code": "xa_cu_mgar",
        "division_type": "xã",
        "short_codename": "cu_mgar",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Ea D'Rơng",
        "code": "xa_ea_drong",
        "division_type": "xã",
        "short_codename": "ea_drong",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Ea M'nang",
        "code": "xa_ea_mnang",
        "division_type": "xã",
        "short_codename": "ea_mnang",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Cư Suê",
        "code": "xa_cu_sue",
        "division_type": "xã",
        "short_codename": "cu_sue",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Cuor Đăng",
        "code": "xa_cuor_dang",
        "division_type": "xã",
        "short_codename": "cuor_dang",
        "parentCode": "huyen_cu_mgar"
    },
    {
        "name": "Xã Cư Né",
        "code": "xa_cu_ne",
        "division_type": "xã",
        "short_codename": "cu_ne",
        "parentCode": "huyen_krong_buk"
    },
    {
        "name": "Xã Chư KBô",
        "code": "xa_chu_kbo",
        "division_type": "xã",
        "short_codename": "chu_kbo",
        "parentCode": "huyen_krong_buk"
    },
    {
        "name": "Xã Cư Pơng",
        "code": "xa_cu_pong",
        "division_type": "xã",
        "short_codename": "cu_pong",
        "parentCode": "huyen_krong_buk"
    },
    {
        "name": "Xã Ea Sin",
        "code": "xa_ea_sin",
        "division_type": "xã",
        "short_codename": "ea_sin",
        "parentCode": "huyen_krong_buk"
    },
    {
        "name": "Xã Pơng Drang",
        "code": "xa_pong_drang",
        "division_type": "xã",
        "short_codename": "pong_drang",
        "parentCode": "huyen_krong_buk"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_krong_buk"
    },
    {
        "name": "Xã Ea Ngai",
        "code": "xa_ea_ngai",
        "division_type": "xã",
        "short_codename": "ea_ngai",
        "parentCode": "huyen_krong_buk"
    },
    {
        "name": "Thị trấn Krông Năng",
        "code": "thi_tran_krong_nang",
        "division_type": "thị trấn",
        "short_codename": "krong_nang",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã ĐLiê Ya",
        "code": "xa_dlie_ya",
        "division_type": "xã",
        "short_codename": "dlie_ya",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Ea Tóh",
        "code": "xa_ea_toh",
        "division_type": "xã",
        "short_codename": "ea_toh",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Ea Tam",
        "code": "xa_ea_tam",
        "division_type": "xã",
        "short_codename": "ea_tam",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Phú Lộc",
        "code": "xa_phu_loc",
        "division_type": "xã",
        "short_codename": "phu_loc",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Tam Giang",
        "code": "xa_tam_giang",
        "division_type": "xã",
        "short_codename": "tam_giang",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Ea Puk",
        "code": "xa_ea_puk",
        "division_type": "xã",
        "short_codename": "ea_puk",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Ea Dăh",
        "code": "xa_ea_dah",
        "division_type": "xã",
        "short_codename": "ea_dah",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Ea Hồ",
        "code": "xa_ea_ho",
        "division_type": "xã",
        "short_codename": "ea_ho",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Phú Xuân",
        "code": "xa_phu_xuan",
        "division_type": "xã",
        "short_codename": "phu_xuan",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Cư Klông",
        "code": "xa_cu_klong",
        "division_type": "xã",
        "short_codename": "cu_klong",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Xã Ea Tân",
        "code": "xa_ea_tan",
        "division_type": "xã",
        "short_codename": "ea_tan",
        "parentCode": "huyen_krong_nang"
    },
    {
        "name": "Thị trấn Ea Kar",
        "code": "thi_tran_ea_kar",
        "division_type": "thị trấn",
        "short_codename": "ea_kar",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Thị trấn Ea Knốp",
        "code": "thi_tran_ea_knop",
        "division_type": "thị trấn",
        "short_codename": "ea_knop",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Ea Sô",
        "code": "xa_ea_so",
        "division_type": "xã",
        "short_codename": "ea_so",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Ea Sar",
        "code": "xa_ea_sar",
        "division_type": "xã",
        "short_codename": "ea_sar",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Xuân Phú",
        "code": "xa_xuan_phu",
        "division_type": "xã",
        "short_codename": "xuan_phu",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Cư Huê",
        "code": "xa_cu_hue",
        "division_type": "xã",
        "short_codename": "cu_hue",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Ea Tih",
        "code": "xa_ea_tih",
        "division_type": "xã",
        "short_codename": "ea_tih",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Ea Đar",
        "code": "xa_ea_dar",
        "division_type": "xã",
        "short_codename": "ea_dar",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Ea Kmút",
        "code": "xa_ea_kmut",
        "division_type": "xã",
        "short_codename": "ea_kmut",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Cư Ni",
        "code": "xa_cu_ni",
        "division_type": "xã",
        "short_codename": "cu_ni",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Ea Păl",
        "code": "xa_ea_pal",
        "division_type": "xã",
        "short_codename": "ea_pal",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Cư Prông",
        "code": "xa_cu_prong",
        "division_type": "xã",
        "short_codename": "cu_prong",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Ea Ô",
        "code": "xa_ea_o",
        "division_type": "xã",
        "short_codename": "ea_o",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Cư ELang",
        "code": "xa_cu_elang",
        "division_type": "xã",
        "short_codename": "cu_elang",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Cư Bông",
        "code": "xa_cu_bong",
        "division_type": "xã",
        "short_codename": "cu_bong",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Xã Cư Jang",
        "code": "xa_cu_jang",
        "division_type": "xã",
        "short_codename": "cu_jang",
        "parentCode": "huyen_ea_kar"
    },
    {
        "name": "Thị trấn M'Đrắk",
        "code": "thi_tran_mdrak",
        "division_type": "thị trấn",
        "short_codename": "mdrak",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Cư Prao",
        "code": "xa_cu_prao",
        "division_type": "xã",
        "short_codename": "cu_prao",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Ea Pil",
        "code": "xa_ea_pil",
        "division_type": "xã",
        "short_codename": "ea_pil",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Ea Lai",
        "code": "xa_ea_lai",
        "division_type": "xã",
        "short_codename": "ea_lai",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Ea H'MLay",
        "code": "xa_ea_hmlay",
        "division_type": "xã",
        "short_codename": "ea_hmlay",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Krông Jing",
        "code": "xa_krong_jing",
        "division_type": "xã",
        "short_codename": "krong_jing",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Ea M' Doal",
        "code": "xa_ea_m_doal",
        "division_type": "xã",
        "short_codename": "ea_m_doal",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Ea Riêng",
        "code": "xa_ea_rieng",
        "division_type": "xã",
        "short_codename": "ea_rieng",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Cư M'ta",
        "code": "xa_cu_mta",
        "division_type": "xã",
        "short_codename": "cu_mta",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Cư K Róa",
        "code": "xa_cu_k_roa",
        "division_type": "xã",
        "short_codename": "cu_k_roa",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Krông Á",
        "code": "xa_krong_a",
        "division_type": "xã",
        "short_codename": "krong_a",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Cư San",
        "code": "xa_cu_san",
        "division_type": "xã",
        "short_codename": "cu_san",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Xã Ea Trang",
        "code": "xa_ea_trang",
        "division_type": "xã",
        "short_codename": "ea_trang",
        "parentCode": "huyen_mdrak"
    },
    {
        "name": "Thị trấn Krông Kmar",
        "code": "thi_tran_krong_kmar",
        "division_type": "thị trấn",
        "short_codename": "krong_kmar",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Dang Kang",
        "code": "xa_dang_kang",
        "division_type": "xã",
        "short_codename": "dang_kang",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Cư KTy",
        "code": "xa_cu_kty",
        "division_type": "xã",
        "short_codename": "cu_kty",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Hòa Thành",
        "code": "xa_hoa_thanh",
        "division_type": "xã",
        "short_codename": "hoa_thanh",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Hòa Tân",
        "code": "xa_hoa_tan",
        "division_type": "xã",
        "short_codename": "hoa_tan",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Hòa Phong",
        "code": "xa_hoa_phong",
        "division_type": "xã",
        "short_codename": "hoa_phong",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Hòa Lễ",
        "code": "xa_hoa_le",
        "division_type": "xã",
        "short_codename": "hoa_le",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Yang Reh",
        "code": "xa_yang_reh",
        "division_type": "xã",
        "short_codename": "yang_reh",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Ea Trul",
        "code": "xa_ea_trul",
        "division_type": "xã",
        "short_codename": "ea_trul",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Khuê Ngọc Điền",
        "code": "xa_khue_ngoc_dien",
        "division_type": "xã",
        "short_codename": "khue_ngoc_dien",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Cư Pui",
        "code": "xa_cu_pui",
        "division_type": "xã",
        "short_codename": "cu_pui",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Hòa Sơn",
        "code": "xa_hoa_son",
        "division_type": "xã",
        "short_codename": "hoa_son",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Cư Drăm",
        "code": "xa_cu_dram",
        "division_type": "xã",
        "short_codename": "cu_dram",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Xã Yang Mao",
        "code": "xa_yang_mao",
        "division_type": "xã",
        "short_codename": "yang_mao",
        "parentCode": "huyen_krong_bong"
    },
    {
        "name": "Thị trấn Phước An",
        "code": "thi_tran_phuoc_an",
        "division_type": "thị trấn",
        "short_codename": "phuoc_an",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã KRông Búk",
        "code": "xa_krong_buk",
        "division_type": "xã",
        "short_codename": "krong_buk",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Ea Kly",
        "code": "xa_ea_kly",
        "division_type": "xã",
        "short_codename": "ea_kly",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Ea Kênh",
        "code": "xa_ea_kenh",
        "division_type": "xã",
        "short_codename": "ea_kenh",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Ea Phê",
        "code": "xa_ea_phe",
        "division_type": "xã",
        "short_codename": "ea_phe",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Ea KNuec",
        "code": "xa_ea_knuec",
        "division_type": "xã",
        "short_codename": "ea_knuec",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Ea Yông",
        "code": "xa_ea_yong",
        "division_type": "xã",
        "short_codename": "ea_yong",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Hòa An",
        "code": "xa_hoa_an",
        "division_type": "xã",
        "short_codename": "hoa_an",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Ea Kuăng",
        "code": "xa_ea_kuang",
        "division_type": "xã",
        "short_codename": "ea_kuang",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Hòa Đông",
        "code": "xa_hoa_dong",
        "division_type": "xã",
        "short_codename": "hoa_dong",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Ea Hiu",
        "code": "xa_ea_hiu",
        "division_type": "xã",
        "short_codename": "ea_hiu",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Hòa Tiến",
        "code": "xa_hoa_tien",
        "division_type": "xã",
        "short_codename": "hoa_tien",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Vụ Bổn",
        "code": "xa_vu_bon",
        "division_type": "xã",
        "short_codename": "vu_bon",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Ea Uy",
        "code": "xa_ea_uy",
        "division_type": "xã",
        "short_codename": "ea_uy",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Xã Ea Yiêng",
        "code": "xa_ea_yieng",
        "division_type": "xã",
        "short_codename": "ea_yieng",
        "parentCode": "huyen_krong_pac"
    },
    {
        "name": "Thị trấn Buôn Trấp",
        "code": "thi_tran_buon_trap",
        "division_type": "thị trấn",
        "short_codename": "buon_trap",
        "parentCode": "huyen_krong_a_na"
    },
    {
        "name": "Xã Dray Sáp",
        "code": "xa_dray_sap",
        "division_type": "xã",
        "short_codename": "dray_sap",
        "parentCode": "huyen_krong_a_na"
    },
    {
        "name": "Xã Ea Na",
        "code": "xa_ea_na",
        "division_type": "xã",
        "short_codename": "ea_na",
        "parentCode": "huyen_krong_a_na"
    },
    {
        "name": "Xã Ea Bông",
        "code": "xa_ea_bong",
        "division_type": "xã",
        "short_codename": "ea_bong",
        "parentCode": "huyen_krong_a_na"
    },
    {
        "name": "Xã Băng A Drênh",
        "code": "xa_bang_a_drenh",
        "division_type": "xã",
        "short_codename": "bang_a_drenh",
        "parentCode": "huyen_krong_a_na"
    },
    {
        "name": "Xã Dur KMăl",
        "code": "xa_dur_kmal",
        "division_type": "xã",
        "short_codename": "dur_kmal",
        "parentCode": "huyen_krong_a_na"
    },
    {
        "name": "Xã Bình Hòa",
        "code": "xa_binh_hoa",
        "division_type": "xã",
        "short_codename": "binh_hoa",
        "parentCode": "huyen_krong_a_na"
    },
    {
        "name": "Xã Quảng Điền",
        "code": "xa_quang_dien",
        "division_type": "xã",
        "short_codename": "quang_dien",
        "parentCode": "huyen_krong_a_na"
    },
    {
        "name": "Thị trấn Liên Sơn",
        "code": "thi_tran_lien_son",
        "division_type": "thị trấn",
        "short_codename": "lien_son",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Yang Tao",
        "code": "xa_yang_tao",
        "division_type": "xã",
        "short_codename": "yang_tao",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Bông Krang",
        "code": "xa_bong_krang",
        "division_type": "xã",
        "short_codename": "bong_krang",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Đắk Liêng",
        "code": "xa_dak_lieng",
        "division_type": "xã",
        "short_codename": "dak_lieng",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Buôn Triết",
        "code": "xa_buon_triet",
        "division_type": "xã",
        "short_codename": "buon_triet",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Buôn Tría",
        "code": "xa_buon_tria",
        "division_type": "xã",
        "short_codename": "buon_tria",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Đắk Phơi",
        "code": "xa_dak_phoi",
        "division_type": "xã",
        "short_codename": "dak_phoi",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Đắk Nuê",
        "code": "xa_dak_nue",
        "division_type": "xã",
        "short_codename": "dak_nue",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Krông Nô",
        "code": "xa_krong_no",
        "division_type": "xã",
        "short_codename": "krong_no",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Nam Ka",
        "code": "xa_nam_ka",
        "division_type": "xã",
        "short_codename": "nam_ka",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Ea R'Bin",
        "code": "xa_ea_rbin",
        "division_type": "xã",
        "short_codename": "ea_rbin",
        "parentCode": "huyen_lak"
    },
    {
        "name": "Xã Ea Ning",
        "code": "xa_ea_ning",
        "division_type": "xã",
        "short_codename": "ea_ning",
        "parentCode": "huyen_cu_kuin"
    },
    {
        "name": "Xã Cư Ê Wi",
        "code": "xa_cu_e_wi",
        "division_type": "xã",
        "short_codename": "cu_e_wi",
        "parentCode": "huyen_cu_kuin"
    },
    {
        "name": "Xã Ea Ktur",
        "code": "xa_ea_ktur",
        "division_type": "xã",
        "short_codename": "ea_ktur",
        "parentCode": "huyen_cu_kuin"
    },
    {
        "name": "Xã Ea Tiêu",
        "code": "xa_ea_tieu",
        "division_type": "xã",
        "short_codename": "ea_tieu",
        "parentCode": "huyen_cu_kuin"
    },
    {
        "name": "Xã Ea BHốk",
        "code": "xa_ea_bhok",
        "division_type": "xã",
        "short_codename": "ea_bhok",
        "parentCode": "huyen_cu_kuin"
    },
    {
        "name": "Xã Ea Hu",
        "code": "xa_ea_hu",
        "division_type": "xã",
        "short_codename": "ea_hu",
        "parentCode": "huyen_cu_kuin"
    },
    {
        "name": "Xã Dray Bhăng",
        "code": "xa_dray_bhang",
        "division_type": "xã",
        "short_codename": "dray_bhang",
        "parentCode": "huyen_cu_kuin"
    },
    {
        "name": "Xã Hòa Hiệp",
        "code": "xa_hoa_hiep",
        "division_type": "xã",
        "short_codename": "hoa_hiep",
        "parentCode": "huyen_cu_kuin"
    },
    {
        "name": "Phường Nghĩa Đức",
        "code": "phuong_nghia_duc",
        "division_type": "phường",
        "short_codename": "nghia_duc",
        "parentCode": "thanh_pho_gia_nghia"
    },
    {
        "name": "Phường Nghĩa Thành",
        "code": "phuong_nghia_thanh",
        "division_type": "phường",
        "short_codename": "nghia_thanh",
        "parentCode": "thanh_pho_gia_nghia"
    },
    {
        "name": "Phường Nghĩa Phú",
        "code": "phuong_nghia_phu",
        "division_type": "phường",
        "short_codename": "nghia_phu",
        "parentCode": "thanh_pho_gia_nghia"
    },
    {
        "name": "Phường Nghĩa Tân",
        "code": "phuong_nghia_tan",
        "division_type": "phường",
        "short_codename": "nghia_tan",
        "parentCode": "thanh_pho_gia_nghia"
    },
    {
        "name": "Phường Nghĩa Trung",
        "code": "phuong_nghia_trung",
        "division_type": "phường",
        "short_codename": "nghia_trung",
        "parentCode": "thanh_pho_gia_nghia"
    },
    {
        "name": "Xã Đăk R'Moan",
        "code": "xa_dak_rmoan",
        "division_type": "xã",
        "short_codename": "dak_rmoan",
        "parentCode": "thanh_pho_gia_nghia"
    },
    {
        "name": "Phường Quảng Thành",
        "code": "phuong_quang_thanh",
        "division_type": "phường",
        "short_codename": "quang_thanh",
        "parentCode": "thanh_pho_gia_nghia"
    },
    {
        "name": "Xã Đắk Nia",
        "code": "xa_dak_nia",
        "division_type": "xã",
        "short_codename": "dak_nia",
        "parentCode": "thanh_pho_gia_nghia"
    },
    {
        "name": "Xã Quảng Sơn",
        "code": "xa_quang_son",
        "division_type": "xã",
        "short_codename": "quang_son",
        "parentCode": "huyen_dak_glong"
    },
    {
        "name": "Xã Quảng Hoà",
        "code": "xa_quang_hoa",
        "division_type": "xã",
        "short_codename": "quang_hoa",
        "parentCode": "huyen_dak_glong"
    },
    {
        "name": "Xã Đắk Ha",
        "code": "xa_dak_ha",
        "division_type": "xã",
        "short_codename": "dak_ha",
        "parentCode": "huyen_dak_glong"
    },
    {
        "name": "Xã Đắk R'Măng",
        "code": "xa_dak_rmang",
        "division_type": "xã",
        "short_codename": "dak_rmang",
        "parentCode": "huyen_dak_glong"
    },
    {
        "name": "Xã Quảng Khê",
        "code": "xa_quang_khe",
        "division_type": "xã",
        "short_codename": "quang_khe",
        "parentCode": "huyen_dak_glong"
    },
    {
        "name": "Xã Đắk Plao",
        "code": "xa_dak_plao",
        "division_type": "xã",
        "short_codename": "dak_plao",
        "parentCode": "huyen_dak_glong"
    },
    {
        "name": "Xã Đắk Som",
        "code": "xa_dak_som",
        "division_type": "xã",
        "short_codename": "dak_som",
        "parentCode": "huyen_dak_glong"
    },
    {
        "name": "Thị trấn Ea T'Ling",
        "code": "thi_tran_ea_tling",
        "division_type": "thị trấn",
        "short_codename": "ea_tling",
        "parentCode": "huyen_cu_jut"
    },
    {
        "name": "Xã Đắk Wil",
        "code": "xa_dak_wil",
        "division_type": "xã",
        "short_codename": "dak_wil",
        "parentCode": "huyen_cu_jut"
    },
    {
        "name": "Xã Ea Pô",
        "code": "xa_ea_po",
        "division_type": "xã",
        "short_codename": "ea_po",
        "parentCode": "huyen_cu_jut"
    },
    {
        "name": "Xã Nam Dong",
        "code": "xa_nam_dong",
        "division_type": "xã",
        "short_codename": "nam_dong",
        "parentCode": "huyen_cu_jut"
    },
    {
        "name": "Xã Đắk DRông",
        "code": "xa_dak_drong",
        "division_type": "xã",
        "short_codename": "dak_drong",
        "parentCode": "huyen_cu_jut"
    },
    {
        "name": "Xã Tâm Thắng",
        "code": "xa_tam_thang",
        "division_type": "xã",
        "short_codename": "tam_thang",
        "parentCode": "huyen_cu_jut"
    },
    {
        "name": "Xã Cư Knia",
        "code": "xa_cu_knia",
        "division_type": "xã",
        "short_codename": "cu_knia",
        "parentCode": "huyen_cu_jut"
    },
    {
        "name": "Xã Trúc Sơn",
        "code": "xa_truc_son",
        "division_type": "xã",
        "short_codename": "truc_son",
        "parentCode": "huyen_cu_jut"
    },
    {
        "name": "Thị trấn Đắk Mil",
        "code": "thi_tran_dak_mil",
        "division_type": "thị trấn",
        "short_codename": "dak_mil",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Xã Đắk Lao",
        "code": "xa_dak_lao",
        "division_type": "xã",
        "short_codename": "dak_lao",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Xã Đắk R'La",
        "code": "xa_dak_rla",
        "division_type": "xã",
        "short_codename": "dak_rla",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Xã Đắk Gằn",
        "code": "xa_dak_gan",
        "division_type": "xã",
        "short_codename": "dak_gan",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Xã Đức Mạnh",
        "code": "xa_duc_manh",
        "division_type": "xã",
        "short_codename": "duc_manh",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Xã Đắk N'Drót",
        "code": "xa_dak_ndrot",
        "division_type": "xã",
        "short_codename": "dak_ndrot",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Xã Long Sơn",
        "code": "xa_long_son",
        "division_type": "xã",
        "short_codename": "long_son",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Xã Đắk Sắk",
        "code": "xa_dak_sak",
        "division_type": "xã",
        "short_codename": "dak_sak",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Xã Thuận An",
        "code": "xa_thuan_an",
        "division_type": "xã",
        "short_codename": "thuan_an",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Xã Đức Minh",
        "code": "xa_duc_minh",
        "division_type": "xã",
        "short_codename": "duc_minh",
        "parentCode": "huyen_dak_mil"
    },
    {
        "name": "Thị trấn Đắk Mâm",
        "code": "thi_tran_dak_mam",
        "division_type": "thị trấn",
        "short_codename": "dak_mam",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Đắk Sôr",
        "code": "xa_dak_sor",
        "division_type": "xã",
        "short_codename": "dak_sor",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Nam Xuân",
        "code": "xa_nam_xuan",
        "division_type": "xã",
        "short_codename": "nam_xuan",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Buôn Choah",
        "code": "xa_buon_choah",
        "division_type": "xã",
        "short_codename": "buon_choah",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Nam Đà",
        "code": "xa_nam_da",
        "division_type": "xã",
        "short_codename": "nam_da",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Đắk Drô",
        "code": "xa_dak_dro",
        "division_type": "xã",
        "short_codename": "dak_dro",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Nâm Nung",
        "code": "xa_nam_nung",
        "division_type": "xã",
        "short_codename": "nam_nung",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Đức Xuyên",
        "code": "xa_duc_xuyen",
        "division_type": "xã",
        "short_codename": "duc_xuyen",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Đắk Nang",
        "code": "xa_dak_nang",
        "division_type": "xã",
        "short_codename": "dak_nang",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Quảng Phú",
        "code": "xa_quang_phu",
        "division_type": "xã",
        "short_codename": "quang_phu",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Xã Nâm N'Đir",
        "code": "xa_nam_ndir",
        "division_type": "xã",
        "short_codename": "nam_ndir",
        "parentCode": "huyen_krong_no"
    },
    {
        "name": "Thị trấn Đức An",
        "code": "thi_tran_duc_an",
        "division_type": "thị trấn",
        "short_codename": "duc_an",
        "parentCode": "huyen_dak_song"
    },
    {
        "name": "Xã Đắk Môl",
        "code": "xa_dak_mol",
        "division_type": "xã",
        "short_codename": "dak_mol",
        "parentCode": "huyen_dak_song"
    },
    {
        "name": "Xã Đắk Hòa",
        "code": "xa_dak_hoa",
        "division_type": "xã",
        "short_codename": "dak_hoa",
        "parentCode": "huyen_dak_song"
    },
    {
        "name": "Xã Nam Bình",
        "code": "xa_nam_binh",
        "division_type": "xã",
        "short_codename": "nam_binh",
        "parentCode": "huyen_dak_song"
    },
    {
        "name": "Xã Thuận Hà",
        "code": "xa_thuan_ha",
        "division_type": "xã",
        "short_codename": "thuan_ha",
        "parentCode": "huyen_dak_song"
    },
    {
        "name": "Xã Thuận Hạnh",
        "code": "xa_thuan_hanh",
        "division_type": "xã",
        "short_codename": "thuan_hanh",
        "parentCode": "huyen_dak_song"
    },
    {
        "name": "Xã Đắk N'Dung",
        "code": "xa_dak_ndung",
        "division_type": "xã",
        "short_codename": "dak_ndung",
        "parentCode": "huyen_dak_song"
    },
    {
        "name": "Xã Nâm N'Jang",
        "code": "xa_nam_njang",
        "division_type": "xã",
        "short_codename": "nam_njang",
        "parentCode": "huyen_dak_song"
    },
    {
        "name": "Xã Trường Xuân",
        "code": "xa_truong_xuan",
        "division_type": "xã",
        "short_codename": "truong_xuan",
        "parentCode": "huyen_dak_song"
    },
    {
        "name": "Thị trấn Kiến Đức",
        "code": "thi_tran_kien_duc",
        "division_type": "thị trấn",
        "short_codename": "kien_duc",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Quảng Tín",
        "code": "xa_quang_tin",
        "division_type": "xã",
        "short_codename": "quang_tin",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Đắk Wer",
        "code": "xa_dak_wer",
        "division_type": "xã",
        "short_codename": "dak_wer",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Nhân Cơ",
        "code": "xa_nhan_co",
        "division_type": "xã",
        "short_codename": "nhan_co",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Kiến Thành",
        "code": "xa_kien_thanh",
        "division_type": "xã",
        "short_codename": "kien_thanh",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Nghĩa Thắng",
        "code": "xa_nghia_thang",
        "division_type": "xã",
        "short_codename": "nghia_thang",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Đạo Nghĩa",
        "code": "xa_dao_nghia",
        "division_type": "xã",
        "short_codename": "dao_nghia",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Đắk Sin",
        "code": "xa_dak_sin",
        "division_type": "xã",
        "short_codename": "dak_sin",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Hưng Bình",
        "code": "xa_hung_binh",
        "division_type": "xã",
        "short_codename": "hung_binh",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Đắk Ru",
        "code": "xa_dak_ru",
        "division_type": "xã",
        "short_codename": "dak_ru",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Nhân Đạo",
        "code": "xa_nhan_dao",
        "division_type": "xã",
        "short_codename": "nhan_dao",
        "parentCode": "huyen_dak_rlap"
    },
    {
        "name": "Xã Quảng Trực",
        "code": "xa_quang_truc",
        "division_type": "xã",
        "short_codename": "quang_truc",
        "parentCode": "huyen_tuy_duc"
    },
    {
        "name": "Xã Đắk Búk So",
        "code": "xa_dak_buk_so",
        "division_type": "xã",
        "short_codename": "dak_buk_so",
        "parentCode": "huyen_tuy_duc"
    },
    {
        "name": "Xã Quảng Tâm",
        "code": "xa_quang_tam",
        "division_type": "xã",
        "short_codename": "quang_tam",
        "parentCode": "huyen_tuy_duc"
    },
    {
        "name": "Xã Đắk R'Tíh",
        "code": "xa_dak_rtih",
        "division_type": "xã",
        "short_codename": "dak_rtih",
        "parentCode": "huyen_tuy_duc"
    },
    {
        "name": "Xã Đắk Ngo",
        "code": "xa_dak_ngo",
        "division_type": "xã",
        "short_codename": "dak_ngo",
        "parentCode": "huyen_tuy_duc"
    },
    {
        "name": "Xã Quảng Tân",
        "code": "xa_quang_tan",
        "division_type": "xã",
        "short_codename": "quang_tan",
        "parentCode": "huyen_tuy_duc"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 9",
        "code": "phuong_9",
        "division_type": "phường",
        "short_codename": "phuong_9",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Xã Xuân Thọ",
        "code": "xa_xuan_tho",
        "division_type": "xã",
        "short_codename": "xuan_tho",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Xã Tà Nung",
        "code": "xa_ta_nung",
        "division_type": "xã",
        "short_codename": "ta_nung",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Xã Trạm Hành",
        "code": "xa_tram_hanh",
        "division_type": "xã",
        "short_codename": "tram_hanh",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Xã Xuân Trường",
        "code": "xa_xuan_truong",
        "division_type": "xã",
        "short_codename": "xuan_truong",
        "parentCode": "thanh_pho_da_lat"
    },
    {
        "name": "Phường Lộc Phát",
        "code": "phuong_loc_phat",
        "division_type": "phường",
        "short_codename": "loc_phat",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Phường Lộc Tiến",
        "code": "phuong_loc_tien",
        "division_type": "phường",
        "short_codename": "loc_tien",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Phường B'lao",
        "code": "phuong_blao",
        "division_type": "phường",
        "short_codename": "blao",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Phường Lộc Sơn",
        "code": "phuong_loc_son",
        "division_type": "phường",
        "short_codename": "loc_son",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Xã Đạm Bri",
        "code": "xa_dam_bri",
        "division_type": "xã",
        "short_codename": "dam_bri",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Xã Lộc Thanh",
        "code": "xa_loc_thanh",
        "division_type": "xã",
        "short_codename": "loc_thanh",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Xã Lộc Nga",
        "code": "xa_loc_nga",
        "division_type": "xã",
        "short_codename": "loc_nga",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Xã Lộc Châu",
        "code": "xa_loc_chau",
        "division_type": "xã",
        "short_codename": "loc_chau",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Xã Đại Lào",
        "code": "xa_dai_lao",
        "division_type": "xã",
        "short_codename": "dai_lao",
        "parentCode": "thanh_pho_bao_loc"
    },
    {
        "name": "Xã Đạ Tông",
        "code": "xa_da_tong",
        "division_type": "xã",
        "short_codename": "da_tong",
        "parentCode": "huyen_dam_rong"
    },
    {
        "name": "Xã Đạ Long",
        "code": "xa_da_long",
        "division_type": "xã",
        "short_codename": "da_long",
        "parentCode": "huyen_dam_rong"
    },
    {
        "name": "Xã Đạ M' Rong",
        "code": "xa_da_m_rong",
        "division_type": "xã",
        "short_codename": "da_m_rong",
        "parentCode": "huyen_dam_rong"
    },
    {
        "name": "Xã Liêng Srônh",
        "code": "xa_lieng_sronh",
        "division_type": "xã",
        "short_codename": "lieng_sronh",
        "parentCode": "huyen_dam_rong"
    },
    {
        "name": "Xã Đạ Rsal",
        "code": "xa_da_rsal",
        "division_type": "xã",
        "short_codename": "da_rsal",
        "parentCode": "huyen_dam_rong"
    },
    {
        "name": "Xã Rô Men",
        "code": "xa_ro_men",
        "division_type": "xã",
        "short_codename": "ro_men",
        "parentCode": "huyen_dam_rong"
    },
    {
        "name": "Xã Phi Liêng",
        "code": "xa_phi_lieng",
        "division_type": "xã",
        "short_codename": "phi_lieng",
        "parentCode": "huyen_dam_rong"
    },
    {
        "name": "Xã Đạ K' Nàng",
        "code": "xa_da_k_nang",
        "division_type": "xã",
        "short_codename": "da_k_nang",
        "parentCode": "huyen_dam_rong"
    },
    {
        "name": "Thị trấn Lạc Dương",
        "code": "thi_tran_lac_duong",
        "division_type": "thị trấn",
        "short_codename": "lac_duong",
        "parentCode": "huyen_lac_duong"
    },
    {
        "name": "Xã Đạ Chais",
        "code": "xa_da_chais",
        "division_type": "xã",
        "short_codename": "da_chais",
        "parentCode": "huyen_lac_duong"
    },
    {
        "name": "Xã Đạ Nhim",
        "code": "xa_da_nhim",
        "division_type": "xã",
        "short_codename": "da_nhim",
        "parentCode": "huyen_lac_duong"
    },
    {
        "name": "Xã Đưng KNớ",
        "code": "xa_dung_kno",
        "division_type": "xã",
        "short_codename": "dung_kno",
        "parentCode": "huyen_lac_duong"
    },
    {
        "name": "Xã Lát",
        "code": "xa_lat",
        "division_type": "xã",
        "short_codename": "lat",
        "parentCode": "huyen_lac_duong"
    },
    {
        "name": "Xã Đạ Sar",
        "code": "xa_da_sar",
        "division_type": "xã",
        "short_codename": "da_sar",
        "parentCode": "huyen_lac_duong"
    },
    {
        "name": "Thị trấn Nam Ban",
        "code": "thi_tran_nam_ban",
        "division_type": "thị trấn",
        "short_codename": "nam_ban",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Thị trấn Đinh Văn",
        "code": "thi_tran_dinh_van",
        "division_type": "thị trấn",
        "short_codename": "dinh_van",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Phi Tô",
        "code": "xa_phi_to",
        "division_type": "xã",
        "short_codename": "phi_to",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Mê Linh",
        "code": "xa_me_linh",
        "division_type": "xã",
        "short_codename": "me_linh",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Đạ Đờn",
        "code": "xa_da_don",
        "division_type": "xã",
        "short_codename": "da_don",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Phúc Thọ",
        "code": "xa_phuc_tho",
        "division_type": "xã",
        "short_codename": "phuc_tho",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Đông Thanh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Gia Lâm",
        "code": "xa_gia_lam",
        "division_type": "xã",
        "short_codename": "gia_lam",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Tân Thanh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Tân Văn",
        "code": "xa_tan_van",
        "division_type": "xã",
        "short_codename": "tan_van",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Hoài Đức",
        "code": "xa_hoai_duc",
        "division_type": "xã",
        "short_codename": "hoai_duc",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Tân Hà",
        "code": "xa_tan_ha",
        "division_type": "xã",
        "short_codename": "tan_ha",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Liên Hà",
        "code": "xa_lien_ha",
        "division_type": "xã",
        "short_codename": "lien_ha",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Đan Phượng",
        "code": "xa_dan_phuong",
        "division_type": "xã",
        "short_codename": "dan_phuong",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Xã Nam Hà",
        "code": "xa_nam_ha",
        "division_type": "xã",
        "short_codename": "nam_ha",
        "parentCode": "huyen_lam_ha"
    },
    {
        "name": "Thị trấn D'Ran",
        "code": "thi_tran_dran",
        "division_type": "thị trấn",
        "short_codename": "dran",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Thị trấn Thạnh Mỹ",
        "code": "thi_tran_thanh_my",
        "division_type": "thị trấn",
        "short_codename": "thanh_my",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Xã Lạc Xuân",
        "code": "xa_lac_xuan",
        "division_type": "xã",
        "short_codename": "lac_xuan",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Xã Đạ Ròn",
        "code": "xa_da_ron",
        "division_type": "xã",
        "short_codename": "da_ron",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Xã Lạc Lâm",
        "code": "xa_lac_lam",
        "division_type": "xã",
        "short_codename": "lac_lam",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Xã Ka Đô",
        "code": "xa_ka_do",
        "division_type": "xã",
        "short_codename": "ka_do",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Xã Quảng Lập",
        "code": "xa_quang_lap",
        "division_type": "xã",
        "short_codename": "quang_lap",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Xã Ka Đơn",
        "code": "xa_ka_don",
        "division_type": "xã",
        "short_codename": "ka_don",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Xã Tu Tra",
        "code": "xa_tu_tra",
        "division_type": "xã",
        "short_codename": "tu_tra",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Xã Pró",
        "code": "xa_pro",
        "division_type": "xã",
        "short_codename": "pro",
        "parentCode": "huyen_don_duong"
    },
    {
        "name": "Thị trấn Liên Nghĩa",
        "code": "thi_tran_lien_nghia",
        "division_type": "thị trấn",
        "short_codename": "lien_nghia",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Hiệp An",
        "code": "xa_hiep_an",
        "division_type": "xã",
        "short_codename": "hiep_an",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Liên Hiệp",
        "code": "xa_lien_hiep",
        "division_type": "xã",
        "short_codename": "lien_hiep",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Hiệp Thạnh",
        "code": "xa_hiep_thanh",
        "division_type": "xã",
        "short_codename": "hiep_thanh",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Bình Thạnh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã N'Thol Hạ",
        "code": "xa_nthol_ha",
        "division_type": "xã",
        "short_codename": "nthol_ha",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Tân Hội",
        "code": "xa_tan_hoi",
        "division_type": "xã",
        "short_codename": "tan_hoi",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Phú Hội",
        "code": "xa_phu_hoi",
        "division_type": "xã",
        "short_codename": "phu_hoi",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Ninh Gia",
        "code": "xa_ninh_gia",
        "division_type": "xã",
        "short_codename": "ninh_gia",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Tà Năng",
        "code": "xa_ta_nang",
        "division_type": "xã",
        "short_codename": "ta_nang",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Đa Quyn",
        "code": "xa_da_quyn",
        "division_type": "xã",
        "short_codename": "da_quyn",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Tà Hine",
        "code": "xa_ta_hine",
        "division_type": "xã",
        "short_codename": "ta_hine",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Đà Loan",
        "code": "xa_da_loan",
        "division_type": "xã",
        "short_codename": "da_loan",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Xã Ninh Loan",
        "code": "xa_ninh_loan",
        "division_type": "xã",
        "short_codename": "ninh_loan",
        "parentCode": "huyen_duc_trong"
    },
    {
        "name": "Thị trấn Di Linh",
        "code": "thi_tran_di_linh",
        "division_type": "thị trấn",
        "short_codename": "di_linh",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Đinh Trang Thượng",
        "code": "xa_dinh_trang_thuong",
        "division_type": "xã",
        "short_codename": "dinh_trang_thuong",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Tân Thượng",
        "code": "xa_tan_thuong",
        "division_type": "xã",
        "short_codename": "tan_thuong",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Tân Lâm",
        "code": "xa_tan_lam",
        "division_type": "xã",
        "short_codename": "tan_lam",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Tân Châu",
        "code": "xa_tan_chau",
        "division_type": "xã",
        "short_codename": "tan_chau",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Tân Nghĩa",
        "code": "xa_tan_nghia",
        "division_type": "xã",
        "short_codename": "tan_nghia",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Gia Hiệp",
        "code": "xa_gia_hiep",
        "division_type": "xã",
        "short_codename": "gia_hiep",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Đinh Lạc",
        "code": "xa_dinh_lac",
        "division_type": "xã",
        "short_codename": "dinh_lac",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Tam Bố",
        "code": "xa_tam_bo",
        "division_type": "xã",
        "short_codename": "tam_bo",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Đinh Trang Hòa",
        "code": "xa_dinh_trang_hoa",
        "division_type": "xã",
        "short_codename": "dinh_trang_hoa",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Liên Đầm",
        "code": "xa_lien_dam",
        "division_type": "xã",
        "short_codename": "lien_dam",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Gung Ré",
        "code": "xa_gung_re",
        "division_type": "xã",
        "short_codename": "gung_re",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Bảo Thuận",
        "code": "xa_bao_thuan",
        "division_type": "xã",
        "short_codename": "bao_thuan",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Hòa Ninh",
        "code": "xa_hoa_ninh",
        "division_type": "xã",
        "short_codename": "hoa_ninh",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Hòa Trung",
        "code": "xa_hoa_trung",
        "division_type": "xã",
        "short_codename": "hoa_trung",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Hòa Nam",
        "code": "xa_hoa_nam",
        "division_type": "xã",
        "short_codename": "hoa_nam",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Hòa Bắc",
        "code": "xa_hoa_bac",
        "division_type": "xã",
        "short_codename": "hoa_bac",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Sơn Điền",
        "code": "xa_son_dien",
        "division_type": "xã",
        "short_codename": "son_dien",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Xã Gia Bắc",
        "code": "xa_gia_bac",
        "division_type": "xã",
        "short_codename": "gia_bac",
        "parentCode": "huyen_di_linh"
    },
    {
        "name": "Thị trấn Lộc Thắng",
        "code": "thi_tran_loc_thang",
        "division_type": "thị trấn",
        "short_codename": "loc_thang",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Bảo",
        "code": "xa_loc_bao",
        "division_type": "xã",
        "short_codename": "loc_bao",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Lâm",
        "code": "xa_loc_lam",
        "division_type": "xã",
        "short_codename": "loc_lam",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Phú",
        "code": "xa_loc_phu",
        "division_type": "xã",
        "short_codename": "loc_phu",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Bắc",
        "code": "xa_loc_bac",
        "division_type": "xã",
        "short_codename": "loc_bac",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã B' Lá",
        "code": "xa_b_la",
        "division_type": "xã",
        "short_codename": "b_la",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Ngãi",
        "code": "xa_loc_ngai",
        "division_type": "xã",
        "short_codename": "loc_ngai",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Quảng",
        "code": "xa_loc_quang",
        "division_type": "xã",
        "short_codename": "loc_quang",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Tân",
        "code": "xa_loc_tan",
        "division_type": "xã",
        "short_codename": "loc_tan",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Đức",
        "code": "xa_loc_duc",
        "division_type": "xã",
        "short_codename": "loc_duc",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc An",
        "code": "xa_loc_an",
        "division_type": "xã",
        "short_codename": "loc_an",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Tân Lạc",
        "code": "xa_tan_lac",
        "division_type": "xã",
        "short_codename": "tan_lac",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Thành",
        "code": "xa_loc_thanh",
        "division_type": "xã",
        "short_codename": "loc_thanh",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Xã Lộc Nam",
        "code": "xa_loc_nam",
        "division_type": "xã",
        "short_codename": "loc_nam",
        "parentCode": "huyen_bao_lam"
    },
    {
        "name": "Thị trấn Đạ M'ri",
        "code": "thi_tran_da_mri",
        "division_type": "thị trấn",
        "short_codename": "da_mri",
        "parentCode": "huyen_da_huoai"
    },
    {
        "name": "Thị trấn Ma Đa Guôi",
        "code": "thi_tran_ma_da_guoi",
        "division_type": "thị trấn",
        "short_codename": "ma_da_guoi",
        "parentCode": "huyen_da_huoai"
    },
    {
        "name": "Xã Hà Lâm",
        "code": "xa_ha_lam",
        "division_type": "xã",
        "short_codename": "ha_lam",
        "parentCode": "huyen_da_huoai"
    },
    {
        "name": "Xã Đạ Tồn",
        "code": "xa_da_ton",
        "division_type": "xã",
        "short_codename": "da_ton",
        "parentCode": "huyen_da_huoai"
    },
    {
        "name": "Xã Đạ Oai",
        "code": "xa_da_oai",
        "division_type": "xã",
        "short_codename": "da_oai",
        "parentCode": "huyen_da_huoai"
    },
    {
        "name": "Xã Đạ Ploa",
        "code": "xa_da_ploa",
        "division_type": "xã",
        "short_codename": "da_ploa",
        "parentCode": "huyen_da_huoai"
    },
    {
        "name": "Xã Ma Đa Guôi",
        "code": "xa_ma_da_guoi",
        "division_type": "xã",
        "short_codename": "xa_ma_da_guoi",
        "parentCode": "huyen_da_huoai"
    },
    {
        "name": "Xã Đoàn Kết",
        "code": "xa_doan_ket",
        "division_type": "xã",
        "short_codename": "doan_ket",
        "parentCode": "huyen_da_huoai"
    },
    {
        "name": "Xã Phước Lộc",
        "code": "xa_phuoc_loc",
        "division_type": "xã",
        "short_codename": "phuoc_loc",
        "parentCode": "huyen_da_huoai"
    },
    {
        "name": "Thị trấn Đạ Tẻh",
        "code": "thi_tran_da_teh",
        "division_type": "thị trấn",
        "short_codename": "da_teh",
        "parentCode": "huyen_da_teh"
    },
    {
        "name": "Xã An Nhơn",
        "code": "xa_an_nhon",
        "division_type": "xã",
        "short_codename": "an_nhon",
        "parentCode": "huyen_da_teh"
    },
    {
        "name": "Xã Quốc Oai",
        "code": "xa_quoc_oai",
        "division_type": "xã",
        "short_codename": "quoc_oai",
        "parentCode": "huyen_da_teh"
    },
    {
        "name": "Xã Mỹ Đức",
        "code": "xa_my_duc",
        "division_type": "xã",
        "short_codename": "my_duc",
        "parentCode": "huyen_da_teh"
    },
    {
        "name": "Xã Quảng Trị",
        "code": "xa_quang_tri",
        "division_type": "xã",
        "short_codename": "quang_tri",
        "parentCode": "huyen_da_teh"
    },
    {
        "name": "Xã Đạ Lây",
        "code": "xa_da_lay",
        "division_type": "xã",
        "short_codename": "da_lay",
        "parentCode": "huyen_da_teh"
    },
    {
        "name": "Xã Triệu Hải",
        "code": "xa_trieu_hai",
        "division_type": "xã",
        "short_codename": "trieu_hai",
        "parentCode": "huyen_da_teh"
    },
    {
        "name": "Xã Đạ Kho",
        "code": "xa_da_kho",
        "division_type": "xã",
        "short_codename": "da_kho",
        "parentCode": "huyen_da_teh"
    },
    {
        "name": "Xã Đạ Pal",
        "code": "xa_da_pal",
        "division_type": "xã",
        "short_codename": "da_pal",
        "parentCode": "huyen_da_teh"
    },
    {
        "name": "Thị trấn Cát Tiên",
        "code": "thi_tran_cat_tien",
        "division_type": "thị trấn",
        "short_codename": "cat_tien",
        "parentCode": "huyen_cat_tien"
    },
    {
        "name": "Xã Tiên Hoàng",
        "code": "xa_tien_hoang",
        "division_type": "xã",
        "short_codename": "tien_hoang",
        "parentCode": "huyen_cat_tien"
    },
    {
        "name": "Xã Phước Cát 2",
        "code": "xa_phuoc_cat_2",
        "division_type": "xã",
        "short_codename": "phuoc_cat_2",
        "parentCode": "huyen_cat_tien"
    },
    {
        "name": "Xã Gia Viễn",
        "code": "xa_gia_vien",
        "division_type": "xã",
        "short_codename": "gia_vien",
        "parentCode": "huyen_cat_tien"
    },
    {
        "name": "Xã Nam Ninh",
        "code": "xa_nam_ninh",
        "division_type": "xã",
        "short_codename": "nam_ninh",
        "parentCode": "huyen_cat_tien"
    },
    {
        "name": "Thị trấn Phước Cát",
        "code": "thi_tran_phuoc_cat",
        "division_type": "thị trấn",
        "short_codename": "phuoc_cat",
        "parentCode": "huyen_cat_tien"
    },
    {
        "name": "Xã Đức Phổ",
        "code": "xa_duc_pho",
        "division_type": "xã",
        "short_codename": "duc_pho",
        "parentCode": "huyen_cat_tien"
    },
    {
        "name": "Xã Quảng Ngãi",
        "code": "xa_quang_ngai",
        "division_type": "xã",
        "short_codename": "quang_ngai",
        "parentCode": "huyen_cat_tien"
    },
    {
        "name": "Xã Đồng Nai Thượng",
        "code": "xa_dong_nai_thuong",
        "division_type": "xã",
        "short_codename": "dong_nai_thuong",
        "parentCode": "huyen_cat_tien"
    },
    {
        "name": "Phường Thác Mơ",
        "code": "phuong_thac_mo",
        "division_type": "phường",
        "short_codename": "thac_mo",
        "parentCode": "thi_xa_phuoc_long"
    },
    {
        "name": "Phường Long Thủy",
        "code": "phuong_long_thuy",
        "division_type": "phường",
        "short_codename": "long_thuy",
        "parentCode": "thi_xa_phuoc_long"
    },
    {
        "name": "Phường Phước Bình",
        "code": "phuong_phuoc_binh",
        "division_type": "phường",
        "short_codename": "phuoc_binh",
        "parentCode": "thi_xa_phuoc_long"
    },
    {
        "name": "Phường Long Phước",
        "code": "phuong_long_phuoc",
        "division_type": "phường",
        "short_codename": "long_phuoc",
        "parentCode": "thi_xa_phuoc_long"
    },
    {
        "name": "Phường Sơn Giang",
        "code": "phuong_son_giang",
        "division_type": "phường",
        "short_codename": "son_giang",
        "parentCode": "thi_xa_phuoc_long"
    },
    {
        "name": "Xã Long Giang",
        "code": "xa_long_giang",
        "division_type": "xã",
        "short_codename": "long_giang",
        "parentCode": "thi_xa_phuoc_long"
    },
    {
        "name": "Xã Phước Tín",
        "code": "xa_phuoc_tin",
        "division_type": "xã",
        "short_codename": "phuoc_tin",
        "parentCode": "thi_xa_phuoc_long"
    },
    {
        "name": "Phường Tân Phú",
        "code": "phuong_tan_phu",
        "division_type": "phường",
        "short_codename": "tan_phu",
        "parentCode": "thanh_pho_dong_xoai"
    },
    {
        "name": "Phường Tân Đồng",
        "code": "phuong_tan_dong",
        "division_type": "phường",
        "short_codename": "tan_dong",
        "parentCode": "thanh_pho_dong_xoai"
    },
    {
        "name": "Phường Tân Bình",
        "code": "phuong_tan_binh",
        "division_type": "phường",
        "short_codename": "tan_binh",
        "parentCode": "thanh_pho_dong_xoai"
    },
    {
        "name": "Phường Tân Xuân",
        "code": "phuong_tan_xuan",
        "division_type": "phường",
        "short_codename": "tan_xuan",
        "parentCode": "thanh_pho_dong_xoai"
    },
    {
        "name": "Phường Tân Thiện",
        "code": "phuong_tan_thien",
        "division_type": "phường",
        "short_codename": "tan_thien",
        "parentCode": "thanh_pho_dong_xoai"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "thanh_pho_dong_xoai"
    },
    {
        "name": "Phường Tiến Thành",
        "code": "phuong_tien_thanh",
        "division_type": "phường",
        "short_codename": "tien_thanh",
        "parentCode": "thanh_pho_dong_xoai"
    },
    {
        "name": "Xã Tiến Hưng",
        "code": "xa_tien_hung",
        "division_type": "xã",
        "short_codename": "tien_hung",
        "parentCode": "thanh_pho_dong_xoai"
    },
    {
        "name": "Phường Hưng Chiến",
        "code": "phuong_hung_chien",
        "division_type": "phường",
        "short_codename": "hung_chien",
        "parentCode": "thi_xa_binh_long"
    },
    {
        "name": "Phường An Lộc",
        "code": "phuong_an_loc",
        "division_type": "phường",
        "short_codename": "an_loc",
        "parentCode": "thi_xa_binh_long"
    },
    {
        "name": "Phường Phú Thịnh",
        "code": "phuong_phu_thinh",
        "division_type": "phường",
        "short_codename": "phu_thinh",
        "parentCode": "thi_xa_binh_long"
    },
    {
        "name": "Phường Phú Đức",
        "code": "phuong_phu_duc",
        "division_type": "phường",
        "short_codename": "phu_duc",
        "parentCode": "thi_xa_binh_long"
    },
    {
        "name": "Xã Thanh Lương",
        "code": "xa_thanh_luong",
        "division_type": "xã",
        "short_codename": "thanh_luong",
        "parentCode": "thi_xa_binh_long"
    },
    {
        "name": "Xã Thanh Phú",
        "code": "xa_thanh_phu",
        "division_type": "xã",
        "short_codename": "thanh_phu",
        "parentCode": "thi_xa_binh_long"
    },
    {
        "name": "Xã Bù Gia Mập",
        "code": "xa_bu_gia_map",
        "division_type": "xã",
        "short_codename": "bu_gia_map",
        "parentCode": "huyen_bu_gia_map"
    },
    {
        "name": "Xã Đak Ơ",
        "code": "xa_dak_o",
        "division_type": "xã",
        "short_codename": "dak_o",
        "parentCode": "huyen_bu_gia_map"
    },
    {
        "name": "Xã Đức Hạnh",
        "code": "xa_duc_hanh",
        "division_type": "xã",
        "short_codename": "duc_hanh",
        "parentCode": "huyen_bu_gia_map"
    },
    {
        "name": "Xã Phú Văn",
        "code": "xa_phu_van",
        "division_type": "xã",
        "short_codename": "phu_van",
        "parentCode": "huyen_bu_gia_map"
    },
    {
        "name": "Xã Đa Kia",
        "code": "xa_da_kia",
        "division_type": "xã",
        "short_codename": "da_kia",
        "parentCode": "huyen_bu_gia_map"
    },
    {
        "name": "Xã Phước Minh",
        "code": "xa_phuoc_minh",
        "division_type": "xã",
        "short_codename": "phuoc_minh",
        "parentCode": "huyen_bu_gia_map"
    },
    {
        "name": "Xã Bình Thắng",
        "code": "xa_binh_thang",
        "division_type": "xã",
        "short_codename": "binh_thang",
        "parentCode": "huyen_bu_gia_map"
    },
    {
        "name": "Xã Phú Nghĩa",
        "code": "xa_phu_nghia",
        "division_type": "xã",
        "short_codename": "phu_nghia",
        "parentCode": "huyen_bu_gia_map"
    },
    {
        "name": "Thị trấn Lộc Ninh",
        "code": "thi_tran_loc_ninh",
        "division_type": "thị trấn",
        "short_codename": "loc_ninh",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Hòa",
        "code": "xa_loc_hoa",
        "division_type": "xã",
        "short_codename": "loc_hoa",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc An",
        "code": "xa_loc_an",
        "division_type": "xã",
        "short_codename": "loc_an",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Tấn",
        "code": "xa_loc_tan",
        "division_type": "xã",
        "short_codename": "loc_tan",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Thạnh",
        "code": "xa_loc_thanh",
        "division_type": "xã",
        "short_codename": "xa_loc_thanh",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Hiệp",
        "code": "xa_loc_hiep",
        "division_type": "xã",
        "short_codename": "loc_hiep",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Thiện",
        "code": "xa_loc_thien",
        "division_type": "xã",
        "short_codename": "loc_thien",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Thuận",
        "code": "xa_loc_thuan",
        "division_type": "xã",
        "short_codename": "loc_thuan",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Quang",
        "code": "xa_loc_quang",
        "division_type": "xã",
        "short_codename": "loc_quang",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Phú",
        "code": "xa_loc_phu",
        "division_type": "xã",
        "short_codename": "loc_phu",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Thành",
        "code": "xa_loc_thanh",
        "division_type": "xã",
        "short_codename": "xa_loc_thanh",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Thái",
        "code": "xa_loc_thai",
        "division_type": "xã",
        "short_codename": "loc_thai",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Điền",
        "code": "xa_loc_dien",
        "division_type": "xã",
        "short_codename": "loc_dien",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Hưng",
        "code": "xa_loc_hung",
        "division_type": "xã",
        "short_codename": "loc_hung",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Thịnh",
        "code": "xa_loc_thinh",
        "division_type": "xã",
        "short_codename": "loc_thinh",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Xã Lộc Khánh",
        "code": "xa_loc_khanh",
        "division_type": "xã",
        "short_codename": "loc_khanh",
        "parentCode": "huyen_loc_ninh"
    },
    {
        "name": "Thị trấn Thanh Bình",
        "code": "thi_tran_thanh_binh",
        "division_type": "thị trấn",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_bu_dop"
    },
    {
        "name": "Xã Hưng Phước",
        "code": "xa_hung_phuoc",
        "division_type": "xã",
        "short_codename": "hung_phuoc",
        "parentCode": "huyen_bu_dop"
    },
    {
        "name": "Xã Phước Thiện",
        "code": "xa_phuoc_thien",
        "division_type": "xã",
        "short_codename": "phuoc_thien",
        "parentCode": "huyen_bu_dop"
    },
    {
        "name": "Xã Thiện Hưng",
        "code": "xa_thien_hung",
        "division_type": "xã",
        "short_codename": "thien_hung",
        "parentCode": "huyen_bu_dop"
    },
    {
        "name": "Xã Thanh Hòa",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_bu_dop"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_bu_dop"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_bu_dop"
    },
    {
        "name": "Xã Thanh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã An Khương",
        "code": "xa_an_khuong",
        "division_type": "xã",
        "short_codename": "an_khuong",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã An Phú",
        "code": "xa_an_phu",
        "division_type": "xã",
        "short_codename": "an_phu",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã Tân Lợi",
        "code": "xa_tan_loi",
        "division_type": "xã",
        "short_codename": "tan_loi",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã Minh Đức",
        "code": "xa_minh_duc",
        "division_type": "xã",
        "short_codename": "minh_duc",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã Minh Tâm",
        "code": "xa_minh_tam",
        "division_type": "xã",
        "short_codename": "minh_tam",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã Phước An",
        "code": "xa_phuoc_an",
        "division_type": "xã",
        "short_codename": "phuoc_an",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã Thanh Bình",
        "code": "xa_thanh_binh",
        "division_type": "xã",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Thị trấn Tân Khai",
        "code": "thi_tran_tan_khai",
        "division_type": "thị trấn",
        "short_codename": "tan_khai",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã Đồng Nơ",
        "code": "xa_dong_no",
        "division_type": "xã",
        "short_codename": "dong_no",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã Tân Hiệp",
        "code": "xa_tan_hiep",
        "division_type": "xã",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Xã Tân Quan",
        "code": "xa_tan_quan",
        "division_type": "xã",
        "short_codename": "tan_quan",
        "parentCode": "huyen_hon_quan"
    },
    {
        "name": "Thị trấn Tân Phú",
        "code": "thi_tran_tan_phu",
        "division_type": "thị trấn",
        "short_codename": "tan_phu",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Thuận Lợi",
        "code": "xa_thuan_loi",
        "division_type": "xã",
        "short_codename": "thuan_loi",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Đồng Tâm",
        "code": "xa_dong_tam",
        "division_type": "xã",
        "short_codename": "dong_tam",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Tân Phước",
        "code": "xa_tan_phuoc",
        "division_type": "xã",
        "short_codename": "tan_phuoc",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Tân Lợi",
        "code": "xa_tan_loi",
        "division_type": "xã",
        "short_codename": "tan_loi",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Thuận Phú",
        "code": "xa_thuan_phu",
        "division_type": "xã",
        "short_codename": "thuan_phu",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Đồng Tiến",
        "code": "xa_dong_tien",
        "division_type": "xã",
        "short_codename": "dong_tien",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_dong_phu"
    },
    {
        "name": "Thị trấn Đức Phong",
        "code": "thi_tran_duc_phong",
        "division_type": "thị trấn",
        "short_codename": "duc_phong",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Đường 10",
        "code": "xa_duong_10",
        "division_type": "xã",
        "short_codename": "duong_10",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Đak Nhau",
        "code": "xa_dak_nhau",
        "division_type": "xã",
        "short_codename": "dak_nhau",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Thọ Sơn",
        "code": "xa_tho_son",
        "division_type": "xã",
        "short_codename": "tho_son",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Bom Bo",
        "code": "xa_bom_bo",
        "division_type": "xã",
        "short_codename": "bom_bo",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Minh Hưng",
        "code": "xa_minh_hung",
        "division_type": "xã",
        "short_codename": "minh_hung",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Đoàn Kết",
        "code": "xa_doan_ket",
        "division_type": "xã",
        "short_codename": "doan_ket",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Đồng Nai",
        "code": "xa_dong_nai",
        "division_type": "xã",
        "short_codename": "dong_nai",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Đức Liễu",
        "code": "xa_duc_lieu",
        "division_type": "xã",
        "short_codename": "duc_lieu",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Thống Nhất",
        "code": "xa_thong_nhat",
        "division_type": "xã",
        "short_codename": "thong_nhat",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Nghĩa Trung",
        "code": "xa_nghia_trung",
        "division_type": "xã",
        "short_codename": "nghia_trung",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Nghĩa Bình",
        "code": "xa_nghia_binh",
        "division_type": "xã",
        "short_codename": "nghia_binh",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Đăng Hà",
        "code": "xa_dang_ha",
        "division_type": "xã",
        "short_codename": "dang_ha",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Xã Phước Sơn",
        "code": "xa_phuoc_son",
        "division_type": "xã",
        "short_codename": "phuoc_son",
        "parentCode": "huyen_bu_dang"
    },
    {
        "name": "Thị trấn Chơn Thành",
        "code": "thi_tran_chon_thanh",
        "division_type": "thị trấn",
        "short_codename": "chon_thanh",
        "parentCode": "huyen_chon_thanh"
    },
    {
        "name": "Xã Thành Tâm",
        "code": "xa_thanh_tam",
        "division_type": "xã",
        "short_codename": "thanh_tam",
        "parentCode": "huyen_chon_thanh"
    },
    {
        "name": "Xã Minh Lập",
        "code": "xa_minh_lap",
        "division_type": "xã",
        "short_codename": "minh_lap",
        "parentCode": "huyen_chon_thanh"
    },
    {
        "name": "Xã Quang Minh",
        "code": "xa_quang_minh",
        "division_type": "xã",
        "short_codename": "quang_minh",
        "parentCode": "huyen_chon_thanh"
    },
    {
        "name": "Xã Minh Hưng",
        "code": "xa_minh_hung",
        "division_type": "xã",
        "short_codename": "minh_hung",
        "parentCode": "huyen_chon_thanh"
    },
    {
        "name": "Xã Minh Long",
        "code": "xa_minh_long",
        "division_type": "xã",
        "short_codename": "minh_long",
        "parentCode": "huyen_chon_thanh"
    },
    {
        "name": "Xã Minh Thành",
        "code": "xa_minh_thanh",
        "division_type": "xã",
        "short_codename": "minh_thanh",
        "parentCode": "huyen_chon_thanh"
    },
    {
        "name": "Xã Nha Bích",
        "code": "xa_nha_bich",
        "division_type": "xã",
        "short_codename": "nha_bich",
        "parentCode": "huyen_chon_thanh"
    },
    {
        "name": "Xã Minh Thắng",
        "code": "xa_minh_thang",
        "division_type": "xã",
        "short_codename": "minh_thang",
        "parentCode": "huyen_chon_thanh"
    },
    {
        "name": "Xã Long Bình",
        "code": "xa_long_binh",
        "division_type": "xã",
        "short_codename": "long_binh",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Xã Bình Tân",
        "code": "xa_binh_tan",
        "division_type": "xã",
        "short_codename": "binh_tan",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Xã Bình Sơn",
        "code": "xa_binh_son",
        "division_type": "xã",
        "short_codename": "binh_son",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Xã Long Hưng",
        "code": "xa_long_hung",
        "division_type": "xã",
        "short_codename": "long_hung",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Xã Phước Tân",
        "code": "xa_phuoc_tan",
        "division_type": "xã",
        "short_codename": "phuoc_tan",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Xã Bù Nho",
        "code": "xa_bu_nho",
        "division_type": "xã",
        "short_codename": "bu_nho",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Xã Long Hà",
        "code": "xa_long_ha",
        "division_type": "xã",
        "short_codename": "long_ha",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Xã Long Tân",
        "code": "xa_long_tan",
        "division_type": "xã",
        "short_codename": "long_tan",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Xã Phú Trung",
        "code": "xa_phu_trung",
        "division_type": "xã",
        "short_codename": "phu_trung",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Xã Phú Riềng",
        "code": "xa_phu_rieng",
        "division_type": "xã",
        "short_codename": "phu_rieng",
        "parentCode": "huyen_phu_rieng"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Phường Hiệp Ninh",
        "code": "phuong_hiep_ninh",
        "division_type": "phường",
        "short_codename": "hiep_ninh",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Xã Thạnh Tân",
        "code": "xa_thanh_tan",
        "division_type": "xã",
        "short_codename": "thanh_tan",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Phường Ninh Sơn",
        "code": "phuong_ninh_son",
        "division_type": "phường",
        "short_codename": "ninh_son",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Phường Ninh Thạnh",
        "code": "phuong_ninh_thanh",
        "division_type": "phường",
        "short_codename": "ninh_thanh",
        "parentCode": "thanh_pho_tay_ninh"
    },
    {
        "name": "Thị trấn Tân Biên",
        "code": "thi_tran_tan_bien",
        "division_type": "thị trấn",
        "short_codename": "tan_bien",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Xã Thạnh Bắc",
        "code": "xa_thanh_bac",
        "division_type": "xã",
        "short_codename": "thanh_bac",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Xã Thạnh Bình",
        "code": "xa_thanh_binh",
        "division_type": "xã",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Xã Thạnh Tây",
        "code": "xa_thanh_tay",
        "division_type": "xã",
        "short_codename": "thanh_tay",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Xã Hòa Hiệp",
        "code": "xa_hoa_hiep",
        "division_type": "xã",
        "short_codename": "hoa_hiep",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Xã Tân Phong",
        "code": "xa_tan_phong",
        "division_type": "xã",
        "short_codename": "tan_phong",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Xã Mỏ Công",
        "code": "xa_mo_cong",
        "division_type": "xã",
        "short_codename": "mo_cong",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Xã Trà Vong",
        "code": "xa_tra_vong",
        "division_type": "xã",
        "short_codename": "tra_vong",
        "parentCode": "huyen_tan_bien"
    },
    {
        "name": "Thị trấn Tân Châu",
        "code": "thi_tran_tan_chau",
        "division_type": "thị trấn",
        "short_codename": "tan_chau",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Tân Hà",
        "code": "xa_tan_ha",
        "division_type": "xã",
        "short_codename": "tan_ha",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Tân Đông",
        "code": "xa_tan_dong",
        "division_type": "xã",
        "short_codename": "tan_dong",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Tân Hội",
        "code": "xa_tan_hoi",
        "division_type": "xã",
        "short_codename": "tan_hoi",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Suối Ngô",
        "code": "xa_suoi_ngo",
        "division_type": "xã",
        "short_codename": "suoi_ngo",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Suối Dây",
        "code": "xa_suoi_day",
        "division_type": "xã",
        "short_codename": "suoi_day",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Tân Hiệp",
        "code": "xa_tan_hiep",
        "division_type": "xã",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Thạnh Đông",
        "code": "xa_thanh_dong",
        "division_type": "xã",
        "short_codename": "thanh_dong",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_tan_chau"
    },
    {
        "name": "Thị trấn Dương Minh Châu",
        "code": "thi_tran_duong_minh_chau",
        "division_type": "thị trấn",
        "short_codename": "duong_minh_chau",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Suối Đá",
        "code": "xa_suoi_da",
        "division_type": "xã",
        "short_codename": "suoi_da",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Phan",
        "code": "xa_phan",
        "division_type": "xã",
        "short_codename": "phan",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Phước Ninh",
        "code": "xa_phuoc_ninh",
        "division_type": "xã",
        "short_codename": "phuoc_ninh",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Phước Minh",
        "code": "xa_phuoc_minh",
        "division_type": "xã",
        "short_codename": "phuoc_minh",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Bàu Năng",
        "code": "xa_bau_nang",
        "division_type": "xã",
        "short_codename": "bau_nang",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Chà Là",
        "code": "xa_cha_la",
        "division_type": "xã",
        "short_codename": "cha_la",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Cầu Khởi",
        "code": "xa_cau_khoi",
        "division_type": "xã",
        "short_codename": "cau_khoi",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Bến Củi",
        "code": "xa_ben_cui",
        "division_type": "xã",
        "short_codename": "ben_cui",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Lộc Ninh",
        "code": "xa_loc_ninh",
        "division_type": "xã",
        "short_codename": "loc_ninh",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Xã Truông Mít",
        "code": "xa_truong_mit",
        "division_type": "xã",
        "short_codename": "truong_mit",
        "parentCode": "huyen_duong_minh_chau"
    },
    {
        "name": "Thị trấn Châu Thành",
        "code": "thi_tran_chau_thanh",
        "division_type": "thị trấn",
        "short_codename": "chau_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hảo Đước",
        "code": "xa_hao_duoc",
        "division_type": "xã",
        "short_codename": "hao_duoc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phước Vinh",
        "code": "xa_phuoc_vinh",
        "division_type": "xã",
        "short_codename": "phuoc_vinh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Đồng Khởi",
        "code": "xa_dong_khoi",
        "division_type": "xã",
        "short_codename": "dong_khoi",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thái Bình",
        "code": "xa_thai_binh",
        "division_type": "xã",
        "short_codename": "thai_binh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Cơ",
        "code": "xa_an_co",
        "division_type": "xã",
        "short_codename": "an_co",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Biên Giới",
        "code": "xa_bien_gioi",
        "division_type": "xã",
        "short_codename": "bien_gioi",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hòa Thạnh",
        "code": "xa_hoa_thanh",
        "division_type": "xã",
        "short_codename": "hoa_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Trí Bình",
        "code": "xa_tri_binh",
        "division_type": "xã",
        "short_codename": "tri_binh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hòa Hội",
        "code": "xa_hoa_hoi",
        "division_type": "xã",
        "short_codename": "hoa_hoi",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thanh Điền",
        "code": "xa_thanh_dien",
        "division_type": "xã",
        "short_codename": "thanh_dien",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thành Long",
        "code": "xa_thanh_long",
        "division_type": "xã",
        "short_codename": "thanh_long",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Ninh Điền",
        "code": "xa_ninh_dien",
        "division_type": "xã",
        "short_codename": "ninh_dien",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Long Vĩnh",
        "code": "xa_long_vinh",
        "division_type": "xã",
        "short_codename": "long_vinh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Phường Long Hoa",
        "code": "phuong_long_hoa",
        "division_type": "phường",
        "short_codename": "long_hoa",
        "parentCode": "thi_xa_hoa_thanh"
    },
    {
        "name": "Phường Hiệp Tân",
        "code": "phuong_hiep_tan",
        "division_type": "phường",
        "short_codename": "hiep_tan",
        "parentCode": "thi_xa_hoa_thanh"
    },
    {
        "name": "Phường Long Thành Bắc",
        "code": "phuong_long_thanh_bac",
        "division_type": "phường",
        "short_codename": "long_thanh_bac",
        "parentCode": "thi_xa_hoa_thanh"
    },
    {
        "name": "Xã Trường Hòa",
        "code": "xa_truong_hoa",
        "division_type": "xã",
        "short_codename": "truong_hoa",
        "parentCode": "thi_xa_hoa_thanh"
    },
    {
        "name": "Xã Trường Đông",
        "code": "xa_truong_dong",
        "division_type": "xã",
        "short_codename": "truong_dong",
        "parentCode": "thi_xa_hoa_thanh"
    },
    {
        "name": "Phường Long Thành Trung",
        "code": "phuong_long_thanh_trung",
        "division_type": "phường",
        "short_codename": "long_thanh_trung",
        "parentCode": "thi_xa_hoa_thanh"
    },
    {
        "name": "Xã Trường Tây",
        "code": "xa_truong_tay",
        "division_type": "xã",
        "short_codename": "truong_tay",
        "parentCode": "thi_xa_hoa_thanh"
    },
    {
        "name": "Xã Long Thành Nam",
        "code": "xa_long_thanh_nam",
        "division_type": "xã",
        "short_codename": "long_thanh_nam",
        "parentCode": "thi_xa_hoa_thanh"
    },
    {
        "name": "Thị trấn Gò Dầu",
        "code": "thi_tran_go_dau",
        "division_type": "thị trấn",
        "short_codename": "go_dau",
        "parentCode": "huyen_go_dau"
    },
    {
        "name": "Xã Thạnh Đức",
        "code": "xa_thanh_duc",
        "division_type": "xã",
        "short_codename": "thanh_duc",
        "parentCode": "huyen_go_dau"
    },
    {
        "name": "Xã Cẩm Giang",
        "code": "xa_cam_giang",
        "division_type": "xã",
        "short_codename": "cam_giang",
        "parentCode": "huyen_go_dau"
    },
    {
        "name": "Xã Hiệp Thạnh",
        "code": "xa_hiep_thanh",
        "division_type": "xã",
        "short_codename": "hiep_thanh",
        "parentCode": "huyen_go_dau"
    },
    {
        "name": "Xã Bàu Đồn",
        "code": "xa_bau_don",
        "division_type": "xã",
        "short_codename": "bau_don",
        "parentCode": "huyen_go_dau"
    },
    {
        "name": "Xã Phước Thạnh",
        "code": "xa_phuoc_thanh",
        "division_type": "xã",
        "short_codename": "phuoc_thanh",
        "parentCode": "huyen_go_dau"
    },
    {
        "name": "Xã Phước Đông",
        "code": "xa_phuoc_dong",
        "division_type": "xã",
        "short_codename": "phuoc_dong",
        "parentCode": "huyen_go_dau"
    },
    {
        "name": "Xã Phước Trạch",
        "code": "xa_phuoc_trach",
        "division_type": "xã",
        "short_codename": "phuoc_trach",
        "parentCode": "huyen_go_dau"
    },
    {
        "name": "Xã Thanh Phước",
        "code": "xa_thanh_phuoc",
        "division_type": "xã",
        "short_codename": "thanh_phuoc",
        "parentCode": "huyen_go_dau"
    },
    {
        "name": "Thị trấn Bến Cầu",
        "code": "thi_tran_ben_cau",
        "division_type": "thị trấn",
        "short_codename": "ben_cau",
        "parentCode": "huyen_ben_cau"
    },
    {
        "name": "Xã Long Chữ",
        "code": "xa_long_chu",
        "division_type": "xã",
        "short_codename": "long_chu",
        "parentCode": "huyen_ben_cau"
    },
    {
        "name": "Xã Long Phước",
        "code": "xa_long_phuoc",
        "division_type": "xã",
        "short_codename": "long_phuoc",
        "parentCode": "huyen_ben_cau"
    },
    {
        "name": "Xã Long Giang",
        "code": "xa_long_giang",
        "division_type": "xã",
        "short_codename": "long_giang",
        "parentCode": "huyen_ben_cau"
    },
    {
        "name": "Xã Tiên Thuận",
        "code": "xa_tien_thuan",
        "division_type": "xã",
        "short_codename": "tien_thuan",
        "parentCode": "huyen_ben_cau"
    },
    {
        "name": "Xã Long Khánh",
        "code": "xa_long_khanh",
        "division_type": "xã",
        "short_codename": "long_khanh",
        "parentCode": "huyen_ben_cau"
    },
    {
        "name": "Xã Lợi Thuận",
        "code": "xa_loi_thuan",
        "division_type": "xã",
        "short_codename": "loi_thuan",
        "parentCode": "huyen_ben_cau"
    },
    {
        "name": "Xã Long Thuận",
        "code": "xa_long_thuan",
        "division_type": "xã",
        "short_codename": "long_thuan",
        "parentCode": "huyen_ben_cau"
    },
    {
        "name": "Xã An Thạnh",
        "code": "xa_an_thanh",
        "division_type": "xã",
        "short_codename": "an_thanh",
        "parentCode": "huyen_ben_cau"
    },
    {
        "name": "Phường Trảng Bàng",
        "code": "phuong_trang_bang",
        "division_type": "phường",
        "short_codename": "trang_bang",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Xã Đôn Thuận",
        "code": "xa_don_thuan",
        "division_type": "xã",
        "short_codename": "don_thuan",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Xã Hưng Thuận",
        "code": "xa_hung_thuan",
        "division_type": "xã",
        "short_codename": "hung_thuan",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Phường Lộc Hưng",
        "code": "phuong_loc_hung",
        "division_type": "phường",
        "short_codename": "loc_hung",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Phường Gia Lộc",
        "code": "phuong_gia_loc",
        "division_type": "phường",
        "short_codename": "gia_loc",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Phường Gia Bình",
        "code": "phuong_gia_binh",
        "division_type": "phường",
        "short_codename": "gia_binh",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Xã Phước Bình",
        "code": "xa_phuoc_binh",
        "division_type": "xã",
        "short_codename": "phuoc_binh",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Phường An Tịnh",
        "code": "phuong_an_tinh",
        "division_type": "phường",
        "short_codename": "an_tinh",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Phường An Hòa",
        "code": "phuong_an_hoa",
        "division_type": "phường",
        "short_codename": "an_hoa",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Xã Phước Chỉ",
        "code": "xa_phuoc_chi",
        "division_type": "xã",
        "short_codename": "phuoc_chi",
        "parentCode": "thi_xa_trang_bang"
    },
    {
        "name": "Phường Hiệp Thành",
        "code": "phuong_hiep_thanh",
        "division_type": "phường",
        "short_codename": "hiep_thanh",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Phú Lợi",
        "code": "phuong_phu_loi",
        "division_type": "phường",
        "short_codename": "phu_loi",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Phú Cường",
        "code": "phuong_phu_cuong",
        "division_type": "phường",
        "short_codename": "phu_cuong",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Phú Hòa",
        "code": "phuong_phu_hoa",
        "division_type": "phường",
        "short_codename": "phu_hoa",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Phú Thọ",
        "code": "phuong_phu_tho",
        "division_type": "phường",
        "short_codename": "phu_tho",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Chánh Nghĩa",
        "code": "phuong_chanh_nghia",
        "division_type": "phường",
        "short_codename": "chanh_nghia",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Định Hoà",
        "code": "phuong_dinh_hoa",
        "division_type": "phường",
        "short_codename": "dinh_hoa",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Hoà Phú",
        "code": "phuong_hoa_phu",
        "division_type": "phường",
        "short_codename": "hoa_phu",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Phú Mỹ",
        "code": "phuong_phu_my",
        "division_type": "phường",
        "short_codename": "phu_my",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Phú Tân",
        "code": "phuong_phu_tan",
        "division_type": "phường",
        "short_codename": "phu_tan",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Tân An",
        "code": "phuong_tan_an",
        "division_type": "phường",
        "short_codename": "tan_an",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Hiệp An",
        "code": "phuong_hiep_an",
        "division_type": "phường",
        "short_codename": "hiep_an",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Tương Bình Hiệp",
        "code": "phuong_tuong_binh_hiep",
        "division_type": "phường",
        "short_codename": "tuong_binh_hiep",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Phường Chánh Mỹ",
        "code": "phuong_chanh_my",
        "division_type": "phường",
        "short_codename": "chanh_my",
        "parentCode": "thanh_pho_thu_dau_mot"
    },
    {
        "name": "Xã Trừ Văn Thố",
        "code": "xa_tru_van_tho",
        "division_type": "xã",
        "short_codename": "tru_van_tho",
        "parentCode": "huyen_bau_bang"
    },
    {
        "name": "Xã Cây Trường II",
        "code": "xa_cay_truong_ii",
        "division_type": "xã",
        "short_codename": "cay_truong_ii",
        "parentCode": "huyen_bau_bang"
    },
    {
        "name": "Thị trấn Lai Uyên",
        "code": "thi_tran_lai_uyen",
        "division_type": "thị trấn",
        "short_codename": "lai_uyen",
        "parentCode": "huyen_bau_bang"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_bau_bang"
    },
    {
        "name": "Xã Long Nguyên",
        "code": "xa_long_nguyen",
        "division_type": "xã",
        "short_codename": "long_nguyen",
        "parentCode": "huyen_bau_bang"
    },
    {
        "name": "Xã Hưng Hòa",
        "code": "xa_hung_hoa",
        "division_type": "xã",
        "short_codename": "hung_hoa",
        "parentCode": "huyen_bau_bang"
    },
    {
        "name": "Xã Lai Hưng",
        "code": "xa_lai_hung",
        "division_type": "xã",
        "short_codename": "lai_hung",
        "parentCode": "huyen_bau_bang"
    },
    {
        "name": "Thị trấn Dầu Tiếng",
        "code": "thi_tran_dau_tieng",
        "division_type": "thị trấn",
        "short_codename": "dau_tieng",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Minh Hoà",
        "code": "xa_minh_hoa",
        "division_type": "xã",
        "short_codename": "minh_hoa",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Minh Thạnh",
        "code": "xa_minh_thanh",
        "division_type": "xã",
        "short_codename": "minh_thanh",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Minh Tân",
        "code": "xa_minh_tan",
        "division_type": "xã",
        "short_codename": "minh_tan",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Định An",
        "code": "xa_dinh_an",
        "division_type": "xã",
        "short_codename": "dinh_an",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Long Hoà",
        "code": "xa_long_hoa",
        "division_type": "xã",
        "short_codename": "long_hoa",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Định Thành",
        "code": "xa_dinh_thanh",
        "division_type": "xã",
        "short_codename": "dinh_thanh",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Định Hiệp",
        "code": "xa_dinh_hiep",
        "division_type": "xã",
        "short_codename": "dinh_hiep",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã An Lập",
        "code": "xa_an_lap",
        "division_type": "xã",
        "short_codename": "an_lap",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Long Tân",
        "code": "xa_long_tan",
        "division_type": "xã",
        "short_codename": "long_tan",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Thanh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Xã Thanh Tuyền",
        "code": "xa_thanh_tuyen",
        "division_type": "xã",
        "short_codename": "thanh_tuyen",
        "parentCode": "huyen_dau_tieng"
    },
    {
        "name": "Phường Mỹ Phước",
        "code": "phuong_my_phuoc",
        "division_type": "phường",
        "short_codename": "my_phuoc",
        "parentCode": "thi_xa_ben_cat"
    },
    {
        "name": "Phường Chánh Phú Hòa",
        "code": "phuong_chanh_phu_hoa",
        "division_type": "phường",
        "short_codename": "chanh_phu_hoa",
        "parentCode": "thi_xa_ben_cat"
    },
    {
        "name": "Xã An Điền",
        "code": "xa_an_dien",
        "division_type": "xã",
        "short_codename": "an_dien",
        "parentCode": "thi_xa_ben_cat"
    },
    {
        "name": "Xã An Tây",
        "code": "xa_an_tay",
        "division_type": "xã",
        "short_codename": "an_tay",
        "parentCode": "thi_xa_ben_cat"
    },
    {
        "name": "Phường Thới Hòa",
        "code": "phuong_thoi_hoa",
        "division_type": "phường",
        "short_codename": "thoi_hoa",
        "parentCode": "thi_xa_ben_cat"
    },
    {
        "name": "Phường Hòa Lợi",
        "code": "phuong_hoa_loi",
        "division_type": "phường",
        "short_codename": "hoa_loi",
        "parentCode": "thi_xa_ben_cat"
    },
    {
        "name": "Phường Tân Định",
        "code": "phuong_tan_dinh",
        "division_type": "phường",
        "short_codename": "tan_dinh",
        "parentCode": "thi_xa_ben_cat"
    },
    {
        "name": "Xã Phú An",
        "code": "xa_phu_an",
        "division_type": "xã",
        "short_codename": "phu_an",
        "parentCode": "thi_xa_ben_cat"
    },
    {
        "name": "Thị trấn Phước Vĩnh",
        "code": "thi_tran_phuoc_vinh",
        "division_type": "thị trấn",
        "short_codename": "phuoc_vinh",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã An Linh",
        "code": "xa_an_linh",
        "division_type": "xã",
        "short_codename": "an_linh",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã Phước Sang",
        "code": "xa_phuoc_sang",
        "division_type": "xã",
        "short_codename": "phuoc_sang",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã An Thái",
        "code": "xa_an_thai",
        "division_type": "xã",
        "short_codename": "an_thai",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã An Long",
        "code": "xa_an_long",
        "division_type": "xã",
        "short_codename": "an_long",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã Tân Hiệp",
        "code": "xa_tan_hiep",
        "division_type": "xã",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã Tam Lập",
        "code": "xa_tam_lap",
        "division_type": "xã",
        "short_codename": "tam_lap",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã Vĩnh Hoà",
        "code": "xa_vinh_hoa",
        "division_type": "xã",
        "short_codename": "vinh_hoa",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Xã Phước Hoà",
        "code": "xa_phuoc_hoa",
        "division_type": "xã",
        "short_codename": "phuoc_hoa",
        "parentCode": "huyen_phu_giao"
    },
    {
        "name": "Phường Uyên Hưng",
        "code": "phuong_uyen_hung",
        "division_type": "phường",
        "short_codename": "uyen_hung",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Tân Phước Khánh",
        "code": "phuong_tan_phuoc_khanh",
        "division_type": "phường",
        "short_codename": "tan_phuoc_khanh",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Vĩnh Tân",
        "code": "phuong_vinh_tan",
        "division_type": "phường",
        "short_codename": "vinh_tan",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Hội Nghĩa",
        "code": "phuong_hoi_nghia",
        "division_type": "phường",
        "short_codename": "hoi_nghia",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Tân Hiệp",
        "code": "phuong_tan_hiep",
        "division_type": "phường",
        "short_codename": "tan_hiep",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Khánh Bình",
        "code": "phuong_khanh_binh",
        "division_type": "phường",
        "short_codename": "khanh_binh",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Phú Chánh",
        "code": "phuong_phu_chanh",
        "division_type": "phường",
        "short_codename": "phu_chanh",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Xã Bạch Đằng",
        "code": "xa_bach_dang",
        "division_type": "xã",
        "short_codename": "bach_dang",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Tân Vĩnh Hiệp",
        "code": "phuong_tan_vinh_hiep",
        "division_type": "phường",
        "short_codename": "tan_vinh_hiep",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Thạnh Phước",
        "code": "phuong_thanh_phuoc",
        "division_type": "phường",
        "short_codename": "thanh_phuoc",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Xã Thạnh Hội",
        "code": "xa_thanh_hoi",
        "division_type": "xã",
        "short_codename": "thanh_hoi",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Thái Hòa",
        "code": "phuong_thai_hoa",
        "division_type": "phường",
        "short_codename": "thai_hoa",
        "parentCode": "thi_xa_tan_uyen"
    },
    {
        "name": "Phường Dĩ An",
        "code": "phuong_di_an",
        "division_type": "phường",
        "short_codename": "di_an",
        "parentCode": "thanh_pho_di_an"
    },
    {
        "name": "Phường Tân Bình",
        "code": "phuong_tan_binh",
        "division_type": "phường",
        "short_codename": "tan_binh",
        "parentCode": "thanh_pho_di_an"
    },
    {
        "name": "Phường Tân Đông Hiệp",
        "code": "phuong_tan_dong_hiep",
        "division_type": "phường",
        "short_codename": "tan_dong_hiep",
        "parentCode": "thanh_pho_di_an"
    },
    {
        "name": "Phường Bình An",
        "code": "phuong_binh_an",
        "division_type": "phường",
        "short_codename": "binh_an",
        "parentCode": "thanh_pho_di_an"
    },
    {
        "name": "Phường Bình Thắng",
        "code": "phuong_binh_thang",
        "division_type": "phường",
        "short_codename": "binh_thang",
        "parentCode": "thanh_pho_di_an"
    },
    {
        "name": "Phường Đông Hòa",
        "code": "phuong_dong_hoa",
        "division_type": "phường",
        "short_codename": "dong_hoa",
        "parentCode": "thanh_pho_di_an"
    },
    {
        "name": "Phường An Bình",
        "code": "phuong_an_binh",
        "division_type": "phường",
        "short_codename": "an_binh",
        "parentCode": "thanh_pho_di_an"
    },
    {
        "name": "Phường An Thạnh",
        "code": "phuong_an_thanh",
        "division_type": "phường",
        "short_codename": "an_thanh",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Phường Lái Thiêu",
        "code": "phuong_lai_thieu",
        "division_type": "phường",
        "short_codename": "lai_thieu",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Phường Bình Chuẩn",
        "code": "phuong_binh_chuan",
        "division_type": "phường",
        "short_codename": "binh_chuan",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Phường Thuận Giao",
        "code": "phuong_thuan_giao",
        "division_type": "phường",
        "short_codename": "thuan_giao",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Phường An Phú",
        "code": "phuong_an_phu",
        "division_type": "phường",
        "short_codename": "an_phu",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Phường Hưng Định",
        "code": "phuong_hung_dinh",
        "division_type": "phường",
        "short_codename": "hung_dinh",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Xã An Sơn",
        "code": "xa_an_son",
        "division_type": "xã",
        "short_codename": "an_son",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Phường Bình Nhâm",
        "code": "phuong_binh_nham",
        "division_type": "phường",
        "short_codename": "binh_nham",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Phường Bình Hòa",
        "code": "phuong_binh_hoa",
        "division_type": "phường",
        "short_codename": "binh_hoa",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Phường Vĩnh Phú",
        "code": "phuong_vinh_phu",
        "division_type": "phường",
        "short_codename": "vinh_phu",
        "parentCode": "thanh_pho_thuan_an"
    },
    {
        "name": "Xã Tân Định",
        "code": "xa_tan_dinh",
        "division_type": "xã",
        "short_codename": "tan_dinh",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Xã Bình Mỹ",
        "code": "xa_binh_my",
        "division_type": "xã",
        "short_codename": "binh_my",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Thị trấn Tân Bình",
        "code": "thi_tran_tan_binh",
        "division_type": "thị trấn",
        "short_codename": "tan_binh",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Thị trấn Tân Thành",
        "code": "thi_tran_tan_thanh",
        "division_type": "thị trấn",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Xã Đất Cuốc",
        "code": "xa_dat_cuoc",
        "division_type": "xã",
        "short_codename": "dat_cuoc",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Xã Hiếu Liêm",
        "code": "xa_hieu_liem",
        "division_type": "xã",
        "short_codename": "hieu_liem",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Xã Lạc An",
        "code": "xa_lac_an",
        "division_type": "xã",
        "short_codename": "lac_an",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Xã Thường Tân",
        "code": "xa_thuong_tan",
        "division_type": "xã",
        "short_codename": "thuong_tan",
        "parentCode": "huyen_bac_tan_uyen"
    },
    {
        "name": "Phường Trảng Dài",
        "code": "phuong_trang_dai",
        "division_type": "phường",
        "short_codename": "trang_dai",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tân Phong",
        "code": "phuong_tan_phong",
        "division_type": "phường",
        "short_codename": "tan_phong",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tân Biên",
        "code": "phuong_tan_bien",
        "division_type": "phường",
        "short_codename": "tan_bien",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Hố Nai",
        "code": "phuong_ho_nai",
        "division_type": "phường",
        "short_codename": "ho_nai",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tân Hòa",
        "code": "phuong_tan_hoa",
        "division_type": "phường",
        "short_codename": "tan_hoa",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tân Hiệp",
        "code": "phuong_tan_hiep",
        "division_type": "phường",
        "short_codename": "tan_hiep",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Bửu Long",
        "code": "phuong_buu_long",
        "division_type": "phường",
        "short_codename": "buu_long",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tân Tiến",
        "code": "phuong_tan_tien",
        "division_type": "phường",
        "short_codename": "tan_tien",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tam Hiệp",
        "code": "phuong_tam_hiep",
        "division_type": "phường",
        "short_codename": "tam_hiep",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Long Bình",
        "code": "phuong_long_binh",
        "division_type": "phường",
        "short_codename": "long_binh",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Quang Vinh",
        "code": "phuong_quang_vinh",
        "division_type": "phường",
        "short_codename": "quang_vinh",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tân Mai",
        "code": "phuong_tan_mai",
        "division_type": "phường",
        "short_codename": "tan_mai",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Thống Nhất",
        "code": "phuong_thong_nhat",
        "division_type": "phường",
        "short_codename": "thong_nhat",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Trung Dũng",
        "code": "phuong_trung_dung",
        "division_type": "phường",
        "short_codename": "trung_dung",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tam Hòa",
        "code": "phuong_tam_hoa",
        "division_type": "phường",
        "short_codename": "tam_hoa",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Hòa Bình",
        "code": "phuong_hoa_binh",
        "division_type": "phường",
        "short_codename": "hoa_binh",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Quyết Thắng",
        "code": "phuong_quyet_thang",
        "division_type": "phường",
        "short_codename": "quyet_thang",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Thanh Bình",
        "code": "phuong_thanh_binh",
        "division_type": "phường",
        "short_codename": "thanh_binh",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Bình Đa",
        "code": "phuong_binh_da",
        "division_type": "phường",
        "short_codename": "binh_da",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường An Bình",
        "code": "phuong_an_binh",
        "division_type": "phường",
        "short_codename": "an_binh",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Bửu Hòa",
        "code": "phuong_buu_hoa",
        "division_type": "phường",
        "short_codename": "buu_hoa",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Long Bình Tân",
        "code": "phuong_long_binh_tan",
        "division_type": "phường",
        "short_codename": "long_binh_tan",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tân Vạn",
        "code": "phuong_tan_van",
        "division_type": "phường",
        "short_codename": "tan_van",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tân Hạnh",
        "code": "phuong_tan_hanh",
        "division_type": "phường",
        "short_codename": "tan_hanh",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Hiệp Hòa",
        "code": "phuong_hiep_hoa",
        "division_type": "phường",
        "short_codename": "hiep_hoa",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Hóa An",
        "code": "phuong_hoa_an",
        "division_type": "phường",
        "short_codename": "hoa_an",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường An Hòa",
        "code": "phuong_an_hoa",
        "division_type": "phường",
        "short_codename": "an_hoa",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Tam Phước",
        "code": "phuong_tam_phuoc",
        "division_type": "phường",
        "short_codename": "tam_phuoc",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Phước Tân",
        "code": "phuong_phuoc_tan",
        "division_type": "phường",
        "short_codename": "phuoc_tan",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Xã Long Hưng",
        "code": "xa_long_hung",
        "division_type": "xã",
        "short_codename": "long_hung",
        "parentCode": "thanh_pho_bien_hoa"
    },
    {
        "name": "Phường Xuân Trung",
        "code": "phuong_xuan_trung",
        "division_type": "phường",
        "short_codename": "xuan_trung",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Xuân Thanh",
        "code": "phuong_xuan_thanh",
        "division_type": "phường",
        "short_codename": "xuan_thanh",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Xuân Bình",
        "code": "phuong_xuan_binh",
        "division_type": "phường",
        "short_codename": "xuan_binh",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Xuân An",
        "code": "phuong_xuan_an",
        "division_type": "phường",
        "short_codename": "xuan_an",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Xuân Hoà",
        "code": "phuong_xuan_hoa",
        "division_type": "phường",
        "short_codename": "xuan_hoa",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Phú Bình",
        "code": "phuong_phu_binh",
        "division_type": "phường",
        "short_codename": "phu_binh",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Xã Bình Lộc",
        "code": "xa_binh_loc",
        "division_type": "xã",
        "short_codename": "binh_loc",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Xã Bảo Quang",
        "code": "xa_bao_quang",
        "division_type": "xã",
        "short_codename": "bao_quang",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Suối Tre",
        "code": "phuong_suoi_tre",
        "division_type": "phường",
        "short_codename": "suoi_tre",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Bảo Vinh",
        "code": "phuong_bao_vinh",
        "division_type": "phường",
        "short_codename": "bao_vinh",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Xuân Lập",
        "code": "phuong_xuan_lap",
        "division_type": "phường",
        "short_codename": "xuan_lap",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Bàu Sen",
        "code": "phuong_bau_sen",
        "division_type": "phường",
        "short_codename": "bau_sen",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Xã Bàu Trâm",
        "code": "xa_bau_tram",
        "division_type": "xã",
        "short_codename": "bau_tram",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Phường Xuân Tân",
        "code": "phuong_xuan_tan",
        "division_type": "phường",
        "short_codename": "xuan_tan",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Xã Hàng Gòn",
        "code": "xa_hang_gon",
        "division_type": "xã",
        "short_codename": "hang_gon",
        "parentCode": "thanh_pho_long_khanh"
    },
    {
        "name": "Thị trấn Tân Phú",
        "code": "thi_tran_tan_phu",
        "division_type": "thị trấn",
        "short_codename": "tan_phu",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Dak Lua",
        "code": "xa_dak_lua",
        "division_type": "xã",
        "short_codename": "dak_lua",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Nam Cát Tiên",
        "code": "xa_nam_cat_tien",
        "division_type": "xã",
        "short_codename": "nam_cat_tien",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú An",
        "code": "xa_phu_an",
        "division_type": "xã",
        "short_codename": "phu_an",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Núi Tượng",
        "code": "xa_nui_tuong",
        "division_type": "xã",
        "short_codename": "nui_tuong",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Tà Lài",
        "code": "xa_ta_lai",
        "division_type": "xã",
        "short_codename": "ta_lai",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Lập",
        "code": "xa_phu_lap",
        "division_type": "xã",
        "short_codename": "phu_lap",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Thịnh",
        "code": "xa_phu_thinh",
        "division_type": "xã",
        "short_codename": "phu_thinh",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Trung",
        "code": "xa_phu_trung",
        "division_type": "xã",
        "short_codename": "phu_trung",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Xuân",
        "code": "xa_phu_xuan",
        "division_type": "xã",
        "short_codename": "phu_xuan",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Lộc",
        "code": "xa_phu_loc",
        "division_type": "xã",
        "short_codename": "phu_loc",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Lâm",
        "code": "xa_phu_lam",
        "division_type": "xã",
        "short_codename": "phu_lam",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Bình",
        "code": "xa_phu_binh",
        "division_type": "xã",
        "short_codename": "phu_binh",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Thanh",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "phu_thanh",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Trà Cổ",
        "code": "xa_tra_co",
        "division_type": "xã",
        "short_codename": "tra_co",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Xã Phú Điền",
        "code": "xa_phu_dien",
        "division_type": "xã",
        "short_codename": "phu_dien",
        "parentCode": "huyen_tan_phu"
    },
    {
        "name": "Thị trấn Vĩnh An",
        "code": "thi_tran_vinh_an",
        "division_type": "thị trấn",
        "short_codename": "vinh_an",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Phú Lý",
        "code": "xa_phu_ly",
        "division_type": "xã",
        "short_codename": "phu_ly",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Trị An",
        "code": "xa_tri_an",
        "division_type": "xã",
        "short_codename": "tri_an",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Tân An",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Vĩnh Tân",
        "code": "xa_vinh_tan",
        "division_type": "xã",
        "short_codename": "vinh_tan",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Bình Lợi",
        "code": "xa_binh_loi",
        "division_type": "xã",
        "short_codename": "binh_loi",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Thạnh Phú",
        "code": "xa_thanh_phu",
        "division_type": "xã",
        "short_codename": "thanh_phu",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Thiện Tân",
        "code": "xa_thien_tan",
        "division_type": "xã",
        "short_codename": "thien_tan",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Bình Hòa",
        "code": "xa_binh_hoa",
        "division_type": "xã",
        "short_codename": "binh_hoa",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Mã Đà",
        "code": "xa_ma_da",
        "division_type": "xã",
        "short_codename": "ma_da",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Xã Hiếu Liêm",
        "code": "xa_hieu_liem",
        "division_type": "xã",
        "short_codename": "hieu_liem",
        "parentCode": "huyen_vinh_cuu"
    },
    {
        "name": "Thị trấn Định Quán",
        "code": "thi_tran_dinh_quan",
        "division_type": "thị trấn",
        "short_codename": "dinh_quan",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Phú Tân",
        "code": "xa_phu_tan",
        "division_type": "xã",
        "short_codename": "phu_tan",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Phú Vinh",
        "code": "xa_phu_vinh",
        "division_type": "xã",
        "short_codename": "phu_vinh",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Phú Lợi",
        "code": "xa_phu_loi",
        "division_type": "xã",
        "short_codename": "phu_loi",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Phú Hòa",
        "code": "xa_phu_hoa",
        "division_type": "xã",
        "short_codename": "phu_hoa",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Ngọc Định",
        "code": "xa_ngoc_dinh",
        "division_type": "xã",
        "short_codename": "ngoc_dinh",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã La Ngà",
        "code": "xa_la_nga",
        "division_type": "xã",
        "short_codename": "la_nga",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Gia Canh",
        "code": "xa_gia_canh",
        "division_type": "xã",
        "short_codename": "gia_canh",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Phú Ngọc",
        "code": "xa_phu_ngoc",
        "division_type": "xã",
        "short_codename": "phu_ngoc",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Phú Cường",
        "code": "xa_phu_cuong",
        "division_type": "xã",
        "short_codename": "phu_cuong",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Túc Trưng",
        "code": "xa_tuc_trung",
        "division_type": "xã",
        "short_codename": "tuc_trung",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Phú Túc",
        "code": "xa_phu_tuc",
        "division_type": "xã",
        "short_codename": "phu_tuc",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Xã Suối Nho",
        "code": "xa_suoi_nho",
        "division_type": "xã",
        "short_codename": "suoi_nho",
        "parentCode": "huyen_dinh_quan"
    },
    {
        "name": "Thị trấn Trảng Bom",
        "code": "thi_tran_trang_bom",
        "division_type": "thị trấn",
        "short_codename": "trang_bom",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Thanh Bình",
        "code": "xa_thanh_binh",
        "division_type": "xã",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Cây Gáo",
        "code": "xa_cay_gao",
        "division_type": "xã",
        "short_codename": "cay_gao",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Bàu Hàm",
        "code": "xa_bau_ham",
        "division_type": "xã",
        "short_codename": "bau_ham",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Sông Thao",
        "code": "xa_song_thao",
        "division_type": "xã",
        "short_codename": "song_thao",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Sông Trầu",
        "code": "xa_song_trau",
        "division_type": "xã",
        "short_codename": "song_trau",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Đông Hoà",
        "code": "xa_dong_hoa",
        "division_type": "xã",
        "short_codename": "dong_hoa",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Bắc Sơn",
        "code": "xa_bac_son",
        "division_type": "xã",
        "short_codename": "bac_son",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Hố Nai 3",
        "code": "xa_ho_nai_3",
        "division_type": "xã",
        "short_codename": "ho_nai_3",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Tây Hoà",
        "code": "xa_tay_hoa",
        "division_type": "xã",
        "short_codename": "tay_hoa",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Trung Hoà",
        "code": "xa_trung_hoa",
        "division_type": "xã",
        "short_codename": "trung_hoa",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Đồi 61",
        "code": "xa_doi_61",
        "division_type": "xã",
        "short_codename": "doi_61",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Hưng Thịnh",
        "code": "xa_hung_thinh",
        "division_type": "xã",
        "short_codename": "hung_thinh",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Quảng Tiến",
        "code": "xa_quang_tien",
        "division_type": "xã",
        "short_codename": "quang_tien",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Giang Điền",
        "code": "xa_giang_dien",
        "division_type": "xã",
        "short_codename": "giang_dien",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã An Viễn",
        "code": "xa_an_vien",
        "division_type": "xã",
        "short_codename": "an_vien",
        "parentCode": "huyen_trang_bom"
    },
    {
        "name": "Xã Gia Tân 1",
        "code": "xa_gia_tan_1",
        "division_type": "xã",
        "short_codename": "gia_tan_1",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Xã Gia Tân 2",
        "code": "xa_gia_tan_2",
        "division_type": "xã",
        "short_codename": "gia_tan_2",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Xã Gia Tân 3",
        "code": "xa_gia_tan_3",
        "division_type": "xã",
        "short_codename": "gia_tan_3",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Xã Gia Kiệm",
        "code": "xa_gia_kiem",
        "division_type": "xã",
        "short_codename": "gia_kiem",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Xã Quang Trung",
        "code": "xa_quang_trung",
        "division_type": "xã",
        "short_codename": "quang_trung",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Xã Bàu Hàm 2",
        "code": "xa_bau_ham_2",
        "division_type": "xã",
        "short_codename": "bau_ham_2",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Xã Hưng Lộc",
        "code": "xa_hung_loc",
        "division_type": "xã",
        "short_codename": "hung_loc",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Xã Lộ 25",
        "code": "xa_lo_25",
        "division_type": "xã",
        "short_codename": "lo_25",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Xã Xuân Thiện",
        "code": "xa_xuan_thien",
        "division_type": "xã",
        "short_codename": "xuan_thien",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Thị trấn Dầu Giây",
        "code": "thi_tran_dau_giay",
        "division_type": "thị trấn",
        "short_codename": "dau_giay",
        "parentCode": "huyen_thong_nhat"
    },
    {
        "name": "Xã Sông Nhạn",
        "code": "xa_song_nhan",
        "division_type": "xã",
        "short_codename": "song_nhan",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Xuân Quế",
        "code": "xa_xuan_que",
        "division_type": "xã",
        "short_codename": "xuan_que",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Nhân Nghĩa",
        "code": "xa_nhan_nghia",
        "division_type": "xã",
        "short_codename": "nhan_nghia",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Xuân Đường",
        "code": "xa_xuan_duong",
        "division_type": "xã",
        "short_codename": "xuan_duong",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Long Giao",
        "code": "xa_long_giao",
        "division_type": "xã",
        "short_codename": "long_giao",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Xuân Mỹ",
        "code": "xa_xuan_my",
        "division_type": "xã",
        "short_codename": "xuan_my",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Thừa Đức",
        "code": "xa_thua_duc",
        "division_type": "xã",
        "short_codename": "thua_duc",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Bảo Bình",
        "code": "xa_bao_binh",
        "division_type": "xã",
        "short_codename": "bao_binh",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Xuân Bảo",
        "code": "xa_xuan_bao",
        "division_type": "xã",
        "short_codename": "xuan_bao",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Xuân Tây",
        "code": "xa_xuan_tay",
        "division_type": "xã",
        "short_codename": "xuan_tay",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Xuân Đông",
        "code": "xa_xuan_dong",
        "division_type": "xã",
        "short_codename": "xuan_dong",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Sông Ray",
        "code": "xa_song_ray",
        "division_type": "xã",
        "short_codename": "song_ray",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Xã Lâm San",
        "code": "xa_lam_san",
        "division_type": "xã",
        "short_codename": "lam_san",
        "parentCode": "huyen_cam_my"
    },
    {
        "name": "Thị trấn Long Thành",
        "code": "thi_tran_long_thanh",
        "division_type": "thị trấn",
        "short_codename": "long_thanh",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã An Phước",
        "code": "xa_an_phuoc",
        "division_type": "xã",
        "short_codename": "an_phuoc",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Bình An",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Long Đức",
        "code": "xa_long_duc",
        "division_type": "xã",
        "short_codename": "long_duc",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Lộc An",
        "code": "xa_loc_an",
        "division_type": "xã",
        "short_codename": "loc_an",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Bình Sơn",
        "code": "xa_binh_son",
        "division_type": "xã",
        "short_codename": "binh_son",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Tam An",
        "code": "xa_tam_an",
        "division_type": "xã",
        "short_codename": "tam_an",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Cẩm Đường",
        "code": "xa_cam_duong",
        "division_type": "xã",
        "short_codename": "cam_duong",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Long An",
        "code": "xa_long_an",
        "division_type": "xã",
        "short_codename": "long_an",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Bàu Cạn",
        "code": "xa_bau_can",
        "division_type": "xã",
        "short_codename": "bau_can",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Long Phước",
        "code": "xa_long_phuoc",
        "division_type": "xã",
        "short_codename": "long_phuoc",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Phước Bình",
        "code": "xa_phuoc_binh",
        "division_type": "xã",
        "short_codename": "phuoc_binh",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Tân Hiệp",
        "code": "xa_tan_hiep",
        "division_type": "xã",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Xã Phước Thái",
        "code": "xa_phuoc_thai",
        "division_type": "xã",
        "short_codename": "phuoc_thai",
        "parentCode": "huyen_long_thanh"
    },
    {
        "name": "Thị trấn Gia Ray",
        "code": "thi_tran_gia_ray",
        "division_type": "thị trấn",
        "short_codename": "gia_ray",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Bắc",
        "code": "xa_xuan_bac",
        "division_type": "xã",
        "short_codename": "xuan_bac",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Suối Cao",
        "code": "xa_suoi_cao",
        "division_type": "xã",
        "short_codename": "suoi_cao",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Thành",
        "code": "xa_xuan_thanh",
        "division_type": "xã",
        "short_codename": "xuan_thanh",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Thọ",
        "code": "xa_xuan_tho",
        "division_type": "xã",
        "short_codename": "xuan_tho",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Trường",
        "code": "xa_xuan_truong",
        "division_type": "xã",
        "short_codename": "xuan_truong",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Hòa",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Hưng",
        "code": "xa_xuan_hung",
        "division_type": "xã",
        "short_codename": "xuan_hung",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Tâm",
        "code": "xa_xuan_tam",
        "division_type": "xã",
        "short_codename": "xuan_tam",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Suối Cát",
        "code": "xa_suoi_cat",
        "division_type": "xã",
        "short_codename": "suoi_cat",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Hiệp",
        "code": "xa_xuan_hiep",
        "division_type": "xã",
        "short_codename": "xuan_hiep",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Phú",
        "code": "xa_xuan_phu",
        "division_type": "xã",
        "short_codename": "xuan_phu",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Xuân Định",
        "code": "xa_xuan_dinh",
        "division_type": "xã",
        "short_codename": "xuan_dinh",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Bảo Hoà",
        "code": "xa_bao_hoa",
        "division_type": "xã",
        "short_codename": "bao_hoa",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Lang Minh",
        "code": "xa_lang_minh",
        "division_type": "xã",
        "short_codename": "lang_minh",
        "parentCode": "huyen_xuan_loc"
    },
    {
        "name": "Xã Phước Thiền",
        "code": "xa_phuoc_thien",
        "division_type": "xã",
        "short_codename": "phuoc_thien",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Long Tân",
        "code": "xa_long_tan",
        "division_type": "xã",
        "short_codename": "long_tan",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Đại Phước",
        "code": "xa_dai_phuoc",
        "division_type": "xã",
        "short_codename": "dai_phuoc",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Thị trấn Hiệp Phước",
        "code": "thi_tran_hiep_phuoc",
        "division_type": "thị trấn",
        "short_codename": "hiep_phuoc",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Phú Hữu",
        "code": "xa_phu_huu",
        "division_type": "xã",
        "short_codename": "phu_huu",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Phú Hội",
        "code": "xa_phu_hoi",
        "division_type": "xã",
        "short_codename": "phu_hoi",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Phú Thạnh",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "phu_thanh",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Phú Đông",
        "code": "xa_phu_dong",
        "division_type": "xã",
        "short_codename": "phu_dong",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Long Thọ",
        "code": "xa_long_tho",
        "division_type": "xã",
        "short_codename": "long_tho",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Vĩnh Thanh",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Phước Khánh",
        "code": "xa_phuoc_khanh",
        "division_type": "xã",
        "short_codename": "phuoc_khanh",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Xã Phước An",
        "code": "xa_phuoc_an",
        "division_type": "xã",
        "short_codename": "phuoc_an",
        "parentCode": "huyen_nhon_trach"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường Thắng Tam",
        "code": "phuong_thang_tam",
        "division_type": "phường",
        "short_codename": "thang_tam",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường Thắng Nhì",
        "code": "phuong_thang_nhi",
        "division_type": "phường",
        "short_codename": "thang_nhi",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường Nguyễn An Ninh",
        "code": "phuong_nguyen_an_ninh",
        "division_type": "phường",
        "short_codename": "nguyen_an_ninh",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 9",
        "code": "phuong_9",
        "division_type": "phường",
        "short_codename": "phuong_9",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường Thắng Nhất",
        "code": "phuong_thang_nhat",
        "division_type": "phường",
        "short_codename": "thang_nhat",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường Rạch Dừa",
        "code": "phuong_rach_dua",
        "division_type": "phường",
        "short_codename": "rach_dua",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Xã Long Sơn",
        "code": "xa_long_son",
        "division_type": "xã",
        "short_codename": "long_son",
        "parentCode": "thanh_pho_vung_tau"
    },
    {
        "name": "Phường Phước Hưng",
        "code": "phuong_phuoc_hung",
        "division_type": "phường",
        "short_codename": "phuoc_hung",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Phường Phước Hiệp",
        "code": "phuong_phuoc_hiep",
        "division_type": "phường",
        "short_codename": "phuoc_hiep",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Phường Phước Nguyên",
        "code": "phuong_phuoc_nguyen",
        "division_type": "phường",
        "short_codename": "phuoc_nguyen",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Phường Long Toàn",
        "code": "phuong_long_toan",
        "division_type": "phường",
        "short_codename": "long_toan",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Phường Long Tâm",
        "code": "phuong_long_tam",
        "division_type": "phường",
        "short_codename": "long_tam",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Phường Phước Trung",
        "code": "phuong_phuoc_trung",
        "division_type": "phường",
        "short_codename": "phuoc_trung",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Phường Long Hương",
        "code": "phuong_long_huong",
        "division_type": "phường",
        "short_codename": "long_huong",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Phường Kim Dinh",
        "code": "phuong_kim_dinh",
        "division_type": "phường",
        "short_codename": "kim_dinh",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Xã Long Phước",
        "code": "xa_long_phuoc",
        "division_type": "xã",
        "short_codename": "long_phuoc",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Xã Hoà Long",
        "code": "xa_hoa_long",
        "division_type": "xã",
        "short_codename": "hoa_long",
        "parentCode": "thanh_pho_ba_ria"
    },
    {
        "name": "Xã Bàu Chinh",
        "code": "xa_bau_chinh",
        "division_type": "xã",
        "short_codename": "bau_chinh",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Thị trấn Ngãi Giao",
        "code": "thi_tran_ngai_giao",
        "division_type": "thị trấn",
        "short_codename": "ngai_giao",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Bình Ba",
        "code": "xa_binh_ba",
        "division_type": "xã",
        "short_codename": "binh_ba",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Suối Nghệ",
        "code": "xa_suoi_nghe",
        "division_type": "xã",
        "short_codename": "suoi_nghe",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Xuân Sơn",
        "code": "xa_xuan_son",
        "division_type": "xã",
        "short_codename": "xuan_son",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Sơn Bình",
        "code": "xa_son_binh",
        "division_type": "xã",
        "short_codename": "son_binh",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Bình Giã",
        "code": "xa_binh_gia",
        "division_type": "xã",
        "short_codename": "binh_gia",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Bình Trung",
        "code": "xa_binh_trung",
        "division_type": "xã",
        "short_codename": "binh_trung",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Xà Bang",
        "code": "xa_xa_bang",
        "division_type": "xã",
        "short_codename": "xa_bang",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Cù Bị",
        "code": "xa_cu_bi",
        "division_type": "xã",
        "short_codename": "cu_bi",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Láng Lớn",
        "code": "xa_lang_lon",
        "division_type": "xã",
        "short_codename": "lang_lon",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Quảng Thành",
        "code": "xa_quang_thanh",
        "division_type": "xã",
        "short_codename": "quang_thanh",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Kim Long",
        "code": "xa_kim_long",
        "division_type": "xã",
        "short_codename": "kim_long",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Suối Rao",
        "code": "xa_suoi_rao",
        "division_type": "xã",
        "short_codename": "suoi_rao",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Đá Bạc",
        "code": "xa_da_bac",
        "division_type": "xã",
        "short_codename": "da_bac",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Xã Nghĩa Thành",
        "code": "xa_nghia_thanh",
        "division_type": "xã",
        "short_codename": "nghia_thanh",
        "parentCode": "huyen_chau_duc"
    },
    {
        "name": "Thị trấn Phước Bửu",
        "code": "thi_tran_phuoc_buu",
        "division_type": "thị trấn",
        "short_codename": "phuoc_buu",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Phước Thuận",
        "code": "xa_phuoc_thuan",
        "division_type": "xã",
        "short_codename": "phuoc_thuan",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Phước Tân",
        "code": "xa_phuoc_tan",
        "division_type": "xã",
        "short_codename": "phuoc_tan",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Xuyên Mộc",
        "code": "xa_xuyen_moc",
        "division_type": "xã",
        "short_codename": "xuyen_moc",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Bông Trang",
        "code": "xa_bong_trang",
        "division_type": "xã",
        "short_codename": "bong_trang",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Tân Lâm",
        "code": "xa_tan_lam",
        "division_type": "xã",
        "short_codename": "tan_lam",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Bàu Lâm",
        "code": "xa_bau_lam",
        "division_type": "xã",
        "short_codename": "bau_lam",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Hòa Hưng",
        "code": "xa_hoa_hung",
        "division_type": "xã",
        "short_codename": "hoa_hung",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Hòa Hiệp",
        "code": "xa_hoa_hiep",
        "division_type": "xã",
        "short_codename": "hoa_hiep",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Hòa Hội",
        "code": "xa_hoa_hoi",
        "division_type": "xã",
        "short_codename": "hoa_hoi",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Bưng Riềng",
        "code": "xa_bung_rieng",
        "division_type": "xã",
        "short_codename": "bung_rieng",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Xã Bình Châu",
        "code": "xa_binh_chau",
        "division_type": "xã",
        "short_codename": "binh_chau",
        "parentCode": "huyen_xuyen_moc"
    },
    {
        "name": "Thị trấn Long Điền",
        "code": "thi_tran_long_dien",
        "division_type": "thị trấn",
        "short_codename": "long_dien",
        "parentCode": "huyen_long_dien"
    },
    {
        "name": "Thị trấn Long Hải",
        "code": "thi_tran_long_hai",
        "division_type": "thị trấn",
        "short_codename": "long_hai",
        "parentCode": "huyen_long_dien"
    },
    {
        "name": "Xã An Ngãi",
        "code": "xa_an_ngai",
        "division_type": "xã",
        "short_codename": "an_ngai",
        "parentCode": "huyen_long_dien"
    },
    {
        "name": "Xã Tam Phước",
        "code": "xa_tam_phuoc",
        "division_type": "xã",
        "short_codename": "tam_phuoc",
        "parentCode": "huyen_long_dien"
    },
    {
        "name": "Xã An Nhứt",
        "code": "xa_an_nhut",
        "division_type": "xã",
        "short_codename": "an_nhut",
        "parentCode": "huyen_long_dien"
    },
    {
        "name": "Xã Phước Tỉnh",
        "code": "xa_phuoc_tinh",
        "division_type": "xã",
        "short_codename": "phuoc_tinh",
        "parentCode": "huyen_long_dien"
    },
    {
        "name": "Xã Phước Hưng",
        "code": "xa_phuoc_hung",
        "division_type": "xã",
        "short_codename": "phuoc_hung",
        "parentCode": "huyen_long_dien"
    },
    {
        "name": "Thị trấn Đất Đỏ",
        "code": "thi_tran_dat_do",
        "division_type": "thị trấn",
        "short_codename": "dat_do",
        "parentCode": "huyen_dat_do"
    },
    {
        "name": "Xã Phước Long Thọ",
        "code": "xa_phuoc_long_tho",
        "division_type": "xã",
        "short_codename": "phuoc_long_tho",
        "parentCode": "huyen_dat_do"
    },
    {
        "name": "Xã Phước Hội",
        "code": "xa_phuoc_hoi",
        "division_type": "xã",
        "short_codename": "phuoc_hoi",
        "parentCode": "huyen_dat_do"
    },
    {
        "name": "Xã Long Mỹ",
        "code": "xa_long_my",
        "division_type": "xã",
        "short_codename": "long_my",
        "parentCode": "huyen_dat_do"
    },
    {
        "name": "Thị trấn Phước Hải",
        "code": "thi_tran_phuoc_hai",
        "division_type": "thị trấn",
        "short_codename": "phuoc_hai",
        "parentCode": "huyen_dat_do"
    },
    {
        "name": "Xã Long Tân",
        "code": "xa_long_tan",
        "division_type": "xã",
        "short_codename": "long_tan",
        "parentCode": "huyen_dat_do"
    },
    {
        "name": "Xã Láng Dài",
        "code": "xa_lang_dai",
        "division_type": "xã",
        "short_codename": "lang_dai",
        "parentCode": "huyen_dat_do"
    },
    {
        "name": "Xã Lộc An",
        "code": "xa_loc_an",
        "division_type": "xã",
        "short_codename": "loc_an",
        "parentCode": "huyen_dat_do"
    },
    {
        "name": "Phường Phú Mỹ",
        "code": "phuong_phu_my",
        "division_type": "phường",
        "short_codename": "phu_my",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Xã Tân Hoà",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Xã Tân Hải",
        "code": "xa_tan_hai",
        "division_type": "xã",
        "short_codename": "tan_hai",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Phường Phước Hoà",
        "code": "phuong_phuoc_hoa",
        "division_type": "phường",
        "short_codename": "phuoc_hoa",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Phường Tân Phước",
        "code": "phuong_tan_phuoc",
        "division_type": "phường",
        "short_codename": "tan_phuoc",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Phường Mỹ Xuân",
        "code": "phuong_my_xuan",
        "division_type": "phường",
        "short_codename": "my_xuan",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Xã Sông Xoài",
        "code": "xa_song_xoai",
        "division_type": "xã",
        "short_codename": "song_xoai",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Phường Hắc Dịch",
        "code": "phuong_hac_dich",
        "division_type": "phường",
        "short_codename": "hac_dich",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Xã Châu Pha",
        "code": "xa_chau_pha",
        "division_type": "xã",
        "short_codename": "chau_pha",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Xã Tóc Tiên",
        "code": "xa_toc_tien",
        "division_type": "xã",
        "short_codename": "toc_tien",
        "parentCode": "thi_xa_phu_my"
    },
    {
        "name": "Phường Tân Định",
        "code": "phuong_tan_dinh",
        "division_type": "phường",
        "short_codename": "tan_dinh",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Đa Kao",
        "code": "phuong_da_kao",
        "division_type": "phường",
        "short_codename": "da_kao",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Bến Nghé",
        "code": "phuong_ben_nghe",
        "division_type": "phường",
        "short_codename": "ben_nghe",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Bến Thành",
        "code": "phuong_ben_thanh",
        "division_type": "phường",
        "short_codename": "ben_thanh",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Nguyễn Thái Bình",
        "code": "phuong_nguyen_thai_binh",
        "division_type": "phường",
        "short_codename": "nguyen_thai_binh",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Phạm Ngũ Lão",
        "code": "phuong_pham_ngu_lao",
        "division_type": "phường",
        "short_codename": "pham_ngu_lao",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Cầu Ông Lãnh",
        "code": "phuong_cau_ong_lanh",
        "division_type": "phường",
        "short_codename": "cau_ong_lanh",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Cô Giang",
        "code": "phuong_co_giang",
        "division_type": "phường",
        "short_codename": "co_giang",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Nguyễn Cư Trinh",
        "code": "phuong_nguyen_cu_trinh",
        "division_type": "phường",
        "short_codename": "nguyen_cu_trinh",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Cầu Kho",
        "code": "phuong_cau_kho",
        "division_type": "phường",
        "short_codename": "cau_kho",
        "parentCode": "quan_1"
    },
    {
        "name": "Phường Thạnh Xuân",
        "code": "phuong_thanh_xuan",
        "division_type": "phường",
        "short_codename": "thanh_xuan",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường Thạnh Lộc",
        "code": "phuong_thanh_loc",
        "division_type": "phường",
        "short_codename": "thanh_loc",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường Hiệp Thành",
        "code": "phuong_hiep_thanh",
        "division_type": "phường",
        "short_codename": "hiep_thanh",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường Thới An",
        "code": "phuong_thoi_an",
        "division_type": "phường",
        "short_codename": "thoi_an",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường Tân Chánh Hiệp",
        "code": "phuong_tan_chanh_hiep",
        "division_type": "phường",
        "short_codename": "tan_chanh_hiep",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường An Phú Đông",
        "code": "phuong_an_phu_dong",
        "division_type": "phường",
        "short_codename": "an_phu_dong",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường Tân Thới Hiệp",
        "code": "phuong_tan_thoi_hiep",
        "division_type": "phường",
        "short_codename": "tan_thoi_hiep",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường Trung Mỹ Tây",
        "code": "phuong_trung_my_tay",
        "division_type": "phường",
        "short_codename": "trung_my_tay",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường Tân Hưng Thuận",
        "code": "phuong_tan_hung_thuan",
        "division_type": "phường",
        "short_codename": "tan_hung_thuan",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường Đông Hưng Thuận",
        "code": "phuong_dong_hung_thuan",
        "division_type": "phường",
        "short_codename": "dong_hung_thuan",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường Tân Thới Nhất",
        "code": "phuong_tan_thoi_nhat",
        "division_type": "phường",
        "short_codename": "tan_thoi_nhat",
        "parentCode": "quan_12"
    },
    {
        "name": "Phường 15",
        "code": "phuong_15",
        "division_type": "phường",
        "short_codename": "phuong_15",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 17",
        "code": "phuong_17",
        "division_type": "phường",
        "short_codename": "phuong_17",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 16",
        "code": "phuong_16",
        "division_type": "phường",
        "short_codename": "phuong_16",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 07",
        "code": "phuong_07",
        "division_type": "phường",
        "short_codename": "phuong_07",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 9",
        "code": "phuong_9",
        "division_type": "phường",
        "short_codename": "phuong_9",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_go_vap"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 27",
        "code": "phuong_27",
        "division_type": "phường",
        "short_codename": "phuong_27",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 26",
        "code": "phuong_26",
        "division_type": "phường",
        "short_codename": "phuong_26",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 25",
        "code": "phuong_25",
        "division_type": "phường",
        "short_codename": "phuong_25",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 07",
        "code": "phuong_07",
        "division_type": "phường",
        "short_codename": "phuong_07",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 24",
        "code": "phuong_24",
        "division_type": "phường",
        "short_codename": "phuong_24",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 06",
        "code": "phuong_06",
        "division_type": "phường",
        "short_codename": "phuong_06",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 15",
        "code": "phuong_15",
        "division_type": "phường",
        "short_codename": "phuong_15",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 17",
        "code": "phuong_17",
        "division_type": "phường",
        "short_codename": "phuong_17",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 21",
        "code": "phuong_21",
        "division_type": "phường",
        "short_codename": "phuong_21",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 22",
        "code": "phuong_22",
        "division_type": "phường",
        "short_codename": "phuong_22",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 19",
        "code": "phuong_19",
        "division_type": "phường",
        "short_codename": "phuong_19",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 28",
        "code": "phuong_28",
        "division_type": "phường",
        "short_codename": "phuong_28",
        "parentCode": "quan_binh_thanh"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 07",
        "code": "phuong_07",
        "division_type": "phường",
        "short_codename": "phuong_07",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 06",
        "code": "phuong_06",
        "division_type": "phường",
        "short_codename": "phuong_06",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 08",
        "code": "phuong_08",
        "division_type": "phường",
        "short_codename": "phuong_08",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 09",
        "code": "phuong_09",
        "division_type": "phường",
        "short_codename": "phuong_09",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường 15",
        "code": "phuong_15",
        "division_type": "phường",
        "short_codename": "phuong_15",
        "parentCode": "quan_tan_binh"
    },
    {
        "name": "Phường Tân Sơn Nhì",
        "code": "phuong_tan_son_nhi",
        "division_type": "phường",
        "short_codename": "tan_son_nhi",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Tây Thạnh",
        "code": "phuong_tay_thanh",
        "division_type": "phường",
        "short_codename": "tay_thanh",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Sơn Kỳ",
        "code": "phuong_son_ky",
        "division_type": "phường",
        "short_codename": "son_ky",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Tân Quý",
        "code": "phuong_tan_quy",
        "division_type": "phường",
        "short_codename": "tan_quy",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Tân Thành",
        "code": "phuong_tan_thanh",
        "division_type": "phường",
        "short_codename": "tan_thanh",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Phú Thọ Hòa",
        "code": "phuong_phu_tho_hoa",
        "division_type": "phường",
        "short_codename": "phu_tho_hoa",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Phú Thạnh",
        "code": "phuong_phu_thanh",
        "division_type": "phường",
        "short_codename": "phu_thanh",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Phú Trung",
        "code": "phuong_phu_trung",
        "division_type": "phường",
        "short_codename": "phu_trung",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Hòa Thạnh",
        "code": "phuong_hoa_thanh",
        "division_type": "phường",
        "short_codename": "hoa_thanh",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Hiệp Tân",
        "code": "phuong_hiep_tan",
        "division_type": "phường",
        "short_codename": "hiep_tan",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường Tân Thới Hòa",
        "code": "phuong_tan_thoi_hoa",
        "division_type": "phường",
        "short_codename": "tan_thoi_hoa",
        "parentCode": "quan_tan_phu"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 09",
        "code": "phuong_09",
        "division_type": "phường",
        "short_codename": "phuong_09",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 07",
        "code": "phuong_07",
        "division_type": "phường",
        "short_codename": "phuong_07",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 08",
        "code": "phuong_08",
        "division_type": "phường",
        "short_codename": "phuong_08",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 15",
        "code": "phuong_15",
        "division_type": "phường",
        "short_codename": "phuong_15",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 17",
        "code": "phuong_17",
        "division_type": "phường",
        "short_codename": "phuong_17",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_phu_nhuan"
    },
    {
        "name": "Phường Linh Xuân",
        "code": "phuong_linh_xuan",
        "division_type": "phường",
        "short_codename": "linh_xuan",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Bình Chiểu",
        "code": "phuong_binh_chieu",
        "division_type": "phường",
        "short_codename": "binh_chieu",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Linh Trung",
        "code": "phuong_linh_trung",
        "division_type": "phường",
        "short_codename": "linh_trung",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Tam Bình",
        "code": "phuong_tam_binh",
        "division_type": "phường",
        "short_codename": "tam_binh",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Tam Phú",
        "code": "phuong_tam_phu",
        "division_type": "phường",
        "short_codename": "tam_phu",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Hiệp Bình Phước",
        "code": "phuong_hiep_binh_phuoc",
        "division_type": "phường",
        "short_codename": "hiep_binh_phuoc",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Hiệp Bình Chánh",
        "code": "phuong_hiep_binh_chanh",
        "division_type": "phường",
        "short_codename": "hiep_binh_chanh",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Linh Chiểu",
        "code": "phuong_linh_chieu",
        "division_type": "phường",
        "short_codename": "linh_chieu",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Linh Tây",
        "code": "phuong_linh_tay",
        "division_type": "phường",
        "short_codename": "linh_tay",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Linh Đông",
        "code": "phuong_linh_dong",
        "division_type": "phường",
        "short_codename": "linh_dong",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Bình Thọ",
        "code": "phuong_binh_tho",
        "division_type": "phường",
        "short_codename": "binh_tho",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Trường Thọ",
        "code": "phuong_truong_tho",
        "division_type": "phường",
        "short_codename": "truong_tho",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Long Bình",
        "code": "phuong_long_binh",
        "division_type": "phường",
        "short_codename": "long_binh",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Long Thạnh Mỹ",
        "code": "phuong_long_thanh_my",
        "division_type": "phường",
        "short_codename": "long_thanh_my",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Tân Phú",
        "code": "phuong_tan_phu",
        "division_type": "phường",
        "short_codename": "tan_phu",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Hiệp Phú",
        "code": "phuong_hiep_phu",
        "division_type": "phường",
        "short_codename": "hiep_phu",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Tăng Nhơn Phú A",
        "code": "phuong_tang_nhon_phu_a",
        "division_type": "phường",
        "short_codename": "tang_nhon_phu_a",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Tăng Nhơn Phú B",
        "code": "phuong_tang_nhon_phu_b",
        "division_type": "phường",
        "short_codename": "tang_nhon_phu_b",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Phước Long B",
        "code": "phuong_phuoc_long_b",
        "division_type": "phường",
        "short_codename": "phuoc_long_b",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Phước Long A",
        "code": "phuong_phuoc_long_a",
        "division_type": "phường",
        "short_codename": "phuoc_long_a",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Trường Thạnh",
        "code": "phuong_truong_thanh",
        "division_type": "phường",
        "short_codename": "truong_thanh",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Long Phước",
        "code": "phuong_long_phuoc",
        "division_type": "phường",
        "short_codename": "long_phuoc",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Long Trường",
        "code": "phuong_long_truong",
        "division_type": "phường",
        "short_codename": "long_truong",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Phước Bình",
        "code": "phuong_phuoc_binh",
        "division_type": "phường",
        "short_codename": "phuoc_binh",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Phú Hữu",
        "code": "phuong_phu_huu",
        "division_type": "phường",
        "short_codename": "phu_huu",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Thảo Điền",
        "code": "phuong_thao_dien",
        "division_type": "phường",
        "short_codename": "thao_dien",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường An Phú",
        "code": "phuong_an_phu",
        "division_type": "phường",
        "short_codename": "an_phu",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường An Khánh",
        "code": "phuong_an_khanh",
        "division_type": "phường",
        "short_codename": "an_khanh",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Bình Trưng Đông",
        "code": "phuong_binh_trung_dong",
        "division_type": "phường",
        "short_codename": "binh_trung_dong",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Bình Trưng Tây",
        "code": "phuong_binh_trung_tay",
        "division_type": "phường",
        "short_codename": "binh_trung_tay",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Cát Lái",
        "code": "phuong_cat_lai",
        "division_type": "phường",
        "short_codename": "cat_lai",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Thạnh Mỹ Lợi",
        "code": "phuong_thanh_my_loi",
        "division_type": "phường",
        "short_codename": "thanh_my_loi",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường An Lợi Đông",
        "code": "phuong_an_loi_dong",
        "division_type": "phường",
        "short_codename": "an_loi_dong",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường Thủ Thiêm",
        "code": "phuong_thu_thiem",
        "division_type": "phường",
        "short_codename": "thu_thiem",
        "parentCode": "thanh_pho_thu_duc"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường Võ Thị Sáu",
        "code": "phuong_vo_thi_sau",
        "division_type": "phường",
        "short_codename": "vo_thi_sau",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 09",
        "code": "phuong_09",
        "division_type": "phường",
        "short_codename": "phuong_09",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_3"
    },
    {
        "name": "Phường 15",
        "code": "phuong_15",
        "division_type": "phường",
        "short_codename": "phuong_15",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 09",
        "code": "phuong_09",
        "division_type": "phường",
        "short_codename": "phuong_09",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 08",
        "code": "phuong_08",
        "division_type": "phường",
        "short_codename": "phuong_08",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 07",
        "code": "phuong_07",
        "division_type": "phường",
        "short_codename": "phuong_07",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 06",
        "code": "phuong_06",
        "division_type": "phường",
        "short_codename": "phuong_06",
        "parentCode": "quan_10"
    },
    {
        "name": "Phường 15",
        "code": "phuong_15",
        "division_type": "phường",
        "short_codename": "phuong_15",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 08",
        "code": "phuong_08",
        "division_type": "phường",
        "short_codename": "phuong_08",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 09",
        "code": "phuong_09",
        "division_type": "phường",
        "short_codename": "phuong_09",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 07",
        "code": "phuong_07",
        "division_type": "phường",
        "short_codename": "phuong_07",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 06",
        "code": "phuong_06",
        "division_type": "phường",
        "short_codename": "phuong_06",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 16",
        "code": "phuong_16",
        "division_type": "phường",
        "short_codename": "phuong_16",
        "parentCode": "quan_11"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 09",
        "code": "phuong_09",
        "division_type": "phường",
        "short_codename": "phuong_09",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 06",
        "code": "phuong_06",
        "division_type": "phường",
        "short_codename": "phuong_06",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 08",
        "code": "phuong_08",
        "division_type": "phường",
        "short_codename": "phuong_08",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 18",
        "code": "phuong_18",
        "division_type": "phường",
        "short_codename": "phuong_18",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 16",
        "code": "phuong_16",
        "division_type": "phường",
        "short_codename": "phuong_16",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 15",
        "code": "phuong_15",
        "division_type": "phường",
        "short_codename": "phuong_15",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_4"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 09",
        "code": "phuong_09",
        "division_type": "phường",
        "short_codename": "phuong_09",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 08",
        "code": "phuong_08",
        "division_type": "phường",
        "short_codename": "phuong_08",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 07",
        "code": "phuong_07",
        "division_type": "phường",
        "short_codename": "phuong_07",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 06",
        "code": "phuong_06",
        "division_type": "phường",
        "short_codename": "phuong_06",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_5"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 09",
        "code": "phuong_09",
        "division_type": "phường",
        "short_codename": "phuong_09",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 06",
        "code": "phuong_06",
        "division_type": "phường",
        "short_codename": "phuong_06",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 08",
        "code": "phuong_08",
        "division_type": "phường",
        "short_codename": "phuong_08",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 07",
        "code": "phuong_07",
        "division_type": "phường",
        "short_codename": "phuong_07",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_6"
    },
    {
        "name": "Phường 08",
        "code": "phuong_08",
        "division_type": "phường",
        "short_codename": "phuong_08",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 02",
        "code": "phuong_02",
        "division_type": "phường",
        "short_codename": "phuong_02",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 01",
        "code": "phuong_01",
        "division_type": "phường",
        "short_codename": "phuong_01",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 03",
        "code": "phuong_03",
        "division_type": "phường",
        "short_codename": "phuong_03",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 09",
        "code": "phuong_09",
        "division_type": "phường",
        "short_codename": "phuong_09",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 04",
        "code": "phuong_04",
        "division_type": "phường",
        "short_codename": "phuong_04",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 13",
        "code": "phuong_13",
        "division_type": "phường",
        "short_codename": "phuong_13",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 12",
        "code": "phuong_12",
        "division_type": "phường",
        "short_codename": "phuong_12",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 05",
        "code": "phuong_05",
        "division_type": "phường",
        "short_codename": "phuong_05",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 14",
        "code": "phuong_14",
        "division_type": "phường",
        "short_codename": "phuong_14",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 06",
        "code": "phuong_06",
        "division_type": "phường",
        "short_codename": "phuong_06",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 15",
        "code": "phuong_15",
        "division_type": "phường",
        "short_codename": "phuong_15",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 16",
        "code": "phuong_16",
        "division_type": "phường",
        "short_codename": "phuong_16",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường 07",
        "code": "phuong_07",
        "division_type": "phường",
        "short_codename": "phuong_07",
        "parentCode": "quan_8"
    },
    {
        "name": "Phường Bình Hưng Hòa",
        "code": "phuong_binh_hung_hoa",
        "division_type": "phường",
        "short_codename": "binh_hung_hoa",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường Bình Hưng Hoà A",
        "code": "phuong_binh_hung_hoa_a",
        "division_type": "phường",
        "short_codename": "binh_hung_hoa_a",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường Bình Hưng Hoà B",
        "code": "phuong_binh_hung_hoa_b",
        "division_type": "phường",
        "short_codename": "binh_hung_hoa_b",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường Bình Trị Đông",
        "code": "phuong_binh_tri_dong",
        "division_type": "phường",
        "short_codename": "binh_tri_dong",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường Bình Trị Đông A",
        "code": "phuong_binh_tri_dong_a",
        "division_type": "phường",
        "short_codename": "binh_tri_dong_a",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường Bình Trị Đông B",
        "code": "phuong_binh_tri_dong_b",
        "division_type": "phường",
        "short_codename": "binh_tri_dong_b",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường Tân Tạo",
        "code": "phuong_tan_tao",
        "division_type": "phường",
        "short_codename": "tan_tao",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường Tân Tạo A",
        "code": "phuong_tan_tao_a",
        "division_type": "phường",
        "short_codename": "tan_tao_a",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường An Lạc",
        "code": "phuong_an_lac",
        "division_type": "phường",
        "short_codename": "an_lac",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường An Lạc A",
        "code": "phuong_an_lac_a",
        "division_type": "phường",
        "short_codename": "an_lac_a",
        "parentCode": "quan_binh_tan"
    },
    {
        "name": "Phường Tân Thuận Đông",
        "code": "phuong_tan_thuan_dong",
        "division_type": "phường",
        "short_codename": "tan_thuan_dong",
        "parentCode": "quan_7"
    },
    {
        "name": "Phường Tân Thuận Tây",
        "code": "phuong_tan_thuan_tay",
        "division_type": "phường",
        "short_codename": "tan_thuan_tay",
        "parentCode": "quan_7"
    },
    {
        "name": "Phường Tân Kiểng",
        "code": "phuong_tan_kieng",
        "division_type": "phường",
        "short_codename": "tan_kieng",
        "parentCode": "quan_7"
    },
    {
        "name": "Phường Tân Hưng",
        "code": "phuong_tan_hung",
        "division_type": "phường",
        "short_codename": "tan_hung",
        "parentCode": "quan_7"
    },
    {
        "name": "Phường Bình Thuận",
        "code": "phuong_binh_thuan",
        "division_type": "phường",
        "short_codename": "binh_thuan",
        "parentCode": "quan_7"
    },
    {
        "name": "Phường Tân Quy",
        "code": "phuong_tan_quy",
        "division_type": "phường",
        "short_codename": "tan_quy",
        "parentCode": "quan_7"
    },
    {
        "name": "Phường Phú Thuận",
        "code": "phuong_phu_thuan",
        "division_type": "phường",
        "short_codename": "phu_thuan",
        "parentCode": "quan_7"
    },
    {
        "name": "Phường Tân Phú",
        "code": "phuong_tan_phu",
        "division_type": "phường",
        "short_codename": "tan_phu",
        "parentCode": "quan_7"
    },
    {
        "name": "Phường Tân Phong",
        "code": "phuong_tan_phong",
        "division_type": "phường",
        "short_codename": "tan_phong",
        "parentCode": "quan_7"
    },
    {
        "name": "Phường Phú Mỹ",
        "code": "phuong_phu_my",
        "division_type": "phường",
        "short_codename": "phu_my",
        "parentCode": "quan_7"
    },
    {
        "name": "Thị trấn Củ Chi",
        "code": "thi_tran_cu_chi",
        "division_type": "thị trấn",
        "short_codename": "cu_chi",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Phú Mỹ Hưng",
        "code": "xa_phu_my_hung",
        "division_type": "xã",
        "short_codename": "phu_my_hung",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã An Phú",
        "code": "xa_an_phu",
        "division_type": "xã",
        "short_codename": "an_phu",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Trung Lập Thượng",
        "code": "xa_trung_lap_thuong",
        "division_type": "xã",
        "short_codename": "trung_lap_thuong",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã An Nhơn Tây",
        "code": "xa_an_nhon_tay",
        "division_type": "xã",
        "short_codename": "an_nhon_tay",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Nhuận Đức",
        "code": "xa_nhuan_duc",
        "division_type": "xã",
        "short_codename": "nhuan_duc",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Phạm Văn Cội",
        "code": "xa_pham_van_coi",
        "division_type": "xã",
        "short_codename": "pham_van_coi",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Phú Hòa Đông",
        "code": "xa_phu_hoa_dong",
        "division_type": "xã",
        "short_codename": "phu_hoa_dong",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Trung Lập Hạ",
        "code": "xa_trung_lap_ha",
        "division_type": "xã",
        "short_codename": "trung_lap_ha",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Trung An",
        "code": "xa_trung_an",
        "division_type": "xã",
        "short_codename": "trung_an",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Phước Thạnh",
        "code": "xa_phuoc_thanh",
        "division_type": "xã",
        "short_codename": "phuoc_thanh",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Phước Hiệp",
        "code": "xa_phuoc_hiep",
        "division_type": "xã",
        "short_codename": "phuoc_hiep",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Tân An Hội",
        "code": "xa_tan_an_hoi",
        "division_type": "xã",
        "short_codename": "tan_an_hoi",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Phước Vĩnh An",
        "code": "xa_phuoc_vinh_an",
        "division_type": "xã",
        "short_codename": "phuoc_vinh_an",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Thái Mỹ",
        "code": "xa_thai_my",
        "division_type": "xã",
        "short_codename": "thai_my",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Tân Thạnh Tây",
        "code": "xa_tan_thanh_tay",
        "division_type": "xã",
        "short_codename": "tan_thanh_tay",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Hòa Phú",
        "code": "xa_hoa_phu",
        "division_type": "xã",
        "short_codename": "hoa_phu",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Tân Thạnh Đông",
        "code": "xa_tan_thanh_dong",
        "division_type": "xã",
        "short_codename": "tan_thanh_dong",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Bình Mỹ",
        "code": "xa_binh_my",
        "division_type": "xã",
        "short_codename": "binh_my",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Tân Phú Trung",
        "code": "xa_tan_phu_trung",
        "division_type": "xã",
        "short_codename": "tan_phu_trung",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Xã Tân Thông Hội",
        "code": "xa_tan_thong_hoi",
        "division_type": "xã",
        "short_codename": "tan_thong_hoi",
        "parentCode": "huyen_cu_chi"
    },
    {
        "name": "Thị trấn Hóc Môn",
        "code": "thi_tran_hoc_mon",
        "division_type": "thị trấn",
        "short_codename": "hoc_mon",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Tân Hiệp",
        "code": "xa_tan_hiep",
        "division_type": "xã",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Nhị Bình",
        "code": "xa_nhi_binh",
        "division_type": "xã",
        "short_codename": "nhi_binh",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Đông Thạnh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Tân Thới Nhì",
        "code": "xa_tan_thoi_nhi",
        "division_type": "xã",
        "short_codename": "tan_thoi_nhi",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Thới Tam Thôn",
        "code": "xa_thoi_tam_thon",
        "division_type": "xã",
        "short_codename": "thoi_tam_thon",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Xuân Thới Sơn",
        "code": "xa_xuan_thoi_son",
        "division_type": "xã",
        "short_codename": "xuan_thoi_son",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Tân Xuân",
        "code": "xa_tan_xuan",
        "division_type": "xã",
        "short_codename": "tan_xuan",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Xuân Thới Đông",
        "code": "xa_xuan_thoi_dong",
        "division_type": "xã",
        "short_codename": "xuan_thoi_dong",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Trung Chánh",
        "code": "xa_trung_chanh",
        "division_type": "xã",
        "short_codename": "trung_chanh",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Xuân Thới Thượng",
        "code": "xa_xuan_thoi_thuong",
        "division_type": "xã",
        "short_codename": "xuan_thoi_thuong",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Xã Bà Điểm",
        "code": "xa_ba_diem",
        "division_type": "xã",
        "short_codename": "ba_diem",
        "parentCode": "huyen_hoc_mon"
    },
    {
        "name": "Thị trấn Tân Túc",
        "code": "thi_tran_tan_tuc",
        "division_type": "thị trấn",
        "short_codename": "tan_tuc",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Phạm Văn Hai",
        "code": "xa_pham_van_hai",
        "division_type": "xã",
        "short_codename": "pham_van_hai",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Vĩnh Lộc A",
        "code": "xa_vinh_loc_a",
        "division_type": "xã",
        "short_codename": "vinh_loc_a",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Vĩnh Lộc B",
        "code": "xa_vinh_loc_b",
        "division_type": "xã",
        "short_codename": "vinh_loc_b",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Bình Lợi",
        "code": "xa_binh_loi",
        "division_type": "xã",
        "short_codename": "binh_loi",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Lê Minh Xuân",
        "code": "xa_le_minh_xuan",
        "division_type": "xã",
        "short_codename": "le_minh_xuan",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Tân Nhựt",
        "code": "xa_tan_nhut",
        "division_type": "xã",
        "short_codename": "tan_nhut",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Tân Kiên",
        "code": "xa_tan_kien",
        "division_type": "xã",
        "short_codename": "tan_kien",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Bình Hưng",
        "code": "xa_binh_hung",
        "division_type": "xã",
        "short_codename": "binh_hung",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Phong Phú",
        "code": "xa_phong_phu",
        "division_type": "xã",
        "short_codename": "phong_phu",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã An Phú Tây",
        "code": "xa_an_phu_tay",
        "division_type": "xã",
        "short_codename": "an_phu_tay",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Hưng Long",
        "code": "xa_hung_long",
        "division_type": "xã",
        "short_codename": "hung_long",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Đa Phước",
        "code": "xa_da_phuoc",
        "division_type": "xã",
        "short_codename": "da_phuoc",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Tân Quý Tây",
        "code": "xa_tan_quy_tay",
        "division_type": "xã",
        "short_codename": "tan_quy_tay",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Bình Chánh",
        "code": "xa_binh_chanh",
        "division_type": "xã",
        "short_codename": "binh_chanh",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Xã Quy Đức",
        "code": "xa_quy_duc",
        "division_type": "xã",
        "short_codename": "quy_duc",
        "parentCode": "huyen_binh_chanh"
    },
    {
        "name": "Thị trấn Nhà Bè",
        "code": "thi_tran_nha_be",
        "division_type": "thị trấn",
        "short_codename": "nha_be",
        "parentCode": "huyen_nha_be"
    },
    {
        "name": "Xã Phước Kiển",
        "code": "xa_phuoc_kien",
        "division_type": "xã",
        "short_codename": "phuoc_kien",
        "parentCode": "huyen_nha_be"
    },
    {
        "name": "Xã Phước Lộc",
        "code": "xa_phuoc_loc",
        "division_type": "xã",
        "short_codename": "phuoc_loc",
        "parentCode": "huyen_nha_be"
    },
    {
        "name": "Xã Nhơn Đức",
        "code": "xa_nhon_duc",
        "division_type": "xã",
        "short_codename": "nhon_duc",
        "parentCode": "huyen_nha_be"
    },
    {
        "name": "Xã Phú Xuân",
        "code": "xa_phu_xuan",
        "division_type": "xã",
        "short_codename": "phu_xuan",
        "parentCode": "huyen_nha_be"
    },
    {
        "name": "Xã Long Thới",
        "code": "xa_long_thoi",
        "division_type": "xã",
        "short_codename": "long_thoi",
        "parentCode": "huyen_nha_be"
    },
    {
        "name": "Xã Hiệp Phước",
        "code": "xa_hiep_phuoc",
        "division_type": "xã",
        "short_codename": "hiep_phuoc",
        "parentCode": "huyen_nha_be"
    },
    {
        "name": "Thị trấn Cần Thạnh",
        "code": "thi_tran_can_thanh",
        "division_type": "thị trấn",
        "short_codename": "can_thanh",
        "parentCode": "huyen_can_gio"
    },
    {
        "name": "Xã Bình Khánh",
        "code": "xa_binh_khanh",
        "division_type": "xã",
        "short_codename": "binh_khanh",
        "parentCode": "huyen_can_gio"
    },
    {
        "name": "Xã Tam Thôn Hiệp",
        "code": "xa_tam_thon_hiep",
        "division_type": "xã",
        "short_codename": "tam_thon_hiep",
        "parentCode": "huyen_can_gio"
    },
    {
        "name": "Xã An Thới Đông",
        "code": "xa_an_thoi_dong",
        "division_type": "xã",
        "short_codename": "an_thoi_dong",
        "parentCode": "huyen_can_gio"
    },
    {
        "name": "Xã Thạnh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_can_gio"
    },
    {
        "name": "Xã Long Hòa",
        "code": "xa_long_hoa",
        "division_type": "xã",
        "short_codename": "long_hoa",
        "parentCode": "huyen_can_gio"
    },
    {
        "name": "Xã Lý Nhơn",
        "code": "xa_ly_nhon",
        "division_type": "xã",
        "short_codename": "ly_nhon",
        "parentCode": "huyen_can_gio"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Phường Tân Khánh",
        "code": "phuong_tan_khanh",
        "division_type": "phường",
        "short_codename": "tan_khanh",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Xã Hướng Thọ Phú",
        "code": "xa_huong_tho_phu",
        "division_type": "xã",
        "short_codename": "huong_tho_phu",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Xã Nhơn Thạnh Trung",
        "code": "xa_nhon_thanh_trung",
        "division_type": "xã",
        "short_codename": "nhon_thanh_trung",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Xã Lợi Bình Nhơn",
        "code": "xa_loi_binh_nhon",
        "division_type": "xã",
        "short_codename": "loi_binh_nhon",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Xã Bình Tâm",
        "code": "xa_binh_tam",
        "division_type": "xã",
        "short_codename": "binh_tam",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Phường Khánh Hậu",
        "code": "phuong_khanh_hau",
        "division_type": "phường",
        "short_codename": "khanh_hau",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Xã An Vĩnh Ngãi",
        "code": "xa_an_vinh_ngai",
        "division_type": "xã",
        "short_codename": "an_vinh_ngai",
        "parentCode": "thanh_pho_tan_an"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thi_xa_kien_tuong"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thi_xa_kien_tuong"
    },
    {
        "name": "Xã Thạnh Trị",
        "code": "xa_thanh_tri",
        "division_type": "xã",
        "short_codename": "thanh_tri",
        "parentCode": "thi_xa_kien_tuong"
    },
    {
        "name": "Xã Bình Hiệp",
        "code": "xa_binh_hiep",
        "division_type": "xã",
        "short_codename": "binh_hiep",
        "parentCode": "thi_xa_kien_tuong"
    },
    {
        "name": "Xã Bình Tân",
        "code": "xa_binh_tan",
        "division_type": "xã",
        "short_codename": "binh_tan",
        "parentCode": "thi_xa_kien_tuong"
    },
    {
        "name": "Xã Tuyên Thạnh",
        "code": "xa_tuyen_thanh",
        "division_type": "xã",
        "short_codename": "tuyen_thanh",
        "parentCode": "thi_xa_kien_tuong"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thi_xa_kien_tuong"
    },
    {
        "name": "Xã Thạnh Hưng",
        "code": "xa_thanh_hung",
        "division_type": "xã",
        "short_codename": "thanh_hung",
        "parentCode": "thi_xa_kien_tuong"
    },
    {
        "name": "Thị trấn Tân Hưng",
        "code": "thi_tran_tan_hung",
        "division_type": "thị trấn",
        "short_codename": "tan_hung",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Hưng Hà",
        "code": "xa_hung_ha",
        "division_type": "xã",
        "short_codename": "hung_ha",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Hưng Điền B",
        "code": "xa_hung_dien_b",
        "division_type": "xã",
        "short_codename": "hung_dien_b",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Hưng Điền",
        "code": "xa_hung_dien",
        "division_type": "xã",
        "short_codename": "hung_dien",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Thạnh Hưng",
        "code": "xa_thanh_hung",
        "division_type": "xã",
        "short_codename": "thanh_hung",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Hưng Thạnh",
        "code": "xa_hung_thanh",
        "division_type": "xã",
        "short_codename": "hung_thanh",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Vĩnh Thạnh",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Vĩnh Châu B",
        "code": "xa_vinh_chau_b",
        "division_type": "xã",
        "short_codename": "vinh_chau_b",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Vĩnh Lợi",
        "code": "xa_vinh_loi",
        "division_type": "xã",
        "short_codename": "vinh_loi",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Vĩnh Đại",
        "code": "xa_vinh_dai",
        "division_type": "xã",
        "short_codename": "vinh_dai",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Vĩnh Châu A",
        "code": "xa_vinh_chau_a",
        "division_type": "xã",
        "short_codename": "vinh_chau_a",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Xã Vĩnh Bửu",
        "code": "xa_vinh_buu",
        "division_type": "xã",
        "short_codename": "vinh_buu",
        "parentCode": "huyen_tan_hung"
    },
    {
        "name": "Thị trấn Vĩnh Hưng",
        "code": "thi_tran_vinh_hung",
        "division_type": "thị trấn",
        "short_codename": "vinh_hung",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Hưng Điền A",
        "code": "xa_hung_dien_a",
        "division_type": "xã",
        "short_codename": "hung_dien_a",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Khánh Hưng",
        "code": "xa_khanh_hung",
        "division_type": "xã",
        "short_codename": "khanh_hung",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Thái Trị",
        "code": "xa_thai_tri",
        "division_type": "xã",
        "short_codename": "thai_tri",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Vĩnh Trị",
        "code": "xa_vinh_tri",
        "division_type": "xã",
        "short_codename": "vinh_tri",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Thái Bình Trung",
        "code": "xa_thai_binh_trung",
        "division_type": "xã",
        "short_codename": "thai_binh_trung",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Vĩnh Bình",
        "code": "xa_vinh_binh",
        "division_type": "xã",
        "short_codename": "vinh_binh",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Vĩnh Thuận",
        "code": "xa_vinh_thuan",
        "division_type": "xã",
        "short_codename": "vinh_thuan",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Tuyên Bình",
        "code": "xa_tuyen_binh",
        "division_type": "xã",
        "short_codename": "tuyen_binh",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Tuyên Bình Tây",
        "code": "xa_tuyen_binh_tay",
        "division_type": "xã",
        "short_codename": "tuyen_binh_tay",
        "parentCode": "huyen_vinh_hung"
    },
    {
        "name": "Xã Bình Hòa Tây",
        "code": "xa_binh_hoa_tay",
        "division_type": "xã",
        "short_codename": "binh_hoa_tay",
        "parentCode": "huyen_moc_hoa"
    },
    {
        "name": "Xã Bình Thạnh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_moc_hoa"
    },
    {
        "name": "Xã Bình Hòa Trung",
        "code": "xa_binh_hoa_trung",
        "division_type": "xã",
        "short_codename": "binh_hoa_trung",
        "parentCode": "huyen_moc_hoa"
    },
    {
        "name": "Xã Bình Hòa Đông",
        "code": "xa_binh_hoa_dong",
        "division_type": "xã",
        "short_codename": "binh_hoa_dong",
        "parentCode": "huyen_moc_hoa"
    },
    {
        "name": "Thị trấn Bình Phong Thạnh",
        "code": "thi_tran_binh_phong_thanh",
        "division_type": "thị trấn",
        "short_codename": "binh_phong_thanh",
        "parentCode": "huyen_moc_hoa"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_moc_hoa"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_moc_hoa"
    },
    {
        "name": "Thị trấn Tân Thạnh",
        "code": "thi_tran_tan_thanh",
        "division_type": "thị trấn",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Bắc Hòa",
        "code": "xa_bac_hoa",
        "division_type": "xã",
        "short_codename": "bac_hoa",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Hậu Thạnh Tây",
        "code": "xa_hau_thanh_tay",
        "division_type": "xã",
        "short_codename": "hau_thanh_tay",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Nhơn Hòa Lập",
        "code": "xa_nhon_hoa_lap",
        "division_type": "xã",
        "short_codename": "nhon_hoa_lap",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Hậu Thạnh Đông",
        "code": "xa_hau_thanh_dong",
        "division_type": "xã",
        "short_codename": "hau_thanh_dong",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Nhơn Hoà",
        "code": "xa_nhon_hoa",
        "division_type": "xã",
        "short_codename": "nhon_hoa",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Kiến Bình",
        "code": "xa_kien_binh",
        "division_type": "xã",
        "short_codename": "kien_binh",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "xa_tan_thanh",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Tân Ninh",
        "code": "xa_tan_ninh",
        "division_type": "xã",
        "short_codename": "tan_ninh",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Nhơn Ninh",
        "code": "xa_nhon_ninh",
        "division_type": "xã",
        "short_codename": "nhon_ninh",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_tan_thanh"
    },
    {
        "name": "Thị trấn Thạnh Hóa",
        "code": "thi_tran_thanh_hoa",
        "division_type": "thị trấn",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Tân Hiệp",
        "code": "xa_tan_hiep",
        "division_type": "xã",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Thuận Bình",
        "code": "xa_thuan_binh",
        "division_type": "xã",
        "short_codename": "thuan_binh",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Thạnh Phước",
        "code": "xa_thanh_phuoc",
        "division_type": "xã",
        "short_codename": "thanh_phuoc",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Thạnh Phú",
        "code": "xa_thanh_phu",
        "division_type": "xã",
        "short_codename": "thanh_phu",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Thuận Nghĩa Hòa",
        "code": "xa_thuan_nghia_hoa",
        "division_type": "xã",
        "short_codename": "thuan_nghia_hoa",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Thủy Đông",
        "code": "xa_thuy_dong",
        "division_type": "xã",
        "short_codename": "thuy_dong",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Thủy Tây",
        "code": "xa_thuy_tay",
        "division_type": "xã",
        "short_codename": "thuy_tay",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Tân Tây",
        "code": "xa_tan_tay",
        "division_type": "xã",
        "short_codename": "tan_tay",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Tân Đông",
        "code": "xa_tan_dong",
        "division_type": "xã",
        "short_codename": "tan_dong",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Xã Thạnh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_thanh_hoa"
    },
    {
        "name": "Thị trấn Đông Thành",
        "code": "thi_tran_dong_thanh",
        "division_type": "thị trấn",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Mỹ Quý Đông",
        "code": "xa_my_quy_dong",
        "division_type": "xã",
        "short_codename": "my_quy_dong",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Mỹ Thạnh Bắc",
        "code": "xa_my_thanh_bac",
        "division_type": "xã",
        "short_codename": "my_thanh_bac",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Mỹ Quý Tây",
        "code": "xa_my_quy_tay",
        "division_type": "xã",
        "short_codename": "my_quy_tay",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Mỹ Thạnh Tây",
        "code": "xa_my_thanh_tay",
        "division_type": "xã",
        "short_codename": "my_thanh_tay",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Mỹ Thạnh Đông",
        "code": "xa_my_thanh_dong",
        "division_type": "xã",
        "short_codename": "my_thanh_dong",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Bình Thành",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Bình Hòa Bắc",
        "code": "xa_binh_hoa_bac",
        "division_type": "xã",
        "short_codename": "binh_hoa_bac",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Bình Hòa Hưng",
        "code": "xa_binh_hoa_hung",
        "division_type": "xã",
        "short_codename": "binh_hoa_hung",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Bình Hòa Nam",
        "code": "xa_binh_hoa_nam",
        "division_type": "xã",
        "short_codename": "binh_hoa_nam",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Xã Mỹ Bình",
        "code": "xa_my_binh",
        "division_type": "xã",
        "short_codename": "my_binh",
        "parentCode": "huyen_duc_hue"
    },
    {
        "name": "Thị trấn Hậu Nghĩa",
        "code": "thi_tran_hau_nghia",
        "division_type": "thị trấn",
        "short_codename": "hau_nghia",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Thị trấn Hiệp Hòa",
        "code": "thi_tran_hiep_hoa",
        "division_type": "thị trấn",
        "short_codename": "hiep_hoa",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Thị trấn Đức Hòa",
        "code": "thi_tran_duc_hoa",
        "division_type": "thị trấn",
        "short_codename": "duc_hoa",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Lộc Giang",
        "code": "xa_loc_giang",
        "division_type": "xã",
        "short_codename": "loc_giang",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã An Ninh Đông",
        "code": "xa_an_ninh_dong",
        "division_type": "xã",
        "short_codename": "an_ninh_dong",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã An Ninh Tây",
        "code": "xa_an_ninh_tay",
        "division_type": "xã",
        "short_codename": "an_ninh_tay",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Hiệp Hòa",
        "code": "xa_hiep_hoa",
        "division_type": "xã",
        "short_codename": "xa_hiep_hoa",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Đức Lập Thượng",
        "code": "xa_duc_lap_thuong",
        "division_type": "xã",
        "short_codename": "duc_lap_thuong",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Đức Lập Hạ",
        "code": "xa_duc_lap_ha",
        "division_type": "xã",
        "short_codename": "duc_lap_ha",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Mỹ Hạnh Bắc",
        "code": "xa_my_hanh_bac",
        "division_type": "xã",
        "short_codename": "my_hanh_bac",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Đức Hòa Thượng",
        "code": "xa_duc_hoa_thuong",
        "division_type": "xã",
        "short_codename": "duc_hoa_thuong",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Hòa Khánh Tây",
        "code": "xa_hoa_khanh_tay",
        "division_type": "xã",
        "short_codename": "hoa_khanh_tay",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Hòa Khánh Đông",
        "code": "xa_hoa_khanh_dong",
        "division_type": "xã",
        "short_codename": "hoa_khanh_dong",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Mỹ Hạnh Nam",
        "code": "xa_my_hanh_nam",
        "division_type": "xã",
        "short_codename": "my_hanh_nam",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Hòa Khánh Nam",
        "code": "xa_hoa_khanh_nam",
        "division_type": "xã",
        "short_codename": "hoa_khanh_nam",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Đức Hòa Đông",
        "code": "xa_duc_hoa_dong",
        "division_type": "xã",
        "short_codename": "duc_hoa_dong",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Đức Hòa Hạ",
        "code": "xa_duc_hoa_ha",
        "division_type": "xã",
        "short_codename": "duc_hoa_ha",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Xã Hựu Thạnh",
        "code": "xa_huu_thanh",
        "division_type": "xã",
        "short_codename": "huu_thanh",
        "parentCode": "huyen_duc_hoa"
    },
    {
        "name": "Thị trấn Bến Lức",
        "code": "thi_tran_ben_luc",
        "division_type": "thị trấn",
        "short_codename": "ben_luc",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Thạnh Lợi",
        "code": "xa_thanh_loi",
        "division_type": "xã",
        "short_codename": "thanh_loi",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Lương Bình",
        "code": "xa_luong_binh",
        "division_type": "xã",
        "short_codename": "luong_binh",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Thạnh Hòa",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Lương Hòa",
        "code": "xa_luong_hoa",
        "division_type": "xã",
        "short_codename": "luong_hoa",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Tân Bửu",
        "code": "xa_tan_buu",
        "division_type": "xã",
        "short_codename": "tan_buu",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã An Thạnh",
        "code": "xa_an_thanh",
        "division_type": "xã",
        "short_codename": "an_thanh",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Bình Đức",
        "code": "xa_binh_duc",
        "division_type": "xã",
        "short_codename": "binh_duc",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Mỹ Yên",
        "code": "xa_my_yen",
        "division_type": "xã",
        "short_codename": "my_yen",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Thanh Phú",
        "code": "xa_thanh_phu",
        "division_type": "xã",
        "short_codename": "thanh_phu",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Long Hiệp",
        "code": "xa_long_hiep",
        "division_type": "xã",
        "short_codename": "long_hiep",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Thạnh Đức",
        "code": "xa_thanh_duc",
        "division_type": "xã",
        "short_codename": "thanh_duc",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Phước Lợi",
        "code": "xa_phuoc_loi",
        "division_type": "xã",
        "short_codename": "phuoc_loi",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Xã Nhựt Chánh",
        "code": "xa_nhut_chanh",
        "division_type": "xã",
        "short_codename": "nhut_chanh",
        "parentCode": "huyen_ben_luc"
    },
    {
        "name": "Thị trấn Thủ Thừa",
        "code": "thi_tran_thu_thua",
        "division_type": "thị trấn",
        "short_codename": "thu_thua",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Long Thạnh",
        "code": "xa_long_thanh",
        "division_type": "xã",
        "short_codename": "long_thanh",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Long Thuận",
        "code": "xa_long_thuan",
        "division_type": "xã",
        "short_codename": "long_thuan",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Mỹ Lạc",
        "code": "xa_my_lac",
        "division_type": "xã",
        "short_codename": "my_lac",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Mỹ Thạnh",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Bình An",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Nhị Thành",
        "code": "xa_nhi_thanh",
        "division_type": "xã",
        "short_codename": "nhi_thanh",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Mỹ An",
        "code": "xa_my_an",
        "division_type": "xã",
        "short_codename": "my_an",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Bình Thạnh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Mỹ Phú",
        "code": "xa_my_phu",
        "division_type": "xã",
        "short_codename": "my_phu",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "huyen_thu_thua"
    },
    {
        "name": "Thị trấn Tân Trụ",
        "code": "thi_tran_tan_tru",
        "division_type": "thị trấn",
        "short_codename": "tan_tru",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Xã Quê Mỹ Thạnh",
        "code": "xa_que_my_thanh",
        "division_type": "xã",
        "short_codename": "que_my_thanh",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Xã Lạc Tấn",
        "code": "xa_lac_tan",
        "division_type": "xã",
        "short_codename": "lac_tan",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Xã Bình Trinh Đông",
        "code": "xa_binh_trinh_dong",
        "division_type": "xã",
        "short_codename": "binh_trinh_dong",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Xã Tân Phước Tây",
        "code": "xa_tan_phuoc_tay",
        "division_type": "xã",
        "short_codename": "tan_phuoc_tay",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Xã Bình Lãng",
        "code": "xa_binh_lang",
        "division_type": "xã",
        "short_codename": "binh_lang",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Xã Bình Tịnh",
        "code": "xa_binh_tinh",
        "division_type": "xã",
        "short_codename": "binh_tinh",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Xã Đức Tân",
        "code": "xa_duc_tan",
        "division_type": "xã",
        "short_codename": "duc_tan",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Xã Nhựt Ninh",
        "code": "xa_nhut_ninh",
        "division_type": "xã",
        "short_codename": "nhut_ninh",
        "parentCode": "huyen_tan_tru"
    },
    {
        "name": "Thị trấn Cần Đước",
        "code": "thi_tran_can_duoc",
        "division_type": "thị trấn",
        "short_codename": "can_duoc",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Long Trạch",
        "code": "xa_long_trach",
        "division_type": "xã",
        "short_codename": "long_trach",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Long Khê",
        "code": "xa_long_khe",
        "division_type": "xã",
        "short_codename": "long_khe",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Long Định",
        "code": "xa_long_dinh",
        "division_type": "xã",
        "short_codename": "long_dinh",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Phước Vân",
        "code": "xa_phuoc_van",
        "division_type": "xã",
        "short_codename": "phuoc_van",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Long Hòa",
        "code": "xa_long_hoa",
        "division_type": "xã",
        "short_codename": "long_hoa",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Long Cang",
        "code": "xa_long_cang",
        "division_type": "xã",
        "short_codename": "long_cang",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Long Sơn",
        "code": "xa_long_son",
        "division_type": "xã",
        "short_codename": "long_son",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Tân Trạch",
        "code": "xa_tan_trach",
        "division_type": "xã",
        "short_codename": "tan_trach",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Mỹ Lệ",
        "code": "xa_my_le",
        "division_type": "xã",
        "short_codename": "my_le",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Tân Lân",
        "code": "xa_tan_lan",
        "division_type": "xã",
        "short_codename": "tan_lan",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Phước Tuy",
        "code": "xa_phuoc_tuy",
        "division_type": "xã",
        "short_codename": "phuoc_tuy",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Long Hựu Đông",
        "code": "xa_long_huu_dong",
        "division_type": "xã",
        "short_codename": "long_huu_dong",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Tân Ân",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Phước Đông",
        "code": "xa_phuoc_dong",
        "division_type": "xã",
        "short_codename": "phuoc_dong",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Long Hựu Tây",
        "code": "xa_long_huu_tay",
        "division_type": "xã",
        "short_codename": "long_huu_tay",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Xã Tân Chánh",
        "code": "xa_tan_chanh",
        "division_type": "xã",
        "short_codename": "tan_chanh",
        "parentCode": "huyen_can_duoc"
    },
    {
        "name": "Thị trấn Cần Giuộc",
        "code": "thi_tran_can_giuoc",
        "division_type": "thị trấn",
        "short_codename": "can_giuoc",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Phước Lý",
        "code": "xa_phuoc_ly",
        "division_type": "xã",
        "short_codename": "phuoc_ly",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Long Thượng",
        "code": "xa_long_thuong",
        "division_type": "xã",
        "short_codename": "long_thuong",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Long Hậu",
        "code": "xa_long_hau",
        "division_type": "xã",
        "short_codename": "long_hau",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Phước Hậu",
        "code": "xa_phuoc_hau",
        "division_type": "xã",
        "short_codename": "phuoc_hau",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Mỹ Lộc",
        "code": "xa_my_loc",
        "division_type": "xã",
        "short_codename": "my_loc",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Phước Lại",
        "code": "xa_phuoc_lai",
        "division_type": "xã",
        "short_codename": "phuoc_lai",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Phước Lâm",
        "code": "xa_phuoc_lam",
        "division_type": "xã",
        "short_codename": "phuoc_lam",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Thuận Thành",
        "code": "xa_thuan_thanh",
        "division_type": "xã",
        "short_codename": "thuan_thanh",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Phước Vĩnh Tây",
        "code": "xa_phuoc_vinh_tay",
        "division_type": "xã",
        "short_codename": "phuoc_vinh_tay",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Phước Vĩnh Đông",
        "code": "xa_phuoc_vinh_dong",
        "division_type": "xã",
        "short_codename": "phuoc_vinh_dong",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Long An",
        "code": "xa_long_an",
        "division_type": "xã",
        "short_codename": "long_an",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Long Phụng",
        "code": "xa_long_phung",
        "division_type": "xã",
        "short_codename": "long_phung",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Đông Thạnh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Xã Tân Tập",
        "code": "xa_tan_tap",
        "division_type": "xã",
        "short_codename": "tan_tap",
        "parentCode": "huyen_can_giuoc"
    },
    {
        "name": "Thị trấn Tầm Vu",
        "code": "thi_tran_tam_vu",
        "division_type": "thị trấn",
        "short_codename": "tam_vu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Bình Quới",
        "code": "xa_binh_quoi",
        "division_type": "xã",
        "short_codename": "binh_quoi",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hòa Phú",
        "code": "xa_hoa_phu",
        "division_type": "xã",
        "short_codename": "hoa_phu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Ngãi Trị",
        "code": "xa_phu_ngai_tri",
        "division_type": "xã",
        "short_codename": "phu_ngai_tri",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Vĩnh Công",
        "code": "xa_vinh_cong",
        "division_type": "xã",
        "short_codename": "vinh_cong",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thuận Mỹ",
        "code": "xa_thuan_my",
        "division_type": "xã",
        "short_codename": "thuan_my",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hiệp Thạnh",
        "code": "xa_hiep_thanh",
        "division_type": "xã",
        "short_codename": "hiep_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phước Tân Hưng",
        "code": "xa_phuoc_tan_hung",
        "division_type": "xã",
        "short_codename": "phuoc_tan_hung",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thanh Phú Long",
        "code": "xa_thanh_phu_long",
        "division_type": "xã",
        "short_codename": "thanh_phu_long",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Dương Xuân Hội",
        "code": "xa_duong_xuan_hoi",
        "division_type": "xã",
        "short_codename": "duong_xuan_hoi",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Lục Long",
        "code": "xa_an_luc_long",
        "division_type": "xã",
        "short_codename": "an_luc_long",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Long Trì",
        "code": "xa_long_tri",
        "division_type": "xã",
        "short_codename": "long_tri",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thanh Vĩnh Đông",
        "code": "xa_thanh_vinh_dong",
        "division_type": "xã",
        "short_codename": "thanh_vinh_dong",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 9",
        "code": "phuong_9",
        "division_type": "phường",
        "short_codename": "phuong_9",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường Tân Long",
        "code": "phuong_tan_long",
        "division_type": "phường",
        "short_codename": "tan_long",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Xã Đạo Thạnh",
        "code": "xa_dao_thanh",
        "division_type": "xã",
        "short_codename": "dao_thanh",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Xã Trung An",
        "code": "xa_trung_an",
        "division_type": "xã",
        "short_codename": "trung_an",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Xã Mỹ Phong",
        "code": "xa_my_phong",
        "division_type": "xã",
        "short_codename": "my_phong",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Xã Tân Mỹ Chánh",
        "code": "xa_tan_my_chanh",
        "division_type": "xã",
        "short_codename": "tan_my_chanh",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Xã Phước Thạnh",
        "code": "xa_phuoc_thanh",
        "division_type": "xã",
        "short_codename": "phuoc_thanh",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Xã Thới Sơn",
        "code": "xa_thoi_son",
        "division_type": "xã",
        "short_codename": "thoi_son",
        "parentCode": "thanh_pho_my_tho"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Xã Long Hưng",
        "code": "xa_long_hung",
        "division_type": "xã",
        "short_codename": "long_hung",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Xã Long Thuận",
        "code": "xa_long_thuan",
        "division_type": "xã",
        "short_codename": "long_thuan",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Xã Long Chánh",
        "code": "xa_long_chanh",
        "division_type": "xã",
        "short_codename": "long_chanh",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Xã Long Hòa",
        "code": "xa_long_hoa",
        "division_type": "xã",
        "short_codename": "long_hoa",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Xã Bình Đông",
        "code": "xa_binh_dong",
        "division_type": "xã",
        "short_codename": "binh_dong",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Xã Bình Xuân",
        "code": "xa_binh_xuan",
        "division_type": "xã",
        "short_codename": "binh_xuan",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Xã Tân Trung",
        "code": "xa_tan_trung",
        "division_type": "xã",
        "short_codename": "tan_trung",
        "parentCode": "thi_xa_go_cong"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Mỹ Phước Tây",
        "code": "xa_my_phuoc_tay",
        "division_type": "xã",
        "short_codename": "my_phuoc_tay",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Mỹ Hạnh Đông",
        "code": "xa_my_hanh_dong",
        "division_type": "xã",
        "short_codename": "my_hanh_dong",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Mỹ Hạnh Trung",
        "code": "xa_my_hanh_trung",
        "division_type": "xã",
        "short_codename": "my_hanh_trung",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Tân Hội",
        "code": "xa_tan_hoi",
        "division_type": "xã",
        "short_codename": "tan_hoi",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Phường Nhị Mỹ",
        "code": "phuong_nhi_my",
        "division_type": "phường",
        "short_codename": "nhi_my",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Nhị Quý",
        "code": "xa_nhi_quy",
        "division_type": "xã",
        "short_codename": "nhi_quy",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Thanh Hòa",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Phú Quý",
        "code": "xa_phu_quy",
        "division_type": "xã",
        "short_codename": "phu_quy",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Xã Long Khánh",
        "code": "xa_long_khanh",
        "division_type": "xã",
        "short_codename": "long_khanh",
        "parentCode": "thi_xa_cai_lay"
    },
    {
        "name": "Thị trấn Mỹ Phước",
        "code": "thi_tran_my_phuoc",
        "division_type": "thị trấn",
        "short_codename": "my_phuoc",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Tân Hòa Đông",
        "code": "xa_tan_hoa_dong",
        "division_type": "xã",
        "short_codename": "tan_hoa_dong",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Thạnh Tân",
        "code": "xa_thanh_tan",
        "division_type": "xã",
        "short_codename": "thanh_tan",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Thạnh Mỹ",
        "code": "xa_thanh_my",
        "division_type": "xã",
        "short_codename": "thanh_my",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Thạnh Hoà",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Phú Mỹ",
        "code": "xa_phu_my",
        "division_type": "xã",
        "short_codename": "phu_my",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Tân Hòa Thành",
        "code": "xa_tan_hoa_thanh",
        "division_type": "xã",
        "short_codename": "tan_hoa_thanh",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Hưng Thạnh",
        "code": "xa_hung_thanh",
        "division_type": "xã",
        "short_codename": "hung_thanh",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Tân Lập 1",
        "code": "xa_tan_lap_1",
        "division_type": "xã",
        "short_codename": "tan_lap_1",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Tân Hòa Tây",
        "code": "xa_tan_hoa_tay",
        "division_type": "xã",
        "short_codename": "tan_hoa_tay",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Tân Lập 2",
        "code": "xa_tan_lap_2",
        "division_type": "xã",
        "short_codename": "tan_lap_2",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Xã Phước Lập",
        "code": "xa_phuoc_lap",
        "division_type": "xã",
        "short_codename": "phuoc_lap",
        "parentCode": "huyen_tan_phuoc"
    },
    {
        "name": "Thị trấn Cái Bè",
        "code": "thi_tran_cai_be",
        "division_type": "thị trấn",
        "short_codename": "cai_be",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Hậu Mỹ Bắc B",
        "code": "xa_hau_my_bac_b",
        "division_type": "xã",
        "short_codename": "hau_my_bac_b",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Hậu Mỹ Bắc A",
        "code": "xa_hau_my_bac_a",
        "division_type": "xã",
        "short_codename": "hau_my_bac_a",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Mỹ Trung",
        "code": "xa_my_trung",
        "division_type": "xã",
        "short_codename": "my_trung",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Hậu Mỹ Trinh",
        "code": "xa_hau_my_trinh",
        "division_type": "xã",
        "short_codename": "hau_my_trinh",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Hậu Mỹ Phú",
        "code": "xa_hau_my_phu",
        "division_type": "xã",
        "short_codename": "hau_my_phu",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Mỹ Tân",
        "code": "xa_my_tan",
        "division_type": "xã",
        "short_codename": "my_tan",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Mỹ Lợi B",
        "code": "xa_my_loi_b",
        "division_type": "xã",
        "short_codename": "my_loi_b",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Thiện Trung",
        "code": "xa_thien_trung",
        "division_type": "xã",
        "short_codename": "thien_trung",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Mỹ Hội",
        "code": "xa_my_hoi",
        "division_type": "xã",
        "short_codename": "my_hoi",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã An Cư",
        "code": "xa_an_cu",
        "division_type": "xã",
        "short_codename": "an_cu",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Hậu Thành",
        "code": "xa_hau_thanh",
        "division_type": "xã",
        "short_codename": "hau_thanh",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Mỹ Lợi A",
        "code": "xa_my_loi_a",
        "division_type": "xã",
        "short_codename": "my_loi_a",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Hòa Khánh",
        "code": "xa_hoa_khanh",
        "division_type": "xã",
        "short_codename": "hoa_khanh",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Thiện Trí",
        "code": "xa_thien_tri",
        "division_type": "xã",
        "short_codename": "thien_tri",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Mỹ Đức Đông",
        "code": "xa_my_duc_dong",
        "division_type": "xã",
        "short_codename": "my_duc_dong",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Mỹ Đức Tây",
        "code": "xa_my_duc_tay",
        "division_type": "xã",
        "short_codename": "my_duc_tay",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Đông Hòa Hiệp",
        "code": "xa_dong_hoa_hiep",
        "division_type": "xã",
        "short_codename": "dong_hoa_hiep",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã An Thái Đông",
        "code": "xa_an_thai_dong",
        "division_type": "xã",
        "short_codename": "an_thai_dong",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Mỹ Lương",
        "code": "xa_my_luong",
        "division_type": "xã",
        "short_codename": "my_luong",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Tân Thanh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã An Thái Trung",
        "code": "xa_an_thai_trung",
        "division_type": "xã",
        "short_codename": "an_thai_trung",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã An Hữu",
        "code": "xa_an_huu",
        "division_type": "xã",
        "short_codename": "an_huu",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Hòa Hưng",
        "code": "xa_hoa_hung",
        "division_type": "xã",
        "short_codename": "hoa_hung",
        "parentCode": "huyen_cai_be"
    },
    {
        "name": "Xã Thạnh Lộc",
        "code": "xa_thanh_loc",
        "division_type": "xã",
        "short_codename": "thanh_loc",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Mỹ Thành Bắc",
        "code": "xa_my_thanh_bac",
        "division_type": "xã",
        "short_codename": "my_thanh_bac",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Phú Cường",
        "code": "xa_phu_cuong",
        "division_type": "xã",
        "short_codename": "phu_cuong",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Mỹ Thành Nam",
        "code": "xa_my_thanh_nam",
        "division_type": "xã",
        "short_codename": "my_thanh_nam",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Phú Nhuận",
        "code": "xa_phu_nhuan",
        "division_type": "xã",
        "short_codename": "phu_nhuan",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Cẩm Sơn",
        "code": "xa_cam_son",
        "division_type": "xã",
        "short_codename": "cam_son",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Phú An",
        "code": "xa_phu_an",
        "division_type": "xã",
        "short_codename": "phu_an",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Mỹ Long",
        "code": "xa_my_long",
        "division_type": "xã",
        "short_codename": "my_long",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Long Tiên",
        "code": "xa_long_tien",
        "division_type": "xã",
        "short_codename": "long_tien",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Hiệp Đức",
        "code": "xa_hiep_duc",
        "division_type": "xã",
        "short_codename": "hiep_duc",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Long Trung",
        "code": "xa_long_trung",
        "division_type": "xã",
        "short_codename": "long_trung",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Hội Xuân",
        "code": "xa_hoi_xuan",
        "division_type": "xã",
        "short_codename": "hoi_xuan",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Tân Phong",
        "code": "xa_tan_phong",
        "division_type": "xã",
        "short_codename": "tan_phong",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Tam Bình",
        "code": "xa_tam_binh",
        "division_type": "xã",
        "short_codename": "tam_binh",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Xã Ngũ Hiệp",
        "code": "xa_ngu_hiep",
        "division_type": "xã",
        "short_codename": "ngu_hiep",
        "parentCode": "huyen_cai_lay"
    },
    {
        "name": "Thị trấn Tân Hiệp",
        "code": "thi_tran_tan_hiep",
        "division_type": "thị trấn",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Hội Đông",
        "code": "xa_tan_hoi_dong",
        "division_type": "xã",
        "short_codename": "tan_hoi_dong",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Hương",
        "code": "xa_tan_huong",
        "division_type": "xã",
        "short_codename": "tan_huong",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Lý Đông",
        "code": "xa_tan_ly_dong",
        "division_type": "xã",
        "short_codename": "tan_ly_dong",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Lý Tây",
        "code": "xa_tan_ly_tay",
        "division_type": "xã",
        "short_codename": "tan_ly_tay",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thân Cửu Nghĩa",
        "code": "xa_than_cuu_nghia",
        "division_type": "xã",
        "short_codename": "than_cuu_nghia",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tam Hiệp",
        "code": "xa_tam_hiep",
        "division_type": "xã",
        "short_codename": "tam_hiep",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Điềm Hy",
        "code": "xa_diem_hy",
        "division_type": "xã",
        "short_codename": "diem_hy",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Nhị Bình",
        "code": "xa_nhi_binh",
        "division_type": "xã",
        "short_codename": "nhi_binh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Dưỡng Điềm",
        "code": "xa_duong_diem",
        "division_type": "xã",
        "short_codename": "duong_diem",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Đông Hòa",
        "code": "xa_dong_hoa",
        "division_type": "xã",
        "short_codename": "dong_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Long Định",
        "code": "xa_long_dinh",
        "division_type": "xã",
        "short_codename": "long_dinh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hữu Đạo",
        "code": "xa_huu_dao",
        "division_type": "xã",
        "short_codename": "huu_dao",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Long An",
        "code": "xa_long_an",
        "division_type": "xã",
        "short_codename": "long_an",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Long Hưng",
        "code": "xa_long_hung",
        "division_type": "xã",
        "short_codename": "long_hung",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Bình Trưng",
        "code": "xa_binh_trung",
        "division_type": "xã",
        "short_codename": "binh_trung",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thạnh Phú",
        "code": "xa_thanh_phu",
        "division_type": "xã",
        "short_codename": "thanh_phu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Bàn Long",
        "code": "xa_ban_long",
        "division_type": "xã",
        "short_codename": "ban_long",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Vĩnh Kim",
        "code": "xa_vinh_kim",
        "division_type": "xã",
        "short_codename": "vinh_kim",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Bình Đức",
        "code": "xa_binh_duc",
        "division_type": "xã",
        "short_codename": "binh_duc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Song Thuận",
        "code": "xa_song_thuan",
        "division_type": "xã",
        "short_codename": "song_thuan",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Kim Sơn",
        "code": "xa_kim_son",
        "division_type": "xã",
        "short_codename": "kim_son",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Phong",
        "code": "xa_phu_phong",
        "division_type": "xã",
        "short_codename": "phu_phong",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Thị trấn Chợ Gạo",
        "code": "thi_tran_cho_gao",
        "division_type": "thị trấn",
        "short_codename": "cho_gao",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Trung Hòa",
        "code": "xa_trung_hoa",
        "division_type": "xã",
        "short_codename": "trung_hoa",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Hòa Tịnh",
        "code": "xa_hoa_tinh",
        "division_type": "xã",
        "short_codename": "hoa_tinh",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Mỹ Tịnh An",
        "code": "xa_my_tinh_an",
        "division_type": "xã",
        "short_codename": "my_tinh_an",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Tân Bình Thạnh",
        "code": "xa_tan_binh_thanh",
        "division_type": "xã",
        "short_codename": "tan_binh_thanh",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Phú Kiết",
        "code": "xa_phu_kiet",
        "division_type": "xã",
        "short_codename": "phu_kiet",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Lương Hòa Lạc",
        "code": "xa_luong_hoa_lac",
        "division_type": "xã",
        "short_codename": "luong_hoa_lac",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Thanh Bình",
        "code": "xa_thanh_binh",
        "division_type": "xã",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Quơn Long",
        "code": "xa_quon_long",
        "division_type": "xã",
        "short_codename": "quon_long",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Bình Phục Nhứt",
        "code": "xa_binh_phuc_nhut",
        "division_type": "xã",
        "short_codename": "binh_phuc_nhut",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Đăng Hưng Phước",
        "code": "xa_dang_hung_phuoc",
        "division_type": "xã",
        "short_codename": "dang_hung_phuoc",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Tân Thuận Bình",
        "code": "xa_tan_thuan_binh",
        "division_type": "xã",
        "short_codename": "tan_thuan_binh",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Song Bình",
        "code": "xa_song_binh",
        "division_type": "xã",
        "short_codename": "song_binh",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Bình Phan",
        "code": "xa_binh_phan",
        "division_type": "xã",
        "short_codename": "binh_phan",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Long Bình Điền",
        "code": "xa_long_binh_dien",
        "division_type": "xã",
        "short_codename": "long_binh_dien",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã An Thạnh Thủy",
        "code": "xa_an_thanh_thuy",
        "division_type": "xã",
        "short_codename": "an_thanh_thuy",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Xuân Đông",
        "code": "xa_xuan_dong",
        "division_type": "xã",
        "short_codename": "xuan_dong",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Hòa Định",
        "code": "xa_hoa_dinh",
        "division_type": "xã",
        "short_codename": "hoa_dinh",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Xã Bình Ninh",
        "code": "xa_binh_ninh",
        "division_type": "xã",
        "short_codename": "binh_ninh",
        "parentCode": "huyen_cho_gao"
    },
    {
        "name": "Thị trấn Vĩnh Bình",
        "code": "thi_tran_vinh_binh",
        "division_type": "thị trấn",
        "short_codename": "vinh_binh",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Đồng Sơn",
        "code": "xa_dong_son",
        "division_type": "xã",
        "short_codename": "dong_son",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Đồng Thạnh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Thành Công",
        "code": "xa_thanh_cong",
        "division_type": "xã",
        "short_codename": "thanh_cong",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Bình Nhì",
        "code": "xa_binh_nhi",
        "division_type": "xã",
        "short_codename": "binh_nhi",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Yên Luông",
        "code": "xa_yen_luong",
        "division_type": "xã",
        "short_codename": "yen_luong",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Thạnh Trị",
        "code": "xa_thanh_tri",
        "division_type": "xã",
        "short_codename": "thanh_tri",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Thạnh Nhựt",
        "code": "xa_thanh_nhut",
        "division_type": "xã",
        "short_codename": "thanh_nhut",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Long Vĩnh",
        "code": "xa_long_vinh",
        "division_type": "xã",
        "short_codename": "long_vinh",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Bình Tân",
        "code": "xa_binh_tan",
        "division_type": "xã",
        "short_codename": "binh_tan",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Vĩnh Hựu",
        "code": "xa_vinh_huu",
        "division_type": "xã",
        "short_codename": "vinh_huu",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Xã Long Bình",
        "code": "xa_long_binh",
        "division_type": "xã",
        "short_codename": "long_binh",
        "parentCode": "huyen_go_cong_tay"
    },
    {
        "name": "Thị trấn Tân Hòa",
        "code": "thi_tran_tan_hoa",
        "division_type": "thị trấn",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Tăng Hoà",
        "code": "xa_tang_hoa",
        "division_type": "xã",
        "short_codename": "tang_hoa",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Tân Phước",
        "code": "xa_tan_phuoc",
        "division_type": "xã",
        "short_codename": "tan_phuoc",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Gia Thuận",
        "code": "xa_gia_thuan",
        "division_type": "xã",
        "short_codename": "gia_thuan",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Thị trấn Vàm Láng",
        "code": "thi_tran_vam_lang",
        "division_type": "thị trấn",
        "short_codename": "vam_lang",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Tân Tây",
        "code": "xa_tan_tay",
        "division_type": "xã",
        "short_codename": "tan_tay",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Kiểng Phước",
        "code": "xa_kieng_phuoc",
        "division_type": "xã",
        "short_codename": "kieng_phuoc",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Tân Đông",
        "code": "xa_tan_dong",
        "division_type": "xã",
        "short_codename": "tan_dong",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Bình Ân",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Tân Điền",
        "code": "xa_tan_dien",
        "division_type": "xã",
        "short_codename": "tan_dien",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Bình Nghị",
        "code": "xa_binh_nghi",
        "division_type": "xã",
        "short_codename": "binh_nghi",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Phước Trung",
        "code": "xa_phuoc_trung",
        "division_type": "xã",
        "short_codename": "phuoc_trung",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_go_cong_dong"
    },
    {
        "name": "Xã Tân Thới",
        "code": "xa_tan_thoi",
        "division_type": "xã",
        "short_codename": "tan_thoi",
        "parentCode": "huyen_tan_phu_dong"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_tan_phu_dong"
    },
    {
        "name": "Xã Phú Thạnh",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "phu_thanh",
        "parentCode": "huyen_tan_phu_dong"
    },
    {
        "name": "Xã Tân Thạnh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_tan_phu_dong"
    },
    {
        "name": "Xã Phú Đông",
        "code": "xa_phu_dong",
        "division_type": "xã",
        "short_codename": "phu_dong",
        "parentCode": "huyen_tan_phu_dong"
    },
    {
        "name": "Xã Phú Tân",
        "code": "xa_phu_tan",
        "division_type": "xã",
        "short_codename": "phu_tan",
        "parentCode": "huyen_tan_phu_dong"
    },
    {
        "name": "Phường Phú Khương",
        "code": "phuong_phu_khuong",
        "division_type": "phường",
        "short_codename": "phu_khuong",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Phường Phú Tân",
        "code": "phuong_phu_tan",
        "division_type": "phường",
        "short_codename": "phu_tan",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Phường An Hội",
        "code": "phuong_an_hoi",
        "division_type": "phường",
        "short_codename": "an_hoi",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Xã Sơn Đông",
        "code": "xa_son_dong",
        "division_type": "xã",
        "short_codename": "son_dong",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Xã Phú Hưng",
        "code": "xa_phu_hung",
        "division_type": "xã",
        "short_codename": "phu_hung",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Xã Mỹ Thạnh An",
        "code": "xa_my_thanh_an",
        "division_type": "xã",
        "short_codename": "my_thanh_an",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Xã Nhơn Thạnh",
        "code": "xa_nhon_thanh",
        "division_type": "xã",
        "short_codename": "nhon_thanh",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Xã Phú Nhuận",
        "code": "xa_phu_nhuan",
        "division_type": "xã",
        "short_codename": "phu_nhuan",
        "parentCode": "thanh_pho_ben_tre"
    },
    {
        "name": "Thị trấn Châu Thành",
        "code": "thi_tran_chau_thanh",
        "division_type": "thị trấn",
        "short_codename": "chau_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Thạch",
        "code": "xa_tan_thach",
        "division_type": "xã",
        "short_codename": "tan_thach",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Qưới Sơn",
        "code": "xa_quoi_son",
        "division_type": "xã",
        "short_codename": "quoi_son",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Khánh",
        "code": "xa_an_khanh",
        "division_type": "xã",
        "short_codename": "an_khanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Giao Long",
        "code": "xa_giao_long",
        "division_type": "xã",
        "short_codename": "giao_long",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Túc",
        "code": "xa_phu_tuc",
        "division_type": "xã",
        "short_codename": "phu_tuc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Đức",
        "code": "xa_phu_duc",
        "division_type": "xã",
        "short_codename": "phu_duc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú An Hòa",
        "code": "xa_phu_an_hoa",
        "division_type": "xã",
        "short_codename": "phu_an_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Phước",
        "code": "xa_an_phuoc",
        "division_type": "xã",
        "short_codename": "an_phuoc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tam Phước",
        "code": "xa_tam_phuoc",
        "division_type": "xã",
        "short_codename": "tam_phuoc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thành Triệu",
        "code": "xa_thanh_trieu",
        "division_type": "xã",
        "short_codename": "thanh_trieu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tường Đa",
        "code": "xa_tuong_da",
        "division_type": "xã",
        "short_codename": "tuong_da",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Quới Thành",
        "code": "xa_quoi_thanh",
        "division_type": "xã",
        "short_codename": "quoi_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phước Thạnh",
        "code": "xa_phuoc_thanh",
        "division_type": "xã",
        "short_codename": "phuoc_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Hóa",
        "code": "xa_an_hoa",
        "division_type": "xã",
        "short_codename": "an_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tiên Long",
        "code": "xa_tien_long",
        "division_type": "xã",
        "short_codename": "tien_long",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Hiệp",
        "code": "xa_an_hiep",
        "division_type": "xã",
        "short_codename": "an_hiep",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hữu Định",
        "code": "xa_huu_dinh",
        "division_type": "xã",
        "short_codename": "huu_dinh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tiên Thủy",
        "code": "xa_tien_thuy",
        "division_type": "xã",
        "short_codename": "tien_thuy",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Sơn Hòa",
        "code": "xa_son_hoa",
        "division_type": "xã",
        "short_codename": "son_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Thị trấn Chợ Lách",
        "code": "thi_tran_cho_lach",
        "division_type": "thị trấn",
        "short_codename": "cho_lach",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Phú Phụng",
        "code": "xa_phu_phung",
        "division_type": "xã",
        "short_codename": "phu_phung",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Sơn Định",
        "code": "xa_son_dinh",
        "division_type": "xã",
        "short_codename": "son_dinh",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Vĩnh Bình",
        "code": "xa_vinh_binh",
        "division_type": "xã",
        "short_codename": "vinh_binh",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Hòa Nghĩa",
        "code": "xa_hoa_nghia",
        "division_type": "xã",
        "short_codename": "hoa_nghia",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Long Thới",
        "code": "xa_long_thoi",
        "division_type": "xã",
        "short_codename": "long_thoi",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Phú Sơn",
        "code": "xa_phu_son",
        "division_type": "xã",
        "short_codename": "phu_son",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Tân Thiềng",
        "code": "xa_tan_thieng",
        "division_type": "xã",
        "short_codename": "tan_thieng",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Vĩnh Thành",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Vĩnh Hòa",
        "code": "xa_vinh_hoa",
        "division_type": "xã",
        "short_codename": "vinh_hoa",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Xã Hưng Khánh Trung B",
        "code": "xa_hung_khanh_trung_b",
        "division_type": "xã",
        "short_codename": "hung_khanh_trung_b",
        "parentCode": "huyen_cho_lach"
    },
    {
        "name": "Thị trấn Mỏ Cày",
        "code": "thi_tran_mo_cay",
        "division_type": "thị trấn",
        "short_codename": "mo_cay",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Định Thủy",
        "code": "xa_dinh_thuy",
        "division_type": "xã",
        "short_codename": "dinh_thuy",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Đa Phước Hội",
        "code": "xa_da_phuoc_hoi",
        "division_type": "xã",
        "short_codename": "da_phuoc_hoi",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Tân Hội",
        "code": "xa_tan_hoi",
        "division_type": "xã",
        "short_codename": "tan_hoi",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Phước Hiệp",
        "code": "xa_phuoc_hiep",
        "division_type": "xã",
        "short_codename": "phuoc_hiep",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Bình Khánh",
        "code": "xa_binh_khanh",
        "division_type": "xã",
        "short_codename": "binh_khanh",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã An Thạnh",
        "code": "xa_an_thanh",
        "division_type": "xã",
        "short_codename": "an_thanh",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã An Định",
        "code": "xa_an_dinh",
        "division_type": "xã",
        "short_codename": "an_dinh",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Thành Thới B",
        "code": "xa_thanh_thoi_b",
        "division_type": "xã",
        "short_codename": "thanh_thoi_b",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Tân Trung",
        "code": "xa_tan_trung",
        "division_type": "xã",
        "short_codename": "tan_trung",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã An Thới",
        "code": "xa_an_thoi",
        "division_type": "xã",
        "short_codename": "an_thoi",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Thành Thới A",
        "code": "xa_thanh_thoi_a",
        "division_type": "xã",
        "short_codename": "thanh_thoi_a",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Minh Đức",
        "code": "xa_minh_duc",
        "division_type": "xã",
        "short_codename": "minh_duc",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Ngãi Đăng",
        "code": "xa_ngai_dang",
        "division_type": "xã",
        "short_codename": "ngai_dang",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Cẩm Sơn",
        "code": "xa_cam_son",
        "division_type": "xã",
        "short_codename": "cam_son",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Xã Hương Mỹ",
        "code": "xa_huong_my",
        "division_type": "xã",
        "short_codename": "huong_my",
        "parentCode": "huyen_mo_cay_nam"
    },
    {
        "name": "Thị trấn Giồng Trôm",
        "code": "thi_tran_giong_trom",
        "division_type": "thị trấn",
        "short_codename": "giong_trom",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Phong Nẫm",
        "code": "xa_phong_nam",
        "division_type": "xã",
        "short_codename": "phong_nam",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Mỹ Thạnh",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Châu Hòa",
        "code": "xa_chau_hoa",
        "division_type": "xã",
        "short_codename": "chau_hoa",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Lương Hòa",
        "code": "xa_luong_hoa",
        "division_type": "xã",
        "short_codename": "luong_hoa",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Lương Quới",
        "code": "xa_luong_quoi",
        "division_type": "xã",
        "short_codename": "luong_quoi",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Lương Phú",
        "code": "xa_luong_phu",
        "division_type": "xã",
        "short_codename": "luong_phu",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Châu Bình",
        "code": "xa_chau_binh",
        "division_type": "xã",
        "short_codename": "chau_binh",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Thuận Điền",
        "code": "xa_thuan_dien",
        "division_type": "xã",
        "short_codename": "thuan_dien",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Sơn Phú",
        "code": "xa_son_phu",
        "division_type": "xã",
        "short_codename": "son_phu",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Bình Hoà",
        "code": "xa_binh_hoa",
        "division_type": "xã",
        "short_codename": "binh_hoa",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Phước Long",
        "code": "xa_phuoc_long",
        "division_type": "xã",
        "short_codename": "phuoc_long",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Hưng Phong",
        "code": "xa_hung_phong",
        "division_type": "xã",
        "short_codename": "hung_phong",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Long Mỹ",
        "code": "xa_long_my",
        "division_type": "xã",
        "short_codename": "long_my",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Tân Hào",
        "code": "xa_tan_hao",
        "division_type": "xã",
        "short_codename": "tan_hao",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Bình Thành",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Tân Thanh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Tân Lợi Thạnh",
        "code": "xa_tan_loi_thanh",
        "division_type": "xã",
        "short_codename": "tan_loi_thanh",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Thạnh Phú Đông",
        "code": "xa_thanh_phu_dong",
        "division_type": "xã",
        "short_codename": "thanh_phu_dong",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Hưng Nhượng",
        "code": "xa_hung_nhuong",
        "division_type": "xã",
        "short_codename": "hung_nhuong",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Xã Hưng Lễ",
        "code": "xa_hung_le",
        "division_type": "xã",
        "short_codename": "hung_le",
        "parentCode": "huyen_giong_trom"
    },
    {
        "name": "Thị trấn Bình Đại",
        "code": "thi_tran_binh_dai",
        "division_type": "thị trấn",
        "short_codename": "binh_dai",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Tam Hiệp",
        "code": "xa_tam_hiep",
        "division_type": "xã",
        "short_codename": "tam_hiep",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Long Định",
        "code": "xa_long_dinh",
        "division_type": "xã",
        "short_codename": "long_dinh",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Long Hòa",
        "code": "xa_long_hoa",
        "division_type": "xã",
        "short_codename": "long_hoa",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Phú Thuận",
        "code": "xa_phu_thuan",
        "division_type": "xã",
        "short_codename": "phu_thuan",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Vang Quới Tây",
        "code": "xa_vang_quoi_tay",
        "division_type": "xã",
        "short_codename": "vang_quoi_tay",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Vang Quới Đông",
        "code": "xa_vang_quoi_dong",
        "division_type": "xã",
        "short_codename": "vang_quoi_dong",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Châu Hưng",
        "code": "xa_chau_hung",
        "division_type": "xã",
        "short_codename": "chau_hung",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Phú Vang",
        "code": "xa_phu_vang",
        "division_type": "xã",
        "short_codename": "phu_vang",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Lộc Thuận",
        "code": "xa_loc_thuan",
        "division_type": "xã",
        "short_codename": "loc_thuan",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Định Trung",
        "code": "xa_dinh_trung",
        "division_type": "xã",
        "short_codename": "dinh_trung",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Thới Lai",
        "code": "xa_thoi_lai",
        "division_type": "xã",
        "short_codename": "thoi_lai",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Bình Thới",
        "code": "xa_binh_thoi",
        "division_type": "xã",
        "short_codename": "binh_thoi",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Phú Long",
        "code": "xa_phu_long",
        "division_type": "xã",
        "short_codename": "phu_long",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Bình Thắng",
        "code": "xa_binh_thang",
        "division_type": "xã",
        "short_codename": "binh_thang",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Thạnh Trị",
        "code": "xa_thanh_tri",
        "division_type": "xã",
        "short_codename": "thanh_tri",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Đại Hòa Lộc",
        "code": "xa_dai_hoa_loc",
        "division_type": "xã",
        "short_codename": "dai_hoa_loc",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Thừa Đức",
        "code": "xa_thua_duc",
        "division_type": "xã",
        "short_codename": "thua_duc",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Thạnh Phước",
        "code": "xa_thanh_phuoc",
        "division_type": "xã",
        "short_codename": "thanh_phuoc",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Xã Thới Thuận",
        "code": "xa_thoi_thuan",
        "division_type": "xã",
        "short_codename": "thoi_thuan",
        "parentCode": "huyen_binh_dai"
    },
    {
        "name": "Thị trấn Ba Tri",
        "code": "thi_tran_ba_tri",
        "division_type": "thị trấn",
        "short_codename": "ba_tri",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Mỹ Hòa",
        "code": "xa_my_hoa",
        "division_type": "xã",
        "short_codename": "my_hoa",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Tân Xuân",
        "code": "xa_tan_xuan",
        "division_type": "xã",
        "short_codename": "tan_xuan",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Mỹ Chánh",
        "code": "xa_my_chanh",
        "division_type": "xã",
        "short_codename": "my_chanh",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Bảo Thạnh",
        "code": "xa_bao_thanh",
        "division_type": "xã",
        "short_codename": "bao_thanh",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã An Phú Trung",
        "code": "xa_an_phu_trung",
        "division_type": "xã",
        "short_codename": "an_phu_trung",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Mỹ Thạnh",
        "code": "xa_my_thanh",
        "division_type": "xã",
        "short_codename": "my_thanh",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Mỹ Nhơn",
        "code": "xa_my_nhon",
        "division_type": "xã",
        "short_codename": "my_nhon",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Phước Ngãi",
        "code": "xa_phuoc_ngai",
        "division_type": "xã",
        "short_codename": "phuoc_ngai",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã An Ngãi Trung",
        "code": "xa_an_ngai_trung",
        "division_type": "xã",
        "short_codename": "an_ngai_trung",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Phú Lễ",
        "code": "xa_phu_le",
        "division_type": "xã",
        "short_codename": "phu_le",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã An Bình Tây",
        "code": "xa_an_binh_tay",
        "division_type": "xã",
        "short_codename": "an_binh_tay",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Bảo Thuận",
        "code": "xa_bao_thuan",
        "division_type": "xã",
        "short_codename": "bao_thuan",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã An Ngãi Tây",
        "code": "xa_an_ngai_tay",
        "division_type": "xã",
        "short_codename": "an_ngai_tay",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã An Hiệp",
        "code": "xa_an_hiep",
        "division_type": "xã",
        "short_codename": "an_hiep",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Vĩnh Hòa",
        "code": "xa_vinh_hoa",
        "division_type": "xã",
        "short_codename": "vinh_hoa",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Tân Thủy",
        "code": "xa_tan_thuy",
        "division_type": "xã",
        "short_codename": "tan_thuy",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã Vĩnh An",
        "code": "xa_vinh_an",
        "division_type": "xã",
        "short_codename": "vinh_an",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã An Đức",
        "code": "xa_an_duc",
        "division_type": "xã",
        "short_codename": "an_duc",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã An Hòa Tây",
        "code": "xa_an_hoa_tay",
        "division_type": "xã",
        "short_codename": "an_hoa_tay",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Xã An Thủy",
        "code": "xa_an_thuy",
        "division_type": "xã",
        "short_codename": "an_thuy",
        "parentCode": "huyen_ba_tri"
    },
    {
        "name": "Thị trấn Thạnh Phú",
        "code": "thi_tran_thanh_phu",
        "division_type": "thị trấn",
        "short_codename": "thanh_phu",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Phú Khánh",
        "code": "xa_phu_khanh",
        "division_type": "xã",
        "short_codename": "phu_khanh",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Đại Điền",
        "code": "xa_dai_dien",
        "division_type": "xã",
        "short_codename": "dai_dien",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Quới Điền",
        "code": "xa_quoi_dien",
        "division_type": "xã",
        "short_codename": "quoi_dien",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Tân Phong",
        "code": "xa_tan_phong",
        "division_type": "xã",
        "short_codename": "tan_phong",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Mỹ Hưng",
        "code": "xa_my_hung",
        "division_type": "xã",
        "short_codename": "my_hung",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã An Thạnh",
        "code": "xa_an_thanh",
        "division_type": "xã",
        "short_codename": "an_thanh",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Thới Thạnh",
        "code": "xa_thoi_thanh",
        "division_type": "xã",
        "short_codename": "thoi_thanh",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Hòa Lợi",
        "code": "xa_hoa_loi",
        "division_type": "xã",
        "short_codename": "hoa_loi",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã An Điền",
        "code": "xa_an_dien",
        "division_type": "xã",
        "short_codename": "an_dien",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Bình Thạnh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã An Thuận",
        "code": "xa_an_thuan",
        "division_type": "xã",
        "short_codename": "an_thuan",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã An Quy",
        "code": "xa_an_quy",
        "division_type": "xã",
        "short_codename": "an_quy",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Thạnh Hải",
        "code": "xa_thanh_hai",
        "division_type": "xã",
        "short_codename": "thanh_hai",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã An Nhơn",
        "code": "xa_an_nhon",
        "division_type": "xã",
        "short_codename": "an_nhon",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Giao Thạnh",
        "code": "xa_giao_thanh",
        "division_type": "xã",
        "short_codename": "giao_thanh",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Thạnh Phong",
        "code": "xa_thanh_phong",
        "division_type": "xã",
        "short_codename": "thanh_phong",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Mỹ An",
        "code": "xa_my_an",
        "division_type": "xã",
        "short_codename": "my_an",
        "parentCode": "huyen_thanh_phu"
    },
    {
        "name": "Xã Phú Mỹ",
        "code": "xa_phu_my",
        "division_type": "xã",
        "short_codename": "phu_my",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Hưng Khánh Trung A",
        "code": "xa_hung_khanh_trung_a",
        "division_type": "xã",
        "short_codename": "hung_khanh_trung_a",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Thanh Tân",
        "code": "xa_thanh_tan",
        "division_type": "xã",
        "short_codename": "thanh_tan",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Thạnh Ngãi",
        "code": "xa_thanh_ngai",
        "division_type": "xã",
        "short_codename": "thanh_ngai",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Tân Phú Tây",
        "code": "xa_tan_phu_tay",
        "division_type": "xã",
        "short_codename": "tan_phu_tay",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Phước Mỹ Trung",
        "code": "xa_phuoc_my_trung",
        "division_type": "xã",
        "short_codename": "phuoc_my_trung",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Tân Thành Bình",
        "code": "xa_tan_thanh_binh",
        "division_type": "xã",
        "short_codename": "tan_thanh_binh",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Thành An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "thanh_an",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Hòa Lộc",
        "code": "xa_hoa_loc",
        "division_type": "xã",
        "short_codename": "hoa_loc",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Tân Thanh Tây",
        "code": "xa_tan_thanh_tay",
        "division_type": "xã",
        "short_codename": "tan_thanh_tay",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Nhuận Phú Tân",
        "code": "xa_nhuan_phu_tan",
        "division_type": "xã",
        "short_codename": "nhuan_phu_tan",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Xã Khánh Thạnh Tân",
        "code": "xa_khanh_thanh_tan",
        "division_type": "xã",
        "short_codename": "khanh_thanh_tan",
        "parentCode": "huyen_mo_cay_bac"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Phường 9",
        "code": "phuong_9",
        "division_type": "phường",
        "short_codename": "phuong_9",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Xã Long Đức",
        "code": "xa_long_duc",
        "division_type": "xã",
        "short_codename": "long_duc",
        "parentCode": "thanh_pho_tra_vinh"
    },
    {
        "name": "Thị trấn Càng Long",
        "code": "thi_tran_cang_long",
        "division_type": "thị trấn",
        "short_codename": "cang_long",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Mỹ Cẩm",
        "code": "xa_my_cam",
        "division_type": "xã",
        "short_codename": "my_cam",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã An Trường A",
        "code": "xa_an_truong_a",
        "division_type": "xã",
        "short_codename": "an_truong_a",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã An Trường",
        "code": "xa_an_truong",
        "division_type": "xã",
        "short_codename": "an_truong",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Huyền Hội",
        "code": "xa_huyen_hoi",
        "division_type": "xã",
        "short_codename": "huyen_hoi",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Tân An",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Phương Thạnh",
        "code": "xa_phuong_thanh",
        "division_type": "xã",
        "short_codename": "phuong_thanh",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Đại Phúc",
        "code": "xa_dai_phuc",
        "division_type": "xã",
        "short_codename": "dai_phuc",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Đại Phước",
        "code": "xa_dai_phuoc",
        "division_type": "xã",
        "short_codename": "dai_phuoc",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Nhị Long Phú",
        "code": "xa_nhi_long_phu",
        "division_type": "xã",
        "short_codename": "nhi_long_phu",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Nhị Long",
        "code": "xa_nhi_long",
        "division_type": "xã",
        "short_codename": "nhi_long",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Xã Đức Mỹ",
        "code": "xa_duc_my",
        "division_type": "xã",
        "short_codename": "duc_my",
        "parentCode": "huyen_cang_long"
    },
    {
        "name": "Thị trấn Cầu Kè",
        "code": "thi_tran_cau_ke",
        "division_type": "thị trấn",
        "short_codename": "cau_ke",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã Hòa Ân",
        "code": "xa_hoa_an",
        "division_type": "xã",
        "short_codename": "hoa_an",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã Châu Điền",
        "code": "xa_chau_dien",
        "division_type": "xã",
        "short_codename": "chau_dien",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã An Phú Tân",
        "code": "xa_an_phu_tan",
        "division_type": "xã",
        "short_codename": "an_phu_tan",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã Hoà Tân",
        "code": "xa_hoa_tan",
        "division_type": "xã",
        "short_codename": "hoa_tan",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã Ninh Thới",
        "code": "xa_ninh_thoi",
        "division_type": "xã",
        "short_codename": "ninh_thoi",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã Phong Phú",
        "code": "xa_phong_phu",
        "division_type": "xã",
        "short_codename": "phong_phu",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã Phong Thạnh",
        "code": "xa_phong_thanh",
        "division_type": "xã",
        "short_codename": "phong_thanh",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã Tam Ngãi",
        "code": "xa_tam_ngai",
        "division_type": "xã",
        "short_codename": "tam_ngai",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã Thông Hòa",
        "code": "xa_thong_hoa",
        "division_type": "xã",
        "short_codename": "thong_hoa",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Xã Thạnh Phú",
        "code": "xa_thanh_phu",
        "division_type": "xã",
        "short_codename": "thanh_phu",
        "parentCode": "huyen_cau_ke"
    },
    {
        "name": "Thị trấn Tiểu Cần",
        "code": "thi_tran_tieu_can",
        "division_type": "thị trấn",
        "short_codename": "tieu_can",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Thị trấn Cầu Quan",
        "code": "thi_tran_cau_quan",
        "division_type": "thị trấn",
        "short_codename": "cau_quan",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Xã Phú Cần",
        "code": "xa_phu_can",
        "division_type": "xã",
        "short_codename": "phu_can",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Xã Hiếu Tử",
        "code": "xa_hieu_tu",
        "division_type": "xã",
        "short_codename": "hieu_tu",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Xã Hiếu Trung",
        "code": "xa_hieu_trung",
        "division_type": "xã",
        "short_codename": "hieu_trung",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Xã Long Thới",
        "code": "xa_long_thoi",
        "division_type": "xã",
        "short_codename": "long_thoi",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Xã Hùng Hòa",
        "code": "xa_hung_hoa",
        "division_type": "xã",
        "short_codename": "hung_hoa",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Xã Tân Hùng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Xã Tập Ngãi",
        "code": "xa_tap_ngai",
        "division_type": "xã",
        "short_codename": "tap_ngai",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Xã Ngãi Hùng",
        "code": "xa_ngai_hung",
        "division_type": "xã",
        "short_codename": "ngai_hung",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_tieu_can"
    },
    {
        "name": "Thị trấn Châu Thành",
        "code": "thi_tran_chau_thanh",
        "division_type": "thị trấn",
        "short_codename": "chau_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Đa Lộc",
        "code": "xa_da_loc",
        "division_type": "xã",
        "short_codename": "da_loc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Mỹ Chánh",
        "code": "xa_my_chanh",
        "division_type": "xã",
        "short_codename": "my_chanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thanh Mỹ",
        "code": "xa_thanh_my",
        "division_type": "xã",
        "short_codename": "thanh_my",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Lương Hoà A",
        "code": "xa_luong_hoa_a",
        "division_type": "xã",
        "short_codename": "luong_hoa_a",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Lương Hòa",
        "code": "xa_luong_hoa",
        "division_type": "xã",
        "short_codename": "luong_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Song Lộc",
        "code": "xa_song_loc",
        "division_type": "xã",
        "short_codename": "song_loc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Nguyệt Hóa",
        "code": "xa_nguyet_hoa",
        "division_type": "xã",
        "short_codename": "nguyet_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hòa Thuận",
        "code": "xa_hoa_thuan",
        "division_type": "xã",
        "short_codename": "hoa_thuan",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hòa Lợi",
        "code": "xa_hoa_loi",
        "division_type": "xã",
        "short_codename": "hoa_loi",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phước Hảo",
        "code": "xa_phuoc_hao",
        "division_type": "xã",
        "short_codename": "phuoc_hao",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hưng Mỹ",
        "code": "xa_hung_my",
        "division_type": "xã",
        "short_codename": "hung_my",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hòa Minh",
        "code": "xa_hoa_minh",
        "division_type": "xã",
        "short_codename": "hoa_minh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Long Hòa",
        "code": "xa_long_hoa",
        "division_type": "xã",
        "short_codename": "long_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Thị trấn Cầu Ngang",
        "code": "thi_tran_cau_ngang",
        "division_type": "thị trấn",
        "short_codename": "cau_ngang",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Thị trấn Mỹ Long",
        "code": "thi_tran_my_long",
        "division_type": "thị trấn",
        "short_codename": "my_long",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Mỹ Long Bắc",
        "code": "xa_my_long_bac",
        "division_type": "xã",
        "short_codename": "my_long_bac",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Mỹ Long Nam",
        "code": "xa_my_long_nam",
        "division_type": "xã",
        "short_codename": "my_long_nam",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Mỹ Hòa",
        "code": "xa_my_hoa",
        "division_type": "xã",
        "short_codename": "my_hoa",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Vĩnh Kim",
        "code": "xa_vinh_kim",
        "division_type": "xã",
        "short_codename": "vinh_kim",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Kim Hòa",
        "code": "xa_kim_hoa",
        "division_type": "xã",
        "short_codename": "kim_hoa",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Hiệp Hòa",
        "code": "xa_hiep_hoa",
        "division_type": "xã",
        "short_codename": "hiep_hoa",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Thuận Hòa",
        "code": "xa_thuan_hoa",
        "division_type": "xã",
        "short_codename": "thuan_hoa",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Long Sơn",
        "code": "xa_long_son",
        "division_type": "xã",
        "short_codename": "long_son",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Nhị Trường",
        "code": "xa_nhi_truong",
        "division_type": "xã",
        "short_codename": "nhi_truong",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Trường Thọ",
        "code": "xa_truong_tho",
        "division_type": "xã",
        "short_codename": "truong_tho",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Hiệp Mỹ Đông",
        "code": "xa_hiep_my_dong",
        "division_type": "xã",
        "short_codename": "hiep_my_dong",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Hiệp Mỹ Tây",
        "code": "xa_hiep_my_tay",
        "division_type": "xã",
        "short_codename": "hiep_my_tay",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Xã Thạnh Hòa Sơn",
        "code": "xa_thanh_hoa_son",
        "division_type": "xã",
        "short_codename": "thanh_hoa_son",
        "parentCode": "huyen_cau_ngang"
    },
    {
        "name": "Thị trấn Trà Cú",
        "code": "thi_tran_tra_cu",
        "division_type": "thị trấn",
        "short_codename": "tra_cu",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Thị trấn Định An",
        "code": "thi_tran_dinh_an",
        "division_type": "thị trấn",
        "short_codename": "dinh_an",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Phước Hưng",
        "code": "xa_phuoc_hung",
        "division_type": "xã",
        "short_codename": "phuoc_hung",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Tập Sơn",
        "code": "xa_tap_son",
        "division_type": "xã",
        "short_codename": "tap_son",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Tân Sơn",
        "code": "xa_tan_son",
        "division_type": "xã",
        "short_codename": "tan_son",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã An Quảng Hữu",
        "code": "xa_an_quang_huu",
        "division_type": "xã",
        "short_codename": "an_quang_huu",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Lưu Nghiệp Anh",
        "code": "xa_luu_nghiep_anh",
        "division_type": "xã",
        "short_codename": "luu_nghiep_anh",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Ngãi Xuyên",
        "code": "xa_ngai_xuyen",
        "division_type": "xã",
        "short_codename": "ngai_xuyen",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Kim Sơn",
        "code": "xa_kim_son",
        "division_type": "xã",
        "short_codename": "kim_son",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Thanh Sơn",
        "code": "xa_thanh_son",
        "division_type": "xã",
        "short_codename": "thanh_son",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Hàm Giang",
        "code": "xa_ham_giang",
        "division_type": "xã",
        "short_codename": "ham_giang",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Hàm Tân",
        "code": "xa_ham_tan",
        "division_type": "xã",
        "short_codename": "ham_tan",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Đại An",
        "code": "xa_dai_an",
        "division_type": "xã",
        "short_codename": "dai_an",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Định An",
        "code": "xa_dinh_an",
        "division_type": "xã",
        "short_codename": "xa_dinh_an",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Ngọc Biên",
        "code": "xa_ngoc_bien",
        "division_type": "xã",
        "short_codename": "ngoc_bien",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Long Hiệp",
        "code": "xa_long_hiep",
        "division_type": "xã",
        "short_codename": "long_hiep",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Tân Hiệp",
        "code": "xa_tan_hiep",
        "division_type": "xã",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_tra_cu"
    },
    {
        "name": "Xã Đôn Xuân",
        "code": "xa_don_xuan",
        "division_type": "xã",
        "short_codename": "don_xuan",
        "parentCode": "huyen_duyen_hai"
    },
    {
        "name": "Xã Đôn Châu",
        "code": "xa_don_chau",
        "division_type": "xã",
        "short_codename": "don_chau",
        "parentCode": "huyen_duyen_hai"
    },
    {
        "name": "Thị trấn Long Thành",
        "code": "thi_tran_long_thanh",
        "division_type": "thị trấn",
        "short_codename": "long_thanh",
        "parentCode": "huyen_duyen_hai"
    },
    {
        "name": "Xã Long Khánh",
        "code": "xa_long_khanh",
        "division_type": "xã",
        "short_codename": "long_khanh",
        "parentCode": "huyen_duyen_hai"
    },
    {
        "name": "Xã Ngũ Lạc",
        "code": "xa_ngu_lac",
        "division_type": "xã",
        "short_codename": "ngu_lac",
        "parentCode": "huyen_duyen_hai"
    },
    {
        "name": "Xã Long Vĩnh",
        "code": "xa_long_vinh",
        "division_type": "xã",
        "short_codename": "long_vinh",
        "parentCode": "huyen_duyen_hai"
    },
    {
        "name": "Xã Đông Hải",
        "code": "xa_dong_hai",
        "division_type": "xã",
        "short_codename": "dong_hai",
        "parentCode": "huyen_duyen_hai"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thi_xa_duyen_hai"
    },
    {
        "name": "Xã Long Toàn",
        "code": "xa_long_toan",
        "division_type": "xã",
        "short_codename": "long_toan",
        "parentCode": "thi_xa_duyen_hai"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thi_xa_duyen_hai"
    },
    {
        "name": "Xã Long Hữu",
        "code": "xa_long_huu",
        "division_type": "xã",
        "short_codename": "long_huu",
        "parentCode": "thi_xa_duyen_hai"
    },
    {
        "name": "Xã Dân Thành",
        "code": "xa_dan_thanh",
        "division_type": "xã",
        "short_codename": "dan_thanh",
        "parentCode": "thi_xa_duyen_hai"
    },
    {
        "name": "Xã Trường Long Hòa",
        "code": "xa_truong_long_hoa",
        "division_type": "xã",
        "short_codename": "truong_long_hoa",
        "parentCode": "thi_xa_duyen_hai"
    },
    {
        "name": "Xã Hiệp Thạnh",
        "code": "xa_hiep_thanh",
        "division_type": "xã",
        "short_codename": "hiep_thanh",
        "parentCode": "thi_xa_duyen_hai"
    },
    {
        "name": "Phường 9",
        "code": "phuong_9",
        "division_type": "phường",
        "short_codename": "phuong_9",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường Tân Ngãi",
        "code": "phuong_tan_ngai",
        "division_type": "phường",
        "short_codename": "tan_ngai",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường Tân Hòa",
        "code": "phuong_tan_hoa",
        "division_type": "phường",
        "short_codename": "tan_hoa",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường Tân Hội",
        "code": "phuong_tan_hoi",
        "division_type": "phường",
        "short_codename": "tan_hoi",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Phường Trường An",
        "code": "phuong_truong_an",
        "division_type": "phường",
        "short_codename": "truong_an",
        "parentCode": "thanh_pho_vinh_long"
    },
    {
        "name": "Thị trấn Long Hồ",
        "code": "thi_tran_long_ho",
        "division_type": "thị trấn",
        "short_codename": "long_ho",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Đồng Phú",
        "code": "xa_dong_phu",
        "division_type": "xã",
        "short_codename": "dong_phu",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Bình Hòa Phước",
        "code": "xa_binh_hoa_phuoc",
        "division_type": "xã",
        "short_codename": "binh_hoa_phuoc",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Hòa Ninh",
        "code": "xa_hoa_ninh",
        "division_type": "xã",
        "short_codename": "hoa_ninh",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Thanh Đức",
        "code": "xa_thanh_duc",
        "division_type": "xã",
        "short_codename": "thanh_duc",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Tân Hạnh",
        "code": "xa_tan_hanh",
        "division_type": "xã",
        "short_codename": "tan_hanh",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Phước Hậu",
        "code": "xa_phuoc_hau",
        "division_type": "xã",
        "short_codename": "phuoc_hau",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Long Phước",
        "code": "xa_long_phuoc",
        "division_type": "xã",
        "short_codename": "long_phuoc",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Phú Đức",
        "code": "xa_phu_duc",
        "division_type": "xã",
        "short_codename": "phu_duc",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Lộc Hòa",
        "code": "xa_loc_hoa",
        "division_type": "xã",
        "short_codename": "loc_hoa",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Long An",
        "code": "xa_long_an",
        "division_type": "xã",
        "short_codename": "long_an",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Phú Quới",
        "code": "xa_phu_quoi",
        "division_type": "xã",
        "short_codename": "phu_quoi",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Thạnh Quới",
        "code": "xa_thanh_quoi",
        "division_type": "xã",
        "short_codename": "thanh_quoi",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Hòa Phú",
        "code": "xa_hoa_phu",
        "division_type": "xã",
        "short_codename": "hoa_phu",
        "parentCode": "huyen_long_ho"
    },
    {
        "name": "Xã Mỹ An",
        "code": "xa_my_an",
        "division_type": "xã",
        "short_codename": "my_an",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã Mỹ Phước",
        "code": "xa_my_phuoc",
        "division_type": "xã",
        "short_codename": "my_phuoc",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã An Phước",
        "code": "xa_an_phuoc",
        "division_type": "xã",
        "short_codename": "an_phuoc",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã Nhơn Phú",
        "code": "xa_nhon_phu",
        "division_type": "xã",
        "short_codename": "nhon_phu",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã Long Mỹ",
        "code": "xa_long_my",
        "division_type": "xã",
        "short_codename": "long_my",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã Hòa Tịnh",
        "code": "xa_hoa_tinh",
        "division_type": "xã",
        "short_codename": "hoa_tinh",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Thị trấn Cái Nhum",
        "code": "thi_tran_cai_nhum",
        "division_type": "thị trấn",
        "short_codename": "cai_nhum",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã Bình Phước",
        "code": "xa_binh_phuoc",
        "division_type": "xã",
        "short_codename": "binh_phuoc",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã Chánh An",
        "code": "xa_chanh_an",
        "division_type": "xã",
        "short_codename": "chanh_an",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã Tân An Hội",
        "code": "xa_tan_an_hoi",
        "division_type": "xã",
        "short_codename": "tan_an_hoi",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Xã Tân Long Hội",
        "code": "xa_tan_long_hoi",
        "division_type": "xã",
        "short_codename": "tan_long_hoi",
        "parentCode": "huyen_mang_thit"
    },
    {
        "name": "Thị trấn Vũng Liêm",
        "code": "thi_tran_vung_liem",
        "division_type": "thị trấn",
        "short_codename": "vung_liem",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Tân Quới Trung",
        "code": "xa_tan_quoi_trung",
        "division_type": "xã",
        "short_codename": "tan_quoi_trung",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Quới Thiện",
        "code": "xa_quoi_thien",
        "division_type": "xã",
        "short_codename": "quoi_thien",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Quới An",
        "code": "xa_quoi_an",
        "division_type": "xã",
        "short_codename": "quoi_an",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Trung Chánh",
        "code": "xa_trung_chanh",
        "division_type": "xã",
        "short_codename": "trung_chanh",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Tân An Luông",
        "code": "xa_tan_an_luong",
        "division_type": "xã",
        "short_codename": "tan_an_luong",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Thanh Bình",
        "code": "xa_thanh_binh",
        "division_type": "xã",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Trung Thành Tây",
        "code": "xa_trung_thanh_tay",
        "division_type": "xã",
        "short_codename": "trung_thanh_tay",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Trung Hiệp",
        "code": "xa_trung_hiep",
        "division_type": "xã",
        "short_codename": "trung_hiep",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Hiếu Phụng",
        "code": "xa_hieu_phung",
        "division_type": "xã",
        "short_codename": "hieu_phung",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Trung Thành Đông",
        "code": "xa_trung_thanh_dong",
        "division_type": "xã",
        "short_codename": "trung_thanh_dong",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Trung Thành",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Trung Hiếu",
        "code": "xa_trung_hieu",
        "division_type": "xã",
        "short_codename": "trung_hieu",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Trung Ngãi",
        "code": "xa_trung_ngai",
        "division_type": "xã",
        "short_codename": "trung_ngai",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Hiếu Thuận",
        "code": "xa_hieu_thuan",
        "division_type": "xã",
        "short_codename": "hieu_thuan",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Trung Nghĩa",
        "code": "xa_trung_nghia",
        "division_type": "xã",
        "short_codename": "trung_nghia",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Trung An",
        "code": "xa_trung_an",
        "division_type": "xã",
        "short_codename": "trung_an",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Hiếu Nhơn",
        "code": "xa_hieu_nhon",
        "division_type": "xã",
        "short_codename": "hieu_nhon",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Hiếu Thành",
        "code": "xa_hieu_thanh",
        "division_type": "xã",
        "short_codename": "hieu_thanh",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Xã Hiếu Nghĩa",
        "code": "xa_hieu_nghia",
        "division_type": "xã",
        "short_codename": "hieu_nghia",
        "parentCode": "huyen_vung_liem"
    },
    {
        "name": "Thị trấn Tam Bình",
        "code": "thi_tran_tam_binh",
        "division_type": "thị trấn",
        "short_codename": "tam_binh",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Tân Lộc",
        "code": "xa_tan_loc",
        "division_type": "xã",
        "short_codename": "tan_loc",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Phú Thịnh",
        "code": "xa_phu_thinh",
        "division_type": "xã",
        "short_codename": "phu_thinh",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Hậu Lộc",
        "code": "xa_hau_loc",
        "division_type": "xã",
        "short_codename": "hau_loc",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Hòa Thạnh",
        "code": "xa_hoa_thanh",
        "division_type": "xã",
        "short_codename": "hoa_thanh",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Hoà Lộc",
        "code": "xa_hoa_loc",
        "division_type": "xã",
        "short_codename": "hoa_loc",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Phú Lộc",
        "code": "xa_phu_loc",
        "division_type": "xã",
        "short_codename": "phu_loc",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Song Phú",
        "code": "xa_song_phu",
        "division_type": "xã",
        "short_codename": "song_phu",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Hòa Hiệp",
        "code": "xa_hoa_hiep",
        "division_type": "xã",
        "short_codename": "hoa_hiep",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Mỹ Lộc",
        "code": "xa_my_loc",
        "division_type": "xã",
        "short_codename": "my_loc",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Long Phú",
        "code": "xa_long_phu",
        "division_type": "xã",
        "short_codename": "long_phu",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Mỹ Thạnh Trung",
        "code": "xa_my_thanh_trung",
        "division_type": "xã",
        "short_codename": "my_thanh_trung",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Tường Lộc",
        "code": "xa_tuong_loc",
        "division_type": "xã",
        "short_codename": "tuong_loc",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Loan Mỹ",
        "code": "xa_loan_my",
        "division_type": "xã",
        "short_codename": "loan_my",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Ngãi Tứ",
        "code": "xa_ngai_tu",
        "division_type": "xã",
        "short_codename": "ngai_tu",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Xã Bình Ninh",
        "code": "xa_binh_ninh",
        "division_type": "xã",
        "short_codename": "binh_ninh",
        "parentCode": "huyen_tam_binh"
    },
    {
        "name": "Phường Cái Vồn",
        "code": "phuong_cai_von",
        "division_type": "phường",
        "short_codename": "cai_von",
        "parentCode": "thi_xa_binh_minh"
    },
    {
        "name": "Phường Thành Phước",
        "code": "phuong_thanh_phuoc",
        "division_type": "phường",
        "short_codename": "thanh_phuoc",
        "parentCode": "thi_xa_binh_minh"
    },
    {
        "name": "Xã Thuận An",
        "code": "xa_thuan_an",
        "division_type": "xã",
        "short_codename": "thuan_an",
        "parentCode": "thi_xa_binh_minh"
    },
    {
        "name": "Xã Đông Thạnh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "xa_dong_thanh",
        "parentCode": "thi_xa_binh_minh"
    },
    {
        "name": "Xã Đông Bình",
        "code": "xa_dong_binh",
        "division_type": "xã",
        "short_codename": "dong_binh",
        "parentCode": "thi_xa_binh_minh"
    },
    {
        "name": "Phường Đông Thuận",
        "code": "phuong_dong_thuan",
        "division_type": "phường",
        "short_codename": "dong_thuan",
        "parentCode": "thi_xa_binh_minh"
    },
    {
        "name": "Xã Mỹ Hòa",
        "code": "xa_my_hoa",
        "division_type": "xã",
        "short_codename": "my_hoa",
        "parentCode": "thi_xa_binh_minh"
    },
    {
        "name": "Xã Đông Thành",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "xa_dong_thanh",
        "parentCode": "thi_xa_binh_minh"
    },
    {
        "name": "Thị trấn Trà Ôn",
        "code": "thi_tran_tra_on",
        "division_type": "thị trấn",
        "short_codename": "tra_on",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Xuân Hiệp",
        "code": "xa_xuan_hiep",
        "division_type": "xã",
        "short_codename": "xuan_hiep",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Nhơn Bình",
        "code": "xa_nhon_binh",
        "division_type": "xã",
        "short_codename": "nhon_binh",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Thới Hòa",
        "code": "xa_thoi_hoa",
        "division_type": "xã",
        "short_codename": "thoi_hoa",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Trà Côn",
        "code": "xa_tra_con",
        "division_type": "xã",
        "short_codename": "tra_con",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Hựu Thành",
        "code": "xa_huu_thanh",
        "division_type": "xã",
        "short_codename": "huu_thanh",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Vĩnh Xuân",
        "code": "xa_vinh_xuan",
        "division_type": "xã",
        "short_codename": "vinh_xuan",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Thuận Thới",
        "code": "xa_thuan_thoi",
        "division_type": "xã",
        "short_codename": "thuan_thoi",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Phú Thành",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "phu_thanh",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Thiện Mỹ",
        "code": "xa_thien_my",
        "division_type": "xã",
        "short_codename": "thien_my",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Lục Sỹ Thành",
        "code": "xa_luc_sy_thanh",
        "division_type": "xã",
        "short_codename": "luc_sy_thanh",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Tích Thiện",
        "code": "xa_tich_thien",
        "division_type": "xã",
        "short_codename": "tich_thien",
        "parentCode": "huyen_tra_on"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Xã Thành Trung",
        "code": "xa_thanh_trung",
        "division_type": "xã",
        "short_codename": "thanh_trung",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Xã Tân An Thạnh",
        "code": "xa_tan_an_thanh",
        "division_type": "xã",
        "short_codename": "tan_an_thanh",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Xã Tân Lược",
        "code": "xa_tan_luoc",
        "division_type": "xã",
        "short_codename": "tan_luoc",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Xã Nguyễn Văn Thảnh",
        "code": "xa_nguyen_van_thanh",
        "division_type": "xã",
        "short_codename": "nguyen_van_thanh",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Xã Thành Lợi",
        "code": "xa_thanh_loi",
        "division_type": "xã",
        "short_codename": "thanh_loi",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Xã Mỹ Thuận",
        "code": "xa_my_thuan",
        "division_type": "xã",
        "short_codename": "my_thuan",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Thị trấn Tân Quới",
        "code": "thi_tran_tan_quoi",
        "division_type": "thị trấn",
        "short_codename": "tan_quoi",
        "parentCode": "huyen_binh_tan"
    },
    {
        "name": "Phường 11",
        "code": "phuong_11",
        "division_type": "phường",
        "short_codename": "phuong_11",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Xã Mỹ Ngãi",
        "code": "xa_my_ngai",
        "division_type": "xã",
        "short_codename": "my_ngai",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Xã Mỹ Tân",
        "code": "xa_my_tan",
        "division_type": "xã",
        "short_codename": "my_tan",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Xã Mỹ Trà",
        "code": "xa_my_tra",
        "division_type": "xã",
        "short_codename": "my_tra",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Phường Mỹ Phú",
        "code": "phuong_my_phu",
        "division_type": "phường",
        "short_codename": "my_phu",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Xã Tân Thuận Tây",
        "code": "xa_tan_thuan_tay",
        "division_type": "xã",
        "short_codename": "tan_thuan_tay",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Phường Hoà Thuận",
        "code": "phuong_hoa_thuan",
        "division_type": "phường",
        "short_codename": "hoa_thuan",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Xã Hòa An",
        "code": "xa_hoa_an",
        "division_type": "xã",
        "short_codename": "hoa_an",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Xã Tân Thuận Đông",
        "code": "xa_tan_thuan_dong",
        "division_type": "xã",
        "short_codename": "tan_thuan_dong",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Xã Tịnh Thới",
        "code": "xa_tinh_thoi",
        "division_type": "xã",
        "short_codename": "tinh_thoi",
        "parentCode": "thanh_pho_cao_lanh"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_sa_dec"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_sa_dec"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_sa_dec"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_sa_dec"
    },
    {
        "name": "Xã Tân Khánh Đông",
        "code": "xa_tan_khanh_dong",
        "division_type": "xã",
        "short_codename": "tan_khanh_dong",
        "parentCode": "thanh_pho_sa_dec"
    },
    {
        "name": "Phường Tân Quy Đông",
        "code": "phuong_tan_quy_dong",
        "division_type": "phường",
        "short_codename": "tan_quy_dong",
        "parentCode": "thanh_pho_sa_dec"
    },
    {
        "name": "Phường An Hoà",
        "code": "phuong_an_hoa",
        "division_type": "phường",
        "short_codename": "an_hoa",
        "parentCode": "thanh_pho_sa_dec"
    },
    {
        "name": "Xã Tân Quy Tây",
        "code": "xa_tan_quy_tay",
        "division_type": "xã",
        "short_codename": "tan_quy_tay",
        "parentCode": "thanh_pho_sa_dec"
    },
    {
        "name": "Xã Tân Phú Đông",
        "code": "xa_tan_phu_dong",
        "division_type": "xã",
        "short_codename": "tan_phu_dong",
        "parentCode": "thanh_pho_sa_dec"
    },
    {
        "name": "Phường An Lộc",
        "code": "phuong_an_loc",
        "division_type": "phường",
        "short_codename": "an_loc",
        "parentCode": "thanh_pho_hong_ngu"
    },
    {
        "name": "Phường An Thạnh",
        "code": "phuong_an_thanh",
        "division_type": "phường",
        "short_codename": "an_thanh",
        "parentCode": "thanh_pho_hong_ngu"
    },
    {
        "name": "Xã Bình Thạnh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "thanh_pho_hong_ngu"
    },
    {
        "name": "Xã Tân Hội",
        "code": "xa_tan_hoi",
        "division_type": "xã",
        "short_codename": "tan_hoi",
        "parentCode": "thanh_pho_hong_ngu"
    },
    {
        "name": "Phường An Lạc",
        "code": "phuong_an_lac",
        "division_type": "phường",
        "short_codename": "an_lac",
        "parentCode": "thanh_pho_hong_ngu"
    },
    {
        "name": "Phường An Bình B",
        "code": "phuong_an_binh_b",
        "division_type": "phường",
        "short_codename": "an_binh_b",
        "parentCode": "thanh_pho_hong_ngu"
    },
    {
        "name": "Phường An Bình A",
        "code": "phuong_an_binh_a",
        "division_type": "phường",
        "short_codename": "an_binh_a",
        "parentCode": "thanh_pho_hong_ngu"
    },
    {
        "name": "Thị trấn Sa Rài",
        "code": "thi_tran_sa_rai",
        "division_type": "thị trấn",
        "short_codename": "sa_rai",
        "parentCode": "huyen_tan_hong"
    },
    {
        "name": "Xã Tân Hộ Cơ",
        "code": "xa_tan_ho_co",
        "division_type": "xã",
        "short_codename": "tan_ho_co",
        "parentCode": "huyen_tan_hong"
    },
    {
        "name": "Xã Thông Bình",
        "code": "xa_thong_binh",
        "division_type": "xã",
        "short_codename": "thong_binh",
        "parentCode": "huyen_tan_hong"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "huyen_tan_hong"
    },
    {
        "name": "Xã Tân Thành A",
        "code": "xa_tan_thanh_a",
        "division_type": "xã",
        "short_codename": "tan_thanh_a",
        "parentCode": "huyen_tan_hong"
    },
    {
        "name": "Xã Tân Thành B",
        "code": "xa_tan_thanh_b",
        "division_type": "xã",
        "short_codename": "tan_thanh_b",
        "parentCode": "huyen_tan_hong"
    },
    {
        "name": "Xã Tân Phước",
        "code": "xa_tan_phuoc",
        "division_type": "xã",
        "short_codename": "tan_phuoc",
        "parentCode": "huyen_tan_hong"
    },
    {
        "name": "Xã Tân Công Chí",
        "code": "xa_tan_cong_chi",
        "division_type": "xã",
        "short_codename": "tan_cong_chi",
        "parentCode": "huyen_tan_hong"
    },
    {
        "name": "Xã An Phước",
        "code": "xa_an_phuoc",
        "division_type": "xã",
        "short_codename": "an_phuoc",
        "parentCode": "huyen_tan_hong"
    },
    {
        "name": "Xã Thường Phước 1",
        "code": "xa_thuong_phuoc_1",
        "division_type": "xã",
        "short_codename": "thuong_phuoc_1",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Xã Thường Thới Hậu A",
        "code": "xa_thuong_thoi_hau_a",
        "division_type": "xã",
        "short_codename": "thuong_thoi_hau_a",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Thị trấn Thường Thới Tiền",
        "code": "thi_tran_thuong_thoi_tien",
        "division_type": "thị trấn",
        "short_codename": "thuong_thoi_tien",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Xã Thường Phước 2",
        "code": "xa_thuong_phuoc_2",
        "division_type": "xã",
        "short_codename": "thuong_phuoc_2",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Xã Thường Lạc",
        "code": "xa_thuong_lac",
        "division_type": "xã",
        "short_codename": "thuong_lac",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Xã Long Khánh A",
        "code": "xa_long_khanh_a",
        "division_type": "xã",
        "short_codename": "long_khanh_a",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Xã Long Khánh B",
        "code": "xa_long_khanh_b",
        "division_type": "xã",
        "short_codename": "long_khanh_b",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Xã Long Thuận",
        "code": "xa_long_thuan",
        "division_type": "xã",
        "short_codename": "long_thuan",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Xã Phú Thuận B",
        "code": "xa_phu_thuan_b",
        "division_type": "xã",
        "short_codename": "phu_thuan_b",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Xã Phú Thuận A",
        "code": "xa_phu_thuan_a",
        "division_type": "xã",
        "short_codename": "phu_thuan_a",
        "parentCode": "huyen_hong_ngu"
    },
    {
        "name": "Thị trấn Tràm Chim",
        "code": "thi_tran_tram_chim",
        "division_type": "thị trấn",
        "short_codename": "tram_chim",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Hoà Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Tân Công Sính",
        "code": "xa_tan_cong_sinh",
        "division_type": "xã",
        "short_codename": "tan_cong_sinh",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Phú Hiệp",
        "code": "xa_phu_hiep",
        "division_type": "xã",
        "short_codename": "phu_hiep",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Phú Đức",
        "code": "xa_phu_duc",
        "division_type": "xã",
        "short_codename": "phu_duc",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Phú Thành B",
        "code": "xa_phu_thanh_b",
        "division_type": "xã",
        "short_codename": "phu_thanh_b",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã An Hòa",
        "code": "xa_an_hoa",
        "division_type": "xã",
        "short_codename": "an_hoa",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã An Long",
        "code": "xa_an_long",
        "division_type": "xã",
        "short_codename": "an_long",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Phú Cường",
        "code": "xa_phu_cuong",
        "division_type": "xã",
        "short_codename": "phu_cuong",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Phú Ninh",
        "code": "xa_phu_ninh",
        "division_type": "xã",
        "short_codename": "phu_ninh",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Phú Thọ",
        "code": "xa_phu_tho",
        "division_type": "xã",
        "short_codename": "phu_tho",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Xã Phú Thành A",
        "code": "xa_phu_thanh_a",
        "division_type": "xã",
        "short_codename": "phu_thanh_a",
        "parentCode": "huyen_tam_nong"
    },
    {
        "name": "Thị trấn Mỹ An",
        "code": "thi_tran_my_an",
        "division_type": "thị trấn",
        "short_codename": "my_an",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Thạnh Lợi",
        "code": "xa_thanh_loi",
        "division_type": "xã",
        "short_codename": "thanh_loi",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Hưng Thạnh",
        "code": "xa_hung_thanh",
        "division_type": "xã",
        "short_codename": "hung_thanh",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Trường Xuân",
        "code": "xa_truong_xuan",
        "division_type": "xã",
        "short_codename": "truong_xuan",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Tân Kiều",
        "code": "xa_tan_kieu",
        "division_type": "xã",
        "short_codename": "tan_kieu",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Mỹ Hòa",
        "code": "xa_my_hoa",
        "division_type": "xã",
        "short_codename": "my_hoa",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Mỹ Quý",
        "code": "xa_my_quy",
        "division_type": "xã",
        "short_codename": "my_quy",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Mỹ Đông",
        "code": "xa_my_dong",
        "division_type": "xã",
        "short_codename": "my_dong",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Đốc Binh Kiều",
        "code": "xa_doc_binh_kieu",
        "division_type": "xã",
        "short_codename": "doc_binh_kieu",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Mỹ An",
        "code": "xa_my_an",
        "division_type": "xã",
        "short_codename": "xa_my_an",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Phú Điền",
        "code": "xa_phu_dien",
        "division_type": "xã",
        "short_codename": "phu_dien",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Láng Biển",
        "code": "xa_lang_bien",
        "division_type": "xã",
        "short_codename": "lang_bien",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Xã Thanh Mỹ",
        "code": "xa_thanh_my",
        "division_type": "xã",
        "short_codename": "thanh_my",
        "parentCode": "huyen_thap_muoi"
    },
    {
        "name": "Thị trấn Mỹ Thọ",
        "code": "thi_tran_my_tho",
        "division_type": "thị trấn",
        "short_codename": "my_tho",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Gáo Giồng",
        "code": "xa_gao_giong",
        "division_type": "xã",
        "short_codename": "gao_giong",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Phương Thịnh",
        "code": "xa_phuong_thinh",
        "division_type": "xã",
        "short_codename": "phuong_thinh",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Ba Sao",
        "code": "xa_ba_sao",
        "division_type": "xã",
        "short_codename": "ba_sao",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Phong Mỹ",
        "code": "xa_phong_my",
        "division_type": "xã",
        "short_codename": "phong_my",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Tân Nghĩa",
        "code": "xa_tan_nghia",
        "division_type": "xã",
        "short_codename": "tan_nghia",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Phương Trà",
        "code": "xa_phuong_tra",
        "division_type": "xã",
        "short_codename": "phuong_tra",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Nhị Mỹ",
        "code": "xa_nhi_my",
        "division_type": "xã",
        "short_codename": "nhi_my",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Mỹ Thọ",
        "code": "xa_my_tho",
        "division_type": "xã",
        "short_codename": "xa_my_tho",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Tân Hội Trung",
        "code": "xa_tan_hoi_trung",
        "division_type": "xã",
        "short_codename": "tan_hoi_trung",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Mỹ Hội",
        "code": "xa_my_hoi",
        "division_type": "xã",
        "short_codename": "my_hoi",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Mỹ Hiệp",
        "code": "xa_my_hiep",
        "division_type": "xã",
        "short_codename": "my_hiep",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Mỹ Long",
        "code": "xa_my_long",
        "division_type": "xã",
        "short_codename": "my_long",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Bình Hàng Trung",
        "code": "xa_binh_hang_trung",
        "division_type": "xã",
        "short_codename": "binh_hang_trung",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Mỹ Xương",
        "code": "xa_my_xuong",
        "division_type": "xã",
        "short_codename": "my_xuong",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Bình Hàng Tây",
        "code": "xa_binh_hang_tay",
        "division_type": "xã",
        "short_codename": "binh_hang_tay",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Xã Bình Thạnh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_cao_lanh"
    },
    {
        "name": "Thị trấn Thanh Bình",
        "code": "thi_tran_thanh_binh",
        "division_type": "thị trấn",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Tân Quới",
        "code": "xa_tan_quoi",
        "division_type": "xã",
        "short_codename": "tan_quoi",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã An Phong",
        "code": "xa_an_phong",
        "division_type": "xã",
        "short_codename": "an_phong",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Phú Lợi",
        "code": "xa_phu_loi",
        "division_type": "xã",
        "short_codename": "phu_loi",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Bình Tấn",
        "code": "xa_binh_tan",
        "division_type": "xã",
        "short_codename": "binh_tan",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Tân Huề",
        "code": "xa_tan_hue",
        "division_type": "xã",
        "short_codename": "tan_hue",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Tân Thạnh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Bình Thành",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "huyen_thanh_binh"
    },
    {
        "name": "Thị trấn Lấp Vò",
        "code": "thi_tran_lap_vo",
        "division_type": "thị trấn",
        "short_codename": "lap_vo",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Mỹ An Hưng A",
        "code": "xa_my_an_hung_a",
        "division_type": "xã",
        "short_codename": "my_an_hung_a",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Tân Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Mỹ An Hưng B",
        "code": "xa_my_an_hung_b",
        "division_type": "xã",
        "short_codename": "my_an_hung_b",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Tân Khánh Trung",
        "code": "xa_tan_khanh_trung",
        "division_type": "xã",
        "short_codename": "tan_khanh_trung",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Long Hưng A",
        "code": "xa_long_hung_a",
        "division_type": "xã",
        "short_codename": "long_hung_a",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Vĩnh Thạnh",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Long Hưng B",
        "code": "xa_long_hung_b",
        "division_type": "xã",
        "short_codename": "long_hung_b",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Bình Thành",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Định An",
        "code": "xa_dinh_an",
        "division_type": "xã",
        "short_codename": "dinh_an",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Định Yên",
        "code": "xa_dinh_yen",
        "division_type": "xã",
        "short_codename": "dinh_yen",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Hội An Đông",
        "code": "xa_hoi_an_dong",
        "division_type": "xã",
        "short_codename": "hoi_an_dong",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Xã Bình Thạnh Trung",
        "code": "xa_binh_thanh_trung",
        "division_type": "xã",
        "short_codename": "binh_thanh_trung",
        "parentCode": "huyen_lap_vo"
    },
    {
        "name": "Thị trấn Lai Vung",
        "code": "thi_tran_lai_vung",
        "division_type": "thị trấn",
        "short_codename": "lai_vung",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Tân Dương",
        "code": "xa_tan_duong",
        "division_type": "xã",
        "short_codename": "tan_duong",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Hòa Thành",
        "code": "xa_hoa_thanh",
        "division_type": "xã",
        "short_codename": "hoa_thanh",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Long Hậu",
        "code": "xa_long_hau",
        "division_type": "xã",
        "short_codename": "long_hau",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Tân Phước",
        "code": "xa_tan_phuoc",
        "division_type": "xã",
        "short_codename": "tan_phuoc",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Hòa Long",
        "code": "xa_hoa_long",
        "division_type": "xã",
        "short_codename": "hoa_long",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Long Thắng",
        "code": "xa_long_thang",
        "division_type": "xã",
        "short_codename": "long_thang",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Vĩnh Thới",
        "code": "xa_vinh_thoi",
        "division_type": "xã",
        "short_codename": "vinh_thoi",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Định Hòa",
        "code": "xa_dinh_hoa",
        "division_type": "xã",
        "short_codename": "dinh_hoa",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Xã Phong Hòa",
        "code": "xa_phong_hoa",
        "division_type": "xã",
        "short_codename": "phong_hoa",
        "parentCode": "huyen_lai_vung"
    },
    {
        "name": "Thị trấn Cái Tàu Hạ",
        "code": "thi_tran_cai_tau_ha",
        "division_type": "thị trấn",
        "short_codename": "cai_tau_ha",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Hiệp",
        "code": "xa_an_hiep",
        "division_type": "xã",
        "short_codename": "an_hiep",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Nhơn",
        "code": "xa_an_nhon",
        "division_type": "xã",
        "short_codename": "an_nhon",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Nhuận Đông",
        "code": "xa_tan_nhuan_dong",
        "division_type": "xã",
        "short_codename": "tan_nhuan_dong",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Phú Trung",
        "code": "xa_tan_phu_trung",
        "division_type": "xã",
        "short_codename": "tan_phu_trung",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Long",
        "code": "xa_phu_long",
        "division_type": "xã",
        "short_codename": "phu_long",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Phú Thuận",
        "code": "xa_an_phu_thuan",
        "division_type": "xã",
        "short_codename": "an_phu_thuan",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Hựu",
        "code": "xa_phu_huu",
        "division_type": "xã",
        "short_codename": "phu_huu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Khánh",
        "code": "xa_an_khanh",
        "division_type": "xã",
        "short_codename": "an_khanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hòa Tân",
        "code": "xa_hoa_tan",
        "division_type": "xã",
        "short_codename": "hoa_tan",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Phường Mỹ Bình",
        "code": "phuong_my_binh",
        "division_type": "phường",
        "short_codename": "my_binh",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Mỹ Long",
        "code": "phuong_my_long",
        "division_type": "phường",
        "short_codename": "my_long",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Đông Xuyên",
        "code": "phuong_dong_xuyen",
        "division_type": "phường",
        "short_codename": "dong_xuyen",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Mỹ Xuyên",
        "code": "phuong_my_xuyen",
        "division_type": "phường",
        "short_codename": "my_xuyen",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Bình Đức",
        "code": "phuong_binh_duc",
        "division_type": "phường",
        "short_codename": "binh_duc",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Bình Khánh",
        "code": "phuong_binh_khanh",
        "division_type": "phường",
        "short_codename": "binh_khanh",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Mỹ Phước",
        "code": "phuong_my_phuoc",
        "division_type": "phường",
        "short_codename": "my_phuoc",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Mỹ Quý",
        "code": "phuong_my_quy",
        "division_type": "phường",
        "short_codename": "my_quy",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Mỹ Thới",
        "code": "phuong_my_thoi",
        "division_type": "phường",
        "short_codename": "my_thoi",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Mỹ Thạnh",
        "code": "phuong_my_thanh",
        "division_type": "phường",
        "short_codename": "my_thanh",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Mỹ Hòa",
        "code": "phuong_my_hoa",
        "division_type": "phường",
        "short_codename": "my_hoa",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Xã Mỹ Khánh",
        "code": "xa_my_khanh",
        "division_type": "xã",
        "short_codename": "my_khanh",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Xã Mỹ Hoà Hưng",
        "code": "xa_my_hoa_hung",
        "division_type": "xã",
        "short_codename": "my_hoa_hung",
        "parentCode": "thanh_pho_long_xuyen"
    },
    {
        "name": "Phường Châu Phú B",
        "code": "phuong_chau_phu_b",
        "division_type": "phường",
        "short_codename": "chau_phu_b",
        "parentCode": "thanh_pho_chau_doc"
    },
    {
        "name": "Phường Châu Phú A",
        "code": "phuong_chau_phu_a",
        "division_type": "phường",
        "short_codename": "chau_phu_a",
        "parentCode": "thanh_pho_chau_doc"
    },
    {
        "name": "Phường Vĩnh Mỹ",
        "code": "phuong_vinh_my",
        "division_type": "phường",
        "short_codename": "vinh_my",
        "parentCode": "thanh_pho_chau_doc"
    },
    {
        "name": "Phường Núi Sam",
        "code": "phuong_nui_sam",
        "division_type": "phường",
        "short_codename": "nui_sam",
        "parentCode": "thanh_pho_chau_doc"
    },
    {
        "name": "Phường Vĩnh Ngươn",
        "code": "phuong_vinh_nguon",
        "division_type": "phường",
        "short_codename": "vinh_nguon",
        "parentCode": "thanh_pho_chau_doc"
    },
    {
        "name": "Xã Vĩnh Tế",
        "code": "xa_vinh_te",
        "division_type": "xã",
        "short_codename": "vinh_te",
        "parentCode": "thanh_pho_chau_doc"
    },
    {
        "name": "Xã Vĩnh Châu",
        "code": "xa_vinh_chau",
        "division_type": "xã",
        "short_codename": "vinh_chau",
        "parentCode": "thanh_pho_chau_doc"
    },
    {
        "name": "Thị trấn An Phú",
        "code": "thi_tran_an_phu",
        "division_type": "thị trấn",
        "short_codename": "an_phu",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Khánh An",
        "code": "xa_khanh_an",
        "division_type": "xã",
        "short_codename": "khanh_an",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Thị Trấn Long Bình",
        "code": "thi_tran_long_binh",
        "division_type": "thị trấn",
        "short_codename": "long_binh",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Khánh Bình",
        "code": "xa_khanh_binh",
        "division_type": "xã",
        "short_codename": "khanh_binh",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Quốc Thái",
        "code": "xa_quoc_thai",
        "division_type": "xã",
        "short_codename": "quoc_thai",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Nhơn Hội",
        "code": "xa_nhon_hoi",
        "division_type": "xã",
        "short_codename": "nhon_hoi",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Phú Hữu",
        "code": "xa_phu_huu",
        "division_type": "xã",
        "short_codename": "phu_huu",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Phú Hội",
        "code": "xa_phu_hoi",
        "division_type": "xã",
        "short_codename": "phu_hoi",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Phước Hưng",
        "code": "xa_phuoc_hung",
        "division_type": "xã",
        "short_codename": "phuoc_hung",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Vĩnh Lộc",
        "code": "xa_vinh_loc",
        "division_type": "xã",
        "short_codename": "vinh_loc",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Vĩnh Hậu",
        "code": "xa_vinh_hau",
        "division_type": "xã",
        "short_codename": "vinh_hau",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Vĩnh Trường",
        "code": "xa_vinh_truong",
        "division_type": "xã",
        "short_codename": "vinh_truong",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Vĩnh Hội Đông",
        "code": "xa_vinh_hoi_dong",
        "division_type": "xã",
        "short_codename": "vinh_hoi_dong",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Xã Đa Phước",
        "code": "xa_da_phuoc",
        "division_type": "xã",
        "short_codename": "da_phuoc",
        "parentCode": "huyen_an_phu"
    },
    {
        "name": "Phường Long Thạnh",
        "code": "phuong_long_thanh",
        "division_type": "phường",
        "short_codename": "long_thanh",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Phường Long Hưng",
        "code": "phuong_long_hung",
        "division_type": "phường",
        "short_codename": "long_hung",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Phường Long Châu",
        "code": "phuong_long_chau",
        "division_type": "phường",
        "short_codename": "long_chau",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Xã Phú Lộc",
        "code": "xa_phu_loc",
        "division_type": "xã",
        "short_codename": "phu_loc",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Xã Vĩnh Xương",
        "code": "xa_vinh_xuong",
        "division_type": "xã",
        "short_codename": "vinh_xuong",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Xã Vĩnh Hòa",
        "code": "xa_vinh_hoa",
        "division_type": "xã",
        "short_codename": "vinh_hoa",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Xã Tân Thạnh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Xã Tân An",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Xã Long An",
        "code": "xa_long_an",
        "division_type": "xã",
        "short_codename": "long_an",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Phường Long Phú",
        "code": "phuong_long_phu",
        "division_type": "phường",
        "short_codename": "long_phu",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Xã Châu Phong",
        "code": "xa_chau_phong",
        "division_type": "xã",
        "short_codename": "chau_phong",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Xã Phú Vĩnh",
        "code": "xa_phu_vinh",
        "division_type": "xã",
        "short_codename": "phu_vinh",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Xã Lê Chánh",
        "code": "xa_le_chanh",
        "division_type": "xã",
        "short_codename": "le_chanh",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Phường Long Sơn",
        "code": "phuong_long_son",
        "division_type": "phường",
        "short_codename": "long_son",
        "parentCode": "thi_xa_tan_chau"
    },
    {
        "name": "Thị trấn Phú Mỹ",
        "code": "thi_tran_phu_my",
        "division_type": "thị trấn",
        "short_codename": "phu_my",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Thị trấn Chợ Vàm",
        "code": "thi_tran_cho_vam",
        "division_type": "thị trấn",
        "short_codename": "cho_vam",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Long Hoà",
        "code": "xa_long_hoa",
        "division_type": "xã",
        "short_codename": "long_hoa",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Long",
        "code": "xa_phu_long",
        "division_type": "xã",
        "short_codename": "phu_long",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Lâm",
        "code": "xa_phu_lam",
        "division_type": "xã",
        "short_codename": "phu_lam",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Hiệp",
        "code": "xa_phu_hiep",
        "division_type": "xã",
        "short_codename": "phu_hiep",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Thạnh",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "xa_phu_thanh",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Hoà Lạc",
        "code": "xa_hoa_lac",
        "division_type": "xã",
        "short_codename": "hoa_lac",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Thành",
        "code": "xa_phu_thanh",
        "division_type": "xã",
        "short_codename": "xa_phu_thanh",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú An",
        "code": "xa_phu_an",
        "division_type": "xã",
        "short_codename": "phu_an",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Xuân",
        "code": "xa_phu_xuan",
        "division_type": "xã",
        "short_codename": "phu_xuan",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Hiệp Xương",
        "code": "xa_hiep_xuong",
        "division_type": "xã",
        "short_codename": "hiep_xuong",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Bình",
        "code": "xa_phu_binh",
        "division_type": "xã",
        "short_codename": "phu_binh",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Thọ",
        "code": "xa_phu_tho",
        "division_type": "xã",
        "short_codename": "phu_tho",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Hưng",
        "code": "xa_phu_hung",
        "division_type": "xã",
        "short_codename": "phu_hung",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Bình Thạnh Đông",
        "code": "xa_binh_thanh_dong",
        "division_type": "xã",
        "short_codename": "binh_thanh_dong",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Tân Hòa",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Tân Trung",
        "code": "xa_tan_trung",
        "division_type": "xã",
        "short_codename": "tan_trung",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Thị trấn Cái Dầu",
        "code": "thi_tran_cai_dau",
        "division_type": "thị trấn",
        "short_codename": "cai_dau",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Khánh Hòa",
        "code": "xa_khanh_hoa",
        "division_type": "xã",
        "short_codename": "khanh_hoa",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Mỹ Đức",
        "code": "xa_my_duc",
        "division_type": "xã",
        "short_codename": "my_duc",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Mỹ Phú",
        "code": "xa_my_phu",
        "division_type": "xã",
        "short_codename": "my_phu",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Ô Long Vỹ",
        "code": "xa_o_long_vy",
        "division_type": "xã",
        "short_codename": "o_long_vy",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Thị trấn Vĩnh Thạnh Trung",
        "code": "thi_tran_vinh_thanh_trung",
        "division_type": "thị trấn",
        "short_codename": "vinh_thanh_trung",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Thạnh Mỹ Tây",
        "code": "xa_thanh_my_tay",
        "division_type": "xã",
        "short_codename": "thanh_my_tay",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Bình Long",
        "code": "xa_binh_long",
        "division_type": "xã",
        "short_codename": "binh_long",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Bình Mỹ",
        "code": "xa_binh_my",
        "division_type": "xã",
        "short_codename": "binh_my",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Bình Thủy",
        "code": "xa_binh_thuy",
        "division_type": "xã",
        "short_codename": "binh_thuy",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Đào Hữu Cảnh",
        "code": "xa_dao_huu_canh",
        "division_type": "xã",
        "short_codename": "dao_huu_canh",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Bình Phú",
        "code": "xa_binh_phu",
        "division_type": "xã",
        "short_codename": "binh_phu",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Xã Bình Chánh",
        "code": "xa_binh_chanh",
        "division_type": "xã",
        "short_codename": "binh_chanh",
        "parentCode": "huyen_chau_phu"
    },
    {
        "name": "Thị trấn Nhà Bàng",
        "code": "thi_tran_nha_bang",
        "division_type": "thị trấn",
        "short_codename": "nha_bang",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Thị trấn Chi Lăng",
        "code": "thi_tran_chi_lang",
        "division_type": "thị trấn",
        "short_codename": "chi_lang",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã Núi Voi",
        "code": "xa_nui_voi",
        "division_type": "xã",
        "short_codename": "nui_voi",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã Nhơn Hưng",
        "code": "xa_nhon_hung",
        "division_type": "xã",
        "short_codename": "nhon_hung",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã An Phú",
        "code": "xa_an_phu",
        "division_type": "xã",
        "short_codename": "an_phu",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã Thới Sơn",
        "code": "xa_thoi_son",
        "division_type": "xã",
        "short_codename": "thoi_son",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Thị trấn Tịnh Biên",
        "code": "thi_tran_tinh_bien",
        "division_type": "thị trấn",
        "short_codename": "tinh_bien",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã Văn Giáo",
        "code": "xa_van_giao",
        "division_type": "xã",
        "short_codename": "van_giao",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã An Cư",
        "code": "xa_an_cu",
        "division_type": "xã",
        "short_codename": "an_cu",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã An Nông",
        "code": "xa_an_nong",
        "division_type": "xã",
        "short_codename": "an_nong",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã Vĩnh Trung",
        "code": "xa_vinh_trung",
        "division_type": "xã",
        "short_codename": "vinh_trung",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã Tân Lợi",
        "code": "xa_tan_loi",
        "division_type": "xã",
        "short_codename": "tan_loi",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã An Hảo",
        "code": "xa_an_hao",
        "division_type": "xã",
        "short_codename": "an_hao",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Xã Tân Lập",
        "code": "xa_tan_lap",
        "division_type": "xã",
        "short_codename": "tan_lap",
        "parentCode": "huyen_tinh_bien"
    },
    {
        "name": "Thị trấn Tri Tôn",
        "code": "thi_tran_tri_ton",
        "division_type": "thị trấn",
        "short_codename": "tri_ton",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Thị trấn Ba Chúc",
        "code": "thi_tran_ba_chuc",
        "division_type": "thị trấn",
        "short_codename": "ba_chuc",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Lạc Quới",
        "code": "xa_lac_quoi",
        "division_type": "xã",
        "short_codename": "lac_quoi",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Lê Trì",
        "code": "xa_le_tri",
        "division_type": "xã",
        "short_codename": "le_tri",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Vĩnh Gia",
        "code": "xa_vinh_gia",
        "division_type": "xã",
        "short_codename": "vinh_gia",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Vĩnh Phước",
        "code": "xa_vinh_phuoc",
        "division_type": "xã",
        "short_codename": "vinh_phuoc",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Châu Lăng",
        "code": "xa_chau_lang",
        "division_type": "xã",
        "short_codename": "chau_lang",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Lương Phi",
        "code": "xa_luong_phi",
        "division_type": "xã",
        "short_codename": "luong_phi",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Lương An Trà",
        "code": "xa_luong_an_tra",
        "division_type": "xã",
        "short_codename": "luong_an_tra",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Tà Đảnh",
        "code": "xa_ta_danh",
        "division_type": "xã",
        "short_codename": "ta_danh",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Núi Tô",
        "code": "xa_nui_to",
        "division_type": "xã",
        "short_codename": "nui_to",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã An Tức",
        "code": "xa_an_tuc",
        "division_type": "xã",
        "short_codename": "an_tuc",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Thị trấn Cô Tô",
        "code": "thi_tran_co_to",
        "division_type": "thị trấn",
        "short_codename": "co_to",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Tân Tuyến",
        "code": "xa_tan_tuyen",
        "division_type": "xã",
        "short_codename": "tan_tuyen",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Xã Ô Lâm",
        "code": "xa_o_lam",
        "division_type": "xã",
        "short_codename": "o_lam",
        "parentCode": "huyen_tri_ton"
    },
    {
        "name": "Thị trấn An Châu",
        "code": "thi_tran_an_chau",
        "division_type": "thị trấn",
        "short_codename": "an_chau",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Hòa",
        "code": "xa_an_hoa",
        "division_type": "xã",
        "short_codename": "an_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Cần Đăng",
        "code": "xa_can_dang",
        "division_type": "xã",
        "short_codename": "can_dang",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Vĩnh Hanh",
        "code": "xa_vinh_hanh",
        "division_type": "xã",
        "short_codename": "vinh_hanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Bình Thạnh",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Thị trấn Vĩnh Bình",
        "code": "thi_tran_vinh_binh",
        "division_type": "thị trấn",
        "short_codename": "vinh_binh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Bình Hòa",
        "code": "xa_binh_hoa",
        "division_type": "xã",
        "short_codename": "binh_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Vĩnh An",
        "code": "xa_vinh_an",
        "division_type": "xã",
        "short_codename": "vinh_an",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hòa Bình Thạnh",
        "code": "xa_hoa_binh_thanh",
        "division_type": "xã",
        "short_codename": "hoa_binh_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Vĩnh Lợi",
        "code": "xa_vinh_loi",
        "division_type": "xã",
        "short_codename": "vinh_loi",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Vĩnh Nhuận",
        "code": "xa_vinh_nhuan",
        "division_type": "xã",
        "short_codename": "vinh_nhuan",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Vĩnh Thành",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Thị trấn Chợ Mới",
        "code": "thi_tran_cho_moi",
        "division_type": "thị trấn",
        "short_codename": "cho_moi",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Thị trấn Mỹ Luông",
        "code": "thi_tran_my_luong",
        "division_type": "thị trấn",
        "short_codename": "my_luong",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Kiến An",
        "code": "xa_kien_an",
        "division_type": "xã",
        "short_codename": "kien_an",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Mỹ Hội Đông",
        "code": "xa_my_hoi_dong",
        "division_type": "xã",
        "short_codename": "my_hoi_dong",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Long Điền A",
        "code": "xa_long_dien_a",
        "division_type": "xã",
        "short_codename": "long_dien_a",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Tấn Mỹ",
        "code": "xa_tan_my",
        "division_type": "xã",
        "short_codename": "tan_my",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Long Điền B",
        "code": "xa_long_dien_b",
        "division_type": "xã",
        "short_codename": "long_dien_b",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Kiến Thành",
        "code": "xa_kien_thanh",
        "division_type": "xã",
        "short_codename": "kien_thanh",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Mỹ Hiệp",
        "code": "xa_my_hiep",
        "division_type": "xã",
        "short_codename": "my_hiep",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Mỹ An",
        "code": "xa_my_an",
        "division_type": "xã",
        "short_codename": "my_an",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Nhơn Mỹ",
        "code": "xa_nhon_my",
        "division_type": "xã",
        "short_codename": "nhon_my",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Long Giang",
        "code": "xa_long_giang",
        "division_type": "xã",
        "short_codename": "long_giang",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Long Kiến",
        "code": "xa_long_kien",
        "division_type": "xã",
        "short_codename": "long_kien",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Bình Phước Xuân",
        "code": "xa_binh_phuoc_xuan",
        "division_type": "xã",
        "short_codename": "binh_phuoc_xuan",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã An Thạnh Trung",
        "code": "xa_an_thanh_trung",
        "division_type": "xã",
        "short_codename": "an_thanh_trung",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Hội An",
        "code": "xa_hoi_an",
        "division_type": "xã",
        "short_codename": "hoi_an",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Hòa Bình",
        "code": "xa_hoa_binh",
        "division_type": "xã",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Xã Hòa An",
        "code": "xa_hoa_an",
        "division_type": "xã",
        "short_codename": "hoa_an",
        "parentCode": "huyen_cho_moi"
    },
    {
        "name": "Thị trấn Núi Sập",
        "code": "thi_tran_nui_sap",
        "division_type": "thị trấn",
        "short_codename": "nui_sap",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Thị trấn Phú Hoà",
        "code": "thi_tran_phu_hoa",
        "division_type": "thị trấn",
        "short_codename": "phu_hoa",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Thị Trấn Óc Eo",
        "code": "thi_tran_oc_eo",
        "division_type": "thị trấn",
        "short_codename": "oc_eo",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Tây Phú",
        "code": "xa_tay_phu",
        "division_type": "xã",
        "short_codename": "tay_phu",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã An Bình",
        "code": "xa_an_binh",
        "division_type": "xã",
        "short_codename": "an_binh",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Vĩnh Phú",
        "code": "xa_vinh_phu",
        "division_type": "xã",
        "short_codename": "vinh_phu",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Vĩnh Trạch",
        "code": "xa_vinh_trach",
        "division_type": "xã",
        "short_codename": "vinh_trach",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Phú Thuận",
        "code": "xa_phu_thuan",
        "division_type": "xã",
        "short_codename": "phu_thuan",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Vĩnh Chánh",
        "code": "xa_vinh_chanh",
        "division_type": "xã",
        "short_codename": "vinh_chanh",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Định Mỹ",
        "code": "xa_dinh_my",
        "division_type": "xã",
        "short_codename": "dinh_my",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Định Thành",
        "code": "xa_dinh_thanh",
        "division_type": "xã",
        "short_codename": "dinh_thanh",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Mỹ Phú Đông",
        "code": "xa_my_phu_dong",
        "division_type": "xã",
        "short_codename": "my_phu_dong",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Vọng Đông",
        "code": "xa_vong_dong",
        "division_type": "xã",
        "short_codename": "vong_dong",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Vĩnh Khánh",
        "code": "xa_vinh_khanh",
        "division_type": "xã",
        "short_codename": "vinh_khanh",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Thoại Giang",
        "code": "xa_thoai_giang",
        "division_type": "xã",
        "short_codename": "thoai_giang",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Bình Thành",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Xã Vọng Thê",
        "code": "xa_vong_the",
        "division_type": "xã",
        "short_codename": "vong_the",
        "parentCode": "huyen_thoai_son"
    },
    {
        "name": "Phường Vĩnh Thanh Vân",
        "code": "phuong_vinh_thanh_van",
        "division_type": "phường",
        "short_codename": "vinh_thanh_van",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường Vĩnh Thanh",
        "code": "phuong_vinh_thanh",
        "division_type": "phường",
        "short_codename": "vinh_thanh",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường Vĩnh Quang",
        "code": "phuong_vinh_quang",
        "division_type": "phường",
        "short_codename": "vinh_quang",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường Vĩnh Hiệp",
        "code": "phuong_vinh_hiep",
        "division_type": "phường",
        "short_codename": "vinh_hiep",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường Vĩnh Bảo",
        "code": "phuong_vinh_bao",
        "division_type": "phường",
        "short_codename": "vinh_bao",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường Vĩnh Lạc",
        "code": "phuong_vinh_lac",
        "division_type": "phường",
        "short_codename": "vinh_lac",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường An Hòa",
        "code": "phuong_an_hoa",
        "division_type": "phường",
        "short_codename": "an_hoa",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường An Bình",
        "code": "phuong_an_binh",
        "division_type": "phường",
        "short_codename": "an_binh",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường Rạch Sỏi",
        "code": "phuong_rach_soi",
        "division_type": "phường",
        "short_codename": "rach_soi",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường Vĩnh Lợi",
        "code": "phuong_vinh_loi",
        "division_type": "phường",
        "short_codename": "vinh_loi",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường Vĩnh Thông",
        "code": "phuong_vinh_thong",
        "division_type": "phường",
        "short_codename": "vinh_thong",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Xã Phi Thông",
        "code": "xa_phi_thong",
        "division_type": "xã",
        "short_codename": "phi_thong",
        "parentCode": "thanh_pho_rach_gia"
    },
    {
        "name": "Phường Tô Châu",
        "code": "phuong_to_chau",
        "division_type": "phường",
        "short_codename": "to_chau",
        "parentCode": "thanh_pho_ha_tien"
    },
    {
        "name": "Phường Đông Hồ",
        "code": "phuong_dong_ho",
        "division_type": "phường",
        "short_codename": "dong_ho",
        "parentCode": "thanh_pho_ha_tien"
    },
    {
        "name": "Phường Bình San",
        "code": "phuong_binh_san",
        "division_type": "phường",
        "short_codename": "binh_san",
        "parentCode": "thanh_pho_ha_tien"
    },
    {
        "name": "Phường Pháo Đài",
        "code": "phuong_phao_dai",
        "division_type": "phường",
        "short_codename": "phao_dai",
        "parentCode": "thanh_pho_ha_tien"
    },
    {
        "name": "Phường Mỹ Đức",
        "code": "phuong_my_duc",
        "division_type": "phường",
        "short_codename": "my_duc",
        "parentCode": "thanh_pho_ha_tien"
    },
    {
        "name": "Xã Tiên Hải",
        "code": "xa_tien_hai",
        "division_type": "xã",
        "short_codename": "tien_hai",
        "parentCode": "thanh_pho_ha_tien"
    },
    {
        "name": "Xã Thuận Yên",
        "code": "xa_thuan_yen",
        "division_type": "xã",
        "short_codename": "thuan_yen",
        "parentCode": "thanh_pho_ha_tien"
    },
    {
        "name": "Thị trấn Kiên Lương",
        "code": "thi_tran_kien_luong",
        "division_type": "thị trấn",
        "short_codename": "kien_luong",
        "parentCode": "huyen_kien_luong"
    },
    {
        "name": "Xã Kiên Bình",
        "code": "xa_kien_binh",
        "division_type": "xã",
        "short_codename": "kien_binh",
        "parentCode": "huyen_kien_luong"
    },
    {
        "name": "Xã Hòa Điền",
        "code": "xa_hoa_dien",
        "division_type": "xã",
        "short_codename": "hoa_dien",
        "parentCode": "huyen_kien_luong"
    },
    {
        "name": "Xã Dương Hòa",
        "code": "xa_duong_hoa",
        "division_type": "xã",
        "short_codename": "duong_hoa",
        "parentCode": "huyen_kien_luong"
    },
    {
        "name": "Xã Bình An",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_kien_luong"
    },
    {
        "name": "Xã Bình Trị",
        "code": "xa_binh_tri",
        "division_type": "xã",
        "short_codename": "binh_tri",
        "parentCode": "huyen_kien_luong"
    },
    {
        "name": "Xã Sơn Hải",
        "code": "xa_son_hai",
        "division_type": "xã",
        "short_codename": "son_hai",
        "parentCode": "huyen_kien_luong"
    },
    {
        "name": "Xã Hòn Nghệ",
        "code": "xa_hon_nghe",
        "division_type": "xã",
        "short_codename": "hon_nghe",
        "parentCode": "huyen_kien_luong"
    },
    {
        "name": "Thị trấn Hòn Đất",
        "code": "thi_tran_hon_dat",
        "division_type": "thị trấn",
        "short_codename": "hon_dat",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Thị trấn Sóc Sơn",
        "code": "thi_tran_soc_son",
        "division_type": "thị trấn",
        "short_codename": "soc_son",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Bình Sơn",
        "code": "xa_binh_son",
        "division_type": "xã",
        "short_codename": "binh_son",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Bình Giang",
        "code": "xa_binh_giang",
        "division_type": "xã",
        "short_codename": "binh_giang",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Mỹ Thái",
        "code": "xa_my_thai",
        "division_type": "xã",
        "short_codename": "my_thai",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Nam Thái Sơn",
        "code": "xa_nam_thai_son",
        "division_type": "xã",
        "short_codename": "nam_thai_son",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Mỹ Hiệp Sơn",
        "code": "xa_my_hiep_son",
        "division_type": "xã",
        "short_codename": "my_hiep_son",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Sơn Kiên",
        "code": "xa_son_kien",
        "division_type": "xã",
        "short_codename": "son_kien",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Sơn Bình",
        "code": "xa_son_binh",
        "division_type": "xã",
        "short_codename": "son_binh",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Mỹ Thuận",
        "code": "xa_my_thuan",
        "division_type": "xã",
        "short_codename": "my_thuan",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Lình Huỳnh",
        "code": "xa_linh_huynh",
        "division_type": "xã",
        "short_codename": "linh_huynh",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Thổ Sơn",
        "code": "xa_tho_son",
        "division_type": "xã",
        "short_codename": "tho_son",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Mỹ Lâm",
        "code": "xa_my_lam",
        "division_type": "xã",
        "short_codename": "my_lam",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Xã Mỹ Phước",
        "code": "xa_my_phuoc",
        "division_type": "xã",
        "short_codename": "my_phuoc",
        "parentCode": "huyen_hon_dat"
    },
    {
        "name": "Thị trấn Tân Hiệp",
        "code": "thi_tran_tan_hiep",
        "division_type": "thị trấn",
        "short_codename": "tan_hiep",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Tân Hội",
        "code": "xa_tan_hoi",
        "division_type": "xã",
        "short_codename": "tan_hoi",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Tân Hiệp B",
        "code": "xa_tan_hiep_b",
        "division_type": "xã",
        "short_codename": "tan_hiep_b",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Tân Hoà",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Thạnh Đông B",
        "code": "xa_thanh_dong_b",
        "division_type": "xã",
        "short_codename": "thanh_dong_b",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Thạnh Đông",
        "code": "xa_thanh_dong",
        "division_type": "xã",
        "short_codename": "thanh_dong",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Tân Hiệp A",
        "code": "xa_tan_hiep_a",
        "division_type": "xã",
        "short_codename": "tan_hiep_a",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Tân An",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Thạnh Đông A",
        "code": "xa_thanh_dong_a",
        "division_type": "xã",
        "short_codename": "thanh_dong_a",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Xã Thạnh Trị",
        "code": "xa_thanh_tri",
        "division_type": "xã",
        "short_codename": "thanh_tri",
        "parentCode": "huyen_tan_hiep"
    },
    {
        "name": "Thị trấn Minh Lương",
        "code": "thi_tran_minh_luong",
        "division_type": "thị trấn",
        "short_codename": "minh_luong",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Mong Thọ A",
        "code": "xa_mong_tho_a",
        "division_type": "xã",
        "short_codename": "mong_tho_a",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Mong Thọ B",
        "code": "xa_mong_tho_b",
        "division_type": "xã",
        "short_codename": "mong_tho_b",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Mong Thọ",
        "code": "xa_mong_tho",
        "division_type": "xã",
        "short_codename": "mong_tho",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Giục Tượng",
        "code": "xa_giuc_tuong",
        "division_type": "xã",
        "short_codename": "giuc_tuong",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Vĩnh Hòa Hiệp",
        "code": "xa_vinh_hoa_hiep",
        "division_type": "xã",
        "short_codename": "vinh_hoa_hiep",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Vĩnh Hoà Phú",
        "code": "xa_vinh_hoa_phu",
        "division_type": "xã",
        "short_codename": "vinh_hoa_phu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Minh Hòa",
        "code": "xa_minh_hoa",
        "division_type": "xã",
        "short_codename": "minh_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Bình An",
        "code": "xa_binh_an",
        "division_type": "xã",
        "short_codename": "binh_an",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thạnh Lộc",
        "code": "xa_thanh_loc",
        "division_type": "xã",
        "short_codename": "thanh_loc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Thị Trấn Giồng Riềng",
        "code": "thi_tran_giong_rieng",
        "division_type": "thị trấn",
        "short_codename": "giong_rieng",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Thạnh Hưng",
        "code": "xa_thanh_hung",
        "division_type": "xã",
        "short_codename": "thanh_hung",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Thạnh Phước",
        "code": "xa_thanh_phuoc",
        "division_type": "xã",
        "short_codename": "thanh_phuoc",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Thạnh Lộc",
        "code": "xa_thanh_loc",
        "division_type": "xã",
        "short_codename": "thanh_loc",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Thạnh Hòa",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Thạnh Bình",
        "code": "xa_thanh_binh",
        "division_type": "xã",
        "short_codename": "thanh_binh",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Bàn Thạch",
        "code": "xa_ban_thach",
        "division_type": "xã",
        "short_codename": "ban_thach",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Bàn Tân Định",
        "code": "xa_ban_tan_dinh",
        "division_type": "xã",
        "short_codename": "ban_tan_dinh",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Ngọc Thành",
        "code": "xa_ngoc_thanh",
        "division_type": "xã",
        "short_codename": "ngoc_thanh",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Ngọc Chúc",
        "code": "xa_ngoc_chuc",
        "division_type": "xã",
        "short_codename": "ngoc_chuc",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Ngọc Thuận",
        "code": "xa_ngoc_thuan",
        "division_type": "xã",
        "short_codename": "ngoc_thuan",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Hòa Hưng",
        "code": "xa_hoa_hung",
        "division_type": "xã",
        "short_codename": "hoa_hung",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Hoà Lợi",
        "code": "xa_hoa_loi",
        "division_type": "xã",
        "short_codename": "hoa_loi",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Hoà An",
        "code": "xa_hoa_an",
        "division_type": "xã",
        "short_codename": "hoa_an",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Long Thạnh",
        "code": "xa_long_thanh",
        "division_type": "xã",
        "short_codename": "long_thanh",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Vĩnh Thạnh",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Vĩnh Phú",
        "code": "xa_vinh_phu",
        "division_type": "xã",
        "short_codename": "vinh_phu",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Hòa Thuận",
        "code": "xa_hoa_thuan",
        "division_type": "xã",
        "short_codename": "hoa_thuan",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Xã Ngọc Hoà",
        "code": "xa_ngoc_hoa",
        "division_type": "xã",
        "short_codename": "ngoc_hoa",
        "parentCode": "huyen_giong_rieng"
    },
    {
        "name": "Thị trấn Gò Quao",
        "code": "thi_tran_go_quao",
        "division_type": "thị trấn",
        "short_codename": "go_quao",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Vĩnh Hòa Hưng Bắc",
        "code": "xa_vinh_hoa_hung_bac",
        "division_type": "xã",
        "short_codename": "vinh_hoa_hung_bac",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Định Hòa",
        "code": "xa_dinh_hoa",
        "division_type": "xã",
        "short_codename": "dinh_hoa",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Thới Quản",
        "code": "xa_thoi_quan",
        "division_type": "xã",
        "short_codename": "thoi_quan",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Định An",
        "code": "xa_dinh_an",
        "division_type": "xã",
        "short_codename": "dinh_an",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Thủy Liễu",
        "code": "xa_thuy_lieu",
        "division_type": "xã",
        "short_codename": "thuy_lieu",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Vĩnh Hòa Hưng Nam",
        "code": "xa_vinh_hoa_hung_nam",
        "division_type": "xã",
        "short_codename": "vinh_hoa_hung_nam",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Vĩnh Phước A",
        "code": "xa_vinh_phuoc_a",
        "division_type": "xã",
        "short_codename": "vinh_phuoc_a",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Vĩnh Phước B",
        "code": "xa_vinh_phuoc_b",
        "division_type": "xã",
        "short_codename": "vinh_phuoc_b",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Vĩnh Tuy",
        "code": "xa_vinh_tuy",
        "division_type": "xã",
        "short_codename": "vinh_tuy",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Xã Vĩnh Thắng",
        "code": "xa_vinh_thang",
        "division_type": "xã",
        "short_codename": "vinh_thang",
        "parentCode": "huyen_go_quao"
    },
    {
        "name": "Thị trấn Thứ Ba",
        "code": "thi_tran_thu_ba",
        "division_type": "thị trấn",
        "short_codename": "thu_ba",
        "parentCode": "huyen_an_bien"
    },
    {
        "name": "Xã Tây Yên",
        "code": "xa_tay_yen",
        "division_type": "xã",
        "short_codename": "tay_yen",
        "parentCode": "huyen_an_bien"
    },
    {
        "name": "Xã Tây Yên A",
        "code": "xa_tay_yen_a",
        "division_type": "xã",
        "short_codename": "tay_yen_a",
        "parentCode": "huyen_an_bien"
    },
    {
        "name": "Xã Nam Yên",
        "code": "xa_nam_yen",
        "division_type": "xã",
        "short_codename": "nam_yen",
        "parentCode": "huyen_an_bien"
    },
    {
        "name": "Xã Hưng Yên",
        "code": "xa_hung_yen",
        "division_type": "xã",
        "short_codename": "hung_yen",
        "parentCode": "huyen_an_bien"
    },
    {
        "name": "Xã Nam Thái",
        "code": "xa_nam_thai",
        "division_type": "xã",
        "short_codename": "nam_thai",
        "parentCode": "huyen_an_bien"
    },
    {
        "name": "Xã Nam Thái A",
        "code": "xa_nam_thai_a",
        "division_type": "xã",
        "short_codename": "nam_thai_a",
        "parentCode": "huyen_an_bien"
    },
    {
        "name": "Xã Đông Thái",
        "code": "xa_dong_thai",
        "division_type": "xã",
        "short_codename": "dong_thai",
        "parentCode": "huyen_an_bien"
    },
    {
        "name": "Xã Đông Yên",
        "code": "xa_dong_yen",
        "division_type": "xã",
        "short_codename": "dong_yen",
        "parentCode": "huyen_an_bien"
    },
    {
        "name": "Thị trấn Thứ Mười Một",
        "code": "thi_tran_thu_muoi_mot",
        "division_type": "thị trấn",
        "short_codename": "thu_muoi_mot",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Thuận Hoà",
        "code": "xa_thuan_hoa",
        "division_type": "xã",
        "short_codename": "thuan_hoa",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Đông Hòa",
        "code": "xa_dong_hoa",
        "division_type": "xã",
        "short_codename": "dong_hoa",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Đông Thạnh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Tân Thạnh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Đông Hưng",
        "code": "xa_dong_hung",
        "division_type": "xã",
        "short_codename": "dong_hung",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Đông Hưng A",
        "code": "xa_dong_hung_a",
        "division_type": "xã",
        "short_codename": "dong_hung_a",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Đông Hưng B",
        "code": "xa_dong_hung_b",
        "division_type": "xã",
        "short_codename": "dong_hung_b",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Vân Khánh",
        "code": "xa_van_khanh",
        "division_type": "xã",
        "short_codename": "van_khanh",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Vân Khánh Đông",
        "code": "xa_van_khanh_dong",
        "division_type": "xã",
        "short_codename": "van_khanh_dong",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Xã Vân Khánh Tây",
        "code": "xa_van_khanh_tay",
        "division_type": "xã",
        "short_codename": "van_khanh_tay",
        "parentCode": "huyen_an_minh"
    },
    {
        "name": "Thị trấn Vĩnh Thuận",
        "code": "thi_tran_vinh_thuan",
        "division_type": "thị trấn",
        "short_codename": "vinh_thuan",
        "parentCode": "huyen_vinh_thuan"
    },
    {
        "name": "Xã Vĩnh Bình Bắc",
        "code": "xa_vinh_binh_bac",
        "division_type": "xã",
        "short_codename": "vinh_binh_bac",
        "parentCode": "huyen_vinh_thuan"
    },
    {
        "name": "Xã Vĩnh Bình Nam",
        "code": "xa_vinh_binh_nam",
        "division_type": "xã",
        "short_codename": "vinh_binh_nam",
        "parentCode": "huyen_vinh_thuan"
    },
    {
        "name": "Xã Bình Minh",
        "code": "xa_binh_minh",
        "division_type": "xã",
        "short_codename": "binh_minh",
        "parentCode": "huyen_vinh_thuan"
    },
    {
        "name": "Xã Vĩnh Thuận",
        "code": "xa_vinh_thuan",
        "division_type": "xã",
        "short_codename": "xa_vinh_thuan",
        "parentCode": "huyen_vinh_thuan"
    },
    {
        "name": "Xã Tân Thuận",
        "code": "xa_tan_thuan",
        "division_type": "xã",
        "short_codename": "tan_thuan",
        "parentCode": "huyen_vinh_thuan"
    },
    {
        "name": "Xã Phong Đông",
        "code": "xa_phong_dong",
        "division_type": "xã",
        "short_codename": "phong_dong",
        "parentCode": "huyen_vinh_thuan"
    },
    {
        "name": "Xã Vĩnh Phong",
        "code": "xa_vinh_phong",
        "division_type": "xã",
        "short_codename": "vinh_phong",
        "parentCode": "huyen_vinh_thuan"
    },
    {
        "name": "Phường Dương Đông",
        "code": "phuong_duong_dong",
        "division_type": "phường",
        "short_codename": "duong_dong",
        "parentCode": "thanh_pho_phu_quoc"
    },
    {
        "name": "Phường An Thới",
        "code": "phuong_an_thoi",
        "division_type": "phường",
        "short_codename": "an_thoi",
        "parentCode": "thanh_pho_phu_quoc"
    },
    {
        "name": "Xã Cửa Cạn",
        "code": "xa_cua_can",
        "division_type": "xã",
        "short_codename": "cua_can",
        "parentCode": "thanh_pho_phu_quoc"
    },
    {
        "name": "Xã Gành Dầu",
        "code": "xa_ganh_dau",
        "division_type": "xã",
        "short_codename": "ganh_dau",
        "parentCode": "thanh_pho_phu_quoc"
    },
    {
        "name": "Xã Cửa Dương",
        "code": "xa_cua_duong",
        "division_type": "xã",
        "short_codename": "cua_duong",
        "parentCode": "thanh_pho_phu_quoc"
    },
    {
        "name": "Xã Hàm Ninh",
        "code": "xa_ham_ninh",
        "division_type": "xã",
        "short_codename": "ham_ninh",
        "parentCode": "thanh_pho_phu_quoc"
    },
    {
        "name": "Xã Dương Tơ",
        "code": "xa_duong_to",
        "division_type": "xã",
        "short_codename": "duong_to",
        "parentCode": "thanh_pho_phu_quoc"
    },
    {
        "name": "Xã Bãi Thơm",
        "code": "xa_bai_thom",
        "division_type": "xã",
        "short_codename": "bai_thom",
        "parentCode": "thanh_pho_phu_quoc"
    },
    {
        "name": "Xã Thổ Châu",
        "code": "xa_tho_chau",
        "division_type": "xã",
        "short_codename": "tho_chau",
        "parentCode": "thanh_pho_phu_quoc"
    },
    {
        "name": "Xã Hòn Tre",
        "code": "xa_hon_tre",
        "division_type": "xã",
        "short_codename": "hon_tre",
        "parentCode": "huyen_kien_hai"
    },
    {
        "name": "Xã Lại Sơn",
        "code": "xa_lai_son",
        "division_type": "xã",
        "short_codename": "lai_son",
        "parentCode": "huyen_kien_hai"
    },
    {
        "name": "Xã An Sơn",
        "code": "xa_an_son",
        "division_type": "xã",
        "short_codename": "an_son",
        "parentCode": "huyen_kien_hai"
    },
    {
        "name": "Xã Nam Du",
        "code": "xa_nam_du",
        "division_type": "xã",
        "short_codename": "nam_du",
        "parentCode": "huyen_kien_hai"
    },
    {
        "name": "Xã Thạnh Yên",
        "code": "xa_thanh_yen",
        "division_type": "xã",
        "short_codename": "thanh_yen",
        "parentCode": "huyen_u_minh_thuong"
    },
    {
        "name": "Xã Thạnh Yên A",
        "code": "xa_thanh_yen_a",
        "division_type": "xã",
        "short_codename": "thanh_yen_a",
        "parentCode": "huyen_u_minh_thuong"
    },
    {
        "name": "Xã An Minh Bắc",
        "code": "xa_an_minh_bac",
        "division_type": "xã",
        "short_codename": "an_minh_bac",
        "parentCode": "huyen_u_minh_thuong"
    },
    {
        "name": "Xã Vĩnh Hòa",
        "code": "xa_vinh_hoa",
        "division_type": "xã",
        "short_codename": "vinh_hoa",
        "parentCode": "huyen_u_minh_thuong"
    },
    {
        "name": "Xã Hoà Chánh",
        "code": "xa_hoa_chanh",
        "division_type": "xã",
        "short_codename": "hoa_chanh",
        "parentCode": "huyen_u_minh_thuong"
    },
    {
        "name": "Xã Minh Thuận",
        "code": "xa_minh_thuan",
        "division_type": "xã",
        "short_codename": "minh_thuan",
        "parentCode": "huyen_u_minh_thuong"
    },
    {
        "name": "Xã Vĩnh Phú",
        "code": "xa_vinh_phu",
        "division_type": "xã",
        "short_codename": "vinh_phu",
        "parentCode": "huyen_giang_thanh"
    },
    {
        "name": "Xã Vĩnh Điều",
        "code": "xa_vinh_dieu",
        "division_type": "xã",
        "short_codename": "vinh_dieu",
        "parentCode": "huyen_giang_thanh"
    },
    {
        "name": "Xã Tân Khánh Hòa",
        "code": "xa_tan_khanh_hoa",
        "division_type": "xã",
        "short_codename": "tan_khanh_hoa",
        "parentCode": "huyen_giang_thanh"
    },
    {
        "name": "Xã Phú Lợi",
        "code": "xa_phu_loi",
        "division_type": "xã",
        "short_codename": "phu_loi",
        "parentCode": "huyen_giang_thanh"
    },
    {
        "name": "Xã Phú Mỹ",
        "code": "xa_phu_my",
        "division_type": "xã",
        "short_codename": "phu_my",
        "parentCode": "huyen_giang_thanh"
    },
    {
        "name": "Phường Cái Khế",
        "code": "phuong_cai_khe",
        "division_type": "phường",
        "short_codename": "cai_khe",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường An Hòa",
        "code": "phuong_an_hoa",
        "division_type": "phường",
        "short_codename": "an_hoa",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường Thới Bình",
        "code": "phuong_thoi_binh",
        "division_type": "phường",
        "short_codename": "thoi_binh",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường An Nghiệp",
        "code": "phuong_an_nghiep",
        "division_type": "phường",
        "short_codename": "an_nghiep",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường An Cư",
        "code": "phuong_an_cu",
        "division_type": "phường",
        "short_codename": "an_cu",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường Tân An",
        "code": "phuong_tan_an",
        "division_type": "phường",
        "short_codename": "tan_an",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường An Phú",
        "code": "phuong_an_phu",
        "division_type": "phường",
        "short_codename": "an_phu",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường Xuân Khánh",
        "code": "phuong_xuan_khanh",
        "division_type": "phường",
        "short_codename": "xuan_khanh",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường Hưng Lợi",
        "code": "phuong_hung_loi",
        "division_type": "phường",
        "short_codename": "hung_loi",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường An Khánh",
        "code": "phuong_an_khanh",
        "division_type": "phường",
        "short_codename": "an_khanh",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường An Bình",
        "code": "phuong_an_binh",
        "division_type": "phường",
        "short_codename": "an_binh",
        "parentCode": "quan_ninh_kieu"
    },
    {
        "name": "Phường Châu Văn Liêm",
        "code": "phuong_chau_van_liem",
        "division_type": "phường",
        "short_codename": "chau_van_liem",
        "parentCode": "quan_o_mon"
    },
    {
        "name": "Phường Thới Hòa",
        "code": "phuong_thoi_hoa",
        "division_type": "phường",
        "short_codename": "thoi_hoa",
        "parentCode": "quan_o_mon"
    },
    {
        "name": "Phường Thới Long",
        "code": "phuong_thoi_long",
        "division_type": "phường",
        "short_codename": "thoi_long",
        "parentCode": "quan_o_mon"
    },
    {
        "name": "Phường Long Hưng",
        "code": "phuong_long_hung",
        "division_type": "phường",
        "short_codename": "long_hung",
        "parentCode": "quan_o_mon"
    },
    {
        "name": "Phường Thới An",
        "code": "phuong_thoi_an",
        "division_type": "phường",
        "short_codename": "thoi_an",
        "parentCode": "quan_o_mon"
    },
    {
        "name": "Phường Phước Thới",
        "code": "phuong_phuoc_thoi",
        "division_type": "phường",
        "short_codename": "phuoc_thoi",
        "parentCode": "quan_o_mon"
    },
    {
        "name": "Phường Trường Lạc",
        "code": "phuong_truong_lac",
        "division_type": "phường",
        "short_codename": "truong_lac",
        "parentCode": "quan_o_mon"
    },
    {
        "name": "Phường Bình Thủy",
        "code": "phuong_binh_thuy",
        "division_type": "phường",
        "short_codename": "binh_thuy",
        "parentCode": "quan_binh_thuy"
    },
    {
        "name": "Phường Trà An",
        "code": "phuong_tra_an",
        "division_type": "phường",
        "short_codename": "tra_an",
        "parentCode": "quan_binh_thuy"
    },
    {
        "name": "Phường Trà Nóc",
        "code": "phuong_tra_noc",
        "division_type": "phường",
        "short_codename": "tra_noc",
        "parentCode": "quan_binh_thuy"
    },
    {
        "name": "Phường Thới An Đông",
        "code": "phuong_thoi_an_dong",
        "division_type": "phường",
        "short_codename": "thoi_an_dong",
        "parentCode": "quan_binh_thuy"
    },
    {
        "name": "Phường An Thới",
        "code": "phuong_an_thoi",
        "division_type": "phường",
        "short_codename": "an_thoi",
        "parentCode": "quan_binh_thuy"
    },
    {
        "name": "Phường Bùi Hữu Nghĩa",
        "code": "phuong_bui_huu_nghia",
        "division_type": "phường",
        "short_codename": "bui_huu_nghia",
        "parentCode": "quan_binh_thuy"
    },
    {
        "name": "Phường Long Hòa",
        "code": "phuong_long_hoa",
        "division_type": "phường",
        "short_codename": "long_hoa",
        "parentCode": "quan_binh_thuy"
    },
    {
        "name": "Phường Long Tuyền",
        "code": "phuong_long_tuyen",
        "division_type": "phường",
        "short_codename": "long_tuyen",
        "parentCode": "quan_binh_thuy"
    },
    {
        "name": "Phường Lê Bình",
        "code": "phuong_le_binh",
        "division_type": "phường",
        "short_codename": "le_binh",
        "parentCode": "quan_cai_rang"
    },
    {
        "name": "Phường Hưng Phú",
        "code": "phuong_hung_phu",
        "division_type": "phường",
        "short_codename": "hung_phu",
        "parentCode": "quan_cai_rang"
    },
    {
        "name": "Phường Hưng Thạnh",
        "code": "phuong_hung_thanh",
        "division_type": "phường",
        "short_codename": "hung_thanh",
        "parentCode": "quan_cai_rang"
    },
    {
        "name": "Phường Ba Láng",
        "code": "phuong_ba_lang",
        "division_type": "phường",
        "short_codename": "ba_lang",
        "parentCode": "quan_cai_rang"
    },
    {
        "name": "Phường Thường Thạnh",
        "code": "phuong_thuong_thanh",
        "division_type": "phường",
        "short_codename": "thuong_thanh",
        "parentCode": "quan_cai_rang"
    },
    {
        "name": "Phường Phú Thứ",
        "code": "phuong_phu_thu",
        "division_type": "phường",
        "short_codename": "phu_thu",
        "parentCode": "quan_cai_rang"
    },
    {
        "name": "Phường Tân Phú",
        "code": "phuong_tan_phu",
        "division_type": "phường",
        "short_codename": "tan_phu",
        "parentCode": "quan_cai_rang"
    },
    {
        "name": "Phường Thốt Nốt",
        "code": "phuong_thot_not",
        "division_type": "phường",
        "short_codename": "thot_not",
        "parentCode": "quan_thot_not"
    },
    {
        "name": "Phường Thới Thuận",
        "code": "phuong_thoi_thuan",
        "division_type": "phường",
        "short_codename": "thoi_thuan",
        "parentCode": "quan_thot_not"
    },
    {
        "name": "Phường Thuận An",
        "code": "phuong_thuan_an",
        "division_type": "phường",
        "short_codename": "thuan_an",
        "parentCode": "quan_thot_not"
    },
    {
        "name": "Phường Tân Lộc",
        "code": "phuong_tan_loc",
        "division_type": "phường",
        "short_codename": "tan_loc",
        "parentCode": "quan_thot_not"
    },
    {
        "name": "Phường Trung Nhứt",
        "code": "phuong_trung_nhut",
        "division_type": "phường",
        "short_codename": "trung_nhut",
        "parentCode": "quan_thot_not"
    },
    {
        "name": "Phường Thạnh Hoà",
        "code": "phuong_thanh_hoa",
        "division_type": "phường",
        "short_codename": "thanh_hoa",
        "parentCode": "quan_thot_not"
    },
    {
        "name": "Phường Trung Kiên",
        "code": "phuong_trung_kien",
        "division_type": "phường",
        "short_codename": "trung_kien",
        "parentCode": "quan_thot_not"
    },
    {
        "name": "Phường Tân Hưng",
        "code": "phuong_tan_hung",
        "division_type": "phường",
        "short_codename": "tan_hung",
        "parentCode": "quan_thot_not"
    },
    {
        "name": "Phường Thuận Hưng",
        "code": "phuong_thuan_hung",
        "division_type": "phường",
        "short_codename": "thuan_hung",
        "parentCode": "quan_thot_not"
    },
    {
        "name": "Xã Vĩnh Bình",
        "code": "xa_vinh_binh",
        "division_type": "xã",
        "short_codename": "vinh_binh",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Thị trấn Thanh An",
        "code": "thi_tran_thanh_an",
        "division_type": "thị trấn",
        "short_codename": "thanh_an",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Thị trấn Vĩnh Thạnh",
        "code": "thi_tran_vinh_thanh",
        "division_type": "thị trấn",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Thạnh Mỹ",
        "code": "xa_thanh_my",
        "division_type": "xã",
        "short_codename": "thanh_my",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Vĩnh Trinh",
        "code": "xa_vinh_trinh",
        "division_type": "xã",
        "short_codename": "vinh_trinh",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Thạnh An",
        "code": "xa_thanh_an",
        "division_type": "xã",
        "short_codename": "xa_thanh_an",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Thạnh Tiến",
        "code": "xa_thanh_tien",
        "division_type": "xã",
        "short_codename": "thanh_tien",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Thạnh Thắng",
        "code": "xa_thanh_thang",
        "division_type": "xã",
        "short_codename": "thanh_thang",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Thạnh Lợi",
        "code": "xa_thanh_loi",
        "division_type": "xã",
        "short_codename": "thanh_loi",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Thạnh Qưới",
        "code": "xa_thanh_quoi",
        "division_type": "xã",
        "short_codename": "thanh_quoi",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Thạnh Lộc",
        "code": "xa_thanh_loc",
        "division_type": "xã",
        "short_codename": "thanh_loc",
        "parentCode": "huyen_vinh_thanh"
    },
    {
        "name": "Xã Trung An",
        "code": "xa_trung_an",
        "division_type": "xã",
        "short_codename": "trung_an",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Xã Trung Thạnh",
        "code": "xa_trung_thanh",
        "division_type": "xã",
        "short_codename": "trung_thanh",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Xã Thạnh Phú",
        "code": "xa_thanh_phu",
        "division_type": "xã",
        "short_codename": "thanh_phu",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Xã Trung Hưng",
        "code": "xa_trung_hung",
        "division_type": "xã",
        "short_codename": "trung_hung",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Thị trấn Cờ Đỏ",
        "code": "thi_tran_co_do",
        "division_type": "thị trấn",
        "short_codename": "co_do",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Xã Thới Hưng",
        "code": "xa_thoi_hung",
        "division_type": "xã",
        "short_codename": "thoi_hung",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Xã Đông Hiệp",
        "code": "xa_dong_hiep",
        "division_type": "xã",
        "short_codename": "dong_hiep",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Xã Đông Thắng",
        "code": "xa_dong_thang",
        "division_type": "xã",
        "short_codename": "dong_thang",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Xã Thới Đông",
        "code": "xa_thoi_dong",
        "division_type": "xã",
        "short_codename": "thoi_dong",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Xã Thới Xuân",
        "code": "xa_thoi_xuan",
        "division_type": "xã",
        "short_codename": "thoi_xuan",
        "parentCode": "huyen_co_do"
    },
    {
        "name": "Thị trấn Phong Điền",
        "code": "thi_tran_phong_dien",
        "division_type": "thị trấn",
        "short_codename": "phong_dien",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Nhơn Ái",
        "code": "xa_nhon_ai",
        "division_type": "xã",
        "short_codename": "nhon_ai",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Giai Xuân",
        "code": "xa_giai_xuan",
        "division_type": "xã",
        "short_codename": "giai_xuan",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Tân Thới",
        "code": "xa_tan_thoi",
        "division_type": "xã",
        "short_codename": "tan_thoi",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Trường Long",
        "code": "xa_truong_long",
        "division_type": "xã",
        "short_codename": "truong_long",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Mỹ Khánh",
        "code": "xa_my_khanh",
        "division_type": "xã",
        "short_codename": "my_khanh",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Xã Nhơn Nghĩa",
        "code": "xa_nhon_nghia",
        "division_type": "xã",
        "short_codename": "nhon_nghia",
        "parentCode": "huyen_phong_dien"
    },
    {
        "name": "Thị trấn Thới Lai",
        "code": "thi_tran_thoi_lai",
        "division_type": "thị trấn",
        "short_codename": "thoi_lai",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Thới Thạnh",
        "code": "xa_thoi_thanh",
        "division_type": "xã",
        "short_codename": "thoi_thanh",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Tân Thạnh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Xuân Thắng",
        "code": "xa_xuan_thang",
        "division_type": "xã",
        "short_codename": "xuan_thang",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Đông Bình",
        "code": "xa_dong_binh",
        "division_type": "xã",
        "short_codename": "dong_binh",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Đông Thuận",
        "code": "xa_dong_thuan",
        "division_type": "xã",
        "short_codename": "dong_thuan",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Thới Tân",
        "code": "xa_thoi_tan",
        "division_type": "xã",
        "short_codename": "thoi_tan",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Trường Thắng",
        "code": "xa_truong_thang",
        "division_type": "xã",
        "short_codename": "truong_thang",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Định Môn",
        "code": "xa_dinh_mon",
        "division_type": "xã",
        "short_codename": "dinh_mon",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Trường Thành",
        "code": "xa_truong_thanh",
        "division_type": "xã",
        "short_codename": "truong_thanh",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Trường Xuân",
        "code": "xa_truong_xuan",
        "division_type": "xã",
        "short_codename": "truong_xuan",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Trường Xuân A",
        "code": "xa_truong_xuan_a",
        "division_type": "xã",
        "short_codename": "truong_xuan_a",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Xã Trường Xuân B",
        "code": "xa_truong_xuan_b",
        "division_type": "xã",
        "short_codename": "truong_xuan_b",
        "parentCode": "huyen_thoi_lai"
    },
    {
        "name": "Phường I",
        "code": "phuong_i",
        "division_type": "phường",
        "short_codename": "i",
        "parentCode": "thanh_pho_vi_thanh"
    },
    {
        "name": "Phường III",
        "code": "phuong_iii",
        "division_type": "phường",
        "short_codename": "iii",
        "parentCode": "thanh_pho_vi_thanh"
    },
    {
        "name": "Phường IV",
        "code": "phuong_iv",
        "division_type": "phường",
        "short_codename": "iv",
        "parentCode": "thanh_pho_vi_thanh"
    },
    {
        "name": "Phường V",
        "code": "phuong_v",
        "division_type": "phường",
        "short_codename": "v",
        "parentCode": "thanh_pho_vi_thanh"
    },
    {
        "name": "Phường VII",
        "code": "phuong_vii",
        "division_type": "phường",
        "short_codename": "vii",
        "parentCode": "thanh_pho_vi_thanh"
    },
    {
        "name": "Xã Vị Tân",
        "code": "xa_vi_tan",
        "division_type": "xã",
        "short_codename": "vi_tan",
        "parentCode": "thanh_pho_vi_thanh"
    },
    {
        "name": "Xã Hoả Lựu",
        "code": "xa_hoa_luu",
        "division_type": "xã",
        "short_codename": "hoa_luu",
        "parentCode": "thanh_pho_vi_thanh"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "thanh_pho_vi_thanh"
    },
    {
        "name": "Xã Hoả Tiến",
        "code": "xa_hoa_tien",
        "division_type": "xã",
        "short_codename": "hoa_tien",
        "parentCode": "thanh_pho_vi_thanh"
    },
    {
        "name": "Phường Ngã Bảy",
        "code": "phuong_nga_bay",
        "division_type": "phường",
        "short_codename": "nga_bay",
        "parentCode": "thanh_pho_nga_bay"
    },
    {
        "name": "Phường Lái Hiếu",
        "code": "phuong_lai_hieu",
        "division_type": "phường",
        "short_codename": "lai_hieu",
        "parentCode": "thanh_pho_nga_bay"
    },
    {
        "name": "Phường Hiệp Thành",
        "code": "phuong_hiep_thanh",
        "division_type": "phường",
        "short_codename": "hiep_thanh",
        "parentCode": "thanh_pho_nga_bay"
    },
    {
        "name": "Phường Hiệp Lợi",
        "code": "phuong_hiep_loi",
        "division_type": "phường",
        "short_codename": "hiep_loi",
        "parentCode": "thanh_pho_nga_bay"
    },
    {
        "name": "Xã Đại Thành",
        "code": "xa_dai_thanh",
        "division_type": "xã",
        "short_codename": "dai_thanh",
        "parentCode": "thanh_pho_nga_bay"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "thanh_pho_nga_bay"
    },
    {
        "name": "Thị trấn Một Ngàn",
        "code": "thi_tran_mot_ngan",
        "division_type": "thị trấn",
        "short_codename": "mot_ngan",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Xã Tân Hoà",
        "code": "xa_tan_hoa",
        "division_type": "xã",
        "short_codename": "tan_hoa",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Thị trấn Bảy Ngàn",
        "code": "thi_tran_bay_ngan",
        "division_type": "thị trấn",
        "short_codename": "bay_ngan",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Xã Trường Long Tây",
        "code": "xa_truong_long_tay",
        "division_type": "xã",
        "short_codename": "truong_long_tay",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Xã Trường Long A",
        "code": "xa_truong_long_a",
        "division_type": "xã",
        "short_codename": "truong_long_a",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Xã Nhơn Nghĩa A",
        "code": "xa_nhon_nghia_a",
        "division_type": "xã",
        "short_codename": "nhon_nghia_a",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Thị trấn Rạch Gòi",
        "code": "thi_tran_rach_goi",
        "division_type": "thị trấn",
        "short_codename": "rach_goi",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Xã Thạnh Xuân",
        "code": "xa_thanh_xuan",
        "division_type": "xã",
        "short_codename": "thanh_xuan",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Thị trấn Cái Tắc",
        "code": "thi_tran_cai_tac",
        "division_type": "thị trấn",
        "short_codename": "cai_tac",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Xã Tân Phú Thạnh",
        "code": "xa_tan_phu_thanh",
        "division_type": "xã",
        "short_codename": "tan_phu_thanh",
        "parentCode": "huyen_chau_thanh_a"
    },
    {
        "name": "Thị Trấn Ngã Sáu",
        "code": "thi_tran_nga_sau",
        "division_type": "thị trấn",
        "short_codename": "nga_sau",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Đông Thạnh",
        "code": "xa_dong_thanh",
        "division_type": "xã",
        "short_codename": "dong_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Đông Phú",
        "code": "xa_dong_phu",
        "division_type": "xã",
        "short_codename": "dong_phu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Hữu",
        "code": "xa_phu_huu",
        "division_type": "xã",
        "short_codename": "phu_huu",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Tân",
        "code": "xa_phu_tan",
        "division_type": "xã",
        "short_codename": "phu_tan",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Thị trấn Mái Dầm",
        "code": "thi_tran_mai_dam",
        "division_type": "thị trấn",
        "short_codename": "mai_dam",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Đông Phước",
        "code": "xa_dong_phuoc",
        "division_type": "xã",
        "short_codename": "dong_phuoc",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Đông Phước A",
        "code": "xa_dong_phuoc_a",
        "division_type": "xã",
        "short_codename": "dong_phuoc_a",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Thị trấn Kinh Cùng",
        "code": "thi_tran_kinh_cung",
        "division_type": "thị trấn",
        "short_codename": "kinh_cung",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Thị trấn Cây Dương",
        "code": "thi_tran_cay_duong",
        "division_type": "thị trấn",
        "short_codename": "cay_duong",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Tân Bình",
        "code": "xa_tan_binh",
        "division_type": "xã",
        "short_codename": "tan_binh",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Bình Thành",
        "code": "xa_binh_thanh",
        "division_type": "xã",
        "short_codename": "binh_thanh",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Thạnh Hòa",
        "code": "xa_thanh_hoa",
        "division_type": "xã",
        "short_codename": "thanh_hoa",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Long Thạnh",
        "code": "xa_long_thanh",
        "division_type": "xã",
        "short_codename": "long_thanh",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Phụng Hiệp",
        "code": "xa_phung_hiep",
        "division_type": "xã",
        "short_codename": "phung_hiep",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Hòa Mỹ",
        "code": "xa_hoa_my",
        "division_type": "xã",
        "short_codename": "hoa_my",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Hòa An",
        "code": "xa_hoa_an",
        "division_type": "xã",
        "short_codename": "hoa_an",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Phương Bình",
        "code": "xa_phuong_binh",
        "division_type": "xã",
        "short_codename": "phuong_binh",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Hiệp Hưng",
        "code": "xa_hiep_hung",
        "division_type": "xã",
        "short_codename": "hiep_hung",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Tân Phước Hưng",
        "code": "xa_tan_phuoc_hung",
        "division_type": "xã",
        "short_codename": "tan_phuoc_hung",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Thị trấn Búng Tàu",
        "code": "thi_tran_bung_tau",
        "division_type": "thị trấn",
        "short_codename": "bung_tau",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Phương Phú",
        "code": "xa_phuong_phu",
        "division_type": "xã",
        "short_codename": "phuong_phu",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "huyen_phung_hiep"
    },
    {
        "name": "Thị trấn Nàng Mau",
        "code": "thi_tran_nang_mau",
        "division_type": "thị trấn",
        "short_codename": "nang_mau",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Vị Trung",
        "code": "xa_vi_trung",
        "division_type": "xã",
        "short_codename": "vi_trung",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Vị Thuỷ",
        "code": "xa_vi_thuy",
        "division_type": "xã",
        "short_codename": "vi_thuy",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Vị Thắng",
        "code": "xa_vi_thang",
        "division_type": "xã",
        "short_codename": "vi_thang",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Vĩnh Thuận Tây",
        "code": "xa_vinh_thuan_tay",
        "division_type": "xã",
        "short_codename": "vinh_thuan_tay",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Vĩnh Trung",
        "code": "xa_vinh_trung",
        "division_type": "xã",
        "short_codename": "vinh_trung",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Vĩnh Tường",
        "code": "xa_vinh_tuong",
        "division_type": "xã",
        "short_codename": "vinh_tuong",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Vị Đông",
        "code": "xa_vi_dong",
        "division_type": "xã",
        "short_codename": "vi_dong",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Vị Thanh",
        "code": "xa_vi_thanh",
        "division_type": "xã",
        "short_codename": "vi_thanh",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Vị Bình",
        "code": "xa_vi_binh",
        "division_type": "xã",
        "short_codename": "vi_binh",
        "parentCode": "huyen_vi_thuy"
    },
    {
        "name": "Xã Thuận Hưng",
        "code": "xa_thuan_hung",
        "division_type": "xã",
        "short_codename": "thuan_hung",
        "parentCode": "huyen_long_my"
    },
    {
        "name": "Xã Thuận Hòa",
        "code": "xa_thuan_hoa",
        "division_type": "xã",
        "short_codename": "thuan_hoa",
        "parentCode": "huyen_long_my"
    },
    {
        "name": "Xã Vĩnh Thuận Đông",
        "code": "xa_vinh_thuan_dong",
        "division_type": "xã",
        "short_codename": "vinh_thuan_dong",
        "parentCode": "huyen_long_my"
    },
    {
        "name": "Thị trấn Vĩnh Viễn",
        "code": "thi_tran_vinh_vien",
        "division_type": "thị trấn",
        "short_codename": "vinh_vien",
        "parentCode": "huyen_long_my"
    },
    {
        "name": "Xã Vĩnh Viễn A",
        "code": "xa_vinh_vien_a",
        "division_type": "xã",
        "short_codename": "vinh_vien_a",
        "parentCode": "huyen_long_my"
    },
    {
        "name": "Xã Lương Tâm",
        "code": "xa_luong_tam",
        "division_type": "xã",
        "short_codename": "luong_tam",
        "parentCode": "huyen_long_my"
    },
    {
        "name": "Xã Lương Nghĩa",
        "code": "xa_luong_nghia",
        "division_type": "xã",
        "short_codename": "luong_nghia",
        "parentCode": "huyen_long_my"
    },
    {
        "name": "Xã Xà Phiên",
        "code": "xa_xa_phien",
        "division_type": "xã",
        "short_codename": "xa_phien",
        "parentCode": "huyen_long_my"
    },
    {
        "name": "Phường Thuận An",
        "code": "phuong_thuan_an",
        "division_type": "phường",
        "short_codename": "thuan_an",
        "parentCode": "thi_xa_long_my"
    },
    {
        "name": "Phường Trà Lồng",
        "code": "phuong_tra_long",
        "division_type": "phường",
        "short_codename": "tra_long",
        "parentCode": "thi_xa_long_my"
    },
    {
        "name": "Phường Bình Thạnh",
        "code": "phuong_binh_thanh",
        "division_type": "phường",
        "short_codename": "binh_thanh",
        "parentCode": "thi_xa_long_my"
    },
    {
        "name": "Xã Long Bình",
        "code": "xa_long_binh",
        "division_type": "xã",
        "short_codename": "long_binh",
        "parentCode": "thi_xa_long_my"
    },
    {
        "name": "Phường Vĩnh Tường",
        "code": "phuong_vinh_tuong",
        "division_type": "phường",
        "short_codename": "vinh_tuong",
        "parentCode": "thi_xa_long_my"
    },
    {
        "name": "Xã Long Trị",
        "code": "xa_long_tri",
        "division_type": "xã",
        "short_codename": "long_tri",
        "parentCode": "thi_xa_long_my"
    },
    {
        "name": "Xã Long Trị A",
        "code": "xa_long_tri_a",
        "division_type": "xã",
        "short_codename": "long_tri_a",
        "parentCode": "thi_xa_long_my"
    },
    {
        "name": "Xã Long Phú",
        "code": "xa_long_phu",
        "division_type": "xã",
        "short_codename": "long_phu",
        "parentCode": "thi_xa_long_my"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "thi_xa_long_my"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Phường 9",
        "code": "phuong_9",
        "division_type": "phường",
        "short_codename": "phuong_9",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Phường 10",
        "code": "phuong_10",
        "division_type": "phường",
        "short_codename": "phuong_10",
        "parentCode": "thanh_pho_soc_trang"
    },
    {
        "name": "Thị trấn Châu Thành",
        "code": "thi_tran_chau_thanh",
        "division_type": "thị trấn",
        "short_codename": "chau_thanh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Hồ Đắc Kiện",
        "code": "xa_ho_dac_kien",
        "division_type": "xã",
        "short_codename": "ho_dac_kien",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Tâm",
        "code": "xa_phu_tam",
        "division_type": "xã",
        "short_codename": "phu_tam",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thuận Hòa",
        "code": "xa_thuan_hoa",
        "division_type": "xã",
        "short_codename": "thuan_hoa",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Phú Tân",
        "code": "xa_phu_tan",
        "division_type": "xã",
        "short_codename": "phu_tan",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã Thiện Mỹ",
        "code": "xa_thien_my",
        "division_type": "xã",
        "short_codename": "thien_my",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Hiệp",
        "code": "xa_an_hiep",
        "division_type": "xã",
        "short_codename": "an_hiep",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Xã An Ninh",
        "code": "xa_an_ninh",
        "division_type": "xã",
        "short_codename": "an_ninh",
        "parentCode": "huyen_chau_thanh"
    },
    {
        "name": "Thị trấn Kế Sách",
        "code": "thi_tran_ke_sach",
        "division_type": "thị trấn",
        "short_codename": "ke_sach",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Thị trấn An Lạc Thôn",
        "code": "thi_tran_an_lac_thon",
        "division_type": "thị trấn",
        "short_codename": "an_lac_thon",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã Xuân Hòa",
        "code": "xa_xuan_hoa",
        "division_type": "xã",
        "short_codename": "xuan_hoa",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã Phong Nẫm",
        "code": "xa_phong_nam",
        "division_type": "xã",
        "short_codename": "phong_nam",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã An Lạc Tây",
        "code": "xa_an_lac_tay",
        "division_type": "xã",
        "short_codename": "an_lac_tay",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã Trinh Phú",
        "code": "xa_trinh_phu",
        "division_type": "xã",
        "short_codename": "trinh_phu",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã Ba Trinh",
        "code": "xa_ba_trinh",
        "division_type": "xã",
        "short_codename": "ba_trinh",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã Thới An Hội",
        "code": "xa_thoi_an_hoi",
        "division_type": "xã",
        "short_codename": "thoi_an_hoi",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã Nhơn Mỹ",
        "code": "xa_nhon_my",
        "division_type": "xã",
        "short_codename": "nhon_my",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã Kế Thành",
        "code": "xa_ke_thanh",
        "division_type": "xã",
        "short_codename": "ke_thanh",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã Kế An",
        "code": "xa_ke_an",
        "division_type": "xã",
        "short_codename": "ke_an",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã Đại Hải",
        "code": "xa_dai_hai",
        "division_type": "xã",
        "short_codename": "dai_hai",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Xã An Mỹ",
        "code": "xa_an_my",
        "division_type": "xã",
        "short_codename": "an_my",
        "parentCode": "huyen_ke_sach"
    },
    {
        "name": "Thị trấn Huỳnh Hữu Nghĩa",
        "code": "thi_tran_huynh_huu_nghia",
        "division_type": "thị trấn",
        "short_codename": "huynh_huu_nghia",
        "parentCode": "huyen_my_tu"
    },
    {
        "name": "Xã Long Hưng",
        "code": "xa_long_hung",
        "division_type": "xã",
        "short_codename": "long_hung",
        "parentCode": "huyen_my_tu"
    },
    {
        "name": "Xã Hưng Phú",
        "code": "xa_hung_phu",
        "division_type": "xã",
        "short_codename": "hung_phu",
        "parentCode": "huyen_my_tu"
    },
    {
        "name": "Xã Mỹ Hương",
        "code": "xa_my_huong",
        "division_type": "xã",
        "short_codename": "my_huong",
        "parentCode": "huyen_my_tu"
    },
    {
        "name": "Xã Mỹ Tú",
        "code": "xa_my_tu",
        "division_type": "xã",
        "short_codename": "my_tu",
        "parentCode": "huyen_my_tu"
    },
    {
        "name": "Xã Mỹ Phước",
        "code": "xa_my_phuoc",
        "division_type": "xã",
        "short_codename": "my_phuoc",
        "parentCode": "huyen_my_tu"
    },
    {
        "name": "Xã Thuận Hưng",
        "code": "xa_thuan_hung",
        "division_type": "xã",
        "short_codename": "thuan_hung",
        "parentCode": "huyen_my_tu"
    },
    {
        "name": "Xã Mỹ Thuận",
        "code": "xa_my_thuan",
        "division_type": "xã",
        "short_codename": "my_thuan",
        "parentCode": "huyen_my_tu"
    },
    {
        "name": "Xã Phú Mỹ",
        "code": "xa_phu_my",
        "division_type": "xã",
        "short_codename": "phu_my",
        "parentCode": "huyen_my_tu"
    },
    {
        "name": "Thị trấn Cù Lao Dung",
        "code": "thi_tran_cu_lao_dung",
        "division_type": "thị trấn",
        "short_codename": "cu_lao_dung",
        "parentCode": "huyen_cu_lao_dung"
    },
    {
        "name": "Xã An Thạnh 1",
        "code": "xa_an_thanh_1",
        "division_type": "xã",
        "short_codename": "an_thanh_1",
        "parentCode": "huyen_cu_lao_dung"
    },
    {
        "name": "Xã An Thạnh Tây",
        "code": "xa_an_thanh_tay",
        "division_type": "xã",
        "short_codename": "an_thanh_tay",
        "parentCode": "huyen_cu_lao_dung"
    },
    {
        "name": "Xã An Thạnh Đông",
        "code": "xa_an_thanh_dong",
        "division_type": "xã",
        "short_codename": "an_thanh_dong",
        "parentCode": "huyen_cu_lao_dung"
    },
    {
        "name": "Xã Đại Ân 1",
        "code": "xa_dai_an_1",
        "division_type": "xã",
        "short_codename": "dai_an_1",
        "parentCode": "huyen_cu_lao_dung"
    },
    {
        "name": "Xã An Thạnh 2",
        "code": "xa_an_thanh_2",
        "division_type": "xã",
        "short_codename": "an_thanh_2",
        "parentCode": "huyen_cu_lao_dung"
    },
    {
        "name": "Xã An Thạnh 3",
        "code": "xa_an_thanh_3",
        "division_type": "xã",
        "short_codename": "an_thanh_3",
        "parentCode": "huyen_cu_lao_dung"
    },
    {
        "name": "Xã An Thạnh Nam",
        "code": "xa_an_thanh_nam",
        "division_type": "xã",
        "short_codename": "an_thanh_nam",
        "parentCode": "huyen_cu_lao_dung"
    },
    {
        "name": "Thị trấn Long Phú",
        "code": "thi_tran_long_phu",
        "division_type": "thị trấn",
        "short_codename": "long_phu",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Xã Song Phụng",
        "code": "xa_song_phung",
        "division_type": "xã",
        "short_codename": "song_phung",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Thị trấn Đại Ngãi",
        "code": "thi_tran_dai_ngai",
        "division_type": "thị trấn",
        "short_codename": "dai_ngai",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Xã Hậu Thạnh",
        "code": "xa_hau_thanh",
        "division_type": "xã",
        "short_codename": "hau_thanh",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Xã Long Đức",
        "code": "xa_long_duc",
        "division_type": "xã",
        "short_codename": "long_duc",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Xã Trường Khánh",
        "code": "xa_truong_khanh",
        "division_type": "xã",
        "short_codename": "truong_khanh",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Xã Phú Hữu",
        "code": "xa_phu_huu",
        "division_type": "xã",
        "short_codename": "phu_huu",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Xã Châu Khánh",
        "code": "xa_chau_khanh",
        "division_type": "xã",
        "short_codename": "chau_khanh",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Xã Tân Thạnh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Xã Long Phú",
        "code": "xa_long_phu",
        "division_type": "xã",
        "short_codename": "xa_long_phu",
        "parentCode": "huyen_long_phu"
    },
    {
        "name": "Thị trấn Mỹ Xuyên",
        "code": "thi_tran_my_xuyen",
        "division_type": "thị trấn",
        "short_codename": "my_xuyen",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Đại Tâm",
        "code": "xa_dai_tam",
        "division_type": "xã",
        "short_codename": "dai_tam",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Tham Đôn",
        "code": "xa_tham_don",
        "division_type": "xã",
        "short_codename": "tham_don",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Thạnh Phú",
        "code": "xa_thanh_phu",
        "division_type": "xã",
        "short_codename": "thanh_phu",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Ngọc Đông",
        "code": "xa_ngoc_dong",
        "division_type": "xã",
        "short_codename": "ngoc_dong",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Thạnh Quới",
        "code": "xa_thanh_quoi",
        "division_type": "xã",
        "short_codename": "thanh_quoi",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Hòa Tú 1",
        "code": "xa_hoa_tu_1",
        "division_type": "xã",
        "short_codename": "hoa_tu_1",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Gia Hòa 1",
        "code": "xa_gia_hoa_1",
        "division_type": "xã",
        "short_codename": "gia_hoa_1",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Ngọc Tố",
        "code": "xa_ngoc_to",
        "division_type": "xã",
        "short_codename": "ngoc_to",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Gia Hòa 2",
        "code": "xa_gia_hoa_2",
        "division_type": "xã",
        "short_codename": "gia_hoa_2",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Xã Hòa Tú II",
        "code": "xa_hoa_tu_ii",
        "division_type": "xã",
        "short_codename": "hoa_tu_ii",
        "parentCode": "huyen_my_xuyen"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thi_xa_nga_nam"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thi_xa_nga_nam"
    },
    {
        "name": "Xã Vĩnh Quới",
        "code": "xa_vinh_quoi",
        "division_type": "xã",
        "short_codename": "vinh_quoi",
        "parentCode": "thi_xa_nga_nam"
    },
    {
        "name": "Xã Tân Long",
        "code": "xa_tan_long",
        "division_type": "xã",
        "short_codename": "tan_long",
        "parentCode": "thi_xa_nga_nam"
    },
    {
        "name": "Xã Long Bình",
        "code": "xa_long_binh",
        "division_type": "xã",
        "short_codename": "long_binh",
        "parentCode": "thi_xa_nga_nam"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thi_xa_nga_nam"
    },
    {
        "name": "Xã Mỹ Bình",
        "code": "xa_my_binh",
        "division_type": "xã",
        "short_codename": "my_binh",
        "parentCode": "thi_xa_nga_nam"
    },
    {
        "name": "Xã Mỹ Quới",
        "code": "xa_my_quoi",
        "division_type": "xã",
        "short_codename": "my_quoi",
        "parentCode": "thi_xa_nga_nam"
    },
    {
        "name": "Thị trấn Phú Lộc",
        "code": "thi_tran_phu_loc",
        "division_type": "thị trấn",
        "short_codename": "phu_loc",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Thị trấn Hưng Lợi",
        "code": "thi_tran_hung_loi",
        "division_type": "thị trấn",
        "short_codename": "hung_loi",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Lâm Tân",
        "code": "xa_lam_tan",
        "division_type": "xã",
        "short_codename": "lam_tan",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Thạnh Tân",
        "code": "xa_thanh_tan",
        "division_type": "xã",
        "short_codename": "thanh_tan",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Lâm Kiết",
        "code": "xa_lam_kiet",
        "division_type": "xã",
        "short_codename": "lam_kiet",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Tuân Tức",
        "code": "xa_tuan_tuc",
        "division_type": "xã",
        "short_codename": "tuan_tuc",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Vĩnh Thành",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Thạnh Trị",
        "code": "xa_thanh_tri",
        "division_type": "xã",
        "short_codename": "thanh_tri",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Vĩnh Lợi",
        "code": "xa_vinh_loi",
        "division_type": "xã",
        "short_codename": "vinh_loi",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Xã Châu Hưng",
        "code": "xa_chau_hung",
        "division_type": "xã",
        "short_codename": "chau_hung",
        "parentCode": "huyen_thanh_tri"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Xã Hòa Đông",
        "code": "xa_hoa_dong",
        "division_type": "xã",
        "short_codename": "hoa_dong",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Phường Khánh Hòa",
        "code": "phuong_khanh_hoa",
        "division_type": "phường",
        "short_codename": "khanh_hoa",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Xã Vĩnh Hiệp",
        "code": "xa_vinh_hiep",
        "division_type": "xã",
        "short_codename": "vinh_hiep",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Xã Vĩnh Hải",
        "code": "xa_vinh_hai",
        "division_type": "xã",
        "short_codename": "vinh_hai",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Xã Lạc Hòa",
        "code": "xa_lac_hoa",
        "division_type": "xã",
        "short_codename": "lac_hoa",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Phường Vĩnh Phước",
        "code": "phuong_vinh_phuoc",
        "division_type": "phường",
        "short_codename": "vinh_phuoc",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Xã Vĩnh Tân",
        "code": "xa_vinh_tan",
        "division_type": "xã",
        "short_codename": "vinh_tan",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Xã Lai Hòa",
        "code": "xa_lai_hoa",
        "division_type": "xã",
        "short_codename": "lai_hoa",
        "parentCode": "thi_xa_vinh_chau"
    },
    {
        "name": "Xã Đại Ân 2",
        "code": "xa_dai_an_2",
        "division_type": "xã",
        "short_codename": "dai_an_2",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Thị trấn Trần Đề",
        "code": "thi_tran_tran_de",
        "division_type": "thị trấn",
        "short_codename": "tran_de",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Xã Liêu Tú",
        "code": "xa_lieu_tu",
        "division_type": "xã",
        "short_codename": "lieu_tu",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Xã Lịch Hội Thượng",
        "code": "xa_lich_hoi_thuong",
        "division_type": "xã",
        "short_codename": "xa_lich_hoi_thuong",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Thị trấn Lịch Hội Thượng",
        "code": "thi_tran_lich_hoi_thuong",
        "division_type": "thị trấn",
        "short_codename": "lich_hoi_thuong",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Xã Trung Bình",
        "code": "xa_trung_binh",
        "division_type": "xã",
        "short_codename": "trung_binh",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Xã Tài Văn",
        "code": "xa_tai_van",
        "division_type": "xã",
        "short_codename": "tai_van",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Xã Viên An",
        "code": "xa_vien_an",
        "division_type": "xã",
        "short_codename": "vien_an",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Xã Thạnh Thới An",
        "code": "xa_thanh_thoi_an",
        "division_type": "xã",
        "short_codename": "thanh_thoi_an",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Xã Thạnh Thới Thuận",
        "code": "xa_thanh_thoi_thuan",
        "division_type": "xã",
        "short_codename": "thanh_thoi_thuan",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Xã Viên Bình",
        "code": "xa_vien_binh",
        "division_type": "xã",
        "short_codename": "vien_binh",
        "parentCode": "huyen_tran_de"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Phường 3",
        "code": "phuong_3",
        "division_type": "phường",
        "short_codename": "phuong_3",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Phường Nhà Mát",
        "code": "phuong_nha_mat",
        "division_type": "phường",
        "short_codename": "nha_mat",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Xã Vĩnh Trạch",
        "code": "xa_vinh_trach",
        "division_type": "xã",
        "short_codename": "vinh_trach",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Xã Vĩnh Trạch Đông",
        "code": "xa_vinh_trach_dong",
        "division_type": "xã",
        "short_codename": "vinh_trach_dong",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Xã Hiệp Thành",
        "code": "xa_hiep_thanh",
        "division_type": "xã",
        "short_codename": "hiep_thanh",
        "parentCode": "thanh_pho_bac_lieu"
    },
    {
        "name": "Thị trấn Ngan Dừa",
        "code": "thi_tran_ngan_dua",
        "division_type": "thị trấn",
        "short_codename": "ngan_dua",
        "parentCode": "huyen_hong_dan"
    },
    {
        "name": "Xã Ninh Quới",
        "code": "xa_ninh_quoi",
        "division_type": "xã",
        "short_codename": "ninh_quoi",
        "parentCode": "huyen_hong_dan"
    },
    {
        "name": "Xã Ninh Quới A",
        "code": "xa_ninh_quoi_a",
        "division_type": "xã",
        "short_codename": "ninh_quoi_a",
        "parentCode": "huyen_hong_dan"
    },
    {
        "name": "Xã Ninh Hòa",
        "code": "xa_ninh_hoa",
        "division_type": "xã",
        "short_codename": "ninh_hoa",
        "parentCode": "huyen_hong_dan"
    },
    {
        "name": "Xã Lộc Ninh",
        "code": "xa_loc_ninh",
        "division_type": "xã",
        "short_codename": "loc_ninh",
        "parentCode": "huyen_hong_dan"
    },
    {
        "name": "Xã Vĩnh Lộc",
        "code": "xa_vinh_loc",
        "division_type": "xã",
        "short_codename": "vinh_loc",
        "parentCode": "huyen_hong_dan"
    },
    {
        "name": "Xã Vĩnh Lộc A",
        "code": "xa_vinh_loc_a",
        "division_type": "xã",
        "short_codename": "vinh_loc_a",
        "parentCode": "huyen_hong_dan"
    },
    {
        "name": "Xã Ninh Thạnh Lợi A",
        "code": "xa_ninh_thanh_loi_a",
        "division_type": "xã",
        "short_codename": "ninh_thanh_loi_a",
        "parentCode": "huyen_hong_dan"
    },
    {
        "name": "Xã Ninh Thạnh Lợi",
        "code": "xa_ninh_thanh_loi",
        "division_type": "xã",
        "short_codename": "ninh_thanh_loi",
        "parentCode": "huyen_hong_dan"
    },
    {
        "name": "Thị trấn Phước Long",
        "code": "thi_tran_phuoc_long",
        "division_type": "thị trấn",
        "short_codename": "phuoc_long",
        "parentCode": "huyen_phuoc_long"
    },
    {
        "name": "Xã Vĩnh Phú Đông",
        "code": "xa_vinh_phu_dong",
        "division_type": "xã",
        "short_codename": "vinh_phu_dong",
        "parentCode": "huyen_phuoc_long"
    },
    {
        "name": "Xã Vĩnh Phú Tây",
        "code": "xa_vinh_phu_tay",
        "division_type": "xã",
        "short_codename": "vinh_phu_tay",
        "parentCode": "huyen_phuoc_long"
    },
    {
        "name": "Xã Phước Long",
        "code": "xa_phuoc_long",
        "division_type": "xã",
        "short_codename": "xa_phuoc_long",
        "parentCode": "huyen_phuoc_long"
    },
    {
        "name": "Xã Hưng Phú",
        "code": "xa_hung_phu",
        "division_type": "xã",
        "short_codename": "hung_phu",
        "parentCode": "huyen_phuoc_long"
    },
    {
        "name": "Xã Vĩnh Thanh",
        "code": "xa_vinh_thanh",
        "division_type": "xã",
        "short_codename": "vinh_thanh",
        "parentCode": "huyen_phuoc_long"
    },
    {
        "name": "Xã Phong Thạnh Tây A",
        "code": "xa_phong_thanh_tay_a",
        "division_type": "xã",
        "short_codename": "phong_thanh_tay_a",
        "parentCode": "huyen_phuoc_long"
    },
    {
        "name": "Xã Phong Thạnh Tây B",
        "code": "xa_phong_thanh_tay_b",
        "division_type": "xã",
        "short_codename": "phong_thanh_tay_b",
        "parentCode": "huyen_phuoc_long"
    },
    {
        "name": "Xã Vĩnh Hưng",
        "code": "xa_vinh_hung",
        "division_type": "xã",
        "short_codename": "vinh_hung",
        "parentCode": "huyen_vinh_loi"
    },
    {
        "name": "Xã Vĩnh Hưng A",
        "code": "xa_vinh_hung_a",
        "division_type": "xã",
        "short_codename": "vinh_hung_a",
        "parentCode": "huyen_vinh_loi"
    },
    {
        "name": "Thị trấn Châu Hưng",
        "code": "thi_tran_chau_hung",
        "division_type": "thị trấn",
        "short_codename": "chau_hung",
        "parentCode": "huyen_vinh_loi"
    },
    {
        "name": "Xã Châu Hưng A",
        "code": "xa_chau_hung_a",
        "division_type": "xã",
        "short_codename": "chau_hung_a",
        "parentCode": "huyen_vinh_loi"
    },
    {
        "name": "Xã Hưng Thành",
        "code": "xa_hung_thanh",
        "division_type": "xã",
        "short_codename": "hung_thanh",
        "parentCode": "huyen_vinh_loi"
    },
    {
        "name": "Xã Hưng Hội",
        "code": "xa_hung_hoi",
        "division_type": "xã",
        "short_codename": "hung_hoi",
        "parentCode": "huyen_vinh_loi"
    },
    {
        "name": "Xã Châu Thới",
        "code": "xa_chau_thoi",
        "division_type": "xã",
        "short_codename": "chau_thoi",
        "parentCode": "huyen_vinh_loi"
    },
    {
        "name": "Xã Long Thạnh",
        "code": "xa_long_thanh",
        "division_type": "xã",
        "short_codename": "long_thanh",
        "parentCode": "huyen_vinh_loi"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Phường Hộ Phòng",
        "code": "phuong_ho_phong",
        "division_type": "phường",
        "short_codename": "ho_phong",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Xã Phong Thạnh Đông",
        "code": "xa_phong_thanh_dong",
        "division_type": "xã",
        "short_codename": "phong_thanh_dong",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Phường Láng Tròn",
        "code": "phuong_lang_tron",
        "division_type": "phường",
        "short_codename": "lang_tron",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Xã Phong Tân",
        "code": "xa_phong_tan",
        "division_type": "xã",
        "short_codename": "phong_tan",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Xã Tân Phong",
        "code": "xa_tan_phong",
        "division_type": "xã",
        "short_codename": "tan_phong",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Xã Phong Thạnh",
        "code": "xa_phong_thanh",
        "division_type": "xã",
        "short_codename": "phong_thanh",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Xã Phong Thạnh A",
        "code": "xa_phong_thanh_a",
        "division_type": "xã",
        "short_codename": "phong_thanh_a",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Xã Phong Thạnh Tây",
        "code": "xa_phong_thanh_tay",
        "division_type": "xã",
        "short_codename": "phong_thanh_tay",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Xã Tân Thạnh",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "tan_thanh",
        "parentCode": "thi_xa_gia_rai"
    },
    {
        "name": "Thị trấn Gành Hào",
        "code": "thi_tran_ganh_hao",
        "division_type": "thị trấn",
        "short_codename": "ganh_hao",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã Long Điền Đông",
        "code": "xa_long_dien_dong",
        "division_type": "xã",
        "short_codename": "long_dien_dong",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã Long Điền Đông A",
        "code": "xa_long_dien_dong_a",
        "division_type": "xã",
        "short_codename": "long_dien_dong_a",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã Long Điền",
        "code": "xa_long_dien",
        "division_type": "xã",
        "short_codename": "long_dien",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã Long Điền Tây",
        "code": "xa_long_dien_tay",
        "division_type": "xã",
        "short_codename": "long_dien_tay",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã Điền Hải",
        "code": "xa_dien_hai",
        "division_type": "xã",
        "short_codename": "dien_hai",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã An Trạch",
        "code": "xa_an_trach",
        "division_type": "xã",
        "short_codename": "an_trach",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã An Trạch A",
        "code": "xa_an_trach_a",
        "division_type": "xã",
        "short_codename": "an_trach_a",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã An Phúc",
        "code": "xa_an_phuc",
        "division_type": "xã",
        "short_codename": "an_phuc",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã Định Thành",
        "code": "xa_dinh_thanh",
        "division_type": "xã",
        "short_codename": "dinh_thanh",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Xã Định Thành A",
        "code": "xa_dinh_thanh_a",
        "division_type": "xã",
        "short_codename": "dinh_thanh_a",
        "parentCode": "huyen_dong_hai"
    },
    {
        "name": "Thị trấn Hòa Bình",
        "code": "thi_tran_hoa_binh",
        "division_type": "thị trấn",
        "short_codename": "hoa_binh",
        "parentCode": "huyen_hoa_binh"
    },
    {
        "name": "Xã Minh Diệu",
        "code": "xa_minh_dieu",
        "division_type": "xã",
        "short_codename": "minh_dieu",
        "parentCode": "huyen_hoa_binh"
    },
    {
        "name": "Xã Vĩnh Bình",
        "code": "xa_vinh_binh",
        "division_type": "xã",
        "short_codename": "vinh_binh",
        "parentCode": "huyen_hoa_binh"
    },
    {
        "name": "Xã Vĩnh Mỹ B",
        "code": "xa_vinh_my_b",
        "division_type": "xã",
        "short_codename": "vinh_my_b",
        "parentCode": "huyen_hoa_binh"
    },
    {
        "name": "Xã Vĩnh Hậu",
        "code": "xa_vinh_hau",
        "division_type": "xã",
        "short_codename": "vinh_hau",
        "parentCode": "huyen_hoa_binh"
    },
    {
        "name": "Xã Vĩnh Hậu A",
        "code": "xa_vinh_hau_a",
        "division_type": "xã",
        "short_codename": "vinh_hau_a",
        "parentCode": "huyen_hoa_binh"
    },
    {
        "name": "Xã Vĩnh Mỹ A",
        "code": "xa_vinh_my_a",
        "division_type": "xã",
        "short_codename": "vinh_my_a",
        "parentCode": "huyen_hoa_binh"
    },
    {
        "name": "Xã Vĩnh Thịnh",
        "code": "xa_vinh_thinh",
        "division_type": "xã",
        "short_codename": "vinh_thinh",
        "parentCode": "huyen_hoa_binh"
    },
    {
        "name": "Phường 9",
        "code": "phuong_9",
        "division_type": "phường",
        "short_codename": "phuong_9",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Phường 4",
        "code": "phuong_4",
        "division_type": "phường",
        "short_codename": "phuong_4",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Phường 1",
        "code": "phuong_1",
        "division_type": "phường",
        "short_codename": "phuong_1",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Phường 5",
        "code": "phuong_5",
        "division_type": "phường",
        "short_codename": "phuong_5",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Phường 2",
        "code": "phuong_2",
        "division_type": "phường",
        "short_codename": "phuong_2",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Phường 8",
        "code": "phuong_8",
        "division_type": "phường",
        "short_codename": "phuong_8",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Phường 6",
        "code": "phuong_6",
        "division_type": "phường",
        "short_codename": "phuong_6",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Phường 7",
        "code": "phuong_7",
        "division_type": "phường",
        "short_codename": "phuong_7",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Phường Tân Xuyên",
        "code": "phuong_tan_xuyen",
        "division_type": "phường",
        "short_codename": "tan_xuyen",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Xã An Xuyên",
        "code": "xa_an_xuyen",
        "division_type": "xã",
        "short_codename": "an_xuyen",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Phường Tân Thành",
        "code": "phuong_tan_thanh",
        "division_type": "phường",
        "short_codename": "tan_thanh",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Xã Tân Thành",
        "code": "xa_tan_thanh",
        "division_type": "xã",
        "short_codename": "xa_tan_thanh",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Xã Tắc Vân",
        "code": "xa_tac_van",
        "division_type": "xã",
        "short_codename": "tac_van",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Xã Lý Văn Lâm",
        "code": "xa_ly_van_lam",
        "division_type": "xã",
        "short_codename": "ly_van_lam",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Xã Định Bình",
        "code": "xa_dinh_binh",
        "division_type": "xã",
        "short_codename": "dinh_binh",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Xã Hòa Thành",
        "code": "xa_hoa_thanh",
        "division_type": "xã",
        "short_codename": "hoa_thanh",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Xã Hòa Tân",
        "code": "xa_hoa_tan",
        "division_type": "xã",
        "short_codename": "hoa_tan",
        "parentCode": "thanh_pho_ca_mau"
    },
    {
        "name": "Thị trấn U Minh",
        "code": "thi_tran_u_minh",
        "division_type": "thị trấn",
        "short_codename": "u_minh",
        "parentCode": "huyen_u_minh"
    },
    {
        "name": "Xã Khánh Hòa",
        "code": "xa_khanh_hoa",
        "division_type": "xã",
        "short_codename": "khanh_hoa",
        "parentCode": "huyen_u_minh"
    },
    {
        "name": "Xã Khánh Thuận",
        "code": "xa_khanh_thuan",
        "division_type": "xã",
        "short_codename": "khanh_thuan",
        "parentCode": "huyen_u_minh"
    },
    {
        "name": "Xã Khánh Tiến",
        "code": "xa_khanh_tien",
        "division_type": "xã",
        "short_codename": "khanh_tien",
        "parentCode": "huyen_u_minh"
    },
    {
        "name": "Xã Nguyễn Phích",
        "code": "xa_nguyen_phich",
        "division_type": "xã",
        "short_codename": "nguyen_phich",
        "parentCode": "huyen_u_minh"
    },
    {
        "name": "Xã Khánh Lâm",
        "code": "xa_khanh_lam",
        "division_type": "xã",
        "short_codename": "khanh_lam",
        "parentCode": "huyen_u_minh"
    },
    {
        "name": "Xã Khánh An",
        "code": "xa_khanh_an",
        "division_type": "xã",
        "short_codename": "khanh_an",
        "parentCode": "huyen_u_minh"
    },
    {
        "name": "Xã Khánh Hội",
        "code": "xa_khanh_hoi",
        "division_type": "xã",
        "short_codename": "khanh_hoi",
        "parentCode": "huyen_u_minh"
    },
    {
        "name": "Thị trấn Thới Bình",
        "code": "thi_tran_thoi_binh",
        "division_type": "thị trấn",
        "short_codename": "thoi_binh",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Biển Bạch",
        "code": "xa_bien_bach",
        "division_type": "xã",
        "short_codename": "bien_bach",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Tân Bằng",
        "code": "xa_tan_bang",
        "division_type": "xã",
        "short_codename": "tan_bang",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Trí Phải",
        "code": "xa_tri_phai",
        "division_type": "xã",
        "short_codename": "tri_phai",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Trí Lực",
        "code": "xa_tri_luc",
        "division_type": "xã",
        "short_codename": "tri_luc",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Biển Bạch Đông",
        "code": "xa_bien_bach_dong",
        "division_type": "xã",
        "short_codename": "bien_bach_dong",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Thới Bình",
        "code": "xa_thoi_binh",
        "division_type": "xã",
        "short_codename": "xa_thoi_binh",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Tân Phú",
        "code": "xa_tan_phu",
        "division_type": "xã",
        "short_codename": "tan_phu",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Tân Lộc Bắc",
        "code": "xa_tan_loc_bac",
        "division_type": "xã",
        "short_codename": "tan_loc_bac",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Tân Lộc",
        "code": "xa_tan_loc",
        "division_type": "xã",
        "short_codename": "tan_loc",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Tân Lộc Đông",
        "code": "xa_tan_loc_dong",
        "division_type": "xã",
        "short_codename": "tan_loc_dong",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Xã Hồ Thị Kỷ",
        "code": "xa_ho_thi_ky",
        "division_type": "xã",
        "short_codename": "ho_thi_ky",
        "parentCode": "huyen_thoi_binh"
    },
    {
        "name": "Thị trấn Trần Văn Thời",
        "code": "thi_tran_tran_van_thoi",
        "division_type": "thị trấn",
        "short_codename": "tran_van_thoi",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Thị trấn Sông Đốc",
        "code": "thi_tran_song_doc",
        "division_type": "thị trấn",
        "short_codename": "song_doc",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Khánh Bình Tây Bắc",
        "code": "xa_khanh_binh_tay_bac",
        "division_type": "xã",
        "short_codename": "khanh_binh_tay_bac",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Khánh Bình Tây",
        "code": "xa_khanh_binh_tay",
        "division_type": "xã",
        "short_codename": "khanh_binh_tay",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Trần Hợi",
        "code": "xa_tran_hoi",
        "division_type": "xã",
        "short_codename": "tran_hoi",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Khánh Lộc",
        "code": "xa_khanh_loc",
        "division_type": "xã",
        "short_codename": "khanh_loc",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Khánh Bình",
        "code": "xa_khanh_binh",
        "division_type": "xã",
        "short_codename": "khanh_binh",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Khánh Hưng",
        "code": "xa_khanh_hung",
        "division_type": "xã",
        "short_codename": "khanh_hung",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Khánh Bình Đông",
        "code": "xa_khanh_binh_dong",
        "division_type": "xã",
        "short_codename": "khanh_binh_dong",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Khánh Hải",
        "code": "xa_khanh_hai",
        "division_type": "xã",
        "short_codename": "khanh_hai",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Lợi An",
        "code": "xa_loi_an",
        "division_type": "xã",
        "short_codename": "loi_an",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Phong Điền",
        "code": "xa_phong_dien",
        "division_type": "xã",
        "short_codename": "phong_dien",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Xã Phong Lạc",
        "code": "xa_phong_lac",
        "division_type": "xã",
        "short_codename": "phong_lac",
        "parentCode": "huyen_tran_van_thoi"
    },
    {
        "name": "Thị trấn Cái Nước",
        "code": "thi_tran_cai_nuoc",
        "division_type": "thị trấn",
        "short_codename": "cai_nuoc",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Thạnh Phú",
        "code": "xa_thanh_phu",
        "division_type": "xã",
        "short_codename": "thanh_phu",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Lương Thế Trân",
        "code": "xa_luong_the_tran",
        "division_type": "xã",
        "short_codename": "luong_the_tran",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Phú Hưng",
        "code": "xa_phu_hung",
        "division_type": "xã",
        "short_codename": "phu_hung",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Tân Hưng",
        "code": "xa_tan_hung",
        "division_type": "xã",
        "short_codename": "tan_hung",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Hưng Mỹ",
        "code": "xa_hung_my",
        "division_type": "xã",
        "short_codename": "hung_my",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Hoà Mỹ",
        "code": "xa_hoa_my",
        "division_type": "xã",
        "short_codename": "hoa_my",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Đông Hưng",
        "code": "xa_dong_hung",
        "division_type": "xã",
        "short_codename": "dong_hung",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Đông Thới",
        "code": "xa_dong_thoi",
        "division_type": "xã",
        "short_codename": "dong_thoi",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Tân Hưng Đông",
        "code": "xa_tan_hung_dong",
        "division_type": "xã",
        "short_codename": "tan_hung_dong",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Xã Trần Thới",
        "code": "xa_tran_thoi",
        "division_type": "xã",
        "short_codename": "tran_thoi",
        "parentCode": "huyen_cai_nuoc"
    },
    {
        "name": "Thị trấn Đầm Dơi",
        "code": "thi_tran_dam_doi",
        "division_type": "thị trấn",
        "short_codename": "dam_doi",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Tạ An Khương",
        "code": "xa_ta_an_khuong",
        "division_type": "xã",
        "short_codename": "ta_an_khuong",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Tạ An Khương Đông",
        "code": "xa_ta_an_khuong_dong",
        "division_type": "xã",
        "short_codename": "ta_an_khuong_dong",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Trần Phán",
        "code": "xa_tran_phan",
        "division_type": "xã",
        "short_codename": "tran_phan",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Tân Trung",
        "code": "xa_tan_trung",
        "division_type": "xã",
        "short_codename": "tan_trung",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Tân Đức",
        "code": "xa_tan_duc",
        "division_type": "xã",
        "short_codename": "tan_duc",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Tân Thuận",
        "code": "xa_tan_thuan",
        "division_type": "xã",
        "short_codename": "tan_thuan",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Tạ An Khương Nam",
        "code": "xa_ta_an_khuong_nam",
        "division_type": "xã",
        "short_codename": "ta_an_khuong_nam",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Tân Duyệt",
        "code": "xa_tan_duyet",
        "division_type": "xã",
        "short_codename": "tan_duyet",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Tân Dân",
        "code": "xa_tan_dan",
        "division_type": "xã",
        "short_codename": "tan_dan",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Tân Tiến",
        "code": "xa_tan_tien",
        "division_type": "xã",
        "short_codename": "tan_tien",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Quách Phẩm Bắc",
        "code": "xa_quach_pham_bac",
        "division_type": "xã",
        "short_codename": "quach_pham_bac",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Quách Phẩm",
        "code": "xa_quach_pham",
        "division_type": "xã",
        "short_codename": "quach_pham",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Thanh Tùng",
        "code": "xa_thanh_tung",
        "division_type": "xã",
        "short_codename": "thanh_tung",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Ngọc Chánh",
        "code": "xa_ngoc_chanh",
        "division_type": "xã",
        "short_codename": "ngoc_chanh",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Xã Nguyễn Huân",
        "code": "xa_nguyen_huan",
        "division_type": "xã",
        "short_codename": "nguyen_huan",
        "parentCode": "huyen_dam_doi"
    },
    {
        "name": "Thị Trấn Năm Căn",
        "code": "thi_tran_nam_can",
        "division_type": "thị trấn",
        "short_codename": "nam_can",
        "parentCode": "huyen_nam_can"
    },
    {
        "name": "Xã Hàm Rồng",
        "code": "xa_ham_rong",
        "division_type": "xã",
        "short_codename": "ham_rong",
        "parentCode": "huyen_nam_can"
    },
    {
        "name": "Xã Hiệp Tùng",
        "code": "xa_hiep_tung",
        "division_type": "xã",
        "short_codename": "hiep_tung",
        "parentCode": "huyen_nam_can"
    },
    {
        "name": "Xã Đất Mới",
        "code": "xa_dat_moi",
        "division_type": "xã",
        "short_codename": "dat_moi",
        "parentCode": "huyen_nam_can"
    },
    {
        "name": "Xã Lâm Hải",
        "code": "xa_lam_hai",
        "division_type": "xã",
        "short_codename": "lam_hai",
        "parentCode": "huyen_nam_can"
    },
    {
        "name": "Xã Hàng Vịnh",
        "code": "xa_hang_vinh",
        "division_type": "xã",
        "short_codename": "hang_vinh",
        "parentCode": "huyen_nam_can"
    },
    {
        "name": "Xã Tam Giang",
        "code": "xa_tam_giang",
        "division_type": "xã",
        "short_codename": "tam_giang",
        "parentCode": "huyen_nam_can"
    },
    {
        "name": "Xã Tam Giang Đông",
        "code": "xa_tam_giang_dong",
        "division_type": "xã",
        "short_codename": "tam_giang_dong",
        "parentCode": "huyen_nam_can"
    },
    {
        "name": "Thị trấn Cái Đôi Vàm",
        "code": "thi_tran_cai_doi_vam",
        "division_type": "thị trấn",
        "short_codename": "cai_doi_vam",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Thuận",
        "code": "xa_phu_thuan",
        "division_type": "xã",
        "short_codename": "phu_thuan",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Mỹ",
        "code": "xa_phu_my",
        "division_type": "xã",
        "short_codename": "phu_my",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Phú Tân",
        "code": "xa_phu_tan",
        "division_type": "xã",
        "short_codename": "phu_tan",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Tân Hải",
        "code": "xa_tan_hai",
        "division_type": "xã",
        "short_codename": "tan_hai",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Việt Thắng",
        "code": "xa_viet_thang",
        "division_type": "xã",
        "short_codename": "viet_thang",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Tân Hưng Tây",
        "code": "xa_tan_hung_tay",
        "division_type": "xã",
        "short_codename": "tan_hung_tay",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Rạch Chèo",
        "code": "xa_rach_cheo",
        "division_type": "xã",
        "short_codename": "rach_cheo",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Nguyễn Việt Khái",
        "code": "xa_nguyen_viet_khai",
        "division_type": "xã",
        "short_codename": "nguyen_viet_khai",
        "parentCode": "huyen_phu_tan"
    },
    {
        "name": "Xã Tam Giang Tây",
        "code": "xa_tam_giang_tay",
        "division_type": "xã",
        "short_codename": "tam_giang_tay",
        "parentCode": "huyen_ngoc_hien"
    },
    {
        "name": "Xã Tân Ân Tây",
        "code": "xa_tan_an_tay",
        "division_type": "xã",
        "short_codename": "tan_an_tay",
        "parentCode": "huyen_ngoc_hien"
    },
    {
        "name": "Xã Viên An Đông",
        "code": "xa_vien_an_dong",
        "division_type": "xã",
        "short_codename": "vien_an_dong",
        "parentCode": "huyen_ngoc_hien"
    },
    {
        "name": "Xã Viên An",
        "code": "xa_vien_an",
        "division_type": "xã",
        "short_codename": "vien_an",
        "parentCode": "huyen_ngoc_hien"
    },
    {
        "name": "Thị trấn Rạch Gốc",
        "code": "thi_tran_rach_goc",
        "division_type": "thị trấn",
        "short_codename": "rach_goc",
        "parentCode": "huyen_ngoc_hien"
    },
    {
        "name": "Xã Tân Ân",
        "code": "xa_tan_an",
        "division_type": "xã",
        "short_codename": "tan_an",
        "parentCode": "huyen_ngoc_hien"
    },
    {
        "name": "Xã Đất Mũi",
        "code": "xa_dat_mui",
        "division_type": "xã",
        "short_codename": "dat_mui",
        "parentCode": "huyen_ngoc_hien"
    }
]

export {ward}