import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import EditForm from './EditForm';
import StepperOp from './elements/Stepper';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function DetailForm() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8,
      paddingBottom: 4,
    }}>
      <Stack direction="row" spacing={1}
        justifyContent='space-between'
        alignItems="center" sx={{
          padding: 2,
          backgroundColor: theme.palette.primary.main
        }}>
        <Stack direction={'row'}>
          <Chip label="Chi tiết" variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
          <Typography variant='h5' color={'#fff'}>
            Cơ hội
          </Typography>
        </Stack>
        <Tabs
        value={value}
        className="tabHead"
        onChange={handleChange}
        indicatorColor={'primary'}
        textColor={'inherit'}
        variant="fullWidth"
        sx={{minWidth:360}}
        aria-label="full width tabs example"
      >

        <Tab label="Tiến độ" {...a11yProps(0)} />
        <Tab label="Thông tin" {...a11yProps(1)} />
      </Tabs>

      </Stack>

    
      <Box >

        <TabPanel value={value} index={0} dir={theme.direction}>
          <StepperOp />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <EditForm />
        </TabPanel>
      </Box>
    </Box>

  );
}
