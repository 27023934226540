import * as React from 'react';
import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { getLanguageById, updateLanguage } from '../../../actions/language';
import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';


const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)

    const [
        { name, isActive, code,_id, description },
        setState
    ] = useState({...editObject});




    const getDataById = () => {
        props?.getLanguageById(_id).then(res => {
            let data = res?.data

            setState(state => ({ ...state, ...data, from: data?.value?.from, to:data?.value?.to }))
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        getDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const handleSubmit = () => {
        let data = {name,  isActive, code, description}
        props?.updateLanguage(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
            })
    }

    React.useImperativeHandle(ref, () => ({ handleSubmit}));

    return (
        <Grid container spacing={{ xs: 0, md: 3 }} columns={{ xs: 12, md: 12 }}>
            <Grid item xs={12} md={4} >
                <FormGroup >
                    <FormControlLabel control={<Checkbox />}
                        checked={isActive}
                        name="isActive"
                        onChange={(e) => customeOnChange('isActive', !isActive)}
                        label="Active" />
                </FormGroup>
            </Grid>

            <Grid item md={4} xs={12} >
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off" >
                    <TextField
                        label="Code "
                        id="code"
                        value={code}
                        name="code"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item  md={4} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Name"
                        id="last-name"
                        value={name}
                        name="name"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item lg={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Note"
                        id="last-description"
                        value={description}
                        multiline={true}
                        minRows={4}
                        name="description"
                        onChange={onChange}
                    />
                </Box>
            </Grid>

        </Grid>
    );
})


const mapDispatchToProps = {
    getLanguageById, updateLanguage
}

const mapStateToProps = (state) => {
    return {
        editObj: state.edit
    }
}

export default connect(mapStateToProps, mapDispatchToProps,  null,  {forwardRef: true})(EditForm);