let dtThousand = [
    {
        key: 'x',
        length: 0,
        lng1: 'Ết',
        lng2: 'ອັດ'
    },
    {
        key: 'x0',
        length: 1,
        lng1: 'Síp',
        lng2: 'ສິບ'
    },
    {
        key: 'x00',
        length: 2,
        lng1: 'Hoi',
        lng2: 'ຮ້ອຍ'
    },
    {
        key: 'x000',
        lng1: 'Phăn',
        length: 3,
        lng2: 'ພັນ'
    },
    {
        key: 'x00000',
        length: 5,
        lng1: 'Sèn',
        lng2: 'ແສນ'
    },
    {
        key: 'x000000',
        lng1: 'Lan',
        length: 6,
        lng2: 'ລ້ານ'
    },
    {
        key: 'x000000000',
        length: 9,
        lng1: 'Tư',
        lng2: 'ຕື້'
    },
]

//Kíp	ກີບ

let dtDonvi = [
    {
        key: 0,
        lng1: 'Sủn',
        lng2: 'ສູນ'
    },
    {
        key: 1,
        lng1: 'Nừng',
        lng2: 'ຫນຶ່ງ'
    },
    {
        key: 2,
        lng1: 'Soỏng',
        lng2: 'ສອງ'
    },
    {
        key: 3,
        lng1: 'Sảm',
        lng2: 'ສາມ'
    },
    {
        key: 4,
        lng1: 'Sì',
        lng2: 'ສີ່'
    },
    {
        key: 5,
        lng1: 'Hạ',
        lng2: 'ຫ້າ'
    },
    {
        key: 6,
        lng1: 'Hốc',
        lng2: 'ຫົກ'
    },
    {
        key: 7,
        lng1: 'Chết',
        lng2: 'ເຈັດ'
    },
    {
        key: 8,
        lng1: 'Pẹt',
        lng2: 'ແປດ'
    },
    {
        key: 9,
        lng1: 'Cạu',
        lng2: 'ເກົ້າ'
    },
    {
        key: 10,
        lng1: 'Síp',
        lng2: 'ສິບ'
    },
    {
        key:20,
        lng1:'Xao',
        lng2: 'ຊາວ'
    }
]


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// eslint-disable-next-line
export { dtThousand, dtDonvi, MenuProps}