const initialState = {}

const editObjectReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_EDIT_OBJ":
               return {...action.payload}
        default:
            break;
    }

    return state
}

export default editObjectReducer