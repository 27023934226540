import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/vi';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/style.css';
import React, { useEffect, useState } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { getMessageGroupLoadMore } from "../../../actions/chat";
import config from '../../../config/index';
import ChatAction from '../chatInside/ChatAction';
import DocViewer from "./doc.js";

const initState = { pathFile: "", type: "", typeFile: "", isLoading: false, openFile: false };

const MainChatDetail = props => {
    const [
        { type, isLoading, openFile, pathFile, typeFile },
        setState
    ] = useState(initState);

    const { profile, currentChatMessage, chatUserOnline, typingMessage } = props

    const chatContainer = React.createRef();

    useEffect(() => {
        //scrollToBottom()
        // eslint-disable-next-line
    }, [isLoading])

    useEffect(() => {
        scrollToBottom()
        console.log('scroll - to - last message')
        // eslint-disable-next-line
    }, [currentChatMessage?.messages])

    const renderImgMessage = (images) => {
        return images.length > 0 && <div className="contentImgMessage">
            <PhotoProvider>
                {images.map((img, index) => {
                    var path = `${config.baseURL}${img.path}`
                    img.original = path + `?image=` + (index + 1);
                    img.width = _.get(img, 'dimensions.width', 1024)
                    img.height = _.get(img, 'dimensions.height', 728)
                    img.title = `message#${(index + 1)}`

                    return index < 5 ?
                        <PhotoView key={index} src={`${path}`} overlay={<div className='NamePicture'>Image</div>}>
                            <div className={`childImg ${images.length === 1 ? 'alone' : ''}`}>
                                <img src={`${img.original}`} alt={img.name} />
                            </div>
                        </PhotoView>
                        :
                        <PhotoView key={index} src={`${path}`}>
                            <div className={`childImg ${images.length === 1 ? 'alone' : ''}`}>
                                <img src={`${img.original}`} alt={img.name} />
                                <div className={`childImg moreImg`}>
                                    <img src="/assets/icon/plus.png" alt="Show more ..." />
                                </div>
                            </div>
                        </PhotoView>
                })}

            </PhotoProvider>
        </div>
    }

    const renderMessage = (data) => {
        return data.length > 0 && data.map((item, index) => {
            var messageProfile = 'System'
            var isMe = item.senderEmployeeCode === profile.employeeCode ? true : false
            var sender = item.senderChat?.profile || ''
            if (item.isSystemNotification !== true) {
                messageProfile = item?.senderChat?.profile
            }

            var isContinue = false
            var isDateContinue = false
            if (index > 0) {
                if (data[index - 1].senderId === item.senderId && data[index - 1].isSystemNotification === false) {
                    isContinue = true
                }
            }

            if (index === 0 || moment(data[index - 1].created).format('L') !== moment(item.created).format('L')) {
                isDateContinue = true
            }

            return <React.Fragment key={`${item._id}${Math.random() * (1 - 10000)}`}>
                {isDateContinue === true && <li className={`itemMessageDetail system`} >{moment(item.created).locale('en').format('dddd')}, {moment(item.created).locale('en').format('LL')}</li>}

                {item.isSystemNotification === true && <li className={`itemMessageDetail system notifi`} >{item.content}</li>}
                {item.isSystemNotification === false && <li className={`itemMessageDetail ${item.type === 'image' && 'img'} ${isMe === true && 'me'}`} >
                    {(index > 0 && isContinue === false)
                        && <div className="avatar detailMess">
                            {_.get(messageProfile, 'fileAvatar.path', '') === '' ? _.get(messageProfile, 'lastName', '').charAt(0) : <div className="ctImg"><img src={config.baseURL + _.get(messageProfile, 'fileAvatar.path', '')} alt={_.get(messageProfile, 'lastName', '')} /></div>}
                        </div>}
                    {index === 0 && <div className="avatar detailMess">
                        {_.get(messageProfile, 'fileAvatar.path', '') === '' ? _.get(messageProfile, 'lastName', '').charAt(0) : <div className="ctImg"><img src={config.baseURL + _.get(messageProfile, 'fileAvatar.path', '')} alt={_.get(messageProfile, 'lastName', '')} /></div>}
                    </div>}

                    <div className={`bodyMessage ${index > 0 && isContinue === true ? "none-avt" : ''} ${item.type !== 'text' && 'ortherMess'}`}>
                        {!_.isEmpty(sender) && isMe === false && isContinue === false ?
                            <div className="senderName" style={{ textAlign: 'left' }}>{sender?.firstName + ' ' + sender?.lastName}</div> : <></>}
                        <div className={item.type === 'text' ? "lineMessage " : "lineMessage img_line"}>
                            <div className="lineContent">
                                {item.isActive === false ? <div className="withdrawMessage">Tin nhắn đã được thu hồi</div> :
                                    <React.Fragment>
                                        {item.type === 'image' ? renderImgMessage(item.images) : item.content}
                                        {item.type === 'document' && renderFile(item.documents)}
                                        {item.type === 'video' && renderVideo(item.videos)}
                                        {isMe === true && <div className="delChat" onClick={() => props.deleteChat({ messageId: item._id })}>
                                            <DeleteOutlineOutlinedIcon style={{ fontSize: 14 }} />
                                        </div>}
                                    </React.Fragment>}

                            </div>
                            <div className="lineTime">{moment(item.created).format('LT')}</div>
                        </div>
                    </div>
                </li>}
            </React.Fragment>
        })
    }

    const renderFile = (files) => {
        return <div className="listFile">
            {files.map((res, idex) => {
                return <div className="itemChatFile" key={`fi${idex}`} onClick={() => _handleOpenFile(res, 'document')}>
                    <img src="/resources/icons/file.png" width="32px" alt="" />
                    <div className="nameFile">
                        {res.originalName}
                    </div>
                </div>
            })}
        </div>
    }

    const renderVideo = (videos) => {
        return <div className="listVideo">
            {videos.map((res, idex) => {
                return <div className="itemChatVideo" key={`fi${idex}`} onClick={() => _handleOpenFile(res, 'video')}>
                    <img src="/assets/icon/video.png" width="32px" alt="" />
                    <div className="nameFile">
                        {res.originalName}
                    </div>
                </div>
            })}
        </div>
    }

    const _handleOpenFile = (item, type) => {
        setState(state => ({
            ...state, titleFile: item.name,
            typeFile: item.extension,
            pathFile: `${config.baseURL}${item.path}`,
            openFile: true,
            type: type
        }))
    }

    const closeFile = () => {
        setState(state => ({ ...state, titleFile: '', typeFile: '', pathFile: '', openFile: false }))
    }

    const onScroll = () => {
        //const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = chatContainer.current.scrollTop
        if (scrollTop === 0) {
            setState(state => ({ ...state, isLoading: true }))
            props.getMessageGroupLoadMore(currentChatMessage.messages[0]._id, currentChatMessage.code)
                .then(it => {
                    setState(state => ({ ...state, isLoading: false }))
                })
        }
    }

    const scrollToBottom = () => {
        const scroll = chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
        chatContainer.current.scrollTo(0, scroll);
    };

    var name = ''
    var status = 'Offline'
    var isSingle = false
    var isOnline = false
    var messages = _.get(currentChatMessage, 'messages', [])

    if (_.get(currentChatMessage, 'type', '') === 'single') {

        var indexEmp = currentChatMessage.memberArr.findIndex(sss => sss?.employeeId?.code !== profile.employeeCode)
        var prof = currentChatMessage.memberArr[indexEmp]
        name = `${_.get(prof, 'name', '')} `
        isSingle = true

        if (chatUserOnline.findIndex(us => us === prof?.userId?._id) > -1) {
            isOnline = true
            status = 'Online'
        }
    }

    var typing = false
    var typingName = ''
    var isTypingInCurrent = typingMessage.length > 0 && currentChatMessage.code === typingMessage[0].room ? true : false

    if (typingMessage.length > 0 && isTypingInCurrent === true) {
        typing = typingMessage[0]
        var tmp = currentChatMessage.memberArr.filter(ite => ite.userId._id === typing.data.userId)

        typingName = tmp[0].employeeId.firstName + ' ' + tmp[0].employeeId.lastName
        // if (this.props.profile.userId === typing.data.userId)
        //     typingName = 'Bạn'
    }
    var sumOnlGroup = 0

    return (
        <div className="centerColChat">
            <div className="detailChatContainer">
                <div className="topDetailChat">
                    {isSingle === false && <div className="avatar group">
                        {currentChatMessage?.memberArr?.map(function (receive, i) {

                            if (chatUserOnline.findIndex(us => us === receive.userId._id) > -1) {
                                sumOnlGroup++
                            }

                            if (i < 2) {
                                var prof = _.get(receive, 'employeeId', {})
                                return <div className="avChild" key={i}>
                                    {_.get(prof, 'fileAvatar.path', '') === '' ? _.get(prof, 'lastName', '').charAt(0) : <img src={config.baseURL + _.get(prof, 'fileAvatar.path', '')} alt={_.get(prof, 'lastName', '')} />}
                                </div>
                            }
                            else if (i === _.get(currentChatMessage, 'memberArr', []).length - 1) {
                                return <div className="avChild absol" key={i}>+{_.get(currentChatMessage, 'memberArr', []).length - 2}</div>
                            }
                            else {
                                return ''
                            }
                        })
                        }
                    </div>}

                    {isSingle === true && <div className="avatar alone">
                        {_.get(prof, 'employeeId.fileAvatar.path', '') === '' ? _.get(prof, 'employeeId.lastName', '').charAt(0) : <div className="ctImg"><img src={config.baseURL + _.get(prof, 'employeeId.fileAvatar.path', '')} alt={_.get(prof, 'employeeId.lastName', '')} /></div>}
                    </div>}
                    <div className="titDetail">
                        {isSingle === false ? currentChatMessage.name : name}
                        {isSingle === true && <p> <span className={`statusChat ${isOnline === true && "onl"}`}></span>{status}</p>}
                        {isSingle === false && <p> <span className={`statusChat ${sumOnlGroup > 0 && "onl"}`}></span>{sumOnlGroup > 0 ? sumOnlGroup : ''} {Number(sumOnlGroup) > 1 ? ' peoples are online' : Number(sumOnlGroup) === 1 ? ' person is online' : ' offline'}</p>}
                    </div>

                </div>
                <div id="chatInside" className="bodyDetailChat" ref={chatContainer} onScroll={onScroll}>
                    <ul className="listMessageDetail">
                        {isLoading === true && <li className="itemMessageDetail system notifi loadmore"><LinearProgress /></li>}
                        {renderMessage(messages)}
                    </ul>
                    {(typingMessage.length > 0 && currentChatMessage.code === _.get(typing, 'room', '') && _.get(typing, 'data.isTyping', false) === true) &&
                        <div className={`typingDetail`} >
                            <div className="spinner" style={{ marginRight: 8 }}>
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                                <div className="bounce4"></div>
                            </div>
                            <b>{typingName}</b>&nbsp; is typing</div>}
                </div>

                <ChatAction scrollToBottom={scrollToBottom} />
            </div>

            <Dialog
                open={openFile}
                onClose={closeFile}
                fullWidth={true}
                maxWidth={`lg`}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogContent>
                    {type === 'document' ? <DocViewer source={pathFile} type={typeFile} /> :
                        <div className="customePlayer">
                            <ReactPlayer
                                playing={openFile}
                                url={pathFile} />
                        </div>}
                </DialogContent>
            </Dialog>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        chatUserOnline: state.chatUserOnline,
        chatMessageLasted: state.chatMessageLasted,
        chatGroup: state.chatGroup,
        userAll: state.user,
        profile: state.profile,
        typingMessage: state.typingMessage,
        currentChatMessage: state.currentChatMessage
    };
}

const mapDispatchToProps = {
    getMessageGroupLoadMore
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(MainChatDetail);