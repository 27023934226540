import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";



export const getRuleById = (id) => (dispatch) => {
  return setTokenAxios().get(`rule/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`rule`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_RULE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`rule`, { params: condition })
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const createRule = (data) => (dispatch) => {
  return setTokenAxios().post(`rule`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_RULE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateRule = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`rule/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_RULE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteRule = (_id) => (dispatch) => {
  return setTokenAxios().delete(`rule/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_RULE",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}