import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useReactToPrint } from 'react-to-print';
import { useEffect } from 'react';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { Box } from '@mui/material';
import { blue } from '@mui/material/colors';

export default function DialogPrint(props) {
    const { isOpen, handleRenderData, handleCloseProps } = props
    const [open, setOpen] = React.useState(false);
    const [template, setTemplate] = React.useState(false);
    const componentPrintRef = React.useRef();

    useEffect(() => {
        setOpen(isOpen)
        let tmpl = handleRenderData()
        setTemplate(tmpl)
        // eslint-disable-next-line
    }, [isOpen])

    const handleClose = () => {
        setOpen(false);
        handleCloseProps(false)
    };


    const actionPrint = useReactToPrint({
        content: () => componentPrintRef.current,
    });

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            fullWidth
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: blue[500] }}>
                <Box flexDirection={'row'} alignItems={'center'} display={'flex'} color={'#fff'}>
                    <PrintOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
                    {"Prepare to print"}
                </Box>
            </DialogTitle >
            <DialogContent style={{
                background: '#e6e6e6',
                paddingTop: 24
            }}>
                <div ref={componentPrintRef} className='A4'>
                    {template}
                </div>
            </DialogContent>
            <DialogActions sx={{ background: '#ffff' }}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={actionPrint} variant='contained' disableElevation>Print</Button>
            </DialogActions>
        </Dialog >
    );
}