import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import { Box, Typography, useTheme } from '@mui/material';
import { blue, green, grey, indigo, orange } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { getAllData } from '../../../actions/request';
import { setEditObject } from '../../../actions/untils';
import { _showAlertError, _showAlertSuccess } from '../../../untils/error';
import BreadCrumb from '../../elements/breadCrumb';
import DrawerExpand from '../../elements/drawerExpand';
import MainForm from '../../elements/MainForm';
import CustomizedMenus from '../../elements/rightMenuCate';
import AddForm from './AddForm';
import EditForm from './EditForm';
import RequestDialog from './RequestDialog';
import RequestDialogDocument from './RequestDialogDocument';

const Main = (params) => {
  const { props } = params
  const dispatch = useDispatch()
  const theme = useTheme()
  const { t } = useTranslation()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const mainFormRef = React.useRef();
  const childFormRef = React.useRef();
  const [isRequest, setIsRequest] = useState(false)
  const [requestFrom, setRequestFrom] = useState('')
  const [type, setType] = useState('add')
  const actionAdd = useSelector(state => state?.enumRedux?.actionAdd)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [indexRow, setIndexRow] = React.useState(null);
  const [dataMenu, setDataMenu] = React.useState({});
  const editObject = useSelector(state => state.edit)

  useEffect(() => {
    if (actionAdd === true) {
      setType('add')
      mainFormRef.current?.openFromParent();
    }
  }, [actionAdd])


  let listData = [...props?.data]

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionMenu = async (value, type) => {
    mainFormRef.current?.openFromParent();
    setType(type)
    if (type !== 'add') {
      dispatch({
        type: "SET_ENUM",
        payload: {
          actionAdd: true,
        }
      })
      await props?.setEditObject(value)
    }
  }

  const handleSave = async () => {
    childFormRef.current?.handleSubmit();
  }

  useEffect(() => {
    props?.getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleApprove = () => {
    setIsRequest(true)
    if(dataMenu?.source !== '' && dataMenu?.requestForCode !== '')
    {
      setRequestFrom('document')
    }
  }

  const handleRightClick = (event, val, index) => {
    event.preventDefault();
    setAnchorEl(event);
    setDataMenu(val)
    setIndexRow(index)
  }

  const handleDelete = (val) => {
    Swal.fire({
      title: 'Warning!',
      html: `<span style="font-size:15px">${t('alrDelete')}</span>`,
      showDenyButton: true,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: `${t('btnConfirm')}`,
      denyButtonText: `${t('btnDelete')}`,
      focusConfirm: false,
      focusDeny: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        props?.deleteIncome(val._id).then(res => {
          _showAlertSuccess(`${t('lblSuccess')}` || '')
        })
          .catch(err => {
            _showAlertError(`${t('lbError')}` || '')
          })
      } else if (result.isDenied) {
        //   Swal.fire('Changes are not saved', '', 'info')
      }
    })

  }

  const handleActive = (val) => {
    setIsRequest(true)
  
  }
  const closeRequest = () => { setIsRequest(false) }

  const renderRow = () => {

    let list = rowsPerPage > 0
      ? listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : listData


    return list.map((row, index) => (
      <TableRow key={row._id} onContextMenu={(e) => handleRightClick(e, row, index)} >
        <TableCell align="left">
        
          {page * rowsPerPage + index + 1}.
        </TableCell>
        <TableCell align="left">
          <Typography variant='subtitle2' color={indigo[500]} sx={{ lineHeight: 1 }}>
            {row?.type}
          </Typography>
         
        </TableCell>
        <TableCell component="th" scope="row" sx={{
          borderWidth: 0.5
        }}>
          <Typography variant='subtitle2' color={indigo[500]} sx={{ lineHeight: 1 }}>
            {row?.name}
          </Typography>
          <Typography variant='subtitle2' color={grey[600]} sx={{ lineHeight: 1, mt: 0.5, fontSize: 9 }}>
            {t('txtCreated')} {row?.creater?.firstName + ' ' + row?.creater?.lastName}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <div style={{ float: 'right', overflow: "hidden", textOverflow: "ellipsis", maxWidth: '450px' }}>
            <Typography noWrap>
              {row?.description}
            </Typography>
            <Typography variant='subtitle2' color={grey[600]} sx={{ lineHeight: 1, mt: 0.5, fontSize: 9 }}>
              {moment(row?.created).format('DD-MM-YYYY HH:mm')}
            </Typography>
          </div>
        </TableCell>
        <TableCell align="right">{row?.isDeny ? <Typography color={orange[600]}>
          Denied
        </Typography> :
          row.isConfirm === false ? <Typography color={blue[600]}>
            Waiting approve
          </Typography> :
            <Typography color={green[600]}>
              Approved
            </Typography>}</TableCell>
      </TableRow>
    ))
  }

  let breadcrumb = [{ name: t('lblHome'), href: '/' }, { name: t('frmSetRequestTemplate'), href: '/req-queue' }]
  return (
    <>
      <BreadCrumb data={breadcrumb} />
      <TableContainer component={Paper} sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 0,
        padding: 2,
        borderRadius: 2
      }}>
        <Box sx={{ fontSize: 24 }}>
          {/* Table of Customer */}
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">

          <TableHead>
            <TableRow sx={{
              fontWeight: 'bold'
            }}>
              <TableCell>{t('lblColNo')}</TableCell>
              <TableCell>{t('lblColType')}</TableCell>
              <TableCell>{t('lblColName')}</TableCell>
              <TableCell align="right">{t('lblColDescription')}</TableCell>
              <TableCell align="right">{t('lblColStatus')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow()}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8, 16, 25, { label: `${t('pagingAll')}`, value: -1 }]}
                colSpan={6}
                count={listData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': t('pagingPerPage'),
                  },
                  native: true,
                }}
                labelRowsPerPage={t('pagingPerPage')}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {anchorEl ? <CustomizedMenus anchorEl={anchorEl} data={dataMenu}
        handleActive={handleActive}
        activeText={t('lblApprove')}
        activeIcon={<BeenhereOutlinedIcon fontSize="small" />}
        closeRequest={closeRequest}
        handleApprove={handleApprove}
        // handleStar={}
        handleDelete={handleDelete}
        // handlePrint={}
        // handleComment={}
        // handleDuplicate={}
        indexRow={indexRow}
        handleEdit={handleActionMenu} type="transaction" /> : <></>}

      {isRequest&&requestFrom==='document'  ? <RequestDialogDocument isOpen={isRequest} data={dataMenu} closeRequest={closeRequest} /> : <></>}
      {isRequest&&requestFrom===''  ? <RequestDialog isOpen={isRequest} data={dataMenu} closeRequest={closeRequest} /> : <></>}
      <MainForm ref={mainFormRef} maxWidth={'sm'} actionSave={handleSave} subtitle={t('frmSetRequestTemplate')} title={type === 'add' && isEmpty(editObject)? t('lblCreate') : t('lblUpdate')}>
        {type === 'add' && isEmpty(editObject) ? <AddForm ref={childFormRef} /> : <EditForm ref={childFormRef} />}
      </MainForm>
    </>
  )
}

const Request = (props) => {
  const { t } = useTranslation()
  let title = t('frmSetRequestTemplate')
  document.title = title
  return (
    <>
      <DrawerExpand headerText={title}>
        <Main props={props} />
      </DrawerExpand>
    </>
  );
};

const mapDispatchToProps = {
  getAllData, setEditObject
}

const mapStateToProps = (state) => {
  return {
    data: state.request
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Request);