import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { updateNotStartStage } from '../../../../actions/chance';
import ChanceNote from './ChanceNote';



const Step0 = (props) => {

    const edit = useSelector(state => state.edit)

    const handleStart = () =>{
        props?.updateNotStartStage(edit?._id, {"stage": "communication"})
    }
    
    useEffect(() => {
     console.log('activeStep', props?.activeStep)
    }, [props?.activeStep])
    
    
    return (
        <Box component={'div'} sx={{minHeight: 450, padding:2}}>
            <Grid container spacing={2}>
                <Box sx={{
                    marginY: 4,
                    display:'flex',
                    justifyContent:'center',
                    flex:1,
                    background:'#F7FAFC',
                    paddingY:3,
                    paddingX: 1
                }}>
                    {/* Kiểm tra stage hiện tại của chance đã bắt đầu hay chưa */}
                    {edit?.stage === 'notStart'?
                     <Button onClick={()=>handleStart()}>
                     BẮT ĐẦU
                 </Button>:
                    <Typography>
                        Đang tiến hành chăm sóc    
                    </Typography>}
                       
                </Box>
                {props?.activeStep===0&&
                    <ChanceNote stage={props?.stage}></ChanceNote>
                }
            </Grid> 
        </Box>
    );
};


const mapDispatchToProps = {
    updateNotStartStage
  }
  export default connect(null, mapDispatchToProps)(Step0);