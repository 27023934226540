import * as React from 'react';
import { useState } from 'react';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { teal } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import { createUser } from '../../../actions/user';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import { useTranslation } from 'react-i18next';
import { getAllDataNoneRedux as getEmployee } from '../../../actions/employee';
import { find } from 'lodash';

const initialState = {
    firstName: "",
    lastName: "",
    password: "",
    userName: "",
    userType:'saler',
    isActive:true,
    code:'',
    phone:''
  };
 function AddForm(props) {
    const theme = useTheme()
    const {t} = useTranslation();
    const [
        { firstName, lastName,userName, userType, isActive,
            employeeId, listEmployee,
            code, password, phone },
        setState
      ] = useState(initialState);

      const init = () =>{
        props?.getEmployee()
        .then(res=>{
           setState(state => ({...state, listEmployee: res?.data}))
        })
      }

      React.useEffect(() => {
        init()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
      

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
      };

      const handleSubmit = () =>{
        let data = {firstName, lastName, userName, password,
            employeeId,
            userType, isActive, code, phone:{
            "nationCode": "+84",
            "phoneNumber": phone
        }}
        props?.createUser(data)
        .then(res=>{
            _showAlertSuccess(res?.message || GET_SUCCESS)
            _clearState()
        })
    }

    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8 }}>
            <Stack direction="row" spacing={1}
                justifyContent='space-between'
                alignItems="center" sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.main
                }}>
                <Stack direction={'row'}>
                    <Chip label={t('lblCreate')} variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
                    <Typography variant='h5' color={'#fff'}>
                       {t('frmUserTitle')}
                    </Typography>
                </Stack>
                <Button variant='contained' disableElevation onClick={()=>handleSubmit()}>
                    {t('btnSubmit')}
                </Button>
            </Stack>
            <Box sx={{ paddingX: 2, paddingY: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color={teal[500]}>
                            {t('lblGeneralInfo')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserFirstName')}
                                    id="first-name"
                                    value={firstName}
                                    name="firstName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserLastName')}
                                    id="last-name"
                                    value={lastName}
                                    name="lastName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} 
                            checked={isActive}
                            name="isActive"
                            onChange={(e)=>customeOnChange('isActive', !isActive)}
                            label={t('frmActive')}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserCode')}
                                    id="code"
                                    value={code}
                                    name="code"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="demo-select-small">{t('frmUserType')}</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={userType}
                                name="userType"
                                onChange={onChange}
                                label={t('frmUserType')}
                            >
                                <MenuItem value='root'>{t('frmUserTypeRoot')}</MenuItem>
                                <MenuItem value='user'>{t('frmUserTypeUser')}</MenuItem>
                                {/* <MenuItem value='manager'>Quản lý</MenuItem>
                                <MenuItem value='leader'>Trưởng nhóm</MenuItem>
                                <MenuItem value='telesale'>Tele sale</MenuItem>
                                <MenuItem value='saler'>Sale</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="demo-select-employeeId">Employee</InputLabel>
                            <Select
                                labelId="demo-select-employeeId"
                                id="demo-select-employeeId"
                                value={employeeId}
                                name="employeeId"
                                onChange={(e)=>{
                                    let u = find(listEmployee,{_id: e.target.value})
                                    setState((state)=>({...state, firstName: u.firstName, 
                                        phone: u?.phone,
                                        lastName: u.lastName, employeeId: u?._id}))
                                }}
                                label={t('frmUserType')}
                            >
                                {listEmployee?.map((res, i)=>{
                                    return <MenuItem value={res?._id} key={i}>{res?.code} - {res?.lastName} {res?.firstName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                   
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserUserName')}
                                    id="outlined-size-username"
                                    value={userName}
                                    name="userName"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label={t('frmUserPassword')}
                                    id="outlined-size-password"
                                    type="password"
                                    value={password}
                                    name="password"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Ghi chú"
                                    id="outlined-size-description"
                                    multiline={true}
                                    size="small"
                                    minRows={5}
                                    fullWidth={true}
                                />
                            </div>
                        </Box>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    );
}
const mapDispatchToProps = {
    createUser, getEmployee
}
export default connect(null, mapDispatchToProps)(AddForm);