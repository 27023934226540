const getExtension = (type) =>  {
    var parts = type.split('/');
    return parts[parts.length - 1];
  }
  
  const isImage = (filename) =>  {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
        return true;
      case 'gif':
        return true;
      case 'bmp':
        return true;
      case 'png':
        return true;
      case 'jpeg':
         return true;
        //etc
        default:
          return false;
    }
  }
  
  const isVideo = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'm4v':
        return true;
      case 'avi':
        return true;
      case 'mpg':
        return true;
      case 'mp4':
        return true;
        // etc
        default:
          return false;
    }
  }

  const isDocument = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'msword':
        return true;
      case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return true;
      case 'pdf':
        return true;
      case 'xls':
        return true;
      case 'doc':
        return true;
      case 'docx':
        return true;
      case 'xlsx':
            return true;
        // etc
      default:
        return false;

    }
  }

  export {isDocument, isImage, isVideo, getExtension}