const initialState = []

const dataReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_DATA":
            return action.payload
            
        case "CREATE_DATA":
            return [...state, action.payload]

        case "UPDATE_DATA":
            
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            
            return [...state]

        case "CHANGE_PASSWORD":
            
                const password = state.findIndex(per => per._id === action.payload._id)
                state[password] = action.payload;
                
                return [...state]

        case "UPDATE_DATA_ACTIVE":
            const data_ = [...state] 
            if(action.payload.length > 0)
            {
                (action.payload).forEach((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    data_[index] = item;
                })
            }
            return data_

        case "SET_CURRENT_DATA_IMG":
                return [...state]

        case "DELETE_DATA":
            const data_old = [...state] 
            var data_deleted = []
           
            if(action.payload.length > 0)
            {
                data_deleted = data_old.filter(del =>!(action.payload).includes(del._id) );
            }
            return data_deleted
        

        default:
            break;
    }

    return state
}

export default dataReducer