import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";



export const getProjectById = (id) => (dispatch) => {
  return setTokenAxios().get(`project/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`project`, condition)
    .then(async res => {
      dispatch({
        type: "SET_PROJECT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`project`, { params: condition })
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const createProject = (data) => (dispatch) => {
  return setTokenAxios().post(`project`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_PROJECT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateProject = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`project/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_PROJECT",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}