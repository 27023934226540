const initialState = {}

const taskDetailReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case "SET_TASK_DETAIL":
            return action.payload

        case "UPDATE_TASK_DETAIL":
            var tmpState = {...state}
            if(action.payload._id === tmpState._id)
            {
                return action.payload
            }

            return {...tmpState}
        
        case "UPDATE_SUB_DETAIL":

            var tmpState1 = {...state}
            if(action.payload.taskId === tmpState1._id)
            {
                tmpState1.subTasks = [action.payload, tmpState1.subTasks]
                return {...tmpState1}
            }

            return {...tmpState1}
        
        case "DELETE_SUB_DETAIL":

            var _tmpState1 = {...state}
            var task_deleted = {}
            if(action.payload.taskId === _tmpState1._id)
            {
                task_deleted = (_tmpState1.subTasks).filter(del =>!(action.payload._id).includes(action.payload._id) );
                _tmpState1.subTasks = task_deleted
                return {..._tmpState1}
            }

            return {..._tmpState1}
            
        default:
            break;
    }

    return state
}

export default taskDetailReducer