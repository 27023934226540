import { Box, Stack, TextField, Button } from '@mui/material';
import React, { useState, useEffect} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TimelineCus from './TimelineCus';
import '../style.css'
import { connect, useSelector } from 'react-redux';
import { createChanceNote } from '../../../../actions/chanceNote';
import { getChanceNoteByChance } from '../../../../actions/chance';

import { find, isEmpty } from 'lodash';


const dataType = [
    {
        code:'phone',
        name: 'Điện thoại'
    },
    {
        code:'message',
        name:'Nhắn tin'
    },
    {
        code:'meeting',
        name:'Cuộc hẹn'
    },
    {
        code:'other',
        name:'Khác'
    }
]

const ChanceNote = (props) => {

    const [type, setType] = useState('phone')

    const [content, setContent] = useState('content')
    const chance = useSelector(state => state.edit)
   
    useEffect(() => {


        const init = async () =>{
            let condition = {
                "stage": {
                    "equal": props?.stage
                },
                limit: 100
            }
            await props?.getChanceNoteByChance(chance?._id, {condition})
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const handleSubmit = () =>{
        if(!isEmpty(chance?._id))
        {
            let data ={
                "chanceId": chance?._id,
                "stage": props?.stage,
                "title": find(dataType,{code: type})?.name ,
                "content": content,
                "isActive": true
            }
            props?.createChanceNote(data)
        }
        else
        {
            alert('Không tìm thấy cơ hội này')
        }
    }
    

    return (
        <Stack direction={'row'} spacing={2} sx={{width:'100%'}} mt={3}>
            <Box sx={{minWidth:300}}>

            <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Tương tác</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                valua={type}
                onChange={(e)=>setType(e.target.value)}
                name="row-radio-buttons-group"
            >
                <FormControlLabel value="phone" control={<Radio />} label="Điện thoại" />
                <FormControlLabel value="message" control={<Radio />} label="Nhắn tin" />
                <FormControlLabel value="meeting" control={<Radio />} label="Cuộc hẹn" />
                <FormControlLabel value="other" control={<Radio />} label="Khác" />
            </RadioGroup>
            </FormControl>

                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { mt: 2, width: '100%', mb:2 },
                    }}
                    noValidate
                    autoComplete="off">
                    <div>
                        <TextField
                            label="Nội dung"
                            id="outlined-size-name"
                            defaultValue=""
                            onChange={(e)=>setContent(e.target.value)}
                            // placeholder='Chi tiết tương tác'
                            multiline
                            minRows={2}
                            size="small"
                        />
                    </div>
                </Box>
                <Button variant='contained' 
                disableElevation
                onClick={()=>handleSubmit()}>Ghi chú</Button>
            </Box>
            <Box>
                <FormLabel id="demo-row-radio-buttons-group"
                sx={{ml:2}}
                >Lịch sử ({props?.chanceNote.length}) </FormLabel>
                <TimelineCus/>
            </Box>
        </Stack>
    );
};

const mapDispatchToProps = {
    createChanceNote, getChanceNoteByChance
  }

  const mapStateToProps = (state) => {
    return {
        chanceNote: state.chanceNote
    }
  }
export default connect(mapStateToProps ,mapDispatchToProps )(ChanceNote);