import { Chip, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ccyFormat, dollarFormat } from '../../../untils/number';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { getAllDataNoneRedux as getLog } from '../../../actions/log';

const DetailForm = props => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation()
    const [dataParent, setDataParent] = React.useState({});
    const [listBottomSheet, setListBottomSheet] = React.useState([]);
    const [log, setLog] = React.useState([]);
    const [isMore, setMore] = React.useState(false);



    React.useEffect(() => {
        handleOpen()
    }, [props?.isOpen])

    React.useEffect(() => {
        setDataParent(props?.dataParent)
        if (props?.dataParent?.code) {

            props?.getLog({
                condition: {
                    documentCode: { equal: props?.dataParent?.code }
                }
            })
                .then(res => {
                    setLog(res?.data)
                })
        }
        // eslint-disable-next-line
    }, [props?.dataParent])

    React.useEffect(() => {
        setListBottomSheet(props?.listBottomSheet)
    }, [props?.listBottomSheet])

    React.useEffect(() => {
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        setOpen(false);
        props?.handleClose(false)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const renderBottomSheet = () => {
        return listBottomSheet.map((row, index) => (
            <TableRow key={row._id}>
                <TableCell className="firstCell" align="left" sx={{ paddingLeft: '0px!important' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {index + 1}.
                    </div>
                </TableCell>
                <TableCell align="right">{moment(row?.date).format('DD/MM/YYYY')}
                    <Stack direction="row" spacing={1}>
                        {row?.code}
                    </Stack>
                </TableCell>
                <TableCell align="left">{row.accountNumberCredit}</TableCell>
                <TableCell align="left">{row.accountNumberDebit}</TableCell>
                <TableCell align="right">{ccyFormat(row.amount)}</TableCell>
                <TableCell align="right">
                    {row?.vat?.name}
                </TableCell>
                <TableCell align="right">{ccyFormat(row.total)}</TableCell>
                <TableCell component="th" scope="row" sx={{
                    borderWidth: 0.5
                }}>
                    {row?.customer?.firstName} {row?.customer?.lastName}
                </TableCell>
                <TableCell align="left">
                    {row?.expense?.name ? <><Chip sx={{ height: 20, mr: 1 }} label={row?.department?.name} color="primary" size="small" />
                        <Typography variant='body' sx={{ lineHeight: 1.2 }}>
                            {row?.expense?.name}
                        </Typography></> : ''}
                </TableCell>
            </TableRow>
        ))
    }



    return (
        <Dialog
            open={open}
            maxWidth='llg'
            fullWidth={true}
            scroll='paper'
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >

            <DialogTitle id="alert-dialog-title"
                sx={{
                    background: '#2196f3'
                }}>
                <Box display='flex' justifyContent={"space-between"} alignItems="center" sx={{ color: '#fff' }}>
                    {`${props?.dataParent?.code || props?.title}`}
                </Box>

            </DialogTitle>
            <DialogContent style={{ minHeight: '400px', backgroundColor: '#F7FAFC', paddingTop: 24 }}>
                {props?.dataParent ? <Paper sx={{ padding: 2, border: "0.5px solid #eaeaea", borderTop: 'none', mb: 2 }} elevation={0} >
                    <Grid container>
                        <Grid item xs={3}>
                            <Stack direction={'column'}>
                                <Typography variant='subtitle2'>{t('frmBillDate')}</Typography>
                                <Typography variant='body2' fontWeight={600}>{moment(dataParent?.date).format('DD/MM/YYYY')}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            <Stack direction={'column'}>
                                <Typography variant='subtitle2'>{t('frmBillNumber')}</Typography>
                                <Typography variant='body2' fontWeight={600}>{dataParent?.code}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            <Stack direction={'column'}>
                                <Typography variant='subtitle2'>{t('frmContact')}</Typography>
                                <Typography variant='body2' fontWeight={600}>{dataParent?.name}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            <Stack direction={'column'}>
                                <Typography variant='subtitle2'>{t('frmAddress')}</Typography>
                                <Typography variant='body2' fontWeight={600}>{dataParent?.address}</Typography>
                            </Stack>
                        </Grid>

                    </Grid>

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={3}>
                            <Stack direction={'column'}>
                                <Typography variant='subtitle2'>{t('frmCurrency')}</Typography>
                                <Typography variant='body2' fontWeight={600}>{dataParent?.currencyMain?.name} ({dataParent?.currencyMain?.symbol}) - {dataParent?.exchangeRate}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            <Stack direction={'column'}>
                                <Typography variant='subtitle2'>{t('frmTotal')}</Typography>
                                <Typography variant='body2' fontWeight={400}>{dollarFormat(dataParent?.total || 0)}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            <Stack direction={'column'}>
                                <Typography variant='subtitle2'>{t('frmDescription')}</Typography>
                                <Typography variant='body2' fontWeight={600}>{dataParent?.description}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                </Paper> : <></>}
                <Paper sx={{ padding: 2, border: "0.5px solid #eaeaea", borderTop: 'none' }} elevation={0} >
                    <div className='headBottomSheet'>

                    </div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table sx={{ minWidth: 500 }} aria-label="sticky table">
                            <TableHead>
                                <TableRow sx={{
                                    fontWeight: 'bold'
                                }}>
                                    <TableCell sx={{ width: 60 }}>{t('lblColNo')}</TableCell>
                                    <TableCell sx={{ width: 60 }}>{t('lblColDate')}</TableCell>
                                    <TableCell sx={{ width: 120 }}>{t('lblColCredit')}</TableCell>
                                    <TableCell sx={{ width: 120 }}>{t('lblColDebit')}</TableCell>
                                    <TableCell sx={{ width: 140 }}>{t('lblColOriginal')}</TableCell>
                                    <TableCell align="right">VAT</TableCell>
                                    <TableCell align="right" sx={{ width: 120 }}>{t('lblColAmount')}</TableCell>
                                    <TableCell >{t('lblColPartner')}</TableCell>
                                    <TableCell >{t('lblColRefer')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='customTable'>
                                {renderBottomSheet()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{
                        borderTop: '0.5px solid #eaeaea',
                        alignItems: 'center',
                        backgroundColor: 'white', paddingTop: 1.5, marginTop: 2, display: 'flex', justifyContent: 'space-between'
                    }}>
                        <div>
                            <Typography variant='caption' color={'#848484'}>
                                {t('txtCreated')} {dataParent?.creater?.firstName}  {dataParent?.creater?.lastName} at {moment(dataParent?.created).format('DD/MM/YYYY HH:mm:ss')}
                            </Typography> &nbsp;
                            <Typography variant='caption' color={'#848484'}>
                                {t('txtUpdated')} {dataParent?.updater?.firstName}  {dataParent?.updater?.lastName} at {moment(dataParent?.updated).format('DD/MM/YYYY HH:mm:ss')}
                            </Typography>

                            <Typography variant='caption'
                                onClick={() => setMore(!isMore)}
                                color={'#0c445f'} sx={{
                                    marginLeft: '16px',
                                    cursor: 'pointer',
                                    transition: '0.3s all ease-in-out',
                                    '&:hover': {
                                        fontWeight: 600
                                    }
                                }}>View more ...</Typography>
                        </div>
                        {
                            (props?.handleActionMenu)
                                ?
                                <Button variant='text'
                                    disableElevation onClick={() => props?.handleActionMenu(dataParent, 'edit')} >
                                    <ModeEditOutlineOutlinedIcon />
                                </Button>
                                : <></>
                        }

                    </Box>
                    {isMore ? <Box>
                        {log?.map((res, id) => {
                            return <div style={{
                                padding: '6px 0px',
                                borderBottom: '0.4px dashed #ccc',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    fontSize: '13px',
                                    color: '#6c797f'
                                }}>
                                    <div style={{ width: 7, height: 7, borderRadius: 7, background: "#6c797f", marginRight: 7 }}></div>
                                    {res?.action === 'create' ? "Created " :
                                        res?.action === 'active' ? "Actived " :
                                            res?.action === 'update' ? "Updated " :
                                                ""
                                    }
                                    by &nbsp;
                                    <Typography variant='subtitle2'>@{res?.creater?.userName.toLowerCase()} - {res?.creater?.firstName + ' ' + res?.creater?.lastName}</Typography>
                                    &nbsp; at &nbsp;
                                    <Typography variant='subtitle2'>{moment(res?.created).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                                </div>
                            </div>
                        })}
                    </Box> : <></>}
                </Paper>







            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog >
    );
};

const mapDispatchToProps = {
    getLog
}

const mapStateToProps = (state) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DetailForm);