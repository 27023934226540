import { findIndex } from "lodash"

const initialState = []

const minimizeReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_MINI_CHAT_CODE":
            return action.payload
            
        case "CREATE_MINI_CHAT_CODE":
            return [...state, action.payload]

        case "UPDATE_MINI_CHAT_CODE":
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            return [...state]

        case "UPDATE_MINI_CHAT_CODE_ACTIVE":
            const rule_ = [...state] 
            if(action.payload.length > 0)
            {
                (action.payload).forEach((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    rule_[index] = item;
                })
            }
            return rule_
 
        case "DELETE_MINI_CHAT_CODE":
            const rule_old = [...state] 
            let indexs = findIndex(rule_old, {code: action.payload})
            rule_old.splice(indexs, 1)
            return [...rule_old]
        

        default:
            break;
    }

    return state
}

export default minimizeReducer