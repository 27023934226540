import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { randomStringX } from '../../untils/functions';
import { Grid, TextField } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { uploadFile } from '../../actions/file';
import { createFeedBack } from '../../actions/feedBack';

import { connect } from 'react-redux';
import { _showAlertSuccess } from '../../untils/error';
import { GET_SUCCESS } from '../../enums/vi';
import { useTranslation } from 'react-i18next';

const MAX_COUNT = 5;

const FeedBack = (props) =>{

    const {t} = useTranslation()
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [imageUploaded, setImageUploaded] = useState([])
    const [fileLimit, setFileLimit] = useState(false);
    const [description, setDescription] = useState('')
    const handleClose = () => {
        props?.handleClose()
    };

    document.addEventListener('paste', function (evt) {
        // Get the data of clipboard
        const uploaded = [...uploadedFiles];
        const imageUploadedTmp = [...imageUploaded];
        const clipboardItems = evt.clipboardData.items;
        const items = [].slice.call(clipboardItems).filter(function (item) {
            // Filter the image items only
            return item.type.indexOf('image') !== -1;
        });
        if (items.length === 0) {
            return;
        }
        const item = items[0];
        // Get the blob of image
        const blob = item.getAsFile();

        // const imageEle = document.getElementById('preview');
        // imageEle.src = URL.createObjectURL(blob);
        let file = new File([blob], blob.name || randomStringX(9), { type: "image/jpeg", lastModified: new Date().getTime() }, 'utf-8');
        let container = new DataTransfer();
        container.items.add(file);
        document.querySelector('#fileUpload').files = container.files;
        uploaded.push(file);
        imageUploadedTmp.push({ name: file?.name, path: URL.createObjectURL(file) })

        setUploadedFiles(uploaded)
        setImageUploaded(imageUploadedTmp)
    });

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        const imageUploadedTmp = [...imageUploaded];

        let limitExceeded = false;
        // eslint-disable-next-line 
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                imageUploadedTmp.push({ name: file?.name, path: URL.createObjectURL(file) })
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) {
            setUploadedFiles(uploaded)
            setImageUploaded(imageUploadedTmp)
        }

    }

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }

    const postFile = async () => {
        var files = [...uploadedFiles]
        var formData = new FormData();
        let data = {
            description
        }
        formData.append('name', 'File Upload');
        [...files].forEach(fl => {
            formData.append("files", fl);
        });

        if (files.length > 0) {
            await props?.uploadFile(formData)
                .then(res => {
                    var imgId = []
                    var index = 0
                    for (let dt of res.success) {
                        imgId.push(dt._id)
                        if (index === res.success.length - 1) {
                            data.imageIdArr = imgId
                            props?.createFeedBack(data)
                                .then(res => {
                                    _showAlertSuccess(res?.data?.message || GET_SUCCESS)
                                    handleClose()
                                })
                        }
                        index++
                    }
                })
                .catch(err => {
                    console.log('uploadFile', err)
                })
        }
    }

    const delImg = (index) =>{
        const uploaded = [...uploadedFiles];
        const imageUploadedTmp = [...imageUploaded];
        uploaded.splice(index, 1)
        imageUploadedTmp.splice(index, 1)

        setUploadedFiles(uploaded)
        setImageUploaded(imageUploadedTmp)
    }

    return (

        <Dialog
            open={props?.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <DialogTitle id="alert-dialog-title">
                {t('titFeedBack')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div><kbd class="key">Ctrl</kbd> + <kbd class="key">V</kbd> in this window</div>

                        <input id='fileUpload' type='file' multiple
                            accept='application/pdf, image/png'
                            onChange={handleFileEvent}
                            disabled={fileLimit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            multiline
                            minRows={5}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <div className="uploaded-files-list" >
                            {imageUploaded.map((file, index) => (
                                <div className='itemTemp' style={{position:'relative'}} key={index}>
                                    <img src={`${file?.path}`} alt={file.name} />
                                    <span>{file.name}</span>
                                    <span onClick={()=>delImg(index)} style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        margin: '0px',
                                        color: '#ff5722',
                                        cursor: 'pointer'
                                    }}>
                                        <CancelOutlinedIcon />
                                    </span>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={postFile} autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}


const mapDispatchToProps = {
   uploadFile, createFeedBack
}

export default connect(null, mapDispatchToProps, null)(FeedBack);