import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';

import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
const ITEM_HEIGHT = 48;

export default function RowMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (type) => {
    props?.actionEdit(props?.data, type)
    handleClose()
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {props?.chance === true ?
          <MenuItem selected={false} onClick={() => handleChange('chance')}>
            <ListItemIcon>
              <AddOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Tạo cơ hội</Typography>
          </MenuItem>
          : <div></div>}
        <MenuItem selected={true} onClick={() => handleChange('edit')}>
          <ListItemIcon>
            <FormatColorTextOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>
        <MenuItem selected={false} onClick={() => handleChange('active')}>
          <ListItemIcon>
            <LockResetOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Lock</Typography>
        </MenuItem>
        <MenuItem selected={false} onClick={() => handleChange('delete')}>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
