const province = [
    {
        "name": "Thành phố Hà Nội",
        "code": "thanh_pho_ha_noi",
        "division_type": "thành phố trung ương",
        "phone_code": 24,
        "districts": 30
    },
    {
        "name": "Hà Giang",
        "code": "tinh_ha_giang",
        "division_type": "tỉnh",
        "phone_code": 219,
        "districts": 11
    },
    {
        "name": "Cao Bằng",
        "code": "tinh_cao_bang",
        "division_type": "tỉnh",
        "phone_code": 206,
        "districts": 10
    },
    {
        "name": "Bắc Kạn",
        "code": "tinh_bac_kan",
        "division_type": "tỉnh",
        "phone_code": 209,
        "districts": 8
    },
    {
        "name": "Tuyên Quang",
        "code": "tinh_tuyen_quang",
        "division_type": "tỉnh",
        "phone_code": 207,
        "districts": 7
    },
    {
        "name": "Lào Cai",
        "code": "tinh_lao_cai",
        "division_type": "tỉnh",
        "phone_code": 214,
        "districts": 9
    },
    {
        "name": "Điện Biên",
        "code": "tinh_dien_bien",
        "division_type": "tỉnh",
        "phone_code": 215,
        "districts": 10
    },
    {
        "name": "Lai Châu",
        "code": "tinh_lai_chau",
        "division_type": "tỉnh",
        "phone_code": 213,
        "districts": 8
    },
    {
        "name": "Sơn La",
        "code": "tinh_son_la",
        "division_type": "tỉnh",
        "phone_code": 212,
        "districts": 12
    },
    {
        "name": "Yên Bái",
        "code": "tinh_yen_bai",
        "division_type": "tỉnh",
        "phone_code": 216,
        "districts": 9
    },
    {
        "name": "Hoà Bình",
        "code": "tinh_hoa_binh",
        "division_type": "tỉnh",
        "phone_code": 218,
        "districts": 10
    },
    {
        "name": "Thái Nguyên",
        "code": "tinh_thai_nguyen",
        "division_type": "tỉnh",
        "phone_code": 208,
        "districts": 9
    },
    {
        "name": "Lạng Sơn",
        "code": "tinh_lang_son",
        "division_type": "tỉnh",
        "phone_code": 205,
        "districts": 11
    },
    {
        "name": "Quảng Ninh",
        "code": "tinh_quang_ninh",
        "division_type": "tỉnh",
        "phone_code": 203,
        "districts": 13
    },
    {
        "name": "Bắc Giang",
        "code": "tinh_bac_giang",
        "division_type": "tỉnh",
        "phone_code": 204,
        "districts": 10
    },
    {
        "name": "Phú Thọ",
        "code": "tinh_phu_tho",
        "division_type": "tỉnh",
        "phone_code": 210,
        "districts": 13
    },
    {
        "name": "Vĩnh Phúc",
        "code": "tinh_vinh_phuc",
        "division_type": "tỉnh",
        "phone_code": 211,
        "districts": 9
    },
    {
        "name": "Bắc Ninh",
        "code": "tinh_bac_ninh",
        "division_type": "tỉnh",
        "phone_code": 222,
        "districts": 8
    },
    {
        "name": "Hải Dương",
        "code": "tinh_hai_duong",
        "division_type": "tỉnh",
        "phone_code": 220,
        "districts": 12
    },
    {
        "name": "Thành phố Hải Phòng",
        "code": "thanh_pho_hai_phong",
        "division_type": "thành phố trung ương",
        "phone_code": 225,
        "districts": 15
    },
    {
        "name": "Hưng Yên",
        "code": "tinh_hung_yen",
        "division_type": "tỉnh",
        "phone_code": 221,
        "districts": 10
    },
    {
        "name": "Thái Bình",
        "code": "tinh_thai_binh",
        "division_type": "tỉnh",
        "phone_code": 227,
        "districts": 8
    },
    {
        "name": "Hà Nam",
        "code": "tinh_ha_nam",
        "division_type": "tỉnh",
        "phone_code": 226,
        "districts": 6
    },
    {
        "name": "Nam Định",
        "code": "tinh_nam_dinh",
        "division_type": "tỉnh",
        "phone_code": 228,
        "districts": 10
    },
    {
        "name": "Ninh Bình",
        "code": "tinh_ninh_binh",
        "division_type": "tỉnh",
        "phone_code": 229,
        "districts": 8
    },
    {
        "name": "Thanh Hóa",
        "code": "tinh_thanh_hoa",
        "division_type": "tỉnh",
        "phone_code": 237,
        "districts": 27
    },
    {
        "name": "Nghệ An",
        "code": "tinh_nghe_an",
        "division_type": "tỉnh",
        "phone_code": 238,
        "districts": 21
    },
    {
        "name": "Hà Tĩnh",
        "code": "tinh_ha_tinh",
        "division_type": "tỉnh",
        "phone_code": 239,
        "districts": 13
    },
    {
        "name": "Quảng Bình",
        "code": "tinh_quang_binh",
        "division_type": "tỉnh",
        "phone_code": 232,
        "districts": 8
    },
    {
        "name": "Quảng Trị",
        "code": "tinh_quang_tri",
        "division_type": "tỉnh",
        "phone_code": 233,
        "districts": 10
    },
    {
        "name": "Thừa Thiên Huế",
        "code": "tinh_thua_thien_hue",
        "division_type": "tỉnh",
        "phone_code": 234,
        "districts": 9
    },
    {
        "name": "Thành phố Đà Nẵng",
        "code": "thanh_pho_da_nang",
        "division_type": "thành phố trung ương",
        "phone_code": 236,
        "districts": 8
    },
    {
        "name": "Quảng Nam",
        "code": "tinh_quang_nam",
        "division_type": "tỉnh",
        "phone_code": 235,
        "districts": 18
    },
    {
        "name": "Quảng Ngãi",
        "code": "tinh_quang_ngai",
        "division_type": "tỉnh",
        "phone_code": 255,
        "districts": 13
    },
    {
        "name": "Bình Định",
        "code": "tinh_binh_dinh",
        "division_type": "tỉnh",
        "phone_code": 256,
        "districts": 11
    },
    {
        "name": "Phú Yên",
        "code": "tinh_phu_yen",
        "division_type": "tỉnh",
        "phone_code": 257,
        "districts": 9
    },
    {
        "name": "Khánh Hòa",
        "code": "tinh_khanh_hoa",
        "division_type": "tỉnh",
        "phone_code": 258,
        "districts": 9
    },
    {
        "name": "Ninh Thuận",
        "code": "tinh_ninh_thuan",
        "division_type": "tỉnh",
        "phone_code": 259,
        "districts": 7
    },
    {
        "name": "Bình Thuận",
        "code": "tinh_binh_thuan",
        "division_type": "tỉnh",
        "phone_code": 252,
        "districts": 10
    },
    {
        "name": "Kon Tum",
        "code": "tinh_kon_tum",
        "division_type": "tỉnh",
        "phone_code": 260,
        "districts": 10
    },
    {
        "name": "Gia Lai",
        "code": "tinh_gia_lai",
        "division_type": "tỉnh",
        "phone_code": 269,
        "districts": 17
    },
    {
        "name": "Đắk Lắk",
        "code": "tinh_dak_lak",
        "division_type": "tỉnh",
        "phone_code": 262,
        "districts": 15
    },
    {
        "name": "Đắk Nông",
        "code": "tinh_dak_nong",
        "division_type": "tỉnh",
        "phone_code": 261,
        "districts": 8
    },
    {
        "name": "Lâm Đồng",
        "code": "tinh_lam_dong",
        "division_type": "tỉnh",
        "phone_code": 263,
        "districts": 12
    },
    {
        "name": "Bình Phước",
        "code": "tinh_binh_phuoc",
        "division_type": "tỉnh",
        "phone_code": 271,
        "districts": 11
    },
    {
        "name": "Tây Ninh",
        "code": "tinh_tay_ninh",
        "division_type": "tỉnh",
        "phone_code": 276,
        "districts": 9
    },
    {
        "name": "Bình Dương",
        "code": "tinh_binh_duong",
        "division_type": "tỉnh",
        "phone_code": 274,
        "districts": 9
    },
    {
        "name": "Đồng Nai",
        "code": "tinh_dong_nai",
        "division_type": "tỉnh",
        "phone_code": 251,
        "districts": 11
    },
    {
        "name": "Bà Rịa - Vũng Tàu",
        "code": "tinh_ba_ria_vung_tau",
        "division_type": "tỉnh",
        "phone_code": 254,
        "districts": 8
    },
    {
        "name": "Thành phố Hồ Chí Minh",
        "code": "thanh_pho_ho_chi_minh",
        "division_type": "thành phố trung ương",
        "phone_code": 28,
        "districts": 22
    },
    {
        "name": "Long An",
        "code": "tinh_long_an",
        "division_type": "tỉnh",
        "phone_code": 272,
        "districts": 15
    },
    {
        "name": "Tiền Giang",
        "code": "tinh_tien_giang",
        "division_type": "tỉnh",
        "phone_code": 273,
        "districts": 11
    },
    {
        "name": "Bến Tre",
        "code": "tinh_ben_tre",
        "division_type": "tỉnh",
        "phone_code": 275,
        "districts": 9
    },
    {
        "name": "Trà Vinh",
        "code": "tinh_tra_vinh",
        "division_type": "tỉnh",
        "phone_code": 294,
        "districts": 9
    },
    {
        "name": "Vĩnh Long",
        "code": "tinh_vinh_long",
        "division_type": "tỉnh",
        "phone_code": 270,
        "districts": 8
    },
    {
        "name": "Đồng Tháp",
        "code": "tinh_dong_thap",
        "division_type": "tỉnh",
        "phone_code": 277,
        "districts": 12
    },
    {
        "name": "An Giang",
        "code": "tinh_an_giang",
        "division_type": "tỉnh",
        "phone_code": 296,
        "districts": 11
    },
    {
        "name": "Kiên Giang",
        "code": "tinh_kien_giang",
        "division_type": "tỉnh",
        "phone_code": 297,
        "districts": 15
    },
    {
        "name": "Thành phố Cần Thơ",
        "code": "thanh_pho_can_tho",
        "division_type": "thành phố trung ương",
        "phone_code": 292,
        "districts": 9
    },
    {
        "name": "Hậu Giang",
        "code": "tinh_hau_giang",
        "division_type": "tỉnh",
        "phone_code": 293,
        "districts": 8
    },
    {
        "name": "Sóc Trăng",
        "code": "tinh_soc_trang",
        "division_type": "tỉnh",
        "phone_code": 299,
        "districts": 11
    },
    {
        "name": "Bạc Liêu",
        "code": "tinh_bac_lieu",
        "division_type": "tỉnh",
        "phone_code": 291,
        "districts": 7
    },
    {
        "name": "Cà Mau",
        "code": "tinh_ca_mau",
        "division_type": "tỉnh",
        "phone_code": 290,
        "districts": 9
    }
]

export {province}