const initialState = []

const expenseReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_EXPENSE":
            return action.payload
            
        case "CREATE_EXPENSE":
            return [...state, action.payload]

        case "UPDATE_EXPENSE":
            
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            
            return [...state]

        case "CHANGE_PASSWORD":
            
                const password = state.findIndex(per => per._id === action.payload._id)
                state[password] = action.payload;
                
                return [...state]

        case "UPDATE_EXPENSE_ACTIVE":
            const expense_ = [...state] 
            if(action.payload.length > 0)
            {
                (action.payload).forEach((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    expense_[index] = item;
                })
            }
            return expense_

        case "SET_CURRENT_EXPENSE_IMG":
                return [...state]

        case "DELETE_EXPENSE":
            const expense_old = [...state] 
            var expense_deleted = []
           
            if(action.payload.length > 0)
            {
                expense_deleted = expense_old.filter(del =>!(action.payload).includes(del._id) );
            }
            return expense_deleted
        

        default:
            break;
    }

    return state
}

export default expenseReducer