let dataModule = [
    {
        code: 'IC',
        name: 'Cash Received'
    },
    {
        code: 'OC',
        name: 'Cash Payment'
    },
    {
        code: 'CRB',
        name: 'Credit Note'
    },
    {
        code: 'DRB',
        name: 'Debit Note'
    },
    {
        code: 'JE',
        name: 'Journal Entrie'
    }
]


export {dataModule}