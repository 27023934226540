import axios from 'axios';
import config from '../config/index'
import { store } from '../store';

let api = axios.create({
    baseURL: `${config.baseURL}api`,
    headers: {
        'token': store.getState().auth.token || localStorage.getItem('token'),
        dataCode: JSON.parse(localStorage.getItem('dataCurrent'))?.code || store.getState()?.enumRedux?.dataCurrent
    }
})

export const setTokenAxios = () => {
    api = axios.create({
        baseURL: `${config.baseURL}api`,
        headers: {
            'token': store.getState().auth.token || localStorage.getItem('token'),
            dataCode: JSON.parse(localStorage.getItem('dataCurrent'))?.code || store.getState()?.enumRedux?.dataCurrent
        }
    })
    return api
}

export const delTokenAxios = () => {
    api = axios.create({
        baseURL: `${config.baseURL}api`,
        headers: { 'token': '' }
    })
    return api
}

export default api