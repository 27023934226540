import * as React from 'react';
import { useState } from 'react';

import { FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import { createBankAccount } from '../../../actions/bankAccount';
import { getAllData as getDataPrimary } from '../../../actions/data';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import { MenuProps } from '../../../untils/variable';


const initialState = {
    name: "",
    isActive: true,
    code: '',
    description: '',
    dataCode: []
};


const AddForm = React.forwardRef((props, ref) => {


    const [
        { name, isActive, code, description, bankNumber, branch, dataCode },
        setState
    ] = useState(initialState);


    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };


    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = () => {
        let data = {
            name, isActive, code, description, bankNumber, branch, dataCode
        }
        props?.createBankAccount(data)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let arr = typeof value === 'string' ? value.split(',') : value
        setState(state => ({ ...state, dataCode: arr }))
    };

    React.useImperativeHandle(ref, () => ({ handleSubmit }));

    return (
        <Grid container spacing={2} sx={{background:'#fff'}}>
            <Grid item xs={12} md={6} >
                <FormGroup >
                    <FormControlLabel control={<Checkbox />}
                        checked={isActive}
                        name="isActive"
                        onChange={(e) => customeOnChange('isActive', !isActive)}
                        label="Active" />
                </FormGroup>
            </Grid>

            <Grid item md={6} xs={12} >
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width:'100%' },
                    }}
                    noValidate
                    autoComplete="off" >
                    <TextField
                        label="Code "
                        id="code"
                        value={code}
                        name="code"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item md={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Name"
                        id="last-name"
                        value={name}
                        name="name"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item md={6} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Branch"
                        id="last-branch"
                        value={branch}
                        name="branch"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item md={6} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Bank Number"
                        id="last-bankNumber"
                        value={bankNumber}
                        name="bankNumber"
                        onChange={onChange}
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item xs={12} style={{ paddingLeft: 16 }}>
                <Typography variant='body2' >Apply for data:</Typography>
                <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        size='small'
                        value={dataCode}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}>
                        {props?.data?.map((item) => (
                            <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                <Checkbox checked={dataCode?.indexOf(item.code) > -1 ? 'checked' : ''} />
                                <ListItemText primary={`${item.code} - ${item.name}`} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item lg={12} xs={12}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { display: 'flex' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Note"
                        id="last-description"
                        value={description}
                        multiline={true}
                        minRows={4}
                        name="description"
                        onChange={onChange}
                    />
                </Box>
            </Grid>

        </Grid>
    );
})


const mapDispatchToProps = {
    createBankAccount, getDataPrimary
}

const mapStateToProps = (state) => {
    return {
        data: state.data
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddForm);