import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, useTheme } from '@mui/material';
import { blueGrey, grey, orange, teal } from '@mui/material/colors';
import { find, isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { updateBookingStage } from '../../../../actions/chance';
import { getAllData as getDemand } from '../../../../actions/demand';
import { getAllData as getProject } from '../../../../actions/project';
import { _showAlertErrorMessage } from '../../../../untils/error';
import { ccyFormat } from '../../../../untils/number';

import ChanceNote from './ChanceNote';


const initData = {
    "bookings": [],
    "additionalAttributes": {
        "customerName": "customerName",
        "customerAge": 5
    }

}


const Step3 = React.forwardRef((props, ref) => {

    const theme = useTheme()
    const editObject = useSelector(state => state.edit)
    const [demandCode, setDemandCode] = useState("")
    const [indexEdit, setIndexEdit] = useState(-1)
    const [projectCode, setProjectCode] = useState("")

    const [
        { bookings, amount, projectAmount },
        setState
    ] = useState({ ...initData });

    useEffect(() => {

        const init = async () => {
            await props?.getDemand()
            await props?.getProject()
        }
        const stateChange = () => {
            let {bookings} = {...editObject?.bookingStage}
            setState(state=>({...state, bookings}))
        }
        init()
        stateChange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };



    const handleSubmit = () => {
        let data = {
            bookingStage: {},
            // stage:'communication'
        }
        data.bookingStage.bookings = bookings
        // console.log({data})
        if (data?.bookingStage) {
            props?.updateBookingStage(editObject?._id, data, { ...editObject })
        }

    }


    const addBooking = () => {
        let booking = {
            "demand": {
                "code": "code",
                "name": "name"
            },
            "project": {
                "code": "code",
                "name": "name"
            },
            "projectAmount": 0,
            "amount": 0,
            // "fileIdArr": ["62dbaf3b174d0564ec3e2722"]
        }

        if (isEmpty(amount) || isEmpty(projectAmount)) {
            _showAlertErrorMessage('Chưa nhập tiền booking')
        }
        booking.amount = amount
        booking.projectAmount = projectAmount
        booking.demand = {
            code: demandCode,
            name: find(props?.demand, { code: demandCode })?.name
        }
        booking.project = {
            code: projectCode,
            name: find(props?.project, { code: projectCode })?.name
        }

        let tmpArr = [...bookings]

        if(indexEdit>-1)
        {
            tmpArr[indexEdit] = booking
        }
        else
        {
            tmpArr.push(booking)
        }
        setIndexEdit(-1)
        setState(state => ({ ...state, bookings: tmpArr}))
        

    }

    const editBooking = (data, index) =>{
        setIndexEdit(index)
        setDemandCode(data.demand.code)
        setProjectCode(data.project.code)
        setState(state=>({...state, projectAmount: data?.projectAmount || 0, amount : data?.amount || 0
        }))
    }

    const deleteBooking = (index) =>{
        let tmpArr = [...bookings]
        if(index>-1)
        {
            tmpArr.splice(index,1)
        }
        setState(state=>({...state, bookings: tmpArr}))
    }

    return (
        <Box component={"div"} sx={{ minHeight: 450, padding: 2 }}>
            <Grid container spacing={2}>


                <Grid container sx={{ backgroundColor: grey[50], padding: 2, mt: 3 }}>
                    <Grid item xs={6} sx={{ padding: 0, mt: 1.5, mb: 1.5 }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name="hasMet"
                                value={true}
                                disabled
                                checked={bookings?.length > 0 ? true : false}
                            />} label="Đã có booking" />


                            {bookings?.length > 0 ?
                                <Box sx={{ display: 'flex', flexDirection:'column', mt: 1.5, mb: 2, pr: 3 }}>
                                    {
                                        bookings?.map((res, index) => {
                                            return <Stack direction="column" key={index} sx={{
                                                width: '100%',
                                                mb:2,
                                                pd:2,
                                                borderBottom: '0.5px dashed #ccc'
                                            }}>
                                                <Stack direction={'row'} justifyContent="space-between" sx={{ width: '100%' }}>

                                                    <Typography variant='body1' sx={{ color: teal[600], fontWeight: 500 }}>
                                                        {index + 1}.   {res?.project?.name}
                                                    </Typography>

                                                    <Chip label={res?.demand?.name} variant="primary" color="primary" />

                                                </Stack>
                                                <Stack direction={'row'} justifyContent="space-between">
                                                    <Typography variant='subtitle2' sx={{
                                                        color: orange[500],
                                                        fontSize: '1.25em',
                                                        fontWeight: 500
                                                    }}>
                                                        {ccyFormat(res?.amount || 0)}
                                                    </Typography>
                                                    <Typography variant='subtitle1' sx={{
                                                        color: blueGrey[500],
                                                        fontSize: '1.25em',
                                                        fontWeight: 500
                                                    }}>
                                                        {ccyFormat(res?.projectAmount || 0)}
                                                    </Typography>
                                                </Stack>

                                                <Stack direction={'row'}>
                                                    <Typography
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={()=> editBooking(res, index)}
                                                        variant="body2" color="primary">Sửa</Typography>

                                                    <Typography
                                                        sx={{ cursor: 'pointer',
                                                        ml:4,
                                                        color: theme.palette.error.main }}
                                                        onClick={()=> deleteBooking(index)}
                                                        variant="body2">Xóa</Typography>
                                                </Stack>

                                            </Stack>
                                        })
                                    }
                                </Box> : <span></span>}



                        </FormGroup>
                        <Button variant="contained" disableElevation
                            sx={{ mt: 3 }} onClick={() => handleSubmit()}
                        >Cập nhật</Button>
                    </Grid>
                    {/* {needCareMore ? */}
                    <Grid item xs={6} sx={{
                        background: '#fff',
                        borderRadius: 1.5,
                        padding: '4px 12px',
                        border:'1px solid',
                        borderColor:indexEdit>-1?orange[500]:'#fff'
                    }}>
                        <FormControl sx={{ minWidth: 360, m: 1, mt: 1 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Thông tin booking</span>
                                {
                                indexEdit>-1&& <Chip label="Đang sửa" size="small" sx={{color: theme.palette.error.main}}/>
                            }
                            </FormLabel>
                           
                        </FormControl>

                        <FormControl sx={{
                            mb: 1.5, mt: 3.5, width: '35ch',
                            '& .MuiInputBase-root': {
                                background: '#fff'
                            }
                        }} size="small">
                            <InputLabel id="demo-select-small">Dự án</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={projectCode}
                                onChange={(e) => setProjectCode(e.target.value)}
                                label="Dự án"
                            >
                                <MenuItem value="" >Chọn dự án quan tâm...</MenuItem>
                                {props?.project?.map((res, ind) => {
                                    return <MenuItem value={res.code} key={ind}>{res.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>


                        <Box
                            component="form"
                            sx={{
                                m: 1, mt: 2, width: '100%'
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Nhu cầu</FormLabel>

                                <FormGroup aria-label="position" row>
                                    {props?.demand?.map((res, ind) => {

                                        return <FormControlLabel value={res.code} key={ind}
                                            checked={demandCode === res.code ? true : false}
                                            control={<Checkbox onChange={(e) => setDemandCode(res.code)} />}
                                            label={`${res?.name}`} />
                                    })}
                                </FormGroup>
                            </FormControl>
                        </Box>

                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label="Giá trị bất động sản"
                                id="last-location-project"
                                value={projectAmount}
                                type="number"
                                name="projectAmount"
                                onChange={onChange}
                                InputLabelProps={{ shrink: projectAmount?true:false }} 

                                size="small"
                            /> <Typography variant='body' sx={{ display: 'flex', mt: 0.5, ml: 1, fontSize: '0.8em', width: '100%' }}>
                                ~{ccyFormat(projectAmount || 0)}
                            </Typography>

                        </Box>



                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, mt: 2, width: '35ch', background: '#fff' },
                            }}
                            noValidate
                            autoComplete="off">

                            <TextField
                                label="Thanh toán "
                                id="last-location-pay"
                                value={amount}
                                name="amount"
                                onChange={onChange}
                                InputLabelProps={{ shrink: amount?true:false }} 
                                size="small"
                            />
                            <Typography variant='body' sx={{ display: 'flex', mt: 0.5, fontSize: '0.8em', width: '100%', ml: 1, }}>
                                ~{ccyFormat(amount || 0)}
                            </Typography>
                        </Box>

                        {indexEdit>-1?<Button variant='contained' sx={{ mt: 2.2, width: '40%' }}
                            onClick={() => addBooking()}
                            color={'primary'} disableElevation>Cập nhật</Button>:
                            <Button variant='contained' sx={{ mt: 2.2, width: '40%' }}
                            onClick={() => addBooking()}
                            color={'primary'} disableElevation>Thêm</Button>}
                        
                    </Grid>
                    {/* :
                        <span></span>
                    } */}

                </Grid>



                <Grid item xs={12}>
                    {props?.activeStep === 3 &&
                        <ChanceNote stage={props?.stage}></ChanceNote>
                    }
                </Grid>
            </Grid>
        </Box>
    );
});

const mapDispatchToProps = {
    updateBookingStage, getDemand, getProject
}


const mapStateToProps = (state) => {
    return {
        demand: state.demand,
        project: state.project
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3);