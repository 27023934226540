import jwtDecode from "jwt-decode";
import {setAuthToken} from "../untils/checkAuthToken";
import  { delTokenAxios, setTokenAxios } from "../api/index"
import {_showAlertError } from "../untils/error";
import {LOGIN_SUCCESS } from "../enums/vi";
import { getProfile } from "./user";

export const login = (credentials) => (dispatch) => {
  return delTokenAxios().post(`user/login`, credentials)
    .then(async res => {
      const { token} = res.data.data
      const decoded = jwtDecode(token)
      await setAuthToken(res.data)
      dispatch(setCurrentUser({...decoded, token}))
     
      //get profile
      let profile = await getProfile(token)
      dispatch(setCurrentProfile(profile?.data || {}))

      await localStorage.setItem("token", res.data.data.token)
      return Promise.resolve({ message: LOGIN_SUCCESS, data: res.data.data})
      
    })
    .catch((err) =>{
      return  Promise.reject({ message: err?.response?.data , error: _showAlertError(err) })
    })
}

export const setCurrentUser = (data) => {
  return {
    type: "SET_CURRENT_PARTNER",
    payload: data
  }
}

export const setCurrentProfile = (data) => {
  return {
    type: "SET_PROFILE",
    payload: data
  }
}

export const setInitApp = (data) => {
  return {
    type: "SET_IS_INIT",
    payload: data
  }
}

export const logOut = (data) => (dispatch) => {
  return setTokenAxios().post(`/user/logout`, data)
      .then(res => {
          delTokenAxios()
           // xoa localStorage
          localStorage.removeItem("token");
          localStorage.removeItem("initApp");
          // set lai reducer
          dispatch(setCurrentUser({}))
          dispatch(setCurrentProfile({}))
          dispatch(setInitApp({isInit: false}))
          return Promise.resolve({ success: res.data })
      })
      .catch((err) => {
          return Promise.reject({ error: err.response.data })
      })
}