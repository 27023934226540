const initialState = []

const logReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_LOG":
            return action.payload

        case "CREATE_LOG":
            return [...state, action.payload]

        case "MORE_LOG":
            return [...state, ...action.payload]

        case "UPDATE_LOG":

            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;

            return [...state]

        case "CHANGE_PASSWORD":

            const password = state.findIndex(per => per._id === action.payload._id)
            state[password] = action.payload;

            return [...state]

        case "UPDATE_LOG_ACTIVE":
            const log_ = [...state]
            if (action.payload.length > 0) {
                (action.payload).forEach((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    log_[index] = item;
                })
            }
            return log_

        case "SET_CURRENT_LOG_IMG":
            return [...state]

        case "DELETE_LOG":
            const log_old = [...state]
            var log_deleted = []

            if (action.payload.length > 0) {
                log_deleted = log_old.filter(del => !(action.payload).includes(del._id));
            }
            return log_deleted


        default:
            break;
    }

    return state
}

export default logReducer