import _ from 'lodash'

export const parseError = (data) =>{
    if(_.has(data, 'errors'))
    {
      var errArr = _.values(_.get(data,'errors',''))
      if(errArr.length>0)
      {
        var validate = _.values(errArr[0])
        if(validate.length>0)
        {
            return validate[0]
        }
        else
        {
            return 'Lỗi hệ thống'
        }
      }
    }
    else
    {
        return _.get(data,'message','') +' - '+ _.get(data,'error.error.message','')
    }
}
