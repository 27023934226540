import * as React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// import Dashboard from "./components/pages/dashboard";
import Accountant from "./components/pages/accountant";
import BankAccount from "./components/pages/bankAccount";
import BeginningBalance from "./components/pages/beginningBalance";
import CreditNote from "./components/pages/creditNote";
import Currency from "./components/pages/currency";
import Data from "./components/pages/data";
import DebitNote from "./components/pages/debitNote";
import Demand from "./components/pages/demand";
import Department from "./components/pages/department";
import Expense from "./components/pages/expense";
import FinacialYear from "./components/pages/financialYear";
import Home from "./components/pages/home";
import Income from "./components/pages/income";
import Language from "./components/pages/language";
import Lead from "./components/pages/lead";
import Login from "./components/pages/login";
import Opportunity from "./components/pages/opportunity";
import Outcome from "./components/pages/outcome";
import Rule from "./components/pages/rule";
import Vat from "./components/pages/vat";
import AdvancePayment from "./components/pages/advancePayment";
import Settlement from "./components/pages/settlement";
import PartnerGroup from "./components/pages/partnerGroup";

import AdditionalAttr from "./components/pages/additionalAttr";
// import AnalysisVisual from "./components/pages/analysisVisual";
// import AnalysisVisualTemp from "./components/pages/analysisVisualTemp";
import Employee from "./components/pages/employee";
import ReportGeneralLedger from "./components/pages/reportGeneralLedger";
import JournalEntrie from "./components/pages/journalEntrie";
import Log from "./components/pages/log";
import UpdateLog from "./components/pages/updateLog";
import Partner from "./components/pages/partner";
import PermissionGroup from "./components/pages/permissionGroup";
import Processing from "./components/pages/processing";
import Product from "./components/pages/product";
import Project from "./components/pages/project";
import Request from "./components/pages/request";
import RequestQueue from "./components/pages/requestQueue";
import RequestTemplate from "./components/pages/requestTemplate";
import Setting from "./components/pages/setting";
import Task from "./components/pages/task";
import Team from "./components/pages/team";
import User from "./components/pages/user";
import ChatInside from "./components/pages/chatInside";
import ExchangeRate from "./components/pages/exchangeRate";


import Analysis from "./components/pages/analysis";
import LngVar from "./components/pages/lngVar";
import Report from "./components/pages/report";
import ReportBank from "./components/pages/reportBank";
import ReadTesting from "./components/pages/readTesting";

import { store } from './store';
import { connectSocketMng } from './actions/socketInside';
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

function App(props) {
  // const [socket, setSocket] = useState(null);

  // let isVaild = checkTokenValid()
  // let navigate = useNavigate()
  // console.log(isVaild)
  // if(!isVaild)
  // {
  //   navigate("/", { replace: true });
  //   return
  // }

  // useEffect(() => {
  // // console.log(socket?.connected || 'init')

  // connectSocketMng()
  //   // setSocket(newSocket);
  //   // return () => newSocket.close();
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [setSocket])
  const { i18n, t } = useTranslation();
  const lang = localStorage.getItem('lng')
 
  const dispatch = useDispatch()
  const config = useSelector(state => state.config)
  let location = useLocation();


  React.useEffect(() => {
    if (location?.pathname === '/task') {

      dispatch({
        type: "SET_ENUM",
        payload: {
          openDrawer: false,
        }
      })
    }
    // eslint-disable-next-line
  }, [location]);

  React.useEffect(() => {
    i18n.changeLanguage(lang);
    let activeModule = localStorage.getItem('activeModule')
    // let activeName = localStorage.getItem('activeName')
    if (isEmpty(activeModule)) {
      activeMenu('Accounting and Financial', t('headMenuAccounting'))
    }
    console.log('isInitApp',  config.isInit)
    if( config.isInit === false) {
      initApp()
    }
    // eslint-disable-next-line
  }, [])

  const initApp = () => {
    connectSocketMng();
    store.dispatch({
      type: 'SET_IS_INIT',
      payload: true
    })
    // localStorage.setItem('initApp', true)
  }


  const activeMenu = (moduleAc, name) => {
    dispatch({
      type: "SET_ENUM",
      payload: {
        activeModule: moduleAc,
        activeName: name
      }
    })
    localStorage.setItem('activeModule', moduleAc)
    localStorage.setItem('activeName', name)
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/task" element={<Task />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/lead" element={<Lead />} />
        <Route path="/opportunity" element={<Opportunity />} />
        <Route path="/user" element={<User />} />
        <Route path="/processing" element={<Processing />} />
        <Route path="/language" element={<Language />} />
        <Route path="/department" element={<Department />} />
        <Route path="/expense" element={<Expense />} />
        <Route path="/currency" element={<Currency />} />
        <Route path="/vat" element={<Vat />} />
        <Route path="/rule" element={<Rule />} />
        <Route path="/cash-received" element={<Income />} />
        <Route path="/financial-settlement" element={<Settlement />} />
        <Route path="/bank-account" element={<BankAccount />} />
        <Route path="/cash-payment" element={<Outcome />} />
        <Route path="/credit-note" element={<CreditNote />} />
        <Route path="/debit-note" element={<DebitNote />} />
        <Route path="/data" element={<Data />} />
        <Route path="/financial-year" element={<FinacialYear />} />
        <Route path="/beginning-balance" element={<BeginningBalance />} />
        <Route path="/cash-analysis" element={<Analysis />} />
        <Route path="/report" element={<Report />} />
        <Route path="/report-bank" element={<ReportBank />} />
        <Route path="/general-ledger" element={<ReportGeneralLedger />} />
        {/* <Route path="/visual" element={<AnalysisVisual />} />
        <Route path="/tmp-visual" element={<AnalysisVisualTemp />} /> */}
        <Route path="/req-queue" element={<RequestQueue />} />
        <Route path="/req-template" element={<RequestTemplate />} />
        <Route path="/request" element={<Request />} />
        <Route path="/employee" element={<Employee />} />
        <Route path="/log" element={<Log />} />
        <Route path="/chat" element={<ChatInside />} />
        <Route path="/read" element={<ReadTesting />} />
        <Route path="/exchange" element={<ExchangeRate />} />
        <Route path="/update-log" element={<UpdateLog />} />
        <Route path="/partner-group" element={<PartnerGroup />} />

        <Route path="/accountant" element={<Accountant />} />
        <Route path="/product" element={<Product />} />
        <Route path="/project" element={<Project />} />
        <Route path="/demand" element={<Demand />} />
        <Route path="/team" element={<Team />} />
        <Route path="/add-attr" element={<AdditionalAttr />} />
        <Route path="/permission-group" element={<PermissionGroup />} />
        <Route path="/langs" element={<LngVar />} />
        <Route path="/journal-entries" element={<JournalEntrie />} />
        <Route path="/advance-payment" element={<AdvancePayment />} />
        
      </Routes>
    </div>
  );
}


export default withTranslation()(App);