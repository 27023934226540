import { findIndex, map } from "lodash"

const initialState = []

const settlementReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_SETTLEMENT":
            return action.payload

        case "SET_MORE_SETTLEMENT":
            return [...state,...action.payload]

        case "CREATE_SETTLEMENT":
            return [action.payload, ...state]

        case "UPDATE_SETTLEMENT":

            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            return [...state]

        case "UPDATE_SETTLEMENT_MULTI":
            let dataNew = [...action.payload]
            let stateM = [...state]
            var newArr = map(stateM, function (v, k) {
                let ind = findIndex(dataNew, { _id: v._id })
                if (ind > -1) {
                    return { ...dataNew[ind] };
                }
                return v;
            });
            return [...newArr]

        case "CHANGE_PASSWORD":
            const password = state.findIndex(per => per._id === action.payload._id)
            state[password] = action.payload;

            return [...state]

        case "UPDATE_SETTLEMENT_ACTIVE":
            const currency_ = [...state]
            if (action.payload.length > 0) {
                (action.payload).forEach((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    currency_[index] = item;
                })
            }
            return currency_

        case "SET_CURRENT_SETTLEMENT_IMG":
            return [...state]

        case "DELETE_SETTLEMENT":
            const currency_old = [...state]
            var currency_deleted = []

            if (action.payload.length > 0) {
                currency_deleted = currency_old.filter(del => !(action.payload).includes(del._id));
            }
            return currency_deleted


        default:
            break;
    }

    return state
}

export default settlementReducer