import { Grid, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { filter, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { updateChance } from '../../../actions/chance';
import { getAllData as getDemand } from '../../../actions/demand';
import { getAllData as getProject } from '../../../actions/project';
import { getAllDataByType as getUser } from '../../../actions/user';

import { district } from '../../../enums/district';
import { province } from '../../../enums/province';
import { ward } from '../../../enums/ward';

const initialState = {
    saleId: {},
    leadId: { _id: '', firstName: 'Choose', lastName: '...' },
    status: '',
    hasInfoZalo: false,
    hasInfoCall: false,
    hasAppointment: false,
    hasMet: false,
    hasOrder: false,
    needCareMore: false,
    projectAmount: '',
    projectAmountDeposit: ''
}


const FilterForm = (props) => {
    const theme = useTheme()
    const [
        { saleId},
        setState
    ] = useState(initialState);
    const [provinceArr] = useState(province)
    const [districtArr, setDistrictArr] = useState([])
    const [wardArr, setWardArr] = useState([])
    const [cityCode, setCityCode] = useState('')
    const [districtCode, setDistrictCode] = useState('')
    const [wardCode, setWardCode] = useState('')
    const [sale, setSale] = useState([])

    useEffect(() => {
        const init = async () => {
            await props?.getUser({
                condition: {
                    // "userType": {
                    //     "equal": "saler"
                    // }
                }, limit: 100
            }).then(res => setSale(res.data))
        }

        init()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.isOpenFilter])


    const chooseProvince = (e) => {
        let districtTmp = filter(district, { parentCode: e.target.value })
        setDistrictArr(districtTmp)
        setCityCode(e.target.value)
    }

    const chooseDistrict = (e) => {
        let wardTmp = filter(ward, { parentCode: e.target.value })
        setWardArr(wardTmp)
        setDistrictCode(e.target.value)
    }

    const chooseWard = (e) => {
        setWardCode(e.target.value)
    }

    const closeDialogMain = () => {
        props?.openFilter(false);
    };

    const handleFilter = () => {
        let cond = {
            condition: {}
        }

        if (!isEmpty(cityCode)) {
            cond.condition.cityCode = { equal: cityCode }
        }
        if (!isEmpty(districtCode)) {
            cond.condition.districtCode = { equal: districtCode }
        }
        if (!isEmpty(wardCode)) {
            cond.condition.wardCode = { equal: wardCode }
        }
        if (!isEmpty(saleId)) {
            cond.condition.trackers= {
                object:{
                    userId:{
                        in: [saleId]
                    }
                }
                }

                
        }
       props?.handleFilter(cond)
    }

    return (
        <div>
            <Dialog
                open={props?.isOpenFilter}
                fullWidth={true}
                maxWidth={'md'}
                onClose={closeDialogMain}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ background: theme.palette.primary.main, color: '#fff' }}>
                    {"Lọc dữ liệu"}
                </DialogTitle>
                <DialogContent style={{ paddingTop: 32 }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            {/* <Typography variant='body2' ml={1} mt={2} mb={2} sx={{ fontWeight: 500 }}>Cọc</Typography> */}
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl sx={{ m: 1, minWidth: 256 }} size="small">
                                        <InputLabel id="demo-select-cityCode">Tỉnh/Thành phố</InputLabel>
                                        <Select
                                            labelId="demo-select-cityCode"
                                            id="demo-select-cityCode"
                                            value={cityCode}
                                            name="cityCode"
                                            onChange={chooseProvince}
                                            label="Tỉnh">
                                            <MenuItem value="">
                                                <em>Chọn....</em>
                                            </MenuItem>
                                            {provinceArr?.map((prov, key) => {
                                                return <MenuItem value={prov?.code} key={key}>{prov.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ m: 1, minWidth: 256 }} size="small">
                                        <InputLabel id="demo-select-districtCode">Huyện/Quận</InputLabel>
                                        <Select
                                            labelId="demo-select-districtCode"
                                            id="demo-select-districtCode"
                                            value={districtCode}
                                            name="districtCode"
                                            onChange={chooseDistrict}
                                            label="Huyện/Quận">
                                            <MenuItem value="">
                                                <em>Chọn....</em>
                                            </MenuItem>
                                            {districtArr?.map((prov, key) => {
                                                return <MenuItem value={prov?.code} key={key}>{prov.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ m: 1, minWidth: 256 }} size="small">
                                        <InputLabel id="demo-select-wardCode">Xã/Phường</InputLabel>
                                        <Select
                                            labelId="demo-select-wardCode"
                                            id="demo-select-wardCode"
                                            value={wardCode}
                                            name="wardCode"
                                            onChange={chooseWard}
                                            label="Xã/Phường">
                                            <MenuItem value="">
                                                <em>Chọn....</em>
                                            </MenuItem>
                                            {wardArr?.map((prov, key) => {
                                                return <MenuItem value={prov?.code} key={key}>{prov.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl sx={{ m: 1, minWidth: 360, ml: 1.5 }} size="small">
                                        <InputLabel id="demo-select-small">Theo dõi</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            onChange={(e)=>setState(state=>({...state, saleId:e.target.value}))}
                                            name="saleId"
                                            value={saleId}
                                            label="Nhân viên"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {sale?.map((res, ind) => {
                                                return <MenuItem key={ind} value={res?._id}>{res?.firstName + ' ' + res?.lastName}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingX: 2 }}>
                <Button onClick={closeDialogMain}>Đóng</Button>
                <Button onClick={handleFilter} variant={'contained'}
                    sx={{ minWidth: 120 }}
                    disableElevation>Lọc</Button>
            </DialogActions>
        </Dialog>
        </div >
    );
}


const mapDispatchToProps = {
    updateChance, getUser, getDemand, getProject
}

const mapStateToProps = (state) => {
    console.log({ state })
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);