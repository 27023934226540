import { Box } from '@mui/material';
import React from 'react';
import A5 from './A5';

const FormA4 = React.forwardRef((props, ref) => {
    return <Box ref={ref} className="printTemplate pixA4">
        <A5/>
        <div style={{margin:'16px 0px', borderBottom: '0.5px dashed black'}}></div>
        <A5/>
    </Box>
});


export default FormA4;