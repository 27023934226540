import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";

export const generateCode = () => (dispatch) => {
  return setTokenAxios().get(`updateLog/generate-code`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data.code })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getLogById = (id) => (dispatch) => {
  return setTokenAxios().get(`updateLog/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`updateLog`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_UPDATE_LOG",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getMore = (condition) => (dispatch) => {
  return setTokenAxios().get("/updateLog",  { params: condition })
      .then(res => {
          dispatch({
              type:"MORE_UPDATE_LOG",
              payload: res.data.data
          })
      return Promise.resolve({ data: res.data })
      })
      .catch((err) => {
         return Promise.reject({ error: err.response.data })
     })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`updateLog`, { params: condition })
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}



export const createLog = (data) => (dispatch) => {
  return setTokenAxios().post(`updateLog`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_UPDATE_LOG",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateLog = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`updateLog/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_UPDATE_LOG",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteLog = ( _id) => (dispatch) => {
  return setTokenAxios().delete(`updateLog/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_UPDATE_LOG",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}