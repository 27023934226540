const initialState = []

const bankAccountReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_EXCHANGE_RATE":
            return action.payload

        case "CREATE_EXCHANGE_RATE":
            return [...state, action.payload]

        case "UPDATE_EXCHANGE_RATE":
            const index = state.findIndex(per => per._id === action.payload._id)
            state[index] = action.payload;
            return [...state]


        case "UPDATE_EXCHANGE_RATE_ACTIVE":
            const bankAccount_ = [...state]
            if (action.payload.length > 0) {
                (action.payload).forEach((item) => {
                    const index = state.findIndex(per => per._id === item._id)
                    bankAccount_[index] = item;
                })
            }
            return bankAccount_

        case "SET_CURRENT_EXCHANGE_RATE_IMG":
            return [...state]

        case "DELETE_EXCHANGE_RATE":
            const bankAccount_old = [...state]
            var bankAccount_deleted = []
            if (action.payload.length > 0) {
                bankAccount_deleted = bankAccount_old.filter(del => !(action.payload).includes(del._id));
            }
            return bankAccount_deleted


        default:
            break;
    }

    return state
}

export default bankAccountReducer