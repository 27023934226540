const initialState = {}

const editPaymentDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_EDIT_PAYMENT_DETAIL":
            return action.payload

        default:
            break;
    }

    return state
}

export default editPaymentDetailReducer