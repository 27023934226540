import { setTokenAxios } from "../api/index";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";
import { _showAlertError } from "../untils/error";


export const getFinancialYearById = (id) => (dispatch) => {
  return setTokenAxios().get(`financial-year/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`financial-year`, { params: condition })
    .then(async res => {
      dispatch({
        type: "SET_FINANCIAL_YEAR",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch(async (err) => {
      await _showAlertError(err)
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`financial-year`, { params: condition })
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch(async (err) => {
      await _showAlertError(err)
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}



export const createFinancialYear = (data) => (dispatch) => {
  return setTokenAxios().post(`financial-year`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_FINANCIAL_YEAR",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateFinancialYear = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`financial-year/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_FINANCIAL_YEAR",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteFinancialYear = ( _id) => (dispatch) => {
  return setTokenAxios().delete(`financial-year/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_FINANCIAL_YEAR",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}
