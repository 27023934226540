import { Box } from '@mui/material';
import React from 'react';
// import { NumberToText } from '../../../../untils/functions';


const A5 = React.forwardRef((props, ref) => {

    return <Box>
        <div className='headPrintBank'>
            <div className='leftPrintBank'>

            </div>
            <div className='centerPrintBank'>
                <div className="prtCompany">
                    ໃບເຄຼຍເງນິ
                </div>
                <div className="prtAddress">
                    借支-还款表
                </div>
            </div>
            <div className='rightPrintBank'>
                <div className="rowTopBank">
                    <span className='titBankTop'>ແຊກັ ທຄ/支票</span>
                    <input type='checkbox' className='checkBoxRight' />
                </div>
                <div className="rowTopBank">
                    <span className='titBankTop'>i-Bank/网银</span>
                    <input type='checkbox' className='checkBoxRight' />
                </div>
                <div className="rowTopBank">
                    <span className='titBankTop'>ເງນິ ສດົ /现金</span>
                    <input type='checkbox' className='checkBoxRight' />
                </div>
            </div>
        </div>
        <div className='headMiddleBank'>
            <div className='leftMiddleBank'>
                <div className='middleRowBank'>
                    <span className='titBank'>ຊື່/姓名 :</span>
                    <span className='contentBank'></span>
                </div>
                <div className='middleRowBank'>
                    <span className='titBank'>ພະແນກ/单位 :</span>
                    <span className='contentBank'></span>
                </div>
                <div className='middleRowBank'>
                    <span className='titBank'>ຕາໍ ແຫນງື່ /职务 :</span>
                    <span className='contentBank'></span>
                </div>
            </div>
            <div className='rightMiddleBank'>
                <div className='middleRowBank'>
                    <span className='titBank'>ເລກທີ/编号 :</span>
                    <span className='contentBank'></span>
                </div>
                <div className='middleRowBank'>
                    <span className='titBank'>ວນັ ທີ/日期 :</span>
                    <span className='contentBank'></span>
                </div>
            </div>
        </div>
        <div className='subHeadPrintBank'>
            <div className='chkPrint'>
                <input type="checkbox" />
                <span>LAK</span>
            </div>
            <div className='chkPrint'>
                <input type="checkbox" />
                <span>USD</span>
            </div>
            <div className='chkPrint'>
                <input type="checkbox" />
                <span>THB</span>
            </div>
        </div>
        <div className='bodyPrintBank'>
            <table>
                <tr>
                    <td>
                        ລາໍດບັ<br />
                        序号
                    </td>
                    <td>
                        ເນອື້ໃນ<br />
                        内容
                    </td>
                    <td>
                        ຄນເງນິ<br />
                        还款
                    </td>
                    <td>
                        ເບີກຕື່ມ<br />
                        再借
                    </td>
                    <td>
                        ຈາ ໍນວນເງນິ<br />
                        金额
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>ລວມຍອດ/总额</td>
                    <td></td>
                </tr>
            </table>
        </div>

        <div className='footerPrintBank'>
            <div className='blockSignBank'>
                <span className='briefSignbBank'></span>
                <span className='titSignBank'>ຫວົ ຫນາ້ ບນັ ຊີ / 财务主管 </span>
            </div>
            <div className='blockSignBank'>
                <span className='briefSignbBank'></span>
                <span className='titSignBank'>ຄງັ ເງນິ / 出纳 </span>
            </div>
            <div className='blockSignBank'>
                <span className='briefSignbBank'></span>
                <span className='titSignBank'>ຜກ້ ວດບນິ / 审单人</span>
            </div>
            <div className='blockSignBank'>
                <span className='briefSignbBank'></span>
                <span className='titSignBank'>ຜຮ້ ບັ /ມອບເງນິ / 收/还款人 </span>
            </div>
        </div>
    </Box>
});


export default A5;