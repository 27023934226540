import _ from 'lodash'
const initialState = []

const typingMessageReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case "SET_TYPING_MESSAGE":
            return [action.payload]

        case "CREATE_TYPING_MESSAGE":
            var tmp = {...state}

            var newMessage = [...action.payload]
            newMessage = _.reverse(newMessage)
            var messages =  [...newMessage, ...state.messages]
            tmp.messages = messages
            return tmp

        case "UPDATE_TYPING_MESSAGE":
            const index = state.findIndex(per => per.groupChatCode === action.payload.groupChatCode)
            state[index] = action.payload;
            
            return [...state]
        
        case "UPDATE_TYPING_MESSAGE_ACTIVE": 
            if(action.payload.length > 0)
            {
                (action.payload).map((item)=>{
                    const index = state.findIndex(per => per._id === item._id)
                    return state[index] = item;
                })
            }
            return [...state] 
        
        case "DELETE_TYPING_MESSAGE":
            var data_deleted = []
            if(action.payload.length > 0)
            {
                data_deleted = state.filter(del =>!(action.payload).includes(del._id) );
            }
            return data_deleted
        

        default:
            break;
    }

    return state
}

export default typingMessageReducer