import { Drawer } from '@mui/material';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import * as React from 'react';

const FormDrawer = React.forwardRef((props, ref) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  function openFromParent() {
    setState({ ...state, right: true });
  }

  function closeFromParent() {
    setState({ ...state, right: false });
  }

  React.useImperativeHandle(ref, () => ({ openFromParent, closeFromParent }));

  const content = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 920 }}
      role="presentation"
    //   onClick={toggleDrawer(anchor, false)}
    //   onKeyDown={toggleDrawer(anchor, false)}
    >
        {props?.children}
    </Box>
  );

  return (
        <React.Fragment key={'right'}>
          {/* <Button onClick={toggleDrawer('right', true)}>Right</Button> */}
          <Drawer
            variant="temporary"
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            // onClose={(_, reason) =>
            //    { 
            //    if(reason === 'backdropClick')
            //     {
            //         toggleDrawer('right', false)
            //     }
            //   }
            // }
            // onOpen={toggleDrawer('right', true)}
          >
           {content('right')}
          </Drawer>
        </React.Fragment>
  );
})

export default FormDrawer;