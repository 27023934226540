import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormA4 from './FormA4';
import FormA5 from './FormA5';

import { useReactToPrint } from 'react-to-print';
import FormA402 from './FormA402';

export default function DialogPrint(props) {
    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState(1);
    const componentPrintRef = React.useRef();

    React.useEffect(() => {
        handleClickOpen()
    }, [props?.isOpen])

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props?.handlePrint(false)
    };

    const render = () => {
        switch (type) {
            case 1:
                return <FormA4 ref={componentPrintRef} />
            case 2:
                return <FormA5 ref={componentPrintRef} />
            case 3:
                return <FormA402 ref={componentPrintRef} />
            default:
                return <></>
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentPrintRef.current,
    });

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            fullWidth={true}
            scroll='paper'
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >

            <DialogTitle id="alert-dialog-title"
                sx={{
                    background: '#2196f3'
                }}>
                <Box display='flex' justifyContent={"space-between"} alignItems="center" sx={{ color: '#fff' }}>
                    {"Print Preview"}
                    <Select
                        labelId="demo-simple-preview-label"
                        id="demo-simple-preview"
                        value={type}
                        sx={{ backgroundColor: '#fff' }}
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                        onChange={handleChange}>
                        <MenuItem value={1}>ແບບຟອມ ເຄຍເງິນ A4</MenuItem>
                        <MenuItem value={2}>ແບບຟອມ ເຄຍເງິນ A5</MenuItem>
                        <MenuItem value={3}>ໃບຢງັ້ຢືນການຈາ່ຍ A4</MenuItem>
                    </Select>
                </Box>

            </DialogTitle>
            <DialogContent>
                {render()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handlePrint} autoFocus>
                    Print
                </Button>
            </DialogActions>
        </Dialog>
    );
}