import { Box } from '@mui/material';
import React from 'react';
import { NumberToText } from '../../../../untils/functions';


const A5 = React.forwardRef((props, ref) => {

    return <Box>
        <div className='headPrint'>
            <div className='leftPrint'>
                <div className="prtCompany">
                    ONE IC CORPORATION
                </div>
                <div className="prtAddress">
                    Viet Nam
                </div>
            </div>
            <div className='rightPrint'>
                <div className="prtSimple">
                    No.: BTT-001
                </div>
                <div className="prtDesSimple">
                    (Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit)
                </div>
            </div>
        </div>
        <div className='headMiddle'>
            <div className='prtTitle'>INVOICE</div>
            <div className='prtDesTitle'>
                <span>Book: ...</span>
                <span>No: ...</span>
                <div className='prtAccount'>
                    <span>Debit: 1011 </span>
                    <span>2.000.000 </span>
                </div>
                <div className='prtAccount'>
                    <span>Credit: 303 </span>
                    <span>2.000.000 </span>
                </div>

            </div>
        </div>
        <div className='bodyPrint'>
            <p>
                Contact: ONE IC CORPORATION
            </p>
            <p>
                Address:
            </p>
            <p>
                Note:
            </p>
            <p>
                Total: 2.000.000 <span>({NumberToText(2000000)})</span>
            </p>
        </div>

        <div className='briefPrint'>
            <span> Additional: ............... documents</span>
        </div>
        <div className='datePrint'>
            <span>20th, October 2022</span>
        </div>
        <div className='footerPrint'>
            <div className='blockSign'>
                <span className='titSign'>Lorem Ipsum </span>
                <span className='briefSign'>(Sign and stamp)</span>
            </div>
            <div className='blockSign'>
                <span className='titSign'>Lorem Ipsum </span>
                <span className='briefSign'>Sign and stamp)</span>
            </div>
            <div className='blockSign'>
                <span className='titSign'>Lorem Ipsum </span>
                <span className='briefSign'>Sign and stamp)</span>
            </div>
            <div className='blockSign'>
                <span className='titSign'>Lorem Ipsum </span>
                <span className='briefSign'>Sign and stamp)</span>
            </div>
        </div>
    </Box>
});


export default A5;