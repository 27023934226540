import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, useTheme } from '@mui/material';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllData } from '../../../actions/additional';

import { indigo } from '@mui/material/colors';
import { setEditObject } from '../../../actions/untils';
import { ActiveStatus } from '../../../enums/activeStatus';
import BreadCrumb from '../../elements/breadCrumb';
import DrawerExpand from '../../elements/drawerExpand';
import FormDrawer from '../../elements/formDrawer';
import RowMenu from '../../elements/rowMenu';
import AddForm from './AddForm';
import EditForm from './EditForm';
import { useTranslation } from 'react-i18next';


const Main = (params) => {
  const { props } = params
  const theme = useTheme()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const childRef = React.useRef();
  const [type, setType] = useState('add')
  const {t} = useTranslation()

  let listData = [...props?.data]

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionMenu = async (value, type) => {
    childRef.current?.openFromParent();
    setType(type)
    if(type !== 'add')
    {
     await props?.setEditObject(value)
    }
  }

  useEffect(() => {
    props?.getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])  

  const renderRow = () => {

    let list = rowsPerPage > 0
      ? listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : listData


    return list.map((row, index) => {

     return <TableRow key={row._id} >
        <TableCell align="left">
          <RowMenu 
          data={row}
          actionEdit={handleActionMenu}
          sx={{ mr: 2 }} />
          {page * rowsPerPage + index + 1}.
        </TableCell>
        <TableCell align="left">
          {row?.code}
        </TableCell>
        <TableCell component="th" scope="row" sx={{
          borderWidth: 0.5
        }}>
           <Typography variant='subtitle2' color={indigo[500]} sx={{ lineHeight:1 }}>
            {row?.name}
          </Typography>
        </TableCell>
        <TableCell align="right">{row?.additionalAttributes?.length}</TableCell>
        <TableCell align="right">{ActiveStatus(row.isActive)}</TableCell>
      </TableRow>
    })
  }

  let breadcrumb = [{ name: 'Home', href: '/' }, { name: 'Đánh giá tài chính', href: '/team' }]
  return (
    <>
      <BreadCrumb data={breadcrumb} />
      <TableContainer component={Paper} sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 0,
        padding: 2,
        borderRadius: 2
      }}>
        <Box sx={{ fontSize: 24 }}>
          {/* Table of Customer */}
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">

          <TableHead>
            <TableRow sx={{
              fontWeight: 'bold'
            }}>
              <TableCell>No.</TableCell>
              <TableCell>Code.</TableCell>
              <TableCell>Tên</TableCell>
              <TableCell align="right">Thuộc tính</TableCell>
              <TableCell align="right">Trạng thái</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow()}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8, 16, 25, { label: 'Tất cả', value: -1 }]}
                colSpan={7}
                count={listData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': t('pagingPerPage'),
                  },
                  native: true,
                }}
                labelRowsPerPage="Quantity:"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Box sx={{
        '& > :not(style)': {
          position: 'fixed',
          right: 16,
          bottom: 42
        }
      }}>
        <Fab color="primary" aria-label="add" onClick={() => handleActionMenu('', 'add')}>
          <AddIcon />
        </Fab>
      </Box>
      <FormDrawer ref={childRef}>
        {type === 'add' ? <AddForm /> : <EditForm/>}
      </FormDrawer>
    </>
  )
}

const Additional = (props) => {
  let title = "Cấu hình thêm"
  document.title = title
  return (
    <>
      <DrawerExpand headerText={title}>
        <Main props={props} />
      </DrawerExpand>
    </>
  );
};

const mapDispatchToProps = {
  getAllData, setEditObject
}

const mapStateToProps = (state) => {
  return {
    data: state.additional
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Additional);