import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../actions/auth';
import { _showAlertErrorMessage } from '../../../untils/error';
import MainAlert from '../../elements/MainAlert';
import logo from './logo.png';
import './style.css';
import { connectSocketMng } from '../../../actions/socketInside';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { store } from '../../../store';

//detect
import { deviceDetect } from 'react-device-detect';
import { LoadingButton } from '@mui/lab';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const images = [
    {
        label: 'Step 1/. Click the button on top - left of an address bar. ',
        imgPath:
            '/resources/step/step1.png',
    },
    {
        label: 'Step 2/. Enable the button to allow access to browser-authenticated permissions.. ',
        imgPath:
            '/resources/step/step2.png',
    },
    {
        label: 'Step 3/. Refresh the browser: press (Ctrl + F5) with Windows or (Command + R) with MacOS',
        imgPath:
            '/resources/step/step3.png',
    }
];

const Login = (props) => {
    const theme = useTheme();
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    // eslint-disable-next-line
    const [isDenied, setIsDenied] = useState(false)
    const [stringReg, setStringReg] = useState('')
    const [objReg, setObjectReg] = useState({})
    const { i18n, t } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };



    React.useEffect(() => {
        i18n.changeLanguage('la');
        mapLocation()
        // eslint-disable-next-line
    }, [])

    const initApp = () => {
        connectSocketMng();
        store.dispatch({
            type: 'SET_IS_INIT',
            payload: true
        })
    }

    function handleLogin(objReg, stringReg) {
        if (!isEmpty(userName) && !isEmpty(password)) {
            props?.login({ userName, password, objReg, stringReg })
                .then(res => {
                    dispatch({
                        type: "SET_ENUM",
                        payload: {
                            navigate: 'cash-analysis',
                        }
                    })
                    initApp()
                    navigate("/cash-analysis", { replace: true });
                })
                .catch(err => {
                    // _showAlertErrorMessage(err?.message) //|| t('LOGIN_FAILURE')

                    // console.log('err-handleLogin', err?.message, t('LOGIN_FAILURE'))
                })
        }
        else {
            _showAlertErrorMessage(t('alrLogin'))
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin()
        }
    }

    const mapLocation = async () => {
        if (isEmpty(objReg?.IP)) {
                    let lat = ''
                    let long = ''
                    const response = await fetch('https://geolocation-db.com/json/');
                    const data = await response.json();

                    let device = await deviceDetect()
                    let string = ''
                    string += `\nappVersion: ${device?.userAgent}, `
                    string += `[\nOS: ${device?.osName}, `
                    string += `\nBrowserName: ${device?.browserName}, `
                    string += `\nosVersion: ${device?.osVersion}, `
                    string += `\nIP: ${data?.IPv4}, `
                    string += `\nlat: ${lat}, `
                    string += `\nlong: ${long}]`

                    let objReg = {
                        osName: device?.osName,
                        browserName: device?.browserName,
                        userAgent: device?.userAgent,
                        osVersion: device?.osVersion,
                        IPv4: data?.IPv4,
                        lat,
                        long
                    }

                    setStringReg(string)
                    setObjectReg(objReg)

                }
        }

        // if (navigator.geolocation) {
        //     await navigator.permissions.query({ name: 'geolocation' }).then(async permissionStatus => {
        //         if (permissionStatus.state === 'denied') {
        //             // alert('Please allow location access.');
        //             //  navigator.permissions.revoke({name:'geolocation'});
        //             setIsDenied(true)
        //         } else {
        //             if (isEmpty(objReg?.IP)) {
        //                 await navigator.geolocation.getCurrentPosition(
        //                     async function (position) {
        //                         // console.log("Latitude is :", position.coords.latitude);
        //                         // console.log("Longitude is :", position.coords.longitude);
        //                         //ip 
        //                         let lat = position?.coords?.latitude
        //                         let long = position?.coords?.longitude
        //                         const response = await fetch('https://geolocation-db.com/json/');
        //                         const data = await response.json();

        //                         let device = await deviceDetect()
        //                         let string = ''
        //                         string += `\nappVersion: ${device?.userAgent}, `
        //                         string += `[\nOS: ${device?.osName}, `
        //                         string += `\nBrowserName: ${device?.browserName}, `
        //                         string += `\nosVersion: ${device?.osVersion}, `
        //                         string += `\nIP: ${data?.IPv4}, `
        //                         string += `\nlat: ${lat}, `
        //                         string += `\nlong: ${long}]`

        //                         let objReg = {
        //                             osName: device?.osName,
        //                             browserName: device?.browserName,
        //                             userAgent: device?.userAgent,
        //                             osVersion: device?.osVersion,
        //                             IPv4: data?.IPv4,
        //                             lat,
        //                             long
        //                         }


        //                         setStringReg(string)
        //                         setObjectReg(objReg)

        //                     },
        //                     function (error) {
        //                         console.error("Error Code = " + error.code + " - " + error.message);
        //                     }
        //                 );
        //             }

        //         }
        //     })
        // }
    

    const renderStep = () => {
        return <Box sx={{ maxWidth: 550, flexGrow: 1 }}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 2,
                    pl: 2,
                    flexDirection: 'column',
                    bgcolor: 'background.default',
                }}
            >
                <Typography variant='h6'>Guideline grants access to the application</Typography>
                <Typography variant='body2'>{images[activeStep].label}</Typography>
            </Paper>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={step.label} className='imageLoginStep'>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    height: 255,
                                    display: 'block',
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                }}
                                src={step.imgPath}
                                alt={step.label}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    }

    return (
        <>
            <Box style={{
                display: 'flex',
                flex: 1, justifyContent: 'center',
                height: '100vh',
                //background: '#ffff',
                alignItems: 'center', alignContent: 'center',
                //  background: 'radial-gradient(circle, #76b8d0 0%, #6aa9d5 30%, #5796c9 45%)',
            }}>
                <div className="formLogin">

                    <img style={{
                        maxWidth: 300,
                        maxHeight: 200,
                        marginBottom: 18,
                    }}
                        src={logo}
                        alt="CRM" />

                    <Typography variant='title'
                        fontSize={24}
                        sx={{ mb: 0, color: "#607d8b", textTransform: 'uppercase' }}>
                        3BGROUP
                    </Typography>

                    <Typography variant='title' sx={{ mb: 2, color: "#607d8b", textTransform: 'uppercase' }}>
                        Bussiness Intelligence
                    </Typography>


                    <input className='inputLogin'
                        placeholder={t('lblLoginU')}
                        value={userName} type="text" onChange={(e) => setUserName(e.target.value)} />

                    <input className='inputLogin'
                        placeholder={t('lblLoginP')}
                        onKeyDown={handleKeyDown}
                        value={password} type="password" onChange={(e) => setPassword(e.target.value)} />

                    {!isEmpty(objReg) ? <Button
                        className='btnLogin'
                        onClick={() => handleLogin(objReg, stringReg)}
                    >
                        {t('btnSignIn')}
                    </Button> : <LoadingButton size='small' />}
                </div>
            </Box>
            {isDenied === true && <Box className='deniedDialog'>
                {renderStep()}
            </Box>}
            {objReg?.lat ?
                <div style={{ fontSize: 11, textAlign: 'right', position: 'absolute', bottom: 6, right: 6 }}>
                    Information : {objReg?.osName}, {objReg?.browserName}, {objReg?.IPv4}  &nbsp;
                    at &nbsp; <a style={{ color: 'grey' }} href={`http://www.google.com/maps/place/${objReg?.lat},${objReg?.long}`} rel="noreferrer">{objReg?.lat},{objReg?.long}</a>
                </div> : ''}
            <MainAlert />
        </>
    );
};



const mapDispatchToProps = {
    login
}


export default connect(null, mapDispatchToProps)(Login);