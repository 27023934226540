import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { sumBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { getCreditNoteByCode } from '../../../../actions/creditNote';
import { getDebitNoteByCode } from '../../../../actions/debitNote';
import { getIncomeByCode } from '../../../../actions/income';
import { getJournalEntrieByCode } from '../../../../actions/journalEntrie';
import { getOutcomeByCode } from '../../../../actions/outcome';
import { getPaymentByParent } from '../../../../actions/payment';
import { renderNumberDollar } from '../../../../untils/number';

import { MoreVertOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import DetailForm from '../../journalEntrie/DetailForm';
import DialogPrint from '../components/DialogPrint';

const DetailFormAccountWithChild = React.forwardRef((props, ref) => {
    let { beginBlance, data, accountNumber, accountNumberArr, title, startDate, endDate, isPrint } = props
    const [main, setMain] = useState([])
    const [openDetail, setOpenDetail] = useState(false)
    const [listBottomSheet, setListBottomSheet] = React.useState([]);
    const [dataParent, setDataParent] = React.useState({});

    let beginBalanceMain = 0

    let tmp = beginBlance.filter(item => [accountNumber].indexOf(item.accountNumber) > -1)
    if (tmp.length > 0) {
        beginBalanceMain = sumBy(tmp, 'beginDebitAmount')
    }

    let sumDebit = 0
    let sumCredit = 0

    const checkAmount = (item) => {
        let debit = 0
        let credit = 0
        let isBank = -1

        if (item?.paymentType === 'DRB' || item?.paymentType === 'CRB') {
            isBank = 0
        }

        if (isBank > -1) { // if bank
            debit = item.paymentType === "DRB" ? item.amount : 0
            credit = item.paymentType === "CRB" ? item.amount : 0
        }
        else {
            debit = accountNumberArr.indexOf(item.accountNumberDebit) > -1 ? item.amount : 0
            credit = accountNumberArr.indexOf(item.accountNumberCredit) > -1 ? item.amount : 0
        }

        return { debit, credit }
    }

    const repare = async () => {
        let tmp = await data?.map((item, index) => {
            let start = index === 0 ? beginBalanceMain : data[index - 1]?.yesterday
            let { debit, credit } = checkAmount(item)

            // let debit = item.paymentType === "CR" ? item.amount : 0
            // let credit = item.paymentType === "CP" ? item.amount : 0
            let end = start + debit - credit
            sumDebit += debit
            sumCredit += credit
            item.end = end
            item.yesterday = end
            item.sumDebit = sumDebit
            item.sumCredit = sumCredit
            item.debit = debit
            item.credit = credit
            return item
        })
        return tmp
    }


    React.useEffect(() => {
        repare().then(res => {
            setMain(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    let mainLength = main.length

    const handleClosePrint = () => {
        props?.handleClosePrint(false)
    }

    const componentPrint = () => {
        return <div className='printFileReport' ref={ref} >
            <div className='topFileReport'>
                <div className='titFileReport'>{title}</div>
                <div className='dateFileReport'>From {startDate} to {endDate}</div>
                <div className='containFileReport'>
                    <div className='colFileReport'>
                        <div className='lineColReport'>
                            <p>
                                Account:
                            </p>
                            <div className='detailColFile'>
                                {accountNumber?.map((res, ind) => {
                                    return <span key={ind}>{res?.code} {ind < accountNumber?.length - 1 ? ', ' : ''}</span>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Table sx={{ width: '100%', border: '1px solid #d2d2d2' }} className='tableRp tblPrint'>
                <TableHead className='header-wrapper'>
                    <TableRow sx={{ background: '#F7FAFC', '& .MuiTableCell-root': { padding: '8px 6px!important' } }}>
                        <TableCell>#</TableCell>
                        <TableCell align="left" width={80}>Date / No.</TableCell>
                        <TableCell align="center" >Account
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{
                                    display: 'block',
                                    minWidth: '30px'
                                }}>CR </span> <span style={{
                                    display: 'block',
                                    minWidth: '30px',
                                }}>DR</span>
                            </div>
                        </TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="center" width={140}>Debit Amount
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{
                                    display: 'block',
                                    width: '70px'
                                }}>Original</span> <span style={{
                                    display: 'block',
                                    width: '70px',
                                }}>Currently</span>
                            </div>
                        </TableCell>
                        <TableCell align="center" width={140}>Credit Amount
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{
                                    display: 'block',
                                    width: '70px'
                                }}>Original</span> <span style={{
                                    display: 'block',
                                    width: '70px',
                                }}>Currently</span>
                            </div>
                        </TableCell>
                        <TableCell align="right" width={110}>Balance </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" >
                            <b>Begining</b>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{renderNumberDollar(beginBalanceMain)}</b></span></TableCell>
                    </TableRow>
                    {main?.map((item, index) => {
                        return <TableRow
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 }, '& td': {
                                    paddingLeft: '4px!important',
                                    paddingRight: '4px!important'
                                }
                            }}>
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell align="left" >
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: 11 }}>
                                    <span>
                                        {moment(item?.date).format('DD/MM/YYYY')}
                                    </span>
                                    <span>{item?.parentCode}</span>
                                </div>
                            </TableCell>
                            <TableCell align="left">
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <span style={{
                                        display: 'block',
                                        minWidth: '30px',
                                        textAlign: 'left'
                                    }}>{item?.accountNumberDebit} </span> <span style={{
                                        display: 'block',
                                        minWidth: '30px',
                                        textAlign: 'right',
                                    }}>{item.accountNumberCredit}</span>
                                </div></TableCell>
                            <TableCell align="left">{item?.description}</TableCell>
                            <TableCell align="right">
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <span style={{
                                        display: 'block',
                                        width: '70px'
                                    }}>
                                        <span className='fmNumber'> {item.debit > 0 ? renderNumberDollar(item.originalAmount) : '-'}</span>
                                        {(item.debit > 0 && item?.currencyCode !== 'LAK') && <span style={{ fontSize: 10, marginLeft: 2 }}>({item?.currencyCode})</span>}
                                    </span>
                                    <span style={{
                                        display: 'block',
                                        width: '70px'
                                    }}>
                                        <span className='fmNumber'> {item.debit > 0 ? renderNumberDollar(item.debit) : '-'}</span>
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell align="right">
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <span style={{
                                        display: 'block',
                                        width: '70px'
                                    }}>
                                        <span className='fmNumber'> {item.credit > 0 ? renderNumberDollar(item.originalAmount) : '-'}</span>
                                        {(item.credit > 0 && item?.currencyCode !== 'LAK') && <span style={{ fontSize: 10, marginLeft: 2 }}>({item?.currencyCode})</span>}
                                    </span>
                                    <span style={{
                                        display: 'block',
                                        width: '70px'
                                    }}>
                                        <span className='fmNumber'> {item.credit > 0 ? renderNumberDollar(item.credit) : '-'}</span>
                                    </span>
                                </div>

                            </TableCell>

                            <TableCell align="right"><span className='fmNumber'> {renderNumberDollar(item.end)}</span></TableCell>
                        </TableRow>
                    })}
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" colSpan={3}>
                            <b>Total</b>
                        </TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(main[mainLength - 1].sumDebit) : '-'}</b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(main[mainLength - 1].sumCredit) : '-'}</b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'> <b></b></span></TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left" colSpan={3}>
                            <b>Closing</b>
                        </TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                        <TableCell align="right"><span className='fmNumber'><b>
                            {mainLength > 0 ? renderNumberDollar(beginBalanceMain + main[mainLength - 1].sumDebit - main[mainLength - 1].sumCredit) : '-'}
                        </b></span></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    }

    const getDataParent = async (val) => {

        switch (val.paymentType) {
            case 'CR':
                await props?.getIncomeByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            case 'CP':
                await props?.getOutcomeByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            case 'DRB':
                await props?.getDebitNoteByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            case 'CRB':
                await props?.getCreditNoteByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            case 'JE':
                await props?.getJournalEntrieByCode(val?.parentCode).then(res => {
                    setDataParent(res.data)
                })
                break;

            default:
                break;
        }
    }

    const handleOpenDetail = async (val) => {
        getDataParent(val)
        await props?.getPaymentByParent(val?.parentCode).then(res => {
            setListBottomSheet(res.data)
            setOpenDetail(true)
        })
    }

    return (
        <>
            <Box className="printTemplate">
                {isPrint ? <DialogPrint isOpen={isPrint} handleRenderData={componentPrint} handleCloseProps={handleClosePrint} /> : ""}
                <TableContainer component={Paper} elevation={0} sx={{ maxHeight: 'calc(100vh - 150px)' }}>
                    <Table sx={{ minWidth: 650 }} className='tableRp' stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow sx={{
                                '& .MuiTableCell-root': {
                                    padding: '8px 12px!important'
                                }
                            }}>
                                <TableCell>#</TableCell>
                                <TableCell align="left" width={90}>Transaction Date</TableCell>
                                <TableCell align="left" width={90}>No.</TableCell>
                                <TableCell align="center" >Account
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <span style={{
                                            display: 'block',
                                            minWidth: '30px'
                                        }}>CR </span> <span style={{
                                            display: 'block',
                                            minWidth: '30px',
                                        }}>DR</span>
                                    </div>
                                </TableCell>
                                <TableCell align="left">Expense</TableCell>
                                <TableCell align="left">Department</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="center" width={160}>Debit Amount
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <span style={{
                                            display: 'block',
                                            width: '80px'
                                        }}>Original</span> <span style={{
                                            display: 'block',
                                            width: '80px',
                                        }}>Currently</span>
                                    </div>
                                </TableCell>
                                <TableCell align="center" width={160}>Credit Amount
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <span style={{
                                            display: 'block',
                                            width: '80px'
                                        }}>Original</span> <span style={{
                                            display: 'block',
                                            width: '80px'
                                        }}>Currently</span>
                                    </div>
                                </TableCell>
                                <TableCell align="right">Balance </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left"></TableCell>
                                <TableCell align="left" >
                                    <b>Begining</b>
                                </TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"><span className='fmNumber'><b>{renderNumberDollar(beginBalanceMain)}</b></span></TableCell>
                            </TableRow>

                            {main?.map((item, index) => {

                                return <TableRow
                                    key={index}
                                    className='rowTbRp'
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                    }}
                                >
                                    <TableCell align="left">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <MoreVertOutlined color='#000000de' onClick={() => handleOpenDetail(item)} />
                                            {index + 1}
                                        </div>
                                    </TableCell>
                                    <TableCell align="left" >
                                        {moment(item?.date).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell align="left">{item?.parentCode}
                                    </TableCell>
                                    <TableCell align="left">
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <span style={{
                                                display: 'block',
                                                minWidth: '30px'
                                            }}>{item?.accountNumberDebit} </span> <span style={{
                                                display: 'block',
                                                minWidth: '30px',
                                                textAlign: 'right',
                                            }}>{item.accountNumberCredit}</span>
                                        </div></TableCell>
                                    <TableCell align="left">{item?.expense?.code}</TableCell>
                                    <TableCell align="left">{item?.department?.code}</TableCell>
                                    <TableCell align="left">{item?.description}</TableCell>
                                    <TableCell align="right">
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <span style={{
                                                display: 'block',
                                                width: '80px'
                                            }}>
                                                {(item.debit > 0 && item?.currencyCode !== 'LAK') && <span style={{ fontSize: 10, marginLeft: 2 }}>({item?.currency?.symbol})</span>}
                                                <span className='fmNumber'> {item.debit > 0 ? renderNumberDollar(item.originalAmount) : '-'}</span>

                                            </span>
                                            <span style={{
                                                display: 'block',
                                                width: '80px'
                                            }}>
                                                <span className='fmNumber'> {item.debit > 0 ? renderNumberDollar(item.debit) : '-'}</span>
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="right">
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <span style={{
                                                display: 'block',
                                                width: '80px'
                                            }}>
                                                {(item.credit > 0 && item?.currencyCode !== 'LAK') && <span style={{ fontSize: 10, marginLeft: 2 }}>({item?.currency?.symbol})</span>}
                                                <span className='fmNumber'> {item.credit > 0 ? renderNumberDollar(item.originalAmount) : '-'}</span>

                                            </span>
                                            <span style={{
                                                display: 'block',
                                                width: '80px'
                                            }}>
                                                <span className='fmNumber'> {item.credit > 0 ? renderNumberDollar(item.credit) : '-'}</span>
                                            </span>
                                        </div>

                                    </TableCell>
                                    <TableCell align="right"><span className='fmNumber'> {renderNumberDollar(item.end)}</span></TableCell>
                                </TableRow>
                            })}
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left"></TableCell>
                                <TableCell align="left" colSpan={6}>
                                    <b>Total</b>
                                </TableCell>
                                {/* <TableCell align="right"><span className='fmNumber'><b>{sumDebit > 0 ? renderNumberDollar(sumDebit) : '-'}</b></span></TableCell>
                            <TableCell align="right"><span className='fmNumber'><b>{sumCredit > 0 ? renderNumberDollar(sumCredit) : '-'}</b></span></TableCell> */}
                                <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(main[mainLength - 1].sumDebit) : '-'}</b></span></TableCell>
                                <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(main[mainLength - 1].sumCredit) : '-'}</b></span></TableCell>
                                <TableCell align="right"><span className='fmNumber'> <b></b></span></TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left"></TableCell>
                                <TableCell align="left" colSpan={6}>
                                    <b>Closing</b>
                                </TableCell>
                                <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                                <TableCell align="right"><span className='fmNumber'><b></b></span></TableCell>
                                <TableCell align="right"><span className='fmNumber'><b>{mainLength > 0 ? renderNumberDollar(beginBalanceMain + main[mainLength - 1].sumDebit - main[mainLength - 1].sumCredit) : '-'}</b></span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {openDetail ? <DetailForm isOpen={openDetail}
                listBottomSheet={listBottomSheet}
                dataParent={dataParent} handleClose={() => setOpenDetail(false)} /> : <></>}
        </>
    );
})


const mapDispatchToProps = {
    getPaymentByParent, getIncomeByCode, getOutcomeByCode, getDebitNoteByCode,
    getCreditNoteByCode, getJournalEntrieByCode
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(DetailFormAccountWithChild);