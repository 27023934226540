import { Button, FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { getAllData as getDataPrimary } from '../../../actions/data';
import { createProduct } from '../../../actions/product';
import { GET_SUCCESS } from '../../../enums/vi';
import { _showAlertSuccess } from '../../../untils/error';
import { MenuProps } from '../../../untils/variable';

const initialState = {
    name: "",
    description: '',
    isActive: true,
    code: '',
    price: '',
    dataCode: []
};
function AddForm(props) {
    const theme = useTheme()
    const [
        { name, isActive, code, price, description, dataCode },
        setState
    ] = useState(initialState);
    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };
    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }
    const _clearState = () => {
        setState({ ...initialState });
    };
    const handleSubmit = () => {
        let data = { name, isActive, code, price, description, dataCode }
        props?.createProduct(data)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let arr = typeof value === 'string' ? value.split(',') : value
        setState(state => ({ ...state, dataCode: arr }))
    };

    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8 }}>
            <Stack direction="row" spacing={1}
                justifyContent='space-between'
                alignItems="center" sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.main
                }}>
                <Stack direction={'row'}>
                    <Chip label="Create" variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
                    <Typography variant='h5' color={'#fff'}>
                        Product
                    </Typography>
                </Stack>
                <Button variant='contained' disableElevation onClick={() => handleSubmit()}>
                    Save
                </Button>
            </Stack>
            <Box sx={{ paddingX: 2, paddingY: 4 }}>
                <Grid container spacing={2}>
                
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Name"
                                    id="last-name"
                                    value={name}
                                    name="name"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Code "
                                    id="code"
                                    value={code}
                                    name="code"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label="Active" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    type="text"
                                    label="Price"
                                    id="outlined-price"
                                    value={price}
                                    name="price"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>


                    <Grid item xs={12} style={{ paddingLeft: 24 }}>
                        <Typography variant='body2' >Apply for data:</Typography>
                        <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                size='small'
                                value={dataCode}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}>
                                {props?.data?.map((item) => (
                                    <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                        <Checkbox checked={dataCode?.indexOf(item.code) > -1 ? 'checked' : ''} />
                                        <ListItemText primary={`${item.code} - ${item.name}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Note"
                                    id="outlined-size-description"
                                    multiline={true}
                                    name="description"
                                    onChange={onChange}
                                    size="small"
                                    minRows={5}
                                    fullWidth={true}
                                />
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
const mapDispatchToProps = {
    createProduct , getDataPrimary
}

const mapStateToProps = (state) => {
    return {
        data: state.data
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddForm);