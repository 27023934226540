import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { teal } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { getDemandById, updateDemand } from '../../../actions/demand';
import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';



const initialState = {
    name: "",
    description: '',
    isActive: true,
    code: '',
};

function EditForm(props) {
    const theme = useTheme()
    const editObject = useSelector(state => state.edit)

    const [
        { name, isActive, code, description, _id },
        setState
    ] = useState({...editObject});


    const getDataById = () => {
        props?.getDemandById(_id).then(res => {
            setState(state => ({ ...state, ...res.data }))
        }).catch(err => {
            return _showAlertErrorMessage(DATA_NOT_FOUND)
        })
    }

    useEffect(() => {
        getDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])




    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const _clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = () => {
        let data = { name, isActive, code, description }
        props?.updateDemand(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
                _clearState()
            })
    }

    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, position: 'relative', pt: 8 }}>
            <Stack direction="row" spacing={1}
                justifyContent='space-between'
                alignItems="center" sx={{
                    padding: 2,
                    backgroundColor: theme.palette.primary.main
                }}>
                <Stack direction={'row'}>
                    <Chip label="Thêm" variant="filled" sx={{ mr: 2, backgroundColor: theme.palette.common.white }} />
                    <Typography variant='h5' color={'#fff'}>
                        Dự án
                    </Typography>
                </Stack>
                <Button variant='contained' disableElevation onClick={() => handleSubmit()}>
                    Lưu
                </Button>
            </Stack>

            <Box sx={{ paddingX: 2, paddingY: 4 }}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color={teal[500]}>
                            Thông tin cơ bản
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Tên"
                                    id="last-name"
                                    value={name}
                                    name="name"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={4}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Mã "
                                    id="code"
                                    value={code}
                                    name="code"
                                    onChange={onChange}
                                    size="small"
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />}
                                checked={isActive}
                                name="isActive"
                                onChange={(e) => customeOnChange('isActive', !isActive)}
                                label="Kích hoạt" />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    label="Ghi chú"
                                    id="outlined-size-description"
                                    multiline={true}
                                    name="description"
                                    onChange={onChange}
                                    size="small"
                                    minRows={5}
                                    fullWidth={true}
                                />
                            </div>
                        </Box>
                    </Grid>



                </Grid>





            </Box>

        </Box>
    );
}


const mapDispatchToProps = {
    getDemandById, updateDemand
}

const mapStateToProps = (state) => {

    return {
        editObj: state.edit
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);