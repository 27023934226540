import * as React from 'react';
import { useState } from 'react';

import { Autocomplete, Button, DialogTitle, FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { connect, useSelector } from 'react-redux';
import { getAllDataNoneRedux as getAccountNumber } from '../../../actions/accountant';
import { updateBeginningBalance, getBeginningBalanceById } from '../../../actions/beginningBalance';
import { getAllDataNoneRedux as getYear } from '../../../actions/financialYear';
import { getAllDataNoneRedux as getPartner } from '../../../actions/partner';
import { DATA_NOT_FOUND, GET_SUCCESS } from '../../../enums/vi';
import { _showAlertErrorMessage, _showAlertSuccess } from '../../../untils/error';
import { getAllData as getDataPrimary } from '../../../actions/data';

import { teal } from '@mui/material/colors';
import { debounce, find, findIndex } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';

import Dialog from '@mui/material/Dialog';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { MenuProps } from '../../../untils/variable';

const EditForm = React.forwardRef((props, ref) => {
    const editObject = useSelector(state => state.edit)

    const [
        { name, isActive, code, description, partner, _id, dataCode },
        setState
    ] = useState({ ...editObject });
    const { t } = useTranslation()
    const [listAccountNumber, setListAccountNumber] = useState([])
    const [listPartner, setListPartner] = useState([])
    const [accountNumber, setAccountNumber] = useState('')
    const [year, setYear] = useState('')
    const [kind, setKind] = useState([])
    const [type, setType] = useState('')
    const [isObject, setIsObject] = useState('')
    // eslint-disable-next-line
    const [isForeign, setIsForeign] = useState('')
    const [detail, setDetail] = useState([])

    const [creditAmount, setCreditAmount] = useState(0)
    const [debitAmount, setDebitAmount] = useState(0)
    const [creditOriginalAmount, setCreditOriginalAmount] = useState(0)
    const [debitOriginalAmount, setDebitOriginalAmount] = useState(0)
    const [beginCreditAmount, setBeginCreditAmount] = useState(0)
    const [beginDebitAmount, setBeginDebitAmount] = useState(0)
    const [beginCreditOriginalAmount, setBeginCreditOriginalAmount] = useState(0)
    const [beginDebitOriginalAmount, setBeginDebitOriginalAmount] = useState(0)

    const [inputValue, setInputValue] = useState('')
    const listData = props?.data


    useEffect(() => {
        const init = async () => {
            let listAccTemp = []
            await props?.getAccountNumber({ condition: { isFinal: { equal: true } }, limit: 1000 }).then(res => {
                listAccTemp = res.data
                setListAccountNumber(res.data)
            })
            await props?.getPartner({ condition: {}, limit: 20 }).then(res => setListPartner(res.data))
            await props?.getYear({ condition: { code: { equal: new Date().getFullYear() + '' } } })
                .then(res => {
                    setYear(res?.data[0])
                })

            await props?.getBeginningBalanceById(_id).then(res => {
                let data = res?.data
               
                let exist1 = data?.kind.indexOf('foreign')
                if (exist1 > -1) {
                    setIsForeign(true)
                }
                else {
                    setIsForeign(false)
                }
                setCreditOriginalAmount(data?.beginCreditOriginalAmount)
                setCreditAmount(data?.beginCreditAmount)
                setBeginCreditAmount(data?.beginCreditAmount)
                setBeginCreditOriginalAmount(data?.beginCreditOriginalAmount)
                setDebitAmount(data?.debitAmount)
                setDebitOriginalAmount(data?.debitOriginalAmount)
                setBeginDebitAmount(data?.beginDebitAmount)
                setBeginDebitOriginalAmount(data?.beginDebitOriginalAmount)
                let account = find(listAccTemp, { code: data?.accountNumber })
                setAccountNumber(account)
                setState(state => ({ ...state, ...data }))
            }).catch(err => {
                return _showAlertErrorMessage(DATA_NOT_FOUND)
            })

            await props?.getDataPrimary()
        }

        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setState(state => ({ ...state, [name]: value }));
    };

    const customeOnChange = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    }

    const handleSubmit = () => {
        let data = {
            name, isActive, type, detail, code, kind, accountNumber: accountNumber?.code, description,
            year: year.code,
            creditAmount: Number(creditAmount),
            debitAmount: Number(debitAmount),
            creditOriginalAmount: Number(creditOriginalAmount),
            debitOriginalAmount: Number(debitOriginalAmount),
            beginCreditAmount: Number(beginCreditAmount),
            beginDebitAmount: Number(beginDebitAmount),
            beginCreditOriginalAmount: Number(beginCreditOriginalAmount),
            beginDebitOriginalAmount: Number(beginDebitOriginalAmount),
            dataCode
        }
        props?.updateBeginningBalance(data, _id)
            .then(res => {
                _showAlertSuccess(res?.message || GET_SUCCESS)
            })
    }

    const onChangeInput = (e) => {
        setInputValue(e.target.value)
        searchAuto(e.target.value)
    }


    const searchAuto = debounce((value) => {
        var data = {
            "condition": {},
            "sort": {
                "created": -1
            },
            "search": value,
            "limit": 10,
            "page": 1
        }
        props?.getPartner({ data, limit: 20 }).then(res => setListPartner(res.data))
    }, 1000)

    const [openDetail, setOpenDetail] = React.useState(false);

    const handleClickOpen = () => {
        setOpenDetail(true);
    };

    const handleClose = () => {
        setOpenDetail(false);
    };

    const chooseCustomer = (val) => {
        let tmp = [...detail]
        let index = findIndex(tmp, { _id: val._id })
        if (index > 0) {
            tmp.splice(index, 1)
        }
        else {
            tmp.push(val)
        }
        setDetail(tmp)
    }

    const handleAccountNumber = (newValue) => {
        let kind = [...newValue.arrayType]
        let exist = kind.indexOf('object')
        setType(newValue?.type)
        setKind(kind)
        if (exist > -1) {
            setIsObject(true)
        }
        else {
            setIsObject(false)
        }

        let exist1 = kind.indexOf('foreign')

        if (exist1 > -1) {
            setIsForeign(true)
        }
        else {
            setIsForeign(false)
        }

        setAccountNumber(newValue);
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let arr = typeof value === 'string' ? value.split(',') : value

        setState(state => ({ ...state, dataCode: arr }))
    };

    React.useImperativeHandle(ref, () => ({ handleSubmit }));
    return (
        <Box sx={{ bgcolor: 'background.paper', flex: 1, padding: 2, position: 'relative', pt: 2 }}>

            <Box sx={{ display: 'flex', mb: 3 }}>
                <Typography fontSize={'medium'} color={teal[400]} sx={{ mr: 4 }}> Financial Year: {moment(year.start).format('DD/MM/YYYY')} - {moment(year?.end).format('DD/MM/YYYY')}</Typography>
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />}
                            checked={isActive}
                            name="isActive"
                            onChange={(e) => customeOnChange('isActive', !isActive)}
                            label="Active" />
                    </FormGroup>
                </Grid>

                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': {},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { display: 'flex' },
                            }}
                            noValidate
                            autoComplete="off" >
                            <Autocomplete
                                freeSolo
                                id="free-solo-accountNumber"
                                disableClearable
                                value={accountNumber}
                                onChange={(event, newValue) => {
                                    handleAccountNumber(newValue)
                                }}
                                size="small"
                                options={listAccountNumber}
                                getOptionLabel={(option) => option ? `${option?.code} - ${option?.name}` : ''}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Account Number"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={5} justifyContent="center">
                    {isObject && <Button variant='text' onClick={() => handleClickOpen()}>DETAIL ({detail.length})</Button>}
                </Grid>

                <Grid item xs={6}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            type="text"
                            label="Credit Amount"
                            id="outlined-creditAmount"
                            value={creditAmount}
                            name="creditAmount"
                            onChange={(e) => setCreditAmount(e.target.value)}
                            size="small"
                        />
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                type="text"
                                label="Debit Amount"
                                id="outlined-deditAmount"
                                value={debitAmount}
                                name="debitAmount"
                                onChange={(e) => setDebitAmount(e.target.value)}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                type="text"
                                label="Credit Original Amount"
                                id="outlined-creditOriginalAmount"
                                value={creditOriginalAmount}
                                name="creditOriginalAmount"
                                onChange={(e) => setCreditOriginalAmount(e.target.value)}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

              <Grid item xs={6}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off" >
                        <div>
                            <TextField
                                type="text"
                                label="Debit Original Amount"
                                id="outlined-debitOriginalAmount"
                                value={debitOriginalAmount}
                                name="debitOriginalAmount"
                                onChange={(e) => setDebitOriginalAmount(e.target.value)}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>}

                <Grid item xs={12}>
                    <Typography color={teal[400]} sx={{ mr: 4 }}>Beginning</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                type="text"
                                label="Credit Amount"
                                id="outlined-beginCreditAmount"
                                value={beginCreditAmount}
                                name="beginCreditAmount"
                                onChange={(e) => setBeginCreditAmount(e.target.value)}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                type="text"
                                label="Debit Amount"
                                id="outlined-beginDebitAmount"
                                value={beginDebitAmount}
                                name="beginDebitAmount"
                                onChange={(e) => setBeginDebitAmount(e.target.value)}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                type="text"
                                label="Credit Original Amount"
                                id="outlined-beginCreditOriginalAmount"
                                value={beginCreditOriginalAmount}
                                name="beginCreditOriginalAmount"
                                onChange={(e) => setBeginCreditOriginalAmount(e.target.value)}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off" >
                        <div>
                            <TextField
                                type="text"
                                label="Debit Original Amount"
                                id="outlined-beginDebitOriginalAmount"
                                value={beginDebitOriginalAmount}
                                name="beginDebitOriginalAmount"
                                onChange={(e) => setBeginDebitOriginalAmount(e.target.value)}
                                size="small"
                            />
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={12} style={{ paddingLeft: 24 }}>
                    <Typography variant='body2' >Apply for data:</Typography>
                    <FormControl sx={{ m: 0, width: '100%', pt: 1 }}>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            size='small'
                            value={dataCode}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}>
                            {listData.map((item) => (
                                <MenuItem key={item.code} value={item.code} className='liSelectCus'>
                                    <Checkbox checked={dataCode?.indexOf(item.code) > -1 ? 'checked' : ''} />
                                    <ListItemText primary={`${item.code} - ${item.name}`} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label="Note"
                                id="outlined-size-description"
                                multiline={true}
                                name="description"
                                onChange={onChange}
                                size="small"
                                minRows={5}
                                fullWidth={true}
                            />
                        </div>
                    </Box>
                </Grid>

            </Grid>

            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                onClose={handleClose} open={openDetail}>
                <DialogTitle>Set up detail</DialogTitle>
                <Grid container spacing={2} sx={{ p: 3, pt: 0 }}>

                    <Grid item xs={12}>
                        {listPartner?.length > 0 ?
                            <Autocomplete
                                sx={{ m: 1 }}
                                id="sale-select-partner"
                                options={[{ _id: '', firstName: t('frmChoose'), lastName: '...', phone: { phoneNumber: '----' } }, ...listPartner]}
                                isOptionEqualToValue={(option, value) => option._id === value?._id}
                                size="small"
                                value={partner}
                                autoHighlight
                                getOptionLabel={(option) => `${option?.firstName} ${option?.lastName} (${option?.phone?.phoneNumber})`}
                                onChange={(event, newValue) => {
                                    chooseCustomer(newValue)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Khách hàng"
                                        variant="outlined"
                                        value={inputValue}
                                        type="text"
                                        onChange={onChangeInput}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )} /> : <></>}

                    </Grid>

                    <TableContainer >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">No.</TableCell>
                                    <TableCell>Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {detail.map((row, index) => (

                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="right" sx={{ width: 32 }}>{index + 1}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.firstName} + {row.lastName}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </Dialog>
        </Box>
    );
})


const mapDispatchToProps = {
    updateBeginningBalance, getAccountNumber, getPartner, getYear, getBeginningBalanceById, getDataPrimary
}

const mapStateToProps = (state) => {
    return {
        data: state.data
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditForm);