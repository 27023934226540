import { setTokenAxios } from "../api/index"
import { _showAlertError } from "../untils/error";
import { GET_FAILURE, GET_SUCCESS } from "../enums/vi";



export const getAccountNumberById = (id) => (dispatch) => {
  return setTokenAxios().get(`account-number/${id}`)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getAccountNumberByArrCode = (arrCode) => (dispatch) => {
  return setTokenAxios().post(`account-number/get-by-code`, arrCode)
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}


export const getAllData = (condition) => (dispatch) => {
  return setTokenAxios().get(`account-number`, condition)
    .then(async res => {
      dispatch({
        type: "SET_ACCOUNT_NUMBER",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const getAllDataNoneRedux = (condition) => (dispatch) => {
  return setTokenAxios().get(`account-number`, { params: condition })
    .then(async res => {
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const createAccountNumber = (data) => (dispatch) => {
  return setTokenAxios().post(`account-number`, data)
    .then(async res => {
      dispatch({
        type: "CREATE_ACCOUNT_NUMBER",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const updateAccountNumber = (data, _id) => (dispatch) => {
  return setTokenAxios().put(`account-number/${_id}`, data)
    .then(async res => {
      dispatch({
        type: "UPDATE_ACCOUNT_NUMBER",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}

export const deleteAccountNumber = (_id) => (dispatch) => {
  return setTokenAxios().delete(`account-number/${_id}`)
    .then(async res => {
      dispatch({
        type: "DELETE_ACCOUNT_NUMBER",
        payload: res.data.data
      })
      return Promise.resolve({ message: GET_SUCCESS, data: res.data.data })
    })
    .catch((err) => {
      _showAlertError(err)
      return Promise.reject({ message: GET_FAILURE, error: err.data.message })
    })
}