const initialState = {notStarted:[], inProcessing:[], completed:[]}

const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REPLACE_TASK":
             return action.payload

        case "SET_TASK":
            var tmpState = {...state}
            if(action.payload.type === 'NOT_STARTED')
            {
                tmpState.notStarted = action.payload.data
            }
            if(action.payload.type === 'IN_PROGRESS')
            {
                tmpState.inProcessing = action.payload.data
            }
            if(action.payload.type === 'COMPLETED')
            {
                tmpState.completed = action.payload.data
            }
            return {...tmpState}


        case "UPDATE_TASK":
  
            var tmpState1 = {...state}
          

            if(action.payload.status === 'NOT_STARTED')
            {
                const index = (tmpState1.notStarted).findIndex(per => per._id === action.payload._id)
                if(index>-1)
                {
                    tmpState1.notStarted[index] = action.payload
                }
                else
                {
                    tmpState1.notStarted = [ action.payload, ...tmpState1.notStarted]
                }
                return {...tmpState1}
                
            }
            if(action.payload.status === 'IN_PROGRESS')
            {
                const index = (tmpState1.inProcessing).findIndex(per => per._id === action.payload._id)
                
                if(index>-1)
                {
                    tmpState1.inProcessing[index] = action.payload
                }
                else
                {
                    tmpState1.inProcessing = [ action.payload, ...tmpState1.inProcessing]
                }
                return {...tmpState1}
            }
            if(action.payload.status === 'COMPLETED')
            {
                const index = (tmpState1.completed).findIndex(per => per._id === action.payload._id)
                if(index>-1)
                {
                    tmpState1.completed[index] = action.payload
                }
                else
                {
                    tmpState1.completed = [ action.payload, ...tmpState1.completed]
                }
                return {...tmpState1}
            }
           
            return {...tmpState1}
        

        case "UPDATE_START_TASK":

            const task_ = {...state}
            var notStarted = [...task_.notStarted]
            var inProcessing = [...task_.inProcessing]



            const indexStart = notStarted.findIndex(per => per.id === action.payload.id)
            notStarted.splice(indexStart,1)
            task_.notStarted = notStarted

            const indexTmp = inProcessing.findIndex(per => per._id === action.payload._id)
            if(indexTmp>-1)
            {
                inProcessing.splice(indexTmp,1)
            }
            task_.inProcessing = [action.payload,...inProcessing]
            return {...task_};

        case "UPDATE_COMPLETED_TASK":

            const task_old = {...state}
            var inProcessing1 = [...task_old.inProcessing1]
            var completed = [...task_old.completed]
            const indexProcess = inProcessing1.findIndex(per => per.id === action.payload.id)
            inProcessing1.splice(indexProcess,1)
            task_old.inProcessing1 = inProcessing1

            const indexCo = completed.findIndex(per => per._id === action.payload._id)
            if(indexCo>-1)
            {
                completed.splice(indexCo,1)
            }
            task_old.completed = [action.payload,...completed]
            return {...task_old};
              
        
        case "DELETE_TASK":
            var tmpStateDel = {...state}
            var task_deleted = []
        
            if(action.payload.status === 'NOT_STARTED')
            {
                const index = (tmpStateDel.notStarted).findIndex(per => per._id === action.payload._id)
                if(index>-1)
                {
                    task_deleted = (tmpStateDel.notStarted).filter(del =>!(action.payload._id).includes(del._id) );
                    tmpStateDel.notStarted = task_deleted
                }
                return {...tmpStateDel}
                
            }
            if(action.payload.status === 'IN_PROGRESS')
            {
                const index = (tmpStateDel.inProcessing).findIndex(per => per._id === action.payload._id)
                if(index>-1)
                {
                    task_deleted = (tmpStateDel.inProcessing).filter(del =>!(action.payload._id).includes(del._id) );
                    tmpStateDel.inProcessing = task_deleted
                }
                return {...tmpStateDel}
            }
            if(action.payload.status === 'COMPLETED')
            {
                const index = (tmpStateDel.completed).findIndex(per => per._id === action.payload._id)
                if(index>-1)
                {
                    task_deleted = (tmpStateDel.completed).filter(del =>!(action.payload._id).includes(del._id) );
                    tmpStateDel.completed = task_deleted
                }
                return {...tmpStateDel}
            }
            

            return {...tmpStateDel}

        default:
            break;
    }

    return state
}

export default taskReducer